import React, { useState, useEffect, createRef } from "react";
import "./EventDashboard.css";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { useParams, useNavigate } from "react-router-dom";
import { useHelperClickTheme } from "../../../contexts/HelperContext";
// import usehisto

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import Close from "@mui/icons-material/Close";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useCookies } from "react-cookie";
import Leaderboard from "../../Leaderboard/Leaderboard";
import ErrorModal from "../../ErrorModal/ErrorModal";
import Pill from "../../Buttons/Pill";
import EventDashTeeTimes from "./EventDashTeeTimes";
import EventRemovePlayer from "./EventRemovePlayer";
import EventAddPlayer from "./EventAddPlayer";
import ScoreInput from "../ScoreInput/ScoreInput";
import EventHandicapEdit from "./EventHandicapEdit";
import EventDashGroupSelector from "../GroupSelector/EventDashGroupSelector";
import GroupSelector from "../GroupSelector/GroupSelector";
import { Dialog, Radio } from "@mui/material";
import { Oval } from "react-loader-spinner";
import BroadcastModal from "../../BroadcastModal/BroadcastModal";
import ClosestLongest from "../ClosestLongest/ClosestLongest";
import PrizeManager from "../../PrizeManager/PrizeManager";
import ReactToPdf from "react-to-pdf";
import EventTeamManager from "../EventCreator/EventTeamManager";
import Countout from "./Countout";
import PageHeader from "../../display/Rows/PageHeader";
import LiveDivisionManager from "./LiveDivisionManager";
import LiveTeeChange from "./LiveTeeChange";
import LiveGroups from "./LiveGroups";
import EventAddGuest from "./EventAddGuest";
import ScorePortal from "./ScorePortal";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import TwoClubEdit from "./TwoClubEdit";
import EventGuests from "../EventCreator/EventGuests";
import PlayerDQ from "./PlayerDQ";
import RemoveLiveRound from "./RemoveLiveRound";
import TVBoardSettings from "./TVBoardSettings";
import MenuSwitcher from "../../MenuSwitcher/MenuSwitcher";

function EventDashboard() {
  // {
  //  eventID, goBack = () => {}
  // }
  const navigate = useNavigate();
  const showHelperClick = useHelperClickTheme();
  function goBack() {
    navigate(-1);
  }
  const eventID = useParams().id;
  // Icons
  const GradientFullIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <OpenInFullIcon
        className="icon-cursor dg-icon hgb-ico ed-ico"
        onClick={onClick}
        sx={{ fill: "url(#linearColors)" }}
      />
    </>
  );
  const GradientEditIcon = ({ onClick, className }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <EditIcon
        className={`${className} dg-icon icon-cursor hgb-ico ed-ico`}
        onClick={onClick}
        sx={{ fill: "url(#linearColors)" }}
      />
    </>
  );

  const GradientInfoIcon = ({ onClick, className }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <InfoIcon
        onClick={onClick}
        className={`dg-icon ${className}`}
        sx={{ fill: "url(#linearColors)" }}
      />
    </>
  );

  const GradientCircleIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <CheckCircleIcon
        onClick={onClick}
        className="big-tick dg-icon"
        sx={{ fill: "url(#linearColors)" }}
      />
    </>
  );

  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  // useEffect(() => {
  //   alert(eventID);
  // }, [eventID]);

  const { currentUser, userDetails } = useAuth();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    submittedIDs: [],
    playerIDs: [],
    roundIDs: [],
  });

  const [shotgun, setShotgun] = useState(false);

  const [enableDivisions, setEnableDivisions] = useState(false);
  const [accountType, setAccountType] = useState("");

  const [showPO, setShowPO] = useState(false);
  const [showPODia, setShowPODia] = useState(false);

  const [selPo, setSelPo] = useState();

  const [show2c, setShow2C] = useState(false);
  const [twoMoney, setTwoMoney] = useState(0);

  const [error, setError] = useState("");

  const [disable, setDisable] = useState(false);

  const [showRemove, setShowRemove] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showHandicaps, setShowHandicaps] = useState(false);
  const [showGroups, setShowGroups] = useState(false);

  const [showBC, setShowBC] = useState(false);
  const [chatID, setChatID] = useState("");
  const [update, setUpdate] = useState(0);
  const [showFin, setShowFin] = useState(false);
  const [finishing, setFinishing] = useState(false);

  const [cancelChosen, setCancelChosen] = useState(false);
  const [cancelInput, setCancelInput] = useState("");
  const [cancelType, setCancelType] = useState("");

  const [players, setPlayers] = useState([]);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [leadOpen, setLeadOpen] = useState(false);
  const [timesOpen, setTimesOpen] = useState(false);

  const [twoEn, setTwoEn] = useState(false);

  const [notFirst, setNotFirst] = useState(false);

  const [completed, setCompleted] = useState(false);
  const [showComplete, setShowComplete] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const [showLD, setShowLD] = useState(false);
  const [showCTP, setShowCTP] = useState(false);

  const [ldArray, setLDArray] = useState([]);
  const [ctpArray, setCTPArray] = useState([]);

  const [showCO, setShowCO] = useState(false);

  const ref = createRef();

  useEffect(() => {
    if (userDetails) {
      setAccountType(userDetails.accountType);
      if (userDetails.accountType === "portal") {
        setToShow(<ScorePortal goBack={() => goBack()} eventID={eventID} />);
        setShow(true);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    // console.log(eventID);
    loadEvent();
    setShow(false);
  }, [eventID]);

  async function loadEvent() {
    setLoading(true);
    setUpdate((c) => c + 1);
    console.log(eventID);
    const eventDoc = await db.collection("events").doc(eventID).get();
    // console.log(1);

    const event = eventDoc.data();

    // if (!event.adminIDs.includes(currentUser.uid)) {
    //   // navigate("/");
    // }

    const { playerIDs, format, adjusted } = event;
    const dateObject = new Date(event.eventDate.toDate());
    const dateString = dateObject.toLocaleDateString("en-us", {
      dateStyle: "medium",
    });
    setDisable(event.completed);
    setTwoEn(event.twoClub ?? false);
    event.dateObject = dateObject;
    event.dateString = dateString;
    const chatID = event.chatID ?? "";
    setChatID(chatID);
    if (event.activeRoundID === event.roundIDs[0]) {
      setNotFirst(false);
    } else {
      setNotFirst(true);
    }
    let rdID = "";
    if (event.activeRoundID !== "") {
      rdID = event.activeRoundID;
    } else {
      rdID = event.roundIDs[event.roundIDs.length - 1];
    }

    const roundIndex = event.roundIDs.indexOf(rdID);

    const roundDoc = await db.collection("rounds").doc(rdID).get();

    const round = roundDoc.data();
    const {
      leaderboard,
      teeMap,
      ldEnabled,
      ctpEnabled,
      submittedIDs,
      playoff,
    } = round;

    setShotgun(round.shotgun ?? false);

    if (round.playerIDs.length === round.submittedIDs.length) {
      setDisable(true);
    }

    if (playoff === "countout") {
      if (leaderboard[0].score === leaderboard[1].score) {
        setShowCO(true);
      }
    }
    if (round.enableDivisions) {
      setEnableDivisions(true);
    }
    setShowLD(ldEnabled);
    setShowCTP(ctpEnabled);
    event.leaderboard = leaderboard;
    const players = [...leaderboard];
    for (let i = 0; i < players.length; i++) {
      const playerID = players[i].playerID;
      players[i].tee = teeMap[playerID];
    }
    let finished = true;
    // Check completion
    for (let i = 0; i < leaderboard.length; i++) {
      const row = leaderboard[i];
      if (leaderboard[i].thru !== 18 && !row.dq && !row.wd) {
        finished = false;
      }
    }

    if (leaderboard.length > 1 && finished && !round.completed) {
      if (leaderboard[0].score === leaderboard[1].score) {
        setShowPO(true);
      }
    }

    setCompleted(finished);
    if (format === "team-net-strokeplay") {
      const refs = playerIDs.map((playerID) =>
        db.collection("users").doc(playerID).get()
      );
      const docs = await Promise.all(refs);
      const playerArr = [];
      for (let i = 0; i < docs.length; i++) {
        const playerDoc = docs[i];
        const player = playerDoc.data();
        const playerID = playerDoc.id;
        const { handicap, firstName, lastName, profileImage } = player;
        const name = `${firstName} ${lastName}`;
        playerArr.push({
          playerID,
          name,
          handicap: adjusted ? Math.round((handicap + 4) * 0.8) : handicap,
          profileImage,
        });
      }
      // console.log(playerArr);
      setPlayers(playerArr);
    } else {
      setPlayers(players);
    }

    // console.log(1);
    event.roundIndex = roundIndex;
    event.teeTimes = round.teeTimes;
    event.submittedIDs = submittedIDs;
    // console.log(event.teamAllowance);
    setDetails(event);
    setLoading(false);
  }

  async function cancelEvent() {
    setLoading(true);
    await db.collection("events").doc(eventID).update({
      cancelled: true,
      cancelType,
      completed: true,
    });
    setCancelChosen(false);
    setLoading(false);
  }

  async function submitCards() {
    setSubmitting(true);
    try {
      const rdoc = await db
        .collection("rounds")
        .doc(details.activeRoundID)
        .get();
      const rData = rdoc.data();
      const { playerIDs } = rData;
      await db.collection("rounds").doc(details.activeRoundID).update({
        submittedIDs: playerIDs,
      });
      setSubmitted(true);
      setDisable(true);
    } catch (err) {
      setSubmitError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }

    setSubmitting(false);
  }

  async function saveTwoClub() {
    db.collection("events").doc(eventID).update({
      twoClubMoney: twoMoney,
    });
    setShow2C(false);
  }

  async function finishEvent() {
    setFinishing(true);
    try {
      await db.collection("events").doc(eventID).update({
        portalComplete: true,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }

    setFinishing(false);
    setShowFin(false);
  }

  async function savePOWinner() {
    setLoading(true);
    setShowPODia(false);
    try {
      await db.collection("events").doc(eventID).update({
        playoffWinner: selPo,
      });
    } catch (err) {
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="event-dash">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      {loading && <BackdropLoader />}
      {showRemove && (
        <EventRemovePlayer
          hide={() => setShowRemove(false)}
          eventID={eventID}
          reload={loadEvent}
          roundID={details.activeRoundID}
        />
      )}
      {showAdd && (
        <EventAddPlayer
          hide={() => setShowAdd(false)}
          eventID={eventID}
          reload={loadEvent}
        />
      )}
      {showBC && (
        <BroadcastModal
          name={details.eventName}
          close={() => setShowBC(false)}
          eventID={eventID}
        />
      )}
      {showHandicaps && (
        <EventHandicapEdit
          hide={() => setShowHandicaps(false)}
          eventID={eventID}
          rounds={details.roundIDs}
          roundID={details.activeRoundID}
          teamAllowance={details.teamAllowance}
          defaultPlayers={players}
        />
      )}
      {showGroups && (
        <EventDashGroupSelector
          hide={() => setShowGroups(false)}
          roundIDs={details.roundIDs}
          defaultPlayers={details.leaderboard}
        />
      )}

      <Dialog open={show2c} onClose={() => setShow2C(false)}>
        <div className="ed-two-club">
          <PageHeader
            showClose
            close={() => setShow2C(false)}
            text="Two Club Money"
          />
          <div className="ph-msg">
            <p>The total will be divided amongst players</p>
          </div>
          <div className="pd-20">
            <div className="input-group">
              <p>Total Amount</p>
              <input
                type="number"
                value={twoMoney}
                onChange={(e) => setTwoMoney(parseInt(e.target.value))}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center mb-20 mt-20">
            <button className="default-button" onClick={saveTwoClub}>
              Save
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showComplete} onClose={() => setShowComplete(false)}>
        <div className="submit-dia">
          <PageHeader
            text="Submit Scorecards"
            showClose
            close={() => setShowComplete(false)}
          />
          {submitting ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : submitted ? (
            <>
              <div className="mpa-fin flex-center">
                <GradientCircleIcon />
              </div>
              <p>
                Scorecards have been submitted. Stats and highlights will appear
                shortly.
              </p>
            </>
          ) : (
            <>
              <GradientInfoIcon className={"big-icon"} onClick={() => {}} />
              <p>
                Are you sure you want to submit all scorecards? This will close
                this round, and this event should this be the final round.
              </p>
              {submitError !== "" && (
                <div className="error-message-row">
                  <p>{submitError}</p>
                </div>
              )}
              <div className="default-button-row">
                <button onClick={submitCards} className="default-button">
                  Confirm
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>
      <Dialog
        open={showFin}
        onClose={() => setShowFin(false)}
        className="cancel-dia"
      >
        <div className="cancel-dia-content">
          <PageHeader
            showClose
            close={() => setShowFin(false)}
            text="Finish Event"
          />
          <div className="ph-msg">
            <p>
              This will remove the event from your live event dashboard, with no
              post round calculations to be performed.
            </p>
          </div>
          {finishing && (
            <>
              <div className="flex-center mb-20 mt-20 loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          )}
          {!finishing && (
            <div className="flex-center mt-20 mb-20">
              <button onClick={finishEvent} className="default-button">
                Confirm
              </button>
            </div>
          )}
        </div>
      </Dialog>

      <Dialog
        open={cancelChosen}
        onClose={() => setCancelChosen(false)}
        className="cancel-dia"
      >
        <div className="cancel-dia-content">
          <PageHeader
            text="Cancel Event"
            showClose
            close={() => setCancelChosen(false)}
          />

          <div className="ph-msg mb-20">
            <p>
              Select an option below to cancel this event. <br />
              <br />
              This action cannot be undone, and no stats will be recorded for
              this event
            </p>
          </div>

          <div className="cd-radio mb-20">
            <div className="cdr-group">
              <Radio
                checked={cancelType === "no-points"}
                value={"no-points"}
                onChange={(e) => setCancelType(e.target.value)}
              />
              <p onClick={() => setCancelType("no-points")}>
                Cancel event with no points awarded
              </p>
            </div>
            {details.leagueID !== "standalone" && (
              <div className="cdr-group">
                <Radio
                  checked={cancelType === "full-points"}
                  value={"full-points"}
                  onChange={(e) => setCancelType(e.target.value)}
                />
                <p onClick={() => setCancelType("full-points")}>
                  Cancel event with full points awarded according to current
                  standings
                </p>
              </div>
            )}
            {details.leagueID !== "standalone" && (
              <div className="cdr-group">
                <Radio
                  checked={cancelType === "half-points"}
                  value={"half-points"}
                  onChange={(e) => setCancelType(e.target.value)}
                />
                <p onClick={() => setCancelType("half-points")}>
                  Cancel event with 50% of points awarded according to current
                  standings
                </p>
              </div>
            )}
          </div>

          <div className="input-group">
            <p>Type "CONFIRM" to confirm this</p>
            <input
              className="default-input"
              value={cancelInput}
              placeholder="CONFIRM"
              onChange={(e) => setCancelInput(e.target.value)}
            />
          </div>

          <div className="flex-center mt-20">
            <button
              onClick={cancelEvent}
              disabled={cancelInput !== "CONFIRM" || cancelType === ""}
              className="default-button"
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showPODia} onClose={() => setShowPODia(false)}>
        <div className="po-wi-ma">
          <PageHeader
            text="Enter Playoff Winner"
            showClose
            close={() => setShowPODia(false)}
          />
          <div className="po-pls pd-20">
            {details.leaderboard &&
              details.leaderboard.map((pl, i) => {
                if (pl.rank !== 1) {
                  return null;
                }

                return (
                  <PlayerItem
                    noLink
                    showBg={selPo && selPo.playerID === pl.playerID}
                    showProfile={() =>
                      setSelPo((c) => {
                        if (c) {
                          if (c.playerID === pl.playerID) {
                            return null;
                          } else {
                            return pl;
                          }
                        } else {
                          return pl;
                        }
                      })
                    }
                    hideIcons
                    name={pl.name}
                    key={`${i}po`}
                    hideHCP
                  />
                );
              })}
          </div>
          <div className="flex-center mb-40">
            <button
              onClick={savePOWinner}
              disabled={!selPo}
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>

      {show ? (
        toShow
      ) : (
        <>
          <div onClick={goBack} className="back-row">
            <ArrowBack />
            <p>Back</p>
          </div>
          <div className="ph-me-opt">
            <div className="page-header">
              <div>
                <h2>
                  Event Dashboard{!loading && ` - ${details.eventName}`}{" "}
                  {!loading &&
                    details.roundIDs.length > 1 &&
                    `(Round ${details.roundIndex + 1})`}
                </h2>
                <div className="aboh-border"></div>
              </div>
            </div>
            <MenuSwitcher />
          </div>

          {/* {details &&
            details.submittedIDs.length === details.playerIDs.length && (
              <div className="input-group pl-20">
                <p>Calculation in progress</p>
              </div>
            )} */}

          {accountType !== "scorer" && accountType !== "portal" && (
            <>
              {cookies["menu-type"] === "drop" && (
                <>
                  <div className="flex flex-align-center mb-20 mt-10 ddmb-box">
                    <div className="ddm-box">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <button className="ddm-but">
                            <span>General</span>
                            <KeyboardArrowDownIcon className="ddm-ico" />
                          </button>
                        </DropdownMenu.Trigger>

                        <DropdownMenu.Portal>
                          <DropdownMenu.Content
                            className="DropdownMenuContent"
                            sideOffset={5}
                          >
                            <DropdownMenu.Item
                              onClick={() => {
                                navigate(`/event-home/${eventID}`);
                              }}
                              className="DropdownMenuItem"
                            >
                              Event Home
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              onClick={() => {
                                window.open(
                                  `https://www.theugatour.com/events/${eventID}`,
                                  "_blank"
                                );
                              }}
                              className="DropdownMenuItem"
                            >
                              Public Link
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              onClick={() => {
                                window.open(
                                  `https://www.theugatour.com/leaderboards/${eventID}`,
                                  "_blank"
                                );
                              }}
                              className="DropdownMenuItem"
                            >
                              TV Leaderboard
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <TVBoardSettings
                                    eventID={eventID}
                                    goBack={() => {
                                      setShow(false);
                                      showHelperClick("evt-dash");
                                    }}
                                  />
                                );
                                setShow(true);
                                showHelperClick("tv-setts");
                              }}
                              className="DropdownMenuItem"
                            >
                              TV Settings
                            </DropdownMenu.Item>
                            {details.roundIDs &&
                              details.roundIDs.length > 1 &&
                              !disable && (
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setToShow(
                                      <RemoveLiveRound
                                        eventID={eventID}
                                        goBack={() => setShow(false)}
                                      />
                                    );
                                    setShow(true);
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Remove Round
                                </DropdownMenu.Item>
                              )}
                          </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Root>
                    </div>
                    <div className="ddm-box">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <button className="ddm-but">
                            <span>Scoring</span>
                            <KeyboardArrowDownIcon className="ddm-ico" />
                          </button>
                        </DropdownMenu.Trigger>

                        <DropdownMenu.Portal>
                          <DropdownMenu.Content
                            className="DropdownMenuContent"
                            sideOffset={5}
                          >
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <LiveGroups
                                    evtComplete={disable}
                                    eventID={eventID}
                                    eventName={details.eventName}
                                    roundID={details.activeRoundID}
                                    goBack={() => {
                                      setShow(false);
                                      showHelperClick("evt-dash");
                                    }}
                                  />
                                );
                                setShow(true);
                                showHelperClick("group-view");
                              }}
                              className="DropdownMenuItem"
                            >
                              Group View
                            </DropdownMenu.Item>
                            {!disable && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <ScoreInput
                                      eventName={details.eventName}
                                      players={details.leaderboard}
                                      roundIDs={details.roundIDs}
                                      eventID={eventID}
                                      roundID={details.activeRoundID}
                                      goBack={() => setShow(false)}
                                    />
                                  );
                                  setShow(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Score Input
                              </DropdownMenu.Item>
                            )}
                            {!disable && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setShowHandicaps(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Edit Handicaps
                              </DropdownMenu.Item>
                            )}

                            {!disable && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <LiveTeeChange
                                      rounds={details.roundIDs}
                                      eventID={eventID}
                                      hide={() => setShow(false)}
                                      defaultPlayers={players}
                                      roundID={details.activeRoundID}
                                    />
                                  );
                                  setShow(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Edit Tees
                              </DropdownMenu.Item>
                            )}
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <Countout
                                    goBack={() => {
                                      setShow(false);
                                      showHelperClick("evt-dash");
                                    }}
                                    roundID={details.activeRoundID}
                                  />
                                );
                                setShow(true);
                                showHelperClick("ctout");
                              }}
                              className="DropdownMenuItem"
                            >
                              Countout
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Root>
                    </div>
                    {!disable && (
                      <div className="ddm-box">
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger asChild>
                            <button className="ddm-but">
                              <span>Players</span>
                              <KeyboardArrowDownIcon className="ddm-ico" />
                            </button>
                          </DropdownMenu.Trigger>

                          <DropdownMenu.Portal>
                            <DropdownMenu.Content
                              className="DropdownMenuContent"
                              sideOffset={5}
                            >
                              {!notFirst && !disable && (
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setShowAdd(true);
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Add Player
                                </DropdownMenu.Item>
                              )}
                              {!notFirst &&
                                !disable &&
                                details.roundIDs.length === 1 && (
                                  <DropdownMenu.Item
                                    onClick={() => {
                                      setToShow(
                                        <EventAddGuest
                                          eventID={eventID}
                                          close={() => setShow(false)}
                                        />
                                      );
                                      setShow(true);
                                    }}
                                    className="DropdownMenuItem"
                                  >
                                    Add Guest
                                  </DropdownMenu.Item>
                                )}
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <GroupSelector
                                      defaultShotgun={shotgun}
                                      roundIDs={details.roundIDs ?? []}
                                      activeRoundID={details.activeRoundID}
                                      reload={loadEvent}
                                      teams={details.teams}
                                      clubID={details.clubID}
                                      roundID={details.activeRoundID}
                                      defaultGroups={details.teeTimes}
                                      goBack={() => setShow(false)}
                                      live
                                      courseDetails={{
                                        teeArray: [""],
                                      }}
                                      players={players}
                                    />
                                  );
                                  setShow(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Edit Groups
                              </DropdownMenu.Item>

                              <DropdownMenu.Item
                                onClick={() => {
                                  // setShowGroups(true);
                                  setToShow(
                                    <EventTeamManager
                                      eventID={eventID}
                                      goBack={() => setShow(false)}
                                    />
                                  );
                                  setShow(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Edit Teams
                              </DropdownMenu.Item>
                              {enableDivisions && (
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setToShow(
                                      <LiveDivisionManager
                                        goBack={() => setShow(false)}
                                        reload={loadEvent}
                                        eventID={eventID}
                                        roundIDs={details.roundIDs}
                                        roundID={details.activeRoundID}
                                      />
                                    );
                                    setShow(true);
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Manage Divisions
                                </DropdownMenu.Item>
                              )}
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <EventGuests
                                      eventID={eventID}
                                      defaultGuests={details.guests ?? []}
                                      goBack={() => {
                                        setShow(false);
                                        showHelperClick("evt-dash");
                                      }}
                                      live
                                      customEvtID={details.guestEventID ?? ""}
                                    />
                                  );
                                  setShow(true);
                                  showHelperClick("evt-guests");
                                }}
                                className="DropdownMenuItem"
                              >
                                Manage Guests
                              </DropdownMenu.Item>
                              {twoEn && (
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setToShow(
                                      <TwoClubEdit
                                        roundID={details.activeRoundID}
                                        goBack={() => setShow(false)}
                                      />
                                    );
                                    setShow(true);
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Manage Guests
                                </DropdownMenu.Item>
                              )}
                              <DropdownMenu.Item
                                onClick={() => {
                                  setShowRemove(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Remove Player
                              </DropdownMenu.Item>
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <PlayerDQ
                                      eventID={eventID}
                                      goBack={() => {
                                        setShow(false);
                                        showHelperClick("evt-dash");
                                      }}
                                    />
                                  );
                                  setShow(true);
                                  showHelperClick("dqs");
                                }}
                                className="DropdownMenuItem"
                              >
                                Disqualifications
                              </DropdownMenu.Item>
                              <DropdownMenu.Item
                                onClick={() => {
                                  setToShow(
                                    <PlayerDQ
                                      type="wd"
                                      eventID={eventID}
                                      goBack={() => {
                                        setShow(false);
                                        showHelperClick("evt-dash");
                                      }}
                                    />
                                  );
                                  setShow(true);
                                  showHelperClick("withdrawals");
                                }}
                                className="DropdownMenuItem"
                              >
                                Withdrawals
                              </DropdownMenu.Item>
                            </DropdownMenu.Content>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                      </div>
                    )}
                    <div className="ddm-box">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <button className="ddm-but">
                            <span>Admin</span>
                            <KeyboardArrowDownIcon className="ddm-ico" />
                          </button>
                        </DropdownMenu.Trigger>

                        <DropdownMenu.Portal>
                          <DropdownMenu.Content
                            className="DropdownMenuContent"
                            sideOffset={5}
                          >
                            {details &&
                              details.submittedIDs.length !==
                                details.playerIDs.length &&
                              !submitted &&
                              completed &&
                              !disable && (
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setShowComplete(true);
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Submit Scorecards
                                </DropdownMenu.Item>
                              )}
                            {showPO && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setShowPODia(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Playoff Winner
                              </DropdownMenu.Item>
                            )}
                            {chatID === "" && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setShowBC(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Group Message
                              </DropdownMenu.Item>
                            )}

                            {twoEn && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setShow2C(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Two Club Money
                              </DropdownMenu.Item>
                            )}
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <PrizeManager
                                    eventID={eventID}
                                    goBack={() => {
                                      setShow(false);
                                      showHelperClick("evt-dash");
                                    }}
                                  />
                                );
                                setShow(true);
                                showHelperClick("prizes");
                              }}
                              className="DropdownMenuItem"
                            >
                              Manage Prizes
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              onClick={() => {
                                setShowFin(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Finish Event
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              onClick={() => {
                                setCancelChosen(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Cancel Event
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Root>
                    </div>
                  </div>
                </>
              )}
              {cookies["menu-type"] !== "drop" && (
                <div className="ch-links">
                  <div className="ch-link">
                    <div className="sub-header">
                      <h5>General</h5>
                    </div>
                    <div className="quick-links">
                      <Pill
                        text={"Event Home"}
                        onClick={() => {
                          navigate(`/event-home/${eventID}`);
                        }}
                      />
                      <Pill
                        text={"Public Link"}
                        onClick={() => {
                          window.open(
                            `https://www.theugatour.com/events/${eventID}`,
                            "_blank"
                          );
                        }}
                      />
                      <Pill
                        text={"TV Leaderboard"}
                        onClick={() => {
                          window.open(
                            `https://www.theugatour.com/leaderboards/${eventID}`,
                            "_blank"
                          );
                        }}
                      />
                      <Pill
                        text={"TV Settings"}
                        onClick={() => {
                          setToShow(
                            <TVBoardSettings
                              eventID={eventID}
                              goBack={() => {
                                setShow(false);
                                showHelperClick("evt-dash");
                              }}
                            />
                          );
                          setShow(true);
                          showHelperClick("tv-setts");
                        }}
                      />
                      {details.roundIDs &&
                        details.roundIDs.length > 1 &&
                        !disable && (
                          <Pill
                            text={"Remove Round"}
                            red
                            onClick={() => {
                              setToShow(
                                <RemoveLiveRound
                                  eventID={eventID}
                                  goBack={() => setShow(false)}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        )}
                      {/* <Pill text={"Printables"} onClicl={() => {}} /> */}
                    </div>
                  </div>
                  {true && (
                    <div className="ch-link">
                      <div className="sub-header">
                        <h5>Scoring</h5>
                      </div>
                      <div className="quick-links">
                        <Pill
                          text={"Group View"}
                          onClick={() => {
                            setToShow(
                              <LiveGroups
                                evtComplete={disable}
                                eventID={eventID}
                                eventName={details.eventName}
                                roundID={details.activeRoundID}
                                goBack={() => {
                                  setShow(false);
                                  showHelperClick("evt-dash");
                                }}
                              />
                            );
                            setShow(true);
                            showHelperClick("group-view");
                          }}
                        />
                        {!disable && (
                          <Pill
                            text={"Score Input"}
                            onClick={() => {
                              setToShow(
                                <ScoreInput
                                  eventName={details.eventName}
                                  players={details.leaderboard}
                                  roundIDs={details.roundIDs}
                                  eventID={eventID}
                                  roundID={details.activeRoundID}
                                  goBack={() => setShow(false)}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        )}
                        {!disable && (
                          <Pill
                            text={"Edit Handicaps"}
                            onClick={() => {
                              setShowHandicaps(true);
                            }}
                          />
                        )}
                        {!disable && (
                          <Pill
                            text={"Edit Tees"}
                            onClick={() => {
                              setToShow(
                                <LiveTeeChange
                                  rounds={details.roundIDs}
                                  eventID={eventID}
                                  hide={() => setShow(false)}
                                  defaultPlayers={players}
                                  roundID={details.activeRoundID}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        )}
                        {/* {showCO && ( */}
                        <Pill
                          text={"Countout"}
                          onClick={() => {
                            setToShow(
                              <Countout
                                goBack={() => {
                                  setShow(false);
                                  showHelperClick("evt-dash");
                                }}
                                roundID={details.activeRoundID}
                              />
                            );
                            setShow(true);
                            showHelperClick("ctout");
                          }}
                        />
                        {/* )} */}
                      </div>
                    </div>
                  )}
                  {!disable && (
                    <div className="ch-link">
                      <div className="sub-header">
                        <h5>Players</h5>
                      </div>
                      <div className="quick-links">
                        {!notFirst && !disable && (
                          <Pill
                            text={"Add Player"}
                            onClick={() => {
                              setShowAdd(true);
                            }}
                          />
                        )}
                        {!notFirst &&
                          !disable &&
                          details.roundIDs.length === 1 && (
                            <Pill
                              text={"Add Guest"}
                              onClick={() => {
                                setToShow(
                                  <EventAddGuest
                                    eventID={eventID}
                                    close={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              }}
                            />
                          )}

                        {!disable && (
                          <Pill
                            text={"Edit Groups"}
                            onClick={() => {
                              // setShowGroups(true);
                              setToShow(
                                <GroupSelector
                                  defaultShotgun={shotgun}
                                  roundIDs={details.roundIDs ?? []}
                                  activeRoundID={details.activeRoundID}
                                  reload={loadEvent}
                                  teams={details.teams}
                                  clubID={details.clubID}
                                  roundID={details.activeRoundID}
                                  defaultGroups={details.teeTimes}
                                  goBack={() => setShow(false)}
                                  live
                                  courseDetails={{
                                    teeArray: [""],
                                  }}
                                  players={players}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        )}
                        {!disable && details.enableTeams && (
                          <Pill
                            text={"Edit Teams"}
                            onClick={() => {
                              // setShowGroups(true);
                              setToShow(
                                <EventTeamManager
                                  eventID={eventID}
                                  goBack={() => setShow(false)}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        )}
                        {enableDivisions && (
                          <Pill
                            text={"Manage Divisions"}
                            onClick={() => {
                              setToShow(
                                <LiveDivisionManager
                                  goBack={() => setShow(false)}
                                  reload={loadEvent}
                                  eventID={eventID}
                                  roundIDs={details.roundIDs}
                                  roundID={details.activeRoundID}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        )}
                        <Pill
                          text={"Manage Guests"}
                          onClick={() => {
                            setToShow(
                              <EventGuests
                                eventID={eventID}
                                defaultGuests={details.guests ?? []}
                                goBack={() => {
                                  setShow(false);
                                  showHelperClick("evt-dash");
                                }}
                                live
                                customEvtID={details.guestEventID ?? ""}
                              />
                            );
                            setShow(true);
                            showHelperClick("evt-guests");
                          }}
                        />
                        {twoEn && (
                          <Pill
                            text={"Edit 2 Clubs"}
                            onClick={() => {
                              setToShow(
                                <TwoClubEdit
                                  roundID={details.activeRoundID}
                                  goBack={() => setShow(false)}
                                />
                              );
                              setShow(true);
                            }}
                          />
                        )}
                        {!disable && (
                          <Pill
                            red
                            text={"Remove Player"}
                            onClick={() => {
                              setShowRemove(true);
                            }}
                          />
                        )}
                        {!disable && (
                          <Pill
                            text={"Disqualifications"}
                            red
                            onClick={() => {
                              setToShow(
                                <PlayerDQ
                                  eventID={eventID}
                                  goBack={() => {
                                    setShow(false);
                                    showHelperClick("evt-dash");
                                  }}
                                />
                              );
                              setShow(true);
                              showHelperClick("dqs");
                            }}
                          />
                        )}
                        {!disable && (
                          <Pill
                            text={"Withdrawals"}
                            red
                            onClick={() => {
                              setToShow(
                                <PlayerDQ
                                  type="wd"
                                  eventID={eventID}
                                  goBack={() => {
                                    setShow(false);
                                    showHelperClick("evt-dash");
                                  }}
                                />
                              );
                              setShow(true);
                              showHelperClick("withdrawals");
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="ch-link">
                    <div className="sub-header">
                      <h5>Admin</h5>
                    </div>
                    <div className="quick-links">
                      {details &&
                        details.submittedIDs.length !==
                          details.playerIDs.length &&
                        !submitted &&
                        completed &&
                        !disable && (
                          <Pill
                            text={"Submit Scorecards"}
                            onClick={() => setShowComplete(true)}
                          />
                        )}
                      {showPO && (
                        <Pill
                          text={"Playoff Winner"}
                          onClick={() => setShowPODia(true)}
                        />
                      )}
                      <Pill
                        text={"Group Message"}
                        onClick={() => {
                          if (chatID === "") {
                            setShowBC(true);
                          }
                        }}
                      />
                      {twoEn && (
                        <Pill
                          text={"Two Club Money"}
                          onClick={() => {
                            setShow2C(true);
                          }}
                        />
                      )}
                      <Pill
                        text={"Manage Prizes"}
                        onClick={() => {
                          setToShow(
                            <PrizeManager
                              eventID={eventID}
                              goBack={() => {
                                setShow(false);
                                showHelperClick("evt-dash");
                              }}
                            />
                          );
                          setShow(true);
                          showHelperClick("prizes");
                        }}
                      />
                      {!disable && (
                        <Pill
                          text={"Finish Event"}
                          onClick={() => {
                            setShowFin(true);
                          }}
                        />
                      )}
                      {!disable && (
                        <Pill
                          red
                          text={"Cancel Event"}
                          onClick={() => {
                            setCancelChosen(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {(accountType === "scorer" || accountType === "portal") && (
            <div className="quick-links">
              <Pill
                text={"Group View"}
                onClick={() => {
                  setToShow(
                    <LiveGroups
                      evtComplete={disable}
                      eventID={eventID}
                      eventName={details.eventName}
                      roundID={details.activeRoundID}
                      goBack={() => setShow(false)}
                    />
                  );
                  setShow(true);
                }}
              />
              {!disable && (
                <Pill
                  text={"Score Input"}
                  onClick={() => {
                    setToShow(
                      <ScoreInput
                        eventName={details.eventName}
                        players={details.leaderboard}
                        roundIDs={details.roundIDs}
                        eventID={eventID}
                        roundID={details.activeRoundID}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                />
              )}
              {false && (
                <Pill
                  text={"Edit Handicaps"}
                  onClick={() => {
                    setShowHandicaps(true);
                  }}
                />
              )}
              {!disable && false && (
                <Pill
                  text={"Edit Tees"}
                  onClick={() => {
                    setToShow(
                      <LiveTeeChange
                        eventID={eventID}
                        hide={() => setShow(false)}
                        defaultPlayers={players}
                        roundID={details.activeRoundID}
                      />
                    );
                    setShow(true);
                  }}
                />
              )}

              {false && !notFirst && !disable && (
                <Pill
                  text={"Add Player"}
                  onClick={() => {
                    setShowAdd(true);
                  }}
                />
              )}
              {!notFirst && false && accountType === "scorer" && !disable && (
                <Pill
                  text={"Add Guest"}
                  onClick={() => {
                    setToShow(
                      <EventAddGuest
                        eventID={eventID}
                        close={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                />
              )}
              {false && !disable && accountType === "scorer" && (
                <Pill
                  red
                  text={"Remove Player"}
                  onClick={() => {
                    setShowRemove(true);
                  }}
                />
              )}
              {false && !disable && details.enableTeams && (
                <Pill
                  text={"Edit Teams"}
                  onClick={() => {
                    // setShowGroups(true);
                    setToShow(
                      <EventTeamManager
                        eventID={eventID}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                />
              )}
              {!disable && false && accountType === "scorer" && (
                <Pill
                  text={"Edit Groups"}
                  onClick={() => {
                    // setShowGroups(true);
                    setToShow(
                      <GroupSelector
                        defaultShotgun={shotgun}
                        roundIDs={details.roundIDs}
                        activeRoundID={details.activeRoundID}
                        reload={loadEvent}
                        teams={details.teams}
                        roundID={details.activeRoundID}
                        defaultGroups={details.teeTimes}
                        goBack={() => setShow(false)}
                        live
                        courseDetails={{
                          teeArray: [""],
                        }}
                        players={players}
                      />
                    );
                    setShow(true);
                  }}
                />
              )}
              {showCO && (
                <Pill
                  text={"Countout"}
                  onClick={() => {
                    setToShow(
                      <Countout
                        goBack={() => {
                          setShow(false);
                          showHelperClick("evt-dash");
                        }}
                        roundID={details.activeRoundID}
                      />
                    );
                    setShow(true);
                    showHelperClick("ctout");
                  }}
                />
              )}
            </div>
          )}

          <div className="edb-box">
            <div className="ed-boxes">
              {!timesOpen && (
                <div className={leadOpen ? "ed-box ed-box-open" : "ed-box"}>
                  <div className="sub-header mb-20">
                    <h5>Leaderboard</h5>

                    <GradientFullIcon
                      onClick={() => setLeadOpen((current) => !current)}
                    />
                  </div>
                  <div className="ed-lb">
                    {!loading && (
                      <Leaderboard
                        eventDetails={details}
                        leagueID={details.leagueID}
                        roundID={details.activeRoundID}
                        uid={currentUser.uid}
                        eventID={eventID}
                      />
                    )}
                  </div>
                </div>
              )}
              {!leadOpen && (
                <div className={timesOpen ? "ed-box ed-box-open" : "ed-box"}>
                  <div className="sub-header mb-20">
                    <h5>Tee Times</h5>
                    <div className="ed-box-icons">
                      {timesOpen && (
                        <GradientEditIcon
                          onClick={() => {
                            setToShow(
                              <GroupSelector
                                defaultShotgun={shotgun}
                                teams={details.teams}
                                reload={loadEvent}
                                roundID={details.activeRoundID}
                                defaultGroups={details.teeTimes}
                                goBack={() => setShow(false)}
                                live
                                courseDetails={{
                                  teeArray: [""],
                                }}
                                players={players}
                              />
                            );
                            setShow(true);
                          }}
                          className={"mr-10"}
                        />
                      )}
                      <GradientFullIcon
                        onClick={() => setTimesOpen((current) => !current)}
                      />
                    </div>
                  </div>
                  {/* <div className="ed-lb"> */}
                  {!loading && (
                    <EventDashTeeTimes
                      eventID={eventID}
                      roundID={details.activeRoundID}
                    />
                  )}
                  {/* </div> */}
                </div>
              )}
              {showLD && (
                <div className="ed-box">
                  {!loading && (
                    <ClosestLongest
                      selected="drive"
                      eventID={eventID}
                      roundIDs={details.roundIDs}
                    />
                  )}
                </div>
              )}
              {showCTP && (
                <div className="ed-box">
                  {!loading && (
                    <ClosestLongest
                      selected="pin"
                      eventID={eventID}
                      roundIDs={details.roundIDs}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EventDashboard;
