/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./MatchplayScore.css";

function MatchplayScore({
  hcpOne,
  hcpTwo,
  idOne,
  idTwo,
  nameOne,
  nameTwo,
  imgOne,
  imgTwo,
  score,
  holes,
  leader,
  hideThru = false,
  teamOneColor = "#1e7a69",
  teamTwoColor = "#232c2e",
  preMatch = false,
  t1Pts = 0,
  t2Pts = 0,
  bbs = false,
  handleClick = () => {},
  noMargin = false,
  complete = false,
}) {
  useEffect(() => {
    // console.log(leader, idOne);
    // console.log(18 - holes, score);
    if (leader !== "") {
      // console.log(leader === idOne);
    }
    // console.log(leader, idOne);
    // console.log(teamOneColor, teamTwoColor);
    // console.log(18 - holes);
  }, []);

  return (
    <div
      onClick={handleClick}
      style={{ marginBottom: noMargin ? "0px" : "20px" }}
      className="matchplay-score"
    >
      <div className={leader === idOne ? "ms-left msla ms-active" : "ms-left"}>
        <div
          style={{
            backgroundColor: leader === idOne ? teamOneColor : "transparent",
          }}
          className="ms-left-box"
        >
          <p>{nameOne}</p>
        </div>
        <div
          style={{
            borderColor:
              leader === idOne
                ? `transparent transparent transparent ${teamOneColor}`
                : "transparent transparent transparent transparent",
            backgroundColor: "transparent",
          }}
          className="ms-left-border"
        ></div>
      </div>
      <div className="ms-mid">
        <div
          style={{
            backgroundImage:
              leader === idOne && leader !== undefined
                ? `linear-gradient(90deg, ${teamOneColor} 0%, ${teamOneColor} 100%)`
                : leader === idTwo && leader !== undefined
                ? `linear-gradient(90deg, ${teamTwoColor} 0%, ${teamTwoColor} 100%)`
                : `linear-gradient(90deg, ${teamOneColor} 0%, ${teamTwoColor} 100%)`,
          }}
          className="msm-top"
        >
          <div className="score-circle">
            {bbs ? (
              <>
                <p>
                  {t1Pts} / {t2Pts}
                </p>
              </>
            ) : (
              <>
                {18 - holes > score && (
                  <p>{score === 0 ? "AS" : `${score} Up`}</p>
                )}
                {18 - holes < score && (
                  <p>
                    {score === 1
                      ? "1 Up"
                      : score === 2
                      ? "2 Up"
                      : `${score} & ${18 - holes}`}
                  </p>
                )}
                {18 - holes === score && (
                  <p>{score === 0 ? "A/S" : `${score} up`}</p>
                )}
              </>
            )}
            {/* <p></p> */}
          </div>
        </div>
        {!hideThru && (
          <div className="msm-bot">
            <p>Thru</p>
            <p>{complete ? "F" : holes}</p>
          </div>
        )}
      </div>
      <div
        className={
          leader === idTwo
            ? "ms-right msra ms-active"
            : leader === idOne
            ? "ms-right msr-inactive"
            : "ms-right"
        }
      >
        <div
          style={{
            borderColor:
              leader === idTwo
                ? `transparent ${teamTwoColor} transparent transparent `
                : "transparent transparent transparent transparent",
            backgroundColor: "transparent",
          }}
          className="ms-right-border"
        ></div>
        <div
          style={{
            backgroundColor: leader === idTwo ? teamTwoColor : "transparent",
          }}
          className="ms-right-box"
        >
          <p>{nameTwo}</p>
        </div>
      </div>
    </div>
  );
}

export default MatchplayScore;
