/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./RoundPlayer.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar } from "@mui/material";
import Menu from "@mui/material/Menu";
import DeblurIcon from "@mui/icons-material/Deblur";
import { CheckCircle } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import classNames from "classnames";
const GradientIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <DeblurIcon
      className="rp-star dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function RoundPlayer({
  format,
  players,
  subAction = () => {},
  handleRemove,
  name,
  imageLink,
  handicap,
  tee = "",
  playerID,
  uid,
  hideHcp = false,
  handleSetup,
  hideTee = false,
  setGroup,
  today = "",
  thru = "",
  hideVert = false,
  border = false,
  event = false,
  live = false,
  liveGroup = false,
  guest = false,
  finished = false,
  onlyScore = false,
  showBg = false,
  showCourse = false,
  swapMode = false,
  selSwap = false,
  showManual = false,
  score = "",
  team = "",
  scorecard = false,
  showTransfer = false,
  transferAction = () => {},
  scorecardAction = () => {},
  swapClick = () => {},
  courseAction = () => {},
  removeAction = () => {},
  swapAction = () => {},
  scoreAction = () => {},
  teeAction = () => {},
  hcpAction = () => {},
  nameAction = () => {},
}) {
  const [show, setShow] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  // eslint-disable-next-line no-unused-vars
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const restricted = ["worst-ball", "average-ball", "best-ball"];

  // console.log(playerID, uid)

  return (
    <div
      onClick={() => {
        if (swapMode) {
          swapClick();
        }
      }}
      style={{
        borderTop: border ? "1px solid #edefef" : "none",
        paddingTop: border ? "20px" : "0px",
        backgroundColor: showBg ? "#C7EFDE" : "",
        // borderRadius: showBg ? "20px" : "",
      }}
      className={classNames("round-player", {
        "rp-swap": swapMode,
        "sel-swap": selSwap,
      })}
    >
      <div className="rp-left">
        <Avatar src={imageLink} alt={name} />
      </div>

      <div className="rp-mid">
        <h2>{name}</h2>
        {team && <p className="rp-team">{team}</p>}
        <div className="flex-align-center  just-bet">
          {!hideTee && (
            <div
              className="rp-tee"
              style={{
                backgroundColor:
                  tee === "white"
                    ? "#232C2E"
                    : tee === "back"
                    ? "#232c2e"
                    : tee,
              }}
            >
              <p style={{ color: tee === "yellow" ? "#232C2E" : "white" }}>
                {tee}
              </p>
            </div>
          )}

          {thru !== "" && (
            <div className="flex-align-center rpt-box">
              <GolfCourseIcon className="dg-icon rp-thru-ico" />
              <div className="rp-thru">
                <p>{thru}</p>
              </div>
            </div>
          )}
        </div>
        {!hideHcp && (
          <div className="rp-hcp">
            <div className="svg-cont">
              <GradientIcon />
            </div>
            {/* <DeblurIcon className="rp-star" style={{ fill: "#21c17c" }} /> */}
            <h3>{handicap < 0 ? `+${-handicap}` : handicap}</h3>
            {guest && (
              <div className="rp-guest">
                <p>GUEST</p>
              </div>
            )}
          </div>
        )}
      </div>
      {finished && (
        <>
          <div className="rp-fi">
            <CheckCircle className="dg-icon mr-20 " />
          </div>
        </>
      )}
      {/* <div className="rp-mr">
          <p
                      id="basic-button2"
        aria-controls={open2 ? 'basic-menu2' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        onClick={handleClick2} 
          >Group 1</p>
        </div>
        <Menu
                  id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button2',
        }}
            >

            </Menu> */}
      {score !== "" && (
        <div className="rp-score-box">
          <h4>
            {score === 0 && format !== "stableford"
              ? "E"
              : score > 0 && format !== "stableford"
              ? `+${score}`
              : score > 0 && format === "stableford"
              ? score
              : score
              ? score
              : 0}
          </h4>
        </div>
      )}
      {today !== "" && (
        <div className="rp-score-box opa-70">
          <h4>
            (
            {today === 0 && format !== "stableford"
              ? "E"
              : today > 0 && format !== "stableford"
              ? `+${today}`
              : today > 0 && format === "stableford"
              ? today
              : today
              ? today
              : 0}
            )
          </h4>
        </div>
      )}
      <div className="rp-right">
        {!hideVert && (
          <MoreVertIcon
            className="icon-cursor hgb-ico"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
        )}

        {scorecard && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {" "}
            <div
              onClick={() => {
                scorecardAction();
                handleClose();
              }}
              className="rp-mi"
            >
              <p>Print Card</p>
            </div>
          </Menu>
        )}

        {!liveGroup && !scorecard && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {!live && (
              <div
                onClick={() => {
                  handleSetup();
                  setShow(false);
                }}
                className="rp-mi"
              >
                <p>Setup Player</p>
              </div>
            )}
            {live && (
              <div
                onClick={() => {
                  removeAction();
                }}
                className="rp-mi"
              >
                <p>Remove</p>
              </div>
            )}
            {live && (
              <div
                onClick={() => {
                  swapAction();
                }}
                className="rp-mi"
              >
                <p>Swap</p>
              </div>
            )}
            {playerID !== uid &&
              !event &&
              !(restricted.includes(format) && players < 5) && (
                <div
                  onClick={() => {
                    handleRemove();
                    setShow(false);
                  }}
                  className="rp-mi"
                >
                  <p>Remove</p>
                </div>
              )}
          </Menu>
        )}
        {liveGroup && !scorecard && (
          <>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div
                onClick={() => {
                  scoreAction();
                }}
                className="rp-mi"
              >
                <p>Score Input</p>
              </div>

              {showManual && (
                <div onClick={subAction} className="rp-mi">
                  <p>Overall Score</p>
                </div>
              )}

              {!onlyScore && (
                <div
                  onClick={() => {
                    teeAction();
                  }}
                  className="rp-mi"
                >
                  <p>Edit Tees</p>
                </div>
              )}

              {!onlyScore && (
                <div
                  onClick={() => {
                    hcpAction();
                  }}
                  className="rp-mi"
                >
                  <p>Edit Hcp</p>
                </div>
              )}

              {showCourse && (
                <div
                  onClick={() => {
                    courseAction();
                  }}
                  className="rp-mi"
                >
                  <p>Edit Course</p>
                </div>
              )}

              {guest && (
                <div
                  onClick={() => {
                    nameAction();
                  }}
                  className="rp-mi"
                >
                  <p>Edit Name</p>
                </div>
              )}
              {showTransfer && (
                <div
                  className="rp-mi"
                  onClick={() => {
                    transferAction();
                    handleClose();
                  }}
                >
                  <p>Transfer Player</p>
                </div>
              )}
            </Menu>
          </>
        )}
      </div>
    </div>
  );
}

export default RoundPlayer;
