import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/performance";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import * as geofirestore from "geofirestore";
import { geofire } from "geofire-common";

const app = firebase.initializeApp({
  apiKey: "AIzaSyB7IUK8lNigjp_8wBSRJYpKQX6ueT8H7D8",
  authDomain: "uga-tour.firebaseapp.com",
  projectId: "uga-tour",
  storageBucket: "uga-tour.appspot.com",
  messagingSenderId: "484842228555",
  appId: "1:484842228555:web:0063742577b114cd2036ce",
  measurementId: "G-VD6YTHDJF2",
  databaseURL:
    "https://uga-tour-default-rtdb.europe-west1.firebasedatabase.app",
});
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
});
firebase
  .firestore()
  .enablePersistence()
  .catch((err) => {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
const firestore = firebase.firestore();

app.analytics();
export const geoFirestore = geofirestore.initializeApp(firestore);
export const admin = firebase;
export const db = app.firestore();
export const auth = app.auth();
export const storage = app.storage();
export const database = app.database();
export const perf = firebase.performance();
export const functions = firebase.functions();
export default app;
