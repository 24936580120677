import React, { useState, useEffect } from "react";
import { Drawer } from "@mui/material";
import "./ColorSelector.css";

function ColorSelector({
  selectedColor = "",
  setSelectedColor = () => {},
  teamName = "",
  handleClick = () => {},
}) {
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    console.log(selectedColor);
  }, [selectedColor]);

  return (
    <div
      onClick={() => {
        // handleClick();
        if (!showDrawer) {
          setShowDrawer((current) => !current);
        }
      }}
      style={{ backgroundColor: selectedColor }}
      className="color-selector"
    >
      <p>{teamName}</p>
      <Drawer
        onClick={() => setShowDrawer(false)}
        anchor="bottom"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <div
          onClick={() => setShowDrawer(false)}
          className="color-selector-drawer"
        >
          <div
            style={{ backgroundColor: "#10603D", borderColor: "#10603D" }}
            onClick={() => setSelectedColor("#10603D")}
            className={
              selectedColor === "#10603D" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "#21c17c", borderColor: "#21c17c" }}
            onClick={() => setSelectedColor("#21c17c")}
            className={
              selectedColor === "#21c17c" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "#e24955", borderColor: "#e24955" }}
            onClick={() => setSelectedColor("#e24955")}
            className={
              selectedColor === "#e24955" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "#379FDC", borderColor: "#379fdc" }}
            onClick={() => setSelectedColor("#379FDC")}
            className={
              selectedColor === "#379FDC" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "#FFA340", borderColor: "#ffa340" }}
            onClick={() => setSelectedColor("#FFA340")}
            className={
              selectedColor === "#FFA340" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "gold", borderColor: "gold" }}
            onClick={() => setSelectedColor("gold")}
            className={
              selectedColor === "gold" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "#6E48BE", borderColor: "#6E48BE" }}
            onClick={() => setSelectedColor("#6E48BE")}
            className={
              selectedColor === "#6E48BE" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "#1B4F6D", borderColor: "#1B4F6D" }}
            onClick={() => setSelectedColor("#1B4F6D")}
            className={
              selectedColor === "#1B4F6D" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
          <div
            style={{ backgroundColor: "#000000", borderColor: "#000000" }}
            onClick={() => setSelectedColor("#000000")}
            className={
              selectedColor === "#000000" ? "csd-item csdi-active" : "csd-item"
            }
          ></div>
        </div>
      </Drawer>
    </div>
  );
}

export default ColorSelector;
