import React, { useState, useEffect } from "react";
import "./TeamEvents.css";
import { useAuth } from "../../contexts/AuthContext";
import { CSVLink, CSVDownload } from "react-csv";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// Icons

// Components
import BackdropLoader from "../Loaders/BackdropLoader";
import LogItem from "../display/LeagueLogs/LogItem/LogItem";
import { db } from "../../firebase";
import StatsHeader from "../Leagues/LeagueEdit/StatsHeader";
function TeamLeagueStats({ leagueID = "", seasonID = "", leagueTitle = "" }) {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(0);
  const [csv, setCSV] = useState([]);

  const [stats, setStats] = useState([]);
  const [cat, setCat] = useState("matches");

  const [seasons, setSeasons] = useState([]);
  const [selSeason, setSelSeason] = useState("");

  const [currentSorter, setCurrentSorter] = useState("");
  const [sortDir, setSortDir] = useState("down");

  useEffect(() => {
    // console.log(seasonID);
    if (seasonID !== "") {
      loadSeasonStats(seasonID);
    } else {
      loadStats();
    }
    loadSeasons();
  }, []);

  async function loadSeasons() {
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const seasonIDs = league.seasonIDs ?? [];
    const seasons = seasonIDs.map((id, i) => {
      const toRet = {
        label: `Season ${i + 1}`,
        value: id,
      };
      return toRet;
    });
    console.log(seasons);
    setSeasons(seasons);
  }

  async function loadSeasonStats(seasonID) {
    // alert("hih");
    setLoading(true);
    const seasonDoc = await db.collection("seasons").doc(seasonID).get();
    const season = seasonDoc.data();
    const { playerIDs } = season;
    const statsDocs = await db
      .collection("seasons")
      .doc(seasonID)
      .collection("statistics")
      .get();
    const refs = statsDocs.docs.map((doc) =>
      db.collection("users").doc(doc.id).get()
    );
    const docs = await Promise.all(refs);

    const playerMap = {};
    const csvArr = [];

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { profileImage, handicap, firstName, lastName, city, country } =
        player;

      playerMap[playerID] = { name: `${firstName} ${lastName}` };
    }
    const arr = [];

    for (let i = 0; i < statsDocs.docs.length; i++) {
      const doc = statsDocs.docs[i];
      const data = doc.data();
      const playerID = doc.id;
      const playerName = playerMap[playerID].name;

      const { stats } = data;
      if (i === 0) {
        const headerRow = Object.keys(stats);
        csvArr.push(headerRow);
      }
      stats.playerID = playerID;
      stats.name = playerName;
      arr.push(stats);
      const statArr = Object.values(stats);
      csvArr.push(statArr);
    }
    setCSV(csvArr);

    setStats(arr);
    // console.log(arr);
    setLoading(false);
  }

  async function loadStats() {
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { playerIDs } = league;
    const statsDocs = await db
      .collection("teamLeagues")
      .doc(leagueID)
      .collection("statistics")
      .get();
    const refs = playerIDs.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(refs);

    const playerMap = {};

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { profileImage, handicap, firstName, lastName, city, country } =
        player;

      playerMap[playerID] = { name: `${firstName} ${lastName}` };
    }
    const arr = [];

    for (let i = 0; i < statsDocs.docs.length; i++) {
      const doc = statsDocs.docs[i];
      const data = doc.data();
      const playerID = doc.id;
      const playerName = playerMap[playerID].name;

      const { stats } = data;
      stats.name = playerName;
      stats.playerID = playerID;

      arr.push(stats);
    }
    console.log(arr);
    setStats(arr);
    setLoading(false);
  }

  function sortByHeader(item) {
    console.log(item);
    if (currentSorter === item) {
      if (sortDir === "up") {
        setStats((current) => {
          const sorted = current.sort((a, b) => b[item] - a[item]);
          return sorted;
        });
        setSortDir("down");
      } else {
        setStats((current) => {
          const sorted = current.sort((a, b) => a[item] - b[item]);
          return sorted;
        });
        setSortDir("up");
      }
    } else {
      setCurrentSorter(item);
      setStats((current) => {
        const sorted = current.sort((a, b) => a[item] - b[item]);
        return sorted;
      });
      setSortDir("up");
    }

    setUpdate((current) => current + 1);
  }
  return (
    <div className="team-league-stats">
      {loading && <BackdropLoader />}

      <div className="flex flex-align-center mb-20">
        {seasons.length > 1 && (
          <div className="input-group pl-20 mr-20">
            <p>Select Season</p>
            <Dropdown
              options={seasons}
              value={selSeason}
              onChange={(e) => {
                setSelSeason(e.value);
                loadSeasonStats(e.value);
              }}
            />
          </div>
        )}

        <div className="sub-header">
          <CSVLink
            filename={`${leagueTitle} Stats ${new Date().toLocaleDateString()}`}
            className="default-button small-but mb-20"
            data={csv}
          >
            Export
          </CSVLink>
        </div>
      </div>

      <div className="switch-row">
        <div className="selector-group sg-small">
          <button
            onClick={() => setCat("matches")}
            className={cat === "matches" ? "sg-left sg-active" : "sg-left"}
          >
            Matches
          </button>
          <button
            onClick={() => setCat("holes")}
            className={cat === "holes" ? "sg-right sg-active" : "sg-right"}
          >
            Holes
          </button>
          <button
            onClick={() => setCat("scoring")}
            className={cat === "scoring" ? "sg-right sg-active" : "sg-right"}
          >
            Scoring
          </button>
          <button
            onClick={() => setCat("putting")}
            className={cat === "putting" ? "sg-right sg-active" : "sg-right"}
          >
            Putting
          </button>
        </div>
      </div>
      <div className="pd-20">
        {cat === "matches" && (
          <StatsHeader
            one="Win %"
            two="Loss %"
            three="Matchplay Win%"
            four="Fourball Win%"
            oneClick={() => {
              sortByHeader("winPercentage");
            }}
            twoClick={() => {
              sortByHeader("lossPercentage");
            }}
            threeClick={() => {
              sortByHeader("matchplayWinPercentage");
            }}
            fourClick={() => {
              sortByHeader("fourballWinPercentage");
            }}
            selected={
              currentSorter === "winPercentage"
                ? "one"
                : currentSorter === "lossPercentage"
                ? "two"
                : currentSorter === "matchplayWinPercentage"
                ? "three"
                : currentSorter === "fourballWinPercentage"
                ? "four"
                : ""
            }
          />
        )}
        {cat === "holes" && (
          <StatsHeader
            one="Win %"
            two="Loss %"
            three="Matchplay Win%"
            four="Fourball Win%"
            oneClick={() => {
              sortByHeader("holeWinPercentage");
            }}
            twoClick={() => {
              sortByHeader("holeLossPercentage");
            }}
            threeClick={() => {
              sortByHeader("matchplayHoleWinPercentage");
            }}
            fourClick={() => {
              sortByHeader("fourballHoleWinPercentage");
            }}
            selected={
              currentSorter === "holeWinPercentage"
                ? "one"
                : currentSorter === "holeLossPercentage"
                ? "two"
                : currentSorter === "matchplayHoleWinPercentage"
                ? "three"
                : currentSorter === "fourballHoleWinPercentage"
                ? "four"
                : ""
            }
          />
        )}
        {cat === "scoring" && (
          <StatsHeader
            one="Par 3s"
            two="Par 4s"
            three="Par 5"
            four="To Par"
            oneClick={() => {
              sortByHeader("averageGrossParThrees");
            }}
            twoClick={() => {
              sortByHeader("averageGrossParFours");
            }}
            threeClick={() => {
              sortByHeader("averageGrossParFives");
            }}
            fourClick={() => {
              sortByHeader("averageToPar");
            }}
            selected={
              currentSorter === "averageParGrossThrees"
                ? "one"
                : currentSorter === "averageGrossParFours"
                ? "two"
                : currentSorter === "averageGrossParFives"
                ? "three"
                : currentSorter === "averageToPar"
                ? "four"
                : ""
            }
          />
        )}
        {cat === "putting" && (
          <StatsHeader
            one="Putt Ave"
            two="1 Putts"
            three="3 Putts"
            four="1 Putt Wins"
            oneClick={() => {
              sortByHeader("puttsPerHole");
            }}
            twoClick={() => {
              sortByHeader("onePutts");
            }}
            threeClick={() => {
              sortByHeader("threePutts");
            }}
            fourClick={() => {
              sortByHeader("onePuttWins");
            }}
            selected={
              currentSorter === "puttsPerHole"
                ? "one"
                : currentSorter === "onePutts"
                ? "two"
                : currentSorter === "threePutts"
                ? "three"
                : currentSorter === "onePuttWins"
                ? "four"
                : ""
            }
          />
        )}
        {stats.map((player, index) => {
          let one, two, three, four;
          if (cat === "matches") {
            one = `${player.winPercentage * 100}%`;
            two = `${Math.round(player.lossPercentage * 100)}%`;
            three = `${player.matchplayWinPercentage * 100}%`;
            four = `${player.fourballWinPercentage * 100}%`;
          }
          if (cat === "holes") {
            one = `${Math.round(player.holeWinPercentage * 100)}%`;
            two = `${Math.round(player.holeLossPercentage * 100)}%`;
            three = `${Math.round(player.matchplayHoleWinPercentage * 100)}%`;
            four = `${Math.round(player.fourballHoleWinPercentage * 100)}%`;
          }
          if (cat === "scoring") {
            one = player.averageGrossParThrees;
            two = player.averageGrossParFours;
            three = player.averageGrossParFives;
            four = player.averageNetToPar;
          }
          if (cat === "putting") {
            one = player.puttsPerHole;
            two = player.onePutts;
            three = player.threePutts;
            four = player.onePuttWins;
          }

          // console.log(player);

          return (
            <LogItem
              key={player.playerID}
              name={player.name}
              rank={index + 1}
              played={one}
              round={false}
              points={two}
              wins={three}
              ave={four}
            />
          );
        })}
      </div>
    </div>
  );
}

export default TeamLeagueStats;
