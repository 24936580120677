import React, { useState, useEffect, useCallback } from "react";
import { admin, db, storage } from "../../../firebase";
import uuid from "react-uuid";
import "./ClubSponsors.css";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";

// Components
import { Dialog } from "@mui/material";
import Pill from "../../../components/Buttons/Pill";
import BackRow from "../../../components/display/Rows/BackRow";
import { useDropzone } from "react-dropzone";
import PageHeader from "../../../components/display/Rows/PageHeader";
import Compressor from "compressorjs";
import BackdropLoader from "../../../components/Loaders/BackdropLoader";
import ErrorModal from "../../../components/ErrorModal/ErrorModal";
import ClubSponsor from "./ClubSponsor";
import Switch from "react-ios-switch/lib/Switch";

import ManualPlayerAdd from "../../../components/Events/EventCreator/ManualPlayerAdd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { successIco } from "../../../components/ClientStats/HelperFunctions";

function ClubSponsors({ defaultSponsors = [], reload = () => {} }) {
  // State
  const [showNew, setShowNew] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  const clubID = useParams().id;
  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState({
    sponsorName: "",
  });

  const [showEdit, setShowEdit] = useState(false);
  const [toEdit, setToEdit] = useState();

  const [showAdd, setShowAdd] = useState([]);

  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [link, setLink] = useState("");
  const [sponsorName, setSponsorName] = useState("");
  const [mainSponsor, setMainSponsor] = useState(false);

  const [editImage, setEditImage] = useState("");
  const [editImageURL, setEditImageURL] = useState("");
  const [editLink, setEditLink] = useState("");
  const [editSponsorName, setEditSponsorName] = useState("");
  const [editMainSponsor, setEditMainSposor] = useState(false);

  const [update, setUpdate] = useState(0);

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    // alert("drop");
    const url = URL.createObjectURL(acceptedFiles[0]);
    console.log(showEdit);
    if (showEdit) {
      console.log("hurr");
      setEditImageURL(url);
      new Compressor(acceptedFiles[0], {
        convertSize: 1000000,
        success: (res) => {
          setEditImage(res);
        },
      });
      setUpdate((current) => current + 1);
    } else {
      setImageURL(url);
      new Compressor(acceptedFiles[0], {
        convertSize: 1000000,
        success: (res) => {
          setImage(res);
        },
      });
    }
  }, []);

  useEffect(() => {
    loadSponsors();
  }, []);

  const onDrop2 = useCallback((acceptedFiles) => {
    // alert("drop");
    const url = URL.createObjectURL(acceptedFiles[0]);
    console.log(showEdit);
    console.log("hurr");
    setEditImageURL(url);
    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        setEditImage(res);
      },
    });
    setUpdate((current) => current + 1);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  const {
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    isDragActive: isDragActive2,
  } = useDropzone({ onDrop: onDrop2 });

  async function saveSponsor() {
    setLoading(true);
    setShowNew(false);

    const task = storage.ref().child(`${clubID}/${image.name}`).put(image);

    try {
      await task.then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then(async (url) => {
          const newSponsor = {
            imageLink: url,
            sponsorName,
            link,
            sponsorID: uuid(),
            mainSponsor,
          };
          await db
            .collection("clubs")
            .doc(clubID)
            .update({
              sponsors: admin.firestore.FieldValue.arrayUnion(newSponsor),
            });
          setSponsorName("");
          setImage("");
          setImageURL("");
          setLink("");
          setMainSponsor(false);
          setSponsors((current) => [...current, newSponsor]);
          toast.success("Sponsor successfully added", {
            icon: successIco,
          });
        });
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }

    setLoading(false);
  }

  async function editSponsor() {
    setLoading(true);
    setShowEdit(false);
    const sponsorID = toEdit.sponsorID;
    const temp = [...sponsors];
    if (editImage !== "") {
      try {
        const task = storage
          .ref()
          .child(`${clubID}/${editImage.name}`)
          .put(editImage);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then(async (url) => {
            for (let i = 0; i < temp.length; i++) {
              const sponsor = temp[i];
              if (sponsor.sponsorID === sponsorID) {
                temp[i].sponsorName = editSponsorName;
                temp[i].imageLink = url;
                temp[i].link = editLink;
                temp[i].mainSponsor = editMainSponsor;
              }
            }
            await db.collection("clubs").doc(clubID).update({
              sponsors: temp,
            });
            setEditSponsorName("");
            setEditImage("");
            setEditImageURL("");
            setEditLink("");
            setEditMainSposor(false);
            setSponsors((current) => temp);
            reload();
            toast.success("Sponsor successfully edited", {
              icon: successIco,
            });
            setUpdate((current) => current + 1);
          });
        });
      } catch (err) {
        console.log(err);
        setError(
          "Sorry - we encountered a technical difficulty there, please try again"
        );
      }
    } else {
      for (let i = 0; i < temp.length; i++) {
        const sponsor = temp[i];
        if (sponsor.sponsorID === sponsorID) {
          temp[i].sponsorName = editSponsorName;
          temp[i].link = editLink;
          temp[i].mainSponsor = editMainSponsor;
        }
      }
      try {
        await db.collection("clubs").doc(clubID).update({
          sponsors: temp,
        });
        setEditSponsorName("");
        setEditImage("");
        setEditImageURL("");
        setEditLink("");
        setSponsors((current) => temp);
        setEditMainSposor(false);
        setUpdate((current) => current + 1);
        toast.success("Sponsor successfully edited", {
          icon: successIco,
        });
        reload();
      } catch (err) {
        console.log(err);
        setError(
          "Sorry - we encountered a technical difficulty there, please try again"
        );
      }
    }

    setLoading(false);
  }

  async function removeSponsor() {
    setLoading(true);
    setShowDelete(false);

    const sponsorID = toDelete.sponsorID;
    const filtered = sponsors.filter(
      (sponsor) => sponsor.sponsorID !== sponsorID
    );

    try {
      await db.collection("clubs").doc(clubID).update({
        sponsors: filtered,
      });
      setSponsors((current) => filtered);
      setToDelete({
        sponsorName: "",
      });
      reload();
      toast.success("Sponsor successfully removed", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }
    setLoading(false);
  }

  async function loadSponsors() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    setSponsors(club.sponsors ?? []);
    setLoading(false);
  }

  return (
    <div className="club-sponsors">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <Dialog open={showNew} onClose={() => setShowNew(false)}>
        <div className="new-sponsor">
          <PageHeader
            close={() => setShowNew(false)}
            showClose
            text="New Sponsor"
          />

          <div className="ec-box">
            <div className="ig-group">
              <div className="input-group">
                <p>Sponsor Name</p>
                <input
                  type="text"
                  placeholder="Enter sponsor name..."
                  value={sponsorName}
                  onChange={(e) => setSponsorName(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="input-group">
                <p>Online Link</p>
                <input
                  type="url"
                  placeholder="Enter link..."
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  className="default-input"
                />
              </div>
            </div>

            <div className="input-group nassau-group">
              <p>Display Everywhere</p>
              <Switch
                onColor="#1e7a69"
                checked={mainSponsor}
                className={
                  mainSponsor ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => setMainSponsor((current) => !current)}
              />
            </div>

            <div className="input-group mb-20">
              <p>Sponsor Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imageURL} />
                      </div>
                    )}
                    {imageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center mb-40">
            <button
              onClick={saveSponsor}
              disabled={imageURL === "" || sponsorName === ""}
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showEdit} onClose={() => setShowEdit(false)}>
        <div className="new-sponsor">
          <PageHeader
            text="Edit Sponsor"
            showClose
            close={() => setShowEdit(false)}
          />
          <div className="ec-box">
            <div className="ig-group">
              <div className="input-group">
                <p>Sponsor Name</p>
                <input
                  type="text"
                  placeholder="Enter sponsor name..."
                  value={editSponsorName}
                  onChange={(e) => setEditSponsorName(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="input-group">
                <p>Online Link</p>
                <input
                  type="url"
                  placeholder="Enter link..."
                  value={editLink}
                  onChange={(e) => setEditLink(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="input-group nassau-group">
                <p>Display Everywhere</p>
                <Switch
                  onColor="#1e7a69"
                  checked={editMainSponsor}
                  className={
                    editMainSponsor
                      ? "scoring-switch switch-on"
                      : "scoring-switch"
                  }
                  onChange={() => setEditMainSposor((current) => !current)}
                />
              </div>
            </div>

            <div className="input-group mb-20">
              <p>Sponsor Picture</p>
              <div className="image-buttons">
                <div {...getRootProps2()}>
                  <input {...getInputProps2()} />
                  <div className="dz-sel">
                    {editImageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="img" src={editImageURL} />
                      </div>
                    )}
                    {editImageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center mb-40">
            <button
              onClick={editSponsor}
              disabled={editImageURL === "" || editSponsorName === ""}
              className="default-button"
            >
              Save Changes
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
        <div className="cs-delete">
          <PageHeader
            text="Delete Sponsor"
            close={() => setShowDelete(false)}
            showClose
          />
          <p>
            Are you sure you want to remove{" "}
            <span className="bold">{toDelete.sponsorName}</span> as one of your
            sponsors?
          </p>
          <div className="flex-center">
            <button onClick={removeSponsor} className="delete-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <BackRow action={goBack} />

      <PageHeader text="Club Sponsors" />

      <div className="quick-links">
        <Pill text={"New Sponsor"} onClick={() => setShowNew(true)} />
      </div>

      <div className="sub-header">
        <h5>Current Sponsors</h5>
      </div>

      {sponsors.length > 0 && (
        <div className="cs-box flex-wrapper">
          {sponsors.map((sponsor, index) => (
            <ClubSponsor
              sponsor={sponsor}
              deleteAction={() => {
                setToDelete(sponsor);
                setShowDelete(true);
              }}
              editAction={() => {
                setEditSponsorName(sponsor.sponsorName);
                setEditImageURL(sponsor.imageLink);
                setEditMainSposor(sponsor.mainSponsor ?? false);
                setToEdit(sponsor);
                setEditLink(sponsor.link ?? "");
                setShowEdit(true);
              }}
              key={sponsor.sponsorID}
            />
          ))}
        </div>
      )}

      {sponsors.length === 0 && (
        <div className="no-msg">
          <p>No sponsors to display</p>
        </div>
      )}
    </div>
  );
}

export default ClubSponsors;
