import React from "react";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// Components
import { Avatar, Tooltip } from "@mui/material";

function ClubSponsor({
  sponsor = {},
  select = false,
  hideIcons = false,
  active = false,
  selectAction = () => {},
  editAction = () => {},
  deleteAction = () => {},
}) {
  return (
    <div
      onClick={() => {
        if (select) {
          selectAction();
        }
      }}
      className={
        select && active
          ? "club-sponsor cs-select cs-active"
          : select && !active
          ? "club-sponsor cs-select"
          : "club-sponsor"
      }
    >
      <div className="sc-left">
        <div className="scl-img flex-center">
          <Avatar alt={sponsor.sponsorName} src={sponsor.imageLink} />
        </div>
        <div className="scl-text">
          <p>{sponsor.sponsorName}</p>
        </div>
      </div>
      {!hideIcons && (
        <div className="sc-right">
          <Tooltip title="Edit Sponsor">
            <EditIcon
              className="dg-icon-bg icon-cursor mb-10"
              onClick={editAction}
            />
          </Tooltip>
          <Tooltip title="Remove Sponsor">
            <DeleteForeverIcon
              className="red-icon-bg icon-cursor"
              onClick={deleteAction}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default ClubSponsor;
