import React from "react";
import { ClipLoader } from "react-spinners";

function CPLoader({ classnames, size = 30 }) {
  return (
    <div className={`${classnames} cp-loader`}>
      <ClipLoader color="#6ba390" size={size} />
    </div>
  );
}

export default CPLoader;
