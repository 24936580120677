import React, { useState, useEffect } from "react";
import "./JackpotHoles.css";

// Icons

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { db } from "../../../firebase";

function JackpotHoles({
  eventID = "",
  ec = false,
  goBack = () => {},
  defaultHoles = [],
  saveHoles = () => {},
}) {
  const holes = Array.from({ length: 18 }, (_, index) => index + 1);
  const [selHoles, setSelHoles] = useState(defaultHoles);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [up, setUp] = useState(0);
  function update() {
    setUp((cur) => cur + 1);
  }

  useEffect(() => {
    if (!ec) {
      loadHoles();
    } else {
      setLoading(false);
    }
  }, []);

  async function loadHoles() {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const jackpotHoles = event.jackpotHoles ?? [];
    setSelHoles(jackpotHoles);
    setLoading(false);
  }

  async function saveEventHoles() {
    setLoading(true);
    try {
      await db.collection("events").doc(eventID).update({
        jackpotHoles: selHoles,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError("Could not save changes, please try again");
    }

    setLoading(false);
  }

  return (
    <div className="jackpot-holes">
      <BackRow action={goBack} />
      <PageHeader text="Jackpot Holes" />
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <div className="jhh-box">
        <div className="jh-holes">
          {holes.map((hole, index) => {
            const act = selHoles.includes(index);
            return (
              <div
                onClick={() => {
                  console.log(act);
                  if (act) {
                    setSelHoles((current) => {
                      const filt = current.filter((h) => h !== index);
                      return filt;
                    });
                  } else {
                    if (selHoles.length === 4) {
                      return;
                    }

                    setSelHoles((current) => {
                      current.push(index);
                      return current;
                    });
                  }
                  update();
                }}
                key={`${index}-jh`}
                className={act ? "jh-hole jhh-active" : "jh-hole"}
              >
                <p>{hole}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex-center">
        <button
          onClick={() => {
            if (ec) {
              saveHoles(selHoles);
              goBack();
            } else {
              saveEventHoles();
            }
          }}
          className="default-button"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default JackpotHoles;
