import React, { useState, useEffect, useRef } from "react";
import "./ScoreInput.css";

import { admin, db } from "../../../firebase";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AdjustIcon from "@mui/icons-material/Adjust";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import Looks3Icon from "@mui/icons-material/Looks3";
import SportsGolfIcon from "@mui/icons-material/SportsGolf";
import { ReactComponent as Putter } from "../../../assets/icos/puttergre2.svg";
// Components
import { Dialog } from "@mui/material";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { Avatar } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Oval } from "react-loader-spinner";
import Pill from "../../Buttons/Pill";
import PageHeader from "../../display/Rows/PageHeader";

const bgc = {
  black: "232c2e",
  white: "whitesmoke",
  red: "#A9363F",
  blue: "#379FDC",
  yellow: "yellow",
  gold: "#d4af37",
  Red: "#A9363F",
  green: "#1e7a69",
};

const bc = {
  black: "232c2e",
  white: "#232c2e",
  red: "#E24955",
  Red: "#e24955",
  blue: "#379FDC",
  yellow: "yellow",
  gold: "#d4af37",
  green: "#1e7a69",
};

const GradientEditIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <Tooltip title={"Edit Scores"}>
      <EditIcon
        onClick={onClick}
        className="icon-cursor dg-icon-bg"
        sx={{ fill: "url(#linearColors)" }}
      />
    </Tooltip>
  </>
);

const GradientDoneIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <Tooltip title="Save Changes">
      <DoneIcon
        onClick={onClick}
        className={`icon-cursor dg-icon-bg ${className}`}
        sx={{ fill: "url(#linearColors)" }}
      />
    </Tooltip>
  </>
);

const GradientUndoIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorrs" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <Tooltip title="Undo Changes">
      <ReplayIcon
        onClick={onClick}
        className="icon-cursor red-icon-bg mr-10"
        sx={{ fill: "url(#linearColorrs)" }}
      />
    </Tooltip>
  </>
);

function ScoreInput({
  eventID = "",
  roundID = "",
  cupID = "",
  cup = false,
  eventName = "",
  players = [],
  goBack = () => {},
  matchplay = false,
  defaultPlayer,
  portal = false,
  defaultMode = "input",
  tees = "",
  teamEvt = false,
  scorerID = "",
}) {
  const eventMatchups = admin.functions().httpsCallable("teamEventMatchups");
  const roundCupScore = admin.functions().httpsCallable("roundCupScore");

  // State
  const [holes, setHoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [hcp, setHcp] = useState(0);
  const [selectedName, setSelectedName] = useState("");
  const [update, setUpdate] = useState(0);
  const [disableSave, setDisableSave] = useState(false);
  const [teamHandicap, setTeamHandicap] = useState("");

  const [showSSV, setShowSSV] = useState(false);

  const [showPutts, setShowPutts] = useState(false);

  const [ldEnabled, setLDEnabled] = useState(false);
  const [ctpEnabled, setCTPEnabled] = useState(false);

  const [scoreMode, setScoreMode] = useState(defaultMode);

  const [details, setDetails] = useState({});

  const [showSS, setShowSS] = useState(false);

  const [ssIp, setSSIP] = useState();
  const [spIp, setSPIP] = useState(0);

  const [searchInput, setSearchInput] = useState("");

  const [edit, setEdit] = useState(portal ? true : false);

  const [savedHoles, setSavedHoles] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [ring, setRing] = useState(2);

  const [ctpHole, setCtpHole] = useState();
  const [ldHole, setLdpHole] = useState();

  const [ctp, setCTP] = useState(0);
  const [ld, setLD] = useState(0);

  const [showCTP, setShowCTP] = useState(false);
  const [showLD, setShowLD] = useState(false);

  const [modalError, setModalError] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);

  const [frtHcp, setFrtHcp] = useState("");

  useEffect(() => {
    // console.log(defaultPlayer);
    if (defaultPlayer) {
      setSelectedName(defaultPlayer.name);
      setHcp(defaultPlayer.handicap);
      setSelectedPlayer(defaultPlayer.playerID);
      // setSelected(true);
      loadHoles(defaultPlayer.playerID);
      setSelected(true);
    }
  }, []);

  async function loadHoles(playerID) {
    db.collection("rounds")
      .doc(roundID)
      .collection("scores")
      .doc(playerID)
      .onSnapshot(async (roundDoc) => {
        setLoading(true);
        const round = roundDoc.data();
        const {
          holes,
          backNineFIR,
          backNineGIR,
          frontNineFIR,
          frontNineGIR,
          totalFIR,
          coursePar,
          totalGIR,
          frontNineGross,
          backNineGross,
          frontNineNet,
          backNineNet,
          backNinePar,
          frontNinePar,
          frontNinePutts,
          backNinePutts,
          totalPutts,
          totalGrossStrokes,
          totalNetStrokes,
          currentGrossScore,
          currentScore,
          totalPoints,
          totalModPoints,
          backNinePoints,
          frontNinePoints,
          format,
          startingScore,
          startingPoints,
        } = round;
        // console.log(playerID);
        // alert(round.courseID);
        if (
          (startingPoints && startingScore) ||
          roundID === "sEOS2TVsxEC8PNn6Rldt"
        ) {
          setShowSSV(true);
          setSPIP(startingPoints);
          setSSIP(startingScore);
        }

        setFrtHcp(round.frontHcp ?? "");

        // console.log(
        //   totalPoints,
        //   totalModPoints,
        //   currentGrossScore,
        //   currentScore
        // );

        const mainRoundDoc = await db.collection("rounds").doc(roundID).get();
        const mainRound = mainRoundDoc.data();
        const { ldEnabled, ctpEnabled, ldMap, ctpMap, scratchMode } = mainRound;
        const courseID = round.courseID ?? mainRound.courseID;
        if (mainRound.enableTeams && mainRound.teamAllowance !== 100) {
          setTeamHandicap(round.teamHandicap ?? "");
        }
        setRing(mainRound.ring ?? 2);
        setLDEnabled(ldEnabled);
        setCTPEnabled(ctpEnabled);
        if (ldEnabled) {
          setLdpHole(mainRound.ldHole);
          setLD(ldMap[playerID] ?? 0);
        }
        if (ctpEnabled) {
          setCtpHole(mainRound.ctpHole);
          setCTP(ctpMap[playerID] ?? 0);
        }
        const courseDoc = await db.collection("courses").doc(courseID).get();
        const course = courseDoc.data();
        const { tees, teeArray, name } = course;
        const courseHoles = tees[teeArray[0]];
        for (let i = 0; i < courseHoles.length; i++) {
          const hole = courseHoles[i];
          holes[i].par = hole.par;
          holes[i].stroke = hole.stroke;
          holes[i].length = hole.length;
        }

        setDetails({
          scratchMode,
          backNineFIR,
          backNineGIR,
          frontNineFIR,
          frontNineGIR,
          totalFIR,
          totalGIR,
          frontNineGross,
          backNineGross,
          frontNineNet,
          backNineNet,
          backNinePar,
          coursePar,
          frontNinePar,
          frontNinePutts,
          backNinePutts,
          totalPutts,
          totalGrossStrokes,
          totalNetStrokes,
          format,
          courseName: name,
          backNinePoints,
          frontNinePoints,
          currentScore,
          currentGrossScore,
          totalPoints,
          totalModPoints,
          startingPoints,
          startingScore,
        });
        setHoles(holes);
        const newHoles = roundDoc.data().holes;
        setSavedHoles(newHoles);
        // console.log("SET SH");
        setLoading(false);
      });
  }

  function handleGrossChange(e, hole) {
    // console.log(e.target.valueAsNumber, hole);
    // console.log("hurr");
    let score;
    if (scoreMode === "selector") {
      score = e;
    } else {
      score = e.target.valueAsNumber;
    }
    setHoles((current) => {
      const temp = [...current];
      const holeToChange = temp[hole];
      const strokesOnHole = holeToChange.strokes;
      const netScore = score === 0 ? 0 : score - strokesOnHole;
      const holePar = holeToChange.par;
      const puttsOnHole = holeToChange.putts;
      const toPar = score === 0 ? 0 : netScore - holePar;

      temp[hole].grossScore = score;
      temp[hole].netScore = netScore;
      temp[hole].toPar = toPar;

      // Team stuff
      if (temp[hole].teamStrokes !== undefined) {
        const holeChange = temp[hole];
        // console.log(holeChange);
        let teamStrokes = holeChange.teamStrokes;
        if (teamStrokes === undefined) {
          teamStrokes = holeChange.strokes;
        }

        // console.log(hole, teamStrokes);
        const teamNetPar = holeToChange.par + teamStrokes;
        const teamNetScore = score === 0 ? 0 : score - teamStrokes;
        const teamNetDiff = score === 0 ? 0 : score - teamNetPar;
        let teamPoints = 0;

        switch (teamNetDiff) {
          case teamNetDiff > 2:
            teamPoints = 0;
            break;
          case 2:
            teamPoints = 0;
            break;
          case 1:
            teamPoints = 1;
            break;
          case 0:
            teamPoints = 2;
            break;
          case -1:
            teamPoints = 3;
            break;
          case -2:
            teamPoints = 4;
            break;
          case teamNetDiff < -2:
            teamPoints = 5;
            break;
          default:
            break;
        }
        if (teamNetDiff < -2) {
          teamPoints = -(teamNetDiff - 2);
        }
        holes[hole].teamPoints = teamPoints;
        holes[hole].teamNet = teamNetScore;
        holes[hole].teamNetDiff = teamNetDiff;
      }

      if (temp[hole].putts === 0 && score !== 0) {
        temp[hole].putts = 2;
      }

      if (score === 0) {
        temp[hole].putts = 0;
      }

      if (score !== 0) {
        let points = 2 - toPar;
        if (points < 0) {
          points = 0;
        }

        // switch (toPar) {
        //   case toPar > 2:
        //     points = 0;
        //     break;
        //   case 2:
        //     points = 0;
        //     break;
        //   case 1:
        //     points = 1;
        //     break;
        //   case 0:
        //     points = 2;
        //     break;
        //   case -1:
        //     points = 3;
        //     break;
        //   case -2:
        //     points = 4;
        //     break;
        //   case toPar < -2:
        //     points = 5;
        //     break;
        //   default:
        //     break;
        // }
        temp[hole].points = points;
      } else {
        temp[hole].points = 0;
      }

      // GIR
      if (score - puttsOnHole <= holePar - 2 && score !== 0) {
        temp[hole].gir = true;
      } else {
        temp[hole].gir = false;
      }
      totals(temp);
      return temp;
    });
    // setUpdate(current => current + 1);
  }

  function totals(arr) {
    let fnGross = 0;
    let bnGross = 0;
    let fnNet = 0;
    let bnNet = 0;
    let tg = 0;
    let tn = 0;
    let fgir = 0;
    let bgir = 0;
    let tgir = 0;
    let fStab = 0;
    let bStab = 0;
    let tStab = 0;

    for (let i = 0; i < arr.length; i++) {
      const hole = arr[i];
      const { netScore, grossScore, gir, points } = hole;
      if (i < 9) {
        fStab += points;
        fnGross += grossScore;
        fnNet += netScore;
        if (gir) {
          fgir += 1;
        }
      } else {
        bStab += points;
        bnGross += grossScore;
        bnNet += netScore;
        if (gir) {
          bgir += 1;
        }
      }

      if (gir) {
        tgir += 1;
      }

      tg += grossScore;
      tn += netScore;
      tStab += points;
    }

    if (isNaN(tg)) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }

    setDetails((current) => {
      current.frontNineGross = fnGross;
      current.backNineGross = bnGross;
      current.frontNineNet = fnNet;
      current.backNineNet = bnNet;
      current.totalGrossStrokes = tg;
      current.totalNetStrokes = tn;
      current.backNineGIR = bgir;
      current.frontNineGIR = fgir;
      current.totalGIR = tgir;
      current.totalPoints = tStab;
      current.backNinePoints = bStab;
      current.frontNinePoints = fStab;
      // console.log(current);
      return current;
    });

    setUpdate((current) => current + 1);
  }

  async function saveChanges() {
    // console.log(roundID);
    setLoading(true);
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const scrambleHandicaps = round.scrambleHandicaps ?? false;
    try {
      if (
        round.enableTeams ||
        round.bbLeaderboard ||
        round.betterballStableford
      ) {
        let { teamLeaderboard, teamType } = round;
        let stableScore = 0;
        if (teamType === undefined) {
          teamType = "scramble";
        }
        if (!round.newTeamboard) {
          for (let i = 0; i < teamLeaderboard.length; i++) {
            const team = teamLeaderboard[i];
            if (team.playerIDs.includes(selectedPlayer)) {
              for (let x = 0; x < 18; x++) {
                if (holes[x].grossScore !== 0) {
                  if (round.betterballStableford) {
                    teamLeaderboard[i].holes[x][selectedPlayer] =
                      holes[x].toPar;
                  } else {
                    if (round.format === "medal") {
                      teamLeaderboard[i].holes[x][selectedPlayer] =
                        holes[x].grossScore - holes[x].par;
                    } else {
                      teamLeaderboard[i].holes[x][selectedPlayer] =
                        holes[x].teamNetDiff ?? holes[x].toPar;
                    }
                  }
                } else {
                  teamLeaderboard[i].holes[x][selectedPlayer] = "";
                }
                const holeScores = teamLeaderboard[i].holes[x];

                if (teamType === "two") {
                  let holeScoreArr = [];
                  for (const [key, object] of Object.entries(holeScores)) {
                    // console.log(`key is ${key}, obj is ${object}`);
                    if (key.includes("lowest") || key.includes("Lowest")) {
                      continue;
                    }
                    holeScoreArr.push({
                      playerID: key,
                      score: object,
                    });
                  }
                  holeScoreArr = holeScoreArr.sort((a, b) => {
                    if (a.score === "") {
                      return 1;
                    }
                    if (b.score === "") {
                      return -1;
                    }
                    return a.score - b.score;
                  });
                  teamLeaderboard[i].holes[x].lowest = holeScoreArr[0].score;
                  teamLeaderboard[i].holes[x].secondLowest =
                    holeScoreArr[1].score;
                }
                if (teamType === "bogey" || teamType === "bogey++") {
                  let holeScoreArr = [];
                  for (const [key, object] of Object.entries(holeScores)) {
                    if (key.includes("best")) {
                      continue;
                    }
                    holeScoreArr.push({
                      playerID: key,
                      score: object,
                    });
                  }
                  holeScoreArr = holeScoreArr.sort((a, b) => {
                    if (a.score === "") {
                      return 1;
                    }
                    if (b.score === "") {
                      return -1;
                    }
                    return a.score - b.score;
                  });
                  teamLeaderboard[i].holes[x].best = holeScoreArr[0].score;
                }
                if (teamType === "custom") {
                  const holeToChange = teamLeaderboard[i].holes[x];
                  let { toCount } = holeToChange;

                  const type = holeToChange.type ? holeToChange.type : "best";
                  let holeScore = 0;
                  let scoreArr = [];
                  for (let x = 0; x < team.playerIDs.length; x++) {
                    const id = team.playerIDs[x];
                    if (holeToChange[id] !== "") {
                      scoreArr.push(holeToChange[id]);
                    }
                  }

                  // Sort arr
                  if (type === "best" || type === "comb") {
                    scoreArr = scoreArr.sort((a, b) => a - b);
                  } else if (type === "worst") {
                    scoreArr = scoreArr.sort((a, b) => b - a);
                  }

                  // console.log(scoreArr);

                  if (type === "comb") {
                    if (scoreArr.length === 0) {
                      holeScore = "";
                    } else if (scoreArr.length === 1) {
                      holeScore += scoreArr[0];
                    } else {
                      holeScore += scoreArr[0];
                      holeScore += scoreArr[scoreArr.length - 1];
                    }
                  } else {
                    if (scoreArr.length < toCount) {
                      toCount = scoreArr.length;
                    }
                    if (scoreArr.length === 0) {
                      holeScore = "";
                    }

                    for (let x = 0; x < toCount; x++) {
                      holeScore += scoreArr[x];
                    }
                  }

                  teamLeaderboard[i].holes[x].score = holeScore;
                  teamLeaderboard[i].holes[x].scores = scoreArr;
                }
                if (teamType === "scramble") {
                  let best = "";

                  for (const [key, value] of Object.entries(
                    teamLeaderboard[i].holes[x]
                  )) {
                    if (key === "best" || value === "") {
                      continue;
                    }

                    if (best === "" || best > value) {
                      best = value;
                    }
                  }
                  stableScore += 2 - holes[x].best;

                  teamLeaderboard[i].holes[x].best = best;
                }
              }
            }
          }
          await roundDoc.ref.update({
            teamLeaderboard,
          });
        } else {
          console.log("new board");
        }
      }
      if (round.jackpot) {
        const { jackpotLeaderboard, jackpotHoles } = round;
        for (let i = 0; i < jackpotLeaderboard.length; i++) {
          const row = jackpotLeaderboard[i];
          if (row.playerID !== selectedPlayer) {
            continue;
          }

          const { jackpotResults } = row;

          // jackpotResults[hole] = score;

          for (let x = 0; x < jackpotHoles.length; x++) {
            const hole = jackpotHoles[x];
            const scoreHole = holes[hole];
            const { toPar, points } = scoreHole;
            if (scoreHole.grossScore === 0) {
              jackpotResults[hole] = "";
            } else {
              if (round.format === "stableford") {
                jackpotResults[hole] = points;
              } else {
                jackpotResults[hole] = toPar;
              }
            }
          }

          let scr = 0;
          let jackthru = 0;

          for (const [key, value] of Object.entries(jackpotResults)) {
            if (value !== "") {
              jackthru += 1;
              scr += value;
            }
          }

          jackpotLeaderboard[i].score = scr;
          jackpotLeaderboard[i].jackpotResults = jackpotResults;
          jackpotLeaderboard[i].thru = jackthru;

          break;
        }
        if (round.format === "stableford") {
          jackpotLeaderboard.sort((a, b) => b.score - a.score);
        } else {
          jackpotLeaderboard.sort((a, b) => a.score - b.score);
        }

        let currentTies = 0;
        for (let i = 0; i < jackpotLeaderboard.length; i++) {
          let ranking = i + 1;
          const row = jackpotLeaderboard[i];
          if (i > 0) {
            if (jackpotLeaderboard[i - 1].score === row.score) {
              ranking = i - currentTies;
              currentTies += 1;
            } else {
              currentTies = 0;
            }
          }

          jackpotLeaderboard[i].rank = ranking;
        }

        await roundDoc.ref.update({
          jackpotLeaderboard,
        });
      }
      if (matchplay) {
        const { matches, format } = round;
        let found = false;

        if (format === "Matchplay") {
          for (let i = 0; i < matches.length; i++) {
            if (found) {
              break;
            }
            const match = matches[i];
            const { playerOne, playerTwo } = match;
            if (playerOne.playerID === selectedPlayer) {
              found = true;
            } else if (playerTwo.playerID === selectedPlayer) {
              found = true;
            }

            if (found) {
              let oppoID = "";
              if (selectedPlayer === playerOne.playerID) {
                oppoID = playerTwo.playerID;
              } else {
                oppoID = playerOne.playerID;
              }

              for (let x = 0; x < match.holes.length; x++) {
                match.holes[x][selectedPlayer] = holes[x].netScore;
                if (match.holes[x][oppoID] !== 0) {
                  const own = holes[x].netScore;
                  const other = match.holes[x][oppoID];
                  if (own > other) {
                    match.holes[x].winner = oppoID;
                  } else if (other > own) {
                    match.holes[x].winner = selectedPlayer;
                  } else {
                    match.holes[x].winner = "";
                  }
                } else {
                  match.holes[x].winner = "";
                }
              }

              let thru = 0;
              let playerHoles = 0;
              let oppoHoles = 0;
              let playerFrontNine = 0;
              let playerBackNine = 0;
              let oppoFrontNine = 0;
              let oppoBackNine = 0;

              let diffCt = 0;

              for (let x = 0; x < match.holes.length; x++) {
                const hole = match.holes[x];
                const rem = 18 - x;
                // console.log(x, rem, diffCt);
                if (diffCt > rem) {
                  console.log(`cotiue ${x}`);
                  continue;
                }
                if (hole[selectedPlayer] !== 0 || hole[oppoID] !== 0) {
                  thru += 1;
                }
                if (hole.winner === oppoID) {
                  oppoHoles += 1;
                  if (x < 9) {
                    oppoFrontNine += 1;
                  } else {
                    oppoBackNine += 1;
                  }
                }
                if (hole.winner === selectedPlayer) {
                  playerHoles += 1;
                  if (x < 9) {
                    playerFrontNine += 1;
                  } else {
                    playerBackNine += 1;
                  }
                }

                diffCt = Math.abs(playerHoles - oppoHoles);
                // console.log(diffCt);
              }

              match.thru = thru;
              const diff = playerHoles - oppoHoles;
              const frontDiff = playerFrontNine - oppoFrontNine;
              const backDiff = playerBackNine - oppoBackNine;
              let frontNineLeader = "";
              let frontNineBy = 0;
              let backNineLeader = "";
              let backNineBy = 0;

              if (frontDiff < 0) {
                frontNineLeader = oppoID;
                frontNineBy = -frontDiff;
              } else if (frontDiff > 0) {
                frontNineLeader = selectedPlayer;
                frontNineBy = frontDiff;
              } else {
                frontNineLeader = "";
                frontNineBy = 0;
              }

              if (backDiff < 0) {
                backNineLeader = oppoID;
                backNineBy = -backDiff;
              } else if (backDiff > 0) {
                backNineLeader = selectedPlayer;
                backNineBy = backDiff;
              } else {
                backNineLeader = "";
                backNineBy = 0;
              }

              match.backNine = backNineLeader;
              match.backNineBy = backNineBy;
              match.frontNine = frontNineLeader;
              match.frontNineBy = frontNineBy;
              console.log(diff);

              if (diff > 0) {
                match.leader = selectedPlayer;
                match.score = diff;
              }
              if (diff < 0) {
                match.leader = oppoID;
                match.score = diff * -1;
              }
              if (diff === 0) {
                match.leader = "";
                match.score = 0;
              }
              console.log(match);
              matches[i] = match;
            }
          }
          await roundDoc.ref.update({
            matches,
          });
        }

        if (format === "Fourball") {
          let matchIndex = 0;
          const playerID = selectedPlayer;
          let team = "";
          let oppoTeam = "";
          let partnerID = "";
          let oppo1 = "";
          let oppo2 = "";
          for (let i = 0; i < matches.length; i++) {
            if (matches[i].teamOne[0].playerID === playerID) {
              matchIndex = i;
              team = "teamOne";
              oppoTeam = "teamTwo";
              partnerID = matches[i].teamOne[1].playerID;
            }
            if (matches[i].teamOne[1].playerID === playerID) {
              matchIndex = i;
              team = "teamOne";
              oppoTeam = "teamTwo";
              partnerID = matches[i].teamOne[0].playerID;
            }
            if (matches[i].teamTwo[0].playerID === playerID) {
              matchIndex = i;
              team = "teamTwo";
              oppoTeam = "teamOne";
              partnerID = matches[i].teamTwo[1].playerID;
            }
            if (matches[i].teamTwo[1].playerID === playerID) {
              matchIndex = i;
              team = "teamTwo";
              oppoTeam = "teamOne";
              partnerID = matches[i].teamTwo[0].playerID;
            }
          }

          if (team === "teamOne") {
            oppo1 = matches[matchIndex].teamTwo[0].playerID;
            oppo2 = matches[matchIndex].teamTwo[1].playerID;
          }
          if (team === "teamTwo") {
            oppo1 = matches[matchIndex].teamOne[0].playerID;
            oppo2 = matches[matchIndex].teamOne[1].playerID;
          }

          for (let i = 0; i < matches[matchIndex].holes.length; i++) {
            matches[matchIndex].holes[i][team][playerID] = holes[i].netScore;
          }

          for (let i = 0; i < matches[matchIndex].holes.length; i++) {
            const hole = matches[matchIndex].holes[i];
            const t1Scores = hole["teamOne"];
            const t2Scores = hole["teamTwo"];

            let filled = true;
            let scrambleFilled1 = false;
            let scrambleFilled2 = false;

            for (const [key, value] of Object.entries(t1Scores)) {
              if (value === 0) {
                filled = false;
              } else {
                scrambleFilled1 = true;
              }
            }
            for (const [key, value] of Object.entries(t2Scores)) {
              if (value === 0) {
                filled = false;
              } else {
                scrambleFilled2 = true;
              }
            }

            if (
              filled ||
              (scrambleHandicaps && scrambleFilled1 && scrambleFilled2)
            ) {
              const score = hole[team][playerID];
              const partnerScore = hole[team][partnerID];
              let lowestOwnTeam = 0;
              let lowestOppoTeam = 0;
              if (partnerScore > score || partnerScore === 0) {
                hole[`lowest${team}`] = score;
                lowestOwnTeam = score;
              } else {
                hole[`lowest${team}`] = score;
                lowestOwnTeam = partnerScore;
              }
              // Oppo team score
              const oppo1Score = hole[oppoTeam][oppo1];
              const oppo2Score = hole[oppoTeam][oppo2];
              if (oppo1Score > oppo2Score || oppo1Score === 0) {
                hole[`lowest${oppoTeam}`] = oppo2Score;
                lowestOppoTeam = oppo2Score;
              } else {
                hole[`lowest${oppoTeam}`] = oppo1Score;
                lowestOppoTeam = oppo1Score;
              }
              // SCRAMBLE
              if (oppo2Score === 0) {
                hole[`lowest${oppoTeam}`] = oppo1Score;
                lowestOppoTeam = oppo1Score;
              }
              // Oppo win hole
              if (lowestOwnTeam > lowestOppoTeam) {
                hole["winner"] = oppoTeam;
              }
              if (lowestOwnTeam < lowestOppoTeam) {
                hole["winner"] = team;
              }
              if (lowestOwnTeam === lowestOppoTeam) {
                hole["winner"] = "tied";
              }
              matches[matchIndex].holes[i] = hole;
            }
          }

          let teamOneHoles = 0;
          let teamTwoHoles = 0;
          let teamOneBackNine = 0;
          let teamTwoBackNine = 0;
          let teamOneFrontNine = 0;
          let teamTwoFrontNine = 0;
          let thru = 0;

          // Time to cycle through this shit
          for (let i = 0; i < matches[matchIndex].holes.length; i++) {
            const winner = matches[matchIndex].holes[i].winner;
            if (winner === "teamOne") {
              teamOneHoles += 1;
              thru += 1;
              if (i < 9) {
                teamOneFrontNine += 1;
              } else {
                teamOneBackNine += 1;
              }
            }
            if (winner === "teamTwo") {
              teamTwoHoles += 1;
              thru += 1;
              if (i < 9) {
                teamTwoFrontNine += 1;
              } else {
                teamTwoBackNine += 1;
              }
            }
            if (winner === "tied") {
              thru += 1;
            }
          }

          const diff = teamOneHoles - teamTwoHoles;
          const frontDiff = teamOneFrontNine - teamTwoFrontNine;
          const backDiff = teamOneBackNine - teamTwoBackNine;

          if (frontDiff > 0) {
            matches[matchIndex].frontNine = "teamOne";
            matches[matchIndex].frontNineBy = frontDiff;
          }
          if (frontDiff < 0) {
            matches[matchIndex].frontNine = "teamTwo";
            matches[matchIndex].frontNineBy = -frontDiff;
          }
          if (frontDiff === 0) {
            matches[matchIndex].frontNine = "";
            matches[matchIndex].frontNineBy = frontDiff;
          }

          if (backDiff > 0) {
            matches[matchIndex].backNine = "teamOne";
            matches[matchIndex].backNineBy = backDiff;
          }
          if (backDiff < 0) {
            matches[matchIndex].backNine = "teamTwo";
            matches[matchIndex].backNineBy = -backDiff;
          }
          if (backDiff === 0) {
            matches[matchIndex].backNine = "";
            matches[matchIndex].backNineBy = backDiff;
          }
          if (diff > 0) {
            matches[matchIndex].leader = "teamOne";
            matches[matchIndex].score = diff;
          }
          if (diff < 0) {
            matches[matchIndex].leader = "teamTwo";
            matches[matchIndex].score = diff * -1;
          }
          if (diff === 0) {
            matches[matchIndex].leader = "";
            matches[matchIndex].score = 0;
          }

          matches[matchIndex]["thru"] = thru;

          const holesRemaining = 18 - thru;
          if (diff > holesRemaining) {
            matches[matchIndex].completed = true;
            matches[matchIndex].complete = true;
          } else {
            matches[matchIndex].completed = false;
            matches[matchIndex].complete = false;
          }
          await roundDoc.ref.update({
            matches,
          });

          if (teamEvt) {
            eventMatchups({
              eventID,
            });
          }
        }
      }

      await db
        .collection("rounds")
        .doc(roundID)
        .collection("scores")
        .doc(selectedPlayer)
        .update({
          holes,
          input: true,
          track: false,
        });
      if (portal) {
        goBack();
      }
      // alert("do/ne");
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }

    setLoading(false);
    // loadHoles()
  }

  async function saveLD() {
    setLoadingModal(true);
    try {
      const rd = await db.collection("rounds").doc(roundID).get();
      const rdData = rd.data();
      const { ldMap } = rdData;
      ldMap[selectedPlayer] = ld;
      await rd.ref.update({
        ldMap,
      });
      setShowLD(false);
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
      setLoadingModal(false);
    }
  }

  async function saveCTP() {
    setLoadingModal(true);
    try {
      const rd = await db.collection("rounds").doc(roundID).get();
      const rdData = rd.data();
      const { ctpMap } = rdData;
      ctpMap[selectedPlayer] = ctp;
      await rd.ref.update({
        ctpMap,
      });
      if (cup) {
        await roundCupScore({ roundID });
      }
      setShowCTP(false);
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }
    setLoadingModal(false);
  }

  function createArrayFromInteger(integer) {
    if (integer < 0) {
      throw new Error("Input must be a positive integer");
    }

    const resultArray = [];
    for (let i = 0; i <= integer; i++) {
      resultArray.push(i);
    }

    return resultArray;
  }

  async function saveSSChanges() {
    setLoading(true);
    try {
      if (details.format === "medal") {
        await db
          .collection("rounds")
          .doc(roundID)
          .collection("scores")
          .doc(selectedPlayer)
          .update({
            startingScore: parseInt(ssIp),
            startingGrossScore: parseInt(ssIp),
          });
      } else {
        await db
          .collection("rounds")
          .doc(roundID)
          .collection("scores")
          .doc(selectedPlayer)
          .update({
            startingPoints: parseInt(spIp),
            startingScore: parseInt(ssIp),
          });
      }
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setShowSS(false);
    setLoading(false);
  }

  return (
    <div className="score-input">
      {!selected ? (
        <>
          <Dialog onClose={() => goBack()} open className="si-select-dialog">
            <div className="si-select">
              <PageHeader text="Select Player" showClose close={goBack} />
              <div className="input-group mb-20 si-search">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="si-players">
                {players.map((player) => {
                  if (scorerID !== "") {
                    if (player.teamID !== scorerID) {
                      return null;
                    }
                  }
                  if (searchInput === "") {
                    return (
                      <PlayerItem
                        key={player.playerID}
                        name={player.name}
                        hcp={player.handicap}
                        playerID={player.playerID}
                        hideIcons
                        showProfile={() => {
                          setSelectedPlayer((current) => {
                            if (current === player.playerID) {
                              return "";
                            } else {
                              return player.playerID;
                            }
                          });
                          setSelectedName(player.name);
                          setHcp(player.handicap);
                          setSelected(true);
                          loadHoles(player.playerID);
                        }}
                        noLink
                        showBg={selected === player.playerID}
                        img={player.profileImage}
                      />
                    );
                  } else {
                    if (
                      player.name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    ) {
                      return (
                        <PlayerItem
                          key={player.playerID}
                          name={player.name}
                          hcp={player.handicap}
                          playerID={player.playerID}
                          hideIcons
                          showProfile={() => {
                            setSelectedPlayer((current) => {
                              if (current === player.playerID) {
                                return "";
                              } else {
                                return player.playerID;
                              }
                            });
                            setSelectedName(player.name);
                            setHcp(player.handicap);
                            setSelected(true);
                            loadHoles(player.playerID);
                          }}
                          noLink
                          showBg={selected === player.playerID}
                          img={player.profileImage}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                })}
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog open={showLD} onClose={() => setShowLD(false)}>
            <div className="si-dialog">
              <div className="page-header">
                <h2>Longest Drive - Hole {ldHole + 1}</h2>
              </div>
              {loadingModal ? (
                <>
                  <div className="flex-center loading">
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="input-group pl-20">
                    <p>Distance</p>

                    <input
                      type="number"
                      value={ld}
                      onChange={(e) => setLD(e.target.valueAsNumber)}
                      className="default-input"
                    />
                  </div>
                  {modalError !== "" && (
                    <div className="error-message-row">
                      <p>{modalError}</p>
                    </div>
                  )}
                  <div className="default-button-row flex-center">
                    <button onClick={saveLD} className="default-button">
                      Save
                    </button>
                  </div>
                </>
              )}
            </div>
          </Dialog>
          <Dialog open={showCTP} onClose={() => setShowCTP(false)}>
            <div className="si-dialog">
              <div className="page-header">
                <h2>Closest to the Pin - Hole {ctpHole + 1}</h2>
              </div>
              {loadingModal ? (
                <>
                  <div className="flex-center loading">
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="input-group pl-20">
                    <p>Distance to the Pin</p>

                    <input
                      type="number"
                      value={ctp}
                      onChange={(e) => setCTP(e.target.valueAsNumber)}
                      className="default-input"
                    />
                  </div>
                  {modalError !== "" && (
                    <div className="error-message-row">
                      <p>{modalError}</p>
                    </div>
                  )}
                  <div className="default-button-row flex-center">
                    <button onClick={saveCTP} className="default-button">
                      Save
                    </button>
                  </div>
                </>
              )}
            </div>
          </Dialog>
          <Dialog open={showSS} onClose={() => setShowSS(false)}>
            <div className="edit-ss">
              <PageHeader
                close={() => setShowSS(false)}
                showClose
                text="Edit Starting Score"
              />
              <div className="ess-ips pd-20">
                <div className="input-group">
                  <p>Starting Score</p>
                  <input
                    className="default-input"
                    value={ssIp}
                    type="number"
                    step={1}
                    onChange={(e) => setSSIP(e.target.value)}
                  />
                </div>
                {details.format !== "medal" && (
                  <div className="input-group">
                    <p>Starting Points</p>
                    <input
                      className="default-input"
                      value={spIp}
                      step={1}
                      type="number"
                      onChange={(e) => setSPIP(e.target.value)}
                    />
                  </div>
                )}
              </div>

              <div className="flex-center mb-40">
                <button onClick={saveSSChanges} className="default-button">
                  Save
                </button>
              </div>
            </div>
          </Dialog>
          {loading && <BackdropLoader />}
          <div onClick={goBack} className="back-row">
            <ArrowBack />
            <p>Back</p>
          </div>

          <PageHeader text={`Score Input - ${selectedName}`} />

          <div className="sic-box">
            <div className="si-card score-table-container prtc">
              <div className="sub-header">
                <h5>{eventName}</h5>
                <div className="flex-align-center">
                  <div>
                    {!edit && (
                      <GradientEditIcon
                        onClick={() => {
                          setEdit(true);
                          //   setSavedHoles((current) => {
                          //     const newArr = [...current];
                          //     return newArr;
                          //   });
                        }}
                      />
                    )}
                    {/* <Looks3Icon
                    className="dg-icon-bg icon-cursor"
                    onClick={() => setShowPutts((cur) => !cur)}
                  /> */}
                  </div>
                  {edit && (
                    <div className="flex-align-center">
                      {!portal && (
                        <div className="selector-group pd-20">
                          <button
                            onClick={() => setScoreMode("input")}
                            className={
                              scoreMode === "input"
                                ? "sg-left sg-active"
                                : "sg-left"
                            }
                          >
                            Input
                          </button>
                          <button
                            onClick={() => setScoreMode("selector")}
                            className={
                              scoreMode === "selector"
                                ? "sg-left sg-active"
                                : "sg-left"
                            }
                          >
                            Selector
                          </button>
                        </div>
                      )}
                      {scoreMode !== "selector" && (
                        <Putter
                          className="dg-icon-bg icon-cursor mr-10 putt-ico"
                          onClick={() => setShowPutts((cur) => !cur)}
                        />
                      )}

                      <GradientUndoIcon
                        onClick={() => {
                          setHoles((current) => {
                            console.log(savedHoles);

                            return savedHoles;
                          });
                          setUpdate((current) => current + 1);
                          setEdit(false);
                        }}
                      />
                      <GradientDoneIcon
                        className={disableSave ? "ico-dis" : ""}
                        onClick={() => {
                          if (disableSave) {
                            return;
                          }
                          saveChanges();
                          setEdit(false);
                        }}
                      />
                      {/* <button
                      onClick={() => setEdit(false)}
                      className="default-button"
                    >
                      Save
                    </button> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="si-course">
                <p>{details.courseName}</p>
              </div>
              <div className="ph-msg mt-0 mb-5">
                <p>Handicap {hcp < 0 ? `+${Math.abs(hcp)}` : hcp}</p>
                {/* <p>Frt Handicap {frtHcp}</p> */}
              </div>
              {teamHandicap !== "" && (
                <div className="ph-msg mt-0 mb-20">
                  <p>
                    Team Handicap{" "}
                    {teamHandicap < 0 ? `+${teamHandicap}` : teamHandicap}
                  </p>
                </div>
              )}
              {tees !== "" && (
                <div className="si-tee">
                  <SportsGolfIcon className="si-ico" />
                  <div
                    style={{
                      backgroundColor: bgc[tees],
                      borderColor: bc[tees],
                    }}
                    className="si-tee-box"
                  >
                    <p
                      style={{
                        color: ["white", "yellow"].includes(tees.toLowerCase())
                          ? "#232c2e"
                          : "white",
                      }}
                    >
                      {tees}
                    </p>
                  </div>
                </div>
              )}
              <div className="si-summary">
                <div className="sis-item">
                  <p>
                    Current Net Score:{" "}
                    <span>
                      {details.currentScore > 0
                        ? `+${details.currentScore}`
                        : details.currentScore}
                    </span>
                  </p>
                </div>
                <div className="sis-item">
                  <p>
                    Current Gross Score:{" "}
                    <span>
                      {details.currentGrossScore > 0
                        ? `+${details.currentGrossScore}`
                        : details.currentGrossScore}
                    </span>
                  </p>
                </div>
                <div className="sis-item">
                  <p>
                    Total Points: <span>{details.totalPoints}</span>
                  </p>
                </div>
                {showSSV && (
                  <div className="sis-item">
                    <p>
                      {" "}
                      Starting Score:{" "}
                      <span>
                        {details.startingScore > 0
                          ? `+${details.startingScore}`
                          : details.startingScore}
                      </span>
                    </p>
                  </div>
                )}
                {showSSV && (
                  <div className="sis-item">
                    <p>
                      {" "}
                      Starting Points: <span>{details.startingPoints}</span>
                    </p>
                  </div>
                )}
                {false && (
                  <div className="sis-item">
                    <p>
                      Total Mod Points: <span>{details.totalModPoints}</span>
                    </p>
                  </div>
                )}
              </div>
              {(ctpEnabled || ldEnabled || showSSV) && (
                <div className="quick-links mb-10">
                  {ctpEnabled && (
                    <Pill
                      text={"Closest to the Pin"}
                      onClick={() => setShowCTP(true)}
                    />
                  )}
                  {ldEnabled && (
                    <Pill
                      text={"Longest Drive"}
                      onClick={() => setShowLD(true)}
                    />
                  )}
                  {(details.startingScore || showSSV) && (
                    <Pill
                      text={"Edit Starting Score"}
                      onClick={() => setShowSS(true)}
                    />
                  )}
                </div>
              )}
              <div className="sit-box">
                <table className="si-table">
                  <thead>
                    <tr className="header-row prtc-header">
                      <th>
                        <GolfCourseIcon />
                      </th>
                      <th>Par</th>
                      {/* <th>Length</th> */}
                      <th>Stroke</th>
                      {(scoreMode !== "selector" || !edit) && <th>Strokes</th>}
                      {scoreMode === "selector" && edit && <th>Gross Score</th>}
                      <th>Gross</th>
                      <th>Net</th>
                      {(scoreMode !== "selector" || !edit) && <th>Points</th>}
                      {teamHandicap !== "" &&
                        (scoreMode !== "selector" || !edit) && (
                          <th>Team Strokes</th>
                        )}
                      {teamHandicap !== "" &&
                        (scoreMode !== "selector" || !edit) && (
                          <th>Team Score</th>
                        )}
                      {showPutts && <th>Putts</th>}
                      {(scoreMode !== "selector" || !edit) && <th>GIR</th>}
                      {(scoreMode !== "selector" || !edit) && <th>FIR</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {(scoreMode === "input" || !edit) &&
                      holes.map((hole, index) => {
                        return (
                          <React.Fragment key={`${index}holes`}>
                            {index === 9 && (
                              <tr
                                key={"first-out"}
                                className="header-row out-row"
                              >
                                <td className="hole-td">OUT</td>
                                <td className="par-td">
                                  {details.frontNinePar ?? 36}
                                </td>
                                <td></td>
                                <td></td>
                                <td className="normal-td bold-td">
                                  {details.frontNineGross}
                                </td>
                                <td className="normal-td bold-td">
                                  {details.frontNineNet}
                                </td>
                                <td className="normal-td">
                                  {details.frontNinePoints}
                                </td>
                                {teamHandicap !== "" && <td></td>}
                                {teamHandicap !== "" && <td></td>}
                                {showPutts && (
                                  <td className="normal-td">
                                    {details.frontNinePutts}
                                  </td>
                                )}
                                <td className="normal-td">
                                  {details.frontNineGIR}
                                </td>
                                <td className="normal-td">
                                  {details.frontNineFIR}
                                </td>
                              </tr>
                            )}
                            <tr key={index}>
                              <td className="normal-td">{index + 1}</td>
                              <td className="normal-td">{hole.par}</td>
                              {/* <td className="normal-td">{hole.length}</td> */}
                              <td className="normal-td">{hole.stroke}</td>
                              <td className="normal-td">{hole.strokes}</td>
                              <td
                                className={
                                  hole.grossScore - hole.par > 0
                                    ? "score-td over-par"
                                    : hole.grossScore - hole.par < 0 &&
                                      hole.grossScore > 0
                                    ? "score-td under-par"
                                    : "score-td"
                                }
                              >
                                {edit ? (
                                  <>
                                    <input
                                      type="number"
                                      min={0}
                                      max={
                                        details.format === "medal"
                                          ? 20
                                          : hole.par + hole.strokes + ring
                                      }
                                      value={hole.grossScore}
                                      className="default-input"
                                      onChange={(e) => {
                                        // if (e.target.value !== "") {
                                        handleGrossChange(e, index);
                                        // }
                                      }}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </>
                                ) : (
                                  hole.grossScore
                                )}
                              </td>
                              <td
                                className={
                                  hole.netScore - hole.par > 0
                                    ? "score-td over-par"
                                    : hole.netScore - hole.par < 0 &&
                                      hole.grossScore > 0
                                    ? "score-td under-par"
                                    : "score-td"
                                }
                              >
                                {hole.netScore}
                              </td>
                              <td
                                className={
                                  hole.netScore - hole.par > 0
                                    ? "score-td over-par"
                                    : hole.netScore - hole.par < 0 &&
                                      hole.grossScore > 0
                                    ? "score-td under-par"
                                    : "score-td"
                                }
                              >
                                {hole.points}
                              </td>
                              {teamHandicap !== "" && (
                                <td className="normal-td">
                                  {hole.teamStrokes}
                                </td>
                              )}
                              {teamHandicap !== "" &&
                                details.format.includes("stable") && (
                                  <td
                                    className={
                                      hole.teamNet - hole.par > 0
                                        ? "score-td over-par"
                                        : hole.teamNet - hole.par < 0 &&
                                          hole.grossScore > 0
                                        ? "score-td under-par"
                                        : "score-td"
                                    }
                                  >
                                    {hole.teamPoints}
                                  </td>
                                )}
                              {teamHandicap !== "" &&
                                !details.format.includes("stable") && (
                                  <td
                                    className={
                                      hole.teamNet - hole.par > 0
                                        ? "score-td over-par"
                                        : hole.teamNet - hole.par < 0 &&
                                          hole.grossScore > 0
                                        ? "score-td under-par"
                                        : "score-td"
                                    }
                                  >
                                    {hole.teamNet}
                                  </td>
                                )}

                              {showPutts && (
                                <td className="normal-td">
                                  {edit ? (
                                    <>
                                      <input
                                        type="number"
                                        min={0}
                                        onChange={(e) => {
                                          setHoles((current) => {
                                            current[index].putts =
                                              e.target.valueAsNumber;
                                            const hole = current[index];
                                            const score = hole.grossScore;
                                            const puttsOnHole = hole.putts;
                                            const holePar = hole.par;
                                            // GIR
                                            if (
                                              score - puttsOnHole <=
                                              holePar - 2
                                            ) {
                                              current[index].gir = true;
                                            } else {
                                              current[index].gir = false;
                                            }

                                            return current;
                                          });
                                          setUpdate((current) => current + 1);
                                        }}
                                        value={hole.putts}
                                        className="default-input"
                                      />
                                    </>
                                  ) : (
                                    hole.putts
                                  )}
                                </td>
                              )}
                              <td>
                                {hole.gir ? (
                                  <DoneIcon className="hit" />
                                ) : (
                                  <CloseIcon className="missed" />
                                )}
                              </td>
                              <td>
                                {hole.par !== 3 && (
                                  <>
                                    {!edit && (
                                      <>
                                        {hole.fir ? (
                                          <AdjustIcon className="hit" />
                                        ) : (
                                          <CloseIcon className="missed" />
                                        )}
                                      </>
                                    )}
                                    {edit && (
                                      <>
                                        {hole.fir ? (
                                          <Tooltip title="Click to change">
                                            <AdjustIcon
                                              onClick={() => {
                                                setHoles((current) => {
                                                  current[index].fir = false;
                                                  return current;
                                                });
                                                setUpdate(
                                                  (current) => current + 1
                                                );
                                              }}
                                              className="hit fir-icon"
                                            />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title="Click to change">
                                            <CloseIcon
                                              onClick={() => {
                                                setHoles((current) => {
                                                  current[index].fir = true;
                                                  return current;
                                                });
                                                setUpdate(
                                                  (current) => current + 1
                                                );
                                              }}
                                              className="missed fir-icon"
                                            />
                                          </Tooltip>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                            {index === 17 && (
                              <tr key={"in-row"} className="header-row in-row">
                                <td className="hole-td">IN</td>
                                <td className="par-td">
                                  {details.backNinePar ?? 36}
                                </td>
                                <td></td>
                                <td></td>

                                <td className="normal-td">
                                  {details.backNineGross}
                                </td>
                                <td className="normal-td">
                                  {details.backNineNet}
                                </td>
                                <td className="normal-td">
                                  {details.backNinePoints}
                                </td>
                                {teamHandicap !== "" && <td></td>}
                                {teamHandicap !== "" && <td></td>}
                                {showPutts && (
                                  <td className="normal-td">
                                    {details.backNinePutts}
                                  </td>
                                )}

                                <td className="normal-td">
                                  {details.backNineFIR}
                                </td>
                                <td className="normal-td">
                                  {details.backNineGIR}
                                </td>
                              </tr>
                            )}
                            {index === 17 && (
                              <tr
                                key={"out-second"}
                                className="header-row out-row"
                              >
                                <td className="hole-td">OUT</td>
                                <td className="par-td">
                                  {details.frontNinePar ?? 36}
                                </td>
                                <td></td>
                                <td></td>
                                <td className="normal-td">
                                  {details.frontNineGross}
                                </td>
                                <td className="normal-td">
                                  {details.frontNineNet}
                                </td>
                                <td className="normal-td">
                                  {details.frontNinePoints}
                                </td>
                                {teamHandicap !== "" && <td></td>}
                                {teamHandicap !== "" && <td></td>}
                                {showPutts && (
                                  <td className="normal-td">
                                    {details.frontNinePutts}
                                  </td>
                                )}

                                <td className="normal-td">
                                  {details.frontNineFIR}
                                </td>
                                <td className="normal-td">
                                  {details.frontNineGIR}
                                </td>
                              </tr>
                            )}
                            {index === 17 && (
                              <tr
                                key={"total"}
                                className="header-row total-row"
                              >
                                <td className="hole-td">TOT</td>
                                <td className="par-td">{details.coursePar}</td>
                                <td></td>

                                <td></td>
                                <td className="player-td bold-td">
                                  {details.totalGrossStrokes}
                                </td>
                                <td className="player-td bold-td">
                                  {details.totalNetStrokes}
                                </td>
                                <td className="normal-td">
                                  {details.startingPoints
                                    ? details.totalPoints -
                                      details.startingPoints
                                    : details.totalPoints}
                                </td>
                                {teamHandicap !== "" && <td></td>}
                                {teamHandicap !== "" && <td></td>}
                                {showPutts && (
                                  <td className="normal-td">
                                    {details.totalPutts}
                                  </td>
                                )}

                                {/* {true && (
                              <>
                                <td className="normal-td"></td>
                                <td className="normal-td"></td>
                              </>
                            )} */}
                                {true && (
                                  <>
                                    <td className="normal-td">
                                      {details.frontNineFIR +
                                        details.backNineFIR}
                                    </td>
                                    <td className="normal-td">
                                      {details.frontNineGIR +
                                        details.backNineGIR}
                                    </td>
                                  </>
                                )}
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}

                    {/* SELECTOR */}
                    {scoreMode === "selector" &&
                      edit &&
                      holes.map((hole, index) => {
                        if (index === 0) {
                          // console.log(hole);
                        }

                        let max = hole.par + hole.strokes + ring;
                        if (details.format === "medal" || details.scratchMode) {
                          // console.log("AAAH");
                          max = 12;
                        }
                        const scoreArr = createArrayFromInteger(max);
                        return (
                          <>
                            {index === 9 && (
                              <tr
                                key={"first-out"}
                                className="header-row out-row"
                              >
                                <td className="hole-td">OUT</td>
                                <td className="par-td">
                                  {details.frontNinePar ?? 36}
                                </td>
                                <td></td>
                                {scoreMode === "selector" && <td></td>}

                                <td className="normal-td bold-td">
                                  {details.frontNineGross}
                                </td>
                                <td className="normal-td bold-td">
                                  {details.frontNineNet}
                                </td>
                                {false && (
                                  <td className="normal-td">
                                    {details.frontNinePutts}
                                  </td>
                                )}
                                {false && (
                                  <td className="normal-td">
                                    {Math.round(
                                      (details.frontNineGIR / 9) * 100
                                    )}
                                    %
                                  </td>
                                )}
                                {false && (
                                  <td className="normal-td">
                                    {Math.round(
                                      (details.frontNineFIR / 9) * 100
                                    )}
                                    %
                                  </td>
                                )}
                              </tr>
                            )}
                            <tr key={index}>
                              <td className="normal-td">{index + 1}</td>
                              <td className="normal-td">{hole.par}</td>
                              <td className="normal-td">{hole.stroke}</td>
                              {/* <td className="sh-td"> */}
                              <td className="selector-holes">
                                {/* <div className="sh-ho-bo"> */}
                                {scoreArr.map((score, i) => {
                                  const net = score - hole.par;
                                  if (index === 0) {
                                    // console.log(net);
                                  }
                                  let holeClass = "";
                                  if (hole.grossScore !== 0 && score !== 0) {
                                    holeClass = `sis-${net} `;
                                  }

                                  if (
                                    score === hole.grossScore &&
                                    hole.grossScore !== 0
                                  ) {
                                    holeClass += `sis-active sis-active-${net} `;
                                  }
                                  if (
                                    score === hole.grossScore &&
                                    hole.grossScore === 0
                                  ) {
                                    holeClass += `sis-active sis-active-zero `;
                                  }

                                  if (net > 0) {
                                    holeClass += `sis-over`;
                                  } else if (net < 0 && score !== 0) {
                                    holeClass += `sis-under`;
                                  } else if (net === 0 && score !== 0) {
                                    holeClass += `sis-par`;
                                  }

                                  return (
                                    <div
                                      onClick={() => {
                                        handleGrossChange(score, index);
                                      }}
                                      key={`${i}selhole`}
                                      className={`si-sel-hole ${holeClass}`}
                                    >
                                      <p>{score}</p>
                                    </div>
                                  );
                                })}
                                {/* </div> */}
                              </td>
                              {/* </td> */}
                              {false && (
                                <td
                                  className={
                                    hole.grossScore - hole.par > 0
                                      ? "score-td over-par"
                                      : hole.grossScore - hole.par < 0 &&
                                        hole.grossScore > 0
                                      ? "score-td under-par"
                                      : "score-td"
                                  }
                                >
                                  {edit ? (
                                    <>
                                      <input
                                        type="number"
                                        min={0}
                                        max={
                                          details.format === "medal"
                                            ? 20
                                            : hole.par + hole.strokes + ring
                                        }
                                        value={hole.grossScore}
                                        className="default-input"
                                        onChange={(e) => {
                                          handleGrossChange(e, index);
                                        }}
                                      />
                                    </>
                                  ) : (
                                    hole.grossScore
                                  )}
                                </td>
                              )}
                              {false && (
                                <td
                                  className={
                                    hole.netScore - hole.par > 0
                                      ? "score-td over-par"
                                      : hole.netScore - hole.par < 0 &&
                                        hole.grossScore > 0
                                      ? "score-td under-par"
                                      : "score-td"
                                  }
                                >
                                  {hole.netScore}
                                </td>
                              )}
                              {false && showPutts && (
                                <td className="normal-td">
                                  {edit ? (
                                    <>
                                      <input
                                        type="number"
                                        min={0}
                                        onChange={(e) => {
                                          setHoles((current) => {
                                            current[index].putts =
                                              e.target.valueAsNumber;
                                            const hole = current[index];
                                            const score = hole.grossScore;
                                            const puttsOnHole = hole.putts;
                                            const holePar = hole.par;
                                            // GIR
                                            if (
                                              score - puttsOnHole <=
                                              holePar - 2
                                            ) {
                                              current[index].gir = true;
                                            } else {
                                              current[index].gir = false;
                                            }

                                            return current;
                                          });
                                          setUpdate((current) => current + 1);
                                        }}
                                        value={hole.putts}
                                        className="default-input"
                                      />
                                    </>
                                  ) : (
                                    hole.putts
                                  )}
                                </td>
                              )}
                              {false && (
                                <td>
                                  {hole.gir ? (
                                    <DoneIcon className="hit" />
                                  ) : (
                                    <CloseIcon className="missed" />
                                  )}
                                </td>
                              )}
                              {false && (
                                <td>
                                  {hole.par !== 3 && (
                                    <>
                                      {!edit && (
                                        <>
                                          {hole.fir ? (
                                            <AdjustIcon className="hit" />
                                          ) : (
                                            <CloseIcon className="missed" />
                                          )}
                                        </>
                                      )}
                                      {edit && (
                                        <>
                                          {hole.fir ? (
                                            <Tooltip title="Click to change">
                                              <AdjustIcon
                                                onClick={() => {
                                                  setHoles((current) => {
                                                    current[index].fir = false;
                                                    return current;
                                                  });
                                                  setUpdate(
                                                    (current) => current + 1
                                                  );
                                                }}
                                                className="hit fir-icon"
                                              />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip title="Click to change">
                                              <CloseIcon
                                                onClick={() => {
                                                  setHoles((current) => {
                                                    current[index].fir = true;
                                                    return current;
                                                  });
                                                  setUpdate(
                                                    (current) => current + 1
                                                  );
                                                }}
                                                className="missed fir-icon"
                                              />
                                            </Tooltip>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              )}
                            </tr>
                            {index === 17 && (
                              <tr key={"in-row"} className="header-row in-row">
                                <td className="hole-td">IN</td>
                                <td className="par-td">
                                  {details.backNinePar ?? 36}
                                </td>
                                <td></td>
                                {scoreMode === "selector" && <td></td>}

                                <td className="normal-td">
                                  {details.backNineGross}
                                </td>
                                <td className="normal-td">
                                  {details.backNineNet}
                                </td>
                                {/* <td className="normal-td">
                                {details.backNinePutts}
                              </td>
                              <td className="normal-td">
                                {Math.round((details.backNineFIR / 9) * 100)}%
                              </td>
                              <td className="normal-td">
                                {Math.round((details.backNineGIR / 9) * 100)}%
                              </td> */}
                              </tr>
                            )}
                            {index === 17 && (
                              <tr
                                key={"out-second"}
                                className="header-row out-row"
                              >
                                <td className="hole-td">OUT</td>
                                <td className="par-td">
                                  {details.frontNinePar ?? 36}
                                </td>
                                {scoreMode === "selector" && <td></td>}

                                <td></td>
                                <td className="normal-td">
                                  {details.frontNineGross}
                                </td>
                                <td className="normal-td">
                                  {details.frontNineNet}
                                </td>
                                {/* <td className="normal-td">
                                {details.frontNinePutts}
                              </td>
                              <td className="normal-td">
                                {Math.round((details.frontNineFIR / 9) * 100)}%
                              </td>
                              <td className="normal-td">
                                {Math.round((details.frontNineGIR / 9) * 100)}%
                              </td> */}
                              </tr>
                            )}
                            {index === 17 && (
                              <tr
                                key={"total"}
                                className="header-row total-row"
                              >
                                <td className="hole-td">TOT</td>
                                <td className="par-td">{details.coursePar}</td>
                                <td></td>
                                {scoreMode === "selector" && <td></td>}

                                <td className="player-td bold-td">
                                  {details.totalGrossStrokes}
                                </td>
                                <td className="player-td bold-td">
                                  {details.totalNetStrokes}
                                </td>
                                {/* <td className="normal-td">
                                {details.totalPutts}
                              </td> */}
                                {/* {true && (
                              <>
                                <td className="normal-td"></td>
                                <td className="normal-td"></td>
                              </>
                            )} */}
                                {false && (
                                  <>
                                    <td className="normal-td">
                                      {Math.round(
                                        ((details.frontNineFIR +
                                          details.backNineFIR) /
                                          18) *
                                          100
                                      )}
                                      %
                                    </td>
                                    <td className="normal-td">
                                      {Math.round(
                                        ((details.frontNineGIR +
                                          details.backNineGIR) /
                                          18) *
                                          100
                                      )}
                                      %
                                    </td>
                                  </>
                                )}
                              </tr>
                            )}
                          </>
                        );
                      })}
                  </tbody>
                </table>{" "}
              </div>
              <div className="flex-center mt-20">
                <button
                  disabled={disableSave}
                  onClick={() => {
                    saveChanges();
                    setEdit(false);
                  }}
                  className="default-button"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ScoreInput;
