import React, { useState, useEffect } from "react";
import "./PlayerItem.css";

// Components
import { Badge, Avatar, Tooltip } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import DeblurIcon from "@mui/icons-material/Deblur";
import PlayerLoader from "../../Loaders/PlayerLoader";
import ChatDrop from "../../ChatDrop/ChatDrop";
import { useClickTheme } from "../../../contexts/MenuContext";

// Icons
import EmailIcon from "@mui/icons-material/Email";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import EditIcon from "@mui/icons-material/Edit";
import { CheckCircle } from "@mui/icons-material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const GradientAddIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <DeblurIcon
      className="ps-icon dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientMailIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <Tooltip placement="right" title="Message">
      <EmailIcon
        onClick={onClick}
        className="pi-ico icon-cursor dg-icon"
        sx={{ fill: "url(#linearColors)" }}
      />
    </Tooltip>
  </>
);

const GradientProfileIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <Tooltip placement="right" title="View Profile">
      <AccountBoxIcon
        onClick={onClick}
        className="pi-icon mt-10 mb-10 dg-icon icon-cursor"
        sx={{ fill: "url(#linearColors)" }}
      />
    </Tooltip>
  </>
);

function PlayerItem({
  showProfile = () => {},
  showBg = false,
  hideIcons = false,
  showCheck = false,
  rank,
  name,
  img,
  hcp,
  hideHCP = false,
  noLink = false,
  hideImage = false,
  guestID = "",
  score = "",
  border = false,
  ranking = false,
  captain = false,
  label = "",
  showFriends = false,
  loading = false,
  playerID = "",
  showBadge = false,
  event = false,
  hideEdit = false,
  hideRemove = false,
  showHover = false,
  hcpEdit = false,
  hideMessage = false,
  hideProfile = false,
  showAdd = false,
  changeHcp = () => {},
  editAction = () => {},
  messageAction = () => {},
  removeAction = () => {},
  addAction = () => {},
  addIsFriend = false,
}) {
  const GradientAddPersonIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <Tooltip placement="right" title={addIsFriend ? "Add" : "Add to league"}>
        <GroupAddIcon
          onClick={onClick}
          className={"ps-icon pi-add-icon dg-icon icon-cursor"}
          sx={{ fill: "url(#linearColors)" }}
        />
      </Tooltip>
    </>
  );

  const GradientRemoveIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <Tooltip placement="right" title="Remove Player">
        <GroupRemoveIcon
          onClick={onClick}
          className={
            hideEdit && showAdd ? "pi-icon mt-10 dg-icon" : "dg-icon pi-icon"
          }
          sx={{ fill: "url(#linearColors)" }}
        />
      </Tooltip>
    </>
  );

  const GradientEditIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <Tooltip placement="right" title="Edit">
        <EditIcon
          onClick={onClick}
          className={
            hideEdit ? "icon-cursor mt-10 dg-icon" : "dg-icon icon-cursor"
          }
          sx={{ fill: "url(#linearColors)" }}
        />
      </Tooltip>
    </>
  );

  const showClick = useClickTheme();

  const [handicap, setHandicap] = useState(hcp);

  useEffect(() => {
    // console.log(hcp);
    // console.log(img);
    setHandicap(hcp);
  }, [hcp]);

  return (
    <>
      {loading ? (
        <PlayerLoader />
      ) : (
        <div
          onClick={(e) => {
            showProfile();
          }}
          style={{
            cursor: hideIcons ? "pointer" : "initial",
            backgroundColor: showBg ? "#C7EFDE" : "white",
          }}
          className={"player-score player-item"}
        >
          {!hideImage && (
            <div className={showBadge ? "ps-left ps-bg-badge" : "ps-left"}>
              <Badge color="default" badgeContent={rank}>
                <Avatar alt={name} src={img} />
              </Badge>
            </div>
          )}
          <div style={{ flex: hideImage ? "1" : "0.8" }} className="ps-right">
            <div
              style={{ flex: hideImage ? "1" : "0.6" }}
              className="ps-right-left"
            >
              {noLink ? (
                <h3 className="no-line">{name}</h3>
              ) : (
                <Tooltip placement="top" title="View Public Profile">
                  <h3
                    onClick={() =>
                      window.open(
                        `https://www.theugatour.com/players/${playerID}`,
                        "_blank"
                      )
                    }
                  >
                    {name} {false && "(Captain)"}
                  </h3>
                </Tooltip>
              )}

              {guestID !== "" && <p className="mb-5">ID: {guestID}</p>}

              {!hideHCP && (
                <div className="hcp-container">
                  <div className="svg-cont">
                    <GradientAddIcon
                      className="ps-icon"
                      style={{ color: "21C17C", height: "16px" }}
                    />
                  </div>

                  <p>{hcp >= 0 ? hcp : `+${hcp * -1}`} hcp</p>
                </div>
              )}
            </div>
            {hcpEdit && (
              <>
                <input
                  onChange={(e) => {
                    changeHcp(e.target.valueAsNumber);
                    setHandicap(e.target.valueAsNumber);
                  }}
                  // defaultValue={hcp}
                  value={handicap}
                  type="number"
                  className="default-input"
                />
              </>
            )}
            {score !== "" && (
              <div className="ps-right-middle">
                <h3>{score}</h3>
                <p>{ranking ? "UGA$" : label !== "" ? label : "Score"}</p>
              </div>
            )}
            {showFriends && (
              <div className="ps-right-right">
                <GroupIcon />
              </div>
            )}
            {showCheck && <CheckCircle className="dg-icon" />}
          </div>
          {!hideIcons && (
            <div className="pi-icons">
              {!hideMessage && (
                <GradientMailIcon
                  onClick={() =>
                    showClick({
                      userID: playerID,
                      playerID: playerID,
                      profileImage: img,
                      type: "chat",
                    })
                  }
                />
              )}
              {!event && !hideProfile && (
                <GradientProfileIcon
                  onClick={() =>
                    window.open(`https://theugatour.com/players/${playerID}`)
                  }
                />
              )}
              {!showAdd && <GradientAddPersonIcon onClick={addAction} />}
              {!hideEdit && <GradientEditIcon onClick={editAction} />}
              {!hideRemove && <GradientRemoveIcon onClick={removeAction} />}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PlayerItem;
