import React from "react";
import { Checkbox } from "@mui/material";

function PlayerListItem({ player = {}, selected = false, select = () => {} }) {
  return (
    <div className="player-list-item">
      <div className="pli-item">
        <p>{player.firstName}</p>
      </div>
      <div className="pli-item">
        <p>{player.lastName}</p>
      </div>
      <div className="pli-item">
        <p>
          {player.handicap < 0
            ? `+${Math.abs(player.handicap)}`
            : player.handicap}
        </p>
      </div>
      <div className="pli-item">
        <Checkbox checked={selected} onChange={select} />
      </div>
    </div>
  );
}

export default PlayerListItem;
