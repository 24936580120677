import React from "react";
import "./GroupPlayerScore.css";

// MUI
import Badge from "@mui/material/Badge";
import { Avatar } from "@mui/material";

function GroupPlayerScore({ name, hcpOne, hcpTwo, img, score, thru, rank }) {
  return (
    <div className="player-score">
      <div className="ps-left">
        <Badge color="default" badgeContent={rank}>
          <Avatar alt={name} src={""} />
        </Badge>
      </div>
      <div className="ps-right">
        <div className="ps-right-left">
          <h3>{name}</h3>
          <div className="hcp-container">
            {/* <StarIcon style={{ color: '21C17C', height:'16px'}} /> */}
            {/* <p>{hcpOne} hcp / {hcpTwo} hcp</p> */}
          </div>
        </div>
        <div className="ps-right-middle">
          <h3>{score === 0 ? "E" : score > 0 ? `+${score}` : score}</h3>
          <p>Score</p>
        </div>
        <div className="ps-right-right">
          <h3>{thru}</h3>
          <p>Thru</p>
        </div>
      </div>
    </div>
  );
}

export default GroupPlayerScore;
