import React from "react";

// Icons
import Edit from "@mui/icons-material/Edit";

function ContestPlayer({
  pos,
  name,
  attempts,
  score,
  showEdit = true,
  handleEdit = () => {},
}) {
  return (
    <div className="contest-player">
      <p className="cp-pos">{pos}</p>
      <p className="cp-name">{name}</p>
      <p className="cp-atts">{attempts}</p>
      <p className="cp-score">{score}</p>
      {showEdit && (
        <div className="cp-edit flex">
          <Edit onClick={handleEdit} className="dg-icon  icon-cursor hgb-ico" />
        </div>
      )}
    </div>
  );
}

export default ContestPlayer;
