import React from "react";
import { Backdrop, LinearProgress } from "@mui/material";

function BackdropProgress({ prog = 0, val = false }) {
  return (
    <div className="backdrop-loader backdrop-progress">
      <Backdrop
        // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div className="bp-progger">
          {/* <Avatar src={pin} alt="loader" /> */}
          <LinearProgress
            variant={val ? "determinate" : "indeterminate"}
            value={prog}
          />
        </div>
      </Backdrop>
    </div>
  );
}

export default BackdropProgress;
