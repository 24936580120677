import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

import { useAuth } from "../../contexts/AuthContext";
import PlayerLoader from "../../components/Loaders/PlayerLoader";
import PlayerScore from "../../components/display/PlayerScore/PlayerScore";

import TeamScorecard from "./TeamScorecard/TeamScorecard";

function TeamLeaderboard({ roundID }) {
  const { currentUser } = useAuth();

  const [board, setBoard] = useState([]);
  const [loading, setLoading] = useState(true);

  const [format, setFormat] = useState("scramble");

  const [roundFormat, setRoundFormat] = useState("");

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  useEffect(() => {
    if (currentUser) {
      loadBoard();
    }
  }, [currentUser]);

  function returnFormat(fmt) {
    if (fmt === "two") {
      return "Better Ball - Two to Count";
    }

    if (fmt === "bogey") {
      return "Bogey Plus";
    }

    if (fmt === "bogey++") {
      return "Bogey Double Plus";
    }

    if (fmt === "net-strokeplay") {
      return "Net Strokeplay";
    }
    if (fmt === "team-net-strokeplay") {
      return "Team Net Strokeplay";
    }
    if (fmt === "average-ball") {
      return "Average Ball";
    }
    if (fmt === "worst-ball") {
      return "Worst Ball";
    }
    if (fmt === "best-ball") {
      return "Best Ball";
    }
    if (fmt === "match-play") {
      return "Match Play";
    }
    if (fmt === "ab-match-play") {
      return "AB Match Play";
    }
    if (fmt === "bb-match-play") {
      return "BB Match Play";
    }
    if (fmt === "wb-match-play") {
      return "WB Match Play";
    }
    if (fmt === "stableford") {
      return "Stableford";
    }
    if (fmt === "mod-stableford") {
      return "Modified Stableford";
    }
    if (fmt === "skins-normal") {
      return "Normal Skins";
    }
    if (fmt === "skins-expo") {
      return "Expo Skins";
    }

    return fmt;
  }

  async function loadBoard() {
    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (roundDoc) => {
        // const arr = [];
        const round = roundDoc.data();
        let { teamLeaderboard, teamType, cup } = round;
        const leaderboard = round.leaderboard ?? [];
        teamType = round.cup ? "scramble" : teamType ?? round.format;
        let rdFormat = round.format;
        const playerMap = {};
        if (cup) {
          const { teams } = round;
          for (let i = 0; i < teams.length; i++) {
            const team = teams[i];
            for (let j = 0; j < team.players.length; j++) {
              const player = team.players[j];
              playerMap[player.playerID] = player.name;
            }
          }
        }
        setFormat(teamType);
        if (round.cup) {
          setRoundFormat("stableford");
          rdFormat = "stableford";
        } else {
          setRoundFormat(round.format);
        }
        for (let i = 0; i < teamLeaderboard.length; i++) {
          const team = teamLeaderboard[i];
          let name = "";
          for (let j = 0; j < team.playerIDs.length; j++) {
            if (cup) {
              const id = team.playerIDs[j];
              const pl = playerMap[id];
              if (j === team.playerIDs.length - 1) {
                name += pl;
              } else {
                name += `${pl} / `;
              }
            } else {
              for (let x = 0; x < leaderboard.length; x++) {
                if (leaderboard[x].playerID === team.playerIDs[j]) {
                  name += `${leaderboard[x].lastName} / `;
                }
              }
            }
          }
          // name = name.slice(0, -3);
          teamLeaderboard[i].name = name;
        }
        if (rdFormat === "stableford") {
          if (teamType === "two") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];
              const { thru, score } = row;

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { lowest, secondLowest } = hole;
                if (lowest !== "") {
                  const lowestPoints = 2 - lowest;
                  totalPoints += lowestPoints;
                }
                if (secondLowest !== "") {
                  const secondLowestPoints = 2 - secondLowest;

                  totalPoints += secondLowestPoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "scramble") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { best } = hole;
                if (best !== "") {
                  const lowestPoints = 2 - best;
                  totalPoints += lowestPoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }

          if (teamType === "custom") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { score, toCount, scores } = hole;
                let counter = toCount;
                if (counter > scores.length) {
                  counter = scores.length;
                }
                for (let g = 0; g < counter; g++) {
                  const score = scores[g];
                  if (score !== "") {
                    const points = 2 - score;
                    totalPoints += points;
                  }
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }

          const sortedBoard = teamLeaderboard.sort((a, b) => b.score - a.score);
          setBoard(sortedBoard);
        } else if (rdFormat === "mod-stableford") {
          if (teamType === "two") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];
              const { thru, score } = row;

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { lowest, secondLowest } = hole;
                if (lowest !== "") {
                  const lowestPoints = 2 - lowest;
                  let scorePoints = 0;
                  switch (lowest) {
                    case lowest > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case lowest < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
                if (secondLowest !== "") {
                  const secondLowestPoints = 2 - secondLowest;
                  let scorePoints = 0;
                  switch (secondLowest) {
                    case secondLowest > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case secondLowest < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "scramble") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { best } = hole;
                if (best !== "") {
                  const lowestPoints = 2 - best;
                  let scorePoints = 0;
                  switch (best) {
                    case best > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case best < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          if (teamType === "custom") {
            for (let j = 0; j < teamLeaderboard.length; j++) {
              const row = teamLeaderboard[j];

              let totalPoints = 0;

              for (let x = 0; x < row.holes.length; x++) {
                const hole = row.holes[x];
                const { score } = hole;
                if (score !== "") {
                  let scorePoints = 0;
                  switch (score) {
                    case score > 2:
                      scorePoints = -5;
                      break;
                    case 2:
                      scorePoints = -3;
                      break;
                    case 1:
                      scorePoints = -1;
                      break;
                    case 0:
                      break;
                    case -1:
                      scorePoints = 2;
                      break;
                    case -2:
                      scorePoints = 5;
                      break;
                    case score < -2:
                      scorePoints = 8;
                      break;
                    default:
                      break;
                  }
                  totalPoints += scorePoints;
                }
              }

              teamLeaderboard[j].score = totalPoints;
            }
          }
          const sortedBoard = teamLeaderboard.sort((a, b) => b.score - a.score);
          setBoard(sortedBoard);
        } else {
          if (format !== "bogey" && format !== "bogey++") {
            const sortedBoard = teamLeaderboard.sort(
              (a, b) => a.score - b.score
            );
            setBoard(sortedBoard);
          } else {
            const sortedBoard = teamLeaderboard.sort(
              (a, b) => a.score - b.score
            );
            setBoard(sortedBoard);
            // setBoard(teamLeaderboard);
          }
        }

        setLoading(false);
      });
  }

  return (
    <div className="team-leaderboard">
      {show ? (
        toShow
      ) : (
        <>
          {/* <div className="pp-header mt-20" style={{ paddingLeft: "10px" }}>
            <h2 className="ne-header">Team Leaderboard</h2>
            <p>{returnFormat(format)}</p>
          </div>
          <div className="leaderboard-message pl-20">
            <p>Tap on a group's name to view more</p>
          </div> */}
          {loading && (
            <div className="ri-loaders">
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
              <PlayerLoader />
            </div>
          )}
          {/* <div className="tl-header">
            <PlayerScore
              hideImage
              name="Name"
              rank={"Pos"}
              header
              // format={"medal"}
              thru={"Thru"}
              score={format.includes("stable") ? "Points" : "Score"}
            />
          </div> */}

          <div className="team-board-box">
            {board.map((team, index) => (
              <PlayerScore
                key={index}
                hideImage
                hideHcp
                // showBadge
                team
                bogey={format === "bogey" || format === "bogey++"}
                showRound={() => {
                  setToShow(
                    <TeamScorecard
                      group={team}
                      roundFormat={roundFormat}
                      format={format}
                      goBack={() => setShow(false)}
                      roundID={roundID}

                      // eventName={}
                    />
                  );
                  setShow(true);
                }}
                showBg={team.playerIDs.includes(currentUser.uid)}
                score={
                  roundFormat.includes("stableford") ||
                  format === "bogey" ||
                  format === "bogey++"
                    ? team.score
                    : team.score > 0
                    ? `+${team.score}`
                    : team.score
                }
                rank={index + 1}
                format={roundFormat}
                // hideThru
                thru={team.thru}
                name={team.name}
                points={format.includes("stable")}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default TeamLeaderboard;
