import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../../firebase";
import { useDropzone } from "react-dropzone";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import Pill from "../../Buttons/Pill";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

function TVBoardSettings({ eventID = "", goBack = () => {} }) {
  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [imgageURL, setImageURL] = useState("");
  const [settings, setSettings] = useState({
    slide: true,
    selectedDivs: [],
    timer: 20,
    displayType: "detailed",
    selectedPage: {
      value: "overall",
      label: "Overall",
    },
  });
  const [upd, setUpdate] = useState(0);
  function update() {
    setUpdate((c) => c + 1);
  }
  const [availableDivs, setAvailableDivs] = useState([]);
  const [selDivs, setSelDivs] = useState([]);
  const [tbDivs, setTBDivs] = useState([]);

  // UE
  useEffect(() => {
    loadDetails();
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Funcs
  async function loadDetails() {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const { activeRoundID, enableDivisions } = event;
    const prizes = event.prizes ?? [];
    const sponsors = event.sponsors ?? [];

    setImageURL(event.boardImage ?? "");

    const roundDoc = await db.collection("rounds").doc(activeRoundID).get();
    const round = roundDoc.data();

    const divs = [
      {
        label: "Overall",
        value: "overall",
      },
      {
        label: "Tee Times",
        value: "teeTimes",
      },
    ];

    if (round.enableTeams) {
      divs.push({
        label: "Teams",
        value: "teams",
      });
    }

    if (round.ryderCup) {
      divs.push({
        label: "Ryder Cup",
        value: "ryder",
      });
    }

    if (prizes.length > 0) {
      divs.push({
        value: "prizes",
        label: "Prizes",
      });
    }

    if (round.jackpot) {
      divs.push({
        label: "Jackpot",
        value: "jackpot",
      });
    }

    if (enableDivisions) {
      divs.push({
        label: "Division Highlights",
        value: "div-highlights",
      });

      const tbds = [];

      for (let i = 0; i < round.divisions.length; i++) {
        const div = round.divisions[i];
        const divBoard = round[div.leaderboardID];
        // divMap[div.leaderboardID] = divBoard;

        if (div.playerType === "teams") {
          tbds.push(div.leaderboardID);
        }

        divs.push({
          label: div.divisionName,
          value: div.leaderboardID,
          teamLeaderboard: div.playerType === "teams",
        });
        // if (i === 0) {
        //   divs.push({
        //     label: div.divisionName,
        //     value: div.leaderboardID,
        //   });
        // }
      }

      setTBDivs(tbds);
    }

    divs.push({
      label: "Holes",
      value: "holes",
    });
    if (sponsors.length > 0) {
      divs.push({
        label: "Sponsors",
        value: "sponsors",
      });
    }

    if (round.twoClub) {
      divs.push({
        label: "Two Club",
        value: "twoClub",
      });
    }
    if (event.eclectic) {
      divs.push({
        label: "Eclectic",
        value: "eclectic",
      });
    }

    if (event.completed) {
      divs.push({
        label: "Stats",
        value: "stats",
      });
    }

    if (event.tvBoardSettings) {
      setSettings(event.tvBoardSettings);
      const selectedDivs = event.tvBoardSettings.selectedDivs ?? [];
      console.log(selectedDivs);
      setSelDivs(selectedDivs);
    } else {
      setSettings({
        slide: true,
        selectedDivs: divs,
        timer: 20,
        displayType: "detailed",
        selectedPage: "overall",
      });
      setSelDivs(divs);
    }

    setAvailableDivs(divs);
    setLoading(false);
  }

  async function saveChanges() {
    // return console.log(selDivs);
    setLoading(true);

    try {
      settings.selectedDivs = selDivs;

      if (settings.selectedPage) {
        if (tbDivs.includes(settings.selectedPage.value)) {
          settings.selectedPage.teamLeaderboard = true;
        } else {
          settings.selectedPage.teamLeaderboard = false;
        }
      }

      console.log(settings, tbDivs);
      await db.collection("events").doc(eventID).update({
        tvBoardSettings: settings,
      });
      if (image !== "") {
        const task = storage.ref().child(`${eventID}/${image.name}`).put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            db.collection("events").doc(eventID).update({
              boardImage: url,
            });
          });
        });
      }
      toast.success("Settings successfully changed", {
        icon: successIco,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again"
      );
    }
    setLoading(false);
  }

  return (
    <div className="tv-board-setts">
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="TV Board Settings" />

      <div>
        <div className="ec-box">
          <div>
            <div className="neh-box pl-0">
              <h3 className="ne-header">Leaderboard Type</h3>
            </div>
            <div className="sd-group">
              <div className="selector-group sg-small">
                <button
                  onClick={() => {
                    setSettings((c) => {
                      c.slide = false;
                      return c;
                    });
                    update();
                  }}
                  className={!settings.slide ? "sg-left sg-active" : "sg-left"}
                >
                  Static
                </button>
                <button
                  onClick={() => {
                    setSettings((c) => {
                      c.slide = true;
                      return c;
                    });
                    update();
                  }}
                  className={settings.slide ? "sg-right sg-active" : "sg-right"}
                >
                  Dynamic
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="neh-box pl-0">
              <h3 className="ne-header">Display Type</h3>
            </div>
            <div className="sd-group">
              <div className="selector-group sg-small">
                <button
                  onClick={() => {
                    setSettings((c) => {
                      c.displayType = "detailed";
                      return c;
                    });
                    update();
                  }}
                  className={
                    settings.displayType === "detailed"
                      ? "sg-left sg-active"
                      : "sg-left"
                  }
                >
                  Detailed
                </button>
                <button
                  onClick={() => {
                    setSettings((c) => {
                      c.displayType = "simple";
                      return c;
                    });
                    update(); // handleSlide();
                  }}
                  className={
                    settings.displayType === "simple"
                      ? "sg-right sg-active"
                      : "sg-right"
                  }
                >
                  Simple
                </button>
              </div>
            </div>
          </div>
          {settings.slide && (
            <div>
              {false && (
                <div className="neh-box pl-0">
                  <h3 className="ne-header"></h3>
                </div>
              )}
              <div className="input-group ml-20">
                <p>Timer (seconds)</p>
                <input
                  type="number"
                  min={0}
                  value={settings.timer}
                  onChange={(e) => {
                    setSettings((c) => {
                      c.timer = e.target.value;
                      return c;
                    });
                    update();
                    // handleSlide();
                  }}
                  className="default-input"
                />
              </div>
            </div>
          )}
        </div>

        {settings.slide && (
          <>
            <div className="neh-box mt-20">
              <h3 style={{ fontSize: "13px" }} className="ne-header msb">
                Selected Options
              </h3>
            </div>
            <div className="fb-div-options">
              {availableDivs.map((div, index) => {
                if (div.label === "Comissioner") {
                  return null;
                }

                const mepped = selDivs.map((val) => val.value);
                const sel = mepped.includes(div.value);
                return (
                  <div
                    onClick={() => {
                      if (sel) {
                        setSelDivs((current) => {
                          const filt = current.filter(
                            (item) => item.value !== div.value
                          );
                          return filt;
                        });
                      } else {
                        setSelDivs((current) => {
                          current.push(div);
                          return current;
                        });
                      }

                      setUpdate((current) => current + 1);
                    }}
                    className={sel ? "div-opt do-active" : "div-opt"}
                    key={`${index}divopt`}
                  >
                    <p>{div.label}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {!settings.slide && (
          <div className="pl-20">
            <div className="neh-box pl-0">
              <h2 style={{ fontSize: "13px" }} className="ne-header">
                Active Leaderboard
              </h2>
            </div>
            <div className="sd-group">
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={settings.selectedPage}
                onChange={(e) => {
                  setSettings((c) => {
                    c.selectedPage = e;
                    console.log(e);
                    return c;
                  });
                  update();
                }}
                options={availableDivs}
              />
            </div>
          </div>
        )}

        <div style={{ padding: "0px 20px" }} className="input-group mb-20">
          <p>Background Image</p>
          <div className="image-buttons">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dz-sel">
                {imgageURL !== "" && (
                  <div className="event-img mt-0">
                    <img className="" alt="profile" src={imgageURL} />
                  </div>
                )}
                {imgageURL === "" && (
                  <h5>
                    Drag 'n' drop an image here, or click to open up the file
                    explorer
                  </h5>
                )}
              </div>
            </div>
            <div className="mt-20">
              <Pill
                text={"Remove"}
                onClick={() => {
                  setImage("");
                  setImageURL("");
                }}
                red
              />
            </div>
            {/* <div className="ec-is" style={{ marginLeft: "0px" }}>
                    <button className="" onClick={() => setShowImages(true)}>
                      Choose Preset Image
                    </button>
                  </div> */}
          </div>
        </div>
      </div>

      <div className="flex-center mt-20 mb-40">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default TVBoardSettings;
