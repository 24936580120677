import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase";
import { useDropzone } from "react-dropzone";

// Icons

// Components
import Papa from "papaparse";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackRow from "../../components/display/Rows/BackRow";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { CSVDownload, CSVLink } from "react-csv";
import { Dialog } from "@mui/material";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";

function HandicapUpload({ clubID = "", players = [], goBack = () => {} }) {
  // State

  const [loading, setLoading] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [members, setMembers] = useState(players);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [error, setError] = useState("");
  const [upload, setUpload] = useState([]);
  const [uploadJSON, setUploadJSON] = useState([]);
  const [complete, setComplete] = useState(false);
  const [uploadName, setUploadName] = useState("");
  const [selPlat, setSetPlat] = useState("");
  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((c) => c + 1);
  }
  const [example, setExample] = useState([
    ["platform", "platformID", "handicap", "firstName", "lastName"],
  ]);

  const [showCSV, setShowCSV] = useState(false);

  const [showDia, setShowDia] = useState(false);

  useEffect(() => {
    if (showCSV) {
      setTimeout(() => setShowCSV(false), 1000);
    }
  }, [showCSV]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadName(file.name);
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        const arr = [];
        const headerRow = res.data[0];
        let plCol = "";
        let idCol = "";
        let hcpCol = "";
        let lowHICol = "";
        let firstNameColl = "";
        let lastNameColl = "";
        console.log(headerRow);
        for (let i = 0; i < headerRow.length; i++) {
          if (headerRow[i] === "platform") {
            plCol = i;
          } else if (headerRow[i] === "platformID") {
            idCol = i;
          } else if (headerRow[i] === "handicap") {
            hcpCol = i;
          } else if (headerRow[i] === "firstName") {
            firstNameColl = i;
          } else if (headerRow[i] === "lastName") {
            lastNameColl = i;
          } else if (headerRow[i] === "lowHI") {
            lowHICol = i;
          }
        }

        console.log(lowHICol);

        if (plCol === "" || idCol === "") {
          return setError(
            "Sorry, we couldn't quite sort that out - please check your column headers!"
          );
        }

        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];
          let hcp = row[hcpCol] ?? "";
          if (hcp !== "") {
            hcp = parseFloat(hcp);
          }

          let lowHI = row[lowHICol] ?? "";
          if (lowHI !== "") {
            lowHI = parseFloat(lowHI);
          }

          arr.push({
            platform: row[plCol],
            platformID: row[idCol],
            handicap: row[hcpCol] ?? "",
            lowHI,
            name: `${row[firstNameColl] ?? ""} ${row[lastNameColl] ?? ""}`,
            firstName: row[firstNameColl] ?? "",
            lastName: row[lastNameColl] ?? "",
          });
        }
        console.log(arr);
        setUpload(res);
        setUploadJSON(arr);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  // UE

  useEffect(() => {
    filterPlatforms();
  }, []);

  useEffect(() => {
    convertToCSV();
  }, []);

  // Funcs

  function convertToCSV() {
    const csvArr = [];
    const platforms = [];
    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const { platformIDs } = player;
      for (let x = 0; x < platformIDs.length; x++) {
        const pl = platformIDs[x];
        const { platform } = pl;
        if (!platforms.includes(platform)) {
          platforms.push(platform);
        }
      }
    }
    // console.log(platforms);
    csvArr.push(["firstName", "lastName"]);
    for (let i = 0; i < platforms.length; i++) {
      csvArr[0].push(platforms[i]);
    }
    // console.log(csvArr);
    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const { firstName, lastName } = player;
      if (i === 1) {
        // console.log(player);
      }
      const plarr = [firstName, lastName];
      const { platformIDs } = player;
      for (let x = 0; x < platforms.length; x++) {
        const defPlatform = platforms[x];
        let fd = false;
        for (let j = 0; j < platformIDs.length; j++) {
          const pl = platformIDs[j];
          const { platform, platformID } = pl;

          if (defPlatform === platform) {
            plarr.push(platformID);
            fd = true;
          }
        }
        if (!fd) {
          plarr.push("");
        }
      }
      if (i < 10) {
        // console.log(plarr);
      }
      csvArr.push(plarr);
    }

    csvArr[0].push("platform", "platformID", "handicap", "lowHI");
    // console.log(csvArr);
    setCSVData(csvArr);
  }

  function filterPlatforms() {
    const arr = ["Custom"];
    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const platformIDs = player.platformIDs ?? [];
      for (let j = 0; j < platformIDs.length; j++) {
        const { platform } = platformIDs[j];
        if (!arr.includes(platform)) {
          arr.push(platform);
        }
      }
    }
    arr.sort((a, b) => a - b);
    // console.log(arr);
    setPlatformOptions(arr);
  }

  async function uploadHandicaps() {
    // return console.log(uploadJSON);
    setLoading(true);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const clubData = clubDoc.data();
      const { memberIDs } = clubData;
      const refs = memberIDs.map((id, index) => {
        // console.log(index);
        return db.collection("users").doc(id).get();
      });
      const docs = await Promise.all(refs);
      for (let i = 0; i < docs.length; i++) {
        const playerDoc = docs[i];
        const player = playerDoc.data();
        if (!playerDoc.exists) {
          continue;
        }

        const platformIDs = player.platformIDs ?? [];
        for (let x = 0; x < platformIDs.length; x++) {
          const plRow = platformIDs[x];
          const rowPlatform = plRow.platform;
          const rowPlatformID = plRow.platformID;

          for (let j = 0; j < uploadJSON.length; j++) {
            const row = uploadJSON[j];

            const { platform, platformID, handicap, lowHI } = row;

            if (rowPlatform === platform && rowPlatformID === platformID) {
              console.log(row);

              if (handicap !== "" && lowHI !== "") {
                await playerDoc.ref.update({
                  handicap,
                  lowHI,
                });
              }

              if (handicap !== "" && lowHI === "") {
                await playerDoc.ref.update({
                  handicap,
                });
              }
              if (handicap === "" && lowHI !== "") {
                await playerDoc.ref.update({
                  lowHI,
                });
              }

              break;
            }
          }
        }
      }
      setComplete(true);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }

    setLoading(false);
  }

  return (
    <div className="hcp-upload">
      <Dialog open={showDia} onClose={() => setShowDia(false)}>
        <div className="hu-dia pd-20">
          <PageHeader
            close={() => setShowDia(false)}
            showClose
            text="Select Platform"
          />
          <div className="hu-plats pd-20">
            {platformOptions.map((platform, i) => {
              return (
                <PlayerItem
                  showBg={selPlat === platform}
                  key={platform}
                  hideHCP
                  hideIcons
                  noLink
                  hideImage
                  name={platform}
                  showProfile={() => {
                    // console.log(players);

                    const arr = [
                      [
                        "firstName",
                        "lastName",
                        "handicap",
                        "lowHI",
                        "platform",
                        "platformID",
                      ],
                    ];

                    for (let i = 0; i < players.length; i++) {
                      const pl = players[i];
                      const { firstName, lastName, platformIDs } = pl;
                      for (let j = 0; j < platformIDs.length; j++) {
                        const pl = platformIDs[j];
                        if (pl.platform === platform) {
                          arr.push([
                            firstName,
                            lastName,
                            "",
                            "",
                            pl.platform,
                            pl.platformID,
                          ]);
                          break;
                        }
                      }
                    }

                    console.log(arr);
                    setExample(arr);

                    setSetPlat(platform);
                    upd();
                  }}
                />
              );
            })}
          </div>

          <div className="flex-center mt-20 mb-40">
            <button
              onClick={() => {
                setShowCSV(false);
                setShowDia(false);
                upd();
                setShowCSV(true);
              }}
              className="default-button"
            >
              Download Template
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <BackRow action={goBack} />
      <PageHeader text="Handicap Upload" />
      <div className="ph-msg">
        <p>Update your members' handicaps with a simple CSV upload.</p>
      </div>
      {complete ? (
        <>
          {" "}
          <div className="erp-finished">
            <div className="erpc-msg">
              <p>
                The players' handicaps were successfully edited. Changes will
                reflect in a few moments.
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mt-20">
            {/* <CSVLink data={csvData} className="default-button csv-sb mt-40 ml-20">
          Download Players
        </CSVLink> */}
            <button
              onClick={() => setShowDia(true)}
              className="default-button csv-sb mt-40 ml-20"
            >
              Download Template
            </button>
          </div>
          <div className="bi-box">
            <div className="pd-20 bi-drop">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-sel">
                  {uploadName ? (
                    <h5 className="filename">{uploadName}</h5>
                  ) : (
                    <h5>
                      Drag 'n' drop a .csv file here, or click to open up the
                      file explorer
                    </h5>
                  )}
                </div>
              </div>
            </div>
            <div className="bi-info">
              <p>
                In order for the players to be added correctly, formatting is
                important but simple! Only a few fields are needed, the platform
                name and the relevant platform ID, as well as other optional
                fields.
                <br />
                <br />
                <strong>
                  The first row (header row) will be included.
                </strong>{" "}
                Name your colums as follows (letter case is important):
              </p>
              <ul>
                <li>platform</li>
                <li>platformID</li>
                <li>handicap</li>
                <li>lowHI</li>
                <li>firstName</li>
                <li>lastName</li>
              </ul>
              {false && (
                <p>
                  Any handicaps or lowHI values that are left blank will be
                  ignored.
                </p>
              )}
            </div>
          </div>

          {showCSV && <CSVDownload data={example} target="_blank" />}

          {uploadJSON.length > 0 && (
            <>
              <div className="hu-players pd-20">
                <div className="hup-head hup-row">
                  <p className="hh-name">Player</p>
                  <p className="hh-hcp">Handicap</p>
                  <p className="hh-hcp">Low HI</p>
                </div>
                {uploadJSON.map((player, i) => {
                  return (
                    <div key={i} className="hup-row">
                      <p className="hh-name">
                        {player.firstName} {player.lastName}
                      </p>
                      <p className="hh-hcp">
                        {player.handicap < 0
                          ? `+${Math.abs(player.handicap)}`
                          : player.handicap}
                      </p>
                      <p className="hh-hcp">
                        {player.lowHI < 0
                          ? `+${Math.abs(player.lowHI)}`
                          : player.lowHI}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className="flex-center mb-40 mt-20">
                <button onClick={uploadHandicaps} className="default-button">
                  Save Changes
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default HandicapUpload;
