import { Close } from "@mui/icons-material";
import React from "react";

function BISmallPlayer({ player = {}, removeAct = () => {} }) {
  return (
    <div className="bis-player">
      <div className="bips-item">
        <p>{player.name}</p>
      </div>
      <div className="bips-item">
        <p>{player.platformID}</p>
      </div>
      <div className="bips-item">
        <Close onClick={removeAct} className="icon-cursor red-icon" />
      </div>
    </div>
  );
}

export default BISmallPlayer;
