import React, { useRef, useEffect, useState } from "react";
import "./Footer.css";
import { db } from "../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
function Footer() {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  const inputRef = useRef();

  const location = useLocation();

  async function saveEmail() {
    const emailAddress = inputRef.current.value;
    if (emailAddress === "") {
      return;
    }
    db.collection("prereg").add({
      date: new Date(),
      emailAddress,
    });
    setSubmitted(true);
  }

  return (
    <>
      {!location.pathname.includes("contestboards/") && (
        <div className="footer">
          <div className="footer-left">
            <div className="fr-header">
              <h2>Download the app</h2>
            </div>
            <div className="fl-reg ">
              <div className="ib-buttons">
                <button
                  style={{ textTransform: "none" }}
                  className="default-button mb-10"
                  onClick={() =>
                    window.open("https://uga-tour.web.app", "_blank")
                  }
                >
                  iOS
                </button>
                <button
                  className="default-button"
                  onClick={() =>
                    window.open("https://uga-tour.web.app", "_blank")
                  }
                >
                  Android
                </button>
              </div>
              {/* {!submitted ? (
              <>
                <input
                  ref={inputRef}
                  placeholder="email address"
                  type={"email"}
                />
                <div className="ib-buttons">
                  <button onClick={saveEmail}>Submit</button>
                </div>
              </>
            ) : (
              <>
                <p>
                  Welcome to the family! Keep an eye out for us in your inbox.
                </p>
              </>
            )} */}
            </div>
          </div>
          <div className="footer-middle">
            <div className="fr-header">
              <h2>The Nitty Gritty</h2>
            </div>
            <p
              className="mb-10 mt-10 link-pee"
              onClick={() => navigate("/terms-conditions")}
            >
              Terms & Conditions
            </p>
            <p
              className="link-pee mb-10"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </p>
            <p className="link-pee mb-20" onClick={() => navigate("/fair-use")}>
              Fair Use Policy
            </p>
            <p
              className="link-pee "
              onClick={() => navigate("https://yenzatech.co.za/", "_blank")}
            >
              www.yenzatech.co.za
            </p>
            <p className="mt-10 mb-20">© Yenza Tech, 2024</p>
          </div>
          <div className="footer-right">
            <div className="fr-header">
              <h2>Social Media</h2>
            </div>
            <div className="footer-logos mb-20">
              <InstagramIcon
                onClick={() =>
                  window.open("https://www.instagram.com/theugatour/", "_blank")
                }
                className="footer-logo"
              />
              <FacebookIcon
                onClick={() =>
                  window.open("https://www.facebook.com/theugatour", "_blank")
                }
                className="footer-logo"
              />
              <YouTubeIcon
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCzHJzG2yrol_AzZfYkLOEqQ",
                    "_blank"
                  )
                }
                className="footer-logo"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
