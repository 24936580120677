import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import ContestBoardRow from "./ContestBoardRow";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

// Icons

// Components
import PlayerScore from "../../components/display/PlayerScore/PlayerScore";
import ContestPlayer from "./ContestPlayer";

function ContestBoard({ hideMenu = () => {} }) {
  // State
  const [board, setBoard] = useState([]);
  const [update, setUpdate] = useState(0);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState({});

  const [name, setName] = useState("");

  const location = useLocation();

  const { contestID } = useParams();

  useEffect(() => {
    console.log(contestID);
    loadBoard(contestID);
  }, []);

  async function loadBoard(contestID) {
    const cd = await db.collection("contests").doc(contestID).get();
    const cData = cd.data();

    const { playerIDs, playerEntries, leaderboard } = cData;

    setName(cData.name);

    const refs = playerIDs.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(refs);

    const players = {};

    for (let i = 0; i < docs.length; i++) {
      const playerID = playerIDs[i];
      const playerDoc = docs[i];
      const player = playerDoc.data();
      const playerName = `${player.firstName} ${player.lastName}`;
      const filt = playerEntries.filter((uid) => uid === player.playerID);
      const { handicap, profileImage, activeRoundID, status } = player;
      const city = player.city ?? "Cape Town";
      const country = player.country ?? "South Africa";
      const defmap = {
        attempts: [],
        best: "",
      };
      const row = leaderboard[playerID] ?? defmap;

      players[playerID] = {
        name: playerName,
        handicap,
        profileImage,
        playerID,
        city,
        entries: filt.length + 1,
        country,
        lastName: player.lastName,
      };
    }

    db.collection("contests")
      .doc(contestID)
      .onSnapshot(async (snap) => {
        const arr = [];
        const contest = snap.data();
        const { leaderboard, prizeType, percs, prizes, entrance, playerIDs } =
          contest;

        const purse = entrance * playerIDs.length;
        console.log(purse);

        for (const [key, value] of Object.entries(leaderboard)) {
          const player = players[key];
          const comb = { ...value, ...player };
          arr.push(comb);
        }
        const sorted = arr.sort((a, b) => {
          if (a.best === "") {
            return 1;
          }
          if (b.best === "") {
            return -1;
          }
          if (a.best < b.best) {
            return -1;
          } else {
            return 1;
          }
        });

        for (let i = 0; i < 3; i++) {
          if (sorted[i] === undefined) {
            continue;
          }

          if (prizeType === "accum") {
            const perc = parseInt(percs[i]) / 100;
            console.log(perc);
            const prize = purse * perc;
            if (prize !== 0) {
              sorted[i].prize = prize;
            }
          } else {
            const prize = prizes[i];
            if (prize !== 0) {
              sorted[i].rounds = prize;
            }
          }
        }

        setPlayers(players);
        setBoard(sorted);
        setLoading(false);
      });
  }

  return (
    <div className="contest-board">
      {loading && <BackdropLoader />}
      {show ? (
        toShow
      ) : (
        <>
          <div className="page-header">
            <h2 className="">{name}</h2>
          </div>
          <div className="ph-msg">
            <p>Leaderboard</p>
          </div>
          <div
            className="leaderboard-players aci-players"
            style={{ width: "95%" }}
          >
            <div className="contest-player contest-player-header">
              <p className="cp-pos">POS</p>
              <p className="cp-name">NAME</p>
              <p className="cp-pos">ATTEMPTS</p>
              <p className="cp-pos">BEST</p>
            </div>
            {board.map((player, index) => (
              <ContestPlayer
                name={player.name}
                key={player.playerID}
                pos={index + 1}
                attempts={player.attempts.length}
                score={player.best}
                showEdit={false}
                handleEdit={() => {}}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ContestBoard;
