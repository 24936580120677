import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Icons

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import Pill from "../../components/Buttons/Pill";
import CourseItem from "../../components/display/CourseItem/CourseItem";
import MatchplayTournamentHome from "../MatchplayTournaments/MatchplayTournamentHome";
import MatchPlayTournamentEditor from "../MatchplayTournaments/MatchPlayTournamentEditor";
import BackRow from "../../components/display/Rows/BackRow";
import PageHeader from "../../components/display/Rows/PageHeader";

function ClubTournaments({ goBack = () => {} }) {
  const navigate = useNavigate();
  const { portalSettings } = useAuth();

  // State
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clubID, setClubID] = useState("");

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const { id } = useParams();

  useEffect(() => {
    setClubID(id);
    loadMatchplayTournaments({ clubID: id });
  }, []);

  async function loadMatchplayTournaments({ clubID = "" }) {
    setLoading(true);
    const leagues = await db
      .collection("tournaments")
      .where("clubID", "==", clubID)
      .get();

    const arr = [];
    console.log(clubID, leagues.docs.length);
    for (let i = 0; i < leagues.docs.length; i++) {
      const tournDoc = leagues.docs[i];
      const tournament = tournDoc.data();
      const tournID = tournDoc.id;

      const { tournamentName, imageLink, country, city } = tournament;
      const dateCreated = new Date(tournament.dateCreated.toDate());
      arr.push({
        country,
        city,
        tournamentName,
        tournID,
        imageLink,
        dateCreated,
      });
    }

    arr.sort((a, b) => b.dateCreated - a.dateCreated);

    setTournaments(arr);
    setLoading(false);
  }

  return (
    <div className="matchplay-tournaments">
      {loading && <BackdropLoader />}

      {show ? (
        toShow
      ) : (
        <>
          {/* <BackRow action={() => navigate(-1)} /> */}
          <PageHeader text="Matchplay Tournaments" />
          <div className="sub-header">
            <h3>Quick Links</h3>
          </div>
          <div className="quick-links">
            <Pill
              text={"New Tournament"}
              onClick={() => {
                setToShow(
                  <MatchPlayTournamentEditor
                    goBack={() => setShow(false)}
                    reload={loadMatchplayTournaments}
                    defaultClubID={portalSettings && portalSettings.clubID}
                    edit={false}
                  />
                );
                setShow(true);
              }}
            />
          </div>

          <div className="mt-items">
            {tournaments.map((tourn, index) => (
              <Link key={tourn.tournID} to={`/tournaments/${tourn.tournID}`}>
                <CourseItem
                  city={tourn.city}
                  country={tourn.country}
                  courseName={tourn.tournamentName}
                  hideStar
                  image={tourn.imageLink}
                  showCourse={() => {
                    // setToShow(
                    //   <MatchplayTournamentHome
                    //     tournID={tourn.tournID}
                    //     goBack={() => setShow(false)}
                    //     reload={() => loadMatchplayTournaments({ clubID })}
                    //   />
                    // );
                    // setShow(true);
                  }}
                />
              </Link>
            ))}
            {tournaments.length === 0 && !loading && (
              <div className="no-msg">
                <p>No tournaments to display</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ClubTournaments;
