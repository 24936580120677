/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./SeasonEditor.css";
import { db, admin, storage } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useDropzone } from "react-dropzone";
import { BallTriangle } from "react-loader-spinner";
import InfoIcon from "@mui/icons-material/Info";
import { Steps } from "intro.js-react";
import "react-dropdown/style.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Backdrop, Dialog } from "@mui/material";
import ClubCourse from "../../../pages/Clubs/ClubCourse";
import CoursePicker from "../../Events/CoursePicker/CoursePicker";
import { Close, Edit } from "@mui/icons-material";
// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import ImageSelector from "../../Events/ImageSelector/ImageSelector";
import BackdropLoader from "../../Loaders/BackdropLoader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import Pill from "../../Buttons/Pill";

import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import PageHeader from "../../display/Rows/PageHeader";

const GradientInfoIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <InfoIcon
      onClick={onClick}
      className={`dg-icon-bg ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const pointsOptions = [
  {
    label: "Tour",
    value: "tour",
  },
  {
    label: "Stableford",
    value: "stableford",
  },
];

function SeasonEditor({
  goBack,
  reload,
  edit,
  leagueID,
  seasonID = "",
  seasons = [],
  defaultSeasonIndex,
  eclectic = false,
}) {
  const [generalOpen, setGeneralOpen] = useState(true);

  const { onboarding, setOnboard } = useAuth();
  useEffect(() => {
    if (onboarding) {
      if (onboarding.seasonCreator === false) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);
  const picScrollRef = useRef();

  const nameRef = useRef();
  const trophyRef = useRef();
  const dateRef = useRef();
  const minRef = useRef();
  const rankRef = useRef();
  const [image, setImage] = useState("");
  const [error, setError] = useState("");
  const [loadingText, setLoadingText] = useState("Saving...");
  const [loading, setLoading] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [imgageURL, setImageURL] = useState("");

  const [defName, setDefName] = useState("");
  const [defTrophy, setDefTrophy] = useState("");
  const [defDate, setDefDate] = useState();
  const [defMin, setDefMin] = useState(0);
  const [defRank, setDefRank] = useState(0);
  const [pointsSystem, setPointsSystem] = useState("tour");

  const [show, setShow] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [hintText, setHintText] = useState("");
  const [currentSeasonID, setCurrentSeasonID] = useState("");

  const [leagueName, setLeagueName] = useState("");
  const [seasonIndex, setSeasonIndex] = useState(defaultSeasonIndex);
  const [started, setStarted] = useState(false);
  const [seasonIDs, setSeasonIDs] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [eventIDs, setEventIDs] = useState([]);
  const [courseID, setCourseID] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseImage, setCourseImage] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  const [showPicker, setShowPicker] = useState(false);

  const [showCrop, setShowCrop] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});

  async function cropImage() {
    const image = new Image();
    image.src = imgageURL;
    const crop = fc;
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }

  useEffect(() => {
    if (edit) {
      loadSeason();
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    setImage(acceptedFiles[0]);
    setShowCrop(true);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  async function checkCurrentSeason(startDate) {
    console.log(currentSeasonID);
    const events = await db
      .collection("events")
      .where("seasonID", "==", currentSeasonID)
      .where("eventDate", ">", startDate)
      .where("cancelled", "==", "false")
      .limit(1)
      .get();
    console.log(events.docs.length);
    if (events.docs.length === 1) {
      const evt = events.docs[0].data();
      const eventID = events.docs[0].id;
      console.log(eventID);
      const eventDate = new Date(evt.eventDate.toDate());
      console.log(eventDate);
      setError("A new season cannot end before the current one is completed");
      return false;
    }
  }

  async function loadSeason() {
    setLoading(true);
    const seasonDoc = await db.collection("seasons").doc(seasonID).get();
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const season = seasonDoc.data();
    const league = leagueDoc.data();
    const activeSeason = league.seasonID;
    const { seasonIDs } = league;
    const {
      imageLink,
      completed,
      eventIDs,
      leagueName,
      minEvents,
      rankCutoff,
      seasonIndex,
      startDate,
      started,
      trophyName,
      seasonName,
      year,
    } = season;
    const courseID = season.courseID ?? "";
    const courseName = season.courseName ?? "";
    const courseImage = season.courseImage ?? "";
    setCourseID(courseID);
    setCourseName(courseName);
    setCourseImage(courseImage);
    setCity(season.city ?? "");
    setCountry(season.country ?? "");
    setEventIDs(eventIDs);
    setDefName(seasonName);
    const pointsSystem = season.pointsSystem ?? "tour";
    setImageURL(imageLink);
    setSeasonIDs(seasonIDs);
    setPointsSystem(pointsSystem);
    setCompleted(completed);
    const dateObj = new Date(startDate.toDate());
    const dateString = dateObj.toISOString().split("T")[0];
    setDefDate(dateString);
    setCurrentSeasonID(activeSeason);
    setDefRank(rankCutoff);
    setDefTrophy(trophyName);
    setLeagueName(leagueName);
    setDefMin(minEvents);
    setSeasonIndex(seasonIndex);
    setStarted(started);
    setLoading(false);
  }

  async function validate() {
    setError("");
    const seasonName = nameRef.current.value;
    const startDate = new Date(dateRef.current.valueAsDate);
    console.log(dateRef.current);
    const startDateString = dateRef.current.value;
    setLoading(true);
    const now = moment();
    const sdMomenbt = moment(startDate);

    // Name
    if (seasonName === "") {
      setError("Please enter a name for the upcoming season");
      return false;
    }

    if (eclectic && courseID === "") {
      setError("Please select a course for your league");
      return false;
    }

    // Date
    if (!started && startDateString === "") {
      setError("Please select a starting date for this season");
      return false;
    }

    if (!started && sdMomenbt.isBefore(now)) {
      setError("Please select a starting date in the future for this season");
      return false;
    }

    for (let i = 0; i < seasons.length; i++) {
      let found = false;
      const seasonDate = moment(seasons[i].date);
      if (seasonID === seasons[i].seasonID) {
        found = true;
        continue;
      }
      if (found) {
        // Seasons after current
        if (sdMomenbt.isAfter(seasonDate)) {
          setError("This season cannot start after the following season");
          return false;
        }
      } else {
        // Season before
        if (sdMomenbt.isBefore(seasonDate)) {
          setError("This season cannot start before after the previous season");
          return false;
        }
      }
    }

    if (currentSeasonID !== "") {
      const endCheck = await checkCurrentSeason(startDate);
      if (endCheck === false) {
        return false;
      }
    }

    // Images
    if (image !== "") {
      // return console.log(image.type);
      if (image.type) {
        if (!image.type.includes("image")) {
          setError("Please make sure to only upload images");
          return false;
        }
      }
    }
  }

  async function saveSeason() {
    // return console.log(image);
    // return console.log(dateRef.current.valueAsDate);
    const seasonName = nameRef.current.value;
    const startDate = new Date(dateRef.current.valueAsDate);
    const trophyName = trophyRef.current.value;
    const rankCutoff = rankRef.current ? parseInt(rankRef.current.value) : 0;
    const minEvents = minRef.current ? parseInt(minRef.current.value) : 0;
    setDefRank(rankCutoff);
    setDefMin(minEvents);
    // const minDates =
    setDefDate(startDate);
    setDefName(seasonName);
    setDefTrophy(trophyName);
    console.log(startDate);
    // setLoading(true);
    if ((await validate()) === false) {
      return setLoading(false);
    }
    // return setLoading(false);
    const year = new Date().getFullYear();

    const season = await db.collection("seasons").add({
      leagueID,
      trophyName,
      minEvents,
      rankCutoff,
      leagueName,
      completed: false,
      year,
      dateCreated: new Date(),
      seasonName,
      startDate,
      imageLink: "",
      seasonIndex,
      eventIDs: [],
      activeEventID: "",
      activeRoundID: "",
      pointsSystem,
      started: false,
      eclectic,
      courseID,
      courseName,
      courseImage,
      city,
      country,
    });

    const seasonID = season.id;
    if (currentSeasonID === "") {
      await db
        .collection("leagues")
        .doc(leagueID)
        .update({
          seasonIDs: admin.firestore.FieldValue.arrayUnion(season.id),
          seasonID: season.id,
          seasonName,
        });
    } else {
      await db
        .collection("leagues")
        .doc(leagueID)
        .update({
          seasonIDs: admin.firestore.FieldValue.arrayUnion(season.id),
        });
    }

    if (image !== "") {
      setLoadingText("Uploading image...");
      if (typeof image === "string") {
        fetch(image).then(async (response) => {
          const contentType = response.headers.get("content-type");
          const blob = await response.blob();
          const file = new File([blob], "cupImage.jpg", { contentType });
          // access file here
          const task = storage
            .ref()
            .child(`${seasonID}/${image.name}`)
            .put(file);

          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("seasons").doc(seasonID).update({
                imageLink: url,
              });
            });
          });
        });
      } else {
        const task = storage
          .ref()
          .child(`${seasonID}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            db.collection("seasons").doc(seasonID).update({
              imageLink: url,
            });
          });
        });
      }
    }

    setLoading(false);
    reload();
    goBack();
  }

  async function saveChanges() {
    // return console.log(image);
    const seasonName = nameRef.current.value;
    const startDate = new Date(dateRef.current.valueAsDate);
    const trophyName = trophyRef.current.value;
    const rankCutoff = rankRef.current ? parseInt(rankRef.current.value) : 0;
    const minEvents = minRef.current ? parseInt(minRef.current.value) : 0;
    setDefRank(rankCutoff);
    setDefMin(minEvents);
    setDefDate(startDate);
    setDefName(seasonName);
    setDefTrophy(trophyName);
    if ((await validate()) === false) {
      return setLoading(false);
    }

    // return setLoading(false);

    await db.collection("seasons").doc(seasonID).update({
      seasonName,
      startDate,
      trophyName,
      rankCutoff,
      pointsSystem,
      minEvents,
      courseName,
      courseID,
      city,
      country,
      courseImage,
    });
    if (currentSeasonID === seasonID) {
      await db.collection("leagues").doc(leagueID).update({
        seasonName,
      });
    }

    if (image !== "") {
      setLoadingText("Uploading image...");
      if (typeof image === "string") {
        alert("string");
        fetch(image).then(async (response) => {
          const contentType = response.headers.get("content-type");
          const blob = await response.blob();
          const file = new File([blob], "cupImage.jpg", { contentType });
          // access file here
          const task = storage
            .ref()
            .child(`${seasonID}/${image.name}`)
            .put(file);

          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              console.log(url, seasonID);
              db.collection("seasons").doc(seasonID).update({
                imageLink: url,
              });
            });
          });
        });
      } else {
        const task = storage
          .ref()
          .child(`${seasonID}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            db.collection("seasons").doc(seasonID).update({
              imageLink: url,
            });
          });
        });
      }
    }

    setLoading(false);
    reload();
    goBack();
  }

  async function endSeason() {
    setLoading(true);
    await db.collection("seasons").doc(seasonID).update({
      completed: true,
    });
    const latestSeason = seasonIDs[seasonIDs.length - 1];
    const currentIndex = seasonIDs.indexOf(seasonID);
    if (latestSeason === seasonID) {
      await db.collection("leagues").doc(leagueID).update({
        seasonID: "",
      });
    } else {
      await db
        .collection("leagues")
        .doc(leagueID)
        .update({
          seasonID: seasonIDs[currentIndex + 1],
        });
    }
    setLoading(false);
    reload();
    goBack();
  }

  async function deleteSeason() {
    setLoading(true);
    await db.collection("seasons").doc(seasonID).delete();
    await db
      .collection("leagues")
      .doc(leagueID)
      .update({
        seasonIDs: admin.firestore.FieldValue.arrayRemove(seasonID),
      });
    setLoading(false);
    reload();
    goBack();
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);
  const steps = [
    {
      element: ".edit-hint",
      intro:
        "Let's create a new season! A new season will be activated once an event is created for that season",
    },
    {
      element: ".date-hint",
      intro:
        "You can create a new season if a current one is active, the start date has to be after any events in the current season however.",
    },
  ];

  return (
    <div className="season-editor onboarding">
      {loading && <BackdropLoader />}
      {showCrop && (
        <Backdrop className="crop-drop" open>
          <div className="crop-co">
            <div className="cd-close">
              <Close onClick={() => setShowCrop(false)} />
            </div>
            <div className="crop-box">
              <Cropper
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                image={imgageURL}
              />
            </div>
            <div className="flex-center crop-but">
              <button
                onClick={() => {
                  cropImage();
                  setShowCrop(false);
                }}
                className="default-button"
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {showImages ? (
        <ImageSelector
          goBack={() => {
            setShowImages(false);
            setTimeout(
              () => picScrollRef.current.scrollIntoView({ behavior: "smooth" }),
              100
            );
          }}
          setSelectedImage={(e) => {
            setImageURL(e);
            setImage(e);
          }}
          selectedImage={image}
          type="trophy"
        />
      ) : showPicker ? (
        <CoursePicker
          setChosen={() => {}}
          goBack={() => setShowPicker(false)}
          setCourseDetails={(e) => {
            setCourseID(e.courseID);
            setCourseImage(e.imageLink);
            setCourseName(e.name);
            setCity(e.city);
            setCountry(e.country);
            setShowPicker(false);
          }}
        />
      ) : (
        <>
          <Dialog open={show} onClose={() => setShow(false)}>
            <div className="info-message">
              <GradientInfoIcon className="big-info-icon" />
              <p>
                Are you sure you want to end the season? This cannot be undone
              </p>
              <div className="ec-button-row">
                <button onClick={endSeason}>Confirm</button>
              </div>
            </div>
          </Dialog>

          <Dialog open={showHint} onClose={() => setShowHint(false)}>
            <div className="info-message">
              <GradientInfoIcon className="big-info-icon" />

              <p>{hintText}</p>
            </div>
          </Dialog>

          <div className="back-row" onClick={() => goBack()}>
            <ArrowBack className="icon-cursor" />
            <p>Back</p>
          </div>

          {/* <div className="page-header">
            <h2>{edit ? "Edit Season" : "Create Season"}</h2>
          </div> */}
          <PageHeader text={edit ? "Edit Season" : "Create Season"} />

          <div className="ph-msg"></div>

          <div className="sub-header mb-20">
            <h5 className="">General</h5>
          </div>
          <div className="ec-box lc-box ">
            {eclectic && (
              <div className="input-group">
                <div className="ig-header">
                  <p>Course</p>
                  {!started && (
                    <Edit
                      className="dg-icon-bg icon-cursor ml-20"
                      onClick={() => {
                        setShowPicker(true);
                      }}
                    />
                  )}
                </div>

                {/* <div>
                    <input
                      disabled
                      value={selectedCourse.label ?? selectedCourse.name}
                    />
                  </div> */}
                {courseName !== "" && (
                  <ClubCourse
                    course={{
                      name: courseName,
                      imageLink: courseImage,
                      city,
                      country,
                    }}
                    hideRight
                  />
                )}
              </div>
            )}

            <div className="input-group mt-0">
              <p>Season Name</p>
              <input
                type="text"
                className="default-input"
                onChange={(e) => setDefName(e.target.value)}
                placeholder="Season name"
                value={defName}
                ref={nameRef}
              />
            </div>
            <div className="input-group mt-0">
              <p>Trophy Name</p>
              <input
                type="text"
                className="default-input"
                onChange={(e) => setDefTrophy(e.target.value)}
                placeholder="Trophy Name"
                value={defTrophy}
                ref={trophyRef}
              />
            </div>

            <div className="input-group  mt-0 date-hint">
              <p>Start Date</p>
              <input
                disabled={started}
                type="date"
                className="default-input"
                onChange={(e) => setDefDate(e.target.value)}
                value={defDate}
                ref={dateRef}
              />
            </div>

            {!eclectic && (
              <div className="input-group mb-20 mt-0">
                <div className="ig-header">
                  <p>Points System</p>
                  <GradientInfoIcon
                    onClick={() => {
                      setHintText([
                        "Our traditional points system awards 500 points to the winner, and scales down from there. The stableford points system accumulates each player's stableford points throughout the season.",
                      ]);
                      setShowHint(true);
                    }}
                    className={"info-icon"}
                  />
                </div>
                <Dropdown
                  disabled={started}
                  options={pointsOptions}
                  value={pointsSystem}
                  onChange={(e) => setPointsSystem(e.value)}
                />
              </div>
            )}

            <div
              ref={picScrollRef}
              // style={{ padding: "0px 20px" }}
              className="input-group mb-20"
            >
              <p>Season Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imgageURL !== "" && (
                      <div className="event-img mt-0">
                        <img className="" alt="profile" src={imgageURL} />
                      </div>
                    )}
                    {imgageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                  <div className="dz-or">
                    <p className="">Or</p>
                  </div>
                </div>
                <Pill
                  text={"Choose Preset Image"}
                  onClick={() => setShowImages(true)}
                />
                {/* <div className="ec-is" style={{ marginLeft: "0px" }}>
                    <button className="" onClick={() => setShowImages(true)}>
                      Choose Preset Image
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
          {!eclectic && (
            <>
              <div className="sub-header mb-20">
                <h5 className="">Finals</h5>
              </div>
              <div className="ec-box lc-box">
                <div className="input-group mt-0">
                  <div className="ig-header">
                    <p>Minimum events</p>

                    <GradientInfoIcon
                      onClick={() => {
                        setHintText(
                          "Choose whether players need to play a minimum amount of regular season events to be eligible for the season final. Leave as zero if there is no minimum."
                        );
                        setShowHint(true);
                      }}
                      className="info-icon"
                    />
                  </div>
                  <input
                    type="number"
                    className="default-input"
                    min={0}
                    onChange={(e) => setDefMin(e.target.valueAsNumber)}
                    value={defMin}
                    ref={minRef}
                  />
                </div>

                <div className="input-group mt-0">
                  <div className="ig-header">
                    <p>Rank Cutoff</p>
                    <GradientInfoIcon
                      onClick={() => {
                        setHintText(
                          "Choose whether only players above a certain ranking are eligible for the season final. Leave as zero if there is no cutoff."
                        );
                        setShowHint(true);
                      }}
                      className="info-icon"
                    />
                  </div>
                  <input
                    type="number"
                    className="default-input"
                    min={0}
                    onChange={(e) => setDefRank(e.target.valueAsNumber)}
                    value={defRank}
                    ref={rankRef}
                  />
                </div>
              </div>
            </>
          )}

          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}

          {
            <div className="default-button-row">
              {edit ? (
                <button className="default-button mr-20" onClick={saveChanges}>
                  Save Changes
                </button>
              ) : (
                <button className="default-button mr-20" onClick={saveSeason}>
                  Create Season{" "}
                </button>
              )}

              {edit && !completed && (
                <>
                  {eventIDs.length > 0 ? (
                    <button
                      className="delete-button"
                      onClick={() => setShow(true)}
                    >
                      End Season
                    </button>
                  ) : (
                    <button
                      className="delete-button"
                      onClick={() => {
                        deleteSeason();
                      }}
                    >
                      Delete Season
                    </button>
                  )}
                </>
              )}
            </div>
          }
        </>
      )}
    </div>
  );
}

export default SeasonEditor;
