import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase";

import { useAuth } from "../../contexts/AuthContext";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import LogItem from "../../components/display/LeagueLogs/LogItem/LogItem";
import LogHeader from "../../components/display/LeagueLogs/LogItem/LogHeader";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackRow from "../../components/display/Rows/BackRow";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

function MatchplayLog({ tournID, goBack = () => {}, playerResults = {} }) {
  const { currentUser } = useAuth();

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pointsType, setPointsType] = useState("normal");

  useEffect(() => {
    loadLogs();
  }, []);

  async function loadLogs() {
    setLoading(true);
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tourn = tournDoc.data();
    const { table } = tourn;
    const sorted = table.sort((a, b) => b.points - a.points);
    setLogs(sorted);
    setLoading(false);
  }

  return (
    <div className="mpt-log">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text={"Tournament Table"} />

      <div className="mpt-log-msg">
        <h3>Info</h3>
        <p>
          {pointsType === "normal"
            ? "10 points are awarded for a win, 5 points for a tie"
            : "5 points are awarded for every hole lead by the end of the match"}
        </p>
      </div>

      <div className="mp-log-box pd-20">
        <LogHeader />
        {false &&
          logs.map((player, index) => (
            <LogItem
              hcp={player.handicap}
              handleClick={() => {}}
              name={player.name}
              played={player.played}
              points={player.points}
              rank={index + 1}
              wins={player.wins}
              key={player.playerID}
            />
          ))}
        {logs.map((player, index) => {
          // let resMap = []
          // const
          let resMap =
            playerResults[player.playerID] ??
            playerResults[player.teamID] ??
            [];

          return (
            <Accordion key={player.playerID}>
              <AccordionSummary>
                {/* <p></p> */}
                <LogItem
                  hcp={player.handicap}
                  handleClick={() => {}}
                  name={player.name}
                  played={player.played}
                  points={player.points}
                  rank={index + 1}
                  wins={player.wins}
                  key={player.teamID ?? player.playerID}
                  showArrow={resMap.length > 0}
                  ave={
                    player.played === 0
                      ? 0
                      : Math.round(player.points / player.played)
                  }
                />
              </AccordionSummary>
              <AccordionDetails>
                {resMap.map((res) => (
                  <div className="res-pee">
                    <p className={res.type}>{res.text}</p>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default MatchplayLog;
