import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../../firebase";
import "./EventTeamManager.css";
import _ from "lodash";
import { useDropzone } from "react-dropzone";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Close from "@mui/icons-material/Close";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import { Dialog, Avatar } from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import Pill from "../../Buttons/Pill";
import ErrorModal from "../../ErrorModal/ErrorModal";
import Switch from "react-ios-switch/lib/Switch";
import PageHeader from "../../display/Rows/PageHeader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import ETMTeam from "./ETMTeam";
import { makeID } from "../../HelperFunctions";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

const GradientInfoIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <InfoIcon
      onClick={onClick}
      className={`dg-icon ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientAddIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="cr-icon icon-cursor dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientMinusIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <RemoveCircleIcon
      onClick={onClick}
      className="cr-icon icon-cursor dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function EventTeamManager({
  eventID = "",
  goBack = () => {},
  league = false,
  leagueID = "",
}) {
  const [teams, setTeams] = useState([]);
  const [ogTeams, setOGTeams] = useState([]);
  const [eventName, setEventName] = useState("");
  const [teamType, setTeamType] = useState("");
  const [dialog, setDialog] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(0);

  const [teeMap, setTeeMap] = useState({});

  const [error, setError] = useState("");

  const [live, setLive] = useState(false);

  const [players, setPlayers] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [showRemoved, setShowRemoved] = useState(false);
  const [remIndexes, setRemIndexes] = useState({
    i1: 0,
    i2: 0,
  });

  const [searchInput, setSearchInput] = useState("");

  const [showSwap, setShowSwap] = useState(false);
  const [swapIndices, setSwapIndices] = useState({
    i1: 0,
    i2: 0,
  });

  const [swapMode, setSwapMode] = useState(false);
  const [swapping, setSwapping] = useState(false);
  const [swapID, setSwapID] = useState("");

  const [showGhost, setShowGhost] = useState(false);
  const [ghostTeam, setGhostTeam] = useState();

  const [leagueTeams, setLeagueTeams] = useState([]);

  const [teamFormat, setTeamFormat] = useState(false);

  const [groupLimit, setGroupLimit] = useState(4);

  const [showRemoveTeam, setShowRemoveTeam] = useState(false);
  const [teamToRemove, setTeamToRemove] = useState(0);

  const [scrambleCaps, setScrambleCaps] = useState(false);

  const [image, setImage] = useState("");
  const [imgageURL, setImageURL] = useState("");

  const [teamInput, setTeamInput] = useState("");

  const [showDia, setShowDia] = useState(false);
  const [teamToEdit, setTeamToEdit] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
  });

  useEffect(() => {
    if (league) {
      loadLeagueDetails();
    } else {
      loadDetails();
    }
  }, []);

  const teamOptions = [
    {
      label: "Combined Scores",
      value: "combined",
    },
    {
      label: "One to count",
      value: "scramble",
    },
    {
      label: "Two to count",
      value: "two",
    },
    {
      label: "Bogey +",
      value: "bogey",
    },
    {
      label: "Bogey ++",
      value: "bogey++",
    },
    {
      label: "Custom",
      value: "custom",
    },
    {
      label: "Overall Scores",
      value: "Overall",
    },
    // {
    //   label: "Better Ball",
    //   value: "bb",
    // },
  ];

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      // padding: grid * 2,
      // margin: `0 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? "#edefef" : "transparent",
      // paddingBottom: '20px',
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const [timeGroups, setTimeGroups] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function loadLeagueDetails() {
    setLoading(true);
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();

    const teams = league.teams ?? [];
    const { playerIDs } = league;

    let gl = "";

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const players = team.players ?? [];
      const pa = players.length;
      if (gl === "" || pa < gl) {
        gl = pa;
      }
    }

    setGroupLimit(gl);

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const players = team.players ?? [];
      const tgl = gl;
      const diff = tgl - players.length;
      for (let x = 0; x < diff; x++) {
        teams[i].players.push({ name: "Empty" });
      }
    }

    const refs = playerIDs.map((uid) => {
      return db.collection("users").doc(uid).get();
    });
    const arr = [];
    const docs = await Promise.all(refs);
    for (let i = 0; i < docs.length; i++) {
      const userDoc = docs[i];
      const playerID = userDoc.id;
      const player = userDoc.data();
      if (!player) {
        continue;
      }
      const { firstName, lastName, handicap, profileImage } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
      });
    }
    console.log(teams, arr);

    if (teams.length === 0) {
      // const shuffled = shuffle(arr);

      let groups = [];

      let limit = 4;

      createCombinedTeams(arr, limit);
    } else {
      const rem = [];
      const idArr = [];
      for (let i = 0; i < teams.length; i++) {
        const players = teams[i].players;
        for (let n = 0; n < players.length; n++) {
          const playerID = players[n].playerID;
          idArr.push(playerID);
        }
      }
      for (let i = 0; i < arr.length; i++) {
        const playerID = arr[i].playerID;
        if (!idArr.includes(playerID)) {
          rem.push(arr[i]);
        }
      }
      console.log(teams);
      setRemoved(rem);
      setTeams(teams);
    }

    setPlayers(arr);
    setLoading(false);
  }

  async function loadDetails() {
    // alert("hurr");
    console.log(eventID);
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();

    let arr = [];

    const { eventName, playerIDs, format } = event;

    const leagueID = event.leagueID;
    if (leagueID !== "" && leagueID !== "standalone") {
      const leagueDoc = await db.collection("leagues").doc(leagueID).get();
      const league = leagueDoc.data();

      const teamPoints = league.teamPoints ?? false;

      if (teamPoints) {
        console.log(league.teams);

        const leagueTeams = league.teams ?? [];

        for (let i = 0; i < leagueTeams.length; i++) {
          const team = leagueTeams[i];
          const { players } = team;
          const filt = players.filter((pl) => playerIDs.includes(pl.playerID));
          leagueTeams[i].players = filt;
        }

        let lim = "";

        const filt = leagueTeams.filter((team) => team.players.length !== 0);

        for (let i = 0; i < filt.length; i++) {
          const team = filt[i];
          const am = team.players.length;
          if (lim === "" || am > lim) {
            lim = am;
          }
        }

        // if (lim !== "") {
        //   setGroupLimit(lim);
        // }

        setLeagueTeams(filt ?? []);
      }
    }

    const guests = event.guests ?? [];
    const bbType = event.bbType ?? "";
    if (format === "team-net-strokeplay" || format === "better-ball") {
      setTeamFormat(true);
    }
    const teams = event.teams ?? [];
    const teamType = event.teamType ?? "combined";
    const ids = [];

    const refs = playerIDs.map((uid) => {
      return db.collection("users").doc(uid).get();
    });

    // console.log(teams);

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const players = team.players ?? [];
      const tgl = event.teamGroupLimit ?? 4;
      const diff = tgl - players.length;
      for (let x = 0; x < diff; x++) {
        teams[i].players.push({ name: "Empty" });
      }
    }

    setTeams(teams);
    const scram = event.scrambleHandicaps ?? false;
    if (teamType === "scramble" && scram) {
      setScrambleCaps(true);
    }
    setOGTeams(eventDoc.data().teams);
    const isLive = event.roundIDs.length > 0;
    setLive(isLive);

    const { teeMap } = event;
    setTeeMap(teeMap);
    const docs = await Promise.all(refs);
    for (let i = 0; i < docs.length; i++) {
      const userDoc = docs[i];
      const playerID = userDoc.id;
      const player = userDoc.data();
      if (!player) {
        continue;
      }
      const { firstName, lastName, handicap, profileImage } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
        tee: teeMap[playerID],
      });
    }

    arr = [...arr, ...guests];
    setPlayers(arr);
    if (teams.length === 0) {
      // const shuffled = shuffle(arr);
      console.log("HEEERE");

      const rounds = event.rounds;
      let groups = [];
      if (rounds.length > 0) {
        const round = rounds[0];
        groups = round.groups;
      }

      let limit = 4;
      if (
        format === "team-net-strokeplay" ||
        (teamType === "scramble" && scram)
      ) {
        limit = 2;
      }
      createCombinedTeams(arr, limit, groups);
    } else {
      const rem = [];
      const idArr = [];
      for (let i = 0; i < teams.length; i++) {
        const players = teams[i].players;
        for (let n = 0; n < players.length; n++) {
          const playerID = players[n].playerID;
          idArr.push(playerID);
        }
      }
      for (let i = 0; i < arr.length; i++) {
        const playerID = arr[i].playerID;
        if (!idArr.includes(playerID)) {
          rem.push(arr[i]);
        }
      }
      console.log(rem);
      setRemoved(rem);
    }
    const limit = event.teamGroupLimit
      ? event.teamGroupLimit
      : format === "team-net-strokeplay"
      ? 2
      : teamType === "scramble" && scram
      ? 2
      : 4;
    // const limit = 2;
    setTeamType(teamType);
    setGroupLimit(limit);
    setEventName(eventName);
    setLoading(false);
  }

  function shuffle(array) {
    // alert("everyday");
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  function createCombinedTeams(playerArr, groupLimit, groups, shuffle = false) {
    let arr = [];
    const ceil = Math.ceil(playerArr.length / groupLimit);
    const remainder = playerArr.length % groupLimit;

    if (groups && groupLimit === 4) {
      for (let i = 0; i < groups.length; i++) {
        arr.push({
          players: groups[i].players,
        });
      }
      setTimeGroups([...arr]);
    }
    // else if (groupLimit === 2) {
    //   for (let i = 0; i < groups.length; i++) {
    //     const group = groups[i];
    //     const { players } = group;
    //     const pl1 = [];
    //     const pl2 = [];
    //     for (let x = 0; x < players.length; x++) {
    //       if (x < 2) {
    //         pl1.push(players[x]);
    //       } else {
    //         pl2.push(players[x]);
    //       }
    //     }
    //     arr.push({
    //       players: pl1,
    //     });
    //     arr.push({
    //       players: pl2,
    //     });
    //   }
    //   // for (let i = 0; i < groups.length; i++) {
    //   //   arr.push({
    //   //     players: groups[i].players,
    //   //   });
    //   // }
    // }
    if (!league || teams.length === 0 || shuffle) {
      for (let j = 0; j < ceil; j++) {
        // const players = []
        arr[j] = {};
        arr[j]["players"] = [];
      }
      for (let i = 0; i < playerArr.length; i++) {
        let groupNum = Math.floor(i / groupLimit);
        arr[groupNum]["players"].push(playerArr[i]);
      }
    } else {
      arr = teams;
    }

    // if (arr.length > 2 && groupLimit > 2) {
    //   if (remainder === 1) {
    //     // Two to move
    //     const thirdLast = arr.length - 3;
    //     const secondLast = arr.length - 2;
    //     const last = arr.length - 1;
    //     arr[last].players.push(arr[thirdLast].players[3]);
    //     arr[last].players.push(arr[secondLast].players[3]);
    //     arr[thirdLast].players.pop();
    //     arr[secondLast].players.pop();
    //     arr[last].players.reverse();
    //   }
    //   if (remainder === 2 && arr.length > 1) {
    //     // One to move
    //     const secondLast = arr.length - 2;
    //     const last = arr.length - 1;
    //     // console.log(secondLast, last, groups);
    //     arr[last].players.push(arr[secondLast].players[3]);
    //     arr[secondLast].players.pop();
    //     arr[last].players.reverse();
    //   }
    // }
    for (let i = 0; i < arr.length; i++) {
      if (league) {
        if (arr[i].teamID === undefined) {
          arr[i].teamID = makeID(15);
          arr[i].points = 0;
          arr[i].played = 0;
        }
      }
      const players = arr[i].players;
      const filtered = players.filter((player) => player !== undefined);
      if (filtered.length < groupLimit) {
        filtered.push({ name: "Empty" });
      }
      arr[i].players = filtered;
    }

    console.log(arr);
    setTeams(arr);
  }

  function handleDrop(e) {
    if (!e.destination) {
      return;
    }
    const dest = parseInt(e.destination.droppableId);
    const playerID = e.draggableId;
    const source = parseInt(e.source.droppableId);

    const start = e.source.index;
    const end = e.destination.index;

    if (dest === source) {
      setTeams((current) => {
        const group = current[dest].players;
        const arr = array_move(group, start, end);
        current[dest].players = arr;
        return current;
      });
    } else {
      setTeams((current) => {
        let temp = [...current];
        const p1 = temp[source].players[start];
        const p2 = temp[dest].players[end];
        temp[source].players[start] = p2;
        temp[dest].players[end] = p1;
        return temp;
      });
    }
  }

  async function saveLeagueChanges() {
    // console.log(teams);
    // return
    setLoading(true);
    try {
      for (let i = 0; i < teams.length; i++) {
        if (teams[i].teamID === undefined) {
          teams[i].teamID = makeID(15);
        }

        const filt = teams[i].players.filter((pl) => pl.name !== "Empty");
        teams[i].players = filt;
      }

      // setLoading(false);
      console.log(teams);

      await db.collection("leagues").doc(leagueID).update({
        teams,
      });
      toast.success("Teams successfully edited", {
        icon: successIco,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);

    try {
      for (let i = 0; i < teams.length; i++) {
        const players = teams[i].players;
        teams[i].players = teams[i].players.filter(
          (player) => player.name !== "Empty"
        );
        // for (let n = 0; n < players.length; n++) {
        //   if (players[n].name === "Empty") {
        //     teams[i].players.splice(n, 1);
        //   }
        // }
      }
      // return setLoading(false);
      await db.collection("events").doc(eventID).update({
        teams,
        teamGroupLimit: groupLimit,
        teamType,
        scrambleHandicaps: scrambleCaps,
      });
      if (live) {
        const changes = [];

        const newTeamIDs = teams.map((team) => team.teamID);
        const ogTeamIDs = ogTeams.map((team) => team.teamID);
        const removed = _.difference(ogTeamIDs, newTeamIDs);

        console.log(removed);
        // return setLoading(false);

        for (let i = 0; i < teams.length; i++) {
          const ogTeam = ogTeams[i];
          if (ogTeam === undefined) {
            changes.push(i);
            continue;
          }
          const team = teams[i];
          const oldIDs = ogTeam.players.map((player) => player.playerID);
          const newIDs = team.players.map((player) => player.playerID);

          const different = !_.isEmpty(_.xor(oldIDs, newIDs));

          if (different) {
            changes.push(i);
          }
        }

        await db.collection("triggers").add({
          type: "team-recalc",
          eventID,
          dateAdded: new Date(),
          changes,
          removed,
        });
      }
      toast.success("Changes were successfully saved", {
        icon: successIco,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  async function saveTeamEdit() {
    // return;
    setLoading(true);
    setShowDia(false);
    try {
      const teamPlayers = teamToEdit.players.map((pl) => pl.playerID);
      const teamPlayer = teamPlayers[0];
      if (image) {
        const task = storage.ref().child(`${eventID}/${image.name}`).put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            setTeams((c) => {
              for (let i = 0; i < c.length; i++) {
                const team = c[i];
                const teamIDs = team.players.map((pl) => pl.playerID);

                if (teamIDs.includes(teamPlayer)) {
                  c[i].teamName = teamInput;
                  c[i].imageLink = url;
                }
              }
              return c;
            });
            setTeamInput("");
            setImage();
            setImageURL("");
          });
        });
      } else {
        setTeams((c) => {
          for (let i = 0; i < c.length; i++) {
            const team = c[i];
            const teamIDs = team.players.map((pl) => pl.playerID);

            if (teamIDs.includes(teamPlayer)) {
              c[i].teamName = teamInput;
              c[i].imageLink = "";
            }
          }
          return c;
        });
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="event-team-manager">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <Dialog
        className="team-edit-dia"
        open={showDia}
        onClose={() => setShowDia(false)}
      >
        <div className="ted-box">
          <PageHeader
            text="Edit Team"
            close={() => setShowDia(false)}
            showClose
          />
          <div className="ec-box">
            <div className="input-group">
              <p>Team Name</p>
              <input
                className="default-input"
                value={teamInput}
                placeholder="Enter team name..."
                onChange={(e) => setTeamInput(e.target.value)}
              />
            </div>
            <div className="input-group mb-20">
              <p>Team Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imgageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imgageURL} />
                      </div>
                    )}
                    {imgageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center mb-40">
            <button onClick={saveTeamEdit} className="default-button">
              Save
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="info-message">
          <div className="svg-cont">
            <GradientInfoIcon className="big-info-icon" />
          </div>

          <p>{dialog}</p>
        </div>
      </Dialog>
      <Dialog open={showRemoveTeam} onClose={() => setShowRemoveTeam(false)}>
        <div className="info-message rem-team">
          <div className="svg-cont">
            <InfoIcon className="big-info-icon red-icon" />
          </div>

          <p>Are you sure you want to remove this team?</p>
          <div className="flex-center mt-20">
            <button
              onClick={() => {
                setTeams((current) => {
                  const team = current[teamToRemove];
                  for (let i = 0; i < team.players.length; i++) {
                    const player = team.players[i];
                    if (player.name !== "Empty") {
                      setRemoved((current2) => [...current2, player]);
                    }
                  }
                  current.splice(teamToRemove, 1);
                  return current;
                });
                setUpdate((current) => current + 1);
                setShowRemoveTeam(false);
              }}
              className="default-button"
            >
              Confirm
            </button>
          </div>
          <div className="flex-center mt-20 mb-20">
            <button
              onClick={() => setShowRemoveTeam(false)}
              className="delete-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showRemoved} onClose={() => setShowRemoved(false)}>
        <div className="gs-rem-dia">
          <PageHeader
            text="Select Player"
            showClose
            close={() => setShowRemoved(false)}
          />
          <div className="ph-msg">
            <p>Select a player to fill this spot</p>
          </div>
          <div className="input-group pl-20">
            <p>Search</p>
            <input
              type="text"
              placeholder="Search for player"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="default-input"
            />
          </div>
          <div className="gsrd-players">
            {removed.map((player) => {
              if (searchInput !== "") {
                const lcSearch = searchInput.toLowerCase();
                const lcName = player.name.toLowerCase();
                if (lcName.includes(lcSearch)) {
                  return (
                    <PlayerItem
                      hcp={player.handicap}
                      name={player.name}
                      noLink
                      hideIcons
                      img={player.profileImage}
                      key={player.playerID}
                      showHover
                      showProfile={() => {
                        const { i1, i2 } = remIndexes;
                        setTeams((current) => {
                          current[i1].players[i2] = player;
                          return current;
                        });
                        setRemoved((current) =>
                          current.filter(
                            (pla) => pla.playerID !== player.playerID
                          )
                        );
                        setUpdate((current) => current + 1);
                        setShowRemoved(false);
                      }}
                    />
                  );
                } else {
                  return null;
                }
              }

              return (
                <PlayerItem
                  hcp={player.handicap}
                  name={player.name}
                  noLink
                  hideIcons
                  img={player.profileImage}
                  key={player.playerID}
                  showHover
                  showProfile={() => {
                    const { i1, i2 } = remIndexes;
                    setTeams((current) => {
                      current[i1].players[i2] = player;
                      return current;
                    });
                    setRemoved((current) =>
                      current.filter((pla) => pla.playerID !== player.playerID)
                    );
                    setUpdate((current) => current + 1);
                    setShowRemoved(false);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Dialog>
      <Dialog
        className="etm-dia"
        open={showSwap}
        onClose={() => setShowSwap(false)}
      >
        <div className="gs-rem-dia">
          <PageHeader
            text="Swap Player"
            close={() => setShowSwap(false)}
            showClose
          />

          <div className="ph-msg">
            <p>Select a player to swap out</p>
          </div>
          <div className="input-group pl-20">
            <p>Search</p>
            <input
              type="text"
              placeholder="Search for player"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="default-input"
            />
          </div>
          <div className="gsrd-players">
            {players.map((player) => {
              if (searchInput !== "") {
                const lcSearch = searchInput.toLowerCase();
                const lcName = player.name.toLowerCase();
                if (lcName.includes(lcSearch)) {
                  return (
                    <PlayerItem
                      hcp={player.handicap}
                      name={player.name}
                      noLink
                      hideIcons
                      img={player.profileImage}
                      key={player.playerID}
                      showHover
                      showProfile={() => {
                        const { i1, i2 } = swapIndices;
                        setTeams((current) => {
                          const p1 = current[i1].players[i2];
                          let p2;
                          let found = false;
                          let p2group;
                          let p2index;
                          for (let i = 0; i < current.length; i++) {
                            if (found) {
                              break;
                            }
                            const group = current[i];
                            const players = group.players;
                            for (let j = 0; j < players.length; j++) {
                              if (players[j].playerID === player.playerID) {
                                p2 = players[j];
                                p2group = i;
                                p2index = j;
                                found = true;
                              }
                            }
                          }
                          current[p2group].players[p2index] = p1;
                          current[i1].players[i2] = p2;
                          return current;
                        });
                        setShowSwap(false);
                      }}
                    />
                  );
                } else {
                  return null;
                }
              }

              return (
                <PlayerItem
                  hcp={player.handicap}
                  name={player.name}
                  noLink
                  hideIcons
                  img={player.profileImage}
                  key={player.playerID}
                  showHover
                  showProfile={() => {
                    const { i1, i2 } = swapIndices;
                    setTeams((current) => {
                      const p1 = current[i1].players[i2];
                      let p2;
                      let found = false;
                      let p2group;
                      let p2index;
                      for (let i = 0; i < current.length; i++) {
                        if (found) {
                          break;
                        }
                        const group = current[i];
                        const players = group.players;
                        for (let j = 0; j < players.length; j++) {
                          if (players[j].playerID === player.playerID) {
                            p2 = players[j];
                            p2group = i;
                            p2index = j;
                            found = true;
                          }
                        }
                      }
                      if (found) {
                        // console.log(p1, p2, p2group, p2index);
                        current[p2group].players[p2index] = p1;
                        current[i1].players[i2] = p2;
                        return current;
                      } else {
                        current[i1].players[i2] = player;
                        return current;
                      }
                    });
                    setShowSwap(false);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Dialog>
      <Dialog open={showGhost} onClose={() => setShowGhost(false)}>
        <div className="gs-rem-dia">
          <PageHeader
            close={() => setShowGhost(false)}
            showClose
            text="Add Ghost"
          />
          <div className="ph-msg">
            <p>Select a player to fill this spot</p>
          </div>
          <div className="input-group pl-20">
            <p>Search</p>
            <input
              type="text"
              placeholder="Search for player"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="default-input"
            />
          </div>
          <div className="gsrd-players">
            {ghostTeam !== undefined &&
              players.map((player) => {
                if (searchInput !== "") {
                  const lcSearch = searchInput.toLowerCase();
                  const lcName = player.name.toLowerCase();
                  if (!lcName.includes(lcSearch)) {
                    return null;
                  }
                }

                const team = teams[ghostTeam];

                const teamIDs = team.players.map((pl) => pl.playerID);

                if (teamIDs.includes(player.playerID)) {
                  return null;
                }

                return (
                  <PlayerItem
                    hcp={player.handicap}
                    name={player.name}
                    noLink
                    hideIcons
                    img={player.profileImage}
                    key={player.playerID}
                    showHover
                    showProfile={() => {
                      setTeams((current) => {
                        const team = current[ghostTeam];
                        player.ghost = true;
                        // team.players.pop();
                        team.players.push(player);
                        console.log(team);
                        team.players.sort((a, b) => {
                          if (b.name === "Empty") {
                            return -1;
                          } else {
                            return 1;
                          }
                        });

                        current[ghostTeam] = team;
                        return current;
                      });

                      setUpdate((current) => current + 1);
                      setShowGhost(false);
                    }}
                  />
                );
              })}
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      <div onClick={goBack} className="back-row">
        <ArrowBack />
        <p>Back</p>
      </div>

      <PageHeader text={league ? "League Teams" : "Event Team Manager"} />
      <div className="ph-msg">
        <p>{eventName}</p>
      </div>
      {!teamFormat && !league && !live && (
        <div className="ec-box">
          <div className="input-group etm-ig pl-20">
            <div className="ig-header">
              <p style={{ marginBottom: "0px" }}>Team Format</p>
              <div className="svg-cont">
                <GradientInfoIcon
                  onClick={() => {
                    setDialog("Select a team format for this event");
                    setShowDialog(true);
                  }}
                  className="info-icon"
                />
              </div>
            </div>
            <Dropdown
              disabled={teamFormat}
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={teamType}
              options={teamOptions}
              onChange={(e) => {
                setTeamType(e.value);
                if (e.value !== "scramble") {
                  setScrambleCaps(false);
                }
              }}
            />
          </div>
          {teamType === "scramble" && (
            <div className="input-group nassau-group">
              <div className="ig-header">
                <p>Scramble Handicaps</p>
                <GradientInfoIcon
                  className="info-icon dg-icon-bg mb-10"
                  onClick={() => {
                    setDialog(
                      "Only possible for 2 players - uses 35% of the higher handicap, and 15% of the lower handicap, added together to form a new group handicap."
                    );
                    setShowDialog(true);
                  }}
                />
              </div>
              <Switch
                onColor="#1e7a69"
                checked={scrambleCaps}
                className={
                  scrambleCaps ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => setScrambleCaps((current) => !current)}
              />
            </div>
          )}
        </div>
      )}

      {(league ||
        [
          "combined",
          "Overall",
          "scramble",
          "two",
          "bogey",
          "bogey++",
          "custom",
        ].includes(teamType)) && (
        <>
          <div className="sub-header">
            <button
              onClick={league ? saveLeagueChanges : saveChanges}
              className="default-button"
            >
              Save Changes
            </button>
          </div>
          <div
            style={{ alignItems: "center" }}
            className="input-group etm-ig pl-20"
          >
            <p style={{ marginBottom: "0px" }}>Team Limit</p>
            <div className="tee-numbers pl-20">
              <div className="svg-cont">
                <GradientMinusIcon
                  onClick={() => {
                    let change = false;
                    if (scrambleCaps) {
                      return;
                    }
                    setGroupLimit((current) => {
                      if (current === 2) {
                        return current;
                      } else {
                        change = true;
                        let newLimit = current - 1;
                        // if (!league) {
                        createCombinedTeams(players, newLimit, null, true);
                        // }
                        return current - 1;
                      }
                    });
                    if (change) {
                      setUpdate((current) => current + 1);
                    }
                  }}
                />
              </div>

              <p>{groupLimit}</p>
              <div className="svg-cont">
                <GradientAddIcon
                  onClick={() => {
                    if (scrambleCaps) {
                      return;
                    }
                    setGroupLimit((current) => {
                      const newLimit = current + 1;
                      // if (!league) {
                      createCombinedTeams(players, newLimit);
                      // }
                      return current + 1;
                    });
                    setUpdate((current) => current + 1);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="quick-links">
            {leagueTeams.length > 0 && (
              <Pill
                text={"Import League Teams"}
                onClick={() => {
                  let lim = "";

                  const parr = [];

                  for (let i = 0; i < leagueTeams.length; i++) {
                    const team = leagueTeams[i];
                    const { players } = team;
                    for (let j = 0; j < players.length; j++) {
                      parr.push(players[j].playerID);
                    }
                    const am = team.players.length;
                    if (lim === "" || am > lim) {
                      lim = am;
                    }
                  }

                  if (lim !== "") {
                    setGroupLimit(lim);
                  }

                  setRemoved((c) => {
                    const filt = c.filter((pl) => !parr.includes(pl.playerID));
                    return filt;
                  });

                  setTeams(leagueTeams);
                  setUpdate((c) => c + 1);
                }}
              />
            )}
            <Pill
              text={"Shuffle"}
              onClick={() => {
                const shuffled = shuffle(players);
                createCombinedTeams(shuffled, groupLimit, null, true);
                setRemoved((current) => []);
                setUpdate((current) => current + 1);
              }}
            />

            <Pill
              text={"Add Team"}
              onClick={() => {
                setTeams((current) => {
                  const toAdd = { players: [], teamID: makeID(15) };
                  for (let i = 0; i < groupLimit; i++) {
                    toAdd.players.push({ name: "Empty" });
                  }
                  current.push(toAdd);
                  return current;
                });
                setUpdate((current) => current + 1);
              }}
            />
            <Pill
              onClick={() => {
                setSwapMode((c) => {
                  if (c) {
                    setSwapID("");
                  }
                  console.log(c);
                  return !c;
                });
                setUpdate((c) => c + 1);
              }}
              text={swapMode ? "Disable Swap Mode" : "Enable Swap Mode"}
            />
            {
              <Pill
                text={"Clear Teams"}
                red
                onClick={() => {
                  setTeams([]);
                  setRemoved(players);
                }}
              />
            }
            {timeGroups.length > 0 && false && (
              <Pill
                text={"Tee Times"}
                onClick={() => {
                  setTeams((current) => {
                    console.log(timeGroups);
                    return timeGroups;
                  });
                  setUpdate((current) => current + 1);
                }}
              />
            )}
          </div>
          {removed.length > 0 && (
            <>
              <div className="sub-header">
                <h5>Pending</h5>
              </div>

              <div className="gs-removed">
                {removed.map((player) => (
                  <div key={`${player.playerID}removed`} className="gsr-item">
                    <Avatar alt={player.name} src={player.profileImage} />
                    <p>{player.name}</p>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="evt-dnd">
            <DragDropContext onDragEnd={(e) => handleDrop(e)}>
              {teams.map((team, index) => {
                const { players } = team;
                let teamCt = 0;
                for (let i = 0; i < players.length; i++) {
                  if (players[i].name !== "Empty") {
                    teamCt += 1;
                  }
                }

                const teamName = team.teamName ?? `Team ${index + 1}`;

                return (
                  <Droppable key={`${index}group`} droppableId={`${index}`}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="test-class drop-group"
                      >
                        <ETMTeam
                          swapIndices={swapIndices}
                          setSwapID={setSwapID}
                          setSwapping={setSwapping}
                          swapID={swapID}
                          swapMode={swapMode}
                          swapping={swapping}
                          index={index}
                          live={live}
                          players={players}
                          groupLimit={groupLimit}
                          team={team}
                          teamCt={teamCt}
                          teamName={teamName}
                          setGhostTeam={(e) => setGhostTeam(e)}
                          setRemIndexes={({ i1, i2 }) =>
                            setRemIndexes({ i1, i2 })
                          }
                          setRemoved={(e) => {
                            setRemoved(e);
                            setUpdate((c) => c + 1);
                          }}
                          setShowDia={(e) => setShowDia(e)}
                          setShowGhost={(e) => {
                            console.log(e);
                            setShowGhost(e);
                          }}
                          setShowRemoveTeam={(e) => setShowRemoveTeam(e)}
                          setShowRemoved={(e) => setShowRemoved(e)}
                          setShowSwap={(e) => setShowSwap(e)}
                          setSwapIndices={({ i1, i2 }) =>
                            setSwapIndices({ i1, i2 })
                          }
                          setTeamToEdit={(e) => {
                            setTeamInput(e.teamName);
                            setTeamToEdit(e);
                          }}
                          setTeamToRemove={(e) => setTeamToRemove(e)}
                          setTeams={(e) => setTeams(e)}
                          teeMap={teeMap}
                        />
                        {false && (
                          <>
                            <div className="group-header">
                              <h2 className="ne-header">{teamName}</h2>
                              <div className="flex-align-center">
                                {team.imageLink && (
                                  <Avatar alt={teamName} src={team.imageLink} />
                                )}
                                <p
                                  onClick={() => {
                                    setTeamToRemove(index);
                                    setShowRemoveTeam(true);
                                  }}
                                  className="red-link"
                                >
                                  Remove
                                </p>
                                {live && teamCt < groupLimit && (
                                  <p
                                    onClick={() => {
                                      setGhostTeam(index);
                                      console.log(index);
                                      setShowGhost(true);
                                    }}
                                    className="black-link"
                                  >
                                    Add ghost
                                  </p>
                                )}
                                {!live && (
                                  <MoreVertIcon
                                    className="icon-cursor mr-10"
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                  />
                                )}

                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      setTeamToEdit(team);
                                      setTeamInput(team.teamName);
                                      setShowDia(true);
                                      handleClose();
                                    }}
                                    className="rp-mi"
                                  >
                                    <p>Edit Details</p>
                                  </div>
                                  {/* {teamCt < groupLimit && ( */}
                                  {!league && (
                                    <div
                                      onClick={() => {
                                        console.log(index, team);
                                        setGhostTeam(index);
                                        setShowGhost(true);
                                        handleClose();
                                      }}
                                      className="rp-mi"
                                    >
                                      <p>Add Ghost</p>
                                    </div>
                                  )}
                                  {/* )} */}
                                  <div
                                    onClick={() => {
                                      setTeamToRemove(index);
                                      setShowRemoveTeam(true);
                                      handleClose();
                                    }}
                                    className="rp-mi"
                                  >
                                    <p>Remove</p>
                                  </div>
                                </Menu>
                              </div>
                            </div>
                            {players.map((player, index2) => (
                              <Draggable
                                index={index2}
                                key={
                                  `${player.playerID}${index}${index2}` ??
                                  index2
                                }
                                draggableId={`${player.playerID}`}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    {player.name === "Empty" ? (
                                      <div className="gs-empty">
                                        <AddCircleIcon
                                          onClick={() => {
                                            setRemIndexes({
                                              i1: index,
                                              i2: index2,
                                            });
                                            setShowRemoved(true);
                                          }}
                                          className="big-icon icon-cursor dg-icon"
                                          sx={{ fill: "url(#linearColors)" }}
                                        />
                                      </div>
                                    ) : (
                                      <RoundPlayer
                                        event
                                        hideTee={true}
                                        swapAction={() => {
                                          setSwapIndices({
                                            i1: index,
                                            i2: index2,
                                          });
                                          setShowSwap(true);
                                        }}
                                        removeAction={() => {
                                          alert("rem");
                                          console.log(player.ghost);
                                          if (!player.ghost) {
                                            setRemoved((current) => [
                                              ...current,
                                              player,
                                            ]);
                                          }
                                          setTeams((current) => {
                                            current[index].players[index2] = {
                                              name: "Empty",
                                            };
                                            return current;
                                          });
                                        }}
                                        live
                                        // hideVert
                                        handleSetup={() => {}}
                                        border
                                        tee={teeMap[player.playerID] ?? ""}
                                        playerID={player.playerID}
                                        key={`player${player.playerID}`}
                                        handicap={player.handicap}
                                        imageLink={player.profileImage}
                                        name={player.name}
                                      />
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                );
              })}{" "}
            </DragDropContext>
          </div>
        </>
      )}
    </div>
  );
}

export default EventTeamManager;
