import React from "react";

function LogHeader({
  final = false,
  move = false,
  showTies = false,
  bbs = false,
}) {
  return (
    <div className="player-score log-header log-item stats-header">
      <div className="ps-left">{/* <p>Pos</p> */}</div>
      <div className="ps-right">
        <div className="ps-right-left">
          <div className="hcp-container"></div>
        </div>
        <div className="ps-right-middle">
          <p>Pld</p>
        </div>
        <div className="ps-right-right">
          <p>Pts</p>
        </div>
        <div className="ps-right-right">
          {/* <h3>{wins}</h3> */}
          <p>{bbs ? "For" : "Wins"}</p>
        </div>
        {showTies && !bbs && (
          <div className="ps-right-right">
            <p>Ties</p>
          </div>
        )}
        {showTies && bbs && (
          <div className="ps-right-right">
            <p>Wins</p>
          </div>
        )}

        <div className="ps-right-right">
          <p>{bbs ? "Against" : final ? "Final" : "Ave"}</p>
        </div>
        {move && (
          <div className="ps-right-right">
            <p>Mvmt</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LogHeader;
