import React from "react";

// Components
import LogItem from "../../components/display/LeagueLogs/LogItem/LogItem";
import LogHeader from "../../components/display/LeagueLogs/LogItem/LogHeader";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

function MatchplayGroupTables({
  groups = [],
  playerResults = {},
  playing = [],
}) {
  return (
    <div className="mp-group-tables pd-20">
      {groups.map((group, index) => {
        return (
          <div key={`${index}mpg`} className="mp-gt-group">
            <div className="neh-box">
              <h2 className="ne-header">Group {index + 1}</h2>
            </div>
            <LogHeader />
            {false &&
              group.players.map((player, index2) => {
                return (
                  <LogItem
                    hcp={player.handicap}
                    showBG={playing.includes(player.playerID)}
                    handleClick={() => {}}
                    name={player.name}
                    played={player.played}
                    points={player.points}
                    rank={index2 + 1}
                    wins={player.wins}
                    key={player.playerID ?? player.teamID}
                    ave={
                      player.played === 0
                        ? 0
                        : Math.round(player.points / player.played)
                    }
                  />
                );
              })}
            {group.players.map((player, index2) => {
              const resMap =
                playerResults[player.teamID] ??
                playerResults[player.playerID] ??
                [];
              // console.log(index2, prog);

              let showBG = false;

              return (
                <>
                  {/* {index2 === prog && <div className="mpg-qual"></div>} */}
                  <Accordion>
                    <AccordionSummary>
                      <LogItem
                        showArrow={resMap.length > 0}
                        hcp={player.handicap}
                        showBG={showBG}
                        handleClick={() => {}}
                        name={player.name}
                        noLink={player.playerID === undefined}
                        played={player.played}
                        points={player.points}
                        rank={index2 + 1}
                        wins={player.wins}
                        key={player.playerID}
                        ave={
                          player.played === 0
                            ? 0
                            : Math.round(player.points / player.played)
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      {resMap.map((res) => (
                        <div className="res-pee">
                          <p className={res.type}>{res.text}</p>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default MatchplayGroupTables;
