import React, { useCallback, useState } from "react";
import { db } from "../../../firebase";
import Papa from "papaparse";

import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import { useDropzone } from "react-dropzone";
import BackdropLoader from "../../Loaders/BackdropLoader";
import csvexample from "../../../assets/TeamExample.csv";

function DivisionUpload({
  eventID = "",
  clubID = "",
  close = () => {},
  divisionID = "",
  reload = () => {},
}) {
  console.log(divisionID);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [upload, setUpload] = useState([]);
  const [uploadJSON, setUploadJSON] = useState([]);
  const [players, setPlayers] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        const arr = [];
        const headerRow = res.data[0];
        let plCol = "";
        let idCol = "";
        // console.log(headerRow);
        for (let i = 0; i < headerRow.length; i++) {
          if (headerRow[i] === "platform") {
            plCol = i;
          } else if (headerRow[i] === "platformID") {
            idCol = i;
          }
        }

        if (plCol === "" || idCol === "") {
          return setError(
            "Sorry, we couldn't quite sort that out - please check your column headers!"
          );
        }

        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];
          //   console.log(row[teamCol]);
          arr.push({
            platform: row[plCol],
            platformID: row[idCol],
          });
        }
        console.log(arr);
        loadPlayers(arr);
        setUpload(res);
        setUploadJSON(arr);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  async function loadPlayers(arr = []) {
    const plarr = [];

    setLoading(true);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const clubData = clubDoc.data();
      const { memberIDs } = clubData;
      const refs = memberIDs.map((id) => db.collection("users").doc(id).get());

      const docs = await Promise.all(refs);

      console.log(docs.length);

      for (let i = 0; i < docs.length; i++) {
        const playerDoc = docs[i];
        const playerID = playerDoc.id;
        const player = playerDoc.data();
        if (!playerDoc.exists) {
          continue;
        }
        const { firstName, lastName, profileImage, handicap } = player;
        let found = false;
        if (i === 0) {
          //   console.log(platformIDs);
        }

        const platformIDs = player.platformIDs ?? [];

        for (let n = 0; n < arr.length; n++) {
          if (found) {
            continue;
          }
          const row = arr[n];
          console.log(row);
          const { platform, platformID } = row;

          if (i === 0) {
            // console.log(row);
          }
          for (let x = 0; x < platformIDs.length; x++) {
            const plRow = platformIDs[x];
            const rowPlatform = plRow.platform;
            const rowPlatformID = plRow.platformID;
            if (rowPlatform === platform && rowPlatformID === platformID) {
              plarr.push({
                playerID,
                name: `${firstName} ${lastName}`,
                platform,
                platformID,
                handicap,
                profileImage,
              });

              found = true;
            }
          }
        }
      }
      console.log(plarr);
      setPlayers(plarr);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function savePlayers() {
    setLoading(true);
    try {
      const eventDoc = await db.collection("events").doc(eventID).get();
      const event = eventDoc.data();
      const { divisions } = event;
      for (let i = 0; i < divisions.length; i++) {
        const div = divisions[i];
        if (div.leaderboardID === divisionID) {
          divisions[i].players = [...divisions[i].players, ...players];
        }
      }
      await eventDoc.ref.update({
        divisions,
      });
      reload();

      close();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }

    setLoading(false);
  }

  return (
    <div className="event-player-upload division-upload">
      <BackRow action={close} />
      <PageHeader text="Division Player Upload" />
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <div className="ph-msg">
        <p>
          Upload players to divisions for this event. Please note that players
          that have not yet been added to the event will be ignored
        </p>
      </div>
      <>
        <div className="bi-box">
          <div className="pd-20 bi-drop">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dz-sel">
                {fileName ? (
                  <h5 className="filename">{fileName}</h5>
                ) : (
                  <h5>
                    Drag 'n' drop a .csv file here, or click to open up the file
                    explorer
                  </h5>
                )}
              </div>
            </div>
          </div>
          <div className="bi-info">
            <p>
              In order for the players to be added correctly, formatting is
              important but simple! Only a few fields are needed, the platform
              name and the relevant platform ID.
              <br />
              <br />
              <strong>The first row (header row) will be included.</strong> Name
              your colums as follows (letter case is important):
            </p>
            <ul>
              <li>platform</li>
              <li>platformID</li>
            </ul>
            <a href={csvexample} className="green-link mt-20">
              Download Sample
            </a>
          </div>
        </div>
        {players.length > 0 && (
          <div className="epu-players">
            <div className="sub-header">
              <h5>Selected Players</h5>
            </div>
            <div className="epup-box">
              {players.map((player, index) => (
                <div key={player.playerID} className={"flex-align-center"}>
                  <p style={{ width: "30vw" }}>
                    {player.name}{" "}
                    {player.handicap !== "" && `(hcp ${player.handicap})`}{" "}
                  </p>
                  <p> {player.teeTime}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {uploadJSON.length > 0 && (
          <div className="pd-20">
            <button onClick={savePlayers} className="default-button">
              Save
            </button>
          </div>
        )}
      </>
    </div>
  );
}

export default DivisionUpload;
