/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./CoursePicker.css";
import { admin } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import SearchIcon from "@mui/icons-material/Search";
import Geohash from "latlon-geohash";
import GeohashDistance from "geohash-distance";
import { Country } from "country-state-city";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
// import { geofire } from "geofire";
import { geoFirestore } from "../../../firebase";

import CourseSquare from "./CourseSquare";
import CourseItem from "../../../components/display/CourseItem/CourseItem";
import { TailSpin } from "react-loader-spinner";
import Geocode from "react-geocode";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import CourseSearch from "./CourseSearch";

import { Steps } from "intro.js-react";
import CoursePickerLoader from "../../../components/Loaders/CoursePickerLoader";
import PageHeader from "../../display/Rows/PageHeader";

function CoursePicker({
  setCourseDetails,
  hideBack = false,
  setChosen,
  goBack = () => {},
  round = false,
}) {
  Geocode.setApiKey("AIzaSyB7IUK8lNigjp_8wBSRJYpKQX6ueT8H7D8");
  Geocode.setLocationType("ROOFTOP");
  const [courses, setCourses] = useState([]);
  const [coursesRated, setCoursesRated] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(0);

  const [loadingLoc, setLoadingLoc] = useState(false);

  const [showSearch, setShowSearch] = useState(false);

  const [showAllRated, setShowAllRated] = useState(false);
  const [showAllDistance, setShowAllDistance] = useState(false);

  const [moveRight, setMoveRight] = useState(false);

  const searchRef = useRef();

  const { userDetails, onboarding, setOnboard } = useAuth();

  const [search, setSearch] = useState("");

  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const step = 10;
  const nearRef = useRef();
  const ratedRef = useRef();
  const isScrollingRight = useRef();
  // isScrollingRight.current = moveRight;

  useEffect(() => {
    // console.log(userDetails);
    if (userDetails) {
      setCity(userDetails.city);
      console.log(userDetails.country);
      if (userDetails.country.length === 2) {
        setCountry(Country.getCountryByCode(userDetails.country ?? "ZA").name);
        setState(userDetails.state);

        loadCourses({
          city: userDetails.city,
          state: userDetails.state,
          country: Country.getCountryByCode(userDetails.country ?? "ZA").name,
        });
      } else {
        loadCourses({
          city: userDetails.city,
          state: userDetails.state,
          country: userDetails.country,
        });
      }
    }
  }, [userDetails]);

  function move() {
    // console.log(move);
    if (isScrollingRight.current) {
      nearRef.current.scrollLeft += 1000;
      requestAnimationFrame(move);
    }
  }

  useEffect(() => {
    if (onboarding) {
      // console.log(onboarding);
      if (onboarding.course === false || onboarding.course === undefined) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);

  useEffect(() => {
    // test();
  }, []);
  async function test() {
    // Create a GeoCollection reference
    const geocollection = geoFirestore.collection("restaurants");

    // Add a GeoDocument to a GeoCollection
    // geocollection.add({
    //   name: "Geofirestore",
    //   score: 100,
    //   coordinates: new admin.firestore.GeoPoint(40.7589, -73.9851),
    // });

    // Create a GeoQuery based on a location
    const query = geocollection.near({
      center: new admin.firestore.GeoPoint(40.7589, -73.9851),
      radius: 1000,
    });

    // Get query (as Promise)
    query.get().then((value) => {
      // All GeoDocument returned by GeoQuery, like the GeoDocument added above
      // console.log(value.docs);
    });
  }

  function compareDistance(a, b) {
    // console.log(a,b)
    if (a.distance < b.distance) {
      return -1;
    }
    if (a.distance > b.distance) {
      return 1;
    }
    return 0;
  }

  function compareRating(a, b) {
    if (a.rating < b.rating) {
      return 1;
    }
    if (a.rating > b.rating) {
      return -1;
    }
    return 0;
  }

  async function getUserLocation() {
    // alert("ayyyye");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          setLoadingLoc(true);
          // console.log(pos);
          const res = await Geocode.fromLatLng(
            pos.coords.latitude,
            pos.coords.longitude
          ).catch((err) => console.log(err));
          // console.log(res);
          let city, state, country;
          for (let i = 0; i < res.results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < res.results[0].address_components[i].types.length;
              j++
            ) {
              switch (res.results[0].address_components[i].types[j]) {
                case "locality":
                  city = res.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = res.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = res.results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }
          setCity(city);
          setCountry(country);
          setState(state);
          setUpdate((current) => current + 1);
          loadCourses({ city, country, state });
          setLoadingLoc(false);
        },
        (e) => {
          console.log("ERROR");
          console.log(e);
          alert("Sorry, we are having trouble locating you");
          setShowSearch(true);
        },
        { enableHighAccuracy: false, timeout: 10000 }
      );
    }
  }

  async function loadCourses({ city, country, state }) {
    if (navigator.geolocation) {
      console.log("here");

      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          // const center = [pos.coords.latitude, pos.coords.longitude];
          // const bounds = geofire.geohashQueryBounds(center, 50000);
          // const b2 = geoFirestore.
          console.log("1");

          const userHash = Geohash.encode(
            pos.coords.latitude,
            pos.coords.longitude,
            10
          );
          console.log("2");

          const center = new admin.firestore.GeoPoint(
            pos.coords.latitude,
            pos.coords.longitude
          );

          // const center = new admin.firestore.GeoPoint(40.7589, -73.9851);
          let city, state, country;
          const res = await Geocode.fromLatLng(
            pos.coords.latitude,
            pos.coords.longitude
          ).catch((err) => console.log(err));

          for (let i = 0; i < res.results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < res.results[0].address_components[i].types.length;
              j++
            ) {
              switch (res.results[0].address_components[i].types[j]) {
                case "locality":
                  city = res.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = res.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = res.results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }
          setCity(city);
          setCountry(country);
          setState(state);

          const geoColl = geoFirestore.collection("courses");
          const query = geoColl
            .near({
              center,
              radius: 100,
            })
            .limit(30);
          const courses = await query.get();

          const arr = [];
          const sarr = [];

          for (let i = 0; i < courses.docs.length; i++) {
            const courseID = courses.docs[i].id;
            const course = courses.docs[i].data();
            if (i === 0) {
              // console.log(course);
            }
            const {
              address,
              city,
              country,
              location,
              name,
              imageLink,
              teeArray,
              tees,
              par,
              length,
            } = course;
            const roundArray = course.roundArray ?? [];
            const totalReviews = course.totalReviews ?? 0;
            const rating = course.rating ?? 5;

            const hash = Geohash.encode(location._lat, location._long, 10);
            // await courses.docs[i].ref.update({
            //   g: {
            //     geohash: hash,
            //     geopoint: location,
            //   },
            // });
            // console.log("stored location");
            const distance =
              Math.round(GeohashDistance.inKm(userHash, hash) * 10) / 10;

            arr.push({
              address,
              city,
              country,
              rating,
              location,
              name,
              courseID,
              distance,
              imageLink,
              roundArray,
              teeArray,
              tees,
              par,
              totalReviews,
              length,
            });
            sarr.push({
              address,
              city,
              country,
              rating,
              location,
              name,
              courseID,
              distance,
              imageLink,
              roundArray,
              teeArray,
              tees,
              par,
              totalReviews,
              length,
            });
          }
          arr.sort(compareDistance);
          setCourses(arr);

          sarr.sort(compareRating);
          setCoursesRated(sarr);

          setLoaded(true);
        },
        (err) => {
          setShowSearch(true);
          setLoaded(true);
          console.log(err);
        },
        { timeout: 5000, enableHighAccuracy: false }
      );
    } else {
      setShowSearch(true);
    }
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);

  const steps = [
    {
      element: ".grwbefd",
      intro: "Tap on a course to select it",
    },
    {
      element: ".course-dist",
      intro: "The courses nearest to you will be displayed here",
      position: "top",
    },
    {
      element: ".course-rate",
      intro: "The top rated courses in youre area will be displayed here",
      position: "top",
    },
  ];

  return (
    <div className="course-picker">
      {!showSearch && (
        <>
          <div
            onClick={() => {
              if (showSearch) {
                setShowSearch(false);
              } else if (showAllDistance) {
                setShowAllDistance(false);
              } else if (showAllRated) {
                setShowAllRated(false);
              } else {
                goBack();
              }
            }}
            className="back-row"
          >
            {!hideBack && <ArrowBackIcon className="ts-arrow" />}
            <p style={{ marginLeft: hideBack ? "0px" : "20px" }}>Back</p>
          </div>

          <PageHeader text="Courses" />
          <div className="cp-info-text">
            <p>Choose a course for this {round ? "round" : "event"}</p>
            <p className="info-pee">
              Should you be unable to find your course, please let us know via
              the <span>Support Page</span> and we will do our best to rectify
              that asap
            </p>
          </div>
        </>
      )}

      {/* <hr className="cp-hr" /> */}

      {!loaded ? (
        <CoursePickerLoader />
      ) : showSearch ? (
        <CourseSearch
          goBack={() => setShowSearch(false)}
          setChosen={setChosen}
          setCourseDetails={setCourseDetails}
          fullBack={goBack}
        />
      ) : (
        <>
          {showAllDistance && (
            <>
              <div className="nc-head pl-20  mt-20">
                <h2 className="ne-header">Nearby Courses</h2>
                {/* <h2 onClick={() => setShowAllDistance(false)} className="green">
                  Back
                </h2> */}
              </div>
              <div className="scale-in-ps square-square">
                <div className="cp-search">
                  <SearchIcon className="cps-svg" />
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    defaultValue={search}
                    placeholder="Type to search..."
                    ref={searchRef}
                  />
                </div>
              </div>

              <div className="scale-in-ps">
                {courses.map((course) => {
                  if (search !== "") {
                    if (
                      !course.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return null;
                    }
                  }

                  return (
                    <CourseItem
                      key={course.courseID}
                      showCourse={() => {
                        setCourseDetails({
                          name: course.name,
                          city: course.city,
                          country: course.country,
                          rating: course.rating,
                          par: course.par[course.teeArray[0]],
                          totalReviews: course.totalReviews,
                          roundArray: course.roundArray,
                          tees: course.tees,
                          teeArray: course.teeArray,
                          //  par: course.par,
                          imageLink: course.imageLink,
                          length: course.length,
                          courseID: course.courseID,
                        });
                        setChosen(true);
                      }}
                      city={course.city}
                      country={course.country}
                      courseName={course.name}
                      image={course.imageLink}
                      rating={course.rating}
                    />
                  );
                })}
              </div>
            </>
          )}

          {showAllRated && (
            <>
              <div className="nc-head pl-20 mt-20">
                <h2 className="ne-header">Top Rated</h2>
                <h2 onClick={() => setShowAllRated(false)} className="green">
                  Back
                </h2>
              </div>
              <div className="square-square"></div>
              <div className="cp-search">
                <SearchIcon className="cps-svg" />
                <input placeholder="Type to search..." ref={searchRef} />
              </div>
              <div className="scale-in-ps">
                {coursesRated.map((course) => {
                  if (search !== "") {
                    if (
                      !course.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return null;
                    }
                  }
                  return (
                    <CourseItem
                      key={course.courseID}
                      showCourse={() => {
                        setCourseDetails({
                          name: course.name,
                          city: course.city,
                          country: course.country,
                          rating: course.rating,
                          par: course.par[course.teeArray[0]],
                          totalReviews: course.totalReviews,
                          roundArray: course.roundArray,
                          tees: course.tees,
                          teeArray: course.teeArray,
                          //  par: course.par,
                          imageLink: course.imageLink,
                          length: course.length,
                          courseID: course.courseID,
                        });
                        setChosen(true);
                      }}
                      city={course.city}
                      country={course.country}
                      courseName={course.name}
                      image={course.imageLink}
                      rating={course.rating}
                    />
                  );
                })}
              </div>
            </>
          )}

          {!showAllDistance && !showAllRated && (
            <>
              <h5
                onClick={() => setShowSearch(true)}
                style={{ marginLeft: "20px", fontSize: "14px" }}
                className="green-link"
              >
                Search for course
              </h5>
              <div className="cp-location">
                <h5>
                  {city}, {state}, {country}
                </h5>
                {loadingLoc ? (
                  <TailSpin color="#21c17c" height={25} width={25} />
                ) : (
                  <LocationSearchingIcon
                    onClick={getUserLocation}
                    className=" dg-icon-bg"
                  />
                )}
              </div>
              <div className="course-dist nearby-courses">
                <div className="nc-head">
                  <h2 className="ne-header">Nearby Courses</h2>
                  <h2
                    onClick={() => setShowAllDistance(true)}
                    className="green-link"
                  >
                    View All
                  </h2>
                </div>

                <div className="cs-container">
                  <div ref={nearRef} className="course-slider scale-in-ps">
                    {courses.map((course) => (
                      <CourseSquare
                        key={course.courseID}
                        handleClick={() => {
                          setCourseDetails({
                            name: course.name,
                            city: course.city,
                            country: course.country,
                            rating: course.rating,
                            par: course.par[course.teeArray[0]],
                            totalReviews: course.totalReviews,
                            roundArray: course.roundArray,
                            tees: course.tees,
                            teeArray: course.teeArray,
                            //  par: course.par,
                            imageLink: course.imageLink,
                            length: course.length,
                            courseID: course.courseID,
                          });
                          setChosen(true);
                        }}
                        distance={course.distance}
                        image={course.imageLink}
                        name={course.name}
                        rating={course.rating}
                      />
                    ))}
                  </div>
                  <div className="right-arrow">
                    <ArrowCircleRightIcon
                      onClick={() => (nearRef.current.scrollLeft += 400)}
                    />
                  </div>
                  <div className="left-arrow">
                    <ArrowCircleLeftRoundedIcon
                      onClick={() => (nearRef.current.scrollLeft -= 400)}
                    />
                  </div>
                </div>
              </div>

              <div className="course-rate nearby-courses">
                <div className="nc-head">
                  <h2 className="ne-header">Top Rated</h2>
                  <h2
                    onClick={() => setShowAllRated(true)}
                    className="green-link"
                  >
                    View All
                  </h2>
                </div>

                <div className="cs-container">
                  <div ref={ratedRef} className="course-slider">
                    {coursesRated.map((course) => (
                      <CourseSquare
                        key={course.courseID}
                        showCourse={() => {
                          setCourseDetails({
                            name: course.name,
                            city: course.city,
                            country: course.country,
                            rating: course.rating,
                            par: course.par[course.teeArray[0]],
                            totalReviews: course.totalReviews,
                            roundArray: course.roundArray,
                            tees: course.tees,
                            teeArray: course.teeArray,
                            //  par: course.par,
                            imageLink: course.imageLink,
                            length: course.length,
                            courseID: course.courseID,
                          });
                          setChosen(true);
                        }}
                        handleClick={() => {
                          setCourseDetails({
                            name: course.name,
                            city: course.city,
                            country: course.country,
                            rating: course.rating,
                            par: course.par[course.teeArray[0]],
                            totalReviews: course.totalReviews,
                            roundArray: course.roundArray,
                            tees: course.tees,
                            teeArray: course.teeArray,
                            //  par: course.par,
                            imageLink: course.imageLink,
                            length: course.length,
                            courseID: course.courseID,
                          });
                          setChosen(true);
                        }}
                        distance={course.distance}
                        image={course.imageLink}
                        name={course.name}
                        rating={course.rating}
                      />
                    ))}
                  </div>
                  <div className="right-arrow">
                    <ArrowCircleRightIcon
                      onClick={() => (ratedRef.current.scrollLeft += 400)}
                    />
                  </div>
                  <div className="left-arrow">
                    <ArrowCircleLeftRoundedIcon
                      onClick={() => (ratedRef.current.scrollLeft -= 400)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CoursePicker;
