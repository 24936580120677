import React from "react";
import "./SimplePlayer.css";

import { Avatar } from "@mui/material";

function SimplePlayer({ imageLink, name, handleClick, city, country }) {
  return (
    <div onClick={handleClick} className="simple-player">
      <div className="simple-left">
        <Avatar alt={name} src={imageLink} />
      </div>
      <div className="simple-right">
        <h2>{name}</h2>
        <p>
          {city}, {country}
        </p>
      </div>
    </div>
  );
}

export default SimplePlayer;
