import React, { useState, useEffect } from "react";
import "./CourseStats.css";
import { db } from "../../firebase";
import { useParams, useNavigate } from "react-router-dom";

// Icons

// Componenets
import BackdropLoader from "../Loaders/BackdropLoader";
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import { Tab, Tabs } from "@mui/material";
import { IoGolfSharp } from "react-icons/io5";
import StatsItem from "../ClientStats/StatsItem/StatsItem";
function CourseStats() {
  // Admin
  const courseID = useParams().id;
  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    courseName: "",
    teeArray: [],
    tees: {},
    par: {},
    backNineLength: {},
    frontNineLength: {},
    lastNine: {},
    length: {},
    firstNine: {},
    country: "",
    city: "",
  });
  const [selTab, setSelTab] = useState("card");
  const [holeStats, setHoleStats] = useState([]);
  const [stats, setStats] = useState({});

  // UE
  useEffect(() => {
    loadDetails();
  }, []);

  // Funcs
  async function loadDetails() {
    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    console.log(course);
    const holeStats = course.holeStats ?? [];
    const {
      name,
      teeArray,
      tees,
      par,
      backNineLength,
      frontNineLength,
      firstNine,
      lastNine,
      length,
      city,
      country,
    } = course;

    teeArray.sort((a, b) => tees[b][0].length - tees[a][0].length);

    setDetails({
      courseName: name,
      teeArray,
      tees,
      backNineLength,
      firstNine,
      frontNineLength,
      lastNine,
      par,
      length,
      city,
      country,
    });

    const stats = course.stats ?? {};

    let frontPuttTotal = 0;
    let backPuttTotal = 0;
    let frontGIRTotal = 0;
    let backGIRTotal = 0;
    let frontFIRTotal = 0;
    let backFIRTotal = 0;
    let backParThrees = 0;
    let frontParThrees = 0;

    for (let i = 0; i < holeStats.length; i++) {
      const hole = holeStats[i];
      const { averagePutts, firAverage, girAverage, par } = hole;
      if (i < 9) {
        frontPuttTotal += averagePutts;
        frontGIRTotal += girAverage;
        if (par === 3) {
          frontParThrees += 1;
        } else {
          frontFIRTotal += firAverage;
        }
      } else {
        backPuttTotal += averagePutts;
        backGIRTotal += girAverage;
        if (par === 3) {
          backParThrees += 1;
        } else {
          backFIRTotal += firAverage;
        }
      }
    }

    const totalParThrees = backParThrees + frontParThrees;
    const totalGIR = frontGIRTotal + backGIRTotal;
    const totalFIR = frontFIRTotal + backFIRTotal;
    const totalPutts = frontPuttTotal + backPuttTotal;

    const totalGIRAve = Math.round((totalGIR / 18) * 100);
    const totalFIRAve = Math.round((totalFIR / (18 - totalParThrees)) * 100);
    const totalPuttAve = Math.round((totalPutts / 18) * 100) / 100;

    const frontGIRAve = Math.round((frontGIRTotal / 9) * 100);
    const frontFIRAve = Math.round(
      (frontFIRTotal / (9 - frontParThrees)) * 100
    );
    const frontPuttAve = Math.round((frontPuttTotal / 9) * 100) / 100;

    const backGIRAve = Math.round((backGIRTotal / 9) * 100);
    const backFIRAve = Math.round((backFIRTotal / (9 - backParThrees)) * 100);
    const backPuttAve = Math.round((backPuttTotal / 9) * 100) / 100;

    stats.totalGIRAve = totalGIRAve;
    stats.totalFIRAve = totalFIRAve;
    stats.totalPuttAve = totalPutts;

    stats.frontGIRAve = frontGIRAve;
    stats.frontFIRAve = frontFIRAve;
    stats.frontPuttAve = Math.round(frontPuttTotal * 100) / 100;

    stats.backGIRAve = backGIRAve;
    stats.backFIRAve = backFIRAve;
    stats.backPuttAve = Math.round(backPuttTotal * 100) / 100;

    stats.totalParThrees = totalParThrees;
    stats.frontParThrees = frontParThrees;
    stats.backParThrees = backParThrees;

    setStats(stats);
    setHoleStats(holeStats);
    setLoading(false);
  }

  return (
    <div className="course-stats-page client-stats">
      {loading && <BackdropLoader />}
      <BackRow action={() => navigate(-1)} />
      <PageHeader text={details.courseName} />

      <div className="rc-ifo bs-subtle bss-white ml-20">
        <div className="rci-hcp">
          <p>Country:</p>
          <h5>{details.country}</h5>
        </div>
        <div className="rci-hcp">
          <p>City:</p>
          <h5>{details.city}</h5>
        </div>
      </div>

      <div className="ec-tabs mt-20">
        <Tabs value={selTab} onChange={(e, v) => setSelTab(v)}>
          <Tab label="Card" value={"card"} />
          <Tab value={"stats"} label="Stats" />
          {/* <Tab value={"courses"} label="Courses" /> */}
        </Tabs>
      </div>

      {selTab === "card" && (
        <div className="cs-card ">
          <div className="rc-sc bs-subtle">
            <table>
              <thead>
                <tr>
                  <th className="rc-head-ico">
                    <IoGolfSharp />
                  </th>
                  {details.teeArray.map((tee, i) => {
                    return (
                      <th style={{ textTransform: "capitalize" }}>{tee}</th>
                    );
                  })}
                  <th>Par</th>
                  <th>Stroke</th>

                  <th>Gross</th>
                  <th>Net</th>
                  <th>Putts</th>
                  <th>GIR</th>
                  <th>FIR</th>
                </tr>
              </thead>
              <tbody>
                {holeStats.map((hole, i) => {
                  const courseHole = hole;
                  const prev = hole.grossHistory ?? [];

                  const tees = details.tees;

                  return (
                    <React.Fragment key={`hole${i}`}>
                      {" "}
                      {i === 9 && (
                        <tr className="rc-row tot-row">
                          <td>OUT</td>
                          {details.teeArray.map((tee, i) => {
                            return (
                              <td style={{ textTransform: "capitalize" }}>
                                {details.frontNineLength[tee]}
                              </td>
                            );
                          })}
                          <td>{details.firstNine[details.teeArray[0]]}</td>
                          <td></td>

                          <td>{stats.frontNineGrossAverage}</td>
                          <td>{stats.frontNineNetAverage}</td>
                          <td>{stats.frontPuttAve}</td>
                          <td>{stats.frontGIRAve}%</td>
                          <td>{stats.frontFIRAve}%</td>
                        </tr>
                      )}
                      <tr className="rc-row">
                        <td>{i + 1}</td>
                        {details.teeArray.map((tee, j) => {
                          const hole = tees[tee][i];
                          return (
                            <td style={{ textTransform: "capitalize" }}>
                              {hole.length}
                            </td>
                          );
                        })}
                        <td>{hole.par}</td>
                        <td>{hole.stroke}</td>

                        <td
                          className={
                            courseHole.grossAverage < hole.par
                              ? "ud-par"
                              : courseHole.grossAverage > hole.par
                              ? "over-par"
                              : ""
                          }
                        >
                          {courseHole.grossAverage}
                        </td>
                        <td
                          className={
                            courseHole.netAverage < hole.par
                              ? "ud-par"
                              : courseHole.netAverage > hole.par
                              ? "over-par"
                              : ""
                          }
                        >
                          {courseHole.netAverage}
                        </td>
                        <td>{courseHole.averagePutts}</td>
                        <td>
                          {Math.round(courseHole.girAverage * 10000) / 100}%
                        </td>
                        <td>
                          {courseHole.par === 3
                            ? "-"
                            : `${
                                Math.round(courseHole.firAverage * 10000) / 100
                              }%`}
                        </td>
                        {/* <td></td> */}
                      </tr>
                      {i === 17 && (
                        <tr className="rc-row tot-row">
                          <td>IN</td>
                          {details.teeArray.map((tee, i) => {
                            return (
                              <td style={{ textTransform: "capitalize" }}>
                                {details.backNineLength[tee]}
                              </td>
                            );
                          })}
                          <td>{details.lastNine[details.teeArray[0]]}</td>
                          <td></td>

                          <td>{stats.backNineGrossAverage}</td>
                          <td>{stats.backNineNetAverage}</td>
                          <td>{stats.backPuttAve}</td>
                          <td>{stats.backGIRAve}%</td>
                          <td>{stats.backFIRAve}%</td>
                        </tr>
                      )}
                      {i === 17 && (
                        <tr className="rc-row tot-row">
                          <td>OUT</td>
                          {details.teeArray.map((tee, i) => {
                            return (
                              <td style={{ textTransform: "capitalize" }}>
                                {details.frontNineLength[tee]}
                              </td>
                            );
                          })}
                          <td>{details.firstNine[details.teeArray[0]]}</td>
                          <td></td>

                          <td>{stats.frontNineGrossAverage}</td>
                          <td>{stats.frontNineNetAverage}</td>
                          <td>{stats.frontPuttAve}</td>
                          <td>{stats.frontGIRAve}%</td>
                          <td>{stats.frontFIRAve}%</td>
                        </tr>
                      )}
                      {i === 17 && (
                        <tr className="rc-row tot-row">
                          <td>TOT</td>
                          {details.teeArray.map((tee, i) => {
                            return (
                              <td style={{ textTransform: "capitalize" }}>
                                {details.length[tee]}
                              </td>
                            );
                          })}
                          <td>{details.par[details.teeArray[0]]}</td>
                          <td></td>

                          <td>{stats.averageGross}</td>
                          <td>{stats.averageNet}</td>
                          <td>{Math.round(stats.totalPuttAve * 100) / 100}</td>
                          <td>{stats.totalGIRAve}%</td>
                          <td>{stats.totalFIRAve}%</td>
                          {/* <td>{stats.averagePutts}</td>
                          <td>{stats.girAve}</td>
                          <td>{stats.firAve}</td> */}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {selTab === "stats" && (
        <div className="csbs-box">
          <div className="bs-subtle bss-white">
            <div className="neh-box">
              <h2 className="ne-header">Scoring (Overall)</h2>{" "}
            </div>

            <div className="rs-box csp-rs flex flex-wrap">
              <StatsItem
                stat={stats.averageGross}
                hideComp
                title="Average Gross Score"
              />
              <StatsItem
                stat={stats.averageNet}
                hideComp
                title="Average Net Score"
                grey
              />
              <StatsItem
                stat={stats.lowestGross}
                hideComp
                grey
                title="Lowest Gross Score"
              />
              <StatsItem
                stat={stats.lowestNet}
                hideComp
                title="Lowest Net Score"
              />
              <StatsItem
                stat={stats.highestGross}
                hideComp
                title="Highest Gross Score"
              />
              <StatsItem
                stat={stats.highestNet}
                hideComp
                grey
                title="Highest Net Score"
              />
              <StatsItem
                stat={Math.round((stats.totalGIRAve * 18) / 100)}
                hideComp
                title="Average Greens In Regulation"
                grey
              />
              <StatsItem
                stat={
                  Math.round(stats.totalFIRAve * (18 - stats.totalParThrees)) /
                  100
                }
                hideComp
                title="Average Fairways In Regulation"
              />
            </div>
            <div className="neh-box">
              <h2 className="ne-header">Scoring (Front 9)</h2>
            </div>
            <div className="rs-box csp-rs flex flex-wrap">
              <StatsItem
                stat={stats.frontNineGrossAverage}
                hideComp
                title="Average Gross Score"
              />
              <StatsItem
                stat={stats.frontNineNetAverage}
                hideComp
                grey
                title="Average Net Score"
              />
              <StatsItem
                stat={stats.frontNineLowestGross}
                hideComp
                grey
                title="Lowest Gross Score"
              />
              <StatsItem
                stat={stats.frontNineLowestNet}
                hideComp
                title="Lowest Net Score"
              />
              <StatsItem
                stat={stats.frontNineHighestGross}
                hideComp
                title="Highest Gross Score"
              />
              <StatsItem
                stat={stats.frontNineHighestNet}
                hideComp
                grey
                title="Highest Net Score"
              />
              <StatsItem
                stat={Math.round(stats.frontGIRAve * 9) / 100}
                hideComp
                grey
                title="Average Greens In Regulation"
              />
              <StatsItem
                stat={
                  Math.round(stats.frontFIRAve * (9 - stats.frontParThrees)) /
                  100
                }
                hideComp
                title="Average Fairways In Regulation"
              />
            </div>
            <div className="neh-box">
              <h2 className="ne-header">Scoring (Back 9)</h2>
            </div>
            <div className="rs-box csp-rs flex flex-wrap">
              <StatsItem
                stat={stats.backNineGrossAverage}
                hideComp
                title="Average Gross Score"
              />
              <StatsItem
                stat={stats.backNineNetAverage}
                hideComp
                grey
                title="Average Net Score"
              />
              <StatsItem
                stat={stats.backNineLowestGross}
                hideComp
                grey
                title="Lowest Gross Score"
              />
              <StatsItem
                stat={stats.backNineLowestNet}
                hideComp
                //   grey
                title="Lowest Net Score"
              />
              <StatsItem
                stat={stats.backNineHighestGross}
                hideComp
                //   grey
                title="Highest Gross Score"
              />
              <StatsItem
                stat={stats.backNineHighestNet}
                hideComp
                grey
                title="Highest Net Score"
              />
              <StatsItem
                stat={Math.round(stats.backGIRAve * 9) / 100}
                hideComp
                grey
                title="Average Greens In Regulation"
              />
              <StatsItem
                stat={
                  Math.round(stats.backFIRAve * (9 - stats.backParThrees)) / 100
                }
                hideComp
                title="Average Fairways In Regulation"
              />
            </div>
            <div className="neh-box">
              <h2 className="ne-header">Putting</h2>
            </div>
            <div className="rs-box csp-rs flex flex-wrap">
              <StatsItem
                stat={stats.averagePutts}
                hideComp
                title="Average Putts"
              />
              <StatsItem
                stat={stats.fewestPutts}
                hideComp
                title="Fewest Putts"
                grey
              />
              <StatsItem
                stat={stats.frontPuttAve}
                hideComp
                title="Average (Front 9)"
                grey
              />
              <StatsItem
                stat={stats.backPuttAve}
                hideComp
                title="Average (Back 9)"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseStats;
