import { Avatar, Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import "./Loaders.css";

function BackdropLoader() {
  return (
    <div className="backdrop-loader">
      <Backdrop
        // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div className="bl-circle">
          <CircularProgress color="inherit" />
          {/* <Avatar src={pin} alt="loader" /> */}
        </div>
      </Backdrop>
    </div>
  );
}

export default BackdropLoader;
