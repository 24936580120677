/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./ChatDrop.css";

import ChatPreview from "./ChatPreview/ChatPreview";
import ChatPage from "./ChatPage/ChatPage";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import { Dialog } from "@mui/material";

import { db } from "../../firebase";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { BallTriangle } from "react-loader-spinner";

import { useAuth } from "../../contexts/AuthContext";
import PlayerLoader from "../Loaders/PlayerLoader";
import Pill from "../Buttons/Pill";

import BroadcastModal from "../BroadcastModal/BroadcastModal";

import moment from "moment/moment";

const GradientIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon sx={{ fill: "url(#linearColors)" }} />
  </>
);

function ChatDrop({
  goBack,
  height,
  messages = [],
  message = "",
  friends = [],
  newChat = true,
  newUser = {},
  setNewUser = () => {},
  setChatID = () => {},
  chatID = "",
  createAdmin = false,
  setCreateAdmin = false,
}) {
  const [showChat, setShowChat] = useState(false);
  const [chat, setChat] = useState();
  const [searchInput, setSearchInput] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingChats, setLoadingChats] = useState(true);
  const [showBC, setShowBC] = useState(false);

  const [showNew, setShowNew] = useState(false);

  const [adminChat, setAdminChat] = useState("");

  const [users, setUsers] = useState([]);

  const { currentUser } = useAuth();

  const [chats, setChats] = useState([]);

  useEffect(() => {
    // console.log(newChat, newUser);
    if (messages.length > 0) {
      setLoadingChats(false);
      loadFriends();
    }
  }, [messages]);

  useEffect(() => {
    if (newChat) {
      createChat();
    }
  }, [newUser]);

  useEffect(() => {
    // console.log(chatID);

    if (createAdmin) {
      createAdminChat();
      setCreateAdmin(false);
    }

    if (chatID !== "") {
      for (let i = 0; i < messages.length; i++) {
        const chat = messages[i];

        if (chat.chatID === chatID) {
          console.log(chat);
          setChat(
            <ChatPage
              groupID={chat.groupID}
              groupImage={chat.image}
              chatName={chat.name}
              message={message}
              players={chat.players}
              adminIDs={chat.adminIDs}
              chatType={chat.chatType}
              isGroupChat={chat.isGroupChat}
              goBack={() => setShowChat(false)}
              userID={chat.receiverID}
              docID={chat.chatID}
              hide={goBack}
            />
          );
          setShowChat(true);
          setChatID("");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      checkAdminChat();
    }
  }, [currentUser]);

  async function checkAdminChat() {
    const adminChatDocs = await db
      .collection("chats")
      .where("userIDs", "array-contains", currentUser.uid)
      .where("adminChat", "==", true)
      .get();

    if (adminChatDocs.docs.length > 0) {
      const chatDoc = adminChatDocs.docs[0];
      const chatID = chatDoc.id;
      setAdminChat(chatID);
    }
  }

  async function createAdminChat() {
    // return console.log("boobs");
    setLoading(true);
    try {
      const chatDoc = await db.collection("chats").add({
        adminChat: true,
        userIDs: ["admin", currentUser.uid],
        dateCreated: new Date(),
        lastMessage: {
          text: "Hi there, welcome to the UGA support chat. How can we help you?",
          sentAt: new Date(),
          sender: "admin",
          receiver: currentUser.uid,
          read: false,
        },
      });
      await chatDoc.collection("messages").add({
        text: "Hi there, welcome to the UGA support chat. How can we help you?",
        sentAt: new Date(),
        sender: "admin",
        receiver: currentUser.uid,
        read: false,
      });
      setAdminChat(chatDoc.id);
      setChat(
        <ChatPage
          docID={chatDoc.id}
          groupImage={""}
          message={message}
          chatName={"UGA Support"}
          players={["admin", currentUser.uid]}
          isGroupChat={false}
          goBack={() => setShowChat(false)}
          userID={"admin"}
          hide={goBack}
        />
      );
      setShowChat(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function loadFriends() {
    setLoading(true);
    const newArr = friends;
    for (let i = 0; i < messages.length; i++) {
      if (friends.includes(messages[i].receiverID)) {
        const index = newArr.indexOf(messages[i].receiverID);
        // console.log(index);

        newArr.splice(index, 1);
      }
    }

    const arr = [];
    const refs = newArr.map((id) => db.collection("users").doc(id).get());
    const docs = await Promise.all(refs);
    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const uid = playerDoc.id;
      const player = playerDoc.data();
      if (!player) {
        continue;
      }
      const { firstName, lastName, profileImage, handicap, settings, friends } =
        player;
      if (settings.messageFriendsOnly && friends.includes(currentUser.uid)) {
        continue;
      }
      arr.push({
        playerID: uid,
        name: `${firstName} ${lastName}`,
        profileImage,
        handicap,
      });
    }

    setUsers(arr);
    setLoading(false);
  }

  async function createChat() {
    setChat(
      <ChatPage
        // groupID={chat.groupID}
        groupImage={newUser.profileImage}
        chatName={newUser.name}
        players={[currentUser.uid, newUser.playerID]}
        isGroupChat={false}
        goBack={() => setShowChat(false)}
        userID={newUser.playerID}
        // docID={chat.chatID}
        hide={goBack}
      />
    );
    setShowChat(true);
    setNewUser(false);
  }

  return (
    <div
      className="chat-drop"
      // style={{ height }}
    >
      {showBC && (
        <BroadcastModal
          showChat={(id) => {
            for (let i = 0; i < messages.length; i++) {
              const chat = messages[i];

              if (chat.chatID === id) {
                console.log(chat);
                setChat(
                  <ChatPage
                    groupID={chat.groupID}
                    groupImage={chat.image}
                    chatName={chat.name}
                    players={chat.players}
                    adminIDs={chat.adminIDs}
                    chatType={chat.chatType}
                    isGroupChat={chat.isGroupChat}
                    goBack={() => setShowChat(false)}
                    userID={chat.receiverID}
                    docID={chat.chatID}
                    hide={goBack}
                  />
                );
                setShowChat(true);
              }
            }
          }}
          close={() => setShowBC(false)}
        />
      )}
      <Dialog open={showNew} onClose={() => setShowNew(false)}>
        <div className="dialog-content nc-dialog p-20">
          <div className="new-chat-message">
            <p>Select a player below to initiate a new chat</p>
          </div>
          <div className="input-group mb-20">
            <p>Search</p>
            <input
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              type="text"
              placeholder="Search for player..."
              className="default-input"
            />
          </div>
          <div className="nc-players">
            {loading && (
              <div className="ri-loaders">
                <PlayerLoader />
                <PlayerLoader />
                <PlayerLoader />
                <PlayerLoader />
              </div>
            )}
            {users.map((user, index) => {
              if (searchInput !== "") {
                if (
                  user.name.toLowerCase().includes(searchInput.toLowerCase())
                ) {
                  return (
                    <PlayerItem
                      hideIcons
                      key={user.playerID}
                      img={user.profileImage}
                      name={user.name}
                      hcp={user.handicap}
                      showProfile={() => {
                        setChat(
                          <ChatPage
                            groupImage={user.profileImage}
                            chatName={user.name}
                            players={[currentUser.uid, user.playerID]}
                            isGroupChat={false}
                            goBack={() => setShowChat(false)}
                            userID={user.playerID}
                            hide={goBack}
                          />
                        );
                        setShowNew(false);
                        setShowChat(true);
                      }}
                    />
                  );
                } else {
                  return null;
                }
              }

              return (
                <PlayerItem
                  hideIcons
                  key={user.playerID}
                  img={user.profileImage}
                  name={user.name}
                  hcp={user.handicap}
                  showProfile={() => {
                    setChat(
                      <ChatPage
                        groupImage={user.profileImage}
                        chatName={user.name}
                        players={[currentUser.uid, user.playerID]}
                        isGroupChat={false}
                        goBack={() => setShowChat(false)}
                        userID={user.playerID}
                        hide={goBack}
                      />
                    );
                    setShowNew(false);
                    setShowChat(true);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Dialog>
      {false ? (
        <div className="loading-container elc">
          <BallTriangle color="#1e7a69" height={80} width={80} />
          <p>Loading chats...</p>
        </div>
      ) : showChat ? (
        chat
      ) : (
        <>
          <div className="np-header">
            <div className="nph-left">
              <h3 className="ne-header" style={{ marginLeft: "0px" }}>
                Chat
              </h3>
            </div>
            {/* <div onClick={() => setShowNew(true)} className="svg-cont">
              <GradientIcon />
            </div> */}

            {/* <AddCircleIcon
              onClick={() => setShowNew(true)}
              className="info-icon"
            /> */}
          </div>
          <div className="chat-pills">
            <Pill onClick={() => setShowNew(true)} text={"New Chat"} />
            <Pill text={"Group Message"} onClick={() => setShowBC(true)} />
            {false && (
              <Pill
                text={"Support Chat"}
                onClick={() => {
                  if (adminChat === "") {
                    createAdminChat();
                  } else {
                    setChat(
                      <ChatPage
                        docID={adminChat}
                        groupImage={""}
                        chatName={"UGA Support"}
                        players={["admin", currentUser.uid]}
                        isGroupChat={false}
                        goBack={() => setShowChat(false)}
                        userID={"admin"}
                        hide={goBack}
                      />
                    );
                    setShowChat(true);
                  }
                }}
              />
            )}
          </div>
          <div className="chat-container">
            {messages.map((chat) => (
              <ChatPreview
                goTo={() => {
                  setChat(
                    <ChatPage
                      adminIDs={chat.adminIDs}
                      chatType={chat.chatType}
                      groupID={chat.groupID}
                      groupImage={chat.image}
                      chatName={chat.name}
                      players={chat.players}
                      isGroupChat={chat.isGroupChat}
                      goBack={() => setShowChat(false)}
                      userID={chat.receiverID}
                      docID={chat.chatID}
                      hide={goBack}
                    />
                  );
                  setShowChat(true);
                }}
                name={chat.name}
                sent={chat.sent}
                image={chat.image}
                message={chat.message}
                time={chat.time}
                key={chat.chatID}
                unreads={chat.unreads}
              />
            ))}
            {messages.length === 0 && (
              <div className="no-messages">
                <p>
                  Conversations with fellow unprofessionals will appear here
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ChatDrop;
