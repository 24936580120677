import React, { useCallback, useState } from "react";
import { admin, db } from "../../../firebase";
import Papa from "papaparse";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import BackRow from "../../display/Rows/BackRow";
import { useDropzone } from "react-dropzone";
import BackdropLoader from "../../Loaders/BackdropLoader";
import csvexample from "../../../assets/PlayerExample.csv";
import BISmallPlayer from "../../UserImport/BISmallPlayer";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

function LeaguePlayerUpload({
  goBack = () => {},
  clubID = "",
  leagueID = "",
  reload = () => {},
}) {
  const [upload, setUpload] = useState([]);
  const [uploadJSON, setUploadJSON] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState("");

  const [players, setPlayers] = useState([]);
  const [error, setError] = useState("");
  const [update, setUpdate] = useState(0);

  // console.log(clubID);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        const arr = [];
        const headerRow = res.data[0];
        let plCol = "";
        let idCol = "";
        // console.log(headerRow);
        for (let i = 0; i < headerRow.length; i++) {
          if (headerRow[i] === "platform") {
            plCol = i;
          } else if (headerRow[i] === "platformID") {
            idCol = i;
          }
        }

        if (plCol === "" || idCol === "") {
          return setError(
            "Sorry, we couldn't quite sort that out - please check your column headers!"
          );
        }

        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];

          arr.push({
            platform: row[plCol],
            platformID: row[idCol],
          });
        }
        loadPlayers(arr);
        setUpload(res);
        // console.log(arr);
        setUploadJSON(arr);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  async function loadPlayers(arr = []) {
    const plarr = [];
    // console.log(arr.length);
    setLoading(true);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const clubData = clubDoc.data();
      const { memberIDs } = clubData;
      const refs = memberIDs.map((id) => db.collection("users").doc(id).get());

      const docs = await Promise.all(refs);

      // console.log(docs.length);

      for (let i = 0; i < docs.length; i++) {
        const playerDoc = docs[i];
        const playerID = playerDoc.id;
        const player = playerDoc.data();
        if (!playerDoc.exists) {
          continue;
        }
        const { firstName, lastName, profileImage } = player;
        let found = false;

        const platformIDs = player.platformIDs ?? [];
        if (i === 1) {
          // console.log(platformIDs);
        }
        for (let n = 0; n < arr.length; n++) {
          if (found) {
            continue;
          }
          const row = arr[n];
          const { platform, platformID, handicap } = row;

          for (let x = 0; x < platformIDs.length; x++) {
            const plRow = platformIDs[x];
            const rowPlatform = plRow.platform;
            const rowPlatformID = plRow.platformID;
            if (i === 1) {
              console.log(rowPlatform, platform, rowPlatformID, platformID);
            }
            if (rowPlatform === platform && rowPlatformID === platformID) {
              console.log("HERRE");
              plarr.push({
                playerID,
                name: `${firstName} ${lastName}`,
                platform,
                platformID,
                handicap,
                profileImage,
              });

              found = true;
            }
          }
        }

        if (!found) {
          console.log(playerID);
        }
      }
      console.log(plarr);
      setPlayers(plarr);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function savePlayers() {
    setLoading(true);
    try {
      const uids = players.map((pl) => pl.playerID);
      console.log(uids);
      const leagueDoc = await db.collection("leagues").doc(leagueID).get();
      const league = leagueDoc.data();
      const { playerIDs } = league;

      for (let i = 0; i < uids.length; i++) {
        if (!playerIDs.includes(uids[i])) {
          playerIDs.push(uids[i]);
        }
      }

      await leagueDoc.ref.update({
        playerIDs,
      });

      toast.success("Players successfully added to your league", {
        icon: successIco,
      });

      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="league-player-upload event-player-upload">
      <BackRow action={goBack} />
      <PageHeader text="League Player Upload" />
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <div className="ph-msg">
        <p>
          Upload players from a different platform for this league. Please note
          only members from your club can be added.
        </p>
      </div>
      <div className="bi-box">
        <div className="pd-20 bi-drop">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-sel">
              {fileName ? (
                <h5 className="filename">{fileName}</h5>
              ) : (
                <h5>
                  Drag 'n' drop a .csv file here, or click to open up the file
                  explorer
                </h5>
              )}
            </div>
          </div>
        </div>
        <div className="bi-info">
          <p>
            In order for the players to be added correctly, formatting is
            important but simple! Only a few fields are needed, the platform
            name and the relevant platform ID, as well as other optional fields.{" "}
            <br />
            <br />
            <strong>The first row (header row) will be included.</strong> Name
            your colums as follows (letter case is important):
          </p>
          <ul>
            <li>platform</li>
            <li>platformID</li>
          </ul>
          <a href={csvexample} className="green-link mt-20">
            Download Sample
          </a>
          {false && (
            <p className="mt-20">
              If you choose to upload tee times, the starting tee and round
              defaults to 1 if not provided.
            </p>
          )}
        </div>
      </div>
      {players.length > 0 && (
        <div className="epu-players">
          <div className="sub-header">
            <h5>Selected Players ({players.length})</h5>
          </div>
          {false && (
            <div className="epup-box">
              {players.map((player, index) => (
                <div key={player.playerID} className={"flex-align-center"}>
                  <p style={{ width: "30vw" }}>
                    {player.name}{" "}
                    {/* {player.handicap !== "" && `(hcp ${player.handicap})`}{" "} */}
                  </p>
                  {/* <p>
                  {" "}
                  {player.teeTime}{" "}
                  {player.startingTee !== "" && `(${player.startingTee})`}{" "}
                </p> */}
                </div>
              ))}
            </div>
          )}
          <div className="bips-box mt-20">
            <div className="bis-player bi-player bi-header">
              <div className="bips-item">
                <p>Player</p>
              </div>
              <div className="bips-item">
                <p>Platform ID</p>
              </div>
              <div className="bips-item">
                <p>Remove</p>
              </div>
            </div>
            {players.map((player, index) => (
              <BISmallPlayer
                key={player.playerID}
                player={player}
                removeAct={() => {
                  setPlayers((c) => {
                    const filt = c.filter(
                      (pl) => pl.playerID !== player.playerID
                    );
                    return filt;
                  });
                  setUpdate((c) => c + 1);
                }}
              />
            ))}
          </div>
        </div>
      )}
      {uploadJSON.length > 0 && (
        <div className="pd-20">
          <button onClick={savePlayers} className="default-button">
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default LeaguePlayerUpload;
