import React, { useState, useEffect } from "react";
import "./PlayerGroups.css";
import { db } from "../../firebase";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import BackRow from "../display/Rows/BackRow";
import ErrorModal from "../ErrorModal/ErrorModal";
import Pill from "../Buttons/Pill";
import { Dialog, Slider } from "@mui/material";
import { makeID } from "../HelperFunctions";
import PGItem from "./PGItem";
import PlayerGroup from "./PlayerGroup";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";

function PlayerGroups({ clubID = "", goBack = () => {}, members = [] }) {
  // Admin
  const groupTypes = [
    { label: "Custom", value: "custom" },
    { label: "Handicap", value: "handicap" },
    { label: "Gender", value: "gender" },
    // { label: "Age", value: "age" },
  ];

  const genderOpts = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
  ];

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [groups, setGroups] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const [toShow, setToShow] = useState();
  const [show, setShow] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [editID, setEditID] = useState("");

  const [nameInput, setNameInput] = useState("");
  const [selGroupType, setSelGroupType] = useState("custom");

  const [ageRange, setAgeRange] = useState([10, 50]);
  const [selPlayerIDs, setSelPlayerIDs] = useState([]);
  const [hcpRange, setHcpRange] = useState([-5, 54]);

  const [gender, setGender] = useState("male");

  // UE
  useEffect(() => {
    console.log(clubID);
    loadGroups();
  }, []);

  // Functions
  async function loadGroups() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();

    const groups = club.memberGroups ?? [];

    console.log(groups);

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const { groupType } = group;
      const arr = [];

      if (groupType === "custom") {
        const { playerIDs } = group;
        for (let j = 0; j < members.length; j++) {
          if (playerIDs.includes(members[j].playerID)) {
            arr.push(members[j]);
          }
        }
        group.players = arr;
      }

      if (groupType === "handicap") {
        const { hcpRange } = group;
        const min = hcpRange[0];
        const max = hcpRange[1];
        for (let j = 0; j < members.length; j++) {
          const member = members[j];
          if (member.handicap <= max && member.handicap >= min) {
            arr.push(member);
          }
        }
        group.players = arr;
      }

      if (groupType === "gender") {
        const { gender } = group;
        for (let j = 0; j < members.length; j++) {
          const member = members[j];
          console.log(member);
          if (member.gender === gender) {
            arr.push(member);
          }
        }
        group.players = arr;
      }
    }

    console.log(groups);

    setGroups(groups);

    setLoading(false);
  }

  async function saveGroup() {
    setShowAdd(false);
    setLoading(true);
    try {
      const group = {
        dateAdded: new Date(),
        playerIDs: [],
        name: nameInput,
        groupType: selGroupType,
        ageRange,
        hcpRange,
        groupID: makeID(10),
        gender,
      };
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();

      const groups = club.memberGroups ?? [];
      groups.push(group);
      await clubDoc.ref.update({
        memberGroups: groups,
      });
      setNameInput("");
      toast.success("Group successfully added", {
        icon: successIco,
      });
      loadGroups();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function deleteGroup(groupID) {
    setShowDelete(false);
    setLoading(true);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();

      const groups = club.memberGroups;

      const filt = groups.filter((gr) => gr.groupID !== groupID);

      await clubDoc.ref.update({
        memberGroups: filt,
      });
      setDeleteID("");
      toast.success("Group successfully remombed", {
        icon: successIco,
      });
      loadGroups();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function editGroup(groupID) {
    setShowAdd(false);
    setLoading(true);
    try {
      const group = {
        dateAdded: new Date(),
        playerIDs: selPlayerIDs,
        name: nameInput,
        groupType: selGroupType,
        ageRange,
        hcpRange,
        groupID,
        gender,
      };

      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();

      const groups = club.memberGroups;

      for (let i = 0; i < groups.length; i++) {
        if (groups[i].groupID === groupID) {
          groups[i] = group;
        }
      }

      await clubDoc.ref.update({
        memberGroups: groups,
      });
      setNameInput("");
      setEditID("");
      toast.success("Group successfully edited", {
        icon: successIco,
      });
      loadGroups();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="player-groups">
      {show ? (
        toShow
      ) : (
        <>
          <Dialog
            open={showDelete}
            className="apg-dia"
            onClose={() => setShowDelete(false)}
          >
            <div className="apg-ctt">
              <PageHeader
                text="Delete Group"
                close={() => setShowDelete(false)}
                showClose
              />
              <div className="ltm-remove pd-20">
                <p>Are you sure you want to delete this group?</p>
              </div>
              <div className="flex flex-center mb-40 mt-20">
                <button
                  onClick={() => deleteGroup(deleteID)}
                  className="default-button"
                >
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>

          <Dialog
            open={showAdd}
            className="apg-dia"
            onClose={() => setShowAdd(false)}
          >
            <div className="apg-ctt">
              <PageHeader
                text="Add Group"
                close={() => setShowAdd(false)}
                showClose
              />

              <div className="ec-box">
                <div className="input-group">
                  <p>Group Title</p>
                  <input
                    className="default-input"
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                    placeholder="Start typing..."
                  />
                </div>

                <div className="input-group">
                  <p>Group Type</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={groupTypes}
                    value={selGroupType}
                    onChange={(e) => {
                      setSelGroupType(e.value);
                    }}
                  />
                </div>
                {selGroupType === "gender" && (
                  <div className="input-group">
                    <p>Gender</p>
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      options={genderOpts}
                      value={gender}
                      onChange={(e) => {
                        setGender(e.value);
                      }}
                    />
                  </div>
                )}

                {selGroupType === "age" && (
                  <>
                    <div className="mpaf-cont">
                      <div className="input-group">
                        <p className="mb-40">Age Range</p>
                        <div className="mpaf-slider">
                          <Slider
                            value={ageRange}
                            min={0}
                            step={1}
                            max={100}
                            disableSwap
                            valueLabelDisplay="on"
                            onChange={(e, newValue) => {
                              setAgeRange(newValue);
                            }}
                          />
                        </div>{" "}
                      </div>
                    </div>
                  </>
                )}
                {selGroupType === "handicap" && (
                  <>
                    <div className="input-group">
                      <p className="mb-40">Handicap Range</p>
                      <div className="mpaf-slider">
                        <Slider
                          value={hcpRange}
                          min={-5}
                          step={0.1}
                          max={54}
                          disableSwap
                          valueLabelDisplay="on"
                          onChange={(e, newValue) => {
                            setHcpRange(newValue);
                          }}
                        />
                      </div>{" "}
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-center mb-40 mt-20">
                <button
                  disabled={nameInput === ""}
                  onClick={
                    editID === "" ? () => saveGroup() : () => editGroup(editID)
                  }
                  className="default-button"
                >
                  Save Group
                </button>
              </div>
            </div>
          </Dialog>

          {error !== "" && (
            <ErrorModal text={error} hide={() => setError("")} />
          )}
          {loading && <BackdropLoader />}
          <BackRow action={() => goBack()} />
          <PageHeader text="Member Groups" />
          <div className="ph-msg">
            <p>
              Assign your members to different groups for organisational
              purposes
            </p>
          </div>

          <div className="quick-links">
            <Pill onClick={() => setShowAdd(true)} text={"Add Group"} />
          </div>

          <div className="pg-groups pd-20">
            {groups.map((group, i) => {
              return (
                <React.Fragment key={group.groupID}>
                  <PGItem
                    viewAction={() => {
                      setToShow(
                        <PlayerGroup
                          clubID={clubID}
                          members={members}
                          group={group}
                          players={group.players ?? []}
                          goBack={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                    deleteAction={() => {
                      setDeleteID(group.groupID);
                      setShowDelete(true);
                    }}
                    editAction={() => {
                      setSelPlayerIDs(group.playerIDs ?? []);
                      setEditID(group.groupID);
                      setAgeRange(group.ageRange);
                      setHcpRange(group.hcpRange);
                      setNameInput(group.name);
                      setSelGroupType(group.groupType);
                      setShowAdd(true);
                    }}
                    group={group}
                    reload={loadGroups}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default PlayerGroups;
