/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./EventPlayerSelector.css";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PlayerSelector from "../../PlayerSelector/PlayerSelector";

import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import PlayerSetup from "../../PlayerSetup/PlayerSetup";

import { useAuth } from "../../../contexts/AuthContext";

function EventPlayerSelector({
  setPlayers = [],
  players = [],
  goBack,
  selPlayers,
  courseDetails,
  max = "",
  hideEdit = false,
  loadingPlayers = false,
  saveInvite = () => {},
}) {
  const [showSelector, setShowSelector] = useState(hideEdit);
  const [selectedPlayers, setSelectedPlayers] = useState(selPlayers);
  const [setup, setSetup] = useState();
  const [showSetup, setShowSetup] = useState(false);

  const { currentUser } = useAuth();

  useEffect(() => {
    console.log(selPlayers);
    console.log(courseDetails);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPlayers(selectedPlayers);
  }, [selectedPlayers]);

  return (
    <div className="player-selector event-player-selector">
      {showSelector && (
        <PlayerSelector
          max={max}
          event={true}
          courseDetails={courseDetails}
          goBack={() => {
            setShowSelector(false);
            window.scrollTo(0, 0);
          }}
          loadingPlayers={loadingPlayers}
          players={players}
          setPlayers={setSelectedPlayers}
          selPlayers={selectedPlayers}
        />
      )}
      {showSetup && setup}

      {!showSetup && !showSelector && (
        <>
          <div className="crc-head mb-20 mt-20">
            <div
              className="crh-left"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ArrowForwardIcon
                onClick={() => {
                  console.log(selectedPlayers);
                  setPlayers(selectedPlayers);
                  goBack();
                }}
                className="ts-arrow"
              />
              <h2 style={{ marginLeft: "20px" }}>Invited Players</h2>
            </div>
            <div onClick={() => setShowSelector(true)} className="crh-right">
              <h2 className="green">Add Players</h2>
            </div>
          </div>

          {selectedPlayers.length > 0 && !hideEdit && (
            <div className="info-message-small">
              <p>Tap on the settings icon to edit a player's handicap</p>
            </div>
          )}

          {selectedPlayers.map((player) => {
            return (
              <RoundPlayer
                handleSetup={() => {
                  setSetup(
                    <PlayerSetup
                      handicap={player.handicap}
                      customHandicap={true}
                      goBack={() => setShowSetup(false)}
                      city={player.city ?? "Cape Town"}
                      country={player.country ?? "South Africa"}
                      imageLink={player.profileImage}
                      length={courseDetails.length}
                      name={player.name}
                      playerID={player.playerID}
                      tees={courseDetails.teeArray}
                      active={player.tee ?? courseDetails.teeArray[0]}
                      changeHandicap={(e) => {
                        setSelectedPlayers((current) => {
                          const newArr = current;
                          for (let i = 0; i < newArr.length; i++) {
                            if (newArr[i].playerID === player.playerID) {
                              newArr[i].handicap = e;
                              newArr[i].customHandicap = true;
                              console.log(newArr[i]);
                            }
                          }

                          return newArr;
                        });
                      }}
                      setTee={(e) => {
                        setSelectedPlayers((current) => {
                          const newArr = current;
                          for (let i = 0; i < newArr.length; i++) {
                            if (newArr[i].playerID === player.playerID) {
                              newArr[i].tee = e;
                            }
                          }

                          return newArr;
                        });
                      }}
                    />
                  );
                  setShowSetup(true);
                }}
                uid={currentUser.uid}
                playerID={player.playerID}
                key={player.playerID}
                handicap={player.handicap}
                imageLink={player.profileImage}
                name={player.name}
                tee={player.tee ?? courseDetails.teeArray[0]}
                handleRemove={() =>
                  setSelectedPlayers((current) =>
                    current.filter((p) => p.playerID !== player.playerID)
                  )
                }
              />
            );
          })}

          {selectedPlayers.length === 0 && (
            <div className="no-invites">
              <h2>No players have been invited yet</h2>
            </div>
          )}

          {hideEdit && selectedPlayers.length > 0 && (
            <div className="confirm-button-row">
              <button
                onClick={() => {
                  saveInvite(selectedPlayers);
                }}
              >
                Invite
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EventPlayerSelector;
