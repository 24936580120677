import React, { useState } from "react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import img from "../../assets/images/log1.png";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
function ContestItem({ contest, handleClick = () => {} }) {
  return (
    <Link to={`/contests/${contest.contestID}`}>
      <div onClick={handleClick} className="contest-item">
        <div className="con-item-left flex-center">
          <Avatar alt={contest.name} src={img} />
        </div>
        <div className="con-item-right">
          <h1>{contest.name}</h1>
          <h2>{contest.dateString}</h2>
          <div className="ci-flex">
            <PeopleAltIcon className="dg-icon" />
            <p>
              {contest.playerIDs.length}{" "}
              {contest.playerIDs.length === 1 ? "player" : "players"}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ContestItem;
