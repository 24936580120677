import React, { useState, useEffect } from "react";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Components
import { Menu, MenuItem } from "@mui/material";

function CustomEvent(props) {
  const [show, setShow] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={
        props.event.eventType === "regular"
          ? "custom-event reg-event"
          : props.event.eventType === "major"
          ? "custom-event maj-event"
          : "custom-event fin-event"
      }
    >
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* {!props.event.active && ( */}
        {props.event.active && (
          <MenuItem
            onClick={() => {
              props.event.showActiveEvent();
              handleClose();
            }}
          >
            Live Dashboard
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            props.event.showEvent();
            handleClose();
          }}
        >
          Event Home
        </MenuItem>
        {/* )} */}

        <MenuItem
          onClick={() => {
            window.open(
              `https://www.theugatour.com/events/${props.event.eventID}`,
              "_blank"
            );
            handleClose();
          }}
        >
          View Event Page
        </MenuItem>
        {!props.event.active && !props.event.past && (
          <MenuItem
            onClick={() => {
              props.event.showInvite();
              handleClose();
            }}
          >
            Invite Players
          </MenuItem>
        )}
      </Menu>
      <div className="ce-left">
        <p>{props.event.eventName}</p>
        <span>{props.event.courseName}</span>
      </div>
      <div className="ce-right">
        {props.event.accountType !== "scorer" && (
          <MoreVertIcon onClick={handleClick} className="ce-icon" />
        )}
      </div>
    </div>
  );
}

export default CustomEvent;
