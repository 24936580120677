import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import "./TeamEvents.css";
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Components
import { Drawer, Avatar } from "@mui/material";
import BackdropLoader from "../Loaders/BackdropLoader";
import MatchplayScore from "../display/MatchplayScore/MatchplayScore";
import MatchplayHoles from "../display/MatchplayHoles/MatchplayHoles";
import MatchplayHoleSlider from "../display/MatchplayScore/MatchplayHoleSlider";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { ArrowBack } from "@mui/icons-material";
import BackRow from "../display/Rows/BackRow";

function TeamEventMatches({ eventID = "", live = true }) {
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState("teams");
  const [bbs, setBBS] = useState(false);
  const [stableScores, setStableScores] = useState({});
  const [rounds, setRounds] = useState([]);
  const [selRound, setSelRound] = useState("");
  const [format, setFormat] = useState("");
  const [matches, setMatches] = useState([]);

  const [showDrop, setShowDrop] = useState(false);

  const [multipleCourses, setMultipleCourses] = useState(false);
  const [cmp, setCMP] = useState({});

  const [teamOptions, setTeamOptions] = useState([]);

  const [update, setUpdate] = useState(0);

  const [matchups, setMatchups] = useState([]);

  const [selTeam, setSelTeam] = useState("all");

  const [showFilters, setShowFilters] = useState(false);

  const [roundMatchups, setRoundMatchups] = useState([]);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [teamMap, setTeamMap] = useState({});

  const [playerTeams, setPlayerTeams] = useState({});

  useEffect(() => {
    if (live) {
      liveListener({ roundID: "" });
    }
  }, [eventID]);

  async function liveListener({ roundID = "" }) {
    setLoading(true);
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const { activeRoundID, roundIDs, leagueID } = event;

    const roundDoc = await db.collection("rounds").doc(activeRoundID).get();
    const round = roundDoc.data();
    const { courses } = round;
    setBBS(round.betterballStableford ?? false);

    const courseMap = {};

    if (courses.length > 1) {
      setMultipleCourses(true);
    }

    for (let i = 0; i < courses.length; i++) {
      const course = courses[i];
      courseMap[course.courseID] = course.name;
    }

    setCMP(courseMap);

    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { teams } = league;

    const mep = {};
    const ptmep = {};

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      mep[team.teamID] = {
        teamName: team.teamName,
        imageLink: team.imageLink,
      };
      const { players } = team;

      for (let x = 0; x < players.length; x++) {
        ptmep[players[x].playerID] = team.teamID;
      }
    }

    setPlayerTeams(ptmep);

    const topts = teams.map((team) => {
      const ret = {
        value: team.teamID,
        label: team.teamName,
      };
      return ret;
    });

    topts.unshift({
      label: "All",
      value: "all",
    });

    setTeamOptions(topts);
    setTeamMap(mep);

    let toUse = activeRoundID;
    if (roundID !== "") {
      toUse = roundID;
    }
    const roundOptions = roundIDs.map((round, index) => {
      const ret = {
        label: `Round ${index + 1}`,
        value: round,
      };
      return ret;
    });
    setRounds(roundOptions);
    setSelRound(toUse);
    if (roundIDs.length > 0) {
      setShowDrop(true);
    }

    db.collection("rounds")
      .doc(toUse)
      .onSnapshot(async (snap) => {
        setLoading(true);
        const round = snap.data();
        setStableScores(round.stableScores ?? {});
        // console.log(round.matches);
        setMatches(round.matches ?? []);
        setFormat(round.format);
        setRoundMatchups(round.matchups);
        setUpdate((current) => current + 1);

        // console.log(round.matches);
        setLoading(false);
      });

    eventDoc.ref.onSnapshot(async (snap) => {
      const dat = snap.data();
      const matchups = dat.matchups ?? [];

      setMatchups(matchups);
    });
    setLoading(false);
  }

  return (
    <div className="team-event-matches">
      {loading && <BackdropLoader />}

      <Drawer
        anchor="top"
        open={showFilters}
        onClose={() => setShowFilters(false)}
      >
        <div className="tem-filters">
          <div className="neh-box mt-20">
            <h2 className="ne-header">Filters</h2>
          </div>
          <div className="input-group fp-drop">
            <p className="ne-header">Select Team</p>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={selTeam}
              options={teamOptions}
              onChange={(e) => {
                setSelTeam(e.value);
              }}
            />
          </div>
          <div className="flex-center">
            <button
              onClick={() => setShowFilters(false)}
              className="new-default-button"
            >
              Confirm
            </button>
          </div>
        </div>
      </Drawer>

      {show ? (
        toShow
      ) : (
        <>
          {showDrop && (
            <div className="flex-apart fp-drop">
              {/* <p className="ne-header">Select Round</p> */}
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={selRound}
                options={rounds}
                onChange={(e) => {
                  liveListener({ roundID: e.value });
                  setSelRound(e.value);
                }}
              />
              {page === "teams" && (
                <p
                  className="green-link"
                  onClick={() => {
                    setSelTeam("all");
                    setPage("matches");
                  }}
                >
                  View All
                </p>
              )}
            </div>
          )}

          {page === "teams" && (
            <div className="tem-teams">
              {matchups.map((mtch, index) => {
                const { teamOne, teamTwo } = mtch;
                let rndT1 = 0;
                let rndT2 = 0;

                const conceded = mtch.conceded ?? false;
                const winner = mtch.winner ?? "";

                for (let i = 0; i < roundMatchups.length; i++) {
                  const mt = roundMatchups[i];
                  if (mt.teamOne.teamID === teamOne.teamID) {
                    rndT1 = mt.teamOne.score;
                  }
                  if (mt.teamTwo.teamID === teamOne.teamID) {
                    rndT1 = mt.teamTwo.score;
                  }
                  if (mt.teamOne.teamID === teamTwo.teamID) {
                    rndT2 = mt.teamOne.score;
                  }
                  if (mt.teamTwo.teamID === teamTwo.teamID) {
                    rndT2 = mt.teamTwo.score;
                  }
                }

                if (rounds.length === 1 && !bbs) {
                  rndT1 = "";
                  rndT2 = "";
                }

                return (
                  <div
                    key={`${index}match`}
                    className="tem-team"
                    onClick={() => {
                      setSelTeam(teamOne.teamID);
                      setPage("matches");
                    }}
                  >
                    <div className="tt-left">
                      <div className="ttl-left">
                        <Avatar src={teamOne.imageLink} />
                        <h2>
                          {teamOne.name}
                          {/* <span style={{ marginRight: "10px" }} /> */}
                        </h2>
                      </div>
                      <div className="ttl-right">
                        <span>{teamOne.score}</span>
                        {(bbs || rounds.length > 1) && (
                          <h5>
                            (
                            {bbs && stableScores
                              ? stableScores[teamOne.teamID]
                              : rndT1}
                            )
                          </h5>
                        )}{" "}
                      </div>
                    </div>
                    <div className="tt-mid">
                      <h2>-</h2>
                    </div>
                    <div className="tt-right">
                      <div className="ttr-left">
                        {(bbs || rounds.length > 1) && (
                          <h5>
                            (
                            {bbs && stableScores
                              ? stableScores[teamTwo.teamID]
                              : rndT2}
                            )
                          </h5>
                        )}
                        <span>{teamTwo.score}</span>{" "}
                      </div>
                      <div className="ttr-right">
                        <h2>
                          {/* <span style={{ marginRight: "10px" }} /> */}
                          {teamTwo.name}
                        </h2>
                        <Avatar src={teamTwo.imageLink} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {page === "matches" && (
            <div className="tem-matches">
              <div className="mb-40">
                {" "}
                <BackRow action={() => setPage("teams")} />
              </div>

              {format === "Matchplay" &&
                matches.map((match, index) => {
                  const { playerOne, playerTwo, courseID } = match;
                  if (!playerOne) {
                    console.log("ret null 1");
                    return null;
                  }
                  const p1TI = playerOne.teamID ?? match.teamOneID;
                  const p2TI = playerTwo.teamID ?? match.teamTwoID;

                  if (selTeam !== "all") {
                    if (p1TI !== selTeam && p2TI !== selTeam) {
                      console.log("ret nll 2");
                      return null;
                    }
                  }

                  const courseName = cmp[courseID];

                  return (
                    <div key={`${index}match`} className="tem-match">
                      {multipleCourses && (
                        <div className="tem-course">
                          <h3>{courseName}</h3>
                        </div>
                      )}
                      <div className="tem-name-row">
                        <div className="tnr-left">
                          <Avatar src="" />
                          <p>{match.playerOne.teamName}</p>
                        </div>
                        <div className="tnr-right">
                          <Avatar src="" />

                          <p>{match.playerTwo.teamName}</p>
                        </div>
                      </div>
                      <MatchplayScore
                        nameOne={match.playerOne.name}
                        nameTwo={match.playerTwo.name}
                        score={match.score}
                        holes={match.thru}
                        complete={match.completed}
                        idOne={match.playerOne.playerID}
                        idTwo={match.playerTwo.playerID}
                        leader={match.leader}
                        handleClick={() => {
                          setToShow(
                            <MatchplayHoles
                              roundID={selRound}
                              goBack={() => setShow(false)}
                              showBack
                              matchNum={index}
                            />
                          );
                          setShow(true);
                        }}
                      />
                      <MatchplayHoleSlider
                        by={match.score}
                        holes={match.holes}
                        idOne={match.playerOne.playerID}
                        idTwo={match.playerTwo.playerID}
                        thru={match.thru}
                      />
                    </div>
                  );
                })}

              {format === "Fourball" &&
                matches.map((match, index) => {
                  const { teamOne, teamTwo } = match;
                  if (!teamOne) {
                    return null;
                  }
                  const p1 = teamOne[0];
                  const p12 = teamOne[1];
                  const p2 = teamTwo[0];
                  const p22 = teamTwo[1];

                  const p1ID = p1.playerID;
                  const p2ID = p2.playerID;

                  const t1ID = playerTeams[p1ID];
                  const t2ID = playerTeams[p2ID];

                  if (selTeam !== "all") {
                    if (t1ID !== selTeam && t2ID !== selTeam) {
                      return null;
                    }
                  }

                  const t1 = p1.teamName;
                  const t2 = p2.teamName;

                  const nameOne = `${p1.lastName} / ${p12.lastName}`;
                  const nameTwo = `${p2.lastName} / ${p22.lastName}`;

                  let leaderID = match.leader;

                  if (typeof leaderID !== "string") {
                    leaderID = "";
                  }

                  // const leaders = match.leader ?? [];

                  // console.log(p1, p2, leaders);

                  // if (leaders.includes(p1.playerID)) {
                  //   leaderID = "teamOne";
                  // } else if (leaders.includes(p2.playerID)) {
                  //   leaderID = "teamTwo";
                  // }

                  // console.log(leaderID);

                  return (
                    <div key={`${index}match`} className="tem-match">
                      <div className="tem-name-row">
                        <div className="tnr-left">
                          <Avatar src="" />
                          <p>{t1}</p>
                        </div>
                        <div className="tnr-right">
                          <Avatar src="" />

                          <p>{t2}</p>
                        </div>
                      </div>
                      <MatchplayScore
                        t1Pts={match.teamOnePoints}
                        t2Pts={match.teamTwoPoints}
                        nameOne={nameOne}
                        nameTwo={nameTwo}
                        score={match.score ?? 0}
                        holes={match.thru}
                        idOne={"teamOne"}
                        idTwo={"teamTwo"}
                        bbs={bbs}
                        complete={match.completed}
                        leader={leaderID}
                        handleClick={() => {
                          if (bbs) {
                            return;
                          }
                          setToShow(
                            <MatchplayHoles
                              roundID={selRound}
                              goBack={() => setShow(false)}
                              showBack
                              matchNum={index}
                              t1Name={nameOne}
                              t2Name={nameTwo}
                            />
                          );
                          setShow(true);
                        }}
                      />
                      {!bbs && (
                        <MatchplayHoleSlider
                          by={match.score}
                          holes={match.holes}
                          idOne={"teamOne"}
                          idTwo={"teamTwo"}
                          thru={match.thru}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TeamEventMatches;
