/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./EventHome.css";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import Slide from "@mui/material/Slide";
import { useClickTheme } from "../../../contexts/MenuContext";
import { useHelperClickTheme } from "../../../contexts/HelperContext";
import moment from "moment";
import EventDashboard from "../EventDashboard/EventDashboard";
import EventPlayerSelector from "./EventPlayerSelector";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { Avatar, Skeleton } from "@mui/material";
import Person from "@mui/icons-material/Person";
import { BallTriangle, Oval } from "react-loader-spinner";
import EditIcon from "@mui/icons-material/Edit";
import EventTeamUpload from "./EventTeamUpload";
import TodayIcon from "@mui/icons-material/Today";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Steps } from "intro.js-react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Dialog from "@mui/material/Dialog";
import ErrorIcon from "@mui/icons-material/Error";
import EventRound from "./EventRound";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RoundInput from "./RoundInput";
import EventCreator from "./EventCreator";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import PlayerSetup from "../../PlayerSetup/PlayerSetup";
import ArrowBack from "@mui/icons-material/ArrowBack";
import RyderTeams from "./RyderTeams";
import { Link, useNavigate, useParams } from "react-router-dom";
import ScorecardCreator from "../../ScorecardCreator/ScorecardCreator";
import RadioGroup from "../../Buttons/RadioGroup/RadioGroup";
import InviteModal from "../InviteModal/InviteModal";
import MenuSwitcher from "../../MenuSwitcher/MenuSwitcher";
import { useCookies } from "react-cookie";
import EventSponsorManager from "./EventSponsorManager";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import GroupSelector from "../GroupSelector/GroupSelector";
import PlayerLoader from "../../Loaders/PlayerLoader";
import ManualPlayerAdd from "./ManualPlayerAdd";
import PlayerTeeSelector from "./PlayerTeeSelector";
import Pill from "../../Buttons/Pill";
import BroadcastModal from "../../BroadcastModal/BroadcastModal";
import EventHandicapEdit from "../EventDashboard/EventHandicapEdit";
import EventTeamManager from "./EventTeamManager";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Info, KeyboardArrowDown } from "@mui/icons-material";
import HandicapDivisions from "./HandicapDivisions";
import PrizeManager from "../../PrizeManager/PrizeManager";
import CustomFormats from "../../CustomFormats/CustomFormats";

import EventPlayerUpload from "./EventPlayerUpload";
import EventGuests from "./EventGuests";
import JackpotHoles from "./JackpotHoles";
import PlayerCourseSelector from "./PlayerCourseSelector";
import EventPlayerList from "./EventPlayerList";
import LeaderboardSettings from "../../LeaderboardSettings/LeaderboardSettings";
import EventExports from "../EventExports/EventExports";
import PageHeader from "../../display/Rows/PageHeader";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";
import EvtPlayerRemoval from "./EvtPlayerRemoval";

const GradientErrorIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorss" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <ErrorIcon
      onClick={onClick}
      className={className}
      sx={{ fill: "url(#linearColorss)" }}
    />
  </>
);
const GradientRemoveCircleIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorss" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <RemoveCircleIcon
      onClick={onClick}
      className={className}
      sx={{ fill: "url(#linearColorss)" }}
    />
  </>
);

const GradientEditIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <EditIcon
      onClick={onClick}
      className="icon-cursor dg-icon-bg mb-10"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="ccr-icon icon-cursor dg-icon-bg"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientOpacityIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="ccr-icon icon-cursor dg-icon-bg dc-ic-op"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function EventHome({ reload }) {
  const { currentUser, onboarding, setOnboard, userDetails } = useAuth();

  const showClick = useClickTheme();
  const showHelperClick = useHelperClickTheme();

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const eventID = useParams().id;

  const { id } = useParams();

  const props = {
    eventName: "",
    leagueName: "",
    imageLink: "",
    rounds: [],
    roundIDs: [],
    entranceFee: 0,
    rules: [],
    standalone: false,
  };
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [invitedPlayers, setInvitedPlayers] = useState([]);
  const [declinedPlayers, setDeclinedPlayers] = useState([]);
  const [details, setDetails] = useState(props);
  const [loaded, setLoaded] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading event...");
  const [error, setError] = useState("");
  const [showRound, setShowRound] = useState(false);
  const [round, setRound] = useState();
  const [showReset, setShowReset] = useState(false);

  const [selCourses, setSelCourses] = useState([]);

  const [showExports, setShowExports] = useState(false);

  const [eclectic, setEclectic] = useState(false);

  const [playerSearch, setPlayerSearch] = useState("");

  const [roundCreation, setRoundCreation] = useState("auto");

  const [showSelector, setShowSelector] = useState(false);
  const [showHCP, setShowHCP] = useState(false);

  const [playersToInvite, setPlayersToInvite] = useState([]);
  const [playersInvited, setPlayersinvited] = useState([]);

  const [scrambleHandicaps, setScrambleHandicaps] = useState(false);

  const [showUpload, setShowUpload] = useState(false);

  const [playersToAdd, setPlayersToAdd] = useState([]);

  const [showBC, setShowBC] = useState(false);

  const [loadingToInvite, setLoadingToInvite] = useState(true);

  const [showCancel, setShowCancel] = useState(false);

  const [courses, setCourses] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(0);

  const [hideCreate, setHideCreate] = useState(false);

  const [leagueOptions, setLeagueOptions] = useState([]);

  const [showPop, setShowPop] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [playerRemove, setPlayerRemove] = useState("");

  const [showManual, setShowManual] = useState(false);

  const [showUninvite, setShowUninvite] = useState(false);
  const [playerUninvite, setPlayerUninvite] = useState("");

  const [active, setActive] = useState("playing");

  const [modal, setModal] = useState();

  const [disableChanges, setDisableChanges] = useState(false);

  const [showCreate, setShowCreate] = useState(false);

  const [leagueTable, setLeagueTable] = useState([]);

  useEffect(() => {
    // setEventID(id);
    if (userDetails) {
      loadEvent();
    }
  }, [userDetails, eventID]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [eventID]);

  useEffect(() => {
    // console.log(details.rounds);
  }, [details]);

  useEffect(() => {
    if (onboarding) {
      if (onboarding.eventHome === false) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);

  const [selectedCourse, setSelectedCourse] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  async function loadAll(
    selCourseID,
    playing = [],
    invited = [],
    declined = [],
    leagueID = "",
    clubID = "",
    playerHandicaps = {}
  ) {
    const courseArr = [];

    // Queries
    const courseDoc = await db.collection("courses").doc(selCourseID).get();
    const course = courseDoc.data();
    const courseID = courseDoc.id;
    const { name, teeArray, length, tees } = course;
    setSelectedCourse({
      label: name,
      value: courseID,
      teeArray,
      length,
      tees,
    });

    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    let leagueIDs = [];
    let pointsTable = [];
    if (leagueDoc.exists) {
      leagueIDs = league.playerIDs;
      pointsTable = league.pointsTable;
      setLeagueTable(league.pointsTable);
    }
    const pointsRank = pointsTable.map((row) => row.playerID);
    let playerIDArr = [];
    const userName = `${userDetails.firstName} ${userDetails.lastName}`;
    const toInvite = [];
    const playerArr = [];
    const inviteArr = [];
    const declineArr = [];

    // if (
    //   !invited.includes(currentUser.uid) &&
    //   !playing.includes(currentUser.uid)
    // ) {
    //   toInvite.push({
    //     label: userName,
    //     value: currentUser.uid,
    //     handicap: userDetails.handicap,
    //     profileImage: userDetails.profileImage,
    //     name: userName,
    //     playerID: currentUser.uid,
    //     lastName: userDetails.lastName,
    //     firstName: userDetails.firstName,
    //   });
    // }
    // if (playing.includes(currentUser.uid)) {
    //   playerArr.push({
    //     label: userName,
    //     value: currentUser.uid,
    //     handicap: userDetails.handicap,
    //     profileImage: userDetails.profileImage,
    //     name: userName,
    //     playerID: currentUser.uid,
    //     lastName: userDetails.lastName,
    //     firstName: userDetails.firstName,
    //   });
    // }
    // if (
    //   invited.includes(currentUser.uid) &&
    //   !playing.includes(currentUser.uid)
    // ) {
    //   inviteArr.push({
    //     label: userName,
    //     value: currentUser.uid,
    //     handicap: userDetails.handicap,
    //     profileImage: userDetails.profileImage,
    //     name: userName,
    //     playerID: currentUser.uid,
    //     lastName: userDetails.lastName,
    //     firstName: userDetails.firstName,
    //   });
    // }
    const friends = userDetails.friends ?? [];
    playerIDArr.push(currentUser.uid);
    for (let i = 0; i < friends.length; i++) {
      const id = friends[i];
      if (!playerIDArr.includes(id)) {
        playerIDArr.push(id);
      }
    }

    for (let i = 0; i < playing.length; i++) {
      const id = playing[i];
      if (!playerIDArr.includes(id)) {
        playerIDArr.push(id);
      }
    }

    for (let i = 0; i < invited.length; i++) {
      const id = invited[i];
      if (!playerIDArr.includes(id)) {
        playerIDArr.push(id);
      }
    }

    for (let i = 0; i < declined.length; i++) {
      const id = declined[i];
      if (!playerIDArr.includes(id)) {
        playerIDArr.push(id);
      }
    }

    for (let i = 0; i < leagueIDs.length; i++) {
      const id = leagueIDs[i];
      if (!playerIDArr.includes(id)) {
        playerIDArr.push(id);
      }
    }

    console.log(playerIDArr.length);

    if (leagueID !== "standalone") {
      playerIDArr = playerIDArr.filter((uid) => leagueIDs.includes(uid));
    }

    console.log(playerIDArr.length);

    if (clubID && userDetails.accountType !== "society") {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();
      const { memberIDs } = club;
      for (let i = 0; i < memberIDs.length; i++) {
        if (!playerIDArr.includes(memberIDs[i])) {
          playerIDArr.push(memberIDs[i]);
        }
      }
    }

    playerIDArr = _.uniq(playerIDArr);

    playerIDArr = playerIDArr.filter((id) => id !== null);

    // console.log(playerIDArr.includes(currentUser.uid));

    // playerIDArr.includes("H0rjnXoz10hk6V0EMxRUTrUXegk1");

    const refs = playerIDArr.map((id) => db.collection("users").doc(id).get());

    const docs = await Promise.all(refs);
    const playerLeagues = [];

    for (let i = 0; i < docs.length; i++) {
      const userDoc = docs[i];
      const playerID = userDoc.id;

      const player = userDoc.data();
      if (!player) {
        continue;
      }
      const playerName = `${player.firstName} ${player.lastName}`;
      const { profileImage, status, dob } = player;
      const gender = player.gender ?? "male";
      let dobObj;
      if (typeof dob === "string") {
        dobObj = new Date(dob);
      } else {
        dobObj = new Date(dob.toDate());
      }
      const dobMom = moment(dobObj);
      const age = moment().diff(dobMom, "years");

      const { leagueIDs } = player;
      for (let j = 0; j < player.leagueIDs.length; j++) {
        const leagueID = player.leagueIDs[j];
        if (!playerLeagues.includes(leagueID)) {
          playerLeagues.push(leagueID);
        }
      }

      // console.log(age);

      let handicap = player.handicap;
      if (playerHandicaps[playerID] !== undefined) {
        handicap = playerHandicaps[playerID];
      }
      if (status === "disabled") {
        continue;
      }

      const settings = player.settings ?? "";
      const rank = pointsRank.indexOf(playerID) + 1;
      let lowHI = player.lowHI ?? player.handicap;
      if (isNaN(lowHI)) {
        lowHI = handicap;
      }
      // If player accepts invites from friends onbly
      // if (settings !== '') {
      //     if (settings.friendsOnly === true && !friendIDs.includes(currentUser.uid)) {
      //         continue;
      //     }
      // }
      if (
        !playing.includes(playerID) &&
        !invited.includes(playerID) &&
        !declined.includes(playerID)
      ) {
        toInvite.push({
          label: playerName,
          value: playerID,
          handicap,
          orginalHandicap: handicap,
          profileImage,
          name: playerName,
          playerID,
          lastName: player.lastName,
          firstName: player.firstName,
          age,
          rank,
          leagueIDs,
          lowHI,
          gender,
        });
      }

      if (playing.includes(playerID)) {
        playerArr.push({
          name: playerName,
          value: playerID,
          handicap,
          originalHandicap: player.handicap,
          profileImage,
          playerID,
          age,
          rank,
          lowHI,
          lastName: player.lastName,
          firstName: player.firstName,
          gender,
          leagueIDs,
        });
      }

      if (invited.includes(playerID) && !playing.includes(playerID)) {
        inviteArr.push({
          name: playerName,
          value: playerID,
          handicap,
          profileImage,
          originalHandicap: player.handicap,
          playerID,
          age,
          rank,
          lowHI,
          lastName: player.lastName,
          firstName: player.firstName,
          gender,
          leagueIDs,
        });
      }

      if (declined.includes(playerID)) {
        declineArr.push({
          name: playerName,
          value: playerID,
          handicap,
          profileImage,
          originalHandicap: player.handicap,
          playerID,
          age,
          lowHI,
          rank,
          lastName: player.lastName,
          firstName: player.firstName,
          gender,
        });
      }
    }

    const leagueRefs = playerLeagues.map((id) =>
      db.collection("leagues").doc(id).get()
    );
    // const leagueDocs = await Promise.all(leagueRefs);

    const leagueDocs = await db
      .collection("leagues")
      .where("clubID", "==", clubID)
      .get();

    const leagueOpts = [
      {
        value: "all",
        label: "All",
      },
    ];

    for (let i = 0; i < leagueDocs.docs.length; i++) {
      const leagueDoc = leagueDocs.docs[i];
      if (!leagueDoc.exists) {
        continue;
      }
      const league = leagueDoc.data();

      const { leagueName } = league;
      const leagueID = leagueDoc.id;
      leagueOpts.push({
        label: leagueName,
        value: leagueID,
      });
    }

    setLeagueOptions(leagueOpts);

    const toAdd = [...toInvite, ...inviteArr];
    // console.log(toAdd);
    setPlayersToAdd(toAdd);
    setPlayersToInvite(toInvite);
    setDeclinedPlayers(declineArr);
    setInvitedPlayers(inviteArr);
    setSelectedPlayers(playerArr);
    setLoadingToInvite(false);
    // console.log(toInvite);
    // setCourses(courseArr);
    setLoaded(true);
  }

  async function loadEvent() {
    console.log("EVT LOAD");
    setLoadingText("Loading event...");
    setLoaded(false);
    const doc = await db.collection("events").doc(eventID).get();
    const event = doc.data();

    const {
      description,
      eventDate,
      eventName,
      leagueID,
      playerIDs,
      limit,
      roundIDs,
    } = event;

    let leagueName = "";

    // console.log(event.adminIDs);

    // console.log(playerIDs);
    setSelCourses(event.courses ?? []);
    const teamPoints = event.teamPoints ?? false;
    const eclectic = event.eclectic ?? false;
    setEclectic(eclectic);

    if (roundIDs.length > 0) {
      setDisableChanges(true);
    }

    const playerHandicaps = event.playerHandicaps ?? {};
    const ring = event.ring ?? 2;
    const handicapLimit = event.handicapLimit ?? 36;
    let imageLink = event.imageLink;
    const chatID = event.chatID ?? "";
    const playoff = event.playoff ?? "playoff";
    const eventType = event.eventType ?? "regular";
    const cutoff = event.cutoff ?? 2;
    const invitedIDs = event.invitedIDs ?? [];
    const rounds = event.rounds ?? [];
    const courseID = event.courseID ?? "Cb6FqfEj9Y6fimMawcuZ";
    const courseName = event.courseName ?? "King David Mowbray";
    const restrictions = event.restrictions ?? "open";
    const format = event.format ?? "net-strokeplay";
    const seasonID = event.seasonID ?? "2mm1CfDjABKNSEwWp5Mw";
    const entranceFee = event.entranceFee ?? 0;
    const rules = event.rules ?? [];
    const edition = event.edition ?? 0;
    const clubID = event.clubID ?? "";
    const previousEventID = event.previousEventID ?? "";
    const previousEventName = event.previousEventName ?? "";
    const enabledStats = event.enabledStats ?? [];
    const playoffHoles = event.playoffHoles ?? [];
    const enableTeams = event.enableTeams ?? false;
    const scratchMode = event.scratchMode ?? false;
    const finalHoleCutoff = event.finalHoleCutoff ?? 2;
    const adjusted = event.adjusted ?? false;
    const declinedIDs = event.declinedIDs ?? [];
    const tempDate = new Date(eventDate.toDate());
    const defaultDate = moment(tempDate).toISOString(true).split(".")[0];
    const percentage = event.percentage ?? 90;
    const presetType = event.presetType ?? "";
    setRoundCreation(event.roundCreation ?? "auto");
    loadAll(
      courseID,
      playerIDs,
      invitedIDs,
      declinedIDs,
      leagueID,
      clubID,
      playerHandicaps
    );

    // const seasonDoc = await db.collection('seasons').doc(seasonID).get();
    // const season = seasonDoc.data();
    // const seasonName = season.name;

    // console.log(event.customType);

    const standalone = leagueID === "standalone";
    if (!standalone) {
      const leagueDoc = await db.collection("leagues").doc(leagueID).get();
      const league = leagueDoc.data();
      if (imageLink === undefined) {
        imageLink = league.imageLink;
      }
      leagueName = league.leagueName;
    }

    const date = new Date(eventDate.toDate());
    const eventTime = date.toLocaleTimeString();
    const dateString = date.toLocaleDateString("en-us", { dateStyle: "full" });
    for (let j = 0; j < rounds.length; j++) {}
    // console.log(event.enableDivisions);

    const evtAdms = event.adminIDs ?? [event.admin];

    if (!evtAdms.includes(currentUser.uid)) {
      // navigate("/");
    }

    setDetails({
      completed: event.completed ?? false,
      useLowHI: event.useLowHI ?? false,
      overallTeamScores: event.overallTeamScores ?? 2,
      adminIDs: event.adminIDs ?? [],
      handicapIndexLimit: event.handicapIndexLimit ?? 54,
      manualFinish: event.manualFinish ?? false,
      joinCode: event.joinCode ?? "",
      joinLive: event.joinLive ?? false,
      enableSubmission: event.enableSubmission ?? false,
      guestEventID: event.guestEventID ?? "",
      singlesAllowance: event.singlesAllowance ?? 100,
      enableDivisions: event.enableDivisions ?? false,
      scrambleHandicaps: event.scrambleHandicaps ?? false,
      jackpot: event.jackpot ?? false,
      teamAllowance: event.teamAllowance ?? 100,
      jackpotHoles: event.jackpotHoles ?? [],
      description,
      date,
      courses: event.courses ?? [],
      eventName,
      twoClub: event.twoClub ?? false,
      leagueID,
      guests: event.guests ?? [],
      divisions: event.divisions ?? [],
      eventTime,
      sr: event.sr ?? false,
      leagueName,
      roundIDs,
      triggerHours: event.triggerHours ?? 5,
      teeMap: event.teeMap ?? {},
      clubID: event.clubID ?? "",
      playerIDs,
      sponsors: event.sponsors ?? [],
      roundCreation: event.roundCreation ?? "auto",
      previousEventID,
      ring,
      handicapLimit,
      playerHandicaps,
      previousEventName,
      imageLink,
      dateString,
      adjusted,
      courseID,
      courseName,
      rounds,
      defaultDate,
      restrictions,
      finalHoleCutoff,
      teamPoints,
      format,
      invitedIDs,
      cutoff,
      limit,
      playoff,
      eventType,
      toCount: event.toCount ?? 2,
      customType: event.customType ?? "formula",
      formulas: event.formulas ?? [],
      customHoles: event.customHoles ?? [],
      teamType: event.teamType ?? "",
      seasonID,
      entranceFee,
      rules,
      edition,
      enabledStats,
      scratchMode,
      standalone,
      chatID,
      playoffHoles,
      enableTeams,
      teams: event.teams ?? [],
      presetType,
      percentage,
      ryderCup: event.ryderCup ?? false,
    });

    setLoaded(true);
  }

  async function removeRound(index) {
    const rounds = details.rounds;
    rounds.splice(index, 1);

    try {
      await db.collection("events").doc(eventID).update({
        rounds,
      });
      setShowPop(false);
      toast.success("Round successfully removed", {
        icon: successIco,
      });
      loadEvent();
    } catch (err) {
      console.log(err);
    }
  }

  async function cancelEvent() {
    setShowCancel(false);
    setError("");
    setLoadingText("Cancelling event...");
    setLoaded(false);

    const tasks = await db
      .collection("tasks")
      .where("eventID", "==", eventID)
      // .where("type", "==", "roundCreation")
      .get();

    for (let i = 0; i < tasks.docs.length; i++) {
      const task = tasks.docs[i];

      task.ref.delete();
    }

    try {
      await db.collection("events").doc(eventID).update({
        cancelled: true,
      });
      reload();
      goBack();
    } catch (err) {
      setError("Could not cancel your event, please try again");
    }
  }

  async function removePlayer() {
    setShowRemove(false);
    setError("");
    setLoadingText("Removing player...");
    setLoaded(false);

    try {
      const eventDoc = await db.collection("events").doc(eventID).get();
      const event = eventDoc.data();
      const { playerIDs } = event;

      playerIDs.splice(playerIDs.indexOf(playerRemove), 1);

      await eventDoc.ref.update({
        playerIDs,
      });
      setSelectedPlayers((current) => {
        const temp = [...current];
        const filt1 = current.filter((item) => item.value !== playerRemove);
        const filtered = temp.filter((item) => item.value === playerRemove);
        setPlayersToAdd((current) => [...current, filtered]);
        setPlayersToInvite((current) => [...current, filtered]);
        return filt1;
      });
      toast.success("Player successfully removed", {
        icon: successIco,
      });
      loadEvent();
      setLoaded(true);
    } catch (err) {
      setError("Could not remove player, please try again");
      setLoaded(true);
    }
  }

  async function removeInvite() {
    setShowUninvite(false);
    setError("");
    setLoadingText("Removing player...");
    setLoaded(false);

    try {
      const eventDoc = await db.collection("events").doc(eventID).get();
      const event = eventDoc.data();
      const { invitedIDs } = event;

      invitedIDs.splice(invitedIDs.indexOf(playerUninvite), 1);
      // playerIDs.filter(playerID => playerID !== userID)

      await eventDoc.ref.update({
        invitedIDs,
      });
      setInvitedPlayers((current) =>
        current.filter((item) => item.value !== playerUninvite)
      );
      toast.success("Invite successfully removed", {
        icon: successIco,
      });
      setLoaded(true);
    } catch (err) {
      setError("Could not uninvite player, please try again");
      setLoaded(true);
    }
  }

  async function editGroups(index, groups = [], open, cfa = false) {
    for (let i = 0; i < groups.length; i++) {
      groups[i].players = groups[i].players.filter((pl) => pl.name !== "Empty");
      // const players = groups[i].players;
      // while (players[players.length - 1].name === "Empty") {
      //   console.log("found one");
      //   groups[i].players.pop();
      // }
    }
    // return console.log(groups);
    const rounds = details.rounds;
    rounds[index].groups = groups;
    if (cfa) {
      for (let i = 0; i < rounds.length; i++) {
        rounds[i].groups = groups;
      }
    }

    await db.collection("events").doc(eventID).update({
      rounds,
    });
    loadEvent();
    setShowRound(false);
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);
  const steps = [
    {
      element: ".edit-hint",
      intro: "Tap here to edit the event's details as well as invite players",
    },
    {
      element: ".round-hint",
      intro:
        "Now that the event is set up, time to add a round or two! Tap here to get going",
    },
  ];

  async function resetEvt() {
    setLoaded(false);
    setShowReset(false);
    const rdDocs = await db
      .collection("rounds")
      .where("eventID", "==", eventID)
      .get();

    rdDocs.forEach(async (rdDoc) => {
      const rdData = rdDoc.data();
      rdData.playerIDs.forEach(
        async (playerID) =>
          await rdDoc.ref.collection("scores").doc(playerID).delete()
      );

      await rdDoc.ref.delete();
    });

    await db.collection("events").doc(eventID).update({
      roundIDs: [],
      playerHandicaps: {},
      activeRoundID: "",
      completed: false,
    });

    const triggerDocs = await db
      .collection("tasks")
      .where("eventID", "==", eventID)
      .where("type", "==", "roundCreation")
      .get();

    await triggerDocs.docs[0].ref.update({
      status: "pending",
      performAt: "",
    });

    const refs = selectedPlayers.map((pl, i) =>
      db.collection("users").doc(pl.playerID).update({
        activeRoundID: "",
        activeEventID: "",
      })
    );

    await Promise.all(refs);

    setShowReset(false);
    loadEvent();
  }

  async function invitePlayers(players = []) {
    setShowSelector(false);
    const invitedArr = invitedPlayers.map((player) => player.playerID);
    // console.log(invitedPlayers);
    for (let i = 0; i < players.length; i++) {
      invitedArr.push(players[i].playerID);
    }
    await db.collection("events").doc(eventID).update({
      invitedIDs: invitedArr,
    });
    loadEvent();
  }

  async function saveHandicap({ playerID, handicap }) {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const evtData = eventDoc.data();
    const playerHandicaps = evtData.playerHandicaps ?? {};
    playerHandicaps[playerID] = handicap;
    await eventDoc.ref.update({
      playerHandicaps,
    });
  }

  async function createRounds() {
    setShowCreate(false);
    setLoaded(false);
    const taskDocs = await db
      .collection("tasks")
      .where("eventID", "==", eventID)
      .where("type", "==", "roundCreation")
      .get();
    if (taskDocs.docs.length === 0) {
      setLoaded(true);
      return setError(
        "Sorry, we encountered a technical difficulty there. Please try again."
      );
    }

    const taskDoc = taskDocs.docs[0];
    await taskDoc.ref.update({
      status: "triggered",
    });
    setDisableChanges(true);
    setHideCreate(true);
    loadEvent();
  }

  return (
    <div className="event-home player-round onboarding scale-in-ps">
      {!loaded && <BackdropLoader />}
      {showExports && (
        <EventExports
          divisions={details.divisions}
          roundIDs={details.roundIDs}
          rounds={details.rounds}
          close={() => setShowExports(false)}
          eventID={eventID}
          teams={details.teams}
        />
      )}
      {modal}

      {showUpload && (
        <EventPlayerUpload
          close={() => setShowUpload(false)}
          eventID={eventID}
          reload={loadEvent}
        />
      )}

      {showHCP && (
        <EventHandicapEdit
          teeMap={details.teeMap}
          defaultPercentage={details.percentage}
          defaultPreset={details.presetType}
          eventID={eventID}
          defaultSR={details.sr ?? false}
          courseID={details.courseID}
          defaultPlayers={selectedPlayers}
          hide={() => setShowHCP(false)}
          live={false}
        />
      )}

      {showBC && (
        <BroadcastModal
          close={() => setShowBC(false)}
          eventID={eventID}
          name={details.eventName}
        />
      )}

      {showRound ? (
        round
      ) : showSelector ? (
        <InviteModal
          reload={loadEvent}
          eventID={eventID}
          hide={() => setShowSelector(false)}
          defaultPlayers={playersToInvite}
          league={leagueTable.length > 0}
          leagueMax={leagueTable.length}
          leagueOptions={leagueOptions}
        />
      ) : showEdit ? (
        <EventCreator
          playing={selectedPlayers}
          defaultLeagueID={details.leagueID ?? ""}
          event={details}
          edit={true}
          defaultEclectic={eclectic}
          noCoursEdit={eclectic}
          goBack={() => {
            setShowEdit(false);
            showHelperClick("event-home");
          }}
          eventID={eventID}
          reload={() => {
            loadEvent();
            // reload();
          }}
          disableChanges={disableChanges}
          defaultChosen
        />
      ) : (
        <>
          {!loaded ? (
            <div className="loading-container elc">
              {/* <BallTriangle color="#21c17c" height={80} width={80} /> */}
              {/* <p>{loadingText}</p> */}
            </div>
          ) : (
            <>
              <Dialog open={showReset} onClose={() => setShowReset(false)}>
                <div className="delete-popup">
                  <Info className="big-icon dg-icon" />
                  <p>Are you sure?</p>
                  <div className="flex-center">
                    <button onClick={resetEvt} className="default-button">
                      Confirm
                    </button>
                  </div>
                </div>
              </Dialog>
              <Dialog open={showCreate} onClose={() => setShowCreate(false)}>
                <div className="delete-popup">
                  <Info className="big-icon dg-icon" />
                  <p>
                    Are you sure you want to create the rounds for this event?
                    Some details cannot be changed once this action has been
                    performed.
                  </p>
                  <div className="flex-center">
                    <button onClick={createRounds} className="default-button">
                      Confirm
                    </button>
                  </div>
                </div>
              </Dialog>
              <Dialog open={showPop} onClose={() => setShowPop(false)}>
                <div className="delete-popup">
                  <GradientErrorIcon
                    className="err-icon"
                    style={{ color: "#E24955" }}
                  />
                  <p>Are you sure you want to delete this round?</p>
                  <div
                    onClick={() => removeRound(deleteIndex)}
                    className="delete-button"
                  >
                    Delete
                  </div>
                </div>
              </Dialog>
              <Dialog open={showCancel} onClose={() => setShowCancel(false)}>
                <div className="delete-popup">
                  <GradientErrorIcon
                    className="err-icon"
                    style={{ color: "#E24955" }}
                  />
                  <p>Are you sure you want to cancel this event?</p>
                  <button
                    onClick={() => cancelEvent()}
                    className="delete-button"
                  >
                    Confirm
                  </button>
                </div>
              </Dialog>
              <Dialog open={showRemove} onClose={() => setShowRemove(false)}>
                <div className="delete-popup">
                  <GradientErrorIcon
                    className="err-icon"
                    style={{ color: "#E24955" }}
                  />
                  <p>Are you sure you want to remove this player?</p>
                  <button
                    onClick={() => removePlayer()}
                    className="delete-button"
                  >
                    Confirm
                  </button>
                </div>
              </Dialog>
              <Dialog
                open={showUninvite}
                onClose={() => setShowUninvite(false)}
              >
                <div className="delete-popup">
                  <GradientErrorIcon
                    className="err-icon"
                    style={{ color: "#E24955" }}
                  />
                  <p>Are you sure you want to uninvite this player?</p>
                  <div onClick={() => removeInvite()} className="delete-button">
                    Confirm
                  </div>
                </div>
              </Dialog>

              {showManual && (
                <ManualPlayerAdd
                  eventID={eventID}
                  leagueOptions={leagueOptions}
                  league={leagueTable.length > 0}
                  leagueMax={leagueTable.length}
                  reload={loadEvent}
                  // limit={details.limit}
                  hide={() => setShowManual(false)}
                  players={playersToAdd}
                />
              )}

              <div onClick={() => goBack()} className="back-row">
                <ArrowBack className="ts-arrow" />
                <p>Back</p>
              </div>
              <div className="ph-me-opt">
                <PageHeader text={details.eventName} />

                <MenuSwitcher />
              </div>

              {cookies["menu-type"] === "drop" && (
                <div className="flex flex-align-center mb-20 mt-10 ddmb-box pl-20">
                  <div className="ddm-box">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>General</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          {details.roundIDs.length > 0 &&
                            userDetails &&
                            // !details.completed &&
                            (userDetails.admin || userDetails.test) && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setShowReset(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Reset
                              </DropdownMenu.Item>
                            )}
                          <DropdownMenu.Item
                            onClick={() => {
                              setShowEdit(true);
                              showHelperClick("evt-edit");
                            }}
                            className="DropdownMenuItem"
                          >
                            Edit Details
                          </DropdownMenu.Item>
                          {details.roundIDs.length > 0 && (
                            <Link target="_blank" to={`/evt-dash/${eventID}`}>
                              <DropdownMenu.Item
                                onClick={() => {}}
                                className="DropdownMenuItem"
                              >
                                Live Dashboard
                              </DropdownMenu.Item>
                            </Link>
                          )}
                          <DropdownMenu.Item
                            onClick={() => {
                              window.open(
                                `https://www.theugatour.com/events/${eventID}`,
                                "_blank"
                              );
                            }}
                            className="DropdownMenuItem"
                          >
                            Public Link
                          </DropdownMenu.Item>

                          <DropdownMenu.Item
                            onClick={() => {
                              window.open(`/#/leagues/${details.leagueID}`);
                            }}
                            className="DropdownMenuItem"
                          >
                            League Page
                          </DropdownMenu.Item>

                          <DropdownMenu.Item
                            onClick={() => {
                              if (details.chatID) {
                                showClick({
                                  type: "chat",
                                  chatID: details.chatID,
                                });
                              } else {
                                setShowBC(true);
                              }
                            }}
                            className="DropdownMenuItem"
                          >
                            Group Message
                          </DropdownMenu.Item>

                          {roundCreation === "manual" &&
                            details.rounds.length > 0 &&
                            details.roundIDs.length === 0 &&
                            !hideCreate && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setShowCreate(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Create Rounds
                              </DropdownMenu.Item>
                            )}
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                  <div className="ddm-box">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Players</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          {!disableChanges && (
                            <DropdownMenu.Item
                              onClick={() => loaded && setShowManual(true)}
                              className="DropdownMenuItem"
                            >
                              Add Players
                            </DropdownMenu.Item>
                          )}

                          {!disableChanges && (
                            <DropdownMenu.Item
                              onClick={() => loaded && setShowSelector(true)}
                              className="DropdownMenuItem"
                            >
                              Invite Players
                            </DropdownMenu.Item>
                          )}
                          {!disableChanges && details.clubID && !isMobile && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setRound(
                                  <EventPlayerUpload
                                    close={() => setShowRound(false)}
                                    eventID={eventID}
                                    reload={loadEvent}
                                    clubID={details.clubID}
                                  />
                                );
                                setShowRound(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Upload Players
                            </DropdownMenu.Item>
                          )}
                          <DropdownMenu.Item
                            onClick={() => {
                              setRound(
                                <EventPlayerList
                                  reload={() => {
                                    loadEvent();
                                  }}
                                  eventID={eventID}
                                  disableChanges={disableChanges}
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                />
                              );
                              setShowRound(true);
                              showHelperClick("evt-player-list");
                            }}
                            className="DropdownMenuItem"
                          >
                            Player Handicaps
                          </DropdownMenu.Item>
                          {details.roundIDs.length === 0 && details.clubID && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setRound(
                                  <EventGuests
                                    customEvtID={details.guestEventID ?? ""}
                                    defaultGuests={details.guests ?? []}
                                    reload={loadEvent}
                                    eventID={eventID}
                                    goBack={() => {
                                      setShowRound(false);
                                      showHelperClick("event-home");
                                    }}
                                    disableChanges={disableChanges}
                                  />
                                );
                                setShowRound(true);
                                showHelperClick("evt-guests");
                              }}
                              className="DropdownMenuItem"
                            >
                              Manage Guests
                            </DropdownMenu.Item>
                          )}
                          {details.enableDivisions && !disableChanges && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setRound(
                                  <HandicapDivisions
                                    stdl={details.leagueID === "standalone"}
                                    clubID={details.clubID}
                                    reload={loadEvent}
                                    defaultDivisions={details.divisions}
                                    eventCreator={false}
                                    eventID={eventID}
                                    toAdd={[
                                      ...selectedPlayers,
                                      ...details.guests,
                                    ]}
                                    goBack={() => {
                                      setShowRound(false);
                                      showHelperClick("event-home");
                                    }}
                                  />
                                );
                                setShowRound(true);
                                showHelperClick("hcp-divs");
                              }}
                              className="DropdownMenuItem"
                            >
                              Manage Divisions
                            </DropdownMenu.Item>
                          )}
                          {!disableChanges && false && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setShowHCP(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Manage Handicaps
                            </DropdownMenu.Item>
                          )}
                          {!disableChanges &&
                            selCourses.length > 1 &&
                            details.rounds.length > 0 && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setRound(
                                    <PlayerCourseSelector
                                      goBack={() => setShowRound(false)}
                                      eventID={eventID}
                                      defaultCourse={details.courseID}
                                    />
                                  );
                                  setShowRound(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Player Courses
                              </DropdownMenu.Item>
                            )}
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                  {(details.enableTeams ||
                    details.format.includes("team") ||
                    details.format === "better-ball") &&
                    !disableChanges && (
                      <div className="ddm-box">
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger asChild>
                            <button className="ddm-but">
                              <span>Teams</span>
                              <KeyboardArrowDown className="ddm-ico" />
                            </button>
                          </DropdownMenu.Trigger>

                          <DropdownMenu.Portal>
                            <DropdownMenu.Content
                              className="DropdownMenuContent"
                              sideOffset={5}
                            >
                              <DropdownMenu.Item
                                onClick={() => {
                                  setRound(
                                    <EventTeamManager
                                      leagueID={details.leagueID}
                                      goBack={() => {
                                        setShowRound(false);
                                        showHelperClick("event-home");
                                      }}
                                      eventID={eventID}
                                    />
                                  );
                                  setShowRound(true);
                                  showHelperClick("evt-teams");
                                }}
                                className="DropdownMenuItem"
                              >
                                Manage Teams
                              </DropdownMenu.Item>

                              <DropdownMenu.Item
                                onClick={() => {
                                  setRound(
                                    <EventTeamUpload
                                      close={() => setShowRound(false)}
                                      eventID={eventID}
                                      reload={loadEvent}
                                      clubID={details.clubID}
                                    />
                                  );
                                  setShowRound(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                Upload Teams
                              </DropdownMenu.Item>
                            </DropdownMenu.Content>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                      </div>
                    )}

                  {!disableChanges && (
                    <div className="ddm-box">
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <button className="ddm-but">
                            <span>Rounds</span>
                            <KeyboardArrowDown className="ddm-ico" />
                          </button>
                        </DropdownMenu.Trigger>

                        <DropdownMenu.Portal>
                          <DropdownMenu.Content
                            className="DropdownMenuContent"
                            sideOffset={5}
                          >
                            {details.rounds.length < 4 && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  setRound(
                                    <RoundInput
                                      format={details.format}
                                      eventName={details.eventName}
                                      eventCourseName={details.courseName}
                                      eventType={details.eventType}
                                      eventDate={details.date}
                                      defaultCourseID={details.courseID}
                                      defaultCourseName={details.courseName}
                                      participants={selectedPlayers}
                                      seasonID={details.seasonID}
                                      rounds={details.rounds}
                                      roundIndex={details.rounds.length}
                                      defaultTime={details.eventTime}
                                      defaultDate={
                                        details.date.toISOString().split("T")[0]
                                      }
                                      eventID={eventID}
                                      goBack={() => {
                                        setShowRound(false);
                                        showHelperClick("rd-setup");
                                      }}
                                      saveRound={() => {
                                        loadEvent();
                                        setShowRound(false);
                                      }}
                                      courses={courses}
                                      players={details.playerIDs.length}
                                      num={details.rounds.length + 1}
                                      eventCourseID={details.courseID}
                                    />
                                  );
                                  setShowRound(true);
                                  showHelperClick("rd-setup");
                                }}
                                className="DropdownMenuItem"
                              >
                                Add Round
                              </DropdownMenu.Item>
                            )}

                            {details.enableTeams &&
                              details.teamType === "custom" && (
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setRound(
                                      <CustomFormats
                                        eventID={eventID}
                                        reload={loadEvent}
                                        defaultCourseCard={details.customHoles}
                                        courseID={details.courseID}
                                        defaultFormulas={details.formulas}
                                        defaultSelected={details.customType}
                                        defaultToCount={details.toCount}
                                        goBack={() => {
                                          setShowRound(false);
                                          showHelperClick("event-home");
                                        }}
                                      />
                                    );
                                    setShowRound(true);
                                    showHelperClick("format-custom");
                                  }}
                                  className="DropdownMenuItem"
                                >
                                  Custom Formats
                                </DropdownMenu.Item>
                              )}
                          </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Root>
                    </div>
                  )}

                  <div className="ddm-box">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Admin</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          <DropdownMenu.Item
                            onClick={() => {
                              setRound(
                                <LeaderboardSettings
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                  id={eventID}
                                  type="evt"
                                />
                              );
                              setShowRound(true);
                              showHelperClick("lb-setts");
                            }}
                            className="DropdownMenuItem"
                          >
                            Leaderboard Settings
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={() => {
                              setRound(
                                <PrizeManager
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                  eventID={eventID}
                                />
                              );
                              setShowRound(true);
                              showHelperClick("prizes");
                            }}
                            className="DropdownMenuItem"
                          >
                            Manage Prizes
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={() => {
                              setRound(
                                <EventSponsorManager
                                  reload={loadEvent}
                                  selectedSponsors={details.sponsors}
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                  clubID={details.clubID}
                                  eventID={eventID}
                                />
                              );
                              setShowRound(true);
                              showHelperClick("evt-sponsors");
                            }}
                            className="DropdownMenuItem"
                          >
                            Manage Sponsors
                          </DropdownMenu.Item>
                          {details.rounds.length > 0 && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setRound(
                                  <ScorecardCreator
                                    eventID={eventID}
                                    goBack={() => setShowRound(false)}
                                  />
                                );
                                setShowRound(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Print Scorecards
                            </DropdownMenu.Item>
                          )}
                          {details.jackpot && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setRound(
                                  <JackpotHoles
                                    eventID={eventID}
                                    goBack={() => setShowRound(false)}
                                  />
                                );
                                setShowRound(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Jackpot Holes
                            </DropdownMenu.Item>
                          )}
                          {details.rounds.length > 0 && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setShowExports(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Exports
                            </DropdownMenu.Item>
                          )}
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                </div>
              )}

              {cookies["menu-type"] !== "drop" && (
                <div className="ch-links">
                  <div className="ch-link">
                    <div className="sub-header mb-10">
                      <h5>General</h5>
                    </div>
                    <div className="quick-links">
                      {details.roundIDs.length > 0 &&
                        userDetails &&
                        !details.completed &&
                        (userDetails.admin || userDetails.test) && (
                          <>
                            <Pill
                              text={"Reset"}
                              onClick={() => setShowReset(true)}
                            />
                          </>
                        )}
                      {details.roundIDs.length > 0 && (
                        <Link to={`/evt-dash/${eventID}`}>
                          <Pill
                            text={"Event Dashboard"}
                            onClick={() => {
                              // setRound(
                              //   <EventDashboard
                              //     eventID={eventID}
                              //     goBack={() => setShowRound(false)}
                              //   />
                              // );
                              // setShowRound(true);
                            }}
                          />
                        </Link>
                      )}
                      <Pill
                        text={"Edit Details"}
                        onClick={() => {
                          setShowEdit(true);
                          showHelperClick("evt-edit");
                        }}
                      />
                      <Pill
                        text={"Public Link "}
                        onClick={() => {
                          window.open(
                            `https://www.theugatour.com/events/${eventID}`,
                            "_blank"
                          );
                        }}
                      />
                      {details.leagueID !== "standalone" && (
                        <Pill
                          text={"League Page"}
                          onClick={() => {
                            window.open(`/#/leagues/${details.leagueID}`);
                          }}
                        />
                      )}
                      <Pill
                        text={"Group Message"}
                        onClick={() => {
                          if (details.chatID) {
                            showClick({
                              type: "chat",
                              chatID: details.chatID,
                            });
                          } else {
                            setShowBC(true);
                          }
                        }}
                      />
                      {roundCreation === "manual" &&
                        details.rounds.length > 0 &&
                        details.roundIDs.length === 0 &&
                        !hideCreate && (
                          <Pill
                            onClick={() => setShowCreate(true)}
                            text={"Create Rounds"}
                          />
                        )}
                    </div>
                  </div>
                  {true && (
                    <div className="ch-link">
                      <div className="sub-header mb-10">
                        <h5>Players</h5>
                      </div>
                      <div className="quick-links">
                        {!disableChanges && (
                          <Pill
                            text={"Add Players"}
                            onClick={() => loaded && setShowManual(true)}
                          />
                        )}
                        {!disableChanges && (
                          <Pill
                            text={"Invite Players"}
                            onClick={() => loaded && setShowSelector(true)}
                          />
                        )}

                        {details.clubID && !disableChanges && !isMobile && (
                          <Pill
                            text={"Upload Players"}
                            onClick={() => {
                              setRound(
                                <EventPlayerUpload
                                  close={() => setShowRound(false)}
                                  eventID={eventID}
                                  reload={loadEvent}
                                  clubID={details.clubID}
                                />
                              );
                              setShowRound(true);
                            }}
                          />
                        )}
                        {details.rounds.length > 0 && (
                          <Pill
                            text={"Player Handicaps"}
                            onClick={() => {
                              setRound(
                                <EventPlayerList
                                  reload={loadEvent}
                                  eventID={eventID}
                                  disableChanges={disableChanges}
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                />
                              );
                              setShowRound(true);
                              showHelperClick("evt-player-list");
                            }}
                          />
                        )}
                        {details.ryderCup && (
                          <Pill
                            text={"Ryder Cup Teams"}
                            onClick={() => {
                              setRound(
                                <RyderTeams
                                  eventID={eventID}
                                  goBack={() => setShowRound(false)}
                                  players={selectedPlayers}
                                  reload={loadEvent}
                                />
                              );
                              setShowRound(true);
                            }}
                          />
                        )}
                        {details.roundIDs.length === 0 && details.clubID && (
                          <Pill
                            text={"Manage Guests"}
                            onClick={() => {
                              setRound(
                                <EventGuests
                                  customEvtID={details.guestEventID ?? ""}
                                  defaultGuests={details.guests ?? []}
                                  reload={loadEvent}
                                  eventID={eventID}
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                  disableChanges={disableChanges}
                                />
                              );
                              setShowRound(true);
                              showHelperClick("evt-guests");
                            }}
                          />
                        )}
                        {/* {!disableChanges && (
                        <Pill text={"Import Players"} onClick={() => {}} />
                      )} */}

                        {details.enableDivisions && !disableChanges && (
                          <Pill
                            text={"Manage Divisions"}
                            onClick={() => {
                              setRound(
                                <HandicapDivisions
                                  stdl={details.leagueID === "standalone"}
                                  clubID={details.clubID}
                                  reload={loadEvent}
                                  defaultDivisions={details.divisions}
                                  eventCreator={false}
                                  eventID={eventID}
                                  toAdd={[
                                    ...selectedPlayers,
                                    ...details.guests,
                                  ]}
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                />
                              );
                              setShowRound(true);
                              showHelperClick("hcp-divs");
                            }}
                          />
                        )}
                        {/* {!disableChanges && ( */}
                        {!disableChanges && false && (
                          <Pill
                            text={"Manage Handicaps"}
                            onClick={() => {
                              setShowHCP(true);
                            }}
                          />
                        )}
                        {!disableChanges &&
                          selCourses.length > 1 &&
                          details.rounds.length > 0 && (
                            <Pill
                              text={"Player Courses"}
                              onClick={() => {
                                setRound(
                                  <PlayerCourseSelector
                                    goBack={() => setShowRound(false)}
                                    eventID={eventID}
                                    defaultCourse={details.courseID}
                                  />
                                );
                                setShowRound(true);
                              }}
                            />
                          )}
                        {(details.enableTeams ||
                          details.format.includes("team") ||
                          details.format === "better-ball") &&
                          !disableChanges && (
                            <Pill
                              text={"Manage Teams"}
                              onClick={() => {
                                setRound(
                                  <EventTeamManager
                                    leagueID={details.leagueID}
                                    goBack={() => {
                                      setShowRound(false);
                                      showHelperClick("event-home");
                                    }}
                                    eventID={eventID}
                                  />
                                );
                                setShowRound(true);
                                showHelperClick("evt-teams");
                              }}
                            />
                          )}
                        {(details.enableTeams ||
                          details.format.includes("team") ||
                          details.format === "better-ball") &&
                          !disableChanges &&
                          details.clubID && (
                            <Pill
                              text={"Upload Teams"}
                              onClick={() => {
                                setRound(
                                  <EventTeamUpload
                                    close={() => setShowRound(false)}
                                    eventID={eventID}
                                    reload={loadEvent}
                                    clubID={details.clubID}
                                  />
                                );
                                setShowRound(true);
                              }}
                            />
                          )}
                        {!disableChanges && (
                          <Pill
                            text={"Remove Players"}
                            red
                            onClick={() => {
                              setRound(
                                <EvtPlayerRemoval
                                  close={() => setShowRound(false)}
                                  defaultPlayers={selectedPlayers}
                                  eventID={eventID}
                                  reload={loadEvent}
                                />
                              );
                              setShowRound(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {((details.rounds.length < 4 && !disableChanges) ||
                    !disableChanges) && (
                    <div className="ch-link">
                      <div className="sub-header mb-10">
                        <h5>Rounds</h5>
                      </div>
                      <div className="quick-links">
                        {details.rounds.length < 4 && !disableChanges && (
                          <Pill
                            text={"Add Round"}
                            onClick={() => {
                              setRound(
                                <RoundInput
                                  format={details.format}
                                  eventName={details.eventName}
                                  eventCourseName={details.courseName}
                                  eventType={details.eventType}
                                  eventDate={details.date}
                                  defaultCourseID={details.courseID}
                                  defaultCourseName={details.courseName}
                                  participants={selectedPlayers}
                                  seasonID={details.seasonID}
                                  rounds={details.rounds}
                                  roundIndex={details.rounds.length}
                                  defaultTime={details.eventTime}
                                  defaultDate={
                                    details.date.toISOString().split("T")[0]
                                  }
                                  eventID={eventID}
                                  goBack={() => {
                                    setShowRound(false);
                                    showHelperClick("event-home");
                                  }}
                                  saveRound={() => {
                                    loadEvent();
                                    setShowRound(false);
                                  }}
                                  courses={courses}
                                  players={details.playerIDs.length}
                                  num={details.rounds.length + 1}
                                  eventCourseID={details.courseID}
                                />
                              );
                              setShowRound(true);
                              showHelperClick("rd-setup");
                            }}
                          />
                        )}

                        {details.enableTeams &&
                          details.teamType === "custom" &&
                          !disableChanges && (
                            <Pill
                              text={"Format Customizer"}
                              onClick={() => {
                                setRound(
                                  <CustomFormats
                                    eventID={eventID}
                                    reload={loadEvent}
                                    defaultCourseCard={details.customHoles}
                                    courseID={details.courseID}
                                    defaultFormulas={details.formulas}
                                    defaultSelected={details.customType}
                                    defaultToCount={details.toCount}
                                    goBack={() => {
                                      setShowRound(false);
                                      showHelperClick("event-home");
                                    }}
                                  />
                                );
                                setShowRound(true);
                                showHelperClick("format-custom");
                              }}
                            />
                          )}
                      </div>
                    </div>
                  )}
                  <div className="ch-link">
                    <div className="sub-header mb-10">
                      <h5>Admin</h5>
                    </div>
                    <div className="quick-links">
                      {/* {userDetails && (userDetails.test || userDetails.admin) && ( */}
                      <Pill
                        text={"Leaderboard Settings"}
                        onClick={() => {
                          setRound(
                            <LeaderboardSettings
                              goBack={() => {
                                setShowRound(false);
                                showHelperClick("event-home");
                              }}
                              id={eventID}
                              type="evt"
                            />
                          );
                          setShowRound(true);
                          showHelperClick("lb-setts");
                        }}
                      />
                      {/* )} */}
                      <Pill
                        text={"Manage Prizes"}
                        onClick={() => {
                          setRound(
                            <PrizeManager
                              goBack={() => {
                                setShowRound(false);
                                showHelperClick("event-home");
                              }}
                              eventID={eventID}
                            />
                          );
                          setShowRound(true);
                          showHelperClick("prizes");
                        }}
                      />
                      {details.clubID && (
                        <Pill
                          text={"Manage Sponsors"}
                          onClick={() => {
                            console.log(details.sponsors);
                            setRound(
                              <EventSponsorManager
                                reload={loadEvent}
                                selectedSponsors={details.sponsors}
                                goBack={() => {
                                  setShowRound(false);
                                  showHelperClick("event-home");
                                }}
                                clubID={details.clubID}
                                eventID={eventID}
                              />
                            );
                            setShowRound(true);
                            showHelperClick("evt-sponsors");
                          }}
                        />
                      )}
                      {details.rounds.length > 0 && (
                        <>
                          <Pill
                            text={"Print Scorecards"}
                            onClick={() => {
                              setRound(
                                <ScorecardCreator
                                  eventID={eventID}
                                  goBack={() => setShowRound(false)}
                                />
                              );
                              setShowRound(true);
                            }}
                          />
                        </>
                      )}

                      {details.jackpot && (
                        <Pill
                          text={"Jackpot Holes"}
                          onClick={() => {
                            setRound(
                              <JackpotHoles
                                eventID={eventID}
                                goBack={() => setShowRound(false)}
                              />
                            );
                            setShowRound(true);
                          }}
                        />
                      )}

                      {details.rounds.length > 0 && (
                        <Pill
                          text={"Exports"}
                          onClick={() => setShowExports(true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="quick-links eh-ql">{/* )} */}</div>

              <div className="eh-box-box">
                <div className="eh-box">
                  <div className="ehb-header pt-0">
                    <h1 className="ne-header">Details</h1>
                    <div className="svg-cont">
                      <GradientEditIcon
                        className="edit-hint mb-10"
                        onClick={() => setShowEdit(true)}
                        style={{ color: "#21c17c" }}
                      />
                    </div>
                  </div>
                  <div className="ehbh-item">
                    <div className="info-circle">
                      <TodayIcon className="ic-icon" />
                    </div>
                    {details.dateString !== undefined ? (
                      <p>{details.dateString}</p>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Skeleton variant="text" />
                      </div>
                    )}
                  </div>
                  <div className="ehbh-item">
                    <div className="info-circle">
                      <GolfCourseIcon className="ic-icon dg-icon" />
                    </div>
                    {details.courseName === undefined ? (
                      <div style={{ width: "50%" }}>
                        <Skeleton variant="text" />
                      </div>
                    ) : (
                      <p>{details.courseName}</p>
                    )}
                  </div>
                  {!details.standalone && (
                    <div className="ehbh-item">
                      <div className="info-circle">
                        <Person className="ic-icon" />
                      </div>
                      {details.leagueName === undefined ? (
                        <div style={{ width: "50%" }}>
                          <Skeleton variant="text" />
                        </div>
                      ) : (
                        <p>{details.leagueName}</p>
                      )}
                    </div>
                  )}
                  {details.description && (
                    <div className="ehbh-item">
                      <div className="info-circle">
                        <TextSnippetIcon className="ic-icon" />
                      </div>
                      {details.description === undefined ? (
                        <div style={{ width: "50%" }}>
                          <Skeleton variant="rectangular" height={100} />
                        </div>
                      ) : (
                        <p style={{ width: "70vw", whiteSpace: "pre-wrap" }}>
                          {details.description}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="p-r-course">
                  <div className="p-r-course-left">
                    <Avatar alt={details.leagueName} src={details.imageLink} />
                  </div>
                </div>
              </div>

              <div className="ehb-header">
                <h1 className="ne-header">Rounds</h1>
                {!disableChanges && (
                  <div className="svg-cont mb-10">
                    <GradientCircleIcon
                      className="round-hint"
                      onClick={() => {
                        if (details.rounds.length > 3) {
                          return null;
                        }
                        setRound(
                          <RoundInput
                            format={details.format}
                            defaultTime={details.eventTime}
                            eventName={details.eventName}
                            eventCourseName={details.courseName}
                            eventType={details.eventType}
                            eventDate={details.date}
                            defaultCourseID={details.courseID}
                            defaultCourseName={details.courseName}
                            participants={selectedPlayers}
                            seasonID={details.seasonID}
                            rounds={details.rounds}
                            roundIndex={details.rounds.length}
                            defaultDate={
                              details.date.toISOString().split("T")[0]
                            }
                            eventID={eventID}
                            goBack={() => {
                              setShowRound(false);
                              showHelperClick("event-home");
                            }}
                            saveRound={() => {
                              loadEvent();
                              setShowRound(false);
                            }}
                            courses={courses}
                            players={details.playerIDs.length}
                            num={details.rounds.length + 1}
                            eventCourseID={details.courseID}
                          />
                        );
                        setShowRound(true);
                        showHelperClick("rd-setup");
                      }}
                      style={{
                        color: details.rounds.length > 3 ? "grey" : "#21c17c",
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="ehb-rounds">
                {details.rounds.map((round, index) => {
                  return (
                    <EventRound
                      loading={loadingToInvite && !disableChanges}
                      hideIcons={disableChanges}
                      editTees={() => {
                        setRound(
                          <PlayerTeeSelector
                            reload={loadEvent}
                            goBack={() => setShowRound(false)}
                            eventID={eventID}
                            eventName={details.eventName}
                            players={[...selectedPlayers, ...details.guests]}
                            round={round}
                            roundIndex={index}
                            rounds={details.rounds}
                          />
                        );
                        setShowRound(true);
                      }}
                      custom={round.groupTypes === "custom"}
                      key={index}
                      editTimes={() => {
                        setRound(
                          <GroupSelector
                            rounds={details.rounds}
                            teams={details.teams}
                            clubID={details.clubID}
                            eventID={eventID}
                            reload={loadEvent}
                            guests={details.guests ?? []}
                            defaultShotgun={round.startType === "shotgun"}
                            open={round.open ?? false}
                            leagueID={details.leagueID}
                            defaultLimit={round.groupLimit ?? 0}
                            defaultGroups={round.groups}
                            event
                            saveChanges={(groups, cfa) => {
                              editGroups(index, groups, round.open, cfa);
                            }}
                            roundIndex={index}
                            eventDate={details.date}
                            courseName={details.courseName}
                            eventName={details.eventName}
                            courseDetails={round}
                            players={selectedPlayers}
                            goBack={() => setShowRound(false)}
                            setOverall={() => {}}
                            uid={currentUser.uid}
                          />
                        );
                        // setRound(<TeeTimeEditor reload={loadEvent} eventID={eventID} roundIndex={index} goBack={() => setShowRound(false)} limit={round.groupLimit} players={selectedPlayers} groups={round.groups} />)
                        setShowRound(true);
                      }}
                      edit={() => {
                        setRound(
                          <RoundInput
                            format={details.format}
                            defaultStartType={round.startType}
                            defaultOpen={round.open}
                            eventName={details.eventName}
                            defaultCTPEnabled={round.ctpEnabled}
                            defaultLDEnabled={round.ldEnabled}
                            defaultCTPHole={round.ctpHole}
                            defaultLDHole={round.ldHole}
                            defaultImageLink={round.imageLink}
                            eventCourseName={details.courseName}
                            eventDate={details.date}
                            seasonID={details.seasonID}
                            roundIndex={index}
                            defaultGroups={round.groups ?? []}
                            defaultTimeType={round.timeTypes}
                            attLimit={details.limit}
                            edit={true}
                            eventID={eventID}
                            goBack={() => {
                              setShowRound(false);
                              showHelperClick("event-home");
                            }}
                            courses={courses}
                            num={index + 1}
                            saveRound={() => {
                              loadEvent();
                              setShowRound(false);
                            }}
                            defaultCourseName={details.courseName}
                            defaultCourseID={round.courseID ?? details.courseID}
                            eventCourseID={details.courseID}
                            defaultDate={
                              new Date(round.date.toDate())
                                .toISOString()
                                .split("T")[0]
                            }
                            defaultIntervals={round.intervals}
                            defaultLimit={round.groupLimit}
                            defaultTypes={round.groupTypes}
                            defaultTees={round.tees}
                            defaultTime={new Date(
                              round.date.toDate()
                            ).toLocaleTimeString()}
                            open={details.restrictions === "open"}
                            rounds={details.rounds}
                            players={details.playerIDs.length}
                          />
                        );
                        setShowRound(true);
                        showHelperClick("rd-setup");
                      }}
                      remove={() => {
                        setDeleteIndex(index);
                        setShowPop(true);
                      }}
                      img={round.imageLink}
                      date={round.date}
                      num={index + 1}
                    />
                  );
                })}

                {details.rounds.length === 0 && (
                  <div className="no-rounds">
                    <p>No rounds added yet</p>
                  </div>
                )}
              </div>
              <div className="ehb-header pb-0">
                <h1 className="ne-header">Players</h1>
              </div>

              <RadioGroup
                active={active}
                setActive={setActive}
                buttons={[
                  { text: "Playing", value: "playing" },
                  { text: "Invited", value: "invited" },
                  { text: "Declined", value: "declined" },
                ]}
              />

              <div className="input-group pl-20 mb-20">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Start typing..."
                  value={playerSearch}
                  onChange={(e) => setPlayerSearch(e.target.value)}
                  className="default-input"
                />
              </div>

              {loadingToInvite ? (
                <div className="flex-center loading">
                  <Oval
                    color="#1e7a69"
                    secondaryColor="#ffffff"
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <div className="eh-players-box">
                  <div className="epb-left">
                    {active === "playing" ? (
                      <>
                        <div className="ig-header">
                          <h3 className="msb">
                            Playing (
                            {selectedPlayers.length + details.guests.length})
                          </h3>
                          {!disableChanges && (
                            <div className="svg-cont eh-invite-add">
                              <GradientCircleIcon
                                onClick={() => loaded && setShowManual(true)}
                                style={{
                                  color:
                                    invitedPlayers.length > details.limit
                                      ? "grey"
                                      : "#21c17c",
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "20px",
                                }}
                                className="round-hint"
                              />
                            </div>
                          )}
                        </div>
                        <div className="part-players-box">
                          {selectedPlayers.map((player) => {
                            if (playerSearch !== "") {
                              const ps = playerSearch.toLowerCase();
                              const pnl = player.name.toLowerCase();
                              if (!pnl.includes(ps)) {
                                return null;
                              }
                            }
                            return (
                              <div key={player.playerID} className="ec-pi">
                                <PlayerItem
                                  event
                                  hideMessage={
                                    player.playerID === currentUser.uid
                                  }
                                  playerID={player.playerID}
                                  hideRemove={disableChanges}
                                  hideEdit={true}
                                  showAdd
                                  hcp={player.handicap}
                                  name={player.name}
                                  img={player.profileImage}
                                  showProfile={() => {}}
                                  removeAction={() => {
                                    setPlayerRemove(player.value);
                                    setShowRemove(true);
                                  }}
                                  editAction={() => {
                                    setModal(
                                      <PlayerSetup
                                        hcpOnly
                                        playerID={player.playerID}
                                        handicap={player.handicap}
                                        changeHandicap={(newHcp) => {
                                          saveHandicap({
                                            playerID: player.playerID,
                                            handicap: newHcp,
                                          });
                                          setSelectedPlayers((current) => {
                                            for (
                                              let i = 0;
                                              i < current.length;
                                              i++
                                            ) {
                                              if (
                                                current[i].playerID ===
                                                player.playerID
                                              ) {
                                                current[i].handicap = newHcp;
                                              }
                                            }
                                            return current;
                                          });
                                        }}
                                        name={player.name}
                                        customHandicap
                                        imageLink={player.profileImage}
                                        goBack={() => setModal()}
                                      />
                                    );
                                    // setShowRound(true);
                                  }}
                                />
                              </div>
                            );
                          })}
                          {details.guests.map((player) => {
                            if (playerSearch !== "") {
                              const ps = playerSearch.toLowerCase();
                              const pnl = player.name.toLowerCase();
                              if (!pnl.includes(ps)) {
                                return null;
                              }
                            }
                            return (
                              <PlayerItem
                                key={player.guestID}
                                hcp={player.handicap}
                                name={`${player.firstName} ${player.lastName}`}
                                hideIcons
                                hideImage
                                noLink
                              />
                            );
                          })}
                          {selectedPlayers.length === 0 &&
                            details.guests.length === 0 && (
                              <div className="no-players">
                                <p>
                                  No players have confirmed their attendance yet
                                </p>
                              </div>
                            )}
                        </div>
                      </>
                    ) : active === "invited" ? (
                      <>
                        <div className="ig-header">
                          <h3 className="msb">
                            Invited ({invitedPlayers.length})
                          </h3>
                          {!disableChanges && (
                            <div className="svg-cont eh-invite-add">
                              <GradientCircleIcon
                                onClick={() => loaded && setShowSelector(true)}
                                style={{
                                  color:
                                    invitedPlayers.length > details.limit
                                      ? "grey"
                                      : "#21c17c",
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "20px",
                                }}
                                className="round-hint"
                              />
                            </div>
                          )}
                        </div>
                        <div className="part-players-box">
                          {invitedPlayers.map((player) => {
                            if (playerSearch !== "") {
                              const ps = playerSearch.toLowerCase();
                              const pnl = player.name.toLowerCase();
                              if (!pnl.includes(ps)) {
                                return null;
                              }
                            }
                            return (
                              <div
                                style={{ justifyContent: "space-between" }}
                                className="ec-pi"
                              >
                                <PlayerItem
                                  playerID={player.playerID}
                                  hcp={player.handicap}
                                  name={player.name}
                                  event
                                  hideEdit
                                  showAdd
                                  removeAction={() => {
                                    setPlayerUninvite(player.value);
                                    setShowUninvite(true);
                                  }}
                                  img={player.profileImage}
                                />
                              </div>
                            );
                          })}
                        </div>
                        {invitedPlayers.length === 0 && (
                          <div className="no-players">
                            <p>No players have been invited yet</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="ig-header">
                          <h3 className="msb">Declined</h3>
                          <GradientOpacityIcon className="dg-icon-bg dc-ic-op" />
                        </div>
                        <div className="part-players-box"></div>
                        {declinedPlayers.length === 0 && (
                          <div className="no-players">
                            <p>No players have declined yet</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {error !== "" && (
                <ErrorModal hide={() => setError("")} text={error} />
              )}

              {/* <div className="ec-button-row">
                <button className='invite-button' onClick={() => setShowPlayers(true)}>Invite Players</button>

                </div> */}

              {!disableChanges && (
                <div className="flex-center mt-20 mb-20">
                  <button
                    onClick={() => setShowCancel(true)}
                    className="cancel-button delete-button"
                  >
                    Cancel Event
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default EventHome;
