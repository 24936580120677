import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import "./LeagueEdit.css";
import { CSVLink, CSVDownload } from "react-csv";

// Icons

// Components
import StatsHeader from "./StatsHeader";
import { Oval } from "react-loader-spinner";
import StatsItem from "./StatsItem";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

function LeagueStats({ leagueID }) {
  const droptions = [
    { label: "General", value: "General" },
    { label: "Scoring", value: "Scoring" },
    { label: "Holes", value: "Holes" },
  ];

  const generalOptions = [
    {
      label: "Net Ave",
      value: "averageNet",
      type: "net",
    },
    {
      label: "Gross Ave",
      value: "averageGross",
      type: "net",
    },
    {
      label: "Putts",
      value: "averagePutts",
      type: "net",
    },
    {
      label: "FIR",
      value: "averageFIR",
      type: "net",
    },
    {
      label: "GIR",
      value: "averageGIR",
      type: "net",
    },

    {
      label: "Wins",
      value: "wins",
      type: "net",
    },
    {
      label: "Top 3s",
      value: "topThreeFinishes",
      type: "net",
    },
    {
      label: "Top 10s",
      value: "topTenFinishes",
      type: "net",
    },
    {
      label: "Top 20s",
      value: "topTwentyFinishes",
      type: "net",
    },
  ];

  const scoreOptions = [
    {
      label: "Eagles (Net)",
      value: "averageEagles",
      type: "net",
    },
    {
      label: "Eagles (Gross)",
      value: "averageEagles",
      type: "gross",
    },
    {
      label: "Birdies (Net)",
      value: "averageBirdies",
      type: "net",
    },
    {
      label: "Birdies (Gross)",
      value: "averageBirdies",
      type: "gross",
    },
    {
      label: "Pars (Net)",
      value: "averagePars",
      type: "net",
    },
    {
      label: "Pars (Gross)",
      value: "averagePars",
      type: "gross",
    },
    {
      label: "Bogies (Net)",
      value: "averageBogies",
      type: "net",
    },
    {
      label: "Bogies (Gross)",
      value: "averageBogies",
      type: "gross",
    },
    {
      label: "Doubles (Net)",
      value: "averageDoubles",
      type: "net",
    },
    {
      label: "Doubles (Gross)",
      value: "averageDoubles",
      type: "gross",
    },
  ];
  const holeOptions = [
    {
      label: "Par 3s (Net)",
      value: "averageParThrees",
      type: "net",
    },
    {
      label: "Par 3s (Gross)",
      value: "averageParThrees",
      type: "gross",
    },
    {
      label: "Par 4s (Net)",
      value: "averageParFours",
      type: "net",
    },
    {
      label: "Par 4s (Gross)",
      value: "averageParFours",
      type: "gross",
    },
    {
      label: "Par 5s (Net)",
      value: "averageParFives",
      type: "net",
    },
    {
      label: "Par 5s (Gross)",
      value: "averageParFives",
      type: "gross",
    },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
  ];

  const statsProp = {
    rounds: 0,
    averageNet: 0,
    averageGross: 0,
    averagePutts: 0,
    fewestPutts: 0,
    lowestGross: 0,
    lowestNet: 0,
    highestGross: 0,
    highestNet: 0,
    frontNineGrossAverage: 0,
    frontNineNetAverage: 0,
    backNineGrossAverage: 0,
    backNineNetAverage: 0,
    frontNineLowestGross: 0,
    backNineLowestGross: 0,
    frontNineLowestNet: 0,
    backNineLowestNet: 0,
    frontNineHighestGross: 0,
    backNineHighestGross: 0,
    frontNineHighestNet: 0,
    backNineHighestNet: 0,
    girAve: 0,
    netGirAve: 0,
    firAve: 0,
    totalThreePutts: 0,
  };

  const grossStatsProp = {
    averageBirdies: 0,
    averageBogies: 0,
    averageDoubles: 0,
    averageEagles: 0,
    averageOthers: 0,
    averageParFives: 0,
    averageParFours: 0,
    averageParThrees: 0,
    averagePars: 0,
    totalRounds: 0,
    totalHoles: 0,
    totalBirdies: 0,
    totalBogies: 0,
    totalDoubles: 0,
    totalEagles: 0,
    totalOthers: 0,
    totalPars: 0,
  };

  const playerStatsProp = {
    majorWins: 0,
    averageBirdies: 0,
    averageBogies: 0,
    averageDoubles: 0,
    averageEagles: 0,
    averageFIR: 0,
    averageGIR: 0,
    averageGross: 0,
    averageNet: 0,
    averageParFives: 0,
    averageParFours: 0,
    averageParThrees: 0,
    averagePars: 0,
    averagePutts: 0,
    totalRounds: 0,
    totalHoles: 0,
    totalBirdies: 0,
    totalBogies: 0,
    totalDoubles: 0,
    totalEagles: 0,
    totalFIR: 0,
    totalGIR: 0,
    totalParFives: 0,
    totalParFours: 0,
    totalParThrees: 0,
    totalPars: 0,
    totalPutts: 0,
    netGIRPutts: 0,
    grossGIRPutts: 0,
    parThreeNetGIRs: 0,
    parFourNetGIRs: 0,
    parFiveNetGIRs: 0,
    parThreeGrossGIRs: 0,
    parFourGrossGIRs: 0,
    parFiveGrossGIRs: 0,
    wins: 0,
    topTwentyFinishes: 0,
    topTenFinishes: 0,
    topThreeFinishes: 0,
    lowestGrossNineHoleScore: 0,
    lowestNetNineHoleScore: 0,
    totalGrossScrambleAttempts: 0,
    totalNetScrambleAttempts: 0,
    totalGrossScrambles: 0,
    totalNetScrambles: 0,
    holeOuts: 0,
    onePutts: 0,
    threePutts: 0,
    grossBounceBackBirdies: 0,
    netBounceBackBirdies: 0,
    percentagePointsWon: 0,
    grossBirdieStreak: 0,
    netBirdieStreak: 0,
    highestGirStreak: 0,
    highestFirStreak: 0,
    highestNetGirStreak: 0,
    currentNoThreePuttStreak: 0,
    currentOnePuttStreak: 0,
    highestNoThreePuttStreak: 0,
    highestOnePuttStreak: 0,
    currentGrossSubParRoundsStreak: 0,
    currentNetSubParRoundsStreak: 0,
    highestGrossSubParRoundsStreak: 0,
    highestNetSubParRoundsStreak: 0,
    grossSubParHolesStreak: 0,
    netSubParHolesStreak: 0,
    averagePuttLength: 0,
    totalPuttLength: 0,
    netBirdieAttempts: 0,
    grossBirdieAttempts: 0,
  };

  // State
  const [selected, setSelected] = useState("averageNet");
  const [selectedType, setSelectedType] = useState("net");
  const [selectedTitle, setSelectedTitle] = useState("General");
  const [selectedOptions, setSelectedOptions] = useState(generalOptions);
  const [dir, setDir] = useState("down");
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(0);

  const [csv, setCSV] = useState([]);

  const [ogStats, setOGStats] = useState([]);
  const [ogCSV, setOGCSV] = useState([]);
  const [seasonMap, setSeasonMap] = useState({});
  const [seasonArr, setSeasonArr] = useState([]);
  const [selSeason, setSelSeason] = useState();
  // UE
  useEffect(() => {
    loadStats();
  }, []);

  // Functions
  async function loadStats() {
    const statsDocs = await db
      .collection("leagues")
      .doc(leagueID)
      .collection("statistics")
      .get();

    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { pointsTable, seasonIDs } = league;

    const seasArr = [
      {
        label: "All",
        value: "overall",
      },
    ];

    for (let i = 0; i < seasonIDs.length; i++) {
      const seasonID = seasonIDs[i];

      seasArr.push({
        value: seasonID,
        label: `Season ${i + 1}`,
      });
    }
    // console.log(s)
    setSeasonArr(seasArr);

    const playerMap = {};
    const statsArr = [];

    const seasonMap = {};

    for (let i = 0; i < pointsTable.length; i++) {
      const row = pointsTable[i];
      const { playerID } = row;
      playerMap[playerID] = row;
    }

    console.log(playerMap);

    const csvArr = [];

    const shRow = Object.keys(playerStatsProp).length;

    const headerRow = Object.keys(playerStatsProp);
    headerRow.unshift("Player");
    csvArr.push(headerRow);

    const grossStatsHeaderRow = Object.keys(grossStatsProp);
    for (let i = 0; i < grossStatsHeaderRow.length; i++) {
      headerRow.push(`gross${grossStatsHeaderRow[i]}`);
    }

    for (let i = 0; i < seasonIDs.length; i++) {
      const seasonID = seasonIDs[i];
      seasonMap[seasonID] = {
        stats: [],
        csv: [headerRow],
      };
    }
    for (let i = 0; i < statsDocs.docs.length; i++) {
      const statsDoc = statsDocs.docs[i];
      const playerID = statsDoc.id;
      const statsData = statsDoc.data();

      if (playerMap[playerID] === undefined) {
        continue;
      }

      //   const { stats, grossStats } = statsData;
      const stats = statsData.stats ?? playerStatsProp;
      // console.log(playerID, playerMap[playerID]);

      const grossStats = statsData.grossStats ?? grossStatsProp;
      statsArr.push({
        netStats: stats,
        grossStats,
        player: playerMap[playerID],
        playerID,
      });

      const statArr = [];
      for (let j = 0; j < csvArr[0].length; j++) {
        let headerVal = csvArr[0][j];

        if (j === 0) {
          statArr.push(playerMap[playerID].userName);
          continue;
        }

        if (j <= shRow + 1) {
          const val = stats[headerVal] ?? 0;
          if (!isNaN(val)) {
            statArr.push(val);
          } else {
            statArr.push(0);
          }

          if (i === 0) {
            // console.log(headerVal, stats[headerVal]);
          }
        } else if (
          j > shRow + 1 &&
          j < shRow + grossStatsHeaderRow.length + 1
        ) {
          const sliced = headerVal.slice(5);

          const val = grossStats[sliced] ?? "";
          if (!isNaN(val)) {
            statArr.push(val);
          } else {
            statArr.push(0);
          }
        }
      }

      // const statArr = Object.values(stats);
      // statArr.unshift(playerMap[playerID.userName]);
      csvArr.push(statArr);

      for (let x = 0; x < seasonIDs.length; x++) {
        const seasonID = seasonIDs[x];

        const stats = statsData[`${seasonID}stats`] ?? playerStatsProp;
        const grossStats = statsData[`${seasonID}GrossStats`] ?? grossStatsProp;

        seasonMap[seasonID].stats.push({
          netStats: stats,
          grossStats,
          player: playerMap[playerID],
          playerID,
        });

        const statArr = [];
        for (let j = 0; j < csvArr[0].length; j++) {
          let headerVal = csvArr[0][j];

          if (j === 0) {
            statArr.push(playerMap[playerID].userName);
            continue;
          }

          if (j <= shRow + 1) {
            const val = stats[headerVal] ?? 0;
            if (!isNaN(val)) {
              statArr.push(val);
            } else {
              statArr.push(0);
            }

            if (i === 0) {
              // console.log(headerVal, stats[headerVal]);
            }
          } else if (
            j > shRow + 1 &&
            j < shRow + grossStatsHeaderRow.length + 1
          ) {
            const sliced = headerVal.slice(5);

            const val = grossStats[sliced] ?? "";
            if (!isNaN(val)) {
              statArr.push(val);
            } else {
              statArr.push(0);
            }
          }
        }

        seasonMap[seasonID].csv.push(statArr);
        // csvArr.push(statArr);
      }
    }
    seasonMap["overall"] = {
      stats: statsArr,
      csv: csvArr,
    };
    // console.log(seasonMap);
    setSeasonMap(seasonMap);
    setOGCSV(csvArr);
    setOGStats(statsArr);
    setCSV(csvArr);
    setSelSeason("overall");
    setStats(statsArr);
    setLoading(false);
  }

  function sortStats(item, type) {
    console.log(dir);
    setStats((current) => {
      if (type === "net") {
        if (dir === "down") {
          current.sort((a, b) =>
            a.netStats[item] > b.netStats[item] || a.netStats[item] === 0
              ? 1
              : -1
          );
        } else {
          current.sort((a, b) =>
            a.netStats[item] < b.netStats[item] ? 1 : -1
          );
        }
      } else {
        if (dir === "up") {
          current.sort((a, b) =>
            a.grossStats[item] > b.grossStats[item] || a.netStats[item] === 0
              ? 1
              : -1
          );
        } else {
          current.sort((a, b) =>
            a.grossStats[item] < b.grossStats[item] ? 1 : -1
          );
        }
      }
      return current;
    });
  }

  return (
    <div className="league-stats">
      <div className="sub-header">
        <h3>Stats</h3>
        <CSVLink className="default-button" data={csv}>
          Export
        </CSVLink>
      </div>
      <div className="ec-box pl-0">
        <div className="input-group pl-20 mr-20">
          <p>Select</p>
          <Dropdown
            options={droptions}
            value={selectedTitle}
            onChange={(e) => {
              setSelectedTitle(e.label);
              if (e.value === "General") {
                setSelectedOptions(generalOptions);
              }
              if (e.value === "Scoring") {
                setSelectedOptions(scoreOptions);
              }
              if (e.value === "Holes") {
                setSelectedOptions(holeOptions);
              }
            }}
          />
        </div>
        <div className="input-group pl-20">
          <p>Select Season</p>
          <Dropdown
            options={seasonArr}
            value={selSeason}
            onChange={(e) => {
              console.log(seasonMap[e.value]);
              const se = seasonMap[e.value];
              const { stats, csv } = se;
              setCSV(csv);
              setStats(stats);
              setSelSeason(e.value);
              setUpdate((c) => c + 1);
            }}
          />
        </div>
      </div>

      <div className="stats-container">
        <div className="stats-box">
          {loading ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : (
            <>
              <StatsHeader
                selected={selected}
                title={selectedTitle}
                dir={dir}
                selectedType={selectedType}
                handleClick={(e) => {
                  setSelectedType(e.type);
                  setSelected((current) => {
                    if (current === e.value) {
                      setDir((current) => {
                        if (current === "up") {
                          return "down";
                        } else {
                          return "up";
                        }
                      });
                    }
                    return e.value;
                  });
                  sortStats(e.value, e.type);
                }}
                options={selectedOptions}
              />
              {stats.map((player, index) => (
                <StatsItem
                  key={player.playerID}
                  player={player}
                  items={selectedOptions}
                  rank={index + 1}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeagueStats;
