import React, { useContext, useEffect, useState } from "react";
import { auth, database, admin } from "../firebase";
import { db } from "../firebase";

const AuthContext = React.createContext();

const featuresProp = {
  events: true,
  leagues: true,
  proStats: false,
  playerUploads: true,
  hcpUploads: true,
  teamLeagues: true,
  matchplayTournaments: true,
  cups: true,
  contests: true,
};

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [currentUID, setCurrentUID] = useState("");
  const [userSettings, setUserSettings] = useState("");
  const [userDetails, setUserDetails] = useState();
  const [onboarding, setOnboarding] = useState();
  const [clubFeatures, setClubFeatures] = useState(featuresProp);
  const [sdOpts, setSDOPts] = useState([
    "Add Event",
    "Add Team Event",
    "Add League",
    "Add Team League",
    "Add User",
    "View Calendar",
  ]);
  const [portalSettings, setPortalSettings] = useState({
    joinedLeague: true,
    leftLeague: true,
    joinedEvent: true,
    leftEvent: true,
    homeEvents: true,
    homeNotifications: true,
    pastEvents: true,
    leagueTables: true,
  });

  // Sign Up
  async function signUp({ emailAddress, password }) {
    await auth.createUserWithEmailAndPassword(emailAddress, password);
  }

  // Log In
  function logIn(email, password) {
    return auth
      .signInWithEmailAndPassword(email, password)
      .catch((e) => console.log(e));
  }

  // Log Out
  function logOut() {
    // console.log("logging out");
    return auth.signOut();
  }

  // Onboarding
  async function setOnboard(item) {
    if (!currentUser) {
      return;
    }
    // console.log(item);
    const current = onboarding;
    current[item] = true;
    await db.collection("users").doc(currentUser.uid).update({
      onboarding: current,
    });
    setOnboarding(current);
  }

  // User Online
  async function isOnline(uid) {
    const userStatusDatabaseRef = database.ref("/status/" + uid);
    const isOfflineForDatabase = {
      state: "offline",
      last_changed: admin.database.ServerValue.TIMESTAMP,
    };

    const isOnlineForDatabase = {
      state: "online",
      last_changed: admin.database.ServerValue.TIMESTAMP,
    };

    database.ref(".info/connected").on("value", function (snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        return;
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
  }

  // User change
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        // console.log(user.email);
        // loadUserName(user.uid);
        setCurrentUID(user.uid);
        isOnline(user.uid);
        loadUserSettings(user.uid);
      } else {
      }
    });

    return unsubscribe;
  }, []);

  async function loadUserSettings(uid) {
    db.collection("users")
      .doc(uid)
      .onSnapshot(async (userProfile) => {
        // console.log("back here");
        if (!userProfile.exists) {
          return;
        }
        const user = userProfile.data();
        const settings = user.settings ?? "";
        const {
          firstName,
          lastName,
          handicap,
          profileImage,
          friends,
          city,
          country,
          state,
          onboarding,
          sub,
          status,
          emailAddress,
        } = user;
        const pendingPremium = user.pendingPremium ?? false;
        const followedEvents = user.followedEvents ?? [];
        const test = user.test ?? false;
        const admin = user.admin ?? false;
        const portal = user.portal ?? false;
        const teamID = user.teamID ?? "";
        if (user.sdOpts) {
          // alert("hi");
          setSDOPts(user.sdOpts);
        }
        const dob = user.dob
          ? new Date(user.dob.toDate())
          : new Date().setFullYear(1990);
        const blocked = user.blocked ?? [];
        const premium = user.premium ?? false;
        if (user.portalSettings) {
          const settins = user.portalSettings;
          // console.log(settins);
          if (settins.pastEvents === undefined) {
            settins.pastEvents = true;
          }
          if (settins.leagueTables === undefined) {
            settins.leagueTables = true;
          }

          setPortalSettings(settins);
          if (user.portalSettings) {
            if (user.portalSettings.clubID) {
              const clubDoc = await db
                .collection("clubs")
                .doc(user.portalSettings.clubID)
                .get();
              if (clubDoc.exists) {
                const club = clubDoc.data();
                const features = club.features ?? featuresProp;
                setClubFeatures(features);
              }
            }
          }
        }
        // console.log(friends);
        const accountType = user.portalType ?? "free";
        setUserDetails({
          dob,
          followedEvents,
          firstName,
          lastName,
          name: `${firstName} ${lastName}`,
          handicap,
          emailAddress,
          profileImage,
          premium,
          trialUsed: user.trialUsed ?? false,
          friends,
          blocked,
          city,
          country,
          state,
          onboarding,
          portal,
          sub,
          status,
          pendingPremium,
          admin,
          test,
          accountType,
          teamID,
        });
        setOnboarding(onboarding);
        setUserSettings(settings);
      });
  }

  const value = {
    currentUser,
    currentUID,
    userSettings,
    userDetails,
    onboarding,
    sdOpts,
    portalSettings,
    clubFeatures,
    logIn,
    signUp,
    logOut,
    setOnboard,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
