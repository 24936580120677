import React, { useEffect, useState } from "react";
import "./Clubs.css";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Icons

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import Pill from "../../components/Buttons/Pill";
import ClubEditor from "./ClubEditor";
import CourseItem from "../../components/display/CourseItem/CourseItem";
import ClubHome from "./ClubHome";

function Clubs() {
  const { currentUser, userDetails } = useAuth();

  const navigate = useNavigate();

  // State
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [accountType, setAccountType] = useState("");

  useEffect(() => {
    if (currentUser && userDetails) {
      console.log(userDetails);
      setAccountType(userDetails.accountType);
      console.log(userDetails.accountType);
      if (userDetails.accountType !== "admin") {
        if (userDetails.accountType === "club") {
          navigate("/");
        } else if (userDetails.accountType === "scorer") {
          navigate("/events");
        } else {
          navigate("/leagues");
        }
      }
      loadClubs();
    }
  }, [currentUser, userDetails]);

  async function loadClubs() {
    const arr = [];

    const clubs = await db
      .collection("clubs")
      .where("adminIDs", "array-contains", currentUser.uid)
      .get();

    for (let i = 0; i < clubs.docs.length; i++) {
      const clubDoc = clubs.docs[i];
      const club = clubDoc.data();
      const clubID = clubDoc.id;
      club.clubID = clubID;
      arr.push(club);
    }
    setClubs(arr);
    setLoading(false);
  }

  return (
    <div className="clubs">
      {show ? (
        toShow
      ) : (
        <>
          <div className="page-header">
            <h2>My Clubs</h2>
          </div>
          {["free", "monthly"].includes(accountType) && (
            <div className="ph-msg">
              <p>
                Due to your account type, only clubs with{" "}
                {accountType === "free" ? "12" : 50} members or less will appear
                here
              </p>
            </div>
          )}
          <div className="sub-header">
            <h3>Quick Links</h3>
          </div>
          <div className="quick-links">
            <Pill
              onClick={() => {
                setToShow(
                  <ClubEditor
                    reload={loadClubs}
                    edit={false}
                    goBack={() => setShow(false)}
                  />
                );
                setShow(true);
              }}
              text={"New Club"}
            />
          </div>

          <div className="clubs-clubs">
            {clubs.map((club, index) => (
              <CourseItem
                key={club.clubID}
                city={club.city}
                country={club.country}
                image={club.imageLink}
                courseName={club.clubName}
                hideStar
                showCourse={() => {
                  setToShow(
                    <ClubHome
                      clubID={club.clubID}
                      goBack={() => setShow(false)}
                    />
                  );
                  setShow(true);
                }}
                members={club.memberIDs.length}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Clubs;
