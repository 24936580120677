import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
function CustomDrop({ onChange = () => {}, options = [], value }) {
  return (
    <Dropdown
      arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
      onChange={(e) => {
        onChange(e);
      }}
      options={options}
      value={value}
    />
  );
}

export default CustomDrop;
