/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./PlayerSetup.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { Dialog } from "@mui/material";

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      onClick={onClick}
      className=""
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function PlayerSetup({
  guest = false,
  changeName = () => {},
  imageLink,
  name,
  city,
  country,
  playerID,
  tees,
  length,
  goBack,
  active = "",
  setTee,
  customHandicap = false,
  changeHandicap = () => {},
  handicap = "",
  hcpOnly = false,
}) {
  useEffect(() => {
    // console.log(length);
  }, []);

  const [editName, setEditName] = useState(false);

  const [activeTee, setActiveTee] = useState(active);

  const nameRef = useRef();
  const hcpRef = useRef();

  const bgc = {
    black: "black",
    white: "white",
    red: "#E24955",
    blue: "#379FDC",
    yellow: "yellow",
    gold: "#d4af37",
    Red: "#E24955",
  };

  const bc = {
    black: "black",
    white: "#C6C7C8",
    red: "#E24955",
    Red: "#E24955",
    blue: "#379FDC",
    yellow: "yellow",
    gold: "#d4af37",
  };

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  return (
    <Dialog onClose={() => goBack()} open>
      <div className="player-setup scale-in-ps">
        {/* <div
          onClick={() => {
            if (hcpOnly) {
              console.log(hcpRef.current.value);
              const newhcp = parseInt(hcpRef.current.value);
              if (newhcp !== handicap) {
                changeHandicap(newhcp);
              }
              return goBack();
            }

            if (customHandicap && isNumeric(hcpRef.current.value)) {
              changeHandicap(hcpRef.current.value);
            }
            if (guest) {
              // console.log(nameRef.current);
              if (nameRef.current !== undefined) {
                changeName(nameRef.current.value);
              }
              changeHandicap(parseInt(hcpRef.current.value));
            }
            setTee(activeTee);
            goBack();
          }}
          className="back-row"
        >
          <ArrowBack className="ts-arrow" />
          <p>Back</p>
        </div> */}

        <div className="page-header">
          <h2>Player Setup</h2>
          <CloseIcon
            onClick={() => {
              goBack();
            }}
            className="close-icon"
          />
        </div>

        <div className="ps-player">
          <div className="psp-h">
            <h2 className="ne-header mb-20">Player</h2>
          </div>

          <div className="round-player ps-rp">
            <div className="rp-left">
              <Avatar src={imageLink} alt={name} />
            </div>
            <div className="rp-mid">
              <div className="rpm-name">
                {editName ? (
                  <input
                    defaultValue={name}
                    ref={nameRef}
                    autoFocus
                    className="default-input"
                  />
                ) : (
                  <h2>{name}</h2>
                )}
                {guest && (
                  <EditIcon
                    onClick={() => {
                      setEditName(true);
                    }}
                    className="ps-name-icon"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {(customHandicap || guest) && (
          <div className="ps-handicap">
            <div className="psp-h">
              <h2 className="ne-header">Handicap</h2>
            </div>
            <input
              ref={hcpRef}
              defaultValue={handicap}
              className="default-input"
              type={"number"}
            />
            {/* <InputNumber type='number' /> */}
          </div>
        )}

        {!hcpOnly && (
          <div className="ps-tees">
            <div className="psp-h">
              <h2 className="ne-header">Tees</h2>
            </div>
            <div className="ts-items">
              {tees &&
                tees.map((tee) => {
                  return (
                    <div
                      onClick={() => setActiveTee(tee)}
                      className={
                        tee === activeTee ? "tee-item ti-active" : "tee-item"
                      }
                    >
                      <div className="ti-left">
                        <div
                          style={{
                            backgroundColor: bgc[tee],
                            borderColor: bc[tee],
                          }}
                          className="til-circle"
                        ></div>
                      </div>
                      <div className="ti-mid">
                        <h2>{tee}</h2>
                        <p>{length[tee]} meters</p>
                      </div>
                      <div className="ti-right">
                        {tee === activeTee && (
                          <GradientCircleIcon className="tir-check" />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div className="default-button-row">
          <button
            className="default-button"
            onClick={() => {
              if (hcpOnly) {
                console.log(hcpRef.current.value);
                const newhcp = parseInt(hcpRef.current.value);
                if (newhcp !== handicap) {
                  changeHandicap(newhcp);
                }
                return goBack();
              }

              if (customHandicap && isNumeric(hcpRef.current.value)) {
                changeHandicap(hcpRef.current.value);
              }
              if (guest) {
                // console.log(nameRef.current);
                if (nameRef.current !== undefined) {
                  changeName(nameRef.current.value);
                }
                changeHandicap(parseInt(hcpRef.current.value));
              }
              setTee(activeTee);
              goBack();
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default PlayerSetup;
