/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./TeamSelector.css";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import Pill from "../Buttons/Pill";
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";

function TeamSelector({
  players = [],
  teams = [],
  setTeams = () => {},
  goBack = () => {},
  showButton = false,
  showMatches = () => {},
  lockNames = false,
  // reload = () => {},
}) {
  const [selTeams, setSelTeams] = useState([
    {
      players: [],
      captain: "",
      name: "",
    },
    {
      players: [],
      captain: "",
      name: "",
    },
  ]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    console.log(players.length, teams);
    let tot = 0;
    for (let i = 0; i < teams.length; i++) {
      tot += teams[i].players.length;
    }
    if (
      teams.length === 0 ||
      teams[0].players === undefined ||
      tot !== players.length
    ) {
      sortPlayers();
    } else {
      setSelTeams(teams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function sortPlayers() {
    const half = Math.ceil(players.length / 2);
    const team1 = [];
    const team2 = [];
    for (let i = 0; i < players.length; i++) {
      if (i < half) {
        team1.push(players[i]);
      } else {
        team2.push(players[i]);
      }
    }
    setSelTeams([
      {
        players: team1,
        captain: "",
        name: "Team 1",
      },
      {
        players: team2,
        captain: "",
        name: "Team 2",
      },
    ]);
  }

  function shuffle(array) {
    // alert('everyday')
    // console.log(array)
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  function shufflePlayers() {
    const half = Math.ceil(players.length / 2);
    const team1 = [];
    const team2 = [];
    const newPlayers = shuffle(players);
    for (let i = 0; i < newPlayers.length; i++) {
      if (i < half) {
        team1.push(newPlayers[i]);
      } else {
        team2.push(newPlayers[i]);
      }
    }
    setSelTeams([
      {
        players: team1,
        captain: "",
        name: "Team 1",
      },
      {
        players: team2,
        captain: "",
        name: "Team 2",
      },
      setUpdate((current) => current + 1),
    ]);
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  function handleDrop(result) {
    if (result.destination === null) {
      return;
    }

    const start = result.source.index;
    const end = result.destination.index;

    // console.log(result.source.droppableId, result.destination.droppableId)

    if (result.source.droppableId === result.destination.droppableId) {
      setSelTeams((current) => {
        const newArr = current;
        if (result.source.droppableId === "droppable") {
          newArr[0].players = array_move(newArr[0].players, start, end);
        }
        if (result.source.droppableId === "droppable2") {
          newArr[1].players = array_move(newArr[1].players, start, end);
        }
        return newArr;
      });
    } else {
      setSelTeams((current) => {
        const newArr = current;
        if (result.source.droppableId === "droppable") {
          const player = newArr[0].players[start];
          newArr[0].players.splice(start, 1);
          newArr[1].players.splice(end, 0, player);
          // const newLength = newArr[1].players.length;
          if (newArr[1].players.length - newArr[0].players.length === 2) {
            const last = newArr[1].players.pop();
            newArr[0].players.push(last);
          }
        } else {
          const player = newArr[1].players[start];
          newArr[1].players.splice(start, 1);
          newArr[0].players.splice(end, 0, player);
          if (newArr[0].players.length - newArr[1].players.length === 2) {
            const last = newArr[0].players.pop();
            newArr[1].players.push(last);
          }
        }
        return newArr;
      });
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      width: "100%",
      // padding: grid * 2,
      // margin: `0 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? "#edefef" : "white",
      borderRadius: "10px",
      padding: isDragging ? "20px" : "0px",
      // paddingBottom: '20px',
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  return (
    <div className="team-selector">
      <BackRow action={goBack} />

      <PageHeader text="Team Selector" />

      <div className="input-group pl-20">
        {/* <p
          onClick={() => {
            shufflePlayers();
          }}
          className="green"
        >
          Shuffle
        </p> */}
        <Pill
          text={"Shuffle"}
          onClick={() => {
            shufflePlayers();
          }}
        />
      </div>

      <div className="c-s-notice">
        <p>Drag and drop players to change teams</p>
      </div>

      <div className="teams-container">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="teams-column">
                  <div className="tc-name">
                    <h5>Team Name</h5>
                  </div>
                  <div className="team-name-input">
                    <input
                      className="default-input"
                      disabled={lockNames}
                      value={selTeams[0].name}
                      onChange={(e) => {
                        setSelTeams((current) => {
                          const newArr = current;
                          newArr[0].name = e.target.value;
                          return newArr;
                        });
                        setUpdate((current) => current + 1);
                      }}
                    />
                  </div>

                  {selTeams[0].players &&
                    selTeams[0].players.map((player, index) => {
                      return (
                        <Draggable
                          index={index}
                          key={player.playerID}
                          draggableId={player.playerID}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              //   style={{opacity: snapshot.isDragging ? '0.5' : '1' }}
                            >
                              <PlayerItem
                                noLink
                                hideIcons
                                hideHCP
                                name={player.name}
                                hcp={player.handicap}
                                // hideImage
                                playerID={player.playerID}
                                img={player.profileImage}
                                hideRemove
                                showAdd
                                hideEdit
                                captain={index === 0}
                                showProfile={() => {}}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>

          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="teams-column">
                  <div className="tc-name">
                    <h5>Team Name</h5>
                  </div>
                  <div className="team-name-input">
                    <input
                      className="default-input"
                      disabled={lockNames}
                      value={selTeams[1].name}
                      onChange={(e) => {
                        setSelTeams((current) => {
                          const newArr = current;
                          newArr[1].name = e.target.value;
                          return newArr;
                        });
                        setUpdate((current) => current + 1);
                      }}
                    />
                  </div>

                  {selTeams[1].players &&
                    selTeams[1].players.map((player, index) => {
                      return (
                        <Draggable
                          index={index}
                          key={player.playerID}
                          draggableId={player.playerID}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              //   style={{opacity: snapshot.isDragging ? '0.5' : '1' }}
                            >
                              <PlayerItem
                                noLink
                                hideIcons
                                hideHCP
                                name={player.name}
                                hcp={player.handicap}
                                // hideImge
                                playerID={player.playerID}
                                img={player.profileImage}
                                hideRemove
                                showAdd
                                hideEdit
                                captain={index === 0}
                                showProfile={() => {}}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {showButton && (
        <div className="ps-button">
          <button
            onClick={() => {
              setTeams(selTeams);
              showMatches();
            }}
          >
            Setup Matches
          </button>
        </div>
      )}

      <div className="flex-center mb-20">
        <button
          onClick={() => {
            setTeams(selTeams);
            goBack();
          }}
          className="default-button"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default TeamSelector;
