import React, { useState, useEffect } from "react";
import "./RoundGraphs.css";
import { db } from "../../../firebase";
import Chart from "react-apexcharts";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BreakfastDiningOutlined } from "@mui/icons-material";
function RoundGraphs({ roundID, userID }) {
  const [meters, setMeters] = useState(false);
  const [barLabels, setBarLabels] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ]);
  const [enabledStats, setenabledStats] = useState([]);
  const [scoring, setScoring] = useState("gross");

  const [selected, setSelected] = useState("");
  const [update, setUpdate] = useState(0);

  const [type, setType] = useState("");
  const [series, setSeries] = useState([]);

  const [loading, setLoading] = useState(false);

  const yLabels = {
    "Stroke Average": "Strokes",
    "Average Putts": "Putts",
    "Average Putt Length": "Distance",
    "Driving Distance": "Distance",
    Scrambling: "Percentage",
    "Birdie Conversion": "Percentage",
    "Average GIR": "Percentage",
    "Average FIR": "Percentage",
    "Par 3 Averages": "Strokes",
    "Par 4 Averages": "Strokes",
    "Par 5 Averages": "Strokes",
    "Last 12 Months": "Last 12 Mths",
    "Last 20 Rounds": "Scores",
    columns: "To Par",
    putting: "Putts",
    puttHoles: "Putts by hole",
    parAverages: "Average",
    firAverages: "Average",
    girAverages: "Average",
    puttLengths: "Putt Lengths",
    misses: "Missed",
  };

  const [graphs, setGraphs] = useState({
    grossHoles: [],
    netHoles: [],
    netColumns: [],
    grossColumns: [],
  });

  const graphOptions = [
    { value: "holes", label: "Holes" },
    { value: "columns", label: "Hole by Hole" },
    { value: "putting", label: "Putting" },
    { value: "puttHoles", label: "Putts by Hole" },
    { value: "puttLengths", label: "Putts Lengths" },
    { value: "parAverages", label: "Par Averages" },
    { value: "girAverages", label: "GIR Averages" },
    { value: "firAverages", label: "FIR Averages" },
    { value: "parThrees", label: "Par Threes" },
    { value: "parFours", label: "Par Fours" },
    { value: "parFives", label: "Par Fives" },
  ];

  useEffect(() => {
    loadRoundNumbers();
  }, []);

  useEffect(() => {
    // console.log(selected);
  }, [selected]);

  const barProp = {
    series: [
      {
        name: "PRODUCT A",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "PRODUCT B",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "PRODUCT C",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "PRODUCT D",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      colors: ["#6ba390", "#db5461", "#232c2e"],
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        stackType: "100",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: false,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      //   plotOptions: {
      //     bar: {
      //       colors: {
      //         ranges: [
      //           {
      //             from: -100,
      //             to: -46,
      //             color: "#F15B46",
      //           },
      //           {
      //             from: -45,
      //             to: 0,
      //             color: "#FEB019",
      //           },
      //         ],
      //       },
      //       columnWidth: "80%",
      //     },
      //   },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          distributed: selected.includes("Averages") || selected === "misses",
          colors: {
            ranges: selected.includes("Averages")
              ? []
              : selected === "puttHoles"
              ? [
                  {
                    from: 1,
                    to: 2,
                    color: "#6ba390",
                  },
                  {
                    from: 2,
                    to: 3,
                    color: "#000000",
                  },
                  {
                    from: 3,
                    to: 5,
                    color: "#db5461",
                  },
                ]
              : [
                  {
                    from: -2,
                    to: -1,
                    color: "#209E73",
                  },
                  {
                    from: -1,
                    to: 0,
                    color: "#6ba390",
                  },
                  {
                    from: 0,
                    to: 1,
                    color: "#db5461",
                  },
                  {
                    from: 2,
                    to: 5,
                    color: "#A9363F",
                  },
                ],
          },
        },
      },
      xaxis: {
        categories: selected.includes("Averages")
          ? ["Par 3s", "Par 4s", "Par 5s"]
          : selected === "misses"
          ? ["Left", "FIR", "Right"]
          : selected === "puttLengths"
          ? ["< 5", "5-10", "11-20", "21-40", "> 40"]
          : barLabels,
        labels: {
          rotate: -90,
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            // console.log(value);
            return value;
            // return value.toFixed(0);
          },
        },
        // min: -2,
        ...(scoring === "net" && selected === "columns" && { max: 2 }),
        title: {
          text: yLabels[selected] ?? selected,
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const pieProp = {
    options: {
      colors: [
        "#FFD700",
        "#6ba390",
        "#db5461",
        "#232c2e",
        "#1e7a69",
        "#A9363F",
      ],
      dataLabels: {
        enabled: true,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      chart: {
        width: 400,
        type: "pie",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      legend: {
        position: "bottom",
      },
      dataLabels: {
        formatter: function (val, opts) {
          if (selected.includes("putt")) {
            return Math.round((val / 100) * 18);
          } else {
            return opts.w.config.series[opts.seriesIndex];
          }
        },
      },
      labels:
        selected === "holes" ||
        ["parThrees", "parFours", "parFives"].includes(selected)
          ? ["Eagles", "Birdies", "Pars", "Bogies", "Doubles", "Others"]
          : selected.includes("Averages")
          ? ["Par 3s", "Par 4s", "Par 5s"]
          : ["1 Putts", "2 Putts", "3 Putts"],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };

  async function loadRoundNumbers() {
    const scoreDoc = await db
      .collection("rounds")
      .doc(roundID)
      .collection("scores")
      .doc(userID)
      .get();
    const scoreData = scoreDoc.data();
    const { stats, grossStats, holes } = scoreData;
    // console.log(stats, grossStats);
    // Net Holes
    const netHoles = [
      stats.totalEagles,
      stats.totalBirdies,
      stats.totalPars,
      stats.totalBogies,
      stats.totalDoubles,
      stats.totalOthers ?? 0,
    ];

    // Gross Holes
    const grossHoles = [
      grossStats.totalEagles,
      grossStats.totalBirdies,
      grossStats.totalPars,
      grossStats.totalBogies,
      grossStats.totalDoubles,
      grossStats.totalOthers,
    ];

    console.log(grossHoles);

    // Par 3, 4 and 5 scores
    const netParThrees = [0, 0, 0, 0, 0, 0];
    const netParFours = [0, 0, 0, 0, 0, 0];
    const netParFives = [0, 0, 0, 0, 0, 0];
    const grossParThrees = [0, 0, 0, 0, 0, 0];
    const grossParFours = [0, 0, 0, 0, 0, 0];
    const grossParFives = [0, 0, 0, 0, 0, 0];

    const puttLengths = [0, 0, 0, 0, 0];

    for (let i = 0; i < holes.length; i++) {
      const hole = holes[i];
      const { par, toPar, strokes } = hole;
      const puttLength = meters
        ? hole.puttLength
        : Math.round(hole.puttLength / 1.048);

      if (puttLength > 0 && puttLength < 5) {
        puttLengths[0] += 1;
      }
      if (puttLength > 4 && puttLength < 10) {
        puttLengths[1] += 1;
      }
      if (puttLength > 9 && puttLength < 20) {
        puttLengths[2] += 1;
      }
      if (puttLength > 19 && puttLength < 40) {
        puttLengths[3] += 1;
      }
      if (puttLength > 39) {
        puttLengths[4] += 1;
      }

      if (par === 3) {
        netParThrees[toPar + 2] += 1;
        if (toPar + 2 + strokes > 5) {
          grossParThrees[5] += 1;
        } else {
          grossParThrees[toPar + 2 + strokes] += 1;
        }
      }
      if (par === 4) {
        netParFours[toPar + 2] += 1;
        if (toPar + 2 + strokes > 5) {
          grossParFours[5] += 1;
        } else {
          grossParFours[toPar + 2 + strokes] += 1;
        }
      }
      if (par === 5) {
        netParFives[toPar + 2] += 1;
        if (toPar + 2 + strokes > 5) {
          grossParFives[5] += 1;
        } else {
          grossParFives[toPar + 2 + strokes] += 1;
        }
      }
    }

    // console.log(puttLengths);

    // Net Columns
    const netColumns = holes.map((hole) => hole.toPar);
    // Gross Columns
    const grossColumns = holes.map((hole) => hole.toPar + hole.strokes);

    const twoPutts = 18 - stats.onePutts - stats.threePutts;
    // Putts
    const putts = [stats.onePutts, twoPutts, stats.threePutts];
    const puttHoles = holes.map((hole) => hole.putts);
    // Par Average
    const netParAverages = [
      stats.averageParThrees,
      Math.round(stats.averageParFours * 100) / 100,
      stats.averageParFives,
    ];
    const grossParAverages = [
      grossStats.averageParThrees,
      grossStats.averageParFours,
      grossStats.averageParFives,
    ];
    const netFIRAverages = [
      stats.parThreeNetFIRScoringAverage ?? 3,
      stats.parFourNetFIRScoringAverage,
      stats.parFiveNetFIRScoringAverage,
    ];
    const grossFIRAverages = [
      stats.parThreeGrossFIRScoringAverage ?? 3,
      stats.parFourGrossFIRScoringAverage,
      stats.parFiveGrossFIRScoringAverage,
    ];
    const netGIRAverages = [
      stats.parThreeNetGIRScoringAverage,
      stats.parFourNetGIRScoringAverage,
      stats.parFiveNetGIRScoringAverage,
    ];
    const grossGIRAverages = [
      stats.parThreeGrossGIRScoringAverage,
      stats.parFourGrossGIRScoringAverage,
      stats.parFiveGrossGIRScoringAverage,
    ];
    const lmp = Math.round(
      (stats.leftFIRMiss /
        (stats.totalParFivesPlayed + stats.totalParFoursPlayed)) *
        100
    );
    const rmp = Math.round(
      (stats.rightFIRMiss /
        (stats.totalParFivesPlayed + stats.totalParFoursPlayed)) *
        100
    );
    const fmp = Math.round(
      (stats.totalFIR /
        (stats.totalParFivesPlayed + stats.totalParFoursPlayed)) *
        100
    );
    const missAverages = [lmp, fmp, rmp];
    setGraphs({
      netParThrees,
      netParFours,
      netParFives,
      grossParThrees,
      grossParFours,
      grossParFives,
      misses: [
        {
          name: "Driving Accuracy",
          data: missAverages,
        },
      ],
      puttLengths: [
        {
          name: "Putt Lengths",
          data: puttLengths,
        },
      ],
      netParAverages: [
        {
          name: "Net Averages",
          data: netParAverages,
        },
      ],
      grossParAverages: [
        {
          name: "Gross Averages",
          data: grossParAverages,
        },
      ],
      netFIRAverages: [
        {
          name: "FIR Averages",
          data: netFIRAverages,
        },
      ],
      grossFIRAverages: [
        {
          name: "FIR Averages",
          data: grossFIRAverages,
        },
      ],
      netGIRAverages: [
        {
          name: "GIR Averages",
          data: netGIRAverages,
        },
      ],
      grossGIRAverages: [
        {
          name: "GIR Averages",
          data: grossGIRAverages,
        },
      ],
      netHoles,
      grossHoles,
      putts,
      puttHoles: [
        {
          name: "Putts by Hole",
          data: puttHoles,
        },
      ],
      netColumns: [
        {
          name: "Net",
          data: netColumns,
        },
      ],
      grossColumns: [
        {
          name: "Gross",
          data: grossColumns,
        },
      ],
    });
    setType("pie");
    setSelected("holes");
    setSeries(netHoles);
    setLoading(false);
  }

  function handleChange({ type = "", value = "", scoreType = "net" }) {
    console.log(type, value, scoreType);
    if (type === "graph" || type === "bar") {
      console.log("peepee");
      if (value === "puttLengths") {
        setType("bar");
        setSeries(graphs.puttLengths);
      }

      if (value === "misses") {
        setType("bar");
        setSeries(graphs.misses);
      }

      if (value === "parThrees") {
        setType("pie");
        if (scoreType === "net") {
          setSeries(graphs.netParThrees);
        } else {
          setSeries(graphs.grossParThrees);
        }
      }
      if (value === "parFours") {
        setType("pie");
        if (scoreType === "net") {
          setSeries(graphs.netParFours);
        } else {
          setSeries(graphs.grossParFours);
        }
      }
      if (value === "parFives") {
        setType("pie");
        if (scoreType === "net") {
          setSeries(graphs.netParFives);
        } else {
          setSeries(graphs.grossParFives);
        }
      }

      if (value === "putting") {
        setType("pie");
        setSeries(graphs.putts);
      }

      if (value === "puttHoles") {
        setType("bar");
        setSeries(graphs.puttHoles);
      }

      if (value === "parAverages") {
        setType("bar");
        if (scoreType === "net") {
          setSeries(graphs.netParAverages);
        } else {
          setSeries(graphs.grossParAverages);
        }
      }

      if (value === "firAverages") {
        // console.log(graphs.netFIRAverages, graphs.netGIRAverages);
        setType("bar");
        if (scoreType === "net") {
          setSeries(graphs.netFIRAverages);
        } else {
          setSeries(graphs.grossFIRAverages);
        }
      }

      if (value === "girAverages") {
        setType("bar");
        if (scoreType === "net") {
          setSeries(graphs.netGIRAverages);
        } else {
          setSeries(graphs.grossGIRAverages);
        }
      }

      if (value === "holes") {
        setType("pie");

        if (scoreType === "net") {
          setSeries(graphs.netHoles);
        } else {
          setSeries(graphs.grossHoles);
        }
      }

      if (value === "columns") {
        setType("bar");
        if (scoreType === "net") {
          setSeries(graphs.netColumns);
        }
        if (scoreType === "gross") {
          setSeries(graphs.grossColumns);
        }
      }
    }

    if (type === "scoring" || type === "pie") {
      if (selected === "parThrees") {
        setType("pie");
        if (scoreType === "net") {
          setSeries(graphs.netParThrees);
        } else {
          setSeries(graphs.grossParThrees);
        }
      }
      if (selected === "parFours") {
        setType("pie");
        if (scoreType === "net") {
          setSeries(graphs.netParFours);
        } else {
          setSeries(graphs.grossParFours);
        }
      }
      if (selected === "parFives") {
        setType("pie");
        if (scoreType === "net") {
          setSeries(graphs.netParFives);
        } else {
          setSeries(graphs.grossParFives);
        }
      }
      if (selected === "holes") {
        if (scoreType === "net") {
          setSeries(graphs.netHoles);
        } else {
          setSeries(graphs.grossHoles);
        }
      }
      if (selected === "columns") {
        if (scoreType === "net") {
          setSeries(graphs.netColumns);
        } else {
          setSeries(graphs.grossColumns);
        }
      }
      if (selected === "parAverages") {
        if (scoreType === "net") {
          setSeries(graphs.netParAverages);
        } else {
          setSeries(graphs.grossParAverages);
        }
      }
      if (selected === "firAverages") {
        if (scoreType === "net") {
          setSeries(graphs.netFIRAverages);
        } else {
          setSeries(graphs.grossFIRAverages);
        }
      }
      if (selected === "girAverages") {
        if (scoreType === "net") {
          setSeries(graphs.netGIRAverages);
        } else {
          setSeries(graphs.grossGIRAverages);
        }
      }
    }
  }

  return (
    <div className="round-graphs bs-subtle">
      <div className="perf-header">
        <div
          style={{
            opacity:
              ["putting", "puttHoles"].includes(selected) && graphs ? "0" : "1",
          }}
          className="switch-row"
        >
          <div className="selector-group sg-small">
            <button
              onClick={() => {
                setScoring("gross");
                handleChange({ type, value: selected, scoreType: "gross" });
              }}
              className={scoring === "gross" ? "sg-left sg-active" : "sg-left"}
            >
              Gross
            </button>
            <button
              onClick={() => {
                setScoring("net");
                handleChange({ type, value: selected, scoreType: "net" });
              }}
              className={scoring === "net" ? "sg-right sg-active" : "sg-right"}
            >
              Net
            </button>
          </div>
        </div>
      </div>
      <div className="stats-selector-row drop mt-20">
        <Dropdown
          arrowClosed={<KeyboardArrowDownIcon className="drop-ico" />}
          arrowOpen={<KeyboardArrowUpIcon className="drop-ico" />}
          value={selected}
          className="def-drop"
          options={graphOptions}
          onChange={(e) => {
            setSelected(e.value);
            handleChange({
              type: "graph",
              value: e.value,
              scoreType: scoring,
            });
            setUpdate((current) => current + 1);
          }}
        />
      </div>
      <div className="r-g-box">
        {type === "bar" && (
          <Chart
            options={barProp.options}
            series={series}
            type={"bar"}
            height={320}
          />
        )}
        {type === "pie" && (
          <Chart
            options={type === "pie" ? pieProp.options : {}}
            series={series}
            type={"pie"}
            height={400}
          />
        )}
      </div>
    </div>
  );
}

export default RoundGraphs;
