import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function SearchInput({ val = "", setVal = () => {}, keyAction = () => {} }) {
  const [sel, setSel] = useState(false);

  return (
    <div
      className={
        sel ? "search-input shadow-md si-act" : "search-input shadow-md"
      }
    >
      {/* <SearchIcon className="si-ico" /> */}
      <input
        className="default-input"
        value={val}
        onFocus={() => setSel(true)}
        onBlur={() => setSel(false)}
        onChange={(e) => setVal(e.target.value)}
        placeholder="Search"
        onKeyDown={(e) => {
          if (e.key === "Enter" && val.length > 2) {
            keyAction();
          }
        }}
      />
      <div className="sdi-box" onClick={() => setVal("")}>
        <HighlightOffIcon className="red-icon-bg icon-cursor" />
      </div>
    </div>
  );
}

export default SearchInput;
