import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "react-dropdown/style.css";
import Dropdown from "react-dropdown";
import classNames from "classnames";

function CustomDropdown({
  value,
  handleChange = () => {},
  opts = [],
  error = false,
  placeHolder = "Select...",
}) {
  return (
    <React.Fragment>
      <Dropdown
        placeholder={placeHolder}
        options={opts}
        className={classNames("def-drop", {
          "ip-drop-error": error,
        })}
        arrowClosed={<KeyboardArrowDownIcon className="drop-ico" />}
        arrowOpen={<KeyboardArrowUpIcon className="drop-ico" />}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </React.Fragment>
  );
}

export default CustomDropdown;
