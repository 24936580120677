import React from "react";
import "./LogItem.css";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { Badge } from "@mui/material";
import DeblurIcon from "@mui/icons-material/Deblur";

import { useAuth } from "../../../../contexts/AuthContext";

const GradientAddIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <DeblurIcon
      className="ps-icon dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
function LogItem({
  img,
  name,
  hcp,
  played,
  points,
  wins,
  rank,
  playerID,
  round = true,
  header = false,
  ave = 0,
  handleClick = () => {},
  goBack,
  upDown = "",
  ties = "",
  final = false,
  fr = "",
  noLink = false,
}) {
  const { currentUser } = useAuth();

  return (
    <div
      style={{
        backgroundColor:
          currentUser && currentUser.uid === playerID
            ? "#E9F8F2"
            : "transparent",
      }}
      className="player-score log-item"
    >
      <div className="ps-left">
        {/* <Badge color="default" badgeContent={rank}>
          <h5 style={{ opacity: 0 }}>#$</h5>
        </Badge> */}
        <p className="rk-pee">{rank}</p>
      </div>
      <div className="ps-right">
        <div className="ps-right-left">
          <h3
            onClick={() =>
              !noLink &&
              window.open(`https://www.theugatour.com/players/${playerID}`)
            }
          >
            {name}
          </h3>
          {false && (
            <div className="hcp-container">
              <div className="svg-cont">
                <GradientAddIcon
                  className="ps-icon"
                  style={{ color: "21C17C", height: "16px" }}
                />
              </div>

              <p>{hcp < 0 ? `+${hcp * -1}` : hcp} hcp</p>
            </div>
          )}
        </div>
        <div className="ps-right-middle">
          <h3>{played}</h3>
          {/* <p>Played</p> */}
        </div>
        <div className="ps-right-right">
          <h3>{round ? Math.round(points * 10) / 10 : points}</h3>
          {/* <p>Points</p> */}
        </div>
        <div className="ps-right-right">
          <h3>{wins}</h3>
          {/* <p>Points</p> */}
        </div>
        {ties !== "" && (
          <div className="ps-right-right">
            <h3>{ties}</h3>
          </div>
        )}
        <div className="ps-right-right">
          <h3>{final ? fr : ave}</h3>
          {/* <p>Points</p> */}
        </div>
        {upDown !== "" && (
          <div className="ps-right-right">
            {upDown === "up" ? (
              <NorthIcon className="move-icon mi-up" />
            ) : upDown === "down" ? (
              <SouthIcon className="move-icon mi-down" />
            ) : (
              <p>-</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default LogItem;
