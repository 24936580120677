import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../firebase";
import moment from "moment";
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import { useDropzone } from "react-dropzone";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

function MatchdayCreator({
  clubID = "",
  goBack = () => {},
  edit = false,
  matchdayID = "",
  reload = () => {},
  disableChanges = false,
}) {
  const formats = [
    { value: "net-strokeplay", label: "Net Strokeplay" },
    {
      value: "stableford",
      label: "Stableford",
    },
    { value: "medal", label: "Strokeplay/Medal" },

    {
      value: "mod-stableford",
      label: "Modified Stableford",
    },
  ];

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [name, setName] = useState("");
  const [date, setDate] = useState();
  const [format, setFormat] = useState("net-strokeplay");
  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const now = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (edit) {
      loadDetails();
    } else {
      setLoading(false);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  async function loadDetails() {
    const matchdayDoc = await db.collection("matchdays").doc(matchdayID).get();
    const matchday = matchdayDoc.data();
    const { name, matchDate, format } = matchday;
    const dateObj = new Date(matchDate.toDate());
    const dateString = moment(dateObj).toISOString(true).split(".")[0];
    console.log(dateString);
    setFormat(format);
    setDate(dateString);
    setName(name);
    setLoading(false);
  }

  async function saveMatchday() {
    setLoading(true);
    try {
      const matchDate = new Date(date);

      await db.collection("matchdays").add({
        dateCreated: new Date(),
        clubID,
        eventIDs: [],
        activeEventIDs: [],
        roundIDs: [],
        leaderboard: [],
        matchDate,
        name,
        format,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError("Could not save changes, please try again");
    }
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      const matchDate = new Date(date);

      await db.collection("matchdays").doc(matchdayID).update({
        matchDate,
        name,
        format,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError("Could not save changes, please try again");
    }
    setLoading(false);
  }

  return (
    <div className="matchday-creator">
      {show ? (
        toShow
      ) : (
        <>
          {loading && <BackdropLoader />}
          <BackRow action={goBack} />
          <PageHeader text={edit ? "Matchday Editor" : "Matchday Creator"} />
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          <div className="ec-box">
            <div className={"input-group"}>
              <div className="ig-header">
                <p>Matchday Name</p>
              </div>
              <input
                placeholder="Start typing..."
                className="default-input"
                onChange={(e) => setName(e.target.value)}
                type="text"
                value={name}
              />
            </div>
            <div className="input-group">
              <p>Format</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                disabled={disableChanges}
                options={formats}
                value={format}
                onChange={(e) => {
                  setFormat(e.value);
                }}
              />
            </div>
            {/* <div className={"input-group"}>
              <p>Event Date</p>
              <input
                disabled={disableChanges}
                onChange={(e) => setDate(e.target.value)}
                value={date}
                max={"9999-12-31"}
                min={now}
                className="default-input"
                type="datetime-local"
              />
            </div> */}
            {false && (
              <div className="input-group mb-20">
                <p>Event Picture</p>
                <div className="image-buttons">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dz-sel">
                      {imageURL !== "" && (
                        <div className="event-img">
                          <img className="" alt="profile" src={imageURL} />
                        </div>
                      )}
                      {imageURL === "" && (
                        <h5>
                          Drag 'n' drop an image here, or click to open up the
                          file explorer
                        </h5>
                      )}
                    </div>
                    {/* <div className="dz-or">
                    <p className="">Or</p>
                  </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex-center">
            <button
              onClick={edit ? saveChanges : saveMatchday}
              className="default-button"
            >
              {edit ? "Save Changes" : "Save"}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default MatchdayCreator;
