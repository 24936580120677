/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./RankTracker.css";
import Chart from "react-apexcharts";
import { db } from "../../../firebase";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useAuth } from "../../../contexts/AuthContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function LeagueRankTracker({
  leagueID,
  seasonIDToUse = "",
  hideTitle = false,
}) {
  const { currentUser } = useAuth();

  const propSeries = [
    {
      name: "Ranking",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];

  useEffect(() => {
    loadAll();
  }, []);

  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState(propSeries);
  const [dates, setDates] = useState([]);

  const [snaps, setSnaps] = useState([]);

  const [users, setUsers] = useState([]);

  async function loadAll() {
    let seasonID = "";
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    if (seasonIDToUse !== "") {
      seasonID = seasonIDToUse;
    } else if (league.seasonID === "") {
      seasonID = league.seasonIDs[league.seasonIDs.length - 1];
    } else {
      seasonID = league.seasonID;
    }

    const seasonDoc = await db.collection("seasons").doc(seasonID).get();
    const season = seasonDoc.data();
    const { pointsTable } = season;
    const sortedPointsTable = [...pointsTable];
    if (pointsTable[0].finalRank) {
    } else {
      sortedPointsTable.sort((a, b) => b.points - a.points);
    }
    const arr = pointsTable.map((row) => {
      const temp = { label: row.userName, value: row.playerID };
      return temp;
    });

    // Snapshots
    const snaps = await seasonDoc.ref
      .collection("snapshots")
      .orderBy("date", "asc")
      .get();
    console.log(snaps.docs.length);
    const dateArr = [];
    const snapArr = [];
    const overallArr = [];
    for (let i = 0; i < snaps.docs.length; i++) {
      const snap = snaps.docs[i].data();
      const date = snap.date
        .toDate()
        .toLocaleDateString("en-gb", { dateStyle: "medium" });
      dateArr.push(date);
      const snapTable = snap.pointsTable;
      const sortedSnapTable = snapTable.sort((a, b) => b.points - a.points);
      overallArr.push(sortedSnapTable);
      for (let j = 0; j < sortedSnapTable.length; j++) {
        const row = sortedSnapTable[j];
        if (row.playerID === currentUser.uid) {
          snapArr.push(j + 1);
        }
      }
    }
    for (let j = 0; j < sortedPointsTable.length; j++) {
      const row = sortedPointsTable[j];
      if (row.playerID === currentUser.uid) {
        snapArr.push(j + 1);
      }
    }
    overallArr.push(sortedPointsTable);
    setSnaps(overallArr);
    const now = new Date().toLocaleDateString("en-gb", { dateStyle: "medium" });
    dateArr.push(now);
    setDates(dateArr);
    setSeries([{ name: "Ranking", data: snapArr }]);
    console.log(snapArr);

    setUsers(arr);
    setLoading(false);
  }

  function loadTracker(playerID) {
    console.log(playerID);
    const arr = [];
    for (let i = 0; i < snaps.length; i++) {
      const table = snaps[i];
      for (let j = 0; j < table.length; j++) {
        const row = table[j];
        if (row.playerID === playerID) {
          arr.push(j + 1);
        }
      }
    }
    setSeries([{ name: "Ranking", data: arr }]);
  }

  const proptions = {
    chart: {
      zoom: {
        enabled: true,
      },
      height: 350,
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#1e7a69", "#E24955"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },

    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(0);
        },
      },
      title: {
        text: "Rank",
      },
      min: 1,
      max: users.length,
      reversed: true,
    },
    xaxis: {
      type: "date",
      title: {
        text: "Date",
      },
      categories: dates,
    },

    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <div className="league-rank-tracker rank-tracker">
      {!hideTitle && (
        <div className="neh-box bb">
          <h2 className="ne-header">Tracker</h2>
        </div>
      )}
      <div className="input-group">
        <Dropdown
          arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
          arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
          options={users}
          placeholder="Select player..."
          onChange={(e) => loadTracker(e.value)}
        />
      </div>
      <div className="track-graph">
        {!loading && (
          <Chart
            options={proptions}
            series={series}
            type={"line"}
            height={320}
            // width={600}
          />
        )}
      </div>
    </div>
  );
}

export default LeagueRankTracker;
