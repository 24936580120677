import React, { useState, useEffect } from "react";
import "./ClientDrills.css";
import { db } from "../../../firebase";

// Icons

// Components
import CPLoader from "../CPLoader";
import { Tabs, Tab } from "@mui/material";
import DrillItem from "../Drills/DrillItem";
import DrillScoreInput from "./DrillScoreInput";
import DrillAttempt from "./DrillAttempt";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { toast } from "react-toastify";
import { errorDefault, successIco } from "../HelperFunctions";
import DrillScores from "./DrillScores";
import Pill from "../../Buttons/Pill";
import ClientDrillLoader from "./ClientDrillLoader";
import DrillDetails from "../Drills/DrillDetails";

function ClientDrills({
  clubID = "",
  clientID = "",
  drillIDs = [],
  clientname = "",
  reload = () => {},
}) {
  // Admin

  // State
  const [loading, setLoading] = useState(true);
  const [drills, setDrills] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [sel, setSel] = useState("drills");
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [error, setError] = useState("");

  const [showPage, setShowPage] = useState(false);
  const [pageToShow, setPageToShow] = useState();

  // UE
  useEffect(() => {
    loadDrills();
  }, [drillIDs]);

  // Funcs
  async function loadDrills() {
    console.log("LOAD DRILLS");
    setLoading(true);
    const drillRefs = drillIDs.map((id) =>
      db.collection("clubs").doc(clubID).collection("drills").doc(id).get()
    );

    const drillDocs = await Promise.all(drillRefs);

    const drillArr = [];
    let attemptArr = [];

    for (let i = 0; i < drillDocs.length; i++) {
      const drillDoc = drillDocs[i];
      const drill = drillDoc.data();

      const attempts = drill.attempts ?? [];

      const filteredAttempts = attempts.filter((a) => a.clientID === clientID);

      if (filteredAttempts.length > 0) {
        drill.highScore = filteredAttempts[0].score;
      } else {
        drill.highScore = 0;
      }

      drillArr.attempts = filteredAttempts;

      drillArr.push(drill);
      attemptArr = [...attemptArr, ...filteredAttempts];
    }

    for (let i = 0; i < attemptArr.length; i++) {
      const att = attemptArr[i];
      const { date } = att;
      const dateObject = new Date(date.toDate());
      attemptArr[i].dateObject = dateObject;
    }

    attemptArr.sort((a, b) => b.date - a.date);

    setDrills(drillArr);
    setAttempts(attemptArr);
    setLoading(false);
  }

  async function deleteDrillAttempt(drillID, attemptID) {
    setLoading(true);
    try {
      const drillDoc = await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drillID)
        .get();
      const drillData = drillDoc.data();
      const { attempts, custom } = drillData;

      const filteredAttempts = attempts.filter(
        (att) => att.attemptID !== attemptID
      );

      await drillDoc.ref.update({
        attempts: filteredAttempts,
      });

      if (custom) {
        const drillDoc = await db
          .collection("admin")
          .doc("academy")
          .collection("drills")
          .doc(drillID)
          .get();
        const drillData = drillDoc.data();
        const { attempts } = drillData;

        const filteredAttempts = attempts.filter(
          (att) => att.attemptID !== attemptID
        );

        await drillDoc.ref.update({
          attempts: filteredAttempts,
        });
      }

      toast.success("The attempt was successfully deleted", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(errorDefault);
    }
    setLoading(false);
  }

  async function removeDrill(drillID) {
    setLoading(true);
    try {
      const userDoc = await db.collection("users").doc(clientID).get();
      const user = userDoc.data();
      const { drillIDs } = user;
      const clubDrillIDs = drillIDs[clubID];

      const filt = clubDrillIDs.filter((id) => id !== drillID);
      drillIDs[clubID] = filt;
      // setLoading(false);
      // return console.log(drillIDs);

      await userDoc.ref.update({
        drillIDs,
      });
      reload();
      toast.success("This drill was successfully removed.", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="client-drills">
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}

      {loading ? (
        <CPLoader />
      ) : showPage ? (
        pageToShow
      ) : (
        <>
          {show && toShow}
          <div className="flex-ac">
            <div className="dash-tab pl-0 ml-20">
              <Tabs
                value={sel}
                onChange={(e, v) => setSel(v)}
                className="dt-tabs shadow-md"
              >
                <Tab value={"drills"} label="Drills" />
                <Tab value={"attempts"} label="Attempts" />
              </Tabs>
            </div>
            <div className="ml-20">
              <Pill
                text={"Add Drill"}
                onClick={() => {
                  setToShow(
                    <ClientDrillLoader
                      clientDrillIDs={drillIDs}
                      clientID={clientID}
                      close={() => setShow(false)}
                      clubID={clubID}
                      reload={reload}
                    />
                  );
                  setShow(true);
                }}
              />
            </div>
          </div>
          <div className="cd-box">
            <div className="wbg bss-white bs-subtle pd-20">
              {sel === "drills" && (
                <>
                  {drills.length === 0 && (
                    <div className="zer-reqs mt-40 mb-40">
                      <p>
                        No drills have been added to this client's profile yet.
                      </p>
                    </div>
                  )}
                  <div className="flex flex-wrap wbg-box">
                    {drills.map((drill, i) => {
                      return (
                        <DrillItem
                          showRemove
                          removeAct={() => {
                            removeDrill(drill.drillID);
                          }}
                          academyID={clubID}
                          drill={drill}
                          showAddScore
                          showAttempts
                          viewAct={() => {
                            setPageToShow(
                              <DrillDetails
                                clubID={clubID}
                                goBack={() => setShowPage(false)}
                                id={drill.drillID}
                              />
                            );
                            setShowPage(true);
                          }}
                          viewAttempts={() => {
                            setPageToShow(
                              <DrillScores
                                clientID={clientID}
                                clubID={clubID}
                                reload={loadDrills}
                                attempts={drill.attempts}
                                back={() => setShowPage(false)}
                                client={clientname}
                                drill={drill}
                              />
                            );
                            setShowPage(true);
                          }}
                          addScore={() => {
                            setToShow(
                              <DrillScoreInput
                                clubID={clubID}
                                clientID={clientID}
                                close={() => setShow(false)}
                                drill={drill}
                                clientName={clientname}
                                reload={loadDrills}
                              />
                            );
                            setShow(true);
                          }}
                          key={drill.drillID}
                        />
                      );
                    })}{" "}
                  </div>
                </>
              )}
              {sel === "attempts" && (
                <>
                  {attempts.length === 0 && (
                    <div className="zer-reqs mt-40 mb-40">
                      <p>
                        No attempts have been added to this client's profile
                        yet.
                      </p>
                    </div>
                  )}

                  <div className="drill-attempt da-header flex flex-align-center mb-10">
                    <div>
                      <p>Date</p>
                    </div>
                    <div>
                      <p>Drill</p>
                    </div>
                    <div>
                      <p>Category</p>
                    </div>
                    <div>
                      <p>Difficulty</p>
                    </div>
                    <div>
                      <p>Score</p>
                    </div>
                    <div>
                      <p></p>
                    </div>
                  </div>

                  {attempts.map((att, i) => {
                    return (
                      <DrillAttempt
                        editAct={() => {
                          setToShow(
                            <DrillScoreInput
                              clientName={clientname}
                              clubID={clubID}
                              clientID={clientID}
                              close={() => setShow(false)}
                              defaults={att}
                              reload={loadDrills}
                              edit
                              drill={att}
                            />
                          );
                          setShow(true);
                        }}
                        deleteAct={() => {
                          deleteDrillAttempt(att.drillID, att.attemptID);
                        }}
                        key={i}
                        attempt={att}
                      />
                    );
                  })}
                </>
              )}
            </div>{" "}
          </div>
        </>
      )}
    </div>
  );
}

export default ClientDrills;
