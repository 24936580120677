/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./FixturePreview.css";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { db } from "../../../firebase";
import RsvpIcon from "@mui/icons-material/Rsvp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventIcon from "@mui/icons-material/Event";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import { isMobile } from "react-device-detect";
import RssFeedIcon from "@mui/icons-material/RssFeed";
// Gradient Icons
const GradientCircleIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon sx={{ fill: "url(#linearColors)" }} />
  </>
);
const GradientEditIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <Tooltip placement="right" title="Edit Event">
      <EditIcon onClick={onClick} sx={{ fill: "url(#linearColors)" }} />
    </Tooltip>
  </>
);

function FixturePreview({
  event,
  showDetails,
  userID,
  invited = false,
  playing = false,
  winner = "",
  past = false,
  editAction = () => {},
  personAction = () => {},
  timeAction = () => {},
  cup = false,
  hidePerson = false,
  hideRounds = false,
  showBG = false,
  hideIcons = false,
  showLive = false,
}) {
  const GradientPersonIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <Tooltip placement="right" title="Invite Players">
        <PersonAddAlt1Icon
          className="mb-10 mt-10 dg-icon-bg"
          onClick={onClick}
          sx={{ fill: "url(#linearColors)" }}
        />
      </Tooltip>
    </>
  );
  const GradientTimeIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <Tooltip placement="right" title="Manage Rounds">
        <AccessTimeIcon
          className="dg-icon-bg"
          onClick={onClick}
          sx={{ fill: "url(#linearColors)" }}
        />
      </Tooltip>
    </>
  );
  const GradientHouseIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <Tooltip placement="right" title={cup ? "Cup Home" : "Event Home"}>
        <HomeIcon
          className="dg-icon-bg"
          onClick={onClick}
          sx={{ fill: "url(#linearColors)" }}
        />
      </Tooltip>
    </>
  );
  useEffect(() => {
    // loadCourseImage();
  }, []);

  const navigate = useNavigate();

  const [courseImage, setCourseImage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [eventDate, setEventDate] = useState();
  const [eventWinner, setEventWinner] = useState(winner);

  async function loadCourseImage() {
    if (event.dateString === undefined || event.courseName === undefined) {
      const courseID = event.courseID;
      const courseDoc = await db.collection("courses").doc(courseID).get();
      if (!courseDoc.exists) {
        return;
      }
      const course = courseDoc.data();
      setCourseImage(course.imageLink);
      setCourseName(course.name);
      const evtDate = new Date(event.date.toDate());
      setEventDate(
        evtDate.toLocaleDateString("en-US", {
          dateStyle: "long",
        })
      );
      // console.log(evtDate)
    }

    if (event.winnerID !== "" && event.winnerID !== undefined) {
      const playerID = event.winnerID;
      const playerDoc = await db.collection("users").doc(playerID).get();
      const player = playerDoc.data();
      const playerName = `${player.firstName} ${player.lastName}`;
      setEventWinner(playerName);
    }
  }

  return (
    <div
      style={{
        backgroundColor: showBG ? "#C7EFDE" : "white",
      }}
      onClick={() => {
        if (showDetails) {
          showDetails();
        } else if (isMobile) {
          if (event.teamEvent) {
            navigate(`/team-event-home/${event.eventID}`);
          } else if (event.cup) {
            navigate(`/cup-home/${event.cupID}`);
          } else {
            navigate(`/event-home/${event.eventID}`);
          }
        }
      }}
      className="fixture-preview scale-in-ps "
    >
      <div className="fp-left">
        <img alt="course" src={event.imageLink} />
      </div>
      <div className="fp-right">
        {/* <Tooltip title="poop"> */}
        <h1>{event.eventName}</h1>
        {/* </Tooltip> */}
        <h2>{event.courseName ?? courseName}</h2>
        {winner !== "" && (
          <div className="fp-winner">
            <EmojiEventsIcon className="fpw-icon" />

            <h3>{winner}</h3>
          </div>
        )}
        <div className="fpr-bot">
          {event.playerIDs && (
            <div className="fprb-item mr-10">
              <PersonIcon className="fp-icon" />
              <h3>
                {event.guests
                  ? event.guests.length + event.playerIDs.length
                  : event.playerIDs.length}
              </h3>
            </div>
          )}
          <div className="fprb-item mr-10">
            <EventIcon className="fp-icon" />
            <h3>{event.dateString ?? eventDate}</h3>
          </div>
          <div className="fprb-item fprb-rds">
            <FlagCircleIcon className="fp-icon" />
            <h3>{event.rounds ? event.rounds.length : 0}</h3>
          </div>
        </div>

        {/* {eventWinner && <p>Winner: {eventWinner}</p>} */}
        <div className="fp-right-button-row"></div>
      </div>

      {!hideIcons && (
        <div className="fp-icons">
          <Link
            key={event.eventID}
            to={
              event.teamEvent
                ? `/team-event-home/${event.eventID}`
                : event.cup
                ? `/cup-home/${event.cupID}`
                : `/event-home/${event.eventID}`
            }
          >
            <GradientHouseIcon
            //  onClick={editAction}
            />
          </Link>
          {showLive && (
            <Link
              key={event.eventID}
              to={
                event.teamEvent
                  ? `/team-evt-dash/${event.eventID}`
                  : event.cup
                  ? `/cup-dash/${event.cupID}`
                  : `/evt-dash/${event.eventID}`
              }
            >
              <Tooltip placement="right" title="Live Dashboard">
                <RssFeedIcon className="dg-icon-bg mt-10" />
              </Tooltip>
            </Link>
          )}
          {!past && !hidePerson && false && (
            <GradientPersonIcon onClick={personAction} />
          )}
          {hidePerson && !showLive && false && <div className="mt-10"></div>}
          {!past && !hideRounds && false && (
            <GradientTimeIcon onClick={timeAction} />
          )}
        </div>
      )}
    </div>
  );
}

export default FixturePreview;
