import React, { useEffect, useState, useCallback } from "react";
import { db, admin, storage } from "../../../firebase";
import "./MessageBoard.css";
// Icons

// Components
import BackRow from "../../../components/display/Rows/BackRow";
import { Dialog } from "@mui/material";
import BackdropLoader from "../../../components/Loaders/BackdropLoader";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import ErrorModal from "../../../components/ErrorModal/ErrorModal";
import Pill from "../../../components/Buttons/Pill";
import MessageItem from "./MessageItem";
import { Close } from "@mui/icons-material";
import PageHeader from "../../../components/display/Rows/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { successIco } from "../../../components/ClientStats/HelperFunctions";

function MessageBoard({ goBack = () => {} }) {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  const clubID = useParams().id;

  // State
  const [messages, setMessages] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [toEdit, setToEdit] = useState();
  const [toDelete, setToDelete] = useState();

  const [messageTitle, setMessageTitle] = useState("");
  const [messageText, setMessageText] = useState();

  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");

  const [editMessageTitle, setEditMessageTitle] = useState("");
  const [editMessageText, setEditMessageText] = useState();

  const [editImage, setEditImage] = useState("");
  const [editImageURL, setEditImageURL] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  // UE
  useEffect(() => {
    loadMessages();
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const url = URL.createObjectURL(acceptedFiles[0]);

    if (showEdit) {
      setEditImageURL(url);
      new Compressor(acceptedFiles[0], {
        convertSize: 1000000,
        success: (res) => {
          setEditImage(res);
        },
      });
    } else {
      setImageURL(url);
      new Compressor(acceptedFiles[0], {
        convertSize: 1000000,
        success: (res) => {
          setImage(res);
        },
      });
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  async function loadMessages() {
    const arr = [];
    const messages = await db
      .collection("clubs")
      .doc(clubID)
      .collection("messages")
      .orderBy("dateCreated", "desc")
      .get();

    for (let i = 0; i < messages.docs.length; i++) {
      const messageDoc = messages.docs[i];
      const messageID = messageDoc.id;
      const message = messageDoc.data();
      const { dateCreated } = message;
      const dateObj = new Date(dateCreated.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      message.messageID = messageID;
      message.dateObj = dateObj;
      message.dateString = dateString;
      arr.push(message);
    }

    setMessages(arr);
    setLoading(false);
  }

  async function saveMessage() {
    setLoading(true);
    setShowNew(false);

    try {
      const messageDoc = await db
        .collection("clubs")
        .doc(clubID)
        .collection("messages")
        .add({
          dateCreated: new Date(),
          message: messageText,
          title: messageTitle,
          imageLink: "",
        });

      if (image !== "") {
        const task = storage
          .ref()
          .child(`${messageDoc.id}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then(async (url) => {
            await messageDoc.update({
              imageLink: url,
            });
            const dateString = new Date().toLocaleDateString("en-us", {
              dateStyle: "full",
            });
            setMessages((current) => {
              current.push({
                dateCreated: new Date(),
                message: messageText,
                messageID: messageDoc.id,
                title: messageTitle,
                dateString,
              });
              return current;
            });
            setMessageText("");
            setMessageTitle("");
            setImage("");
            setImageURL("");
          });
        });
      } else {
        const dateString = new Date().toLocaleDateString("en-us", {
          dateStyle: "full",
        });
        setMessages((current) => {
          current.unshift({
            dateCreated: new Date(),
            message: messageText,
            title: messageTitle,
            messageID: messageDoc.id,
            dateString,
          });
          return current;
        });
        setMessageText("");
        setMessageTitle("");
        setImage("");
        setImageURL("");
        toast.success("Message successfully added", {
          icon: successIco,
        });
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function updateMessage() {
    setLoading(true);
    setShowEdit(false);

    const messageID = toEdit.messageID;

    try {
      const messageDoc = await db
        .collection("clubs")
        .doc(clubID)
        .collection("messages")
        .doc(toEdit.messageID)
        .update({
          message: editMessageText,
          title: editMessageTitle,
        });

      if (editImage !== "") {
        const task = storage
          .ref()
          .child(`${messageID}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then(async (url) => {
            await messageDoc.update({
              imageLink: url,
            });
            setMessages((current) => {
              for (let i = 0; i < current.length; i++) {
                if (current[i].messageID === messageID) {
                  current[i].message = editMessageText;
                  current[i].title = editMessageTitle;
                  current[i].imageLink = url;
                }
              }
              return current;
            });

            setEditMessageText("");
            setEditMessageTitle("");
            setEditImage("");
            setEditImageURL("");
            toast.success("Message successfully edited", {
              icon: successIco,
            });
          });
        });
      } else {
        setMessages((current) => {
          for (let i = 0; i < current.length; i++) {
            if (current[i].messageID === messageID) {
              current[i].message = editMessageText;
              current[i].title = editMessageTitle;
            }
          }
          return current;
        });
        setEditMessageText("");
        setEditMessageTitle("");
        setEditImage("");
        setEditImageURL("");
        toast.success("Message successfully edited", {
          icon: successIco,
        });
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function deleteMessage() {
    setLoading(true);
    setShowDelete(false);
    try {
      await db
        .collection("clubs")
        .doc(clubID)
        .collection("messages")
        .doc(toDelete.messageID)
        .delete();
      setMessages((current) =>
        current.filter((message) => message.messageID !== toDelete.messageID)
      );
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  return (
    <div className="message-board">
      <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
        <div className="cs-delete">
          <PageHeader
            text="Delete Message"
            close={() => setShowDelete(false)}
            showClose
          />
          <p>Are you sure you want to delete this message?</p>
          <div className="flex-center">
            <button onClick={deleteMessage} className="delete-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showNew} onClose={() => setShowNew(false)}>
        <div className="mb-add pd-20">
          <PageHeader
            text="New Message"
            showClose
            close={() => setShowNew(false)}
          />

          <div className="ec-box">
            <div className="ecb-left">
              <div className="input-group">
                <p>Title</p>
                <input
                  type="text"
                  value={messageTitle}
                  onChange={(e) => setMessageTitle(e.target.value)}
                  className="default-input"
                  placeholder="Enter message title..."
                />
              </div>
              <div className="input-group">
                <p>Message</p>
                <textarea
                  value={messageText}
                  placeholder="Enter message..."
                  onChange={(e) => setMessageText(e.target.value)}
                  className="default-input"
                />
              </div>
            </div>
            <div className="input-group mb-20">
              <p>Add Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imageURL} />
                      </div>
                    )}
                    {imageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center mb-20">
            <button
              onClick={saveMessage}
              disabled={messageTitle === "" || messageText === ""}
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showEdit} onClose={() => setShowEdit(false)}>
        <div className="mb-add pd-20">
          <div className="page-header">
            <h2>Edit Message</h2>
            <Close className="icon-cursor" onClick={() => setShowEdit(false)} />
          </div>
          <div className="ec-box">
            <div className="ecb-left">
              <div className="input-group">
                <p>Title</p>
                <input
                  type="text"
                  value={editMessageTitle}
                  onChange={(e) => setEditMessageTitle(e.target.value)}
                  className="default-input"
                  placeholder="Enter message title..."
                />
              </div>
              <div className="input-group">
                <p>Message</p>
                <textarea
                  value={editMessageText}
                  placeholder="Enter message..."
                  onChange={(e) => setEditMessageText(e.target.value)}
                  className="default-input"
                />
              </div>
            </div>
            <div className="input-group mb-20">
              <p>Edit Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {editImageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={editImageURL} />
                      </div>
                    )}
                    {editImageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center mb-20">
            <button
              onClick={updateMessage}
              disabled={editMessageTitle === "" || editMessageText === ""}
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>

      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <BackRow action={goBack} />

      <PageHeader text="Message Board" />

      <div className="quick-links">
        <Pill text={"Add Message"} onClick={() => setShowNew(true)} />
      </div>

      <div className="pd-20">
        {messages.length === 0 && (
          <div className="no-msg">
            <p>No items to display</p>
          </div>
        )}
        {messages.map((message, index) => (
          <MessageItem
            key={index}
            deleteAction={() => {
              setToDelete(message);
              setShowDelete(true);
            }}
            editAction={() => {
              setToEdit(message);
              console.log(message.imageLink);
              setEditImageURL(message.imageLink);
              setEditMessageText(message.message);
              setEditMessageTitle(message.title);
              setShowEdit(true);
            }}
            message={message}
          />
        ))}
      </div>
    </div>
  );
}

export default MessageBoard;
