import React, { useEffect } from "react";
import "./CupNassau.css";
import MatchplayHoleSlider from "../../display/MatchplayScore/MatchplayHoleSlider";

function CupNassau({
  nameOne = "",
  nameTwo = "",
  leader = "",
  teamOne = 0,
  teamTwo = 0,
  holes,
  holeArray = [],
  teamOneBG = "",
  teamTwoBG = "",
  idOne = "",
  idTwo = "",
  handleClick = () => {},
}) {
  useEffect(() => {
    // console.log(nameOne);
    // console.log(teamOne, teamTwo);
    // console.log(teamOneBG, teamTwoBG);
  }, []);
  return (
    <div onClick={handleClick} className="cup-nassau matchplay-score">
      <div className={"matchplay-score"}>
        <div
          className={teamOne > teamTwo ? "ms-left msla ms-active" : "ms-left"}
        >
          <div
            style={{ backgroundColor: teamOne > teamTwo ? teamOneBG : "white" }}
            className="ms-left-box"
          >
            <p>{nameOne}</p>
          </div>
          <div
            style={{
              borderColor:
                teamOne > teamTwo
                  ? `transparent transparent transparent ${teamOneBG}`
                  : "transparent transparent transparent transparent",
              backgroundColor: "white",
            }}
            className="ms-left-border"
          ></div>
        </div>
        <div className="ms-mid">
          <div
            style={{
              backgroundColor:
                teamOne > teamTwo
                  ? teamOneBG
                  : teamOne < teamTwo
                  ? teamTwoBG
                  : "#21c17c",
              backgroundImage:
                teamOne === teamTwo
                  ? `linear-gradient(90deg, ${teamOneBG} 0%, ${teamTwoBG} 100%)`
                  : "white",
            }}
            className="msm-top"
          >
            <div className="score-circle">
              <p>
                {teamOne} - {teamTwo}
              </p>
            </div>
          </div>
          <div className="msm-bot">
            <p>Thru</p>
            <p>{holes}</p>
          </div>
        </div>
        <div
          className={
            teamOne < teamTwo
              ? "ms-right msra ms-active"
              : leader === "teamOne"
              ? "ms-right msr-inactive"
              : "ms-right"
          }
        >
          <div
            style={{
              borderColor:
                teamOne < teamTwo
                  ? `transparent ${teamTwoBG} transparent transparent `
                  : "transparent transparent transparent transparent",
              backgroundColor: "white",
            }}
            className="ms-right-border"
          ></div>
          <div
            style={{ backgroundColor: teamOne < teamTwo ? teamTwoBG : "white" }}
            className="ms-right-box"
          >
            <p>{nameTwo}</p>
          </div>
        </div>
      </div>
      {holeArray.length > 0 && (
        <MatchplayHoleSlider
          teamOneColor={teamOneBG}
          teamTwoColor={teamTwoBG}
          holes={holeArray}
          idOne={idOne}
          idTwo={idTwo}
        />
      )}
    </div>
  );
}

export default CupNassau;
