import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icos
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAuth } from "../../../contexts/AuthContext";
// Components
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "react-dropdown/style.css";
import Dropdown from "react-dropdown";
import BackRow from "../../display/Rows/BackRow";
import BackdropLoader from "../../Loaders/BackdropLoader";
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import ScoreInput from "../ScoreInput/ScoreInput";
import LiveTeeChange from "./LiveTeeChange";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import EventHandicapEdit from "./EventHandicapEdit";
import { CSVLink, CSVDownload } from "react-csv";
import LivePlayerCourseEdit from "./LivePlayerCourseEdit";
import ScoreSubmission from "./ScoreSubmission";
import { Settings } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { useCookies } from "react-cookie";
import ListGroupPlayer from "./ListGroupPlayer";

function LiveGroups({
  roundID = "",
  goBack = () => {},
  eventID = "",
  eventName = "",
  evtComplete = false,
}) {
  // State
  const [enableSubmission, setEnableSubmission] = useState(false);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [courseID, setCourseID] = useState("");
  const [groups, setGroups] = useState([]);
  const [activeRoundID, setActiveRoundID] = useState(roundID);
  const [showTransfer, setShowTransfer] = useState(false);
  const [stModal, setSTModal] = useState(false);
  const [stPlayer, setSTPlayer] = useState("");
  const { userDetails } = useAuth();
  const [hideTeeHcp, setHideTeeHCP] = useState(false);
  const [showListOption, setShowListOption] = useState(false);
  const [roundIndex, setRoundIndex] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies([
    "lg-settings",
    "lg-view",
  ]);

  const [enableTeams, setEnableTeams] = useState(false);

  const [settings, setSettings] = useState({
    showTeam: false,
    hideComplete: false,
    hideTees: false,
  });

  const [selLGP, setSelLGP] = useState("");

  const [showSettings, setShowSettings] = useState(false);

  const [error, setError] = useState("");

  const [courseMap, setCourseMap] = useState({});
  const [scrollPos, setScrollPos] = useState(0);

  const [showNameChange, setShowNameChange] = useState(false);
  const [firstNameInput, setFirstNameInput] = useState("");
  const [lastNameInput, setLastNameInput] = useState("");
  const [selGuest, setSelGuest] = useState();

  const [teamMap, setTeamMap] = useState({});

  const [rounds, setRounds] = useState([]);
  const [roundOpts, setRoundOpts] = useState([]);
  const [selRd, setSelRd] = useState(roundID);

  const [format, setFormat] = useState("");

  const [view, setView] = useState("groups");

  useEffect(() => {
    if (cookies["lg-settings"]) {
      // console.log(cookies["lg-settings"]);
      setSettings(cookies["lg-settings"]);
    }
    if (cookies["lg-view"]) {
      setView(cookies["lg-view"]);
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      if (userDetails.accountType === "scorer") {
        setHideTeeHCP(true);
      } else {
        setHideTeeHCP(false);
      }
    }
  }, []);

  useEffect(() => {
    loadGroups({ roundID });
  }, []);

  function sortTeamMap(teams) {
    const mep = {};

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const { players, teamName } = team;
      for (let j = 0; j < players.length; j++) {
        const player = players[j];
        mep[player.playerID] = teamName;
      }
    }
    // console.log(mep);
    setTeamMap(mep);
  }

  async function loadGroups({ roundID = "" }) {
    setSelLGP("");
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    setActiveRoundID(event.activeRoundID);
    setEnableSubmission(event.enableSubmission ?? false);
    // console.log(event.teams);
    if (event.enableTeams) {
      setEnableTeams(true);
      sortTeamMap(event.teams ?? []);
    } else {
      setShowListOption(true);
    }
    const { roundIDs } = event;
    const rdOpts = [];
    const ari = roundIDs.indexOf(event.activeRoundID);
    for (let i = ari; i < roundIDs.length; i++) {
      const toRet = {
        label: `Round ${i + 1}`,
        value: roundIDs[i],
      };
      rdOpts.push(toRet);
    }
    // const rdOpts = roundIDs.map((id, i) => {
    //   const toRet = {
    //     label: `Round ${i + 1}`,
    //     value: id,
    //   };
    //   return toRet;
    // });
    setRoundOpts(rdOpts);
    setSelRd(roundID);
    // console.log(event.courses);
    setCourses(event.courses ?? []);
    setRounds(roundIDs);

    if (roundIDs.length > 1) {
      if (roundIDs.indexOf(event.activeRoundID) !== roundIDs.length - 1) {
        // setShowTransfer(true);
      }
    }

    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (snapshot) => {
        const doc = snapshot.data();
        const { leaderboard, teeTimes, teeMap, format, roundIndex } = doc;
        setCourseID(doc.courseID);
        setRoundIndex(roundIndex);
        setFormat(format);
        // setCourses(doc.courses ?? []);
        const genderMap = doc.genderMap ?? {};
        // console.log(leaderboard);
        const compMap = {};
        const hcpMap = {};
        const playerMap = {};

        const cMap = {};

        const courseTitles = {};

        const courses = doc.courses ?? [];

        for (let i = 0; i < courses.length; i++) {
          const course = courses[i];
          courseTitles[course.courseID] = course.name;
        }

        // console.log(leaderboard);

        for (let i = 0; i < leaderboard.length; i++) {
          const row = leaderboard[i];
          const { playerID, thru, handicap } = row;
          row.gender = genderMap[playerID] ?? "male";
          compMap[playerID] = thru;
          hcpMap[playerID] = handicap;
          playerMap[playerID] = row;
          cMap[playerID] = courseTitles[row.courseID] ?? "";
        }
        setCourseMap(cMap);

        // console.log(playerMap);

        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];

          if (typeof group.time === "object") {
            const dtobj = new Date(group.time.toDate());
            teeTimes[i].time = new Date(group.time.toDate()).toLocaleTimeString(
              "en-gb",
              { timeStyle: "short" }
            );
          }
          let comp = true;
          const players = group.players ?? group.playerIDs;
          for (let x = 0; x < players.length; x++) {
            const player = players[x];
            let playerID = "";

            if (group.players === undefined) {
              teeTimes[i].players = [];
            }

            if (typeof player === "string") {
              playerID = player;
              teeTimes[i].players[x] = playerMap[player];
              teeTimes[i].players[x].tee = teeMap[player];
              teeTimes[i].players[x].handicap = hcpMap[player];
              if (playerMap[playerID]) {
                // console.log(playerMap[playerID]);
                teeTimes[i].players[x].score = playerMap[playerID].score;
                if (roundIndex > 0) {
                  teeTimes[i].players[x].today = playerMap[playerID].today;
                }
                teeTimes[i].players[x].dq = playerMap[playerID].dq ?? false;
                teeTimes[i].players[x].wd = playerMap[playerID].wd ?? false;
              }
            } else {
              playerID = player.playerID;
              teeTimes[i].players[x].tee = teeMap[player.playerID];
              teeTimes[i].players[x].handicap = hcpMap[player.playerID];

              if (playerMap[playerID]) {
                // console.log(playerMap[playerID]);
                teeTimes[i].players[x].score = playerMap[playerID].score;
                if (roundIndex > 0) {
                  teeTimes[i].players[x].today = playerMap[playerID].today;
                }
                teeTimes[i].players[x].dq = playerMap[playerID].dq ?? false;
                teeTimes[i].players[x].wd = playerMap[playerID].wd ?? false;
              }
            }
            const thru = compMap[playerID];
            teeTimes[i].players[x].thru = thru;
            if (!playerMap[playerID]) {
              console.log(playerID);
            }
            if (playerMap[playerID]) {
              teeTimes[i].players[x].scoreTransferredInto =
                playerMap[playerID].scoreTransferredInto ?? false;
              teeTimes[i].players[x].scoreTransferred =
                playerMap[playerID].scoreTransferred ?? false;
            }

            if (
              thru !== 18 &&
              thru !== undefined &&
              playerMap[playerID] !== undefined &&
              !playerMap[playerID].dq &&
              !playerMap[playerID].wd
            ) {
              comp = false;
            }
          }
          teeTimes[i].completed = comp;
        }

        setGroups(teeTimes);
        setLoading(false);
      });
  }

  async function saveNameChange() {
    // return console.log(selGuest);
    setShowNameChange(false);

    setLoading(true);
    try {
      const playerID = selGuest.playerID;
      const name = `${firstNameInput} ${lastNameInput}`;
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const eventDoc = await db.collection("events").doc(eventID).get();
      const round = roundDoc.data();
      const event = eventDoc.data();
      const eventGuests = event.guests;
      const { leaderboard, enableTeams, jackpot, guests, teeTimes } = round;

      // Leaderboard
      for (let i = 0; i < leaderboard.length; i++) {
        if (leaderboard[i].playerID === playerID) {
          leaderboard[i].name = name;
          leaderboard[i].firstName = firstNameInput;
          leaderboard[i].lastName = lastNameInput;
        }
      }

      // Guests
      for (let i = 0; i < guests.length; i++) {
        if (guests[i].playerID === playerID) {
          guests[i].name = name;
          guests[i].firstName = firstNameInput;
          guests[i].lastName = lastNameInput;
        }
      }

      // Event Guests
      for (let i = 0; i < eventGuests.length; i++) {
        if (eventGuests[i].playerID === playerID) {
          eventGuests[i].name = name;
          eventGuests[i].firstName = firstNameInput;
          eventGuests[i].lastName = lastNameInput;
        }
      }

      // Tee Times
      for (let i = 0; i < teeTimes.length; i++) {
        const group = teeTimes[i];
        const { players } = group;
        for (let x = 0; x < players.length; x++) {
          if (players[x].playerID === playerID) {
            teeTimes[i].players[x].name = name;
          }
        }
      }

      await roundDoc.ref.update({
        leaderboard,
        guests,
        teeTimes,
      });
      await eventDoc.ref.update({
        guests: eventGuests,
      });

      const leaderboardIDs = round.leaderboardIDs ?? [];

      for (let i = 0; i < leaderboardIDs.length; i++) {
        const leaderboardID = leaderboardIDs[i];
        const board = round[leaderboardID];
        const leaderboard = board.leaderboard;
        const { playerIDs } = board;
        if (playerIDs.includes(playerID)) {
          for (let j = 0; j < leaderboard.length; j++) {
            if (leaderboard[j].playerID === playerID) {
              leaderboard[j].name = name;
              leaderboard[j].firstName = firstNameInput;
              leaderboard[j].lastName = lastNameInput;
            }
          }
        }
        board.leaderboard = leaderboard;
        await roundDoc.ref.update({
          [`${leaderboardID}`]: board,
        });
      }

      setFirstNameInput("");
      setLastNameInput("");
      setSelGuest();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function transferScore({ playerID = "" }) {
    setLoading(true);
    setSTModal(false);
    try {
      await db.collection("triggers").add({
        type: "player-transfer",
        playerID: stPlayer,
        eventID,
        roundID: selRd,
        dateAdded: new Date(),
      });
    } catch (err) {
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="live-groups">
      {show ? (
        toShow
      ) : (
        <>
          <BackRow action={goBack} />
          {loading && <BackdropLoader />}
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          <Dialog
            open={showNameChange}
            onClose={() => setShowNameChange(false)}
          >
            <div className="lg-guest-edit">
              {selGuest && (
                <PageHeader
                  showClose
                  close={() => setShowNameChange(false)}
                  text={`Change ${selGuest.name}'s name`}
                />
              )}
              <div className="ec-box">
                <div className="input-group pl-20">
                  <p>First Name</p>
                  <input
                    type="text"
                    placeholder="Start typing..."
                    value={firstNameInput}
                    onChange={(e) => setFirstNameInput(e.target.value)}
                    className="default-input"
                  />
                </div>
                <div className="input-group pl-20">
                  <p>Last Name</p>
                  <input
                    type="text"
                    placeholder="Start typing..."
                    value={lastNameInput}
                    onChange={(e) => setLastNameInput(e.target.value)}
                    className="default-input"
                  />
                </div>
              </div>

              <div className="flex-center mt-40 mb-40">
                <button
                  onClick={saveNameChange}
                  disabled={firstNameInput === "" || lastNameInput === ""}
                  className="default-button"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Dialog>

          <Dialog
            open={showSettings}
            onClose={() => {
              setCookie("lg-settings", settings);
              setShowSettings(false);
            }}
          >
            <div className="sett-dia ">
              <PageHeader
                text="Settings"
                showClose
                close={() => {
                  setCookie("lg-settings", settings);
                  setShowSettings(false);
                }}
              />

              <div className="set-dia-ctt pd-20">
                <div className="eve-rds">
                  {enableTeams && (
                    <div className="eve-rd-item">
                      <Checkbox
                        checked={settings.showTeam}
                        onChange={(e) => {
                          setSettings((c) => {
                            c.showTeam = !c.showTeam;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      />
                      <p>Display Team Names</p>
                    </div>
                  )}
                  <div className="eve-rd-item">
                    <Checkbox
                      checked={settings.hideComplete}
                      onChange={(e) => {
                        setSettings((c) => {
                          c.hideComplete = !c.hideComplete;
                          return c;
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>Hide Complete Groups</p>
                  </div>
                  <div className="eve-rd-item">
                    <Checkbox
                      checked={settings.hideTees}
                      onChange={(e) => {
                        setSettings((c) => {
                          c.hideTees = !c.hideTees;
                          return c;
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>Hide Tees</p>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>

          <Dialog open={stModal} onClose={() => setSTModal(false)}>
            <div className="stm-ctt submit-dia">
              <PageHeader
                text="Transfer Player"
                showClose
                close={() => setSTModal(false)}
              />
              <p>
                Are you sure you want to transfer this player's score to the
                next round?
              </p>
              <div className="flex-center">
                <button onClick={transferScore} className="default-button">
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>

          <div className="sett-ph flex flex-betw">
            <PageHeader text={`Live Group View - ${eventName}`} />
            <Settings
              className="dg-icon-bg icon-cursor sett-ph-ico"
              onClick={() => {
                setShowSettings(true);
              }}
            />
          </div>

          {showListOption && (
            <div className="pl-20">
              <div className="selector-group">
                <button
                  onClick={() => {
                    setView("groups");
                    setCookie("lg-view", "groups");
                  }}
                  className={
                    view === "groups" ? "sg-active sg-right" : "sg-right"
                  }
                >
                  Groups
                </button>
                <button
                  onClick={() => {
                    setView("list");
                    setCookie("lg-view", "list");
                  }}
                  className={
                    view === "list" ? "sg-middle sg-active" : "sg-middle"
                  }
                >
                  List
                </button>
              </div>
            </div>
          )}

          {userDetails &&
            false &&
            (userDetails.test || userDetails.admin) &&
            roundOpts.length > 1 && (
              <>
                <div className="input-group pl-20">
                  <div className="ig-header">
                    <p>Select Round</p>
                  </div>

                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={roundOpts}
                    value={selRd}
                    onChange={(e) => {
                      loadGroups({ roundID: e.value });

                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
              </>
            )}

          {view === "groups" && (
            <div className="drop-groups">
              {groups.map((group, i) => {
                if (settings.hideComplete) {
                  if (group.completed) {
                    return null;
                  }
                }

                return (
                  <div
                    key={i}
                    className={
                      group.completed ? "drop-group dg-comp" : "drop-group"
                    }
                  >
                    <div className="group-header">
                      <div className="gh-flex">
                        <h2 className="ne-header">
                          {group.name ? group.name : `Group ${i + 1}`} -{" "}
                          {group.time} - Tee: {group.startingTee}
                        </h2>
                        {group.completed && (
                          <>
                            <CheckCircleIcon className="dg-icon mr-20 " />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dg-players">
                      {group.players.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        // if (i === 0) {
                        // }
                        if (player.wd || player.dq) {
                          return null;
                        }

                        const { thru } = player;

                        const isBefore =
                          rounds.indexOf(selRd) < rounds.indexOf(roundID);

                        let hideVert = false;
                        if (player.scoreTransferred) {
                          hideVert = true;
                        }

                        if (
                          selRd !== activeRoundID &&
                          !player.scoreTransferredInto
                        ) {
                          hideVert = true;
                        }

                        if (isBefore) {
                          hideVert = true;
                        }

                        // console.log(hideVert);

                        return (
                          <RoundPlayer
                            transferAction={() => {
                              setSTPlayer(player.playerID);
                              setSTModal(true);
                            }}
                            showTransfer={showTransfer && player.thru === 18}
                            hideTee={settings.hideTees}
                            onlyScore={hideTeeHcp}
                            team={
                              settings.showTeam ? teamMap[player.playerID] : ""
                            }
                            today={
                              player.today !== undefined && roundIndex > 0
                                ? player.today
                                : ""
                            }
                            showManual={enableSubmission}
                            subAction={() => {
                              setToShow(
                                <ScoreSubmission
                                  close={() => setShow(false)}
                                  roundID={roundID}
                                  playerID={player.playerID}
                                  hcp={player.handicap}
                                  courseID={player.courseID ?? courseID}
                                />
                              );
                              setShow(true);
                            }}
                            hideVert={player.dq || evtComplete || hideVert}
                            showCourse={courses.length > 1}
                            courseAction={() => {
                              setToShow(
                                <LivePlayerCourseEdit
                                  close={() => setShow(false)}
                                  courses={courses}
                                  player={player}
                                  eventID={eventID}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            score={player.dq ? "DQ" : player.score}
                            format={format}
                            finished={player.thru === 18}
                            guest={player.playerID.includes("guest")}
                            nameAction={() => {
                              setSelGuest(player);
                              // console.log(player);
                              setShowNameChange(true);
                            }}
                            scoreAction={() => {
                              setScrollPos(window.scrollY);
                              const tsp = window.scrollY;
                              setUpdate((c) => c + 1);
                              setToShow(
                                <ScoreInput
                                  roundID={selRd}
                                  eventID={eventID}
                                  eventName={eventName}
                                  defaultPlayer={player}
                                  goBack={() => {
                                    // console.log(tsp);
                                    setShow(false);
                                    window.scrollTo({
                                      top: tsp,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            thru={thru === 0 || thru === 18 ? "" : thru}
                            hcpAction={() => {
                              const tsp = window.scrollY;

                              setScrollPos(window.scrollY);

                              setToShow(
                                <EventHandicapEdit
                                  rounds={rounds}
                                  live
                                  eventID={eventID}
                                  defaultPlayer={player}
                                  roundID={roundID}
                                  hide={() => {
                                    setShow(false);
                                    console.log(tsp);
                                    window.scrollTo({
                                      top: tsp,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            teeAction={() => {
                              setScrollPos(window.scrollY);
                              const tsp = window.scrollY;

                              setToShow(
                                <LiveTeeChange
                                  rounds={rounds}
                                  defaultPlayer={player}
                                  eventID={eventID}
                                  hide={() => {
                                    setShow(false);
                                    console.log(tsp);
                                    window.scrollTo({
                                      top: tsp,
                                      behavior: "smooth",
                                    });
                                  }}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            liveGroup
                            name={
                              courses.length > 1
                                ? `${player.name} (${
                                    courseMap[player.playerID]
                                  })`
                                : player.name
                            }
                            tee={player.tee}
                            handicap={player.handicap}
                            key={player.playerID}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {view === "list" && (
            <div className="list-groups pd-20">
              {groups.map((group, i) => {
                return (
                  <div className="list-group" key={`${i}listgroup`}>
                    <div className="lg-top">
                      <div className="gh-flex">
                        <h2 className="ne-header">
                          {group.name ? group.name : `Group ${i + 1}`} -{" "}
                          {group.time} - Tee: {group.startingTee}
                        </h2>
                        {group.completed && (
                          <>
                            <CheckCircleIcon className="dg-icon mr-20 " />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="lg-players">
                      <ListGroupPlayer
                        header
                        showToday={roundIndex > 0}
                        player={{
                          name: "Player",
                          score: "Score",
                          thru: "Thru",
                          handicap: "Hcp",
                          today: "Today",
                        }}
                      />
                      {group.players.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        let hideVert = false;
                        if (player.scoreTransferred) {
                          hideVert = true;
                        }

                        if (
                          selRd !== activeRoundID &&
                          !player.scoreTransferredInto
                        ) {
                          hideVert = true;
                        }
                        const isBefore =
                          rounds.indexOf(selRd) < rounds.indexOf(roundID);
                        if (isBefore || evtComplete) {
                          hideVert = true;
                        }
                        return (
                          <ListGroupPlayer
                            showToday={
                              player.today !== undefined && roundIndex > 0
                            }
                            key={player.playerID}
                            roundID={selRd}
                            hideEdit={selLGP !== "" || hideVert}
                            player={player}
                            showScore={selLGP === player.playerID}
                            hideScore={() => {
                              setSelLGP("");
                              setUpdate((c) => c + 1);
                            }}
                            setShowScore={() => {
                              // alert("set");
                              setSelLGP(player.playerID);
                              setUpdate((c) => c + 1);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LiveGroups;
