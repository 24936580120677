/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from "react";

const HelperContext = React.createContext();
const HelperClickContext = React.createContext();

export function useHelperTheme() {
  return useContext(HelperContext);
}
export function useHelperClickTheme() {
  return useContext(HelperClickContext);
}

export function HelperProvider({ children }) {
  const [update, setUpdate] = useState(0);
  const [clicked, setClicked] = useState("");

  const clickRef = useRef();
  clickRef.current = clicked;

  function showClick(click) {
    // setClicked()
    setClicked((current) => {
      return click;
    });
    setUpdate((current) => current + 1);
    return;
  }

  return (
    <HelperContext.Provider value={clicked}>
      <HelperClickContext.Provider value={showClick}>
        {children}
      </HelperClickContext.Provider>
    </HelperContext.Provider>
  );
}
