import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import ErrorModal from "../../ErrorModal/ErrorModal";
import Pill from "../../Buttons/Pill";
import { Dialog } from "@mui/material";
import PlayerScore from "../../display/PlayerScore/PlayerScore";
import { Edit, Remove } from "@mui/icons-material";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

function TwoClubEdit({ roundID = "", goBack = () => {} }) {
  // Admin

  // State
  const [loading, setLoading] = useState(true);
  const [board, setBoard] = useState([]);
  const [avail, setAvail] = useState([]);
  const [showDia, setShowDia] = useState(false);
  const [showCo, setShowCo] = useState(false);
  const [error, setError] = useState("");
  const [toDel, setToDel] = useState("");
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }
  const [toAdd, setToAdd] = useState([]);

  // UE
  useEffect(() => {
    loadBoard();
  }, []);

  // Funcs
  async function loadBoard() {
    db.collection("rounds")
      .doc(roundID)
      .onSnapshot((doc) => {
        const rd = doc.data();
        const { twoClubBoard, leaderboard } = rd;

        const twoClubIDs = twoClubBoard.map((row) => row.playerID);

        const filtBoard = leaderboard.filter(
          (row) => !twoClubIDs.includes(row.playerID)
        );

        console.log(filtBoard);

        setBoard(twoClubBoard);
        setAvail(filtBoard);
        setLoading(false);
      });
  }

  async function saveAdds() {
    setShowDia(false);
    setLoading(true);
    try {
      const filtBoard = avail.filter((pl) => toAdd.includes(pl.playerID));
      const filtRefs = toAdd.map((pl) =>
        db.collection("rounds").doc(roundID).collection("scores").doc(pl).get()
      );
      const filtDocs = await Promise.all(filtRefs);
      for (let i = 0; i < filtDocs.length; i++) {
        const doc = filtDocs[i];
        const data = doc.data();
        const twoClubScore = data.twoClubScore ?? 0;
        for (let j = 0; j < filtBoard.length; j++) {
          if (filtBoard[j].playerID === doc.id) {
            filtBoard[j].score = twoClubScore;
          }
        }
      }
      const combBoard = [...board, ...filtBoard];
      combBoard.sort((a, b) => b.score - a.score);
      let currentTies = 0;
      for (let i = 0; i < combBoard.length; i++) {
        let ranking = i + 1;

        const row = combBoard[i];
        if (i > 0) {
          if (combBoard[i - 1].score === row.score) {
            ranking = i - currentTies;
            currentTies += 1;
            // console.log(i, ranking);
          } else {
            currentTies = 0;
          }
        }

        combBoard[i].rank = ranking;
      }
      //   console.log(combBoard);
      //   return setLoading(false);
      await db.collection("rounds").doc(roundID).update({
        twoClubBoard: combBoard,
      });
      setToAdd([]);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function saveChanges() {
    setShowCo(false);
    setLoading(true);
    try {
      const filtBoard = board.filter((pl) => pl.playerID !== toDel);
      await db.collection("rounds").doc(roundID).update({
        twoClubBoard: filtBoard,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="two-club-edit">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Edit 2 Club Board" />

      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog open={showDia} onClose={() => setShowDia(false)}>
        <div className="add-2c-dia">
          <PageHeader
            text="Add Player"
            close={() => setShowDia(false)}
            showClose
          />
          <div className="ph-msg">
            <p>Select a player / players to add</p>
          </div>

          <div className="pd-20 flex-align-center flex-wrap">
            {avail.map((pl, i) => (
              <PlayerItem
                noLink
                showBg={toAdd.includes(pl.playerID)}
                showHover
                showProfile={() => {
                  setToAdd((c) => {
                    if (c.includes(pl.playerID)) {
                      const filt = c.filter((row) => row !== pl.playerID);
                      console.log(filt);
                      return filt;
                    } else {
                      c.push(pl.playerID);
                      return c;
                    }
                  });
                  update();
                }}
                hideIcons
                hideHCP
                hideImage
                name={pl.name}
              />
            ))}
          </div>
          <div className="flex-center mt-40 mb-40">
            <button onClick={saveAdds} className="default-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showCo} onClose={() => setShowCo(false)}>
        <div className="confirm-dia pd-20">
          <PageHeader
            text="Remove Player"
            close={() => setShowCo(false)}
            showClose
          />
          <div className="cd-text mb-20 mt-20">
            <p>
              Are you sure you want to remove this player from the 2 club board?
            </p>
          </div>

          <div className="flex-center mt-40 mb-40">
            <button onClick={saveChanges} className="default-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <div className="quick-links">
        <Pill text={"Add Player"} onClick={() => setShowDia(true)} />
      </div>

      <div className="two-board-box pd-20">
        {board.map((player, i) => {
          return (
            <div className="two-edit-row flex-align-center mb-10">
              <PlayerScore
                blackScore
                hideImage
                score={player.score}
                rank={player.rank}
                thru={player.thru}
                format="stable"
                hcp={player.handicap}
                showRound={() => {}}
                name={player.name}
              />
              <Remove
                onClick={() => {
                  setToDel(player.playerID);
                  setShowCo(true);
                }}
                className="icon-cursor red-icon-bg icon-bg"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TwoClubEdit;
