import React, { useState, useEffect, createRef } from "react";
import "./EventDashboard.css";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import BackdropLoader from "../../Loaders/BackdropLoader";
// Icons
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

// Components
import ErrorModal from "../../ErrorModal/ErrorModal";
import Pill from "../../Buttons/Pill";
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import Leaderboard from "../../Leaderboard/Leaderboard";
import EventDashTeeTimes from "./EventDashTeeTimes";
import LiveCupGroups from "./LiveCupGroups";
import TeamLeaderboard from "../../Leaderboard/TeamLeaderboard";
import { useParams, useNavigate } from "react-router-dom";
import EventHandicapEdit from "./EventHandicapEdit";
import CupMatchups from "../../CupCreator/CupMatchups";
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { CheckCircle, Circle, Info } from "@mui/icons-material";

function CupDashboard({}) {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const cupID = useParams().id;
  const { currentUser, userDetails } = useAuth();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    cupName: "",
  });
  const [manComp, setManComp] = useState(false);

  const [showMC, setShowMC] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [error, setError] = useState("");

  const [disable, setDisable] = useState(false);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [leadOpen, setLeadOpen] = useState(false);
  const [timesOpen, setTimesOpen] = useState(false);

  const [players, setPlayers] = useState([]);
  const [bbLeaderboard, setBBLeaderboard] = useState(false);

  async function saveLiveMatches(matches = [], roundIndex = 0) {
    setLoading(true);
    const roundDoc = await db
      .collection("rounds")
      .doc(details.activeRoundID)
      .get();
    const round = roundDoc.data();
    const currentMatches = round.matches;
    const format = round.format;
    for (let i = 0; i < matches.length; i++) {
      if (format === "Fourball") {
        currentMatches[i].teamOne = matches[i].teamOne;
        currentMatches[i].teamTwo = matches[i].teamTwo;
      } else {
        currentMatches[i].playerOne = matches[i].playerOne;
        currentMatches[i].playerTwo = matches[i].playerTwo;
      }
    }

    await db.collection("rounds").doc(details.activeRoundID).update({
      matches: currentMatches,
    });
  }

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    // alert("boobs");
    const cupDoc = await db.collection("cups").doc(cupID).get();
    const cup = cupDoc.data();
    const {
      cupName,
      activeRoundID,
      bbLeaderboard,
      playerIDs,
      rounds,
      teams,
      teamOneColor,
      teamTwoColor,
    } = cup;
    const playerHandicaps = cup.playerHandicaps ?? {};
    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);
    const arr = [];
    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName, profileImage } = player;
      const handicap = playerHandicaps[playerID] ?? player.handicap;
      const name = `${firstName} ${lastName}`;
      arr.push({
        playerID,
        name,
        handicap,
        profileImage,
        originalHandicap: player.handicap,
      });
    }

    const roundDoc = await db.collection("rounds").doc(activeRoundID).get();
    const round = roundDoc.data();
    const { format, matches } = round;

    setDisable(round.completed);

    let comp = true;

    for (let i = 0; i < matches.length; i++) {
      if (matches[i].completed === false) {
        comp = false;
        break;
      }
    }

    setManComp(comp);

    setPlayers(arr);
    setBBLeaderboard(bbLeaderboard);
    setDetails({
      cupName,
      completed: round.completed,
      activeRoundID,
      rounds,
      teams,
      matches,
      format,
      teamOneColor,
      teamTwoColor,
    });
    setLoading(false);
  }

  async function submitCards() {
    setSubmitting(true);
    try {
      const cupDoc = await db.collection("cups").doc(cupID).get();
      const cup = cupDoc.data();
      const { activeRoundID } = cup;
      const roundDoc = await db.collection("rounds").doc(activeRoundID).get();
      const round = roundDoc.data();

      await roundDoc.ref.update({
        submittedIDs: round.playerIDs,
      });

      setManComp(false);
      setDisable(true);
      setSubmitted(true);
    } catch (err) {
      setSubmitError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }

    setSubmitting(false);
  }

  return (
    <div className="cup-dashboard">
      {show ? (
        toShow
      ) : (
        <>
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}

          <Dialog open={showMC} onClose={() => setShowMC(false)}>
            <div className="submit-dia">
              <PageHeader
                close={() => setShowMC(false)}
                showClose
                text="Complete Round"
              />
              {submitting ? (
                <>
                  <div className="flex-center loading">
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : submitted ? (
                <>
                  <div className="mpa-fin flex-center">
                    <CheckCircle className="big-tick dg-icon" />
                  </div>
                  <p>
                    Scorecards have been submitted. Stats and highlights will
                    appear shortly.
                  </p>
                </>
              ) : (
                <>
                  <Info className={`dg-icon big-icon`} onClick={() => {}} />
                  <p>
                    This will close this round, and this cup should this be the
                    final round.
                  </p>
                  {submitError !== "" && (
                    <div className="error-message-row">
                      <p>{submitError}</p>
                    </div>
                  )}
                  <div className="default-button-row">
                    <button onClick={submitCards} className="default-button">
                      Confirm
                    </button>
                  </div>
                </>
              )}
            </div>
          </Dialog>

          {loading && <BackdropLoader />}
          <BackRow action={goBack} />
          <PageHeader text={`Live Dashboard - ${details.cupName}`} />

          <div className="ch-links">
            <div className="ch-link">
              <div className="sub-header">
                <h5>General</h5>
              </div>
              <div className="quick-links">
                <Pill
                  text={"Public Link"}
                  onClick={() => {
                    window.open(
                      `https://www.theugatour.com/cups/${cupID}`,
                      "_blank"
                    );
                  }}
                />
                <Pill
                  text={"TV Leaderboard"}
                  onClick={() => {
                    window.open(
                      `https://www.theugatour.com/leaderboards/${cupID}`,
                      "_blank"
                    );
                  }}
                />
                <Pill
                  text={"Group view"}
                  onClick={() => {
                    setToShow(
                      <LiveCupGroups
                        cupID={cupID}
                        cupName={details.cupName}
                        goBack={() => setShow(false)}
                        roundID={details.activeRoundID}
                      />
                    );
                    setShow(true);
                  }}
                />
                {!disable && (
                  <Pill
                    text={"Edit Handicaps"}
                    onClick={() => {
                      setToShow(
                        <EventHandicapEdit
                          defaultPlayers={players}
                          hide={() => setShow(false)}
                          cupID={cupID}
                          live
                          roundID={details.activeRoundID}
                        />
                      );
                      setShow(true);
                    }}
                  />
                )}
                {!disable && (
                  <Pill
                    text={"Edit Matches"}
                    onClick={() => {
                      setToShow(
                        <CupMatchups
                          showBack
                          saveMatches={saveLiveMatches}
                          teams={details.teams}
                          teamOneColor={details.teamOneColor}
                          teamTwoColor={details.teamTwoColor}
                          goBack={() => setShow(false)}
                          defaultMatches={details.matches}
                          format={details.format}
                        />
                      );
                      setShow(true);
                    }}
                  />
                )}
                {manComp && !disable && (
                  <Pill
                    text={"Complete Round"}
                    onClick={() => {
                      setShowMC(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="edb-box">
            <div className="ed-boxes">
              {!timesOpen && (
                <div className={leadOpen ? "ed-box ed-box-open" : "ed-box"}>
                  <div className="sub-header mb-20">
                    <h5>Leaderboard</h5>

                    <OpenInFullIcon
                      className="icon-cursor dg-icon hgb-ico"
                      onClick={() => setLeadOpen((current) => !current)}
                    />
                  </div>
                  <div className="ed-lb">
                    {!loading && (
                      <Leaderboard
                        eventDetails={details}
                        cupID={cupID}
                        roundID={details.activeRoundID}
                        uid={currentUser.uid}
                      />
                    )}
                  </div>
                </div>
              )}
              {!leadOpen && !bbLeaderboard && (
                <div className={timesOpen ? "ed-box ed-box-open" : "ed-box"}>
                  <div className="sub-header mb-20">
                    <h5>Tee Times</h5>
                    <div className="ed-box-icons">
                      <OpenInFullIcon
                        className="icon-cursor dg-icon hgb-ico"
                        onClick={() => setTimesOpen((current) => !current)}
                      />
                    </div>
                  </div>
                  {/* <div className="ed-lb"> */}
                  {!loading && (
                    <EventDashTeeTimes
                      // eventID={cupID}
                      roundID={details.activeRoundID}
                    />
                  )}
                  {/* </div> */}
                </div>
              )}
              {!leadOpen && bbLeaderboard && (
                <div className={timesOpen ? "ed-box ed-box-open" : "ed-box"}>
                  <div className="sub-header mb-20">
                    <h5>Better Ball</h5>
                    <div className="ed-box-icons">
                      <OpenInFullIcon
                        className="icon-cursor dg-icon"
                        onClick={() => setTimesOpen((current) => !current)}
                      />
                    </div>
                  </div>
                  {/* <div className="ed-lb"> */}
                  {!loading && (
                    <TeamLeaderboard
                      // eventID={cupID}
                      roundID={details.activeRoundID}
                    />
                  )}
                  {/* </div> */}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CupDashboard;
