/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./HoleSelector.css";
import PageHeader from "../../display/Rows/PageHeader";

function HoleSelector({ selected = [], setSelected = () => {}, goBack }) {
  const holes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

  const [update, setUpdate] = useState(0);

  // const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [selState, setSelState] = useState(selected);

  const selRef = useRef();
  selRef.current = selState;

  useEffect(() => {
    console.log(selState);
    setUpdate((current) => current + 1);
  }, [selRef]);

  return (
    <div className="hole-selector">
      <div
        onClick={() => {
          const newArr = [];
          selState.forEach((hole) => {
            if (!newArr.includes(hole)) {
              newArr.push(hole);
            }
          });
          setSelected(newArr);
          goBack();
        }}
        className="back-row"
      >
        <ArrowBackIcon className="ts-arrow" />
        <p>Back</p>
      </div>

      <PageHeader text="Select Playoff Holes (max 3)" />

      <div className="ph-container">
        <div className="playoff-holes">
          {holes.map((hole) => {
            let includes = false;
            if (selRef.current.includes(hole)) {
              includes = true;
            }
            return (
              <div
                onClick={() => {
                  setSelState((current) => {
                    const newArr = current;

                    if (includes) {
                      const veryNewArr = newArr.filter((item) => {
                        return item !== hole;
                      });
                      const vvnewArr = [];
                      veryNewArr.forEach((hole) => {
                        if (!vvnewArr.includes(hole)) {
                          vvnewArr.push(hole);
                        }
                      });
                      const holeShitNew = veryNewArr.filter(
                        (v, i, a) => a.indexOf(v) === i
                      );
                      return holeShitNew;
                    } else {
                      if (newArr.length > 2) {
                        return newArr;
                      }
                      newArr.push(hole);
                    }
                    const holeShitNew = newArr.filter(
                      (v, i, a) => a.indexOf(v) === i
                    );
                    return holeShitNew;
                  });
                  setUpdate((current) => current + 1);
                  // forceUpdate();
                  // console.log(selRef.current);
                }}
                className={"playoff-hole"}
                key={hole}
              >
                <div className={includes ? "ph-inner ph-active" : "ph-inner"}>
                  <p>{hole}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HoleSelector;
