import React, { useState, useEffect } from "react";
import "./ClientDrills.css";
// Icons

// Components
import CPLoader from "../CPLoader";
import DrillItem from "../Drills/DrillItem";
import { Dialog } from "@mui/material";
import { db, admin } from "../../../firebase";
import PageHeader from "../../display/Rows/PageHeader";
import { toast } from "react-toastify";
import { successIco } from "../HelperFunctions";

function ClientDrillLoader({
  clubID = "",
  clientID = "",
  close = () => {},
  clientDrillIDs = [],
  reload = () => {},
}) {
  const [loading, setLoading] = useState(true);
  const [drills, setDrills] = useState();
  const [selDrill, setSelDrill] = useState("");
  const [added, setAdded] = useState(false);

  useEffect(() => {
    console.log(clubID);
    loadDrills();
  }, []);

  async function loadDrills() {
    const academyDrills = await db
      .collection("clubs")
      .doc(clubID)
      .collection("drills")
      //   .orderBy("attempts.length")
      .get();

    const arr = [];

    for (let i = 0; i < academyDrills.docs.length; i++) {
      const drillDoc = academyDrills.docs[i];
      const drill = drillDoc.data();
      const drillID = drillDoc.id;
      if (!clientDrillIDs.includes(drillID)) {
        arr.push(drill);
      }
    }
    setDrills(arr);
    setLoading(false);
  }

  async function addDrill() {
    setLoading(true);
    try {
      const userDoc = await db.collection("users").doc(clientID).get();
      const user = userDoc.data();
      const drillIDs = user.drillIDs ?? {
        [`${clubID}`]: [],
      };

      drillIDs[clubID].push(selDrill.drillID);
      // setLoading(false);
      // return console.log(drillIDs);

      await userDoc.ref.update({
        drillIDs,
      });

      setAdded(true);
      toast.success("Drill successfully added to player's profile", {
        icon: successIco,
      });
      reload();
      close();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  return (
    <div className="cd-loader">
      <Dialog className="cdl-dia" open onClose={close}>
        <div className="cdl-ctt pd-20">
          <PageHeader text="Add Drill" close={close} showClose />
          <div className="ph-msg">
            <p>Add a drill from your libray to this player's selection</p>
          </div>
          {loading ? (
            <CPLoader />
          ) : added ? (
            <>
              <div className="ri-submitted">
                <div className="neh-box">
                  <h2 className="ne-header">Successfully added</h2>
                </div>
                <div className="ri-sub-text">
                  <p>
                    You will now be able to add attempts for this drill to this
                    client's profile
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <div className="neh-box">
                <h2 className="ne-header">Add Drill</h2>
              </div> */}

              {selDrill !== "" && (
                <div className="cdl-but">
                  <button
                    onClick={addDrill}
                    className="default-button shadow-md med-btn"
                  >
                    Add
                  </button>
                </div>
              )}

              <div className="flex flex-wrap pd-20">
                {drills.map((drill, i) => {
                  return (
                    <DrillItem
                      key={drill.drillID}
                      hideVert
                      cursor
                      academyID={clubID}
                      drill={drill}
                      selected={selDrill && selDrill.drillID === drill.drillID}
                      handleClick={() => setSelDrill(drill)}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default ClientDrillLoader;
