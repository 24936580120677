import React, { useEffect, useState, useCallback, useRef } from "react";
import { db, admin, storage } from "../../firebase";

// Icons
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";

// Components
import BackRow from "../display/Rows/BackRow";
import BackdropLoader from "../Loaders/BackdropLoader";
import PageHeader from "../display/Rows/PageHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import Pill from "../Buttons/Pill";
import ImageSelector from "../Events/ImageSelector/ImageSelector";
import { useDropzone } from "react-dropzone";
import { Dialog } from "@mui/material";
function TeamSeasonEditor({
  goBack = () => {},
  leagueID = "",
  seasonID = "",
  reload = () => {},
  edit = false,
  defaultLeagueName = "",
  seasons = [],
  defaultSeasonIndex,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [imgageURL, setImageURL] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [seasonIDs, setSeasonIDs] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [eventIDs, setEventIDs] = useState([]);
  const [started, setStarted] = useState(false);
  const [seasonName, setSeasonName] = useState("");
  const [trophyName, setTrophyName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [show, setShow] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [hintText, setHintText] = useState("");
  const picScrollRef = useRef();
  const [currentSeasonID, setCurrentSeasonID] = useState("");
  const [leagueName, setLeagueName] = useState(defaultLeagueName);
  const [seasonIndex, setSeasonIndex] = useState(defaultSeasonIndex);

  useEffect(() => {
    if (edit) {
      loadSeason();
    } else {
      setLoading(false);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  async function loadSeason() {
    setLoading(true);
    const seasonDoc = await db.collection("seasons").doc(seasonID).get();
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const season = seasonDoc.data();
    const league = leagueDoc.data();
    const activeSeason = league.seasonID;
    const { seasonIDs } = league;
    const {
      imageLink,
      completed,
      eventIDs,
      startDate,
      started,
      trophyName,
      seasonName,
    } = season;
    setEventIDs(eventIDs);
    setSeasonName(seasonName);
    setLeagueName(leagueName);
    setImageURL(imageLink);
    setSeasonIDs(seasonIDs);
    setCompleted(completed);
    console.log(startDate);
    const dateObj = new Date(startDate.toDate());
    const dateString = dateObj.toISOString().split("T")[0];
    setStartDate(dateString);
    setCurrentSeasonID(activeSeason);
    setTrophyName(trophyName);
    setStarted(started);
    setLoading(false);
  }

  async function validate() {
    setError("");
    const startDateObj = new Date(startDate);
    const startDateString = startDate;
    setLoading(true);
    const now = moment();
    const sdMomenbt = moment(startDateObj);

    // Name
    if (seasonName === "") {
      setError("Please enter a name for the upcoming season");
      return false;
    }

    // Date
    if (!started && startDateString === "") {
      setError("Please select a starting date for this season");
      return false;
    }

    if (!started && sdMomenbt.isBefore(now)) {
      setError("Please select a starting date in the future for this season");
      return false;
    }

    for (let i = 0; i < seasons.length; i++) {
      let found = false;
      const seasonDate = moment(seasons[i].date);
      if (seasonID === seasons[i].seasonID) {
        found = true;
        continue;
      }
      if (found) {
        // Seasons after current
        if (sdMomenbt.isAfter(seasonDate)) {
          setError("This season cannot start after the following season");
          return false;
        }
      } else {
        // Season before
        if (sdMomenbt.isBefore(seasonDate)) {
          setError("This season cannot start before after the previous season");
          return false;
        }
      }
    }

    // Images
    if (image !== "") {
      // return console.log(image.type);
      if (image.type) {
        if (!image.type.includes("image")) {
          setError("Please make sure to only upload images");
          return false;
        }
      }
    }
  }

  async function saveSeason() {
    // setLoading(true);
    if ((await validate()) === false) {
      return setLoading(false);
    }
    // return setLoading(false);
    const year = new Date().getFullYear();

    const season = await db.collection("seasons").add({
      leagueID,
      trophyName,
      leagueName,
      completed: false,
      year,
      dateCreated: new Date(),
      seasonName,
      startDate: new Date(startDate),
      imageLink: "",
      seasonIndex,
      eventIDs: [],
      activeEventID: "",
      activeRoundID: "",
      started: false,
      teamTable: [],
      pointsTable: [],
      teamLeague: true,
    });

    const seasonID = season.id;
    if (currentSeasonID === "") {
      await db
        .collection("teamLeagues")
        .doc(leagueID)
        .update({
          seasonIDs: admin.firestore.FieldValue.arrayUnion(season.id),
        });
    } else {
      await db
        .collection("teamLeagues")
        .doc(leagueID)
        .update({
          seasonIDs: admin.firestore.FieldValue.arrayUnion(season.id),
        });
    }

    if (image !== "") {
      if (typeof image === "string") {
        fetch(image).then(async (response) => {
          const contentType = response.headers.get("content-type");
          const blob = await response.blob();
          const file = new File([blob], "cupImage.jpg", { contentType });
          // access file here
          const task = storage
            .ref()
            .child(`${seasonID}/${image.name}`)
            .put(file);

          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("seasons").doc(seasonID).update({
                imageLink: url,
              });
            });
          });
        });
      } else {
        const task = storage
          .ref()
          .child(`${seasonID}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            db.collection("seasons").doc(seasonID).update({
              imageLink: url,
            });
          });
        });
      }
    }

    setLoading(false);
    reload();
    goBack();
  }

  async function saveChanges() {
    // return console.log(image);
    if ((await validate()) === false) {
      return setLoading(false);
    }

    // return setLoading(false);

    await db.collection("seasons").doc(seasonID).update({
      seasonName,
      startDate,
      trophyName,
    });
    if (currentSeasonID === seasonID) {
      await db.collection("teamLeagues").doc(leagueID).update({
        seasonName,
      });
    }

    if (image !== "") {
      if (typeof image === "string") {
        alert("string");
        fetch(image).then(async (response) => {
          const contentType = response.headers.get("content-type");
          const blob = await response.blob();
          const file = new File([blob], "cupImage.jpg", { contentType });
          // access file here
          const task = storage
            .ref()
            .child(`${seasonID}/${image.name}`)
            .put(file);

          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              console.log(url, seasonID);
              db.collection("seasons").doc(seasonID).update({
                imageLink: url,
              });
            });
          });
        });
      } else {
        const task = storage
          .ref()
          .child(`${seasonID}/${image.name}`)
          .put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            db.collection("seasons").doc(seasonID).update({
              imageLink: url,
            });
          });
        });
      }
    }

    setLoading(false);
    reload();
    goBack();
  }

  async function endSeason() {
    setShow(false);
    setLoading(true);
    await db.collection("seasons").doc(seasonID).update({
      completed: true,
    });
    const latestSeason = seasonIDs[seasonIDs.length - 1];
    const currentIndex = seasonIDs.indexOf(seasonID);
    if (latestSeason === seasonID) {
      await db.collection("teamLeagues").doc(leagueID).update({
        seasonID: "",
      });
    } else {
      await db
        .collection("teamLeagues")
        .doc(leagueID)
        .update({
          seasonID: seasonIDs[currentIndex + 1],
        });
    }
    setLoading(false);
    reload();
    goBack();
  }

  async function deleteSeason() {
    setLoading(true);
    await db.collection("seasons").doc(seasonID).delete();
    await db
      .collection("teamLeagues")
      .doc(leagueID)
      .update({
        seasonIDs: admin.firestore.FieldValue.arrayRemove(seasonID),
      });
    setLoading(false);
    reload();
    goBack();
  }

  return (
    <div className="season-editor team-season-editor onboarding">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {loading && <BackdropLoader />}

      {showImages ? (
        <ImageSelector
          goBack={() => {
            setShowImages(false);
            setTimeout(
              () => picScrollRef.current.scrollIntoView({ behavior: "smooth" }),
              100
            );
          }}
          setSelectedImage={(e) => {
            setImageURL(e);
            setImage(e);
          }}
          selectedImage={image}
          type="trophy"
        />
      ) : (
        <>
          <BackRow action={goBack} />
          <PageHeader text="Season Manager" />
          <Dialog open={show} onClose={() => setShow(false)}>
            <PageHeader
              text="End Season"
              showClose
              close={() => setShow(false)}
            />
            <div className="info-message">
              <InfoIcon
                className={`dg-icon-bg big-info-icon`}
                sx={{ fill: "url(#linearColors)" }}
              />
              <p>
                Are you sure you want to end the season? This cannot be undone
              </p>
              <div className="flex-center mt-40">
                <button className="default-button" onClick={endSeason}>
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>
          <div className="sub-header">
            <h5>General</h5>
          </div>

          <div className="ec-box lc-box">
            <div className="input-group mt-0">
              <p>Season Name</p>
              <input
                type="text"
                className="default-input"
                onChange={(e) => setSeasonName(e.target.value)}
                placeholder="Season name"
                value={seasonName}
              />
            </div>
            <div className="input-group mt-0">
              <p>Trophy Name</p>
              <input
                type="text"
                className="default-input"
                onChange={(e) => setTrophyName(e.target.value)}
                placeholder="Trophy Name"
                value={trophyName}
              />
            </div>

            <div className="input-group  mt-0 date-hint">
              <p>Start Date</p>
              <input
                disabled={started}
                type="date"
                className="default-input"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </div>
          </div>
          <div
            ref={picScrollRef}
            style={{ padding: "0px 20px" }}
            className="input-group mb-20"
          >
            <p>Season Picture</p>
            <div className="image-buttons">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-sel">
                  {imgageURL !== "" && (
                    <div className="event-img mt-0">
                      <img className="" alt="profile" src={imgageURL} />
                    </div>
                  )}
                  {imgageURL === "" && (
                    <h5>
                      Drag 'n' drop an image here, or click to open up the file
                      explorer
                    </h5>
                  )}
                </div>
                <div className="dz-or">
                  <p className="">Or</p>
                </div>
              </div>
              <Pill
                text={"Choose Preset Image"}
                onClick={() => setShowImages(true)}
              />
              {/* <div className="ec-is" style={{ marginLeft: "0px" }}>
                    <button className="" onClick={() => setShowImages(true)}>
                      Choose Preset Image
                    </button>
                  </div> */}
            </div>
          </div>
          <div className="default-button-row">
            {edit ? (
              <button className="default-button mr-20" onClick={saveChanges}>
                Save Changes
              </button>
            ) : (
              <button className="default-button mr-20" onClick={saveSeason}>
                Create Season{" "}
              </button>
            )}

            {edit && !completed && (
              <>
                {eventIDs.length > 0 ? (
                  <button
                    className="delete-button"
                    onClick={() => setShow(true)}
                  >
                    End Season
                  </button>
                ) : (
                  <button
                    className="delete-button"
                    onClick={() => {
                      deleteSeason();
                    }}
                  >
                    Delete Season
                  </button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default TeamSeasonEditor;
