import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { Dialog } from "@mui/material";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// Mui
import EditIcon from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NewsPill from "./NewsPill";
import MatchplayScore from "../../components/display/MatchplayScore/MatchplayScore";
import MatchplayGroupTables from "./MatchplayGroupTables";
import PageHeader from "../../components/display/Rows/PageHeader";
// Gradient Icons
const GradientEditIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <EditIcon
      onClick={onClick}
      className={`${className} dg-icon`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function MatchplayGroups({
  tournID = "",
  goBack = () => {},
  players = [],
  edit = true,
  defaultGroups = [],
  display = false,
  defaultGroupSize = 4,
  defaultProgression = 2,
  reload = () => {},
  pointsType = "normal",
  doubles = false,
  defaultKnockout = [],
  teams = [],
  playerResults = {},
}) {
  const [groups, setGroups] = useState(defaultGroups);
  const [loading, setLoading] = useState(true);
  const [groupSize, setGroupSize] = useState(defaultGroupSize);
  const [progression, setProgression] = useState(defaultProgression);
  const [knockout, setKnockout] = useState(defaultKnockout);
  const [update, setUpdate] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [toSwap, setToSwap] = useState();
  const [selSwap, setSelSwap] = useState();
  const [fromGroup, setFromGroup] = useState();

  const [filtered, setFiltered] = useState([]);

  const [selected, setSelected] = useState("group");

  const [selGroup, setSelGroup] = useState();

  const [groupOptions, setGroupOptions] = useState([]);

  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (toSwap && selGroup) {
      // console.log(selGroup);
      const intGroup = parseInt(selGroup);
      const toFilter = groups[intGroup].players;
      for (let i = 0; i < toFilter.length; i++) {
        if (doubles) {
          toFilter[i].value = toFilter[i].teamID;
        } else {
          toFilter[i].value = toFilter[i].playerID;
        }
        toFilter[i].label = toFilter[i].name;
      }
      console.log(toFilter);

      setFiltered(toFilter);
      setSelSwap();
    }
  }, [selGroup]);

  useEffect(() => {
    console.log(defaultGroups);
    // sortGroups();

    if (defaultGroups.length === 0) {
      if (doubles) {
        sortTeamGroups();
      } else {
        sortGroups();
      }
    } else {
      // console.log(defaultGroups);
      const grarr = [];

      for (let i = 0; i < defaultGroups.length; i++) {
        grarr.push({
          label: `Group ${i + 1}`,
          value: `${i}`,
        });
      }

      // console.log(grarr);

      setGroupOptions(grarr);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (rendered) {
      if (doubles) {
        sortTeamGroups();
      } else {
        sortGroups();
      }
    } else {
      setRendered(true);
    }
  }, [groupSize, progression]);

  function shuffle(array) {
    // alert("everyday");
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  async function saveChanges() {
    setLoading(true);
    await db.collection("tournaments").doc(tournID).update({
      groups,
      knockout,
    });
    reload();
    setLoading(false);
    goBack();
  }

  async function sortTeamGroups() {
    const arr = [];
    const groupAmount = Math.ceil(teams.length / groupSize);
    for (let i = 0; i < groupAmount; i++) {
      arr.push({
        players: [],
      });
    }

    const shuffled = shuffle(teams);
    for (let i = 0; i < shuffled.length; i++) {
      shuffled[i].played = 0;
      shuffled[i].wins = 0;
      shuffled[i].points = 0;
      shuffled[i].ties = 0;
    }

    for (let i = 0; i < shuffled.length; i++) {
      let found = false;

      for (let x = 0; x < arr.length; x++) {
        if (found) {
          continue;
        }
        if (arr[x].players.length < groupSize) {
          arr[x].players.push(shuffled[i]);
          found = true;
        }
      }
    }

    setGroups(arr);

    const progressing = (shuffled.length / groupSize) * progression;
    const brackArr = [];
    const rounded = Math.ceil(progressing / shuffled.length) * shuffled.length;
    let rounds = Math.log2(rounded) - 1;

    if (progressing === 2) {
      rounds = 1;
    }

    let mi = 1;

    for (let i = 0; i < rounds; i++) {
      const temp = {
        matches: [],
      };

      let div = progressing;
      for (let j = 0; j < i + 1; j++) {
        div = div / 2;
      }
      let matches = div;
      if (matches > 2 && matches % 4 !== 0) {
        matches = Math.ceil(matches / 4) * 4;
      }
      for (let x = 0; x < matches; x++) {
        temp.matches.push({
          match: "",
          players: [],
          winnerID: "",
          winnerName: "",
          by: "",
          matchIndex: mi,
          qualis: [],
        });
        mi += 1;
      }
      brackArr.push(temp);
    }
    console.log(brackArr);
    let progArr = [];

    for (let i = 0; i < shuffled.length / groupSize; i++) {
      for (let x = 0; x < progression; x++) {
        if (x === 0) {
          progArr.push({
            name: `Winner Group ${i + 1}`,
            group: i,
            position: x,
          });
        } else {
          progArr.push({
            name: `Runner Up Group ${Math.abs(groups.length - i)}`,
            group: Math.abs(groups.length - i) - 1,
            position: x,
          });
        }
      }
    }

    const remaining =
      brackArr.length > 0 ? brackArr[0].matches.length * 2 - progArr.length : 0;

    if (remaining > 0) {
      for (let i = 0; i < remaining; i++) {
        progArr.push({ name: "BYE" });
      }
      progArr = shuffle(progArr);
    }

    let counter = 0;

    for (let i = 0; i < brackArr.length; i++) {
      const roundMatches = brackArr[i].matches;
      for (let x = 0; x < roundMatches.length; x++) {
        if (i === 0) {
          brackArr[i].matches[x].players.push(progArr[2 * counter]);
          brackArr[i].matches[x].players.push(progArr[2 * counter + 1]);
        } else if (i === brackArr.length - 1) {
          brackArr[i].matches[x].players.push({
            name: `Winner Semi-Final 1`,
          });
          brackArr[i].matches[x].players.push({
            name: `Winner Semi-Final 2`,
          });
        } else {
          brackArr[i].matches[x].players.push({
            name: `Winner Match`,
          });
          brackArr[i].matches[x].players.push({
            name: `Winner Match`,
          });
        }
        if (i !== 0) {
          const prevRoundMatches = brackArr[i - 1].matches;
          const matchNums = prevRoundMatches.map((match) => match.matchIndex);
          let cnt1 = x * 2;
          brackArr[i].matches[x].qualis.push(matchNums[cnt1]);
          brackArr[i].matches[x].qualis.push(matchNums[2 * x + 1]);
        }
        counter += 1;
      }
    }
    brackArr.reverse();
    for (let i = 0; i < brackArr.length; i++) {
      for (let j = 0; j < brackArr[i].matches.length; j++) {
        if (i === 0) {
          brackArr[i].matches[j].match = "Final";
        }
        if (i === 1) {
          brackArr[i].matches[j].match = "Semi-Final";
        }
        if (i === 2) {
          brackArr[i].matches[j].match = "Quarter-Final";
        }
        if (i === 3) {
          brackArr[i].matches[j].match = "Round of 16";
        }
        if (i === 4) {
          brackArr[i].matches[j].match = "Round of 32";
        }
      }
    }
    brackArr.reverse();

    const grarr = [];

    for (let i = 0; i < arr.length; i++) {
      grarr.push({
        label: `Group ${i + 1}`,
        value: `${i}`,
      });
    }

    setGroupOptions(grarr);

    setKnockout(brackArr);
    setLoading(false);
  }

  async function sortGroups() {
    setLoading(true);
    const arr = [];
    let groupAmount = Math.ceil(players.length / groupSize);
    if (defaultGroups.length > 0) {
      groupAmount = defaultGroups.length;
    }
    if (players.length === 0) {
      return setLoading(false);
    }
    for (let i = 0; i < groupAmount; i++) {
      arr.push({
        players: [],
      });
    }

    const shuffled = shuffle(players);

    for (let i = 0; i < shuffled.length; i++) {
      shuffled[i].played = 0;
      shuffled[i].wins = 0;
      shuffled[i].points = 0;
      shuffled[i].ties = 0;
    }

    for (let i = 0; i < shuffled.length; i++) {
      let found = false;

      for (let x = 0; x < arr.length; x++) {
        if (found) {
          continue;
        }
        if (arr[x].players.length < groupSize) {
          arr[x].players.push(shuffled[i]);
          found = true;
        }
      }
    }
    // console.log(arr);
    if (defaultGroups.length === 0) {
      setGroups(arr);
    }

    let mi = 1;

    // Knockout
    const progressing = groupAmount * progression;
    console.log(groupAmount, progression);
    console.log(progressing);
    const brackArr = [];
    const rounded = Math.ceil(progressing / shuffled.length) * shuffled.length;
    let rounds = Math.log2(rounded) - 1;

    if (progressing === 2) {
      rounds = 1;
    }

    for (let i = 0; i < rounds; i++) {
      const temp = {
        matches: [],
      };

      let div = progressing;
      for (let j = 0; j < i + 1; j++) {
        div = div / 2;
      }
      let matches = div;
      if (matches > 2 && matches % 4 !== 0) {
        matches = Math.ceil(matches / 4) * 4;
      }
      for (let x = 0; x < matches; x++) {
        temp.matches.push({
          match: "",
          players: [],
          winnerID: "",
          winnerName: "",
          by: "",
          matchIndex: mi,
          qualis: [],
        });
        mi += 1;
      }
      brackArr.push(temp);
    }

    let progArr = [];

    for (let i = 0; i < shuffled.length / groupSize; i++) {
      for (let x = 0; x < progression; x++) {
        if (x === 0) {
          progArr.push({
            name: `Winner Group ${i + 1}`,
            group: i,
            position: x,
          });
        } else {
          if (i % 2 === 0) {
            progArr.push({
              name: `Runner Up Group ${i + 1 + 1}`,
              group: i + 1,
              position: x,
            });
          } else {
            progArr.push({
              name: `Runner Up Group ${i}`,
              group: i - 1,
              position: x,
            });
          }
        }
      }
    }

    const remaining = brackArr[0].matches.length * 2 - progArr.length;

    if (remaining > 0) {
      for (let i = 0; i < remaining; i++) {
        progArr.push({ name: "BYE" });
      }
      progArr = shuffle(progArr);
    }

    let counter = 0;

    for (let i = 0; i < brackArr.length; i++) {
      const roundMatches = brackArr[i].matches;
      for (let x = 0; x < roundMatches.length; x++) {
        if (i === 0) {
          brackArr[i].matches[x].players.push(progArr[2 * counter]);
          brackArr[i].matches[x].players.push(progArr[2 * counter + 1]);
        } else if (i === brackArr.length - 1) {
          let q1 = "";
          let q2 = "";
          if (i !== 0) {
            const prevRoundMatches = brackArr[i - 1].matches;
            const matchNums = prevRoundMatches.map((match) => match.matchIndex);
            let cnt1 = x * 2;
            brackArr[i].matches[x].qualis.push(matchNums[cnt1]);

            brackArr[i].matches[x].qualis.push(matchNums[2 * x + 1]);
            q1 = matchNums[cnt1];
            q2 = matchNums[2 * x + 1];
          }
          brackArr[i].matches[x].players.push({
            name: `Winner Semi-Final 1`,
          });
          brackArr[i].matches[x].players.push({
            name: `Winner Semi-Final 2`,
          });
        } else {
          brackArr[i].matches[x].players.push({
            name: `Winner Match`,
          });
          brackArr[i].matches[x].players.push({
            name: `Winner Match`,
          });
        }

        counter += 1;
      }
    }
    brackArr.reverse();
    for (let i = 0; i < brackArr.length; i++) {
      for (let j = 0; j < brackArr[i].matches.length; j++) {
        if (i === 0) {
          brackArr[i].matches[j].match = "Final";
        }
        if (i === 1) {
          brackArr[i].matches[j].match = "Semi-Final";
        }
        if (i === 2) {
          brackArr[i].matches[j].match = "Quarter-Final";
        }
        if (i === 3) {
          brackArr[i].matches[j].match = "Round of 16";
        }
        if (i === 4) {
          brackArr[i].matches[j].match = "Round of 32";
        }
      }
    }
    brackArr.reverse();

    const grarr = [];

    for (let i = 0; i < arr.length; i++) {
      grarr.push({
        label: `Group ${i + 1}`,
        value: `${i}`,
      });
    }

    console.log(brackArr);

    setGroupOptions(grarr);

    setKnockout(brackArr);
    setLoading(false);
  }

  function confirmSwap() {
    // return console.log(groups);
    setGroups((current) => {
      let swapDetails;
      const from = parseInt(fromGroup);
      const to = parseInt(selGroup);

      const arr = [...current];
      console.log(selSwap);
      for (let i = 0; i < arr[to].players.length; i++) {
        console.log(arr[to].players[i].playerID === selSwap);
        if (arr[to].players[i].playerID === selSwap) {
          console.log("equals");
          swapDetails = arr[to].players[i];
        }
      }

      console.log(swapDetails);

      // console.log(arr[from].players);
      // console.log(arr[to].players);

      arr[from].players = arr[from].players.filter((player) => {
        // console.log(player.playerID === toSwap.playerID);
        return player.playerID !== toSwap.playerID;
      });
      arr[to].players = arr[to].players.filter((player) => {
        // console.log(player.playerID === selSwap);
        return player.playerID !== selSwap;
      });

      // let sliceOne;
      // let sliceTwo;

      // for (let i = 0; i < arr[from].players.length; i++) {
      //   if (arr[from].players[i].playerID === toSwap.playerID) {
      //     sliceOne = i;
      //   }
      // }

      // for (let i = 0; i < arr[to].players.length; i++) {
      //   if (arr[to].players[i].playerID === toSwap.playerID) {
      //     sliceTwo = i;
      //   }
      // }

      // arr[from].players.splice(sliceOne, 1);
      // arr[to].players.splice(sliceTwo, 1);

      arr[from].players.push(swapDetails);
      arr[to].players.push(toSwap);
      console.log(arr);
      return arr;
    });
    setFromGroup();
    setToSwap();
    setShowDialog(false);
  }

  function confirmTeamSwap() {
    // return console.log(groups);
    setGroups((current) => {
      let swapDetails;

      const from = parseInt(fromGroup);
      const to = parseInt(selGroup);

      const arr = [...current];

      for (let i = 0; i < arr[to].players.length; i++) {
        if (arr[to].players[i].teamID === selSwap) {
          swapDetails = arr[to].players[i];
        }
      }

      // console.log(arr[from].players);
      // console.log(arr[to].players);

      arr[from].players = arr[from].players.filter((player) => {
        // console.log(player.playerID === toSwap.playerID);
        return player.teamID !== toSwap.teamID;
      });
      arr[to].players = arr[to].players.filter((player) => {
        // console.log(player.playerID === selSwap);
        return player.teamID !== selSwap;
      });

      console.log(arr);
      console.log(swapDetails, toSwap);
      let sliceOne;
      let sliceTwo;

      // for (let i = 0; i < arr[from].players.length; i++) {
      //   if (arr[from].players[i].teamID === toSwap.teamID) {
      //     sliceOne = i;
      //   }
      // }

      // for (let i = 0; i < arr[to].players.length; i++) {
      //   if (arr[to].players[i].teamID === toSwap.teamID) {
      //     sliceTwo = i;
      //   }
      // }

      // arr[from].players.splice(sliceOne, 1);
      // arr[to].players.splice(sliceTwo, 1);

      arr[from].players.push(swapDetails);
      arr[to].players.push(toSwap);
      return arr;
    });
    setFromGroup();
    setToSwap();
    setShowDialog(false);
  }

  return (
    <div className="mpl-groups">
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="mpl-dia">
          {toSwap && (
            <div className="mpl-dia-player">
              {/* <p>Swapping</p> */}
              <PlayerItem
                hcp={toSwap.handicap}
                img={toSwap.profileImage}
                name={toSwap.name}
                hideIcons
                hideHCP={doubles}
                noLink
                showProfile={() => {}}
              />
            </div>
          )}
          <div className="input-group pp-hint mb-20">
            <div className="ig-header">
              <p>New Group</p>
            </div>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={selGroup}
              options={groupOptions}
              onChange={(e) => {
                setSelGroup(e.value);
              }}
            />
          </div>
          <div className="input-group pp-hint mb-20">
            <div className="ig-header">
              <p>Swap With</p>
            </div>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={selSwap}
              options={filtered}
              onChange={(e) => {
                console.log(e);
                setSelSwap(e.value);
              }}
            />
          </div>
          <div className="flex-center">
            <button
              onClick={doubles ? confirmTeamSwap : confirmSwap}
              className="default-button"
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      {/* {!display && ( */}
      <div onClick={() => goBack()} className="back-row">
        <ArrowBack className="" />
        <p>Back</p>
      </div>
      {/* )} */}

      <PageHeader text="Tournament Groups" />
      {display && (
        <div className="mpt-log-msg">
          <h3>Info</h3>
          <p>
            {pointsType === "normal"
              ? "10 points are awarded for a win, 5 points for a tie"
              : "5 points are awarded for every hole lead by the end of the match"}
          </p>
        </div>
      )}

      {!display && (
        <>
          <div className="mpg-changer">
            <p>Group Size</p>
            <div className="mpg-right">
              <div className="info-circle">
                <RemoveCircleIcon
                  onClick={() =>
                    setGroupSize((current) => {
                      if (current === 2) {
                        return current;
                      } else {
                        return current - 1;
                      }
                    })
                  }
                  className="ic-icon"
                />
              </div>
              <h5>{groupSize}</h5>

              <div className="info-circle">
                <AddCircleIcon
                  onClick={() => {
                    setGroupSize((current) => {
                      if (players.length / 2 <= current) {
                        return current;
                      } else {
                        return current + 1;
                      }
                    });
                  }}
                  className="ic-icon"
                />
              </div>
            </div>
          </div>
          <div className="mpg-changer">
            <p>Progression</p>
            <div className="mpg-right">
              <div className="info-circle">
                <RemoveCircleIcon
                  onClick={() =>
                    setProgression((current) => {
                      if (current === 1) {
                        return current;
                      } else {
                        return current - 1;
                      }
                    })
                  }
                  className="ic-icon"
                />
              </div>
              <h5>{progression}</h5>

              <div className="info-circle">
                <AddCircleIcon
                  onClick={() => {
                    setProgression((current) => {
                      if (current === 2) {
                        return current + 1;
                      } else {
                        return current + 1;
                      }
                    });
                  }}
                  className="ic-icon"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {!display && (
        <div className="input-group pl-20">
          <p
            onClick={() => {
              if (doubles) {
                sortTeamGroups();
              } else {
                sortGroups();
              }
            }}
            className="green-link shuffle-players"
          >
            Shuffle
          </p>
        </div>
      )}
      {!display && (
        <div className="input-group pl-20 mb-20">
          <p
            onClick={() => {
              setGroups([]);
              setUpdate((c) => c + 1);
            }}
            className="red-link shuffle-players"
          >
            Clear Groups
          </p>
        </div>
      )}
      <div className="mpg-pills">
        <NewsPill
          text={"Group Stages"}
          active={selected === "group"}
          handleClick={() => setSelected("group")}
        />
        <NewsPill
          text={"Knockout Phase"}
          active={selected === "knockout"}
          handleClick={() => setSelected("knockout")}
        />
      </div>

      {selected === "group" && (
        <>
          {!display && (
            <>
              <div className={display ? "mplg-box mt-20" : "mplg-box"}>
                {groups.map((group, index) => {
                  const players = group.players;
                  return (
                    <div key={`${index}groups`} className="mt-20 mpgs">
                      <div className="group-headef">
                        <h2>Group {index + 1}</h2>
                      </div>
                      <div className="mpg-group">
                        {players.map((player) => (
                          <div key={player.playerID} className="mpg-player">
                            <PlayerItem
                              name={player.name}
                              hcp={player.handicap}
                              img={player.profileImage}
                              showProfile={() => {}}
                              hideMessage
                              hideProfile
                              hideRemove
                              hideHCP={doubles}
                              showAdd
                              editAction={() => {
                                setSelGroup();
                                setFromGroup(index.toString());
                                setGroupOptions((current) => {
                                  const grarr = [];

                                  for (let i = 0; i < groups.length; i++) {
                                    if (i === index) {
                                      continue;
                                    }
                                    grarr.push({
                                      label: `Group ${i + 1}`,
                                      value: `${i}`,
                                    });
                                  }
                                  return grarr;
                                });
                                setToSwap(player);
                                setShowDialog(true);
                              }}
                            />
                            {false && (
                              <GradientEditIcon
                                onClick={() => {
                                  setSelGroup();
                                  setFromGroup(index.toString());
                                  setToSwap(player);
                                  setShowDialog(true);
                                }}
                                className={"mpg-icon"}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {display && (
            <MatchplayGroupTables
              playerResults={playerResults}
              groups={groups}
            />
          )}

          {!display && (
            <div style={{ marginBottom: "20px" }} className="flex-center">
              <button
                className="default-button mt-40 mb-40"
                disabled={groups.length === 0}
                onClick={() => {
                  saveChanges();
                }}
              >
                Save Groups
              </button>
            </div>
          )}
        </>
      )}

      {selected === "knockout" && (
        <>
          <div className="mpl-fixtures">
            {knockout.map((round, index) => {
              const roundMatches = round.matches;
              return (
                <React.Fragment key={`${index}knock`}>
                  <div className="mpl-round-header">
                    <h2>{round.matches[0].match}</h2>
                  </div>
                  <div className="mpl-matches">
                    {doubles &&
                      roundMatches.map((match, index2) => {
                        const p1 = match.players[0];
                        const p2 = match.players[1];
                        // console.log(match);
                        return (
                          <div key={`${index2}match`} className="mpb-match">
                            <PlayerItem
                              hideRemove
                              hideEdit
                              hcp={p1.handicap}
                              hideImage
                              showAdd
                              hideHCP={isNaN(p1.handicap) || doubles}
                              hideMessage
                              hideProfile
                              noLink
                              editAction={() => {}}
                              name={p1.name}
                            />
                            <div className="mpbm-circle">
                              {match.winnerID === "" ? (
                                <p>vs</p>
                              ) : match.by === "BYE" ? (
                                <p>BYE</p>
                              ) : (
                                <>
                                  {match.leader === "teamOne" ? (
                                    <p>
                                      {match.rem !== 0
                                        ? `${match.by} UP`
                                        : `${match.by} & ${match.rem}`}
                                    </p>
                                  ) : (
                                    <p>
                                      {match.rem !== 0
                                        ? `${match.by} DN`
                                        : `${match.by} & ${match.rem}`}
                                    </p>
                                  )}
                                </>
                              )}{" "}
                            </div>

                            <PlayerItem
                              hideRemove
                              hideEdit
                              hcp={p2.handicap}
                              hideImage
                              hideHCP={isNaN(p2.handicap) || doubles}
                              showAdd
                              hideMessage
                              hideProfile
                              noLink
                              name={p2.name}
                              editAction={() => {}}
                            />
                          </div>
                        );
                        return (
                          <MatchplayScore
                            hcpOne={p1.handicap}
                            hideThru
                            preMatch
                            hcpTwo={p2.handicap}
                            nameOne={p1.name}
                            nameTwo={p2.name}
                            handleClick={() => {}}
                          />
                        );
                      })}
                    {!doubles &&
                      roundMatches.map((match, index2) => {
                        const p1 = match.players[0];
                        const p2 = match.players[1];
                        // console.log(match);
                        return (
                          <div key={`${index2}match`} className="mpb-match">
                            <PlayerItem
                              hideRemove
                              hideEdit
                              hcp={p1.handicap}
                              hideImage
                              showAdd
                              hideHCP={isNaN(p1.handicap) || doubles}
                              hideMessage
                              hideProfile
                              noLink
                              editAction={() => {}}
                              name={p1.name}
                            />
                            {match.leader === "" ||
                            match.leader === undefined ? (
                              <p className="ml-10 mr-10">vs</p>
                            ) : match.by === "BYE" ? (
                              <p>BYE</p>
                            ) : (
                              <>
                                {match.leader === p1.playerID ? (
                                  <p>
                                    {match.rem !== 0
                                      ? `${match.by} UP`
                                      : `${match.by} & ${match.rem}`}
                                  </p>
                                ) : (
                                  <p>
                                    {match.rem !== 0
                                      ? `${match.by} DN`
                                      : `${match.by} & ${match.rem}`}
                                  </p>
                                )}
                              </>
                            )}

                            <PlayerItem
                              hideRemove
                              hideEdit
                              hcp={p2.handicap}
                              hideImage
                              hideHCP={isNaN(p2.handicap) || doubles}
                              showAdd
                              hideMessage
                              hideProfile
                              noLink
                              name={p2.name}
                              editAction={() => {}}
                            />
                          </div>
                        );
                        return (
                          <MatchplayScore
                            hcpOne={p1.handicap}
                            hideThru
                            preMatch
                            hcpTwo={p2.handicap}
                            nameOne={p1.name}
                            nameTwo={p2.name}
                            handleClick={() => {}}
                          />
                        );
                      })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default MatchplayGroups;
