import React, { useState, useEffect } from "react";
import "./SignUp.css";
import { db, admin } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import BackdropLoader from "../Loaders/BackdropLoader";

function SignUp({ goBack = () => {} }) {
  const { userDetails } = useAuth();

  const navigate = useNavigate();

  const generatePayfast = admin.functions().httpsCallable("payfastSignature");
  const generatePFSub = admin.functions().httpsCallable("payfastSubSignature");

  async function exchangeRate(amount) {
    try {
      const rate = await fetch(
        `https://api.exchangerate.host/convert?from=USD&to=ZAR&amount=${amount}`
      );
      const jsRate = await rate.json();
      console.log(jsRate.result);
      // console.log(Math.round(jsRate.result), typeof jsRate.result);
      const rounded = Math.round(jsRate.result);
      return rounded;
      // return Math.round(jsRate.result);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (userDetails) {
      setTrialUsed(userDetails.trialUsed);
      setLoading(false);
    }
  }, [userDetails]);

  // Admin
  const { currentUser } = useAuth();

  const [selected, setSelected] = useState("");

  const [trialUsed, setTrialUsed] = useState(false);
  const [codeResult, setCodeResult] = useState("");

  const [showCode, setShowCode] = useState(false);
  const [codeInput, setCodeInput] = useState("");
  const [loadingCode, setLoadingCode] = useState(false);
  const [codeFailed, setCodeFailed] = useState(false);
  const [codeSuccess, setCodeSuccess] = useState(false);

  const [showTrial, setShowTrial] = useState(false);
  const [loadingTrial, setLoadingTrial] = useState(false);
  const [trialFailed, setTrialFailed] = useState(false);
  const [trialSuccess, setTrialSuccess] = useState(false);

  const [formHTML, setFormHTML] = useState();
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);

  async function callPayfastSub() {
    setLoading(true);
    console.log(selected);
    const now = new Date();
    const value = "50";
    let exchtotal = await exchangeRate(5.99);

    // const total = "5";
    const { firstName, lastName, emailAddress } = userDetails;
    const formattedDate = now.toISOString().split("T")[0];
    let html = ``;

    let frequency = "";
    let initialAmount = "0";

    if (selected === "monthly") {
      console.log(exchtotal);
      frequency = "3";
    } else if (selected === "quarterly") {
      frequency = "4";
      exchtotal = await exchangeRate(13.99);
      initialAmount = exchtotal;
    } else {
      exchtotal = await exchangeRate(49.99);
      frequency = "6";
      initialAmount = exchtotal;
    }

    const total = exchtotal.toString();

    const htmlData = await generatePFSub({
      total: "5",
      firstName,
      lastName,
      frequency,
      emailAddress,
      initialAmount,
      userID: currentUser.uid,
      type: "subscription",
    });
    html += htmlData.data;
    console.log(html);
    setFormHTML(html);
    setShow(true);
    setLoading(false);
  }

  async function callPayfast() {
    // return console.log(userDetails);
    setLoading(true);
    const now = new Date();
    const exchtotal = await exchangeRate(80);
    const total = exchtotal.toString();
    const { firstName, lastName, emailAddress } = userDetails;
    const formattedDate = now.toISOString().split("T")[0];
    let html = ``;

    const htmlData = await generatePayfast({
      total,
      firstName,
      lastName,
      userID: currentUser.uid,
      emailAddress,
      type: "Once Off",
    });
    html += htmlData.data;
    console.log(html);
    setFormHTML(html);
    setShow(true);
    setLoading(false);
  }

  async function activateFree() {
    setLoading(true);
    const now = new Date();
    now.setMonth(now.getMonth() + 1);
    try {
      await db.collection("users").doc(currentUser.uid).update({
        portal: true,
        portalType: "free",
        trialUsed: true,
        portalExpiry: now,
      });
      setTrialUsed(true);
      setTrialSuccess(true);
    } catch (err) {
      console.log(err);
      setTrialFailed(true);
    }
    setLoading(false);
  }

  async function checkCode() {
    setLoading(true);
    const now = new Date();

    const codes = await db
      .collection("admin")
      .doc("premium")
      .collection("codes")
      .where("code", "==", codeInput)
      .get();

    if (codes.docs.length === 0) {
      setCodeResult("not-found");
      return setLoading(false);
    }

    const codeDoc = codes.docs[0];
    const codeData = codeDoc.data();
    const { users, used, limit, duration, expiry } = codeData;
    const dateObj = new Date(expiry.toDate());
    // Expired
    if (now > dateObj) {
      setCodeResult("expired");
      return setLoading(false);
    }
    // Not eligible
    if (users !== "") {
      if (!users.includes(currentUser.uid)) {
        setCodeResult("not-eligible");
        return setLoading(false);
      }
    }
    // Used up limit
    if (users.length === limit && limit !== 0) {
      setCodeResult("limit-reached");
      return setLoading(false);
    }
    // Used by user lready
    if (used.includes(currentUser.uid)) {
      setCodeResult("used-already");
      return setLoading(false);
    }

    alert("boob");

    const premiumExpiry = new Date();
    premiumExpiry.setMonth(premiumExpiry.getMonth() + duration);
    console.log(premiumExpiry);
    await db.collection("users").doc(currentUser.uid).update({
      portal: true,
      portalType: "code",
      portalExpiry: premiumExpiry,
    });
    await codeDoc.ref.update({
      used: admin.firestore.FieldValue.arrayUnion(currentUser.uid),
    });
    // setCodeResult("success");
    setCodeResult("");

    setCodeSuccess(true);
    return setLoading(false);
  }

  async function setFree() {
    setLoading(true);
    await db.collection("users").doc(currentUser.uid).update({
      portal: true,
      portalType: "free",
    });
    setTrialSuccess(true);
    setLoading(false);
  }

  return (
    <div className="sign-up">
      {loading && <BackdropLoader />}
      {showTrial ? (
        <>
          {trialSuccess ? (
            <>
              <div className="payfast-info">
                <div className="pi-back">
                  <div onClick={() => setShowTrial(false)} className="back-row">
                    <ArrowBack className="icon-cursor" />

                    <p>Back</p>
                  </div>
                </div>
                <h1>Success!</h1>
                <p>Your free account has been activated.</p>
                <div className="flex-center">
                  <button onClick={() => window.location.reload()}>
                    Continue
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="payfast-info">
                <div className="pi-back">
                  <div onClick={() => setShowTrial(false)} className="back-row">
                    <ArrowBack className="icon-cursor" />

                    <p>Back</p>
                  </div>
                </div>
                <h1>Free Trial</h1>
                <p>
                  All users receive 1 month of free access - activate yours
                  today
                </p>
              </div>
              <div className="su-free">
                <div className="su-confirm-row">
                  <button
                    onClick={() => {
                      activateFree();
                    }}
                    className="btn-20"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : codeResult !== "" ? (
        <>
          <div className="payfast-info">
            <div className="pi-back">
              <div onClick={() => setCodeResult("")} className="back-row">
                <ArrowBack className="icon-cursor" />

                <p>Back</p>
              </div>
            </div>
            <h1>Sorry!</h1>
            <p>
              {codeResult === ""
                ? "Enter your promotional code below to activate your premium account"
                : codeResult === "not-found"
                ? "Sorry - we could not find any promotions with that code"
                : codeResult === "not-eligibe"
                ? "Sorry! Your account is not eligible for this promotional code"
                : codeResult === "limit-reached"
                ? "Sorry! The limit for this promotional code has been reached"
                : codeResult === "expired"
                ? "Sorry! This promotional code has expired"
                : codeResult === "used-already"
                ? "Sorry! You have already used this promotional code"
                : codeResult === "success"
                ? "Success! Enjoy your premium subscription."
                : ""}
            </p>
          </div>
        </>
      ) : codeSuccess ? (
        <>
          <div className="payfast-info">
            <div className="pi-back">
              <div onClick={() => goBack()} className="back-row">
                <ArrowBack className="icon-cursor" />

                <p>Back</p>
              </div>
            </div>
            <h1>Success!</h1>
            <p>Your portal account has been activated.</p>
          </div>
        </>
      ) : showCode ? (
        <>
          <div className="payfast-info">
            <div className="pi-back">
              <div onClick={() => setShowCode(false)} className="back-row">
                <ArrowBack className="icon-cursor" />

                <p>Back</p>
              </div>
            </div>
            <h1>Access Codes</h1>
            <p>Please enter your code below to active your portal account</p>
          </div>
          <div className="pi-codes">
            <div className="su-free">
              <input
                type="text"
                placeholder="Enter code"
                value={codeInput}
                onChange={(e) => setCodeInput(e.target.value)}
                className="default-input"
              />
              <div className="su-confirm-row">
                <button
                  onClick={() => {
                    checkCode();
                  }}
                  className="btn-20"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      ) : show ? (
        <>
          <div className="payfast-info">
            <div className="pi-back">
              <div onClick={() => setShow(false)} className="back-row">
                <ArrowBack className="icon-cursor" />

                <p>Back</p>
              </div>
            </div>
            <h1>Almost there!</h1>
            <p>
              Our payments are processed by Payfast - you will temporarily be
              taken away from this site.
            </p>
          </div>
          <div
            className="payfast-form"
            dangerouslySetInnerHTML={{ __html: formHTML }}
          ></div>
        </>
      ) : (
        <>
          <div className="payfast-info">
            <div className="pi-back">
              <div onClick={goBack} className="back-row">
                <ArrowBack className="icon-cursor" />
                <p>Back</p>
              </div>
            </div>
            <div className="pi-top">
              <h1>Select an account type</h1>
              <p>Subscriptions are cancellable at any time</p>
            </div>
          </div>
          <div className="su-confirm-row">
            <button
              onClick={() => {
                if (selected === "monthly") {
                  callPayfastSub();
                } else if (selected === "free") {
                  setFree();
                } else {
                  window.open(
                    "mailto:commissioner@theugatour.com?subject=Enquiry"
                  );
                }
              }}
              disabled={selected === ""}
              className={selected !== "" ? "btn-20" : ""}
            >
              Confirm
            </button>
          </div>
          <div className="su-options">
            <div
              className={selected === "free" ? "su-opt suo-active " : "su-opt"}
            >
              <div className="suo-left">
                <h3>Free Account</h3>
                <p>Absolutely free to use</p>
                <h5>No Club functionality</h5>
              </div>
              <div className="suo-right">
                <h3>Free</h3>
                <p></p>
              </div>
              <div className="flex-center">
                <button
                  onClick={() =>
                    setSelected((current) => {
                      if (current === "free") {
                        return "";
                      } else {
                        return "free";
                      }
                    })
                  }
                  className="default-button"
                >
                  Select
                </button>
              </div>
            </div>
            {false && (
              <div
                className={
                  selected === "monthly" ? "su-opt suo-active " : "su-opt"
                }
              >
                <div className="suo-left">
                  <h3>Premium Account</h3>
                  <p>Billed every month</p>
                  <h5>Limited to 50 league/club members</h5>
                </div>
                <div className="suo-right">
                  <h3>$12.99</h3>
                  <p>per month</p>
                </div>
                <div className="flex-center">
                  <button
                    onClick={() =>
                      setSelected((current) => {
                        if (current === "monthly") {
                          return "";
                        } else {
                          return "monthly";
                        }
                      })
                    }
                    className="default-button"
                  >
                    Select
                  </button>
                </div>
              </div>
            )}
            <div
              className={selected === "club" ? "su-opt suo-active " : "su-opt"}
            >
              <div className="suo-left">
                <h3>Club Mode</h3>
                <p>Custom built to your needs</p>
                <h5>Unlimited league/club members</h5>
              </div>
              <div className="suo-right">
                <h3>Contact</h3>
                <p>for a quote</p>
              </div>
              <div className="flex-center">
                <button
                  onClick={() =>
                    setSelected((current) => {
                      if (current === "club") {
                        return "";
                      } else {
                        return "club";
                      }
                    })
                  }
                  className="default-button"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SignUp;
