import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

// Icons

// Components
import { ClipLoader } from "react-spinners";
import RoundItem from "./RoundItem/RoundItem";
import RoundCard from "./RoundCard/RoundCard";
import SearchInput from "./SearchInput";
import { isMobile } from "react-device-detect";

function ClientRounds({ userID = "" }) {
  // Admin

  // State
  const [loading, setLoading] = useState(true);
  const [rounds, setRounds] = useState([]);

  const [search, setSearch] = useState("");

  const [selRound, setSelRound] = useState();
  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((c) => c + 1);
  }

  // UE
  useEffect(() => {
    loadRounds();
  }, []);

  // Funcs
  async function loadRounds() {
    const rdDocs = await db
      .collection("rounds")
      .where("playerIDs", "array-contains", userID)
      .where("completed", "==", true)
      .orderBy("date", "desc")
      .get();

    const rdRefs = rdDocs.docs.map((rdDoc) =>
      rdDoc.ref.collection("scores").doc(userID).get()
    );
    const scoreDocs = await Promise.all(rdRefs);

    const arr = [];

    for (let i = 0; i < rdDocs.docs.length; i++) {
      const rdDoc = rdDocs.docs[i];
      const scoreDoc = scoreDocs[i];

      const scoreData = scoreDoc.data();
      const roundID = rdDoc.id;
      const rdData = rdDoc.data();
      if (scoreData.holesCompleted !== 18) {
        continue;
      }
      const { date, courseName, courseID } = rdData;
      const dateString = new Date(date.toDate()).toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      scoreData.courseName = courseName;
      scoreData.courseID = courseID;
      // scoreData.notes = rdData.notes ?? "";

      scoreData.dateString = dateString;
      scoreData.roundID = roundID;
      scoreData.clientID = userID;
      arr.push(scoreData);
    }

    setRounds(arr);
    setLoading(false);
  }

  return (
    <div className="client-rounds flex">
      {!(isMobile && selRound) && (
        <div
          className={
            loading ? "cr-load" : selRound ? "cr-left crl-smol" : "cr-left"
          }
        >
          {loading ? (
            <div className="cp-loader">
              {" "}
              <ClipLoader color="#1e7a69" size={30} className="btn-ico" />
            </div>
          ) : (
            <>
              <div className="cr-search">
                <SearchInput setVal={setSearch} val={search} />
              </div>
              {rounds.map((rd, i) => {
                // console.log(search, rd);
                let c = rd.courseName ?? "";
                if (search !== "") {
                  if (!c.toLowerCase().includes(search.toLowerCase())) {
                    return null;
                  }
                }

                return (
                  <RoundItem
                    key={rd.roundID}
                    clickAct={() => {
                      setSelRound(rd);
                      upd();
                    }}
                    round={rd}
                    active={selRound && selRound.roundID === rd.roundID}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
      <div className="clro-right">
        {selRound && (
          <RoundCard
            showBack={isMobile}
            userID={userID}
            round={selRound}
            goBack={() => setSelRound()}
          />
        )}
      </div>
    </div>
  );
}

export default ClientRounds;
