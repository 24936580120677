import React, { useEffect, useState } from "react";
import "./PlayerRound.css";

import GolfCourseIcon from "@mui/icons-material/GolfCourse";

import { Avatar } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import AdjustIcon from "@mui/icons-material/Adjust";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import { Tabs, Tab } from "@mui/material";

import Switch from "react-ios-switch";
// import EventStatsDisplay from "../display/StatsDisplay/EventStatsDisplay";

import { useAuth } from "../../contexts/AuthContext";

import MatchplayScore from "../display/MatchplayScore/MatchplayScore";
import NassauScore from "../display/NassauScore/NassauScore";
import MatchplayHoleSlider from "../display/MatchplayScore/MatchplayHoleSlider";
import MatchplayHoles from "../display/MatchplayHoles/MatchplayHoles";

// import SkinsBoard from "../../pages/Live/Leaderboard/SkinsBoard/SkinsBoard";
import Leaderboard from "../Leaderboard/Leaderboard";
import CupNassau from "../Leaderboard/CupNassau/CupNassau";
import ScoreTracker from "./ScoreTracker";
import StablePoints from "../display/StablePoints/StablePoints";
import BackRow from "../display/Rows/BackRow";
// import RoundGraphs from "../display/RoundGraphs/RoundGraphs";
// import RoundSummary from "../RoundSummary/RoundSummary";

const imageArray = [
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fallan-nygren--xvKQuNtOhI-unsplash.jpg?alt=media&token=27aa3696-86d7-4634-95a3-576ccbe50f1f",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Famauri-cruz-filho-kBNV9WpCs5k-unsplash.jpg?alt=media&token=b204cbdb-ad91-4d44-8e46-aa19d286537c",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fstephen-leonardi-wfC28E82Slc-unsplash.jpg?alt=media&token=afd6cac1-b21c-4882-a360-d71c907811cc",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fandrew-anderson-CtyC2JjLhVg-unsplash.jpg?alt=media&token=d6613b7f-9469-452f-a419-18b95a317d7a",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fcraig-hellier-jiWa0CSv3Ow-unsplash.jpg?alt=media&token=8bf37bec-55c4-4e98-8dad-f6a9902d4d1d",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fdean-ricciardi-08Ipbe8GpWw-unsplash.jpg?alt=media&token=583ebdd6-3f5c-4e17-8b97-798cdb6cf95f",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fdean-ricciardi-UyTfzNdLHzs-unsplash.jpg?alt=media&token=28c37df5-2ada-483c-8255-57a25bd707c3",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fdean-ricciardi-r1NYKtFS6VQ-unsplash.jpg?alt=media&token=4eadfae4-c32b-48e0-8bd2-d833949f0fd4",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fedwin-compton-Z8XlmAj65iM-unsplash.jpg?alt=media&token=29ca3dc9-5295-453c-a8cc-5ceb5b1f5dfd",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fjura-FegOaqn_4GQ-unsplash.jpg?alt=media&token=66b1ee76-8285-4e69-903e-43c966a37ad0",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fping-lee-Aq2ZfIRzSj8-unsplash.jpg?alt=media&token=14d87a13-4e73-4ea4-bfb0-5addf6437a81",
  "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Frichard-brutyo-HQXFhq8FNJ8-unsplash.jpg?alt=media&token=0ae28edf-37e0-4d2a-99a0-b5af724b731c",
];

function PlayerRound({
  eventName,
  scoreCard = [],
  leagueName,
  dateString,
  handicap,
  courseName,
  imageLink = "",
  goBack,
  courseID,
  frontNinePar,
  backNinePar,
  frontNineNet,
  frontNineGross,
  backNineNet,
  backNineGross,
  totalNetStrokes,
  totalGrossStrokes,
  coursePar,
  frontNinePutts,
  backNinePutts,
  backNineGIR,
  frontNineGIR,
  backNineFIR,
  frontNineFIR,
  totalPutts,
  liveEvent = false,
  playerID,
  stats,
  grossStats,
  playerName = "",
  roundID = "",
  format = "",
  nassau = false,
  leaderboard,
  holes = [],
  groups = [],
  teamNames = [],
  matches = [],
  score,
  netTracker = [],
  grossTracker = [],
  customRound = false,
  roundHoles = [],
}) {
  const [showMP, setShowMP] = useState(false);

  const [sumSlider, setSumSlider] = useState("slide-in-right");

  const [toShow, setToShow] = useState(false);
  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState("scorecard");
  const [showProfile, setShowProfile] = useState(false);
  const [scoring, setScoring] = useState("net");
  // eslint-disable-next-line no-unused-vars
  const [units, setUnits] = useState("meters");
  const [mpDetails, setMPDetails] = useState({
    holes: 0,
    leader: "",
    nameOne: "",
    nameTwo: "",
    teamOne: 0,
    teamTwo: 0,
    idOne: "",
    idTwo: "",
    score: 0,
  });
  const [matchplay, setMatchplay] = useState();

  const [statsSlider, setStatsSlider] = useState("slide-in-right");
  const [graphSlider, setGraphSlider] = useState("slide-in-right");
  const [trackerSlider, setTrackerSlider] = useState("slide-in-right");

  const [cup, setCup] = useState(false);

  const [skins, setSkins] = useState(false);

  const { userSettings } = useAuth();

  useEffect(() => {
    // console.log(roundHoles);
    if (matches.length > 0) {
      setCup(true);
    }

    if (
      [
        "match-play",
        "bb-match-play",
        "ab-match-play",
        "wb-match-play",
      ].includes(format)
    ) {
      sortMatchplay();
    }
    if (format.includes("skins")) {
      setSkins(true);
    }
  }, []);

  function sortMatchplay() {
    setMatchplay(true);
    if (
      format === "bb-match-play" ||
      format === "ab-match-play" ||
      format === "wb-match-play"
    ) {
      if (nassau) {
        // console.log("here for some reaosn");
        let teamOne = 0;
        let teamTwo = 0;
        if (leaderboard.leader === "teamOne") {
          teamOne += 1;
        } else if (leaderboard.leader === "teamTwo") {
          teamTwo += 1;
        } else if (leaderboard.thru > 0) {
          teamOne += 0.5;
          teamTwo += 0.5;
        }
        if (leaderboard.frontNine === "teamOne") {
          teamOne += 1;
        } else if (leaderboard.frontNine === "teamTwo") {
          teamTwo += 1;
        } else if (leaderboard.thru > 0) {
          teamOne += 0.5;
          teamTwo += 0.5;
        }
        if (leaderboard.backNine === "teamOne") {
          teamOne += 1;
        } else if (leaderboard.backNine === "teamTwo") {
          teamTwo += 1;
        } else if (leaderboard.thru > 9) {
          teamOne += 0.5;
          teamTwo += 0.5;
        }
        setMPDetails({
          nameOne: teamNames.teamOne,
          nameTwo: teamNames.teamTwo,
          holes: leaderboard.thru,
          teamOne,
          teamTwo,
          idOne: "teamOne",
          idTwo: "teamTwo",
          leader: leaderboard.leader,
          holeArray: holes,
        });
        setShowMP(true);
        // return setLoaded(true);
      } else {
        setMPDetails({
          nameOne: teamNames.teamOne,
          nameTwo: teamNames.teamTwo,
          score: leaderboard.score ?? 0,
          leader: leaderboard.leader,
          holes: leaderboard.thru,
          idOne: "teamOne",
          idTwo: "teamTwo",
          holeArray: holes,
        });
        // console.log(mpDetails);
        setShowMP(true);
      }
    }

    if (format === "match-play") {
      const p1ID = groups[0].playerID;
      const p2ID = groups[1].playerID;
      if (nassau) {
        let teamOne = 0;
        let teamTwo = 0;

        // Overall
        if (leaderboard.leader === p1ID) {
          teamOne += 1;
        } else if (leaderboard.leader === p2ID) {
          teamTwo += 1;
        } else if (leaderboard.thru > 0) {
          teamOne += 0.5;
          teamTwo += 0.5;
        }
        // Front nine
        if (leaderboard.frontNine === p1ID) {
          teamOne += 1;
        } else if (leaderboard.frontNine === p2ID) {
          teamTwo += 1;
        } else if (leaderboard.thru > 0) {
          teamOne += 0.5;
          teamTwo += 0.5;
        }
        // Back nine
        if (leaderboard.backNine === p1ID) {
          teamOne += 1;
        } else if (leaderboard.backNine === p2ID) {
          teamTwo += 1;
        } else if (leaderboard.thru > 9) {
          teamOne += 0.5;
          teamTwo += 0.5;
        }

        setMPDetails({
          holes: leaderboard.thru,
          leader: leaderboard.leader,
          nameOne: groups[0].name,
          nameTwo: groups[1].name,
          teamOne,
          teamTwo,
          idOne: p1ID,
          idTwo: p2ID,
          holeArray: holes,
        });
        setShowMP(true);
      } else {
        setMPDetails({
          nameOne: groups[0].name,
          nameTwo: groups[1].name,
          idOne: groups[0].playerID,
          idTwo: groups[1].playerID,
          leader: leaderboard.leader,
          holes: leaderboard.thru,
          score: leaderboard.by,
          holeArray: holes,
        });
        setShowMP(true);
      }
    }
  }

  useEffect(() => {
    if (userSettings) {
      setUnits(userSettings.units);
    }
  }, [userSettings]);

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return (
    <div className="player-round">
      {!showProfile ? (
        <>
          {/* <div className="ts-header">
            <ArrowForwardIcon onClick={() => goBack()} className="ts-arrow" />
            <h2>Player Round {playerName !== "" && `- ${playerName}`}</h2>
          </div> */}
          <BackRow action={goBack} />
          <div className="p-r-course">
            {/* <div onClick={goBack} className="back-arrow">
                <ArrowLeftIcon className='prh-icon' />
            </div> */}
            {/* <div
              onClick={() => setShowProfile(true)}
              className="p-r-course-left"
            >
              <Avatar
                alt={courseName}
                src={
                  imageLink === ""
                    ? imageArray[randomIntFromInterval(0, imageArray.length)]
                    : imageLink
                }
              />
            </div> */}
            <div
              onClick={() => setShowProfile(true)}
              className="p-r-course-right player-round-header"
            >
              {/* <h2 className="ne">{courseName}</h2> */}
              {/* <p>{dateString}</p> */}
            </div>
          </div>
          <div className="player-round-header">
            <h2 className="ne-header">{eventName}</h2>
            <p>{playerName}</p>
            <h3>Handicap: {handicap}</h3>
          </div>

          {!liveEvent && (
            <Tabs
              value={selected}
              onChange={(e, newValue) => {
                if (newValue === "statistics") {
                  if (selected === "scorecard" || selected === "summary") {
                    setStatsSlider("slide-in-right");
                  } else {
                    setStatsSlider("slide-in-left");
                  }
                }
                if (newValue === "summary") {
                  if (selected === "scorecard") {
                    setStatsSlider("slide-in-right");
                  } else {
                    setStatsSlider("slide-in-left");
                  }
                }
                if (newValue === "graphs") {
                  if (["scorecard", "statistics"].includes(selected)) {
                    setGraphSlider("slide-in-right");
                  } else {
                    setGraphSlider("slide-in-left");
                  }
                }
                if (newValue === "tracker") {
                  if (["board"].includes(selected)) {
                    setTrackerSlider("slide-in-left");
                  } else {
                    setTrackerSlider("slide-in-right");
                  }
                }
                setSelected(newValue);
              }}
            >
              <Tab label="Scorecard" id={"scorecard"} value={"scorecard"} />
              {eventName !== "" && (
                <Tab label="Summary" id={"summary"} value={"summary"} />
              )}
              <Tab label="Statistics" id={"statistics"} value={"statistics"} />
              <Tab label="Graphs" id={"graphs"} value={"graphs"} />
              {netTracker.length > 0 && (
                <Tab label="Tracker" id={"tracker"} value={"tracker"} />
              )}
              {matchplay && (
                <Tab label="Matchplay" id={"statistics"} value={"matchplay"} />
              )}
              {skins && <Tab label="Skins" id={"statistics"} value={"skins"} />}
              {customRound &&
                ["average-ball", "worst-ball", "net-strokeplay"].includes(
                  format
                ) && <Tab label="Board" id={"statistics"} value={"board"} />}
              {cup && customRound && (
                <Tab label="Cup" id={"statistics"} value={"cup"} />
              )}
              {format.includes("stable") && (
                <Tab label="Stableford" value={"stableford"} />
              )}
            </Tabs>
          )}
          {/* 
          {!liveEvent && (
            <div className="pr-menu">
              <div
                onClick={() => setSelected("scorecard")}
                className={
                  selected === "scorecard" ? "prm-item prm-active" : "prm-item"
                }
              >
                <p>Scorecard</p>
              </div>
              <div
                onClick={() => setSelected("statistics")}
                className={
                  selected === "statistics" ? "prm-item prm-active" : "prm-item"
                }
              >
                <p>Statistics</p>
              </div>
            </div>
          )} */}

          {liveEvent && <div style={{ margin: "10px" }}></div>}

          {selected === "skins" && (
            <div className="mt-20">
              <Leaderboard roundID={roundID} />
            </div>
          )}

          {selected === "skins" && (
            <>{/* <SkinsBoard roundID={roundID} /> */}</>
          )}

          {selected === "cup" && (
            <div className={"slide-in-right"}>
              <Leaderboard cupID="custom" roundID={roundID} />
            </div>
          )}

          {selected === "graphs" && (
            <div className={graphSlider}>
              {/* <RoundGraphs roundID={roundID} userID={playerID} /> */}
            </div>
          )}

          {selected === "summary" && (
            <div className={sumSlider}>
              {/* <RoundSummary roundID={roundID} playerID={playerID} /> */}
            </div>
          )}

          {selected === "tracker" && (
            <div className={trackerSlider}>
              <ScoreTracker
                format={format}
                roundHoles={roundHoles}
                netTracker={netTracker}
                grossTracker={grossTracker}
              />
            </div>
          )}

          {selected === "stableford" && <StablePoints roundID={roundID} />}

          {matchplay && selected === "scorecard" && (
            <>
              {!nassau && ["match-play"].includes(format) && (
                <div className="pd-20">
                  <MatchplayScore
                    idOne={groups[0].playerID}
                    idTwo={groups[1].playerID}
                    nameOne={groups[0].name}
                    nameTwo={groups[1].name}
                    holes={leaderboard.thru}
                    leader={leaderboard.leader}
                    score={leaderboard.by}
                  />
                  <MatchplayHoleSlider
                    holes={holes}
                    idOne={groups[0].playerID}
                    idTwo={groups[1].playerID}
                    teamOneColor="#21c17c"
                    teamTwoColor="#1e7a69"
                  />
                </div>
              )}
            </>
          )}

          {selected === "board" && (
            <div className="pd-20 slide-in-right">
              <Leaderboard roundID={roundID} />
            </div>
          )}

          {selected === "matchplay" && (
            <MatchplayHoles roundID={roundID} nassau={nassau} />
          )}

          {["bb-match-play", "ab-match-play", "wb-match-play"].includes(
            format
          ) &&
            !nassau &&
            selected === "scorecard" && (
              <>
                {
                  <div className="pd-20">
                    <MatchplayScore
                      teamOneColor={"#21c17c"}
                      teamTwoColor={"#1e7a69"}
                      holes={mpDetails.holes}
                      idOne={mpDetails.idOne}
                      idTwo={mpDetails.idTwo}
                      leader={mpDetails.leader}
                      nameOne={mpDetails.nameOne}
                      nameTwo={mpDetails.nameTwo}
                      handleClick={() => {}}
                      score={mpDetails.score}
                    />
                    <MatchplayHoleSlider
                      teamOneColor={"#21c17c"}
                      teamTwoColor={"#1e7a69"}
                      idOne={mpDetails.idOne}
                      idTwo={mpDetails.idTwo}
                      holes={mpDetails.holeArray}
                    />
                  </div>
                }
              </>
            )}
          {[
            "bb-match-play",
            "ab-match-play",
            "wb-match-play",
            "match-play",
          ].includes(format) &&
            nassau &&
            selected === "scorecard" && (
              <>
                {show ? (
                  toShow
                ) : (
                  <div className="pd-20">
                    <CupNassau
                      teamOneBG={"#21c17c"}
                      teamTwoBG={"#1e7a69"}
                      holes={mpDetails.holes}
                      leader={mpDetails.leader}
                      nameOne={mpDetails.nameOne}
                      nameTwo={mpDetails.nameTwo}
                      teamOne={mpDetails.teamOne}
                      teamTwo={mpDetails.teamTwo}
                      handleClick={() => {}}
                    />
                    <MatchplayHoleSlider
                      teamOneColor={"#21c17c"}
                      teamTwoColor={"#1e7a69"}
                      idOne={mpDetails.idOne}
                      idTwo={mpDetails.idTwo}
                      holes={mpDetails.holeArray}
                    />
                  </div>
                )}
              </>
            )}

          {selected === "scorecard" && (
            <div className="score-table-container slide-in-left prtc">
              <table>
                <thead>
                  <tr className="header-row prtc-header">
                    <th>
                      <GolfCourseIcon />
                    </th>
                    <th>Par</th>
                    <th>Hcp</th>
                    <th>Score</th>
                    <th>Net</th>
                    <th>Putts</th>
                    <th>FIR</th>
                    <th>GIR</th>
                  </tr>
                </thead>
                <tbody>
                  {scoreCard.map((score, index) => {
                    return (
                      <>
                        {index === 9 && (
                          <tr className="header-row out-row">
                            <td className="hole-td">OUT</td>
                            <td className="par-td">{frontNinePar}</td>
                            <td></td>
                            <td className="normal-td bold-td">
                              {frontNineGross}
                            </td>
                            <td className="normal-td bold-td">
                              {frontNineNet}
                            </td>
                            <td className="normal-td">{frontNinePutts}</td>
                            <td className="normal-td">
                              {Math.round((frontNineFIR / 9) * 100)}%
                            </td>
                            <td className="normal-td">
                              {Math.round((frontNineGIR / 9) * 100)}%
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="normal-td">{index + 1}</td>
                          <td className="normal-td">{score.par}</td>
                          <td className="normal-td">{score.stroke}</td>
                          <td
                            className={
                              score.grossScore - score.par > 1
                                ? "score-td over-par double-over-par"
                                : score.grossScore - score.par > 0
                                ? "score-td over-par"
                                : score.grossScore - score.par < -1 &&
                                  score.grossScore > 0
                                ? "score-td under-par double-under-par"
                                : score.grossScore - score.par < 0 &&
                                  score.grossScore > 0
                                ? "score-td under-par"
                                : "score-td"
                            }
                          >
                            <span>{score.grossScore}</span>
                          </td>
                          <td
                            className={
                              score.toPar > 1 && score.grossScore > 0
                                ? "score-td over-par double-over-par"
                                : score.toPar > 0
                                ? "score-td over-par"
                                : score.toPar < -1 && score.grossScore > 0
                                ? "score-td under-par double-under-par"
                                : score.toPar < 0
                                ? "score-td under-par"
                                : "score-td"
                            }
                          >
                            <span>{score.netScore}</span>
                          </td>
                          <td className="normal-td">{score.putts}</td>
                          <td className="icon-td">
                            {score.fir ? (
                              <AdjustIcon className="hit" />
                            ) : (
                              <CloseIcon className="missed" />
                            )}
                          </td>
                          <td className="icon-td">
                            {score.gir ? (
                              <DoneIcon className="hit" />
                            ) : (
                              <CloseIcon className="missed" />
                            )}
                          </td>
                        </tr>
                        {index === 17 && (
                          <tr className="header-row in-row">
                            <td className="hole-td">IN</td>
                            <td className="par-td">{backNinePar}</td>
                            <td></td>
                            <td className="normal-td">{backNineGross}</td>
                            <td className="normal-td">{backNineNet}</td>
                            <td className="normal-td">{backNinePutts}</td>
                            <td className="normal-td">
                              {Math.round((backNineFIR / 9) * 100)}%
                            </td>
                            <td className="normal-td">
                              {Math.round((backNineGIR / 9) * 100)}%
                            </td>
                          </tr>
                        )}
                        {index === 17 && (
                          <tr className="header-row out-row">
                            <td className="hole-td">OUT</td>
                            <td className="par-td">{frontNinePar}</td>
                            <td></td>
                            <td className="normal-td">{frontNineGross}</td>
                            <td className="normal-td">{frontNineNet}</td>
                            <td className="normal-td">{frontNinePutts}</td>
                            <td className="normal-td">
                              {Math.round((frontNineFIR / 9) * 100)}%
                            </td>
                            <td className="normal-td">
                              {Math.round((frontNineGIR / 9) * 100)}%
                            </td>
                          </tr>
                        )}
                        {index === 17 && (
                          <tr className="header-row total-row">
                            <td className="hole-td">TOT</td>
                            <td className="par-td">{coursePar}</td>
                            <td></td>
                            <td className="player-td bold-td">
                              {totalGrossStrokes}
                            </td>
                            <td className="player-td bold-td">
                              {totalNetStrokes}
                            </td>
                            <td className="normal-td">{totalPutts}</td>
                            {liveEvent && (
                              <>
                                <td className="normal-td"></td>
                                <td className="normal-td"></td>
                              </>
                            )}
                            {!liveEvent && (
                              <>
                                <td className="normal-td">
                                  {Math.round(
                                    ((frontNineFIR + backNineFIR) / 18) * 100
                                  )}
                                  %
                                </td>
                                <td className="normal-td">
                                  {Math.round(
                                    ((frontNineGIR + backNineGIR) / 18) * 100
                                  )}
                                  %
                                </td>
                              </>
                            )}
                          </tr>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {selected === "statistics" && (
            <div className={statsSlider}>
              <div className="switch-row">
                <h3>Gross</h3>
                <Switch
                  checked={scoring === "net"}
                  onChange={() =>
                    setScoring((current) => {
                      if (current === "net") {
                        return "gross";
                      } else {
                        return "net";
                      }
                    })
                  }
                  className={
                    scoring === "net"
                      ? "scoring-switch switch-on"
                      : "scoring-switch"
                  }
                  onColor="#209E73"
                />
                <h3>Net</h3>
              </div>
              <div className="performance-statistics pr-statistics  scale-in-ps">
                <div className="rs-msg">
                  <p>Values displayed in brackets are career numbers</p>
                </div>
                <div className="perf-stats-container">
                  <div className="perf-stats">
                    {/* <EventStatsDisplay
                      uid={playerID}
                      holes={scoreCard}
                      stats={stats}
                      grossStats={grossStats}
                      net={scoring === "net"}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PlayerRound;
