/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./CupMatchups.css";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Dialog } from "@mui/material";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import Pill from "../Buttons/Pill";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PageHeader from "../display/Rows/PageHeader";
import { makeID } from "../HelperFunctions";

function CupMatchups({
  showBack = false,
  goBack,
  teams = [],
  format = "",
  saveMatches = () => {},
  defaultMatches = [],
  display = false,
  teamOneColor = "#1e7a69",
  teamTwoColor = "#21c17c",
}) {
  const [matches, setMatches] = useState(defaultMatches);

  const [showDialog, setShowDialog] = useState(false);
  const [update, setUpdate] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [team, setTeam] = useState(0);
  const [match, setMatch] = useState(0);
  const [playerNum, setPlayerNum] = useState(0);

  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const neow = new Date().toLocaleTimeString("en-gb", { timeStyle: "short" });

  useEffect(() => {
    // console.log(teams);
    if (!display) {
      sortMatches();
    } else {
      // console.log(defaultMatches);
      setMatches(defaultMatches);
      setLoaded(true);
    }
    // console.log(defaultMatches);
  }, []);

  useEffect(() => {
    if (!showBack) {
      saveMatches(matches);
    }
    // console.log(matches);
  }, [matches]);

  // useEffect(() => {

  // }, [format])

  function sortMatches() {
    console.log(format);
    if (defaultMatches.length > 0) {
      console.log("more 0");
      if (format === "Matchplay") {
        console.log("MP");
        const players = teams[0].players.length + teams[1].players.length;
        const matchAmount = Math.floor(players / 2);
        if (matchAmount === defaultMatches.length) {
          // setMatch(defaultMatches)

          const playerArr = [];
          for (let i = 0; i < defaultMatches.length; i++) {
            const match = defaultMatches[i];
            if (match.matchID === undefined) {
              defaultMatches[i].matchID = makeID(10);
            }
            if (match.time === "") {
              match.time = neow;
            }
            if (match.playerOne !== "") {
              playerArr.push(match.playerOne.playerID);
            }
            if (match.playerTwo !== "") {
              playerArr.push(match.playerTwo.playerID);
            }
          }
          setSelectedPlayers(playerArr);
          // console.log(playerArr);
          setLoaded(true);
          return setUpdate((current) => current + 1);
        }
      } else {
        console.log("FB");
        const players = teams[0].players.length + teams[1].players.length;
        const matchAmount = Math.floor(players / 4);
        if (matchAmount === defaultMatches.length) {
          const playerArr = [];
          for (let i = 0; i < defaultMatches.length; i++) {
            const match = defaultMatches[i];
            if (match.matchID === undefined) {
              defaultMatches[i].matchID = makeID(10);
            }
            console.log(match.time);
            if (match.time === "") {
              match.time = neow;
            }
            if (match.teamOne[0] !== "") {
              playerArr.push(match.teamOne[0].playerID);
            }
            if (match.teamOne[1] !== "") {
              playerArr.push(match.teamOne[1].playerID);
            }
            if (match.teamTwo[0] !== "") {
              playerArr.push(match.teamTwo[0].playerID);
            }
            if (match.teamTwo[1] !== "") {
              playerArr.push(match.teamTwo[1].playerID);
            }
          }

          setSelectedPlayers(playerArr);

          setLoaded(true);
          console.log("ret default");
          return setMatch(defaultMatches);
        }
      }
    }

    console.log("got past");

    const arr = [];
    const players = teams[0].players.length + teams[1].players.length;
    // console.log(players);

    if (format === "Matchplay") {
      const matchAmount = Math.floor(players / 2);
      // console.log(matchA)
      for (let i = 0; i < matchAmount; i++) {
        arr.push({
          playerOne: "",
          playerTwo: "",
          score: 0,
          leader: "",
          time: neow,
          complete: false,
          matchID: makeID(10),
        });
      }
    } else {
      const matchAmount = Math.floor(players / 4);
      // console.log(matchAmount);
      for (let i = 0; i < matchAmount; i++) {
        arr.push({
          teamOne: ["", ""],
          teamTwo: ["", ""],
          score: 0,
          leader: ["", ""],
          time: neow,
          complete: false,
          matchID: makeID(10),
        });
      }
    }

    setMatches(arr);
    setLoaded(true);
  }

  function shuffle(array) {
    // alert("everyday");
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  function shufflePlayers() {
    // console.log(teams[0].players);
    if (format === "Matchplay") {
      const teamOne = shuffle(teams[0].players);
      const teamTwo = shuffle(teams[1].players);
      const newMatches = matches;
      const newArr = [];
      for (let i = 0; i < matches.length; i++) {
        if (!teamOne[i] || !teamTwo[i]) {
          continue;
        }
        newMatches[i].playerOne = teamOne[i];
        newArr.push(teamOne[i].playerID);
        newArr.push(teamTwo[i].playerID);
        newMatches[i].playerTwo = teamTwo[i];
      }
      setMatches(newMatches);
      console.log(newMatches);
      setSelectedPlayers(newArr);
      setUpdate((current) => current + 1);
    } else {
      const teamOne = shuffle(teams[0].players);
      const teamTwo = shuffle(teams[1].players);
      const newMatches = matches;
      const newArr = [];
      for (let i = 0; i < matches.length; i++) {
        const first = i * 2;
        if (!teamOne[first] || !teamTwo[first]) {
          continue;
        }
        newMatches[i].teamOne[0] = teamOne[first];
        newMatches[i].teamOne[1] = teamOne[first + 1];
        newMatches[i].teamTwo[0] = teamTwo[first];
        newMatches[i].teamTwo[1] = teamTwo[first + 1];
        newArr.push(teamOne[first].playerID);
        newArr.push(teamOne[first + 1].playerID);
        newArr.push(teamTwo[first].playerID);
        newArr.push(teamTwo[first + 1].playerID);
      }
      setMatches(newMatches);
      setSelectedPlayers(newArr);

      setUpdate((current) => current + 1);
    }
  }

  return (
    <div className="cup-matchups mb-40">
      {showBack && (
        <div onClick={() => goBack()} className="back-row">
          <ArrowBack className="icon-cursor" />
          <p>Back</p>
        </div>
      )}
      {showBack && <PageHeader text="Cup Matches" />}
      {showBack && (
        <div className="pl-20">
          <button
            onClick={() => saveMatches(matches)}
            className="default-button"
          >
            Save
          </button>
        </div>
      )}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="cup-player-selector">
          <div className="cps-header">
            <h5>Select a player for this match</h5>
          </div>

          {teams[team] &&
            teams[team].players.map((player, index) => {
              if (selectedPlayers.includes(player.playerID)) {
                return null;
              }

              return (
                <div key={player.playerID} className="cup-player">
                  <PlayerItem
                    hcp={player.handicap}
                    // hideImage
                    hideEdit
                    hideIcons
                    noLink
                    img={player.profileImage}
                    name={player.name}
                    showProfile={() => {
                      if (display) {
                        return;
                      }
                      if (format === "Matchplay") {
                        // SINGLES

                        setMatches((current) => {
                          const temp = current;
                          if (team === 0) {
                            // Add remove player from selected array
                            if (temp[match]["playerOne"] !== "") {
                              // Currently not empty
                              const uid = temp[match]["playerOne"].playerID;
                              // console.log(uid);
                              setSelectedPlayers((current) =>
                                current.filter((item) => item !== uid)
                              );
                            }
                            temp[match]["playerOne"] = player;
                          } else {
                            // Add remove player from selected array
                            if (temp[match]["playerTwo"] !== "") {
                              const uid = temp[match]["playerTwo"].playerID;
                              setSelectedPlayers((current) =>
                                current.filter((item) => item.playerID !== uid)
                              );
                            }
                            temp[match]["playerTwo"] = player;
                          }
                          return temp;
                        });
                        setSelectedPlayers((current) => [
                          ...current,
                          player.playerID,
                        ]);
                        setShowDialog(false);
                      } else {
                        // GROUP

                        setMatches((current) => {
                          const temp = current;
                          if (team === 0) {
                            // Add or remove player from selected array
                            if (temp[match].teamOne[playerNum] !== "") {
                              const uid =
                                temp[match].teamOne[playerNum].playerID;
                              setSelectedPlayers((current) =>
                                current.filter((item) => item !== uid)
                              );
                            }
                            temp[match].teamOne[playerNum] = player;
                          } else {
                            // Add or remove player from selected array
                            if (temp[match].teamTwo[playerNum] !== "") {
                              const uid =
                                temp[match].teamTwo[playerNum].playerID;
                              setSelectedPlayers((current) =>
                                current.filter((item) => item !== uid)
                              );
                            }
                            temp[match].teamTwo[playerNum] = player;
                          }
                          // console.log(temp);
                          return temp;
                        });
                        setSelectedPlayers((current) => [
                          ...current,
                          player.playerID,
                        ]);
                        setShowDialog(false);
                      }
                    }}
                  />
                </div>
              );
            })}
        </div>
      </Dialog>

      {loaded && !display && (
        <div className=" pl-20 ec-box mt-20">
          <Pill text={"Shuffle"} onClick={shufflePlayers} />
          <Pill
            text={"Add Match"}
            onClick={() => {
              setMatches((c) => {
                if (format === "Matchplay") {
                  c.push({
                    playerOne: "",
                    playerTwo: "",
                    score: 0,
                    leader: "",
                    time: neow,
                    complete: false,
                    matchID: makeID(10),
                  });
                } else {
                  c.push({
                    teamOne: ["", ""],
                    teamTwo: ["", ""],
                    score: 0,
                    leader: ["", ""],
                    time: neow,
                    complete: false,
                    matchID: makeID(10),
                  });
                }
                return c;
              });
              setUpdate((c) => c + 1);
            }}
          />
        </div>
      )}

      {loaded && (
        <div className="match-container mt-0">
          {matches.map((match, index) => {
            return (
              <div className="cm-match-box" key={index}>
                <div
                  style={{
                    backgroundImage: display
                      ? `linear-gradient(90deg, ${teamOneColor} 0%, ${teamTwoColor} 100%)`
                      : "white",
                  }}
                  className={
                    display ? "match-header display-mh" : "match-header"
                  }
                >
                  <h5>
                    Match {index + 1} {display && ` - ${match.time}`}
                  </h5>
                  {!display && (
                    <input
                      // defaultValue={now}
                      type={"time"}
                      className="default-input"
                      disabled={display}
                      value={match.time}
                      onChange={(e) => {
                        setMatches((current) => {
                          const temp = current;
                          temp[index].time = e.target.value;
                          return temp;
                        });
                        setUpdate((current) => current + 1);
                      }}
                    />
                  )}
                </div>
                {format === "Matchplay" ? (
                  <>
                    <div className="sm-box">
                      <div className="singles-match">
                        <div className="singles-left">
                          {match.playerOne === "" ? (
                            <>
                              {false && (
                                <div className="cm-pc">
                                  <div
                                    className="cm-input-wrapper"
                                    onClick={() => {
                                      // alert("click");
                                      if (display) {
                                        return;
                                      }
                                      setTeam(0);
                                      setMatch(index);
                                      setShowDialog(true);
                                      setUpdate((current) => current + 1);
                                      //   setShowFormats(true);
                                    }}
                                  >
                                    <input
                                      className="default-input"
                                      disabled
                                      value={"Select player..."}
                                    />
                                    {!display && (
                                      <Edit
                                        className="dg-icon-bg icon-cursor ml-20"
                                        onClick={() => {
                                          setTeam(0);
                                          setMatch(index);
                                          setShowDialog(true);
                                          setUpdate((current) => current + 1);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                              <PlayerItem
                                name={"Select Player"}
                                hideImage
                                noLink
                                hideMessage
                                showAdd
                                hideHCP
                                hideProfile
                                hideRemove
                                editAction={() => {
                                  setTeam(0);
                                  setMatch(index);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <PlayerItem
                                playerID={match.playerOne.playerID}
                                removeAction={() => {
                                  setSelectedPlayers((current) => {
                                    const temp = current.filter(
                                      (item) =>
                                        item !== match.playerOne.playerID
                                    );
                                    return temp;
                                  });
                                  setMatches((current) => {
                                    const temp = current;
                                    temp[index].playerOne = "";
                                    return temp;
                                  });
                                }}
                                name={match.playerOne.name}
                                // noLink
                                hideHCP
                                hideImage
                                hideProfile
                                hideMessage
                                showAdd
                                editAction={() => {
                                  if (display) {
                                    return;
                                  }
                                  setTeam(0);
                                  setMatch(index);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                }}
                              />
                              {false && (
                                <div className="cm-pc">
                                  <div
                                    onClick={() => {
                                      if (display) {
                                        return;
                                      }
                                      setTeam(0);
                                      setMatch(index);
                                      setShowDialog(true);
                                      setUpdate((current) => current + 1);
                                      //   setShowFormats(true);
                                    }}
                                    className="cm-input-wrapper"
                                  >
                                    {!display && (
                                      <Edit
                                        className="dg-icon-bg icon-cursor ml-20 mr-10"
                                        onClick={() => {
                                          setTeam(0);
                                          setMatch(index);
                                          setShowDialog(true);
                                          setUpdate((current) => current + 1);
                                        }}
                                      />
                                    )}
                                    <input
                                      className="default-input"
                                      disabled
                                      value={match.playerOne.name}
                                    />
                                  </div>
                                  {!display && (
                                    <DeleteForeverIcon
                                      onClick={() => {
                                        setSelectedPlayers((current) => {
                                          const temp = current.filter(
                                            (item) =>
                                              item !== match.playerOne.playerID
                                          );
                                          return temp;
                                        });
                                        setMatches((current) => {
                                          const temp = current;
                                          temp[index].playerOne = "";
                                          return temp;
                                        });
                                      }}
                                      className="red-icon-bg ml-10"
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="singles-middle">
                          <h4>vs</h4>
                        </div>
                        <div className="singles-right">
                          {match.playerTwo === "" ? (
                            <>
                              <PlayerItem
                                name={"Select player..."}
                                hideHCP
                                hideMessage
                                noLink
                                hideImage
                                showAdd
                                hideProfile
                                hideRemove
                                editAction={() => {
                                  setTeam(1);
                                  setMatch(index);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                }}
                              />
                              {false && (
                                <div
                                  className="cm-pc"
                                  onClick={() => {
                                    if (display) {
                                      return;
                                    }
                                    setTeam(1);
                                    setMatch(index);
                                    setShowDialog(true);
                                    setUpdate((current) => current + 1);
                                    //   setShowFormats(true);
                                  }}
                                >
                                  {!display && (
                                    <Edit
                                      className="dg-icon-bg icon-cursor ml-20"
                                      onClick={() => {
                                        setTeam(1);
                                        setMatch(index);
                                        setShowDialog(true);
                                        setUpdate((current) => current + 1);
                                      }}
                                    />
                                  )}
                                  <input
                                    className="default-input"
                                    disabled
                                    value={"Select player..."}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <PlayerItem
                                playerID={match.playerTwo.playerID}
                                name={match.playerTwo.name}
                                hideHCP
                                hideImage
                                hideMessage
                                hideProfile
                                showAdd
                                // noLink
                                editAction={() => {
                                  setTeam(1);
                                  setMatch(index);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                }}
                                removeAction={() => {
                                  setSelectedPlayers((current) => {
                                    const temp = current.filter(
                                      (item) =>
                                        item !== match.playerTwo.playerID
                                    );
                                    return temp;
                                  });
                                  setMatches((current) => {
                                    const temp = current;
                                    temp[index].playerTwo = "";
                                    return temp;
                                  });
                                }}
                              />
                              {false && (
                                <div className="cm-pc">
                                  <div
                                    className="cm-input-wrapper"
                                    onClick={() => {
                                      if (display) {
                                        return;
                                      }
                                      setTeam(1);
                                      setMatch(index);
                                      setShowDialog(true);
                                      setUpdate((current) => current + 1);
                                      //   setShowFormats(true);
                                    }}
                                  >
                                    {!display && (
                                      <Edit
                                        className="dg-icon-bg icon-cursor ml-20"
                                        onClick={() => {
                                          setTeam(1);
                                          setMatch(index);
                                          setShowDialog(true);
                                          setUpdate((current) => current + 1);
                                        }}
                                      />
                                    )}
                                    <input
                                      className="default-input"
                                      disabled
                                      value={match.playerTwo.name}
                                    />
                                  </div>
                                  {!display && (
                                    <DeleteForeverIcon
                                      onClick={() => {
                                        setSelectedPlayers((current) => {
                                          const temp = current.filter(
                                            (item) =>
                                              item !== match.playerTwo.playerID
                                          );
                                          return temp;
                                        });
                                        setMatches((current) => {
                                          const temp = current;
                                          temp[index].playerTwo = "";
                                          return temp;
                                        });
                                      }}
                                      className="red-icon-bg ml-10"
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <DeleteForeverIcon
                        onClick={() => {
                          // DELETE MATCH
                          setMatches((c) => {
                            const filt = c.filter(
                              (mch) => mch.matchID !== match.matchID
                            );
                            return filt;
                          });
                          setSelectedPlayers((c) => {
                            const filt = c.filter(
                              (pl) =>
                                pl !== match.playerOne.playerID &&
                                pl !== match.playerTwo.playerID
                            );
                            return filt;
                          });
                          setUpdate((c) => c + 1);
                        }}
                        className="red-icon-bg icon-cursor mb-10"
                      />
                    </div>
                  </>
                ) : (
                  <div className="sm-box">
                    <div className="doubles-match singles-match">
                      <div className="doubles-left">
                        {match.teamOne && match.teamOne[0] === "" ? (
                          <>
                            <PlayerItem
                              noLink
                              name="Select Player"
                              hideHCP
                              hideImage
                              hideProfile
                              hideMessage
                              hideRemove
                              showAdd
                              editAction={() => {
                                setTeam(0);
                                setMatch(index);
                                setPlayerNum(0);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div
                                className="cm-pc"
                                onClick={() => {
                                  if (display) {
                                    return;
                                  }
                                  setTeam(0);
                                  setMatch(index);
                                  setPlayerNum(0);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                  //   setShowFormats(true);
                                }}
                              >
                                <input
                                  className="default-input"
                                  disabled
                                  value={"Select player..."}
                                />
                                {!display && (
                                  <Edit
                                    className="dg-icon-bg icon-cursor ml-20"
                                    onClick={() => {
                                      setTeam(0);
                                      setMatch(index);
                                      setPlayerNum(0);
                                      setShowDialog(true);
                                      setUpdate((current) => current + 1);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <PlayerItem
                              playerID={match.teamOne[0].playerID}
                              // noLink
                              name={match.teamOne[0].name}
                              hideHCP
                              hideImage
                              hideProfile
                              hideMessage
                              removeAction={() => {
                                setSelectedPlayers((current) => {
                                  const temp = current.filter(
                                    (item) => item !== match.teamOne[0].playerID
                                  );
                                  return temp;
                                });
                                setMatches((current) => {
                                  const temp = current;
                                  temp[index].teamOne[0] = "";
                                  return temp;
                                });
                              }}
                              showAdd
                              editAction={() => {
                                setTeam(0);
                                setPlayerNum(0);
                                setMatch(index);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div className="cm-pc">
                                <div
                                  className="cm-input-wrapper"
                                  onClick={() => {
                                    if (display) {
                                      return;
                                    }
                                    setTeam(0);
                                    setPlayerNum(0);
                                    setMatch(index);
                                    setShowDialog(true);
                                    setUpdate((current) => current + 1);
                                    //   setShowFormats(true);
                                  }}
                                >
                                  <input
                                    className="default-input"
                                    disabled
                                    value={
                                      match.teamOne && match.teamOne[0].name
                                    }
                                  />
                                  {!display && (
                                    <Edit
                                      className="dg-icon-bg icon-cursor ml-20"
                                      onClick={() => {
                                        setTeam(0);
                                        setPlayerNum(0);
                                        setMatch(index);
                                        setShowDialog(true);
                                        setUpdate((current) => current + 1);
                                      }}
                                    />
                                  )}
                                </div>
                                {!display && (
                                  <DeleteForeverIcon
                                    onClick={() => {
                                      setSelectedPlayers((current) => {
                                        const temp = current.filter(
                                          (item) =>
                                            item !== match.teamOne[0].playerID
                                        );
                                        return temp;
                                      });
                                      setMatches((current) => {
                                        const temp = current;
                                        temp[index].teamOne[0] = "";
                                        return temp;
                                      });
                                    }}
                                    className="cm-remove"
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                        {match.teamOne && match.teamOne[1] === "" ? (
                          <>
                            {" "}
                            <PlayerItem
                              noLink
                              name="Select Player"
                              hideHCP
                              hideImage
                              hideProfile
                              hideMessage
                              hideRemove
                              showAdd
                              editAction={() => {
                                setTeam(0);
                                setMatch(index);
                                setPlayerNum(1);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div
                                className="cm-pc"
                                onClick={() => {
                                  if (display) {
                                    return;
                                  }
                                  setTeam(0);
                                  setMatch(index);
                                  setPlayerNum(1);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                  //   setShowFormats(true);
                                }}
                              >
                                <input
                                  className="default-input"
                                  disabled
                                  value={"Select player..."}
                                />
                                {!display && (
                                  <Edit
                                    className="dg-icon-bg icon-cursor ml-20"
                                    onClick={() => {
                                      setTeam(0);
                                      setMatch(index);
                                      setPlayerNum(1);
                                      setShowDialog(true);
                                      setUpdate((current) => current + 1);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <PlayerItem
                              // noLink
                              playerID={match.teamOne[1].playerID}
                              name={match.teamOne[1].name}
                              hideHCP
                              hideImage
                              hideProfile
                              hideMessage
                              removeAction={() => {
                                setSelectedPlayers((current) => {
                                  const temp = current.filter(
                                    (item) => item !== match.teamOne[1].playerID
                                  );
                                  return temp;
                                });
                                setMatches((current) => {
                                  const temp = current;
                                  temp[index].teamOne[1] = "";
                                  return temp;
                                });
                              }}
                              showAdd
                              editAction={() => {
                                setTeam(0);
                                setPlayerNum(1);
                                setMatch(index);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div className="cm-pc">
                                <div
                                  className="cm-input-wrapper"
                                  onClick={() => {
                                    if (display) {
                                      return;
                                    }
                                    setTeam(0);
                                    setPlayerNum(1);
                                    setMatch(index);
                                    setShowDialog(true);
                                    setUpdate((current) => current + 1);
                                    //   setShowFormats(true);
                                  }}
                                >
                                  <input
                                    className="default-input"
                                    disabled
                                    value={
                                      match.teamOne && match.teamOne[1].name
                                    }
                                  />
                                  {!display && (
                                    <Edit
                                      className="dg-icon-bg icon-cursor ml-20"
                                      onClick={() => {
                                        setTeam(0);
                                        setPlayerNum(1);
                                        setMatch(index);
                                        setShowDialog(true);
                                        setUpdate((current) => current + 1);
                                      }}
                                    />
                                  )}
                                </div>
                                {!display && (
                                  <DeleteForeverIcon
                                    onClick={() => {
                                      setSelectedPlayers((current) => {
                                        const temp = current.filter(
                                          (item) =>
                                            item !== match.teamOne[1].playerID
                                        );
                                        return temp;
                                      });
                                      setMatches((current) => {
                                        const temp = current;
                                        temp[index].teamOne[1] = "";
                                        return temp;
                                      });
                                    }}
                                    className="cm-remove"
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="doubles-middle singles-middle">
                        <h4>vs</h4>
                      </div>
                      <div className="doubles-right">
                        {match.teamTwo[0] === "" ? (
                          <>
                            <PlayerItem
                              noLink
                              name={"Select Player"}
                              hideHCP
                              hideRemove
                              hideImage
                              hideProfile
                              hideMessage
                              showAdd
                              editAction={() => {
                                setTeam(1);
                                setMatch(index);
                                setPlayerNum(0);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div
                                className="cm-pc"
                                onClick={() => {
                                  if (display) {
                                    return;
                                  }
                                  setTeam(1);
                                  setMatch(index);
                                  setPlayerNum(0);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                  //   setShowFormats(true);
                                }}
                              >
                                {!display && (
                                  <Edit
                                    className="dg-icon-bg icon-cursor ml-20"
                                    onClick={() => {
                                      setTeam(1);
                                      setMatch(index);
                                      setPlayerNum(0);
                                      setShowDialog(true);
                                      setUpdate((current) => current + 1);
                                    }}
                                  />
                                )}
                                <input
                                  className="default-input"
                                  disabled
                                  value={"Select player..."}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <PlayerItem
                              // noLink
                              playerID={match.teamTwo[0].playerID}
                              name={match.teamTwo[0].name}
                              hideHCP
                              hideImage
                              hideProfile
                              hideMessage
                              removeAction={() => {
                                setSelectedPlayers((current) => {
                                  const temp = current.filter(
                                    (item) => item !== match.teamTwo[0].playerID
                                  );
                                  return temp;
                                });
                                setMatches((current) => {
                                  const temp = current;
                                  temp[index].teamTwo[0] = "";
                                  return temp;
                                });
                              }}
                              showAdd
                              editAction={() => {
                                setTeam(1);
                                setPlayerNum(0);
                                setMatch(index);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div className="cm-pc">
                                <div
                                  className="cm-input-wrapper"
                                  onClick={() => {
                                    if (display) {
                                      return;
                                    }
                                    setTeam(1);
                                    setPlayerNum(0);
                                    setMatch(index);
                                    setShowDialog(true);
                                    setUpdate((current) => current + 1);
                                    //   setShowFormats(true);
                                  }}
                                >
                                  {!display && (
                                    <Edit
                                      className="dg-icon-bg icon-cursor ml-20"
                                      onClick={() => {
                                        setTeam(1);
                                        setPlayerNum(0);
                                        setMatch(index);
                                        setShowDialog(true);
                                        setUpdate((current) => current + 1);
                                      }}
                                    />
                                  )}
                                  <input
                                    className="default-input"
                                    disabled
                                    value={match.teamTwo[0].name}
                                  />
                                </div>
                                {!display && (
                                  <DeleteForeverIcon
                                    onClick={() => {
                                      setSelectedPlayers((current) => {
                                        const temp = current.filter(
                                          (item) =>
                                            item !== match.teamTwo[0].playerID
                                        );
                                        return temp;
                                      });
                                      setMatches((current) => {
                                        const temp = current;
                                        temp[index].teamTwo[0] = "";
                                        return temp;
                                      });
                                    }}
                                    className="cm-remove"
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                        {match.teamTwo[1] === "" ? (
                          <>
                            <PlayerItem
                              noLink
                              name={"Select Player"}
                              hideHCP
                              hideImage
                              hideProfile
                              hideRemove
                              hideMessage
                              showAdd
                              editAction={() => {
                                setTeam(1);
                                setMatch(index);
                                setPlayerNum(1);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div
                                className="cm-pc"
                                onClick={() => {
                                  if (display) {
                                    return;
                                  }
                                  setTeam(1);
                                  setMatch(index);
                                  setPlayerNum(1);
                                  setShowDialog(true);
                                  setUpdate((current) => current + 1);
                                  //   setShowFormats(true);
                                }}
                              >
                                {!display && (
                                  <Edit
                                    className="dg-icon-bg icon-cursor ml-20"
                                    onClick={() => {
                                      setTeam(1);
                                      setMatch(index);
                                      setPlayerNum(1);
                                      setShowDialog(true);
                                      setUpdate((current) => current + 1);
                                    }}
                                  />
                                )}
                                <input
                                  className="default-input"
                                  disabled
                                  value={"Select player..."}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <PlayerItem
                              playerID={match.teamTwo[1].playerID}
                              name={match.teamTwo[1].name}
                              hideHCP
                              hideImage
                              hideProfile
                              hideMessage
                              removeAction={() => {
                                setSelectedPlayers((current) => {
                                  const temp = current.filter(
                                    (item) => item !== match.teamTwo[1].playerID
                                  );
                                  return temp;
                                });
                                setMatches((current) => {
                                  const temp = current;
                                  temp[index].teamTwo[1] = "";
                                  return temp;
                                });
                              }}
                              showAdd
                              editAction={() => {
                                setTeam(1);
                                setPlayerNum(1);
                                setMatch(index);
                                setShowDialog(true);
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {false && (
                              <div className="cm-pc">
                                <div
                                  className="cm-input-wrapper"
                                  onClick={() => {
                                    if (display) {
                                      return;
                                    }
                                    setTeam(1);
                                    setPlayerNum(1);
                                    setMatch(index);
                                    setShowDialog(true);
                                    setUpdate((current) => current + 1);
                                    //   setShowFormats(true);
                                  }}
                                >
                                  {!display && (
                                    <Edit
                                      className="dg-icon-bg icon-cursor ml-20"
                                      onClick={() => {
                                        setTeam(1);
                                        setPlayerNum(1);
                                        setMatch(index);
                                        setShowDialog(true);
                                        setUpdate((current) => current + 1);
                                      }}
                                    />
                                  )}
                                  <input
                                    className="default-input"
                                    disabled
                                    value={match.teamTwo[1].name}
                                  />
                                </div>
                                {!display && (
                                  <DeleteForeverIcon
                                    onClick={() => {
                                      setSelectedPlayers((current) => {
                                        const temp = current.filter(
                                          (item) =>
                                            item !== match.teamTwo[1].playerID
                                        );
                                        return temp;
                                      });
                                      setMatches((current) => {
                                        const temp = current;
                                        temp[index].teamTwo[1] = "";
                                        return temp;
                                      });
                                    }}
                                    className="cm-remove"
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <DeleteForeverIcon
                      onClick={() => {
                        // DELETE MATCH
                        setMatches((c) => {
                          const filt = c.filter(
                            (mch) => mch.matchID !== match.matchID
                          );
                          return filt;
                        });
                        setSelectedPlayers((c) => {
                          const filt = c.filter(
                            (pl) =>
                              pl !== match.teamOne[0].playerID &&
                              pl !== match.teamOne[1].playerID &&
                              pl !== match.teamTwo[0].playerID &&
                              pl !== match.teamTwo[1].playerID
                          );
                          return filt;
                        });
                        setUpdate((c) => c + 1);
                      }}
                      className="red-icon-bg icon-cursor mb-10"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CupMatchups;
