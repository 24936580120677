import React, { useState, useEffect } from "react";

// Icons

// Components
import FixturePreview from "../../display/FixturePreview/FixturePreview";
import Switch from "react-ios-switch";
import EventHome from "../../Events/EventCreator/EventHome";
import GroupSelector from "../../Events/GroupSelector/GroupSelector";
import InviteModal from "../../Events/InviteModal/InviteModal";
import EventRounds from "../../Events/EventCreator/EventRounds";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import TeamLeagueEventHome from "../../ClubLeagues/TeamLeagueEventHome";

function LeagueEvents({
  pastEvents = [],
  futureEvents = [],
  loading = true,
  setToShow = () => {},
  setShow = () => {},
  teamLeague = false,
}) {
  // State
  const [checked, setChecked] = useState("upcoming");

  return (
    <div className="league-events">
      <>
        <div className="switch-row">
          <RadioGroupTwo
            active={checked}
            buttons={[
              {
                text: "Past",
                value: "past",
              },
              {
                text: "Upcoming",
                value: "upcoming",
              },
            ]}
            setActive={(e) => {
              setChecked(e);
            }}
          />
        </div>
        {checked === "upcoming" ? (
          <>
            <div className="sub-header">
              <h3>Upcoming Events</h3>
            </div>
            <div className="fp-cont">
              {futureEvents.map((event, index) => (
                <FixturePreview
                  showLive={event.roundIDs.length > 0}
                  key={event.eventID}
                  past={false}
                  hidePerson={event.roundIDs.length > 0 || teamLeague}
                  hideRounds={event.roundIDs.length > 0 || teamLeague}
                  event={event}
                  showDetails={() => {}}
                  editAction={() => {
                    if (teamLeague) {
                      setToShow(
                        <TeamLeagueEventHome
                          goBack={() => setShow(false)}
                          eventID={event.eventID}
                        />
                      );
                    } else {
                      setToShow(
                        <EventHome
                          eventID={event.eventID}
                          goBack={() => setShow(false)}
                        />
                      );
                    }
                    setShow(true);
                  }}
                  personAction={() => {
                    setToShow(
                      <InviteModal
                        eventID={event.eventID}
                        hide={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                  timeAction={() => {
                    setToShow(
                      <EventRounds
                        eventID={event.eventID}
                        defaultRounds={event.rounds}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="sub-header">
              <h3>Past Events</h3>
            </div>
            <div className="fp-cont">
              {pastEvents.map((event, index) => (
                <FixturePreview
                  showLive={event.roundIDs.length > 0}
                  hidePerson={event.roundIDs.length > 0}
                  hideRounds={event.roundIDs.length > 0}
                  key={event.eventID}
                  past
                  event={event}
                  editAction={() => {
                    if (teamLeague) {
                      setToShow(
                        <TeamLeagueEventHome
                          goBack={() => setShow(false)}
                          eventID={event.eventID}
                        />
                      );
                    } else {
                      setToShow(
                        <EventHome
                          eventID={event.eventID}
                          goBack={() => setShow(false)}
                        />
                      );
                    }
                    setShow(true);
                  }}
                  showDetails={() => {}}
                />
              ))}
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default LeagueEvents;
