import React, { useState, useEffect } from "react";

// Icons

// Components
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import BackRow from "../display/Rows/BackRow";
import Switch from "react-ios-switch/lib/Switch";
import ErrorModal from "../ErrorModal/ErrorModal";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";

function LeaderboardSettings({ type = "evt", id = "", goBack = () => {} }) {
  // Admin
  const settProp = {
    hidePutts: false,
    hideFIR: false,
    showCountry: false,
    showImage: false,
    hideNet: false,
    hidePoints: false,
    hideLiveStats: false,
    hideRounds: false,
    enableChat: false,
    showPlayerClub: false,
  };

  // State
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(settProp);
  const [upd, setUpd] = useState(0);
  const [multRounds, setMultRounds] = useState(false);
  function update() {
    setUpd((c) => c + 1);
  }
  const [error, setError] = useState("");

  // UE
  useEffect(() => {
    loadEventSettings();
  }, []);

  // Functions
  async function loadEventSettings() {
    const eventDoc = await db.collection("events").doc(id).get();
    const event = eventDoc.data();

    const leaderboardSettings = event.leaderboardSettings ?? settProp;
    setMultRounds(event.rounds.length > 1);
    setSettings(leaderboardSettings);
    setLoading(false);
  }

  async function saveEventChanges() {
    setLoading(true);
    try {
      await db.collection("events").doc(id).update({
        leaderboardSettings: settings,
      });
      toast.success("Settings were successfully changed", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again."
      );
    }

    setLoading(false);
    goBack();
  }

  return (
    <div className="leaderboard-settings">
      <BackRow action={goBack} />
      <PageHeader text="Leaderboard Settings" />

      {loading && <BackdropLoader />}

      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      {type === "evt" && (
        <div className="ph-msg">
          <p>Edit your leaderboard settings for this event.</p>
        </div>
      )}

      <div className="sub-header">
        <h2 className="ne-header">Display</h2>
      </div>

      <div className="ec-box">
        <div className="input-group nassau-group">
          <p>Hide Putts</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.hidePutts}
            className={
              settings.hidePutts ? "scoring-switch switch-on" : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.hidePutts = !c.hidePutts;
                return c;
              });
              update();
            }}
          />
        </div>
        <div className="input-group nassau-group">
          <p>Hide FIR</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.hideFIR}
            className={
              settings.hideFIR ? "scoring-switch switch-on" : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.hideFIR = !c.hideFIR;
                return c;
              });
              update();
            }}
          />
        </div>
        <div className="input-group nassau-group">
          <p>Hide Net</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.hideNet}
            className={
              settings.hideNet ? "scoring-switch switch-on" : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.hideNet = !c.hideNet;
                return c;
              });
              update();
            }}
          />
        </div>
        <div className="input-group nassau-group">
          <p>Hide Points</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.hidePoints}
            className={
              settings.hidePoints
                ? "scoring-switch switch-on"
                : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.hidePoints = !c.hidePoints;
                return c;
              });
              update();
            }}
          />
        </div>
        {false && (
          <div className="input-group nassau-group">
            <p>Display Player Country</p>
            <Switch
              onColor="#1e7a69"
              checked={settings.showCountry}
              className={
                settings.showCountry
                  ? "scoring-switch switch-on"
                  : "scoring-switch"
              }
              onChange={() => {
                setSettings((c) => {
                  c.showCountry = !c.showCountry;
                  return c;
                });
                update();
              }}
            />
          </div>
        )}{" "}
        <div className="input-group nassau-group">
          <p>Display Player Image</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.showImage}
            className={
              settings.showImage ? "scoring-switch switch-on" : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.showImage = !c.showImage;
                return c;
              });
              update();
            }}
          />
        </div>
        <div className="input-group nassau-group">
          <p>Display Player Club Name</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.showPlayerClub}
            className={
              settings.showPlayerClub
                ? "scoring-switch switch-on"
                : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.showPlayerClub = !c.showPlayerClub;
                return c;
              });
              update();
            }}
          />
        </div>
      </div>

      <div className="sub-header">
        <h2 className="ne-header">Leaderboards</h2>
      </div>

      <div className="ec-box">
        <div className="input-group nassau-group">
          <p>Hide Live Stats</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.hideLiveStats}
            className={
              settings.hideLiveStats
                ? "scoring-switch switch-on"
                : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.hideLiveStats = !c.hideLiveStats;
                return c;
              });
              update();
            }}
          />
        </div>
        {multRounds && (
          <div className="input-group nassau-group">
            <p>Hide Rounds Overview</p>
            <Switch
              onColor="#1e7a69"
              checked={settings.hideRounds}
              className={
                settings.hideRounds
                  ? "scoring-switch switch-on"
                  : "scoring-switch"
              }
              onChange={() => {
                setSettings((c) => {
                  c.hideRounds = !c.hideRounds;
                  return c;
                });
                update();
              }}
            />
          </div>
        )}
      </div>

      <div className="sub-header">
        <h2 className="ne-header">Other</h2>
      </div>

      <div className="ec-box">
        <div className="input-group nassau-group">
          <p>Enable Event Chat</p>
          <Switch
            onColor="#1e7a69"
            checked={settings.enableChat}
            className={
              settings.enableChat
                ? "scoring-switch switch-on"
                : "scoring-switch"
            }
            onChange={() => {
              setSettings((c) => {
                c.enableChat = !c.enableChat;
                return c;
              });
              update();
            }}
          />
        </div>
      </div>

      <div className="flex flex-center">
        <button onClick={saveEventChanges} className="default-button">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default LeaderboardSettings;
