import React, { useEffect, useState, useCallback, useRef } from "react";
import "./Clubs.css";
import { db, storage } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import AddCircle from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";

// Components
import { Backdrop, Dialog, Tabs, Tab } from "@mui/material";
import ManualPlayerAdd from "../../components/Events/EventCreator/ManualPlayerAdd";
import Compressor from "compressorjs";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import CourseSearch from "../../components/Events/CoursePicker/CourseSearch";
import CourseItem from "../../components/display/CourseItem/CourseItem";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { useDropzone } from "react-dropzone";
import ImageSelector from "../../components/Events/ImageSelector/ImageSelector";
import Pill from "../../components/Buttons/Pill";
import ClubCourse from "./ClubCourse";
import Close from "@mui/icons-material/Close";
import RadioGroup from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import { Country, City, State } from "country-state-city";
import Switch from "react-ios-switch/lib/Switch";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PageHeader from "../../components/display/Rows/PageHeader";

function ClubEditor({
  clubID = "",
  edit = false,
  goBack = () => {},
  reload = () => {},
}) {
  const { currentUser, userDetails, portalSettings } = useAuth();

  const cityRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();

  const [primaryColour, setPrimaryColour] = useState("");
  const [secondaryColor, setSecondaryColour] = useState("");
  const [joinCode, setJoinCode] = useState("");
  const [requireID, setRequireID] = useState(false);
  const [selTab, setSelTab] = useState("details");
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [privateClub, setPrivate] = useState(false);
  const [showImages, setShowImages] = useState(false);

  const [addressType, setAddressType] = useState("manual");

  const [showAdd, setShowAdd] = useState(false);
  const [showAddPlayers, setShowAddPlayers] = useState(false);

  const [defaultClub, setDefaultClub] = useState(false);

  const [showRoles, setShowRoles] = useState(false);
  const [roles, setRoles] = useState([]);

  const [clubName, setClubName] = useState("");
  const [shortName, setShortName] = useState("");
  const [courses, setCourses] = useState([]);
  const [selCourse, setSelCourse] = useState("");
  const [admins, setAdmins] = useState([]);
  const [friends, setFriends] = useState([]);
  const [members, setMembers] = useState([]);
  const [selected, setSelected] = useState([]);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [selCountry, setSelCountry] = useState("");
  const [selCity, setSelCity] = useState("");
  const [selState, setSelState] = useState("");

  const [showCrop, setShowCrop] = useState(false);

  const [update, setUpdate] = useState(0);

  const [useColour, setUseColour] = useState(false);

  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});
  const [error, setError] = useState("");

  const [address, setAddress] = useState("");

  const [playerMap, setPlayerMap] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    const url = URL.createObjectURL(acceptedFiles[0]);
    // console.log(typeof acceptedFiles[0]);
    setImageURL(url);
    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        setImage(res);
        setShowCrop(true);
      },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  async function cropImage() {
    const image = new Image();
    console.log(imageURL);
    image.src = imageURL;
    const crop = fc;
    console.log(image);
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }

  useEffect(() => {
    if (clubID !== "") {
      loadDetails();
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      loadFriends();
      if (!edit) {
        sortLocation();
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (portalSettings) {
      if (portalSettings.clubID === clubID) {
        setDefaultClub(clubID);
      }
    }
  }, [portalSettings]);

  function loadLocations({ country, city, state }) {
    // console.log(userCity, userCountry, userState);
    let stateCode = "";
    // Country
    const allCunts = Country.getAllCountries().map((country) => {
      const item = {
        name: country.name,
        value: country.name,
        code: country.isoCode,
      };
      return item;
    });
    setCountries(allCunts);

    // State
    const allStates = State.getStatesOfCountry(country);
    setStates(allStates);

    for (let i = 0; i < allStates.length; i++) {
      if (allStates[i].name === state) {
        stateCode = allStates[i].isoCode;
      }
    }

    // City
    const cities = City.getCitiesOfState(country, stateCode);
    setCities(cities);
  }

  async function loadDetails() {
    setLoading(true);
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const {
      clubName,
      courseIDs,
      shortName,
      memberIDs,
      adminIDs,
      imageLink,
      city,
      country,
      state,
    } = club;
    setJoinCode(club.joinCode ?? "");
    setRequireID(club.requireID ?? "");
    setImageURL(imageLink);
    loadLocations({ country, city, state });
    setPrimaryColour(club.primaryColour ?? "");
    setSecondaryColour(club.secondaryColor ?? "");
    setPrivate(club.private ?? false);
    setUseColour(club.useColour);

    setSelCountry(country);
    if (country !== "") {
      setSelCity(city);
      setSelState(state);
    }
    // console.log(country);
    // const cunt = Country.getCountryByCode(country).name;
    // const allCunts = Country.getAllCountries().map((country) => {
    //   const item = {
    //     name: country.name,
    //     value: country.name,
    //     code: country.isoCode,
    //   };
    //   return item;
    // });
    // console.log(country, cunt);
    // setCountries(allCunts);
    // setSelCountry(country);
    // loadStates(country);
    // loadCities(state, country);
    await loadMembers({ memberIDs, adminIDs });
    const courseRefs = courseIDs.map((courseID) =>
      db.collection("courses").doc(courseID).get()
    );
    const courseDocs = await Promise.all(courseRefs);
    const carr = [];

    for (let i = 0; i < courseDocs.length; i++) {
      const courseDoc = courseDocs[i];
      const course = courseDoc.data();
      const courseID = courseDoc.id;
      course.courseID = courseID;
      carr.push(course);
    }
    setCourses(carr);
    setClubName(clubName);
    setShortName(shortName);
    setLoading(false);
  }

  async function loadMembers({ memberIDs = [], adminIDs = [] }) {
    const arr = [];
    const marr = [];
    const links = memberIDs.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(links);
    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      if (!playerDoc.exists) {
        continue;
      }
      const player = playerDoc.data();
      const playerID = playerDoc.id;

      const { firstName, lastName, profileImage, handicap } = player;
      marr.push({
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
      });
      if (adminIDs.includes(playerID)) {
        arr.push({
          name: `${firstName} ${lastName}`,
          playerID,
          profileImage,
          handicap,
        });
      }
    }
    setAdmins(arr);
    setMembers(marr);
  }

  function loadStates(cunt) {
    const states = State.getStatesOfCountry(cunt);
    const countryDeets = Country.getCountryByCode(cunt);
    // setSelCountry(countryDeets.name);
    console.log(selState);
    setStates(states);
  }

  async function loadCities(state, count = "") {
    console.log(state, count);
    let toUse = selCountry;
    if (count !== "") {
      toUse = count;
    }
    const test = State.getStatesOfCountry(toUse);
    let sc = "";
    let cc = "";
    for (let i = 0; i < test.length; i++) {
      if (test[i].name === state) {
        sc = test[i].isoCode;
        cc = test[i].countryCode;
      }
    }
    const cities = City.getCitiesOfState(cc, sc);

    const newArr = cities.map((city) => {
      const item = {
        name: city.name,
        value: city.name,
      };
      return item;
    });

    // setSelState(sc);

    setUpdate((current) => current + 1);
    setCities(newArr);
  }

  async function loadFriends() {
    const { friends } = userDetails;
    const links = friends.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(links);

    const arr = [];

    const mep = {};

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      if (!playerDoc.exists) {
        continue;
      }
      const { firstName, lastName, profileImage, handicap } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        playerID,
        profileImage,
        handicap,
      });
      mep[playerID] = {
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
        firstName,
        lastName,
      };
    }
    setPlayerMap(mep);
    setFriends(arr);
  }

  function validate() {
    setError("");
    // Name
    if (clubName === "") {
      setError("Please enter a name for this club");
      return false;
    }

    // Course address
    if (addressType === "course" && courses.length === 0) {
      setError("Please select a course to use for for your club.");
    }
    if (addressType === "course" && selCourse === "") {
      setError(
        "Please select a course to use for as an address for your club."
      );
    }
    return true;
  }

  async function saveClub() {
    setLoading(true);
    if (validate() === false) {
      return setLoading(false);
    }

    const adminIDs = admins.map((admin) => admin.playerID);
    if (!adminIDs.includes(currentUser.uid)) {
      adminIDs.push(currentUser.uid);
    }

    const courseIDs = courses.map((course) => course.courseID);

    if (defaultClub) {
      portalSettings.clubID = clubID;
      await db.collection("users").doc(currentUser.uid).update({
        portalSettings,
      });
    }

    let city = selCity;
    let state = selState;
    let country = selCountry;
    let addressInput = address;

    if (addressType === "course") {
      for (let i = 0; i < courses.length; i++) {
        const course = courses[i];
        if (course.courseID === selCourse) {
          city = course.city;
          state = course.state;
          country = course.country;
          addressInput = course.address;
        }
      }
    }

    try {
      const clubDoc = await db.collection("clubs").add({
        clubName,
        shortName,
        city,
        joinCode,
        state,
        private: privateClub,
        sponsors: [],
        country,
        address: addressInput,
        memberIDs: adminIDs,
        leagueIDs: [],
        eventIDs: [],
        dateCreated: new Date(),
        adminIDs,
        status: "pending",
        courseIDs,
        primaryColour,
        secondaryColor,
        requireID,
        useColour,
      });

      // await db.collection("users").doc(currentUser.uid).update({
      //   portal: true,
      //   portalType: "ptp",
      // })

      if (image !== "") {
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], `${clubID}image.jpg`, {
              contentType,
            });
            // access file here
            const task = storage
              .ref()
              .child(`${clubDoc.id}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("clubs").doc(clubDoc.id).update({
                  imageLink: url,
                });
              });
            });
          });
        } else {
          const task = storage
            .ref()
            .child(`${clubDoc.id}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("clubs").doc(clubDoc.id).update({
                imageLink: url,
              });
            });
          });
        }
      }
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    if (validate() === false) {
      return setLoading(false);
    }
    const adminIDs = admins.map((admin) => admin.playerID);
    if (!adminIDs.includes(currentUser.uid)) {
      adminIDs.push(currentUser.uid);
    }

    const courseIDs = courses.map((course) => course.courseID);

    if (defaultClub && portalSettings.clubID !== clubID) {
      portalSettings.clubID = clubID;
      await db.collection("users").doc(currentUser.uid).update({
        portalSettings,
      });
    } else {
      if (!defaultClub && portalSettings.clubID === clubID) {
        portalSettings.clubID = "";
        await db.collection("users").doc(currentUser.uid).update({
          portalSettings,
        });
      }
    }

    let city = selCity;
    let state = selState;
    let country = selCountry;
    let addressInput = address;

    if (addressType === "course") {
      for (let i = 0; i < courses.length; i++) {
        const course = courses[i];
        if (course.courseID === selCourse) {
          city = course.city;
          state = course.state;
          country = course.country;
          addressInput = course.address;
        }
      }
    }

    try {
      await db.collection("clubs").doc(clubID).update({
        clubName,
        shortName,
        city,
        private: privateClub,
        state,
        country,
        address: addressInput,
        adminIDs,
        courseIDs,
        primaryColour,
        secondaryColor,
        joinCode,
        requireID,
        useColour,
      });

      if (image !== "") {
        console.log("UPLOAD IMAGE");
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], `${clubID}image.jpg`, {
              contentType,
            });
            // access file here
            const task = storage
              .ref()
              .child(`${clubID}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("clubs").doc(clubID).update({
                  imageLink: url,
                });
              });
            });
          });
        } else {
          console.log("OBJECT");
          const task = storage
            .ref()
            .child(`${clubID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              console.log(url);
              db.collection("clubs").doc(clubID).update({
                imageLink: url,
              });
            });
          });
        }
      }
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  function sortLocation() {
    const userCity = userDetails.city;
    const userCountry = userDetails.country;
    const userState = userDetails.state;
    // console.log(userCity, userCountry, userState);
    let stateCode = "";
    // Country
    const allCunts = Country.getAllCountries().map((country) => {
      const item = {
        name: country.name,
        value: country.name,
        code: country.isoCode,
      };
      return item;
    });
    setCountries(allCunts);
    setSelCountry(userCountry);

    // State
    const allStates = State.getStatesOfCountry(userCountry);
    setStates(allStates);
    setSelState(userState);

    for (let i = 0; i < allStates.length; i++) {
      if (allStates[i].name === userState) {
        stateCode = allStates[i].isoCode;
      }
    }

    // City
    const cities = City.getCitiesOfState(userCountry, stateCode);
    setCities(cities);
    setSelCity(userCity);
    setLoading(false);
  }

  return (
    <div className="club-editor">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {showCrop && (
        <Backdrop className="crop-drop" open>
          <div className="crop-co">
            <div className="cd-close">
              <Close onClick={() => setShowCrop(false)} />
            </div>
            <div className="crop-box">
              <Cropper
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                image={imageURL}
              />
            </div>
            <div className="flex-center crop-but">
              <button
                onClick={() => {
                  cropImage();
                  setShowCrop(false);
                }}
                className="default-button"
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {!edit && showAddPlayers && (
        <ManualPlayerAdd
          hide={() => setShowAddPlayers(false)}
          players={friends}
          hideFilters
          custom
          preSelected={selected}
          customAction={(e) => {
            setSelected(e);
            setUpdate((current) => current + 1);
          }}
        />
      )}

      <Dialog open={showAdd} onClose={() => setShowAdd(false)}>
        <div className="ce-admins">
          <PageHeader
            text="Add Admins"
            close={() => setShowAdd(false)}
            showClose
          />
          <div className="input-group mb-20">
            <p>Search</p>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={"Start typing..."}
              className="default-input"
            />
          </div>
          <div className="cea-players">
            {members.map((player) => {
              if (search !== "") {
                if (!player.name.toLowerCase().includes(search.toLowerCase())) {
                  return null;
                }
              }

              let found = false;
              for (let i = 0; i < admins.length; i++) {
                if (admins[i].playerID === player.playerID) {
                  found = true;
                }
              }
              return (
                <PlayerItem
                  key={player.playerID}
                  name={player.name}
                  noLink
                  hideIcons
                  showProfile={() => {
                    setAdmins((current) => {
                      if (found) {
                        const filtered = current.filter(
                          (admin) => admin.playerID !== player.playerID
                        );
                        return filtered;
                      } else {
                        current.push(player);
                        return current;
                      }
                    });
                    setUpdate((current) => current + 1);
                  }}
                  showBg={found}
                  showAdd
                  hideEdit
                  img={player.profileImage}
                  hideHCP
                />
              );
            })}
          </div>
          {/* <div className="flex-center mt-40">
            <button className="default-button">Save</button>
          </div> */}
        </div>
      </Dialog>
      <Dialog open={showRoles} onClose={() => setShowRoles(false)}>
        <div className="ce-role-add">
          <div className="page-header">
            <h2>Add Role</h2>
          </div>
          <div className="cera-inputs">
            <div className="input-group">
              <p>Role</p>
              <input placeholder="Enter role type..." />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="info-message">
          <div className="svg-cont">
            <InfoIcon className="big-info-icon dg-icon" />
          </div>

          <p>{dialog}</p>
        </div>
      </Dialog>
      {showSearch ? (
        <CourseSearch
          defaultSelected={courses.map((course) => course.courseID)}
          goBack={() => setShowSearch(false)}
          multiple
          setCourseDetails={(e) => setCourses((current) => [...current, ...e])}
        />
      ) : showImages ? (
        <ImageSelector
          goBack={() => {
            setShowImages(false);
          }}
          setSelectedImage={(e) => {
            setImageURL(e);
            setImage(e);
          }}
          selectedImage={image}
          type="event"
        />
      ) : (
        <>
          <div className="back-row" onClick={goBack}>
            <ArrowBack />
            <p>Back</p>
          </div>
          <PageHeader text={edit ? "Club Editor" : "Club Creator"} />
          {/* <div className="page-header">
            <h2>{edit ? "Club Editor" : "Club Creator"}</h2>
          </div> */}
          <div className="ec-tabs">
            <Tabs value={selTab} onChange={(e, value) => setSelTab(value)}>
              <Tab label="Details" value="details" />
              <Tab label="Courses" value="courses" />
              <Tab label="Location" value="location" />
              <Tab label="Admin" value="admin" />
            </Tabs>
          </div>
          {selTab === "details" && (
            <>
              <div className="sub-header">
                <h5>Details</h5>
              </div>
              <div className="ph-msg mt-10">
                <p>Leave blank if option is irrelevant</p>
              </div>
              <div className="ec-box">
                <div className="input-group">
                  <p>Club Name</p>
                  <input
                    type="text"
                    value={clubName}
                    onChange={(e) => setClubName(e.target.value)}
                    className="default-input"
                    placeholder="Enter club name..."
                  />
                </div>
                <div className="input-group">
                  <div className="ig-header">
                    <p>Short Name</p>
                    <div className="svg-cont">
                      <InfoIcon
                        onClick={() => {
                          setDialog(
                            `This is the shorter version of your club name, which will appear when an abbreviated version is needed. E.g. August Country Club could become ACC.`
                          );
                          setShowDialog(true);
                        }}
                        className="info-icon dg-icon-bg"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    value={shortName}
                    onChange={(e) => setShortName(e.target.value)}
                    className="default-input"
                    placeholder="Enter club name..."
                  />
                </div>

                <div className="input-group colour-group">
                  <p>Primary Colour</p>
                  <input
                    type="color"
                    value={primaryColour}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setPrimaryColour(e.target.value);
                    }}
                    className="color-input"
                  />
                </div>
                <div className="input-group colour-group">
                  <p>Secondary Colour</p>
                  <input
                    type="color"
                    value={secondaryColor}
                    onChange={(e) => setSecondaryColour(e.target.value)}
                    className="color-input"
                  />
                </div>
                <div className="input-group ">
                  <p>Club Picture</p>
                  <div className="image-buttons">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dz-sel">
                        {imageURL !== "" && (
                          <div className="event-img">
                            <img className="" alt="profile" src={imageURL} />
                          </div>
                        )}
                        {imageURL === "" && (
                          <h5>
                            Drag 'n' drop an image here, or click to open up the
                            file explorer
                          </h5>
                        )}
                      </div>
                      <div className="dz-or">
                        <p className="">Or</p>
                      </div>
                    </div>
                    <Pill
                      text={"Choose Preset Image"}
                      onClick={() => setShowImages(true)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {selTab === "courses" && (
            <>
              <div className="sub-header">
                <div className="ig-header">
                  <h5>Courses</h5>
                  <InfoIcon
                    onClick={() => {
                      setDialog(
                        "Assign one or more courses as the default courses for your club. Statistics will be viewable for any courses added to your club (max 4)"
                      );
                      setShowDialog(true);
                    }}
                    className="info-icon dg-icon-bg"
                  />
                  {courses.length < 4 && (
                    <AddCircle
                      onClick={() => setShowSearch(true)}
                      className="dg-icon-bg icon-cursor ml-20"
                    />
                  )}
                </div>
              </div>
              {courses.length > 1 && addressType === "course" && (
                <div className="ph-msg mt-10">
                  <p>Select a course to use as your main location</p>
                </div>
              )}
              <div className="ec-box mt-20">
                {courses.map((course) => {
                  return (
                    <ClubCourse
                      showBox={courses.length > 1 && addressType === "course"}
                      course={course}
                      key={course.courseID}
                      setSelected={() =>
                        setSelCourse((current) => {
                          if (selCourse === course.courseID) {
                            return "";
                          } else {
                            return course.courseID;
                          }
                        })
                      }
                      selected={
                        course.courseID === selCourse &&
                        addressType === "course"
                      }
                      removeAction={(e) => {
                        setCourses((current) => {
                          const filtered = current.filter(
                            (item) => item.courseID !== course.courseID
                          );
                          return filtered;
                        });
                        setUpdate((current) => current + 1);
                      }}
                    />
                  );
                })}
              </div>{" "}
            </>
          )}
          {selTab === "location" && (
            <>
              <div className="sub-header">
                <h5>Location</h5>
              </div>
              {courses.length > 0 && (
                <div className="ec-box">
                  <div className="input-group">
                    <p>Select Location Type</p>
                    <RadioGroupTwo
                      active={addressType}
                      setActive={(e) => {
                        setAddressType(e);
                        if (e === "course") {
                          if (courses.length > 0 && selCourse === "") {
                            setSelCourse(courses[0].courseID);
                          }
                        }
                        setUpdate((c) => c + 1);
                      }}
                      buttons={[
                        { text: "Manual", value: "manual" },
                        { text: "Course", value: "course" },
                      ]}
                    />
                  </div>
                </div>
              )}
              {addressType === "manual" && (
                <div className="ec-box">
                  <div className="input-group select-group">
                    <p>Country</p>
                    <select
                      className="default-input default-select"
                      value={selCountry}
                      ref={countryRef}
                      onChange={(e) => {
                        loadStates(countryRef.current.value);
                        setSelCountry(countryRef.current.value);
                      }}
                    >
                      <option value=""></option>
                      {countries.map((country, i) => {
                        return (
                          <option
                            key={`${i}${country.code}`}
                            selected={country.code === selCountry}
                            value={country.code}
                          >
                            {country.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="input-group select-group">
                    <p>State / Province</p>
                    <select
                      className="default-input default-select"
                      value={selState}
                      disabled={selCountry === ""}
                      ref={stateRef}
                      onChange={(e) => {
                        setSelState(stateRef.current.value);
                        loadCities(stateRef.current.value);
                      }}
                    >
                      <option value=""></option>

                      {states.map((state) => {
                        return (
                          <option
                            key={state.name}
                            selected={state.name === selState}
                            value={state.code}
                          >
                            {state.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="input-group select-group">
                    <p>City</p>
                    <select
                      className="default-input default-select"
                      value={selCity}
                      disabled={selState === ""}
                      ref={cityRef}
                      onChange={(e) => {
                        setSelCity(cityRef.current.value);
                      }}
                    >
                      <option value=""></option>

                      {cities.map((city) => {
                        return (
                          <option
                            key={city.name}
                            selected={city.name === selCity}
                            value={city.name}
                          >
                            {city.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input-group">
                    <p>Address</p>
                    <input
                      type="text"
                      placeholder="Enter address..."
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="default-input"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {selTab === "admin" && (
            <>
              <div className="sub-header">
                <h5>Admin</h5>
              </div>
              <div className="ec-box">
                {false && (
                  <div className="input-group nassau-group">
                    <div className="ig-header">
                      <p>Default Club</p>
                      <div className="svg-cont">
                        <InfoIcon
                          onClick={() => {
                            setDialog(
                              `Set this club as your default club throughout your account. Any events, leagues or tournaments will automatically fall under ${clubName}'s umbrella`
                            );
                            setShowDialog(true);
                          }}
                          className="info-icon dg-icon-bg"
                        />
                      </div>
                    </div>
                    <Switch
                      onColor="#1e7a69"
                      checked={defaultClub}
                      className={
                        defaultClub
                          ? "scoring-switch switch-on"
                          : "scoring-switch"
                      }
                      onChange={() => setDefaultClub((current) => !current)}
                    />
                  </div>
                )}
                {userDetails && (userDetails.test || userDetails.admin) && (
                  <div className="input-group join-hint">
                    <div className="ig-header">
                      <p>Join Code</p>
                    </div>
                    <input
                      onChange={(e) => setJoinCode(e.target.value)}
                      type={"text"}
                      value={joinCode}
                      placeholder={"Enter joining code"}
                      className="default-input"
                    />
                  </div>
                )}

                {userDetails && (userDetails.test || userDetails.admin) && (
                  <div className="input-group nassau-group">
                    <div className="ig-header">
                      <p>Require Handicap ID</p>
                      <div className="svg-cont">
                        <InfoIcon
                          onClick={() => {
                            setDialog(
                              "Do players need to enter their handicap ID number when joining the club with the specified joining code?"
                            );
                            setShowDialog(true);
                          }}
                          className="info-icon dg-icon-bg"
                        />
                      </div>
                    </div>
                    <Switch
                      onColor="#1e7a69"
                      checked={requireID}
                      className={
                        setRequireID
                          ? "scoring-switch switch-on"
                          : "scoring-switch"
                      }
                      onChange={() => setRequireID((current) => !current)}
                    />
                  </div>
                )}
                <div className="input-group nassau-group">
                  <div className="ig-header">
                    <p>Private</p>
                    <div className="svg-cont">
                      <InfoIcon
                        onClick={() => {
                          setDialog(
                            "Choose whether to hide this club in public searches or not."
                          );
                          setShowDialog(true);
                        }}
                        className="info-icon dg-icon-bg"
                      />
                    </div>
                  </div>
                  <Switch
                    onColor="#1e7a69"
                    checked={privateClub}
                    className={
                      privateClub
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => setPrivate((current) => !current)}
                  />
                </div>
                {primaryColour !== "" && (
                  <div className="input-group nassau-group">
                    <div className="ig-header">
                      <p>Use Primary Colour</p>
                      <div className="svg-cont">
                        <InfoIcon
                          onClick={() => {
                            setDialog(
                              "Choose whether use your primary colour in your public links."
                            );
                            setShowDialog(true);
                          }}
                          className="info-icon dg-icon-bg"
                        />
                      </div>
                    </div>
                    <Switch
                      onColor="#1e7a69"
                      checked={useColour}
                      className={
                        useColour
                          ? "scoring-switch switch-on"
                          : "scoring-switch"
                      }
                      onChange={() => setUseColour((current) => !current)}
                    />
                  </div>
                )}
              </div>
              {false && (
                <>
                  <div className="sub-header">
                    <div className="ig-header">
                      <h5>Admins</h5>
                      <AddCircle
                        onClick={() => {
                          setShowAdd(true);
                        }}
                        className="dg-icon-bg icon-cursor ml-20"
                      />
                    </div>
                  </div>
                  <div className="ec-box mt-20">
                    {!edit && userDetails && (
                      <PlayerItem
                        name={userDetails.name}
                        hideHCP
                        showAdd
                        img={userDetails.profileImage}
                        noLink
                        hideMessage
                        hideProfile
                        hideEdit
                        hideRemove
                      />
                    )}
                    {admins.map((player) => (
                      <PlayerItem
                        key={`admin${player.playerID}`}
                        name={player.name}
                        noLink
                        showAdd
                        hideEdit
                        hideRemove={admins.length === 1}
                        hideMessage
                        hideProfile
                        removeAction={() => {
                          setAdmins((current) => {
                            const filtered = current.filter(
                              (admin) => admin.playerID !== player.playerID
                            );
                            return filtered;
                          });
                          setUpdate((current) => current + 1);
                        }}
                        img={player.profileImage}
                        hideHCP
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
          {!edit && false && (
            <>
              <div className="sub-header">
                <div className="ig-header">
                  <h5>Players</h5>
                  <AddCircle
                    onClick={() => {
                      setShowAddPlayers(true);
                    }}
                    className="dg-icon-bg icon-cursor ml-20"
                  />
                </div>
              </div>

              <div className="ec-box mt-20">
                {!edit && userDetails && (
                  <PlayerItem
                    name={userDetails.name}
                    hideHCP
                    showAdd
                    img={userDetails.profileImage}
                    noLink
                    hideMessage
                    hideProfile
                    hideEdit
                    hideRemove
                  />
                )}
                {members.map((player, index) => (
                  <PlayerItem
                    name={player.name}
                    noLink
                    showAdd
                    hideEdit
                    hideMessage
                    hcp={player.handicap}
                    hideProfile
                    removeAction={() => {
                      setAdmins((current) => {
                        const filtered = current.filter(
                          (admin) => admin.playerID !== player.playerID
                        );
                        return filtered;
                      });
                      setUpdate((current) => current + 1);
                    }}
                    img={player.profileImage}
                    // hideHCP
                  />
                ))}
                {playerMap &&
                  selected.map((playerID) => {
                    const player = playerMap[playerID];
                    return (
                      <PlayerItem
                        name={player.name}
                        noLink
                        showAdd
                        hideEdit
                        hideMessage
                        hcp={player.handicap}
                        hideProfile
                        removeAction={() => {
                          setAdmins((current) => {
                            const filtered = current.filter(
                              (admin) => admin.playerID !== player.playerID
                            );
                            return filtered;
                          });
                          setUpdate((current) => current + 1);
                        }}
                        img={player.profileImage}
                        // hideHCP
                      />
                    );
                  })}
              </div>
            </>
          )}
          <div className="flex-center">
            <button
              onClick={edit ? saveChanges : saveClub}
              className="default-button"
            >
              {edit ? "Save Changes" : "Save Club"}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ClubEditor;
