import React, { useState, useEffect } from "react";
import "./Home.css";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment/moment";

// Icons

// Components
import NotificationItem from "../../components/Notifications/NotificationItem";
import { Oval } from "react-loader-spinner";

function HomeNotifications({ setToShow = () => {}, setShow = () => {} }) {
  const { currentUser } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [nots, setNots] = useState([]);

  // UE
  useEffect(() => {
    if (currentUser) {
      loadNotifications();
    }
  }, [currentUser]);

  // Functions
  async function loadNotifications() {
    const notifications = await db
      .collection("users")
      .doc(currentUser.uid)
      .collection("portalNotifications")
      .orderBy("date", "desc")
      .get();

    const arr = [];

    for (let i = 0; i < notifications.docs.length; i++) {
      const notificationDoc = notifications.docs[i];
      const notificationID = notificationDoc.id;
      const notification = notificationDoc.data();
      const dateObj = new Date(notification.date.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      notification.dateObject = dateObj;
      notification.notificationID = notificationID;
      notification.dateString = dateString;
      const notMoment = moment(dateObj);
      const timeSince = notMoment.fromNow();
      notification.timeSince = timeSince;
      notification.dateMoment = notMoment;
      arr.push(notification);
    }

    setNots(arr);
    setLoading(false);
  }

  return (
    <div className="home-notifications home-events">
      <div className="page-header">
        <h2>Latest Alerts</h2>
      </div>
      {loading ? (
        <div className="he-loader">
          <Oval
            color="#1e7a69"
            secondaryColor="#ffffff"
            height={40}
            width={40}
          />
        </div>
      ) : (
        <>
          {nots.length > 0 && (
            <div className="scale-in-pbs pb-20">
              {nots.map((not) => (
                <NotificationItem
                  key={not.notificationID}
                  shadow
                  notification={not}
                />
              ))}
            </div>
          )}
          {nots.length === 0 && (
            <div className="no-msg">
              <p>No alerts to display</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default HomeNotifications;
