import React from "react";

// Icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// Components
import { Avatar, Checkbox } from "@mui/material";

function ClubCourse({
  course,
  removeAction = () => {},
  selected = false,
  setSelected = () => {},
  showBox = false,
  hideRight = false,
  hideCity = false,
  select = false,
}) {
  // console.log(course);

  return (
    <div
      onClick={() => {
        if (select) {
          setSelected();
        }
      }}
      className={selected ? "club-course cc-sel" : "club-course"}
    >
      <div className={select ? "cc-left cc-cursor" : "cc-left"}>
        <Avatar
          alt={course.name}
          src={
            course.imageLink === undefined ||
            course.imageLink === null ||
            course.imageLink === ""
              ? "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fdean-ricciardi-UyTfzNdLHzs-unsplash.jpg?alt=media&token=28c37df5-2ada-483c-8255-57a25bd707c3"
              : course.imageLink
          }
        />
      </div>
      <div className={select ? "cc-right cc-cursor" : "cc-right"}>
        <div className="ccr-left">
          <h2 className="">{course.name ?? course.label}</h2>
          {!hideCity && (
            <p>
              {course.city}, {course.country}
            </p>
          )}
        </div>
        {!hideRight && (
          <div className="ccr-icons">
            {showBox && (
              <div className="cc-box">
                <Checkbox checked={selected} onChange={setSelected} />
                {/* <p>Use Location</p> */}
              </div>
            )}
            <DeleteOutlineIcon
              onClick={removeAction}
              className="red-icon icon-cursor"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ClubCourse;
