import React from "react";
import Close from "@mui/icons-material/Close";

function PageHeader({
  text = "",
  close = () => {},
  showClose = false,
  plZero = false,
}) {
  return (
    <div
      style={{ paddingLeft: plZero ? "0px" : "20px" }}
      className="page-header"
    >
      <div>
        <h2>{text}</h2> <div className="aboh-border"></div>
      </div>

      {showClose && <Close className="" onClick={close} />}
    </div>
  );
}

export default PageHeader;
