import React from "react";
import "./ChatMessage.css";

function ChatMessage({ message, time, sent, unread = false }) {
  return (
    <div className={sent ? "cmc-sent" : "cmc-rec"}>
      <div
        style={{
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
        className={sent ? "chat-message cm-sent" : "chat-message cm-received"}
      >
        <div className="cm-time">
          <p>{time}</p>
        </div>
        <div className="cm-msg">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
