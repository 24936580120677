import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
// Icons

// Components
import StatsItem from "../StatsItem/StatsItem";
import BackdropLoader from "../../Loaders/BackdropLoader";

function ClientCourseStats({ stats, grossStats, userID = "" }) {
  const [ogStats, setOGStats] = useState([]);
  const [ogGrossStats, setOGGrossStats] = useState([]);
  const [gross, setGross] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadStats();
  }, []);

  async function loadStats() {
    const userDoc = await db.collection("users").doc(userID).get();
    const user = userDoc.data();

    const { stats, grossStats } = user;

    setOGStats(stats);
    setOGGrossStats(grossStats);
    setLoading(false);
  }

  return (
    <div className="cc-stats">
      {loading ? (
        <>
          <div className="cp-loader">
            <BackdropLoader color="#6ba390" />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="bs-subtle bss-white">
            <div className="flex flex-align-center pl-20 mt-20">
              <div className="selector-group sg-small mt-20 mr-20">
                <button
                  onClick={() => {
                    setGross(true);
                  }}
                  className={gross ? "sg-left sg-active" : "sg-left"}
                >
                  Gross
                </button>
                <button
                  onClick={() => {
                    setGross(false);
                  }}
                  className={!gross ? "sg-right sg-active" : "sg-right"}
                >
                  Net
                </button>
              </div>
            </div>
            <div className="neh-box mt-20">
              <h2 className="ne-header">Scoring</h2>
            </div>
            <div className="rs-box flex flex-wrap">
              <StatsItem
                title="Scoring Average"
                stat={gross ? stats.averageGross : stats.averageNet}
                comp={gross ? ogStats.averageGross : ogStats.averageNet}
                better={
                  (gross && ogStats.averageGross > stats.averageGross) ||
                  (!gross && ogStats.averageNet > stats.averageNet)
                }
              />
              <StatsItem
                grey
                title="Total Rounds"
                stat={stats.totalRounds}
                comp={ogStats.totalRounds}
              />
              <StatsItem
                grey
                title={"Par 3 Average"}
                stat={
                  !gross ? stats.averageParThrees : grossStats.averageParThrees
                }
                comp={
                  gross
                    ? ogGrossStats.averageParThrees
                    : ogStats.averageParThrees
                }
                better={
                  (gross &&
                    ogGrossStats.averageParThrees >
                      grossStats.averageParThrees) ||
                  (!gross && ogStats.averageParThrees > stats.averageParThrees)
                }
              />
              <StatsItem
                title={"Par 4 Average"}
                stat={
                  !gross ? stats.averageParFours : grossStats.averageParFours
                }
                comp={
                  gross ? ogGrossStats.averageParFours : ogStats.averageParFours
                }
                better={
                  (gross &&
                    ogGrossStats.averageParFours >
                      grossStats.averageParFours) ||
                  (!gross && ogStats.averageParFours > stats.averageParFours)
                }
              />
              <StatsItem
                comp={
                  gross ? ogGrossStats.averageParFives : ogStats.averageParFives
                }
                better={
                  (gross &&
                    ogGrossStats.averageParFives >
                      grossStats.averageParFives) ||
                  (!gross && ogStats.averageParFives > stats.averageParFives)
                }
                title={"Par 5 Average"}
                stat={
                  !gross ? stats.averageParFives : grossStats.averageParFives
                }
              />
              <StatsItem
                grey
                title={"Birdie Conversion"}
                stat={
                  !(
                    (stats.netBirdieAttempts > 0 || stats.totalNetGir > 0) &&
                    stats.totalGIR > 0
                  )
                    ? "0"
                    : !gross
                    ? `${Math.round(
                        (stats.totalBirdies / stats.netBirdieAttempts ??
                          stats.totalNetGir) * 100
                      )}%`
                    : `${Math.round(
                        (grossStats.totalBirdies / stats.totalGIR) * 100
                      )}%`
                }
                comp={
                  !(
                    (ogStats.netBirdieAttempts > 0 ||
                      ogStats.totalNetGir > 0) &&
                    ogStats.totalGIR > 0
                  )
                    ? "0"
                    : !gross
                    ? `${Math.round(
                        (ogStats.totalBirdies / ogStats.netBirdieAttempts ??
                          ogStats.totalNetGir) * 100
                      )}%`
                    : `${Math.round(
                        (ogGrossStats.totalBirdies / ogStats.totalGIR) * 100
                      )}%`
                }
                better={
                  (!gross &&
                    ogStats.totalBirdies / ogStats.totalNetGir <
                      stats.totalBirdies / stats.totalNetGir) ||
                  ogGrossStats.totalBirdies / ogStats.totalGIR <
                    grossStats.totalBirdies / stats.totalGIR
                }
              />
              <StatsItem
                title={"Bounce Back Birdies"}
                stat={
                  !gross
                    ? stats.netBounceBackBirdies
                    : stats.grossBounceBackBirdies
                }
                comp={
                  !gross
                    ? ogStats.netBounceBackBirdies
                    : ogStats.grossBounceBackBirdies
                }
                better={false}
                //   better={gross && stats.grossBounceBackBirdies > }
              />
            </div>
            <div className="neh-box mt-20">
              <h2 className="ne-header">Holes</h2>
            </div>
            <div className="rs-box flex flex-wrap">
              <StatsItem
                title={"Average Eagles"}
                stat={!gross ? stats.averageEagles : grossStats.averageEagles}
                comp={
                  gross ? ogGrossStats.averageEagles : ogStats.averageEagles
                }
                better={
                  (gross &&
                    ogGrossStats.averageEagles < grossStats.averageEagles) ||
                  (!gross && ogStats.averageEagles < stats.averageEagles)
                }
              />
              <StatsItem
                grey
                title={"Average Birdies"}
                stat={!gross ? stats.averageBirdies : grossStats.averageBirdies}
                comp={
                  gross ? ogGrossStats.averageBirdies : ogStats.averageBirdies
                }
                better={
                  (gross &&
                    ogGrossStats.averageBirdies < grossStats.averageBirdies) ||
                  (!gross && ogStats.averageBirdies < stats.averageBirdies)
                }
              />
              <StatsItem
                grey
                title={"Average Pars"}
                stat={!gross ? stats.averagePars : grossStats.averagePars}
                comp={gross ? ogGrossStats.averagePars : ogStats.averagePars}
                better={
                  (gross &&
                    ogGrossStats.averagePars < grossStats.averagePars) ||
                  (!gross && ogStats.averagePars < stats.averagePars)
                }
              />
              <StatsItem
                title={"Average Bogies"}
                stat={!gross ? stats.averageBogies : grossStats.averageBogies}
                comp={
                  gross ? ogGrossStats.averageBogies : ogStats.averageBogies
                }
                better={
                  (gross &&
                    ogGrossStats.averageBogies > grossStats.averageBogies) ||
                  (!gross && ogStats.averageBogies > stats.averageBogies)
                }
              />
              <StatsItem
                title={"Average Doubles"}
                stat={!gross ? stats.averageDoubles : grossStats.averageDoubles}
                comp={
                  gross ? ogGrossStats.averageDoubles : ogStats.averageDoubles
                }
                better={
                  (gross &&
                    ogGrossStats.averageDoubles > grossStats.averageDoubles) ||
                  (!gross && ogStats.averageDoubles > stats.averageDoubles)
                }
              />
              <StatsItem
                title={"Average Other"}
                grey
                stat={!gross ? stats.averageOthers : grossStats.averageOthers}
                comp={
                  gross ? ogGrossStats.averageOthers : ogStats.averageOthers
                }
                better={
                  (gross &&
                    ogGrossStats.averageOthers > grossStats.averageOthers) ||
                  (!gross && ogStats.averageOthers > stats.averageOthers)
                }
              />
            </div>
            <div className="neh-box mt-20">
              <h2 className="ne-header">Approach</h2>
            </div>
            <div className="rs-box flex flex-wrap">
              <StatsItem
                title={"Greens in Regulation"}
                stat={
                  gross
                    ? `${Math.round((stats.averageGIR / 18) * 100)}%`
                    : `${Math.round((stats.averageNetGIR / 18) * 100)}%`
                }
                comp={
                  gross
                    ? `${Math.round((ogStats.averageGIR / 18) * 100)}%`
                    : `${Math.round((ogStats.averageNetGIR / 18) * 100)}%`
                }
                better={
                  (gross && ogStats.averageGIR < stats.averageGIR) ||
                  (!gross && ogStats.averageNetGIR < stats.averageNetGIR)
                }
              />
              <StatsItem
                grey
                title={"Greens in Regulation - Par 3s"}
                stat={
                  gross
                    ? `${Math.round(
                        (stats.parThreeGrossGIRs / stats.totalParThrees) * 100
                      )}%`
                    : `${Math.round(
                        (stats.parThreeNetGIRs / stats.totalParThrees) * 100
                      )}%`
                }
                comp={
                  gross
                    ? `${Math.round(
                        (ogStats.parThreeGrossGIRs / ogStats.totalParThrees) *
                          100
                      )}%`
                    : `${Math.round(
                        (ogStats.parThreeNetGIRs / ogStats.totalParThrees) * 100
                      )}%`
                }
                better={
                  (gross &&
                    ogStats.parThreeGrossGIRs / ogStats.totalParThrees <
                      stats.parThreeGrossGIRs / stats.totalParThrees) ||
                  (!gross &&
                    ogStats.parThreeNetGIRs / ogStats.totalParThrees <
                      stats.parThreeNetGIRs / stats.parThreeGrossGIRs)
                }
              />
              <StatsItem
                grey
                title={"Greens in Regulation - Par 4s"}
                stat={
                  gross
                    ? `${Math.round(
                        (stats.parFourGrossGIRs / stats.totalParFours) * 100
                      )}%`
                    : `${Math.round(
                        (stats.parFourNetGIRs / stats.totalParFours) * 100
                      )}%`
                }
                comp={
                  gross
                    ? `${Math.round(
                        (ogStats.parFourGrossGIRs / ogStats.totalParFours) * 100
                      )}%`
                    : `${Math.round(
                        (ogStats.parFourNetGIRs / ogStats.totalParFours) * 100
                      )}%`
                }
                better={
                  (gross &&
                    ogStats.parFourGrossGIRs / ogStats.totalParFours <
                      stats.parFourGrossGIRs / stats.totalParFours) ||
                  (!gross &&
                    ogStats.parFourNetGIRs / ogStats.totalParFours <
                      stats.parFourNetGIRs / stats.parFourGrossGIRs)
                }
              />
              <StatsItem
                title={"Greens in Regulation - Par 5s"}
                stat={
                  gross
                    ? `${Math.round(
                        (stats.parFiveGrossGIRs / stats.totalParFives) * 100
                      )}%`
                    : `${Math.round(
                        (stats.parFiveNetGIRs / stats.totalParFives) * 100
                      )}%`
                }
                comp={
                  gross
                    ? `${Math.round(
                        (ogStats.parFiveGrossGIRs / ogStats.totalParFives) * 100
                      )}%`
                    : `${Math.round(
                        (ogStats.parFiveNetGIRs / ogStats.totalParFives) * 100
                      )}%`
                }
                better={
                  (gross &&
                    ogStats.parFiveGrossGIRs / ogStats.totalParFives <
                      stats.parFiveGrossGIRs / stats.totalParFives) ||
                  (!gross &&
                    ogStats.parFiveNetGIRs / ogStats.totalParFives <
                      stats.parFiveNetGIRs / stats.parFiveGrossGIRs)
                }
              />

              <StatsItem
                title={"Scrambling"}
                stat={
                  !(
                    stats.totalNetScrambleAttempts > 0 &&
                    stats.totalGrossScrambleAttempts > 0
                  )
                    ? "0%"
                    : !gross
                    ? `${Math.round(
                        (stats.totalNetScrambles /
                          stats.totalNetScrambleAttempts) *
                          100
                      )}%`
                    : `${Math.round(
                        (stats.totalGrossScrambles /
                          stats.totalGrossScrambleAttempts) *
                          100
                      )}%`
                }
                comp={
                  !(
                    ogStats.totalNetScrambleAttempts > 0 &&
                    ogStats.totalGrossScrambleAttempts > 0
                  )
                    ? "0%"
                    : !gross
                    ? `${Math.round(
                        (ogStats.totalNetScrambles /
                          ogStats.totalNetScrambleAttempts) *
                          100
                      )}%`
                    : `${Math.round(
                        (ogStats.totalGrossScrambles /
                          ogStats.totalGrossScrambleAttempts) *
                          100
                      )}%`
                }
                better={
                  (gross &&
                    ogStats.totalGrossScrambles /
                      ogStats.totalGrossScrambleAttempts >
                      stats.totalGrossScrambles /
                        ogStats.totalGrossScrambleAttempts) ||
                  (!gross &&
                    ogStats.totalNetScrambles /
                      ogStats.totalNetScrambleAttempts <
                      stats.totalNetScrambles / stats.totalNetScrambleAttempts)
                }
              />
              <StatsItem
                title={"Sand Saves"}
                grey
                stat={
                  stats.sandyAttempts > 0
                    ? `${Math.round(stats.sandSave * 100)}%`
                    : "0%"
                }
                comp={`${Math.round(ogStats.sandSave * 100)}%`}
                better={ogStats.sandSave < stats.sandSave}
              />
            </div>
            <div className="neh-box mt-20">
              <h2 className="ne-header">Off the Tee</h2>
            </div>
            <div className="rs-box flex flex-wrap">
              <StatsItem
                title={"Average Fairways In Regulation"}
                stat={`${Math.round((stats.averageFIR / 14) * 100)}%`}
                comp={`${Math.round((ogStats.averageFIR / 14) * 100)}%`}
                better={ogStats.averageFIR < stats.averageFIR}
              />
              <StatsItem
                grey
                title={"Par 4 Average (FIR)"}
                stat={
                  !gross
                    ? stats.parFourNetFIRScoringAverage
                    : stats.parFourGrossFIRScoringAverage
                }
                comp={
                  !gross
                    ? ogStats.parFourNetFIRScoringAverage
                    : ogStats.parFourGrossFIRScoringAverage
                }
                better={
                  (gross &&
                    ogStats.parFourGrossFIRScoringAverage >
                      stats.parFourGrossFIRScoringAverage) ||
                  (!gross &&
                    ogStats.parFourNetFIRScoringAverage >
                      stats.parFourNetFIRScoringAverage)
                }
              />
              <StatsItem
                grey
                title={"Par 5 Average (FIR)"}
                stat={
                  !gross
                    ? stats.parFiveNetFIRScoringAverage
                    : stats.parFiveGrossFIRScoringAverage
                }
                comp={
                  !gross
                    ? ogStats.parFiveNetFIRScoringAverage
                    : ogStats.parFiveGrossFIRScoringAverage
                }
                better={
                  (gross &&
                    ogStats.parFiveGrossFIRScoringAverage >
                      stats.parFiveGrossFIRScoringAverage) ||
                  (!gross &&
                    ogStats.parFiveNetFIRScoringAverage >
                      stats.parFiveNetFIRScoringAverage)
                }
              />
            </div>
            <div className="neh-box mt-20">
              <h2 className="ne-header">Putting</h2>
            </div>
            <div className="rs-box flex flex-wrap">
              <StatsItem
                title="Average Putts"
                comp={ogStats.averagePutts}
                stat={stats.averagePutts}
                better={ogStats.averagePutts > stats.averagePutts}
              />
              <StatsItem
                grey
                title="Putts per Hole"
                stat={Math.round((stats.averagePutts / 18) * 100) / 100}
                comp={Math.round((ogStats.averagePutts / 18) * 100) / 100}
                better={ogStats.averagePutts > stats.averagePutts}
              />
              <StatsItem
                grey
                title="1 Putts per Round"
                stat={
                  Math.round((stats.onePutts / stats.totalRounds) * 100) / 100
                }
                comp={
                  Math.round((ogStats.onePutts / ogStats.totalRounds) * 100) /
                  100
                }
                better={
                  ogStats.onePutts / ogStats.totalRounds >
                  stats.onePutts / stats.totalRounds
                }
              />
              <StatsItem
                title="3 Putts per Round"
                stat={
                  Math.round((stats.threePutts / stats.totalRounds) * 100) / 100
                }
                comp={
                  Math.round((ogStats.threePutts / ogStats.totalRounds) * 100) /
                  100
                }
                better={
                  ogStats.threePutts / ogStats.totalRounds <
                  stats.threePutts / stats.totalRounds
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ClientCourseStats;
