import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmModal from "../ConfirmModal";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

function DrillAttempt({
  attempt = {},
  editAct = () => {},
  deleteAct = () => {},
  display = false,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="drill-attempt flex flex-align-center">
      {showModal && (
        <ConfirmModal
          text="Are you sure you want to delete this score?"
          close={() => setShowModal(false)}
          confirm={() => {
            setShowModal();
            deleteAct();
          }}
        />
      )}

      <div className="da-date">
        <p>
          {new Date(attempt.date.toDate()).toLocaleDateString("en-us", {
            dateStyle: "medium",
          })}
        </p>
      </div>
      <div className="da-title">
        <p>{attempt.title}</p>
      </div>
      <div className="da-cat">
        <p>{attempt.category}</p>
      </div>
      <div className={`da-diff da-diff-${attempt.difficulty}`}>
        <p>{attempt.difficulty}</p>
      </div>
      <div className="da-score">
        <p>{attempt.score}</p>
      </div>

      <div className="da-opts">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button className="IconButton" aria-label="Customise options">
              <MoreVertIcon className="grey-ico" />
            </button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="DropdownMenuContent"
              sideOffset={5}
            >
              <DropdownMenu.Item onClick={editAct} className="DropdownMenuItem">
                Edit Score
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => setShowModal(true)}
                className="DropdownMenuItem"
              >
                Delete Score
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
}

export default DrillAttempt;
