import React, { useState, useEffect } from "react";
import "./PLStatsHome.css";
import { admin, db } from "../../../firebase";
import { Link, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import "../Drills/Drills.css";
import { Country } from "country-state-city";
import algoliasearch from "algoliasearch";
import { useCookies } from "react-cookie";
import Geohash from "latlon-geohash";
import GeohashDistance from "geohash-distance";
import Geocode from "react-geocode";
import { geoFirestore } from "../../../firebase";
// Icons

// Components
import { useAuth } from "../../../contexts/AuthContext";

import PageHeader from "../../display/Rows/PageHeader";
import BackdropLoader from "../../Loaders/BackdropLoader";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import BackRow from "../../display/Rows/BackRow";
import { Tab, Tabs } from "@mui/material";
import ClubDrills from "../Drills/ClubDrills";
import SearchInput from "../SearchInput";
import CourseItem from "../../display/CourseItem/CourseItem";
import { ClipLoader } from "react-spinners";
import ProStatsPlayers from "./ProStatsPlayers";
import Pill from "../../Buttons/Pill";

function PLStatsHome() {
  const searchClient = algoliasearch(
    "QF50LJZ12F",
    "729ebd0baad19471b95fc34f74b98202"
  );
  Geocode.setApiKey("AIzaSyB7IUK8lNigjp_8wBSRJYpKQX6ueT8H7D8");
  Geocode.setLocationType("ROOFTOP");
  const index = searchClient.initIndex("uga_tour_courses");
  // Admin
  const clubID = useParams().id;
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useAuth();

  const [cookies, setCookie, removeCookie] = useCookies(["selPLTab"]);

  // State
  const [loading, setLoading] = useState(true);
  const [loadingClose, setLoadingClose] = useState(true);
  const [closeCourses, setCloseCourses] = useState([]);
  const [members, setMembers] = useState([]);
  const [search, setSearch] = useState("");
  const [selTab, setSelTab] = useState("players");
  const [courseSearch, setCourseSearch] = useState("");
  const [courses, setCourses] = useState([]);
  const [showPlayers, setShowPlayers] = useState(false);
  const [showPlayerOption, setShowPlayerOption] = useState(false);

  // UE
  useEffect(() => {
    if (userDetails) {
      loadPlayers();
      loadCourses();
    }

    console.log(userDetails.accountType);
    // console.log(location);
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      console.log(userDetails.accountType);
      if (
        userDetails.accountType !== "club" ||
        userDetails.admin ||
        userDetails.test
      ) {
        setShowPlayerOption(true);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (cookies.selPLTab) {
      setSelTab(cookies.selPLTab);
    }
  }, []);

  // Funcs
  async function loadPlayers() {
    setLoading(true);
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();

    const accountType = club.accountType ?? "club";

    const memberIDs = userDetails.test
      ? club.proStatsIDs
      : accountType === "club"
      ? club.memberIDs
      : club.proStatsIDs;
    const refs = memberIDs.map((uid) => db.collection("users").doc(uid).get());
    const docs = await Promise.all(refs);
    const arr = [];

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      if (!playerDoc.exists) {
        continue;
      }
      const playerID = docs[i].id;

      const player = playerDoc.data();

      const { firstName, lastName, handicap, profileImage } = player;

      arr.push({
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
      });
    }
    setMembers(arr);
    setLoading(false);
  }

  async function algoliCourse() {
    const arr = [];
    setLoading(true);
    index
      .search(courseSearch, {
        hitsPerPage: 100,
      })
      .then(async ({ hits }) => {
        const algoArr = [];
        const linkMap = hits.map((hit) =>
          db.collection("courses").doc(hit.objectID).get()
        );
        const courseDocs = await Promise.all(linkMap);
        for (let n = 0; n < hits.length; n++) {
          const algoCourse = hits[n];
          // console.log(algoCourse);
          const { objectID, name, imageLink, rating, city, country, state } =
            algoCourse;
          const courseDoc = courseDocs[n];
          const courseData = courseDoc.data();
          if (!courseDoc.exists) {
            continue;
          }
          const {
            roundArray,
            tees,
            length,
            par,
            teeArray,
            address,
            location,
            firstNine,
            lastNine,
          } = courseData;

          algoArr.push({
            courseID: objectID,
            name,
            imageLink,
            rating,
            city,
            country,
            state,
            roundArray,
            tees,
            length,
            par,
            teeArray,
            address,
            location,
            firstNine,
            lastNine,
          });
        }
        const userCountry = Country.getCountryByCode(userDetails.country);
        const uc = userCountry.name;
        algoArr.sort((a, b) => {
          if (uc === b.country) {
            return 1;
          } else {
            return -1;
          }
        });
        console.log(uc, algoArr);
        setCourses(algoArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function compareDistance(a, b) {
    // console.log(a,b)
    if (a.distance < b.distance) {
      return -1;
    }
    if (a.distance > b.distance) {
      return 1;
    }
    return 0;
  }

  async function loadCourses() {
    if (navigator.geolocation) {
      // console.log("here");

      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          // console.log(pos);
          const userHash = Geohash.encode(
            pos.coords.latitude,
            pos.coords.longitude,
            10
          );

          const center = new admin.firestore.GeoPoint(
            pos.coords.latitude,
            pos.coords.longitude
          );

          let city, state, country;
          const res = await Geocode.fromLatLng(
            pos.coords.latitude,
            pos.coords.longitude
          ).catch((err) => console.log(err));

          for (let i = 0; i < res.results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < res.results[0].address_components[i].types.length;
              j++
            ) {
              switch (res.results[0].address_components[i].types[j]) {
                case "locality":
                  city = res.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = res.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = res.results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }

          const geoColl = geoFirestore.collection("courses");
          const query = geoColl
            .near({
              center,
              radius: 100,
            })
            .limit(15);
          const courses = await query.get();

          const arr = [];
          const sarr = [];

          for (let i = 0; i < courses.docs.length; i++) {
            const courseID = courses.docs[i].id;
            const course = courses.docs[i].data();
            if (i === 0) {
              // console.log(course);
            }
            const {
              address,
              city,
              country,
              location,
              name,
              imageLink,
              teeArray,
              tees,
              par,
              length,
              firstNine,
              lastNine,
            } = course;
            const roundArray = course.roundArray ?? [];
            const totalReviews = course.totalReviews ?? 0;
            const rating = course.rating ?? 5;

            const hash = Geohash.encode(location._lat, location._long, 10);
            const distance =
              Math.round(GeohashDistance.inKm(userHash, hash) * 10) / 10;

            arr.push({
              address,
              city,
              country,
              rating,
              location,
              name,
              courseID,
              distance,
              imageLink,
              roundArray,
              teeArray,
              tees,
              par,
              totalReviews,
              length,
              firstNine,
              lastNine,
            });
          }
          arr.sort(compareDistance);
          setCloseCourses(arr);
          setLoadingClose(false);
        },
        (err) => console.log(err),
        { timeout: 5000, enableHighAccuracy: false }
      );
    }
  }

  return (
    <div className="pl-stats-home">
      {showPlayers ? (
        <ProStatsPlayers
          clubID={clubID}
          goBack={() => setShowPlayers(false)}
          reload={loadPlayers}
        />
      ) : (
        <>
          {" "}
          {loading && <BackdropLoader />}
          <BackRow action={() => navigate(`/`)} />
          <PageHeader text="Pro Stats Home" />
          {true && (
            <div className="ec-tabs mt-20">
              <Tabs
                value={selTab}
                onChange={(e, v) => {
                  setSelTab(v);
                  setCookie("selPLTab", v);
                }}
              >
                <Tab label="Players" value={"players"} />
                {userDetails && (userDetails.admin || userDetails.test) && (
                  <Tab value={"drills"} label="Drills" />
                )}
                <Tab value={"courses"} label="Courses" />
              </Tabs>
            </div>
          )}
          {selTab === "players" && (
            <>
              {" "}
              {showPlayerOption && (
                <div className="flex ml-20 mt-20">
                  <Pill
                    onClick={() => setShowPlayers(true)}
                    text={"Manage Players"}
                  />
                </div>
              )}
              <div className="input-group pl-20">
                <p>Search for player</p>
                <input
                  className="default-input"
                  value={search}
                  placeholder="Start typing..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="psh-members">
                {members.map((player, i) => {
                  if (search !== "") {
                    const sl = search.toLowerCase();
                    const pl = player.name.toLowerCase();

                    if (!pl.includes(sl)) {
                      return null;
                    }
                  }

                  return (
                    <NavLink
                      className={"psh-nav"}
                      key={player.playerID}
                      to={`/pl-stats/${player.playerID}`}
                    >
                      <PlayerItem
                        showHover
                        hcp={player.handicap}
                        noLink
                        hideIcons
                        img={player.profileImage}
                        name={`${player.firstName} ${player.lastName}`}
                      />
                    </NavLink>
                  );
                })}
              </div>
            </>
          )}
          {selTab === "drills" && (
            <>
              <ClubDrills clubID={clubID} />
            </>
          )}
          {selTab === "courses" && (
            <>
              <div className="pls-crs-search">
                <div className="flex-align-center">
                  <div className="input-group pl-20">
                    <p>Search for course</p>{" "}
                    <SearchInput
                      keyAction={() => {
                        if (courseSearch.length > 2) {
                          algoliCourse();
                        }
                      }}
                      val={courseSearch}
                      setVal={setCourseSearch}
                    />
                  </div>
                  <button
                    disabled={courseSearch.length < 3}
                    onClick={algoliCourse}
                    className="default-button small-but ml-20"
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="rdi-cc-res flex flex-wrap flex-align-center mt-20">
                {courses.map((course, i) => (
                  <Link
                    key={`search${course.courseID}`}
                    to={`/course-stats/${course.courseID}`}
                  >
                    <CourseItem
                      image={course.imageLink}
                      course={course}
                      city={course.city}
                      country={course.country}
                      hideStar
                      courseName={course.name}
                      // key={`search${course.courseID}`}
                      // showBG={selCourse.courseID === course.courseID}
                      showCourse={() => {
                        // navigate(`/course-stats/${course.courseID}`);
                      }}
                    />
                  </Link>
                ))}
              </div>
              <div className="pl-20 mt-20">
                <h2 className="ne-header">Nearby courses</h2>
              </div>
              <div className="rdi-cc-res flex flex-wrap flex-align-center">
                {closeCourses.map((course, i) => (
                  <Link
                    key={`search${course.courseID}`}
                    to={`/course-stats/${course.courseID}`}
                  >
                    <CourseItem
                      image={course.imageLink}
                      course={course}
                      hideStar
                      city={course.city}
                      country={course.country}
                      courseName={course.name}
                      key={course.courseID}
                      showCourse={() => {
                        // alert("click");
                      }}
                    />
                  </Link>
                ))}
              </div>
              {loadingClose && (
                <div className="cp-loader">
                  <ClipLoader color="#6ba390" size={40} className="btn-ico" />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PLStatsHome;
