import React from "react";
import "./CourseItem.css";
import StarIcon from "@mui/icons-material/Star";
import { LocationOn, Person } from "@mui/icons-material";
// import "/node_modules/flag-icons/css/flag-icons.min.css";

const GradientIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <StarIcon className="ci-star dg-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);

function CourseItem({
  hideComma = false,
  image,
  courseName,
  city,
  country,
  rating,
  showCourse,
  hideStar = false,
  seasonName = "",
  showCountry = false,
  members = "",
  borderColor = "#1e7a69",
  distance = 0,
  measure = "km",
  showBG = false,
}) {
  return (
    <div
      onClick={showCourse}
      className={showBG ? "course-item ci-bg" : "course-item"}
    >
      <div className="ci-left">
        <img
          src={
            image === undefined || image === "" || image === null
              ? "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fdean-ricciardi-UyTfzNdLHzs-unsplash.jpg?alt=media&token=28c37df5-2ada-483c-8255-57a25bd707c3"
              : image
          }
          alt={courseName}
        />
      </div>
      <div className="ci-right">
        <h1 style={{ borderColor }}>{courseName}</h1>
        {false && (
          <div className="league-cuntry">
            <p>
              {city}
              {hideComma ? "" : ", "} {country}
            </p>
          </div>
        )}

        <div className="fpr-bot mt-10">
          <div className="fprb-item mr-10">
            <LocationOn className="fp-icon" />
            <h3>
              {city}
              {hideComma ? "" : ", "} {country}
            </h3>
          </div>
          {members !== "" && (
            <div className="fprb-item mr-10">
              <Person className="fp-icon" />
              <h3>{members}</h3>
            </div>
          )}
        </div>

        {!hideStar && (
          <div className="ci-rating">
            <div className="svg-cont">
              <GradientIcon className="ci-star" />
            </div>
            <p>{rating}</p>
          </div>
        )}
        {distance !== 0 && (
          <>
            <p>
              {distance}
              {measure} away
            </p>
          </>
        )}
        {/* {seasonName !== "" && (
          <> */}
        {false && members !== "" && (
          <p className="ne-header">
            {members} {members === 1 ? "Member" : "Members"}
          </p>
        )}
        {/* </>
        )} */}
      </div>
    </div>
  );
}

export default CourseItem;
