import React, { useEffect, useState } from "react";
import "./SDMenu.css";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { featuresProp } from "../HelperFunctions";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
function SDMenu({ options = [], clicked = 0, clubID = "" }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [opts, setOpts] = useState([]);
  const [upda, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }
  const { clubFeatures } = useAuth();
  const [features, setFeatures] = useState(featuresProp);

  const navigate = useNavigate();

  const icoMap = {
    "Add Event": <InsertInvitationIcon className="sdm-ico" />,
    "Add Team Event": <GroupAddIcon className="sdm-ico" />,
    "Add League": <EmojiEventsIcon className="sdm-ico" />,
    "Add Team League": <GroupWorkIcon className="sdm-ico" />,
    "Add User": <PersonAddIcon className="sdm-ico" />,
    "View Calendar": <CalendarViewMonthIcon className="sdm-ico" />,
  };

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const ico = icoMap[option];
      temp.push({
        label: option,
        icon: ico,
      });
    }

    setOpts(temp);
  }, [options]);

  useEffect(() => {
    if (clubFeatures) {
      // console.log(clubFeatures);
      setFeatures(clubFeatures);
    }
  }, [clubFeatures]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".sd-menu")) {
        // Handle click outside the component
        setOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div id="sdm" className="sd-menu">
      <SpeedDial
        id="sdm-sd"
        ariaLabel="Quick Menu"
        open={open}
        direction="down"
        onClose={(e) => {
          // handleClose();
          //   console.log(e);
        }}
        // onOpen={handleOpen}
        // onMouseOver={(e) => e.preventDefault()}
        onClick={(e) => {
          // console.log(e);
          // console.log("trigged");
          setOpen((c) => !c);
        }}
        icon={
          <PlaylistAddCircleIcon className="sdm-ico-big" id={"sd-big-ico"} />
        }
        sx={{ position: "absolute", right: 20, top: 100 }}
      >
        {opts.map((opt, i) => {
          if (
            (opt.label === "Add Event" || opt.label === "View Calendar") &&
            features.events !== true
          ) {
            return null;
          }
          if (
            (opt.label === "Add Team Event" ||
              opt.label === "Add Team League") &&
            features.teamLeagues !== true
          ) {
            return null;
          }
          if (opt.label === "Add League" && features.leagues !== true) {
            return null;
          }

          return (
            <SpeedDialAction
              className="sdm-opt"
              key={opt.label}
              tooltipOpen
              icon={opt.icon}
              tooltipTitle={opt.label}
              onClick={(e) => {
                console.log(e);

                if (opt.label === "Add Event") {
                  if (e.metaKey) {
                    window.open("/#/event-creator", "_blank");
                  } else {
                    navigate("/event-creator");
                  }
                }
                if (opt.label === "Add Team Event") {
                  if (e.metaKey) {
                    window.open("/#/team-event-creator", "_blank");
                  } else {
                    navigate("/team-event-creator");
                  }
                }
                if (opt.label === "Add League") {
                  if (e.metaKey) {
                    window.open("/#/league-creator", "_blank");
                  } else {
                    navigate("/league-creator");
                  }
                }
                if (opt.label === "Add Team League") {
                  if (e.metaKey) {
                    window.open("/#/team-league-creator", "_blank");
                  } else {
                    navigate("/team-league-creator");
                  }
                }
                if (opt.label === "Add User") {
                  if (e.metaKey) {
                    window.open(`/members/${clubID}`, "_blank");
                  } else {
                    navigate(`/members/${clubID}`, {
                      state: {
                        showCreate: true,
                      },
                    });
                  }
                }
                if (opt.label === "View Calendar") {
                  if (e.metaKey) {
                    window.open(`/calendar`, "_blank");
                  } else {
                    navigate(`/calendar`, {
                      state: {
                        showCreate: true,
                      },
                    });
                  }
                }

                // setOpen(false);
                update();
              }}
            />
          );
        })}
      </SpeedDial>
    </div>
  );
}

export default SDMenu;
