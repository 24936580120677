/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, cloneElement } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PlayerSetup from "../../PlayerSetup/PlayerSetup";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import { Avatar, Backdrop, Dialog } from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAuth } from "../../../contexts/AuthContext";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./GroupSelector.css";
import Switch from "react-ios-switch/lib/Switch";

import { db } from "../../../firebase";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { Checkbox } from "@mui/material";
import { useCookies } from "react-cookie";

import Close from "@mui/icons-material/Close";
import Pill from "../../Buttons/Pill";
import TimeItem from "../../display/TeeTimes/TimeItem";
import PageHeader from "../../display/Rows/PageHeader";
import EventPlayerUpload from "../EventCreator/EventPlayerUpload";
import BackdropLoader from "../../Loaders/BackdropLoader";
import LiveGroupUpload from "./LiveGroupUpload";
import { Settings } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import ja from "date-fns/locale/ja/index";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

const GradientAddIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="cr-icon icon-cursor dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
const BigGradientAddIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="big-icon icon-cursor dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientMinusIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <RemoveCircleIcon
      onClick={onClick}
      className="cr-icon icon-cursor dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function GroupSelector({
  format = "",
  defaultShotgun = false,
  defaultGroups = [],
  defaultLimit = 0,
  players = [],
  guests = [],
  rounds = [],
  roundIDs = [],
  activeRoundID = "",
  goBack,
  eventDate = new Date(),
  uid,
  open = false,
  live = false,
  eventName = "",
  courseName = "",
  roundID = "",
  leagueID = "",
  teamEvent = false,
  courseDetails,
  setOverall = () => {},
  event = false,
  reload = () => {},
  saveChanges = () => {},
  matchplay = false,
  eventID = "",
  clubID = "",
  itvl = 8,
  matches = [],
  teams = [],
  roundIndex = 0,
}) {
  function compareTimes(timeA, timeB) {
    const [hoursA, minutesA] = timeA.split(":");
    const [hoursB, minutesB] = timeB.split(":");
    return (
      new Date(0, 0, 0, hoursA, minutesA) - new Date(0, 0, 0, hoursB, minutesB)
    );
  }
  const groupTypes = [
    "handicap (highest first)",
    "handicap (lowest first)",
    "handicap (mixed)",
    ...(leagueID !== "standalone" && leagueID !== "" && !matchplay
      ? ["points table"]
      : ""),
  ];

  const sortOpts = [
    "Shuffle",
    "Time",
    ...(live ? ["Leaderboard"] : ""),
    ...(teams.length > 0 ? ["Teams"] : ""),
  ];

  const [showSel, setShowSel] = useState(false);
  const [cfa, setCFA] = useState(false);

  const [groupAmount, setGroupAmount] = useState(1);
  const [groupLimit, setGroupLimit] = useState(
    defaultLimit !== 0
      ? defaultLimit
      : players.length % 3 === 0
      ? 3
      : defaultGroups.length > 1
      ? defaultGroups.length
      : 4
  );
  const [update, setUpdate] = useState(0);

  const scrollRef = useRef();

  const [settings, setSettings] = useState({
    showTeam: false,
  });
  const [showSettings, setShowSettings] = useState(false);

  const { onboarding, setOnboard } = useAuth();
  useEffect(() => {
    if (onboarding) {
      if (
        onboarding.groupSelector === false ||
        onboarding.groupSelector === undefined
      ) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);

  const [groups, setGroups] = useState(players);

  const [leaderboard, setLeaderboard] = useState([]);

  // const [matches, setMatches] = useState([])
  // const [groupTimes, setGroupTim]

  const [formatted, setFormatted] = useState([]);
  const [showSetup, setShowSetup] = useState(false);
  const [setup, setSetup] = useState();
  const [intervals, setIntervals] = useState(8);
  const [table, setTable] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["gs-settings"]);

  const [swapMode, setSwapMode] = useState(false);
  const [swapping, setSwapping] = useState(false);
  const [swapID, setSwapID] = useState("");

  const [rdOpts, setRDOpts] = useState([]);

  const [removed, setRemoved] = useState([]);
  const [showRemoved, setShowRemoved] = useState(false);
  const [remIndexes, setRemIndexes] = useState({
    i1: 0,
    i2: 0,
  });

  const [showSwap, setShowSwap] = useState(false);
  const [swapIndices, setSwapIndices] = useState({
    i1: 0,
    i2: 0,
  });

  const [lbSort, setLBSort] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const [sorted, setSorted] = useState(false);

  const [shotgun, setShotgun] = useState(defaultShotgun);

  const [groupSwap, setGroupSwap] = useState(false);
  const [swapGroup, setSwapGroup] = useState(0);

  const [groupMove, setGroupMove] = useState(false);
  const [moveGroup, setMoveGroup] = useState(0);

  const [loading, setLoading] = useState(false);

  const [rdImportOpts, setRDImportOpts] = useState([]);
  const [rdMap, setRDMap] = useState({});
  const [selRd, setSelRd] = useState("");

  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    if (rounds.length > 1) {
      const mep = {};
      const arr = [];
      for (let i = 0; i < rounds.length; i++) {
        if (i !== roundIndex) {
          arr.push({
            value: i.toString(),
            label: `Round ${i + 1}`,
          });
          mep[i.toString()] = rounds[i].groups ?? [];
        }
      }

      setRDImportOpts(arr);
      setRDMap(mep);
    }
  }, []);

  useEffect(() => {
    // console.log(leagueID);
    if (cookies["gs-settings"]) {
      setSettings(cookies["gs-settings"]);
    }
  }, []);

  async function loadPT() {
    if (leagueID === "standalone" || teamEvent || leagueID === "") {
      return;
    }
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    setTable(league.pointsTable);
  }

  function shuffle(array) {
    // alert('everyday')
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  function moveElementInArray(array, fromIndex, toIndex) {
    // Check if the provided indexes are within the valid range
    if (
      fromIndex < 0 ||
      fromIndex >= array.length ||
      toIndex < 0 ||
      toIndex >= array.length
    ) {
      console.error(
        "Invalid index provided. Please make sure the indexes are within the array bounds."
      );
      return array; // Return the original array unchanged
    }

    // Remove the element from the 'fromIndex' position
    const elementToMove = array.splice(fromIndex, 1)[0];

    // Insert the element at the 'toIndex' position
    array.splice(toIndex, 0, elementToMove);

    // Return the updated array with the element moved to the new position
    return array;
  }

  function sortTeamMap() {
    const mep = {};

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const { players, teamName } = team;
      for (let j = 0; j < players.length; j++) {
        const player = players[j];
        mep[player.playerID] = teamName;
      }
    }
    setTeamMap(mep);
  }

  useEffect(() => {
    sortTeamMap();
    if (roundIDs.length > 1) {
      // loadRoundGroups
      sortRounds();
    }

    if (leagueID !== "custom") {
      loadPT();
    }

    if (defaultGroups.length === 1) {
      setGroupAmount(Math.ceil(players.length / groupLimit));
    }
    window.scrollTo(0, 0);
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  function sortIntervals(interval) {
    let frontTime = "";
    let backTime = "";

    const temp = [...formatted];

    for (let i = 0; i < temp.length; i++) {
      const group = temp[i];
      const { startingTee, time } = group;
      console.log(typeof startingTee);
      if (startingTee === 1 || startingTee === "1") {
        if (frontTime === "") {
          frontTime = time;
        } else {
          const [hoursStr, minutesStr] = frontTime.split(":");
          // Convert hours and minutes to numbers
          const hours = parseInt(hoursStr, 10);
          const minutes = parseInt(minutesStr, 10);

          // Calculate total minutes and add the increment value
          let totalMinutes = hours * 60 + minutes + interval * i;
          // Calculate new hours and minutes
          const newHours = Math.floor(totalMinutes / 60) % 24; // Ensure it stays within 24 hours
          const newMinutes = totalMinutes % 60;

          // Format the new time string
          const newTime = `${newHours.toString().padStart(2, "0")}:${newMinutes
            .toString()
            .padStart(2, "0")}`;
          temp[i].time = newTime;
        }
      } else {
        if (backTime === "") {
          backTime = time;
        } else {
          const [hoursStr, minutesStr] = backTime.split(":");

          // Convert hours and minutes to numbers
          const hours = parseInt(hoursStr, 10);
          const minutes = parseInt(minutesStr, 10);

          // Calculate total minutes and add the increment value
          let totalMinutes = hours * 60 + minutes + interval;

          // Calculate new hours and minutes
          const newHours = Math.floor(totalMinutes / 60) % 24; // Ensure it stays within 24 hours
          const newMinutes = totalMinutes % 60;

          // Format the new time string
          const newTime = `${newHours.toString().padStart(2, "0")}:${newMinutes
            .toString()
            .padStart(2, "0")}`;

          temp[i].time = newTime;
        }
      }
    }
    setFormatted(temp);
    setUpdate((c) => c + 1);
  }

  function sgTimes() {
    let startTime = "";
    const temp = [...formatted];

    for (let i = 0; i < temp.length; i++) {
      if (i === 0) {
        startTime = temp[i].time;
      } else {
        temp[i].time = startTime;
      }
    }

    setFormatted(temp);
    setUpdate((c) => c + 1);
  }

  function sortRounds() {
    const opts = roundIDs.map((id, i) => {
      const toRet = {
        label: `Round ${i + 1}`,
        value: id,
      };
      return toRet;
    });
    setRDOpts(opts);
    setSelRd(activeRoundID);
    loadRoundGroups(activeRoundID);
  }

  useEffect(() => {
    if (guests.length > 0) {
      const remArr = [];
      setGroups((c) => {
        const toRet = [...c, ...guests];
        // console.log(toRet);
        return toRet;
      });

      setUpdate((c) => c + 1);
      for (let i = 0; i < guests.length; i++) {
        const guest = guests[i];
        guest.playerID = guest.guestID;
        guest.profileImage = "";
        guest.guest = true;
        guest.name = `${guest.firstName} ${guest.lastName}`;
        let found = false;
        for (let z = 0; z < defaultGroups.length; z++) {
          const group = defaultGroups[z];
          const { players } = group;
          for (let x = 0; x < players.length; x++) {
            if (players[x].playerID === guest.guestID) {
              found = true;
            }
          }
        }
        if (found === false) {
          remArr.push(guest);
        }
      }
      setRemoved((current) => {
        return [...current, ...remArr];
      });
    }

    if (open) {
      const newGroups = defaultGroups;

      const minGroups = Math.ceil(players.length / groupLimit);

      const diff = minGroups - defaultGroups.length;

      const playingArr = [];

      for (let i = 0; i < newGroups.length; i++) {
        const group = newGroups[i];
        const { players } = group;
        for (let n = 0; n < players.length; n++) {
          if (players[n].name !== "Empty") {
            playingArr.push(players[n].playerID);
          }
        }
        const diff = defaultLimit - players.length;
        for (let x = 0; x < diff; x++) {
          newGroups[i].players.push({
            name: "Empty",
          });
        }
      }

      for (let i = 0; i < diff; i++) {
        const plarr = [];
        for (let n = 0; n < groupLimit; n++) {
          plarr.push({ name: "Empty" });
        }
        newGroups.push({
          players: plarr,
          time: "",
          startingTee: 1,
        });
      }

      const filtered = players.filter(
        (player) => !playingArr.includes(player.playerID)
      );

      setRemoved((current) => [...current, ...filtered]);

      setFormatted(newGroups);

      return;
    }

    if (live) {
      const lbMap = convertArrayToObject(players, "playerID");
      // let playerIDs = players.map((pl) => pl.playerID);
      let playArr = [...players];
      // const addedPlayers = []
      let longest = 0;
      const teeTimes = [...defaultGroups];
      for (let j = 0; j < teeTimes.length; j++) {
        const group = teeTimes[j];
        if (typeof group.time !== "string") {
          const date = new Date(group.time.toDate());
          const time = date.toLocaleTimeString();
          teeTimes[j].time = time;
        }
        const groupPlayers = group.players ?? [];
        if (groupPlayers.length > longest) {
          longest = groupPlayers.length;
        }
        for (let x = 0; x < groupPlayers.length; x++) {
          const player = groupPlayers[x];

          if (typeof player === "string") {
            playArr = playArr.filter((pl) => pl.playerID !== player);
          } else {
            playArr = playArr.filter((pl) => pl.playerID !== player.playerID);
          }

          const pts = lbMap[player];

          if (pts) {
            teeTimes[j].players[x] = lbMap[player];
          }
        }
      }

      console.log(longest);

      playArr = playArr.filter((pl) => !pl.dq);
      playArr = playArr.filter((pl) => !pl.wd);
      setRemoved(playArr);

      for (let i = 0; i < teeTimes.length; i++) {
        const players = teeTimes[i].players ?? [];
        if (players.length < longest) {
          while (teeTimes[i].players.length < longest) {
            teeTimes[i].players.push({
              name: "Empty",
            });
          }
        }
      }
      console.log(teeTimes);
      setGroupLimit(longest);
      setFormatted(teeTimes);
      setUpdate((current) => current + 1);
      return setSorted(true);
    }

    if (live) {
      // alert("here");
      const newPlayerArr = [];
      for (let i = 0; i < defaultGroups.length; i++) {
        const group = defaultGroups[i];
        for (let j = 0; j < group.players.length; j++) {
          newPlayerArr.push(group.players[j]);
        }
      }
      setGroupLimit(defaultGroups[0].players.length);
      setGroups(newPlayerArr);
      setFormatted(defaultGroups);
      setUpdate((current) => current + 1);
      // formatGroups();
      return setSorted(true);
    }

    if (event && false) {
      loadGroups();
      return;
    }

    if (
      defaultGroups.length > 1 &&
      defaultGroups[0].players.length > 0 &&
      event
    ) {
      // const groupIDs = groups.map((player) => player.playerID);
      const founds = [];
      for (let i = 0; i < defaultGroups.length; i++) {
        const group = defaultGroups[i];
        for (let j = 0; j < group.players.length; j++) {
          founds.push(group.players[j].playerID);
        }
        if (group.players.length < defaultLimit) {
          const diff = defaultLimit - group.players.length;
          for (let x = 0; x < diff; x++) {
            defaultGroups[i].players.push({ name: "Empty" });
          }
        }
      }

      const rem = [];

      for (let i = 0; i < groups.length; i++) {
        if (!founds.includes(groups[i].playerID)) {
          rem.push(groups[i]);
        }
      }

      setRemoved((current) => [...current, ...rem]);
      // formatGroups(groups);
      setFormatted(defaultGroups);
      setUpdate((current) => current + 1);
      return setSorted(true);
    }
    if (format !== "net-strokeplay" && defaultGroups.length < 2) {
      if (Math.floor(players.length / 2) === 0) {
        setGroupLimit(1);
      } else {
        let lim = Math.floor(players.length / 2);
        if (lim > 4) {
          lim = 4;
        }

        setGroupLimit(lim);
      }
      window.scrollTo(0, 0);
    }

    if (sorted) {
      return;
    }
    if (!event) {
      sortGroups();
    }
    if (event) {
      // alert("here");
      if (
        defaultGroups.length === 0 ||
        defaultGroups.length < players.length / 4 ||
        defaultGroups[0].players.length === 0
      ) {
        sortGroups();
      } else {
        setFormatted(defaultGroups);
        formatGroups();
      }
    }
  }, []);

  async function loadGroups() {
    setLoading(true);
    // console.log("LOAD GROUPS");
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const { rounds } = event;
    const guests = event.guests ?? [];
    const round = rounds[roundIndex];
    const groups = round.groups ?? [];
    const { groupLimit } = round;
    const founds = [];

    if (rounds.length > 1) {
      const mep = {};
      const arr = [];
      for (let i = 0; i < rounds.length; i++) {
        if (i !== roundIndex) {
          arr.push({
            value: i.toString(),
            label: `Round ${i + 1}`,
          });
          mep[i.toString()] = rounds[i].groups ?? [];
        }
      }

      setRDImportOpts(arr);
      setRDMap(mep);
    }

    const remArr = [];
    for (let i = 0; i < guests.length; i++) {
      const guest = guests[i];
      guest.playerID = guest.guestID;
      guest.profileImage = "";
      guest.guest = true;
      guest.name = `${guest.firstName} ${guest.lastName}`;
      let found = false;
      for (let z = 0; z < defaultGroups.length; z++) {
        const group = defaultGroups[z];
        const { players } = group;
        for (let x = 0; x < players.length; x++) {
          if (players[x].playerID === guest.guestID) {
            found = true;
          }
        }
      }
      if (found === false) {
        remArr.push(guest);
      }
    }
    setRemoved((current) => {
      return [...current, ...remArr];
    });
    setUpdate((c) => c + 1);

    if (groups.length === 0) {
      console.log("EMPTY");
      sortGroups();
      // setRemoved(players);
      setLoading(false);
    } else {
      for (let i = 0; i < groups.length; i++) {
        const group = defaultGroups[i];
        for (let j = 0; j < group.players.length; j++) {
          founds.push(group.players[j].playerID);
        }
        if (group.players.length < groupLimit) {
          const diff = defaultLimit - group.players.length;
          for (let x = 0; x < diff; x++) {
            defaultGroups[i].players.push({ name: "Empty" });
          }
        }
      }

      const rem = [];

      for (let i = 0; i < players.length; i++) {
        if (!founds.includes(players[i].playerID)) {
          rem.push(groups[i]);
        }
      }

      setRemoved((current) => [...current, ...rem]);
      // formatGroups(groups);
      setFormatted(defaultGroups);
      setUpdate((current) => current + 1);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (sorted && !live) {
      // sortGroups();
    }
  }, [groupLimit]);

  function formatGroups() {
    // alert("form");
    const arr = [];
    for (let i = 0; i < defaultGroups.length; i++) {
      const group = defaultGroups[i];
      const groupPlayers = group.players ?? [];
      if (groupPlayers.length >= groupLimit) {
        setGroupLimit(groupPlayers.length);
      }
      for (let j = 0; j < groupPlayers.length; j++) {
        arr.push(groupPlayers[j]);
      }
    }

    if (arr.length !== players.length) {
      for (let i = 0; i < players.length; i++) {
        const player = players[i];
        let found = false;
        for (let j = 0; j < arr.length; j++) {
          if (player.playerID === arr[j].playerID) {
            found = true;
          }
        }
        if (!found) {
          arr.push(player);
        }
      }
      const ceil = Math.ceil(groups.length / groupLimit);
      setGroupLimit(ceil);
    }
    // for (let i = 0; i < arr.length; i++) {
    //   const players = arr[i].players;
    //   if (players.length < groupLimit) {
    //     while (arr[i].players.length < groupLimit) {
    //       arr[i].players.push({
    //         name: "Empty",
    //       });
    //     }
    //   }
    // }
    setGroups(arr);
    setSorted(true);
  }

  function sortGroups(custom = "") {
    let gl = groupLimit;
    if (custom !== "") {
      gl = custom;
    }
    const arr = [];
    const ceil = Math.ceil(groups.length / gl);
    const remainder = groups.length % gl;
    // console.log(groups);
    let newGroups = [...groups];
    for (let j = 0; j < ceil; j++) {
      // const players = []
      arr[j] = {};
      arr[j]["players"] = [];
    }
    for (let i = 0; i < groups.length; i++) {
      let groupNum = Math.floor(i / gl);
      // console.log(groups[i], groupNum);
      // if (
      //   i === groups.length - remainder - 1 &&
      //   groupLimit - remainder === 2 &&
      //   groupLimit === 4
      // ) {
      //   groupNum += 1;
      // }
      // if (i === groups.length - remainder - 1 && groupLimit === 3) {
      //   groupNum += 1;
      // }
      if (groups[i].tee === undefined) {
        groups[i].tee = courseDetails.teeArray[0];
      }
      arr[groupNum]["players"].push(groups[i]);
    }

    for (let n = 0; n < arr.length; n++) {
      if (formatted.length > n) {
        arr[n].startingTee = formatted[n].startingTee;
        arr[n].time = formatted[n].time;
      } else {
        arr[n].startingTee = 1;
        const now = new Date(eventDate);
        if (shotgun) {
          arr[n].time = now.toLocaleTimeString("en-gb", {
            timeStyle: "short",
          });
        } else {
          now.setMinutes(now.getMinutes() + n * intervals);
          arr[n].time = now.toLocaleTimeString("en-gb", {
            timeStyle: "short",
          });
        }
      }
    }

    if (gl === 4 && arr.length > 2) {
      // console.log("here");
      if (remainder === 1) {
        // Two to move
        const thirdLast = arr.length - 3;
        const secondLast = arr.length - 2;
        const last = arr.length - 1;
        arr[last].players.push(arr[thirdLast].players[3]);
        arr[last].players.push(arr[secondLast].players[3]);
        arr[thirdLast].players.pop();
        arr[secondLast].players.pop();
        arr[last].players.reverse();
      }
      if (remainder === 2 && arr.length > 1) {
        // One to move
        const secondLast = arr.length - 2;
        const last = arr.length - 1;
        arr[last].players.push(arr[secondLast].players[3]);
        arr[secondLast].players.pop();
        arr[last].players.reverse();
      }
    }

    if (gl === 3 && remainder === 1 && arr.length > 2) {
      // One to move
      const secondLast = arr.length - 2;
      const last = arr.length - 1;
      arr[last].players.push(arr[secondLast].players[2]);
      arr[secondLast].players.pop();
      arr[last].players.reverse();
    }

    const ids = [];

    for (let i = 0; i < arr.length; i++) {
      const players = arr[i].players;

      for (let j = 0; j < players.length; j++) {
        if (players[j].playerID) {
          ids.push(players[j].playerID);
        }
      }

      // console.log(players.length, gl);

      if (players.length < gl) {
        while (arr[i].players.length < gl) {
          arr[i].players.push({
            name: "Empty",
          });
        }
      }
    }

    setRemoved((c) => {
      c.filter((item) => {
        console.log(ids.includes(item.playerID), item.playerID);
        return !ids.includes(item.playerID);
      });
      return c;
    });

    setOverall(arr);
    setFormatted(arr);
    setSorted(true);
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  function sortPlayers(result) {
    if (!result.destination) {
      return;
    }

    const start = result.source.index;
    const end = result.destination.index;

    setGroups((current) => {
      const arr = array_move(current, start, end);
      for (let i = 0; i < arr.length; i++) {
        arr[i].players = arr[i].players.sort((a, b) =>
          a.name === "Empty" ? 1 : -1
        );
      }
      setOverall(arr);
      setFormatted(arr);
      return arr;
    });

    sortGroups();
  }

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      // padding: grid * 2,
      // margin: `0 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? "#edefef" : "transparent",
      // paddingBottom: '20px',
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const [hintsEnabled, setHintsEnabled] = useState(false);

  const steps = [
    {
      element: ".group-limit",
      intro: "Select the maximum amount of players per group",
      position: "top",
    },
    {
      element: ".shuffle-players",
      intro: "Tap here to randomize the group selection",
      position: "top",
    },
  ];

  async function saveLiveChanges() {
    // return console.log(formatted);
    setLoading(true);
    for (let i = 0; i < formatted.length; i++) {
      const group = formatted[i];
      formatted[i].players = group.players.filter((pl) => pl.name !== "Empty");
    }
    // alert("doi");
    // setLoading(false);
    // return console.log(formatted);

    await db
      .collection("rounds")
      .doc(rdOpts.length > 1 ? selRd : roundID)
      .update({
        teeTimes: formatted,
        shotgun,
      });
    saveChanges();
    reload();
    toast.success("Groups were successfully changed", {
      icon: successIco,
    });
    setLoading(false);
    goBack();
  }

  function handleDrop(e) {
    if (!e.destination) {
      return;
    }
    const dest = parseInt(e.destination.droppableId);
    const playerID = e.draggableId;
    const source = parseInt(e.source.droppableId);

    const start = e.source.index;
    const end = e.destination.index;

    if (dest === source) {
      setFormatted((current) => {
        const group = current[dest].players;
        const arr = array_move(group, start, end);
        current[dest].players = arr;
        // for (let i = 0; i < current.length; i++) {
        //   current[i].players = current[i].players.sort((a, b) =>
        //     b.name === "Empty" ? -1 : 1
        //   );
        // }
        return current;
      });
    } else {
      setFormatted((current) => {
        let temp = [...current];
        const p1 = temp[source].players[start];
        const p2 = temp[dest].players[end];
        temp[source].players[start] = p2;
        temp[dest].players[end] = p1;
        // for (let i = 0; i < temp.length; i++) {
        //   temp[i].players = temp[i].players.sort((a, b) =>
        //     a.name === "Empty" ? 1 : -1
        //   );
        // }
        return temp;
      });
    }
  }

  function arrange(type = "") {
    if (type === "points table") {
      const mep = {};

      for (let i = 0; i < table.length; i++) {
        const row = table[i];
        const { playerID, points } = row;
        mep[playerID] = points;
      }
      const sorted = groups.sort((a, b) => {
        return mep[a.playerID] - mep[b.playerID];
      });

      setFormatted((current) => {
        // Clear groups
        for (let i = 0; i < current.length; i++) {
          current[i].players = [];
        }

        // Populate Groups
        for (let i = 0; i < current.length; i++) {
          let added = false;
          for (let j = 0; j < groupLimit; j++) {
            const mult = i * groupLimit + j;
            if (sorted[mult]) {
              current[i].players.push(sorted[mult]);
            } else {
              current[i].players.push({ name: "Empty" });
            }
          }
        }

        return current;
      });
      setUpdate((current) => current + 1);
    }
    if (type === "handicap (mixed)") {
      const sorted = groups.sort((a, b) => a.handicap - b.handicap);
      const playerGroups = [];
      const groupLength = formatted.length;
      // const groupLimit = Math.ceil(sorted.length / )
      // Set up hcp groups
      for (let i = 0; i < groupLimit; i++) {
        playerGroups.push([]);
      }

      // Split into groups
      for (let i = 0; i < sorted.length; i++) {
        const mult = Math.floor(i / groupLength);
        playerGroups[mult].push(sorted[i]);
      }

      setFormatted((current) => {
        // Clear groups
        for (let i = 0; i < current.length; i++) {
          current[i].players = [];
        }

        // Populate groups
        for (let i = 0; i < current.length; i++) {
          for (let j = 0; j < groupLimit; j++) {
            if (playerGroups[j][i]) {
              current[i].players.push(playerGroups[j][i]);
            } else {
              current[i].players.push({ name: "Empty" });
            }
          }
        }
        return current;
      });
      setUpdate((current) => current + 1);
    }

    if (type === "handicap (highest first)") {
      const sorted = groups.sort((a, b) => b.handicap - a.handicap);
      setFormatted((current) => {
        // Clear groups
        for (let i = 0; i < current.length; i++) {
          current[i].players = [];
        }

        // Populate Groups
        for (let i = 0; i < current.length; i++) {
          let added = false;
          for (let j = 0; j < groupLimit; j++) {
            const mult = i * groupLimit + j;
            if (sorted[mult]) {
              current[i].players.push(sorted[mult]);
            } else {
              current[i].players.push({ name: "Empty" });
            }
          }
        }
        return current;
      });
      setRemoved((c) => []);

      setUpdate((current) => current + 1);
    }
    if (type === "handicap (lowest first)") {
      const sorted = groups.sort((a, b) => a.handicap - b.handicap);
      setFormatted((current) => {
        // Clear groups
        for (let i = 0; i < current.length; i++) {
          current[i].players = [];
        }

        // Populate Groups
        for (let i = 0; i < current.length; i++) {
          let added = false;
          for (let j = 0; j < groupLimit; j++) {
            const mult = i * groupLimit + j;
            if (sorted[mult]) {
              current[i].players.push(sorted[mult]);
            } else {
              current[i].players.push({ name: "Empty" });
            }
          }
        }
        return current;
      });
      setRemoved((c) => []);
      setUpdate((current) => current + 1);
    }
  }

  function swapElements(arr, i1, i2) {
    // Step 1
    let temp = arr[i1];

    // Step 2
    arr[i1] = arr[i2];

    // Step 3
    arr[i2] = temp;
    return arr;
  }

  function handleGroupDrop(destIndex, sourceIndex) {
    const changedGroups = swapElements(formatted, sourceIndex, destIndex);
    for (let i = 0; i < changedGroups.length; i++) {
      const now = new Date(eventDate);
      now.setMinutes(now.getMinutes() + i * 8);
      changedGroups[i].time = now.toLocaleTimeString("en-gb", {
        timeStyle: "short",
      });
    }
    setFormatted(changedGroups);
    setUpdate((current) => current + 1);
  }

  function addLiveEmpty(lim) {
    setFormatted((c) => {
      for (let i = 0; i < c.length; i++) {
        const row = c[i];
        if (row.players) {
          if (row.players.length < lim) {
            const diff = lim - row.players.length;
            for (let j = 0; j < diff; j++) {
              c[i].players.push({ name: "Empty" });
            }
          }
        }
      }
      return c;
    });
  }

  function removeLiveEmpty(lim) {
    setFormatted((c) => {
      for (let i = 0; i < c.length; i++) {
        const row = c[i];
        if (row.players) {
          if (row.players[lim]) {
            if (row.players[lim].name === "Empty") {
              c[i].players.pop();
            }
          }
        }
      }
      console.log(c);
      return c;
    });
  }

  async function loadRoundGroups(roundID) {
    setLoading(true);
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { teeTimes } = round;
    for (let i = 0; i < teeTimes.length; i++) {
      if (typeof teeTimes[i].time !== "string") {
        teeTimes[i].time = new Date(
          teeTimes[i].time.toDate()
        ).toLocaleTimeString();
      }
    }
    setFormatted(teeTimes);
    setLeaderboard(round.leaderboard);
    setLoading(false);
  }

  function sortByLB() {
    if (lbSort) {
      leaderboard.reverse();
    }

    const groupAmount = Math.ceil(leaderboard.length / groupLimit);
    const groups = [...formatted];
    for (let i = 0; i < groups.length; i++) {
      groups[i].players = [];
    }

    for (let i = 0; i < leaderboard.length; i++) {
      const { name, playerID, handicap, profileImage } = leaderboard[i];

      let found = false;
      for (let j = 0; j < groups.length; j++) {
        if (found) {
          continue;
        }
        if (groups[j].players.length < groupLimit) {
          groups[j].players.push({
            name,
            playerID,
            handicap,
            profileImage,
          });
          found = true;
        }
      }
      if (found === false) {
        groups.push({
          time: "",
          startingTee: 1,
          players: [
            {
              name,
              playerID,
              handicap,
              profileImage,
            },
          ],
        });
      }
    }

    setRemoved([]);
    setLBSort((c) => !c);
    setFormatted(groups);
    setUpdate((c) => c + 1);
  }

  return (
    <>
      {showSel ? (
        <EventPlayerUpload
          close={() => setShowSel(false)}
          eventID={eventID}
          clubID={clubID}
        />
      ) : showSetup ? (
        setup
      ) : (
        <div className="group-selector">
          {loading && <BackdropLoader />}
          <Dialog
            open={showSettings}
            onClose={() => {
              setCookie("gs-settings", settings);
              setShowSettings(false);
            }}
          >
            <div className="sett-dia ">
              <PageHeader
                text="Settings"
                showClose
                close={() => {
                  setCookie("gs-settings", settings);
                  setShowSettings(false);
                }}
              />

              <div className="set-dia-ctt pd-20">
                <div className="eve-rds">
                  {teams.length > 0 && (
                    <div className="eve-rd-item">
                      <Checkbox
                        checked={settings.showTeam}
                        onChange={(e) => {
                          setSettings((c) => {
                            c.showTeam = !c.showTeam;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      />
                      <p>Display Team Names</p>
                    </div>
                  )}
                  {/* <div className="eve-rd-item">
                    <Checkbox
                      checked={settings.hideComplete}
                      onChange={(e) => {
                        setSettings((c) => {
                          c.hideComplete = !c.hideComplete;
                          return c;
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>Hide Complete Groups</p>
                  </div> */}
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog open={showRemoved} onClose={() => setShowRemoved(false)}>
            <div className="gs-rem-dia">
              <PageHeader
                text="Select Player"
                close={() => setShowRemoved(false)}
                showClose
              />
              <div className="ph-msg">
                <p>Select a player to fill this spot</p>
              </div>
              <div className="input-group pl-20">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="gsrd-players">
                {removed.map((player) => {
                  if (searchInput !== "") {
                    const lcSearch = searchInput.toLowerCase();
                    const lcName = player.name.toLowerCase();
                    if (lcName.includes(lcSearch)) {
                      return (
                        <PlayerItem
                          hcp={player.handicap}
                          name={player.name}
                          noLink
                          hideIcons
                          img={player.profileImage}
                          key={player.playerID}
                          showHover
                          showProfile={() => {
                            const { i1, i2 } = remIndexes;
                            setFormatted((current) => {
                              current[i1].players[i2] = player;
                              return current;
                            });
                            setRemoved((current) =>
                              current.filter(
                                (pla) => pla.playerID !== player.playerID
                              )
                            );
                            setUpdate((current) => current + 1);
                            setShowRemoved(false);
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  }

                  return (
                    <PlayerItem
                      hcp={player.handicap}
                      name={player.name}
                      noLink
                      hideIcons
                      img={player.profileImage}
                      key={player.playerID}
                      showHover
                      showProfile={() => {
                        const { i1, i2 } = remIndexes;
                        setFormatted((current) => {
                          current[i1].players[i2] = player;
                          return current;
                        });
                        setRemoved((current) =>
                          current.filter(
                            (pla) => pla.playerID !== player.playerID
                          )
                        );
                        setUpdate((current) => current + 1);
                        setShowRemoved(false);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </Dialog>

          <Dialog
            open={groupMove}
            className="mpa-dia"
            onClose={() => setGroupMove(false)}
          >
            <div className="gs-rem-dia">
              <PageHeader
                text="Move Groups"
                close={() => setGroupMove(false)}
                showClose
              />
              <div className="ph-msg">
                <p>Select a spot to move to </p>
              </div>
              <div className="gs-groups">
                {formatted.map((group, index) => {
                  if (index === moveGroup) {
                    return null;
                  }
                  return (
                    <TimeItem
                      key={`${index}time`}
                      selectable
                      onClick={() => {
                        setFormatted((current) => {
                          const moved = moveElementInArray(
                            current,
                            moveGroup,
                            index
                          );
                          return moved;
                        });
                        setGroupMove(false);
                      }}
                      players={group.players}
                      tee={group.startingTee}
                      time={group.time}
                    />
                  );
                })}
              </div>
            </div>
          </Dialog>
          <Dialog
            open={groupSwap}
            className="mpa-dia"
            onClose={() => setGroupSwap(false)}
          >
            <div className="gs-rem-dia">
              <PageHeader
                text="Swap Groups"
                close={() => setGroupSwap(false)}
                showClose
              />
              <div className="ph-msg">
                <p>Select a group to swap out</p>
              </div>
              <div className="gs-groups">
                {formatted.map((group, index) => {
                  if (index === swapGroup) {
                    return null;
                  }
                  return (
                    <TimeItem
                      key={`${index}time`}
                      selectable
                      onClick={() => {
                        handleGroupDrop(index, swapGroup);
                        setGroupSwap(false);
                      }}
                      players={group.players}
                      tee={group.startingTee}
                      time={group.time}
                    />
                  );
                })}
              </div>
            </div>
          </Dialog>

          <Dialog
            className="mpa-dia"
            open={showSwap}
            onClose={() => setShowSwap(false)}
          >
            <div className="gs-rem-dia">
              <PageHeader
                text="Swap Player"
                close={() => setShowSwap(false)}
                showClose
              />
              <div className="ph-msg">
                <p>Select a player to swap out</p>
              </div>
              <div className="input-group pl-20">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="gsrd-players">
                {groups.map((player) => {
                  const remMap = removed.map((p) => p.playerID);
                  if (remMap.includes(player.playerID)) {
                    return null;
                  }
                  if (searchInput !== "") {
                    const lcSearch = searchInput.toLowerCase();
                    const lcName = player.name.toLowerCase();
                    if (lcName.includes(lcSearch)) {
                      return (
                        <PlayerItem
                          hcp={player.handicap}
                          name={player.name}
                          noLink
                          hideIcons
                          img={player.profileImage}
                          key={player.playerID}
                          showHover
                          showProfile={() => {
                            const { i1, i2 } = swapIndices;
                            setFormatted((current) => {
                              const p1 = current[i1].players[i2];
                              let p2;
                              let found = false;
                              let p2group;
                              let p2index;
                              for (let i = 0; i < current.length; i++) {
                                if (found) {
                                  break;
                                }
                                const group = current[i];
                                const players = group.players;
                                for (let j = 0; j < players.length; j++) {
                                  if (players[j].playerID === player.playerID) {
                                    p2 = players[j];
                                    p2group = i;
                                    p2index = j;
                                    found = true;
                                  }
                                }
                              }
                              current[p2group].players[p2index] = p1;
                              current[i1].players[i2] = p2;
                              return current;
                            });
                            setShowSwap(false);
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  }

                  return (
                    <PlayerItem
                      hcp={player.handicap}
                      name={player.name}
                      noLink
                      hideIcons
                      img={player.profileImage}
                      key={player.playerID}
                      showHover
                      showProfile={() => {
                        const { i1, i2 } = swapIndices;
                        setFormatted((current) => {
                          const p1 = current[i1].players[i2];
                          let p2;
                          let found = false;
                          let p2group;
                          let p2index;
                          for (let i = 0; i < current.length; i++) {
                            if (found) {
                              break;
                            }
                            const group = current[i];
                            const players = group.players;
                            for (let j = 0; j < players.length; j++) {
                              if (players[j].playerID === player.playerID) {
                                p2 = players[j];
                                p2group = i;
                                p2index = j;
                                found = true;
                              }
                            }
                          }
                          if (p2 === undefined) {
                            p2 = player;
                            current[i1].players[i2] = p2;
                          } else {
                            current[p2group].players[p2index] = p1;
                            current[i1].players[i2] = p2;
                          }
                          return current;
                        });
                        setShowSwap(false);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </Dialog>

          <div
            onClick={() => {
              setOverall(formatted);
              goBack();
            }}
            ref={scrollRef}
            className="back-row"
          >
            <ArrowBack className="ts-arrow" />
            <p>Back</p>
          </div>
          {courseName !== "" && (
            <div className="ph-top mt-20">
              <p>
                {courseName !== "" && (
                  <>
                    {eventName} at {courseName}
                  </>
                )}
              </p>
            </div>
          )}
          <div className="sett-ph">
            <PageHeader text={live ? "Edit Groups" : "Round Setup"} />
            {teams.length > 0 && (
              <Settings
                className="dg-icon-bg icon-cursor sett-ph-ico"
                onClick={() => setShowSettings(true)}
              />
            )}
          </div>

          {rdOpts.length > 1 && (
            <div className="input-group groups-hint pl-20">
              <p className="">Select Round</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                onChange={(e) => {
                  setSelRd(e.value);
                  loadRoundGroups(e.value);
                }}
                options={rdOpts}
                value={selRd}
              />
            </div>
          )}

          <div className="cr-group">
            <div className="gs-row mt-20">
              <div className="crg-right group-limit">
                <div className="input-group mt-0 mr-5v">
                  <p className="ne-">Group Limit</p>
                </div>
                <div className="tee-numbers">
                  <div className="svg-cont">
                    <GradientMinusIcon
                      onClick={() => {
                        setGroupLimit((current) => {
                          if (current === 2) {
                            return current;
                          } else {
                            if (live) {
                              removeLiveEmpty(current - 1);
                            } else {
                              sortGroups(current - 1);
                            }

                            return current - 1;
                          }
                        });

                        setUpdate((current) => current + 1);
                      }}
                    />
                  </div>

                  <p>{groupLimit}</p>
                  <div className="svg-cont">
                    <GradientAddIcon
                      onClick={() => {
                        setGroupLimit((current) => {
                          if (
                            format !== "net-strokeplay" &&
                            format !== "medal"
                          ) {
                            if (
                              Math.floor(players.length / (current + 1)) < 1
                            ) {
                              return current;
                            }
                          }

                          if (format.includes("-match")) {
                            if (players.length / (current + 1) < 2) {
                              return current;
                            }
                          }

                          if (current === 4) {
                            return current;
                          } else {
                            if (true) {
                              addLiveEmpty(current + 1);
                            }
                            return current + 1;
                          }
                        });

                        setUpdate((current) => current + 1);
                      }}
                    />
                  </div>
                </div>
              </div>
              {!shotgun && (
                <div className="crg-right group-limit">
                  <div className="input-group mt-0 mr-5v">
                    <p className="ne-">Intervals</p>
                  </div>
                  <div className="tee-numbers">
                    <div className="svg-cont">
                      <GradientMinusIcon
                        onClick={() => {
                          setIntervals((current) => {
                            if (current === 1) {
                              return current;
                            } else {
                              sortIntervals(current - 1);
                              return current - 1;
                            }
                          });
                          setUpdate((current) => current + 1);
                        }}
                      />
                    </div>

                    <p>{intervals}</p>
                    <div className="svg-cont">
                      <GradientAddIcon
                        onClick={() => {
                          setIntervals((current) => {
                            sortIntervals(current + 1);
                            return current + 1;
                          });

                          setUpdate((current) => current + 1);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="gsr-flex">
                <div className="input-group groups-hint mr-5v">
                  <p className="ne-">Group Presets</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    onChange={(e) => arrange(e.value)}
                    options={groupTypes}
                  />
                </div>
                <div className="input-group groups-hint">
                  <p className="ne-">Sort By</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    onChange={(e) => {
                      if (e.value === "Shuffle") {
                        setGroups((current) => {
                          const newarr = shuffle(current);

                          return newarr;
                        });
                        setRemoved((current) => []);
                        setUpdate((current) => current + 1);
                        sortGroups();
                        setUpdate((current) => current + 1);
                      }
                      if (e.value === "Time") {
                        setFormatted((current) => {
                          current.sort((a, b) => compareTimes(a.time, b.time));
                          return current;
                        });
                        setUpdate((current) => current + 1);
                      }
                      if (e.value === "Teams") {
                        const arr = [];
                        for (let i = 0; i < teams.length; i++) {
                          const team = teams[i];
                          console.log(team);
                          arr.push({
                            players: team.players,
                            startingTee: 1,
                            time: "",
                            name: team.teamName ?? "",
                          });
                        }

                        setFormatted(arr);
                        setRemoved([]);
                        setUpdate((c) => c + 1);
                      }
                    }}
                    options={sortOpts}
                  />
                </div>
                {live && (
                  <>
                    <div className="input-group ml-5v">
                      <p>Start Type</p>
                      <div className="flex">
                        <div className="selector-group sg-small mr-20">
                          <button
                            onClick={() => {
                              setShotgun(false);
                              sortIntervals(intervals);
                            }}
                            className={
                              shotgun === false
                                ? "sg-left sg-active"
                                : "sg-left"
                            }
                          >
                            Regular
                          </button>
                          <button
                            onClick={() => {
                              setShotgun(true);
                              sgTimes();
                            }}
                            className={
                              shotgun === true
                                ? "sg-right sg-active"
                                : "sg-right"
                            }
                          >
                            Shotgun
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!live && rdImportOpts.length > 0 && (
                  <div className="input-group groups-hint ml-20">
                    <p className="ne-">Import from Round</p>
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      onChange={(e) => {
                        console.log(e, rdMap[e.value]);
                        setFormatted(rdMap[e.value]);
                      }}
                      options={rdImportOpts}
                    />
                  </div>
                )}
                {!live && rounds.length > 1 && (
                  <div className="input-group pl-20">
                    <p>Change for all Rounds</p>
                    <Switch
                      onColor="#1e7a69"
                      checked={cfa}
                      className={
                        cfa ? "scoring-switch switch-on" : "scoring-switch"
                      }
                      onChange={() => {
                        setCFA((current) => {
                          // handleSR(!current);
                          return !current;
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* <InputNumber defaultValue={10} controls={true} />    */}
          </div>
          <div className="quick-links mt-20">
            {clubID !== "" && !isMobile && (
              <Pill
                onClick={() => {
                  setSetup(
                    <LiveGroupUpload
                      close={() => setShowSetup(false)}
                      clubID={clubID}
                      roundID={selRd}
                      reload={loadRoundGroups}
                    />
                  );
                  setShowSetup(true);
                }}
                text={"Upload Groups"}
              />
            )}
            {!swapMode && (
              <>
                {false && (
                  <Pill
                    text={"Shuffle"}
                    onClick={() => {
                      setGroups((current) => {
                        const newarr = shuffle(current);

                        return newarr;
                      });
                      setRemoved((current) => []);
                      setUpdate((current) => current + 1);
                      sortGroups();
                      setUpdate((current) => current + 1);
                    }}
                  />
                )}

                <Pill
                  text={"Add Group"}
                  onClick={() => {
                    const newGroup = { players: [], startingTee: 1, time: "" };
                    for (let i = 0; i < groupLimit; i++) {
                      newGroup.players.push({ name: "Empty" });
                    }
                    setFormatted((current) => {
                      current.push(newGroup);
                      return current;
                    });
                    setUpdate((current) => current + 1);
                  }}
                />
                {/* <Pill text={"Import Groups"} onClick={() => {}} /> */}
                {false && (
                  <Pill
                    text={"Sort By Time"}
                    onClick={() => {
                      setFormatted((current) => {
                        current.sort((a, b) => compareTimes(a.time, b.time));
                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                )}
                {false && leaderboard.length > 0 && (
                  <Pill text={"Sort by Leaderboard"} onClick={sortByLB} />
                )}
                {false && teams.length > 0 && (
                  <Pill
                    text={"Sort by teams"}
                    onClick={() => {
                      const arr = [];
                      for (let i = 0; i < teams.length; i++) {
                        const team = teams[i];
                        console.log(team);
                        arr.push({
                          players: team.players,
                          startingTee: 1,
                          time: "",
                          name: team.teamName ?? "",
                        });
                      }

                      setFormatted(arr);
                      setUpdate((c) => c + 1);
                    }}
                  />
                )}
                {matches.length > 0 && (
                  <Pill
                    text={"Sort by matches"}
                    onClick={() => {
                      const arr = [];
                      for (let i = 0; i < matches.length; i++) {
                        const match = matches[i];
                        if (match.playerOne) {
                          arr.push({
                            time: match.time ?? "",
                            tee: 1,
                            players: [match.playerOne, match.playerTwo],
                          });
                          setFormatted(arr);
                          setUpdate((c) => c + 1);
                        } else {
                          arr.push({
                            time: match.time ?? " ",
                            tee: 1,
                            players: [...match.teamOne, ...match.teamTwo],
                          });
                          setFormatted(arr);
                          setUpdate((c) => c + 1);
                        }
                        // arr.push({
                        //   players:
                        // })
                      }
                    }}
                  />
                )}
              </>
            )}
            <Pill
              onClick={() =>
                setSwapMode((c) => {
                  if (c) {
                    setSwapID("");
                  }
                  return !c;
                })
              }
              text={swapMode ? "Disable Swap Mode" : "Enable Swap Mode"}
            />
            <Pill
              red
              text={"Clear Groups"}
              onClick={() => {
                const arr = [];
                const idArr = [];

                for (let i = 0; i < formatted.length; i++) {
                  const group = formatted[i];
                  const players = group.players ?? [];
                  for (let j = 0; j < players.length; j++) {
                    const player = players[j];
                    if (
                      player.name !== "Empty" &&
                      !idArr.includes(player.playerID)
                    ) {
                      arr.push(player);
                      idArr.push(player.playerID);
                    }
                  }
                }

                setFormatted([]);
                setRemoved(arr);
                setUpdate((c) => c + 1);
              }}
            />
          </div>

          <p className="dnd-msg">Drag and drop players to edit groups</p>

          {removed.length > 0 && (
            <>
              <div className="sub-header">
                <h5>Pending</h5>
              </div>

              <div className="gs-removed">
                {removed.map((player) => (
                  <div key={`${player.playerID}removed`} className="gsr-item">
                    <Avatar alt={player.name} src={player.profileImage} />
                    <p>{player.name}</p>
                  </div>
                ))}
              </div>
            </>
          )}

          {true && (
            <div className="drop-groups">
              <DragDropContext
                onDragEnd={(e) => {
                  // return;
                  if (e.type === "players") {
                    handleDrop(e);
                  }
                }}
              >
                {true &&
                  formatted.map((group, index) => {
                    const players = group.players ?? [];
                    // console.log(group);
                    const ids = players.map((player) => player.playerID);
                    let groupID = ids.join("");
                    groupID = `${groupID}${index}`;
                    if (groupID === "") {
                      groupID = `${index}grp`;
                    }

                    return (
                      <div key={groupID} className="test-class drop-group">
                        <div className="group-header">
                          <div className="gh-flex">
                            <div className="flex-align-center">
                              {/* <div
                                      className="flex-center"
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorIcon />
                                    </div> */}

                              <h2 className="ne-header">
                                {group.name !== ""
                                  ? group.name
                                  : `Group ${index + 1}`}
                              </h2>
                            </div>
                            <div className="flex-align-center">
                              <div
                                onClick={() => {
                                  setGroupMove(true);
                                  setMoveGroup(index);
                                }}
                                className="black-link mr-10 "
                              >
                                Move
                              </div>
                              <div
                                onClick={() => {
                                  setGroupSwap(true);
                                  setSwapGroup(index);
                                }}
                                className="green-link mr-10 "
                              >
                                Swap
                              </div>
                              <div
                                onClick={() => {
                                  setFormatted((current) => {
                                    current.splice(index, 1);

                                    const pids = [];
                                    for (let i = 0; i < current.length; i++) {
                                      const group = current[i];

                                      for (
                                        let j = 0;
                                        j < group.players.length;
                                        j++
                                      ) {
                                        if (group.players[j].playerID) {
                                          pids.push(group.players[j].playerID);
                                        }
                                      }
                                    }

                                    for (let i = 0; i < players.length; i++) {
                                      if (
                                        players[i].name !== "Empty" &&
                                        !pids.includes(players[i].playerID)
                                      ) {
                                        setRemoved((c) => [...c, players[i]]);
                                      }
                                    }
                                    return current;
                                  });
                                  setUpdate((current) => current + 1);
                                }}
                                className="red-link"
                              >
                                Remove
                              </div>
                            </div>
                          </div>

                          <div className="gh-options">
                            <input
                              className="default-input"
                              id={`timeip-${index}`}
                              value={group.time}
                              type={"time"}
                              onKeyDown={(e) => {
                                if (e.key === "Tab") {
                                  e.preventDefault();
                                  const ni = (index + 1) % formatted.length;
                                  document
                                    .getElementById(`timeip-${ni}`)
                                    .focus();
                                }
                              }}
                              onChange={(e) => {
                                setFormatted((current) => {
                                  const arr = current;
                                  arr[index].time = e.target.value;
                                  return arr;
                                });
                                setUpdate((current) => current + 1);
                              }}
                            />
                            {shotgun && (
                              <div className="ml-20">
                                <Dropdown
                                  arrowClosed={
                                    <KeyboardArrowDownIcon className="drop-icon" />
                                  }
                                  arrowOpen={
                                    <KeyboardArrowUpIcon className="drop-icon" />
                                  }
                                  placeholder={group.startingTee}
                                  // value={group.startingTee}
                                  onChange={(e) => {
                                    setFormatted((current) => {
                                      const arr = current;
                                      arr[index].startingTee = e.value;
                                      return arr;
                                    });
                                    setUpdate((current) => current + 1);
                                  }}
                                  options={[
                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                    14, 15, 16, 17, 18,
                                  ]}
                                />
                              </div>
                            )}
                            {!shotgun && (
                              <div
                                className={
                                  index === 0 ? "ighr tee-select" : "ighr"
                                }
                              >
                                <div className="ths">
                                  <div
                                    onClick={() => {
                                      setFormatted((current) => {
                                        const arr = current;
                                        arr[index].startingTee = 1;
                                        return arr;
                                      });
                                      setUpdate((current) => current + 1);
                                    }}
                                    className={
                                      group.startingTee == 1
                                        ? "thsl tshl-active"
                                        : "thsl"
                                    }
                                  >
                                    <p>1</p>
                                  </div>
                                  <div
                                    onClick={() => {
                                      setFormatted((current) => {
                                        const arr = current;
                                        arr[index].startingTee = 10;
                                        return arr;
                                      });
                                      setUpdate((current) => current + 1);
                                    }}
                                    className={
                                      group.startingTee == 10
                                        ? "thsr tshl-active"
                                        : "thsr"
                                    }
                                  >
                                    <p>10</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <Droppable
                          type="players"
                          key={`${index}group`}
                          droppableId={`${index}drop`}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="test-class drop-"
                            >
                              {players.map((player, index2) => {
                                if (player.name === "Empty") {
                                  // console.log("EMPTY");
                                  // console.log(index, index2, player.playerID);
                                  return (
                                    <div
                                      key={`${index}${index2}empty`}
                                      className="gs-empty"
                                    >
                                      <BigGradientAddIcon
                                        onClick={() => {
                                          if (swapMode) {
                                            return;
                                          }
                                          setRemIndexes({
                                            i1: index,
                                            i2: index2,
                                          });
                                          setShowRemoved(true);
                                        }}
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <Draggable
                                      index={index2}
                                      key={`${index}${index2}${player.playerID}`}
                                      draggableId={`${player.playerID}`}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          {player.name === "Empty" ? (
                                            <div className="gs-empty">
                                              <BigGradientAddIcon
                                                onClick={() => {
                                                  if (swapMode) {
                                                    return;
                                                  }
                                                  setRemIndexes({
                                                    i1: index,
                                                    i2: index2,
                                                  });
                                                  setShowRemoved(true);
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <RoundPlayer
                                              hideTee
                                              event
                                              hideHcp
                                              selSwap={
                                                swapID === player.playerID &&
                                                swapping
                                              }
                                              swapClick={() => {
                                                if (!swapMode) {
                                                  return;
                                                }
                                                if (swapping) {
                                                  const { i1, i2 } =
                                                    swapIndices;
                                                  setFormatted((current) => {
                                                    const p1 =
                                                      current[i1].players[i2];
                                                    let p2;
                                                    let found = false;
                                                    let p2group;
                                                    let p2index;
                                                    for (
                                                      let i = 0;
                                                      i < current.length;
                                                      i++
                                                    ) {
                                                      if (found) {
                                                        break;
                                                      }
                                                      const group = current[i];
                                                      const players =
                                                        group.players;
                                                      for (
                                                        let j = 0;
                                                        j < players.length;
                                                        j++
                                                      ) {
                                                        if (
                                                          players[j]
                                                            .playerID ===
                                                          player.playerID
                                                        ) {
                                                          p2 = players[j];
                                                          p2group = i;
                                                          p2index = j;
                                                          found = true;
                                                        }
                                                      }
                                                    }
                                                    if (p2 === undefined) {
                                                      p2 = player;
                                                      current[i1].players[i2] =
                                                        p2;
                                                    } else {
                                                      current[p2group].players[
                                                        p2index
                                                      ] = p1;
                                                      current[i1].players[i2] =
                                                        p2;
                                                    }
                                                    return current;
                                                  });
                                                  setSwapID("");
                                                  setSwapping(false);
                                                } else {
                                                  setSwapID(player.playerID);
                                                  setSwapIndices({
                                                    i1: index,
                                                    i2: index2,
                                                  });
                                                  setSwapping(true);
                                                }
                                              }}
                                              swapMode={swapMode}
                                              guest={player.playerID.includes(
                                                "guest"
                                              )}
                                              swapAction={() => {
                                                setSwapIndices({
                                                  i1: index,
                                                  i2: index2,
                                                });
                                                setShowSwap(true);
                                              }}
                                              removeAction={() => {
                                                const pids = [];
                                                for (
                                                  let i = 0;
                                                  i < formatted.length;
                                                  i++
                                                ) {
                                                  if (i === index) {
                                                    continue;
                                                  }

                                                  const group = formatted[i];

                                                  for (
                                                    let j = 0;
                                                    j < group.players.length;
                                                    j++
                                                  ) {
                                                    if (
                                                      group.players[j].playerID
                                                    ) {
                                                      pids.push(
                                                        group.players[j]
                                                          .playerID
                                                      );
                                                    }
                                                  }
                                                }

                                                console.log(
                                                  pids.includes(player.playerID)
                                                );

                                                if (
                                                  !pids.includes(
                                                    player.playerID
                                                  )
                                                ) {
                                                  setRemoved((current) => [
                                                    ...current,
                                                    player,
                                                  ]);
                                                }
                                                setFormatted((current) => {
                                                  console.log("doing");
                                                  current[index].players[
                                                    index2
                                                  ] = {
                                                    name: "Empty",
                                                  };
                                                  // const sorted = current[
                                                  //   index
                                                  // ].players.sort((a, b) => {
                                                  //   if (a.name === "Empty") {
                                                  //     return 1;
                                                  //   } else {
                                                  //     return -1;
                                                  //   }
                                                  // });
                                                  // current[index].players = sorted;
                                                  current[index].players =
                                                    current[index].players.sort(
                                                      (a, b) =>
                                                        a.name === "Empty"
                                                          ? 1
                                                          : -1
                                                    );
                                                  console.log(current);
                                                  return current;
                                                });
                                                setUpdate((c) => c + 1);
                                              }}
                                              live
                                              hideVert={
                                                (!event && !live) || swapMode
                                              }
                                              handleSetup={() => {
                                                setSetup(
                                                  <PlayerSetup
                                                    goBack={() =>
                                                      setShowSetup(false)
                                                    }
                                                    city={
                                                      player.city ?? "Cape Town"
                                                    }
                                                    country={
                                                      player.country ??
                                                      "South Africa"
                                                    }
                                                    imageLink={
                                                      player.profileImage
                                                    }
                                                    length={
                                                      courseDetails.length
                                                    }
                                                    name={player.name}
                                                    playerID={player.playerID}
                                                    tees={
                                                      courseDetails.teeArray
                                                    }
                                                    active={
                                                      player.tee ??
                                                      courseDetails.teeArray[0]
                                                    }
                                                    setTee={(e) => {
                                                      setFormatted(
                                                        (current) => {
                                                          const newArr =
                                                            current;
                                                          for (
                                                            let i = 0;
                                                            i < newArr.length;
                                                            i++
                                                          ) {
                                                            for (
                                                              let j = 0;
                                                              j <
                                                              newArr[i].players
                                                                .length;
                                                              j++
                                                            ) {
                                                              if (
                                                                newArr[i]
                                                                  .players[j]
                                                                  .playerID ===
                                                                player.playerID
                                                              ) {
                                                                newArr[
                                                                  i
                                                                ].players[
                                                                  j
                                                                ].tee = e;
                                                              }
                                                            }
                                                          }

                                                          return newArr;
                                                        }
                                                      );
                                                    }}
                                                  />
                                                );
                                                setShowSetup(true);
                                              }}
                                              border
                                              uid={uid}
                                              playerID={player.playerID}
                                              key={`player${player.playerID}`}
                                              handicap={player.handicap}
                                              imageLink={player.profileImage}
                                              name={player.name}
                                              team={
                                                settings.showTeam
                                                  ? teamMap[player.playerID]
                                                  : ""
                                              }
                                              // name={
                                              //   teamMap[player.playerID]
                                              //     ? `${player.name} (${
                                              //         teamMap[player.playerID]
                                              //       })`
                                              //     : player.name
                                              // }
                                              tee={
                                                player.tee ??
                                                courseDetails.teeArray[0]
                                              }
                                            />
                                          )}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              })}
                              {/* {React.cloneElement(provided.placeholder, {
                                      key: "1",
                                    })} */}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}

                {/* )} */}
                {/* </Droppable> */}
              </DragDropContext>
            </div>
          )}

          {false && (
            <DragDropContext onDragEnd={sortPlayers}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="test-class"
                  >
                    {sorted &&
                      groups.map((player, index) => {
                        let startingTee = "";
                        let groupNum = 0;

                        const ceil = Math.ceil(groups.length / groupLimit);
                        const remainder = groups.length % groupLimit;

                        let changeGroup = false;

                        let hideHeader = false;

                        if (
                          index === groups.length - remainder - 1 &&
                          remainder === 1 &&
                          groupLimit === 4
                        ) {
                          changeGroup = true;
                        }

                        if (
                          index === groups.length - remainder - 1 &&
                          groupLimit === 3
                        ) {
                          changeGroup = true;
                        }

                        if (remainder === 0) {
                          changeGroup = false;
                        }

                        if (
                          index === groups.length - remainder &&
                          groupLimit - remainder === 2
                        ) {
                          hideHeader = true;
                        }
                        let showHeader = false;

                        if (Number.isInteger(index / groupLimit)) {
                          showHeader = true;
                          if (formatted.length - 1 < index / groupLimit) {
                            startingTee =
                              formatted[formatted.length - 1].startingTee;
                            groupNum = index / groupLimit;
                          } else {
                            startingTee =
                              formatted[index / groupLimit].startingTee;
                            groupNum = index / groupLimit;
                          }
                        }

                        if (
                          index === groups.length - remainder - 1 &&
                          remainder !== 0 &&
                          groupLimit === 3
                        ) {
                          showHeader = true;
                        }

                        if (changeGroup) {
                          startingTee = formatted[groupNum].startingTee;
                        }

                        if (hideHeader) {
                          showHeader = false;
                        }

                        if (showHeader) {
                          if (
                            groupNum === 0 &&
                            index !== 0 &&
                            groupLimit === 4 &&
                            remainder !== 0
                          ) {
                            groupNum = Math.ceil(index / groups.length) + 1;
                            startingTee = formatted[groupNum].startingTee;
                          }
                          if (
                            groupNum === 0 &&
                            index !== 0 &&
                            groupLimit === 3 &&
                            remainder !== 0
                          ) {
                            groupNum = Math.ceil(index / groups.length) + 2;
                          }
                        }
                        let inGroupNum = 0;
                        let gn3 = 0;
                        for (let i = 0; i < formatted.length; i++) {
                          const players = formatted[i].players;
                          for (let x = 0; x < players.length; x++) {
                            if (players[x].playerID === player.playerID) {
                              gn3 = i;
                              inGroupNum = x;
                            }
                          }
                        }
                        groupNum = gn3;
                        if (inGroupNum === 0) {
                          showHeader = true;
                          startingTee = formatted[groupNum].startingTee;
                        } else {
                          showHeader = false;
                        }

                        let timeToUse = undefined;

                        if (showHeader) {
                          if (typeof formatted[groupNum].time === "object") {
                            timeToUse = new Date(
                              formatted[groupNum].time.toDate()
                            );
                            timeToUse = timeToUse.toLocaleTimeString("en-gb", {
                              timeStyle: "short",
                            });
                          }
                        }

                        return (
                          <>
                            {/* {index === 0 && <div className='group-header'>
                <h2>Group 1</h2>
            </div>} */}

                            {showHeader && (
                              <div className="group-header">
                                <h2 className="ne-header">
                                  Group {groupNum + 1}
                                </h2>
                                <div className="gh-options">
                                  <input
                                    className="default-input"
                                    defaultValue={
                                      timeToUse
                                        ? timeToUse
                                        : formatted[groupNum] !== undefined
                                        ? formatted[groupNum].time
                                        : formatted[formatted.length - 1].time
                                    }
                                    type={"time"}
                                    onChange={(e) => {
                                      setFormatted((current) => {
                                        const arr = current;
                                        arr[groupNum].time = e.target.value;
                                        return arr;
                                      });
                                      setUpdate((current) => current + 1);
                                    }}
                                  />
                                  {shotgun && <></>}
                                  {!shotgun && (
                                    <div
                                      className={
                                        index === 0 ? "ighr tee-select" : "ighr"
                                      }
                                    >
                                      <div className="ths">
                                        <div
                                          onClick={() => {
                                            setFormatted((current) => {
                                              const arr = current;
                                              arr[groupNum].startingTee = 1;
                                              return arr;
                                            });
                                            setUpdate((current) => current + 1);
                                          }}
                                          className={
                                            startingTee === 1
                                              ? "thsl tshl-active"
                                              : "thsl"
                                          }
                                        >
                                          <p>1</p>
                                        </div>
                                        <div
                                          onClick={() => {
                                            setFormatted((current) => {
                                              const arr = current;
                                              arr[groupNum].startingTee = 10;
                                              return arr;
                                            });
                                            setUpdate((current) => current + 1);
                                          }}
                                          className={
                                            startingTee === 10
                                              ? "thsr tshl-active"
                                              : "thsr"
                                          }
                                        >
                                          <p>10</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            <Draggable
                              index={index}
                              key={player.playerID}
                              draggableId={player.playerID}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  //   style={{opacity: snapshot.isDragging ? '0.5' : '1' }}
                                >
                                  <RoundPlayer
                                    hideTee
                                    event
                                    guest={player.playerID.includes("guest")}
                                    live
                                    hideVert={!event}
                                    handleSetup={() => {
                                      setSetup(
                                        <PlayerSetup
                                          goBack={() => setShowSetup(false)}
                                          city={player.city ?? "Cape Town"}
                                          country={
                                            player.country ?? "South Africa"
                                          }
                                          imageLink={player.profileImage}
                                          length={courseDetails.length}
                                          name={player.name}
                                          playerID={player.playerID}
                                          tees={courseDetails.teeArray}
                                          active={
                                            player.tee ??
                                            courseDetails.teeArray[0]
                                          }
                                          setTee={(e) => {
                                            setFormatted((current) => {
                                              const newArr = current;
                                              for (
                                                let i = 0;
                                                i < newArr.length;
                                                i++
                                              ) {
                                                for (
                                                  let j = 0;
                                                  j < newArr[i].players.length;
                                                  j++
                                                ) {
                                                  if (
                                                    newArr[i].players[j]
                                                      .playerID ===
                                                    player.playerID
                                                  ) {
                                                    newArr[i].players[j].tee =
                                                      e;
                                                  }
                                                }
                                              }

                                              return newArr;
                                            });
                                          }}
                                        />
                                      );
                                      setShowSetup(true);
                                    }}
                                    handleRemove={() =>
                                      setFormatted((current) =>
                                        current.filter(
                                          (p) => p.playerID !== player.playerID
                                        )
                                      )
                                    }
                                    border
                                    uid={uid}
                                    playerID={player.playerID}
                                    key={player.playerID}
                                    handicap={player.handicap}
                                    imageLink={player.profileImage}
                                    name={player.name}
                                    tee={
                                      player.tee ?? courseDetails.teeArray[0]
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          </>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {/* <div className="cr-players"> */}
          {/* {players.map(player => <RoundPlayer 
                          uid={uid} playerID={player.playerID} key={player.playerID} handicap={player.handicap} imageLink={player.profileImage} name={player.name} tee={player.tee ?? courseDetails.teeArray[0]} 

              />)} */}
          {/* </div> */}

          {(event || live) && (
            <div className="flex-center mb-20">
              <button
                className="default-button"
                // disabled={removed.length > 0 && !open}
                onClick={() => {
                  if (live) {
                    saveLiveChanges();
                  } else {
                    saveChanges(formatted, cfa);
                  }
                }}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default GroupSelector;
