import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons
import Close from "@mui/icons-material/Close";
// Components
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";

function ChatSettings({ goBack, chatID, settings = {} }) {
  // State
  const [chatType, setChatType] = useState("group");

  // UE
  useEffect(() => {}, []);

  async function saveChanges() {
    db.collection("chat").doc(chatID).update({
      chatType,
    });
    goBack();
  }

  return (
    <div className="chat-settings">
      <div className="page-header">
        <h2>Chat Settings</h2>
        <Close onClick={goBack} className="icon-cursor" />
      </div>
      <div className="ph-msg">
        <p>Edit the settings for this group chat</p>
      </div>
      <div className="input-group pl-20">
        <p>Chat Type</p>
        <RadioGroupTwo
          buttons={[
            { text: "Group Chat", value: "group" },
            { text: "Broadcast", value: "admin" },
          ]}
          active={chatType}
          setActive={setChatType}
        />
      </div>
      <div className="default-button-row flex-center">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default ChatSettings;
