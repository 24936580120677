import React, { useState, useEffect } from "react";
import { db, admin } from "../../firebase";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
// Components
import BackRow from "../../components/display/Rows/BackRow";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PageHeader from "../../components/display/Rows/PageHeader";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { Tooltip, Dialog } from "@mui/material";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";

function MatchplayRobins({ tournamentID = "", goBack = () => {} }) {
  useEffect(() => {
    loadRounds();
  }, []);

  const [loading, setLoading] = useState(true);
  const [rounds, setRounds] = useState([]);
  const [error, setError] = useState("");

  const [res, setRes] = useState([]);

  const [matchEdit, setMatchEdit] = useState();
  const [showManual, setShowManual] = useState(false);

  const [active, setActive] = useState("res");

  const [searchInput, setSearchInput] = useState("");
  const [selWinner, setSelWinner] = useState();

  const [manMatch, setManMatch] = useState();

  const [manualBy, setManualBy] = useState(0);
  const [manualRem, setManualRem] = useState(0);
  const [update, setUpdate] = useState(0);

  const [doubles, setDoubles] = useState(false);

  async function loadRounds() {
    const roundDocs = await db
      .collection("rounds")
      .where("tournamentID", "==", tournamentID)
      //   .where("leaderboard.completed", "==", false)
      .orderBy("date", "desc")
      .get();

    const tournDoc = await db.collection("tournaments").doc(tournamentID).get();
    const tourn = tournDoc.data();
    setRes(tourn.results ?? []);
    setDoubles(tourn.playerType === "doubles");

    const arr = [];

    for (let i = 0; i < roundDocs.docs.length; i++) {
      const roundDoc = roundDocs.docs[i];
      const roundID = roundDoc.id;
      const round = roundDoc.data();

      const dateObject = new Date(round.date.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });

      round.roundID = roundID;
      round.dateObject = dateObject;
      round.dateString = dateString;
      arr.push(round);
    }

    setRounds(arr);
    setLoading(false);
  }

  async function manualFinish({ players = [], roundID = "" }) {
    setLoading(true);
    setShowManual(false);
    try {
      const tournDoc = await db
        .collection("tournaments")
        .doc(tournamentID)
        .get();
      const tourn = tournDoc.data();

      const { table, pointsType } = tourn;

      const playedMap = tourn.playedMap ?? {};

      if (doubles) {
        const t1ID = players[0].teamID;
        const t2ID = players[1].teamID;
        if (playedMap[t1ID]) {
          playedMap[t1ID].push(t2ID);
        } else {
          playedMap[t1ID] = [t2ID];
        }

        if (playedMap[t2ID]) {
          playedMap[t2ID].push(t1ID);
        } else {
          playedMap[t2ID] = [t1ID];
        }

        if (selWinner.name === "tie") {
          for (let x = 0; x < table.length; x++) {
            if (table[x].teamID === t1ID || table[x].teamID === t2ID) {
              table[x].played += 1;
              if (pointsType === "normal") {
                table[x].points += 10;
              }
              table[x].tied ? (table[x].tied += 1) : (table[x].tied = 1);
            }
          }
        } else {
          const loserID = selWinner.teamID === t1ID ? t2ID : t1ID;
          for (let i = 0; i < table.length; i++) {
            if (table[i].teamID === loserID) {
              table[i].played += 1;
            }
            if (table[i].teamID === selWinner.teamID) {
              if (pointsType === "normal") {
                table[i].points += 20;
                table[i].played += 1;
              } else {
                table[i].points += manualBy;
              }
              table[i].wins += 1;
            }
          }
        }

        const res = {
          date: new Date(),
          teamOne: players[0],
          teamTwo: players[1],
          by: manualBy,
          winner: selWinner,
          rem: manualRem,
          roundID,
        };
        // console.log(table, res, playedMap);
        // return setLoading(false);

        await tournDoc.ref.update({
          results: admin.firestore.FieldValue.arrayUnion(res),
          table,
          playedMap,
        });
      } else {
        const p1ID = players[0].playerID;
        const p2ID = players[1].playerID;

        if (playedMap[p1ID]) {
          playedMap[p1ID].push(p2ID);
        } else {
          playedMap[p1ID] = [p2ID];
        }

        if (playedMap[p2ID]) {
          playedMap[p2ID].push(p1ID);
        } else {
          playedMap[p2ID] = [p1ID];
        }

        if (selWinner.name === "tie") {
          for (let i = 0; i < players.length; i++) {
            const player = players[i];
            const playerID = player.playerID;
            for (let x = 0; x < table.length; x++) {
              if (table[x].playerID === playerID) {
                table[x].played += 1;
                table[x].tied ? (table[x].tied += 1) : (table[x].tied = 1);
                if (pointsType === "normal") {
                  table[x].points += 10;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < players.length; i++) {
            const player = players[i];
            const playerID = player.playerID;
            for (let x = 0; x < table.length; x++) {
              if (table[x].playerID === playerID) {
                if (selWinner.playerID === playerID) {
                  table[x].played += 1;
                  if (pointsType === "normal") {
                    table[x].points += 20;
                  } else {
                    table[x].points += manualBy;
                  }
                  table[x].wins += 1;
                } else {
                  table[x].played += 1;
                }
              }
            }
          }
        }

        const res = {
          date: new Date(),
          playerOne: players[0],
          playerTwo: players[1],
          by: manualBy,
          winner: selWinner,
          rem: manualRem,
          roundID,
        };

        console.log(table);
        // return setLoading(false);

        await tournDoc.ref.update({
          results: admin.firestore.FieldValue.arrayUnion(res),
          table,
          playedMap,
        });
      }

      if (roundID !== "") {
        await db.collection("users").doc(players[0].playerID).update({
          activeRoundID: "",
        });
        await db.collection("users").doc(players[1].playerID).update({
          activeRoundID: "",
        });
        const rd = await db.collection("rounds").doc(roundID).get();
        const rdd = rd.data();
        const { leaderboard } = rdd;
        leaderboard.completed = true;
        leaderboard.manual = true;
        leaderboard.by = manualBy;
        leaderboard.thru = 18 - manualRem;
        leaderboard.leader = selWinner.playerID;
        await rd.ref.update({
          leaderboard,
        });
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
    loadRounds();
  }

  return (
    <div className="matchplay-robins">
      <Dialog open={showManual} onClose={() => setShowManual(false)}>
        {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

        <div className="mpb-manual pd-40">
          <PageHeader
            close={() => setShowManual(false)}
            text="Manual Input"
            showClose
          />
          <div className="ph-msg mb-20">
            <p>Select the player who won this game </p>
          </div>
          {manMatch && !doubles && (
            <div className="mpb-match">
              <PlayerItem
                showBg={
                  selWinner &&
                  selWinner.playerID === manMatch.players[0].playerID
                }
                showProfile={() => setSelWinner(manMatch.players[0])}
                hideRemove
                hcp={manMatch.players[0].handicap}
                hideImage
                hideHCP
                showAdd
                hideMessage
                hideProfile
                noLink
                hideIcons
                name={manMatch.players[0].name}
              />
              <div className="mp-cir-box">
                <div className="mpbm-circle">
                  <p>vs</p>
                </div>
              </div>

              <PlayerItem
                showBg={
                  selWinner &&
                  selWinner.playerID === manMatch.players[1].playerID
                }
                showProfile={() => setSelWinner(manMatch.players[1])}
                hideRemove
                hcp={manMatch.players[1].handicap}
                hideImage
                hideIcons
                showAdd
                hideHCP
                hideMessage
                hideProfile
                noLink
                name={manMatch.players[1].name}
              />
            </div>
          )}
          {manMatch && doubles && (
            <div className="mpb-match">
              <PlayerItem
                showBg={
                  selWinner && selWinner.teamID === manMatch.players[0].teamID
                }
                showProfile={() => setSelWinner(manMatch.players[0])}
                hideRemove
                hcp={manMatch.players[0].handicap}
                hideImage
                hideHCP
                showAdd
                hideMessage
                hideProfile
                noLink
                hideIcons
                name={manMatch.players[0].name}
              />
              <div className="mp-cir-box">
                <div className="mpbm-circle">
                  <p>vs</p>
                </div>
              </div>

              <PlayerItem
                showBg={
                  selWinner && selWinner.teamID === manMatch.players[1].teamID
                }
                showProfile={() => setSelWinner(manMatch.players[1])}
                hideRemove
                hcp={manMatch.players[1].handicap}
                hideImage
                hideIcons
                showAdd
                hideHCP
                hideMessage
                hideProfile
                noLink
                name={manMatch.players[1].name}
              />
            </div>
          )}
          <div className="mini-pi">
            <PlayerItem
              name={"Tie"}
              showBg={selWinner && selWinner.name === "Tie"}
              hideIcons
              noLink
              hideHCP
              hideImage
              showProfile={() => {
                setSelWinner({
                  name: "Tie",
                  playerID: "tie",
                });
                setManualBy(0);
                setManualRem(0);
              }}
            />
          </div>
          <div className="mp-man-holes">
            <div className="input-group">
              <p>Holes Up</p>
              <input
                type="number"
                disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualBy(e.target.value)}
                value={manualBy}
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Holes Remaining</p>
              <input
                type="number"
                disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualRem(e.target.value)}
                value={manualRem}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center">
            <button
              disabled={selWinner === undefined || manualRem > manualBy}
              className="default-button"
              onClick={() =>
                manualFinish({
                  players: manMatch.players,
                  roundID: manMatch.roundID,
                })
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Tournament Rounds" />
      <RadioGroupTwo
        buttons={[
          { text: "Results", value: "res" },
          { text: "Rounds", value: "rounds" },
        ]}
        active={active}
        setActive={setActive}
      />
      <div className="input-group mb-20 pl-20">
        <p>Search</p>
        <input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          type="text"
          placeholder="Search for player..."
          className="default-input"
        />
      </div>

      <div className="mpr-rounds pd-20">
        {active === "res" &&
          !doubles &&
          res.map((round, index) => {
            const p1 = round.playerOne;
            const p2 = round.playerTwo;
            const match = round;

            let showEdit = false;

            if (searchInput !== "") {
              const sl = searchInput.toLowerCase();
              const n1 = p1.name.toLowerCase();
              const n2 = p2.name.toLowerCase();

              if (!n1.includes(sl) && !n2.includes(sl)) {
                return null;
              }
            }

            return (
              <div key={index} className="mpr-match mb-20">
                <div className="mprm-details mb-10">
                  <h3>{round.dateString}</h3>
                </div>
                <div className="mpb-match">
                  <PlayerItem
                    showBg={match.winner.playerID === p1.playerID}
                    hideRemove
                    hideEdit={true}
                    hcp={p1.handicap}
                    hideImage
                    showAdd
                    hideHCP={isNaN(p1.handicap)}
                    hideMessage
                    hideProfile
                    noLink
                    editAction={() => {
                      setMatchEdit(match.match);
                    }}
                    name={p1.name}
                  />
                  <div className="mp-cir-box">
                    {round.roundID && !match.completed && (
                      <RssFeedIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    {round.roundID && match.completed && (
                      <GolfCourseIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    <div className="mpbm-circle">
                      {match.leader === "" ? (
                        <p>vs</p>
                      ) : match.by === "BYE" ? (
                        <p>BYE</p>
                      ) : (
                        <>
                          {match.leader === p1.playerID ? (
                            <p>
                              {match.rem === 0
                                ? `${match.by} UP`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          ) : (
                            <p>
                              {match.rem === 0
                                ? `${match.by} DN`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <PlayerItem
                    showBg={match.winner.playerID === p2.playerID}
                    hideRemove
                    hcp={p2.handicap}
                    hideImage
                    hideEdit={true}
                    hideHCP={isNaN(p2.handicap)}
                    showAdd
                    hideMessage
                    hideProfile
                    noLink
                    name={p2.name}
                    editAction={() => {}}
                  />
                </div>
              </div>
            );
          })}
        {active === "res" &&
          doubles &&
          res.map((round, index) => {
            const p1 = round.teamOne;
            const p2 = round.teamTwo;
            const match = round;

            let showEdit = false;

            if (searchInput !== "") {
              const sl = searchInput.toLowerCase();
              const n1 = p1.name.toLowerCase();
              const n2 = p2.name.toLowerCase();

              if (!n1.includes(sl) && !n2.includes(sl)) {
                return null;
              }
            }

            return (
              <div key={index} className="mpr-match mb-20">
                <div className="mprm-details mb-10">
                  <h3>{round.dateString}</h3>
                </div>
                <div className="mpb-match">
                  <PlayerItem
                    showBg={
                      (doubles && match.winner.teamID === p1.teamID) ||
                      (!doubles && match.winner.playerID === p1.playerID)
                    }
                    hideRemove
                    hideEdit={true}
                    hcp={p1.handicap}
                    hideImage
                    showAdd
                    hideHCP={isNaN(p1.handicap) || doubles}
                    hideMessage
                    hideProfile
                    noLink
                    editAction={() => {
                      setMatchEdit(match.match);
                    }}
                    name={p1.name}
                  />
                  <div className="mp-cir-box">
                    {round.roundID && !match.completed && (
                      <RssFeedIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    {round.roundID && match.completed && (
                      <GolfCourseIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    <div className="mpbm-circle">
                      {match.leader === "" ? (
                        <p>vs</p>
                      ) : match.by === "BYE" ? (
                        <p>BYE</p>
                      ) : (
                        <>
                          {match.leader === p1.playerID ? (
                            <p>
                              {match.rem === 0
                                ? `${match.by} UP`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          ) : (
                            <p>
                              {match.rem === 0
                                ? `${match.by} DN`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <PlayerItem
                    showBg={
                      (doubles && match.winner.teamID === p2.teamID) ||
                      (!doubles && match.winner.playerID === p2.playerID)
                    }
                    hideRemove
                    hcp={p2.handicap}
                    hideImage
                    hideEdit={true}
                    hideHCP={isNaN(p2.handicap) || doubles}
                    showAdd
                    hideMessage
                    hideProfile
                    noLink
                    name={p2.name}
                    editAction={() => {}}
                  />
                </div>
              </div>
            );
          })}
        {active === "rounds" &&
          rounds.map((round, index) => {
            const { groups } = round;
            const p1 = groups[0];
            const p2 = groups[1];
            const match = round.leaderboard;
            let showEdit = !match.completed;
            console.log(match);
            let rem = 18 - match.thru;

            const completed = match.score > 18 - match.thru;
            if (completed) {
              showEdit = false;
              rem = 0;
            }
            // if (match.completed) {
            // }

            if (searchInput !== "") {
              const sl = searchInput.toLowerCase();
              const n1 = p1.name.toLowerCase();
              const n2 = p2.name.toLowerCase();

              if (!n1.includes(sl) && !n2.includes(sl)) {
                return null;
              }
            }

            return (
              <div key={round.roundID} className="mpr-match mb-20">
                <div className="mprm-details mb-10">
                  <h3>{round.dateString}</h3>
                </div>
                <div className="mpb-match">
                  <PlayerItem
                    showBg={match.leader === p1.playerID}
                    hideRemove
                    hideEdit={
                      index > 0 || match.winnerID !== "" || match.started
                    }
                    hcp={p1.handicap}
                    hideImage
                    showAdd
                    hideHCP={isNaN(p1.handicap)}
                    hideMessage
                    hideProfile
                    noLink
                    editAction={() => {
                      setMatchEdit(match.match);
                    }}
                    name={p1.name}
                  />
                  <div className="mp-cir-box">
                    {round.roundID && !match.completed && (
                      <RssFeedIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    {round.roundID && match.completed && (
                      <GolfCourseIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    <div className="mpbm-circle">
                      {match.leader === "" ? (
                        <p>vs</p>
                      ) : match.by === "BYE" ? (
                        <p>BYE</p>
                      ) : (
                        <>
                          {match.leader === p1.playerID ? (
                            <p>
                              {rem !== 0
                                ? `${match.score} UP`
                                : `${match.score} & ${18 - match.thru}`}
                            </p>
                          ) : (
                            <p>
                              {rem !== 0
                                ? `${match.score} DN`
                                : `${match.score} & ${18 - match.thru}`}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    {showEdit && (
                      <EditIcon
                        onClick={() => {
                          setManMatch({
                            players: groups,
                            roundID: round.roundID,
                          });
                          setShowManual(true);
                        }}
                        className="icon-cursor dg-icon-bg mt-10"
                      />
                    )}
                  </div>

                  <PlayerItem
                    showBg={match.leader === p2.playerID}
                    hideRemove
                    hcp={p2.handicap}
                    hideImage
                    hideEdit={
                      index > 0 || match.winnerID !== "" || match.started
                    }
                    hideHCP={isNaN(p2.handicap)}
                    showAdd
                    hideMessage
                    hideProfile
                    noLink
                    name={p2.name}
                    editAction={() => {}}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MatchplayRobins;
