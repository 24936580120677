import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import ErrorModal from "../../ErrorModal/ErrorModal";
import PageHeader from "../../display/Rows/PageHeader";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import { Dialog } from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

function removeFirstName(name) {
  var names = name.split(" ");

  if (names.length > 1) {
    return names.slice(1).join(" ");
  }

  return name;
}

function calcTeamStablefordScore({
  holes = [],
  teamType = "",
  playerIDs = [],
  format = "",
}) {
  let totalPoints = 0;
  if (format === "stableford") {
    if (teamType === "two") {
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        const { lowest, secondLowest } = hole;
        if (lowest !== "") {
          const lowestPoints = 2 - lowest;
          totalPoints += lowestPoints;
        }
        if (secondLowest !== "") {
          const secondLowestPoints = 2 - secondLowest;

          totalPoints += secondLowestPoints;
        }
      }
    }
    if (teamType === "scramble") {
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        const { best } = hole;
        if (best !== "") {
          const lowestPoints = 2 - best;
          totalPoints += lowestPoints;
        }
      }
    }

    if (teamType === "custom") {
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        const { score, toCount, scores } = hole;
        let counter = toCount;
        if (counter > scores.length) {
          counter = scores.length;
        }
        for (let g = 0; g < counter; g++) {
          const score = scores[g];
          if (score !== "") {
            const points = 2 - score;
            totalPoints += points;
          }
        }
      }
    }

    if (teamType === "combined") {
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        let holePoints = 0;

        for (let g = 0; g < playerIDs.length; g++) {
          const scr = hole[playerIDs[g]];
          if (scr !== "") {
            holePoints += 2 - scr;
          }
        }
        totalPoints += holePoints;
      }
    }
  }
  if (format === "mod-stableford") {
    if (teamType === "two") {
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        const { lowest, secondLowest } = hole;
        if (lowest !== "") {
          const lowestPoints = 2 - lowest;
          let scorePoints = 0;
          switch (lowest) {
            case lowest > 2:
              scorePoints = -5;
              break;
            case 2:
              scorePoints = -3;
              break;
            case 1:
              scorePoints = -1;
              break;
            case 0:
              break;
            case -1:
              scorePoints = 2;
              break;
            case -2:
              scorePoints = 5;
              break;
            case lowest < -2:
              scorePoints = 8;
              break;
            default:
              break;
          }
          totalPoints += scorePoints;
        }
        if (secondLowest !== "") {
          const secondLowestPoints = 2 - secondLowest;
          let scorePoints = 0;
          switch (secondLowest) {
            case secondLowest > 2:
              scorePoints = -5;
              break;
            case 2:
              scorePoints = -3;
              break;
            case 1:
              scorePoints = -1;
              break;
            case 0:
              break;
            case -1:
              scorePoints = 2;
              break;
            case -2:
              scorePoints = 5;
              break;
            case secondLowest < -2:
              scorePoints = 8;
              break;
            default:
              break;
          }
          totalPoints += scorePoints;
        }
      }
    }
    if (teamType === "scramble") {
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        const { best } = hole;
        if (best !== "") {
          const lowestPoints = 2 - best;
          let scorePoints = 0;
          switch (best) {
            case best > 2:
              scorePoints = -5;
              break;
            case 2:
              scorePoints = -3;
              break;
            case 1:
              scorePoints = -1;
              break;
            case 0:
              break;
            case -1:
              scorePoints = 2;
              break;
            case -2:
              scorePoints = 5;
              break;
            case best < -2:
              scorePoints = 8;
              break;
            default:
              break;
          }
          totalPoints += scorePoints;
        }
      }
    }
    if (teamType === "custom") {
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        const { score } = hole;
        if (score !== "") {
          let scorePoints = 0;
          switch (score) {
            case score > 2:
              scorePoints = -5;
              break;
            case 2:
              scorePoints = -3;
              break;
            case 1:
              scorePoints = -1;
              break;
            case 0:
              break;
            case -1:
              scorePoints = 2;
              break;
            case -2:
              scorePoints = 5;
              break;
            case score < -2:
              scorePoints = 8;
              break;
            default:
              break;
          }
          totalPoints += scorePoints;
        }
      }
    }
  }
  return totalPoints;
}

function Countout({ roundID = "", goBack = () => {} }) {
  const optis = [
    {
      label: "Front 9",
      value: "frontNine",
    },
    {
      label: "Back 9",
      value: "backNine",
    },
    {
      label: "Handicap",
      value: "handicap",
    },
    {
      label: "Even Holes",
      value: "evs",
    },
    {
      label: "Odd Holes",
      value: "odds",
    },
    {
      label: "Last 6 Holes",
      value: "lastSix",
    },
    {
      label: "Last 3 Holes",
      value: "lastThree",
    },
    {
      label: "Last Hole",
      value: "lastHole",
    },
    {
      label: "Stroke 1",
      value: "strokeOne",
    },
    {
      label: "Stroke 2",
      value: "strokeTwo",
    },
    {
      label: "Stroke 3",
      value: "strokeThree",
    },
  ];

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [format, setFormat] = useState("");

  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((current) => current + 1);
  }

  const [divisions, setDivisions] = useState([]);
  const [selDiv, setSelDiv] = useState("overall");

  const [tiedPlayers, setTiedPlayers] = useState([]);

  const [tiedMap, setTiedMap] = useState({});

  const [sel, setSel] = useState();

  const [countouts, setCountOuts] = useState([]);
  const [options, setOptions] = useState(optis);
  const [formatMap, setFormatMap] = useState({});

  const [showDia, setShowDia] = useState(false);

  const [sorter, setSorter] = useState("frontNine");

  useEffect(() => {
    loadAll();
  }, []);

  async function loadAll() {
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();

    const {
      format,
      leaderboard,
      enableTeams,
      enableDivisions,
      courseID,
      teamType,
    } = round;

    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const { tees, teeArray } = course;
    const card = tees[teeArray[0]];

    // console.log(teamType);

    const divisions = round.divisions ?? [];

    const divBoards = [];

    const divOpts = [
      {
        label: "Overall",
        value: "overall",
      },
    ];

    const lbMap = {};

    const score = leaderboard[0].score;

    const arr = [];
    // const arrr = [];

    for (let i = 0; i < leaderboard.length; i++) {
      if (i < 10) {
        arr.push(leaderboard[i]);
      }
      // if (leaderboard[i].score === score) {
      //   arr.push(leaderboard[i]);
      // } else {
      //   break;
      // }
    }

    const ids = arr.map((player) => player.playerID);

    const refs = ids.map((id) =>
      roundDoc.ref.collection("scores").doc(id).get()
    );

    const docs = await Promise.all(refs);

    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i];
      const data = doc.data();
      const { holes } = data;

      // Handicap
      arr[i].handicap = data.handicap;

      // Front 9
      arr[i].frontNine =
        format === "stableford" ? data.frontNinePoints : data.frontNineNet;

      // Back 9
      arr[i].backNine =
        format === "stableford" ? data.backNinePoints : data.backNineNet;

      // Evs & Odds
      let evs = 0;
      let odds = 0;
      let par3s = 0;
      let par4s = 0;
      let par5s = 0;
      for (let i = 0; i < holes.length; i++) {
        const hole = holes[i];
        if ((i + 1) % 2 === 0) {
          if (format === "stableford") {
            evs += hole.points;
          } else if (format === "medal") {
            evs += hole.grossScore;
          } else {
            evs += hole.netScore;
          }
        } else {
          if (format === "stableford") {
            odds += hole.points;
          } else if (format === "medal") {
            odds += hole.grossScore;
          } else {
            odds += hole.netScore;
          }
        }

        if (hole.par === 3) {
          if (format === "stableford") {
            par3s += hole.points;
          } else if (format === "medal") {
            par3s += hole.grossScore;
          } else {
            par3s += hole.netScore;
          }
        }
        if (hole.par === 4) {
          if (format === "stableford") {
            par4s += hole.points;
          } else if (format === "medal") {
            par4s += hole.grossScore;
          } else {
            par4s += hole.netScore;
          }
        }
        if (hole.par === 5) {
          if (format === "stableford") {
            par5s += hole.points;
          } else if (format === "medal") {
            par5s += hole.grossScore;
          } else {
            par5s += hole.netScore;
          }
        }
      }

      arr[i].odds = odds;
      arr[i].evs = evs;
      arr[i].par3s = par3s;
      arr[i].par4s = par4s;
      arr[i].par5s = par5s;

      // Last 6
      let lastSix = 0;
      for (let x = 12; x < holes.length; x++) {
        const hole = holes[x];
        if (format === "stableford") {
          lastSix += hole.points;
        } else if (format === "medal") {
          lastSix += hole.grossScore;
        } else {
          lastSix += hole.netScore;
        }
      }
      arr[i].lastSix = lastSix;

      // Last 3
      let lastThree = 0;
      for (let x = 15; x < holes.length; x++) {
        const hole = holes[x];
        if (format === "stableford") {
          lastThree += hole.netScore;
        } else if (format === "medal") {
          lastThree += hole.grossScore;
        } else {
          lastThree += hole.points;
        }
      }
      arr[i].lastThree = lastThree;
      // Last 1
      if (format === "stableford") {
        arr[i].lastHole = holes[17].points;
      } else if (format === "medal") {
        arr[i].lastHole = holes[17].grossScore;
      } else {
        arr[i].lastHole = holes[17].netScore;
      }

      // Strokes
      for (let x = 0; x < holes.length; x++) {
        const hole = holes[x];
        const { stroke } = hole;
        if (format === "stableford") {
          if (stroke === 1) {
            arr[i].strokeOne = hole.points;
          }
          if (stroke === 2) {
            arr[i].strokeTwo = hole.points;
          }
          if (stroke === 3) {
            arr[i].strokeThree = hole.points;
          }
        } else if (format === "medal") {
          if (stroke === 1) {
            arr[i].strokeOne = hole.grossScore;
          }
          if (stroke === 2) {
            arr[i].strokeTwo = hole.grossScore;
          }
          if (stroke === 3) {
            arr[i].strokeThree = hole.grossScore;
          }
        } else {
          if (stroke === 1) {
            arr[i].strokeOne = hole.netScore;
          }
          if (stroke === 2) {
            arr[i].strokeTwo = hole.netScore;
          }
          if (stroke === 3) {
            arr[i].strokeThree = hole.netScore;
          }
        }
      }
      arr[i].scores = data;
    }

    lbMap["overall"] = arr;

    if (enableTeams) {
      const { teamLeaderboard } = round;
      const winningTeamScore = teamLeaderboard[0].score;
      // const filtTeam = teamLeaderboard.filter(
      //   (team) => team.score === winningTeamScore
      // );

      if (format === "stableford") {
        for (let i = 0; i < teamLeaderboard.length; i++) {
          const team = teamLeaderboard[i];
          const { holes, playerIDs } = team;
          const ss = calcTeamStablefordScore({
            holes,
            teamType,
            format,
            playerIDs,
          });
          teamLeaderboard[i].score = ss;
        }
        teamLeaderboard.sort((a, b) => b.score - a.score);
        // Rank and track
        let currentTies = 0;
        for (let i = 0; i < teamLeaderboard.length; i++) {
          let ranking = i + 1;

          const row = teamLeaderboard[i];
          if (i > 0) {
            if (teamLeaderboard[i - 1].score === row.score) {
              ranking = i - currentTies;
              currentTies += 1;
            } else {
              currentTies = 0;
            }
          }

          teamLeaderboard[i].rank = ranking;
        }
      }

      const filtTeam = teamLeaderboard;
      if (filtTeam.length > 10) {
        filtTeam.length = 10;
      }

      if (filtTeam.length > 1) {
        divOpts.push({
          label: "Teams",
          value: "teams",
        });

        for (let i = 0; i < filtTeam.length; i++) {
          const team = filtTeam[i];
          const { holes } = team;

          let name = "";
          for (let j = 0; j < team.playerIDs.length; j++) {
            for (let x = 0; x < leaderboard.length; x++) {
              if (leaderboard[x].playerID === team.playerIDs[j]) {
                if (leaderboard[x].playerID.includes("guest")) {
                  name += `${removeFirstName(leaderboard[x].name)} / `;
                } else {
                  name += `${leaderboard[x].lastName} / `;
                }
              }
            }
          }
          name = name.slice(0, -3);
          filtTeam[i].name = name;

          // Front 9
          let frontNine = 0;
          for (let x = 0; x < 9; x++) {
            const courseHole = card[x];
            if (i === 0) {
              // console.log(holes[x]);
            }

            if (teamType === "scramble") {
              const holeScore = holes[x].best;
              if (holeScore === "") {
                continue;
              }
              if (format === "stableford") {
                frontNine += 2 - holeScore;
              } else {
                frontNine += courseHole.par + holeScore;
              }
            }

            if (teamType === "custom") {
              const hole = holes[x];
              const { scores } = hole;
              let { toCount } = hole;
              if (scores.length < toCount) {
                toCount = scores.length;
              }
              if (format === "stableford") {
                let holePts = 0;
                for (let x = 0; x < toCount; x++) {
                  holePts += 2 - scores[x];
                }
                frontNine += holePts;
              } else {
                let holeStrokes = 0;
                for (let x = 0; x < toCount; x++) {
                  holeStrokes += courseHole.par - scores[x];
                }
                frontNine += holeStrokes;
              }
            }

            if (teamType === "two") {
              const hole = holes[x];
              const { lowest, secondLowest } = hole;
              if (format === "stableford") {
                if (lowest !== "") {
                  frontNine += 2 - lowest;
                }
                if (secondLowest !== "") {
                  frontNine += 2 - secondLowest;
                }
              } else {
                if (lowest !== "") {
                  frontNine += courseHole.par - lowest;
                }
                if (secondLowest !== "") {
                  frontNine += courseHole.par - secondLowest;
                }
              }
            }
          }
          filtTeam[i].frontNine = frontNine;

          // Back 9
          let backNine = 0;
          for (let x = 9; x < 18; x++) {
            const courseHole = card[x];
            if (teamType === "scramble") {
              const holeScore = holes[x].best;
              if (holeScore === "") {
                continue;
              }
              if (format === "stableford") {
                backNine += 2 - holeScore;
              } else {
                backNine += courseHole.par + holeScore;
              }
            }
            if (teamType === "custom") {
              const hole = holes[x];
              const { scores } = hole;
              let { toCount } = hole;
              if (scores.length < toCount) {
                toCount = scores.length;
              }
              if (format === "stableford") {
                let holePts = 0;
                for (let x = 0; x < toCount; x++) {
                  holePts += 2 - scores[x];
                }
                backNine += holePts;
              } else {
                let holeStrokes = 0;
                for (let x = 0; x < toCount; x++) {
                  holeStrokes += courseHole.par - scores[x];
                }
                backNine += holeStrokes;
              }
            }

            if (teamType === "two") {
              const hole = holes[x];
              const { lowest, secondLowest } = hole;
              if (format === "stableford") {
                if (lowest !== "") {
                  backNine += 2 - lowest;
                }
                if (secondLowest !== "") {
                  backNine += 2 - secondLowest;
                }
              } else {
                if (lowest !== "") {
                  backNine += courseHole.par - lowest;
                }
                if (secondLowest !== "") {
                  backNine += courseHole.par - secondLowest;
                }
              }
            }
          }
          filtTeam[i].backNine = backNine;

          // Odds & Evs
          let odds = 0;
          let evs = 0;

          for (let x = 0; x < holes.length; x++) {
            const courseHole = card[x];
            let holeScr = 0;
            if (teamType === "scramble") {
              const holeScore = holes[x].best;
              if (holeScore === "") {
                continue;
              }
              if (format === "stableford") {
                holeScr = 2 - holeScore;
              } else {
                holeScr = courseHole.par + holeScore;
              }
            }

            if (teamType === "custom") {
              const hole = holes[x];
              const { scores } = hole;
              let { toCount } = hole;
              if (scores.length < toCount) {
                toCount = scores.length;
              }
              if (format === "stableford") {
                let holePts = 0;
                for (let x = 0; x < toCount; x++) {
                  holePts += 2 - scores[x];
                }
                holeScr = holePts;
              } else {
                let holeStrokes = 0;
                for (let x = 0; x < toCount; x++) {
                  holeStrokes += courseHole.par - scores[x];
                }
                holeScr = holeStrokes;
              }
            }

            if (teamType === "two") {
              const hole = holes[x];
              const { lowest, secondLowest } = hole;
              if (format === "stableford") {
                if (lowest !== "") {
                  holeScr += 2 - lowest;
                }
                if (secondLowest !== "") {
                  holeScr += 2 - secondLowest;
                }
              } else {
                if (lowest !== "") {
                  holeScr += courseHole.par - lowest;
                }
                if (secondLowest !== "") {
                  holeScr += courseHole.par - secondLowest;
                }
              }
            }

            if ((x + 1) % 2 === 0) {
              evs += holeScr;
            } else {
              odds += holeScr;
            }
          }

          filtTeam[i].odds = odds;
          filtTeam[i].evs = evs;

          // Last 6
          let lastSix = 0;
          for (let x = 12; x < holes.length; x++) {
            const courseHole = card[x];
            if (teamType === "scramble") {
              const holeScore = holes[x].best;
              if (holeScore === "") {
                continue;
              }
              if (format === "stableford") {
                lastSix += 2 - holeScore;
              } else {
                lastSix += courseHole.par + holeScore;
              }
            }

            if (teamType === "custom") {
              const hole = holes[x];
              const { scores } = hole;
              let { toCount } = hole;
              if (scores.length < toCount) {
                toCount = scores.length;
              }
              if (format === "stableford") {
                let holePts = 0;
                for (let x = 0; x < toCount; x++) {
                  holePts += 2 - scores[x];
                }
                lastSix += holePts;
              } else {
                let holeStrokes = 0;
                for (let x = 0; x < toCount; x++) {
                  holeStrokes += courseHole.par - scores[x];
                }
                lastSix += holeStrokes;
              }
            }

            if (teamType === "two") {
              const hole = holes[x];
              const { lowest, secondLowest } = hole;
              if (format === "stableford") {
                if (lowest !== "") {
                  lastSix += 2 - lowest;
                }
                if (secondLowest !== "") {
                  lastSix += 2 - secondLowest;
                }
              } else {
                if (lowest !== "") {
                  lastSix += courseHole.par - lowest;
                }
                if (secondLowest !== "") {
                  lastSix += courseHole.par - secondLowest;
                }
              }
            }
          }
          filtTeam[i].lastSix = lastSix;

          // Last 3
          let lastThree = 0;
          for (let x = 15; x < holes.length; x++) {
            const courseHole = card[x];
            if (teamType === "scramble") {
              const holeScore = holes[x].best;
              if (holeScore === "") {
                continue;
              }
              if (format === "stableford") {
                lastThree += 2 - holeScore;
              } else {
                lastThree += courseHole.par + holeScore;
              }
            }

            if (teamType === "custom") {
              const hole = holes[x];
              const { scores } = hole;
              let { toCount } = hole;
              if (scores.length < toCount) {
                toCount = scores.length;
              }
              if (format === "stableford") {
                let holePts = 0;
                for (let x = 0; x < toCount; x++) {
                  holePts += 2 - scores[x];
                }
                lastThree += holePts;
              } else {
                let holeStrokes = 0;
                for (let x = 0; x < toCount; x++) {
                  holeStrokes += courseHole.par - scores[x];
                }
                lastThree += holeStrokes;
              }
            }

            if (teamType === "two") {
              const hole = holes[x];
              const { lowest, secondLowest } = hole;
              if (format === "stableford") {
                if (lowest !== "") {
                  lastThree += 2 - lowest;
                }
                if (secondLowest !== "") {
                  lastThree += 2 - secondLowest;
                }
              } else {
                if (lowest !== "") {
                  lastThree += courseHole.par - lowest;
                }
                if (secondLowest !== "") {
                  lastThree += courseHole.par - secondLowest;
                }
              }
            }
          }
          filtTeam[i].lastThree = lastThree;

          // Last 1
          const courseLast = card[17].par;
          if (teamType === "scramble") {
            const holeScore = holes[17].best;
            if (holeScore !== "") {
              if (format === "stableford") {
                filtTeam[i].lastHole = 2 - holeScore;
              } else {
                filtTeam[i].lastHole = courseLast + holeScore;
              }
            }
          }

          if (teamType === "custom") {
            const hole = holes[17];
            const { scores } = hole;
            let { toCount } = hole;
            if (scores.length < toCount) {
              toCount = scores.length;
            }
            if (format === "stableford") {
              let holePts = 0;
              for (let x = 0; x < toCount; x++) {
                holePts += 2 - scores[x];
              }
              filtTeam[i].lastHole = holePts;
            } else {
              let holeStrokes = 0;
              for (let x = 0; x < toCount; x++) {
                holeStrokes += card[17].par - scores[x];
              }
              filtTeam[i].lastHole = holeStrokes;
            }
          }

          if (teamType === "two") {
            const hole = holes[17];
            const { lowest, secondLowest } = hole;
            if (format === "stableford") {
              let last = 0;
              if (lowest !== "") {
                last += 2 - lowest;
              }
              if (secondLowest !== "") {
                last += 2 - secondLowest;
              }
              filtTeam[i].lastHole = last;
            } else {
              let last = 0;
              if (lowest !== "") {
                last += courseLast - lowest;
              }
              if (secondLowest !== "") {
                last += courseLast - secondLowest;
              }
              filtTeam[i].lastHole = last;
            }
          }

          // Strokes
          for (let x = 0; x < card.length; x++) {
            const courseHole = card[x];
            const { stroke, par } = courseHole;
            if (stroke === 1) {
              if (teamType === "scramble") {
                const holeScore = holes[x].best;
                if (holeScore !== "") {
                  if (format === "stableford") {
                    filtTeam[i].strokeOne = 2 - holeScore;
                  } else {
                    filtTeam[i].strokeOne += courseLast + holeScore;
                  }
                }
              }
              if (teamType === "custom") {
                const hole = holes[x];
                const { scores } = hole;
                let { toCount } = hole;
                if (scores.length < toCount) {
                  toCount = scores.length;
                }
                if (format === "stableford") {
                  let holePts = 0;
                  for (let x = 0; x < toCount; x++) {
                    holePts += 2 - scores[x];
                  }
                  filtTeam[i].strokeOne = holePts;
                } else {
                  let holeStrokes = 0;
                  for (let x = 0; x < toCount; x++) {
                    holeStrokes += courseLast - scores[x];
                  }
                  filtTeam[i].strokeOne = holeStrokes;
                }
              }
              if (teamType === "two") {
                const hole = holes[x];
                const { lowest, secondLowest } = hole;
                let tot = 0;
                if (format === "stableford") {
                  if (lowest !== "") {
                    tot += 2 - lowest;
                  }
                  if (secondLowest !== "") {
                    tot += 2 - secondLowest;
                  }
                } else {
                  if (lowest !== "") {
                    tot += courseHole.par - lowest;
                  }
                  if (secondLowest !== "") {
                    tot += courseHole.par - secondLowest;
                  }
                }
                filtTeam[i].strokeOne = tot;
              }
            }
            if (stroke === 2) {
              if (teamType === "scramble") {
                const holeScore = holes[x].best;
                if (holeScore !== "") {
                  if (format === "stableford") {
                    filtTeam[i].strokeTwo = 2 - holeScore;
                  } else {
                    filtTeam[i].strokeTwo += courseLast + holeScore;
                  }
                }
              }
              if (teamType === "custom") {
                const hole = holes[x];
                const { scores } = hole;
                let { toCount } = hole;
                if (scores.length < toCount) {
                  toCount = scores.length;
                }
                if (format === "stableford") {
                  let holePts = 0;
                  for (let x = 0; x < toCount; x++) {
                    holePts += 2 - scores[x];
                  }
                  filtTeam[i].strokeTwo = holePts;
                } else {
                  let holeStrokes = 0;
                  for (let x = 0; x < toCount; x++) {
                    holeStrokes += courseLast - scores[x];
                  }
                  filtTeam[i].strokeTwo = holeStrokes;
                }
              }
              if (teamType === "two") {
                const hole = holes[x];
                const { lowest, secondLowest } = hole;
                let tot = 0;
                if (format === "stableford") {
                  if (lowest !== "") {
                    tot += 2 - lowest;
                  }
                  if (secondLowest !== "") {
                    tot += 2 - secondLowest;
                  }
                } else {
                  if (lowest !== "") {
                    tot += courseHole.par - lowest;
                  }
                  if (secondLowest !== "") {
                    tot += courseHole.par - secondLowest;
                  }
                }
                filtTeam[i].strokeTwo = tot;
              }
            }
            if (stroke === 3) {
              if (teamType === "scramble") {
                const holeScore = holes[x].best;
                if (holeScore !== "") {
                  if (format === "stableford") {
                    filtTeam[i].strokeThree = 2 - holeScore;
                  } else {
                    filtTeam[i].strokeThree += courseLast + holeScore;
                  }
                }
              }
              if (teamType === "custom") {
                const hole = holes[x];
                const { scores } = hole;
                let { toCount } = hole;
                if (scores.length < toCount) {
                  toCount = scores.length;
                }
                if (format === "stableford") {
                  let holePts = 0;
                  for (let x = 0; x < toCount; x++) {
                    holePts += 2 - scores[x];
                  }
                  filtTeam[i].strokeThree = holePts;
                } else {
                  let holeStrokes = 0;
                  for (let x = 0; x < toCount; x++) {
                    holeStrokes += courseLast - scores[x];
                  }
                  filtTeam[i].strokeThree = holeStrokes;
                }
              }
              if (teamType === "two") {
                const hole = holes[x];
                const { lowest, secondLowest } = hole;
                let tot = 0;
                if (format === "stableford") {
                  if (lowest !== "") {
                    tot += 2 - lowest;
                  }
                  if (secondLowest !== "") {
                    tot += 2 - secondLowest;
                  }
                } else {
                  if (lowest !== "") {
                    tot += courseHole.par - lowest;
                  }
                  if (secondLowest !== "") {
                    tot += courseHole.par - secondLowest;
                  }
                }
                filtTeam[i].strokeThree = tot;
              }
            }
          }
        }
      }
      lbMap["teams"] = filtTeam;
    }

    if (enableDivisions) {
      const mep = {};
      for (let i = 0; i < divisions.length; i++) {
        const div = divisions[i];
        console.log(div);
        if (div.players.length === 0) {
          continue;
        }
        if (div.playerType === "teams") {
          divOpts.push({
            label: div.divisionName,
            value: div.leaderboardID,
          });
          const filtTeam = round[div.leaderboardID].leaderboard;
          if (filtTeam.length > 10) {
            filtTeam.length = 10;
          }
          for (let i = 0; i < filtTeam.length; i++) {
            const team = filtTeam[i];
            const { holes } = team;

            let name = "";
            for (let j = 0; j < team.playerIDs.length; j++) {
              for (let x = 0; x < leaderboard.length; x++) {
                if (leaderboard[x].playerID === team.playerIDs[j]) {
                  if (leaderboard[x].playerID.includes("guest")) {
                    name += `${removeFirstName(leaderboard[x].name)} / `;
                  } else {
                    name += `${leaderboard[x].lastName} / `;
                  }
                }
              }
            }
            name = name.slice(0, -3);
            filtTeam[i].name = name;

            // Front 9
            let frontNine = 0;
            for (let x = 0; x < 9; x++) {
              const courseHole = card[x];
              if (i === 0) {
                // console.log(holes[x], teamType);
              }

              if (teamType === "scramble") {
                const holeScore = holes[x].best;
                if (holeScore === "") {
                  continue;
                }
                if (format === "stableford") {
                  frontNine += 2 - holeScore;
                } else {
                  frontNine += courseHole.par + holeScore;
                }
              }

              if (teamType === "custom") {
                const hole = holes[x];
                const { scores } = hole;
                let { toCount } = hole;
                if (scores.length < toCount) {
                  toCount = scores.length;
                }
                if (format === "stableford") {
                  let holePts = 0;
                  for (let x = 0; x < toCount; x++) {
                    holePts += 2 - scores[x];
                  }
                  frontNine += holePts;
                } else {
                  let holeStrokes = 0;
                  for (let x = 0; x < toCount; x++) {
                    holeStrokes += courseHole.par - scores[x];
                  }
                  frontNine += holeStrokes;
                }
              }

              if (teamType === "two") {
                const hole = holes[x];
                const { lowest, secondLowest } = hole;
                if (format === "stableford") {
                  if (lowest !== "") {
                    frontNine += 2 - lowest;
                  }
                  if (secondLowest !== "") {
                    frontNine += 2 - secondLowest;
                  }
                } else {
                  if (lowest !== "") {
                    frontNine += courseHole.par - lowest;
                  }
                  if (secondLowest !== "") {
                    frontNine += courseHole.par - secondLowest;
                  }
                }
              }
            }
            filtTeam[i].frontNine = frontNine;

            // Back 9
            let backNine = 0;
            for (let x = 9; x < 18; x++) {
              const courseHole = card[x];
              if (teamType === "scramble") {
                const holeScore = holes[x].best;
                if (holeScore === "") {
                  continue;
                }
                if (format === "stableford") {
                  backNine += 2 - holeScore;
                } else {
                  backNine += courseHole.par + holeScore;
                }
              }
              if (teamType === "custom") {
                const hole = holes[x];
                const { scores } = hole;
                let { toCount } = hole;
                if (scores.length < toCount) {
                  toCount = scores.length;
                }
                if (format === "stableford") {
                  let holePts = 0;
                  for (let x = 0; x < toCount; x++) {
                    holePts += 2 - scores[x];
                  }
                  backNine += holePts;
                } else {
                  let holeStrokes = 0;
                  for (let x = 0; x < toCount; x++) {
                    holeStrokes += courseHole.par - scores[x];
                  }
                  backNine += holeStrokes;
                }
              }

              if (teamType === "two") {
                const hole = holes[x];
                const { lowest, secondLowest } = hole;
                if (format === "stableford") {
                  if (lowest !== "") {
                    backNine += 2 - lowest;
                  }
                  if (secondLowest !== "") {
                    backNine += 2 - secondLowest;
                  }
                } else {
                  if (lowest !== "") {
                    backNine += courseHole.par - lowest;
                  }
                  if (secondLowest !== "") {
                    backNine += courseHole.par - secondLowest;
                  }
                }
              }
            }
            filtTeam[i].backNine = backNine;

            // Last 6
            let lastSix = 0;
            for (let x = 12; x < holes.length; x++) {
              const courseHole = card[x];
              if (teamType === "scramble") {
                const holeScore = holes[x].best;
                if (holeScore === "") {
                  continue;
                }
                if (format === "stableford") {
                  lastSix += 2 - holeScore;
                } else {
                  lastSix += courseHole.par + holeScore;
                }
              }

              if (teamType === "custom") {
                const hole = holes[x];
                const { scores } = hole;
                let { toCount } = hole;
                if (scores.length < toCount) {
                  toCount = scores.length;
                }
                if (format === "stableford") {
                  let holePts = 0;
                  for (let x = 0; x < toCount; x++) {
                    holePts += 2 - scores[x];
                  }
                  lastSix += holePts;
                } else {
                  let holeStrokes = 0;
                  for (let x = 0; x < toCount; x++) {
                    holeStrokes += courseHole.par - scores[x];
                  }
                  lastSix += holeStrokes;
                }
              }

              if (teamType === "two") {
                const hole = holes[x];
                const { lowest, secondLowest } = hole;
                if (format === "stableford") {
                  if (lowest !== "") {
                    lastSix += 2 - lowest;
                  }
                  if (secondLowest !== "") {
                    lastSix += 2 - secondLowest;
                  }
                } else {
                  if (lowest !== "") {
                    lastSix += courseHole.par - lowest;
                  }
                  if (secondLowest !== "") {
                    lastSix += courseHole.par - secondLowest;
                  }
                }
              }
            }
            filtTeam[i].lastSix = lastSix;

            // Last 3
            let lastThree = 0;
            for (let x = 15; x < holes.length; x++) {
              const courseHole = card[x];
              if (teamType === "scramble") {
                const holeScore = holes[x].best;
                if (holeScore === "") {
                  continue;
                }
                if (format === "stableford") {
                  lastThree += 2 - holeScore;
                } else {
                  lastThree += courseHole.par + holeScore;
                }
              }

              if (teamType === "custom") {
                const hole = holes[x];
                const { scores } = hole;
                let { toCount } = hole;
                if (scores.length < toCount) {
                  toCount = scores.length;
                }
                if (format === "stableford") {
                  let holePts = 0;
                  for (let x = 0; x < toCount; x++) {
                    holePts += 2 - scores[x];
                  }
                  lastThree += holePts;
                } else {
                  let holeStrokes = 0;
                  for (let x = 0; x < toCount; x++) {
                    holeStrokes += courseHole.par - scores[x];
                  }
                  lastThree += holeStrokes;
                }
              }

              if (teamType === "two") {
                const hole = holes[x];
                const { lowest, secondLowest } = hole;
                if (format === "stableford") {
                  if (lowest !== "") {
                    lastThree += 2 - lowest;
                  }
                  if (secondLowest !== "") {
                    lastThree += 2 - secondLowest;
                  }
                } else {
                  if (lowest !== "") {
                    lastThree += courseHole.par - lowest;
                  }
                  if (secondLowest !== "") {
                    lastThree += courseHole.par - secondLowest;
                  }
                }
              }
            }
            filtTeam[i].lastThree = lastThree;

            // Last 1
            const courseLast = card[17].par;
            if (teamType === "scramble") {
              const holeScore = holes[17].best;
              if (holeScore === "") {
                if (format === "stableford") {
                  filtTeam[i].lastHole = 2 - holeScore;
                } else {
                  filtTeam[i].lastHole = courseLast + holeScore;
                }
              }
            }

            if (teamType === "custom") {
              const hole = holes[17];
              const { scores } = hole;
              let { toCount } = hole;
              if (scores.length < toCount) {
                toCount = scores.length;
              }
              if (format === "stableford") {
                let holePts = 0;
                for (let x = 0; x < toCount; x++) {
                  holePts += 2 - scores[x];
                }
                filtTeam[i].lastHole = holePts;
              } else {
                let holeStrokes = 0;
                for (let x = 0; x < toCount; x++) {
                  holeStrokes += card[17].par - scores[x];
                }
                filtTeam[i].lastHole = holeStrokes;
              }
            }

            if (teamType === "two") {
              const hole = holes[17];
              const { lowest, secondLowest } = hole;
              if (format === "stableford") {
                let last = 0;
                if (lowest !== "") {
                  last += 2 - lowest;
                }
                if (secondLowest !== "") {
                  last += 2 - secondLowest;
                }
                filtTeam[i].lastHole = last;
              } else {
                let last = 0;
                if (lowest !== "") {
                  last += courseLast - lowest;
                }
                if (secondLowest !== "") {
                  last += courseLast - secondLowest;
                }
                filtTeam[i].lastHole = last;
              }
            }

            // Strokes
            for (let x = 0; x < card.length; x++) {
              const courseHole = card[x];
              const { stroke, par } = courseHole;
              if (stroke === 1) {
                if (teamType === "scramble") {
                  const holeScore = holes[x].best;
                  if (holeScore !== "") {
                    if (format === "stableford") {
                      filtTeam[i].strokeOne = 2 - holeScore;
                    } else {
                      filtTeam[i].strokeOne += courseLast + holeScore;
                    }
                  }
                }
                if (teamType === "custom") {
                  const hole = holes[x];
                  const { scores } = hole;
                  let { toCount } = hole;
                  if (scores.length < toCount) {
                    toCount = scores.length;
                  }
                  if (format === "stableford") {
                    let holePts = 0;
                    for (let x = 0; x < toCount; x++) {
                      holePts += 2 - scores[x];
                    }
                    filtTeam[i].strokeOne = holePts;
                  } else {
                    let holeStrokes = 0;
                    for (let x = 0; x < toCount; x++) {
                      holeStrokes += courseLast - scores[x];
                    }
                    filtTeam[i].strokeOne = holeStrokes;
                  }
                }
                if (teamType === "two") {
                  const hole = holes[x];
                  const { lowest, secondLowest } = hole;
                  let tot = 0;
                  if (format === "stableford") {
                    if (lowest !== "") {
                      tot += 2 - lowest;
                    }
                    if (secondLowest !== "") {
                      tot += 2 - secondLowest;
                    }
                  } else {
                    if (lowest !== "") {
                      tot += courseHole.par - lowest;
                    }
                    if (secondLowest !== "") {
                      tot += courseHole.par - secondLowest;
                    }
                  }
                  filtTeam[i].strokeOne = tot;
                }
              }
              if (stroke === 2) {
                if (teamType === "scramble") {
                  const holeScore = holes[x].best;
                  if (holeScore !== "") {
                    if (format === "stableford") {
                      filtTeam[i].strokeTwo = 2 - holeScore;
                    } else {
                      filtTeam[i].strokeTwo += courseLast + holeScore;
                    }
                  }
                }
                if (teamType === "custom") {
                  const hole = holes[x];
                  const { scores } = hole;
                  let { toCount } = hole;
                  if (scores.length < toCount) {
                    toCount = scores.length;
                  }
                  if (format === "stableford") {
                    let holePts = 0;
                    for (let x = 0; x < toCount; x++) {
                      holePts += 2 - scores[x];
                    }
                    filtTeam[i].strokeTwo = holePts;
                  } else {
                    let holeStrokes = 0;
                    for (let x = 0; x < toCount; x++) {
                      holeStrokes += courseLast - scores[x];
                    }
                    filtTeam[i].strokeTwo = holeStrokes;
                  }
                }
                if (teamType === "two") {
                  const hole = holes[x];
                  const { lowest, secondLowest } = hole;
                  let tot = 0;
                  if (format === "stableford") {
                    if (lowest !== "") {
                      tot += 2 - lowest;
                    }
                    if (secondLowest !== "") {
                      tot += 2 - secondLowest;
                    }
                  } else {
                    if (lowest !== "") {
                      tot += courseHole.par - lowest;
                    }
                    if (secondLowest !== "") {
                      tot += courseHole.par - secondLowest;
                    }
                  }
                  filtTeam[i].strokeTwo = tot;
                }
              }
              if (stroke === 3) {
                if (teamType === "scramble") {
                  const holeScore = holes[x].best;
                  if (holeScore !== "") {
                    if (format === "stableford") {
                      filtTeam[i].strokeThree = 2 - holeScore;
                    } else {
                      filtTeam[i].strokeThree += courseLast + holeScore;
                    }
                  }
                }
                if (teamType === "custom") {
                  const hole = holes[x];
                  const { scores } = hole;
                  let { toCount } = hole;
                  if (scores.length < toCount) {
                    toCount = scores.length;
                  }
                  if (format === "stableford") {
                    let holePts = 0;
                    for (let x = 0; x < toCount; x++) {
                      holePts += 2 - scores[x];
                    }
                    filtTeam[i].strokeThree = holePts;
                  } else {
                    let holeStrokes = 0;
                    for (let x = 0; x < toCount; x++) {
                      holeStrokes += courseLast - scores[x];
                    }
                    filtTeam[i].strokeThree = holeStrokes;
                  }
                }
                if (teamType === "two") {
                  const hole = holes[x];
                  const { lowest, secondLowest } = hole;
                  let tot = 0;
                  if (format === "stableford") {
                    if (lowest !== "") {
                      tot += 2 - lowest;
                    }
                    if (secondLowest !== "") {
                      tot += 2 - secondLowest;
                    }
                  } else {
                    if (lowest !== "") {
                      tot += courseHole.par - lowest;
                    }
                    if (secondLowest !== "") {
                      tot += courseHole.par - secondLowest;
                    }
                  }
                  filtTeam[i].strokeThree = tot;
                }
              }
            }
          }
          lbMap[div.leaderboardID] = filtTeam;
        } else {
          const divBoard = round[div.leaderboardID].leaderboard;
          const { format } = div;
          // console.log(format);
          mep[div.leaderboardID] = format;
          const leadScore = divBoard[0].score;

          const filt = divBoard;
          if (filt.length > 10) {
            filt.length = 10;
          }

          // if (filt.length > 1) {
          divOpts.push({
            label: div.divisionName,
            value: div.leaderboardID,
          });
          const ids = filt.map((player) => player.playerID);
          const refs = ids.map((id) =>
            roundDoc.ref.collection("scores").doc(id).get()
          );

          const docs = await Promise.all(refs);

          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i];
            if (!doc.exists) {
              continue;
            }
            const data = doc.data();
            const { holes } = data;

            // Front 9
            // Front 9
            if (format === "stable-medal") {
              let frtPts = 0;
              let backPts = 0;
              for (let z = 0; z < holes.length; z++) {
                const hole = holes[z];
                if (z < 9) {
                  frtPts += hole.msPoints;
                } else {
                  backPts += hole.msPoints;
                }
              }
              filt[i].frontNine = frtPts;
              filt[i].backNine = backPts;
            } else {
              filt[i].frontNine =
                format === "stableford"
                  ? data.frontNinePoints
                  : format === "medal"
                  ? data.frontNineGross
                  : data.frontNineNet;

              // Back 9
              filt[i].backNine =
                format === "stableford"
                  ? data.backNinePoints
                  : format === "medal"
                  ? data.backNineGross
                  : data.backNineNet;
            }
            // Last 6
            let lastSix = 0;
            for (let x = 12; x < holes.length; x++) {
              const hole = holes[x];
              if (format === "stableford") {
                lastSix += hole.points;
              } else if (format === "medal") {
                lastSix += hole.grossScore;
              } else if (format === "stable-medal") {
                lastSix += hole.msPoints;
              } else {
                lastSix += hole.netScore;
              }
            }
            filt[i].lastSix = lastSix;

            // Last 3
            let lastThree = 0;
            for (let x = 15; x < holes.length; x++) {
              const hole = holes[x];
              if (format === "stableford") {
                lastThree += hole.netScore;
              } else if (format === "medal") {
                lastThree += hole.grossScore;
              } else if (format === "stable-medal") {
                lastThree += hole.msPoints;
              } else {
                lastThree += hole.points;
              }
            }
            filt[i].lastThree = lastThree;

            // Last 1
            if (format === "stableford") {
              filt[i].lastHole = holes[17].points;
            } else if (format === "medal") {
              filt[i].lastHole = holes[17].grossScore;
            } else if (format === "stable-medal") {
              filt[i].lastHole = holes[17].msPoints;
            } else {
              filt[i].lastHole = holes[17].netScore;
            }

            // Strokes
            for (let x = 0; x < holes.length; x++) {
              const hole = holes[x];
              const { stroke } = hole;
              if (format === "stableford") {
                if (stroke === 1) {
                  filt[i].strokeOne = hole.points;
                }
                if (stroke === 2) {
                  filt[i].strokeTwo = hole.points;
                }
                if (stroke === 3) {
                  filt[i].strokeThree = hole.points;
                }
              } else if (format === "stable-medal") {
                if (stroke === 1) {
                  filt[i].strokeOne = hole.msPoints;
                }
                if (stroke === 2) {
                  filt[i].strokeTwo = hole.msPoints;
                }
                if (stroke === 3) {
                  filt[i].strokeThree = hole.msPoints;
                }
              } else if (format === "medal") {
                if (stroke === 1) {
                  filt[i].strokeOne = hole.grossScore;
                }
                if (stroke === 2) {
                  filt[i].strokeTwo = hole.grossScore;
                }
                if (stroke === 3) {
                  filt[i].strokeThree = hole.grossScore;
                }
              } else {
                if (stroke === 1) {
                  filt[i].strokeOne = hole.netScore;
                }
                if (stroke === 2) {
                  filt[i].strokeTwo = hole.netScore;
                }
                if (stroke === 3) {
                  filt[i].strokeThree = hole.netScore;
                }
              }
            }

            filt[i].scores = data;
          }
          // console.log(filt);
          lbMap[div.leaderboardID] = filt;
        }
        // }
      }
      setFormatMap(mep);
    }
    console.log(lbMap);

    setDivisions(divOpts);
    setTiedMap(lbMap);
    setTiedPlayers(arr);
    setFormat(format);
    setLoading(false);
  }

  function sortIt(sorter, format) {
    // console.log(sorter, format);
    setTiedPlayers((current) => {
      current.sort((a, b) => {
        if (format.includes("stable")) {
          if (a.score !== b.score) {
            return b.score - a.score;
          }
        }
        if (!format.includes("stable")) {
          if (a.score !== b.score) {
            return a.score - b.score;
          }
        }
        if (format.includes("stable") && sorter !== "handicap") {
          console.log("here");
          return b[sorter] - a[sorter];
        } else {
          return a[sorter] - b[sorter];
        }
      });
      return current;
    });
    setTiedMap((current) => {
      console.log(sorter, current[selDiv]);
      current[selDiv].sort((a, b) => {
        if (format.includes("stable")) {
          if (a.score !== b.score) {
            return b.score - a.score;
          }
        }
        if (!format.includes("stable")) {
          if (a.score !== b.score) {
            return a.score - b.score;
          }
        }
        if (format.includes("stable") && sorter !== "handicap") {
          return b[sorter] - a[sorter];
        } else {
          return a[sorter] - b[sorter];
        }
      });
      return current;
    });
    upd();
  }

  return (
    <div className="countout">
      <BackRow action={goBack} />
      <PageHeader text="Countout" />
      <div className="ph-msg">
        <p>Click on a header to sort players</p>
      </div>
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog open={showDia} onClose={() => setShowDia(false)}>
        <div className="countout-dialog">
          <PageHeader
            text="Add Countout"
            showClose
            close={() => setShowDia(false)}
          />
          <div className="pd-20">
            <div className="input-group">
              <p>Select Leaderboard</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={sel}
                options={options}
                onChange={(e) => {
                  setSel(e.value);
                }}
              />
            </div>
            <div className="flex-center mt-20 mb-40">
              <button onClick={() => {}} className="default-button">
                Save
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      {/* 
      <div className="green-link ml-20" onClick={() => setShowDia(true)}>
        Add Countout
      </div> */}

      {divisions.length > 0 && (
        <div className="input-group pl-20">
          <p>Select Leaderboard</p>
          <Dropdown
            arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
            arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
            value={selDiv}
            options={divisions}
            onChange={(e) => {
              setSelDiv(e.value);
            }}
          />
        </div>
      )}

      <div className="tied-players">
        <div className="tp-header mb-10">
          <div className="tpp-ra mr-20"></div>
          <div className="tph-name">
            <p className="msb">Name</p>
          </div>
          {options.map((opt, index) => {
            if (selDiv === "teams" && opt.value === "handicap") {
              return null;
            }
            return (
              <div
                key={opt.value}
                onClick={() => {
                  const fmt = formatMap[selDiv] ?? format;
                  setSorter(opt.value);
                  sortIt(opt.value, fmt);
                }}
                className={
                  sorter === opt.value ? "tph-opt tpho-active" : "tph-opt"
                }
              >
                <p className="msb">{opt.label}</p>
              </div>
            );
          })}
        </div>
        {tiedMap[selDiv] &&
          tiedMap[selDiv].map((player, index) => {
            // console.log(player);
            return (
              <div key={`tied${index}`} className="tp-player">
                <div className="tpp-ra mr-20">
                  <p>{player.rank}.</p>
                </div>
                <PlayerItem
                  hcp={player.handicap}
                  key={player.playerID}
                  hideImage
                  hideHCP={selDiv === "teams" || player.teamID !== undefined}
                  hideIcons
                  name={`${player.name} (${player.score})`}
                />

                {options.map((opt, i) => {
                  if (selDiv === "teams" && opt.value === "handicap") {
                    return null;
                  }

                  let act = false;
                  if (sorter === opt.value) {
                    if (index === 0 && tiedMap[selDiv][1]) {
                      if (tiedMap[selDiv][1].score === player.score) {
                        act = true;
                      }
                    } else {
                      const above = tiedMap[selDiv][index - 1];
                      const below = tiedMap[selDiv][index + 1];
                      if (above && above.score !== player.score) {
                        if (below) {
                          if (below.score === player.score) {
                            act = true;
                          }
                        }
                      }
                    }
                  }

                  return (
                    <div
                      key={`${i}opt`}
                      className={act ? "tph-opt tpho-act" : "tph-opt"}
                    >
                      <p>{player[opt.value]}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Countout;
