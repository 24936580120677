import React, { useState, useEffect } from "react";
import "./LeagueAddPlayer.css";

// Icons
import Close from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// Components
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { db, admin } from "../../../firebase";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import PlayerListItem from "../../display/PlayerItem/PlayerListItem";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import classNames from "classnames";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      onClick={onClick}
      className="big-tick dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function LeagueAddPlayer({
  hide = () => {},
  players = [],
  leagueID = "",
  reload = () => {},
  teamLeague = false,
}) {
  // State
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [viewType, setViewType] = useState("items");
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }
  const [sorter, setSorter] = useState("");
  const [sortDir, setSortDir] = useState("");
  const [playerrs, setPlayers] = useState(players);

  function sortPlayers({ sorter = "", dir = "" }) {
    setSorter(sorter);
    setSortDir(dir);
    if (dir === "up") {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => a[sorter] - b[sorter]);
        } else {
          c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
        }
        return c;
      });
    } else {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => b[sorter] - a[sorter]);
        } else {
          c.sort((a, b) => b[sorter].localeCompare(a[sorter]));
        }
        return c;
      });
    }
    update();
  }

  // UE

  // Functions
  async function saveChanges() {
    setLoading(true);
    let type = "leagues";
    if (teamLeague) {
      type = "teamLeagues";
    }
    try {
      await db
        .collection(type)
        .doc(leagueID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(...selected),
        });
      setFinished(true);
      toast.success("Players successfully added to league", {
        icon: successIco,
      });
      reload();
      hide();
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }
  return (
    <div className="league-add-player">
      <Dialog open onClose={hide} className="mpa-dia lap-dia">
        <div className="lap-content">
          <div className="page-header">
            <h2>Add Players</h2>
            <Close className="icon-cursor" onClick={hide} />
          </div>
          {!finished && (
            <div className="ph-msg">
              <p>Select players to add to your league</p>
            </div>
          )}
          {loading ? (
            <div className="flex-center loading">
              <Oval
                color="#21c17c"
                secondaryColor="#ffffff"
                height={40}
                width={40}
              />
            </div>
          ) : finished ? (
            <>
              {" "}
              <div className="im-msg">
                <p>Your selected players have been invited</p>
              </div>
              <div className="mpa-fin flex-center">
                <GradientCircleIcon />
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-align-center lap-filt">
                <div className="input-group mb-20 si-search">
                  <p>Search</p>
                  <div className="flex-align-center">
                    <input
                      type="text"
                      placeholder="Search for player"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className="default-input"
                    />
                    <HighlightOffIcon
                      className="icon-cursor red-icon-bg ml-20"
                      onClick={() => setSearchInput("")}
                    />
                  </div>
                </div>
                <RadioGroupTwo
                  active={viewType}
                  buttons={[
                    {
                      value: "items",
                      text: "Items",
                    },
                    {
                      value: "list",
                      text: "List",
                    },
                  ]}
                  setActive={() => {
                    setViewType((c) => {
                      if (c === "list") {
                        return "items";
                      } else {
                        return "list";
                      }
                    });
                  }}
                />
              </div>

              {errorMessage !== "" && (
                <div className="error-message-row">
                  <p>{errorMessage}</p>
                </div>
              )}
              <div className="default-button-row">
                <button
                  disabled={selected.length === 0}
                  onClick={saveChanges}
                  className="default-button"
                >
                  Add Players
                </button>
              </div>
              <div
                style={{
                  justifyContent:
                    players.length > 3 ? "space-between" : "flex-start",
                }}
                className="im-players"
              >
                {viewType === "list" && (
                  <>
                    <div className="player-list-item pli-head pb-20">
                      <div
                        onClick={() => {
                          if (sorter === "firstName") {
                            if (sortDir === "up") {
                              sortPlayers({ sorter: "firstName", dir: "down" });
                            } else {
                              sortPlayers({ sorter: "firstName", dir: "up" });
                            }
                          } else {
                            sortPlayers({ sorter: "firstName", dir: "down" });
                          }
                        }}
                        className={classNames("pli-item", {
                          "plih-sel-up":
                            sorter === "firstName" && sortDir === "up",
                          "plih-sel-dw":
                            sorter === "firstName" && sortDir !== "up",
                        })}
                      >
                        <p>First Name</p>
                      </div>
                      <div
                        onClick={() => {
                          if (sorter === "lastName") {
                            if (sortDir === "up") {
                              sortPlayers({ sorter: "lastName", dir: "down" });
                            } else {
                              sortPlayers({ sorter: "lastName", dir: "up" });
                            }
                          } else {
                            sortPlayers({ sorter: "lastName", dir: "down" });
                          }
                        }}
                        className={classNames("pli-item", {
                          "plih-sel-up":
                            sorter === "lastName" && sortDir === "up",
                          "plih-sel-dw":
                            sorter === "lastName" && sortDir !== "up",
                        })}
                      >
                        <p>Last Name</p>
                      </div>
                      <div
                        onClick={() => {
                          if (sorter === "handicap") {
                            if (sortDir === "up") {
                              sortPlayers({ sorter: "handicap", dir: "down" });
                            } else {
                              sortPlayers({ sorter: "handicap", dir: "up" });
                            }
                          } else {
                            sortPlayers({ sorter: "handicap", dir: "down" });
                          }
                        }}
                        className={classNames("pli-item", {
                          "plih-sel-up":
                            sorter === "handicap" && sortDir === "up",
                          "plih-sel-dw":
                            sorter === "handicap" && sortDir !== "up",
                        })}
                      >
                        <p>Hcp</p>
                      </div>
                      <div className="pli-item">
                        <p>Selected</p>
                      </div>
                    </div>
                  </>
                )}
                {playerrs.map((player) => {
                  if (searchInput !== "") {
                    const sl = searchInput.toLowerCase();
                    if (!player.name.toLowerCase().includes(sl)) {
                      return null;
                    }
                  }

                  if (viewType === "list") {
                    return (
                      <PlayerListItem
                        player={player}
                        key={player.playerID}
                        selected={selected.includes(player.playerID)}
                        select={() => {
                          setSelected((current) => {
                            if (current.includes(player.playerID)) {
                              const filt = current.filter(
                                (uid) => uid !== player.playerID
                              );
                              return filt;
                            } else {
                              return [...current, player.playerID];
                            }
                          });
                        }}
                      />
                    );
                  } else {
                    return (
                      <PlayerItem
                        noLink
                        key={player.playerID}
                        showProfile={() =>
                          setSelected((current) => {
                            if (current.includes(player.playerID)) {
                              const filt = current.filter(
                                (uid) => uid !== player.playerID
                              );
                              return filt;
                            } else {
                              return [...current, player.playerID];
                            }
                          })
                        }
                        hcp={player.handicap}
                        img={player.profileImage}
                        name={player.name}
                        playerID={player.playerID}
                        hideIcons
                        showBg={selected.includes(player.playerID)}
                      />
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default LeagueAddPlayer;
