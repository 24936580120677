import React from "react";
import "./MenuSwitcher.css";
import { useCookies } from "react-cookie";

import Switch from "react-ios-switch/lib/Switch";

function MenuSwitcher({}) {
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  return (
    <div className="me-swi">
      {/* <div className="msw-top">
        <h3>Menu Type</h3>
      </div> */}
      <div className="msw-bot">
        <div className="mes-txt">
          <p>Drop</p>
        </div>
        <div className="mes-mid">
          <Switch
            onColor="#1e7a69"
            checked={cookies["menu-type"] === "pill"}
            className={
              cookies["menu-type"] === "pill"
                ? "scoring-switch switch-on"
                : "scoring-switch"
            }
            onChange={() => {
              const curr = cookies["menu-type"];
              if (curr === "pill") {
                setCookie("menu-type", "drop");
              } else {
                setCookie("menu-type", "pill");
              }
            }}
          />
        </div>
        <div className="mes-txt">
          <p>Buttons</p>
        </div>
      </div>
    </div>
  );
}

export default MenuSwitcher;
