import React, { useState } from "react";
import { db } from "../../firebase";
import "./Contact.css";
import Checkbox from "@mui/material/Checkbox";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { Helmet } from "react-helmet";
function Contact() {
  // State
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState("");

  async function save() {
    setLoading(true);
    try {
      await db.collection("queries").add({
        date: new Date(),
        name,
        email,
        text: message,
        type: "comish-contact",
      });
      setFinished(true);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we had some technical difficulties there - please try again!"
      );
    }
    setLoading(false);
  }

  return (
    <div className="contact-page">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <Helmet>
        <title>The Commissioner | Contact</title>
      </Helmet>
      {loading && <BackdropLoader />}
      <div className="dp-header">
        <h1>Contact Us</h1>
      </div>

      <div className="cp-email  animate__animated animate__fadeIn">
        <p>
          Looking to get setup on The Commissioner or have any other questions?
          Please feel free to contact us below, and we will get back to you as
          soon as possible.
          <br />
          <br />
        </p>
        <p>Alternatively, you can contact us at support@theugatour.com</p>
      </div>

      {finished ? (
        <div className="contact-finished">
          <h1>Thank you for contacting us</h1>
          <p>We will be in touch as soon as we can.</p>
        </div>
      ) : (
        <div className="contact-box  animate__animated animate__fadeIn">
          <div className="contact-container">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name here..."
              className="contact-input"
            />
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email here..."
              className="contact-input"
            />
            <textarea
              rows={10}
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter your message here..."
              className="contact-input"
            />
            <div className="check-confirm">
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked((current) => !current)}
              />
              <p>
                By checking this box I allow the UGA Tour to contact me with
                regards to this query
              </p>
            </div>
            <div className="flex-center mt-40 mb-40">
              <button
                onClick={save}
                disabled={name === "" || email === "" || message === ""}
                className="default-button"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
