/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./CupCreator.css";
import { BallTriangle } from "react-loader-spinner";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { db, storage } from "../../firebase";
import Switch from "react-ios-switch/lib/Switch";
import moment from "moment";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BackdropLoader from "../Loaders/BackdropLoader";
// Components
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import Compressor from "compressorjs";
import Pill from "../Buttons/Pill";
import ErrorModal from "../ErrorModal/ErrorModal";
import CoursePicker from "../Events/CoursePicker/CoursePicker";
import EventPlayerSelector from "../Events/EventCreator/EventPlayerSelector";
import CustomRules from "../CustomRules/CustomRules";
import TeamSelector from "./TeamSelector";
import { Backdrop, Dialog } from "@mui/material";
import StatsSelector from "../StatsSelector/StatsSelector";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import ImageSelector from "../Events/ImageSelector/ImageSelector";
import InviteModal from "../Events/InviteModal/InviteModal";
import { useDropzone } from "react-dropzone";

import { useAuth } from "../../contexts/AuthContext";
import ColorSelector from "./ColorSelector";
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import { Close } from "@mui/icons-material";

const GradientInfoIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <InfoIcon
      onClick={onClick}
      className={`dg-icon-bg ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
const GradientEditIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <EditIcon
      onClick={onClick}
      className={`dg-icon-bg ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
const GradientAddCircleIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className={`dg-icon-bg ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function CupCreator({
  goBack,
  reload = () => {},
  defaultInvited = [],
  defaultScratchMode = false,
  defaultTees = [],
  defaultCourseID = "",
  defaultCourseName = "",
  defaultRules = [],
  defaultTeams = [
    { players: [], captain: "", name: "Team 1" },
    { players: [], captain: "", name: "Team 2" },
  ],
  edit = true,
  defaultPlayers = [],
  defaultEventName = "",
  defaultDate = new Date(),
  defaultDescription = "",
  defaultMatchups = "Admin",
  teeMap = {},
  cupID = "",
  taskID = "",
  defaultStats = [],
  defaultNassau = false,
  rounds = [],
  defaultTeamOneColor = "#21c17c",
  defaultTeamTwoColor = "#e24955",
  defaultSeeding = false,
  defaultLength = {},
  defaultImageLink = "",
  defaultRing = 2,
  defaultAllowance = 100,
  defaultBB = false,
  defaultCompletion = "auto",
  disableChanges = false,
  // defaultTeamOneColor = { hex: "#21c17c" },
  // defaultTeamTwoColor = { hex: "#e24955" },
}) {
  const now = new Date().toISOString().split("T")[0];
  const picScrollRef = useRef();

  const pickerColors = [
    "#10603D",
    "#21c17c",
    "#e24955",
    "#379fdc",
    "#ffa340",
    "#ffd700",
    "#6E48BE",
    "#1B4F6D",
    "#000000",
  ];

  const [image, setImage] = useState("");
  const [nassau, setNassau] = useState(defaultNassau);
  const [userDates, setUserDates] = useState([]);
  const [imgageURL, setImageURL] = useState(defaultImageLink);
  const [scratchMode, setScratchMode] = useState(defaultScratchMode);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [completion, setCompletion] = useState(defaultCompletion);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});
  const [showCrop, setShowCrop] = useState(false);

  const { currentUser, userDetails } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [error, setError] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState("");

  const [showImages, setShowImages] = useState(false);

  const [generalOpen, setGeneralOpen] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);

  const [chosen, setChosen] = useState(edit);
  const [players, setPlayers] = useState(defaultPlayers);
  const [teams, setTeams] = useState(defaultTeams);

  const [playerMap, setPlayerMap] = useState({});

  const [teamOneColor, setTeamOneColor] = useState(defaultTeamOneColor);
  const [teamTwoColor, setTeamTwoColor] = useState(defaultTeamTwoColor);
  const [showPickerOne, setShowPickerOne] = useState(false);
  const [showPickerTwo, setShowPickerTwo] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState(defaultPlayers);
  const [selectedCourse, setSelectedCourse] = useState({
    value: defaultCourseID,
    label: defaultCourseName,
    teeArray: defaultTees,
    length: defaultLength,
  });
  const [progress, setProgress] = useState(0);

  const [ring, setRing] = useState(defaultRing);

  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(0);
  const [enabledStats, setEnabledStats] = useState(defaultStats);
  const [showStats, setShowStats] = useState(false);

  const [rules, setRules] = useState(defaultRules);
  const [showRules, setShowRules] = useState(false);

  const [showPlayers, setShowPlayers] = useState(false);
  const [showTeams, setShowTeams] = useState(false);

  const [courseChanged, setCourseChanged] = useState(false);

  const [allowance, setAllowance] = useState(defaultAllowance);

  const [bbLeaderboard, setBBLeaderboard] = useState(defaultBB);

  const nameRef = useRef();
  const dateRef = useRef();
  const descRef = useRef();

  const scrollRef = useRef();

  const [nameState, setNameState] = useState(defaultEventName);
  const [dateState, setDateState] = useState(
    new Date(defaultDate).toISOString().split("T")[0]
  );
  const [descState, setDescState] = useState(defaultDescription);

  const [matchups, setMatchups] = useState(defaultMatchups);

  const [seeding, setSeeding] = useState(defaultSeeding);

  useEffect(() => {
    if (currentUser && userDetails) {
      // console.log(defaultDate);
      loadAll();
      loadUserDates();
      // console.log(defaultDate);
      // console.log(defaultPlayers.length);
      setUpdate((current) => current + 1);
    }
  }, [currentUser, userDetails]);

  async function cropImage() {
    const image = new Image();
    // console.log(imgageURL);
    image.src = imgageURL;
    const crop = fc;
    // console.log(image);
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }

  function compare(a, b) {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  }

  async function loadAll() {
    const playerArr = [];

    const selArr = [];

    // console.log(userDetails.friends);

    // Queries
    // const players = await db
    //   .collection("users")
    //   .orderBy("lastName", "asc")
    //   .get();

    // console.log(userDetails);

    const mep = {};

    const { firstName, lastName, profileImage, handicap } = userDetails;
    selArr.push({
      label: `${firstName} ${lastName}`,
      value: currentUser.uid,
      handicap,
      profileImage,
      name: `${firstName} ${lastName}`,
      playerID: currentUser.uid,
      lastName: lastName,
    });
    playerArr.push({
      label: `${firstName} ${lastName}`,
      value: currentUser.uid,
      handicap,
      profileImage,
      name: `${firstName} ${lastName}`,
      playerID: currentUser.uid,
      lastName: lastName,
    });

    mep[currentUser.uid] = {
      label: `${firstName} ${lastName}`,
      value: currentUser.uid,
      handicap,
      profileImage,
      name: `${firstName} ${lastName}`,
      playerID: currentUser.uid,
      lastName: lastName,
    };

    const refs = userDetails.friends.map((uid) =>
      db.collection("users").doc(uid).get()
    );
    const docs = await Promise.all(refs);

    for (let i = 0; i < userDetails.friends.length; i++) {
      setProgress((i / userDetails.friends.length) * 100);
      const playerID = userDetails.friends[i];
      const doc = docs[i];
      const player = doc.data();
      if (!doc.exists) {
        continue;
      }
      const playerName = `${player.firstName} ${player.lastName}`;
      const { profileImage, status } = player;
      let { handicap } = player;
      for (let j = 0; j < defaultTeams.length; j++) {
        const team = defaultTeams[j] ?? [];
        const players = team.players ?? [];
        for (let x = 0; x < players.length; x++) {
          if (
            team.players[x].playerID === playerID &&
            team.players[x].customHandicap
          ) {
            handicap = players[x].handicap;
          }
        }
      }
      if (status === "disabled") {
        continue;
      }
      const settings = player.settings ?? "";
      // If player accepts invites from friends onbly
      // if (settings !== '') {
      //     if (settings.friendsOnly === true && !friendIDs.includes(currentUser.uid)) {
      //         continue;
      //     }
      // }

      if (!edit) {
        playerArr.push({
          label: playerName,
          value: playerID,
          handicap,
          profileImage,
          name: playerName,
          playerID,
          lastName: player.lastName,
        });
      }

      mep[playerID] = {
        label: playerName,
        value: playerID,
        handicap,
        profileImage,
        name: playerName,
        playerID,
        lastName: player.lastName,
      };

      if (edit) {
        if (defaultPlayers.includes(playerID)) {
          selArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
            name: playerName,
            tee: teeMap[playerID],
            lastName: player.lastName,
            playerID,
          });
        } else if (!defaultPlayers.includes(playerID)) {
          // console.log(playerID)
          playerArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
            name: playerName,
            tee: teeMap[playerID],
            lastName: player.lastName,
            playerID,
          });
        }
      }
    }
    console.log(selArr);
    selArr.sort(compare);
    playerArr.sort(compare);
    if (true) {
      setSelectedPlayers(selArr);
    }

    setPlayerMap(mep);

    setPlayers(playerArr);
    setLoading(false);
  }

  async function loadUserDates() {
    const now = new Date();
    const cups = await db
      .collection("cups")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("cupDate", ">", now)
      .get();
    const events = await db
      .collection("events")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("eventDate", ">", now)
      .get();

    const dateArr = [];
    for (let i = 0; i < cups.docs.length; i++) {
      const cupDoc = cups.docs[i];
      if (cupDoc.id === cupID) {
        continue;
      }
      const cup = cupDoc.data();
      const { rounds } = cup;
      for (let j = 0; j < rounds.length; j++) {
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      const event = eventDoc.data();
      const { rounds } = event;
      for (let j = 0; j < rounds.length; j++) {
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    setUserDates(dateArr);
  }

  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    setShowCrop(true);

    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        console.log(res);
        setImage(res);
      },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  function validate() {
    const cupName = nameRef.current.value;
    const date = dateRef.current.value;
    const dateVal = dateRef.current.valueAsDate;
    const now = moment();
    const dateMoment = moment(dateVal);
    setError("");

    console.log(teams.length);

    // Name
    if (cupName === "") {
      setError("Please enter a name for this cup");
      nameRef.current.focus();
      return false;
    }
    if (date === "") {
      setError("Please select a date for this cup");
      dateRef.current.focus();
      return false;
    }

    // if (now.isAfter(dateMoment)) {
    //   setError("Please ensure the date for this cup is in the future");
    //   dateRef.current.focus();
    //   // return false;
    // }

    // for (let i = 0; i < userDates.length; i++) {
    //   const userDateMoment = moment(userDates[i]);
    //   if (userDateMoment.isSame(dateMoment, "date")) {
    //     setError("Sorry! This date conflicts with one of your future rounds");
    //     dateRef.current.focus();
    //     return false;
    //   }
    // }

    // for (let i = 0; i < rounds.length; i++) {
    //   const roundMoment = moment(new Date(rounds[i].date.toDate()));
    //   if (roundMoment.isBefore(dateMoment, "date")) {
    //     setError("Sorry! The cup date cannot be set after one of the rounds");
    //     dateRef.current.focus();
    //     return false;
    //   }
    // }

    // if (selectedPlayers.length < 4) {
    //   setError("A minimum of 4 players is required for this kind of event");
    //   return false;
    // }

    // if (teams[0].players === undefined) {
    //   setError("Please ensure to set up your teams");
    //   return false;
    // }

    if (image !== "") {
      if (image.type) {
        if (!image.type.includes("image")) {
          setError("Please make sure to only upload images");
          return false;
        }
      }
    }
  }

  async function storeEvent() {
    setLoading(true);

    if (validate() === false) {
      return setLoading(false);
    }
    setProgress(0);

    const cupName = nameRef.current.value;
    const cupDate = new Date(dateRef.current.value);
    const performAt = new Date(cupDate);
    performAt.setHours(cupDate.getHours() - 12);
    const description = descRef.current.value;
    // console.log(selectedCourse);
    const playerIDs = selectedPlayers.map((player) => player.playerID);
    const teeMap = {};
    for (let i = 0; i < selectedPlayers.length; i++) {
      teeMap[selectedPlayers[i].value] =
        selectedPlayers[i].tee ?? selectedCourse.teeArray[0];
    }
    // return console.log(selectedCourse, cupName, cupDate, description, teams)
    setProgress((current) => current + 25);

    // const teamCaptains = [
    //   teams[0].players[0].playerID,
    //   teams[1].players[0].playerID,
    // ];

    // const seeds = {
    //   [`${teams[0].name}`]: [],
    //   [`${teams[1].name}`]: [],
    // };

    if (teams.length > 2) {
      teams.splice(2, 1);
    }

    // console.log(
    //   rules,
    //   teamCaptains,
    //   seeding,
    //   seeds,
    //   cupDate,
    //   cupName,
    //   selectedCourse,
    //   description,
    //   playerIDs,
    //   teams,
    //   teeMap,
    //   matchups,
    //   nassau,
    //   enabledStats,
    //   teamOneColor,
    //   teamTwoColor
    // );
    // return setLoading(false);

    console.log(teams);

    try {
      const cup = await db.collection("cups").add({
        rules,
        // teamCaptains,
        bbLeaderboard,
        // seeding,
        // seeds,
        ring,
        allowance,
        completed: false,
        cupDate,
        cupName,
        roundIDs: [],
        rounds: [],
        completion,
        format: "cup",
        courseID: selectedCourse.courseID,
        courseName: selectedCourse.name,
        description,
        playerIDs,
        cancelled: false,
        teams,
        teeMap,
        admins: [currentUser.uid],
        matchups,
        nassau,
        enabledStats,
        teamOneColor,
        scratchMode,
        teamTwoColor,
        sr: false,
      });
      setProgress((current) => current + 25);

      const task = await db.collection("tasks").add({
        performAt: "",
        cupID: cup.id,
        type: "cupRoundCreation",
        status: "pending",
      });
      await cup.update({
        taskID: task.id,
      });
      setProgress((current) => current + 25);
      if (image !== "") {
        setLoadingText("Uploading image...");
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "cupImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${cup.id}/${image.name}`)
              .put(file);

            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("cups").doc(cup.id).update({
                  imageLink: url,
                });
              });
            });
          });
        } else {
          const task = storage
            .ref()
            .child(`${cup.id}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("cups").doc(cup.id).update({
                imageLink: url,
              });
            });
          });
        }
      }
      reload();
      setProgress(100);
      setLoading(false);
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered some difficulties there - please try again."
      );
      setLoading(false);
    }
  }

  async function saveChanges() {
    // return console.log(teams, selectedPlayers);
    setProgress(0);
    if (validate() === false) {
      return;
    }

    setLoading(true);

    const cupName = nameRef.current.value;
    const cupDate = new Date(dateRef.current.value);
    const performAt = new Date(cupDate);
    performAt.setHours(cupDate.getHours() - 12);
    const description = descRef.current.value;

    const playerIDs = selectedPlayers.map((player) => player.value);
    const score = {
      [`${teams[0].name}`]: 0,
      [`${teams[1].name}`]: 0,
    };
    const teeMap = {};
    for (let i = 0; i < selectedPlayers.length; i++) {
      teeMap[selectedPlayers[i].value] =
        selectedPlayers[i].tee ?? selectedCourse.teeArray[0];
    }
    setProgress((current) => current + 25);

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      for (let j = 0; j < team.players.length; j++) {
        for (let x = 0; x < selectedPlayers.length; x++) {
          const player = selectedPlayers[x];
          if (player.playerID === team.players[j].playerID) {
            if (player.customHandicap) {
              team.players[j].customHandicap = true;
              team.players[j].handicap = player.handicap;
            }
          }
        }
      }
    }

    for (let i = 0; i < teams.length; i++) {
      const filterede = teams[i].players.filter((player) => {
        // console.log(playerIDs, player.playerID);
        // console.log(playerIDs.includes(player.playerID));
        return playerIDs.includes(player.playerID);
      });
      teams[i].players = filterede;
    }

    if (courseChanged) {
      // console.log(selectedCourse.imageLink);
      for (let i = 0; i < rounds.length; i++) {
        rounds[i].courseID = selectedCourse.courseID ?? selectedCourse.value;
        rounds[i].courseName = selectedCourse.name ?? selectedCourse.label;
        rounds[i].imageLink = selectedCourse.imageLink;
      }
    }

    // const teamCaptains = [
    //   teams[0].players[0].playerID,
    //   teams[1].players[0].playerID,
    // ];

    if (teams.length > 2) {
      teams.pop();
    }

    // return console.log(
    //   seeding,
    //   teamCaptains,
    //   rounds,
    //   rules,
    //   cupDate,
    //   cupName,
    //   selectedCourse,
    //   teams,
    //   teeMap,
    //   matchups,
    //   score
    // );

    try {
      await db
        .collection("cups")
        .doc(cupID)
        .update({
          seeding,
          bbLeaderboard,
          // teamCaptains,
          allowance,
          rounds,
          scratchMode,
          rules,
          ring,
          cupDate,
          cupName,
          format: "cup",
          courseID: selectedCourse.courseID ?? selectedCourse.value,
          courseName: selectedCourse.name ?? selectedCourse.label,
          description,
          playerIDs,
          teams,
          completion,
          teeMap,
          admins: [currentUser.uid],
          matchups,
          score,
          nassau,
          enabledStats,
          teamOneColor,
          teamTwoColor,
        });
      setProgress((current) => current + 25);

      // await db.collection("tasks").doc(taskID).update({
      //   performAt,
      // });
      setProgress((current) => current + 25);

      if (image !== "") {
        setLoadingText("Uploading image...");
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "cupImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${cupID}/${image.name}`)
              .put(file);

            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("cups").doc(cupID).update({
                  imageLink: url,
                });
              });
            });
          });
        } else {
          const task = storage.ref().child(`${cupID}/${image.name}`).put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("cups").doc(cupID).update({
                imageLink: url,
              });
            });
          });
        }
      }
      setProgress(100);
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered some difficulties there - please try again."
      );
      setLoading(false);
    }
  }

  return (
    <div className="cup-creator event-creator onboarding">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {showCrop && (
        <Backdrop className="crop-drop" open>
          <div className="crop-co">
            <div className="cd-close">
              <Close onClick={() => setShowCrop(false)} />
            </div>
            <div className="crop-box">
              <Cropper
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                image={imgageURL}
              />
            </div>
            <div className="flex-center crop-but">
              <button
                onClick={() => {
                  cropImage();
                  setShowCrop(false);
                }}
                className="default-button"
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {loading ? (
        <div className="loading-container elc">
          {/* <BallTriangle color="#21c17c" height={80} width={80} />
          <p className="mb-20">{loadingText}</p> */}
          <Box sx={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </div>
      ) : !chosen ? (
        <CoursePicker
          goBack={goBack}
          setChosen={setChosen}
          setCourseDetails={(e) => {
            setSelectedCourse(e);
            if (edit) {
              setCourseChanged(true);
            }
          }}
        />
      ) : showImages ? (
        <ImageSelector
          goBack={() => {
            setShowImages(false);
            setTimeout(
              () => picScrollRef.current.scrollIntoView({ behavior: "smooth" }),
              100
            );
          }}
          setSelectedImage={(e) => {
            setImageURL(e);
            setImage(e);
          }}
          selectedImage={image}
          type="event"
        />
      ) : showRules ? (
        <CustomRules
          goBack={() => {
            setShowRules(false);
            setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
          }}
          defaultRules={rules}
          saveRules={setRules}
        />
      ) : showPlayers ? (
        <InviteModal
          defaultPlayers={players}
          defaultSelected={selectedPlayers}
          creation
          add={(e) => {
            const arr = [];
            for (let i = 0; i < e.length; i++) {
              const player = playerMap[e[i]];
              arr.push(player);
            }
            setSelectedPlayers(arr);
            setUpdate((current) => current + 1);
          }}
          hide={() => setShowPlayers(false)}
        />
      ) : // <EventPlayerSelector
      //   courseDetails={selectedCourse}
      //   players={players}
      //   goBack={() => {
      //     setShowPlayers(false);
      //     setTimeout(() => {
      //       scrollRef.current.scrollIntoView({ behavior: "smooth" });
      //     }, 100);
      //   }}
      //   selPlayers={selectedPlayers}
      //   setPlayers={setSelectedPlayers}
      // />
      showStats ? (
        <StatsSelector
          goBack={() => {
            setShowStats(false);
            setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
          }}
          selected={enabledStats}
          setSelected={setEnabledStats}
        />
      ) : showTeams ? (
        <TeamSelector
          goBack={() => {
            setShowTeams(false);
            setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
          }}
          players={selectedPlayers}
          teams={teams}
          setTeams={setTeams}
          lockNames={edit && seeding}
        />
      ) : (
        <>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <div className="info-message">
              <GradientInfoIcon className="big-info-icon" />
              <p>{dialog}</p>
            </div>
          </Dialog>
          {false && (
            <div onClick={() => goBack()} className="back-row">
              <ArrowBack className="ts-arrow" />
              {edit ? <p>Cup Editor</p> : <p>Cup Creator</p>}
            </div>
          )}
          <BackRow action={goBack} />
          <PageHeader text={edit ? "Cup Editor" : "Cup Creator"} />
          <div className="sub-header">
            <h2 className="ne-header">General</h2>
          </div>
          <div className="ec-box" style={{ alignItems: "flex-end" }}>
            {!disableChanges && (
              <div className="input-group">
                <div className="ig-header">
                  <p>Course</p>
                  <EditIcon
                    className="dg-icon-bg icon-cursor ml-20"
                    onClick={() => {
                      setChosen(false);
                    }}
                  />
                </div>
                <div>
                  <input
                    disabled
                    value={selectedCourse.label ?? selectedCourse.name}
                  />
                </div>
              </div>
            )}
            <div className="input-group">
              <p>Cup Name</p>
              <input
                onChange={(e) => setNameState(e.target.value)}
                defaultValue={nameState}
                type="text"
                ref={nameRef}
              />
            </div>
            {!disableChanges && (
              <div className="input-group">
                <p>Cup Date</p>
                <input
                  onChange={(e) => setDateState(e.target.value)}
                  defaultValue={dateState}
                  max={"9999-12-31"}
                  min={now}
                  type="date"
                  ref={dateRef}
                />
              </div>
            )}
            {!disableChanges && (
              <div className="input-group">
                <p>Handicap Allowance (%)</p>
                <input
                  type="number"
                  max={100}
                  min={0}
                  value={allowance}
                  onChange={(e) => setAllowance(parseInt(e.target.value))}
                />
              </div>
            )}
          </div>
          <div className="sub-header">
            <h2 className="ne-header">Details</h2>
          </div>
          <div className="ec-box">
            <div className="input-group">
              <p>Description</p>
              <textarea
                placeholder="Type something..."
                defaultValue={descState}
                onChange={(e) => setDescState(e.target.value)}
                rows={6}
                ref={descRef}
              />
            </div>

            <div ref={picScrollRef} className="input-group mb-20">
              <p>Cup Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imgageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imgageURL} />
                      </div>
                    )}
                    {imgageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                  <div className="dz-or">
                    <p className="">Or</p>
                  </div>
                </div>
                <Pill
                  text={"Choose Preset Image"}
                  onClick={() => setShowImages(true)}
                />
              </div>
            </div>
          </div>
          <div className="sub-header">
            <h2 className="ne-header">Admin</h2>
          </div>
          <div className="ec-box">
            {" "}
            {true && (
              <div className="input-group  mt-10">
                <div className="ig-header">
                  <p>Round Completion</p>
                  <InfoIcon
                    className="info-icon dg-icon-bg"
                    onClick={() => {
                      setDialog(
                        "If set to auto, rounds will close as soon as the final match is completed, and either switch to the next round or close the event. If set to manual, this has to be triggered manually from the Live Event Dashboard"
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <div className="selector-group">
                  <button
                    onClick={() => setCompletion("auto")}
                    className={
                      completion === "auto" ? "sg-active sg-right" : "sg-right"
                    }
                  >
                    Auto
                  </button>
                  <button
                    onClick={() => setCompletion("manual")}
                    className={
                      completion === "manual"
                        ? "sg-middle sg-active"
                        : "sg-middle"
                    }
                  >
                    Manual
                  </button>
                </div>
              </div>
            )}
            {!disableChanges && (
              <div className="input-group nassau-group">
                <div className="ig-header">
                  <p>Scratch Mode</p>
                  <GradientInfoIcon
                    className="info-icon"
                    onClick={() => {
                      setDialog(
                        "Enabling this will set every handicap to zero, allowing everyone to play off scratch. Caution is advised.."
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <Switch
                  onColor="#1e7a69"
                  checked={scratchMode}
                  className={
                    scratchMode ? "scoring-switch switch-on" : "scoring-switch"
                  }
                  onChange={() => setScratchMode((current) => !current)}
                />
              </div>
            )}
            {!disableChanges && (
              <div className="input-group nassau-group">
                <div className="ig-header">
                  <p>Better Ball Leaderboard</p>
                  <GradientInfoIcon
                    className="info-icon"
                    onClick={() => {
                      setDialog(
                        "Enabling this will create a separate leaderboard from your Fourball groups"
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <Switch
                  onColor="#1e7a69"
                  checked={bbLeaderboard}
                  className={
                    bbLeaderboard
                      ? "scoring-switch switch-on"
                      : "scoring-switch"
                  }
                  onChange={() => setBBLeaderboard((current) => !current)}
                />
              </div>
            )}
            {!disableChanges && (
              <div className="input-group nassau-group">
                <div className="ig-header">
                  <p>Nassau</p>
                  <GradientInfoIcon
                    onClick={() => {
                      setDialog(
                        "Enabling Nassau splits the match into 3 parts - front nine, back nine and overall, with 1 point available for each."
                      );
                      setShowDialog(true);
                    }}
                    className="info-icon"
                  />
                </div>

                <Switch
                  onColor="#1e7a69"
                  checked={nassau}
                  className={
                    nassau ? "scoring-switch switch-on" : "scoring-switch"
                  }
                  onChange={() => setNassau((current) => !current)}
                />
              </div>
            )}
            {false && (
              <div className="input-group nassau-group">
                <div className="ig-header">
                  <p>Seeding</p>
                  <GradientInfoIcon
                    onClick={() => {
                      setDialog(
                        "Enabling seeding allows captains to rank their players, and matches will be created accordingly (i.e. 1 plays 1, 2 plays 2 etc.). Captains can pick their seeds under the 'Boardroom' tab, and have up to 2 days before the start of the cup to do so. The rankings are kept secret until the day of the round."
                      );
                      setShowDialog(true);
                    }}
                    className="info-icon"
                  />
                </div>
                <Switch
                  onColor="#1e7a69"
                  checked={seeding}
                  className={
                    seeding ? "scoring-switch switch-on" : "scoring-switch"
                  }
                  onChange={() => setSeeding((current) => !current)}
                />
              </div>
            )}
          </div>
          <div className="ec-box">
            {!disableChanges && (
              <div className="input-group mt-10">
                <div className="ig-header">
                  <p>Enabled Stats ({enabledStats.length})</p>
                  <GradientAddCircleIcon
                    onClick={() => setShowStats(true)}
                    className="info-icon"
                  />
                </div>
              </div>
            )}

            <div className="input-group mt-10">
              <div className="ig-header">
                <p>Custom Rules ({rules.length})</p>
                <GradientAddCircleIcon
                  onClick={() => setShowRules(true)}
                  className="info-icon"
                />
              </div>
            </div>

            {false && (
              <div className="input-group mt-10">
                <div className="ig-header">
                  <p>
                    {edit ? "Manage" : "Add"} Players ({selectedPlayers.length})
                  </p>
                  {edit ? (
                    <GradientEditIcon
                      onClick={() => {
                        setShowPlayers(true);
                        setError("");
                      }}
                      className="info-icon"
                    />
                  ) : (
                    <GradientAddCircleIcon
                      onClick={() => {
                        setShowPlayers(true);
                        setError("");
                      }}
                      className="info-icon"
                    />
                  )}
                </div>
              </div>
            )}
            {!scratchMode && !disableChanges && (
              <div className="input-group stats-hint mt-10">
                <div className="ig-header">
                  <p>Stroke Limit</p>
                  <GradientInfoIcon
                    className="info-icon"
                    onClick={() => {
                      setDialog(
                        "By default, players will ring out at a net double bogey (net +2). Choose whether or not players have a higher stroke limit for this tournament."
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <input
                  value={ring}
                  step={1}
                  min={2}
                  max={10}
                  onChange={(e) => {
                    const val = e.target.value;
                    console.log(val);
                    setRing(val);
                  }}
                  type={"number"}
                />
              </div>
            )}
          </div>
          <div className="sub-header">
            <h2 className="ne-header">Team Details</h2>
          </div>
          <div className="ec-box">
            <div className="input-group">
              <div className="ig-header">
                <p>Team Colors</p>
              </div>
              <div className="team-color">
                <input
                  type="color"
                  value={teamOneColor}
                  className="color-input mr-20"
                  onChange={(e) => setTeamOneColor(e.target.value)}
                />
                <input
                  type="color"
                  value={teamTwoColor}
                  className="color-input"
                  onChange={(e) => setTeamTwoColor(e.target.value)}
                />
                {/* <ColorSelector
                  selectedColor={teamOneColor}
                  handleClick={() => setShowPickerOne((current) => !current)}
                  setSelectedColor={setTeamOneColor}
                  teamName={teams[0].name}
                /> */}
                {false && (
                  <ColorSelector
                    selectedColor={teamTwoColor}
                    handleClick={() => setShowPickerTwo((current) => !current)}
                    setSelectedColor={setTeamTwoColor}
                    teamName={teams[1].name}
                  />
                )}
              </div>
            </div>
          </div>
          {/* {courseChanged && edit && (
              <div className="input-group nassau-group">
                <p>Change for all rounds</p>
                <Switch
                  onColor="#21c17c"
                  checked={changeRounds}
                  onChange={() => setChangeRounds((current) => !current)}
                />
              </div>
            )} */}
          {/* <div className="input-group">
                    
                    <div className="ig-header">
                    <p>Matches</p>    
                    <InfoIcon className='info-icon' onClick={() => {
                        // alert('balls')
                        setDialog('Select how matches are determined - by the event admin, by team captains, or randomly.')
                        setShowDialog(true)}} />
                </div>
                <div className="selector-group">
                            <button onClick={() => setMatchups('Admin')} className={matchups === 'Admin' ? 'sg-left sg-active' : "sg-left"}>
                                Admin
                            </button>
                            <button onClick={() => setMatchups('Captains')} className={matchups === 'Captains' ? 'sg-middle sg-active' : "sg-middle"}>
                                Captains
                            </button>
                            <button  onClick={() => setMatchups('Random')} className={matchups === 'Random' ? 'sg-active sg-right' : "sg-right"}>
                                Random
                            </button>
                        </div>
            </div> */}
          {/* {error !== "" && (
            <div className="error-message-row">
              <p>{error}</p>
            </div>
          )} */}
          {edit && (
            <div className="flex-center mb-20">
              <button
                className="default-button black-button"
                onClick={() => setShowTeams(true)}
              >
                Manage Teams
              </button>
            </div>
          )}
          <div ref={scrollRef} className=""></div>
          <div className="flex-center mb-20">
            {!edit ? (
              <button className="default-button" onClick={storeEvent}>
                Create Event
              </button>
            ) : (
              <button className="default-button" onClick={saveChanges}>
                Save Changes
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default CupCreator;
