import React, { useState, useEffect } from "react";
import "./MatchPlayTournaments.css";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";

// Icons

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import Pill from "../../components/Buttons/Pill";
import CourseItem from "../../components/display/CourseItem/CourseItem";
import MatchplayTournamentHome from "./MatchplayTournamentHome";
import MatchPlayTournamentEditor from "./MatchPlayTournamentEditor";
import BackRow from "../../components/display/Rows/BackRow";
import { Link } from "react-router-dom";

function Tournaments() {
  const { currentUser } = useAuth();

  // State
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  useEffect(() => {
    if (currentUser) {
      loadMatchplayTournaments();
    }
  }, [currentUser]);

  async function loadMatchplayTournaments() {
    setLoading(true);
    const leagues = await db
      .collection("tournaments")
      .where("admins", "array-contains", currentUser.uid)
      .get();

    const arr = [];

    for (let i = 0; i < leagues.docs.length; i++) {
      const tournDoc = leagues.docs[i];
      const tournament = tournDoc.data();
      const tournID = tournDoc.id;

      const { tournamentName, imageLink, country, city } = tournament;
      arr.push({
        country,
        city,
        tournamentName,
        tournID,
        imageLink,
      });
    }
    setTournaments(arr);
    setLoading(false);
  }

  return (
    <div className="matchplay-tournaments">
      {loading && <BackdropLoader />}

      {show ? (
        toShow
      ) : (
        <>
          <div className="page-header">
            <h2>My Tournaments</h2>
          </div>
          <div className="sub-header">
            <h3>Quick Links</h3>
          </div>
          <div className="quick-links">
            <Pill
              text={"New Tournament"}
              onClick={() => {
                setToShow(
                  <MatchPlayTournamentEditor
                    goBack={() => setShow(false)}
                    reload={loadMatchplayTournaments}
                  />
                );
                setShow(true);
              }}
            />
          </div>

          <div className="mt-items">
            {tournaments.map((tourn, index) => (
              <Link to={`/tournaments/${tourn.tournID}`}>
                <CourseItem
                  key={tourn.tournID}
                  city={tourn.city}
                  country={tourn.country}
                  courseName={tourn.tournamentName}
                  hideStar
                  image={tourn.imageLink}
                  showCourse={() => {
                    // setToShow(
                    //   <MatchplayTournamentHome
                    //     tournID={tourn.tournID}
                    //     goBack={() => setShow(false)}
                    //     reload={loadMatchplayTournaments}
                    //   />
                    // );
                    // setShow(true);
                  }}
                />
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Tournaments;
