import React, { useState, useEffect } from "react";
import "./PrizeManager.css";
import { admin, db } from "../../firebase";

// Icons
import Close from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// Components
import BackRow from "../display/Rows/BackRow";
import ErrorModal from "../ErrorModal/ErrorModal";
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import Pill from "../Buttons/Pill";
import { Dialog, Tooltip } from "@mui/material";
import Switch from "react-ios-switch/lib/Switch";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";

function returnFormat(fmt) {
  if (fmt === "stable-medal") {
    return "Medal Stableford";
  }
  if (fmt === "two") {
    return "Better Ball - Two to Count";
  }

  if (fmt === "bogey") {
    return "Bogey Plus";
  }

  if (fmt === "bogey++") {
    return "Bogey Double Plus";
  }

  if (fmt === "net-strokeplay") {
    return "Net Strokeplay";
  }
  if (fmt === "team-net-strokeplay") {
    return "Team Net Strokeplay";
  }
  if (fmt === "average-ball") {
    return "Average Ball";
  }
  if (fmt === "worst-ball") {
    return "Worst Ball";
  }
  if (fmt === "best-ball") {
    return "Best Ball";
  }
  if (fmt === "match-play") {
    return "Match Play";
  }
  if (fmt === "ab-match-play") {
    return "AB Match Play";
  }
  if (fmt === "bb-match-play") {
    return "BB Match Play";
  }
  if (fmt === "wb-match-play") {
    return "WB Match Play";
  }
  if (fmt === "stableford") {
    return "Stableford";
  }
  if (fmt === "mod-stableford") {
    return "Modified Stableford";
  }
  if (fmt === "skins-normal") {
    return "Normal Skins";
  }
  if (fmt === "skins-expo") {
    return "Expo Skins";
  }

  return fmt;
}

const formatOptions = [
  {
    label: "Medal",
    value: "medal",
  },
  {
    label: "Net Strokeplay",
    value: "net-strokeplay",
  },
  {
    label: "Stableford",
    value: "stableford",
  },
  // {
  //   value: "mod-stableford",
  //   label: "Modified Stableford",
  // },
  {
    value: "custom",
    label: "Custom",
  },
];

function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function PrizeManager({ goBack = () => {}, eventID = "" }) {
  // State
  const [prizes, setPrizes] = useState([]);
  const [opp, setOPP] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [update, setUpdate] = useState(0);

  const [divMap, setDivMap] = useState({});

  const [error, setError] = useState("");

  const [edit, setEdit] = useState(false);

  const [started, setStarted] = useState(false);

  const [rounds, setRounds] = useState(0);
  const [roundOptions, setRoundOptions] = useState([]);

  const [loading, setLoading] = useState(true);

  const [prizeName, setPrizeName] = useState("");
  const [prizeWinner, setPrizeWinner] = useState("");
  const [cashPrize, setCashPrize] = useState(0);
  const [format, setFormat] = useState("");
  const [position, setPosition] = useState(1);
  const [divisions, setDivisions] = useState([]);
  const [round, setRound] = useState("overall");

  const [changed, setChanged] = useState(false);

  const [selDiv, setSelDiv] = useState("");

  const [toEdit, setToEdit] = useState();
  const [toDelete, setToDelete] = useState();

  useEffect(() => {
    loadPrizes();
  }, []);

  async function loadPrizes() {
    setLoading(true);
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const { rounds } = event;
    const prizes = event.prizes ?? [];
    const divs = event.divisions ?? [];
    const mep = {};
    for (let i = 0; i < divs.length; i++) {
      divs[i].value = divs[i].leaderboardID;
      divs[i].label = divs[i].divisionName;
      mep[divs[i].leaderboardID] = divs[i];
    }
    divs.unshift({
      label: "Overall",
      value: "overall",
    });
    if (event.enableTeams) {
      divs.push({
        label: "Teams",
        value: "teams",
      });
      mep["teams"] = {
        leaderboard: [],
        label: "Teams",
        value: "teams",
        divisionName: "Team Leaderboard",
      };
    }
    const opp = event.opp ?? false;

    const roundArr = [
      {
        label: "Overall",
        value: "overall",
      },
    ];
    for (let i = 0; i < event.rounds.length; i++) {
      roundArr.push({
        label: `${i + 1}`,
        value: `${i}`,
      });
    }

    setDivMap(mep);
    setOPP(event.opp ?? false);
    setDivisions(divs);
    setRounds(event.rounds.length ?? 0);
    setRoundOptions(roundArr);
    setStarted(event.roundIDs.length > 0);
    setSelDiv("overall");

    const { roundIDs } = event;

    const winnerIDs = [];

    // Look for winners
    if (event.roundIDs.length > 0) {
      // Round refs ready
      const roundRefs = roundIDs.map((roundID) =>
        db.collection("rounds").doc(roundID).get()
      );
      const roundDocs = await Promise.all(roundRefs);

      for (let i = 0; i < prizes.length; i++) {
        const prize = prizes[i];
        // console.log(prize);
        // console.log(i);
        const { leaderboardID, format } = prize;
        if (format === "custom") {
          continue;
        }

        let round = prize.round ?? "overall";
        let position = prize.position;
        if (typeof position === "string") {
          position = parseInt(position);
        }
        let roundID = "";
        // If overall, get last roundid
        if (round === "overall") {
          round = roundIDs.length - 1;
        }
        roundID = roundIDs[round];

        // console.log(round, roundID);
        const roundDoc = roundDocs[round];
        const roundData = roundDoc.data();

        if (format === "teams") {
          const teamLeaderboard = roundData.leaderboard;
          if (!roundData.enableTeams || teamLeaderboard.length === 0) {
            continue;
          }

          const winners = teamLeaderboard[0];
          const { playerIDs } = winners;
          const nameArray = [];

          for (let i = 0; i < round.leaderboard.length; i++) {
            const row = round.leaderboard[i];
            if (playerIDs.includes(row.playerID)) {
              nameArray.push(row.lastName);
            }
          }
          let name = "";
          for (let i = 0; i < nameArray.length; i++) {
            if (i === nameArray.length - 1) {
              name += nameArray[i];
            } else {
              name += `${nameArray[i]} / `;
            }
          }

          winners.name = name;
          prize[i].winner = winners;

          continue;
        }
        let leaderboard;

        // Get overall leaderboard or division leaderboard
        if (leaderboardID === "overall") {
          leaderboard = roundData.leaderboard;
        } else {
          leaderboard = roundData[leaderboardID].leaderboard;
        }
        const sameFormat = format === roundData.format;

        let stable = "asc";

        if (sameFormat) {
          if (opp) {
            const pos = position - 1;
            let found = false;
            for (let j = position - 1; j < leaderboard.length; j++) {
              if (found) {
                continue;
              }
              const player = leaderboard[j];
              if (!winnerIDs.includes(player.playerID)) {
                prizes[i].winner = player;
                winnerIDs.push(player.playerID);
                found = true;
              }
            }
          } else {
            const winner = leaderboard[position - 1];
            prizes[i].winner = winner;
            winnerIDs.push(winner.playerID);
          }
        } else {
          let orderBy = "";
          if (format === "mod-stableford") {
            orderBy = "totalModPoints";
            stable = "desc";
          } else if (format === "stableford") {
            orderBy = "totalPoints";
            stable = "desc";
          } else if (format === "medal") {
            orderBy = "currentGrossScore";
          } else {
            orderBy = "currentScore";
          }
          if (leaderboardID === "overall") {
            const winnerDoc = await db
              .collection("rounds")
              .doc(roundID)
              .collection("scores")
              .orderBy(orderBy, stable)
              .limit(10)
              .get();
            let found = false;
            let x = position - 1;
            if (opp) {
              while (found === false) {
                if (winnerIDs.includes(winnerDoc.docs[x].id)) {
                  x += 1;
                } else {
                  found = true;
                }
              }
            }
            let winner = winnerDoc.docs[x];
            const winnerID = winner.id;
            const roundLeaderboard = roundData.leaderboard;
            for (let j = 0; j < roundLeaderboard.length; j++) {
              const row = roundLeaderboard[j];
              if (row.playerID === winnerID) {
                // console.log(row);
                prizes[i].winner = row;
                winnerIDs.push(winnerID);
              }
            }
          } else {
            // console.log(winnerIDs);
            // console.log(i);
            const playerIDs = leaderboard.map((row) => row.playerID);
            const scores = await db
              .collection("rounds")
              .doc(roundID)
              .collection("scores")
              .orderBy(orderBy, stable)
              .get();
            // console.log(scores.docs.length);
            let found = false;
            let x = position - 1;
            for (let j = 0; j < scores.docs.length; j++) {
              if (found) {
                continue;
              }

              const scoreDoc = scores.docs[j];
              const playerID = scoreDoc.id;
              if (j < x) {
                continue;
              }
              if (!playerIDs.includes(playerID)) {
                continue;
              }
              if (opp) {
                if (winnerIDs.includes(playerID)) {
                  continue;
                }
              }
              found = true;
              //   console.log("got past");
              //   console.log(playerID);
              for (let x = 0; x < roundData.leaderboard.length; x++) {
                const row = roundData.leaderboard[x];
                if (row.playerID === playerID) {
                  //   console.log(row);
                  prizes[i].winner = row;
                }
              }
              winnerIDs.push(playerID);
            }
          }
        }
      }

      for (let i = 0; i < roundDocs.length; i++) {
        const roundDoc = roundDocs[i];
        const roundID = roundDoc.id;
        const round = roundDoc.data();
        const { ctpEnabled, ldEnabled, ctpMap, ldMap } = round;

        if (ctpEnabled) {
          let found = false;
          const sorted = Object.entries(ctpMap).sort((a, b) => a[1] - b[1]);
          for (let j = 0; j < sorted.length; j++) {
            const playerID = sorted[j][0];
            if (found) {
              continue;
            }

            if (opp) {
              if (winnerIDs.includes(playerID)) {
                continue;
              }
            }
            let winner;
            for (let j = 0; j < round.leaderboard.length; j++) {
              const row = round.leaderboard[j];
              if (row.playerID === playerID) {
                winner = row;
              }
            }
            prizes.push({
              prizeName: `Closest to the pin - Round ${i + 1}`,
              prizeID: `ctp${i}`,
              winner,
              extra: true,
            });
            found = true;
          }
        }

        if (ldEnabled) {
          let found = false;
          const sorted = Object.entries(ldMap).sort((a, b) => b[1] - a[1]);

          for (let j = 0; j < sorted.length; j++) {
            const playerID = sorted[j][0];
            if (found) {
              continue;
            }
            if (opp) {
              if (winnerIDs.includes(playerID)) {
                continue;
              }
            }
            let winner;
            for (let j = 0; j < round.leaderboard.length; j++) {
              const row = round.leaderboard[j];
              if (row.playerID === playerID) {
                winner = row;
              }
            }
            prizes.push({
              prizeName: `Longest Drive - Round ${i + 1}`,
              prizeID: `ld${i}`,
              winner,
              extra: true,
            });
            found = true;
          }
        }
      }

      console.log(prizes);
      setPrizes(prizes);
      setLoading(false);
    } else {
      setPrizes(prizes);
      setLoading(false);
    }
  }

  async function savePrize() {
    setLoading(true);
    setShowDialog(false);
    const newPrize = {
      prizeName,
      leaderboardID: selDiv,
      format,
      position,
      round,
      prizeID: makeID(10),
      winner: { name: prizeWinner },
      cashPrize,
    };
    try {
      setPrizes((current) => [...current, newPrize]);
      await db
        .collection("events")
        .doc(eventID)
        .update({
          prizes: admin.firestore.FieldValue.arrayUnion(newPrize),
        });
      setPrizeName("");
      setCashPrize(0);
      setPrizeWinner("");
      setRound("overall");
      setPosition(1);
      setSelDiv("overall");
      setFormat("");
      toast.success("Prize successfuly added", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function editPrize() {
    setLoading(true);
    setShowDialog(false);
    console.log(toEdit);
    const newPrize = {
      prizeName,
      round,
      leaderboardID: selDiv,
      format,
      position,
      prizeID: toEdit.prizeID,
      winner: { name: prizeWinner },
      cashPrize,
    };

    console.log(newPrize);

    try {
      const eventDoc = await db.collection("events").doc(eventID).get();
      const event = eventDoc.data();
      const oldPrizes = event.prizes;

      for (let i = 0; i < oldPrizes.length; i++) {
        if (oldPrizes[i].prizeID === toEdit.prizeID) {
          oldPrizes[i] = newPrize;
        }
      }

      setPrizes((current) => {
        for (let i = 0; i < current.length; i++) {
          if (current[i].prizeID === toEdit.prizeID) {
            current[i] = newPrize;
          }
        }
        return current;
      });
      await db.collection("events").doc(eventID).update({
        prizes: oldPrizes,
      });
      setPrizeName("");
      setCashPrize(0);
      setPrizeWinner("");
      setPosition(1);
      setRound("overall");
      setSelDiv("overall");
      setFormat("");
      setEdit(false);
      setToEdit();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function deletePrize() {
    setLoading(true);
    setShowDelete(false);
    try {
      const newPrizes = prizes.filter((p) => p.prizeID !== toDelete.prizeID);
      setPrizes((current) => {
        const filtered = current.filter(
          (prize) => prize.prizeID !== toDelete.prizeID
        );
        // newPrizes = filtered;
        return filtered;
      });
      await db.collection("events").doc(eventID).update({
        prizes: newPrizes,
      });
      toast.success("Prize successfully deleted", {
        icon: successIco,
      });
      setToDelete();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  function changeOPP(bool) {
    db.collection("events").doc(eventID).update({
      opp: bool,
    });
    if (started) {
      loadPrizes();
    }
  }

  async function saveRankChanges() {
    setLoading(true);
    try {
      await db.collection("events").doc(eventID).update({
        prizes,
      });
      setChanged(false);
      if (started) {
        loadPrizes();
      }
      toast.success("Changes successfully saved", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="prize-manager">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog
        className="mpa-dia pm-dia"
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <div className="pm-add pd-20">
          <div className="page-header">
            <h2>{edit ? "Edit Prize" : "New Prize"}</h2>
            <Close
              className={""}
              onClick={() => {
                setPrizeName("");
                setPosition(1);
                setRound("overall");
                setSelDiv("overall");
                setFormat("");
                setShowDialog(false);
              }}
            />
          </div>
          <div className="ec-box">
            <div className="input-group">
              <p>Prize Name</p>
              <input
                type="text"
                placeholder="Enter prize name..."
                value={prizeName}
                onChange={(e) => setPrizeName(e.target.value)}
                className="default-input"
              />
            </div>
            {selDiv === "overall" && (
              <div className="input-group">
                <p>Prize Format</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={formatOptions}
                  value={format}
                  onChange={(e) => {
                    setFormat(e.value);
                  }}
                />
              </div>
            )}
            <div className="input-group">
              <p>Round</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                options={roundOptions}
                value={round}
                onChange={(e) => {
                  setRound(e.value);
                }}
              />
            </div>
            {divisions.length > 1 && (
              <div className="input-group">
                <p>Division</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={divisions}
                  value={selDiv}
                  onChange={(e) => {
                    setSelDiv(e.value);
                  }}
                />
              </div>
            )}
            {format === "custom" && (
              <div className="input-group">
                <p>Winner</p>
                <input
                  type="text"
                  placeholder="Enter winner name..."
                  value={prizeWinner}
                  onChange={(e) => setPrizeWinner(e.target.value)}
                  className="default-input"
                />
              </div>
            )}
            <div className="input-group">
              <p>Position</p>
              <input
                type="number"
                min={1}
                placeholder="Enter prize position..."
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Cash Prize</p>
              <input
                type="number"
                min={0}
                placeholder="Enter cash prize..."
                value={cashPrize}
                onChange={(e) => setCashPrize(e.target.value)}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center">
            <button
              onClick={edit ? editPrize : savePrize}
              disabled={
                prizeName === "" || (format === "" && selDiv !== "teams")
              }
              className="default-button"
            >
              {edit ? "Save Changes" : "Save"}
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
        <div className="cs-delete">
          <div className="page-header">
            <h2>Delete Prize</h2>
            <Close
              onClick={() => setShowDelete(false)}
              className="icon-cursor"
            />
          </div>
          <p>Are you sure you want to remove this prize?</p>
          <div className="flex-center">
            <button onClick={deletePrize} className="delete-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader text="Prizes Manager" />
      <div className="quick-links">
        <div className="input-group mt-0 nassau-group mr-5v">
          <p>One Prize Per Player</p>
          <Switch
            onColor="#1e7a69"
            checked={opp}
            className={opp ? "scoring-switch switch-on" : "scoring-switch"}
            onChange={() =>
              setOPP((current) => {
                changeOPP(!current);
                return !current;
              })
            }
          />
        </div>
        <Pill
          text={"New Prize"}
          onClick={() => {
            setPrizeName("");
            setPosition(1);
            setRound("overall");
            setSelDiv("overall");
            setFormat("");
            setShowDialog(true);
          }}
        />
        {changed && (
          <div style={{ marginLeft: "5vw" }} className="flex-center">
            <button onClick={saveRankChanges} className="default-button">
              Save Changes
            </button>
          </div>
        )}
      </div>
      {prizes.length === 0 && (
        <div className="no-msg">
          <p>No items to display</p>
        </div>
      )}
      {prizes.length > 0 && (
        <div className="pm-prizes pd-20 flex-wrapper">
          {prizes.map((prize, index) => {
            // console.log(prize);
            const { leaderboardID } = prize;
            let divName = "";
            // console.log(prize.winner);
            const round = prize.round ?? "overall";
            if (!prize.extra) {
              if (leaderboardID === "overall") {
              } else {
                divName = divMap[leaderboardID].divisionName;
              }
            }

            return (
              <div key={prize.prizeID} className="prize-item-container">
                <div className="prize-item">
                  <div className="pi-left">
                    <h2>{prize.prizeName}</h2>
                    <p>
                      {returnFormat(prize.format)}
                      {round !== "overall" && ` - Round ${parseInt(round) + 1}`}
                    </p>
                    {divName && <h5>{divName}</h5>}
                  </div>
                  <div className="pi-right">
                    <Tooltip title="Edit Prize">
                      <EditIcon
                        className="dg-icon hgb-ico icon-cursor"
                        // onClick={editAction}
                        onClick={() => {
                          setToEdit(prize);
                          if (prize.winner) {
                            setPrizeWinner(prize.winner.name);
                          }
                          setCashPrize(prize.cashPrize ?? 0);
                          setRound(prize.round ?? "overall");
                          setPrizeName(prize.prizeName);
                          setFormat(prize.format);
                          setSelDiv(prize.leaderboardID);
                          setPosition(prize.position);
                          setEdit(true);
                          setShowDialog(true);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Delete Prize">
                      <DeleteForeverIcon
                        className="red-icon icon-cursor"
                        onClick={() => {
                          setToDelete(prize);
                          setShowDelete(true);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                {opp && prize.extra && <div style={{ width: "10vw" }}></div>}
                {opp && !prize.extra && (
                  <div className="pic-arrows">
                    {index !== prizes.length - 1 && (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setPrizes((current) => {
                            const overallTemp = [...current];
                            const tempOne = overallTemp[index];
                            const tempTwo = overallTemp[index + 1];
                            current[index] = tempTwo;
                            current[index + 1] = tempOne;
                            return current;
                          });
                          setChanged(true);
                          setUpdate((current) => current + 1);
                        }}
                        className="icon-cursor red-icon mr-10"
                      />
                    )}
                    {index !== 0 && (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setPrizes((current) => {
                            const overallTemp = [...current];
                            const tempOne = overallTemp[index];
                            const tempTwo = overallTemp[index - 1];
                            current[index] = tempTwo;
                            current[index - 1] = tempOne;

                            return current;
                          });
                          setChanged(true);

                          setUpdate((current) => current + 1);
                        }}
                        className="icon-cursor dg-icon"
                      />
                    )}
                  </div>
                )}
                {prize.winner && (
                  <div className="pi-winner">
                    <p>
                      Winner: <span>{prize.winner.name}</span>
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default PrizeManager;
