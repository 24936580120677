import React, { useState, useRef, useEffect } from "react";
import "./ChatPage.css";

import { useNavigate } from "react-router-dom";

import { db, admin } from "../../../firebase";
import { Avatar } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatMessage from "../ChatMessage/ChatMessage";
import moment from "moment";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";

import PlayerItem from "../../display/PlayerItem/PlayerItem";

import { useAuth } from "../../../contexts/AuthContext";
import Menu from "@mui/material/Menu";
import GroupChatMessage from "../ChatMessage/GroupChatMessage";
import ChatSettings from "../ChatSettings/ChatSettings";
import { Oval } from "react-loader-spinner";

function ChatPage({
  docID = "",
  userID,
  newChat = false,
  goBack,
  adminIDs = [],
  chatType = "",
  isGroupChat = false,
  players = [],
  message = "",
  chatName,
  groupImage,
  groupID,
  hide = () => {},
}) {
  // console.log(docID);

  const defs = {
    imageLink: "",
    userName: "",
    status: "",
  };

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { currentUser, userDetails } = useAuth();

  const [showSettings, setShowSettings] = useState(false);

  const [details, setDetails] = useState(defs);
  const [chatID, setChatID] = useState(docID);
  const [status, setStatus] = useState("");
  const [lastSeen, setLastSeen] = useState("");
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [player, setPlayer] = useState();
  // <PlayerProfile goBack={() => setShowPlayer(false)} uid={userID} />
  const [showPlayer, setShowPlayer] = useState(false);
  const [showLeague, setShowLeague] = useState(false);
  const [showPlayers, setShowPlayers] = useState(false);

  const [playerIDs, setPlayerIDs] = useState();

  const [blockedBy, setBlockedBy] = useState(false);
  const [blocked, setBlocked] = useState(false);

  const [chatInput, setChatInput] = useState(message);

  const [unreadIDs, setUnreadIDs] = useState([]);

  const [disabled, setDisabled] = useState(false);

  const [adminChat, setAdminChat] = useState(false);

  useEffect(() => {
    if (userDetails) {
      setDisabled(userDetails.status === "disabled");
    }
  }, [userDetails]);

  useEffect(() => {
    scrollRef.current &&
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
  }, [messages]);

  useEffect(() => {
    if (userID === "admin") {
      setAdminChat(true);
    }
    // console.log(docID);
    if (!isGroupChat && userID !== "admin") {
      loadDetails();
      getStatus();
    }
    if (!isGroupChat) {
      loadDetails();
    } else {
      loadPlayerIDs();
    }

    if (!newChat) {
      loadMessages();
    }
  }, []);

  useEffect(() => {
    if (currentUser && userID !== "admin") {
      // checkBlockage();
    }
  }, [currentUser]);

  const inputRef = useRef();
  const scrollRef = useRef();

  async function loadDetails() {
    // console.log(userID)
    if (userID === "admin") {
      setDetails({
        userName: "UGA HQ",
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/images%2Fgeneral%2FUntitled%20design%20(2).png?alt=media&token=9db74cbc-c820-499e-add1-46e578f0d7e2",
      });
    } else {
      const userDoc = await db.collection("users").doc(userID).get();
      const user = userDoc.data();
      const userName = `${user.firstName} ${user.lastName}`;
      const { profileImage } = user;

      setDetails({
        userName,
        imageLink: profileImage,
      });
    }
  }

  async function loadPlayerIDs() {
    const chatDoc = await db.collection("chats").doc(chatID).get();
    const chat = chatDoc.data();
    const { playerIDs } = chat;
    setPlayerIDs(playerIDs);
  }

  async function getStatus() {
    db.collection("status")
      .doc(userID)
      .onSnapshot((snap) => {
        if (snap.data() === undefined) {
          return;
        }
        setStatus(snap.data().state);
        const lsDate = new Date(snap.data().last_changed.toDate());
        const now = moment();
        const lsMoment = moment(lsDate);
        let diff = `${now.diff(lsMoment, "minutes")} minutes ago`;
        if (now.diff(lsMoment, "minutes") === 1) {
          diff = `${now.diff(lsMoment, "minutes")} minute ago`;
        }
        if (now.diff(lsMoment, "minutes") > 59) {
          if (now.diff(lsMoment, "hours") === 1) {
            diff = `${now.diff(lsMoment, "hours")} hour ago`;
          } else {
            diff = `${now.diff(lsMoment, "hours")} hours ago`;
          }
        }
        if (now.diff(lsMoment, "hours") > 23) {
          if (now.diff(lsMoment, "days") === 1) {
            diff = `${now.diff(lsMoment, "days")} day ago`;
          } else {
            diff = `${now.diff(lsMoment, "days")} days ago`;
          }
        }
        if (now.diff(lsMoment, "days") > 30) {
          if (now.diff(lsMoment, "months") > 1) {
            diff = `${now.diff(lsMoment, "months")} months ago`;
          } else {
            diff = `${now.diff(lsMoment, "months")} month ago`;
          }
        }
        // console.log(diff)
        // if (now.diff(lsMoment, 'hours') > 24)
        setLastSeen(diff);
        setUpdate((current) => current + 1);
      });
  }

  async function createChat() {
    // return console.log(inputRef.current.value);
    messages.push({
      text: inputRef.current.value,
      sentAt: new Date(),
      sender: currentUser.uid,
      receiver: userID,
      read: false,
    });
    const chatDoc = await db.collection("chats").add({
      userIDs: [userID, currentUser.uid],
      isGroupChat: false,
      lastMessage: {
        text: inputRef.current.value,
        sentAt: new Date(),
        sender: currentUser.uid,
        receiver: userID,
        read: false,
      },
    });
    await chatDoc.collection("messages").add({
      text: inputRef.current.value,
      sentAt: new Date(),
      sender: currentUser.uid,
      receiver: userID,
      read: false,
    });
    inputRef.current.value = "";
    setChatID(chatDoc.id);
    setUpdate((current) => current + 1);
    loadMessages(chatDoc.id);
  }

  async function sendMessage() {
    const text = inputRef.current.value;
    // console.log(text);

    if (messages.length === 0) {
      return createChat();
    }

    inputRef.current.value = "";

    messages.push({
      text,
      sentAt: new Date(),
      sender: currentUser.uid,
      receiver: userID,
      read: false,
    });

    await db.collection("chats").doc(chatID).collection("messages").add({
      text,
      sentAt: new Date(),
      sender: currentUser.uid,
      receiver: userID,
      read: false,
    });

    await db
      .collection("chats")
      .doc(chatID)
      .update({
        lastMessage: {
          text,
          sentAt: new Date(),
          sender: currentUser.uid,
          receiver: userID,
          read: false,
        },
      });
  }

  async function sendGroupMessage() {
    const text = chatInput;

    messages.push({
      text,
      sentAt: new Date(),
      read: [],
      sender: currentUser.uid,

      unread: playerIDs,
    });

    await db
      .collection("chats")
      .doc(chatID)
      .collection("messages")
      .add({
        text,
        sentAt: new Date(),
        sender: currentUser.uid,
        read: [currentUser.uid],
      });

    await db
      .collection("chats")
      .doc(chatID)
      .update({
        lastMessage: {
          text,
          sentAt: new Date(),
          sender: currentUser.uid,
          read: [],
          unread: playerIDs,
        },
      });

    setChatInput("");
  }

  async function loadMessages(id = "") {
    const toUse = id === "" ? chatID : id;
    if (toUse === "") {
      return setLoading(false);
    }
    const playerMap = {};
    const refs = players.map((player) =>
      db.collection("users").doc(player.userID).get()
    );
    const playerDocs = await Promise.all(refs);
    playerDocs.forEach((playerDoc) => {
      if (!playerDoc.exists) {
        return;
      }
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName, handicap, profileImage } = player;
      playerMap[playerID] = {
        playerID,
        name: adminIDs.includes(playerID)
          ? `${firstName} ${lastName} (Admin)`
          : `${firstName} ${lastName}`,
        handicap,
        profileImage,
      };
    });
    db.collection("chats")
      .doc(toUse)
      .collection("messages")
      .orderBy("sentAt")
      .onSnapshot((snap) => {
        const arr = [];
        const unArr = [];
        console.log(snap.docs.length);
        for (let i = 0; i < snap.docs.length; i++) {
          const msg = snap.docs[i].data();
          const { text, sender, receiver, read } = msg;
          const sentAt = new Date(msg.sentAt.toDate()).toLocaleTimeString(
            "en-gb",
            { timeStyle: "short" }
          );
          // console.log(sender, receiver);
          const sent = sender === currentUser.uid;
          if (isGroupChat && !read.includes(currentUser.uid)) {
            unArr.push(snap.docs[i].id);
          }
          if (isGroupChat) {
            arr.push({
              receiver: receiver ?? [],
              sender,
              text,
              time: sentAt,
              sent,
              docID: snap.docs[i].id,
              read,
              name: playerMap[sender] ? playerMap[sender].name : "Admin",
            });
          } else {
            arr.push({
              receiver: receiver ?? [],
              sender,
              text,
              time: sentAt,
              sent,
              docID: snap.docs[i].id,
              read,
            });
          }
        }
        console.log(arr);
        // console.log("new message");
        setMessages(arr);
        setUnreadIDs(unArr);
        if (isGroupChat) {
          markGroupAsRead();
        } else {
          console.log("marking as read");
          setTimeout(() => {
            markAsRead();
          }, 1000);
          // markAsRead();
        }
        setLoading(false);
      });
  }

  async function markAsRead() {
    if (!chatID) {
      return;
    }
    const chatDoc = await db.collection("chats").doc(chatID).get();
    const chat = chatDoc.data();
    const { lastMessage } = chat;
    if (lastMessage.receiver === currentUser.uid) {
      lastMessage.read = true;
    }
    await chatDoc.ref.update({
      lastMessage,
    });

    const docs = await chatDoc.ref
      .collection("messages")
      .where("read", "==", false)
      .where("receiver", "==", currentUser.uid)
      .get();
    docs.forEach((doc) => {
      doc.ref.update({
        read: true,
      });
    });
  }

  async function markGroupAsRead() {
    const chatDoc = await db.collection("chats").doc(chatID).get();
    const chat = chatDoc.data();
    const { lastMessage } = chat;
    if (!lastMessage.read.includes(currentUser.uid)) {
      lastMessage.read.push(currentUser.uid);
    }
    await chatDoc.ref.update({
      lastMessage,
    });

    for (let i = 0; i < unreadIDs.length; i++) {
      await db
        .collection("chats")
        .doc(chatID)
        .collection("messages")
        .doc(unreadIDs[i])
        .update({
          read: admin.firestore.FieldValue.arrayUnion(currentUser.uid),
        });
    }

    setUnreadIDs([]);
  }

  async function checkBlockage() {
    const userDoc = await db.collection("users").doc(userID).get();
    const user = userDoc.data();
    const userBlocked = user.blocked ?? [];
    // console.log(userDetails);
    if (userBlocked.includes(currentUser.uid)) {
      setBlockedBy(true);
    }

    if (userDetails.blocked.includes(userID)) {
      setBlocked(true);
    }
  }

  async function blockUser() {
    await db
      .collection("users")
      .doc(currentUser.uid)
      .update({
        blocked: admin.firestore.FieldValue.arrayUnion(userID),
      });
    setBlocked(true);
  }

  async function unBlockUser() {
    await db
      .collection("users")
      .doc(currentUser.uid)
      .update({
        blocked: admin.firestore.FieldValue.arrayRemove(userID),
      });
    setBlocked(false);
  }

  return (
    <>
      <div className="chat-page">
        {showSettings ? (
          <ChatSettings goBack={() => setShowSettings(false)} />
        ) : (
          <>
            <div
              style={{ borderBottom: "1px solid #edefef" }}
              className="cp-header"
            >
              <div className="cph-left">
                <ArrowBack className="back-arrow" onClick={goBack} />
                <Avatar
                  onClick={() => {}}
                  className="cp-ava"
                  src={
                    isGroupChat
                      ? groupImage
                      : details.imageLink ??
                        "https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/images%2Fgeneral%2FUntitled%20design%20(2).png?alt=media&token=9db74cbc-c820-499e-add1-46e578f0d7e2"
                  }
                  alt={details.userName}
                />
                <div className="cp-deets">
                  <h1
                    className="ne-header"
                    onClick={() => {
                      if (isGroupChat) {
                        setShowLeague(true);
                      } else if (userID !== "admin") {
                        setShowPlayer(true);
                      }
                    }}
                    style={{ marginBottom: "2.5px" }}
                  >
                    {isGroupChat
                      ? chatName
                      : blocked
                      ? `${details.userName} (Blocked)`
                      : blockedBy
                      ? `${details.userName} (Blocked you)`
                      : details.userName}
                  </h1>
                  {!isGroupChat && userID !== "admin" && (
                    <p onClick={() => setShowPlayer(true)}>
                      {status === "online"
                        ? "Online"
                        : `Last Seen: ${lastSeen}`}
                    </p>
                  )}
                  {adminChat && <p>Support Chat</p>}
                  {isGroupChat && (
                    <p
                      onClick={() => setShowPlayers(true)}
                      className="player-pee"
                    >
                      {players.map((player, index) => {
                        if (index === players.length - 1) {
                          return player.name;
                        } else {
                          return `${player.name}, `;
                        }
                      })}
                    </p>
                  )}
                </div>
              </div>
              <div className="cph-right">
                {userID !== "admin" && (
                  <MoreVertIcon
                    className="cph-ico"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  />
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {isGroupChat && (
                    <div
                      onClick={() => {
                        setShowSettings(true);
                        handleClose();
                      }}
                      className="rp-mi"
                    >
                      <p>Settings</p>
                    </div>
                  )}
                  {!blocked && !isGroupChat && (
                    <div
                      onClick={() => {
                        blockUser();
                        handleClose();
                      }}
                      className="rp-mi"
                    >
                      <p>Block User</p>
                    </div>
                  )}
                  {blocked && (
                    <div
                      onClick={() => {
                        unBlockUser();
                        handleClose();
                      }}
                      className="rp-mi"
                    >
                      <p>Unblock User</p>
                    </div>
                  )}
                  {!isGroupChat && (
                    <div
                      onClick={() => {
                        handleClose();
                        navigate("/support", {
                          state: {
                            type: {
                              value: "spam-abuse",
                              label:
                                "This person keeps messaging me and I would like it to stop.",
                            },
                          },
                        });
                        hide();
                      }}
                      className="rp-mi"
                    >
                      <p>Report Spam/Abuse</p>
                    </div>
                  )}
                </Menu>
              </div>
            </div>
            <div className="cp-msgs">
              {loading ? (
                <>
                  <div
                    className="flex-center loading"
                    style={{ paddingTop: "50px" }}
                  >
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : (
                <>
                  {messages.map((msg) => {
                    if (isGroupChat) {
                      return (
                        <GroupChatMessage
                          unread={!msg.read}
                          message={msg.text}
                          sent={msg.sent}
                          key={msg.docID}
                          time={msg.time}
                          name={msg.name}
                        />
                      );
                    } else {
                      return (
                        <ChatMessage
                          unread={!msg.read}
                          message={msg.text}
                          sent={msg.sent}
                          key={msg.docID}
                          time={msg.time}
                        />
                      );
                    }
                  })}
                  <div ref={scrollRef}></div>
                  {messages.length === 0 && (
                    <div className="no-msgs">
                      <span>Send a message to get the chat going</span>
                    </div>
                  )}
                  {blocked ? (
                    <div className="blocked">
                      <span>You have blocked this user</span>
                    </div>
                  ) : blockedBy ? (
                    <div className="blocked">
                      <span>This user has blocked you</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <div className="cp-input">
              <div className="cpii">
                <textarea
                  disabled={blocked || blockedBy || disabled}
                  ref={inputRef}
                  rows={1}
                  onChange={(e) => setChatInput(e.target.value)}
                  value={chatInput}
                  placeholder={"Your message"}
                />
              </div>
              <button
                onClick={() => {
                  if (isGroupChat) {
                    sendGroupMessage();
                  } else {
                    sendMessage();
                  }
                }}
                className="cpi-button"
              >
                Send
                {/* <SendIcon style={{ fill: "white" }} /> */}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ChatPage;
