import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Components
import BackdropLoader from "../Loaders/BackdropLoader";
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import { Avatar, Dialog } from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

function TeamSubmissions({ eventID = "", teams = [], goBack = () => {} }) {
  // Admin

  // State
  const [loading, setLoading] = useState(true);
  const [rounds, setRounds] = useState([]);

  const [rdOpts, setRDOpts] = useState([]);
  const [selRd, setSelRd] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [delID, setDelID] = useState(0);
  const [up, setUp] = useState("");
  function update() {
    setUp((c) => c + 1);
  }

  // UE
  useEffect(() => {
    loadDetails();
  }, []);

  // Funcs
  async function loadDetails() {
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const { rounds } = event;

    const rdops = rounds.map((rd, i) => {
      const toRet = {
        value: i,
        label: `Round ${i + 1}`,
      };
      return toRet;
    });

    setRDOpts(rdops);
    setRounds(rounds);
    setLoading(false);
  }

  async function deleteSubs() {
    setShowDelete(false);
    setLoading(true);
    try {
      const eventDoc = await db.collection("teamEvents").doc(eventID).get();
      const event = eventDoc.data();

      const { rounds } = event;
      delete rounds[selRd].teamSubmissions[delID];

      await eventDoc.ref.update({
        rounds,
      });
      setDelID("");
      loadDetails();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="team-submissions">
      {loading && <BackdropLoader />}

      <BackRow action={goBack} />

      <PageHeader text="Team Submissions" />

      <Dialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        className="ts-del-dia"
      >
        <div className="tsd-ctt pd-20">
          <PageHeader
            text="Delete Submissions"
            showClose
            close={() => setShowDelete(false)}
          />

          <div className="del-text pd-20">
            <p> Are you sure you want to remove these submissions?</p>
          </div>

          <div className="flex flex-center mt-20 mb-20">
            <button onClick={deleteSubs} className="default-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      {rdOpts.length > 1 && (
        <div className="input-group pl-20">
          <p>Select Round</p>
          <Dropdown
            arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
            arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
            value={selRd}
            options={rdOpts}
            onChange={(e) => {
              setSelRd(e.value);
            }}
          />
        </div>
      )}

      <div className="ltm-teams">
        {rounds.length > 0 &&
          teams.map((team, i) => {
            const teamID = team.teamID;

            const rd = rounds[selRd];
            const rdTeam = rd.teamSubmissions[teamID] ?? {};
            const subs = rdTeam.players ?? [];
            // console.log(rdTeam);
            const format = rd.format;

            return (
              <div className="ltm-team pd-20" key={team.teamID}>
                <div className="ltm-team-top flex-align-center">
                  <Avatar alt={team.teamName} src={team.imageLink} />
                  <h3 className="mr-5v ml-20">
                    {team.teamName}{" "}
                    {rdTeam.course && `(Course: ${rdTeam.course.label})`}
                  </h3>
                  {subs.length > 0 && (
                    <DeleteForeverIcon
                      onClick={() => {
                        setDelID(teamID);
                        update();
                        setShowDelete(true);
                      }}
                      className="icon-cursor red-icon-bg ml-20"
                    />
                  )}
                </div>
                <div className="ltm-membes pd-20 flex-wrap">
                  {format === "Matchplay" &&
                    subs.map((player, index2) => {
                      return (
                        <div className="ts-pl" key={player.playerID}>
                          <div className="tsp-left">
                            <p>{index2 + 1}.</p>
                          </div>

                          <PlayerItem
                            key={player.playerID}
                            name={player.name}
                            noLink
                            showAdd
                            showProfile={() => {}}
                            playerID={player.playerID}
                            hideProfile
                            hideEdit
                            hideIcons
                            //   hideIcons
                            hideImage
                            hideHCP
                            removeAction={() => {}}
                          />
                        </div>
                      );
                    })}
                  {format === "Fourball" &&
                    subs.map((team, index2) => {
                      const { players } = team;

                      return (
                        <div className="ts-pl ts-team" key={`${index2}team`}>
                          <div className="tsp-left">
                            <p>{index2 + 1}.</p>
                          </div>

                          <div className="tsp-right">
                            <PlayerItem
                              key={players[0].playerID}
                              name={players[0].name}
                              noLink
                              showAdd
                              showProfile={() => {}}
                              playerID={players[0].playerID}
                              hideProfile
                              hideEdit
                              hideIcons
                              //   hideIcons
                              hideImage
                              hideHCP
                              removeAction={() => {}}
                            />
                            <PlayerItem
                              key={players[1].playerID}
                              name={players[1].name}
                              noLink
                              showAdd
                              showProfile={() => {}}
                              playerID={players[1].playerID}
                              hideProfile
                              hideEdit
                              hideIcons
                              //   hideIcons
                              hideImage
                              hideHCP
                              removeAction={() => {}}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TeamSubmissions;
