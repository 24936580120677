import React, { useState, useEffect } from "react";
import "./Home.css";

import { useMenuTheme } from "../../contexts/MenuContext";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Icons

// Components
import Pill from "../../components/Buttons/Pill";
import UserImport from "../../components/UserImport/UserImport";
import EventCreator from "../../components/Events/EventCreator/EventCreator";
import EventHome from "../../components/Events/EventCreator/EventHome";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import MatchPlayTournamentEditor from "../MatchplayTournaments/MatchPlayTournamentEditor";
import HomeEvents from "./HomeEvents";
import HomeNotifications from "./HomeNotifications";
import AddedUsers from "../../components/AddedUsers/AddedUsers";
import HomeActiveEvents from "./HomeActiveEvents";
import HomePastEvents from "./HomePastEvents";
import HomeLeagueTables from "./HomeLeagueTables";
import LeagueCreator from "../../components/Leagues/LeagueCreator/LeagueCreator";
import CupCreator from "../../components/CupCreator/CupCreator";
import NewContest from "../Contests/NewContest";
import ClubHome from "../Clubs/ClubHome";
import PageHeader from "../../components/display/Rows/PageHeader";

function Home() {
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const navigate = useNavigate();
  const [clubMode, setClubMode] = useState();
  const [update, setUpdate] = useState(0);

  const clicked = useMenuTheme();

  const { portalSettings, currentUser, userDetails } = useAuth();

  const [accountType, setAccountType] = useState("");

  useEffect(() => {
    if (userDetails) {
      setAccountType(userDetails.accountType);
    }
  }, [userDetails]);

  useEffect(() => {
    if (portalSettings && userDetails) {
      setUpdate((current) => current + 1);
      if (portalSettings.clubID && userDetails.accountType === "club") {
        // console.log(portalSettings);
        const { clubID } = portalSettings;
        setToShow();

        setToShow(<ClubHome clubID={clubID} hideBack />);
        setShow(true);
        setUpdate((current) => current + 1);
      }
      if (portalSettings.clubID && userDetails.accountType === "society") {
        navigate(`/leagues/${portalSettings.clubID}`);
      }
    }
  }, [portalSettings, currentUser, userDetails]);

  useEffect(() => {
    if (typeof clicked === "string" && !portalSettings.clubID) {
      if (clicked.includes("home")) {
        setShow(false);
      }
    }
  }, [clicked]);

  return (
    <div className="home">
      {show ? (
        toShow
      ) : (
        <>
          <PageHeader text="Home" />
          <HomeActiveEvents />

          <div className="sub-header">
            <h5>Quick Links</h5>
          </div>
          <div className="quick-links">
            <Pill
              text={"Added Users"}
              onClick={() => {
                setToShow(<AddedUsers goBack={() => setShow(false)} />);
                setShow(true);
              }}
            />
            <Pill
              text={"Add User"}
              onClick={() => {
                setToShow(<UserImport goBack={() => setShow(false)} />);
                setShow(true);
              }}
            />
            <Pill
              text={"New Event"}
              onClick={() => {
                setToShow(
                  <EventCreator edit={false} goBack={() => setShow(false)} />
                );
                setShow(true);
              }}
            />
            {accountType !== "society" && (
              <Pill
                text={"New League"}
                onClick={() => {
                  setToShow(
                    <LeagueCreator
                      edit={false}
                      reload={() => {}}
                      goBack={() => setShow(false)}
                    />
                  );
                  setShow(true);
                }}
              />
            )}
            {accountType !== "society" && (
              <Pill
                text={"New Cup"}
                onClick={() => {
                  setToShow(
                    <CupCreator
                      edit={false}
                      reload={() => {}}
                      goBack={() => setShow(false)}
                    />
                  );
                  setShow(true);
                }}
              />
            )}
            {accountType === "club" && (
              <>
                <Pill
                  text={"New Tournament"}
                  onClick={() => {
                    setToShow(
                      <MatchPlayTournamentEditor
                        edit={false}
                        goBack={() => setShow(false)}
                        reload={() => {}}
                      />
                    );
                    setShow(true);
                  }}
                />
                <Pill
                  text={"New Contest"}
                  onClick={() => {
                    setToShow(
                      <NewContest
                        edit={false}
                        goBack={() => setShow(false)}
                        reload={() => {}}
                      />
                    );
                    setShow(true);
                  }}
                />
              </>
            )}
          </div>

          {portalSettings && (
            <div className="home-dash">
              {portalSettings.homeEvents !== false && (
                <div className="hd-item">
                  <HomeEvents setShow={setShow} setToShow={setToShow} />
                </div>
              )}
              {portalSettings.homeNotifications !== false && (
                <div className="hd-item">
                  <HomeNotifications setShow={setShow} setToShow={setToShow} />
                </div>
              )}
              {portalSettings.pastEvents && (
                <div className="hd-item">
                  <HomePastEvents setShow={setShow} setToShow={setToShow} />
                </div>
              )}
              {portalSettings.leagueTables && (
                <div className="hd-item">
                  <HomeLeagueTables />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Home;
