import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
// Icons

// Components
import RoundSummaryBox from "./RoundSummaryBox";
import { ClipLoader } from "react-spinners";

const prop = {
  textOne: "",
  textTwo: "",
  textThree: "",
  reverse: false,
  icon: "",
  type: "",
};

function RoundSummary({ playerID = "", roundID = "" }) {
  const [general, setGeneral] = useState([prop]);
  const [driving, setDriving] = useState([prop]);
  const [putting, setPutting] = useState([prop]);
  const [approach, setApproach] = useState([prop]);
  const [strokes, setStrokes] = useState([prop]);
  const [leagues, setLeagues] = useState([]);

  const [leagueName, setLeagueName] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(playerID, roundID);
    loadSumary();
  }, []);

  async function loadSumary() {
    console.log(roundID);
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const roundData = roundDoc.data();
    const { enabledStats, grossHoleAverages, netHoleAverages, leagueID } =
      roundData;
    const roundStats = roundData.stats;
    console.log(roundData);
    const userRoundDoc = await roundDoc.ref
      .collection("scores")
      .doc(playerID)
      .get();
    const userRound = userRoundDoc.data();
    const userRoundStats = userRound.stats;
    const { holes } = userRound;
    // console.log(userRoundStats);
    const userDoc = await db.collection("users").doc(playerID).get();
    const user = userDoc.data();
    const userStats = user.stats;
    // console.log(userStats);
    // console.log(roundStats);

    const courseDoc = await db
      .collection("courses")
      .doc(roundData.courseID)
      .get();
    const courseData = courseDoc.data();
    const courseStats = courseData.stats;
    // console.log(courseStats);

    const gen = [];
    const driv = [];
    const putt = [];
    const app = [];
    const str = [];
    const leg = [];

    // General

    // League
    if (
      leagueID !== "standalone" &&
      leagueID !== "" &&
      leagueID !== undefined
    ) {
      const leagueDoc = await db.collection("leagues").doc(leagueID).get();
      const league = leagueDoc.data();
      const leagueStats = league.stats;
      setLeagueName(league.leagueName);
      const { leagueName } = league;
      const highestLeagueGross = leagueStats.highestGross;
      const lowestLeagueGross = leagueStats.lowestGross;
      const highestLeagueNet = leagueStats.highestNet;
      const lowestLeagueNet = leagueStats.lowestNet;

      if (userRound.totalNetStrokes === highestLeagueNet) {
        leg.push({
          textOne: "This was the",
          textTwo: `highest net score (${highestLeagueNet})`,
          textThree: `in ${leagueName} history`,
          type: "bad",
        });
      }
      if (userRound.totalNetStrokes === lowestLeagueNet) {
        leg.push({
          textOne: "This was the",
          textTwo: `lowest net score (${lowestLeagueNet})`,
          textThree: `in ${leagueName} history`,
          type: "good",
        });
      }
      if (userRound.totalGrossStrokes === highestLeagueGross) {
        leg.push({
          textOne: "This was the",
          textTwo: `highest gross score (${highestLeagueGross})`,
          textThree: `in ${leagueName} history`,
          type: "bad",
        });
      }
      if (userRound.totalGrossStrokes === lowestLeagueGross) {
        leg.push({
          textOne: "This was the",
          textTwo: `lowest gross score (${lowestLeagueGross})`,
          textThree: `in ${leagueName} history`,
          type: "good",
        });
      }

      setLeagues(leg);
    }

    // Driving
    const roundFIR = userRound.totalFIR;
    const averageRoundFIR = Math.round((roundStats.firAve * 18) / 100);
    const averageCourseFIR = Math.round((courseStats.firAve * 18) / 100);
    const averageUserFIR = userStats.averageFIR;

    // Round FIR
    if (roundFIR > averageRoundFIR) {
      const diff = Math.round(
        ((roundFIR - averageRoundFIR) / averageRoundFIR) * 100
      );
      driv.push({
        textOne: "You were",
        textTwo: `${diff}% more accurate off the tee`,
        textThree: "than the rest of the field on average today",
        type: "good",
      });
    }
    if (roundFIR < averageRoundFIR) {
      const diff = Math.round(
        ((averageRoundFIR - roundFIR) / averageRoundFIR) * 100
      );
      driv.push({
        textOne: "You hit",
        textTwo: `${diff}% less fairways`,
        textThree: "than the rest of the field on average today",
        type: "bad",
      });
    }

    // User FIR
    if (roundFIR > averageUserFIR) {
      console.log(roundFIR, averageUserFIR);

      const diff = Math.round(
        ((roundFIR - averageUserFIR) / averageUserFIR) * 100
      );
      console.log(diff);
      driv.push({
        textOne: "You hit",
        textTwo: `${diff}% more fairways`,
        textThree: "than you usually do today",
        type: "good",
      });
    }
    if (roundFIR < averageUserFIR) {
      console.log(roundFIR, averageUserFIR);
      const diff = Math.round(
        ((averageUserFIR - roundFIR) / averageUserFIR) * 100
      );
      driv.push({
        textOne: "You hit",
        textTwo: `${diff}% less fairways`,
        textThree: "than you usually do today",
        type: "bad",
      });
    }

    // Course FIR
    if (roundFIR > averageCourseFIR) {
      const diff = Math.round(
        ((roundFIR - averageCourseFIR) / averageCourseFIR) * 100
      );
      driv.push({
        textOne: "You hit",
        textTwo: `${diff}% more fairways`,
        textThree: "than players usually do at this course",
        type: "good",
      });
    }
    if (roundFIR < averageCourseFIR) {
      const diff = Math.round(
        ((averageCourseFIR - roundFIR) / averageCourseFIR) * 100
      );
      driv.push({
        textOne: "You hit",
        textTwo: `${diff}% less fairways`,
        textThree: "than players usually do at this course",
        type: "bad",
      });
    }

    // Putting
    const userAveragePutts = userStats.averagePutts;
    const userRoundPutts = userRound.totalPutts;
    const courseAveragePutts = courseStats.averagePutts;
    const averageRoundPutts = roundStats.averagePutts;

    // Round Putts
    if (userRoundPutts > averageRoundPutts) {
      const diff = userRoundPutts - averageRoundPutts;
      putt.push({
        textOne: "You hit",
        textTwo: `${Math.round(diff * 100) / 100} more putts`,
        textThree: "than the rest of the field today.",
        type: "bad",
      });
    }

    if (userRoundPutts < averageRoundPutts) {
      const diff = averageRoundPutts - userRoundPutts;
      putt.push({
        textOne: "You hit",
        textTwo: `${Math.round(diff * 100) / 100} less putts`,
        textThree: "than the rest of the field today.",
        type: "good",
      });
    }

    // User Putts
    if (userRoundPutts > userAveragePutts) {
      const diff = Math.round((userRoundPutts - userAveragePutts) * 100) / 100;
      putt.push({
        textOne: "You hit",
        textTwo: `${diff} more putts`,
        textThree: "than you usually do today.",
        type: "bad",
      });
    }

    if (userRoundPutts < userAveragePutts) {
      const diff = Math.round((userAveragePutts - userRoundPutts) * 100) / 100;
      putt.push({
        textOne: "You hit",
        textTwo: `${diff} less putts`,
        textThree: "than you usually do today.",
        type: "good",
      });
    }

    // Course Putts
    if (userRoundPutts > courseAveragePutts) {
      const diff =
        Math.round((userRoundPutts - courseAveragePutts) * 100) / 100;
      putt.push({
        textOne: "You hit",
        textTwo: `${diff} more putts`,
        textThree: "than the course average today.",
        type: "bad",
      });
    }

    if (userRoundPutts < courseAveragePutts) {
      const diff =
        Math.round((courseAveragePutts - userRoundPutts) * 100) / 100;
      putt.push({
        textOne: "You hit",
        textTwo: `${diff} less putts`,
        textThree: "than the course average today.",
        type: "good",
      });
    }

    if (userRoundPutts < roundStats.fewestPutts) {
      putt.push({
        textOne: "You hit",
        textTwo: `fewer putts (${userRoundPutts})`,
        textThree: "than anyone today",
        type: "good",
      });
    }

    // Approach
    const userGIR = userRound.totalGIR;
    const userAverageGIR = Math.round(
      (userStats.totalNetGir / userStats.totalHoles) * 18
    );
    const roundAverageGIR = Math.round((roundStats.girAve / 100) * 18);
    const courseAverageGIR = Math.round((roundStats.girAve / 100) * 18);

    // Round
    if (userGIR > roundAverageGIR) {
      const diff = Math.round(
        ((userGIR - roundAverageGIR) / roundAverageGIR) * 100
      );
      app.push({
        textOne: "You hit",
        textTwo: `${diff}% more greens`,
        textThree: "than the rest of the field",
        type: "good",
      });
    }
    if (userGIR < roundAverageGIR) {
      const diff = Math.round(
        ((roundAverageGIR - userGIR) / roundAverageGIR) * 100
      );
      app.push({
        textOne: "You hit",
        textTwo: `${diff}% less greens`,
        textThree: "than the rest of the field",
        type: "bad",
      });
    }

    // User
    if (userGIR > userAverageGIR) {
      const diff = Math.round(
        ((userGIR - userAverageGIR) / userAverageGIR) * 100
      );
      app.push({
        textOne: "You hit",
        textTwo: `${diff}% more greens`,
        textThree: "than you usually do",
        type: "good",
      });
    }
    if (userGIR < userAverageGIR) {
      const diff = Math.round(
        ((userAverageGIR - userGIR) / userAverageGIR) * 100
      );
      app.push({
        textOne: "You hit",
        textTwo: `${diff}% less greens`,
        textThree: "than you usually do",
        type: "bad",
      });
    }

    // Course
    if (userGIR > courseAverageGIR) {
      const diff = Math.round(
        ((userGIR - courseAverageGIR) / courseAverageGIR) * 100
      );
      app.push({
        textOne: "You hit",
        textTwo: `${diff}% more greens`,
        textThree: "than the course average",
        type: "good",
      });
    }
    if (userGIR < courseAverageGIR) {
      const diff = Math.round(
        ((courseAverageGIR - userGIR) / courseAverageGIR) * 100
      );
      app.push({
        textOne: "You hit",
        textTwo: `${diff}% less greens`,
        textThree: "than the course average",
        type: "bad",
      });
    }

    // Strokes
    if (netHoleAverages) {
      for (let i = 0; i < holes.length; i++) {
        const userNet = holes[i].netScore;
        const userGross = holes[i].grossScore;
        const netAve = netHoleAverages[i];

        if (userNet - netAve > 1) {
          str.push({
            textOne: "You lost",
            textTwo: `${Math.round((userNet - netAve) * 100) / 100} strokes`,
            textThree: `on the field on hole ${i + 1}`,
            type: "bad",
          });
        }
        if (userNet - netAve === 1) {
          str.push({
            textOne: "You lost",
            textTwo: `${Math.round((userNet - netAve) * 100) / 100} strokes`,
            textThree: `on the field on hole ${i + 1}`,
            type: "bad",
          });
        }
        if (netAve - userNet > 1) {
          str.push({
            textOne: "You gained",
            textTwo: `${Math.round((netAve - userNet) * 100) / 100} strokes`,
            textThree: `on the field on hole ${i + 1}`,
            type: "good",
          });
        }
        if (netAve - userNet === 1) {
          str.push({
            textOne: "You gained",
            textTwo: `${Math.round((netAve - userNet) * 100) / 100} stroke`,
            textThree: `on the field on hole ${i + 1}`,
            type: "good",
          });
        }
      }
    }

    // 9s
    const frontDiff =
      Math.round(
        (roundStats.frontNineNetAverage - userRound.frontNineNet) * 100
      ) / 100;
    const backDiff =
      Math.round(
        (roundStats.backNineNetAverage - userRound.backNineNet) * 100
      ) / 100;

    if (frontDiff > 0) {
      gen.push({
        textOne: "You gained",
        textTwo: `${frontDiff} strokes`,
        textThree: "on the field on the front 9",
        type: "good",
      });
    }

    if (frontDiff < 0) {
      gen.push({
        textOne: "You lost",
        textTwo: `${-frontDiff} strokes`,
        textThree: "to the field on the front 9",
        type: "bad",
      });
    }
    if (backDiff > 0) {
      gen.push({
        textOne: "You gained",
        textTwo: `${backDiff} strokes`,
        textThree: "on the field on the back 9",
        type: "good",
      });
    }

    if (backDiff < 0) {
      gen.push({
        textOne: "You lost",
        textTwo: `${-backDiff} strokes`,
        textThree: "to the field on the back 9",
        type: "bad",
      });
    }

    // Par 3s, 4s, 5s
    if (userRoundStats.averageParThrees - userStats.averageParThrees > 0.3) {
      const diff =
        Math.round(
          (userRoundStats.averageParThrees - userStats.averageParThrees) * 100
        ) / 100;
      gen.push({
        textOne: "You averaged",
        textTwo: `${diff} more strokes`,
        textThree: "on par 3s today than usual",
        type: "bad",
      });
    }
    if (userRoundStats.averageParThrees - userStats.averageParThrees < -0.3) {
      const diff =
        Math.round(
          (userStats.averageParThrees - userRoundStats.averageParThrees) * 100
        ) / 100;
      gen.push({
        textOne: "You averaged",
        textTwo: `${diff} less strokes`,
        textThree: "on par 3s today than usual",
        type: "good",
      });
    }
    console.log(userRoundStats.averageParFours, userStats.averageParFours);
    if (userRoundStats.averageParFours - userStats.averageParFours > 0.3) {
      const diff =
        Math.round(
          (userRoundStats.averageParFours - userStats.averageParFours) * 100
        ) / 100;
      gen.push({
        textOne: "You averaged",
        textTwo: `${diff} more strokes`,
        textThree: "on par 4s today than usual",
        type: "bad",
      });
    }
    if (userRoundStats.averageParFours - userStats.averageParFours < -0.3) {
      const diff =
        Math.round(
          (userStats.averageParFours - userRoundStats.averageParFours) * 100
        ) / 100;
      gen.push({
        textOne: "You averaged",
        textTwo: `${diff} less strokes`,
        textThree: "on par 4s today than usual",
        type: "good",
      });
    }
    if (userRoundStats.averageParFives - userStats.averageParFives > 0.3) {
      const diff =
        Math.round(
          (userRoundStats.averageParFives - userStats.averageParFives) * 100
        ) / 100;
      gen.push({
        textOne: "You averaged",
        textTwo: `${diff} more strokes`,
        textThree: "on par 5s today than usual",
        type: "bad",
      });
    }
    if (userRoundStats.averageParFives - userStats.averageParFives < -0.3) {
      const diff =
        Math.round(
          (userStats.averageParFives - userRoundStats.averageParFives) * 100
        ) / 100;
      gen.push({
        textOne: "You averaged",
        textTwo: `${diff} less strokes`,
        textThree: "on par 5s today than usual",
        type: "good",
      });
    }

    const {
      highestEventNet,
      lowestEventNet,
      highestEventGross,
      lowestEventGross,
    } = userStats;
    if (userRound.totalNetStrokes === highestEventNet) {
      gen.push({
        textOne: "This was your",
        textTwo: `highest net score (${highestEventNet})`,
        textThree: "in ranked UGA events",
        type: "bad",
      });
    }
    if (userRound.totalNetStrokes === lowestEventNet) {
      gen.push({
        textOne: "This was your",
        textTwo: `lowest net score (${lowestEventNet})`,
        textThree: "in ranked UGA events",
        type: "good",
      });
    }
    if (userRound.totalGrossStrokes === highestEventGross) {
      gen.push({
        textOne: "This was your",
        textTwo: `highest gross score (${highestEventGross})`,
        textThree: "in ranked UGA events",
        type: "bad",
      });
    }
    if (userRound.totalGrossStrokes === lowestEventGross) {
      gen.push({
        textOne: "This was your",
        textTwo: `lowest gross score (${lowestEventGross})`,
        textThree: "in ranked UGA events",
        type: "good",
      });
    }

    setGeneral(gen);

    setStrokes(str);

    setApproach(app);
    setPutting(putt);
    setDriving(driv);

    setLoading(false);
  }

  return (
    <div className="round-summary bs-subtle">
      {loading ? (
        <>
          <div className="cp-loader">
            <ClipLoader color="#1e7a69" size={30} className="btn-ico" />
          </div>
        </>
      ) : (
        <>
          <div className="rs-boxes">
            <div className="neh-box">
              <h2 className="ne-header">General</h2>
            </div>
            <div className="rs-boxes">
              {general.map((item, index) => (
                <RoundSummaryBox
                  key={`ge${index}`}
                  textOne={item.textOne}
                  textTwo={item.textTwo}
                  textThree={item.textThree}
                  icon={"general"}
                  reverse={index % 2 !== 0}
                  type={item.type}
                />
              ))}
            </div>
            {leagueName !== "" && leagues.length > 0 && (
              <>
                <div className="neh-box mt-20">
                  <h2 className="ne-header">{leagueName}</h2>
                </div>
                <div className="rs-boxes">
                  {leagues.map((item, index) => (
                    <RoundSummaryBox
                      key={`le${index}`}
                      textOne={item.textOne}
                      textTwo={item.textTwo}
                      textThree={item.textThree}
                      icon={"drive"}
                      reverse={index % 2 !== 0}
                      type={item.type}
                    />
                  ))}
                </div>
              </>
            )}
            <div className="neh-box mt-20">
              <h2 className="ne-header">Driving</h2>
            </div>
            {driving.map((item, index) => (
              <RoundSummaryBox
                key={`dr${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"drive"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Putting</h2>
          </div>
          <div className="rs-boxes">
            {putting.map((item, index) => (
              <RoundSummaryBox
                key={`pu${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"putt"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Approach</h2>
          </div>
          <div className="rs-boxes">
            {approach.map((item, index) => (
              <RoundSummaryBox
                key={`ap${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"approach"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
          </div>
          {strokes.length !== 0 && (
            <div className="neh-box mt-20">
              <h2 className="ne-header">Strokes Gained / Lost</h2>
            </div>
          )}
          <div className="rs-boxes">
            {strokes.map((item, index) => (
              <RoundSummaryBox
                key={`str${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"approach"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default RoundSummary;
