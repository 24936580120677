import React, { useState } from "react";
import "./RadioGroup.css";
function RadioGroupTwo({ buttons = [], active = "", setActive = () => {} }) {
  return (
    <div className="radio-group">
      <div className="RadioButton-two btn-group">
        {buttons.map((button, index) => {
          return (
            <div
              key={button.value}
              data-val={`${index}`}
              onClick={() => setActive(button.value)}
              className={
                active === button.value
                  ? "btn btn-active btn-default"
                  : "btn btn-default"
              }
            >
              {button.text}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RadioGroupTwo;
