import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import "./Features.css";
import { Helmet } from "react-helmet";

// Icons

// Components
import { Tab, Tabs } from "@mui/material";

function Features() {
  const tabOpts = [
    {
      value: "events",
      label: "Events",
    },
    {
      value: "members",
      label: "Members",
    },
    {
      value: "leagues",
      label: "Leagues",
    },
    {
      value: "matchplay",
      label: "Matchplay Leagues",
    },
    {
      value: "cups",
      label: "Cups",
    },
    {
      value: "contests",
      label: "Contests",
    },
  ];

  const [selTab, setSelTab] = useState("events");
  const [showSelect, setShowSelect] = useState(false);

  return (
    <div className="features-page">
      <Helmet>
        <title>The Commissioner | Features</title>
      </Helmet>
      <div className="dp-header">
        <h1>Features</h1>
      </div>

      <div className="flex flex-center">
        <div className="disp-head mt-20">
          <h2 className="">The Ultimate Scoring Management Software</h2>
          {/* <div className="dis-bb"></div>
          <div className="dis-bb-2"></div> */}
        </div>
      </div>

      <div className="features-tabs">
        <div className="ft-box">
          <Tabs
            className="ft-tabs"
            value={selTab}
            onChange={(e, v) => setSelTab(v)}
          >
            {tabOpts.map((t, i) => (
              <Tab key={t.value} value={t.value} label={t.label} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="ftib-ctr">
        <div className="fti-box">
          {selTab === "events" && (
            <div className="fti-item">
              <div className="fti-head">
                <h3>Events</h3>
                <p>Transform your events</p>
              </div>
            </div>
          )}
        </div>{" "}
      </div>
    </div>
  );
}

export default Features;
