import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import { Oval } from "react-loader-spinner";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import LogHeader from "../../components/display/LeagueLogs/LogItem/LogHeader";
import LogItem from "../../components/display/LeagueLogs/LogItem/LogItem";

function HomeLeagueTables() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(true);
  const [droptions, setDroptions] = useState([]);
  const [boards, setBoards] = useState({});
  const [selBoard, setSelBoard] = useState([]);

  const [selSeason, setSelSeason] = useState("");

  const [update, setUpdate] = useState(0);

  useEffect(() => {
    if (currentUser) {
      load();
    }
  }, [currentUser]);

  async function load() {
    const leagues = await db
      .collection("leagues")
      .where("admins", "array-contains", currentUser.uid)
      //   .orderBy("dateCreated", "desc")
      .get();

    const dropArr = [];
    const boardMap = {};

    for (let i = 0; i < leagues.docs.length; i++) {
      const leagueDoc = leagues.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();

      const { leagueName, pointsTable, shortName, seasonID } = league;
      dropArr.push({
        value: leagueID,
        label: shortName ?? leagueName,
      });

      let filtered = pointsTable.filter((player) => player.totalRounds !== 0);
      filtered = filtered.sort((a, b) => b.points - a.points);
      boardMap[leagueID] = filtered;
    }

    setDroptions(dropArr);
    setBoards(boardMap);
    if (dropArr.length > 0) {
      setSelSeason(dropArr[0]);
      setSelBoard(boardMap[dropArr[0].value]);
    }
    setLoading(false);
  }

  return (
    <div className="home-league-table">
      <div className="page-header ph-link">
        <h2 onClick={() => navigate("/leagues")}>League Tables</h2>
        <Dropdown
          arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
          arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
          options={droptions}
          value={selSeason}
          onChange={(e) => {
            const seasonID = e.value;
            setSelBoard(boards[seasonID]);
            setUpdate((current) => current + 1);
            setSelSeason(e);
          }}
        />
      </div>
      {loading ? (
        <div className="he-loader">
          <Oval
            color="#1e7a69"
            secondaryColor="#ffffff"
            height={40}
            width={40}
          />
        </div>
      ) : (
        <>
          <LogHeader
            final={selBoard.length > 0 && selBoard[0].finalRank !== undefined}
          />
          {selBoard.map((player, index) => {
            const final =
              selBoard.length > 0 && selBoard[0].finalRank !== undefined;

            return (
              <LogItem
                key={player.playerID}
                upDown={player.move ?? ""}
                playerID={player.playerID}
                handleClick={() => {}}
                points={player.points}
                wins={player.wins ?? 0}
                rank={player.ranking ?? index + 1}
                played={player.totalRounds}
                name={player.userName}
                hcp={player.handicap}
                img={player.imageLink}
                ave={
                  final
                    ? player.finalRank
                    : player.totalRounds > 0
                    ? Math.round(player.points / player.totalRounds)
                    : 0
                }
              />
            );
          })}
        </>
      )}
    </div>
  );
}

export default HomeLeagueTables;
