import React, { useState } from "react";

// Components
import CourseItem from "../../display/CourseItem/CourseItem";
import SeasonEditor from "../SeasonEditor/SeasonEditor";
import TeamSeasonEditor from "../../ClubLeagues/TeamSeasonEditor";

function LeagueSeasons({
  seasons = [],
  activeSeasonID = "",
  loading = true,
  setShow = () => {},
  setToShow = () => {},
  reload = () => {},
  teamLeague = false,
  eclectic = false,
}) {
  return (
    <div className="league-seasons">
      <div className="sub-header">
        <h3>Seasons</h3>
      </div>

      <div className="seasons-container">
        {seasons.map((season, index) => (
          <CourseItem
            showCourse={() => {
              if (teamLeague) {
                setToShow(
                  <TeamSeasonEditor
                    edit
                    goBack={() => setShow(false)}
                    reload={reload}
                    leagueID={season.leagueID}
                    seasonID={season.seasonID}
                    seasons={seasons}
                  />
                );
              } else {
                setToShow(
                  <SeasonEditor
                    eclectic={eclectic}
                    seasonID={season.seasonID}
                    leagueID={season.leagueID}
                    edit
                    seasons={seasons}
                    reload={() => {
                      reload();
                    }}
                    goBack={() => {
                      setShow(false);
                    }}
                  />
                );
              }
              setShow(true);
            }}
            key={season.seasonID}
            hideComma
            courseName={season.seasonName}
            image={season.imageLink}
            city={
              activeSeasonID === season.seasonID
                ? "Active"
                : season.completed === true
                ? "Completed"
                : "Upcoming"
            }
            hideStar
          />
        ))}
      </div>
    </div>
  );
}

export default LeagueSeasons;
