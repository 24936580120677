import React, { useEffect, useState, useRef } from "react";
import { db } from "../../../firebase";

// Comps
import BackRow from "../../display/Rows/BackRow";
import PageHeader from "../../display/Rows/PageHeader";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { Dialog } from "@mui/material";
import LiveTeeChange from "./LiveTeeChange";
import ScoreInput from "../ScoreInput/ScoreInput";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

function ScorePortal({ eventID = "", goBack = () => {} }) {
  // State
  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState(false);

  const [players, setPlayers] = useState([]);
  const [groups, setGroups] = useState([]);

  const [roundID, setRoundID] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const [eventName, setEventName] = useState("");

  const [view, setView] = useState("player");

  const [selectedPlayer, setSelectedPlayer] = useState();

  const [currentComp, setCurrentComp] = useState("");

  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((c) => c + 1);
  }

  const scrollRef = useRef();

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    const evtDoc = await db.collection("events").doc(eventID).get();
    const event = evtDoc.data();
    const { activeRoundID } = event;

    setEventName(event.eventName);

    setRoundID(activeRoundID);

    db.collection("rounds")
      .doc(activeRoundID)
      .onSnapshot(async (snapshot) => {
        const round = snapshot.data();
        const { leaderboard, teeTimes, teeMap } = round;

        const genderMap = round.genderMap ?? {};

        const compMap = {};
        const hcpMap = {};
        const playerMap = {};

        for (let i = 0; i < leaderboard.length; i++) {
          const row = leaderboard[i];
          const { playerID, thru, handicap } = row;
          row.gender = genderMap[playerID] ?? "male";
          compMap[playerID] = thru;
          hcpMap[playerID] = handicap;
          playerMap[playerID] = row;
        }

        const playerArr = [];

        let notComp = false;

        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];

          if (typeof group.time === "object") {
            const dtobj = new Date(group.time.toDate());
            teeTimes[i].time = new Date(group.time.toDate()).toLocaleTimeString(
              "en-gb",
              { timeStyle: "short" }
            );
          }
          let comp = true;
          for (let x = 0; x < group.players.length; x++) {
            const player = group.players[x];
            let playerID = "";

            if (typeof player === "string") {
              playerID = player;
              teeTimes[i].players[x] = playerMap[player];
            } else {
              playerID = player.playerID;

              teeTimes[i].players[x].tee = teeMap[player.playerID];
              teeTimes[i].players[x].handicap = hcpMap[player.playerID];
            }
            const thru = compMap[playerID];
            teeTimes[i].players[x].thru = thru;

            if (thru !== 18 && !notComp) {
              notComp = true;
              teeTimes[i].players[x].scroll = true;
            }

            if (thru !== 18 && thru !== undefined) {
              comp = false;
            }
          }
          teeTimes[i].completed = comp;
        }

        // console.log(teeTimes);

        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];
          const { players } = group;
          for (let j = 0; j < players.length; j++) {
            const player = players[j];
            playerArr.push(player);
          }
        }

        // console.log(playerArr);

        setGroups(teeTimes);
        setPlayers(players);
        setLoading(false);
      });
  }

  return (
    <div className="score-portal">
      {loading && <BackdropLoader />}
      {show ? (
        toShow
      ) : (
        <>
          {view === "player" && (
            <>
              <Dialog open>
                <div className="score-portal-players">
                  {!show && <BackRow action={goBack} />}

                  <div className="page-header">
                    <h2>Score Input</h2>
                    <div className="input-group mb-20 mt-0 si-search">
                      <p>Search</p>
                      <input
                        type="text"
                        placeholder="Search for player"
                        value={searchInput}
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                          upd();
                        }}
                        className="default-input"
                      />
                    </div>
                  </div>

                  {groups.map((group, i) => {
                    const { players, time, startingTee } = group;
                    if (searchInput !== "") {
                      let show = false;
                      const sl = searchInput.toLowerCase();
                      for (let i = 0; i < players.length; i++) {
                        const player = players[i];
                        const nl = player.name.toLowerCase();
                        // console.log(nl.includes(sl));
                        if (nl.includes(sl)) {
                          show = true;
                        }
                      }
                      if (!show) {
                        console.log("ret zilch");

                        return null;
                      }
                    }
                    return (
                      <div key={`${time}${startingTee}i`} className="spp-group">
                        <div className="sppg-header">
                          <h3 className="ne-header">
                            {time} - Tee: {startingTee}
                          </h3>
                        </div>
                        <div className="spgg-pl">
                          {players.map((player, i2) => {
                            let scroll = player.scroll;

                            if (scroll) {
                              //   alert(i2);
                            }

                            if (searchInput !== "") {
                              const sl = searchInput.toLowerCase();
                              const nl = player.name.toLowerCase();
                              if (!nl.includes(sl)) {
                                return null;
                              }
                            }

                            return (
                              <div ref={scroll ? scrollRef : null}>
                                <PlayerItem
                                  noLink
                                  showProfile={() => {
                                    setSelectedPlayer(player);
                                    setToShow(
                                      <LiveTeeChange
                                        hide={() => setShow(false)}
                                        defaultPlayer={player}
                                        eventID={eventID}
                                        roundID={roundID}
                                        portal
                                        progress={() => {
                                          setToShow(
                                            <ScoreInput
                                              eventName={eventName}
                                              defaultPlayer={player}
                                              defaultMode="selector"
                                              goBack={() => {
                                                setShow(false);
                                                setTimeout(() => {
                                                  if (scrollRef.current) {
                                                    scrollRef.current.scrollIntoView(
                                                      { behavior: "smooth" }
                                                    );
                                                  }
                                                }, 100);
                                              }}
                                              portal
                                              //   tees={player.tee ?? ""}
                                              eventID={eventID}
                                              roundID={roundID}
                                            />
                                          );
                                        }}
                                      />
                                    );

                                    setShow(true);
                                  }}
                                  //   showBg={player.thru === 18}
                                  showCheck={player.thru === 18}
                                  hideIcons
                                  key={player.playerID}
                                  name={player.name}
                                  hcp={player.handicap}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Dialog>
            </>
          )}

          {view === "groups" && <></>}
        </>
      )}
    </div>
  );
}

export default ScorePortal;
