import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Chart from "react-apexcharts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
function ScoreTracker({
  netTracker,
  grossTracker,
  min = 0,
  max = 0,
  roundHoles = [],
  format = "",
}) {
  const holes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  const proptions = {
    chart: {
      height: 350,
      type: "area",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#21C17C", "#E24955"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(0);
        },
      },
      title: {
        text: "Score",
      },
      //   min,
      //   max,
    },
    xaxis: {
      title: {
        text: "Hole",
      },
      categories: holes,
    },
    tooltip: {
      //   x: {
      //     format: "",
      //   },
    },
  };
  const scorePropSeries = [
    {
      name: "Score",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];
  const [series, setSeries] = useState(scorePropSeries);
  const [netSeries, setNetSeries] = useState(scorePropSeries);
  const [grossSeries, setGrossSeries] = useState(scorePropSeries);
  const [pointsSeries, setPointsSeries] = useState(scorePropSeries);

  useEffect(() => {
    sort();
  }, []);

  function sort() {
    const netArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const grossArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const pointsArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (let i = 0; i < netArr.length; i++) {
      if (i > 0) {
        netArr[i] = netTracker[i] ?? netArr[i - 1];
        grossArr[i] = grossTracker[i] ?? grossArr[i - 1];
      } else {
        netArr[i] = netTracker[i] ?? 0;
        grossArr[i] = grossTracker[i] ?? 0;
      }
    }
    let cum = 0;
    for (let i = 0; i < roundHoles.length; i++) {
      if (format === "stableford") {
        cum += roundHoles[i].points;
      } else {
        cum += roundHoles[i].modPoints;
      }
      pointsArr[i] = cum;
    }
    setNetSeries([{ name: "Net", data: netArr }]);
    setPointsSeries([{ name: "Points", data: pointsArr }]);

    setGrossSeries([{ name: "Gross", data: grossArr }]);
    setSeries([{ name: "Net", data: netArr }]);
  }

  return (
    <div className="score-tracker rank-tracker">
      <div className="input-group">
        <p>Net / Gross</p>
        <Dropdown
          arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
          arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
          options={[
            { name: "Net", value: "Net" },
            { name: "Gross", value: "Gross" },
            ...(format.includes("stable")
              ? [
                  {
                    value: "points",
                    label: "Points",
                  },
                ]
              : []),
          ]}
          onChange={(e) =>
            e.value === "Net"
              ? setSeries(netSeries)
              : e.value === "points"
              ? setSeries(pointsSeries)
              : setSeries(grossSeries)
          }
        />
      </div>

      <div className="track-graph">
        <Chart options={proptions} series={series} type={"line"} height={320} />
      </div>
    </div>
  );
}

export default ScoreTracker;
