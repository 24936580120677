import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons

// Components
import PageHeader from "../../display/Rows/PageHeader";
import { Oval } from "react-loader-spinner";
import { Dialog } from "@mui/material";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
function LivePlayerCourseEdit({
  player = {},
  courses = [],
  close = () => {},
  roundID = "",
  eventID = "",
}) {
  const [loading, setLoading] = useState(false);
  const [courseOpts, setCourseOpts] = useState([]);
  const [selCourse, setSelCourse] = useState();
  const [completed, setCompleted] = useState(false);
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  useEffect(() => {
    console.log(player);
    const copts = courses.map((course) => {
      const tr = {
        value: course.courseID,
        label: course.name ?? course.label,
      };
      return tr;
    });
    console.log(copts);
    setCourseOpts(copts);
  }, []);

  async function saveTrigger() {
    setLoading(true);
    try {
      await db.collection("triggers").add({
        type: "evt-course-edit",
        playerID: player.playerID,
        roundID,
        courseID: selCourse,
        eventID,
        dateAdded: new Date(),
      });
      setCompleted(true);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  return (
    <div className="live-player-course-edit">
      <Dialog open onClose={close}>
        <div className="lpce-ctt pd-20">
          <PageHeader text="Live Course Edit" close={close} />

          {loading ? (
            <>
              {" "}
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : completed ? (
            <>
              <div className="erp-finished">
                <div className="erpc-msg">
                  <p>
                    This player's course was successfully edited. Changes will
                    reflect in a few moments.
                  </p>
                  {/* <div className="flex-center">
                      <button onClick={reset} className="default-button">
                        Keep Purging
                      </button>
                    </div> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pd-20">
                <div className="pcs-player">
                  <PlayerItem
                    name={player.name}
                    key={player.playerID}
                    hideIcons
                    noLink
                    hideImage
                    hideHCP
                  />
                  <Dropdown
                    options={courseOpts}
                    value={selCourse}
                    onChange={(e) => {
                      setSelCourse(e.value);
                      update();
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-center mt-20 mb-40">
                <button
                  disabled={selCourse === ""}
                  onClick={saveTrigger}
                  className="default-button"
                >
                  Save Changes
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default LivePlayerCourseEdit;
