/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./PlayerSelector.css";
import { Avatar } from "@mui/material";
import SimplePlayer from "./SimplePlayer";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { TailSpin } from "react-loader-spinner";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { Steps } from "intro.js-react";

import { useAuth } from "../../contexts/AuthContext";

function PlayerSelector({
  setPlayers,
  players = [],
  goBack,
  selPlayers,
  courseDetails,
  event = false,
  max = "",
  loadingPlayers = false,
}) {
  const [av, setAv] = useState([]);
  const [sel, setSel] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(0);
  const [search, setSearch] = useState("");

  const { onboarding, setOnboard } = useAuth();

  useEffect(() => {
    if (onboarding) {
      if (
        onboarding.playerSelector === false ||
        onboarding.playerSelector === undefined
      ) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);

  function compare(a, b) {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  }

  function save() {
    setPlayers((current) => [...current, ...sel]);

    goBack();
  }

  useEffect(() => {
    const newArr = [];
    for (let i = 0; i < players.length; i++) {
      let found = false;

      for (let j = 0; j < selPlayers.length; j++) {
        if (
          selPlayers[j].playerID === players[i].playerID &&
          selPlayers[j].value === players[i].value
        ) {
          found = true;
        }
      }
      if (!found) {
        newArr.push(players[i]);
      }
    }
    setAv(newArr);
  }, [players]);

  const [hintsEnabled, setHintsEnabled] = useState(false);

  const steps = [
    {
      element: ".add-player",
      intro: "Tap on a player to add them to the round",
      position: "top",
    },
    {
      element: ".confirm-add",
      intro: "Tap here to confirm the addition of the selected players",
      position: "top",
    },
  ];

  return (
    <div className="player-selector scale-in-ps">
      <div onClick={() => goBack()} className="back-row">
        <ArrowBack className="ts-arrow" />
        <p>Add Players</p>
      </div>

      <div className="ps-slider">
        {sel.map((player, index) => (
          <div key={index} className="ps-item">
            <div className="ps-details">
              <div className="psc">
                <CancelIcon
                  onClick={() => {
                    setSel((current) =>
                      current.filter(
                        (item) => item.playerID !== player.playerID
                      )
                    );
                    setAv((current) => {
                      const newArr = [...current, player];
                      newArr.sort(compare);
                      return newArr;
                    });
                    setUpdate((current) => current + 1);
                  }}
                  className="ps-cancel icon-cursor"
                />
              </div>

              <Avatar src={player.profileImage} alt={player.name} />
              <h2>{player.name}</h2>
            </div>
          </div>
        ))}
      </div>

      <div className="sub-header mb-20">
        <h5>Available Players</h5>
      </div>

      <div className="pss-box">
        <div className="ps-search">
          {/* <SearchIcon /> */}
          <input
            placeholder="Search for player..."
            value={search}
            className="default-input"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      {loadingPlayers && (
        <div className="ps-spin-box">
          <div className="friends-loader">
            <TailSpin color="#21c17c" height={25} width={25} />
          </div>
          <p>Loading players...</p>
        </div>
      )}

      <div
        style={{
          height: sel.length > 0 ? "calc(100vh - 459px" : "calc(100vh - 370px",
        }}
        className="ps-players add-player"
      >
        {av.map((player) => {
          if (search !== "") {
            if (!player.name.toLowerCase().includes(search.toLowerCase())) {
              return null;
            }
          }

          return (
            <SimplePlayer
              handleClick={() => {
                if (max !== "") {
                  if (sel.length === parseInt(max)) {
                    return;
                  }
                }

                if (player.tee === undefined) {
                  player.tee = courseDetails.teeArray[0];
                }
                setSel((current) => [...current, player]);
                setAv((current) => {
                  // console.log(current);
                  const temp = current.filter(
                    (p) => p.playerID !== player.playerID
                  );
                  // console.log(temp);
                  return temp;
                });
              }}
              city={player.city ?? "Cape Town"}
              name={player.name}
              country={player.country ?? "South Africa"}
              imageLink={player.profileImage}
              key={player.playerID}
            />
          );
        })}
      </div>

      <div className="flex-center mt-20">
        {event ? (
          <button
            className="default-button"
            onClick={save}
            disabled={sel.length === 0}
          >
            {sel.length === 1
              ? "Add 1 player to event"
              : sel.length > 1
              ? `Add ${sel.length} players to event`
              : "Select players"}
          </button>
        ) : (
          <button
            className="default-button"
            onClick={save}
            disabled={sel.length === 0}
          >
            {sel.length === 1
              ? "Add 1 player to round"
              : sel.length > 1
              ? `Add ${sel.length} players to round`
              : "Select players"}
          </button>
        )}
      </div>
    </div>
  );
}

export default PlayerSelector;
