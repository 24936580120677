import React, { useState, useEffect } from "react";
import * as ss from "simple-statistics";
import "./StatsCorrelations.css";
import classNames from "classnames";

// Icons

// Components
import Chart from "react-apexcharts";
import CustomDropdown from "../CustomDropdown";
import { GrCapacity } from "react-icons/gr";
import * as Slider from "@radix-ui/react-slider";
import { DeleteForever } from "@mui/icons-material";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { db } from "../../../firebase";
import RoundCard from "../RoundCard/RoundCard";

function StatsCorrelation({ rounds = [], userID = "" }) {
  const statOpts = [
    {
      label: "Gross Score",
      value: "totalGrossStrokes",
    },
    {
      label: "Net Score",
      value: "totalNetStrokes",
    },
    {
      label: "Total Putts",
      value: "totalPutts",
    },
    {
      label: "GIR",
      value: "totalGIR",
    },
    {
      label: "FIR",
      value: "totalFIR",
    },
    { label: "Eagles", value: "totalEagles" },
    { label: "Birdies", value: "totalBirdies" },
    { label: "Pars", value: "totalPars" },
    { label: "Bogies", value: "totalBogies" },
    { label: "Doubles", value: "totalDoubles" },
    { label: "Triples", value: "totalTriples" },
    {
      label: "Scrambling",
      value: "scramblePerc",
    },
    {
      label: "Birdie Conversion",
      value: "birdieConversion",
    },
    {
      label: "1 Putts",
      value: "onePutts",
    },
    {
      label: "3 Putts",
      value: "threePutts",
    },
    {
      label: "Par 3 Average",
      value: "averageParThrees",
    },
    {
      label: "Par 4 Average",
      value: "averageParFours",
    },
    {
      label: "Par 5 Average",
      value: "averageParFives",
    },
  ];

  // State
  const [correls, setCorrels] = useState([]);
  const [s1, setS1] = useState();
  const [s2, setS2] = useState();
  const [selCorr, setSelCorr] = useState();
  const [graphs, setGraphs] = useState([]);
  const [dateArr, setDateArr] = useState([]);

  const [series, setSeries] = useState([]);
  const [update, setUpdate] = useState(0);
  const [dates, setDates] = useState([]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [selRds, setSelRds] = useState([1, rounds.length]);

  const proptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(config);
          const idx = config.dataPointIndex + selRds[0] - 1;
          console.log(idx);
          const roundID = rounds[idx].roundID;
          loadRd(roundID);
        },
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    colors: ["#1e7a69", "#232c2e", "#A9363F"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: "date",
      categories: dates,
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      intersect: true,
      shared: false,
    },
  };

  // UE
  useEffect(() => {
    sortDefaults();
  }, []);

  function generate() {
    if (!s1 || !s2) {
      return;
    }
    // console.log(selRds);
    const sr = selRds[0] - 1;
    const er = selRds[1] - 1;
    // console.log(sr, er);

    const stat1 = s1.value;
    const stat2 = s2.value;

    const arr1 = [];
    const arr2 = [];

    const carr = [];

    for (let i = 0; i < rounds.length; i++) {
      if (i < sr || i > er) {
        continue;
      }

      const one = rounds[i][stat1];
      const two = rounds[i][stat2];

      if (one === undefined || two === undefined) {
        continue;
      }
      arr1.push(one);
      arr2.push(two);
      if (carr.length === 0) {
        carr.push(0);
      } else {
        const cr = ss.sampleCorrelation(arr1, arr2).toFixed(2);
        carr.push(isNaN(cr) ? 0 : cr);
      }
    }
    // console.log(carr);
    const correl = ss.sampleCorrelation(arr1, arr2).toFixed(2);
    setCorrels((c) => {
      c.push({
        label: `${s1.label} / ${s2.label}`,
        value: isNaN(correl) ? 0 : correl,
        arr: carr,
        rounds: [sr, er],
      });
      // console.log(c);
      return c;
    });
    setUpdate((c) => c + 1);

    setSelCorr(correls.length - 1);

    setSeries((c) => {
      // console.log(correl.label, correl.arr);
      return [
        {
          name: `${s1.label} / ${s2.label}`,
          data: carr,
        },
      ];
    });
  }

  function sortDefaults() {
    console.log(rounds);
    if (rounds.length === 0) {
      return;
    }

    const correlArr = [];

    const gross = [];
    const putts = [];
    const fir = [];
    const gir = [];

    const gpcArr = [];
    const gfcArr = [];
    const ggcArr = [];
    const gpucArr = [];
    const fpcArr = [];
    const fgcArr = [];

    // console.log(rounds);

    for (let i = 0; i < rounds.length; i++) {
      // console.log(i + 1);
      const rd = rounds[i];
      const { totalGrossStrokes, totalPutts, totalFIR, totalGIR } = rd;
      if (totalGIR === undefined) {
        // console.log(rd);
        console.log("CONT");
        continue;
      }
      gross.push(totalGrossStrokes);
      putts.push(totalPutts);
      fir.push(totalFIR);
      gir.push(totalGIR);

      // console.log(gross, putts, gir);

      if (i === 0) {
        gpcArr.push(0);
        gfcArr.push(0);
        ggcArr.push(0);
        gpucArr.push(0);
        fpcArr.push(0);
        fgcArr.push(0);
      } else {
        const gpccorrel = ss.sampleCorrelation(gross, putts).toFixed(2);
        if (isNaN(gpccorrel)) {
          // console.log("NAN");
          gpcArr.push(0);
        } else {
          gpcArr.push(gpccorrel);
        }

        const gfccorrel = ss.sampleCorrelation(gross, fir).toFixed(2);
        if (isNaN(gfccorrel)) {
          // console.log("NAN");
          gfcArr.push(0);
        } else {
          gfcArr.push(gfccorrel);
        }

        const ggccorrel = ss.sampleCorrelation(gross, gir).toFixed(2);
        if (isNaN(ggccorrel)) {
          // console.log("NAN");
          ggcArr.push(0);
        } else {
          ggcArr.push(ggccorrel);
        }

        const gpuccorrel = ss.sampleCorrelation(putts, gir).toFixed(2);
        if (isNaN(gpuccorrel)) {
          // console.log("NAN");
          gpucArr.push(0);
        } else {
          gpucArr.push(gpuccorrel);
        }

        const fpccorrel = ss.sampleCorrelation(putts, fir).toFixed(2);
        // fpcArr.push(fpccorrel);
        if (isNaN(fpccorrel)) {
          // console.log("NAN");
          fpcArr.push(0);
        } else {
          fpcArr.push(fpccorrel);
        }

        const fgccorrel = ss.sampleCorrelation(gir, fir).toFixed(2);
        // fgcArr.push(fgccorrel);
        if (isNaN(fgccorrel)) {
          // console.log("NAN");
          fgcArr.push(0);
        } else {
          fgcArr.push(fgccorrel);
        }
      }
    }
    // console.log(gpcArr);
    // console.log(gross, putts);

    const grossPuttCorrel = ss.sampleCorrelation(gross, putts).toFixed(2);
    const grossFIRCorrel = ss.sampleCorrelation(gross, fir).toFixed(2);
    const grossGIRCorrel = ss.sampleCorrelation(gross, gir).toFixed(2);
    const girPuttCorrel = ss.sampleCorrelation(putts, gir).toFixed(2);
    const firPuttCorrel = ss.sampleCorrelation(putts, fir).toFixed(2);
    const firGirCorrel = ss.sampleCorrelation(gir, fir).toFixed(2);

    correlArr.push({
      label: "Gross Score / Putts",
      value: isNaN(grossPuttCorrel) ? 0 : grossPuttCorrel,
      arr: gpcArr,
      rounds: [0, rounds.length - 1],
    });
    correlArr.push({
      label: "Gross Score / FIR",
      value: isNaN(grossFIRCorrel) ? 0 : grossFIRCorrel,
      arr: gfcArr,
      rounds: [0, rounds.length - 1],
    });
    correlArr.push({
      label: "Gross Score / GIR",
      value: isNaN(grossGIRCorrel) ? 0 : grossGIRCorrel,
      arr: ggcArr,
      rounds: [0, rounds.length - 1],
    });
    correlArr.push({
      label: "Putts / GIR",
      value: isNaN(girPuttCorrel) ? 0 : girPuttCorrel,
      arr: gpucArr,
      rounds: [0, rounds.length - 1],
    });
    correlArr.push({
      label: "Putts / FIR",
      value: isNaN(firPuttCorrel) ? 0 : firPuttCorrel,
      arr: fpcArr,
      rounds: [0, rounds.length - 1],
    });
    correlArr.push({
      label: "GIR / FIR",
      value: isNaN(firGirCorrel) ? 0 : firGirCorrel,
      arr: fgcArr,
      rounds: [0, rounds.length - 1],
    });

    // console.log(correlArr);

    setCorrels(correlArr);

    // console.log(grossPuttCorrel);
  }

  async function loadRd(roundID) {
    console.log(roundID);
    setLoading(true);
    const rdDoc = await db.collection("rounds").doc(roundID).get();
    const scoreDoc = await rdDoc.ref.collection("scores").doc(userID).get();
    const scoreData = scoreDoc.data();
    const rdData = rdDoc.data();

    const { date, courseName, courseID } = rdData;
    const dateString = new Date(date.toDate()).toLocaleDateString("en-us", {
      dateStyle: "full",
    });
    scoreData.courseName = courseName;
    scoreData.courseID = courseID;
    // scoreData.notes = rdData.notes ?? "";

    scoreData.dateString = dateString;
    scoreData.roundID = roundID;
    scoreData.clientID = userID;

    console.log(scoreData);

    setToShow(
      <RoundCard
        round={scoreData}
        goBack={() => setShow(false)}
        showBack
        userID={userID}
      />
    );
    setShow(true);

    setLoading(false);
  }

  return (
    <div className="stats-correl">
      {loading && <BackdropLoader />}
      {show ? (
        toShow
      ) : (
        <>
          {/* <div className="neh-box">
        <h2 className="ne-header">Stats Correlations</h2>
      </div> */}
          <div className="ph-msg bss-white bs-subtle pd-10">
            <p>
              The values below indicate how much the first stat is influenced by
              the second one. The closer to 1 the value is, the more
              proportionate the relationship is. A negative correlation value
              indicates an inverse relationship.
            </p>
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Create your own</h2>
          </div>
          <div className="bs-subtle bss-white mb-20">
            <div className="flex flex-wrap flex-align-center">
              <div className="input-group">
                <p>Stat One (Base)</p>
                <CustomDropdown
                  value={s1}
                  handleChange={setS1}
                  opts={statOpts}
                />
              </div>
              <div className="input-group">
                <p>Stat Two (Affector)</p>
                <CustomDropdown
                  value={s2}
                  handleChange={setS2}
                  opts={statOpts}
                />
              </div>
              <div className="input-group">
                <p>Rounds</p>
                <div className="">
                  <Slider.Root
                    minStepsBetweenThumbs={1}
                    className="SliderRoot"
                    // defaultValue={[50]}
                    max={rounds.length}
                    onValueChange={(e) => {
                      setSelRds(e);
                    }}
                    step={1}
                    min={1}
                    value={selRds}
                  >
                    <Slider.Track className="SliderTrack sl-tr-rg">
                      <Slider.Range className="SliderRange sl-rg" />
                    </Slider.Track>
                    <Slider.Thumb className="SliderThumb" aria-label="Volume" />
                    <Slider.Thumb className="SliderThumb" aria-label="Volume" />
                  </Slider.Root>

                  <div className="slider-vals flex flex-align-center flex-betw mt-10">
                    <div className="sv-left">
                      <p>{selRds[0]}</p>
                    </div>
                    <div className="sv-left">
                      <p>{selRds[1]}</p>
                    </div>
                  </div>

                  {/* <h3 className="ml-20">{discountValue}%</h3> */}
                </div>
              </div>
              <button onClick={generate} className="default-button small-but">
                Generate
              </button>
            </div>
            <div className="sc-box flex flex-wrap flex-align-center mt-20">
              {correls.map((correl, i) => {
                // console.log(correl);
                const { rounds } = correl;
                return (
                  <div
                    className={classNames(
                      "stats-item correl-item flex flex-align-center",
                      {
                        "correl-item-sel": i === selCorr,
                      }
                    )}
                    key={`${i}correl`}
                  >
                    <div
                      onClick={() => {
                        setSelCorr(i);
                        // setSelCorr((c) => {
                        //   if (c.includes(i)) {
                        //     const filt = c.filter((item) => item !== i);
                        //     return filt;
                        //   } else {
                        //     c.push(i);
                        //     return c;
                        //   }
                        // });
                        setSeries((c) => {
                          // console.log(correl.label, correl.arr);
                          return [
                            {
                              name: correl.label,
                              data: correl.arr,
                            },
                          ];
                        });
                      }}
                      className="si-left"
                    >
                      <div className="si-tit">
                        <h3>{correl.label}</h3>
                      </div>
                      <div className="si-right correl-right">
                        <div
                          className={classNames("si-sta", {
                            "ssa-neg": correl.value > 0,
                            "ssa-pos": correl.value < 0,
                          })}
                        >
                          <p>
                            {correl.value > 0 && "+"}
                            {correl.value}
                          </p>
                        </div>
                      </div>
                      <div className="si-rds">
                        <p>
                          (Rounds {rounds[0] + 1} - {rounds[1] + 1})
                        </p>
                      </div>
                    </div>

                    <div
                      className="correl-del"
                      onClick={() => {
                        setCorrels((c) => {
                          c.splice(i, 1);
                          return c;
                        });
                        setUpdate((c) => c + 1);
                      }}
                    >
                      <DeleteForever className="red-icon-bg icon-cursor" />
                    </div>
                  </div>
                );
              })}
            </div>{" "}
          </div>

          <div className="correl-graphs bss-white bs-subtle">
            <Chart
              options={proptions}
              series={series}
              type={"line"}
              height={320}
            />
          </div>
        </>
      )}
      {/* <h2 className="def-h2">Create your own</h2> */}
    </div>
  );
}

export default StatsCorrelation;
