import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

// Components
import { Dialog } from "@mui/material";
import BackdropLoader from "../../Loaders/BackdropLoader";
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { errDefault } from "../../HelperFunctions";
import { toast } from "react-toastify";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import Pill from "../../Buttons/Pill";
import { HighlightOff } from "@mui/icons-material";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import PlayerListItem from "../../display/PlayerItem/PlayerListItem";
import classNames from "classnames";
import { successIco } from "../../ClientStats/HelperFunctions";

// Icons

function EvtPlayerRemoval({
  defaultPlayers = [],
  eventID = "",
  reload = () => {},
  close = () => {},
}) {
  // State
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [upd, setUpd] = useState(0);
  const [error, setError] = useState("");
  const [players, setPlayers] = useState(defaultPlayers);
  const [searchInput, setSearchInput] = useState("");

  const [sorter, setSorter] = useState("");
  const [sortDir, setSortDir] = useState("");
  const [viewType, setViewType] = useState("items");

  function update() {
    setUpd((c) => c + 1);
  }

  // UE
  useEffect(() => {
    console.log(defaultPlayers);
  }, []);

  // Funcs
  async function removePlayers() {
    setError("");
    setLoading(true);
    try {
      const evtDoc = await db.collection("events").doc(eventID).get();
      const event = evtDoc.data();
      const { playerIDs } = event;
      const filt = playerIDs.filter((id) => !selected.includes(id));

      await evtDoc.ref.update({
        playerIDs: filt,
      });
      reload();
      toast.success("Players successfully removed from event.", {
        icon: successIco,
      });
      close();
    } catch (err) {
      console.log(err);
      setError(errDefault);
    }

    setLoading(false);
  }

  function sortPlayers({ sorter = "", dir = "" }) {
    setSorter(sorter);
    setSortDir(dir);
    if (dir === "up") {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => a[sorter] - b[sorter]);
        } else {
          c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
        }
        return c;
      });
    } else {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => b[sorter] - a[sorter]);
        } else {
          c.sort((a, b) => b[sorter].localeCompare(a[sorter]));
        }
        return c;
      });
    }
    update();
  }

  return (
    <div className="evt-player-removal">
      <Dialog open onClose={close}>
        <div className="epr-ctt">
          {loading && <BackdropLoader />}
          <PageHeader text="Remove Players" showClose close={close} />
          <div className="ph-msg">
            <p>Select players to remove from this event</p>
          </div>
          <div className="ig-group mb-20 mt-20">
            <div className="input-group mt-0 si-search">
              {/* <p>Search</p> */}
              <div className="flex-align-center">
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
                <HighlightOff
                  className="icon-cursor red-icon-bg ml-20 ipm-search-ico"
                  onClick={() => setSearchInput("")}
                />
              </div>
            </div>
            <div className="ml-20">
              <Pill
                text={"Select All"}
                onClick={() => {
                  const ids = players.map((player) => {
                    return player.playerID;
                  });
                  setSelected((current) => [...ids]);
                  update();
                }}
              />
            </div>

            <Pill
              red
              text={"Deselect All"}
              onClick={() => {
                setSelected((current) => []);
              }}
            />
            <RadioGroupTwo
              active={viewType}
              buttons={[
                {
                  value: "items",
                  text: "Items",
                },
                {
                  value: "list",
                  text: "List",
                },
              ]}
              setActive={() => {
                setViewType((c) => {
                  if (c === "list") {
                    return "items";
                  } else {
                    return "list";
                  }
                });
              }}
            />
          </div>

          {error !== "" && (
            <div className="error-message-row">
              <p>{error}</p>
            </div>
          )}
          <div className="pl-20 flex-center">
            <button
              disabled={selected.length === 0}
              onClick={() => {
                removePlayers();
              }}
              className=" delete-button"
            >
              Remove {selected.length}{" "}
              {selected.length === 1 ? "Player" : "Players"}
            </button>
          </div>

          <div
            style={{
              justifyContent:
                players.length > 2 ? "space-between" : "flex-start",
            }}
            className="im-players mpa-players"
          >
            {viewType === "list" && (
              <>
                <div className="player-list-item pli-head pb-20">
                  <div
                    onClick={() => {
                      if (sorter === "firstName") {
                        if (sortDir === "up") {
                          sortPlayers({ sorter: "firstName", dir: "down" });
                        } else {
                          sortPlayers({ sorter: "firstName", dir: "up" });
                        }
                      } else {
                        sortPlayers({ sorter: "firstName", dir: "down" });
                      }
                    }}
                    className={classNames("pli-item", {
                      "plih-sel-up": sorter === "firstName" && sortDir === "up",
                      "plih-sel-dw": sorter === "firstName" && sortDir !== "up",
                    })}
                  >
                    <p>First Name</p>
                  </div>
                  <div
                    onClick={() => {
                      if (sorter === "lastName") {
                        if (sortDir === "up") {
                          sortPlayers({ sorter: "lastName", dir: "down" });
                        } else {
                          sortPlayers({ sorter: "lastName", dir: "up" });
                        }
                      } else {
                        sortPlayers({ sorter: "lastName", dir: "down" });
                      }
                    }}
                    className={classNames("pli-item", {
                      "plih-sel-up": sorter === "lastName" && sortDir === "up",
                      "plih-sel-dw": sorter === "lastName" && sortDir !== "up",
                    })}
                  >
                    <p>Last Name</p>
                  </div>
                  <div
                    onClick={() => {
                      if (sorter === "handicap") {
                        if (sortDir === "up") {
                          sortPlayers({ sorter: "handicap", dir: "down" });
                        } else {
                          sortPlayers({ sorter: "handicap", dir: "up" });
                        }
                      } else {
                        sortPlayers({ sorter: "handicap", dir: "down" });
                      }
                    }}
                    className={classNames("pli-item", {
                      "plih-sel-up": sorter === "handicap" && sortDir === "up",
                      "plih-sel-dw": sorter === "handicap" && sortDir !== "up",
                    })}
                  >
                    <p>Hcp</p>
                  </div>
                  <div className="pli-item">
                    <p>Selected</p>
                  </div>
                </div>
              </>
            )}
            {players.map((player) => {
              if (searchInput !== "") {
                const sl = searchInput.toLowerCase();
                if (!player.name.toLowerCase().includes(sl)) {
                  return null;
                }
              }

              if (viewType === "list") {
                return (
                  <PlayerListItem
                    player={player}
                    key={`remove${player.playerID}`}
                    selected={selected.includes(player.playerID)}
                    select={() => {
                      setSelected((current) => {
                        if (current.includes(player.playerID)) {
                          const filt = current.filter(
                            (uid) => uid !== player.playerID
                          );
                          return filt;
                        } else {
                          return [...current, player.playerID];
                        }
                      });
                    }}
                  />
                );
              } else {
                return (
                  <PlayerItem
                    noLink
                    showProfile={() =>
                      setSelected((current) => {
                        if (current.includes(player.playerID)) {
                          const filt = current.filter(
                            (uid) => uid !== player.playerID
                          );
                          return filt;
                        } else {
                          setSearchInput("");
                          return [...current, player.playerID];
                        }
                      })
                    }
                    hcp={player.handicap}
                    img={player.profileImage}
                    name={player.name}
                    playerID={player.playerID}
                    hideIcons
                    showBg={selected.includes(player.playerID)}
                  />
                );
              }
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default EvtPlayerRemoval;
