import React from "react";
import "./StatsItem.css";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

function StatsItem({
  stat,
  title = "",
  comp,
  better = false,
  grey = false,
  hideComp = false,
}) {
  return (
    <div
      className={classNames("stats-item flex flex-align-center", {
        "si-grey": grey && !isMobile,
      })}
    >
      <div className="si-tit">
        <h3>{title}</h3>
      </div>
      <div className="si-right">
        <div className="si-sta">
          <p>{isNaN(stat) && typeof stat !== "string" ? "-" : stat}</p>
        </div>
        {comp !== undefined && !hideComp && (
          <div className={better ? "si-comp sic-bet" : "sic-wor si-comp"}>
            <p>({comp})</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default StatsItem;
