import React from "react";
import "./NewsPill.css";
function NewsPill({ text, handleClick = () => {}, active = false }) {
  return (
    <div
      onClick={() => handleClick()}
      className={active ? "news-pill news-pill-active" : "news-pill"}
    >
      <p>{text}</p>
    </div>
  );
}

export default NewsPill;
