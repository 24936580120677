import React, { useEffect, useState } from "react";
import "./CupScoreBlocks.css";

function CupScoreBlocks({
  teamOneColor = "",
  teamOnePoints = 0,
  teamTwoColor = "",
  teamTwoPoints = 0,
}) {
  const [pointsArray, setPointsArray] = useState([]);

  useEffect(() => {
    sortScores();
  }, [teamOnePoints, teamTwoPoints]);

  function sortScores() {
    const totalPoints = teamOnePoints + teamTwoPoints;
    const arr = [];
    for (let i = 0; i < teamOnePoints; i++) {
      arr.push("teamOne");
    }
    for (let i = 0; i < teamTwoPoints; i++) {
      arr.push("teamTwo");
    }
    // console.log(arr);
    setPointsArray(arr);
  }

  return (
    <div className="cup-score-blocks">
      {/* <div className="csp-left">
        <h3 style={{ color: teamOneColor }}>{teamOnePoints}</h3>
      </div> */}
      <div className="csb-mid">
        {pointsArray.map((point, i) => (
          <div
            key={`${i}${point}`}
            style={{
              backgroundColor:
                point === "teamOne" ? teamOneColor : teamTwoColor,
              flex: 1 / pointsArray.length,
            }}
            className="csp-block"
          ></div>
        ))}
      </div>
      {/* <div className="csb-right">
        <h3 style={{ color: teamTwoColor }}>{teamTwoPoints}</h3>
      </div> */}
    </div>
  );
}

export default CupScoreBlocks;
