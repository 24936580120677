import React, { useState } from "react";
import { db } from "../../../firebase";
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components
import Chart from "react-apexcharts";
import { Autocomplete, TextField } from "@mui/material";
import CustomDropdown from "../CustomDropdown";
import moment from "moment";
import BackdropLoader from "../../Loaders/BackdropLoader";

const meters = true;

const yLabels = {
  "Stroke Average": "Strokes",
  "Average Putts": "Putts",
  "Average Putt Length": "Distance",
  "Driving Distance": "Distance",
  Scrambling: "Percentage",
  "Birdie Conversion": "Percentage",
  "Average GIR": "Percentage",
  "Average FIR": "Percentage",
  "Par 3 Averages": "Strokes",
  "Par 4 Averages": "Strokes",
  "Par 5 Averages": "Strokes",
  "Last 12 Months": "Last 12 Mths",
  "Last 20 Rounds": "Scores",
  columns: "To Par",
  putting: "Putts",
  puttHoles: "Putts by hole",
  parAverages: "Average",
  firAverages: "Average",
  girAverages: "Average",
  puttLengths: "Putt Lengths",
  misses: "Missed",
};
function ClientCourseGraphs({
  rounds = [],
  stats = [],
  overallStats = {},
  overallGrossStats = {},
  grossStats = [],
  showRd = () => {},
  userID = "",
}) {
  // Admin
  const graphOpts = [
    {
      label: "Line",
      value: "line",
    },
    {
      label: "Column",
      value: "column",
    },
    {
      label: "Pie",
      value: "pie",
    },
  ];

  const statOpts2 = {
    line: [
      {
        label: "Gross Strokes",
        value: "grossScore",
        type: "Rounds",
      },
      {
        label: "Net Strokes",
        value: "netScore",
        type: "Rounds",
      },
      {
        label: "Stableford Points",
        value: "totalPoints",
        type: "Rounds",
      },
      {
        label: "Total Putts",
        value: "totalPutts",
        type: "Rounds",
      },
      {
        label: "1 Putts",
        value: "onePutts",
        type: "Rounds",
      },
      {
        label: "Three Putts",
        value: "threePutts",
        type: "Rounds",
      },
      {
        label: "Fairways Hit",
        value: "totalFIR",
        type: "Rounds",
      },
      {
        label: "Greens Hit",
        value: "totalGIR",
        type: "Rounds",
      },
      {
        label: "Par 3s To Par",
        value: "parThreesToPar",
        type: "Rounds",
        gross: true,
      },
      {
        label: "Par 4s To Par",
        value: "parFoursToPar",
        type: "Rounds",
        gross: true,
      },
      {
        label: "Par 5s To Par",
        value: "parFivesToPar",
        type: "Rounds",
        gross: true,
      },
      {
        label: "Par 3s",
        value: "averageParThrees",
        type: "Rounds",
        gross: true,
      },
      {
        label: "Par 4s",
        value: "averageParFours",
        type: "Rounds",
        gross: true,
      },
      {
        label: "Par 5s",
        value: "averageParFives",
        type: "Rounds",
        gross: true,
      },
      {
        label: "Par 3s (GIR)",
        value: "parThreeGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 4s (GIR)",
        value: "parFourGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 5s (GIR)",
        value: "parFiveGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 4s (FIR)",
        value: "parFourGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 5s (FIR)",
        value: "parFiveGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Birdie Conversion",
        value: "birdieConversion",
        type: "Rounds",
      },
      {
        label: "Scrambling",
        value: "scramblePerc",
        type: "Rounds",
      },
    ],
    column: [
      {
        label: "Pars to Par",
        value: "parsToPar",
      },
      {
        label: "Last 10 Rounds",
        value: "last10",
      },
      //   {
      //     label: "Last 12 Months",
      //     value: "last12",
      //   },
    ],
    pie: [
      {
        label: "Putting",
        value: "putting",
      },
    ],
  };

  // State
  const [type, setType] = useState("line");
  const [selStat, setSelStat] = useState("");
  const [selStats, setSelStats] = useState([]);
  const [series, setSeries] = useState([]);
  const [puttDist, setPuttDist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [barSeries, setBarSeries] = useState([]);
  const [pieSeries, setPieSeries] = useState([]);
  const [barLabels, setBarLabels] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ]);
  const [dates, setDates] = useState([]);

  const proptions = {
    chart: {
      height: 350,
      type,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(config);
          const idx = config.dataPointIndex;
          // console.log(idx);
          const roundID = rounds[idx].roundID;
          console.log(roundID);
          loadRd(roundID);
          // showRd(round);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    colors: ["#6ba390", "#232c2e", "#A9363F"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: "date",
      categories: dates,
    },
    yaxis: {
      title: {
        text: selStat.label,
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      intersect: true,
      shared: false,
    },
  };

  const pieProp = {
    options: {
      colors:
        selStat.value === "putting"
          ? ["#6ba390", "#232c2e", "#A9363F"]
          : ["#FFD700", "#1e7a69", "#A9363F", "#232c2e"],
      dataLabels: {
        enabled: true,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      chart: {
        width: 400,
        type: "pie",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      legend: {
        position: "bottom",
      },
      labels:
        selStat.value === "Finishes"
          ? ["Wins", "Top 3", "Top 10", "Top 20"]
          : ["1-putts", "2-putts", "3-putts"],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };

  const barProp = {
    series: [
      {
        name: "PRODUCT A",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "PRODUCT B",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "PRODUCT C",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "PRODUCT D",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      colors: ["#6ba390", "#db5461", "#232c2e"],
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        stackType: "100",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: false,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      //   plotOptions: {
      //     bar: {
      //       colors: {
      //         ranges: [
      //           {
      //             from: -100,
      //             to: -46,
      //             color: "#F15B46",
      //           },
      //           {
      //             from: -45,
      //             to: 0,
      //             color: "#FEB019",
      //           },
      //         ],
      //       },
      //       columnWidth: "80%",
      //     },
      //   },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          distributed:
            (selStat.value && selStat.value.includes("Averages")) ||
            (selStat.value && selStat.value === "misses") ||
            (selStat.value && selStat.value.includes("parsToPar")),
          colors: {
            ranges:
              selStat.value &&
              (selStat.value.includes("Dista") ||
                selStat.value.includes("parsToPar"))
                ? []
                : selStat.value === "puttHoles"
                ? [
                    {
                      from: 1,
                      to: 2,
                      color: "#6ba390",
                    },
                    {
                      from: 2,
                      to: 3,
                      color: "#000000",
                    },
                    {
                      from: 3,
                      to: 5,
                      color: "#db5461",
                    },
                  ]
                : [
                    {
                      from: -2,
                      to: -1,
                      color: "#209E73",
                    },
                    {
                      from: -1,
                      to: 0,
                      color: "#6ba390",
                    },
                    {
                      from: 0,
                      to: 1,
                      color: "#db5461",
                    },
                    {
                      from: 2,
                      to: 5,
                      color: "#A9363F",
                    },
                  ],
          },
        },
      },
      xaxis: {
        categories:
          selStat.value && selStat.value.includes("Averages")
            ? ["Par 3s", "Par 4s", "Par 5s"]
            : selStat.value === "misses"
            ? ["Left", "FIR", "Right"]
            : selStat.value === "puttLengths"
            ? ["< 5", "5-10", "11-20", "21-40", "> 40"]
            : barLabels,
        labels: {
          rotate: -90,
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            // console.log(value);
            return value;
            // return value.toFixed(0);
          },
        },
        // min: -2,
        ...(type === "columns" && { max: 2 }),
        title: {
          text: yLabels[selStat.value] ?? selStat.label,
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  // Funcs
  function sortLineGraph() {
    const srs = [];
    const dateArr = [];
    for (let i = 0; i < selStats.length; i++) {
      srs.push({
        name: selStats[i].label,
        data: [],
      });
    }

    const statArr = [...stats];
    const rdArr = [...rounds];
    const ct = parseInt(rounds.length);
    statArr.reverse();
    rdArr.reverse();
    for (let i = 0; i < ct; i++) {
      for (let j = 0; j < selStats.length; j++) {
        const stat = selStats[j];
        console.log(stat.gross);

        const shot = rdArr[i];
        if (j === 0) {
          const { roundDate } = shot;
          const ds = new Date(roundDate.toDate()).toLocaleDateString("en-us", {
            dateStyle: "medium",
          });
          dateArr.push(ds);
        }
        // console.log(shot, stat.value);

        if (stat.value === "scramblePerc") {
          const val =
            Math.round(
              (shot.stats.totalGrossScrambles /
                shot.stats.totalGrossScrambleAttempts) *
                100
            ) / 100;
          srs[j].data.push(val);
        } else if (stat.value === "birdieConversion") {
          const val =
            Math.round(
              (grossStats[i].totalBirdies / shot.stats.totalGIR) * 100
            ) / 100;
          srs[j].data.push(val);
        } else {
          if (stat.gross) {
            srs[j].data.push(grossStats[i][stat.value]);
          } else {
            srs[j].data.push(
              isNaN(shot.stats[stat.value]) ? 0 : shot.stats[stat.value]
            );
          }
        }
      }
    }

    dateArr.reverse();
    for (let i = 0; i < selStats.length; i++) {
      srs[i].data.reverse();
    }

    console.log(dateArr, srs);
    setDates(dateArr);
    setSeries(srs);
  }

  function sortPieGraph() {
    if (selStat.value === "putting") {
      const puttArr = [
        overallStats.onePutts,
        overallStats.totalPutts -
          overallStats.onePutts -
          overallStats.threePutts,
        overallStats.threePutts,
      ];
      console.log(puttArr);
      setPieSeries(puttArr);
    }
  }

  function sortBarGraph() {
    if (selStat.value === "parsToPar") {
      const arr = [
        Math.round((overallGrossStats.averageParThrees - 3) * 100) / 100,
        Math.round((overallGrossStats.averageParFours - 4) * 100) / 100,
        Math.round((overallGrossStats.averageParFives - 5) * 100) / 100,
      ];
      console.log(arr);
      setBarSeries([
        {
          name: "Gross",
          data: arr,
        },
      ]);
      setBarLabels(["Par 3s", "Par 4s", "Par 5s"]);
    }
    if (selStat.value === "parsToParGIR") {
      alert("HERE");
      const arr = [
        overallStats.parThreeAverage,
        overallStats.parFoursToParGIR,
        overallStats.parFivesToParGIR,
      ];
      console.log(arr);
      setBarSeries([
        {
          name: "Gross",
          data: arr,
        },
      ]);
      setBarLabels(["Par 3s", "Par 4s", "Par 5s"]);
    }

    if (selStat.label === "Last 10 Rounds") {
      // console.log(hcpArray);
      const arr = [
        {
          name: "Net",
          data: [],
        },
        {
          name: "Gross",
          data: [],
        },
      ];
      const length = rounds.length > 10 ? 10 : rounds.length;
      const dates = [];
      for (let i = 0; i < length; i++) {
        const round = rounds[i];
        const { grossScore, netScore } = round;
        const dateObj = new Date(round.roundDate.toDate()).toLocaleDateString(
          "en-us",
          { dateStyle: "medium" }
        );
        dates.push(dateObj);
        arr[1].data.push(grossScore);
        arr[0].data.push(netScore);
      }
      console.log(dates, arr);
      setBarLabels(dates);
      setBarSeries(arr);
    }

    if (selStat.label === "Last 12 Months") {
      const monthArr = [];
      for (let i = 0; i < 12; i++) {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        const month = date.toLocaleDateString("en-us", { month: "narrow" });
        monthArr.push(month);
      }
      monthArr.reverse();
      const monthRounds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < rounds.length; i++) {
        const round = rounds[i];
        const roundDate = new Date(round.date);
        const roundMoment = moment(roundDate);
        const now = new Date();
        now.setDate(30);
        const nowMoment = moment(now);
        const diff = nowMoment.diff(roundMoment, "months");

        if (diff < 12) {
          monthRounds[diff] += 1;
        }
      }
      monthRounds.reverse();
      console.log(monthArr, monthRounds);
      setBarLabels(monthArr);
      setBarSeries([{ name: "Rounds Played", data: monthRounds }]);
      //   return setUpdate((current) => current + 1);
    }
  }

  async function loadRd(roundID) {
    console.log(roundID);
    setLoading(true);
    const rdDoc = await db.collection("rounds").doc(roundID).get();
    const scoreDoc = await rdDoc.ref.collection("scores").doc(userID).get();
    const scoreData = scoreDoc.data();
    const rdData = rdDoc.data();

    const { date, courseName, courseID } = rdData;
    const dateString = new Date(date.toDate()).toLocaleDateString("en-us", {
      dateStyle: "full",
    });
    scoreData.courseName = courseName;
    scoreData.courseID = courseID;
    // scoreData.notes = rdData.notes ?? "";

    scoreData.dateString = dateString;
    scoreData.roundID = roundID;
    scoreData.clientID = userID;

    console.log(scoreData);
    showRd(scoreData);
    setLoading(false);
  }

  return (
    <div className="cc-graphs">
      <div className="neh-box">
        <h2 className="ne-header">Graph Builder</h2>
      </div>
      {loading && <BackdropLoader />}
      <div className="bss-white bs-subtle flex flex-wrap flex-align-center pd-10">
        <div className="input-group">
          <p>Graph Type</p>
          <CustomDropdown
            value={type}
            opts={graphOpts}
            handleChange={(e) => setType(e.value)}
          />
        </div>{" "}
        {type === "line" && (
          <div className="input-group">
            <p>Statistic (max 3)</p>
            {/* <CustomDropdown
                value={selStat}
                opts={statOpts[type] ?? []}
                handleChange={(e) => {
                  console.log(e);
                  setSelStat(e);
                }}
              /> */}
            <Autocomplete
              //   groupBy={(op) => op.type}
              value={selStats}
              options={statOpts2[type]}
              multiple
              placeholder="Select..."
              renderInput={(params) => (
                <TextField
                  className="ac-tf"
                  placeholder="Select..."
                  {...params}
                />
              )}
              onChange={(e, v) => {
                console.log(v);
                if (v.length === 4) {
                  return;
                }
                setSelStats(v);
              }}
              popupIcon={<KeyboardArrowDownIcon className="drop-ico" />}
              sx={{ width: "20vw" }}
            />
          </div>
        )}
        {type === "pie" && (
          <div className="input-group">
            <p>Statistic</p>
            <CustomDropdown
              value={selStat}
              opts={statOpts2[type] ?? []}
              handleChange={(e) => {
                console.log(e);
                setSelStat(e);
              }}
            />
          </div>
        )}
        {type === "column" && (
          <div className="input-group">
            <p>Statistic</p>
            <CustomDropdown
              value={selStat}
              opts={statOpts2[type] ?? []}
              handleChange={(e) => {
                console.log(e);
                setSelStat(e);
              }}
            />
          </div>
        )}
        <button
          disabled={
            (type === "line" && selStats.length === 0) ||
            (type === "pie" && selStat === "")
          }
          onClick={() => {
            if (type === "line") {
              sortLineGraph();
            }
            if (type === "pie") {
              sortPieGraph();
            }
            if (type === "column") {
              sortBarGraph();
            }
          }}
          className="default-button small-btn"
        >
          Generate
        </button>
      </div>

      <div className="bs-subtle bss-white mt-20">
        {type === "line" && (
          <Chart options={proptions} series={series} type={type} height={320} />
        )}
        {type === "pie" && (
          <Chart
            options={pieProp.options}
            series={pieSeries}
            type={type}
            height={400}
          />
        )}
        {type === "column" && (
          <Chart
            options={barProp.options}
            series={barSeries}
            type={"bar"}
            height={320}
          />
        )}
      </div>
    </div>
  );
}

export default ClientCourseGraphs;
