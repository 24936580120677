import React, { useState } from "react";
import "./StatsSelector.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PageHeader from "../display/Rows/PageHeader";
function StatsSelector({
  goBack = () => {},
  selected = [],
  setSelected = () => {},
}) {
  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(0);

  const options = [
    {
      title: "Distance to Pin",
      description:
        "Capture your distance to the pin when landing on the green in regulation on Par 3s.",
    },
    {
      title: "Driving Distance",
      description:
        "Capture your driving distances on all Par 5s. Whether you hit the fairway or not. So you may as well put your back out trying.",
    },
    {
      title: "Putt Length",
      description: "Capture your total distance in terms of putts made",
    },
    {
      title: "Greenside Bunkers",
      description:
        "Capture greenside bunkers hit to keep track of those sandies!",
    },
    {
      title: "Fairway Misses",
      description:
        "Capture whether fairways were missed to the left or right, to establish more of a pattern of play.",
    },
    {
      title: "Lost Balls",
      description:
        "Keep track of how many balls get lost during the course of the round",
    },
    // {
    //   title: "",
    //   description: "",
    // },
  ];
  const GradientIcon = () => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <CheckCircleIcon
        className="ps-icon dg-icon"
        sx={{ fill: "url(#linearColors)" }}
      />
    </>
  );

  return (
    <div className="stats-selector">
      <div
        onClick={() => {
          goBack();
        }}
        className="back-row"
      >
        <ArrowBackIcon className="ts-arrow" />
        <p>Back</p>
      </div>

      <PageHeader text="Enabled Stats" />

      <div className="rules-container">
        {options.map((stat) => {
          const found = selected.includes(stat.title);

          return (
            <div
              onClick={() => {
                // alert("clacked");
                setSelected((current) => {
                  if (!found) {
                    // Add rule
                    console.log("adding");
                    return [...current, stat.title];
                  } else {
                    // Remove rule
                    return current.filter((item) => item !== stat.title);
                  }
                });
                console.log(selected);
                setUpdate((current) => current + 1);
              }}
              className={found ? "rule-item ri-sel" : "rule-item"}
            >
              <div className="ri-left">
                {found && (
                  <div className="svg-cont">
                    <GradientIcon style={{ fill: "#21c17c" }} />
                  </div>
                )}
                {!found && <CancelIcon style={{ fill: "#c6c7c8" }} />}
              </div>
              <div className="ri-right">
                <h2>{stat.title}</h2>
                <p>{stat.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StatsSelector;
