import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  drillCategories,
  drillDifficulties,
  sortDrillsByDifficulty,
} from "../HelperFunctions";
import "./Drills.css";
import { toast } from "react-toastify";

// Icons
import { CheckIcon, DividerHorizontalIcon } from "@radix-ui/react-icons";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackdropLoader from "../../Loaders/BackdropLoader";
import DrillCreator from "./DrillCreator";
import { Tabs, Tab } from "@mui/material";
import DropMenu from "../DropMenu/DropMenu";
import DrillItem from "./DrillItem";
import ErrorModal from "../../ErrorModal/ErrorModal";
import * as Checkbox from "@radix-ui/react-checkbox";
import CustomDropdown from "../CustomDropdown";
import classNames from "classnames";
import Pill from "../../Buttons/Pill";
import DrillDetails from "./DrillDetails";
import AdminDrillCreator from "./AdminDrillCreator";

const sortOpts = [
  { label: "Difficulty (Easy to Hard)", value: "difficultyUp", dir: "up" },
  { label: "Difficulty (Hard to Easy)", value: "difficultyDn", dir: "dn" },
  { label: "Popularity", value: "popularity", dir: "dn" },
];
function AdminDrills() {
  // Admin
  const navigate = useNavigate();
  const { currentUser, userDetails } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [presetDrills, setPresetDrills] = useState([]);
  // const [clubID, setClubID] = useState("");
  const [error, setError] = useState("");
  const [upd, setUpd] = useState(0);
  const [sortOpt, setSortOpt] = useState("");
  function update() {
    setUpd((c) => c + 1);
  }

  const [search, setSearch] = useState("");
  const [selDiffs, setSelDiffs] = useState(drillDifficulties);
  const [selCats, setSelCats] = useState(drillCategories);

  const [pageToShow, setPageToShow] = useState();
  const [showPage, setShowPage] = useState(false);

  // UE
  useEffect(() => {
    console.log(userDetails);
    if (userDetails === undefined) {
      navigate("/");
    } else {
      if (userDetails) {
        if (userDetails.test || userDetails.admin) {
          loadPresetDrills();
        } else {
          navigate("/");
        }
      }
    }
  }, [userDetails]);

  // Funcs
  function sortArrs(sorter) {
    if (sorter === "difficultyUp") {
      setPresetDrills((c) => {
        const sorted = sortDrillsByDifficulty(c, "up");
        return sorted;
      });
    }
    if (sorter === "difficultyDn") {
      setPresetDrills((c) => {
        const sorted = sortDrillsByDifficulty(c, "dn");
        return sorted;
      });
    }
    if (sorter === "popularity") {
      setPresetDrills((c) => {
        c.sort((a, b) => b.attempts.length - a.attempts.length);
        return c;
      });
    }
  }
  async function loadPresetDrills() {
    const presetDrills = await db
      .collection("admin")
      .doc("academy")
      .collection("drills")
      .get();
    const arr = [];

    for (let i = 0; i < presetDrills.docs.length; i++) {
      const drillDoc = presetDrills.docs[i];
      const drill = drillDoc.data();
      arr.push(drill);
    }
    const sortedArr = sortDrillsByDifficulty(arr);
    console.log(sortedArr);
    setPresetDrills(sortedArr);
    setLoading(false);
  }
  async function deleteDrill(drillID) {
    setLoading(true);
    try {
      await db
        .collection("admin")
        .doc("academy")
        .collection("drills")
        .doc(drillID)
        .delete();

      setPresetDrills((c) => {
        const filt = c.filter((d) => d.drillID !== drillID);
        return filt;
      });
      toast.success("Drill successfully deleted.");
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficult there - please try again."
      );
    }
    setLoading(false);
  }
  return (
    <div className="admin-drills">
      {loading && <BackdropLoader />}
      {showPage ? (
        pageToShow
      ) : (
        <>
          <PageHeader showBack={true} back={() => navigate(-1)} text="Drills" />
          <div className="quick-links">
            <Pill
              text={"Add Drill"}
              onClick={() => {
                setPageToShow(
                  <AdminDrillCreator back={() => setShowPage(false)} />
                );
                setShowPage(true);
              }}
            />
          </div>
          <div className="ac-dri-box pd-20">
            <div className="ad-filters flex wbg pd-20">
              <div className="input-group mr-20">
                <p>Search by title</p>
                <input
                  value={search}
                  className="default-input"
                  placeholder="Start typing..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="input-group mr-20">
                <p>Sort By</p>
                <CustomDropdown
                  opts={sortOpts}
                  value={sortOpt}
                  handleChange={(e) => {
                    setSortOpt(e.value);
                    console.log(e);
                    sortArrs(e.value);
                  }}
                />
              </div>
              {/* </div>
            <div className="ad-filters flex flex- wbg pd-20"> */}
              <div className="input-group">
                <p>Difficulties</p>
                <div className="flex flex-align-center flex-wrap ac-dr-ch mt-10">
                  {drillDifficulties.map((diff, i) => {
                    const checked = selDiffs.includes(diff);

                    return (
                      <div
                        className="cao-item "
                        onClick={() => {
                          setSelDiffs((c) => {
                            if (c.includes(diff)) {
                              const filt = c.filter((it) => it !== diff);
                              return filt;
                            } else {
                              c.push(diff);
                              return c;
                            }
                          });
                          update();
                        }}
                        key={diff}
                      >
                        <Checkbox.Root
                          className="CheckboxRoot"
                          checked={selDiffs.includes(diff)}
                          onCheckedChange={() => {
                            // setSelDiffs((c) => {
                            //   if (c.includes(diff)) {
                            //     const filt = c.filter((it) => it !== diff);
                            //     return filt;
                            //   } else {
                            //     c.push(diff);
                            //     return c;
                            //   }
                            // });
                            // update();
                          }}
                        >
                          <Checkbox.Indicator className="CheckboxIndicator">
                            {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                            {checked === true && <CheckIcon />}
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <p>{diff}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="input-group">
                <p>Categories</p>
                <div className="flex flex-align-center flex-wrap ac-dr-ch mt-10">
                  {drillCategories.map((cat, i) => {
                    const checked = selCats.includes(cat);

                    return (
                      <div
                        className="cao-item "
                        onClick={() => {
                          setSelCats((c) => {
                            if (c.includes(cat)) {
                              const filt = c.filter((it) => it !== cat);
                              console.log(filt);
                              return filt;
                            } else {
                              c.push(cat);
                              return c;
                            }
                          });
                          update();
                        }}
                        key={cat}
                      >
                        <Checkbox.Root
                          className="CheckboxRoot"
                          checked={selCats.includes(cat)}
                          onCheckedChange={() => {
                            // setSelCats((c) => {
                            //   if (c.includes(cat)) {
                            //     const filt = c.filter((it) => it !== cat);
                            //     console.log(filt);
                            //     return filt;
                            //   } else {
                            //     c.push(cat);
                            //     return c;
                            //   }
                            // });
                            // update();
                          }}
                        >
                          <Checkbox.Indicator className="CheckboxIndicator">
                            {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                            {checked === true && <CheckIcon />}
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <p>{cat}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={classNames("wbg drill-box flex flex-wrap pd-20")}>
              {presetDrills.map((drill, i) => {
                const { title, category, difficulty } = drill;

                if (search !== "") {
                  const sl = search.toLowerCase();
                  const tl = title.toLowerCase();

                  // console.log(category, selCats);

                  if (!tl.includes(sl)) {
                    return null;
                  }
                }

                if (!selCats.includes(category)) {
                  return null;
                }

                if (!selDiffs.includes(difficulty)) {
                  return null;
                }

                return (
                  <DrillItem
                    // addAct={() => addToLibrary(drill)}
                    key={drill.drillID}
                    viewAct={() => {
                      setPageToShow(
                        <DrillDetails
                          // clubID={clubID}
                          goBack={() => setShowPage(false)}
                          id={drill.drillID}
                        />
                      );
                      setShowPage(true);
                    }}
                    drill={drill}
                    showAdd={false}
                    custom
                    showRemove
                    editAct={() => {
                      setPageToShow(
                        <AdminDrillCreator
                          back={() => setShowPage(false)}
                          // defaultclubID={clubID}
                          edit
                          // defaultClubID={clubID}
                          defaults={drill}
                          // reload={loadClubDrills}
                        />
                      );
                      setShowPage(true);
                    }}
                    removeAct={() => deleteDrill(drill.drillID)}
                    // showAdd={!acDrillIDs.includes(drill.drillID)}
                    //   clubID={clubID}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AdminDrills;
