import React, { useState, useEffect } from "react";

// Icons

// Components
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackdropLoader from "../Loaders/BackdropLoader";
import { db } from "../../firebase";
import Pill from "../Buttons/Pill";
import MatchdaySelector from "./MatchdaySelector";
import FixturePreview from "../display/FixturePreview/FixturePreview";
import MatchdayCreator from "./MatchdayCreator";

function MatchdayHome({ clubID = "", goBack = () => {}, reload = () => {} }) {
  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [matchdayID, setMatchdayID] = useState("");

  const [events, setEvents] = useState([]);

  const [started, setStarted] = useState(false);

  const [details, setDetails] = useState({
    name: "",
  });

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    const matchDocs = await db
      .collection("matchdays")
      .where("clubID", "==", clubID)
      .get();
    const matchday = matchDocs.docs[0].data();
    const matchdayID = matchDocs.docs[0].id;
    setMatchdayID(matchdayID);
    const dateObj = new Date(matchday.matchDate.toDate());
    const dateString = dateObj.toLocaleDateString("en-us", {
      dateStyle: "full",
    });
    matchday.matchdayID = matchdayID;
    matchday.dateObj = dateObj;
    matchday.dateString = dateString;

    const { eventIDs } = matchday;
    if (matchday.activeEventIDs.length > 0) {
      setStarted(true);
    }
    console.log(eventIDs);
    const refs = eventIDs.map((eventID) =>
      db.collection("events").doc(eventID).get()
    );
    const docs = await Promise.all(refs);

    const arr = [];

    for (let i = 0; i < docs.length; i++) {
      const eventDoc = docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      console.log(event);
      const dateObj = new Date(event.eventDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });

      event.eventID = eventID;
      event.dateObj = dateObj;
      event.dateString = dateString;
      arr.push(event);
    }

    arr.sort((a, b) => a.dateObj - b.dateObj);

    setDetails(matchday);
    setEvents(arr);

    setLoading(false);
  }

  return (
    <div className="event-home matchday-home">
      {show ? (
        toShow
      ) : (
        <>
          {loading && <BackdropLoader />}
          <BackRow action={goBack} />
          <PageHeader text={details.name} />
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          <div className="quick-links">
            <Pill
              text={"Select Events"}
              onClick={() => {
                setToShow(
                  <MatchdaySelector
                    clubID={clubID}
                    goBack={() => setShow(false)}
                    matchdayID={matchdayID}
                    reload={loadDetails}
                  />
                );
                setShow(true);
              }}
            />
            <Pill
              text={"Edit Details"}
              onClick={() => {
                setToShow(
                  <MatchdayCreator
                    clubID={clubID}
                    disableChanges={started}
                    edit
                    goBack={() => setShow(false)}
                    matchdayID={matchdayID}
                    reload={loadDetails}
                  />
                );
                setShow(true);
              }}
            />
            {true && (
              <Pill
                text={"View Link "}
                onClick={() => {
                  window.open(
                    `https://www.theugatour.com/matchdays/${matchdayID}`,
                    "_blank"
                  );
                }}
              />
            )}
          </div>
          <div className="sub-header">
            <h5>Selected Events</h5>
          </div>
          <div className="md-events ms-events">
            {events.length === 0 && !loading && (
              <div className="ph-msg mt-20">
                <p>No events have been selected</p>
              </div>
            )}
            {events.map((event, index) => {
              return (
                <FixturePreview key={event.eventID} hideIcons event={event} />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default MatchdayHome;
