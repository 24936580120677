import React from "react";
import "./TimeItem.css";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

function TimeItem({
  players = [],
  time,
  tee,
  types = "",
  showBG = false,
  selectable = false,
  unselectable = false,
  onClick = () => {},
  name = "",
}) {
  return (
    <div
      style={{
        backgroundColor: unselectable
          ? "#edefef"
          : showBG
          ? "rgb(199, 239, 222)"
          : "white",
      }}
      onClick={onClick}
      className={selectable ? "time-item ti-select" : "time-item"}
    >
      <div className="time-row">
        {/* <AccessTimeIcon className="tt-icon" /> */}
        <h1 style={{ marginRight: tee ? "10px" : "0px" }}>{time}</h1>
        {tee && <h1>Hole {tee}</h1>}
        {name !== "" && (
          <h1 className="ml-10" style={{ marginLeft: "10px" }}>
            {name}
          </h1>
        )}
      </div>

      <div className="ti-content">
        <div className="ti-left">
          <div className="players-container">
            {players.map((player) => {
              if (typeof player === "string") {
                return <p key={player}>{player}</p>;
              } else {
                return <p key={`time${player.playerID}`}>{player.name}</p>;
              }
            })}
            {players.length === 0 && (
              <p>
                {types === "leaderboard"
                  ? "Group to be determined via leaderboard"
                  : types === "random,"
                  ? "Group will be randomized"
                  : "Group still to be confirmed"}
              </p>
            )}
          </div>
        </div>
        <div className="ti-right"></div>
      </div>
    </div>
  );
}

export default TimeItem;
