import React, { useCallback, useEffect, useState } from "react";
import { db } from "../../../firebase";
import Papa from "papaparse";

// Icons

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import { useDropzone } from "react-dropzone";
import BackdropLoader from "../../Loaders/BackdropLoader";
import csvexample from "../../../assets/PlayerExample.csv";
import BackdropProgress from "../../Loaders/BackdropProgress";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import _, { round } from "lodash";
function LiveGroupUpload({
  roundID = "",
  clubID = "",
  close = () => {},
  reload = () => {},
}) {
  function makeID(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const [uploadJSON, setUploadJSON] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  const [players, setPlayers] = useState([]);

  const [teeTimes, setTeeTimes] = useState([]);

  const [error, setError] = useState("");

  const [progging, setProgging] = useState(false);
  const [prog, setProg] = useState(0);
  const [update, setUpdate] = useState(0);

  const [showGroups, setShowGroups] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        const arr = [];
        const headerRow = res.data[0];
        let plCol = "";
        let idCol = "";
        let timeCol = "";
        let roundCol = "";
        let teeColl = "";
        let gidColl = "";
        // console.log(headerRow);
        for (let i = 0; i < headerRow.length; i++) {
          if (headerRow[i] === "platform") {
            plCol = i;
          } else if (headerRow[i] === "platformID") {
            idCol = i;
          } else if (headerRow[i] === "teeTime") {
            timeCol = i;
          } else if (headerRow[i] === "round") {
            roundCol = i;
          } else if (headerRow[i] === "startingTee") {
            teeColl = i;
          }
        }

        if (timeCol !== "") {
          setShowGroups(true);
        } else {
          setShowGroups(false);
        }

        if (plCol === "" || idCol === "") {
          return setError(
            "Sorry, we couldn't quite sort that out - please check your column headers!"
          );
        }

        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];
          let rnd = row[roundCol];
          if (rnd === "") {
            rnd = 1;
          }

          // console.log(guest);

          arr.push({
            platform: row[plCol],
            platformID: row[idCol],
            teeTime: row[timeCol] ?? "",
            round: rnd,
            startingTee: row[teeColl] ?? 1,
          });
        }
        const show = timeCol !== "";
        // console.log(arr);
        loadPlayers(arr, show);
        setUploadJSON(arr);
      },
    });
  }, []);

  useEffect(() => {
    console.log(clubID);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  function compareTimes(timeA, timeB) {
    const [hoursA, minutesA] = timeA.split(":");
    const [hoursB, minutesB] = timeB.split(":");
    return (
      new Date(0, 0, 0, hoursA, minutesA) - new Date(0, 0, 0, hoursB, minutesB)
    );
  }

  async function loadPlayers(arr = [], show = false) {
    let plarr = [];
    // console.log(show);
    // setLoading(true);
    setProgging(true);
    try {
      setProg(0);
      setUpdate((current) => current + 1);

      const clubDoc = await db.collection("clubs").doc(clubID).get();
      setProg(40);
      setUpdate((current) => current + 1);

      const fdArr = [];
      const idArr = [];

      const clubData = clubDoc.data();
      const { memberIDs } = clubData;
      const refs = memberIDs.map((id, index) => {
        // console.log(index);
        return db.collection("users").doc(id).get();
      });
      const docs = await Promise.all(refs);
      setProg(70);
      // console.log(docs.length);

      const guests = arr.filter((player) => player.guest === true);
      // console.log(guests);

      for (let i = 0; i < docs.length; i++) {
        const pr = 50 + ((i / docs.length) * 100) / 20;
        // console.log(pr);
        setProg((i / docs.length) * 100);
        setUpdate((current) => current + 1);
        // console.log((i / docs.length) * 100);
        const playerDoc = docs[i];
        const playerID = playerDoc.id;
        const player = playerDoc.data();
        if (!playerDoc.exists) {
          continue;
        }
        const { firstName, lastName, profileImage } = player;
        let found = false;
        if (i === 0) {
          //   console.log(platformIDs);
        }

        const platformIDs = player.platformIDs ?? [];

        for (let n = 0; n < arr.length; n++) {
          if (found) {
            continue;
          }
          const row = arr[n];
          // console.log(row);
          const { platform, platformID, handicap } = row;

          if (i === 0) {
            // console.log(row);
          }
          for (let x = 0; x < platformIDs.length; x++) {
            const plRow = platformIDs[x];
            const rowPlatform = plRow.platform;
            const rowPlatformID = plRow.platformID;
            if (rowPlatform === platform && rowPlatformID === platformID) {
              if (idArr.includes(playerID)) {
                continue;
              }
              idArr.push(playerID);
              if (row.teeTime) {
                const teeTime = row.teeTime;
                const startingTee = row.startingTee ?? 1;
                const round = row.round ?? 1;
                plarr.push({
                  playerID,
                  name: `${firstName} ${lastName}`,
                  platform,
                  platformID,
                  handicap: handicap ?? player.handicap,
                  teeTime,
                  startingTee,
                  round,
                  profileImage,
                });
              } else {
                plarr.push({
                  playerID,
                  name: `${firstName} ${lastName}`,
                  platform,
                  platformID,
                  handicap,
                  profileImage,
                });
              }

              found = true;
              fdArr.push(n);
            }
          }
        }

        if (!found) {
          // console.log(playerID);
        }
      }
      // console.log(plarr);
      plarr = _.uniq(plarr);
      // console.log(plarr);
      for (let i = 0; i < guests.length; i++) {
        guests[i].playerID = guests[i].guestID;
        if (guests[i].handicap === "") {
          guests[i].handicap = 20;
        }
      }

      plarr = [...plarr, ...guests];
      plarr.sort((a, b) => compareTimes(a.teeTime, b.teeTime));

      if (show) {
        const groups = [];
        for (let i = 0; i < plarr.length; i++) {
          const player = plarr[i];
          const { playerID, handicap } = player;
          const playerHcp = player.handicap;

          if (player.teeTime) {
            const { teeTime, startingTee } = player;
            let found = false;
            for (let x = 0; x < groups.length; x++) {
              const group = groups[x];
              const { time } = group;
              if (time === teeTime && startingTee === group.startingTee) {
                const { name, handicap, playerID, profileImage } = player;
                groups[x].players.push({
                  name,
                  handicap: handicap === "" ? playerHcp : handicap,
                  profileImage,
                  playerID,
                  startingTee,
                });
                found = true;
              }
            }

            if (!found) {
              const { name, handicap, playerID, profileImage } = player;

              const players = [];
              players.push({
                name,
                handicap: handicap === "" ? playerHcp : handicap,
                profileImage,
                playerID,
                startingTee,
              });
              groups.push({
                time: teeTime,
                startingTee,
                players,
              });
            }
          }
        }
        // console.log(groups);
        setTeeTimes(groups);
      }
      fdArr.sort((a, b) => a - b);
      // console.log(fdArr);
      // console.log(plarr);
      setPlayers(plarr);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
    setProgging(false);
  }

  async function savePlayers() {
    setLoading(true);

    // Players
    const groups = [];

    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { playerIDs } = round;

    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      // console.log(player);
      const { playerID } = player;

      if (!playerIDs.includes(playerID)) {
        continue;
      }

      if (player.teeTime) {
        const rN = player.round ?? 1;
        const num = rN - 1;
        const { teeTime, startingTee } = player;
        let found = false;
        for (let x = 0; x < groups.length; x++) {
          const group = groups[x];
          const { time } = group;
          if (time === teeTime && startingTee === group.startingTee) {
            const { name, handicap, playerID, profileImage } = player;
            groups[x].players.push({
              name,
              handicap,
              profileImage,
              playerID,
              startingTee,
            });
            found = true;
          }
        }

        if (!found) {
          const { name, handicap, playerID, profileImage } = player;

          const players = [];
          players.push({
            name,
            handicap,
            profileImage,
            playerID,
            startingTee,
          });
          groups.push({
            time: teeTime,
            startingTee,
            players,
          });
        }
      }
    }

    // console.log(playerIDs, rounds);
    // console.log(playerHandicaps);
    console.log(groups);
    // return setLoading(false);

    try {
      await db.collection("rounds").doc(roundID).update({
        teeTimes: groups,
      });
      reload();
      close();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }

    setLoading(false);
  }

  return (
    <div className="live-group-upload">
      <BackRow action={close} />
      <PageHeader text="Tee Sheet Upload" />
      {loading && <BackdropLoader />}

      {progging && <BackdropProgress prog={prog} />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <div className="ph-msg mb-20">
        <p>Upload players using the Player ID system.</p>
      </div>

      <div className="bi-box">
        <div className="pd-20 bi-drop">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-sel">
              {fileName ? (
                <h5 className="filename">{fileName}</h5>
              ) : (
                <h5>
                  Drag 'n' drop a .csv file here, or click to open up the file
                  explorer
                </h5>
              )}
            </div>
          </div>
        </div>
        <div className="bi-info">
          <p>
            In order for the players to be added correctly, formatting is
            important but simple! Only a few fields are needed, the platform
            name and the relevant platform ID, as well as other optional fields.{" "}
            <br />
            <br />
            <strong>The first row (header row) will be included.</strong> Name
            your colums as follows (letter case is important):
          </p>
          <ul>
            <li>platform</li>
            <li>platformID</li>
            <li>teeTime</li>
            <li>startingTee</li>
          </ul>
          <a href={csvexample} className="green-link mt-20">
            Download Sample
          </a>
          <br />
          <br />
          <p>
            Please note that guests and any players that are not part of this
            event will be ignored.
          </p>
        </div>
      </div>

      {players.length > 0 && (
        <>
          <div className="sub-header">
            <h5>Selected Players ({players.length})</h5>
          </div>
          <div className="drop-groups">
            {teeTimes.map((group, i) => {
              return (
                <div
                  key={i}
                  className={
                    group.completed ? "drop-group dg-comp" : "drop-group"
                  }
                >
                  <div className="group-header">
                    <div className="gh-flex">
                      <h2 className="ne-header">
                        Group {i + 1} - {group.time} - Tee: {group.startingTee}
                      </h2>
                    </div>
                  </div>
                  <div className="dg-players">
                    {group.players.map((player, i2) => {
                      if (player.name === "Empty") {
                        return null;
                      }
                      return (
                        <RoundPlayer
                          guest={player.playerID.includes("guest")}
                          hideVert
                          hideTee
                          name={player.name}
                          tee={player.tee}
                          handicap={player.handicap}
                          key={player.playerID}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {uploadJSON.length > 0 && (
        <div className="pd-20">
          <button onClick={savePlayers} className="default-button">
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default LiveGroupUpload;
