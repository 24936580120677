import React, { useCallback, useState } from "react";
import { db } from "../../../firebase";
import Papa from "papaparse";

// Icons

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import { useDropzone } from "react-dropzone";
import BackdropLoader from "../../Loaders/BackdropLoader";
import csvexample from "../../../assets/TeamExample.csv";
import { makeID } from "../../HelperFunctions";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";
function EventTeamUpload({
  eventID = "",
  clubID = "",
  close = () => {},
  league = false,
  leagueID = "",
  reload = () => {},
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [upload, setUpload] = useState([]);
  const [uploadJSON, setUploadJSON] = useState([]);
  const [players, setPlayers] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        const arr = [];
        const headerRow = res.data[0];
        let plCol = "";
        let idCol = "";
        let teamCol = "";
        // console.log(headerRow);
        for (let i = 0; i < headerRow.length; i++) {
          if (headerRow[i] === "platform") {
            plCol = i;
          } else if (headerRow[i] === "platformID") {
            idCol = i;
          } else if (headerRow[i] === "teamNumber") {
            teamCol = i;
          }
        }

        if (plCol === "" || idCol === "" || teamCol === "") {
          return setError(
            "Sorry, we couldn't quite sort that out - please check your column headers!"
          );
        }

        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];
          //   console.log(row[teamCol]);
          arr.push({
            platform: row[plCol],
            platformID: row[idCol],
            teamNumber: row[teamCol],
          });
        }
        console.log(arr);
        loadPlayers(arr);
        setUpload(res);
        setUploadJSON(arr);
      },
    });
  }, []);

  async function loadPlayers(arr = []) {
    const plarr = [];

    setLoading(true);
    try {
      //   console.log(clubID);
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const clubData = clubDoc.data();
      const { memberIDs } = clubData;
      const refs = memberIDs.map((id) => db.collection("users").doc(id).get());

      const docs = await Promise.all(refs);

      //   console.log(docs.length);

      for (let i = 0; i < docs.length; i++) {
        const playerDoc = docs[i];
        const playerID = playerDoc.id;
        const player = playerDoc.data();
        if (!playerDoc.exists) {
          continue;
        }
        const { firstName, lastName, profileImage, handicap } = player;
        let found = false;
        if (i === 0) {
          //   console.log(platformIDs);
        }

        const platformIDs = player.platformIDs ?? [];

        for (let n = 0; n < arr.length; n++) {
          if (found) {
            continue;
          }
          const row = arr[n];
          //   console.log(row);
          const { platform, platformID } = row;

          // if (i === 0) {
          //   console.log(row);
          // }
          for (let x = 0; x < platformIDs.length; x++) {
            const plRow = platformIDs[x];
            const rowPlatform = plRow.platform;
            const rowPlatformID = plRow.platformID;
            const teamNumber = row.teamNumber;
            if (rowPlatform === platform && rowPlatformID === platformID) {
              if (row.teeTime) {
                plarr.push({
                  playerID,
                  name: `${firstName} ${lastName}`,
                  platform,
                  platformID,
                  handicap,
                  profileImage,
                  teamNumber,
                });
              } else {
                plarr.push({
                  playerID,
                  name: `${firstName} ${lastName}`,
                  platform,
                  platformID,
                  handicap,
                  profileImage,
                  firstName,
                  lastName,
                  teamNumber,
                });
              }

              found = true;
            }
          }
        }
      }
      console.log(plarr);
      plarr.sort((a, b) => a.teamNumber - b.teamNumber);
      setPlayers(plarr);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  async function saveLeaguePlayers() {
    setLoading(true);
    try {
      const temp = [];
      const lD = await db.collection("leagues").doc(leagueID).get();
      const ld = lD.data();
      const teams = ld.teams ?? [];
      for (let i = 0; i < players.length; i++) {
        const player = players[i];
        const { teamNumber } = player;
        // console.log(teamNumber, temp);
        if (temp[teamNumber - 1] === undefined) {
          temp.push({
            players: [],
            points: 0,
            teamID: makeID(15),
            played: 0,
          });
        }
        temp[teamNumber - 1].players.push(player);
      }

      const newTeams = [...teams, ...temp];

      // setLoading(false);
      // return console.log(newTeams);

      await db.collection("leagues").doc(leagueID).update({
        teams: newTeams,
      });
      toast.success("Teams successfully uploaded", {
        icon: successIco,
      });
      close();
    } catch (err) {
      console.log(err);

      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function savePlayers() {
    setLoading(true);
    try {
      const eventDoc = await db.collection("events").doc(eventID).get();
      const event = eventDoc.data();
      const { playerIDs } = event;
      const teams = event.teams ?? [];
      const temp = [];

      console.log(teams);

      for (let i = 0; i < players.length; i++) {
        const player = players[i];
        const { playerID } = player;
        if (!playerIDs.includes(playerID)) {
          console.log(`does not include ${player}`);
          continue;
        }
        const { teamNumber } = player;
        console.log(teamNumber, temp);
        if (temp[teamNumber - 1] === undefined) {
          temp.push({
            players: [],
          });
        }
        temp[teamNumber - 1].players.push(player);
      }

      const newTeams = [...teams, ...temp];
      console.log(newTeams);
      //   return setLoading(false);

      await eventDoc.ref.update({
        teams: temp,
      });
      reload();
      close();
    } catch (err) {
      console.log(err);

      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="event-player-upload">
      <BackRow action={close} />
      <PageHeader text="Team Upload" />
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <div className="ph-msg">
        <p>
          Upload players from a different platform to set up teams for this
          event. Please note only members from your club can be added.
        </p>
      </div>

      <div className="bi-box">
        <div className="pd-20 bi-drop">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-sel">
              {fileName ? (
                <h5 className="filename">{fileName}</h5>
              ) : (
                <h5>
                  Drag 'n' drop a .csv file here, or click to open up the file
                  explorer
                </h5>
              )}
            </div>
          </div>
        </div>
        <div className="bi-info">
          <p>
            In order for the players to be added correctly, formatting is
            important but simple! Only three fields are needed, the platform
            name, the relevant platform ID and the team number. <br />
            <br />
            <strong>The first row (header row) will be included.</strong> Name
            your colums as follows (letter case is important):
          </p>
          <ul>
            <li>platform</li>
            <li>platformID</li>
            <li>teamNumber</li>
          </ul>
          <a href={csvexample} className="green-link mt-20">
            Download Sample
          </a>
          <p className="mt-20">
            Please note that players that have not been added to the tournament
            yet will be ignored.
          </p>
        </div>
      </div>

      {players.length > 0 && (
        <div className="epu-players mt-20">
          <div className="sub-header">
            <h5>Selected Teams</h5>
          </div>
          <div className="epup-box">
            {players.map((player, index) => (
              <div key={player.playerID} className={"flex-align-center"}>
                <p style={{ width: "30vw" }}>{player.name} </p>
                <p> {`Team: ${player.teamNumber}`}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {uploadJSON.length > 0 && (
        <div className="pd-20">
          <button
            onClick={league ? saveLeaguePlayers : savePlayers}
            className="default-button"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default EventTeamUpload;
