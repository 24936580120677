/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./CupScore.css";
import MatchplayScore from "../../display/MatchplayScore/MatchplayScore";
import MatchplayHoles from "../../display/MatchplayHoles/MatchplayHoles";
import CupNassau from "../CupNassau/CupNassau";
import NassauScore from "../../display/NassauScore/NassauScore";
import CupScoreBlocks from "../../display/CupScoreBlocks/CupScoreBlocks";
import MatchplayHoleSlider from "../../display/MatchplayScore/MatchplayHoleSlider";

function CupScore({
  matches = [],
  format = "",
  cupScore,
  score,
  teams = [],
  nassau = false,
  roundID = "",
  teamOneColor = "#1e7a69",
  teamTwoColor = "#21c17c",
  pointsToWin = 0,
}) {
  const [scoreState, setScoreState] = useState([]);
  const [cupScoreState, setCupScoreState] = useState([]);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    sortScores();
  }, [score]);

  useEffect(() => {
    console.log(cupScore);
    setUpdate((current) => current + 1);
  }, [score, cupScore]);

  function sortScores() {
    const scoreArr = [];
    const cupScoreArr = [];

    // console.log(teams);
    const teamOne = teams[0].name;
    const teamTwo = teams[1].name;

    scoreArr[0] = [teamOne, score[teamOne]];
    scoreArr[1] = [teamTwo, score[teamTwo]];

    cupScoreArr[0] = [teamOne, cupScore[teamOne]];
    cupScoreArr[1] = [teamTwo, cupScore[teamTwo]];

    // for (const [key, value] of Object.entries(score)) {
    //     scoreArr.push([key, value])
    // }

    // for (const [key, value] of Object.entries(cupScore)) {
    //     cupScoreArr.push([key, value])
    // }

    setScoreState(scoreArr);
    setCupScoreState(cupScoreArr);

    // console.log(scoreArr, cupScoreArr);
  }

  return (
    <div className="cup-score scale-in-ps">
      {show ? (
        toShow
      ) : (
        <>
          {scoreState.length > 0 && (
            <>
              <div
                style={{
                  color: "blue",
                  backgroundImage: `linear-gradient(45deg, ${teamOneColor} 0%, ${teamTwoColor} 100%)`,
                }}
                className="cs-details"
              >
                <div className="score-details">
                  <div className="s-d-left">
                    <h2>
                      {cupScoreState[0][0]}{" "}
                      <span style={{ marginRight: "10px" }} />
                      {cupScoreState[0][1]}
                    </h2>
                  </div>
                  <div className="s-d-mid">
                    <h2>-</h2>
                  </div>
                  <div className="s-d-right">
                    <h2>
                      {cupScoreState[1][1]}
                      <span style={{ marginRight: "10px" }} />
                      {cupScoreState[1][0]}
                    </h2>
                  </div>
                </div>
                <p className="today-score">Today</p>
                <div className="score-details sd-today">
                  <div className="s-d-left">
                    <h2>{scoreState[0][1]}</h2>
                  </div>
                  <div className="s-d-mid">
                    <h2>-</h2>
                  </div>
                  <div className="s-d-right">
                    <h2>{scoreState[1][1]} </h2>
                  </div>
                </div>
                {pointsToWin !== 0 && (
                  <div className="points-win">Points to Win: {pointsToWin}</div>
                )}
              </div>
              <CupScoreBlocks
                teamOneColor={teamOneColor}
                teamTwoColor={teamTwoColor}
                teamOnePoints={cupScoreState[0][1]}
                teamTwoPoints={cupScoreState[1][1]}
              />
            </>
          )}

          <div className="c-s-notice">
            <p>Tap on a match to view more</p>
          </div>

          <div className="c-s-box">
            {nassau &&
              matches.map((match, index) => {
                if (format === "Matchplay") {
                  const p1 = match.playerOne;
                  const p2 = match.playerTwo;
                  const t1 = match.playerOne.playerID;
                  const t2 = match.playerTwo.playerID;
                  let t1Points = 0;
                  let t2Points = 0;

                  if (match.leader === t1) {
                    t1Points += 1;
                  } else if (match.leader === t2) {
                    t2Points += 1;
                  } else if (match.thru > 0) {
                    t1Points += 0.5;
                    t2Points += 0.5;
                  }

                  if (match.frontNine === t1) {
                    t1Points += 1;
                  } else if (match.frontNine === t2) {
                    t2Points += 1;
                  } else if (match.frontNine !== undefined && match.thru > 0) {
                    t1Points += 0.5;
                    t2Points += 0.5;
                  }

                  if (match.backNine === t1) {
                    t1Points += 1;
                  } else if (match.backNine === t2) {
                    t2Points += 1;
                  } else if (match.backNine !== undefined && match.thru > 9) {
                    t1Points += 0.5;
                    t2Points += 0.5;
                  }

                  return (
                    <React.Fragment key={match.matchID}>
                      <div className="ns-header">
                        <h3 className="ne-header">Match {index + 1}</h3>
                      </div>
                      {/* <MatchplayScore
                        key={index}
                        idOne={p1.playerID}
                        idTwo={p2.playerID}
                        nameOne={p1.name}
                        nameTwo={p2.name}
                        holes={match.thru ?? 0}
                        leader={match.leader}
                        score={match.score}
                      /> */}
                      <CupNassau
                        idOne={match.playerOne.playerID}
                        idTwo={match.playerTwo.playerID}
                        teamOneBG={teamOneColor}
                        teamTwoBG={teamTwoColor}
                        holeArray={match.holes}
                        handleClick={() => {
                          setToShow(
                            <NassauScore
                              teamOneColor={teamOneColor}
                              teamTwoColor={teamTwoColor}
                              holes={match.thru ?? 0}
                              idOne={t1}
                              idTwo={t2}
                              leader={match.leader}
                              leaderboard={match}
                              goBack={() => setShow(false)}
                              nameOne={p1.name}
                              nameTwo={p2.name}
                              score={match.score}
                              showBack
                              roundID={roundID}
                              matchNum={index}
                            />
                          );
                          setShow(true);
                        }}
                        key={index}
                        nameOne={p1.name}
                        nameTwo={p2.name}
                        holes={match.thru ?? 0}
                        leader={match.leader ?? ""}
                        teamOne={t1Points}
                        teamTwo={t2Points}
                      />
                    </React.Fragment>
                  );
                } else {
                  const t1 = match.teamOne;
                  const t2 = match.teamTwo;
                  let t1Points = 0;
                  let t2Points = 0;

                  if (match.leader === "teamOne") {
                    t1Points += 1;
                  } else if (match.leader === "teamTwo") {
                    t2Points += 1;
                  } else if (match.thru > 0) {
                    t1Points += 0.5;
                    t2Points += 0.5;
                  }

                  if (match.frontNine === "teamOne") {
                    t1Points += 1;
                  } else if (match.frontNine === "teamTwo") {
                    t2Points += 1;
                  } else if (match.frontNine !== undefined && match.thru > 0) {
                    t1Points += 0.5;
                    t2Points += 0.5;
                  }

                  if (match.backNine === "teamOne") {
                    t1Points += 1;
                  } else if (match.backNine === "teamTwo") {
                    t2Points += 1;
                  } else if (match.backNine !== undefined && match.thru > 9) {
                    t1Points += 0.5;
                    t2Points += 0.5;
                  }

                  return (
                    <React.Fragment key={match.matchID}>
                      <div className="ns-header">
                        <h3 className="ne-header">Match {index + 1}</h3>
                      </div>
                      <CupNassau
                        idOne="teamOne"
                        idTwo="teamTwo"
                        teamOneBG={teamOneColor}
                        teamTwoBG={teamTwoColor}
                        holeArray={match.holes}
                        handleClick={() => {
                          // console.log(teamOneColor, teamTwoColor);
                          setToShow(
                            <NassauScore
                              teamOneColor={teamOneColor}
                              teamTwoColor={teamTwoColor}
                              holes={match.thru ?? 0}
                              idOne={"teamOne"}
                              idTwo={"teamTwo"}
                              leader={match.leader}
                              leaderboard={match}
                              goBack={() => setShow(false)}
                              nameOne={`${t1[0].name} / ${t1[1].name}`}
                              nameTwo={`${t2[0].name} / ${t2[1].name}`}
                              score={match.score}
                              showBack
                              roundID={roundID}
                              matchNum={index}
                            />
                          );
                          setShow(true);
                        }}
                        key={index}
                        nameOne={`${t1[0].name} / ${t1[1].name}`}
                        nameTwo={`${t2[0].name} / ${t2[1].name}`}
                        holes={match.thru ?? 0}
                        leader={match.leader ?? ""}
                        teamOne={t1Points}
                        teamTwo={t2Points}
                      />
                    </React.Fragment>
                  );
                }
              })}
            {!nassau &&
              matches.map((match, index) => {
                if (format === "Matchplay") {
                  const p1 = match.playerOne;
                  const p2 = match.playerTwo;
                  return (
                    <React.Fragment key={match.matchID}>
                      <div className="ns-header">
                        <h3 className="ne-header">Match {index + 1}</h3>
                      </div>
                      <MatchplayScore
                        key={index}
                        noMargin
                        idOne={p1.playerID}
                        idTwo={p2.playerID}
                        nameOne={p1.name}
                        teamOneColor={teamOneColor}
                        teamTwoColor={teamTwoColor}
                        nameTwo={p2.name}
                        holes={match.thru ?? 0}
                        leader={match.leader}
                        score={match.score}
                        handleClick={() => {
                          setToShow(
                            <MatchplayHoles
                              roundID={roundID}
                              goBack={() => setShow(false)}
                              showBack
                              matchNum={index}
                            />
                          );
                          setShow(true);
                        }}
                      />
                      <MatchplayHoleSlider
                        mb
                        teamOneColor={teamOneColor}
                        teamTwoColor={teamTwoColor}
                        idOne={match.playerOne.playerID}
                        idTwo={match.playerTwo.playerID}
                        holes={match.holes}
                      />
                    </React.Fragment>
                  );
                } else {
                  const t1 = match.teamOne;
                  const t2 = match.teamTwo;

                  return (
                    <React.Fragment key={match.matchID}>
                      <div className="ns-header">
                        <h3 className="ne-header">Match {index + 1}</h3>
                      </div>
                      <MatchplayScore
                        noMargin
                        key={index}
                        idOne={"teamOne"}
                        idTwo={"teamTwo"}
                        teamOneColor={teamOneColor}
                        teamTwoColor={teamTwoColor}
                        nameOne={`${t1[0].name} / ${t1[1].name}`}
                        nameTwo={`${t2[0].name} / ${t2[1].name}`}
                        holes={match.thru ?? 0}
                        leader={match.leader ?? ""}
                        score={match.score}
                        handleClick={() => {
                          setToShow(
                            <MatchplayHoles
                              roundID={roundID}
                              goBack={() => setShow(false)}
                              showBack
                              matchNum={index}
                            />
                          );
                          setShow(true);
                        }}
                      />
                      <MatchplayHoleSlider
                        teamOneColor={teamOneColor}
                        teamTwoColor={teamTwoColor}
                        idOne={"teamOne"}
                        idTwo={"teamTwo"}
                        holes={match.holes}
                      />
                    </React.Fragment>
                  );
                }
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default CupScore;
