import React, { useState, useEffect } from "react";

// Icons

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import BackRow from "../../components/display/Rows/BackRow";
import { db } from "../../firebase";
import PageHeader from "../../components/display/Rows/PageHeader";
import MatchplayScore from "../../components/display/MatchplayScore/MatchplayScore";

function getRound(currentRound, totalRounds) {
  const roundNames = [
    "First Round",
    "Round of 32",
    "Round of 16",
    "Quarter-finals",
    "Semi-Finals",
    "Final",
  ];

  // Check if the current round is within the range of the provided rounds
  if (currentRound > totalRounds || currentRound < 1) {
    return "Invalid round";
  }

  // Calculate the maximum number of rounds that can be represented with the available round names
  const maxRounds = roundNames.length;

  // Calculate the number of remaining rounds after the current round
  const remainingRounds = totalRounds - currentRound;

  // Calculate the index of the round name based on the remaining rounds
  const roundIndex = Math.min(maxRounds - remainingRounds, maxRounds - 1);

  // Return the corresponding round name
  return roundNames[roundIndex];
}

function MatchplayRounds({ tournID = "", goBack = () => {} }) {
  // Admin

  // State
  const [bracket, setBracket] = useState([]);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const [playerType, setPlayerType] = useState("singles");

  // UE
  useEffect(() => {
    loadRounds();
  }, []);

  // Funcs
  async function loadRounds() {
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tourn = tournDoc.data();

    const { tournamentType, roundIDs, playerType } = tourn;
    setType(tournamentType);
    setPlayerType(playerType);
    const roundRefs = roundIDs.map((id) =>
      db.collection("rounds").doc(id).get()
    );
    const roundDocs = await Promise.all(roundRefs);

    const arr = [];
    const mep = {};

    for (let i = 0; i < roundDocs.length; i++) {
      const roundDoc = roundDocs[i];
      if (!roundDoc.exists) {
        continue;
      }
      const roundID = roundDoc.id;
      const round = roundDoc.data();
      const date = new Date(round.date.toDate());
      const dateString = date.toLocaleDateString("en-us", {
        dateStyle: "long",
      });

      const { leaderboard } = round;
      const { leader, by, thru } = leaderboard;
      mep[roundID] = {
        leader,
        by,
        dateString,
        date,
        roundID,
        thru,
      };
    }

    if (tournamentType === "knockout") {
      const { bracket } = tourn;

      for (let i = 0; i < bracket.length; i++) {
        const matches = bracket[i].matches;
        for (let j = 0; j < matches.length; j++) {
          const match = matches[j];
          if (match.roundID) {
            bracket[i].matches[j].roundDetails = mep[match.roundID];
          }
        }
      }

      console.log(bracket);
      setBracket(bracket);
    }

    setLoading(false);
  }

  return (
    <div className="matchplay-rounds">
      <BackRow action={goBack} />
      <PageHeader text="Matchplay Tournament Rounds" />
      <div className="ph-msg">
        <p>Click on a match to view live scoring</p>
      </div>
      {loading && <BackdropLoader />}

      <div className="mpr-rds pd-20">
        {type === "knockout" &&
          bracket.map((round, i) => {
            const roundType = getRound(i + 1, bracket.length + 1);

            const matches = round.matches.filter(
              (match) => match.roundDetails !== undefined
            );

            if (matches.length === 0) {
              return null;
            }

            return (
              <div className="mpr-rd" key={i}>
                <div className="mpr-rd-head mb-10">
                  <h3>{roundType}</h3>
                </div>
                {matches.map((match) => {
                  if (playerType === "singles") {
                    const p1 = match.players[0];
                    const p2 = match.players[1];

                    const { roundDetails } = match;

                    return (
                      <div key={roundDetails.roundID} className="mpl-mps">
                        <p>Match {match.match}</p>
                        <MatchplayScore
                          handleClick={() =>
                            window.open(
                              `https://www.theugatour.com/rounds/${roundDetails.roundID}`
                            )
                          }
                          hcpOne={p1.handicap}
                          //   hideThru
                          idOne={p1.playerID}
                          idTwo={p2.playerID}
                          leader={roundDetails.leader}
                          score={roundDetails.by}
                          holes={roundDetails.thru}
                          hcpTwo={p2.handicap}
                          nameOne={p1.name}
                          nameTwo={p2.name}
                        />
                      </div>
                    );
                  } else {
                    const { teams } = match;
                    const { roundDetails } = match;

                    return (
                      <MatchplayScore
                        holes={roundDetails.thru}
                        score={roundDetails.by}
                        leader={roundDetails.leader}
                        nameOne={teams[0].name}
                        nameTwo={teams[1].name}
                        idOne={"teamOne"}
                        idTwo={"teamTwo"}
                        handleClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${roundDetails.roundID}`
                          )
                        }
                      />
                    );
                  }
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MatchplayRounds;
