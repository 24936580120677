import React, { useState, useEffect } from "react";

// Icons

// Components
import LogItem from "../../display/LeagueLogs/LogItem/LogItem";
import LogHeader from "../../display/LeagueLogs/LogItem/LogHeader";
import LeagueMemberJoined from "./LeagueMemberJoined";

import EclecticHeader from "../../Eclectic/EclecticHeader";
import LeagueTeamLog from "./LeagueTeamLog";

function LeagueEditHome({
  leaderboard = [],
  datesJoined = [],
  teams = [],
  eclectic = false,
}) {
  const [active, setActive] = useState("singles");

  return (
    <div className="league-edit-home">
      <div className="home-dash">
        <div className="hd-item">
          <div className="page-header">
            <h2>Points Table</h2>
          </div>
          {teams.length > 0 && (
            <div>
              <div className="selector-group">
                <button
                  onClick={() => setActive("singles")}
                  className={
                    active === "singles" ? "sg-active sg-right" : "sg-right"
                  }
                >
                  Singles
                </button>
                <button
                  onClick={() => setActive("teams")}
                  className={
                    active === "teams" ? "sg-middle sg-active" : "sg-middle"
                  }
                >
                  Teams
                </button>
              </div>
            </div>
          )}
          {active === "teams" ? (
            <>
              <LeagueTeamLog
                teams={teams}
                final={teams.length > 0 && teams[0].finalRank !== undefined}
              />
            </>
          ) : (
            <>
              {eclectic ? (
                <div className="leh-log">
                  <EclecticHeader />
                  {leaderboard.map((player, index) => {
                    return (
                      <LogItem
                        key={player.playerID}
                        rank={player.ranking ?? index + 1}
                        name={player.userName}
                        played={player.wins}
                        wins={player.gross}
                        points={player.net}
                        ave={player.stableford}
                        handleClick={() => {}}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="leh-log">
                  <LogHeader
                    final={
                      leaderboard.length > 0 &&
                      leaderboard[0].finalRank !== undefined
                    }
                  />
                  {leaderboard.map((player, index) => (
                    <LogItem
                      ave={
                        player.totalRounds === 0
                          ? player.totalRounds
                          : Math.round(player.points / player.totalRounds)
                      }
                      key={player.playerID}
                      name={player.userName}
                      rank={index + 1}
                      played={player.totalRounds}
                      points={player.points}
                      wins={player.wins}
                      hcp={player.handicap}
                      final={player.finalRank !== undefined}
                      fr={player.finalRank}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <div className="hd-item">
          <div className="page-header">
            <h2>Recent Members</h2>
          </div>
          <div className="recent-members">
            {datesJoined.map((player, index) => (
              <LeagueMemberJoined
                key={`${player.playerID}joined`}
                player={player}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeagueEditHome;
