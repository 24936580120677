import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import "react-dropdown/style.css";

// Icons
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AddCircle from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import { CSVLink, CSVDownload } from "react-csv";

import BackRow from "../../components/display/Rows/BackRow";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PageHeader from "../../components/display/Rows/PageHeader";
import { Dialog } from "@mui/material";
import Dropdown from "react-dropdown";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import Pill from "../../components/Buttons/Pill";
import { toast } from "react-toastify";
import { successIco } from "../../components/ClientStats/HelperFunctions";

function PlayerIDs({ clubID = "", goBack = () => {}, players = [] }) {
  const [showEdit, setShowEdit] = useState(false);
  const [toEdit, setToEdit] = useState();

  const [loading, setLoading] = useState(false);

  const [platformOptions, setPlatformOptions] = useState([]);

  const [update, setUpdate] = useState(0);

  const [members, setMembers] = useState(players);

  const [showNew, setShowNew] = useState(false);

  const [csvData, setCSVData] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  const [newOption, setNewOption] = useState("");
  const [newName, setNewName] = useState("");
  const [newID, setNewID] = useState("");

  const [changed, setChanged] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    filterPlatforms();
  }, []);

  useEffect(() => {
    convertToCSV();
  }, []);

  function convertToCSV() {
    const csvArr = [];
    const platforms = [];
    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const { platformIDs } = player;
      for (let x = 0; x < platformIDs.length; x++) {
        const pl = platformIDs[x];
        const { platform } = pl;
        if (!platforms.includes(platform)) {
          platforms.push(platform);
        }
      }
    }
    csvArr.push(["firstName", "lastName", "emailAddress"]);
    for (let i = 0; i < platforms.length; i++) {
      csvArr[0].push(platforms[i]);
    }
    // console.log(csvArr);
    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const { firstName, lastName, emailAddress } = player;

      const plarr = [firstName, lastName, emailAddress];
      const { platformIDs } = player;
      for (let x = 0; x < platforms.length; x++) {
        const defPlatform = platforms[x];
        let fd = false;
        for (let j = 0; j < platformIDs.length; j++) {
          const pl = platformIDs[j];
          const { platform, platformID } = pl;

          if (defPlatform === platform) {
            plarr.push(platformID);
            fd = true;
          }
        }
        if (!fd) {
          plarr.push("");
        }
      }
      if (i < 10) {
      }
      csvArr.push(plarr);
    }
    setCSVData(csvArr);
  }

  function filterPlatforms() {
    const arr = ["Custom"];
    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const platformIDs = player.platformIDs ?? [];
      for (let j = 0; j < platformIDs.length; j++) {
        const { platform } = platformIDs[j];
        if (!arr.includes(platform)) {
          arr.push(platform);
        }
      }
    }
    arr.sort((a, b) => a - b);
    setPlatformOptions(arr);
  }

  function resetValues() {
    setNewID("");
    setNewName("");
    setNewOption("");
    setShowNew(false);
    setChanged(false);
  }

  async function saveChanges() {
    // return;
    const { platformIDs } = toEdit;
    let hnaID = "";
    let clubHNAID = "";

    for (let i = 0; i < toEdit.platformIDs.length; i++) {
      if (toEdit.platformIDs[i].platform === "HNA ID") {
        hnaID = toEdit.platformIDs[i].platformID;
      }
      if (toEdit.platformIDs[i].platform === "HNA") {
        hnaID = toEdit.platformIDs[i].platformID;
      }
      if (toEdit.platformIDs[i].platform === "hnaID") {
        hnaID = toEdit.platformIDs[i].platformID;
      }
      if (toEdit.platformIDs[i].platform === "clubHNAID") {
        clubHNAID = toEdit.platformIDs[i].platformID;
      }
    }
    setShowEdit(false);
    setLoading(true);
    try {
      if (hnaID !== "") {
        await db.collection("users").doc(toEdit.playerID).update({
          platformIDs,
          // hnaID,
          clubHNAID,
        });
      } else {
        await db.collection("users").doc(toEdit.playerID).update({
          platformIDs,
          clubHNAID,
        });
      }
      setMembers((current) => {
        for (let i = 0; i < current.length; i++) {
          if (current[i].playerID === toEdit.playerID) {
            current[i].platformIDs = platformIDs;
          }
        }
        return current;
      });
      toast.success("Changes were successfully saved", {
        icon: successIco,
      });
    } catch (err) {
      setError(
        "Sorry - we encountered some difficulties there, please try again."
      );
    }
    setLoading(false);
    resetValues();
    setToEdit();
  }

  return (
    <div className="club-playerIDs">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      {loading && <BackdropLoader />}
      <Dialog open={showEdit} onClose={() => setShowEdit(false)}>
        <div className="cpid-dia">
          <PageHeader
            text="Edit Player IDs"
            showClose
            close={() => setShowEdit(false)}
          />
          <div className="ph-msg">
            <p>Please ensure to save any changes made</p>
          </div>
          {toEdit && (
            <div className="cpid-edit">
              <div className="sub-header">
                <h5>Edit {toEdit.name}'s UIDs</h5>

                {!showNew && (
                  <AddCircle
                    onClick={() => setShowNew(true)}
                    className="icon-cursor dg-icon-bg"
                  />
                )}
              </div>
              <div className="cpid-items">
                {toEdit.platformIDs.map((pl, index) => {
                  let platform = pl.platform;
                  let custom = false;
                  if (!platformOptions.includes(platform)) {
                    custom = true;
                  }

                  return (
                    <div key={platform} className="cpid-item pl-20">
                      <div className="input-group mr-20">
                        <p>Platform Type</p>
                        <Dropdown
                          arrowClosed={
                            <KeyboardArrowDownIcon className="drop-icon" />
                          }
                          arrowOpen={
                            <KeyboardArrowUpIcon className="drop-icon" />
                          }
                          value={custom ? "Custom" : platform}
                          options={platformOptions}
                          onChange={(e) => {
                            if (e.value === "Custom") {
                              setToEdit((current) => {
                                current.platformIDs[index].platform = "";
                                return current;
                              });
                              setUpdate((current) => current + 1);
                            } else {
                              setToEdit((current) => {
                                current.platformIDs[index].platform = e.value;
                                return current;
                              });
                              setUpdate((current) => current + 1);
                              setChanged(true);
                            }
                          }}
                        />
                      </div>
                      {custom && (
                        <div className="input-group mr-20">
                          <p>Custom Type</p>
                          <input
                            type="text"
                            placeholder="Enter type here..."
                            value={platform.platform}
                            onChange={(e) => {
                              setToEdit((current) => {
                                current.platformIDs[index].platform =
                                  e.target.value;
                                return current;
                              });
                              setUpdate((current) => current + 1);
                              setChanged(true);
                            }}
                            className="default-input"
                          />
                        </div>
                      )}
                      <div className="input-group">
                        <p>UID</p>
                        <input
                          placeholder="Enter ID here..."
                          type="text"
                          value={pl.platformID}
                          onChange={(e) => {
                            setToEdit((current) => {
                              current.platformIDs[index].platformID =
                                e.target.value;
                              return current;
                            });
                            setUpdate((current) => current + 1);
                            setChanged(true);
                          }}
                          className="default-input"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              {showNew && (
                <>
                  <div className="sub-header mt-20">
                    <h5>New ID</h5>
                  </div>
                  <div className="cpid-item cpid-new pl-20 m4-20">
                    <div className="input-group mr-20">
                      <p>Select Type</p>
                      <Dropdown
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        value={newOption}
                        options={platformOptions}
                        onChange={(e) => {
                          setNewOption(e.value);
                        }}
                      />
                    </div>
                    {newOption === "Custom" && (
                      <div className="input-group mr-20">
                        <p>Custom Type</p>
                        <input
                          type="text"
                          placeholder="Enter type here..."
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                          className="default-input"
                        />
                      </div>
                    )}
                    <div className="input-group">
                      <p>UID</p>
                      <input
                        placeholder="Enter ID here..."
                        type="text"
                        value={newID}
                        onChange={(e) => setNewID(e.target.value)}
                        className="default-input"
                      />
                    </div>
                    <SaveIcon
                      onClick={() => {
                        if (
                          !showNew ||
                          newOption === "" ||
                          newID === "" ||
                          (newOption === "Custom" && newName === "")
                        ) {
                          return;
                        }
                        setToEdit((current) => {
                          current.platformIDs.push({
                            platformID: newID,
                            platform:
                              newOption === "Custom" ? newName : newOption,
                          });
                          return current;
                        });
                        resetValues();
                        setChanged(true);
                      }}
                      className={
                        !showNew ||
                        newOption === "" ||
                        newID === "" ||
                        (newOption === "Custom" && newName === "")
                          ? "dg-icon-bg dib-dis mt-20 ml-20"
                          : "dg-icon-bg icon-cursor mt-20 ml-20"
                      }
                    />
                  </div>
                </>
              )}
              {changed && (
                <div className="flex-center mt-40 mb-40">
                  <button onClick={saveChanges} className="default-button">
                    Save
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>

      <BackRow action={goBack} />
      <PageHeader text="Manage Player IDs" />
      <div className="ph-msg">
        <p>
          Assign membership numbers or unique identifiers from other platforms
          to your members.
        </p>
      </div>
      {/* <div className="quick-links"> */}
      <div className="mt-20">
        <CSVLink data={csvData} className="default-button csv-sb mt-40 ml-20">
          Export CSV
        </CSVLink>
      </div>

      {/* </div> */}
      <div className="flex-center pids-search">
        <div className="input-group pd-20">
          <p>Search for player</p>
          <input
            placeholder="Enter name here..."
            className="default-input"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>

      <div className="pids-box-box">
        <div className="pids-box pd-20">
          <div className="cpi-row cpi-header">
            <p>Name</p>
            {platformOptions.map((platform, index) => {
              if (platform === "Custom") {
                return null;
              }
              return <h5 key={`${index}header`}>{platform}</h5>;
            })}
          </div>
          {members.map((player, index) => {
            if (searchInput !== "") {
              if (
                !player.name.toLowerCase().includes(searchInput.toLowerCase())
              ) {
                return null;
              }
            }
            const arr = player.platformIDs;
            const mep = {};
            arr.forEach((pl) => {
              mep[pl.platform] = pl.platformID;
            });
            return (
              <div key={player.playerID} className="cpi-row">
                <p>{player.name}</p>
                {platformOptions.map((platform, i2) => {
                  if (arr.length > 0) {
                    //   console.log(mep[platform]);
                  }
                  if (platform === "Custom") {
                    return null;
                  }
                  if (platform === "HNA ID") {
                    <h5
                      key={`${player.playerID}${i2}plat`}
                      className={`${player.playerID}${i2}plat`}
                    >
                      {mep["hnaID"]}
                    </h5>;
                  }
                  if (mep[platform]) {
                    return (
                      <h5
                        key={`${player.playerID}${i2}plat`}
                        className={`${player.playerID}${i2}plat`}
                      >
                        {mep[platform]}
                      </h5>
                    );
                  } else {
                    return (
                      <h5
                        key={`${player.playerID}${i2}plat`}
                        className={`${player.playerID}${i2}plat`}
                      >
                        -
                      </h5>
                    );
                  }
                })}
                <Edit
                  className="icon-cursor dg-icon hgb-ico ml-20"
                  onClick={() => {
                    setToEdit(player);
                    resetValues();
                    setShowEdit(true);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PlayerIDs;
