import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

// Components
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import Pill from "../Buttons/Pill";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";
function PlayerGroup({
  group = {},
  players = [],
  goBack = () => {},
  members = [],
  clubID = "",
  reload = () => {},
}) {
  const [showAdd, setShowAdd] = useState(false);
  const [selPlayers, setSelPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [toRemove, setToRemove] = useState({});
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [upd, setUpd] = useState(0);
  const [customPlayers, setCustomPlayers] = useState(players);
  function update() {
    setUpd((c) => c + 1);
  }

  async function saveChanges() {
    setShowAdd(false);
    setLoading(true);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();
      const { memberGroups } = club;

      for (let i = 0; i < memberGroups.length; i++) {
        if (memberGroups[i].groupID === group.groupID) {
          memberGroups[i].playerIDs = [
            ...memberGroups[i].playerIDs,
            ...selPlayers,
          ];
        }
      }

      //   console.log(memberGroups);
      //   return setLoading(false);

      await clubDoc.ref.update({
        memberGroups,
      });

      const arr = [];

      for (let i = 0; i < members.length; i++) {
        if (selPlayers.includes(members[i].playerID)) {
          arr.push(members[i]);
        }
      }
      setCustomPlayers((c) => [...c, ...arr]);
      setSelPlayers([]);
      toast.success("Changes successfully saved", {
        icon: successIco,
      });
      reload();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }
    setLoading(false);
  }

  async function removePlayer() {
    setShowRemove(false);
    setLoading(true);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();
      const { memberGroups } = club;

      for (let i = 0; i < memberGroups.length; i++) {
        if (memberGroups[i].groupID === group.groupID) {
          memberGroups[i].playerIDs = memberGroups[i].playerIDs.filter(
            (pl) => pl !== toRemove
          );
        }
      }
      await clubDoc.ref.update({
        memberGroups,
      });
      setCustomPlayers((c) => {
        const filt = c.filter((pl) => pl.playerID !== toRemove);
        return filt;
      });
      setToRemove("");
      toast.success("Player successfully removed from this group", {
        icon: successIco,
      });
      reload();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }
    setLoading(false);
  }

  return (
    <div className="player-group">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <BackRow action={goBack} />
      <PageHeader text={group.name} />

      <Dialog open={showRemove} onClose={() => setShowRemove(false)}>
        <div className="pg-dia-ctt">
          <PageHeader
            text="Remove Player"
            showClose
            close={() => setShowRemove(false)}
          />
          <div className="ltm-remove pl-20">
            <p>Are you sure you want to remove this player?</p>
          </div>
          <div className="flex flex-center mt-40 mb-20">
            <button onClick={() => removePlayer()} className="default-button ">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showAdd} onClose={() => setShowAdd(false)}>
        <div className="pg-dia-ctt">
          <PageHeader
            text="Add players"
            showClose
            close={() => setShowAdd(false)}
          />
          <div className="ph-msg">
            <p>Select players to add to this group</p>
          </div>

          <div className="input-group">
            <p>Search</p>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={"Start typing..."}
              className="default-input"
            />
          </div>

          <div className="mt-20">
            <button onClick={() => saveChanges()} className="default-button">
              Confirm
            </button>
          </div>

          <div className="pdc-pls">
            {members.map((player, i) => {
              if (search !== "") {
                if (!player.name.toLowerCase().includes(search.toLowerCase())) {
                  return null;
                }
              }

              return (
                <PlayerItem
                  hideIcons
                  hideImage
                  noLink
                  name={player.name}
                  hideHCP
                  showBg={selPlayers.includes(player.playerID)}
                  key={player.playerID}
                  showProfile={() => {
                    setSelPlayers((c) => {
                      if (c.includes(player.playerID)) {
                        const filt = c.filter((pl) => pl !== player.playerID);
                        return filt;
                      } else {
                        c.push(player.playerID);
                        return c;
                      }
                    });
                    update();
                  }}
                />
              );
            })}
          </div>
        </div>
      </Dialog>

      {group.groupType === "custom" && (
        <>
          <div className="quick-links">
            <Pill text={"Add Players"} onClick={() => setShowAdd(true)} />
          </div>
        </>
      )}

      <div className="pg-players">
        {customPlayers.map((player, i) => (
          <PlayerItem
            hideRemove={group.groupType !== "custom"}
            key={player.playerID}
            name={player.name}
            hcp={player.handicap}
            hideImage
            hideEdit
            hideMessage
            showAdd
            removeAction={() => {
              setToRemove(player.playerID);
              setShowRemove(true);
            }}
            hideProfile
          />
        ))}
      </div>
    </div>
  );
}

export default PlayerGroup;
