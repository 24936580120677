import React, { useState, useEffect } from "react";
import "./Support.css";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useClickTheme } from "../../contexts/MenuContext";

// Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import { ArrowBack } from "@mui/icons-material";
import QueryItem from "./QueryItem";
import NewQueryModal from "./NewQueryModal";
import Pill from "../../components/Buttons/Pill";
import PageHeader from "../../components/display/Rows/PageHeader";

function Support() {
  // Admin
  const { currentUser, userDetails } = useAuth();

  const showClick = useClickTheme();

  // State
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("queries");
  const [cats, setCats] = useState([]);
  const [text, setText] = useState("");
  const [items, setItems] = useState([]);
  const [selCat, setSelCat] = useState("");
  const [loadingQueries, setLoadingQueries] = useState(true);
  const [queries, setQueries] = useState([]);
  const [error, setError] = useState("");
  const [showItem, setShowItem] = useState(false);
  const [itemToShow, setItemToShow] = useState();
  const [finished, setFinished] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [showNew, setShowNew] = useState(false);

  const [adminChat, setAdminChat] = useState("");

  //
  useEffect(() => {
    if (currentUser) {
      loadFaqs();
      loadQueries();
      checkAdminChat();
    }
  }, [currentUser]);

  // Functions
  async function loadFaqs() {
    const faqDoc = await db.collection("admin").doc("faqs").get();
    const faqData = faqDoc.data();
    const { categories, items } = faqData;
    setCats(categories);
    setItems(items);
    setLoading(false);
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function validate() {
    setError("");

    if (text === "") {
      setError("Please give us a quick indication of the issue at hand");
      return false;
    }
  }

  async function checkAdminChat() {
    const adminChatDocs = await db
      .collection("chats")
      .where("userIDs", "array-contains", currentUser.uid)
      .where("adminChat", "==", true)
      .get();

    if (adminChatDocs.docs.length > 0) {
      const chatDoc = adminChatDocs.docs[0];
      const chatID = chatDoc.id;
      setAdminChat(chatID);
    }
  }

  async function saveQuery() {
    if (validate() === false) {
      return;
    }

    const queryID = makeid(10);

    try {
      setLoading(true);
      const userName = `${userDetails.firstName} ${userDetails.lastName}`;
      await db.collection("queries").doc(queryID).set({
        userID: currentUser.uid,
        text: text,
        // type: option,
        date: new Date(),
        status: "submitted",
        queryID,
        userName,
      });
      setFinished(true);
      setLoading(false);
      loadQueries();
    } catch (err) {
      setError(
        "Sorry, we encountered some technical difficulties there - please try again"
      );
    }
  }

  async function loadQueries() {
    setLoadingQueries(true);
    const queries = await db
      .collection("queries")
      .where("userID", "==", currentUser.uid)
      .orderBy("date", "desc")
      .get();
    const arr = [];
    console.log(queries.docs.length);
    for (let i = 0; i < queries.docs.length; i++) {
      const queryDoc = queries.docs[i];
      const queryID = queryDoc.id;
      const queryData = queryDoc.data();
      const { text, type, date, status } = queryData;
      const dateObj = new Date(date.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });
      arr.push({
        queryID,
        text,
        type,
        dateObj,
        dateString,
        status,
      });
    }
    setQueries(arr);
    setLoadingQueries(false);
  }

  return (
    <div className="support-page">
      {loading && <BackdropLoader />}
      {showNew && (
        <NewQueryModal
          reload={loadQueries}
          close={() => setShowNew(false)}
          uid={currentUser.uid}
        />
      )}
      <PageHeader text="Support" />
      <div className="ph-msg">
        <p>
          Submit a query, or check our FAQs to see whether you may find your
          problem there.
        </p>
      </div>
      <div className="sub-header">
        <h3>Quick Links</h3>
      </div>
      <div className="quick-links">
        <Pill text={"New Query"} onClick={() => setShowNew(true)} />

        {false && (
          <Pill
            onClick={() => {
              console.log(adminChat);
              showClick({
                type: "admin-chat",
                chatID: adminChat,
              });
            }}
            text="Support Chat"
          />
        )}
      </div>
      {false && (
        <RadioGroupTwo
          active={active}
          setActive={setActive}
          buttons={[
            { text: "Queries", value: "queries" },
            { text: "FAQs", value: "faqs" },
          ]}
        />
      )}
      {active === "faqs" ? (
        <>
          {showItem ? (
            <>
              <div onClick={() => setShowItem(false)} className="back-row mt-0">
                <ArrowBack />
                <p>Back</p>
              </div>
              <div className="faq-item mt-20">
                <h3>{itemToShow.title}</h3>
                <p>{itemToShow.text}</p>
              </div>
            </>
          ) : (
            <>
              <div className="input-group pl-20 mb-20">
                <p>Search</p>
                <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Search for item..."
                  className="default-input"
                />
              </div>
              {selCat === "" && (
                <div className="fq-box">
                  <div className="faq-cats">
                    {cats.map((cat) => {
                      if (searchInput !== "") {
                        const sl = searchInput.toLowerCase();
                        if (cat.toLowerCase().includes(sl)) {
                          return (
                            <div
                              onClick={() => {
                                setSelCat(cat);
                                setSearchInput("");
                              }}
                              className="faq-cat"
                            >
                              <h5>{cat}</h5>
                              <ArrowForwardIosIcon className="faq-icon" />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      }
                      return (
                        <div
                          onClick={() => {
                            setSelCat(cat);
                          }}
                          className="faq-cat"
                        >
                          <h5>{cat}</h5>
                          <ArrowForwardIosIcon className="faq-icon" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {selCat !== "" && (
                <>
                  <div
                    onClick={() => {
                      setSelCat("");
                      setSearchInput("");
                    }}
                    className="back-row mt-0"
                  >
                    <ArrowBack />
                    <p>Back</p>
                  </div>
                  <div className="faq-cats mt-20">
                    {items.map((item) => {
                      if (item.category === selCat) {
                        if (searchInput !== "") {
                          if (item.title.toLowerCase().includes(searchInput)) {
                            return (
                              <div
                                onClick={() => {
                                  setItemToShow(item);
                                  setShowItem(true);
                                }}
                                className="faq-cat"
                              >
                                <h5>{item.title}</h5>
                                <ArrowForwardIosIcon className="faq-icon" />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                        return (
                          <div
                            onClick={() => {
                              setItemToShow(item);
                              setShowItem(true);
                            }}
                            className="faq-cat"
                          >
                            <h5>{item.title}</h5>
                            <ArrowForwardIosIcon className="faq-icon" />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className="mb-10 sub-header">
            <h3>Queries</h3>
          </div>{" "}
          <div className="query-display slide-in-right">
            {queries.length === 0 && (
              <div className="no-msg mt-20">
                <p>No queries to display</p>
              </div>
            )}
            {queries.map((query) => (
              <QueryItem
                text={query.text}
                date={query.dateString}
                type={query.queryID}
                status={query.status}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Support;
