import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../../firebase";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import {
  drillCategories,
  isVideo,
  makeID,
  drillDifficulties,
} from "../HelperFunctions";
import Compressor from "compressorjs";
import classNames from "classnames";

// Icons
import { AiFillDelete } from "react-icons/ai";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import CPLoader from "../CPLoader";
import CustomDropdown from "../CustomDropdown";
import ErrorModal from "../../ErrorModal/ErrorModal";
import DrillImage from "./DrillImage";
import BackRow from "../../display/Rows/BackRow";
import Cropper from "react-easy-crop";
import { Backdrop, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";

function DrillCreator({
  defaultClubID = "",
  edit = false,
  defaults = {},
  back = () => {},
  reload = () => {},
  sdtl = false,
}) {
  // Admin
  const navigate = useNavigate();

  // const onDrop = useCallback((acceptedFiles) => {
  //   const arr = [];
  //   for (let i = 0; i < acceptedFiles.length; i++) {
  //     const url = URL.createObjectURL(acceptedFiles[i]);
  //     new Compressor(acceptedFiles[i], {
  //       convertSize: 1000000,
  //       success: (res) => {
  //         setImages((c) => {
  //           if (c.length === 0) {
  //             setMainImage(url);
  //           }
  //           c.push({
  //             url,
  //             image: res,
  //             description: "",
  //           });
  //           // console.log(c);
  //           return c;
  //         });
  //         upd();
  //       },
  //     });
  //   }
  // }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        console.log(res);
        setImage(res);
        setShowCrop(true);
      },
    });
  }, []);

  const onDrop2 = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);

    const isVid = isVideo(acceptedFiles[0]);
    if (!isVid) {
      return setError("Please ensure to select only videos");
    }
    const url = URL.createObjectURL(acceptedFiles[0]);
    const vid = document.createElement("video");
    vid.src = url;
    vid.onloadedmetadata = () => {
      console.log(vid.duration);
      if (vid.duration > 60) {
        return setError(
          "Please ensure to upload only videos that are less than one minute in length"
        );
      } else {
        setVideo({
          vidLink: url,
          vid: acceptedFiles[0],
        });
      }
    };

    //   upd();

    // console.log(arr);
    // setImages((c) => [...c, ...arr]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const {
    getRootProps: getVideoRootProps,
    getInputProps: getVideoInputProps,
    isDragActive: isVideoDragActive,
  } = useDropzone({ onDrop: onDrop2 });

  const [showCrop, setShowCrop] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});

  async function cropImage() {
    const image = new Image();
    image.src = imageURL;
    const crop = fc;
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }

  // State
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState("General");
  const [perfectScore, setPerfectScore] = useState("");
  const [clubID, setClubID] = useState(defaultClubID);
  const [video, setVideo] = useState({
    vid: "",
    vidLink: "",
  });
  const [imageURL, setImageURL] = useState("");
  const [image, setImage] = useState();
  const [errorType, setErrorType] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [update, setUpdate] = useState(0);
  const [difficulty, setDifficulty] = useState("");

  const [saved, setSaved] = useState(false);

  function upd() {
    setUpdate((c) => c + 1);
  }

  // UE
  useEffect(() => {
    if (edit) {
      sortDefaults();
    }
  }, []);

  // Funcs
  function sortDefaults() {
    setTitle(defaults.title);
    setMainImage(defaults.mainImage);
    setCategory(defaults.category);
    setDescription(defaults.description);
    setDifficulty(defaults.difficulty);
    setPerfectScore(defaults.perfectScore);
    setImages(defaults.images);
    setVideo({ vidLink: defaults.videoLink });
  }

  function validate() {
    if (title === "") {
      setError("Please enter a title for this drill");
      setErrorType("tit");
      return false;
    }

    if (description === "") {
      setError("Please enter a description for this drill");
      setErrorType("desc");
      return false;
    }

    if (difficulty === "") {
      setError("Please select a difficulty leve for this drill");
    }
  }

  async function saveDrill() {
    setLoading(true);
    if (validate() === false) {
      return setLoading(false);
    }
    try {
      const drillID = makeID(15);
      const drillDoc = await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drillID)
        .set({
          dateAdded: new Date(),
          attempts: [],
          description,
          title,
          category: category.value,
          images: [],
          mainImage: "",
          videoLink: "",
          drillID,
          perfectScore,
          difficulty: difficulty.value,
          custom: true,
        });

      const imgs = [...images];
      let mI = "";

      if (false) {
        for (let i = 0; i < imgs.length; i++) {
          let main = false;
          if (imgs[i].url === mainImage) {
            main = true;
          }
          const img = imgs[i];
          const storageRef = storage.ref();
          const imageRef = storageRef.child(
            `/${clubID}images/${makeID(5)}${img.image.name}`
          );
          const snapshot = await imageRef.put(img.image);
          const imageUrl = await snapshot.ref.getDownloadURL();
          imgs[i].url = imageUrl;
          imgs[i].uploaded = true;

          if (main) {
            mI = imageUrl;
          }

          delete imgs[i].image;
        }

        let videoLink = "";

        if (video.vidLink !== "") {
          const file = video.vid;
          const storageRef = storage.ref();
          const medID = makeID(10);
          const medRef = storageRef.child(
            `media/${clubID}/videos/${medID}.mp4`
          );
          const ss = await medRef.put(file);
          const url = await ss.ref.getDownloadURL();
          videoLink = url;
        }
      }

      if (image) {
        if (typeof image === "string") {
          const response = await fetch(image);
          const contentType = response.headers.get("content-type");
          const blob = await response.blob();
          const file = new File([blob], `${makeID(10)}.jpg`, { contentType });
          const storageRef = storage.ref();
          const imageRef = storageRef.child(
            `/${clubID}drillimages/${makeID(5)}${image.name}`
          );
          const snapshot = await imageRef.put(file);
          mI = await snapshot.ref.getDownloadURL();
        } else {
          const storageRef = storage.ref();
          const imageRef = storageRef.child(
            `/${clubID}drillimages/${makeID(5)}${image.name}`
          );
          const snapshot = await imageRef.put(image);
          mI = await snapshot.ref.getDownloadURL();
        }
      }

      await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drillID)
        .update({
          mainImage: mI,
        });
      setSaved(true);
      reload();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }
    setLoading(false);
  }

  async function saveChanges() {
    // return console.log(clubID, defaults);
    setLoading(true);
    try {
      const imgs = [...images];
      let mI = defaults.mainImage;

      let vl = defaults.videoLink;

      if (false) {
        if (video.vid) {
          const file = video.vid;
          const storageRef = storage.ref();
          const medID = makeID(10);
          const medRef = storageRef.child(
            `media/${clubID}/videos/${medID}.mp4`
          );
          const ss = await medRef.put(file);
          const url = await ss.ref.getDownloadURL();
          vl = url;
        }

        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
          let main = false;
          if (img.url === mainImage) {
            main = true;
          }
          if (img.image) {
            const storageRef = storage.ref();
            const imageRef = storageRef.child(
              `/${clubID}images/${makeID(5)}${img.image.name}`
            );
            const snapshot = await imageRef.put(img.image);
            const imageUrl = await snapshot.ref.getDownloadURL();
            imgs[i].url = imageUrl;
            imgs[i].uploaded = true;
          }
          if (main) {
            mI = imgs[i].url;
          }
        }
      }

      if (image) {
        if (typeof image === "string") {
          const response = await fetch(image);
          const contentType = response.headers.get("content-type");
          const blob = await response.blob();
          const file = new File([blob], `${makeID(10)}.jpg`, { contentType });
          const storageRef = storage.ref();
          const imageRef = storageRef.child(
            `/${clubID}drillimages/${makeID(5)}${image.name}`
          );
          const snapshot = await imageRef.put(file);
          mI = await snapshot.ref.getDownloadURL();
        } else {
          const storageRef = storage.ref();
          const imageRef = storageRef.child(
            `/${clubID}drillimages/${makeID(5)}${image.name}`
          );
          const snapshot = await imageRef.put(image);
          mI = await snapshot.ref.getDownloadURL();
        }
      }

      await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(defaults.drillID)
        .update({
          title,
          description,
          perfectScore,
          category,
          difficulty,
          mainImage: mI,
          // videoLink: vl,
        });
      setSaved(true);
      reload();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }
    setLoading(false);
  }

  return (
    <Dialog open onClose={back}>
      <div className={sdtl ? "drill-creator sig-page" : "drill-creator"}>
        {showCrop && (
          <Backdrop className="crop-drop" open>
            <div className="crop-co">
              <div className="cd-close">
                <Close onClick={() => setShowCrop(false)} />
              </div>
              <div className="crop-box">
                <Cropper
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  image={imageURL}
                />
              </div>
              <div className="flex-center crop-but">
                <button
                  onClick={() => {
                    cropImage();
                    setShowCrop(false);
                  }}
                  className="default-button"
                >
                  Save
                </button>
              </div>
            </div>
          </Backdrop>
        )}
        {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
        {/* <BackRow action={back} /> */}
        <PageHeader
          showClose
          close={back}
          showBack
          text={edit ? "Edit Drill" : "Create Drill"}
        />
        {loading ? (
          <div className="pd-20">
            <div className="wbg">
              <CPLoader />
            </div>
          </div>
        ) : saved ? (
          <>
            <div className="ri-submitted">
              <div className="neh-box">
                <h2 className="ne-header">Successfully saved</h2>
              </div>
              <div className="ri-sub-text">
                {edit ? (
                  <p></p>
                ) : (
                  <p>
                    Your newly created drill will appear on all relevant pages
                    now
                  </p>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="dc-ctt pd-20">
              <div className="wbg pd-20">
                <div className="dc-ctt-box">
                  <div className="input-group">
                    <p>Title</p>
                    <input
                      className={classNames("default-input", {
                        "default-input-err": errorType === "tit",
                      })}
                      value={title}
                      placeholder="Start typing..."
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <p>Category</p>
                    <CustomDropdown
                      opts={drillCategories}
                      placeHolder="Select Category..."
                      value={category}
                      handleChange={setCategory}
                    />
                  </div>
                  <div className="input-group">
                    <p>Difficulty</p>
                    <CustomDropdown
                      opts={drillDifficulties}
                      placeHolder="Select Difficulty..."
                      value={difficulty}
                      handleChange={setDifficulty}
                    />
                  </div>
                  <div className="input-group">
                    <p>Perfect Score</p>
                    <input
                      value={perfectScore}
                      onChange={(e) => setPerfectScore(e.target.value)}
                      className="default-input"
                      min={0}
                      placeholder="Start typing..."
                    />
                  </div>
                  <div className="input-group">
                    <p>Description</p>
                    <textarea
                      className={classNames("def-ip-ta default-input", {
                        "def-ip-err": errorType === "desc",
                      })}
                      placeholder="Start typing..."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={10}
                    />
                  </div>
                  <div className="input-group">
                    <p>Upload an image for the drill</p>
                    <div className="ip-mult-drop flex">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-sel">
                          {imageURL !== "" && (
                            <div className="event-img">
                              <img className="" alt="profile" src={imageURL} />
                            </div>
                          )}
                          {imageURL === "" && (
                            <h5>
                              Drag 'n' drop an image here, or click to open up
                              the file explorer
                            </h5>
                          )}
                        </div>
                      </div>
                      <div className="ipmd-imgs flex flex-wrap ml-20">
                        {images.map((img, i) => {
                          // console.log(typeof img.url, typeof mainImage);
                          // console.log(img);
                          return (
                            <>
                              <DrillImage
                                imageURL={img.url}
                                deleteAct={() => {
                                  setImages((c) => {
                                    const filt = c.filter(
                                      (item) => item.url !== img.url
                                    );
                                    return filt;
                                  });
                                  upd();
                                }}
                                description={img.description}
                                setDescription={(e) => {
                                  setImages((c) => {
                                    c[i].description = e;
                                    return c;
                                  });
                                  upd();
                                }}
                                selected={img.url === mainImage}
                                setSelected={() => {
                                  setMainImage(img.url);
                                  upd();
                                }}
                              />
                            </>
                            // <DropImage
                            //   key={i}
                            //   selected={img.url === mainImage}
                            //   setSelected={() => setMainImage(img.url)}
                            //   deleteAct={() => {
                            //     setImages((c) => {
                            //       const filt = c.filter(
                            //         (image) => image.url !== img.url
                            //       );
                            //       if (img.url === mainImage) {
                            //         if (filt.length > 0) {
                            //           setMainImage(filt[0]);
                            //         } else {
                            //           setMainImage("");
                            //         }
                            //       }
                            //       return filt;
                            //     });
                            //     upd();
                            //   }}
                            //   imageURL={img.url}
                            // />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {false && (
                  <div className="flex flex-wrap">
                    <div className="input-group">
                      <p>Upload an image for the drill</p>
                      <div className="ip-mult-drop flex">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="dz-sel">
                            <h5>
                              Drag 'n' drop an image here, or click to open up
                              the file explorer
                            </h5>
                          </div>
                        </div>
                        <div className="ipmd-imgs flex flex-wrap ml-20">
                          {images.map((img, i) => {
                            // console.log(typeof img.url, typeof mainImage);
                            // console.log(img);
                            return (
                              <>
                                <DrillImage
                                  imageURL={img.url}
                                  deleteAct={() => {
                                    setImages((c) => {
                                      const filt = c.filter(
                                        (item) => item.url !== img.url
                                      );
                                      return filt;
                                    });
                                    upd();
                                  }}
                                  description={img.description}
                                  setDescription={(e) => {
                                    setImages((c) => {
                                      c[i].description = e;
                                      return c;
                                    });
                                    upd();
                                  }}
                                  selected={img.url === mainImage}
                                  setSelected={() => {
                                    setMainImage(img.url);
                                    upd();
                                  }}
                                />
                              </>
                              // <DropImage
                              //   key={i}
                              //   selected={img.url === mainImage}
                              //   setSelected={() => setMainImage(img.url)}
                              //   deleteAct={() => {
                              //     setImages((c) => {
                              //       const filt = c.filter(
                              //         (image) => image.url !== img.url
                              //       );
                              //       if (img.url === mainImage) {
                              //         if (filt.length > 0) {
                              //           setMainImage(filt[0]);
                              //         } else {
                              //           setMainImage("");
                              //         }
                              //       }
                              //       return filt;
                              //     });
                              //     upd();
                              //   }}
                              //   imageURL={img.url}
                              // />
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {false && (
                      <div className="input-group">
                        <p>Video Description (max 1 minute)</p>
                        <div className="dz-group flex flex-align-center">
                          <div className="ipg-img">
                            <div {...getVideoRootProps()}>
                              <input {...getVideoInputProps()} />
                              <div className="dz-sel">
                                {video.vidLink === "" ? (
                                  <h5>
                                    Drag 'n' drop a video here, or click to open
                                    up the file explorer
                                  </h5>
                                ) : (
                                  <video alt={"prof"} src={video.vidLink} />
                                )}
                              </div>
                            </div>
                          </div>
                          {video.vidLink !== "" && (
                            <div className="flex flex-col ml-20">
                              <div
                                onClick={() => {
                                  setVideo({
                                    vid: "",
                                    vidLink: "",
                                  });
                                }}
                                className="ico-butt-red"
                              >
                                <AiFillDelete />
                              </div>
                              {/* <div onClick={() => {}} className="ico-butt ml-0 mt-10">
                      <AiFillPlayCircle />
                    </div> */}
                            </div>
                          )}
                          {video.vidLink !== "" && (
                            <div className="cc-media-player ml-20">
                              <video
                                src={video.vidLink}
                                controls
                                width={"300px"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="pd-20 flex flex-center">
                  <button
                    onClick={edit ? saveChanges : saveDrill}
                    className="default-button shadow-md"
                  >
                    {edit ? "Save Changes" : "Save Drill"}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default DrillCreator;
