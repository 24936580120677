import React, { useState, useEffect } from "react";
import "./FullLog.css";

import LogItem from "./LogItem/LogItem";

import { BallTriangle } from "react-loader-spinner";
import LogHeader from "./LogItem/LogHeader";

function FullLog({
  leagueID,
  setLoading = () => {},
  leagueLog,
  exitClass,
  showProfile,
  goBack,
  cutoff = 0,
  final = false,
}) {
  const [noLogs, setNoLogs] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (leagueLog.length === 0) {
        setNoLogs(true);
      } else {
        setNoLogs(false);
      }
    }, 3000);
  }, [leagueLog]);

  return (
    <div
      className={
        leagueLog.length === 0
          ? "full-log scale-in-ps fl-fs-loader"
          : `${exitClass}s full-log slide-in-lefft`
      }
    >
      {noLogs ? (
        <div className="no-rounds">
          <p>No logs to be displayed currently </p>
        </div>
      ) : leagueLog.length === 0 ? (
        <div className="loading-container elc">
          <BallTriangle color="#21c17c" height={80} width={80} />
          <p>{"Loading log..."}</p>
        </div>
      ) : (
        <>
          {" "}
          <LogHeader final={final} move={leagueLog[0].move !== undefined} />
          {
            leagueLog.map((player, index) => {
              if (index === cutoff) {
                if (index === 0) {
                  return (
                    <LogItem
                      key={player.playerID}
                      upDown={player.move ?? ""}
                      playerID={player.playerID}
                      goBack={goBack}
                      handleClick={showProfile}
                      points={Math.round(player.points)}
                      wins={player.wins ?? 0}
                      rank={player.ranking ?? index + 1}
                      played={player.totalRounds}
                      name={player.userName}
                      hcp={player.handicap}
                      img={player.imageLink}
                      ave={
                        final
                          ? player.finalRank
                          : player.totalRounds > 0
                          ? Math.round(player.points / player.totalRounds)
                          : 0
                      }
                    />
                  );
                } else {
                  return (
                    <>
                      <div className="rank-div"></div>
                      <LogItem
                        key={player.playerID}
                        upDown={player.move ?? ""}
                        playerID={player.playerID}
                        goBack={goBack}
                        handleClick={showProfile}
                        points={player.points}
                        wins={player.wins ?? 0}
                        rank={player.ranking ?? index + 1}
                        played={player.totalRounds}
                        name={player.userName}
                        hcp={player.handicap}
                        img={player.imageLink}
                        ave={
                          final
                            ? player.finalRank
                            : player.totalRounds > 0
                            ? Math.round(player.points / player.totalRounds)
                            : 0
                        }
                      />
                    </>
                  );
                }
              } else {
                return (
                  <LogItem
                    key={player.playerID}
                    upDown={player.move ?? ""}
                    playerID={player.playerID}
                    goBack={goBack}
                    handleClick={showProfile}
                    points={player.points}
                    wins={player.wins ?? 0}
                    rank={player.ranking ?? index + 1}
                    played={player.totalRounds}
                    name={player.userName}
                    hcp={player.handicap}
                    img={player.imageLink}
                    ave={
                      final
                        ? player.finalRank
                        : player.totalRounds > 0
                        ? Math.round(player.points / player.totalRounds)
                        : 0
                    }
                  />
                );
              }
            })
            // leagueLog.map((player, index) => {
            //     return <tr>
            //         <td>{index + 1}</td>
            //         <td onClick={() => {
            //             setPlayerModalID(player.playerID)
            //             setShowPlayerModal(true)
            //             }} className='text-start'>{player.playerName}</td>
            //         <td>{player.eventsCompeted}</td>
            //         <td>{player.points}</td>
            //         <td>{player.wins}</td>
            //         <td>{player.handicap}</td>
            //         </tr>
            // })
          }
        </>
      )}
    </div>
  );
}

export default FullLog;
