import React, { useState } from "react";
import { admin, db } from "../../firebase";

// Icons
import Cancel from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";

// Components
import PlayerItem from "../display/PlayerItem/PlayerItem";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { successIco } from "../ClientStats/HelperFunctions";

function LeagueMembers({
  players = [],
  leagueID = "",
  teamLeague = false,
  reload = () => {},
}) {
  const [searchInput, setSearchInput] = useState("");
  const [show, setShow] = useState(false);

  const [toRemove, setToRemove] = useState("");

  async function removePlayer(uid) {
    // return console.log(uid);
    let type = "leagues";
    if (teamLeague) {
      type = "teamLeagues";
    }
    db.collection(type)
      .doc(leagueID)
      .update({
        playerIDs: admin.firestore.FieldValue.arrayRemove(uid),
      });
    setShow(false);
    reload();
    toast.success("Player was successfully removed from your league", {
      icon: successIco,
    });
  }

  return (
    <div className="league-members">
      <Dialog open={show} onClose={() => setShow(false)}>
        <div className="emc-dia">
          <div className="emc-close">
            <CloseIcon className="icon-cursor" onClick={() => setShow(false)} />
          </div>
          <Cancel className="red-icon big-red-icon" />

          <p className="mb-20">
            Are you sure you want to remove this player from your league?
          </p>
          <div className="flex-center">
            <button
              onClick={() => removePlayer(toRemove)}
              className="delete-button"
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <div className="sub-header">
        <h3>Members ({players.length})</h3>
      </div>
      <div className="input-group pl-20">
        <p>Search</p>
        <input
          type="text"
          placeholder="Search for player"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="default-input"
        />
      </div>
      <div className="members-container">
        {players.map((player) => {
          const nl = player.name.toLowerCase();
          const sl = searchInput.toLowerCase();

          if (sl !== "") {
            if (nl.includes(sl)) {
              return (
                <PlayerItem
                  hcp={player.handicap}
                  img={player.profileImage}
                  name={player.name}
                  hideEdit
                  showAdd
                  hideProfile
                  key={player.playerID}
                  removeAction={() => {
                    setToRemove(player.playerID);
                    setShow(true);
                  }}
                  playerID={player.playerID}
                />
              );
            } else {
              return null;
            }
          }

          return (
            <PlayerItem
              hcp={player.handicap}
              img={player.profileImage}
              name={player.name}
              hideEdit
              showAdd
              hideProfile
              key={player.playerID}
              playerID={player.playerID}
              removeAction={() => {
                setToRemove(player.playerID);
                setShow(true);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default LeagueMembers;
