/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
import React from "react";

function PrivacyPolicy() {
  return (
    <div style={{ padding: "20px" }} className="privacy-policy">
      <h1>PRIVACY POLICY</h1>

      <h2>
        Privacy Policy for The Commissioner Tournament Management &amp; live
        scoring platform
      </h2>

      <h3>1. Introduction</h3>
      <p>
        The UGA ("we," "us," or "our") is committed to protecting the privacy
        and security of personal information that we collect, process, and store
        as part of our online golf tournament management software. This Privacy
        Policy explains how we handle personal information in accordance with
        the requirements of the Protection of Personal Information Act (POPIA).
      </p>

      <h3>2. Personal Information Collection</h3>
      <p>
        We may collect the following types of personal information from users of
        our golf tournament management software:
      </p>
      <ul>
        <li>Contact information: Names, email addresses.</li>
        <li>
          Account information: Usernames, passwords, and other authentication
          credentials.
        </li>
        <li>
          Golf-related information: Handicap, scores, tournament performance,
          and related data.
        </li>
      </ul>

      <h3>3. Purpose of Personal Information Collection</h3>
      <p>
        We collect and process personal information for the following purposes:
      </p>
      <ul>
        <li>
          Registering and managing user accounts for our golf tournament
          management software.
        </li>
        <li>Facilitating tournament participation and scoring.</li>
        <li>Providing customer support and addressing user inquiries.</li>
        <li>
          Conducting research and analysis to improve our software and services.
        </li>
        <li>Complying with legal obligations.</li>
      </ul>

      <h3>4. Lawful Basis for Processing Personal Information</h3>
      <p>
        We process personal information based on one or more of the following
        lawful bases:
      </p>
      <ul>
        <li>
          User consent: We obtain consent from users to process personal
          information for specific purposes.
        </li>
        <li>
          Contractual necessity: Processing is necessary to fulfill our
          contractual obligations to users.
        </li>
        <li>
          Legal compliance: Processing is required to comply with applicable
          laws and regulations.
        </li>
        <li>
          Legitimate interests: We may process personal information to pursue
          our legitimate business interests, provided they do not override
          users' rights and freedoms.
        </li>
      </ul>

      <h3>5. Information Sharing and Disclosure</h3>
      <p>We may share personal information with the following third parties:</p>
      <ul>
        <li>
          Tournament organizers: Golf clubs or organizations hosting tournaments
          through our software.
        </li>
        <li>
          Legal authorities: If required by law or in response to a legal
          request.
        </li>
      </ul>

      <h3>6. Security Measures</h3>
      <p>
        We take reasonable and appropriate security measures to protect personal
        information from unauthorized access, disclosure, alteration, or
        destruction. However, no method of transmission over the internet or
        electronic storage is entirely secure, and we cannot guarantee absolute
        security.
      </p>

      <h3>7. Data Retention</h3>
      <p>
        We retain personal information for as long as necessary to fulfill the
        purposes outlined in this Privacy Policy or as required by applicable
        laws. When personal information is no longer needed, we will securely
        delete or anonymize it.
      </p>

      <h3>8. User Rights</h3>
      <p>Users have the right to:</p>
      <ul>
        <li>
          Access and obtain a copy of their personal information held by us.
        </li>
        <li>Correct any inaccuracies in their personal information.</li>
        <li>Withdraw consent to process their personal information.</li>
        <li>
          Request the deletion of their personal information under certain
          circumstances.
        </li>
        <li>Lodge complaints with the relevant data protection authority.</li>
      </ul>

      <h3>9. Updates to the Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We will inform users about significant updates by providing notice
        through our website or other communication channels.
      </p>

      <h3>10. Contact Us</h3>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at{" "}
        <a href="mailto:info@theugatour.com">info@theugatour.com</a>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
