/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from "react";

const MenuContext = React.createContext();
const ClickContext = React.createContext();

export function useMenuTheme() {
  return useContext(MenuContext);
}
export function useClickTheme() {
  return useContext(ClickContext);
}

export function MenuProvider({ children }) {
  const [update, setUpdate] = useState(0);
  const [clicked, setClicked] = useState("");

  const clickRef = useRef();
  clickRef.current = clicked;

  function showClick(click) {
    // setClicked()
    setClicked((current) => {
      if (click === current) {
        return `${click}again`;
      } else {
        return click;
      }
    });
    setUpdate((current) => current + 1);
    return;
  }

  return (
    <MenuContext.Provider value={clicked}>
      <ClickContext.Provider value={showClick}>
        {children}
      </ClickContext.Provider>
    </MenuContext.Provider>
  );
}
