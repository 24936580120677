import React from "react";
import "./ClientDrills.css";

function DisplayDrillAttempt({ attempt, rk }) {
  return (
    <div className="drill-attempt dd-attempt flex flex-align-center">
      <div>
        <p>{rk}</p>
      </div>
      <div className="da-date">
        <p>
          {new Date(attempt.date.toDate()).toLocaleDateString("en-us", {
            dateStyle: "medium",
          })}
        </p>
      </div>
      <div className="da-tit">
        <p>{attempt.clientName}</p>
      </div>
      <div>
        <p>{attempt.score}</p>
      </div>
    </div>
  );
}

export default DisplayDrillAttempt;
