import React, { useState, useEffect } from "react";
import { admin, db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import TimeItem from "../../display/TeeTimes/TimeItem";
import PageHeader from "../../display/Rows/PageHeader";

function EventAddPlayer({
  eventID,
  hide = () => {},
  teamEvent = false,
  reload = () => {},
}) {
  const { currentUser, userDetails } = useAuth();

  // State
  const [players, setPlayers] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectingGroup, setSelectingGroup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [handicap, setHandicap] = useState();

  const [finished, setFinished] = useState(false);

  const [times, setTimes] = useState([]);
  const [selGroup, setSelGroup] = useState();

  // UE
  useEffect(() => {
    if (userDetails) {
      loadPlayers();
    }
  }, [userDetails]);

  async function loadPlayers() {
    let friendIDs = userDetails.friends;

    let eventDoc;

    if (teamEvent) {
      eventDoc = await db.collection("teamEvents").doc(eventID).get();
    } else {
      eventDoc = await db.collection("events").doc(eventID).get();
    }

    const event = eventDoc.data();
    const { playerIDs, leagueID, roundIDs } = event;
    const invitedIDs = event.invitedIDs ?? [];
    if (!teamEvent) {
      loadTeeTimes(roundIDs);
    }
    let leagueIDs = [];
    if (leagueID !== "standalone" && !teamEvent) {
      const leagueDoc = await db.collection("leagues").doc(leagueID).get();
      const league = leagueDoc.data();
      leagueIDs = league.playerIDs;
    }
    if (leagueID !== "standalone" && teamEvent) {
      const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
      const league = leagueDoc.data();
      leagueIDs = league.playerIDs;
    }

    if (event.clubID) {
      const clubDoc = await db.collection("clubs").doc(event.clubID).get();
      const club = clubDoc.data();
      const { memberIDs } = club;

      for (let i = 0; i < memberIDs.length; i++) {
        const id = memberIDs[i];
        if (!friendIDs.includes(id)) {
          friendIDs.push(id);
        }
      }
    }

    for (let i = 0; i < playerIDs.length; i++) {
      const id = playerIDs[i];
      if (!friendIDs.includes(id)) {
        friendIDs.push(id);
      }
    }

    for (let i = 0; i < invitedIDs.length; i++) {
      const id = invitedIDs[i];
      if (!friendIDs.includes(id)) {
        friendIDs.push(id);
      }
    }

    for (let i = 0; i < leagueIDs.length; i++) {
      const id = leagueIDs[i];
      if (!friendIDs.includes(id)) {
        friendIDs.push(id);
      }
    }

    friendIDs = friendIDs.filter((id) => {
      if (playerIDs.includes(id) || invitedIDs.includes(id)) {
        return false;
      } else {
        return true;
      }
    });

    console.log(friendIDs);

    // To Invite
    const refs = friendIDs.map((id) => db.collection("users").doc(id).get());

    const arr = [];

    const docs = await Promise.all(refs);
    for (let i = 0; i < docs.length; i++) {
      const userDoc = docs[i];
      const playerID = userDoc.id;
      const player = userDoc.data();
      if (!player) {
        continue;
      }
      const { firstName, lastName, handicap, profileImage } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
      });
    }
    setPlayers(arr);

    setLoading(false);
  }

  async function loadTeeTimes(roundIDs = []) {
    let arr = [];

    for (let i = 0; i < roundIDs.length; i++) {
      const roundID = roundIDs[i];

      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const { teeTimes, leaderboard } = round;

      // Check types
      const firstGroup = teeTimes[0];
      const { players } = firstGroup;
      const firstPlayer = players[0];
      let convertTime = false;
      const firstTime = firstGroup.time;
      if (typeof firstTime === "string") {
      } else {
        convertTime = true;
      }

      if (convertTime) {
        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];
          const timeObj = new Date(group.time.toDate());
          const timeString = timeObj.toLocaleTimeString("en-us", {
            timeStyle: "short",
          });
          teeTimes[i].time = timeString;
        }
      }

      // Load details from leaderboard
      if (typeof firstPlayer === "string") {
        const playerMap = {};
        leaderboard.forEach((player) => (playerMap[player.playerID] = player));

        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];
          const { players } = group;
          for (let j = 0; j < players.length; j++) {
            const uid = players[j];
            teeTimes[i].players[j] = playerMap[uid].name;
          }
        }
        arr = teeTimes;
      } else {
        arr = teeTimes;
      }
    }
    console.log(arr);
    setTimes(arr);
  }

  async function addTeamPlayer() {
    setLoading(true);

    let hcpStore = handicap;

    if (typeof handicap === "string") {
      hcpStore = parseInt(hcpStore);
    }

    try {
      const eventDoc = await db.collection("teamEvents").doc(eventID).get();
      const event = eventDoc.data();

      const { roundIDs } = event;

      for (let i = 0; i < roundIDs.length; i++) {
        await db
          .collection("rounds")
          .doc(roundIDs[i])
          .update({
            playerIDs: admin.firestore.FieldValue.arrayUnion(selected),
          });
      }

      await db.collection("triggers").add({
        type: "event-add-player",
        playerID: selected,
        group: selGroup,
        eventID,
        dateAdded: new Date(),
        handicap: hcpStore,
      });
      setFinished(true);
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
      setSelectingGroup(true);
    }
    setLoading(false);
  }

  async function addPlayer() {
    setLoading(true);
    setSelectingGroup(false);

    let hcpStore = handicap;

    if (typeof handicap === "string") {
      hcpStore = parseFloat(hcpStore);
      hcpStore = Math.round(hcpStore);
    }

    try {
      await db.collection("triggers").add({
        type: "event-add-player",
        playerID: selected,
        group: selGroup,
        eventID,
        dateAdded: new Date(),
        handicap: hcpStore,
      });
      reload();
      setFinished(true);
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
      setSelectingGroup(true);
    }
    setLoading(false);
  }

  return (
    <Dialog onClose={hide} open className="add-player-modal mpa-dia">
      <div className="event-add-player">
        {!finished && (
          <>
            <PageHeader text="Add Player" showClose close={hide} />
            <div className="im-msg">
              {selectingGroup ? (
                <p>Select a group for {selectedName} to join</p>
              ) : (
                <p>Select a play to add to this event</p>
              )}
            </div>
          </>
        )}
        {finished && (
          <>
            <PageHeader text="Successfully Added" showClose close={hide} />

            <div className="im-msg">
              <p>
                {selectedName} has been added to your event. Changes will
                reflect in a few moments
              </p>
            </div>
          </>
        )}
        {loading ? (
          <>
            <div className="flex-center loading">
              <Oval
                color="#1e7a69"
                secondaryColor="#ffffff"
                height={40}
                width={40}
              />
            </div>
          </>
        ) : finished ? (
          <>
            {/* <div className="sub-header">
              <h5>{selectedName} has successfully been added to your event</h5>
            </div> */}
          </>
        ) : selectingGroup ? (
          <>
            {/* <div className="sub-header">
              <h5>Select a group for this player to join</h5>
            </div> */}
            {selectingGroup && (
              <div
                onClick={() => setSelectingGroup(false)}
                className="back-row"
              >
                <ArrowBack />
                <p>Back</p>
              </div>
            )}
            <div className="input-group pl-20">
              <p>Handicap</p>
              <input
                type="number"
                value={handicap}
                onChange={(e) => setHandicap(e.target.value)}
                className="default-input"
              />
            </div>
            <div className="apm-times">
              {times.map((time, index) => {
                const amt = time.players.filter(
                  (pl) => pl.name !== "Empty"
                ).length;
                return (
                  <TimeItem
                    unselectable={amt === 4}
                    selectable={amt < 4}
                    showBG={index === selGroup}
                    onClick={() => {
                      setSelGroup((current) => {
                        if (index === current) {
                          return null;
                        } else {
                          return index;
                        }
                      });
                    }}
                    tee={time.tee}
                    time={time.time}
                    key={time.time}
                    players={time.players}
                  />
                );
              })}
            </div>
            {errorMessage !== "" && (
              <div className="error-message-row">
                <p>{errorMessage}</p>
              </div>
            )}
            <div className="flex-center mb-20 mt-20">
              <button
                onClick={() => {
                  addPlayer();
                }}
                // disabled={!teamEvent && selGroup === undefined}
                className="default-button"
              >
                Add Player
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex-center mt-20">
              <button
                onClick={() => {
                  setSelectingGroup(true);
                }}
                disabled={selected === ""}
                className="default-button"
              >
                {teamEvent ? "Input Handicap" : "Select Group"}
              </button>
            </div>
            <div className="input-group mb-20 si-search">
              <p>Search</p>
              <input
                type="text"
                placeholder="Search for player"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="default-input"
              />
            </div>
            <div className="eap-players">
              {players.map((player) => {
                if (searchInput !== "") {
                  const sl = searchInput.toLowerCase();
                  if (player.name.toLowerCase().includes(sl)) {
                    return (
                      <PlayerItem
                        noLink
                        showProfile={() =>
                          setSelected((current) => {
                            if (current === player.playerID) {
                              setSelectedName("");
                              setHandicap();
                              return "";
                            } else {
                              setSelectedName(player.name);
                              setHandicap(player.handicap);
                              return player.playerID;
                            }
                          })
                        }
                        hcp={player.handicap}
                        img={player.profileImage}
                        name={player.name}
                        playerID={player.playerID}
                        hideIcons
                        key={player.playerID}
                        showBg={selected.includes(player.playerID)}
                      />
                    );
                  } else {
                    return null;
                  }
                }
                return (
                  <PlayerItem
                    key={player.playerID}
                    showProfile={() =>
                      setSelected((current) => {
                        if (current === player.playerID) {
                          setSelectedName("");
                          setHandicap();
                          return "";
                        } else {
                          setSelectedName(player.name);
                          setHandicap(player.handicap);
                          return player.playerID;
                        }
                      })
                    }
                    noLink
                    hcp={player.handicap}
                    img={player.profileImage}
                    name={player.name}
                    playerID={player.playerID}
                    hideIcons
                    showBg={selected.includes(player.playerID)}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default EventAddPlayer;
