import React, { useEffect, useState } from "react";
import "./ClosestLongest.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import { db } from "../../../firebase";

import { useAuth } from "../../../contexts/AuthContext";

function ClosestLongest({
  eventID,
  roundIDs = [],
  cup = false,
  selected = "",
  teamOneColor = "#1e7a69",
  teamTwoColor = "#209E73",
}) {
  const [rounds, setRounds] = useState([]);
  const [selRound, setSelRound] = useState();
  const [loading, setLoading] = useState(true);
  // const

  const { userSettings } = useAuth();

  const [meters, setMeters] = useState(true);

  useEffect(() => {
    if (userSettings) {
      setMeters(userSettings.units === "meters");
    }
  }, [userSettings]);

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    const roundArr = [];
    for (let i = 0; i < roundIDs.length; i++) {
      const roundID = roundIDs[i];
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      if (!roundDoc.exists) {
        continue;
      }
      const round = roundDoc.data();
      const playerArr = [];
      const { leaderboard, teams, format, playerIDs } = round;
      if (format === "team-net-strokeplay") {
        const refs = playerIDs.map((playerID) =>
          db.collection("users").doc(playerID).get()
        );
        const docs = await Promise.all(refs);
        for (let n = 0; n < docs.length; n++) {
          const playerDoc = docs[n];
          const player = playerDoc.data();
          const name = `${player.firstName} ${player.lastName}`;
          const playerID = playerDoc.id;
          playerArr.push({
            playerID,
            name,
          });
        }
      }
      let teamOneIDds, teamTwoIDs;
      if (cup) {
        teamOneIDds = teams[0].players.map((player) => player.playerID);
        teamTwoIDs = teams[1].players.map((player) => player.playerID);
      }

      // console.log(teamOneIDds, teamTwoIDs);
      const ctpEnabled = round.ctpEnabled ?? false;
      const ldEnabled = round.ldEnabled ?? false;
      if (ctpEnabled || ldEnabled) {
        const toPush = {
          value: roundID,
          label: `Round ${i + 1}`,
        };
        if (ctpEnabled) {
          const { ctpMap } = round;
          let ctpLeaderboard = [];
          if (cup) {
            for (let j = 0; j < teams.length; j++) {
              const players = teams[j].players;
              for (let n = 0; n < players.length; n++) {
                ctpLeaderboard.push(players[n]);
              }
            }
          } else if (format === "team-net-strokeplay") {
            ctpLeaderboard = playerArr;
          } else {
            ctpLeaderboard = roundDoc.data().leaderboard;
          }
          for (let i = 0; i < ctpLeaderboard.length; i++) {
            const row = ctpLeaderboard[i];
            const playerID = row.playerID;
            // console.log(ctpMap[playerID]);
            // if (row.ctp === 0) {
            //   continue;
            // }
            if (cup) {
              let teamColor = "";
              if (teamOneIDds.includes(playerID)) {
                teamColor = teamOneColor;
              } else {
                teamColor = teamTwoColor;
              }

              ctpLeaderboard[i]["teamColor"] = teamColor;
            }

            ctpLeaderboard[i]["ctp"] = ctpMap[playerID] ?? "";
          }

          ctpLeaderboard.sort((a, b) => {
            if (a.ctp === "") {
              return 1;
            }
            if (b.ctp === "") {
              return -1;
            }

            return a.ctp - b.ctp;
          });
          toPush["ctpLeaderboard"] = ctpLeaderboard;
          toPush["ctpEnabled"] = true;
          toPush["ctpMap"] = round.ctpMap;
          toPush["ctpHole"] = round.ctpHole;
        }
        if (ldEnabled) {
          const { ldMap } = round;
          let ldLeaderboard = [];
          if (cup) {
            for (let j = 0; j < teams.length; j++) {
              const players = teams[j].players;
              for (let n = 0; n < players.length; n++) {
                ldLeaderboard.push(players[n]);
              }
            }
          } else if (format === "team-net-strokeplay") {
            ldLeaderboard = playerArr;
          } else {
            ldLeaderboard = roundDoc.data().leaderboard;
          }
          for (let i = 0; i < ldLeaderboard.length; i++) {
            const row = ldLeaderboard[i];
            if (row.ld === 0) {
              continue;
            }
            const playerID = row.playerID;

            ldLeaderboard[i].ld = ldMap[playerID] ?? 0;

            if (cup) {
              let teamColor = "";
              if (teamOneIDds.includes(playerID)) {
                teamColor = teamOneColor;
              } else {
                teamColor = teamTwoColor;
              }
              ldLeaderboard[i]["teamColor"] = teamColor;
            }
          }

          ldLeaderboard.sort((a, b) => b.ld - a.ld);
          toPush["ldLeaderboard"] = ldLeaderboard;
          toPush["ldMap"] = round.ldMap;
          toPush["ldEnabled"] = true;
          toPush["ldHole"] = round.ldHole;
        }
        roundArr.push(toPush);
      }
    }
    setSelRound(roundArr[0]);
    setRounds(roundArr);
    setLoading(false);
  }

  return (
    <div className="closest-longest">
      {roundIDs.length > 1 && (
        <div className="input-group">
          <div className="ig-header">
            <p>Select Round</p>
          </div>
          <Dropdown
            options={rounds}
            value={selRound}
            onChange={(e) => {
              for (let i = 0; i < rounds.length; i++) {
                if (rounds[i].value === e.value) {
                  setSelRound(rounds[i]);
                }
              }
            }}
          />
        </div>
      )}
      {!loading && selRound.ldEnabled && selected === "drive" && (
        <>
          <div className="sub-header mt-20">
            <h5 className="">Longest Drive - Hole {selRound.ldHole + 1}</h5>
          </div>
          <div className="cl-list">
            {selRound.ldLeaderboard.map((row, index) => (
              <div
                key={`${index}ctp`}
                style={{
                  backgroundColor:
                    index === 0 ? row.teamColor ?? teamOneColor : "white",
                }}
                className={index === 0 ? "cl-row cl-row-active" : "cl-row"}
              >
                <p>{row.name}</p>
                {meters ? <h5>{row.ld}m</h5> : <h5>{Math.round(row.ld)}m</h5>}
              </div>
            ))}
          </div>
        </>
      )}
      {!loading && selRound.ctpEnabled && selected === "pin" && (
        <>
          <div className="sub-header mt-20">
            <h5 className="">
              Closest to the Pin - Hole {selRound.ctpHole + 1}
            </h5>
          </div>
          <div className="cl-list">
            {selRound.ctpLeaderboard.map((row, index) => (
              <div
                key={`${index}cl`}
                style={{
                  backgroundColor:
                    index === 0 ? row.teamColor ?? teamOneColor : "transparent",
                }}
                className={index === 0 ? "cl-row cl-row-active" : "cl-row"}
              >
                <p>{row.name}</p>
                {meters ? (
                  <h5>{row.ctp}m</h5>
                ) : (
                  <h5>{Math.round(row.ctp)}ft</h5>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ClosestLongest;
