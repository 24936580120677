import React, { useState, useEffect, useRef, useCallback } from "react";
import { db, storage } from "../../firebase";
import "./TeamLeagues.css";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Icons
import Close from "@mui/icons-material/Close";
import AddCircle from "@mui/icons-material/AddCircle";

// Components
import Switch from "react-ios-switch/lib/Switch";

import Compressor from "compressorjs";
import { Country, City, State } from "country-state-city";
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackdropLoader from "../Loaders/BackdropLoader";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { useDropzone } from "react-dropzone";
import ImageSelector from "../../components/Events/ImageSelector/ImageSelector";
import Pill from "../../components/Buttons/Pill";
import { Dialog } from "@mui/material";
import EventCreator from "../Events/EventCreator/EventCreator";

function TeamLeagueEditor({
  edit = false,
  teamLeagueID = "",
  goBack = () => {},
  reload = () => {},
  hideBack = false,
  defaultClubID = "",
}) {
  useEffect(() => {
    console.log(defaultClubID);
    if (!edit) {
      setLoading(false);
    }
  }, []);
  const navigate = useNavigate();

  const { userDetails, currentUser } = useAuth();

  useEffect(() => {
    if (edit) {
      loadDetails();
      return;
    }
    if (userDetails) {
      sortLocation();
    }
  }, [userDetails]);

  useEffect(() => {
    if (currentUser) {
      loadFriends();
    }
  }, [currentUser]);

  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [friends, setFriends] = useState([]);
  const [playerMap, setPlayerMap] = useState();
  const [betterballStableford, setBetterballStableford] = useState(false);
  const [matchPoints, setMatchpoints] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        setImage(res);
      },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  function sortLocation() {
    const userCity = userDetails.city;
    const userCountry = userDetails.country;
    const userState = userDetails.state;
    // console.log(userCity, userCountry, userState);
    let stateCode = "";
    // Country
    const allCunts = Country.getAllCountries().map((country) => {
      const item = {
        name: country.name,
        value: country.name,
        code: country.isoCode,
      };
      return item;
    });
    setCountries(allCunts);
    setSelCountry(userCountry);

    // State
    const allStates = State.getStatesOfCountry(userCountry);
    setStates(allStates);
    setSelState(userState);

    for (let i = 0; i < allStates.length; i++) {
      if (allStates[i].name === userState) {
        stateCode = allStates[i].isoCode;
      }
    }

    // City
    const cities = City.getCitiesOfState(userCountry, stateCode);
    setCities(cities);
    setSelCity(userCity);
    setLoading(false);
  }
  const [admins, setAdmins] = useState([]);

  const cityRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();
  // General
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [showAdd, setShowAdd] = useState(false);

  // Details
  const [leagueName, setLeagueName] = useState();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [description, setDescription] = useState("");

  const [underway, setUnderway] = useState(false);

  const [update, setUpdate] = useState(0);
  const [shortName, setShortName] = useState("");

  const [selCountry, setSelCountry] = useState("");
  const [selCity, setSelCity] = useState("");
  const [selState, setSelState] = useState("");

  async function loadFriends() {
    const { friends } = userDetails;
    console.log(friends);
    const links = friends.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(links);

    const arr = [];

    const mep = {};

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      if (!playerDoc.exists) {
        continue;
      }
      const { firstName, lastName, profileImage, handicap } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
      });
      mep[playerID] = {
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
      };
    }
    setPlayerMap(mep);
    setFriends(arr);
  }

  function validate() {
    if (leagueName === "") {
      setError("Please enter a name for your league");
      return false;
    }

    if (imageURL === "") {
      setError("Please select an image for your league");
      return false;
    }
  }

  function loadLocations({ country, city, state }) {
    // console.log(userCity, userCountry, userState);
    let stateCode = "";
    // Country
    const allCunts = Country.getAllCountries().map((country) => {
      const item = {
        name: country.name,
        value: country.name,
        code: country.isoCode,
      };
      return item;
    });
    setCountries(allCunts);

    // State
    const allStates = State.getStatesOfCountry(country);
    setStates(allStates);

    for (let i = 0; i < allStates.length; i++) {
      if (allStates[i].name === state) {
        stateCode = allStates[i].isoCode;
      }
    }

    // City
    const cities = City.getCitiesOfState(country, stateCode);
    setCities(cities);
  }

  async function loadDetails() {
    setLoading(true);
    const leagueDoc = await db
      .collection("teamLeagues")
      .doc(teamLeagueID)
      .get();
    const league = leagueDoc.data();

    const {
      leagueName,
      shortName,
      description,
      imageLink,
      country,
      city,
      state,
    } = league;
    let { adminIDs } = league;
    adminIDs = adminIDs.filter((item) => item !== null);
    setMatchpoints(league.matchPoints ?? false);
    setBetterballStableford(league.betterballStableford ?? false);
    loadLocations({ country, city, state });
    setSelCity(city);
    setSelCountry(country);
    setSelState(state);
    setLeagueName(leagueName);
    setShortName(shortName);
    setDescription(description);
    setImageURL(imageLink);
    setLoading(false);
    setAdmins(adminIDs);
  }

  async function saveLeague() {
    setLoading(true);
    const val = validate();
    if (val === false) {
      return setLoading(false);
    }
    const adminIDs = admins.map((admin) => admin.playerID);
    if (!adminIDs.includes(currentUser.uid)) {
      adminIDs.push(currentUser.uid);
    }

    try {
      const leagueDpc = await db.collection("teamLeagues").add({
        dateCreated: new Date(),
        leagueName,
        shortName,
        adminIDs,
        description,
        matchPoints,
        clubID: defaultClubID,
        city: selCity,
        country: selCountry,
        state: selState,
        roundIDs: [],
        started: false,
        seasonIDs: [],
        seasonID: "",
        seasonName: "",
        eventIDs: [],
        playerIDs: [],
        invitedIDs: [],
        teams: [],
        pointsTable: [],
        teamTable: [],
        betterballStableford,
      });

      const leagueID = leagueDpc.id;

      if (image !== "") {
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], `${leagueID}image.jpg`, {
              contentType,
            });
            // access file here
            const task = storage
              .ref()
              .child(`${leagueID}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("teamLeagues").doc(leagueID).update({
                  imageLink: url,
                });
              });
            });
          });
        } else {
          const task = storage
            .ref()
            .child(`${leagueID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("teamLeagues").doc(leagueID).update({
                imageLink: url,
              });
            });
          });
        }
      }
      reload();
      goBack();
      navigate(`/team-leagues/${leagueID}`);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    if (validate() === false) {
      return setLoading(false);
    }
    const adminIDs = admins.map((admin) => admin.playerID ?? admin);
    if (!adminIDs.includes(currentUser.uid)) {
      adminIDs.push(currentUser.uid);
    }

    // console.log(adminIDs);
    // return setLoading(false);

    try {
      await db.collection("teamLeagues").doc(teamLeagueID).update({
        leagueName,
        shortName,
        description,
        city: selCity,
        country: selCountry,
        state: selState,
        clubID: defaultClubID,
        adminIDs,
        betterballStableford,
        matchPoints,
      });

      if (image !== "") {
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], `${teamLeagueID}image.jpg`, {
              contentType,
            });
            // access file here
            const task = storage
              .ref()
              .child(`${teamLeagueID}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("teamLeagues").doc(teamLeagueID).update({
                  imageLink: url,
                });
              });
            });
          });
        } else {
          const task = storage
            .ref()
            .child(`${teamLeagueID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("teamLeagues").doc(teamLeagueID).update({
                imageLink: url,
              });
            });
          });
        }
      }
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  function loadStates(cunt) {
    const states = State.getStatesOfCountry(cunt);
    const countryDeets = Country.getCountryByCode(cunt);
    // setSelCountry(countryDeets.name);
    setStates(states);
  }

  async function loadCities(state, count = "") {
    let toUse = selCountry;
    if (count !== "") {
      toUse = count;
    }
    const test = State.getStatesOfCountry(toUse);
    let sc = "";
    let cc = "";
    for (let i = 0; i < test.length; i++) {
      if (test[i].name === state) {
        sc = test[i].isoCode;
        cc = test[i].countryCode;
      }
    }
    const cities = City.getCitiesOfState(cc, sc);

    const newArr = cities.map((city) => {
      const item = {
        name: city.name,
        value: city.name,
      };
      return item;
    });

    // setSelState(sc);

    setUpdate((current) => current + 1);
    setCities(newArr);
  }

  return (
    <div className="team-league-editor">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {loading && <BackdropLoader />}
      {!hideBack && <BackRow action={goBack} />}
      <PageHeader text="Team League Editor" />

      <Dialog open={showAdd} onClose={() => setShowAdd(false)}>
        <div className="ce-admins">
          <PageHeader
            text="Add Admins"
            showClose
            close={() => setShowAdd(false)}
          />
          <div className="cea-players">
            {friends.map((player) => {
              let found = false;
              for (let i = 0; i < admins.length; i++) {
                if (admins[i].playerID === player.playerID) {
                  found = true;
                }
              }
              return (
                <PlayerItem
                  key={player.playerID}
                  name={player.name}
                  noLink
                  hideIcons
                  showProfile={() => {
                    setAdmins((current) => {
                      if (found) {
                        const filtered = current.filter(
                          (admin) => admin.playerID !== player.playerID
                        );
                        return filtered;
                      } else {
                        current.push(player);
                        return current;
                      }
                    });
                    setUpdate((current) => current + 1);
                  }}
                  showBg={found}
                  showAdd
                  hideEdit
                  img={player.profileImage}
                  hideHCP
                />
              );
            })}
          </div>
          {/* <div className="flex-center mt-40">
            <button className="default-button">Save</button>
          </div> */}
        </div>
      </Dialog>

      {showImages ? (
        <ImageSelector
          goBack={() => {
            setShowImages(false);
          }}
          setSelectedImage={(e) => {
            setImageURL(e);
            setImage(e);
          }}
          selectedImage={image}
          type="event"
        />
      ) : (
        <>
          <div className="sub-header">
            <h5>Details</h5>
          </div>
          <div className="ec-box">
            <div className="input-group">
              <p>League Name</p>
              <input
                type="text"
                value={leagueName}
                onChange={(e) => setLeagueName(e.target.value)}
                className="default-input"
                placeholder="Enter league name..."
              />
            </div>
            <div className="input-group">
              <p>Short Name</p>
              <input
                type="text"
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
                className="default-input"
                placeholder="Enter club name..."
              />
            </div>

            <div className="input-group nassau-group mt-10">
              <div className="ig-header">
                <p>Betterball Stableford</p>
              </div>
              <Switch
                onColor="#1e7a69"
                // disabled={adjusted}
                checked={betterballStableford}
                className={
                  betterballStableford
                    ? "scoring-switch switch-on"
                    : "scoring-switch"
                }
                onChange={() => {
                  setBetterballStableford((current) => !current);
                }}
              />
            </div>

            <div className="input-group nassau-group mt-10">
              <div className="ig-header">
                <p>Match Pts System</p>
              </div>
              <Switch
                onColor="#1e7a69"
                // disabled={adjusted}
                checked={matchPoints}
                className={
                  matchPoints ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => {
                  setMatchpoints((current) => !current);
                }}
              />
            </div>
          </div>

          <div className="ec-box">
            <div className="input-group mb-20">
              <p>League Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imageURL} />
                      </div>
                    )}
                    {imageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                  <div className="dz-or">
                    <p className="">Or</p>
                  </div>
                </div>
                <Pill
                  text={"Choose Preset Image"}
                  onClick={() => setShowImages(true)}
                />
              </div>
            </div>
            <div className="input-group">
              <div className="ig-header">
                <p>Description</p>
              </div>{" "}
              <textarea
                placeholder="Enter league description..."
                defaultValue={description}
                rows={6}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="sub-header">
            <h5>Location</h5>
          </div>
          <div className="ec-box">
            <div className="input-group select-group">
              <p>Country</p>
              <select
                className="default-input default-select"
                value={selCountry}
                ref={countryRef}
                onChange={(e) => {
                  loadStates(countryRef.current.value);
                  setSelCountry(countryRef.current.value);
                }}
              >
                <option value=""></option>
                {countries.map((country) => {
                  return (
                    <option
                      key={country.code}
                      selected={country.code === selCountry}
                      value={country.code}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-group select-group">
              <p>State / Province</p>
              <select
                className="default-input default-select"
                value={selState}
                disabled={selCountry === ""}
                ref={stateRef}
                onChange={(e) => {
                  setSelState(stateRef.current.value);
                  loadCities(stateRef.current.value);
                }}
              >
                <option value=""></option>

                {states.map((state) => {
                  return (
                    <option
                      selected={state.name === selState}
                      value={state.code}
                    >
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-group select-group">
              <p>City</p>
              <select
                className="default-input default-select"
                value={selCity}
                disabled={selState === ""}
                ref={cityRef}
                onChange={(e) => {
                  setSelCity(cityRef.current.value);
                }}
              >
                <option value=""></option>

                {cities.map((city) => {
                  return (
                    <option selected={city.name === selCity} value={city.name}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="sub-header">
            <div className="ig-header">
              <h5>Admins</h5>
              <AddCircle
                onClick={() => {
                  setShowAdd(true);
                }}
                className="dg-icon-bg icon-cursor ml-20"
              />
            </div>
          </div>

          <div className="ec-box mt-20">
            {userDetails && (
              <PlayerItem
                name={userDetails.name}
                hideHCP
                showAdd
                img={userDetails.profileImage}
                noLink
                hideMessage
                hideProfile
                hideEdit
                hideRemove
              />
            )}
            {playerMap &&
              admins.map((playerID) => {
                const player = playerMap[playerID];
                if (playerID === currentUser.uid) {
                  return null;
                }
                return (
                  <PlayerItem
                    key={`admin${player.playerID}`}
                    name={player.name}
                    noLink
                    showAdd
                    hideEdit
                    hideRemove={admins.length === 1}
                    hideMessage
                    hideProfile
                    removeAction={() => {
                      setAdmins((current) => {
                        const filtered = current.filter(
                          (admin) => admin.playerID !== player.playerID
                        );
                        return filtered;
                      });
                      setUpdate((current) => current + 1);
                    }}
                    img={player.profileImage}
                    hideHCP
                  />
                );
              })}
          </div>
          <div className="flex-center mb-40">
            <button
              className="default-button"
              onClick={edit ? saveChanges : saveLeague}
            >
              {edit ? "Save Changes" : "Save League"}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default TeamLeagueEditor;
