import React from "react";

// Icons
import DeblurIcon from "@mui/icons-material/Deblur";

// Components
import { Badge } from "@mui/material";

const GradientAddIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <DeblurIcon className="ps-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);

function StatsItem({ items = [], rank = 0, player = {} }) {
  return (
    <div className="stats-item log-item player-score">
      <div className="ps-left">
        <p>{rank}</p>
      </div>
      <div className="ps-right">
        <div className="ps-right-left">
          <h3>{player.player.userName}</h3>
          {false && (
            <div className="hcp-container">
              <div className="svg-cont">
                <GradientAddIcon
                  className="ps-icon"
                  style={{ color: "21C17C", height: "16px" }}
                />
              </div>

              <p>
                {player.handicap < 0
                  ? `+${player.player.handicap * -1}`
                  : player.player.handicap}{" "}
                hcp
              </p>
            </div>
          )}
        </div>
        {items.map((item) => {
          // console.log(item.label);
          return (
            <div
              key={`${player.playerID}${item.value}${item.type}`}
              className="ps-right-right"
            >
              <p>
                {item.type === "net"
                  ? player.netStats[item.value]
                  : item.type === "mp" && item.value.includes("Perc")
                  ? Math.round(player.matchplayStats[item.value] * 100)
                  : item.type === "mp"
                  ? player.matchplayStats[item.value]
                  : player.grossStats[item.value]}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StatsItem;
