import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import "./Drills.css";

// Icons

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { sortTiedArray } from "../HelperFunctions";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Tabs, Tab } from "@mui/material";
import DisplayDrillAttempt from "../ClientDrills/DisplayDrillAttempt";
import BackRow from "../../display/Rows/BackRow";

function DrillDetails({ id = "", clubID = "", goBack = () => {} }) {
  // Admin
  // const { id } = useParams();
  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(true);
  const [academyID, setAcademyID] = useState("");
  const [added, setAdded] = useState(false);
  const [error, setError] = useState("");
  const [selPage, setSelPage] = useState("details");
  const [custom, setCustom] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [perfectScore, setPerfectScore] = useState("");

  const [academyAttempts, setAcademyAttempts] = useState([]);
  const [globalAttempts, setGlobalAttempts] = useState([]);

  const [academyHighScore, setAcademyHighScore] = useState(0);
  const [globalHighScore, setGlobalHighScore] = useState(0);

  // UE
  useEffect(() => {
    // alert("hello");

    setAcademyID({ clubID });
    loadDetails({ clubID });
  }, []);

  // Funcs
  async function loadDetails({ clubID = "" }) {
    console.log(id, clubID);
    const acaDrillDoc = await db
      .collection("clubs")
      .doc(clubID)
      .collection("drills")
      .doc(id)
      .get();

    if (acaDrillDoc.exists) {
      const acaDrillData = acaDrillDoc.data();
      setCustom(true);
      setAdded(true);

      const {
        title,
        description,
        images,
        videoLink,
        attempts,
        perfectScore,
        highScore,
      } = acaDrillData;

      console.log(attempts);

      const sortedTies = sortTiedArray(attempts);

      setDescription(description);
      setImages(images);
      setVideoLink(videoLink);
      setAcademyAttempts(sortedTies);
      setAcademyHighScore(highScore ?? 0);
      setPerfectScore(perfectScore);
      setTitle(title);
      setLoading(false);
    } else {
      const drillDoc = await db
        .collection("admin")
        .doc("academy")
        .collection("drills")
        .doc(id)
        .get();
      const drillData = drillDoc.data();
      const {
        title,
        description,
        images,
        videoLink,
        attempts,
        perfectScore,
        highScore,
      } = drillData;
      console.log(attempts);

      const sortedTies = sortTiedArray(attempts);

      setTitle(title);
      setDescription(description);
      setImages(images);
      setVideoLink(videoLink);
      setGlobalAttempts(sortedTies);
      setGlobalHighScore(highScore ?? 0);
      setPerfectScore(perfectScore);
      setLoading(false);
    }
  }

  return (
    <div className="drill-details sig-page">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <BackRow action={goBack} />
      <PageHeader text={title} showBack back={() => navigate(-1)} />

      <div className="dash-tab gre-meu ml-20">
        <Tabs value={selPage} onChange={(e, v) => setSelPage(v)}>
          <Tab value={"details"} label={"Details"} />
          <Tab value={"attempts"} label={"Attempts"} />
        </Tabs>
      </div>

      <div className="pd-20">
        <div className="dd-box bs-subtle bss-white wbg pd-20">
          {selPage === "details" && (
            <>
              <div className="flex flex-align-center">
                <div className="dd-item flex flex-col">
                  <div className="neh-bo">
                    <h2 className="ne-header">Description</h2>
                  </div>
                  <div className="dd-desc">
                    <p>{description}</p>
                  </div>
                </div>
                <div className="dd-item flex flex-col flex-align-center ml-20">
                  <div className="neh-bo">
                    <h2 className="ne-header">Perfect Score</h2>
                  </div>
                  <div className="dd-desc">
                    <p>{perfectScore}</p>
                  </div>
                </div>
                <div className="dd-item flex flex-col flex-align-center ml-20">
                  <div className="neh-bx">
                    <h2 className="ne-header">High Score</h2>
                  </div>
                  <div className="dd-desc">
                    <p>{added ? academyHighScore : globalHighScore}</p>
                  </div>
                </div>
              </div>
              {images.length > 0 && (
                <>
                  <div className="neh-box mt-20">
                    <h2 className="ne-header">Images</h2>
                  </div>

                  <div className="dd-img-box flex">
                    {images.map((img, i) => {
                      return (
                        <div className="dd-img flex flex-col flex-align-center mr-20">
                          <img src={img.url} alt="description" />
                          <div className="dd-desc mt-10">
                            <p>{img.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}

          {selPage === "attempts" && (
            <>
              <div className="drill-attempt dd-attempt da-header flex flex-align-center mb-10">
                <div>
                  <p>Rank</p>
                </div>
                <div>
                  <p>Date</p>
                </div>
                <div>
                  <p>Name</p>
                </div>
                <div>
                  <p>Score</p>
                </div>
              </div>
              {custom &&
                academyAttempts.map((att, i) => {
                  console.log("ac");

                  return (
                    <DisplayDrillAttempt
                      key={`${i}att`}
                      attempt={att}
                      rk={att.rank}
                    />
                  );
                })}
              {!custom &&
                globalAttempts.map((att, i) => {
                  console.log("glob");
                  console.log(att);
                  return (
                    <DisplayDrillAttempt
                      key={`${i}att`}
                      attempt={att}
                      rk={att.rank}
                    />
                  );
                })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DrillDetails;
