import React, { useState, useEffect } from "react";
import { db, admin } from "../../../firebase";

import BackdropLoader from "../../Loaders/BackdropLoader";
import PageHeader from "../../display/Rows/PageHeader";
import { Dialog } from "@mui/material";
import ErrorModal from "../../ErrorModal/ErrorModal";
import MatchplayScore from "../../display/MatchplayScore/MatchplayScore";
import BackRow from "../../display/Rows/BackRow";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

function MatchplayHoleResults({
  matches = [],
  roundID = "",
  goBack = () => {},
  format = "Matchplay",
  eventID = "",
  defaultMatch,
  scorerID = "",
  cupID = "",
}) {
  const eventMatchups = admin.functions().httpsCallable("teamEventMatchups");
  const roundCupScore = admin.functions().httpsCallable("roundCupScore");

  const [loading, setLoading] = useState();
  const [error, setError] = useState("");

  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((c) => c + 1);
  }

  const [selMatch, setSelMatch] = useState(defaultMatch);
  const [showSelector, setShowSelector] = useState(true);

  useEffect(() => {
    console.log(scorerID);
    if (defaultMatch) {
      console.log(defaultMatch);
      setShowSelector(false);
    }
  }, []);

  function fbCalcs() {
    setSelMatch((cur) => {
      let t1Holes = 0;
      let t2Holes = 0;

      let thru = 0;

      for (let i = 0; i < cur.holes.length; i++) {
        const hole = cur.holes[i];
        if (hole.winner === "teamOne") {
          t1Holes += 1;
        }
        if (hole.winner === "teamTwo") {
          t2Holes += 1;
        }
        if (hole.winner !== "") {
          thru = i + 1;
        }
      }

      const diff = t1Holes - t2Holes;
      if (diff > 0) {
        cur.leader = "teamOne";
        cur.score = diff;
      } else if (diff < 0) {
        cur.leader = "teamTwo";
        cur.score = -diff;
      } else {
        cur.leader = "";
        cur.score = 0;
      }

      cur.thru = thru;

      const rem = 18 - thru;

      if (Math.abs(diff) > rem || thru === 18) {
        cur.completed = true;
        cur.complete = true;
      } else {
        cur.completed = false;
        cur.complete = false;
      }

      console.log(cur);

      return cur;
    });
    upd();
  }
  function mpCalcs() {
    setSelMatch((cur) => {
      let t1Holes = 0;
      let t2Holes = 0;

      let thru = 0;

      for (let i = 0; i < cur.holes.length; i++) {
        const hole = cur.holes[i];
        if (hole.winner === selMatch.playerOne.playerID) {
          t1Holes += 1;
        }
        if (hole.winner === selMatch.playerTwo.playerID) {
          t2Holes += 1;
        }
        if (hole.winner !== "") {
          thru = i + 1;
        }
      }

      const diff = t1Holes - t2Holes;
      if (diff > 0) {
        cur.leader = selMatch.playerOne.playerID;
        cur.score = diff;
      } else if (diff < 0) {
        cur.leader = selMatch.playerTwo.playerID;
        cur.score = -diff;
      } else {
        cur.leader = "";
        cur.score = 0;
      }

      cur.thru = thru;

      const rem = 18 - thru;

      console.log(diff, rem);

      if (Math.abs(diff) > rem || thru === 18) {
        cur.completed = true;
        cur.complete = true;
      } else {
        cur.completed = false;
        cur.complete = false;
      }

      return cur;
    });
    upd();
  }

  async function saveChanges() {}

  async function saveFourballResult() {
    // return console.log(selMatch);
    setLoading(true);

    try {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();

      const { matches } = round;

      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (match.matchID === selMatch.matchID) {
          matches[i] = selMatch;
        }
      }

      console.log(matches);
      // return setLoading(false);

      await roundDoc.ref.update({
        matches,
      });

      if (cupID !== "") {
        roundCupScore({ roundID });
      } else {
        eventMatchups({
          eventID,
        });
      }
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="mp-hole-results">
      {loading && <BackdropLoader />}
      <Dialog open={showSelector} onClose={() => setShowSelector(false)}>
        <div className="tri-dia pd-20">
          <BackRow action={goBack} />

          <div className="tri-matches mt-20">
            {format === "Matchplay" &&
              matches.map((match, index) => {
                if (scorerID !== "") {
                  if (
                    match.playerOne.teamID !== scorerID &&
                    match.playerTwo.teamID !== scorerID
                  ) {
                    return null;
                  }
                }
                return (
                  <div key={match.matchID} className="tem-match">
                    <MatchplayScore
                      nameOne={match.playerOne.name}
                      nameTwo={match.playerTwo.name}
                      score={match.score}
                      holes={match.thru}
                      idOne={match.playerOne.playerID}
                      idTwo={match.playerTwo.playerID}
                      leader={match.leader}
                      handleClick={() => {
                        setSelMatch(match);
                        setShowSelector(false);
                      }}
                    />
                  </div>
                );
              })}
            {format === "Fourball" &&
              matches.map((match, idnex) => {
                const { teamOne, teamTwo } = match;
                if (!teamOne) {
                  return null;
                }
                if (scorerID !== "") {
                  if (
                    match.teamOneID !== scorerID &&
                    match.teamTwoID !== scorerID
                  ) {
                    return null;
                  }
                }
                const p1 = teamOne[0];
                const p12 = teamOne[1];
                const p2 = teamTwo[0];
                const p22 = teamTwo[1];

                const t1 = p1.teamName;
                const t2 = p2.teamName;

                const nameOne = `${p1.lastName} / ${p12.lastName}`;
                const nameTwo = `${p2.lastName} / ${p22.lastName}`;

                let leaderID = match.leader;
                if (typeof leaderID !== "string") {
                  leaderID = "";
                }
                return (
                  <div key={match.matchID} className="tem-match">
                    <MatchplayScore
                      nameOne={nameOne}
                      nameTwo={nameTwo}
                      score={match.score ?? 0}
                      holes={match.thru}
                      idOne={"teamOne"}
                      idTwo={"teamTwo"}
                      leader={leaderID}
                      handleClick={() => {
                        console.log(match);
                        setSelMatch(match);
                        setShowSelector(false);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader text="Matchplay Holes" />
      <div className="ph-msg mb-20">
        <p>Select the relevant winner(s) for each hole</p>
      </div>

      {selMatch && (
        <div className="mhr-box">
          <div className="mhrb-ct">
            <div className="mhrb-hole-i">
              <div className="mhrb-left">
                <p></p>
              </div>
              <div className="mhrb-right">
                <div className="mhrb-holes">
                  <div style={{ marginRight: "2vw" }} className="mhrb-hole">
                    <div className="mr-hole-top">
                      <div className="mhrb-mid">
                        <p>
                          Match
                          <br />
                          State
                        </p>
                      </div>
                    </div>
                  </div>
                  {selMatch.holes &&
                    selMatch.holes.map((hole, i) => {
                      return (
                        <div className="mhrb-hole">
                          <div className="mr-hole-top">
                            <div className="mhrb-mid">
                              <p>
                                Hole <br />
                                {i + 1}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="mhrb-main">
              <div className="mhrb-left">
                <div className="mhrb-top mhrb-name">
                  {format === "Fourball" && (
                    <h2>
                      {selMatch.teamOne[0].name} / {selMatch.teamOne[1].name}
                    </h2>
                  )}
                  {format === "Matchplay" && <h2>{selMatch.playerOne.name}</h2>}
                </div>
                <div className="mhr-vers">
                  <p>vs</p>
                </div>
                <div className="mrhb-bot mhrb-name">
                  {format === "Fourball" && (
                    <h2>
                      {selMatch.teamTwo[0].name} / {selMatch.teamTwo[1].name}
                    </h2>
                  )}{" "}
                  {format === "Matchplay" && <h2>{selMatch.playerTwo.name}</h2>}
                </div>
              </div>

              {format === "Fourball" && (
                <div className="mhrb-state">
                  {selMatch && (
                    <>
                      <div className="mhr-state-top">
                        {selMatch.leader === "teamOne" &&
                          !selMatch.completed && (
                            <div className="mhr-state-act">
                              <div className="mhrw-box">
                                <h3>{selMatch.score} UP</h3>
                              </div>
                            </div>
                          )}
                        {selMatch.leader === "teamOne" &&
                          selMatch.completed && (
                            <div className="mhr-state-act">
                              <div className="mhrw-box">
                                <h3>
                                  {selMatch.score === 0 && "Tied"}
                                  {selMatch.score === 1 && "1 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 18 &&
                                    "2 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 17 &&
                                    "2 & 1"}
                                  {selMatch.score > 2 &&
                                    `${selMatch.score} & ${18 - selMatch.thru}`}
                                </h3>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="mhr-state-mid">
                        {selMatch.leader === "" && (
                          <div className="mhr-state-act-tie">
                            <div className="mhrw-box">
                              <h3>TIE</h3>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mhr-state-bot">
                        {selMatch.leader === "teamTwo" &&
                          !selMatch.completed && (
                            <div className="mhr-state-act mhrt-two-act">
                              <div className="mhrw-box">
                                <h3>{selMatch.score} UP</h3>
                              </div>
                            </div>
                          )}
                        {selMatch.leader === "teamTwo" &&
                          selMatch.completed && (
                            <div className="mhr-state-act mhrt-two-act">
                              <div className="mhrw-box">
                                <h3>
                                  {selMatch.score === 0 && "Tied"}
                                  {selMatch.score === 1 && "1 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 18 &&
                                    "2 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 17 &&
                                    "2 & 1"}
                                  {selMatch.score > 2 &&
                                    `${selMatch.score} & ${18 - selMatch.thru}`}
                                </h3>
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </div>
              )}
              {format === "Matchplay" && (
                <div className="mhrb-state">
                  {selMatch && (
                    <>
                      <div className="mhr-state-top">
                        {selMatch.leader === selMatch.playerOne.playerID &&
                          !selMatch.completed && (
                            <div className="mhr-state-act">
                              <div className="mhrw-box">
                                <h3>{selMatch.score} UP</h3>
                              </div>
                            </div>
                          )}
                        {selMatch.leader === selMatch.playerOne.playerID &&
                          selMatch.completed && (
                            <div className="mhr-state-act">
                              <div className="mhrw-box">
                                <h3>
                                  {selMatch.score === 0 && "Tied"}
                                  {selMatch.score === 1 && "1 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 18 &&
                                    "2 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 17 &&
                                    "2 & 1"}
                                  {selMatch.score > 2 &&
                                    `${selMatch.score} & ${18 - selMatch.thru}`}
                                </h3>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="mhr-state-mid">
                        {selMatch.leader === "" && (
                          <div className="mhr-state-act-tie">
                            <div className="mhrw-box">
                              <h3>TIE</h3>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mhr-state-bot">
                        {selMatch.leader === selMatch.playerTwo.playerID &&
                          !selMatch.completed && (
                            <div className="mhr-state-act-two">
                              <div className="mhrw-box">
                                <h3>{selMatch.score} UP</h3>
                              </div>
                            </div>
                          )}
                        {selMatch.leader === selMatch.playerTwo.playerID &&
                          selMatch.completed && (
                            <div className="mhr-state-act-two">
                              <div className="mhrw-box">
                                <h3>
                                  {selMatch.score === 0 && "Tied"}
                                  {selMatch.score === 1 && "1 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 18 &&
                                    "2 Up"}
                                  {selMatch.score === 2 &&
                                    selMatch.thru === 17 &&
                                    "2 & 1"}
                                  {selMatch.score > 2 &&
                                    `${selMatch.score} & ${18 - selMatch.thru}`}
                                </h3>
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="mrhb-right">
                <div className="mhrb-holes">
                  {format === "Fourball" &&
                    selMatch.holes &&
                    selMatch.holes.map((hole, i) => {
                      // console.log(hole);
                      return (
                        <div className="mhrb-hole">
                          <div
                            className={
                              hole.winner === "teamOne"
                                ? "mhr-win-hole mhrt-act"
                                : "mhr-win-hole"
                            }
                          >
                            <div
                              onClick={() => {
                                setSelMatch((cur) => {
                                  if (cur.holes[i].winner === "teamOne") {
                                    cur.holes[i].winner = "";
                                  } else {
                                    cur.holes[i].winner = "teamOne";
                                  }
                                  return cur;
                                });
                                upd();
                                fbCalcs();
                              }}
                              className="mhrw-box"
                            >
                              <h3>W</h3>
                            </div>
                          </div>
                          <div
                            className={
                              hole.winner === "tied"
                                ? "mhr-tie-hole mhrt-tie-act"
                                : "mhr-tie-hole"
                            }
                          >
                            <div
                              onClick={() => {
                                setSelMatch((cur) => {
                                  if (cur.holes[i].winner === "tied") {
                                    cur.holes[i].winner = "";
                                  } else {
                                    cur.holes[i].winner = "tied";
                                  }
                                  return cur;
                                });
                                upd();
                                fbCalcs();
                              }}
                              className="mhrw-box"
                            >
                              <h3>T</h3>
                            </div>
                          </div>
                          <div
                            className={
                              hole.winner === "teamTwo"
                                ? "mhr-win-hole mhrt-two-act"
                                : "mhr-win-hole"
                            }
                          >
                            <div
                              onClick={() => {
                                setSelMatch((cur) => {
                                  if (cur.holes[i].winner === "teamTwo") {
                                    cur.holes[i].winner = "";
                                  } else {
                                    cur.holes[i].winner = "teamTwo";
                                  }
                                  return cur;
                                });
                                upd();
                                fbCalcs();
                              }}
                              className="mhrw-box"
                            >
                              <h3>W</h3>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {format === "Matchplay" &&
                    selMatch.holes &&
                    selMatch.holes.map((hole, i) => {
                      // console.log(hole);
                      return (
                        <div className="mhrb-hole">
                          <div
                            className={
                              hole.winner === selMatch.playerOne.playerID
                                ? "mhr-win-hole mhrt-act"
                                : "mhr-win-hole"
                            }
                          >
                            <div
                              onClick={() => {
                                setSelMatch((cur) => {
                                  if (
                                    cur.holes[i].winner ===
                                    selMatch.playerOne.playerID
                                  ) {
                                    cur.holes[i].winner = "";
                                  } else {
                                    cur.holes[i].winner =
                                      selMatch.playerOne.playerID;
                                  }
                                  return cur;
                                });
                                upd();
                                mpCalcs();
                              }}
                              className="mhrw-box"
                            >
                              <h3>W</h3>
                            </div>
                          </div>
                          <div
                            className={
                              hole.winner === "tied"
                                ? "mhr-tie-hole mhrt-tie-act"
                                : "mhr-tie-hole"
                            }
                          >
                            <div
                              onClick={() => {
                                setSelMatch((cur) => {
                                  if (cur.holes[i].winner === "tied") {
                                    cur.holes[i].winner = "";
                                  } else {
                                    cur.holes[i].winner = "tied";
                                  }
                                  return cur;
                                });
                                upd();
                                mpCalcs();
                              }}
                              className="mhrw-box"
                            >
                              <h3>T</h3>
                            </div>
                          </div>
                          <div
                            className={
                              hole.winner === selMatch.playerTwo.playerID
                                ? "mhr-win-hole mhrt-two-act"
                                : "mhr-win-hole"
                            }
                          >
                            <div
                              onClick={() => {
                                setSelMatch((cur) => {
                                  if (
                                    cur.holes[i].winner ===
                                    selMatch.playerTwo.playerID
                                  ) {
                                    cur.holes[i].winner = "";
                                  } else {
                                    cur.holes[i].winner =
                                      selMatch.playerTwo.playerID;
                                  }
                                  return cur;
                                });
                                upd();
                                mpCalcs();
                              }}
                              className="mhrw-box"
                            >
                              <h3>W</h3>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center mt-40 mb-40">
            <button onClick={saveFourballResult} className="default-button">
              Save Changes
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MatchplayHoleResults;
