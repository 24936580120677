import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";

// Icons

// Components
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackdropLoader from "../Loaders/BackdropLoader";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import { Avatar } from "@mui/material";

function TeamLeagueEventPlayers({
  goBack = () => {},
  eventID = "",
  reload = () => {},
  live = false,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [update, setUpdate] = useState(0);

  // State
  const [teams, setTeams] = useState([]);
  const [playerIDs, setPlayerIDs] = useState([]);

  const [livePlayers, setLivePlayers] = useState([]);
  const [liveAdded, setLiveAdded] = useState([]);
  const [teamsPlaying, setTeamsPlaying] = useState([]);
  const [roundID, setRoundID] = useState("");

  useEffect(() => {
    loadPlayers();
  }, []);

  async function loadPlayers() {
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();
    const { leagueID, playerIDs } = event;

    if (!live) {
      setPlayerIDs(playerIDs);
    }
    if (live) {
      setLivePlayers(playerIDs);
      setRoundID(event.activeRoundID);

      const teamArr = [];
      for (let i = 0; i < event.matchups.length; i++) {
        const matchup = event.matchups[i];
        const { teamOne, teamTwo } = matchup;
        teamArr.push(teamOne.teamID);
        teamArr.push(teamTwo.teamID);
      }

      setTeamsPlaying(teamArr);
    }
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();

    const { teams } = league;

    for (let i = 0; i < teams.length; i++) {
      const tm = teams[i].players;

      tm.sort((a, b) => a.lastName.localeCompare(b.lastName));
      console.log(tm);
      teams[i].players = tm;
    }

    setTeams(teams);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      await db.collection("teamEvents").doc(eventID).update({
        playerIDs,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function saveLiveChanges() {
    setLoading(true);
    try {
      await db
        .collection("teamEvents")
        .doc(eventID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(...playerIDs),
        });
      await db
        .collection("rounds")
        .doc(roundID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(...playerIDs),
        });
      goBack();
      // await db.collection("triggers").add({
      //   type: "te-player-add",
      //   playerIDs,
      //   eventID,
      //   dateAdded: new Date(),
      // });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="tle-players">
      <BackRow action={goBack} />
      <PageHeader text="Manage Players" />
      <div className="ph-msg">
        <p>Click on a player to select or deselect them from this event</p>
      </div>
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <div className="ltm-teams">
        {teams.map((team, index) => {
          if (live) {
            if (!teamsPlaying.includes(team.teamID)) {
              return null;
            }
          }

          return (
            <div className="ltm-team pd-20" key={team.teamID}>
              <div className="ltm-team-top flex-align-center">
                <Avatar alt={team.teamName} src={team.imageLink} />
                <h3 className="mr-5v ml-20">{team.teamName}</h3>
              </div>
              <div className="ltm-members pd-20 flex-wrap">
                {team.players.map((player, index2) => {
                  if (live) {
                    if (livePlayers.includes(player.playerID)) {
                      return null;
                    }
                  }

                  return (
                    <PlayerItem
                      key={player.playerID}
                      name={player.name}
                      noLink
                      showAdd
                      showProfile={() => {
                        setPlayerIDs((current) => {
                          if (current.includes(player.playerID)) {
                            const filt = current.filter(
                              (item) => item !== player.playerID
                            );
                            return filt;
                          } else {
                            current.push(player.playerID);
                            return current;
                          }
                        });
                        setUpdate((current) => current + 1);
                      }}
                      showBg={playerIDs.includes(player.playerID)}
                      playerID={player.playerID}
                      hideProfile
                      hideEdit
                      hideIcons
                      //   hideIcons
                      hideImage
                      hideHCP
                      removeAction={() => {}}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex-center mb-40">
        <button
          onClick={live ? saveLiveChanges : saveChanges}
          className="default-button"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default TeamLeagueEventPlayers;
