import React, { useEffect, useState } from "react";
import "./ClientCourseProfile.css";
import { db } from "../../../firebase";

// Icons
import { IoGolfSharp } from "react-icons/io5";

// Components
import { Tab, Tabs } from "@mui/material";
import ClientCourseGraphs from "./ClientCourseGraphs";
import RoundItem from "../RoundItem/RoundItem";
import RoundCard from "../RoundCard/RoundCard";
import ClientCourseStats from "./ClientCourseStats";
import BackdropLoader from "../../Loaders/BackdropLoader";

function ClientCourseProfile({ course = {}, userID = "" }) {
  // State
  const [sel, setSel] = useState("card");
  const [grossStats, setGrossStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [crs, setCrs] = useState([]);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  // UE
  useEffect(() => {
    // console.log(course);
    setSel("card");
    loadGrossStats();
  }, [course]);

  async function loadGrossStats() {
    const { rounds } = course;
    // console.log(rounds);
    const roundIDs = rounds.map((r) => r.roundID);

    const refs = roundIDs.map((id) =>
      db.collection("rounds").doc(id).collection("scores").doc(userID).get()
    );

    const docs = await Promise.all(refs);

    const arr = [];
    const rdArr = [];

    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i].data();

      const { grossStats } = doc;
      const crRound = rounds[i];
      const dateObject = new Date(crRound.roundDate.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });

      grossStats.parThreesToPar =
        Math.round((grossStats.averageParThrees - 3) * 100) / 100;
      grossStats.parFoursToPar =
        Math.round((grossStats.averageParFours - 4) * 100) / 100;
      grossStats.parFivesToPar =
        Math.round((grossStats.averageParFives - 5) * 100) / 100;
      doc.roundID = roundIDs[i];
      doc.courseID = course.courseID;
      doc.courseName = course.name;
      doc.clientID = userID;
      doc.dateObject = dateObject;
      doc.dateString = dateString;
      rdArr.push(doc);
      arr.push(grossStats);
    }

    // rdArr.reverse();

    // console.log(rdArr);

    rdArr.sort((a, b) => b.dateObject - a.dateObject);

    setCrs(rdArr);

    setGrossStats(arr);
    setLoading(false);
  }

  return (
    <div className="cc-profile">
      {loading ? (
        <>
          <div className="cp-loader">
            <BackdropLoader color="#6ba390" size={20} className="btn-ico" />
          </div>
        </>
      ) : show ? (
        toShow
      ) : (
        <>
          <div className="gre-meu gmb">
            <Tabs value={sel} onChange={(e, v) => setSel(v)}>
              <Tab label={"Scorecard"} value={"card"} />
              <Tab label={"Graphs"} value={"graphs"} />
              <Tab label={"Rounds"} value={"rounds"} />
              <Tab label={"Stats"} value={"stats"} />
            </Tabs>
          </div>
          {sel === "card" && (
            <>
              <div className="rc-sc bs-subtle mt-20">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="wide-td">Course Averages</th>
                      <th></th>
                      <th></th>
                      <th></th>

                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="wide-td">Your Averages</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <th className="rc-head-ico">
                        <IoGolfSharp />
                      </th>
                      <th>Par</th>
                      <th>Stroke</th>
                      <th>Gross</th>
                      <th>Net</th>
                      <th>Putts</th>
                      <th>GIR</th>
                      <th>FIR</th>
                      <th></th>
                      <th>Gross</th>
                      <th>Net</th>
                      <th>Putts</th>
                      <th>GIR</th>
                      <th>FIR</th>
                      <th>Previous</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {course.holeStats.map((hole, i) => {
                      const courseHole = course.courseHoleStats[i];
                      const prev = hole.grossHistory ?? [];
                      return (
                        <React.Fragment key={`hole${i}`}>
                          {" "}
                          <tr className="rc-row">
                            <td>{i + 1}</td>
                            <td>{hole.par}</td>
                            <td>{hole.stroke}</td>
                            <td
                              className={
                                courseHole.grossAverage < hole.par
                                  ? "ud-par"
                                  : courseHole.grossAverage > hole.par
                                  ? "over-par"
                                  : ""
                              }
                            >
                              {courseHole.grossAverage}
                            </td>
                            <td
                              className={
                                courseHole.netAverage < hole.par
                                  ? "ud-par"
                                  : courseHole.netAverage > hole.par
                                  ? "over-par"
                                  : ""
                              }
                            >
                              {courseHole.netAverage}
                            </td>
                            <td>{courseHole.averagePutts}</td>
                            <td>
                              {Math.round(courseHole.girAverage * 10000) / 100}%
                            </td>
                            <td>
                              {Math.round(courseHole.firAverage * 10000) / 100}%
                            </td>
                            <th></th>

                            <td
                              className={
                                hole.averageGross < hole.par
                                  ? "ud-par"
                                  : hole.averageGross > hole.par
                                  ? "over-par"
                                  : ""
                              }
                            >
                              {hole.averageGross}
                            </td>
                            <td
                              className={
                                hole.averageNet < hole.par
                                  ? "ud-par"
                                  : hole.averageNet > hole.par
                                  ? "over-par"
                                  : ""
                              }
                            >
                              {hole.averageNet}
                            </td>
                            <td
                              className={
                                hole.averagePutts > 2 ? "over-par" : ""
                              }
                            >
                              {hole.averagePutts}
                            </td>
                            <td>{hole.girAverage * 100}%</td>
                            <td>{hole.firAverage * 100}%</td>
                            {prev.map((pre, i2) => {
                              if (i2 > 4) {
                                return null;
                              }
                              return <td key={`${i2}pre`}>{pre}</td>;
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {sel === "graphs" && (
            <>
              <ClientCourseGraphs
                overallStats={course.stats}
                rounds={course.rounds}
                grossStats={grossStats}
                userID={userID}
                overallGrossStats={course.grossStats}
                showRd={(rd) => {
                  setSel("rounds");
                  setToShow(
                    <RoundCard
                      userID={userID}
                      showBack
                      goBack={() => setShow(false)}
                      round={rd}
                    />
                  );
                  setShow(true);
                }}
              />
            </>
          )}

          {sel === "stats" && (
            <>
              <ClientCourseStats
                grossStats={course.grossStats}
                stats={course.stats}
                userID={userID}
              />
            </>
          )}

          {sel === "rounds" && (
            <div className="mt-20">
              {crs.map((rd, i) => {
                rd.courseName = course.name;
                // const dt = new Date(
                //   course.rounds[i].roundDate.toDate()
                // ).toLocaleDateString("en-us", { dateStyle: "full" });
                // rd.dateString = dt;
                return (
                  <RoundItem
                    key={i}
                    clickAct={() => {
                      setToShow(
                        <RoundCard
                          userID={userID}
                          showBack
                          goBack={() => setShow(false)}
                          round={rd}
                        />
                      );
                      setShow(true);
                    }}
                    round={rd}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ClientCourseProfile;
