import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import { Dialog } from "@mui/material";
import ErrorModal from "../../ErrorModal/ErrorModal";
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

function RemoveLiveRound({ eventID = "", goBack = () => {} }) {
  const [loading, setLoading] = useState(true);
  const [rounds, setRounds] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState("");
  const [selRound, setSelRound] = useState();
  const [done, setDone] = useState(false);

  const [currentRound, setCurrentRound] = useState();
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();

    const { rounds, roundIDs, activeRoundID } = event;

    const currentIndex = roundIDs.indexOf(activeRoundID);
    setCurrentRound(currentIndex);
    setRounds(rounds);

    setLoading(false);
  }

  async function saveTrigger() {
    setLoading(true);
    try {
      await db.collection("triggers").add({
        dateAdded: new Date(),
        type: "del-live-rd",
        eventID,
        roundIndex: selRound,
      });
      setDone(true);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="rem-live-rd">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <BackRow action={goBack} />
      <PageHeader text="Remove Round" />
      <div className="ph-msg">
        <p>Select an upcoming round to remove</p>
      </div>
      {done ? (
        <>
          <div className="erp-finished">
            <div className="erpc-msg">
              <p>
                This round was successfully removed. Changes will reflect in a
                few moments.
              </p>
              {/* <div className="flex-center">
                      <button onClick={reset} className="default-button">
                        Keep Purging
                      </button>
                    </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="rlr-rds mt-20 pd-20">
            {rounds.map((rd, i) => {
              if (i <= currentRound) {
                return null;
              }

              const { date } = rd;
              const dateObj = new Date(date.toDate());
              const ds = dateObj.toLocaleDateString("en-us", {
                dateStyle: "long",
              });

              return (
                <PlayerItem
                  noLink
                  hideIcons
                  hideHCP
                  hideImage
                  name={`Round ${i + 1} - ${ds}`}
                  showBg={selRound === i}
                  showProfile={() => {
                    setSelRound(i);
                    update();
                  }}
                />
              );
            })}
          </div>
          <div className="flex-center">
            <button onClick={saveTrigger} className="default-button">
              Confirm
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default RemoveLiveRound;
