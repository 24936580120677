import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import StatsItem from "../StatsItem/StatsItem";

// Icons

// Components
import CustomDropdown from "../CustomDropdown";
import { ClipLoader } from "react-spinners";
import { yearsSince2022 } from "../../HelperFunctions";

function RoundStats({
  stats,
  grossStats,
  userID = "",
  courseID = "",
  course = "",
}) {
  const [loading, setLoading] = useState(true);
  const [comps, setComps] = useState({});
  const [grossComps, setGrossComps] = useState({});

  const [gross, setGross] = useState(true);

  const [selComp, setSelComp] = useState("");
  const [compMap, setCompMap] = useState({});
  const [compOpts, setCompOpts] = useState({});

  useEffect(() => {
    // console.log(stats.totalGrossScrambles / stats.totalGrossScrambleAttempts);
    loadComparisons();
  }, []);

  async function loadComparisons() {
    const playerDoc = await db.collection("users").doc(userID).get();
    const player = playerDoc.data();

    const courseStats = await playerDoc.ref
      .collection("courseStats")
      .doc(courseID)
      .get();
    const csData = courseStats.data();

    const { stats, grossStats } = player;
    setComps(stats);
    setGrossComps(grossStats);
    // console.log(stats);
    const opts = [
      {
        label: "Overall",
        value: "overall",
      },
      {
        label: course,
        value: courseID,
      },
    ];
    const cmap = {
      overall: {
        grossStats,
        stats,
      },
      [`${courseID}`]: {
        grossStats: csData.grossStats,
        stats: csData.stats,
      },
    };

    const yarr = yearsSince2022();
    for (let i = 0; i < yarr.length; i++) {
      const year = yarr[i];
      cmap[year] = {
        stats: player[`${year}stats`],
        grossStats: player[`${year}GrossStats`],
      };
      opts.push({
        label: `${year}`,
        value: `${year}`,
      });
    }
    setCompMap(cmap);
    setCompOpts(opts);
    setSelComp("overall");
    setLoading(false);
  }

  return (
    <div className="round-stats bs-subtle">
      {loading ? (
        <div className="cp-loader"></div>
      ) : (
        <>
          <div className="flex flex-align-center">
            <div className="selector-group sg-small mr-20">
              <button
                onClick={() => {
                  setGross(true);
                }}
                className={gross ? "sg-left sg-active" : "sg-left"}
              >
                Gross
              </button>
              <button
                onClick={() => {
                  setGross(false);
                }}
                className={!gross ? "sg-right sg-active" : "sg-right"}
              >
                Net
              </button>
            </div>
            <CustomDropdown
              placeHolder="Select comparison..."
              value={selComp}
              opts={compOpts}
              handleChange={(e) => {
                setSelComp(e.value);
                const mepE = compMap[e.value];
                // console.log(compMap, e.value);
                setComps(mepE.stats);
                setGrossComps(mepE.grossStats);
              }}
            />
          </div>

          <div className="neh-box mt-20">
            <h2 className="ne-header">Scoring</h2>
          </div>
          <div className="rs-box flex flex-wrap">
            <StatsItem
              title={gross ? "Gross Strokes" : "Net Strokes"}
              stat={gross ? stats.grossScore : stats.netScore}
              comp={gross ? comps.averageGross : comps.averageNet}
              better={
                (gross && stats.grossScore < comps.averageGross) ||
                (!gross && stats.netScore < comps.averageNet)
              }
            />
            <StatsItem
              grey
              title={"Par 3 Averages"}
              stat={
                gross ? grossStats.averageParThrees : stats.averageParThrees
              }
              comp={
                gross ? grossComps.averageParThrees : comps.averageParThrees
              }
              better={
                (gross &&
                  stats.averageParThrees < grossComps.averageParThrees) ||
                (!gross && stats.averageParThrees < comps.averageParThrees)
              }
            />
            <StatsItem
              grey
              title={"Par 4 Averages"}
              stat={gross ? grossStats.averageParFours : stats.averageParFours}
              comp={gross ? grossComps.averageParFours : comps.averageParFours}
              better={
                (gross && stats.averageParFours < grossComps.averageParFours) ||
                (!gross && stats.averageParFours < comps.averageParFours)
              }
            />
            <StatsItem
              title={"Par 5 Averages"}
              stat={gross ? grossStats.averageParFives : stats.averageParFives}
              comp={gross ? grossComps.averageParFives : comps.averageParFives}
              better={
                (gross && stats.averageParFives < grossComps.averageParFives) ||
                (!gross && stats.averageParFives < comps.averageParFives)
              }
            />
            <StatsItem
              title={"Eagles"}
              stat={gross ? grossStats.totalEagles : stats.totalEagles}
              comp={
                gross
                  ? Math.round(
                      (grossComps.totalEagles / comps.totalRounds) * 100
                    ) / 100
                  : Math.round((comps.totalEagles / comps.totalRounds) * 100) /
                    100
              }
              better={
                gross
                  ? grossComps.totalEagles / comps.totalRounds <
                    grossStats.totalEagles
                  : comps.totalEagles / comps.totalRounds < stats.totalEagles
              }
            />
            <StatsItem
              title={"Birdies"}
              grey
              stat={gross ? grossStats.totalBirdies : stats.totalBirdies}
              comp={gross ? grossComps.averageBirdies : comps.averageBirdies}
              better={
                gross
                  ? grossComps.averageBirdies < grossStats.totalBirdies
                  : comps.averageBirdies < stats.totalBirdies
              }
            />
            <StatsItem
              title={"Pars"}
              grey
              stat={gross ? grossStats.totalPars : stats.totalPars}
              comp={gross ? grossComps.averagePars : comps.averagePars}
              better={
                gross
                  ? grossComps.averagePars < grossStats.totalPars
                  : comps.averagePars < stats.totalPars
              }
            />
            <StatsItem
              title={"Bogies"}
              stat={gross ? grossStats.totalBogies : stats.totalBogies}
              comp={gross ? grossComps.averageBogies : comps.totalBogies}
              better={
                gross
                  ? grossComps.totalBogies / comps.totalRounds <
                    grossStats.totalBogies
                  : comps.totalBogies / comps.totalRounds < stats.totalBogies
              }
            />
            <StatsItem
              title={"Doubles"}
              stat={gross ? grossStats.totalDoubles : stats.totalDoubles}
              comp={
                gross ? grossComps.averageDoubles : grossComps.averageDoubles
              }
              better={
                gross
                  ? grossComps.averageDoubles > grossStats.totalDoubles
                  : comps.averageDoubles > stats.totalDoubles
              }
            />
            <StatsItem
              grey
              title={"Other"}
              stat={gross ? grossStats.totalOthers : stats.totalOthers}
              comp={gross ? grossComps.averageOther : comps.averageOther}
              better={
                gross
                  ? grossComps.averageOther > grossStats.totalOthers
                  : comps.averageOther > stats.totalOthers
              }
            />
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Approach</h2>
          </div>
          <div className="rs-box flex flex-wrap">
            <StatsItem
              title="Greens in Regulation"
              stat={gross ? stats.totalGIR : stats.totalNetGir}
              comp={gross ? comps.averageGIR : comps.averageNetGIR}
              better={
                gross
                  ? comps.averageGIR < stats.totalGIR
                  : comps.averageNetGIR < stats.totalNetGir
              }
            />
            <StatsItem
              title="Greens in Regulation (Par 3s)"
              grey
              stat={gross ? stats.parThreeGrossGIRs : stats.parThreeNetGIRs}
              comp={
                gross
                  ? Math.round(
                      (comps.parThreeGrossGIRs / comps.totalParThrees) *
                        stats.totalParThreesPlayed *
                        100
                    ) / 100
                  : Math.round(
                      (comps.parThreeNetGIRs / comps.totalParThrees) *
                        stats.totalParThreesPlayed *
                        100
                    ) / 100
              }
              better={
                gross
                  ? (comps.parThreeGrossGIRs / comps.totalParThrees) *
                      stats.totalParThreesPlayed <
                    stats.parThreeGrossGIRs
                  : (comps.parThreeNetGIRs / comps.totalParThrees) *
                      stats.totalParThreesPlayed <
                    stats.parThreeNetGIRs
              }
            />
            <StatsItem
              title="Greens in Regulation (Par 4s)"
              grey
              stat={gross ? stats.parFourGrossGIRs : stats.parFourNetGIRs}
              comp={
                gross
                  ? Math.round(
                      (comps.parFourGrossGIRs / comps.totalParFours) *
                        stats.totalParFoursPlayed *
                        100
                    ) / 100
                  : Math.round(
                      (comps.parFourNetGIRs / comps.totalParFours) *
                        stats.totalParFoursPlayed *
                        100
                    ) / 100
              }
              better={
                gross
                  ? (comps.parFourGrossGIRs / comps.totalParFours) *
                      stats.totalParFoursPlayed <
                    stats.parFourGrossGIRs
                  : (comps.parFourNetGIRs / comps.totalParFours) *
                      stats.totalParFoursPlayed <
                    stats.parFourNetGIRs
              }
            />
            <StatsItem
              title="Greens in Regulation (Par 5s)"
              stat={gross ? stats.parFiveGrossGIRs : stats.parFiveNetGIRs}
              comp={
                gross
                  ? Math.round(
                      (comps.parFiveGrossGIRs / comps.totalParFives) *
                        stats.totalParFivesPlayed *
                        100
                    ) / 100
                  : Math.round(
                      (comps.parFiveNetGIRs / comps.totalParFives) *
                        stats.totalParFivesPlayed *
                        100
                    ) / 100
              }
              better={
                gross
                  ? (comps.parFiveGrossGIRs / comps.totalParFives) *
                      stats.totalParThreesPlayed <
                    stats.parFiveGrossGIRs
                  : (comps.parFiveNetGIRs / comps.totalParFives) *
                      stats.totalParFivesPlayed <
                    stats.parFiveNetGIRs
              }
            />
            <StatsItem
              title="Par 3 Average (GIR)"
              stat={
                gross
                  ? stats.parThreeGrossGIRScoringAverage
                  : stats.parThreeNetGIRScoringAverage
              }
              comp={
                gross
                  ? comps.parThreeGrossGIRScoringAverage
                  : comps.parThreeNetGIRScoringAverage
              }
              better={
                gross
                  ? stats.parThreeGrossGIRScoringAverage <
                    comps.parThreeGrossGIRScoringAverage
                  : stats.parThreeNetGIRScoringAverage <
                    comps.parThreeNetGIRScoringAverage
              }
            />
            <StatsItem
              grey
              title="Par 4 Average (GIR)"
              stat={
                gross
                  ? stats.parFourGrossGIRScoringAverage
                  : stats.parFourNetGIRScoringAverage
              }
              comp={
                gross
                  ? comps.parFourGrossGIRScoringAverage
                  : comps.parFourNetGIRScoringAverage
              }
              better={
                gross
                  ? stats.parFourGrossGIRScoringAverage <
                    comps.parFourGrossGIRScoringAverage
                  : stats.parFourNetGIRScoringAverage <
                    comps.parFourNetGIRScoringAverage
              }
            />
            <StatsItem
              grey
              title="Par 5 Average (GIR)"
              stat={
                gross
                  ? stats.parFiveGrossGIRScoringAverage
                  : stats.parFiveNetGIRScoringAverage
              }
              comp={
                gross
                  ? comps.parFiveGrossGIRScoringAverage
                  : comps.parFiveNetGIRScoringAverage
              }
              better={
                gross
                  ? stats.parFiveGrossGIRScoringAverage <
                    comps.parFiveGrossGIRScoringAverage
                  : stats.parFiveNetGIRScoringAverage <
                    comps.parFiveNetGIRScoringAverage
              }
            />
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Putting</h2>
          </div>
          <div className="rs-box flex flex-wrap">
            <StatsItem
              title="Total Putts"
              stat={stats.totalPutts}
              better={stats.totalPutts < comps.averagePutts}
              comp={comps.averagePutts}
            />
            <StatsItem
              grey
              title="Putts per Hole"
              stat={Math.round((stats.totalPutts / 18) * 100) / 100}
              better={stats.totalPutts < comps.averagePutts}
              comp={Math.round((comps.averagePutts / 18) * 100) / 100}
            />
            <StatsItem
              grey
              title="1 Putts"
              stat={stats.onePutts}
              comp={
                Math.round((comps.onePutts / comps.totalRounds) * 100) / 100
              }
              better={comps.onePutts / comps.totalRounds < stats.onePutts}
            />
            <StatsItem
              title="3 Putts"
              stat={stats.threePutts}
              comp={
                Math.round((comps.threePutts / comps.totalRounds) * 100) / 100
              }
              better={comps.threePutts / comps.totalRounds > stats.threePutts}
            />
            <StatsItem
              title="Putts / GIR"
              stat={
                !gross
                  ? Math.round((stats.netGIRPutts / stats.totalNetGir) * 100) /
                    100
                  : Math.round((stats.grossGIRPutts / stats.totalGIR) * 100) /
                    100
              }
              comp={
                !gross
                  ? Math.round((comps.netGIRPutts / comps.totalNetGir) * 100) /
                    100
                  : Math.round((comps.grossGIRPutts / comps.totalGIR) * 100) /
                    100
              }
              better={
                !gross
                  ? Math.round((stats.netGIRPutts / stats.totalNetGir) * 10) /
                      10 <=
                    Math.round((comps.netGIRPutts / comps.totalNetGir) * 10) /
                      10
                  : Math.round((stats.grossGIRPutts / stats.totalGIR) * 10) /
                      10 <=
                    Math.round((comps.grossGIRPutts / comps.totalGIR) * 10) / 10
              }
            />
            <StatsItem
              title={"Birdie Conversion"}
              grey
              better={
                !gross
                  ? stats.totalBirdies / stats.totalNetGir >=
                    comps.totalBirdies / comps.totalNetGir
                  : grossStats.totalBirdies / stats.totalGIR >=
                    grossComps.totalBirdies / comps.totalGIR
              }
              stat={
                !gross
                  ? `${Math.round(
                      (stats.totalBirdies / stats.totalNetGir) * 100
                    )}%`
                  : `${Math.round(
                      (grossStats.totalBirdies / stats.totalGIR) * 100
                    )}%`
              }
              comp={
                !gross
                  ? `${Math.round(
                      (comps.totalBirdies / comps.totalNetGir) * 100
                    )}%`
                  : `${Math.round(
                      (grossComps.totalBirdies / comps.totalGIR) * 100
                    )}%`
              }
            />
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Off the Tee</h2>
          </div>
          <div className="rs-box flex flex-wrap">
            <StatsItem
              title="Fairways in Regulation"
              stat={stats.totalFIR}
              comp={comps.averageFIR}
              better={comps.averageFIR < stats.totalFIR}
            />
            <StatsItem
              grey
              title="Par 4 Average (FIR)"
              stat={
                gross
                  ? stats.parFourGrossFIRScoringAverage
                  : stats.parFourNetFIRScoringAverage
              }
              comp={
                gross
                  ? comps.parFourGrossFIRScoringAverage
                  : comps.parFourNetFIRScoringAverage
              }
              better={
                gross
                  ? stats.parFourGrossFIRScoringAverage <
                    comps.parFourGrossFIRScoringAverage
                  : stats.parFourNetFIRScoringAverage <
                    comps.parFourNetFIRScoringAverage
              }
            />
            <StatsItem
              title="Par 5 Average (FIR)"
              stat={
                gross
                  ? stats.parFiveGrossFIRScoringAverage
                  : stats.parFiveNetFIRScoringAverage
              }
              comp={
                gross
                  ? comps.parFiveGrossFIRScoringAverage
                  : comps.parFiveNetFIRScoringAverage
              }
              better={
                gross
                  ? stats.parFiveGrossFIRScoringAverage <
                    comps.parFiveGrossFIRScoringAverage
                  : stats.parFiveNetFIRScoringAverage <
                    comps.parFiveNetFIRScoringAverage
              }
            />
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Around the Green</h2>
          </div>

          <div className="rs-box flex flex-wrap">
            <StatsItem
              title={"Scrambling"}
              better={
                !gross
                  ? stats.totalNetScrambles / stats.totalNetScrambleAttempts >=
                    comps.totalNetScrambles / comps.totalNetScrambleAttempts
                  : stats.totalGrossScrambles /
                      stats.totalGrossScrambleAttempts >=
                    comps.totalGrossScrambles / comps.totalGrossScrambleAttempts
              }
              stat={
                !gross
                  ? `${Math.round(
                      (stats.totalNetScrambles /
                        stats.totalNetScrambleAttempts) *
                        100
                    )}%`
                  : `${Math.round(
                      (stats.totalGrossScrambles /
                        stats.totalGrossScrambleAttempts) *
                        100
                    )}%`
              }
              comp={
                !gross
                  ? `${Math.round(
                      (comps.totalNetScrambles /
                        comps.totalNetScrambleAttempts) *
                        100
                    )}%`
                  : `${Math.round(
                      (comps.totalGrossScrambles /
                        comps.totalGrossScrambleAttempts) *
                        100
                    )}%`
              }
            />

            <StatsItem
              grey
              title={"Sand Saves"}
              stat={
                stats.sandyAttempts > 0
                  ? `${Math.round(
                      (stats.sandies / stats.sandyAttempts) * 100
                    )}%`
                  : "0%"
              }
              comp={
                comps.sandyAttempts > 0
                  ? `${Math.round(comps.sandSave * 100)}%`
                  : "0"
              }
              better={
                stats.sandyAttempts > 0
                  ? stats.sandies / stats.sandyAttempts >
                    comps.sandSave / comps.sandyAttempts
                  : true
              }
            />
          </div>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Other</h2>
          </div>
          <div className="rs-box flex flex-wrap">
            <StatsItem
              title="Bounce Back Birdies"
              stat={
                !gross
                  ? stats.netBounceBackBirdies ?? 0
                  : stats.grossBounceBackBirdies ?? 0
              }
              comp={
                !gross
                  ? Math.round(
                      comps.netBounceBackBirdies / comps.totalRounds
                    ) ?? 0
                  : Math.round(
                      comps.grossBounceBackBirdies / comps.totalRounds
                    ) ?? 0
              }
              higher={
                !gross
                  ? stats.netBounceBackBirdies <=
                    comps.netBounceBackBirdies / comps.totalRounds
                  : stats.grossBounceBackBirdies <=
                    comps.grossBounceBackBirdies / comps.totalRounds
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

export default RoundStats;
