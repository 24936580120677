import React from "react";
import StarIcon from "@mui/icons-material/Star";
import "./CourseSquare.css";

const GradientIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <StarIcon className="cs-star" sx={{ fill: "url(#linearColors)" }} />
  </>
);

function CourseSquare({ name, rating, distance, image, handleClick }) {
  return (
    <div
      onClick={handleClick}
      style={{
        backgroundImage:
          image !== "" && image !== null && image !== undefined
            ? `url(${image})`
            : "url(https://firebasestorage.googleapis.com/v0/b/uga-tour.appspot.com/o/course-stock%2Fping-lee-Aq2ZfIRzSj8-unsplash.jpg?alt=media&token=14d87a13-4e73-4ea4-bfb0-5addf6437a81)",
      }}
      className="course-square"
    >
      <div className="course-square-content">
        <div className="cs-header">
          <h1>{name}</h1>
        </div>

        <div className="cs-deets">
          <div className="svg-cont">
            <GradientIcon />
          </div>
          {/* <StarIcon className="cs-star" /> */}
          <p>
            {rating} · {distance}km
          </p>
        </div>
      </div>
    </div>
  );
}

export default CourseSquare;
