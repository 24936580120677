import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import "./PlayerTeeSelector.css";
import { useAuth } from "../../../contexts/AuthContext";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import PTSPlayer from "./PTSPlayer";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import PageHeader from "../../display/Rows/PageHeader";
import Switch from "react-ios-switch/lib/Switch";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";
import { Info } from "@mui/icons-material";
import InfoModal from "../../ErrorModal/InfoModal";

function PlayerTeeSelector({
  eventID = "",
  eventName = "",
  goBack = () => {},
  players = [],
  roundIndex = 0,
  round = {},
  rounds = [],
  cup = false,
  matchplay = false,
  teamEvt = false,
  defaultMap = {},
  courseIDs = [],
  reload = () => {},
}) {
  // State
  const [selections, setSelections] = useState([]);
  const [teeArray, setTeeArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lengths, setLengths] = useState({});
  const [courseName, setCourseName] = useState("");
  const [update, setUpdate] = useState(0);
  const [cfa, setCFA] = useState(false);

  const [defaultTees, setDefaultTees] = useState({});

  const [droptions, setDroptions] = useState([]);
  const [rdImportOpts, setRDImportOpts] = useState([]);
  const [rdMap, setRDMap] = useState({});
  const [yards, setYards] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [modal, setModal] = useState();
  const [showModal, setShowModal] = useState(false);

  const [divisions, setDivisions] = useState([]);
  const [divMap, setDivMap] = useState({});
  const [selDiv, setSelDiv] = useState("");

  const [all, setAll] = useState("");
  const { userSettings } = useAuth();

  // UE
  useEffect(() => {
    // console.log(courseIDs);
    // console.log(players);
    loadAll();

    if (rounds.length > 1) {
      const mep = {};
      const arr = [];
      for (let i = 0; i < rounds.length; i++) {
        if (i !== roundIndex) {
          arr.push({
            value: i.toString(),
            label: `Round ${i + 1}`,
          });
          mep[i.toString()] = rounds[i].teeMap ?? {};
        }
      }

      setRDImportOpts(arr);
      setRDMap(mep);
    }
  }, []);

  useEffect(() => {
    if (userSettings) {
      setYards(userSettings.units === "yards");
    }
  }, [userSettings]);

  async function loadAll() {
    let { teeArray, length } = round;
    const teeMap = round.teeMap ?? {};
    const groups = round.groups ?? [];
    let courseID = round.courseID;
    const evtDoc = await db
      .collection(cup ? "cups" : teamEvt ? "teamEvents" : "events")
      .doc(eventID)
      .get();
    const evt = evtDoc.data();
    if (!courseID) {
      courseID = evt.courseID;
    }

    // if (evt.enableDivisions) {
    let divisions = evt.divisions ?? [];

    divisions = divisions.filter((div) => div.playerType !== "teams");

    const mep = {};
    for (let i = 0; i < divisions.length; i++) {
      const div = divisions[i];
      const { divisionName, leaderboardID } = div;

      console.log(div.playerType === "teams");

      divisions[i].label = divisionName;
      divisions[i].value = leaderboardID;

      mep[leaderboardID] = div;
    }

    divisions.unshift({
      label: "Men",
      value: "male",
    });
    divisions.unshift({
      label: "Women",
      value: "female",
    });

    divisions.unshift({
      label: "All",
      value: "all",
    });
    setDivMap(mep);
    setDivisions(divisions);
    setSelDiv("all");
    console.log(divisions);
    // }

    // console.log(teeMap);

    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    if (teeArray === undefined) {
      teeArray = course.teeArray;
      length = course.length;
    }

    // const round = evt.rounds[roundIndex];
    // console.log(round);

    const defMap = round.defaultTees ?? {};

    if (courseIDs.length > 0) {
      teeArray = [];
      for (let i = 0; i < courseIDs.length; i++) {
        const crs = courseIDs[i];
        // console.log(crs);
        if (!defMap[crs.courseID]) {
          defMap[crs.courseID] = {
            name: crs.name,
            tee: "",
            courseID: crs.courseID,
            teeArray: crs.teeArray,
          };
        }
        for (let j = 0; j < crs.teeArray.length; j++) {
          const tee = crs.teeArray[j];
          // console.log(teeArray.includes(tee));
          if (!teeArray.includes(tee)) {
            teeArray.push(tee);
          }
        }
      }
    } else {
      if (!defMap[courseID]) {
        defMap[courseID] = {
          name: course.name,
          tee: "",
          courseID,
          teeArray,
        };
      }
    }

    // console.log(defMap);
    setDefaultTees(defMap);
    setCourseName(course.name);

    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const { playerID } = player;
      let tee = "";
      if (teeMap[playerID]) {
        tee = teeMap[playerID];
        players[i].tee = tee;
        continue;
      }

      // for (let j = 0; j < groups.length; j++) {
      //   const group = groups[j];
      //   if (group.players) {
      //     for (let x = 0; x < group.players.length; x++) {
      //       if (group.players[x].tee) {
      //         tee = group.players[x].tee;
      //       }
      //     }
      //   }
      // }

      if (tee === "") {
        tee = teeArray[0];
      }

      players[i].tee = tee;
    }

    // console.log(players);
    setSelections(players);
    setLengths(length);
    setTeeArray(teeArray);

    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    const teeMap = round.teeMap ?? {};
    for (let i = 0; i < selections.length; i++) {
      const player = selections[i];
      const { playerID, tee } = player;
      teeMap[playerID] = tee;
    }
    for (const [key, value] of Object.entries(defaultTees)) {
      if (value.tee === "None") {
        defaultTees[key].tee = "";
      }
      const filtTees = value.teeArray.filter((item) => item !== "None");
      console.log(filtTees, key);
      // defaultTees[key].teeArray = filtTees;
    }
    console.log(defaultTees);
    // return setLoading(false);

    round.teeMap = teeMap;
    round.defaultTees = defaultTees;
    const newRounds = rounds;
    newRounds[roundIndex] = round;

    if (cfa) {
      for (let i = 0; i < rounds.length; i++) {
        rounds[i].teeMap = teeMap;
      }
    }

    // return console.log(newRounds);
    if (cup) {
      await db.collection("cups").doc(eventID).update({
        rounds: newRounds,
        teeMap,
      });
    } else {
      await db
        .collection(teamEvt ? "teamEvents" : "events")
        .doc(eventID)
        .update({
          rounds: newRounds,
          teeMap,
        });
    }
    reload();
    toast.success("Changes successfully saved", {
      icon: successIco,
    });
    setLoading(false);
    goBack();
  }

  function teeChange() {
    // return console.log(selDiv, divMap);
    setSelections((current) => {
      for (let i = 0; i < current.length; i++) {
        let chg = false;

        if (selDiv === "all") {
          chg = true;
        } else if (selDiv === "male") {
          if (current[i].gender === "male") {
            chg = true;
          }
        } else if (selDiv === "female") {
          if (current[i].gender === "female") {
            chg = true;
          }
        } else {
          const div = divMap[selDiv];
          if (div.divType === "custom") {
            const playerIDs = div.players.map((player) => player.playerID);
            if (playerIDs.includes(current[i].playerID)) {
              chg = true;
            }
          }

          if (div.divType === "handicap") {
            const { min, max } = div;
            const hcp = current[i].handicap;
            if (hcp < max && hcp > min) {
              chg = true;
            }
          }

          if (div.divType === "gender") {
            if (current[i].gender === div.gender) {
              chg = true;
            }
          }
        }

        if (chg) {
          current[i].tee = all.value;
        }
      }
      return current;
    });
    setUpdate((current) => current + 1);
  }

  return (
    <div className="player-tee-selector">
      {loading && <BackdropLoader />}
      {showModal && modal}
      <div onClick={goBack} className="back-row">
        <ArrowBack className="cursor-icon" />
        <p>Back</p>
      </div>
      <div className="ph-top mt-20">
        <p>
          {courseName !== "" && (
            <>
              {eventName} at {courseName}
            </>
          )}
        </p>
      </div>

      <PageHeader text={`Tee Selector - Round ${roundIndex + 1}`} />
      <div className="ph-msg">
        <p>Select tees for each individual player</p>
      </div>

      <div className="ec-box">
        {Object.values(defaultTees).map((crs, i) => {
          // console.log(crs);

          const opts = crs.teeArray ?? [];
          if (!opts.includes("None")) {
            opts.unshift("None");
          }

          return (
            <div key={crs.courseID} className="input-group tee-ip">
              <div className="ig-header">
                {courseIDs.length < 2 ? (
                  <p>Default Tees:</p>
                ) : (
                  <p>Default: {crs.name}</p>
                )}
                <Info
                  className="dg-icon-bg icon-cursor mb-20 ml-20"
                  onClick={() => {
                    setModal(
                      <InfoModal
                        hide={() => setShowModal(false)}
                        text="Default Tees are used when no tees have been allocated to a player - they do not override any settings made for individual players."
                      />
                    );
                    setShowModal(true);
                  }}
                />
              </div>

              <Dropdown
                value={crs.tee}
                placeholder={"Select Tee..."}
                options={opts}
                onChange={(e) => {
                  console.log(e);
                  setDefaultTees((c) => {
                    c[crs.courseID].tee = e.value;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              />
            </div>
          );
        })}
      </div>

      <div className="ec-box">
        {divisions.length > 0 && (
          <div className="input-group">
            <p>Select Option</p>
            <Dropdown
              value={selDiv}
              placeholder={"Select division..."}
              options={divisions}
              onChange={(e) => {
                setSelDiv(e.value);
              }}
            />
          </div>
        )}
        <div className="input-group">
          <p>Change All</p>
          <div className="pts-all">
            <Dropdown
              value={all}
              placeholder={"Select tees..."}
              options={teeArray}
              onChange={(e) => {
                setAll(e);
              }}
            />
            <button
              onClick={() => {
                if (divisions.length > 0) {
                  teeChange();
                } else {
                  setSelections((current) => {
                    for (let i = 0; i < current.length; i++) {
                      current[i].tee = all.value;
                    }
                    return current;
                  });

                  setUpdate((current) => current + 1);
                }
              }}
              className="default-button black-button"
            >
              Go
            </button>
          </div>
        </div>
        {rdImportOpts.length > 0 && (
          <div className="input-group groups-hint ml-20">
            <p className="">Import from Round</p>
            <Dropdown
              // arrowClosed={
              //   <KeyboardArrowDownIcon className="drop-icon" />
              // }
              // arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              onChange={(e) => {
                console.log(e, rdMap[e.value]);
                const teeMap = rdMap[e.value];

                setSelections((c) => {
                  for (let i = 0; i < c.length; i++) {
                    const player = c[i];
                    const { playerID } = player;
                    if (teeMap[playerID]) {
                      c[i].tee = teeMap[playerID];
                    }
                  }
                  return c;
                });
                setUpdate((c) => c + 1);
              }}
              options={rdImportOpts}
            />
          </div>
        )}
      </div>
      {/* <div className="ec-box"> */}
      {rounds.length > 1 && (
        <div className="input-group pl-20">
          <p>Change for all Rounds</p>
          <Switch
            onColor="#1e7a69"
            checked={cfa}
            className={cfa ? "scoring-switch switch-on" : "scoring-switch"}
            onChange={() => {
              setCFA((current) => {
                // handleSR(!current);
                return !current;
              });
            }}
          />
        </div>
      )}
      {/* </div> */}

      <div className="input-group pl-20">
        <p>Search for player</p>
        <input
          type="text"
          placeholder="Enter player name.."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="default-input"
        />
      </div>
      <div className="default-button-row">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
      <div className="pts-players">
        {selections.map((player, index) => {
          if (searchInput !== "") {
            const sl = searchInput.toLowerCase();
            const nl = player.name.toLowerCase();
            if (!nl.includes(sl)) {
              return null;
            }
          }
          // console.log(player);
          return (
            // <>
            <PTSPlayer
              player={player}
              tees={teeArray}
              key={player.playerID ?? player.guestID}
              lengths={lengths}
              yards={yards}
              selected={player.tee}
              setSelected={(e) => {
                setSelections((current) => {
                  current[index].tee = e;
                  return current;
                });
                setUpdate((current) => current + 1);
              }}
            />
            // </>
          );
        })}
      </div>
    </div>
  );
}

export default PlayerTeeSelector;
