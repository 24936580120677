import React, { useEffect, useState } from "react";
import "./StablePoints.css";
import { db } from "../../../firebase";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAuth } from "../../../contexts/AuthContext";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import ScoreLoader from "../../Loaders/ScoreLoader";
function StablePoints({ roundID, leaderboard = [] }) {
  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);

  const holes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

  const [pointsArr, setPointsArr] = useState([]);
  const [totals, setTotals] = useState({ front: 0, back: 0, total: 0 });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sortUsers();
  }, []);

  useEffect(() => {
    if (currentUser) {
      loadPoints(currentUser.uid);
    }
  }, [currentUser]);

  async function sortUsers() {
    if (leaderboard.length > 0) {
      const arr = leaderboard.map((row) => {
        const user = { label: row.name, value: row.playerID };
        return user;
      });
      // console.log(arr);
      setUsers(arr);
    } else {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const { leaderboard } = round;
      const arr = leaderboard.map((row) => {
        const user = { label: row.name, value: row.playerID };
        return user;
      });
      setUsers(arr);
    }
  }

  async function loadPoints(playerID) {
    setLoading(true);
    const scoreDoc = await db
      .collection("rounds")
      .doc(roundID)
      .collection("scores")
      .doc(playerID)
      .get();
    const doc = scoreDoc.data();
    const { holes } = doc;
    const pointsArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let front = 0;
    let back = 0;
    let total = 0;
    for (let i = 0; i < holes.length; i++) {
      pointsArr[i] = holes[i].modPoints;
      if (i < 9) {
        front += holes[i].modPoints;
      } else {
        back += holes[i].modPoints;
      }

      total += holes[i].modPoints;
    }
    setPointsArr(pointsArr);
    setTotals({
      front,
      back,
      total,
    });
    setLoading(false);
  }

  return (
    <div className="stable-points">
      <div className="neh-box mt-20 bb" style={{ paddingLeft: "10px" }}>
        <h2 className="ne-header">Stableford</h2>
      </div>
      <div className="input-group">
        <p>Select player...</p>
        <Dropdown
          arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
          arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
          options={users}
          onChange={(e) => loadPoints(e.value)}
        />
      </div>
      {loading ? (
        <ScoreLoader />
      ) : (
        <div className="sp-holes mt-20">
          <div className="score-table-container">
            <table cellPadding={"0"} cellSpacing={"0"}>
              <thead>
                <tr className="header-row main-header">
                  <th>
                    <GolfCourseIcon />
                  </th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {pointsArr.map((hole, index) => {
                  return (
                    <>
                      {index === 9 && (
                        <tr key={`${index}out`} className="header-row out-row">
                          <td className="hole-td">OUT</td>
                          <td className="player-td bold-td">{totals.front}</td>
                        </tr>
                      )}

                      <tr key={`${index}hole`}>
                        <td className="hole-td">{index + 1}</td>
                        <td className="player-td">{hole}</td>
                      </tr>
                      {index === 17 && (
                        <tr key={`${index}in`} className="header-row in-row">
                          <td className="hole-td">IN</td>
                          <td className="player-td bold-td">{totals.back}</td>
                        </tr>
                      )}
                      {index === 17 && (
                        <tr key={`${index}oout`} className="header-row out-row">
                          <td className="hole-td">OUT</td>
                          <td className="player-td bold-td">{totals.front}</td>
                        </tr>
                      )}
                      {index === 17 && (
                        <tr
                          key={`${index}tot`}
                          className="header-row total-row"
                        >
                          <td className="hole-td">TOT</td>
                          <td className="player-td bold-td">{totals.total}</td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <div className="sph-head">
          <p>Hole</p>
          <p>Points</p>
        </div>
        {pointsArr.map((hole, index) => (
          <div key={index} className="sph-row">
            <p>{index + 1}</p>
            <p>{hole}</p>
          </div>
        ))} */}
        </div>
      )}
    </div>
  );
}

export default StablePoints;
