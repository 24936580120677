import React from "react";
import { Helmet } from "react-helmet";
import "./Pricing.css";

// Icos
import { FaPeopleGroup } from "react-icons/fa6";
import { FaBuffer } from "react-icons/fa6";
import { FaCalendarPlus } from "react-icons/fa6";
import { FaCartPlus } from "react-icons/fa6";

function Pricing() {
  return (
    <div className="">
      <Helmet>
        <title>The Commissioner | Pricing</title>
      </Helmet>
      <div className="dp-header">
        <h1>Pricing</h1>
      </div>
      <div className="pb-box">
        <div className="price-boxes">
          <div className="price-item">
            <div className="pi-top">
              <div className="pi-title">
                <h2>Pay To Play</h2>
                <div className="dis-bb"></div>
                <div className="dis-bb-2"></div>
              </div>
              <div className="pi-desc">
                <p>
                  Top up with credits every time you want to create an event or
                  a league. Select only the modules you want to use.
                </p>
              </div>
            </div>
            <div className="pi-div"></div>
            <div className="pi-st">
              <h5>Charges vary according to</h5>
            </div>
            <div className="pi-icos">
              <div className="pii-item">
                <FaPeopleGroup />
                <p>Amount of Members</p>
              </div>
              <div className="pii-item">
                <FaCalendarPlus />
                <p>Number of Events</p>
              </div>
              <div className="pii-item">
                <FaBuffer />
                <p>Enabled Modules</p>
              </div>
              <div className="pii-item">
                <FaCartPlus />
                <p>Selected Addons</p>
              </div>
            </div>
          </div>
          <div className="price-item">
            <div className="pi-top">
              <div className="pi-title">
                <h2>Pay as you go</h2>
                <div className="dis-bb"></div>
                <div className="dis-bb-2"></div>
              </div>
              <div className="pi-desc">
                <p>
                  Charges accrue over a monthly period, depending on the modules
                  enabled by the user and overall usage.
                </p>
              </div>
            </div>
            <div className="pi-div"></div>
          </div>
          <div className="price-item">
            <div className="pi-top">
              <div className="pi-title">
                <h2>Enterprise</h2>
                <div className="dis-bb"></div>
                <div className="dis-bb-2"></div>
              </div>
              <div className="pi-desc">
                <p>
                  Fully customized pricing according to your exact needs and
                  size. Contact us to get a quote for your organisation today.
                </p>
              </div>
              <div className="pi-div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
