import React, { useState, useEffect } from "react";
import "./EventDashboard.css";

// Icons
import CloseIcon from "@mui/icons-material/Close";

// Components
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { db, admin } from "../../../firebase";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import PageHeader from "../../display/Rows/PageHeader";

function EventRemovePlayer({
  eventID,
  hide = () => {},
  roundID,
  reload = () => {},
}) {
  // State
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirming, setConfirming] = useState(false);
  const [finished, setFinished] = useState(false);
  const [inputText, setInputText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [removalType, setRemovalType] = useState("removal");

  // UE
  useEffect(() => {
    loadPlayers();
  }, []);

  // Functions
  async function loadPlayers() {
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const leaderboard = round.leaderboard;
    setPlayers(leaderboard);
    setLoading(false);
  }

  async function removePlayer(playerID) {
    setErrorMessage("");
    setLoading(true);

    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();

    try {
      await db.collection("triggers").add({
        type: "evt-remove-player",
        eventID,
        playerID,
      });

      setPlayers((current) => {
        const filtered = current.filter(
          (player) => player.playerID !== playerID
        );
        return filtered;
      });
      setFinished(true);
      reload();

      return setLoading(false);

      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const { leaderboard, playerIDs, teeTimes } = round;
      const withdrawnIDs = round.withdrawnIDs ?? [];
      let guests = round.guests ?? [];
      const teamLeaderboard = round.teamLeaderboard ?? [];
      const divisions = round.divisions ?? [];
      const jackpot = round.jackpot ?? false;

      if (jackpot) {
        let jackpotLeaderboard = round.jackpotLeaderboard;
        jackpotLeaderboard = jackpotLeaderboard.filter(
          (row) => !row.playerID === playerID
        );
        roundDoc.ref.update({
          jackpotLeaderboard,
        });
      }

      for (let i = 0; i < divisions.length; i++) {
        const division = divisions[i];
        const { leaderboardID } = division;
        const divBoard = round[leaderboardID];
        const board = round[leaderboardID].leaderboard;
        const ids = divBoard.playerIDs;
        if (!ids.includes(playerID)) {
          continue;
        }
        const filtIDs = ids.filter((id) => id !== playerID);
        const filt = board.filter((pl) => pl.playerID !== playerID);
        divBoard.leaderboard = filt;
        divBoard.playerIDs = filtIDs;
        console.log(divBoard);
        roundDoc.ref.update({
          [`${leaderboardID}`]: divBoard,
        });
      }

      let index = "";

      for (let i = 0; i < leaderboard.length; i++) {
        const row = leaderboard[i];
        if (row.playerID === playerID) {
          index = i;
        }
      }

      if (index !== "") {
        leaderboard.splice(index, 1);
      }

      // Removals
      // const newBoard = leaderboard.filter((player) => {
      //   // console.log(player.playerID !== playerID);
      //   return player.playerID !== playerID;
      // });
      const newIDs = playerIDs.filter((uid) => uid !== playerID);

      for (let i = 0; i < teeTimes.length; i++) {
        const time = teeTimes[i];
        if (time.playerIDs === undefined) {
          const playerArr = time.players;

          if (typeof playerArr[0] === "object") {
            const idArr = playerArr.map((pl) => pl.playerID);
            if (idArr.includes(playerID)) {
              teeTimes[i].players = playerArr.filter(
                (pl) => pl.playerID !== playerID
              );
            }
          } else {
            if (time.players.includes(playerID)) {
              const playerArr = time.players;
              const newParr = playerArr.filter((uid) => uid !== playerID);
              teeTimes[i].players = newParr;
              // teeTimes[i].players.filter((uid) => uid !== playerID);
            }
          }
        } else {
          if (time.playerIDs.includes(playerID)) {
            const playerArr = time.playerIDs;
            if (typeof playerArr[0] === "object") {
              const newParr = playerArr.filter(
                (plyr) => plyr.playerID !== playerID
              );
              teeTimes[i].playerIDs = newParr;
            } else {
              const newParr = playerArr.filter((uid) => uid !== playerID);
              teeTimes[i].playerIDs = newParr;
            }
            // teeTimes[i].playerIDs.filter((uid) => uid !== playerID);
          }
        }
      }

      // let teamID = "";
      const changes = [];

      for (let i = 0; i < teamLeaderboard.length; i++) {
        const team = teamLeaderboard[i];

        if (team.playerIDs.includes(playerID)) {
          const parr = team.playerIDs;
          const newParr = parr.filter((uid) => uid !== playerID);
          teamLeaderboard[i].playerIDs = newParr;
          // for (let x = 0; x < teamLeaderboard[i].holes.length; x++) {
          //   delete teamLeaderboard[i].holes[x].playerID;
          // }
        }
      }

      // console.log(teams, changes);
      // return setLoading(false);

      if (playerID.includes("guest")) {
        guests = guests.filter((g) => g.playerID !== playerID);
      }

      withdrawnIDs.push(playerID);

      //   return console.log(teeTimes);

      await roundDoc.ref.update({
        leaderboard,
        playerIDs: newIDs,
        teeTimes,
        withdrawnIDs,
        guests,
        teamLeaderboard,
      });

      if (event.enableTeams) {
        const teams = event.teams ?? [];

        for (let i = 0; i < teams.length; i++) {
          const playerIDs = teams[i].players.map((pl) => pl.playerID);
          if (playerIDs.includes(playerID)) {
            changes.push(i);
            teams[i].players = teams[i].players.filter(
              (pl) => pl.playerID !== playerID
            );
          }
        }

        await eventDoc.ref.update({
          teams,
        });

        await db.collection("triggers").add({
          type: "team-recalc",
          eventID,
          dateAdded: new Date(),
          changes,
        });
      }

      // const

      if (!playerID.includes("guest")) {
        await roundDoc.ref.collection("scores").doc(playerID).delete();
        // await roundDoc.ref.collection("scores").doc(playerID).update({
        //   withdrawn: true,
        // });

        if (eventID && eventID !== "") {
          const eventPlayerIDs = event.playerIDs;
          const eventWDIDs = event.withdrawnIDs ?? [];
          let guests = event.guests ?? [];
          const newEventIDs = eventPlayerIDs.filter((uid) => uid !== playerID);
          eventWDIDs.push(playerID);

          if (playerID.includes("guest")) {
            guests = guests.filter((g) => g.playerID !== playerID);
          }

          await eventDoc.ref.update({
            playerIDs: newEventIDs,
            withdrawnIDs,
            guests,
          });
          await db
            .collection("users")
            .doc(playerID)
            .update({
              activeRoundID: "",
              activeEventID: "",
              roundIDs: admin.firestore.FieldValue.arrayRemove(roundID),
              eventIDs: admin.firestore.FieldValue.arrayRemove(eventID),
            });
        } else {
          await db
            .collection("users")
            .doc(playerID)
            .update({
              activeRoundID: "",
              roundIDs: admin.firestore.FieldValue.arrayRemove(roundID),
            });
        }
      }
      setPlayers((current) => {
        const filtered = current.filter(
          (player) => player.playerID !== playerID
        );
        return filtered;
      });
      setFinished(true);
      reload();
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Sorry, we encountered some technical difficulties there - please try again"
      );
    }
    setLoading(false);
  }

  function reset() {
    setLoading(true);
    setFinished(false);
    setConfirming(false);
    setSelected("");
    setSelectedName("");
    setInputText("");
    setLoading(false);
  }

  return (
    <Dialog onClose={hide} open={true} className="mpa-dia">
      <div className="event-remove-player">
        {loading ? (
          <>
            <div className="erp-loading flex-center">
              <Oval
                color="#1e7a69"
                secondaryColor="#ffffff"
                height={40}
                width={40}
              />
            </div>
          </>
        ) : (
          <>
            <PageHeader text="Remove Player" close={hide} showClose />
            {finished ? (
              <>
                <div className="erp-finished">
                  <div className="erpc-msg">
                    <p>
                      <strong>{selectedName}</strong> was successfully removed
                      from this event
                    </p>
                    {/* <div className="flex-center">
                      <button onClick={reset} className="default-button">
                        Keep Purging
                      </button>
                    </div> */}
                  </div>
                </div>
              </>
            ) : confirming ? (
              <>
                <div className="erp-confirm mt-20 mb-20">
                  <div className="erpc-msg">
                    <p>
                      Are you sure you want to remove{" "}
                      <strong>{selectedName}</strong> from this event?
                    </p>
                  </div>
                  <div className="im-msg mb-10">
                    <p>Type "REMOVE" below to confirm</p>
                  </div>
                  <input
                    type="text"
                    value={inputText}
                    placeholder="Start typing..."
                    onChange={(e) => setInputText(e.target.value)}
                    className="default-input mb-20"
                  />
                  <div className="flex-center">
                    <button
                      onClick={() => removePlayer(selected)}
                      disabled={inputText !== "REMOVE"}
                      className="delete-button mt-20"
                    >
                      CONFIRM
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="im-msg">
                  <p>Select a player to remove from this tournament</p>
                </div>
                <div className="erp-button-row flex-center mt-20 mb-20">
                  <button
                    disabled={selected === ""}
                    onClick={() => setConfirming(true)}
                    className="delete-button"
                  >
                    CONFIRM
                  </button>
                </div>

                <div className="input-group mb-20 si-search">
                  <p>Search</p>
                  <input
                    type="text"
                    placeholder="Search for player"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="default-input"
                  />
                </div>

                <div className="erp-players">
                  {players.map((player, index) => {
                    if (searchInput !== "") {
                      const sl = searchInput.toLowerCase();
                      if (player.name.toLowerCase().includes(sl)) {
                        return (
                          <PlayerItem
                            noLink
                            showProfile={() => {
                              setSelected((current) => {
                                if (current === player.playerID) {
                                  return "";
                                } else {
                                  return player.playerID;
                                }
                              });
                              setSelectedName((current) => {
                                if (current === player.name) {
                                  return "";
                                } else {
                                  return player.name;
                                }
                              });
                            }}
                            showBg={selected === player.playerID}
                            hcp={player.handicap}
                            img={player.profileImage}
                            name={player.name}
                            playerID={player.playerID}
                            hideIcons
                            key={player.playerID}
                          />
                        );
                      } else {
                        return null;
                      }
                    }

                    return (
                      <PlayerItem
                        key={player.playerID}
                        hcp={player.handicap}
                        img={player.profileImage}
                        name={player.name}
                        hideIcons
                        noLink
                        showBg={selected === player.playerID}
                        showProfile={() => {
                          setSelected((current) => {
                            if (current === player.playerID) {
                              return "";
                            } else {
                              return player.playerID;
                            }
                          });
                          setSelectedName((current) => {
                            if (current === player.name) {
                              return "";
                            } else {
                              return player.name;
                            }
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
}

export default EventRemovePlayer;
