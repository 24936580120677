import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";

import { Link, useNavigate, useParams } from "react-router-dom";
import img from "../../assets/images/log1.png";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import { Tabs, Tab, Snackbar, Dialog } from "@mui/material";
import { Avatar } from "@mui/material";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import NewContest from "./NewContest";
import ContestBoard from "./ContestBoard";
import ContestInput from "./ContestInput";
import AdminContestInput from "./AdminContestInput";
import ContestPlayerManager from "./ContestPlayerManager";
import RadioGroup from "../../components/Buttons/RadioGroup/RadioGroup";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import Pill from "../../components/Buttons/Pill";
import PageHeader from "../../components/display/Rows/PageHeader";

function ContestPage({ goBack = () => {}, reload = () => {} }) {
  const { currentUser } = useAuth();
  const { contestID } = useParams();

  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [adminOnly, setAdminOnly] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [showRemove, setShowRemove] = useState(false);

  const [manage, setManage] = useState(false);

  const [details, setDetails] = useState({
    name: "",
  });

  const [showSnack, setShowSnack] = useState(false);

  const [players, setPlayers] = useState([]);

  const [playerMap, setPlayerMap] = useState({});

  const [active, setActive] = useState("info");

  useEffect(() => {
    if (currentUser) {
      loadContest();
    }
  }, [currentUser]);

  async function loadContest() {
    setLoading(true);
    const contestDoc = await db.collection("contests").doc(contestID).get();
    const contest = contestDoc.data();

    const {
      playerIDs,
      adminIDs,
      leaderboard,
      name,
      attempts,
      completed,
      entrance,
      joinCode,
      percs,
      adminOnly,
      prizes,
      prizeType,
      playerEntries,
    } = contest;
    const dateObject = new Date(contest.dateCreated.toDate());
    const dateString = dateObject.toLocaleDateString("en-us", {
      dateStyle: "full",
    });
    if (adminIDs.includes(currentUser.uid)) {
      setAdmin(true);
    }

    setAdminOnly(adminOnly);

    let purse = 0;

    if (prizeType === "accum") {
      purse = playerIDs.length * entrance + playerEntries.length * entrance;
    } else {
      purse = prizes[0] + prizes[1] + prizes[2];
    }

    const refs = playerIDs.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(refs);

    const arr = [];
    const pm = {};

    for (let i = 0; i < docs.length; i++) {
      const playerID = playerIDs[i];
      const playerDoc = docs[i];
      const player = playerDoc.data();
      const playerName = `${player.firstName} ${player.lastName}`;
      const filt = playerEntries.filter((uid) => uid === player.playerID);
      const { handicap, profileImage, activeRoundID, status } = player;
      const city = player.city ?? "Cape Town";
      const country = player.country ?? "South Africa";
      const defmap = {
        attempts: [],
        best: "",
      };
      const row = leaderboard[playerID] ?? defmap;

      arr.push({
        name: playerName,
        handicap,
        profileImage,
        playerID,
        city,
        lb: row,
        country,
        lastName: player.lastName,
        entries: filt.length + 1,
      });
      pm[playerID] = {
        name: playerName,
        handicap,
        profileImage,
        playerID,
        city,
        entries: filt.length + 1,
        country,
        lastName: player.lastName,
      };
    }
    setPlayers(arr);
    setPlayerMap(pm);
    setDetails({
      name,
      dateObject,
      dateString,
      entrance,
      attempts,
      joinCode,
      purse,
      completed,
    });
    setLoading(false);
  }

  async function removePlayer(playerID) {
    setLoading(true);
    const contestDoc = await db.collection("contests").doc(contestID).get();
    const contest = contestDoc.data();
    const { playerIDs, leaderboard } = contest;

    const filtered = playerIDs.filter((uid) => uid !== playerID);
    delete leaderboard[playerID];

    await contestDoc.ref.update({
      playerIDs: filtered,
      leaderboard,
    });
    setLoading(false);
    reload();
    goBack();
  }

  return (
    <div className="contest-page">
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        message="Code copied to clipboard"
      />
      <Dialog
        className="cm-rem-dia"
        open={showRemove}
        onClose={() => setShowRemove(false)}
      >
        <div className="close-row">
          <CancelIcon
            onClick={() => setShowRemove(false)}
            className="dg-icon"
          />
        </div>
        <div className="flex-col-center conman-remove">
          <RemoveCircleIcon className="red-icon big-icon" />

          <p>Are you sure you want to remove yourself from this contest?</p>

          <div className="flex-center">
            <button
              onClick={() => removePlayer(currentUser.uid)}
              className="new-default-button"
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      {manage ? (
        <ContestPlayerManager
          playerMap={playerMap}
          reload={loadContest}
          contestID={contestID}
          goBack={() => setManage(false)}
          players={players}
        />
      ) : showEdit ? (
        <NewContest
          edit
          reload={loadContest}
          defaultContestID={contestID}
          goBack={() => setShowEdit(false)}
        />
      ) : (
        <>
          <div onClick={() => navigate(-1)} className="back-row">
            <ArrowBack />
            <p>Back</p>
          </div>

          <PageHeader text={`Contest Dashboard - ${details.name}`} />

          <div className="sub-header">
            <h5>Quick Links</h5>
          </div>

          <div className="quick-links">
            <Link target="_blank" to={`/contestboards/${contestID}`}>
              <Pill
                text={"Full Board"}
                onClick={() => {
                  // window.open("");
                }}
              />
            </Link>
            <Pill text={"Edit Settings"} onClick={() => setShowEdit(true)} />
            <Pill text={"Player Manager"} onClick={() => setManage(true)} />
            <Pill
              text={"Share Code"}
              onClick={() => {
                navigator.clipboard.writeText(`${details.joinCode}`);
                setShowSnack(true);
              }}
            />
          </div>

          <RadioGroupTwo
            active={active}
            setActive={setActive}
            buttons={[
              { text: "Info", value: "info" },
              { text: "Input", value: "input" },
            ]}
          />

          {active === "info" && (
            <>
              <div className="sub-header">
                <h5>Information</h5>
              </div>
              <div className="cd-info fd-info">
                <div className="contest-info-row mb-20">
                  <div className="cir-item">
                    <div className="rev-cir">
                      <h2>{details.entrance}</h2>
                    </div>
                    <div className="cir-div"></div>
                    <h3>Entrance</h3>
                  </div>
                  <div className="cir-item cir-center">
                    <div className="rev-cir">
                      <h2>
                        {details.attempts === "0"
                          ? "Unlimited"
                          : details.attempts}
                      </h2>
                    </div>
                    <div className="cir-div"></div>

                    <h3>Attempts</h3>
                  </div>
                  <div className="cir-item">
                    <div className="rev-cir">
                      <h2>{details.purse}</h2>
                    </div>
                    <div className="cir-div"></div>

                    <h3>Purse</h3>
                  </div>
                </div>
              </div>
              <div className="sub-header">
                <h5>Players</h5>
              </div>
              <div className="participants-bloc">
                {players.map((player) => (
                  <PlayerItem
                    key={player.playerID}
                    hcp={player.handicap}
                    playerID={player.playerID}
                    name={player.name}
                    showAdd={true}
                    hideEdit
                    hideRemove
                    noLink
                    img={player.profileImage}
                    showProfile={() => {}}
                  />
                ))}
              </div>
            </>
          )}
          {active === "details" && (
            <ContestBoard
              players={playerMap}
              hideMenu={setHideMenu}
              contestID={contestID}
            />
          )}
          {active === "score" && (
            <>
              <ContestInput contestID={contestID} />
            </>
          )}
          {active === "input" && (
            <>
              <AdminContestInput players={playerMap} contestID={contestID} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ContestPage;
