/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import "./MatchplayHoles.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BackRow from "../Rows/BackRow";

function MatchplayHoles({
  roundID,
  goBack = () => {},
  showBack = false,
  matchNum = 0,
  nassau = false,
  teamOneColor = "#21c17c",
  teamTwoColor = "#1e7a69",
}) {
  const [holles, setHoles] = useState([]);
  const [names, setNames] = useState({
    teamOne: "",
    teamTwo: "",
  });
  const [startOnTen, setStartOnTen] = useState(false);

  useEffect(() => {
    if (nassau) {
      loadNassauHoles();
    } else {
      loadHoles();
    }
  }, []);

  async function loadNassauHoles() {
    console.log(roundID);
    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (roundDoc) => {
        if (!roundDoc.exists) {
          return;
        }

        const round = roundDoc.data();
        const { leaderboard, playerIDs, format, matches } = round;
        let holeses;
        console.log(format);
        if (format === "Fourball" || format === "Matchplay") {
          holeses = round.matches[matchNum].holes;
        } else {
          holeses = round.holes;
        }
        const roundHoles = [];

        for (let j = 0; j < holeses.length; j++) {
          const hole = holeses[j];
          roundHoles.push({ ...hole, by: 0, leaders: "" });
        }

        if (format === "Fourball") {
          const match = round.matches[matchNum];
          setStartOnTen(match.startOnTen);
          let leaders = "";
          let by = 0;

          for (let j = 0; j < roundHoles.length; j++) {
            // const hole = roundHoles[i]
            let i = j;
            if (match.startOnTen) {
              if (j < 9) {
                i += 9;
              } else {
                i -= 9;
              }
            }
            const winner = roundHoles[i].winner;
            if (winner === "tied") {
              roundHoles[i].leaders = leaders;
              roundHoles[i].by = by;
            }
            if (winner === "teamOne") {
              // AS
              if (leaders === "") {
                leaders = "teamOne";
                by = 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = 1;
                continue;
              }

              // Up
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
                continue;
              }
            }
            if (winner === "teamTwo") {
              // console.log(i)
              // AS
              if (leaders === "") {
                leaders = "teamTwo";
                by = 1;
                roundHoles[i].leaders = leaders;
                roundHoles[i].by = by;
                continue;
              }

              // Up
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].leaders = "teamTwo";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
              }
            }
            // roundHoles[i] = hole;
            // console.log(roundHoles[i])
          }
          if (match.startOnTen) {
            let temp = roundHoles.splice(0, roundHoles.length / 2);
            let splitted = [...roundHoles, ...temp];
            setHoles(splitted);
          } else {
            setHoles(roundHoles);
          }
          // console.log(matches);
          console.log(matches[matchNum]);
          const t1P1 = matches[matchNum].teamOne[0].name;
          const t1P2 = matches[matchNum].teamOne[1].name;
          const t2P1 = matches[matchNum].teamTwo[0].name;
          const t2P2 = matches[matchNum].teamTwo[1].name;
          const teamOneName = `${t1P1} / ${t1P2}`;
          const teamTwoName = `${t2P1} / ${t2P2}`;
          setNames({
            teamOne: teamOneName,
            teamTwo: teamTwoName,
          });
        }

        if (format === "Matchplay") {
          const match = round.matches[matchNum];
          setStartOnTen(match.startOnTen);
          const playerOne = match.playerOne.playerID;
          const playerTwo = match.playerTwo.playerID;
          const playerOneDoc = await db
            .collection("users")
            .doc(playerOne)
            .get();
          const playerTwoDoc = await db
            .collection("users")
            .doc(playerTwo)
            .get();
          const p1Data = playerOneDoc.data();
          const p2Data = playerTwoDoc.data();

          const p1Name = p1Data.lastName;
          const p2Name = p2Data.lastName;

          setNames({
            teamOne: p1Name,
            teamTwo: p2Name,
          });

          let leaders = "";
          let by = 0;

          for (let j = 0; j < roundHoles.length; j++) {
            let i = j;
            if (match.startOnTen) {
              if (j < 9) {
                i += 9;
              } else {
                i -= 9;
              }
            }
            // console.log(i);
            const winner = roundHoles[i].winner;
            // console.log(i, leaders, winner, by);

            if (winner === "") {
              if (
                roundHoles[i][playerOne] !== 0 ||
                roundHoles[i][playerTwo] !== 0
              ) {
                roundHoles[i].by = by;
                roundHoles[i].leaders = leaders;
              } else {
              }
            }

            if (winner === playerOne) {
              // console.log(leaders, by);
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
                continue;
              }
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamTwo";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamOne";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
              }
            }

            if (winner === playerTwo) {
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamTwo";
              }
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                  leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamOne";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamTwo";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamTwo";
              }
            }
          }
          if (match.startOnTen) {
            let temp = roundHoles.splice(0, roundHoles.length / 2);
            let splitted = [...roundHoles, ...temp];
            setHoles(splitted);
          } else {
            setHoles(roundHoles);
          }
        }

        if (format === "match-play") {
          setStartOnTen(leaderboard.startOnTen);
          const playerOne = playerIDs[0];
          const playerTwo = playerIDs[1];

          const playerOneDoc = await db
            .collection("users")
            .doc(playerOne)
            .get();
          const playerTwoDoc = await db
            .collection("users")
            .doc(playerTwo)
            .get();
          const p1Data = playerOneDoc.data();
          const p2Data = playerTwoDoc.data();

          const p1Name = p1Data.lastName;
          const p2Name = p2Data.lastName;

          setNames({
            teamOne: p1Name,
            teamTwo: p2Name,
          });

          let leaders = "";
          let by = 0;

          for (let j = 0; j < roundHoles.length; j++) {
            let i = j;
            if (leaderboard.startOnTen) {
              if (j < 9) {
                i += 9;
              } else {
                i -= 9;
              }
            }
            const winner = roundHoles[i].winner;

            if (winner === "") {
              if (
                roundHoles[i][playerOne] !== 0 ||
                roundHoles[i][playerTwo] !== 0
              ) {
                roundHoles[i].by = by;
                roundHoles[i].leaders = leaders;
              } else {
              }
            }

            if (winner === playerOne) {
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
                continue;
              }
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamTwo";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamOne";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
              }
            }

            if (winner === playerTwo) {
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].by += 1;
                roundHoles[i].leaders = "teamTwo";
              }
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamOne";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamTwo";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamTwo";
              }
            }
          }
          if (leaderboard.startOnTen) {
            let temp = roundHoles.splice(0, roundHoles.length / 2);
            let splitted = [...roundHoles, ...temp];
            setHoles(splitted);
          } else {
            setHoles(roundHoles);
          }
        } else if (format !== "Fourball" && format !== "Matchplay") {
          setStartOnTen(leaderboard.startOnTen);

          let leaders = "";
          let by = 0;

          for (let j = 0; j < roundHoles.length; j++) {
            // const hole = roundHoles[i]
            let i = j;
            if (leaderboard.startOnTen) {
              if (j < 9) {
                i += 9;
              } else {
                i -= 9;
              }
            }
            const winner = roundHoles[i].winner;
            if (winner === "tied") {
              roundHoles[i].leaders = leaders;
              roundHoles[i].by = by;
            }
            if (winner === "teamOne") {
              // AS
              if (leaders === "") {
                leaders = "teamOne";
                by = 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = 1;
                continue;
              }

              // Up
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
                continue;
              }
            }
            if (winner === "teamTwo") {
              // AS
              if (leaders === "") {
                leaders = "teamTwo";
                by = 1;
                roundHoles[i].leaders = leaders;
                roundHoles[i].by = by;
                continue;
              }

              // Up
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].leaders = "teamTwo";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
              }
            }
            // roundHoles[i] = hole;
            // console.log(roundHoles[i])
          }
          const teamNames = round.teamNames;
          setNames({
            teamOne: teamNames.teamOne,
            teamTwo: teamNames.teamTwo,
          });
          if (leaderboard.startOnTen) {
            let temp = roundHoles.splice(0, roundHoles.length / 2);
            let splitted = [...roundHoles, ...temp];
            setHoles(splitted);
          } else {
            setHoles(roundHoles);
          }
        }
      });
  }

  async function loadHoles() {
    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (roundDoc) => {
        if (!roundDoc.exists) {
          return;
        }
        const round = roundDoc.data();
        const { playerIDs, teamNames, format, teams } = round;
        // console.log(format);
        let holeses;
        if (format === "Matchplay" || format === "Fourball") {
          holeses = round.matches[matchNum].holes;
        } else {
          holeses = round.holes;
        }

        const roundHoles = [];

        for (let j = 0; j < holeses.length; j++) {
          const hole = holeses[j];
          roundHoles.push({ ...hole, by: 0, leaders: "" });
        }

        if (format === "Matchplay") {
          const match = round.matches[matchNum];
          const playerOne = match.playerOne;
          const playerTwo = match.playerTwo;
          const p1Name = playerOne.guest ? playerOne.name : playerOne.lastName;
          const p2Name = playerTwo.guest ? playerTwo.name : playerTwo.lastName;
          setNames({
            teamOne: p1Name,
            teamTwo: p2Name,
          });
          let leaders = "";
          let by = 0;

          for (let i = 0; i < roundHoles.length; i++) {
            const winner = roundHoles[i].winner;
            if (i >= match.thru) {
              break;
            }
            if (i === 0) {
              // console.log(roundHoles[i]);
            }

            if (
              (roundHoles[i][playerOne.playerID] === 0 ||
                roundHoles[i][playerOne.playerID] === 0) &&
              winner === ""
            ) {
              // continue;
            }

            if (winner === "" || winner === "tied") {
              if (
                roundHoles[i][playerOne] !== 0 ||
                roundHoles[i][playerTwo] !== 0
              ) {
                roundHoles[i].by = by;
                roundHoles[i].leaders = leaders;
              } else {
              }
            }

            if (winner === playerOne.playerID) {
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
                continue;
              }
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamTwo";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamOne";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
              }
            }

            if (winner === playerTwo.playerID) {
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].by += 1;
                roundHoles[i].leaders = "teamTwo";
              }
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamOne";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamTwo";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamTwo";
              }
            }
          }
          console.log(roundHoles);
          return setHoles(roundHoles);
        }

        if (format === "Fourball") {
          let leaders = "";
          let by = 0;

          for (let i = 0; i < roundHoles.length; i++) {
            // const hole = roundHoles[i]

            const winner = roundHoles[i].winner;
            if (winner === "tied") {
              roundHoles[i].leaders = leaders;
              roundHoles[i].by = by;
            }
            if (winner === "teamOne") {
              // AS
              if (leaders === "") {
                leaders = "teamOne";
                by = 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = 1;
                continue;
              }

              // Up
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
                continue;
              }
            }
            if (winner === "teamTwo") {
              // AS
              if (leaders === "") {
                leaders = "teamTwo";
                by = 1;
                roundHoles[i].leaders = leaders;
                roundHoles[i].by = by;
                continue;
              }

              // Up
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].leaders = "teamTwo";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
              }
            }
            // roundHoles[i] = hole;
          }
          setNames({
            teamOne: teams[0].name,
            teamTwo: teams[1].name,
          });
          return setHoles(roundHoles);
        }

        if (format === "match-play") {
          const playerOne = playerIDs[0];
          const playerTwo = playerIDs[1];

          const playerOneDoc = await db
            .collection("users")
            .doc(playerOne)
            .get();
          const playerTwoDoc = await db
            .collection("users")
            .doc(playerTwo)
            .get();
          const p1Data = playerOneDoc.data();
          const p2Data = playerTwoDoc.data();

          const p1Name = p1Data.lastName;
          const p2Name = p2Data.lastName;

          setNames({
            teamOne: p1Name,
            teamTwo: p2Name,
          });

          let leaders = "";
          let by = 0;

          for (let i = 0; i < roundHoles.length; i++) {
            const winner = roundHoles[i].winner;

            if (winner === "") {
              if (
                roundHoles[i][playerOne] !== 0 ||
                roundHoles[i][playerTwo] !== 0
              ) {
                roundHoles[i].by = by;
                roundHoles[i].leaders = leaders;
              } else {
              }
            }

            if (winner === playerOne) {
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
                continue;
              }
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamTwo";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamOne";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamOne";
              }
            }

            if (winner === playerTwo) {
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].by += 1;
                roundHoles[i].leaders = "teamTwo";
              }
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = "teamOne";
                }
                continue;
              }
              if (leaders === "") {
                by = 1;
                leaders = "teamTwo";
                roundHoles[i].by = by;
                roundHoles[i].leaders = "teamTwo";
              }
            }
          }
          return setHoles(roundHoles);
        } else {
          let leaders = "";
          let by = 0;

          for (let i = 0; i < roundHoles.length; i++) {
            // const hole = roundHoles[i]

            const winner = roundHoles[i].winner;
            if (winner === "tied") {
              roundHoles[i].leaders = leaders;
              roundHoles[i].by = by;
            }
            if (winner === "teamOne") {
              // AS
              if (leaders === "") {
                leaders = "teamOne";
                by = 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = 1;
                continue;
              }

              // Up
              if (leaders === "teamOne") {
                by += 1;
                roundHoles[i].leaders = "teamOne";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamTwo") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
                continue;
              }
            }
            if (winner === "teamTwo") {
              // AS
              if (leaders === "") {
                leaders = "teamTwo";
                by = 1;
                roundHoles[i].leaders = leaders;
                roundHoles[i].by = by;
                continue;
              }

              // Up
              if (leaders === "teamTwo") {
                by += 1;
                roundHoles[i].leaders = "teamTwo";
                roundHoles[i].by = by;
                continue;
              }

              // Down
              if (leaders === "teamOne") {
                if (by === 1) {
                  by = 0;
                  leaders = "";
                  roundHoles[i].by = 0;
                  roundHoles[i].leaders = "";
                } else {
                  by -= 1;
                  roundHoles[i].by = by;
                  roundHoles[i].leaders = leaders;
                }
              }
            }
            // roundHoles[i] = hole;
          }
          setNames({
            teamOne: teamNames.teamOne,
            teamTwo: teamNames.teamTwo,
          });
          setHoles(roundHoles);
        }
      });
  }

  return (
    <div className="matchplay-holes scale-in-ps">
      {showBack && <BackRow action={goBack} />}
      <div className="mh-header">
        <div className="mh-left">
          <h4>{names.teamOne}</h4>
        </div>
        <div className="mh-mid">
          <h4>vs</h4>
        </div>
        <div className="mh-right">
          <h4>{names.teamTwo}</h4>
        </div>
      </div>

      <div className="mh-holes">
        {holles.map((hole, index) => {
          return (
            <div key={index} className="mh-header">
              <div
                className={
                  hole.leaders === "teamOne" ? "mh-right mhr-active" : "mh-left"
                }
              >
                <div
                  style={{
                    backgroundColor:
                      hole.leaders === "teamOne"
                        ? teamOneColor
                        : hole.leaders === "teamTwo"
                        ? teamTwoColor
                        : "",
                  }}
                  className="mhrc"
                >
                  <h4>
                    {hole.leaders === "teamOne"
                      ? `${hole.by} up`
                      : hole.leaders === ""
                      ? "-"
                      : ""}
                  </h4>
                </div>
              </div>
              <div className="mh-mid mh-vs">
                <h4>Hole {index + 1}</h4>
              </div>
              <div
                className={
                  hole.leaders === "teamTwo"
                    ? "mh-right mhr-active"
                    : "mh-right"
                }
              >
                <div
                  style={{
                    backgroundColor:
                      hole.leaders === "teamOne"
                        ? teamOneColor
                        : hole.leaders === "teamTwo"
                        ? teamTwoColor
                        : "",
                  }}
                  className="mhrc"
                >
                  <h4>
                    {hole.leaders === "teamTwo"
                      ? `${hole.by} up`
                      : hole.leaders === ""
                      ? "-"
                      : ""}
                  </h4>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MatchplayHoles;
