import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Icons

// Components
import PageHeader from "../../components/display/Rows/PageHeader";
import BackRow from "../../components/display/Rows/BackRow";
import LeagueEdit from "../../components/Leagues/LeagueEdit/LeagueEdit";
import LeagueCreator from "../../components/Leagues/LeagueCreator/LeagueCreator";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import CourseItem from "../../components/display/CourseItem/CourseItem";
import TeamLeagueHome from "../../components/ClubLeagues/TeamLeagueHome";

function ClubLeagues({ goBack = () => {}, reload = () => {}, courses = [] }) {
  const navigate = useNavigate();

  // State
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clubID, setClubID] = useState("");
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const { id } = useParams();

  // UE
  useEffect(() => {
    setClubID(id);
    loadLeagues({ clubID: id });
  }, []);

  async function loadLeagues({ clubID }) {
    setLoading(true);
    const leagueDocs = await db
      .collection("leagues")
      .where("clubID", "==", clubID)
      .get();
    const arr = [];

    const teamLeagues = await db
      .collection("teamLeagues")
      .where("clubID", "==", clubID)
      .get();

    for (let i = 0; i < leagueDocs.docs.length; i++) {
      const leagueDoc = leagueDocs.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();
      const { leagueName, playerIDs, city, country, imageLink } = league;
      arr.push({
        leagueID,
        leagueName,
        playerIDs,
        city,
        country,
        imageLink,
      });
    }

    // console.log(teamLeagues.docs.length);
    for (let i = 0; i < teamLeagues.docs.length; i++) {
      const leagueDoc = teamLeagues.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();
      league.leagueID = leagueID;
      league.matchplay = true;
      // console.log(league);
      arr.push(league);
    }

    arr.sort((a, b) => a.leagueName.localeCompare(b.leagueName));

    setLeagues(arr);
    setLoading(false);
  }

  return (
    <div className="club-leagues">
      {loading && <BackdropLoader />}

      {show ? (
        toShow
      ) : (
        <>
          <BackRow
            action={() => {
              navigate(-1);
            }}
          />{" "}
          <PageHeader text="Leagues" />
          <div className="cl-box">
            {leagues.length > 0 && (
              <div className="scale-in-ps ch-leagues pb-20">
                {leagues.map((league, index) => {
                  return (
                    <Link
                      key={league.leagueID}
                      to={
                        league.matchplay
                          ? `/team-leagues/${league.leagueID}`
                          : `/leagues/${league.leagueID}`
                      }
                    >
                      <CourseItem
                        city={league.city}
                        // showCourse={() => {
                        //   if (league.matchplay) {
                        //     setToShow(
                        //       <TeamLeagueHome
                        //         goBack={() => setShow(false)}
                        //         leagueID={league.leagueID}
                        //       />
                        //     );
                        //     setShow(true);
                        //   } else {
                        //     setToShow(
                        //       <LeagueEdit
                        //         goBack={() => setShow(false)}
                        //         leagueID={league.leagueID}
                        //         clubID={clubID}
                        //       />
                        //     );
                        //     setShow(true);
                        //   }
                        // }}
                        country={league.country}
                        courseName={league.leagueName}
                        image={league.imageLink}
                        members={league.playerIDs.length}
                        hideStar
                      />
                    </Link>
                  );
                })}
              </div>
            )}
            {leagues.length === 0 && (
              <div className="no-msg">
                <p>No league to display</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ClubLeagues;
