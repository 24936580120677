import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useClickTheme } from "../../contexts/MenuContext";
import { useParams, useNavigate, Link } from "react-router-dom";

// Icons
import {
  AddCircle,
  Edit,
  Person,
  Info,
  Error,
  KeyboardArrowDown,
} from "@mui/icons-material";
import TodayIcon from "@mui/icons-material/Today";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

// Components
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useCookies } from "react-cookie";
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import TeamLeagueEventEditor from "./TeamLeagueEventEditor";
import Pill from "../Buttons/Pill";
import { Skeleton, Avatar, Dialog } from "@mui/material";
import BroadcastModal from "../BroadcastModal/BroadcastModal";
import TeamLeagueEventPlayers from "./TeamLeagueEventPlayers";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import ClubSponsors from "../../pages/Clubs/Sponsors/ClubSponsors";
import TeamLeagueRound from "./TeamLeagueRound";
import EventSponsorManager from "../Events/EventCreator/EventSponsorManager";
import EventRound from "../Events/EventCreator/EventRound";
import TeamEventDashboard from "../Events/EventDashboard/TeamEventDashboard";
import PlayerTeeSelector from "../Events/EventCreator/PlayerTeeSelector";
import GroupSelector from "../Events/GroupSelector/GroupSelector";
import TLEMatchup from "./TLEMatchup";
import EventHandicapEdit from "../Events/EventDashboard/EventHandicapEdit";
import TeamSubmissions from "./TeamSubmissions";
import MenuSwitcher from "../MenuSwitcher/MenuSwitcher";
function TeamLeagueEventHome({}) {
  useEffect(() => {
    loadDetails();
  }, []);
  const { currentUser, userDetails } = useAuth();
  const eventID = useParams().id;

  const [showReset, setShowReset] = useState(false);
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  const showClick = useClickTheme();
  const [showBC, setShowBC] = useState(false);

  const [bbs, setBBS] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingPlayers, setLoadingPlayers] = useState(true);
  const [error, setError] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const [hideCreate, setHideCreate] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);

  const [started, setStarted] = useState(false);

  const [indexStarted, setIndexStarted] = useState(0);

  const [teams, setTeams] = useState([]);
  const [playing, setPlaying] = useState([]);

  const [players, setPlayers] = useState([]);

  const [submissions, setSubmissions] = useState(false);

  const [rounds, setRounds] = useState([]);

  const [courseDetails, setCourseDetails] = useState({});

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [details, setDetails] = useState({
    rounds: [],
    roundIDs: [],
  });

  async function loadDetails() {
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const {
      eventName,
      eventDate,
      courseID,
      courseName,
      playerIDs,
      rounds,
      roundIDs,
      leagueID,
      description,
      leagueName,
      imageLink,
      sponsors,
      scratchMode,
      clubID,
    } = event;

    const courses = event.courses ?? [];

    const playerHandicaps = event.playerHandicaps ?? {};

    if (event.activeRoundID !== "") {
      const ind = roundIDs.indexOf(event.activeRoundID);
      // console.log(ind);
      setIndexStarted(ind);
    }

    for (let i = 0; i < rounds.length; i++) {
      const rd = rounds[i];
      if (rd.teamSubmissions) {
        if (Object.entries(rd.teamSubmissions).length > 0) {
          setSubmissions(true);
        }
      }
    }

    setRounds(rounds);
    setStarted(roundIDs.length > 0);
    setPlaying(event.playerIDs);

    for (let i = 0; i < courses.length; i++) {
      const course = courses[i];
      const { courseID } = course;
      const courseDoc = await db.collection("courses").doc(courseID).get();
      const courseData = courseDoc.data();
      courses[i].teeArray = courseData.teeArray;
    }

    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();

    const { teams } = league;

    setBBS(league.betterballStableford ?? false);

    setTeams(teams);

    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();

    const { teeArray, length } = course;
    setCourseDetails({ teeArray, length });

    const dateObj = new Date(eventDate.toDate());
    const dateString = dateObj.toLocaleDateString("en-us", {
      dateStyle: "full",
    });

    const courseIDs = courses.map((course) => course.courseID);

    setDetails({
      completed: event.completed,
      courseIDs,
      eventName,
      courseID,
      courseName,
      clubID,
      sponsors,
      courses,
      rounds,
      dateObj,
      dateString,
      leagueID,
      roundIDs,
      description,
      leagueName,
      imageLink,
      scratchMode,
    });

    // alert(playerIDs.length);

    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);
    const arr = [];
    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName, profileImage } = player;
      const handicap = playerHandicaps[playerID] ?? player.handicap;
      const name = `${firstName} ${lastName}`;
      arr.push({
        playerID,
        name,
        handicap,
        profileImage,
        originalHandicap: player.handicap,
      });
    }

    setPlayers(arr);

    setLoading(false);
  }

  async function createRounds() {
    setShowCreate(false);
    setLoading(true);
    const taskDocs = await db
      .collection("tasks")
      .where("eventID", "==", eventID)
      .where("type", "==", "teamRoundCreation")
      .get();
    if (taskDocs.docs.length === 0) {
      setLoading(false);
      return setError(
        "Sorry, we encountered a technical difficulty there. Please try again."
      );
    }

    const taskDoc = taskDocs.docs[0];
    await taskDoc.ref.update({
      status: "triggered",
    });
    setHideCreate(true);
    loadDetails();
  }

  async function removeRound(index) {
    const rounds = details.rounds;
    rounds.splice(index, 1);

    try {
      await db.collection("teamEvents").doc(eventID).update({
        rounds,
      });
      setShowPop(false);
      loadDetails();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
  }

  async function editGroups(index, groups = []) {
    for (let i = 0; i < groups.length; i++) {
      const players = groups[i].players;
      while (players[players.length - 1].name === "Empty") {
        console.log("found one");
        groups[i].players.pop();
      }
    }
    // return console.log(groups);
    const rounds = details.rounds;
    rounds[index].groups = groups;

    await db.collection("teamEvents").doc(eventID).update({
      rounds,
    });
    setShow(false);
  }

  async function resetEvt() {
    setLoading(true);
    setShowReset(false);
    const rdDocs = await db
      .collection("rounds")
      .where("eventID", "==", eventID)
      .get();

    rdDocs.forEach((rdDoc) => rdDoc.ref.delete());

    const evtDoc = await db.collection("teamEvents").doc(eventID).get();
    const evtData = evtDoc.data();
    const matchups = evtData.matchups ?? [];

    for (let i = 0; i < matchups.length; i++) {
      matchups[i].teamOne.score = 0;
      matchups[i].teamTwo.score = 0;
    }

    await db.collection("teamEvents").doc(eventID).update({
      roundIDs: [],
      matchups,
    });

    const triggerDocs = await db
      .collection("tasks")
      .where("eventID", "==", eventID)
      .where("type", "==", "teamRoundCreation")
      .get();

    await triggerDocs.docs[0].ref.update({
      status: "pending",
    });

    const refs = players.map((pl, i) =>
      db.collection("users").doc(pl.playerID).update({
        activeRoundID: "",
        activeTeamEventID: "",
      })
    );

    await Promise.all(refs);

    loadDetails();
  }

  return (
    <div className="tl-event-home event-home">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <Dialog open={showReset} onClose={() => setShowReset(false)}>
        <div className="delete-popup">
          <Info className="big-icon dg-icon" />
          <p>Are you sure?</p>
          <div className="flex-center">
            <button onClick={resetEvt} className="default-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      {showBC && (
        <BroadcastModal
          close={() => setShowBC(false)}
          eventID={eventID}
          name={details.eventName}
        />
      )}
      {show ? (
        toShow
      ) : (
        <>
          <Dialog open={showCreate} onClose={() => setShowCreate(false)}>
            <div className="delete-popup">
              <Info className="big-icon dg-icon" />
              <p>
                Are you sure you want to create the rounds for this event? Some
                details cannot be changed once this action has been performed.
              </p>
              <div className="flex-center">
                <button onClick={createRounds} className="default-button">
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>
          <Dialog open={showPop} onClose={() => setShowPop(false)}>
            <div className="delete-popup">
              <Error className="err-icon" style={{ color: "#E24955" }} />
              <p>Are you sure you want to delete this round?</p>
              <div
                onClick={() => removeRound(deleteIndex)}
                className="delete-button"
              >
                Delete
              </div>
            </div>
          </Dialog>
          <BackRow action={goBack} />
          <div className="ph-me-opt">
            <PageHeader text={details.eventName} />
            <MenuSwitcher />
          </div>

          {cookies["menu-type"] === "drop" && (
            <div className="flex flex-align-center mb-20 ddmb-box mt-10">
              <div className="ddm-box">
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button className="ddm-but">
                      <span>General</span>
                      <KeyboardArrowDown className="ddm-ico" />
                    </button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="DropdownMenuContent"
                      sideOffset={5}
                    >
                      {details.roundIDs.length > 0 && !details.completed && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setShowReset(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          Reset
                        </DropdownMenu.Item>
                      )}
                      <DropdownMenu.Item
                        onClick={() => {
                          setToShow(
                            <TeamLeagueEventEditor
                              eventID={eventID}
                              edit
                              disableChanges={started}
                              leagueID={details.leagueID}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                        className="DropdownMenuItem"
                      >
                        Edit Details
                      </DropdownMenu.Item>
                      {details.roundIDs.length > 0 && (
                        <DropdownMenu.Item
                          onClick={() => {}}
                          className="DropdownMenuItem"
                        >
                          <Link to={`/team-evt-dash/${eventID}`}>
                            Live Dashboard
                          </Link>
                        </DropdownMenu.Item>
                      )}
                      <DropdownMenu.Item
                        onClick={() => {
                          window.open(
                            `https://www.theugatour.com/events/${eventID}`,
                            "_blank"
                          );
                        }}
                        className="DropdownMenuItem"
                      >
                        Public Link
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onClick={() => {
                          window.open(
                            `/#/team-leagues/${details.leagueID}`,
                            "_blank"
                          );
                        }}
                        className="DropdownMenuItem"
                      >
                        League Page
                      </DropdownMenu.Item>

                      <DropdownMenu.Item
                        onClick={() => {
                          if (details.chatID) {
                            showClick({
                              type: "chat",
                              chatID: details.chatID,
                            });
                          } else {
                            setShowBC(true);
                          }
                        }}
                        className="DropdownMenuItem"
                      >
                        Group Message
                      </DropdownMenu.Item>
                      {details.rounds.length > 0 &&
                        details.roundIDs.length === 0 &&
                        !hideCreate && (
                          <DropdownMenu.Item
                            onClick={() => {
                              setShowCreate(true);
                            }}
                            className="DropdownMenuItem"
                          >
                            Create Rounds
                          </DropdownMenu.Item>
                        )}
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </div>
              <div className="ddm-box">
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button className="ddm-but">
                      <span>Players</span>
                      <KeyboardArrowDown className="ddm-ico" />
                    </button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="DropdownMenuContent"
                      sideOffset={5}
                    >
                      {!started && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <TLEMatchup
                                eventID={eventID}
                                goBack={() => setShow(false)}
                                leagueID={details.leagueID}
                              />
                            );
                            setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          Team Matchups
                        </DropdownMenu.Item>
                      )}
                      {!started && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <TeamLeagueEventPlayers
                                eventID={eventID}
                                goBack={() => setShow(false)}
                                reload={loadDetails}
                              />
                            );
                            setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          Manage Players
                        </DropdownMenu.Item>
                      )}
                      {!details.scratchMode && !started && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <EventHandicapEdit
                                teeMap={details.teeMap}
                                defaultPercentage={details.percentage}
                                defaultPreset={details.presetType}
                                eventID={eventID}
                                defaultSR={details.sr ?? true}
                                teamEvt
                                courseID={details.courseID}
                                defaultPlayers={players}
                                hide={() => setShow(false)}
                                live={false}
                              />
                            );
                            setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          Manage Handicaps
                        </DropdownMenu.Item>
                      )}
                      {submissions && (
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <TeamSubmissions
                                eventID={eventID}
                                teams={teams}
                                goBack={() => setShow(false)}
                              />
                            );
                            setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          View Submissions
                        </DropdownMenu.Item>
                      )}
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </div>
              <div className="ddm-box">
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button className="ddm-but">
                      <span>Rounds</span>
                      <KeyboardArrowDown className="ddm-ico" />
                    </button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="DropdownMenuContent"
                      sideOffset={5}
                    >
                      <DropdownMenu.Item
                        onClick={() => {
                          setToShow(
                            <TeamLeagueRound
                              eventID={eventID}
                              goBack={() => setShow(false)}
                              reload={loadDetails}
                              roundIndex={rounds.length}
                              live={false}
                              hideMP={bbs}
                            />
                          );
                          setShow(true);
                        }}
                        className="DropdownMenuItem"
                      >
                        Add Round
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </div>
              <div className="ddm-box">
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <button className="ddm-but">
                      <span>Admin</span>
                      <KeyboardArrowDown className="ddm-ico" />
                    </button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="DropdownMenuContent"
                      sideOffset={5}
                    >
                      <DropdownMenu.Item
                        onClick={() => {
                          setToShow(
                            <EventSponsorManager
                              reload={loadDetails}
                              selectedSponsors={details.sponsors}
                              goBack={() => setShow(false)}
                              clubID={details.clubID}
                              eventID={eventID}
                              teamEvent
                            />
                          );
                          setShow(true);
                        }}
                        className="DropdownMenuItem"
                      >
                        Manage Sponsors
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </div>
            </div>
          )}

          {cookies["menu-type"] !== "drop" && (
            <div className="ch-links">
              <div className="ch-link">
                <div className="sub-header mb-10">
                  <h5>General</h5>
                </div>
                <div className="quick-links">
                  {userDetails &&
                    // userDetails.admin &&
                    details.roundIDs.length > 0 && (
                      <Pill onClick={() => setShowReset(true)} text={"reset"} />
                    )}
                  <Pill
                    text={"Edit Details"}
                    onClick={() => {
                      setToShow(
                        <TeamLeagueEventEditor
                          eventID={eventID}
                          edit
                          disableChanges={started}
                          leagueID={details.leagueID}
                          goBack={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                  />

                  {started && (
                    <Link to={`/team-evt-dash/${eventID}`}>
                      {" "}
                      <Pill
                        text={"Live Dashboard"}
                        // onClick={() => {
                        //   setToShow(
                        //     <TeamEventDashboard
                        //       eventID={eventID}
                        //       goBack={() => setShow(false)}
                        //     />
                        //   );
                        //   setShow(true);
                        // }}
                      />
                    </Link>
                  )}
                  <Pill
                    text={"Public Link "}
                    onClick={() => {
                      window.open(
                        `https://www.theugatour.com/events/${eventID}`,
                        "_blank"
                      );
                    }}
                  />
                  <Pill
                    text={"League Page "}
                    onClick={() => {
                      window.open(
                        `/#/team-leagues/${details.leagueID}`,
                        "_blank"
                      );
                    }}
                  />
                  <Pill
                    text={"Group Message"}
                    onClick={() => {
                      if (details.chatID) {
                        showClick({
                          type: "chat",
                          chatID: details.chatID,
                        });
                      } else {
                        setShowBC(true);
                      }
                    }}
                  />
                  {details.rounds.length > 0 &&
                    details.roundIDs.length === 0 &&
                    !hideCreate && (
                      <Pill
                        onClick={() => setShowCreate(true)}
                        text={"Create Rounds"}
                      />
                    )}
                </div>
              </div>
              {true && (
                <div className="ch-link">
                  <div className="sub-header mb-10">
                    <h5>Players</h5>
                  </div>
                  <div className="quick-links">
                    {!started && (
                      <Pill
                        text={"Team Matchups"}
                        onClick={() => {
                          setToShow(
                            <TLEMatchup
                              eventID={eventID}
                              goBack={() => setShow(false)}
                              leagueID={details.leagueID}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                    {!started && (
                      <Pill
                        text={"Manage Players"}
                        onClick={() => {
                          setToShow(
                            <TeamLeagueEventPlayers
                              eventID={eventID}
                              goBack={() => setShow(false)}
                              reload={loadDetails}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                    {submissions && (
                      <Pill
                        text={"View Submissions"}
                        onClick={() => {
                          setToShow(
                            <TeamSubmissions
                              eventID={eventID}
                              teams={teams}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                    {!details.scratchMode && (
                      <Pill
                        text={"Manage Handicaps"}
                        onClick={() => {
                          setToShow(
                            <EventHandicapEdit
                              teeMap={details.teeMap}
                              defaultPercentage={details.percentage}
                              defaultPreset={details.presetType}
                              eventID={eventID}
                              defaultSR={details.sr ?? true}
                              teamEvt
                              courseID={details.courseID}
                              defaultPlayers={players}
                              hide={() => setShow(false)}
                              live={false}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {!started && (
                <div className="ch-link">
                  <div className="sub-header mb-10">
                    <h5>Rounds</h5>
                  </div>
                  <div className="quick-links">
                    <Pill
                      text={"Add Round"}
                      onClick={() => {
                        setToShow(
                          <TeamLeagueRound
                            eventID={eventID}
                            goBack={() => setShow(false)}
                            reload={loadDetails}
                            roundIndex={rounds.length}
                            live={false}
                            hideMP={bbs}
                          />
                        );
                        setShow(true);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="ch-link">
                <div className="sub-header mb-10">
                  <h5>Admin</h5>
                </div>
                <div className="quick-links">
                  <Pill
                    text={"Manage Sponsors"}
                    onClick={() => {
                      setToShow(
                        <EventSponsorManager
                          reload={loadDetails}
                          selectedSponsors={details.sponsors}
                          goBack={() => setShow(false)}
                          clubID={details.clubID}
                          eventID={eventID}
                          teamEvent
                        />
                      );
                      setShow(true);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="eh-box-box">
            <div className="eh-box">
              <div className="ehb-header pt-0">
                <h1 className="ne-header">Details</h1>
                <div className="svg-cont">
                  <Edit
                    className="edit-hint dg-icon-bg icon-cursor mb-10"
                    onClick={() => {
                      setToShow(
                        <TeamLeagueEventEditor
                          eventID={eventID}
                          edit
                          leagueID={details.leagueID}
                          goBack={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                    style={{ color: "#21c17c" }}
                  />
                </div>
              </div>
              <div className="ehbh-item">
                <div className="info-circle">
                  <TodayIcon className="ic-icon" />
                </div>
                {details.dateString !== undefined ? (
                  <p>{details.dateString}</p>
                ) : (
                  <div style={{ width: "50%" }}>
                    <Skeleton variant="text" />
                  </div>
                )}
              </div>
              <div className="ehbh-item">
                <div className="info-circle">
                  <GolfCourseIcon className="ic-icon dg-icon" />
                </div>
                {details.courseName === undefined ? (
                  <div style={{ width: "50%" }}>
                    <Skeleton variant="text" />
                  </div>
                ) : (
                  <p>{details.courseName}</p>
                )}
              </div>
              <div className="ehbh-item">
                <div className="info-circle">
                  <Person className="ic-icon" />
                </div>
                {details.leagueName === undefined ? (
                  <div style={{ width: "50%" }}>
                    <Skeleton variant="text" />
                  </div>
                ) : (
                  <p>{details.leagueName}</p>
                )}
              </div>
              <div className="ehbh-item">
                <div className="info-circle">
                  <TextSnippetIcon className="ic-icon" />
                </div>
                {details.description === undefined ? (
                  <div style={{ width: "50%" }}>
                    <Skeleton variant="rectangular" height={100} />
                  </div>
                ) : (
                  <p style={{ width: "70vw" }}>{details.description}</p>
                )}
              </div>
            </div>
            <div className="p-r-course">
              <div className="p-r-course-left">
                <Avatar alt={details.leagueName} src={details.imageLink} />
              </div>
            </div>
          </div>
          <div className="ehb-header">
            <h1 className="ne-header">Rounds</h1>
            {!started && (
              <AddCircle
                onClick={() => {
                  setToShow(
                    <TeamLeagueRound
                      eventID={eventID}
                      goBack={() => setShow(false)}
                      reload={loadDetails}
                      roundIndex={rounds.length}
                      hideMP={bbs}
                    />
                  );
                  setShow(true);
                }}
                className="dg-icon-bg icon-cursor mb-10"
              />
            )}
          </div>
          <div className="ehb-rounds">
            {rounds.map((round, index) => {
              // const ds = new Date(round.date.toDate()).toLocaleDateString();
              return (
                <EventRound
                  key={`${index}eventround`}
                  date={round.date}
                  // hideTees
                  remove={() => {
                    setDeleteIndex(index);
                    setShowPop(true);
                  }}
                  custom
                  editTimes={() => {
                    setToShow(
                      <GroupSelector
                        matches={round.matches}
                        matchplay
                        guests={details.guests ?? []}
                        defaultShotgun={round.startType === "shotgun"}
                        open={round.open ?? false}
                        leagueID={details.leagueID}
                        defaultLimit={round.groupLimit ?? 0}
                        defaultGroups={round.groups ?? []}
                        event
                        saveChanges={(groups) => {
                          editGroups(index, groups, round.open);
                        }}
                        eventDate={details.dateObj}
                        courseName={details.courseName}
                        eventName={details.eventName}
                        courseDetails={courseDetails}
                        players={players}
                        goBack={() => setShow(false)}
                        teamEvent
                        setOverall={() => {}}
                        uid={currentUser.uid}
                      />
                    );
                    setShow(true);
                  }}
                  num={index + 1}
                  // hideTees
                  editTees={() => {
                    setToShow(
                      <PlayerTeeSelector
                        teamEvt
                        courseIDs={details.courses}
                        goBack={() => setShow(false)}
                        eventID={eventID}
                        eventName={details.eventName}
                        players={players}
                        round={round}
                        roundIndex={index}
                        rounds={details.rounds}
                      />
                    );
                    setShow(true);
                  }}
                  hideIcons={started && index <= indexStarted}
                  edit={() => {
                    setToShow(
                      <TeamLeagueRound
                        edit
                        round={round}
                        goBack={() => setShow(false)}
                        eventID={eventID}
                        reload={loadDetails}
                        roundIndex={index}
                        hideMP={bbs}
                        live={false}
                        // live={round.matches.length > 0}
                      />
                    );
                    setShow(true);
                  }}
                />
              );
            })}
          </div>
          {rounds.length === 0 && (
            <div className="no-rounds pl-20 mb-20">
              <p>No rounds added yet</p>
            </div>
          )}

          <div className="ehb-header">
            <h1 className="ne-header">Players</h1>
          </div>
          <div className="ltm-teams">
            {teams.map((team, index) => {
              return (
                <div className="ltm-team pd-20" key={team.teamID}>
                  <div className="ltm-team-top flex-align-center">
                    <Avatar alt={team.teamName} src={team.imageLink} />
                    <h3 className="mr-5v ml-20">{team.teamName}</h3>
                  </div>
                  <div className="ltm-members pd-20 flex-wrap">
                    {team.players.map((player, index2) => {
                      if (!playing.includes(player.playerID)) {
                        return null;
                      }
                      return (
                        <PlayerItem
                          key={player.playerID}
                          name={player.name}
                          noLink
                          showAdd
                          showProfile={() => {}}
                          playerID={player.playerID}
                          hideProfile
                          hideEdit
                          hideIcons
                          //   hideIcons
                          hideImage
                          hideHCP
                          removeAction={() => {}}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {playing.length === 0 && (
              <div className="no-players pd-20">
                <p>No players have been added yet</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default TeamLeagueEventHome;
