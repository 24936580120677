import React, { useEffect } from "react";
import "./About.css";
import SouthIcon from "@mui/icons-material/South";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

import int from "../../assets/images/int.png";
import func from "../../assets/images/func.png";
import cal from "../../assets/images/cal.png";
import man from "../../assets/images/man.png";
import app from "../../assets/images/app.png";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <Helmet>
        <title>The Commissioner | About</title>
      </Helmet>
      <div className="dp-header">
        <h1>About The Commissioner</h1>
      </div>
      <div className="about-boxes">
        <div className="about-box about-box-one">
          <AnimationOnScroll
            animateOnce
            className="flex-center"
            animateIn="animate__slideInLeft"
          >
            <div className="abo-left">
              <div className="abo-header">
                <h1>Simple Structure</h1>
                <div className="aboh-border"></div>
              </div>
              <div className="abo-text">
                <p>
                  The UGA system is divided into 5 modules in a sub-structure
                  format which creates a simple yet effective workflow for
                  managing events, tournaments and players. "The Commissioner"
                  is the hub from which you can manage all of these modules
                  simply & effectively.
                </p>
              </div>
              <div className="abo-bullets">
                <div className="abob">
                  <h3>Club Module:</h3>
                  <p>Manage your club, players and events from a central hub</p>
                </div>
                <div className="abob">
                  <h3>League Module:</h3>
                  <p>
                    Create specific divisions for different player groups &
                    manage them over time
                  </p>
                </div>
                <div className="abob">
                  <h3>Events Module:</h3>
                  <p>
                    Create events which form part of leagues, or create
                    standalone events
                  </p>
                </div>
                <div className="abob">
                  <h3>Rounds Module:</h3>
                  <p>Set up tournament formats and rounds</p>
                </div>
                <div className="abob">
                  <h3>Live Module:</h3>
                  <p>
                    Score and display your events LIVE using the UGA Tour App
                    for FREE
                  </p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce animateIn="animate__slideInRight">
            <div className="abo-right">
              <div className="module-item">
                <h1>CLUB</h1>
              </div>
              <div className="module-arrow">
                <SouthIcon className="mod-arr" />
              </div>
              <div className="module-item">
                <h1>LEAGUE</h1>
              </div>
              <div className="module-arrow">
                <SouthIcon className="mod-arr" />
              </div>
              <div className="module-item">
                <h1>EVENT</h1>
              </div>
              <div className="module-arrow">
                <SouthIcon className="mod-arr" />
              </div>
              <div className="module-item">
                <h1>ROUNDS</h1>
              </div>
              <div className="module-arrow">
                <SouthIcon className="mod-arr" />
              </div>
              <div className="module-item">
                <h1>LIVE</h1>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
        <div className="about-box about-box-two">
          <AnimationOnScroll
            animateOnce
            className="flex-center"
            animateIn="animate__slideInLeft"
          >
            <div className="abo-left abo-img">
              <div className="aboi-box">
                <img src={int} alt={"integration"} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce animateIn="animate__slideInRight">
            <div className="abo-right">
              <div className="abo-header">
                <h1>Designed to integrate</h1>
              </div>
              <div className="aboh-border"></div>
              <div className="abo-text">
                <div className="abot-item">
                  <p>
                    Unlike many other golf management software, The Commissioner
                    is designed to integrate with the existing systems of any
                    club or golfing body rather than take over already existing
                    systems.
                  </p>
                </div>
                <div className="abot-item">
                  <p>
                    <span>
                      The Commissioner is a tournament management software{" "}
                    </span>
                    that allows club pros and golf directors to continue using
                    their existing membership management systems in conjunction
                    with the software.
                  </p>
                </div>
                <div className="abot-item">
                  <p>
                    <span>"Player ID Management" </span>
                    on The Commissioner allows clubs to export tee sheets,
                    member databases and more directly from their existing
                    membership management platforms in CSV format and upload
                    these to our platform in order to create tee sheets, league
                    player lists an more in seconds!
                  </p>
                </div>
                <div className="abot-item">
                  <p>
                    The Commissioner platform also integrates seamlessly with
                    both <span>The UGA Tour App</span> to allow club's members
                    the ability to score live, track stats and view live
                    leaderboards on course during events as well as with The UGA
                    Tour Website so provide shareable leaderboards, league
                    pages, event pages and player profiles to anyone at any
                    time!
                  </p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
        <div className="about-box about-box-one">
          <AnimationOnScroll
            animateOnce
            className="flex-center"
            animateIn="animate__slideInLeft"
          >
            <div className="abo-left">
              <div className="abo-header">
                <h1>Club Management</h1>
                <div className="aboh-border"></div>
              </div>
              <div className="abo-text">
                <p className="heavy-pee">
                  The club dashboard on The Commissioner provides a "top down"
                  view of your operations on the platform and is the central hub
                  from which admins are able to:
                </p>
              </div>
              <div className="abo-bulls">
                <ul className="main-ul">
                  <li>Create & View Events & Leagues</li>
                  <li>Manage player database</li>
                  <ul className="sub-ul">
                    <li>Add Players</li>
                    <li>Message Players</li>
                    <li>Bulk import players/tee sheets using CSV upload</li>
                    <li>Manage player ID's for cross-platform integration</li>
                  </ul>
                  <li>Manage club sponsors for display on leaderboards</li>
                  <li>Access/view past & future leagues/events</li>
                  <li>
                    Post tournament realated messages on the club message board
                  </li>
                  <li>Keep updated with automated notifications</li>
                </ul>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce animateIn="animate__slideInRight">
            {" "}
            <div className="aboi-box">
              <img src={man} alt={"club"} />
            </div>
          </AnimationOnScroll>
        </div>

        <div className="about-box about-box-two">
          <AnimationOnScroll
            animateOnce
            className="flex-center"
            animateIn="animate__slideInLeft"
          >
            {" "}
            <div className="aboi-box">
              <img src={cal} alt={"events"} />
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce animateIn="animate__slideInRight">
            <div className="abo-header mobile-mt-20">
              <h1>Event Management</h1>
              <div className="aboh-border"></div>
            </div>
            <div className="abo-text">
              <p className="heavy-pee">
                The event creator gives club admins full control over the setup
                of any event by allowing them to:
              </p>
            </div>
            <div className="abo-bulls">
              <ul className="main-ul">
                <li>Choose from a list of singles or team formats</li>
                <li>Create leaderboard divisions by handicap or age</li>
                <li>
                  Enable stats, custom rules and other admin extra's to add more
                  to the event for your players
                </li>
                <li>
                  Import players from third party software using CSV upload
                </li>
                <li>
                  Manage prize list & automatic prize allocations for live
                  events
                </li>
                <li>
                  Admin score input
                  <ul className="sub-ul">
                    <li>
                      Input scores for players who do no make use of The UGA
                      Tour App for live scoring
                    </li>
                    <li>
                      The simple input format means that anyone on a club's
                      staff can collect and input scores
                    </li>
                  </ul>
                </li>
                <li>Generate live link for viewers</li>
                <ul>
                  <li>
                    Hosted on The UGA website and viewable to anyone with the
                    link
                  </li>
                  <li>Each event receives it's own dedicated page</li>
                </ul>
              </ul>
            </div>
          </AnimationOnScroll>
        </div>

        <div className="about-box about-box-one">
          <AnimationOnScroll
            animateOnce
            className="flex-center"
            animateIn="animate__slideInLeft"
          >
            <div className="abo-left">
              <div className="abo-header">
                <h1>Live Scoring - The UGA Tour App</h1>
                <div className="aboh-border"></div>
              </div>
              <div className="aboibs">
                <div className="aboib-left">
                  <div className="abo-info-box">
                    <div className="abo-sub-header">
                      <h3>WHAT IS THE UGA TOUR APP?</h3>
                    </div>
                    <div className="abo-ib-text">
                      <p>
                        The UGA Tour App is a 100% Free app that we offer for
                        the members of any club. The app integrates fully with
                        The Commissioner platform to allow for live scoring,
                        stats tracking and live leaderboard presentation for any
                        leagues, events, tournaments set up by a club using The
                        Commissioner platform.
                      </p>
                    </div>
                  </div>
                  <div className="abo-info-box">
                    <div className="abo-sub-header">
                      <h3>FULLY INTEGRATED</h3>
                    </div>
                    <div className="abo-ib-text">
                      <p>
                        The UGA Tour App will automatically open a round for any
                        players registered on the app who are playing in a
                        tournament or event, whenever it starts. This allows one
                        player to score for their team or group and will run a
                        live leaderboard during an event which is viewable for
                        the players on the app.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="aboib-right">
                  <div className="abo-info-box">
                    <div className="abo-sub-header">
                      <h3>AND THERE'S MORE</h3>
                    </div>
                    <div className="abo-ib-text">
                      <p>
                        Not only does The UGA Tour App facilitate live
                        tournaments and events, but any players with a profile
                        will be able to view a large array of stats and
                        information about their game which grows as they use the
                        app more.
                        <br />
                        <br />
                        With over 30 000 courses on our database, players & club
                        members can use the app to score and track stats any
                        time - even for social rounds!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce animateIn="animate__slideInRight">
            <div className="abo-right">
              <div className="aboi-box">
                <img src={app} alt={"integration"} />
              </div>
            </div>
          </AnimationOnScroll>
        </div>

        <div className="about-box about-box-two">
          <AnimationOnScroll animateOnce animateIn="animate__slideInLeft">
            <div className="abo-left">
              <div className="aboi-box">
                <img src={func} alt={"functionality"} />
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce animateIn="animate__slideInRight">
            <div className="abo-right">
              <div className="abo-header">
                <h1>ADDITIONAL FEATURES</h1>
                <div className="aboh-border"></div>
              </div>
              <div className="abo-info-box">
                <div className="abo-sub-header">
                  <h3>League Module</h3>
                </div>
                <div className="abo-ib-text">
                  <p>
                    Creating leagues for your club creates the perfect
                    sub-structures within the club for stats regarding each
                    division and player within to group to be recorded as well
                    as for on-going league table to unfold, giving players a
                    more immersive experience through having more to play for
                    than stand-alone events.
                  </p>
                </div>
              </div>
              <div className="abo-info-box">
                <div className="abo-sub-header">
                  <h3>FORMAT CUSTOMISER</h3>
                </div>
                <div className="abo-ib-text">
                  <p>
                    Can't find the format you're looking for on our list of
                    preset formats? Use the built in format customiser to create
                    the format you want & save it as a preset for future use.
                    <br />
                    <br />
                    *Applies to team formats only
                  </p>
                </div>
              </div>
              <div className="abo-info-box">
                <div className="abo-sub-header">
                  <h3>Course Stats</h3>
                </div>
                <div className="abo-ib-text">
                  <p>
                    The Commissioner generates statistics by analysing scores at
                    your course(s). On top of 18 or 9-hole scoring averages,
                    view hole specific stats with regards to gross and net
                    scoring averages, greens and fairways hit, putts and more.
                  </p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
}

export default About;
