import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import BackdropLoader from "../../Loaders/BackdropLoader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

function PlayerDQ({ eventID = "", goBack = () => {}, type = "dq" }) {
  // State
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [selPlayer, setSelPlayer] = useState({});
  const [selDQPlayer, setSelDQPlayer] = useState({});
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  const [confirming, setConfirming] = useState(false);
  const [confirmInput, setConfirmInput] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  const [confirmingRe, setConfirmingRe] = useState(false);
  const [reConfirmInput, setReConfirmInput] = useState("");
  const [reConfirmed, setReConfirmed] = useState(false);

  const [active, setActive] = useState("dq");

  const [dqd, setDQd] = useState([]);

  // UE
  useEffect(() => {
    loadDetails();
  }, []);

  // Funcs
  async function loadDetails() {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const { activeRoundID } = event;

    const roundDoc = await db.collection("rounds").doc(activeRoundID).get();
    const round = roundDoc.data();

    let { leaderboard } = round;
    // const guests = round.guests ?? [];
    // leaderboard = [...leaderboard, ...guests];

    if (type === "wd") {
      const wdd = leaderboard.filter((pl) => pl.wd);
      console.log(wdd);
      setDQd(wdd);
    } else {
      const dqd = leaderboard.filter((pl) => pl.dq);
      console.log(dqd);
      setDQd(dqd);
    }

    setPlayers(leaderboard);
    setLoading(false);
  }

  async function saveTrigger() {
    setLoading(true);
    try {
      await db.collection("triggers").add({
        type: type === "wd" ? "player-wd" : "player-dq",
        eventID,
        playerID: selPlayer.playerID,
      });
      setConfirmed(true);
    } catch (err) {
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
      console.log(err);
    }
    setLoading(false);
  }

  function reset() {
    setConfirmed(false);
    setConfirmInput("");
    setConfirming(false);
    setSelPlayer({});
  }

  async function unDQ() {
    setLoading(true);
    try {
      await db.collection("triggers").add({
        type: type === "wd" ? "player-uwd" : "player-udq",
        eventID,
        playerID: selDQPlayer.playerID,
      });
      setReConfirmed(true);
    } catch (err) {
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
      console.log(err);
    }
    setLoading(false);
  }

  return (
    <div className="player-dq">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader
        text={type === "wd" ? "Withdraw Player" : "Disqualify Player"}
      />

      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}

      {reConfirmed ? (
        <>
          {" "}
          <div className="erp-finished">
            <div className="erpc-msg">
              <p>
                <strong>{selDQPlayer.name}</strong> was successfully re added to
                this event. Please note you will have to add them back into any
                groups or teams they were a part of.
              </p>
              {/* <div className="flex-center">
                <button onClick={reset} className="default-button">
                  More DQ
                </button>
              </div> */}
            </div>
          </div>
        </>
      ) : confirmingRe ? (
        <>
          <div className="erp-confirm mt-20 mb-20">
            <div className="erpc-msg">
              <p>
                Are you sure you want to add <strong>{selDQPlayer.name}</strong>{" "}
                back to this event?
              </p>
            </div>
            <div className="im-msg mb-10">
              <p>Type "YES" below to confirm</p>
            </div>
            <input
              type="text"
              value={reConfirmInput}
              placeholder="Start typing..."
              onChange={(e) => setReConfirmInput(e.target.value)}
              className="default-input mb-20"
            />
            <div className="flex-center">
              <button
                onClick={() => unDQ()}
                disabled={reConfirmInput !== "YES"}
                className="default-button mt-20"
              >
                CONFIRM
              </button>
            </div>
          </div>
        </>
      ) : confirmed ? (
        <>
          <div className="erp-finished">
            <div className="erpc-msg">
              <p>
                <strong>{selPlayer.name}</strong> was successfully{" "}
                {type === "wd" ? "withdrawn" : "disqualified"} from this event
              </p>
              {/* <div className="flex-center">
                <button onClick={reset} className="default-button">
                  More DQ
                </button>
              </div> */}
            </div>
          </div>
        </>
      ) : confirming ? (
        <>
          <div className="erp-confirm mt-20 mb-20">
            <div className="erpc-msg">
              <p>
                Are you sure you want to{" "}
                {type === "wd" ? "withdraw" : "disqualify"}{" "}
                <strong>{selPlayer.name}</strong> from this event?
              </p>
            </div>
            <div className="im-msg mb-10">
              <p>Type "REMOVE" below to confirm</p>
            </div>
            <input
              type="text"
              value={confirmInput}
              placeholder="Start typing..."
              onChange={(e) => setConfirmInput(e.target.value)}
              className="default-input mb-20"
            />
            <div className="flex-center">
              <button
                onClick={() => saveTrigger()}
                disabled={confirmInput !== "REMOVE"}
                className="delete-button mt-20"
              >
                CONFIRM
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <RadioGroupTwo
            active={active}
            setActive={setActive}
            buttons={[
              {
                text: type === "wd" ? "Withdraw" : "Disqualify",
                value: "dq",
              },
              {
                text: type === "wd" ? "Rejoin" : "Requalify",
                value: "rq",
              },
            ]}
          />

          {active === "dq" ? (
            <>
              <div
                className={classNames("neh-box", {
                  "mt-20": !isMobile,
                })}
              >
                <h3 className="ne-header msb">Available Players</h3>
              </div>

              <div className="input-group pl-20">
                <p>Search for player</p>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>
              <div className="pl-20 mt-20">
                <button
                  disabled={selPlayer.playerID === undefined}
                  onClick={() => {
                    setConfirming(true);
                  }}
                  className="default-button"
                >
                  Select
                </button>
              </div>
              <div className="pd-20 flex flex-wrap mb-40 pdq-ps">
                {players.map((player, i) => {
                  if (search !== "") {
                    const sl = search.toLowerCase();
                    const pl = player.name.toLowerCase();

                    if (!pl.includes(sl)) {
                      return null;
                    }
                  }

                  if (player.dq || player.wd) {
                    return null;
                  }

                  return (
                    <PlayerItem
                      hideImage
                      hideIcons
                      noLink
                      key={player.playerID}
                      name={player.name}
                      hideHCP
                      showBg={selPlayer.playerID === player.playerID}
                      showProfile={() => setSelPlayer(player)}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="neh-box">
                <h3 className="ne-header msb">
                  {type === "wd" ? "Withdrawn Players" : "Disqualified Players"}
                </h3>
              </div>
              <div className="pd-20 flex flex-wrap mb-40 pdq-ps">
                {dqd.map((player, i) => {
                  //   if (search !== "") {
                  //     const sl = search.toLowerCase();
                  //     const pl = player.name.toLowerCase();

                  //     if (!pl.includes(sl)) {
                  //       return null;
                  //     }
                  //   }

                  return (
                    <PlayerItem
                      hideImage
                      hideIcons
                      noLink
                      key={player.playerID}
                      name={player.name}
                      hideHCP
                      showBg={selDQPlayer.playerID === player.playerID}
                      showProfile={() => {
                        setSelDQPlayer(player);
                        update();
                      }}
                    />
                  );
                })}
              </div>

              <div className="flex mb-40 ml-20">
                <button
                  disabled={selDQPlayer.playerID === undefined}
                  onClick={() => setConfirmingRe(true)}
                  className="default-button"
                >
                  {type === "wd" ? "Rejoin" : "Undisqualify"}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PlayerDQ;
