import React from "react";
import "./QueryItem.css";

function QueryItem({ date, text, status, type }) {
  return (
    <div className="query-item">
      <h2>{date}</h2>
      <h3>Status: {status}</h3>
      <h5>QueryID: {type}</h5>
      <p>{text}</p>
    </div>
  );
}

export default QueryItem;
