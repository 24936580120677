import React from "react";
import ArrowBack from "@mui/icons-material/ArrowBack";

function BackRow({ action = () => {} }) {
  return (
    <div onClick={action} className="back-row">
      <ArrowBack />
      <p>Back</p>
    </div>
  );
}

export default BackRow;
