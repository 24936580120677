import { Skeleton } from "@mui/material";
import React from "react";
import "./Loaders.css";
function PlayerLoader() {
  return (
    <div className="player-loader">
      <div className="pi-left">
        <Skeleton variant="circular" width={40} height={40} />
      </div>
      <div className="pi-right">
        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      </div>
    </div>
  );
}

export default PlayerLoader;
