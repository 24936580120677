import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import "./Cups.css";
// Icons

// Components
import Pill from "../../components/Buttons/Pill";
import FixturePreview from "../../components/display/FixturePreview/FixturePreview";
import CupCreator from "../../components/CupCreator/CupCreator";
import CupHome from "../../components/CupCreator/CupHome";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
function Cups() {
  const { currentUser } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [cups, setCups] = useState([]);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  useEffect(() => {
    if (currentUser) {
      loadCups(currentUser.uid);
    }
  }, [currentUser]);

  async function loadCups(uid) {
    setLoading(true);
    const now = new Date();
    const cups = await db
      .collection("cups")
      .where("admins", "array-contains", uid)
      .orderBy("cupDate", "desc")
      // .where("cupDate", ">", now)
      //   .where("cancelled", "==", false)
      .get();
    const arr = [];
    for (let i = 0; i < cups.docs.length; i++) {
      const cupDoc = cups.docs[i];
      const cupID = cupDoc.id;
      const cup = cupDoc.data();
      const {
        imageLink,
        taskID,
        cupName,
        cupDate,
        courseName,
        courseID,
        playerIDs,
        teams,
        rules,
        description,
        matchups,
        teeMap,
        nassau,
        rounds,
      } = cup;
      const dateString = new Date(cupDate.toDate()).toLocaleDateString(
        "en-us",
        { dateStyle: "full" }
      );

      arr.push({
        cupID,
        rounds,
        imageLink,
        date: cupDate,
        eventName: cupName,
        dateString,
        courseName,
        courseID,
        playerIDs,
        teams,
        rules,
        description,
        matchups,
        teeMap,
        taskID,
        nassau,
        cup: true,
      });
    }

    // console.log(arr)

    setCups(arr);
    setLoading(false);
  }
  return (
    <div className="cups-page">
      {show ? (
        toShow
      ) : (
        <>
          {loading && <BackdropLoader />}
          <PageHeader text="Cups" />
          <div className="sub-header">
            <h3>Quick Links</h3>
          </div>
          <div className="quick-links">
            <Pill
              text={"New Cup"}
              onClick={() => {
                setToShow(
                  <CupCreator
                    goBack={() => setShow(false)}
                    edit={false}
                    reload={loadCups}
                  />
                );
                setShow(true);
              }}
            />
          </div>
          <div className="cp-cups">
            {cups.map((cup, index) => {
              return (
                <FixturePreview
                  key={cup.cupID}
                  cup
                  hidePerson
                  hideRounds
                  event={cup}
                  editAction={() => {
                    setToShow(
                      <CupHome
                        goBack={() => setShow(false)}
                        cupID={cup.cupID}
                      />
                    );
                    setShow(true);
                  }}
                />
              );
            })}
            {cups.length === 0 && !loading && (
              <div className="no-msg">
                <p>No cups to display</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Cups;
