import React, { useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import FixturePreview from "../../components/display/FixturePreview/FixturePreview";
import { Link } from "react-router-dom";

function MobileCalendar({ events = [] }) {
  const [value, onChange] = useState(new Date());
  const [showNo, setShowNo] = useState(true);
  const [update, setUpdate] = useState(0);
  return (
    <div className="mob-cal">
      <div className="mcc-box">
        <Calendar
          onChange={(e) => {
            onChange(e);
            let found = false;
            for (let i = 0; i < events.length; i++) {
              const calMoment = moment(e);
              const evtMoment = moment(events[i].eventDate);
              if (calMoment.isSame(evtMoment, "date")) {
                found = true;
              }
            }

            setShowNo(!found);
            setUpdate((current) => current + 1);
          }}
          value={value}
          tileContent={({ activeStartDate, date, view }) => {
            let toReturn = "";
            const calDate = moment(date);
            for (let i = 0; i < events.length; i++) {
              const evtDate = moment(events[i].eventDate);
              // console.log(evtDate)
              if (calDate.isSame(evtDate, "date")) {
                toReturn = "";
              }
            }

            return toReturn;
          }}
          tileClassName={({ activeStartDate, date, view }) => {
            let toReturn = "";
            const calDate = moment(date);
            for (let i = 0; i < events.length; i++) {
              const evtDate = moment(events[i].eventDate);
              if (calDate.isSame(evtDate, "date")) {
                toReturn = "rc-ed";
              }
            }
            return toReturn;
          }}
        />
      </div>
      <div className="mc-evts">
        {events.map((evt, index) => {
          console.log(evt);
          const evtMoment = moment(evt.eventDate);
          const calMoment = moment(value);
          if (evtMoment.isSame(calMoment, "date")) {
            return <FixturePreview key={evt.eventID} event={evt} hideIcons />;
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}

export default MobileCalendar;
