/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./LeagueCreator.css";
import { admin, db, storage } from "../../../firebase";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import Pill from "../../Buttons/Pill";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useDropzone } from "react-dropzone";
import { Steps } from "intro.js-react";
import InfoIcon from "@mui/icons-material/Info";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { Dialog } from "@mui/material";

import Switch from "react-ios-switch/lib/Switch";

import { Country, City, State } from "country-state-city";

import { Snackbar, Backdrop } from "@mui/material";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import { useAuth } from "../../../contexts/AuthContext";

import PlayerItem from "../../display/PlayerItem/PlayerItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ImageSelector from "../../Events/ImageSelector/ImageSelector";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Close } from "@mui/icons-material";
import PageHeader from "../../display/Rows/PageHeader";

const GradientRemoveCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorss" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <RemoveCircleIcon
      onClick={onClick}
      className=""
      sx={{ fill: "url(#linearColorss)" }}
    />
  </>
);

const GradientInfoIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <InfoIcon
      onClick={onClick}
      className={`dg-icon-bg ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function LeagueCreator({
  defaultLeagueID = "",
  edit = false,
  goBack,
  reload = () => {},
  defaultClubID = "",
  defaultCourseID = "",
  hideBack = false,
}) {
  const typeOptions = [
    {
      label: "Normal",
      value: "normal",
    },
    { label: "Eclectic", value: "eclectic" },
  ];
  const navigate = useNavigate();
  const [showCrop, setShowCrop] = useState(false);

  const [image, setImage] = useState("");
  const [generalOpen, setGeneralOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [clubID, setClubID] = useState(defaultClubID ?? "");
  const [clubOptions, setClubOptions] = useState([]);

  const [leagueType, setLeagueType] = useState("normal");

  const picScrollRef = useRef();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    setShowCrop(true);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState("");
  async function cropImage() {
    const image = new Image();
    image.src = imgageURL;
    const crop = fc;
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }
  const publicOptions = [
    {
      label: "Public",
      value: "public",
    },
    {
      label: "Private",
      value: "private",
    },
  ];
  const [progress, setProgress] = useState(0);
  const [showSnack, setShowSnack] = useState(false);
  const { currentUser, userDetails } = useAuth();

  const [interviews, setInterviews] = useState(true);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [loadingText, setLoadingText] = useState("Loading...");
  const [players, setPlayers] = useState([]);
  const [adminOptions, setAdminOptions] = useState([]);
  const [joinCode, setJoinCode] = useState("");

  const [type, setType] = useState("private");

  const [teamPoints, setTeamPoints] = useState(false);

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [invitedPlayers, setInvitedPlayers] = useState([]);
  const [inviteable, setInviteable] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [update, setUpdate] = useState(0);
  const [imgageURL, setImageURL] = useState("");

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [selCountry, setSelCountry] = useState("");
  const [selCity, setSelCity] = useState("");
  const [selState, setSelState] = useState("");

  const [nameStore, setNameStore] = useState("");
  const [shortNameStore, setShortNameStore] = useState("");
  const [descStore, setDescStore] = useState("");

  const [inviteOnly, setInviteOnly] = useState(true);
  const [defaultEvents, setDefaultEvents] = useState(0);

  const [seasonsExist, setSeasonsExist] = useState(false);

  const [hidePoints, setHidePoints] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});

  const nameRef = useRef();
  const shortNameRef = useRef();
  const descRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();

  useEffect(() => {
    console.log(defaultClubID);
    if (currentUser) {
      // console.log(currentUser);
      if (!edit) {
        // loadPlayers({
        //   defaultPlayers: [],
        //   defaultAdmins: [],
        //   defaultInvited: [],
        // });
      } else {
        loadDetails();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (userDetails && !edit) {
      sortLocation({});
    }
    if (userDetails) {
      loadClubOptions();
    }
  }, [userDetails]);

  async function loadClubOptions() {
    const clubDocs = await db
      .collection("clubs")
      .where("adminIDs", "array-contains", currentUser.uid)
      .get();

    const clubArr = [];

    for (let i = 0; i < clubDocs.docs.length; i++) {
      const clubDoc = clubDocs.docs[i];
      const clubID = clubDoc.id;
      const club = clubDoc.data();
      const { clubName } = club;
      clubArr.push({
        clubName,
        clubID,
        value: clubID,
        label: clubName,
      });
    }
    setClubOptions(clubArr);
    setLoading(false);
  }

  async function loadDetails() {
    const leagueDoc = await db.collection("leagues").doc(defaultLeagueID).get();
    const league = leagueDoc.data();
    const {
      leagueName,
      playerIDs,
      description,
      invitedIDs,
      admins,
      city,
      country,
      state,
      dateCreated,
      imageLink,
      interviews,
      inviteOnly,
      deleted,
      joinCode,
      shortName,
      eventIDs,
      type,
    } = league;
    setHidePoints(league.hidePoints ?? false);
    loadPlayers({
      defaultPlayers: playerIDs,
      defaultInvited: invitedIDs,
      defaultAdmins: admins,
    });
    sortLocation({
      defaultCity: city,
      defaultCountry: country,
      defaultState: state,
    });
    const seasonIDs = league.seasonIDS ?? [];
    setSeasonsExist(seasonIDs.length > 0);
    setLeagueType(league.leagueType ?? "normal");
    setClubID(league.clubID ?? "");
    setJoinCode(joinCode);
    setDescStore(description);
    setImageURL(imageLink);
    setInterviews(interviews);
    setInviteOnly(inviteOnly);
    setDefaultEvents(eventIDs.length);
    setType(type);
    setNameStore(leagueName);
    setShortNameStore(shortName);
    setLoading(false);
  }

  function sortLocation({
    defaultCity = "",
    defaultCountry = "",
    defaultState = "",
  }) {
    console.log(userDetails);
    const userCity = edit ? defaultCity : userDetails.city;
    const userCountry = edit ? defaultCountry : userDetails.country;
    const userState = edit ? defaultState : userDetails.state;
    // console.log(userCity, userCountry, userState);
    let stateCode = "";

    // Country
    const allCunts = Country.getAllCountries().map((country) => {
      const item = {
        name: country.name,
        value: country.name,
        code: country.isoCode,
      };
      return item;
    });
    setCountries(allCunts);
    setSelCountry(userCountry);

    // State
    const allStates = State.getStatesOfCountry(userCountry);
    setStates(allStates);
    setSelState(userState);

    for (let i = 0; i < allStates.length; i++) {
      if (allStates[i].name === userState) {
        stateCode = allStates[i].isoCode;
      }
    }

    // City
    const cities = City.getCitiesOfState(userCountry, stateCode);
    // console.log(cities);
    setCities(cities);
    setSelCity(userCity);
  }

  function loadStates(cunt) {
    const states = State.getStatesOfCountry(cunt);
    const countryDeets = Country.getCountryByCode(cunt);
    setSelCountry(countryDeets.name);
    setStates(states);
  }

  async function loadCities(state, count = "") {
    // console.log(selCountry);
    let toUse = selCountry;
    if (count !== "") {
      toUse = count;
    }
    // console.log(toUse);
    const test = State.getStatesOfCountry(toUse);
    // console.log(test.length);
    let sc = "";
    let cc = "";
    for (let i = 0; i < test.length; i++) {
      if (test[i].name === state) {
        sc = test[i].isoCode;
        cc = test[i].countryCode;
      }
    }
    console.log(sc, cc);
    const cities = City.getCitiesOfState(cc, sc);

    const newArr = cities.map((city) => {
      const item = {
        name: city.name,
        value: city.name,
      };
      return item;
    });
    console.log(selState);
    console.log(states);
    // setSelState(sc);

    setUpdate((current) => current + 1);
    setCities(newArr);
  }

  function makeID() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 12; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log(result);
    return setJoinCode(result);
  }

  async function loadPlayers({
    defaultPlayers = [],
    defaultAdmins = [],
    defaultInvited = [],
  }) {
    // console.log(userDetails);
    const friends = [];
    setLoading(true);
    const playerArr = [];
    const selArr = [];
    const adminArr = [];
    const selAdminArr = [];
    const invitedArr = [];
    const toInviteArr = [];
    // const players = await db
    //   .collection("users")
    //   .orderBy("lastName", "asc")
    //   .get();

    if (edit) {
      selArr.push({
        label: `${userDetails.firstName} ${userDetails.lastName}`,
        value: currentUser.uid,
        handicap: userDetails.handicap,
        profileImage: userDetails.profileImage,
      });
      selAdminArr.push({
        label: `${userDetails.firstName} ${userDetails.lastName}`,
        value: currentUser.uid,
        handicap: userDetails.handicap,
        profileImage: userDetails.profileImage,
      });
    }

    for (let i = 0; i < defaultPlayers.length; i++) {
      if (
        !friends.includes(defaultPlayers[i]) &&
        defaultPlayers[i] !== currentUser.uid
      ) {
        friends.push(defaultPlayers[i]);
      }
    }
    const refs = friends.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);

    for (let i = 0; i < playerDocs.length; i++) {
      setProgress((i / friends.length) * 100);
      setUpdate((current) => current + 1);
      const playerID = playerDocs[i].id;

      const playerDoc = playerDocs[i];

      const player = playerDoc.data();
      if (player === undefined) {
        continue;
      }
      const playerName = `${player.firstName} ${player.lastName}`;
      const { handicap, profileImage, status } = player;
      if (status === "disabled") {
        continue;
      }
      const settings = player.settings ?? "";
      // If player accepts invites from friends onbly
      // if (settings !== '') {
      //     if (settings.friendsOnly === true && !friendIDs.includes(currentUser.uid)) {
      //         continue;
      //     }
      // }

      if (!edit) {
        playerArr.push({
          label: playerName,
          value: playerID,
          handicap,
          profileImage,
        });
      }

      if (edit) {
        // Admins
        if (
          !defaultAdmins.includes(playerID) &&
          playerID !== currentUser.uid &&
          defaultPlayers.includes(playerID)
        ) {
          adminArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
          });
        }
        if (defaultAdmins.includes(playerID)) {
          selAdminArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
          });
        }

        // Invited
        if (
          defaultInvited.includes(playerID) &&
          !defaultPlayers.includes(playerID)
        ) {
          invitedArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
          });
        }

        if (
          !defaultInvited.includes(playerID) &&
          !defaultPlayers.includes(playerID)
        ) {
          toInviteArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
          });
        }

        // Members
        if (defaultPlayers.includes(playerID)) {
          // console.log("herrre");
          selArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
          });
        } else {
          playerArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
          });
        }
      }
    }

    if (edit) {
      setSelectedPlayers(selArr);
    }
    setAdminOptions(adminArr);
    setAdmins(selAdminArr);
    setPlayers(playerArr);
    setInvitedPlayers(invitedArr);
    setInviteable(toInviteArr);
    setLoading(false);
  }

  function validate() {
    setError("");
    // Name
    if (nameRef.current.value === "") {
      setError("Please enter a name for your league");
      return false;
    }
    if (shortNameRef.current.value === "") {
      setError("Please enter a short name for your league");
      return false;
    }
    // Description
    // if (descRef.current.value === "") {
    //   setError("Please enter a description for your league");
    //   return false;
    // }
    // City
    if (cityRef.current.value === "") {
      setError("Please enter a city for your league");
      return false;
    }
    // Country
    if (countryRef.current.value === "") {
      setError("Please enter a country for your league");
      return false;
    }
    // Type
    if (type === "") {
      setError("Please choose whether this league is public or private");
      return false;
    }
    // Image
    if (image !== "") {
      if (image.type) {
        if (!image.type.includes("image")) {
          setError("Please make sure to only upload images");
          return false;
        }
      }
    }
  }

  async function saveLeague() {
    // return console.log(image);
    setLoading(true);
    const playerIDs = selectedPlayers.map((item) => item.value);
    const adminIDs = [currentUser.uid];

    for (let i = 0; i < admins.length; i++) {
      adminIDs.push(admins[i].value);
    }

    if (validate() === false) {
      return;
    }

    let pointsTable = [
      {
        playerID: currentUser.uid,
        userName: `${userDetails.firstName} ${userDetails.lastName}`,
        handicap: userDetails.handicap,
        wins: 0,
        totalRounds: 0,
        rank: 1,
        points: 0,
      },
    ];

    if (leagueType === "eclectic") {
      pointsTable = [
        {
          netHoles: [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          grossHoles: [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          pointHoles: [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          net: 0,
          gross: 0,
          stableford: 0,
          totalRounds: 0,
          wins: 0,
          played: 0,
          rank: 1,
          playerID: currentUser.uid,
          lastName: userDetails.lastName,
          firstName: userDetails.firstName,
          userName: `${userDetails.firstName} ${userDetails.lastName}`,
          handicap: userDetails.handicap,
        },
      ];
    }

    const leagueName = nameRef.current.value;
    const shortName = shortNameRef.current.value;
    const description = descRef.current.value;

    setLoadingText("Creating league...");
    setProgress(0);
    setLoading(true);

    try {
      const league = await db.collection("leagues").add({
        records: [],
        leagueName,
        shortName,
        hidePoints,
        clubID,
        description,
        inviteOnly,
        city: selCity,
        country: selCountry,
        state: selState,
        playerIDs: [currentUser.uid],
        admins: adminIDs,
        activeEventID: "",
        eventIDs: [],
        roundIDs: [],
        pointsTable,
        imageLink: "",
        invitedIDs: playerIDs,
        joinCode,
        type,
        seasonIDs: [],
        leagueType,
        seasonID: "",
        seasonName: "",
        interviews,
        dateCreated: new Date(),
        teamPoints,
      });
      // .catch((err) => console.log(err));
      console.log(1);
      setProgress((current) => current + 30);
      await db
        .collection("users")
        .doc(currentUser.uid)
        .update({
          leagueIDs: admin.firestore.FieldValue.arrayUnion(league.id),
        });
      setProgress((current) => current + 10);
      if (image !== "") {
        setLoadingText("Uploading image...");
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "leagueImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${league.id}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                await db.collection("leagues").doc(league.id).update({
                  imageLink: url,
                });
                setProgress((current) => current + 20);
                reload();
                goBack();
                navigate(`/leagues/${league.id}`);
              });
            });
          });
        } else {
          const task = storage
            .ref()
            .child(`${league.id}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            setProgress((current) => current + 40);
            await snapshot.ref.getDownloadURL().then(async (url) => {
              await db.collection("leagues").doc(league.id).update({
                imageLink: url,
              });
              reload();
              goBack();
              navigate(`/leagues/${league.id}`);
              setProgress((current) => current + 20);
            });
          });
        }
      } else {
        setProgress(100);
        reload();
        goBack();
        navigate(`/leagues/${league.id}`);
      }
    } catch (err) {
      console.log(err);
      return setError("Could not save your league, please try again");
    }
    setLoading(false);
  }

  async function saveChanges() {
    setLoadingText("Saving changes...");
    const leagueName = nameRef.current.value;
    const shortName = shortNameRef.current.value;
    const description = descRef.current.value;
    setLoading(true);

    // Check for new invitees
    const invitedIDs = invitedPlayers.map((item) => item.value);
    const playerIDs = selectedPlayers.map((item) => item.value);
    const adminIDs = admins.map((item) => item.value);
    if (!adminIDs.includes(currentUser.uid)) {
      adminIDs.push(currentUser.uid);
    }
    // console.log(playerIDs, adminIDs);

    try {
      await db.collection("leagues").doc(defaultLeagueID).update({
        leagueName,
        shortName,
        hidePoints,
        clubID,
        description,
        city: selCity,
        country: selCountry,
        state: selState,
        joinCode,
        invitedIDs,
        playerIDs,
        admins: adminIDs,
        inviteOnly,
        type,
        interviews,
        leagueType,
        teamPoints,
      });
      if (image !== "") {
        setLoadingText("Uploading image...");
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "leagueImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${defaultLeagueID}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("leagues").doc(defaultLeagueID).update({
                  imageLink: url,
                });
                setProgress((current) => current + 20);
              });
            });
          });
        } else {
          const task = storage
            .ref()
            .child(`${defaultLeagueID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("leagues").doc(defaultLeagueID).update({
                imageLink: url,
              });
            });
          });
        }
      }
      reload();
      goBack();
    } catch (err) {
      return setError("Could not save your league, please try again");
    }
  }

  function selectAll() {
    setSelectedPlayers((current) => [...current, ...players]);
    setPlayers([]);
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);
  const steps = [
    {
      element: "gnwrkbej",
      intro:
        "Let's get your first league up and running. The process is fairly straightforward, but here are a few tips to help you out.",
    },
    {
      element: ".join-hint",
      intro:
        "Generating a joining code allows other users to join this league simply by using that code",
    },
    {
      element: ".pp-hint",
      intro:
        "Making your league public allows any user on the app to join, where as a private league is restricted to invitees and those with a joining code",
    },
    {
      element: ".admin-hint",
      intro:
        "Any user with admin rights can add, edit and delete events and/or the entire league - make sure you would trust this person to drive your grandma home",
    },
  ];

  async function deleteLeague() {
    await db.collection("leagues").doc(defaultLeagueID).update({
      deleted: true,
    });
    reload();

    goBack();
  }

  return (
    <div className="league-creator event-creator onboarding">
      {showCrop && (
        <Backdrop className="crop-drop" open>
          <div className="crop-co">
            <div className="cd-close">
              <Close onClick={() => setShowCrop(false)} />
            </div>
            <div className="crop-box">
              <Cropper
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                image={imgageURL}
              />
            </div>
            <div className="flex-center crop-but">
              <button
                onClick={() => {
                  cropImage();
                  setShowCrop(false);
                }}
                className="default-button"
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      <Snackbar
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}
        message="Copied to clipboard"
      />
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="info-message">
          <GradientInfoIcon className="big-info-icon" />
          <p>{dialog}</p>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}

      {loading ? (
        <div className="loading-container elc">
          {/* <BallTriangle color="#21c17c" height={80} width={80} />
          <p className="mb-20">{loadingText}</p> */}
          <Box sx={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </div>
      ) : showImages ? (
        <ImageSelector
          goBack={() => {
            setShowImages(false);
            setTimeout(
              () => picScrollRef.current.scrollIntoView({ behavior: "smooth" }),
              100
            );
          }}
          setSelectedImage={(e) => {
            setImageURL(e);
            setImage(e);
          }}
          selectedImage={image}
          type="league"
        />
      ) : (
        <>
          {!hideBack && (
            <div onClick={goBack} className="back-row">
              <ArrowBack className="icon-cursor" />
              <p>Back</p>
            </div>
          )}

          <PageHeader text={edit ? "Edit League" : "Create League"} />

          <div className="sub-header">
            <h2 className="ne-header">General</h2>
          </div>

          <div className="ec-box">
            {!seasonsExist && (
              <div className="input-group league-hint">
                <div className="ig-header">
                  <p>League Type</p>
                  <GradientInfoIcon
                    className="info-icon"
                    onClick={() => {
                      setDialog([
                        "Normal leagues run on a points system, eclectic leagues track your best 18 holes over the season",
                      ]);
                      setShowDialog(true);
                    }}
                  />
                </div>

                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={typeOptions}
                  value={leagueType}
                  onChange={(e) => {
                    console.log(e.value);
                    setLeagueType(e.value);
                  }}
                />
              </div>
            )}
            {clubOptions.length > 1 && (
              <div className="input-group league-hint ">
                <div className="ig-header">
                  <p>Club</p>
                </div>

                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={clubOptions}
                  value={clubID}
                  onChange={(e) => {
                    console.log(e.value);
                    setClubID(e.value);
                  }}
                />
              </div>
            )}
            <div className="input-group ">
              <p>League Name</p>
              <input
                // className="default-inupt"
                placeholder="Enter league name..."
                ref={nameRef}
                type={"text"}
                value={nameStore}
                onChange={(e) => setNameStore(e.target.value)}
              />
            </div>
            <div className="input-group">
              <p>League Short Name</p>
              <input
                placeholder="E.g. UGA Tour"
                ref={shortNameRef}
                type={"text"}
                value={shortNameStore}
                onChange={(e) => setShortNameStore(e.target.value)}
              />
            </div>
            <div className="input-group select-group">
              <p>Country</p>
              <select
                className="default-input default-select"
                value={selCountry}
                ref={countryRef}
                onChange={(e) => {
                  loadStates(countryRef.current.value);
                  setSelCountry(countryRef.current.value);
                }}
              >
                <option value=""></option>
                {countries.map((country) => {
                  return (
                    <option
                      key={country.code}
                      selected={country.code === selCountry}
                      value={country.code}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-group select-group">
              <p>State / Province</p>
              <select
                className="default-input default-select"
                value={selState}
                disabled={selCountry === ""}
                ref={stateRef}
                onChange={(e) => {
                  setSelState(stateRef.current.value);
                  loadCities(stateRef.current.value);
                }}
              >
                <option value=""></option>

                {states.map((state) => {
                  return (
                    <option
                      selected={state.name === selState}
                      value={state.code}
                    >
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-group select-group mb-20">
              <p>City</p>
              <select
                className="default-input default-select"
                value={selCity}
                disabled={selState === ""}
                ref={cityRef}
                onChange={(e) => {
                  setSelCity(cityRef.current.value);
                }}
              >
                <option value=""></option>

                {cities.map((city) => {
                  return (
                    <option selected={city.name === selCity} value={city.name}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="sub-header">
            <h2 className="ne-header">Details</h2>
          </div>
          <div className="ec-box">
            <div className="input-group">
              <div className="ig-header">
                <p>Description</p>
                <GradientInfoIcon
                  className="info-icon"
                  onClick={() => {
                    setDialog([
                      "Browsers can be a bit funny with lines - please type /n to start a new paragraph.",
                    ]);
                    setShowDialog(true);
                  }}
                />
              </div>{" "}
              <textarea
                placeholder="Enter league description..."
                defaultValue={descStore}
                rows={6}
                onChange={(e) => setDescStore(e.target.value)}
                ref={descRef}
              />
            </div>
            <div ref={picScrollRef} className="input-group mb-20">
              <p>League Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imgageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imgageURL} />
                      </div>
                    )}
                    {imgageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                  <div className="dz-or">
                    <p className="">Or</p>
                  </div>
                </div>
                <Pill
                  text={"Choose Preset Image"}
                  onClick={() => setShowImages(true)}
                />
              </div>
            </div>
          </div>

          <div className="sub-header">
            <h2 className="ne-header">Admin</h2>
          </div>
          <div className="ec-box">
            <div className="input-group nassau-group ">
              <div className="ig-header">
                <p>Enable Interviews</p>
                <GradientInfoIcon
                  className="info-icon"
                  onClick={() => {
                    setDialog(
                      "Enabling this will create interviews with players in your league before and after events."
                    );
                    setShowDialog(true);
                  }}
                />
              </div>{" "}
              <Switch
                onColor="#1e7a69"
                checked={interviews}
                className={
                  interviews ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => setInterviews((current) => !current)}
              />
            </div>
            <div className="input-group nassau-group ">
              <div className="ig-header">
                <p>Hide Points</p>
                <GradientInfoIcon
                  className="info-icon"
                  onClick={() => {
                    setDialog("Enabling this will hide points tables.");
                    setShowDialog(true);
                  }}
                />
              </div>
              <Switch
                onColor="#1e7a69"
                checked={hidePoints}
                className={
                  hidePoints ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => setHidePoints((current) => !current)}
              />
            </div>
            <div className="input-group nassau-group ">
              <div className="ig-header">
                <p>Enable Teams</p>
                <GradientInfoIcon
                  className="info-icon"
                  onClick={() => {
                    setDialog(
                      "Enabling this will allow you to create a separate team leaderboard."
                    );
                    setShowDialog(true);
                  }}
                />
              </div>{" "}
              <Switch
                onColor="#1e7a69"
                checked={teamPoints}
                className={
                  teamPoints ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => setTeamPoints((current) => !current)}
              />
            </div>

            <div className="input-group nassau-group ">
              <div className="ig-header">
                <p>Invite Only</p>
                <GradientInfoIcon
                  className="info-icon"
                  onClick={() => {
                    setDialog(
                      "Choose whether other players can join without being invited"
                    );
                    setShowDialog(true);
                  }}
                />
              </div>{" "}
              <Switch
                onColor="#1e7a69"
                checked={inviteOnly}
                className={
                  inviteOnly ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => setInviteOnly((current) => !current)}
              />
            </div>

            <div className="input-group pp-hint">
              <div className="ig-header">
                <p>Public / Private</p>

                <GradientInfoIcon
                  className="info-icon"
                  onClick={() => {
                    setDialog(
                      "Choose whether your league shows up in searches"
                    );
                    setShowDialog(true);
                  }}
                />
              </div>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={type}
                options={publicOptions}
                onChange={(e) => {
                  setType(e.value);
                }}
              />
            </div>
            <div className="input-group join-hint">
              <div className="ig-header">
                <p>Joining Code</p>
                <ContentCopyIcon
                  onClick={() => {
                    navigator.clipboard.writeText(joinCode);
                    setShowSnack(true);
                  }}
                  className="mb-10 info-icon"
                />
                <button onClick={makeID}>Generate</button>
              </div>
              <input
                onChange={(e) => setJoinCode(e.target.value)}
                type={"text"}
                value={joinCode}
                placeholder="Enter join code..."
              />
            </div>
          </div>

          {false && (
            <div className="admin-group pl-20">
              {edit && (
                <div className="input-group admin-hint mb-20">
                  <p>Add Admins</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={adminOptions}
                    onChange={(e) => {
                      const player = adminOptions.find(
                        (obj) => obj.value === e.value
                      );

                      setAdmins((current) => [...current, player]);
                      setAdminOptions((current) =>
                        current.filter((obj) => obj.value !== e.value)
                      );
                    }}
                  />
                </div>
              )}
              {edit && (
                <div className="input-group">
                  <p className="bot-grad">Current Admins</p>
                </div>
              )}
              {admins.length > 0 && (
                <div className="part-players-box league-admins">
                  {admins.map((player) => (
                    <PlayerItem
                      hcp={player.handicap}
                      hideMessage={player.value === currentUser.uid}
                      hideRemove={player.value === currentUser.uid}
                      name={player.label}
                      img={player.profileImage}
                      hideEdit
                      showAdd
                      playerID={player.playerID}
                      // noLink
                      hideProfile
                      removeAction={() => {
                        setAdmins((current) =>
                          current.filter((item) => item.value !== player.value)
                        );
                        setAdminOptions((current) => [...current, player]);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {false && (
            <div className="sub-header">
              <h2 className="ne-header">Players</h2>
            </div>
          )}

          {false && (
            <div className="admin-group lc-invited">
              {!edit && (
                <>
                  <div className="input-group pl-20">
                    <div className="ig-header">
                      <p className="">Invite Players</p>
                      <button onClick={selectAll}>Select All</button>
                    </div>
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      options={players}
                      onChange={(e) => {
                        const player = players.find(
                          (obj) => obj.value === e.value
                        );

                        setSelectedPlayers((current) => [...current, player]);
                        setPlayers((current) =>
                          current.filter((obj) => obj.value !== e.value)
                        );
                      }}
                    />
                  </div>
                  <div className="part-players-box pl-20">
                    {selectedPlayers.map((player) => (
                      <PlayerItem
                        hcp={player.handicap}
                        hideEdit
                        name={player.label}
                        img={player.profileImage}
                        removeAction={() => {
                          setSelectedPlayers((current) =>
                            current.filter(
                              (item) => item.value !== player.value
                            )
                          );
                          setPlayers((current) => [...current, player]);
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}

          {edit && false && (
            <>
              <div className="input-group pl-20">
                <p className="">Invite Players</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={inviteable}
                  onChange={(e) => {
                    const player = inviteable.find(
                      (obj) => obj.value === e.value
                    );

                    setInvitedPlayers((current) => [...current, player]);
                    setInviteable((current) =>
                      current.filter((obj) => obj.value !== e.value)
                    );
                  }}
                />
              </div>
              <div className="part-players-box">
                {invitedPlayers.map((player) => (
                  <div className="ec-pi">
                    <PlayerItem
                      hcp={player.handicap}
                      name={player.label}
                      img={player.profileImage}
                    />
                    <GradientRemoveCircleIcon
                      onClick={() => {
                        setInvitedPlayers((current) =>
                          current.filter((item) => item.value !== player.value)
                        );
                        setInviteable((current) => [...current, player]);
                      }}
                      className="rci"
                    />
                  </div>
                ))}
              </div>
              {/* <div className="input-group">
                <p className="bot-grad">Current Members</p>
              </div>
              <div className="members-box">
                {selectedPlayers.map((player) => (
                  <div className="ec-pi">
                    <PlayerItem
                      hcp={player.handicap}
                      name={player.label}
                      img={player.profileImage}
                    />
                    <GradientRemoveCircleIcon
                      onClick={() => {
                        setSelectedPlayers((current) =>
                          current.filter((item) => item.value !== player.value)
                        );
                        setPlayers((current) => [...current, player]);
                        setInviteable((current) => [...current, player]);
                      }}
                      className="rci"
                    />
                  </div>
                ))}
              </div> */}
            </>
          )}

          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}

          <div
            style={{ marginBottom: "0px" }}
            className="flex-center mt-40 mb-20"
          >
            {!edit ? (
              <button className="default-button" onClick={saveLeague}>
                Create League
              </button>
            ) : (
              <button className="default-button" onClick={saveChanges}>
                Save Changes
              </button>
            )}
          </div>
          {edit && defaultEvents === 0 && (
            <div style={{ marginTop: "0px" }} className="flex-center mb-40">
              <button
                onClick={deleteLeague}
                style={{ marginTop: "0px !important" }}
                className="delete-button"
              >
                Delete
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LeagueCreator;
