import React, { useEffect, useState } from "react";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { db, admin } from "../../../firebase";

// Icons

function BBSResult({
  goBack = () => {},
  defaultMatch = {},
  eventID = "",
  roundID = "",
}) {
  const callBoard = admin.functions().httpsCallable("bbsTLCall");

  // State
  const [loading, setLoading] = useState(false);
  const [match, setMatch] = useState(defaultMatch);
  const [error, setError] = useState("");

  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  useEffect(() => {
    console.log(defaultMatch);
    console.log(roundID);
  }, []);

  async function saveMatch() {
    setLoading(true);

    try {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();

      const { matches } = round;

      for (let i = 0; i < matches.length; i++) {
        if (matches[i].matchID === match.matchID) {
          console.log("FOUD");
          matches[i].teamOnePoints = match.teamOnePoints;
          matches[i].teamTwoPoints = match.teamTwoPoints;
          matches[i].thru = 18;
        }
      }

      await roundDoc.ref.update({
        matches,
      });

      await callBoard({ roundID });

      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
    }
    setLoading(false);
  }

  return (
    <div className="bbs-result">
      {loading && <BackdropLoader />}

      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}

      <BackRow action={goBack} />
      <PageHeader text="Result Input" />

      <div className="bbsr-box pd-20">
        <div className="bbsr-team flex flex-align-center">
          <PlayerItem
            showBg={match.teamOnePoints > match.teamTwoPoints}
            //   showProfile={() => setSelWinner(selMatch.teamOne)}
            hideRemove
            hideImage
            hideHCP
            showAdd
            hideMessage
            hideProfile
            noLink
            // showHover={false}

            hideIcons
            name={`${match.teamOne[0].lastName} / ${match.teamOne[1].lastName}`}
          />
          <input
            min={0}
            className="default-input"
            onChange={(e) => {
              setMatch((c) => {
                if (e.target.valueAsNumber < 0) {
                  c.teamOnePoints = 0;
                } else {
                  c.teamOnePoints = e.target.valueAsNumber;
                }
                return c;
              });
              update();
            }}
            type="number"
            value={match.teamOnePoints}
          />
        </div>
        <div className="bbsr-team flex flex-align-center">
          <PlayerItem
            showBg={match.teamOnePoints < match.teamTwoPoints}
            //   showProfile={() => setSelWinner(selMatch.teamOne)}
            hideRemove
            hideImage
            hideHCP
            showAdd
            hideMessage
            hideProfile
            noLink
            hideIcons
            name={`${match.teamTwo[0].lastName} / ${match.teamTwo[1].lastName}`}
          />
          <input
            className="default-input"
            min={0}
            onChange={(e) => {
              setMatch((c) => {
                if (e.target.valueAsNumber < 0) {
                  c.teamTwoPoints = 0;
                } else {
                  c.teamTwoPoints = e.target.valueAsNumber;
                }
                return c;
              });
              update();
            }}
            type="number"
            value={match.teamTwoPoints}
          />
        </div>
        <div className="flex flex-center mt-40">
          <button onClick={saveMatch} className="default-button">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default BBSResult;
