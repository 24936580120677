import React, { useState, useEffect, useCallback } from "react";
import { admin, db, storage } from "../../firebase";
import { useDropzone } from "react-dropzone";

// Icons
import { AddCircle, Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import SportsIcon from "@mui/icons-material/Sports";

// Components
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import { Dialog, Avatar } from "@mui/material";
import Pill from "../Buttons/Pill";
import PlayerItem from "../display/PlayerItem/PlayerItem";

function LeagueTeamManager({
  goBack = () => {},
  leagueID = "",
  seasonID = "",
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const [teams, setTeams] = useState([]);

  const [update, setUpdate] = useState(0);

  const [teamName, setTeamName] = useState("");

  const [playerMap, setPlayerMap] = useState({});

  const [showAdd, setShowAdd] = useState(false);

  const [captains, setCaptains] = useState({});

  const [showPlayers, setShowPlayers] = useState(false);

  const [showCap, setShowCap] = useState(false);
  const [selCap, setSelCap] = useState("");

  const [capTeam, setCapTeam] = useState("");

  const [showRemovePlayer, setShowRemovePlayer] = useState(false);
  const [showRemoveTeam, setShowRemoveTeam] = useState(false);

  const [playerToRemove, setPlayerToRemove] = useState();
  const [teamToRemove, setTeamToRemove] = useState();

  const [sel, setSel] = useState([]);

  const [started, setStarted] = useState(false);

  const [editing, setEditing] = useState(false);
  const [toEdit, setToEdit] = useState();

  const [players, setPlayers] = useState([]);

  const [teamToAdd, setTeamToAdd] = useState("");

  const [image, setImage] = useState("");
  const [imgageURL, setImageURL] = useState("");
  function makeID(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  useEffect(() => {
    loadTeams();
  }, []);

  function cleanTeam() {
    setTeamName("");
    setImage("");
    setImageURL("");
  }

  function cleanEdit() {
    setTeamName("");
    setImage("");
    setImageURL("");
    setEditing(false);
    setToEdit();
  }

  async function saveTeam() {
    setLoading(true);
    setShowAdd(false);
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    try {
      if (image !== "") {
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "cupImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${leagueID}/${image.name}`)
              .put(file);

            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                const team = {
                  teamName,
                  imageLink: url,
                  teamID: makeID(10),
                  players: [],
                  played: 0,
                  won: 0,
                  tied: 0,
                  lost: 0,
                  points: 0,
                };

                await db
                  .collection("teamLeagues")
                  .doc(leagueID)
                  .update({
                    teams: admin.firestore.FieldValue.arrayUnion(team),
                  });
                loadTeams();
                cleanTeam();
              });
            });
          });
        } else {
          console.log(1);
          const task = storage
            .ref()
            .child(`${leagueID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            console.log(2);
            await snapshot.ref.getDownloadURL().then(async (url) => {
              console.log(url);
              const team = {
                teamName,
                imageLink: url,
                teamID: makeID(10),
                players: [],
                played: 0,
                won: 0,
                tied: 0,
                lost: 0,
                points: 0,
              };
              await db
                .collection("teamLeagues")
                .doc(leagueID)
                .update({
                  teams: admin.firestore.FieldValue.arrayUnion(team),
                });
              loadTeams();
              cleanTeam();
            });
          });
        }
      } else {
        const team = {
          teamName,
          imageLink: "",
          teamID: makeID(10),
          players: [],
          played: 0,
          won: 0,
          tied: 0,
          lost: 0,
          points: 0,
        };
        await db
          .collection("teamLeagues")
          .doc(leagueID)
          .update({
            teams: admin.firestore.FieldValue.arrayUnion(team),
          });
        loadTeams();
        cleanTeam();
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function removeTeam() {
    setLoading(true);
    setShowRemoveTeam(false);
    try {
      const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
      const league = leagueDoc.data();
      const { teams } = league;

      const filtered = teams.filter(
        (team) => team.teamID !== teamToRemove.teamID
      );

      await leagueDoc.ref.update({ teams: filtered });
      setTeamToRemove();
      loadTeams();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  async function loadTeams() {
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { teams, playerIDs } = league;
    setCaptains(league.captains ?? {});
    const leagueCaptains = league.captains ?? {};
    if (league.seasonID) {
      setStarted(true);
    }

    let teamed = [];

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const { players, teamID } = team;

      for (let k = 0; k < players.length; k++) {
        const player = players[k];
        const pid = player.playerID;

        if (leagueCaptains[teamID] === pid) {
          teams[i].players[k].captain = true;
        }
      }

      const ids = players.map((player) => player.playerID);

      teamed = [...teamed, ...ids];

      teams[i].players.sort((a, b) => (b.captain ? 1 : -1));
    }

    const filtede = playerIDs.filter((id) => !teamed.includes(id));

    const playerMap = {};

    const playerRefs = filtede.map((player) =>
      db.collection("users").doc(player).get()
    );

    const playerDocs = await Promise.all(playerRefs);

    const arr = [];

    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const playerID = playerDoc.id;
      const player = playerDoc.data();
      const { firstName, lastName, profileImage, handicap } = player;

      const name = `${firstName} ${lastName}`;

      arr.push({
        playerID,
        firstName,
        lastName,
        name,
        profileImage,
        handicap,
      });

      playerMap[playerID] = {
        playerID,
        firstName,
        lastName,
        name,
        profileImage,
        handicap,
      };
    }

    setPlayers(arr);
    setPlayerMap(playerMap);
    setTeams(teams);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    setShowAdd(false);
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { teams } = league;

    try {
      if (image !== "") {
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "cupImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${leagueID}/${image.name}`)
              .put(file);

            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                for (let i = 0; i < teams.length; i++) {
                  if (teams[i].teamID === toEdit.teamID) {
                    teams[i].teamName = teamName;
                    teams[i].imageLink = url;
                  }
                }

                await db.collection("teamLeagues").doc(leagueID).update({
                  teams,
                });
                loadTeams();
                cleanTeam();
                cleanEdit();
              });
            });
          });
        } else {
          const task = storage
            .ref()
            .child(`${leagueID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              for (let i = 0; i < teams.length; i++) {
                if (teams[i].teamID === toEdit.teamID) {
                  teams[i].teamName = teamName;
                  teams[i].imageLink = url;
                }
              }
              await db.collection("teamLeagues").doc(leagueID).update({
                teams,
              });
              loadTeams();
              cleanTeam();
              cleanEdit();
            });
          });
        }
      } else {
        for (let i = 0; i < teams.length; i++) {
          if (teams[i].teamID === toEdit.teamID) {
            teams[i].teamName = teamName;
          }
        }
        await db.collection("teamLeagues").doc(leagueID).update({
          teams,
        });
        loadTeams();
        cleanTeam();
        cleanEdit();
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
  }

  async function addToTeam() {
    setLoading(true);
    setShowPlayers(false);
    try {
      const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
      const league = leagueDoc.data();
      const { teams } = league;

      for (let i = 0; i < teams.length; i++) {
        if (teams[i].teamID === teamToAdd) {
          for (let j = 0; j < sel.length; j++) {
            teams[i].players.push(playerMap[sel[j]]);
          }
        }
      }

      await leagueDoc.ref.update({ teams });
      setSel([]);
      setTeamToAdd("");
      loadTeams();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function removeFromTeam() {
    setLoading(true);
    setShowRemovePlayer(false);
    try {
      const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
      const league = leagueDoc.data();
      const { teams } = league;

      for (let i = 0; i < teams.length; i++) {
        teams[i].players = teams[i].players.filter(
          (player) => player.playerID !== playerToRemove.playerID
        );
      }

      await leagueDoc.ref.update({ teams });
      setPlayerToRemove();
      loadTeams();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function saveCaptain() {
    setShowCap(false);
    setLoading(true);
    try {
      const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
      const league = leagueDoc.data();

      const captains = league.captains ?? {};
      captains[capTeam] = selCap;

      const captainIDs = [];
      for (let [key, value] of Object.entries(captains)) {
        captainIDs.push(value);
        console.log(captainIDs);
      }

      await leagueDoc.ref.update({
        captains,
        captainIDs,
      });
      console.log(captains);
      setCaptains(captains);
      setSelCap("");
      setCapTeam("");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  return (
    <div className="league-team-manager">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="League Teams" />
      <div className="ph-msg mb-20">
        <p>Teams can only be edited between seasons</p>
      </div>

      <Dialog open={showPlayers} onClose={() => setShowPlayers(false)}>
        <div className="ltm-players">
          <PageHeader
            text="Select Players"
            close={() => setShowPlayers(false)}
            showClose
          />
          <div className="ltmp-box">
            {players.map((player, index) => (
              <PlayerItem
                key={player.playerID}
                name={player.name}
                showBg={sel.includes(player.playerID)}
                showProfile={() => {
                  setSel((current) => {
                    console.log(current);
                    if (current.includes(player.playerID)) {
                      const filt = current.filter(
                        (id) => id !== player.playerID
                      );
                      return filt;
                    } else {
                      current.push(player.playerID);
                      return current;
                    }
                  });
                  setUpdate((current) => current + 1);
                }}
                noLink
                hideIcons
                hideImage
                hideHCP
              />
            ))}
          </div>
          <div className="flex-center mt-20">
            <button
              onClick={addToTeam}
              disabled={sel.length === 0}
              className="default-button"
            >
              Add Players
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showRemoveTeam} onClose={() => setShowRemoveTeam(false)}>
        <PageHeader
          text="Remove Team"
          showClose
          close={() => setShowRemoveTeam(false)}
        />
        <div className="ltm-remove pd-20">
          {teamToRemove && (
            <p>
              Are you sure you want to remove {teamToRemove.teamName} from this
              league?
            </p>
          )}
          <div className="flex-center">
            <button
              onClick={removeTeam}
              className="default-button delete-button mt-20 mb-20"
            >
              Remove
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={showRemovePlayer}
        onClose={() => setShowRemovePlayer(false)}
      >
        <PageHeader
          text="Remove Player"
          showClose
          close={() => setShowRemovePlayer(false)}
        />
        <div className="ltm-remove pd-20">
          {playerToRemove && (
            <p>
              Are you sure you want to remove {playerToRemove.name} from this
              team?
            </p>
          )}
          <div className="flex-center">
            <button
              onClick={removeFromTeam}
              className="default-button delete-button mt-20 mb-20"
            >
              Remove
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={showAdd}
        onClose={() => {
          if (editing) {
            cleanEdit();
          }
          setShowAdd(false);
        }}
      >
        <div className="ltm-add pd-20">
          <PageHeader
            text={editing ? "Edit Team" : "Add Team"}
            close={() => {
              if (editing) {
                cleanEdit();
              }
              setShowAdd(false);
            }}
            showClose
          />
          <div className="ltma-input">
            <div className="input-group">
              <p>Team Name</p>
              <input
                type="text"
                placeholder="Enter team name..."
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                className="default-input"
              />
            </div>
            <div style={{ padding: "0px 20px" }} className="input-group mb-20">
              <p>Team Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imgageURL !== "" && (
                      <div className="event-img mt-0">
                        <img className="" alt="profile" src={imgageURL} />
                      </div>
                    )}
                    {imgageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center">
            <button
              onClick={editing ? saveChanges : saveTeam}
              disabled={teamName === ""}
              className="default-button"
            >
              {editing ? "Save Changes" : "Save Team"}
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        className="cap-sel-dia"
        open={showCap}
        onClose={() => setShowCap(false)}
      >
        <div className="csd-ctt pd-20">
          <PageHeader
            text="Select Captain"
            close={() => setShowCap(false)}
            showClose
          />

          <div className="cap-box">
            {teams.map((team, i) => {
              if (team.teamID !== capTeam) {
                return null;
              }
              return (
                <div key={i} className="pd-20 cb-box">
                  {team.players.map((pl, j) => {
                    return (
                      <PlayerItem
                        showProfile={() => setSelCap(pl.playerID)}
                        key={`pl${j}`}
                        name={pl.name}
                        hideHCP
                        showBg={selCap === pl.playerID}
                        hideImage
                        noLink
                        hideIcons
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="flex flex-center">
            <button onClick={saveCaptain} className="default-button">
              Save
            </button>
          </div>
        </div>
      </Dialog>

      {!started && (
        <div className="quick-links">
          <Pill text={"New Team"} onClick={() => setShowAdd(true)} />
        </div>
      )}

      <div className="ltm-teams">
        {teams.map((team, index) => {
          return (
            <div className="ltm-team pd-20" key={team.teamID}>
              <div className="ltm-team-top flex-align-center">
                <Avatar alt={team.teamName} src={team.imageLink} />
                <h3 className="mr-5v ml-20">{team.teamName}</h3>
                <Edit
                  className="icon-cursor dg-icon-bg mr-20"
                  onClick={() => {
                    setToEdit(team);
                    setEditing(true);
                    setTeamName(team.teamName);
                    setImageURL(team.imageLink);
                    setShowAdd(true);
                  }}
                />
                <AddCircle
                  onClick={() => {
                    setTeamToAdd(team.teamID);
                    setShowPlayers(true);
                  }}
                  className="icon-cursor dg-icon-bg mr-20"
                />
                <SportsIcon
                  onClick={() => {
                    setCapTeam(team.teamID);
                    setShowCap(true);
                  }}
                  className="icon-cursor dg-icon-bg mr-20"
                />
                {!started && (
                  <DeleteIcon
                    onClick={() => {
                      setTeamToRemove(team);
                      setShowRemoveTeam(true);
                    }}
                    className="red-icon-bg icon-cursor"
                  />
                )}
              </div>
              {/* <div className="sub-header">
                <h5>Members</h5>
              </div> */}
              <div className="ltm-members pd-20 flex-wrap">
                {team.players.map((player, index2) => {
                  const isCap = captains[team.teamID] === player.playerID;

                  return (
                    <PlayerItem
                      key={player.playerID}
                      name={`${player.name} ${isCap ? "(CAPTAIN)" : ""}`}
                      noLink
                      showAdd
                      playerID={player.playerID}
                      hideProfile
                      hideEdit
                      hideRemove={seasonID !== ""}
                      //   hideIcons
                      hideImage
                      hideHCP
                      removeAction={() => {
                        setPlayerToRemove(player);
                        setShowRemovePlayer(true);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LeagueTeamManager;
