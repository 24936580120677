import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import RoundSummaryBox from "./RoundCard/RoundSummaryBox";

function ClientSeasonSummary({
  type = "",
  stats = {},
  grossStats = {},
  comps = {},
  grossComps = {},
}) {
  // Admin
  const prop = {
    textOne: "",
    textTwo: "",
    textThree: "",
    reverse: false,
    icon: "",
    type: "",
  };

  // State
  const [loading, setLoading] = useState(true);
  const [scoring, setScoring] = useState([prop]);
  const [holes, setHoles] = useState([prop]);
  const [driving, setDriving] = useState([prop]);
  const [putting, setPutting] = useState([prop]);
  const [approach, setApproach] = useState([prop]);

  // UE
  useEffect(() => {
    comparisons();
  }, [stats]);

  // Funcs
  async function comparisons() {
    const scor = [];
    const driv = [];
    const putt = [];
    const app = [];
    const holes = [];

    // Scoring
    console.log(stats, comps);
    const grossDiff =
      Math.round((stats.averageGross - comps.averageGross) * 100) / 100;
    if (grossDiff > 0) {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(grossDiff)} more strokes`,
        textThree: "per round than usual",
        type: "bad",
      });
    } else {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(grossDiff)} less strokes`,
        textThree: "per round than usual",
        type: "good",
      });
    }

    // Par 3s
    const par3Diff =
      Math.round(
        (grossStats.averageParThrees - grossComps.averageParThrees) * 100
      ) / 100;
    if (par3Diff > 0) {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(par3Diff)} more strokes`,
        textThree: "on par 3s than usual",
        type: "bad",
      });
    } else {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(par3Diff)} less strokes`,
        textThree: "on par 3s than usual",
        type: "good",
      });
    }

    // Par 4s
    const par4Diff =
      Math.round(
        (grossStats.averageParFours - grossComps.averageParFours) * 100
      ) / 100;
    if (par4Diff > 0) {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(par4Diff)} more strokes`,
        textThree: "on par 4s than usual",
        type: "bad",
      });
    } else {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(par4Diff)} less strokes`,
        textThree: "on par 4s than usual",
        type: "good",
      });
    }

    // Par 5s
    const par5Diff =
      Math.round(
        (grossStats.averageParFives - grossComps.averageParFives) * 100
      ) / 100;
    if (par5Diff > 0) {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(par5Diff)} more strokes`,
        textThree: "on par 5s than usual",
        type: "bad",
      });
    } else {
      scor.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(par5Diff)} less strokes`,
        textThree: "on par 5s than usual",
        type: "good",
      });
    }

    // Holes
    const eagleDiff =
      Math.round((grossStats.averageEagles - grossComps.averageEagles) * 100) /
      100;
    if (eagleDiff > 0) {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(eagleDiff)} more eagles`,
        textThree: "per round than usual",
        type: "good",
      });
    } else {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(eagleDiff)} less eagles`,
        textThree: "per round than usual",
        type: "bad",
      });
    }
    const birdieDiff =
      Math.round(
        (grossStats.averageBirdies - grossComps.averageBirdies) * 100
      ) / 100;
    if (birdieDiff > 0) {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(birdieDiff)} more birdies`,
        textThree: "per round than usual",
        type: "good",
      });
    } else {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(birdieDiff)} less birdies`,
        textThree: "per round than usual",
        type: "bad",
      });
    }
    const parDiff =
      Math.round((grossStats.averagePars - grossComps.averagePars) * 100) / 100;
    if (parDiff > 0) {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(parDiff)} more pars`,
        textThree: "per round than usual",
        type: "good",
      });
    } else {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(parDiff)} less pars`,
        textThree: "per round than usual",
        type: "bad",
      });
    }
    const bogeyDiff =
      Math.round((grossStats.averageBogies - grossComps.averageBogies) * 100) /
      100;
    if (bogeyDiff > 0) {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(bogeyDiff)} more bogies`,
        textThree: "per round than usual",
        type: "bad",
      });
    } else {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(bogeyDiff)} less bogies`,
        textThree: "per round than usual",
        type: "good",
      });
    }
    const doubleDiff =
      Math.round(
        (grossStats.averageDoubles - grossComps.averageDoubles) * 100
      ) / 100;
    if (doubleDiff > 0) {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(doubleDiff)} more doubles`,
        textThree: "per round than usual",
        type: "bad",
      });
    } else {
      holes.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(doubleDiff)} less doubles`,
        textThree: "per round than usual",
        type: "good",
      });
    }

    // Approach
    const girDiff =
      Math.round((stats.averageGIR - comps.averageGIR) * 100) / 100;
    if (girDiff > 0) {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(girDiff)} more greens`,
        textThree: "per round than usual",
        type: "good",
      });
    } else {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(girDiff)} less greens`,
        textThree: "per round than usual",
        type: "bad",
      });
    }

    const par3Girs = Math.round(
      (stats.parThreeGrossGIRs / stats.totalParThrees) * 100
    );
    const par3GirComps = Math.round(
      (comps.parThreeGrossGIRs / comps.totalParThrees) * 100
    );

    const par3GirDiff = par3Girs - par3GirComps;
    if (par3GirDiff > 0) {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(par3GirDiff)}% more greens`,
        textThree: "on par 3s than usual",
        type: "good",
      });
    } else {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(par3GirDiff)}% less greens`,
        textThree: "on par 3s than usual",
        type: "bad",
      });
    }

    const par4Girs = Math.round(
      (stats.parFourGrossGIRs / stats.totalParFours) * 100
    );
    const par4GirComps = Math.round(
      (comps.parFourGrossGIRs / comps.totalParFours) * 100
    );

    const par4GirDiff = par4Girs - par4GirComps;
    if (par4GirDiff > 0) {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(par4GirDiff)}% more greens`,
        textThree: "on par 4s than usual",
        type: "good",
      });
    } else {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(par4GirDiff)}% less greens`,
        textThree: "on par 4s than usual",
        type: "bad",
      });
    }

    const par5Girs = Math.round(
      (stats.parFiveGrossGIRs / stats.totalParFives) * 100
    );
    const par5GirComps = Math.round(
      (comps.parFiveGrossGIRs / comps.totalParFives) * 100
    );

    const par5GirDiff = par5Girs - par5GirComps;
    if (par5GirDiff > 0) {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(par5GirDiff)}% more greens`,
        textThree: "on par 5s than usual",
        type: "good",
      });
    } else {
      app.push({
        textOne: "You hit",
        textTwo: `${Math.abs(par5GirDiff)}% less greens`,
        textThree: "on par 5s than usual",
        type: "bad",
      });
    }

    const scramblePerc = Math.round(
      (stats.totalGrossScrambles / stats.totalGrossScrambleAttempts) * 100
    );
    const compScramblePerc = Math.round(
      (comps.totalGrossScrambles / comps.totalGrossScrambleAttempts) * 100
    );
    const scrambleDiff = scramblePerc - compScramblePerc;
    if (scrambleDiff > 0) {
      app.push({
        textOne: "You got up and down",
        textTwo: `${Math.abs(scrambleDiff)}% more`,
        textThree: "than usual",
        type: "good",
      });
    } else {
      app.push({
        textOne: "You got up and down",
        textTwo: `${Math.abs(scrambleDiff)}% less`,
        textThree: "than usual",
        type: "bad",
      });
    }

    // Driving
    const firDiff = Math.round(stats.averageFIR - comps.averageFIR * 100) / 100;
    if (firDiff > 0) {
      driv.push({
        textOne: "You hit",
        textTwo: `${Math.abs(firDiff)}% more`,
        textThree: "fairways than usual",
        type: "good",
      });
    } else {
      driv.push({
        textOne: "You hit",
        textTwo: `${Math.abs(firDiff)}% less`,
        textThree: "fairways than usual",
        type: "bad",
      });
    }

    // PUtting
    const puttDiff =
      Math.round(stats.averagePutts - comps.averagePutts * 100) / 100;
    if (puttDiff > 0) {
      putt.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(puttDiff)} more`,
        textThree: "putts than usual",
        type: "bad",
      });
    } else {
      putt.push({
        textOne: "You hit",
        textTwo: `${Math.abs(puttDiff)} less`,
        textThree: "putts than usual",
        type: "good",
      });
    }

    const averageOnePutts =
      Math.round((stats.onePutts / stats.totalRounds) * 100) / 100;
    const averageCompOnePutts =
      Math.round((comps.onePutts / comps.totalRounds) * 100) / 100;
    const onePuttDiff =
      Math.round(averageOnePutts - averageCompOnePutts * 100) / 100;
    if (onePuttDiff > 0) {
      putt.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(onePuttDiff)} more`,
        textThree: "1-putts than usual",
        type: "good",
      });
    } else {
      putt.push({
        textOne: "You hit",
        textTwo: `${Math.abs(onePuttDiff)} less`,
        textThree: "1-putts than usual",
        type: "bad",
      });
    }

    const averageThreePutts =
      Math.round((stats.threePutts / stats.totalRounds) * 100) / 100;
    const averageCompThreePutts =
      Math.round((comps.threePutts / comps.totalRounds) * 100) / 100;

    const threePuttDiff =
      Math.round(averageThreePutts - averageCompThreePutts * 100) / 100;
    if (threePuttDiff > 0) {
      putt.push({
        textOne: "You averaged",
        textTwo: `${Math.abs(threePuttDiff)} more`,
        textThree: "3-putts than usual",
        type: "bad",
      });
    } else {
      putt.push({
        textOne: "You hit",
        textTwo: `${Math.abs(threePuttDiff)} less`,
        textThree: "3-putts than usual",
        type: "good",
      });
    }
    setPutting(putt);
    setDriving(driv);
    setApproach(app);
    setHoles(holes);
    setScoring(scor);
    setLoading(false);
  }

  return (
    <div className="round-summary season-summary">
      {loading ? (
        <>
          <div className="cp-loader">
            <ClipLoader color="#1e7a69" size={30} className="btn-ico" />
          </div>
        </>
      ) : (
        <>
          <div className="rs-boxes">
            <div className="neh-box">
              <h2 className="ne-header">Scoring</h2>
            </div>
            <div className="rs-boxes">
              {scoring.map((item, index) => (
                <RoundSummaryBox
                  key={`ge${index}`}
                  textOne={item.textOne}
                  textTwo={item.textTwo}
                  textThree={item.textThree}
                  icon={"general"}
                  reverse={index % 2 !== 0}
                  type={item.type}
                />
              ))}
            </div>
            <div className="neh-box">
              <h2 className="ne-header">Holes</h2>
            </div>
            {holes.map((item, index) => (
              <RoundSummaryBox
                key={`ge${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"general"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
            <div className="neh-box">
              <h2 className="ne-header">Approach</h2>
            </div>
            {approach.map((item, index) => (
              <RoundSummaryBox
                key={`ge${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"general"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
            <div className="neh-box">
              <h2 className="ne-header">Driving</h2>
            </div>
            {driving.map((item, index) => (
              <RoundSummaryBox
                key={`ge${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"general"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
            <div className="neh-box">
              <h2 className="ne-header">Putting</h2>
            </div>
            {putting.map((item, index) => (
              <RoundSummaryBox
                key={`ge${index}`}
                textOne={item.textOne}
                textTwo={item.textTwo}
                textThree={item.textThree}
                icon={"general"}
                reverse={index % 2 !== 0}
                type={item.type}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ClientSeasonSummary;
