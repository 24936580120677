import React, { useState } from "react";
import "./Modals.css";
// Icons

// Components
import PageHeader from "../display/Rows/PageHeader";
import { DotLoader } from "react-spinners";
import { Dialog } from "@mui/material";

function ConfirmModal({
  loading = false,
  setLoading = () => {},
  text = "",
  confirm = () => {},
  close = () => {},
}) {
  // State

  return (
    // <div className="confirm-modal">
    <Dialog className="confirm-modal" open onClose={close}>
      <div className="cm-ctt">
        <PageHeader showClose close={close} text="Confirm" />
        {loading ? (
          <div className="dia-loader">
            <DotLoader loading color="#1e7a69" />
          </div>
        ) : (
          <>
            <div className="ct-txt">
              <p>{text}</p>
            </div>
            <div className="flex-center mt-20 mb-20">
              <button className="default-button med-btn" onClick={confirm}>
                Confirm
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
    // </div>
  );
}

export default ConfirmModal;
