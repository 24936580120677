import React, { useState, useEffect } from "react";
import "./SmartStats.css";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Info } from "@mui/icons-material";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import { db } from "../../../firebase";
import { Dialog } from "@mui/material";
import { ClipLoader } from "react-spinners";

function returnAverages(hcp) {
  console.log(hcp);
  if (hcp <= 5) {
    const toReturn = {
      gir: 0.7,
      fir: 0.8,
      putts: 29,
      birdes: 2.8,
      pars: 10.9,
      ud: 0.42,
      bogeys: 4.4,
    };
    return toReturn;
  }
  if (hcp > 5 && hcp <= 10) {
    const toReturn = {
      gir: 0.5,
      fir: 0.55,
      putts: 31,
      birdes: 1.5,
      pars: 8.5,
      ud: 0.3,
    };
    return toReturn;
  }
  if (hcp > 10 && hcp <= 15) {
    const toReturn = {
      gir: 0.35,
      fir: 0.45,
      putts: 30,
      birdes: 0.5,
      pars: 5.7,
      ud: 0.23,
    };
    return toReturn;
  }
  if (hcp > 15 && hcp <= 20) {
    const toReturn = {
      gir: 0.2,
      fir: 0.35,
      putts: 33,
      birdes: 0,
      pars: 4.5,
      ud: 0.18,
    };
    return toReturn;
  }
  if (hcp > 20 && hcp <= 30) {
    const toReturn = {
      gir: 0.2,
      fir: 0.25,
      putts: 36,
      birdes: 0,
      pars: 3,
      ud: 0.11,
    };
    return toReturn;
  }
  if (hcp > 30) {
    const toReturn = {
      gir: 0.1,
      fir: 0.1,
      putts: 38,
      birdes: 0,
      pars: 1,
      ud: 0.5,
    };
    return toReturn;
  }
}

function ownPuttPredict(hcp) {
  const val = 0.1021 * hcp + 30.1;
  return val;
}

function gptGirPredict(hcp) {
  const val = -0.5231 * hcp + 12.2667;
  return val;
}

function gptFirPredict(hcp) {
  const val = -2.7527 * hcp + 71.1818;
  return (val * 14) / 100;
}

function birdiePredict(hcp) {
  const val = -0.05233 * hcp + 1.713;
  return val;
}

function parPredict(hcp) {
  const val = -0.242 * hcp + 9.34;
  return val;
}

function bogeyPredict(hcp) {
  let val = -0.05467 * hcp + 6.793;
  if (hcp < 5) {
    val -= 3 * ((5 - hcp) / 5);
  }
  return val;
}
function doublePredict(hcp) {
  let val = 0.1353 * hcp + 1.26;
  if (hcp > 35) {
    val -= 3 * ((hcp - 35) / 5);
  }
  return val;
}

function otherPredict(hcp) {
  let val = 0.2233 * hcp - 1.211;
  if (val < 0) {
    val = 0;
  }

  return val;
}

function parThreePredict(hcp) {
  const val = 0.04399 * hcp + 0.4122;
  return val;
}
function parFourPredict(hcp) {
  const val = 0.06087 * hcp + 0.3341;
  return val;
}
function parFivePredict(hcp) {
  const val = 0.07115 * hcp + 0.01366;
  return val;
}

function SmartStats({
  eventID = "",
  courseID = "",
  roundID = "",
  userID = "",
  goBack = () => {},
}) {
  const [loading, setLoading] = useState(true);
  const [showDia, setShowDia] = useState(false);

  const [expectedHoles, setExpectedHoles] = useState({
    birdies: 0,
    pars: 0,
    bogies: 0,
    doubles: 0,
    other: 0,
  });
  const [actualHoles, setActualHoles] = useState({
    birdies: 0,
    pars: 0,
    bogies: 0,
    doubles: 0,
    other: 0,
  });

  const [total, setTotal] = useState(0);

  const [cat, setCat] = useState("overall");

  const [puttStats, setPuttStats] = useState({
    smartPutts: 0,
    actualPutts: 0,
    diff: 0,
  });
  const [atgStats, setATGStats] = useState({
    smartUDs: 0,
    actualUDs: 0,
    strokes: 0,
  });
  const [girStats, setGIRStats] = useState({
    smartGIR: 0,
    actualGIR: 0,
    diff: 0,
    strokes: 0,
  });
  const [firStats, setFIRStats] = useState({
    smartFIR: 0,
    actualFIR: 0,
    diff: 0,
    strokes: 0,
  });

  const [otherStats, setOtherStats] = useState({
    firAve: 0,
    noFirAve: 0,
    girAve: 0,
    noGirAve: 0,
    parThreeExpect: 0,
    parThreeActual: 0,
    parFourExpect: 0,
    parFourActual: 0,
    parFiveExpect: 0,
    parFiveActual: 0,
  });

  useEffect(() => {
    loadStats();
  }, []);

  const [puttingComments, setPuttingComments] = useState([]);

  async function loadStats() {
    console.log(roundID, userID, courseID);
    const roundDoc = await db
      .collection("rounds")
      .doc(roundID)
      .collection("scores")
      .doc(userID)
      .get();
    const round = roundDoc.data();
    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const smartRatings = course.smartRating ?? {
      bunkers: 2,
      courseLength: 2,
      fairwayWidth: 2,
      greenFirmness: 2,
      greenSize: 2,
      greenSpeed: 2,
      roughLength: 2,
    };
    // console.log(round);
    const { handicap, stats, totalPutts, grossStats } = round;
    const averages = returnAverages(handicap);

    const weather = 2;

    const expectedPars = parPredict(handicap);

    let puttStat = averages.putts;
    puttStat = ownPuttPredict(handicap);
    // const girStat = averages.gir;
    const girStat = gptGirPredict(handicap) / 18;
    // const firStat = averages.fir;
    const firStat = gptFirPredict(handicap) / (18 - stats.totalParThreesPlayed);
    const udPerc = averages.ud;
    const girStatAve = Math.round(girStat * 18);
    const firStatAve = Math.round(firStat * (18 - stats.totalParThreesPlayed));
    const { totalGIR } = stats;

    // PUTTING
    let puttMult = 1;

    // Course multipliers

    if (smartRatings.greenSize === 1) {
      puttMult -= 0.025;
    }
    if (smartRatings.greenSpeed === 1) {
      puttMult -= 0.025;
    }
    if (smartRatings.greenSize === 3) {
      puttMult += 0.025;
    }
    if (smartRatings.greenSpeed === 3) {
      puttMult += 0.025;
    }

    // GIR multiplier
    const gdPerc = 1 + stats.totalGIR / girStatAve;
    // const gdMult = Math.round(0.1 * gdPerc * 100) / 100;
    const gdMult = (gdPerc / 100) * 2;
    puttMult += gdMult;

    const smartPutts = Math.round(puttStat * puttMult * 10) / 10;
    const puttDiff = totalPutts - smartPutts;
    const diffPerc = Math.round((puttDiff / smartPutts) * 100);
    setPuttStats({
      actualPutts: totalPutts,
      smartPutts,
      diff: diffPerc,
    });

    // AROUND THE GREEN
    const totalScrambleAttempts =
      stats.totalGrossScrambleAttempts + stats.totalNetScrambleAttempts;
    const totalScramble = stats.totalGrossScrambles + stats.totalNetScrambles;
    const scramblePerc = totalScramble / totalScrambleAttempts;

    let atgMult = 1;

    if (smartRatings.greenSize === 1) {
      atgMult -= 0.05;
    }
    if (smartRatings.greenSize === 3) {
      atgMult += 0.05;
    }
    if (smartRatings.greenFirmness === 1) {
      atgMult += 0.05;
    }
    if (smartRatings.greenFirmess === 3) {
      atgMult -= 0.05;
    }
    if (smartRatings.bunkers === 1) {
      atgMult += 0.05;
    }
    if (smartRatings.bunkers === 3) {
      atgMult -= 0.05;
    }

    const multPerc = scramblePerc * atgMult;
    const atgDiff = multPerc - udPerc;
    const atgStrokes = atgDiff * stats.totalGrossScrambleAttempts;
    setATGStats({
      actualUDs: scramblePerc,
      smartUDs: udPerc,
      strokes: atgStrokes,
      diff: atgDiff,
    });

    // APPROACH
    let girMult = 1;
    // Course Multipliers
    if (smartRatings.greenSize === 1) {
      girMult -= 0.025;
    }
    if (smartRatings.greenSize === 3) {
      girMult += 0.025;
    }
    if (smartRatings.greenFirmness === 1) {
      girMult += 0.025;
    }
    if (smartRatings.greenFirmess === 3) {
      girMult -= 0.025;
    }
    if (smartRatings.courseLength === 1) {
      girMult += 0.025;
    }
    if (smartRatings.courseLength === 3) {
      girMult -= 0.025;
    }
    if (smartRatings.bunkers === 1) {
      girMult += 0.025;
    }
    if (smartRatings.bunkers === 3) {
      girMult -= 0.025;
    }
    if (weather === 1) {
      girMult += 0.025;
    }
    if (weather === 3) {
      girMult -= 0.025;
    }

    // FIR percentage
    const firPerc = stats.totalFIR / (18 - stats.totalParThreesPlayed);
    const fdPerc = firPerc / firStat;
    let fdMult = 1 + fdPerc / 10;

    if (smartRatings.roughLength === 1) {
      fdMult *= 1.025;
    }
    if (smartRatings.roughLength === 3) {
      fdMult *= 0.95;
    }

    fdMult = fdMult - 1;

    girMult -= fdMult;

    const smartGIRs = Math.round(girStatAve * girMult);
    const girDiff = totalGIR - smartGIRs;

    const scrambleMult = 1 - scramblePerc * 0.5;

    const girStrokes = girDiff * 0.9871982047 * scrambleMult;

    setGIRStats({
      smartGIR: smartGIRs,
      actualGIR: totalGIR,
      diff: girDiff,
      strokes: girStrokes,
    });

    // OFF THE TEE
    const { totalFIR } = stats;
    let firMult = 1;

    if (smartRatings.fairwayWidth === 1) {
      firMult -= 0.05;
    }
    if (smartRatings.fairwayWidth === 3) {
      firMult += 0.05;
    }
    if (smartRatings.courseLength === 1) {
      firMult += 0.05;
    }
    if (smartRatings.courseLength === 3) {
      firMult -= 0.05;
    }

    if (weather === 1) {
      firMult += 0.05;
    }
    if (weather === 3) {
      firMult -= 0.15;
    }

    const smartFIRs = Math.round(firStatAve * firMult);
    const firDiff = totalFIR - smartFIRs;
    const girPerc = 1 - totalGIR / 18;
    const firStrokes = firDiff * 0.981423606 * girPerc;
    setFIRStats({
      smartFIR: smartFIRs,
      actualFIR: totalFIR,
      diff: firDiff,
      strokes: firStrokes,
    });
    const tt = firStrokes + girStrokes - puttDiff + atgStrokes;
    setTotal(tt);

    // Holes
    const expBird = birdiePredict(handicap);
    const expPar = parPredict(handicap);
    const expBogies = bogeyPredict(handicap);
    const expDoubles = doublePredict(handicap);
    const expOthers = otherPredict(handicap);

    setExpectedHoles({
      birdies: expBird,
      pars: expPar,
      bogies: expBogies,
      doubles: expDoubles,
      other: expOthers,
    });

    setActualHoles({
      birdies: grossStats.totalBirdies + grossStats.totalEagles,
      pars: grossStats.totalPars,
      bogies: grossStats.totalBogies,
      doubles: grossStats.totalDoubles,
      other: grossStats.totalOthers,
    });

    // Other stats
    let firHoles = 0;
    let girHoles = 0;
    let firToPar = 0;
    let girToPar = 0;

    let noFirHoles = 0;
    let noGirHoles = 0;
    let noFirToPar = 0;
    let noGirToPar = 0;

    let girFirs = 0;
    let girNoFirs = 0;

    for (let i = 0; i < round.holes.length; i++) {
      const hole = round.holes[i];
      const { fir, gir, grossScore, par } = hole;

      if (fir) {
        firHoles += 1;
        firToPar += grossScore - par;
      }
      if (!fir && par !== 3) {
        noFirHoles += 1;
        noFirToPar += grossScore - par;
      }
      if (gir) {
        if (fir) {
          girFirs += 1;
        } else {
          girNoFirs += 1;
        }
        girHoles += 1;
        girToPar += grossScore - par;
      }
      if (!gir) {
        noGirHoles += 1;
        noGirToPar += grossScore - par;
      }
    }

    const firAve = Math.round((firToPar / firHoles) * 100) / 100;
    const noFirAve = Math.round((noFirToPar / noFirHoles) * 100) / 100;
    const girAve = Math.round((girToPar / girHoles) * 100) / 100;
    const noGirAve = Math.round((noGirToPar / noGirHoles) * 100) / 100;

    const expThree = parThreePredict(handicap);
    const expFour = parFourPredict(handicap);
    const expFive = parFivePredict(handicap);

    const parThreeActual = grossStats.averageParThrees - 3;
    const parFourActual = grossStats.averageParFours - 4;
    const parFiveActual = grossStats.averageParFives - 5;

    setOtherStats({
      firAve,
      noFirAve,
      girAve,
      noGirAve,
      parThreeExpect: expThree,
      parFourExpect: expFour,
      parFiveExpect: expFive,
      parThreeActual,
      parFourActual,
      parFiveActual,
    });

    setLoading(false);
  }

  return (
    <div className="smart-stats bs-subtle">
      <Dialog open={showDia} onClose={() => setShowDia(false)}>
        <div className="rankings-dialog">
          <div className="ra-di-header">
            <h2 className="ne-header">How it works</h2>
          </div>
          <div className="rd-info">
            <p>
              The<strong> UGA Strokes Gained</strong> system calculates how
              players fare compared to worldwide averages for their handicap,
              broken down into the following:
            </p>
            <ul>
              <li>Putting</li>
              <li>Around The Green</li>
              <li>Approach</li>
              <li>Off The Tee</li>
            </ul>

            <p>
              Numbers are relative to players' handicaps, which is why you might
              see a few stats that surprise you.
            </p>
          </div>
        </div>
      </Dialog>

      {loading ? (
        <>
          <div className="cp-loader">
            <ClipLoader color="#1e7a69" size={30} className="btn-ico" />
          </div>
        </>
      ) : (
        <>
          <div className="switch-row mb-20">
            <div className="selector-group sg-small">
              <button
                onClick={() => setCat("overall")}
                className={cat === "overall" ? "sg-left sg-active" : "sg-left"}
              >
                Overall
              </button>
              <button
                onClick={() => setCat("holes")}
                className={cat === "holes" ? "sg-right sg-active" : "sg-right"}
              >
                Holes
              </button>
              {false && (
                <button
                  onClick={() => setCat("other")}
                  className={
                    cat === "other" ? "sg-right sg-active" : "sg-right"
                  }
                >
                  Other
                </button>
              )}
            </div>
          </div>

          {cat === "overall" && (
            <>
              <div className="neh-box">
                <h2 className="ne-header">Putting</h2>
              </div>

              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected:</h5>
                  <p>{puttStats.smartPutts} putts</p>
                </div>
                <div className="smart-item">
                  <h5>Actual:</h5>
                  <p>{puttStats.actualPutts} putts</p>
                </div>
                <div className="smart-item">
                  <h5 className={puttStats.diff > 0 ? "red" : "green"}>
                    Strokes {puttStats.diff > 0 ? "lost" : "gained"}
                  </h5>
                  <p className={puttStats.diff > 0 ? "red bold" : "green bold"}>
                    {puttStats.diff > 0 ? "-" : "+"}
                    {Math.round(
                      Math.abs(puttStats.smartPutts - puttStats.actualPutts) *
                        100
                    ) / 100}
                  </p>
                </div>
              </div>

              <div className="neh-box">
                <h2 className="ne-header">Around the Green</h2>
              </div>
              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected Up & Down %</h5>
                  <p>{atgStats.smartUDs * 100}%</p>
                </div>
                <div className="smart-item">
                  <h5>Actual Up & Down %</h5>
                  <p>{Math.round(atgStats.actualUDs * 100)}%</p>
                </div>
                <div className="smart-item">
                  <h5 className={atgStats.diff < 0 ? "red" : "green"}>
                    Strokes {atgStats.diff < 0 ? "lost" : "gained"}
                  </h5>
                  <p className={atgStats.diff < 0 ? "red bold" : "green bold"}>
                    {atgStats.diff < 0 ? "-" : "+"}
                    {Math.round(Math.abs(atgStats.strokes) * 100) / 100}
                  </p>
                </div>
              </div>

              <div className="neh-box">
                <h2 className="ne-header">Approach</h2>
              </div>

              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected Greens Hit:</h5>
                  <p>{girStats.smartGIR}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual Greens Hit:</h5>
                  <p>{girStats.actualGIR}</p>
                </div>
                <div className="smart-item">
                  <h5 className={girStats.diff < 0 ? "red" : "green"}>
                    Strokes {girStats.diff < 0 ? "lost" : "gained"}
                  </h5>
                  <p className={girStats.diff < 0 ? "red bold" : "green bold"}>
                    {girStats.diff < 0 ? "-" : "+"}
                    {Math.round(Math.abs(girStats.strokes) * 100) / 100}
                  </p>
                </div>
              </div>

              <div className="neh-box">
                <h2 className="ne-header">Off the Tee</h2>
              </div>
              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected Fairways Hit:</h5>
                  <p>{firStats.smartFIR}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual Fairways Hit:</h5>
                  <p>{firStats.actualFIR}</p>
                </div>
                <div className="smart-item">
                  <h5 className={firStats.diff < 0 ? "red" : "green"}>
                    Strokes {firStats.diff < 0 ? "lost" : "gained"}
                  </h5>
                  <p className={firStats.diff < 0 ? "red bold" : "green bold"}>
                    {firStats.diff < 0 ? "-" : "+"}
                    {Math.round(Math.abs(firStats.strokes) * 100) / 100}
                  </p>
                </div>
              </div>
            </>
          )}

          {cat === "holes" && (
            <>
              <div className="neh-box">
                <h2 className="ne-header">Birdies or better</h2>
              </div>

              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected:</h5>
                  <p>{Math.round(expectedHoles.birdies * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual:</h5>
                  <p>{Math.round(actualHoles.birdies)}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      actualHoles.birdies < expectedHoles.birdies
                        ? "red"
                        : "green"
                    }
                  >
                    Difference
                  </h5>
                  <p
                    className={
                      actualHoles.birdies < expectedHoles.birdies
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {actualHoles.birdies < expectedHoles.birdies ? "-" : "+"}
                    {Math.round(
                      Math.abs(actualHoles.birdies - expectedHoles.birdies) *
                        100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">Pars</h2>
              </div>

              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected:</h5>
                  <p>{Math.round(expectedHoles.pars * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual:</h5>
                  <p>{Math.round(actualHoles.pars)}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      actualHoles.pars < expectedHoles.pars ? "red" : "green"
                    }
                  >
                    Difference
                  </h5>
                  <p
                    className={
                      actualHoles.pars < expectedHoles.pars
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {actualHoles.pars < expectedHoles.pars ? "-" : "+"}
                    {Math.round(
                      Math.abs(actualHoles.pars - expectedHoles.pars) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">Bogies</h2>
              </div>

              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected:</h5>
                  <p>{Math.round(expectedHoles.bogies * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual:</h5>
                  <p>{Math.round(actualHoles.bogies)}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      actualHoles.bogies > expectedHoles.bogies
                        ? "red"
                        : "green"
                    }
                  >
                    Difference
                  </h5>
                  <p
                    className={
                      actualHoles.bogies > expectedHoles.bogies
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {actualHoles.bogies > expectedHoles.bogies ? "-" : "+"}
                    {Math.round(
                      Math.abs(actualHoles.bogies - expectedHoles.bogies) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">Double Bogies</h2>
              </div>

              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected:</h5>
                  <p>{Math.round(expectedHoles.doubles * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual:</h5>
                  <p>{Math.round(actualHoles.doubles)}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      actualHoles.doubles > expectedHoles.doubles
                        ? "red"
                        : "green"
                    }
                  >
                    Difference
                  </h5>
                  <p
                    className={
                      actualHoles.doubles > expectedHoles.doubles
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {actualHoles.doubles > expectedHoles.doubles ? "-" : "+"}
                    {Math.round(
                      Math.abs(actualHoles.doubles - expectedHoles.doubles) *
                        100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">Other</h2>
              </div>

              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected:</h5>
                  <p>{Math.round(expectedHoles.other * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual:</h5>
                  <p>{Math.round(actualHoles.other)}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      actualHoles.other > expectedHoles.other ? "red" : "green"
                    }
                  >
                    Difference
                  </h5>
                  <p
                    className={
                      actualHoles.other > expectedHoles.other
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {actualHoles.other > expectedHoles.other ? "-" : "+"}
                    {Math.round(
                      Math.abs(actualHoles.other - expectedHoles.other) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
            </>
          )}

          {cat === "other" && (
            <>
              <div className="neh-box">
                <h2 className="ne-header">
                  Fairways in Regulation - Affect to par
                </h2>
              </div>
              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>FIR to par (average):</h5>
                  <p>{otherStats.firAve}</p>
                </div>
                <div className="smart-item">
                  <h5>No FIR to par (average):</h5>
                  <p>{otherStats.noFirAve}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      otherStats.noFirAve > otherStats.firAve ? "red" : "green"
                    }
                  >
                    Difference:
                  </h5>
                  <p
                    className={
                      otherStats.noFirAve > otherStats.firAve
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {otherStats.noFirAve > otherStats.firAve ? "-" : "+"}
                    {Math.round(
                      Math.abs(otherStats.noFirAve - otherStats.firAve) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">
                  Greens in Regulation - Affect to par
                </h2>
              </div>
              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>GIR to par (average):</h5>
                  <p>{otherStats.girAve}</p>
                </div>
                <div className="smart-item">
                  <h5>No GIR to par (average):</h5>
                  <p>{otherStats.noGirAve}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      otherStats.noGirAve > otherStats.girAve ? "red" : "green"
                    }
                  >
                    Difference:
                  </h5>
                  <p
                    className={
                      otherStats.noGirAve > otherStats.girAve
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {otherStats.noGirAve > otherStats.girAve ? "-" : "+"}
                    {Math.round(
                      Math.abs(otherStats.noGirAve - otherStats.girAve) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">Par 3s - to par</h2>
              </div>
              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected to par:</h5>
                  <p>{Math.round(otherStats.parThreeExpect * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual to par:</h5>
                  <p>{Math.round(otherStats.parThreeActual * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      otherStats.parThreeActual > otherStats.parThreeExpect
                        ? "red"
                        : "green"
                    }
                  >
                    Difference:
                  </h5>
                  <p
                    className={
                      otherStats.parThreeActual > otherStats.parThreeExpect
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {otherStats.parThreeActual > otherStats.parThreeExpect
                      ? "-"
                      : "+"}
                    {Math.round(
                      Math.abs(
                        otherStats.parThreeActual - otherStats.parThreeExpect
                      ) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">Par 4s - to par</h2>
              </div>
              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected to par:</h5>
                  <p>{Math.round(otherStats.parFourExpect * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual to par:</h5>
                  <p>{Math.round(otherStats.parFourActual * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      otherStats.parFourActual > otherStats.parFourExpect
                        ? "red"
                        : "green"
                    }
                  >
                    Difference:
                  </h5>
                  <p
                    className={
                      otherStats.parFourActual > otherStats.parFourExpect
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {otherStats.parFourActual > otherStats.parFourExpect
                      ? "-"
                      : "+"}
                    {Math.round(
                      Math.abs(
                        otherStats.parFourActual - otherStats.parFourExpect
                      ) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
              <div className="neh-box">
                <h2 className="ne-header">Par 5s - to par</h2>
              </div>
              <div className="smart-box pd-20">
                <div className="smart-item">
                  <h5>Expected to par:</h5>
                  <p>{Math.round(otherStats.parFiveExpect * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5>Actual to par:</h5>
                  <p>{Math.round(otherStats.parFiveActual * 100) / 100}</p>
                </div>
                <div className="smart-item">
                  <h5
                    className={
                      otherStats.parFiveActual > otherStats.parFiveExpect
                        ? "red"
                        : "green"
                    }
                  >
                    Difference:
                  </h5>
                  <p
                    className={
                      otherStats.parFiveActual > otherStats.parFiveExpect
                        ? "red bold"
                        : "green bold"
                    }
                  >
                    {otherStats.parFiveActual > otherStats.parFiveExpect
                      ? "-"
                      : "+"}
                    {Math.round(
                      Math.abs(
                        otherStats.parFiveActual - otherStats.parFiveExpect
                      ) * 100
                    ) / 100}
                  </p>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/* <p>Total: {total}</p> */}
    </div>
  );
}

export default SmartStats;
