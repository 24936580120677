import React from "react";
import "./ErrorModal.css";
// Icons
import Cancel from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
// Components
import { Dialog } from "@mui/material";

function ErrorModal({ text = "", hide = () => {} }) {
  return (
    <div className="error-modal-comp">
      <Dialog open={true} onClose={hide}>
        <div className="emc-dia">
          <div className="emc-close">
            <CloseIcon className="icon-cursor" onClick={hide} />
          </div>
          <Cancel className="red-icon big-red-icon" />
          <p>{text}</p>
        </div>
      </Dialog>
    </div>
  );
}

export default ErrorModal;
