import React, { useState, useEffect } from "react";
import "./ManualPlayerAdd.css";
import { db, admin } from "../../../firebase";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components
import Switch from "react-ios-switch/lib/Switch";
import {
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
} from "@mui/material";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import { Oval } from "react-loader-spinner";
import Slide from "@mui/material/Slide";
import Pill from "../../Buttons/Pill";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import PlayerListItem from "../../display/PlayerItem/PlayerListItem";
import classNames from "classnames";
import PageHeader from "../../display/Rows/PageHeader";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";
import _ from "lodash";
import { HighlightOff } from "@mui/icons-material";

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      onClick={onClick}
      className="big-tick dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function ManualPlayerAdd({
  eventID,
  cupID = "",
  tournamentID = "",
  players = [],
  hide = () => {},
  reload = () => {},
  limit = 0,
  league = false,
  leagueMax = 1,
  hideFilters = false,
  preSelected = [],
  leagueOptions = [],
  custom = false,
  customAction = () => {},
}) {
  const [selected, setSelected] = useState(preSelected);
  const [errorMessage, setErrorMessage] = useState("");
  const [finished, setFinished] = useState(false);

  const [leagueID, setLeagueID] = useState("all");

  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewType, setViewType] = useState("items");
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }
  const [playerrs, setPlayers] = useState(players);

  const [open, setOpen] = useState(false);

  const [showFilters, setShowFilters] = useState(false);

  const [sorter, setSorter] = useState("");
  const [sortDir, setSortDir] = useState("");

  const [rankEnabled, setRankEnabled] = useState(false);
  const [hcpEnabled, setHcpEnabled] = useState(false);
  const [ageEnabled, setAgeEnabled] = useState(false);
  const [hcpRange, setHcpRange] = useState([-5, 54]);
  const [ageRange, setAgeRange] = useState([0, 100]);
  const [minRank, setMinRank] = useState(leagueMax);

  useEffect(() => {
    console.log(players);
  }, []);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  async function addPlayers() {
    setLoading(true);
    try {
      await db
        .collection("events")
        .doc(eventID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(...selected),
        });
      setFinished(true);
      toast.success("Players were successfully added to the event", {
        icon: successIco,
      });
      reload();
      hide();
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function addCupPlayers() {
    setLoading(true);
    try {
      await db
        .collection("cups")
        .doc(cupID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(...selected),
        });
      setFinished(true);
      toast.success("Players were successfully added to the cup", {
        icon: successIco,
      });
      reload();
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function addTournamentPlayers() {
    setLoading(true);
    try {
      await db
        .collection("tournaments")
        .doc(tournamentID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(...selected),
        });
      setFinished(true);
      toast.success("Players were successfully added to the tournament", {
        icon: successIco,
      });
      reload();
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  function sortPlayers({ sorter = "", dir = "" }) {
    setSorter(sorter);
    setSortDir(dir);
    if (dir === "up") {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => a[sorter] - b[sorter]);
        } else {
          c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
        }
        return c;
      });
    } else {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => b[sorter] - a[sorter]);
        } else {
          c.sort((a, b) => b[sorter].localeCompare(a[sorter]));
        }
        return c;
      });
    }
    update();
  }

  return (
    <Dialog
      //   TransitionComponent={Transition}
      //   keepMounted
      className="mpa-dia"
      open={true}
      //   aria-describedby="alert-dialog-slide-description"
      onClose={() => {
        if (finished) {
          reload();
        }
        hide();
      }}
    >
      <div className="manual-player-add">
        <PageHeader text="Add Player" showClose close={hide} />
        <div className="ph-msg">
          {finished ? (
            <p>Your selected players have been added to this event</p>
          ) : (
            <p>
              {cupID
                ? "Manually add players to this cup"
                : tournamentID
                ? "Manually add players to this tournament"
                : custom
                ? "Manually add players"
                : "Manually add players to this event"}
            </p>
          )}
        </div>
        {loading ? (
          <>
            <div className="flex-center loading">
              <Oval
                color="#1e7a69"
                secondaryColor="#ffffff"
                height={40}
                width={40}
              />
            </div>
          </>
        ) : finished ? (
          <>
            <div className="mpa-fin flex-center">
              <GradientCircleIcon />
            </div>
          </>
        ) : (
          <>
            <div className="ig-group mb-20 mt-20">
              <div className="input-group mt-0 si-search">
                <p>Search</p>
                <div className="flex-align-center">
                  <input
                    type="text"
                    placeholder="Search for player"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="default-input"
                  />
                  <HighlightOff
                    className="icon-cursor red-icon-bg ml-20 ipm-search-ico"
                    onClick={() => setSearchInput("")}
                  />
                </div>
              </div>
              <div className="ml-20">
                <Pill
                  text={"Select All"}
                  onClick={() => {
                    console.log(leagueID);

                    const ids = playerrs.map((player) => {
                      if (hcpEnabled) {
                        if (
                          player.handicap < hcpRange[0] ||
                          player.handicap > hcpRange[1]
                        ) {
                          return null;
                        }
                      }

                      if (ageEnabled) {
                        if (
                          player.age < ageRange[0] ||
                          player.age > ageRange[1]
                        ) {
                          return null;
                        }
                      }
                      if (leagueID !== "all") {
                        const leagueIDs = player.leagueIDs ?? [];
                        console.log(player.leagueIDs);
                        if (!leagueIDs.includes(leagueID)) {
                          return null;
                        }
                      }

                      if (rankEnabled) {
                        if (player.rank > minRank || player.rank === 0) {
                          return null;
                        }
                      }

                      return player.playerID;
                    });
                    const together = [...selected, ...ids];
                    const uq = _.uniq(together);
                    const filt = uq.filter((pl) => pl !== null);
                    console.log(filt);

                    setSelected((current) => filt);
                  }}
                />
              </div>
              <Pill
                red
                text={"Deselect All"}
                onClick={() => {
                  setSelected((current) => []);
                }}
              />
              <RadioGroupTwo
                active={viewType}
                buttons={[
                  {
                    value: "items",
                    text: "Items",
                  },
                  {
                    value: "list",
                    text: "List",
                  },
                ]}
                setActive={() => {
                  setViewType((c) => {
                    if (c === "list") {
                      return "items";
                    } else {
                      return "list";
                    }
                  });
                }}
              />
            </div>

            {!hideFilters && (
              <Accordion
              // expanded={showFilters}
              // onChange={() => setShowFilters(false)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="se-header">
                    <h2 className="ne-header">Filters</h2>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mpaf-cont">
                    <div className="mpa-filters">
                      <div className="mpaf-pill">
                        <Pill
                          text="Reset"
                          red
                          onClick={() => {
                            setAgeRange([0, 100]);
                            setHcpRange([-5, 54]);
                            setMinRank(leagueMax);
                            setRankEnabled(false);
                            setAgeEnabled(false);
                            setHcpEnabled(false);
                            setLeagueID("all");
                          }}
                        />
                      </div>
                      <div className="mpafs">
                        <div className="mpaf">
                          <div className="mpaf-header">
                            <p>Handicap</p>
                            <Switch
                              onColor="#1e7a69"
                              checked={hcpEnabled}
                              className={
                                hcpEnabled
                                  ? "scoring-switch switch-on"
                                  : "scoring-switch"
                              }
                              onChange={() =>
                                setHcpEnabled((current) => !current)
                              }
                            />
                          </div>
                          <div className="mpaf-slider">
                            <Slider
                              step={0.1}
                              disabled={!hcpEnabled}
                              value={hcpRange}
                              min={-5}
                              max={54}
                              disableSwap
                              valueLabelDisplay="on"
                              onChange={(e, newValue) => setHcpRange(newValue)}
                            />
                          </div>
                        </div>
                        <div className="mpaf">
                          <div className="mpaf-header">
                            <p>Age</p>
                            <Switch
                              onColor="#1e7a69"
                              checked={ageEnabled}
                              className={
                                ageEnabled
                                  ? "scoring-switch switch-on"
                                  : "scoring-switch"
                              }
                              onChange={() =>
                                setAgeEnabled((current) => !current)
                              }
                            />
                          </div>
                          <div className="mpaf-slider">
                            <Slider
                              value={ageRange}
                              min={0}
                              max={100}
                              disableSwap
                              disabled={!ageEnabled}
                              valueLabelDisplay="on"
                              onChange={(e, newValue) => setAgeRange(newValue)}
                            />
                          </div>
                        </div>

                        {league && (
                          <div className="mpaf">
                            <div className="mpaf-header">
                              <p>League Rank</p>
                              <Switch
                                onColor="#1e7a69"
                                checked={rankEnabled}
                                className={
                                  rankEnabled
                                    ? "scoring-switch switch-on"
                                    : "scoring-switch"
                                }
                                onChange={() =>
                                  setRankEnabled((current) => !current)
                                }
                              />
                            </div>
                            <div className="mpaf-slider">
                              <Slider
                                value={minRank}
                                min={1}
                                max={leagueMax}
                                disableSwap
                                disabled={!rankEnabled}
                                valueLabelDisplay="on"
                                onChange={(e, newValue) => setMinRank(newValue)}
                              />
                            </div>
                          </div>
                        )}
                        {leagueOptions.length > 0 && (
                          <div className="mpaf mpaf-drop">
                            <div className="mpaf-header">
                              <p>League</p>
                            </div>
                            <div className="mpaf-drop">
                              <Dropdown
                                arrowClosed={
                                  <KeyboardArrowDownIcon className="drop-icon" />
                                }
                                arrowOpen={
                                  <KeyboardArrowUpIcon className="drop-icon" />
                                }
                                options={leagueOptions}
                                value={leagueID}
                                onChange={(e) => {
                                  console.log(e.value);
                                  setLeagueID(e.value);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            <div className="default-button-row flex-center">
              <button
                onClick={
                  custom
                    ? () => {
                        console.log(selected);
                        customAction(selected);
                        hide();
                      }
                    : cupID
                    ? addCupPlayers
                    : tournamentID
                    ? addTournamentPlayers
                    : addPlayers
                }
                disabled={selected.length === 0}
                className="default-button"
              >
                Add {selected.length}{" "}
                {selected.length === 1 ? "Player" : "Players"}
              </button>
            </div>
            <div className="mpa-players pl-20">
              {viewType === "list" && (
                <>
                  <div className="player-list-item pli-head pb-20">
                    <div
                      onClick={() => {
                        if (sorter === "firstName") {
                          if (sortDir === "up") {
                            sortPlayers({ sorter: "firstName", dir: "down" });
                          } else {
                            sortPlayers({ sorter: "firstName", dir: "up" });
                          }
                        } else {
                          sortPlayers({ sorter: "firstName", dir: "down" });
                        }
                      }}
                      className={classNames("pli-item", {
                        "plih-sel-up":
                          sorter === "firstName" && sortDir === "up",
                        "plih-sel-dw":
                          sorter === "firstName" && sortDir !== "up",
                      })}
                    >
                      <p>First Name</p>
                    </div>
                    <div
                      onClick={() => {
                        if (sorter === "lastName") {
                          if (sortDir === "up") {
                            sortPlayers({ sorter: "lastName", dir: "down" });
                          } else {
                            sortPlayers({ sorter: "lastName", dir: "up" });
                          }
                        } else {
                          sortPlayers({ sorter: "lastName", dir: "down" });
                        }
                      }}
                      className={classNames("pli-item", {
                        "plih-sel-up":
                          sorter === "lastName" && sortDir === "up",
                        "plih-sel-dw":
                          sorter === "lastName" && sortDir !== "up",
                      })}
                    >
                      <p>Last Name</p>
                    </div>
                    <div
                      onClick={() => {
                        if (sorter === "handicap") {
                          if (sortDir === "up") {
                            sortPlayers({ sorter: "handicap", dir: "down" });
                          } else {
                            sortPlayers({ sorter: "handicap", dir: "up" });
                          }
                        } else {
                          sortPlayers({ sorter: "handicap", dir: "down" });
                        }
                      }}
                      className={classNames("pli-item", {
                        "plih-sel-up":
                          sorter === "handicap" && sortDir === "up",
                        "plih-sel-dw":
                          sorter === "handicap" && sortDir !== "up",
                      })}
                    >
                      <p>Hcp</p>
                    </div>
                    <div className="pli-item">
                      <p>Selected</p>
                    </div>
                  </div>
                </>
              )}
              {playerrs.map((player) => {
                // console.log(player);

                if (leagueID !== "all") {
                  const leagueIDs = player.leagueIDs ?? [];
                  if (!leagueIDs.includes(leagueID)) {
                    return null;
                  }
                }

                if (rankEnabled) {
                  if (player.rank > minRank || player.rank === 0) {
                    return null;
                  }
                }

                if (hcpEnabled) {
                  const minHcp = hcpRange[0];
                  const maxHcp = hcpRange[1];

                  if (player.handicap < minHcp || player.handicap > maxHcp) {
                    return null;
                  }
                }

                if (ageEnabled) {
                  const minAge = ageRange[0];
                  const maxAge = ageRange[1];
                  if (player.age < minAge || player.age > maxAge) {
                    return null;
                  }
                }

                if (searchInput !== "") {
                  const sl = searchInput.toLowerCase();
                  if (!player.name.toLowerCase().includes(sl)) {
                    return null;
                  }
                }

                if (viewType === "list") {
                  return (
                    <PlayerListItem
                      player={player}
                      key={player.playerID}
                      selected={selected.includes(player.playerID)}
                      select={() => {
                        setSelected((current) => {
                          if (current.includes(player.playerID)) {
                            const filt = current.filter(
                              (uid) => uid !== player.playerID
                            );
                            return filt;
                          } else {
                            return [...current, player.playerID];
                          }
                        });
                      }}
                    />
                  );
                } else {
                  return (
                    <PlayerItem
                      noLink
                      showProfile={() => {
                        console.log(selected.length, limit);
                        if (
                          selected.length === limit &&
                          selected.length !== 0
                        ) {
                          return;
                        }
                        setSelected((current) => {
                          if (current.includes(player.playerID)) {
                            const filt = current.filter(
                              (uid) => uid !== player.playerID
                            );
                            return filt;
                          } else {
                            return [...current, player.playerID];
                          }
                        });
                      }}
                      hcp={player.handicap}
                      img={player.profileImage}
                      name={player.name}
                      playerID={player.playerID}
                      hideIcons
                      key={player.playerID}
                      showBg={selected.includes(player.playerID)}
                    />
                  );
                }
              })}
            </div>
            {errorMessage !== "" && (
              <div className="error-message-row">
                <p>{errorMessage}</p>
              </div>
            )}
            {false && (
              <div className="default-button-row flex-center mb-20">
                <button
                  onClick={
                    custom
                      ? () => {
                          console.log(selected);
                          customAction(selected);
                          hide();
                        }
                      : cupID
                      ? addCupPlayers
                      : tournamentID
                      ? addTournamentPlayers
                      : addPlayers
                  }
                  disabled={selected.length === 0}
                  className="default-button"
                >
                  Add {selected.length}{" "}
                  {selected.length === 1 ? "Player" : "Players"}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
}

export default ManualPlayerAdd;
