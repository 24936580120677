import React, { useState, useEffect } from "react";
import { db, admin } from "../../../firebase";

// Icons

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import PageHeader from "../../display/Rows/PageHeader";
import { Dialog } from "@mui/material";
import ErrorModal from "../../ErrorModal/ErrorModal";
import MatchplayScore from "../../display/MatchplayScore/MatchplayScore";
import BackRow from "../../display/Rows/BackRow";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

function TEDResultInput({
  matches = [],
  roundID = "",
  goBack = () => {},
  format = "Matchplay",
  defaultMatch,
  eventID = "",
  scorerID = "",
  cupID = "",
}) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");

  const [selMatch, setSelMatch] = useState(defaultMatch);
  const [showSelector, setShowSelector] = useState(true);

  const [selWinner, setSelWinner] = useState();
  const [manualBy, setManualBy] = useState(0);
  const [manualRem, setManualRem] = useState(0);

  const eventMatchups = admin.functions().httpsCallable("teamEventMatchups");
  const roundCupScore = admin.functions().httpsCallable("roundCupScore");

  useEffect(() => {
    // console.log(defaultMatch);
    console.log(format);
    if (defaultMatch) {
      // console.log(defaultMatch);
      setManualBy(defaultMatch.score ?? 0);
      setManualRem(18 - defaultMatch.thru);
      if (format === "Fourball") {
        if (defaultMatch.winner === "teamOne") {
          setSelWinner(defaultMatch.teamOne);
        } else if (defaultMatch.winner === "teamTwo") {
          setSelWinner(defaultMatch.teamTwo);
        } else if (defaultMatch.winner === "" && defaultMatch.completed) {
          setSelWinner({
            name: "Tie",
            playerID: "tie",
          });
        }
      }
      if (format === "Matchplay") {
        if (defaultMatch.winner === defaultMatch.playerOne.playerID) {
          setSelWinner(defaultMatch.playerOne);
        } else if (defaultMatch.winner === defaultMatch.playerTwo.playerID) {
          setSelWinner(defaultMatch.playerTwo);
        } else if (defaultMatch.winner === "" && defaultMatch.completed) {
          setSelWinner({
            name: "Tie",
            playerID: "tie",
          });
        }
      }

      setShowSelector(false);
    }
  }, []);

  async function saveResult() {
    setLoading(true);

    try {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();

      const { matches } = round;

      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (match.matchID === selMatch.matchID) {
          matches[i].winner =
            selWinner.playerID === "tie" ? "" : selWinner.playerID;
          matches[i].leader =
            selWinner.playerID === "tie" ? "" : selWinner.playerID;
          matches[i].score = parseInt(manualBy);
          matches[i].thru = 18 - manualRem;
          matches[i].manualFinish = true;
          matches[i].completed = true;
          matches[i].complete = true;
        }
      }
      // setLoading(false);
      // return console.log(matches);

      await roundDoc.ref.update({
        matches,
      });

      eventMatchups({
        eventID,
      });

      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function saveFourballResult() {
    // return console.log(selWinner);
    setLoading(true);

    try {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();

      const { matches } = round;

      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (match.matchID === selMatch.matchID) {
          let team = "";
          console.log(selWinner);
          if (selWinner.name === "Tie") {
            matches[i].winner = "";
            matches[i].leader = "";
            matches[i].score = 0;
            matches[i].thru = 18;
            matches[i].manualFinish = true;
            matches[i].completed = true;
            matches[i].complete = true;
          } else {
            const p1 = selWinner[0].playerID;

            const { teamOne, teamTwo } = selMatch;

            const p1T1 = teamOne[0].playerID;
            const p2T1 = teamOne[1].playerID;
            const p1T2 = teamTwo[0].playerID;
            const p2T2 = teamTwo[1].playerID;

            if (p1 === p1T1 || p1 === p2T1) {
              team = "teamOne";
            }

            if (p1 === p1T2 || p1 === p2T2) {
              team = "teamTwo";
            }

            matches[i].winner = team;
            matches[i].leader = team;
            matches[i].score = parseInt(manualBy);
            matches[i].thru = 18 - manualRem;
            matches[i].manualFinish = true;
            matches[i].completed = true;
            matches[i].complete = true;
          }
          // console.log(matches[i]);
        }
      }
      // console.log(matches);
      // return setLoading(false);

      await roundDoc.ref.update({
        matches,
      });
      eventMatchups({
        eventID,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function saveCupResult() {
    console.log("CUP RESULT");
    setLoading(true);

    try {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();

      const { matches } = round;

      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (match.matchID === selMatch.matchID) {
          matches[i].winner =
            selWinner.playerID === "tie" ? "" : selWinner.playerID;
          matches[i].leader =
            selWinner.playerID === "tie" ? "" : selWinner.playerID;
          matches[i].score = parseInt(manualBy);
          matches[i].thru = 18 - manualRem;
          matches[i].manualFinish = true;
          matches[i].completed = true;
          matches[i].complete = true;
        }
      }
      // setLoading(false);
      // return console.log(matches);
      await roundDoc.ref.update({
        matches,
      });
      console.log("STORED");

      await roundCupScore({ roundID });
      console.log("ALSO STORED");

      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function saveCupFourballResult() {
    setLoading(true);

    try {
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();

      const { matches } = round;

      for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        if (match.matchID === selMatch.matchID) {
          let team = "";
          console.log(selWinner);
          if (selWinner.name === "Tie") {
            matches[i].winner = "";
            matches[i].leader = "";
            matches[i].score = 0;
            matches[i].thru = 18;
            matches[i].manualFinish = true;
            matches[i].completed = true;
            matches[i].complete = true;
          } else {
            const p1 = selWinner[0].playerID;

            const { teamOne, teamTwo } = selMatch;

            const p1T1 = teamOne[0].playerID;
            const p2T1 = teamOne[1].playerID;
            const p1T2 = teamTwo[0].playerID;
            const p2T2 = teamTwo[1].playerID;

            if (p1 === p1T1 || p1 === p2T1) {
              team = "teamOne";
            }

            if (p1 === p1T2 || p1 === p2T2) {
              team = "teamTwo";
            }

            matches[i].winner = team;
            matches[i].leader = team;
            matches[i].score = parseInt(manualBy);
            matches[i].thru = 18 - manualRem;
            matches[i].manualFinish = true;
            matches[i].completed = true;
            matches[i].complete = true;
          }
        }
      }
      // console.log(matches);
      // return setLoading(false);

      await roundDoc.ref.update({
        matches,
      });

      await roundCupScore({ roundID });

      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="ted-result-input">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog open={showSelector} onClose={() => setShowSelector(false)}>
        <div className="tri-dia pd-20">
          <BackRow action={goBack} />

          <div className="tri-matches mt-20">
            {format === "Matchplay" &&
              matches.map((match, index) => {
                if (scorerID !== "") {
                  if (
                    match.playerOne.teamID !== scorerID &&
                    match.playerTwo.teamID !== scorerID
                  ) {
                    return null;
                  }
                }
                return (
                  <div key={match.matchID} className="tem-match">
                    <MatchplayScore
                      nameOne={match.playerOne.name}
                      nameTwo={match.playerTwo.name}
                      score={match.score}
                      holes={match.thru}
                      idOne={match.playerOne.playerID}
                      idTwo={match.playerTwo.playerID}
                      leader={match.leader}
                      handleClick={() => {
                        setSelMatch(match);
                        setShowSelector(false);
                      }}
                    />
                  </div>
                );
              })}
            {format === "Fourball" &&
              matches.map((match, idnex) => {
                const { teamOne, teamTwo } = match;
                if (scorerID !== "") {
                  if (
                    match.teamOneID !== scorerID &&
                    match.teamTwoID !== scorerID
                  ) {
                    return null;
                  }
                }
                if (!teamOne) {
                  return null;
                }
                const p1 = teamOne[0];
                const p12 = teamOne[1];
                const p2 = teamTwo[0];
                const p22 = teamTwo[1];

                const t1 = p1.teamName;
                const t2 = p2.teamName;

                const nameOne = `${p1.lastName ?? p1.name} / ${
                  p12.lastName ?? p12.name
                }`;
                const nameTwo = `${p2.lastName ?? p2.name} / ${
                  p22.lastName ?? p22.name
                }`;

                console.log(p1, p12);

                let leaderID = match.leader;
                if (typeof leaderID !== "string") {
                  leaderID = "";
                }
                return (
                  <div key={match.matchID} className="tem-match">
                    <MatchplayScore
                      nameOne={nameOne}
                      nameTwo={nameTwo}
                      score={match.score ?? 0}
                      holes={match.thru}
                      idOne={"teamOne"}
                      idTwo={"teamTwo"}
                      leader={leaderID}
                      handleClick={() => {
                        setSelMatch(match);
                        setShowSelector(false);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader text="Matchplay Result" />
      <div className="ph-msg mb-20">
        <p>Select the player / group who won this game </p>
      </div>
      {selMatch && format === "Matchplay" && (
        <div className="ted-col">
          <div className="mpb-match">
            <PlayerItem
              showBg={
                selWinner && selWinner.playerID === selMatch.playerOne.playerID
              }
              showProfile={() => setSelWinner(selMatch.playerOne)}
              hideRemove
              hcp={selMatch.playerOne.handicap}
              hideImage
              hideHCP
              showAdd
              hideMessage
              hideProfile
              noLink
              hideIcons
              name={selMatch.playerOne.name}
            />
            <div className="mp-cir-box">
              <div className="mpbm-circle">
                <p>vs</p>
              </div>
            </div>

            <PlayerItem
              showBg={
                selWinner && selWinner.playerID === selMatch.playerTwo.playerID
              }
              showProfile={() => setSelWinner(selMatch.playerTwo)}
              hideRemove
              hcp={selMatch.playerTwo.handicap}
              hideImage
              hideIcons
              showAdd
              hideHCP
              hideMessage
              hideProfile
              noLink
              name={selMatch.playerTwo.name}
            />
          </div>
          <div className="mini-pi">
            <PlayerItem
              name={"Tie"}
              showBg={selWinner && selWinner.name === "Tie"}
              hideIcons
              noLink
              hideHCP
              hideImage
              showProfile={() => {
                setSelWinner({
                  name: "Tie",
                  playerID: "tie",
                });
                setManualBy(0);
                setManualRem(0);
              }}
            />
          </div>
          <div className="mp-man-holes">
            <div className="input-group">
              <p>Holes Up</p>
              <input
                type="number"
                disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualBy(e.target.value)}
                value={manualBy}
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Holes Remaining</p>
              <input
                type="number"
                disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualRem(e.target.value)}
                value={manualRem}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center mb-20">
            <button
              disabled={selWinner === undefined}
              className="default-button"
              onClick={() => {
                if (cupID === "") {
                  saveResult();
                } else {
                  saveCupResult();
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
      {selMatch && format === "Fourball" && (
        <div className="ted-col">
          <div className="mpb-match">
            <PlayerItem
              showBg={
                selWinner &&
                selWinner.name !== "Tie" &&
                selWinner[0].playerID === selMatch.teamOne[0].playerID
              }
              showProfile={() => setSelWinner(selMatch.teamOne)}
              hideRemove
              hideImage
              hideHCP
              showAdd
              hideMessage
              hideProfile
              noLink
              hideIcons
              name={`${
                selMatch.teamOne[0].lastName ?? selMatch.teamOne[0].name
              } / ${selMatch.teamOne[1].lastName ?? selMatch.teamOne[1].name}`}
            />
            <div className="mp-cir-box">
              <div className="mpbm-circle">
                <p>vs</p>
              </div>
            </div>

            <PlayerItem
              showBg={
                selWinner &&
                selWinner.name !== "Tie" &&
                selWinner[0].playerID === selMatch.teamTwo[0].playerID
              }
              showProfile={() => setSelWinner(selMatch.teamTwo)}
              hideRemove
              hideImage
              hideIcons
              showAdd
              hideHCP
              hideMessage
              hideProfile
              noLink
              name={`${
                selMatch.teamTwo[0].lastName ?? selMatch.teamTwo[0].name
              } / ${selMatch.teamTwo[1].lastName ?? selMatch.teamTwo[1].name}`}
            />
          </div>
          <div className="mini-pi">
            <PlayerItem
              name={"Tie"}
              showBg={selWinner && selWinner.name === "Tie"}
              hideIcons
              noLink
              hideHCP
              hideImage
              showProfile={() => {
                setSelWinner({
                  name: "Tie",
                  playerID: "tie",
                });
                setManualBy(0);
                setManualRem(0);
              }}
            />
          </div>
          <div className="mp-man-holes">
            <div className="input-group">
              <p>Holes Up</p>
              <input
                type="number"
                disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualBy(e.target.value)}
                value={manualBy}
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Holes Left</p>
              <input
                type="number"
                // disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualRem(e.target.value)}
                value={manualRem}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center">
            <button
              disabled={
                selWinner === undefined ||
                (selWinner !== undefined &&
                  selWinner.name !== "Tie" &&
                  manualBy === 0 &&
                  manualRem === 0)
              }
              className="default-button"
              onClick={() => {
                console.log(cupID, format);
                if (cupID !== "") {
                  if (format === "Matchplay") {
                    saveCupResult();
                  } else {
                    saveCupFourballResult();
                  }
                } else {
                  if (format === "Matchplay") {
                    saveResult();
                  } else {
                    saveFourballResult();
                  }
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TEDResultInput;
