import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";

// Icons

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { toast } from "react-toastify";
import { successIco } from "../HelperFunctions";
import { errDefault } from "../../HelperFunctions";
import PageHeader from "../../display/Rows/PageHeader";
import { Dialog, Tab, Tabs } from "@mui/material";
import Pill from "../../Buttons/Pill";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import BackRow from "../../display/Rows/BackRow";
import ConfirmModal from "../ConfirmModal";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import PlayerListItem from "../../display/PlayerItem/PlayerListItem";
import classNames from "classnames";

function ProStatsPlayers({
  clubID = "",
  reload = () => {},
  goBack = () => {},
}) {
  // Admin
  const { userDetails } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [allMembers, setAllMembers] = useState([]);
  const [activatedMembers, setActivatedMembers] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const [addSearch, setAddSearch] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [toRemove, setToRemove] = useState();
  const [viewType, setViewType] = useState("items");
  const [sorter, setSorter] = useState("");
  const [sortDir, setSortDir] = useState("");
  const [upd, setUpd] = useState(0);
  const [selTab, setSelTab] = useState("players");
  const [changes, setChanges] = useState([]);

  function update() {
    setUpd((c) => c + 1);
  }

  // UE
  useEffect(() => {
    loadPlayers();
  }, []);

  // Functions
  async function loadPlayers() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const { memberIDs } = club;
    const proStatsIDs = club.proStatsIDs ?? [];
    const refs = memberIDs.map((uid) => db.collection("users").doc(uid).get());
    const docs = await Promise.all(refs);
    const arr = [];
    const changeArr = [];

    const changeDocs = await clubDoc.ref
      .collection("proStatsChanges")
      .orderBy("date", "desc")
      .get();

    const playerMap = {};

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      if (!playerDoc.exists) {
        continue;
      }
      const playerID = docs[i].id;

      const player = playerDoc.data();

      const { firstName, lastName, handicap, profileImage } = player;

      playerMap[playerID] = {
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
      };

      arr.push({
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
      });
    }

    for (let i = 0; i < changeDocs.docs.length; i++) {
      const changeDoc = changeDocs.docs[i];
      const changeData = changeDoc.data();
      const { type, date } = changeData;
      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("default", {
        dateStyle: "medium",
      });
      const players = [];
      if (type === "removal") {
        const { playerID } = changeData;
        const player = playerMap[playerID];
        players.push(player);
      } else {
        const { playerIDs } = changeData;
        for (let j = 0; j < playerIDs.length; j++) {
          const playerID = playerIDs[j];
          const player = playerMap[playerID];
          players.push(player);
        }
      }
      changeArr.push({
        players,
        type,
        dateObject,
        dateString,
      });
    }

    setChanges(changeArr);

    arr.sort((a, b) => a.lastName.localeCompare(b.lastName));

    const proStatsPlayers = arr.filter((pl) =>
      proStatsIDs.includes(pl.playerID)
    );

    setAllMembers(arr);
    setActivatedMembers(proStatsPlayers);
    setLoading(false);
  }

  async function saveChanges() {
    setShow(false);
    setLoading(true);
    try {
      const activatedIDs = activatedMembers.map((pl) => pl.playerID);
      const selectedIDs = selected.map((pl) => pl.playerID);

      const proStatsIDs = [...activatedIDs, ...selectedIDs];
      // setLoading(false);
      // return console.log(proStatsIDs);

      await db.collection("clubs").doc(clubID).update({
        proStatsIDs,
      });

      await db
        .collection("clubs")
        .doc(clubID)
        .collection("proStatsChanges")
        .add({
          type: "addition",
          date: new Date(),
          playerIDs: selectedIDs,
        });

      setActivatedMembers((c) => {
        return [...c, ...selected];
      });
      setSelected((c) => []);
      update();

      toast.success("Changes successfully saved", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(errDefault);
    }
    setLoading(false);
  }

  async function removePlayer(player) {
    setLoading(true);
    setShowConfirm(false);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();
      const { proStatsIDs } = club;

      const filt = proStatsIDs.filter((p) => p !== player.playerID);

      await clubDoc.ref.update({
        proStatsIDs: filt,
      });

      await clubDoc.ref.collection("proStatsChanges").add({
        type: "removal",
        date: new Date(),
        playerID: player.playerID,
      });

      setActivatedMembers((c) => {
        const filt = c.filter((pl) => pl.playerID !== player.playerID);
        return filt;
      });
      setToRemove();
      update();
      toast.success("Changes successfully saved", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(errDefault);
    }
    setLoading(false);
  }

  function sortPlayers({ sorter = "", dir = "" }) {
    setSorter(sorter);
    setSortDir(dir);
    if (dir === "up") {
      setAllMembers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => a[sorter] - b[sorter]);
        } else {
          c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
        }
        return c;
      });
    } else {
      setAllMembers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => b[sorter] - a[sorter]);
        } else {
          c.sort((a, b) => b[sorter].localeCompare(a[sorter]));
        }
        return c;
      });
    }
    update();
  }

  return (
    <div className="ps-player-select">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {showConfirm && (
        <ConfirmModal
          close={() => setShowConfirm(false)}
          confirm={() => removePlayer(toRemove)}
          text="Are you sure you want to remove this player from your Pro Stats Players?"
        />
      )}
      <Dialog open={show} onClose={() => setShow(false)}>
        <div className="psp-dia-ctt">
          <PageHeader
            text="Select Players"
            showClose
            close={() => setShow(false)}
          />
          {selected.length > 0 && (
            <div className="mt-20 pl-20">
              <button
                onClick={saveChanges}
                className="default-button small-but"
              >
                Add {selected.length}{" "}
                {selected.length === 1 ? "player" : "players"}
              </button>
            </div>
          )}
          <div className="ig-group">
            <div className="input-group pl-20 mr-20">
              <p>Search</p>
              <input
                value={addSearch}
                onChange={(e) => setAddSearch(e.target.value)}
                placeholder="Search for player..."
                className="default-input"
              />
            </div>{" "}
            <Pill
              text={"Select All"}
              onClick={() => {
                const addedIDs = activatedMembers.map((pl) => pl.playerID);
                const arr = [];
                for (let i = 0; i < allMembers.length; i++) {
                  const player = allMembers[i];
                  if (!addedIDs.includes(player.playerID)) {
                    arr.push(player);
                  }
                }
                console.log(arr);
                setSelected(arr);
                update();
              }}
            />
            <Pill
              red
              text={"Deselect All"}
              onClick={() => {
                setSelected((current) => []);
                update();
              }}
            />
            <RadioGroupTwo
              active={viewType}
              buttons={[
                {
                  value: "items",
                  text: "Items",
                },
                {
                  value: "list",
                  text: "List",
                },
              ]}
              setActive={() => {
                setViewType((c) => {
                  if (c === "list") {
                    return "items";
                  } else {
                    return "list";
                  }
                });
              }}
            />
          </div>

          <div className="psc-players">
            {viewType === "list" && (
              <>
                <div className="player-list-item pli-head pb-20">
                  <div
                    onClick={() => {
                      if (sorter === "firstName") {
                        if (sortDir === "up") {
                          sortPlayers({ sorter: "firstName", dir: "down" });
                        } else {
                          sortPlayers({ sorter: "firstName", dir: "up" });
                        }
                      } else {
                        sortPlayers({ sorter: "firstName", dir: "down" });
                      }
                    }}
                    className={classNames("pli-item", {
                      "plih-sel-up": sorter === "firstName" && sortDir === "up",
                      "plih-sel-dw": sorter === "firstName" && sortDir !== "up",
                    })}
                  >
                    <p>First Name</p>
                  </div>
                  <div
                    onClick={() => {
                      if (sorter === "lastName") {
                        if (sortDir === "up") {
                          sortPlayers({ sorter: "lastName", dir: "down" });
                        } else {
                          sortPlayers({ sorter: "lastName", dir: "up" });
                        }
                      } else {
                        sortPlayers({ sorter: "lastName", dir: "down" });
                      }
                    }}
                    className={classNames("pli-item", {
                      "plih-sel-up": sorter === "lastName" && sortDir === "up",
                      "plih-sel-dw": sorter === "lastName" && sortDir !== "up",
                    })}
                  >
                    <p>Last Name</p>
                  </div>
                  <div
                    onClick={() => {
                      if (sorter === "handicap") {
                        if (sortDir === "up") {
                          sortPlayers({ sorter: "handicap", dir: "down" });
                        } else {
                          sortPlayers({ sorter: "handicap", dir: "up" });
                        }
                      } else {
                        sortPlayers({ sorter: "handicap", dir: "down" });
                      }
                    }}
                    className={classNames("pli-item", {
                      "plih-sel-up": sorter === "handicap" && sortDir === "up",
                      "plih-sel-dw": sorter === "handicap" && sortDir !== "up",
                    })}
                  >
                    <p>Hcp</p>
                  </div>
                  <div className="pli-item">
                    <p>Selected</p>
                  </div>
                </div>
              </>
            )}
            {allMembers.map((player, i) => {
              if (addSearch !== "") {
                const sl = addSearch.toLowerCase();
                const nl = player.name.toLowerCase();
                if (!nl.includes(sl)) {
                  return null;
                }
              }

              const activatedIDs = activatedMembers.map((pl) => pl.playerID);

              if (activatedIDs.includes(player.playerID)) {
                return null;
              }

              if (viewType === "list") {
                return (
                  <PlayerListItem
                    player={player}
                    key={player.playerID}
                    selected={selected
                      .map((p) => p.playerID)
                      .includes(player.playerID)}
                    select={() => {
                      setSelected((c) => {
                        console.log(c);
                        const cIDs = c.map((pl) => pl.playerID);
                        if (cIDs.includes(player.playerID)) {
                          const filt = c.filter(
                            (p) => p.playerID !== player.playerID
                          );
                          return filt;
                        } else {
                          c.push(player);
                          return c;
                        }
                      });
                      update();
                    }}
                  />
                );
              } else {
                return (
                  <PlayerItem
                    key={player.playerID}
                    name={player.name}
                    img={player.profileImage}
                    hideIcons
                    hcp={player.handicap}
                    noLink
                    showBg={selected
                      .map((p) => p.playerID)
                      .includes(player.playerID)}
                    showProfile={() => {
                      setSelected((c) => {
                        console.log(c);
                        const cIDs = c.map((pl) => pl.playerID);
                        if (cIDs.includes(player.playerID)) {
                          const filt = c.filter(
                            (p) => p.playerID !== player.playerID
                          );
                          return filt;
                        } else {
                          c.push(player);
                          return c;
                        }
                      });
                      update();
                    }}
                  />
                );
              }
            })}
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader text="Pro Stats Players" />

      <div className="ec-tabs">
        <Tabs value={selTab} onChange={(e, v) => setSelTab(v)}>
          <Tab value={"players"} label="Players" />
          <Tab value={"changes"} label="Changes" />
        </Tabs>
      </div>
      {selTab === "players" && (
        <>
          <div className="flex pl-20 mt-20">
            <Pill text={"Add Players"} onClick={() => setShow(true)} />
          </div>

          {activatedMembers.length > 0 && (
            <div className="input-group pl-20">
              <p>Search</p>
              <input
                value={addSearch}
                onChange={(e) => setAddSearch(e.target.value)}
                placeholder="Search for player..."
                className="default-input"
              />
            </div>
          )}

          <div className="psp-players flex-wrap pd-20">
            {activatedMembers.map((player, i) => {
              if (search !== "") {
                const sl = search.toLowerCase();
                const nl = player.name.toLowerCase();
                if (!nl.includes(sl)) {
                  return null;
                }
              }
              return (
                <PlayerItem
                  key={player.playerID}
                  name={player.name}
                  hcp={player.handicap}
                  // noLink
                  img={player.profileImage}
                  hideEdit
                  showAdd
                  hideProfile
                  hideMessage
                  removeAction={() => {
                    setToRemove(player);
                    setShowConfirm(true);
                  }}
                />
              );
            })}
            {!loading && activatedMembers.length === 0 && (
              <div className="no-players pd-20">
                <p>No players have been added yet</p>
              </div>
            )}
          </div>
        </>
      )}
      {selTab === "changes" && (
        <div className="flex-center mt-40">
          <div className="psp-changes ac-graph bss-white pd-20">
            <div className="psp-change pspc-head">
              <p>Date</p>
              <p>Type</p>
              <p>Players</p>
            </div>
            {changes.map((change, i) => {
              const { players } = change;
              return (
                <div key={`change${i}`} className="psp-change">
                  <p>{change.dateString}</p>
                  <p className={`psp-${change.type} pspc-type`}>
                    {" "}
                    {change.type}
                  </p>
                  <p className={`pspp-${change.type}`}>
                    {players.map((player, j) => {
                      if (j === players.length - 1) {
                        return (
                          <React.Fragment key={`${player.name}${j}`}>
                            {player.name}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment key={`${player.name}${j}`}>
                            {player.name},{" "}
                          </React.Fragment>
                        );
                      }
                    })}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProStatsPlayers;
