import React, { useEffect, useState } from "react";

// Icons

// Components
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import { Dialog } from "@mui/material";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";

function CSVStatsSelector({
  dl = () => {},
  close = () => {},
  net = false,
  stats = {},
  grossStats = {},
}) {
  useEffect(() => {
    console.log(stats.averageOthers, grossStats.averageOthers);
  }, []);

  const [update, setUpdate] = useState(0);
  const [selectedStats, setSelectedStats] = useState([
    {
      label: "Scoring Average",
      value: net ? "averageNet" : "averageGross",
      sel: true,
      type: "scoring",
    },
    {
      label: "Total Rounds",
      value: "totalRounds",
      sel: true,
      type: "scoring",
    },
    {
      label: "Par 3 Average",
      value: "averageParThrees",
      sel: true,
      type: "scoring",
    },
    {
      label: "Par 4 Average",
      value: "averageParFours",
      sel: true,
      type: "scoring",
    },
    {
      label: "Par 5 Average",
      value: "averageParFives",
      sel: true,
      type: "scoring",
    },
    {
      label: "Birdie Conversion",
      value: "birdieConversion",
      sel: true,
      type: "scoring",
    },
    {
      label: "Bounce Back Birdies",
      value: net ? "netBounceBackBirdies" : "grossBounceBackBirdies",
      sel: true,
      type: "scoring",
    },
    {
      label: "Average Eagles",
      value: "averageEagles",
      sel: true,
      type: "holes",
    },
    {
      label: "Average Birdies",
      value: "averageBirdies",
      sel: true,
      type: "holes",
    },
    {
      label: "Average Par",
      value: "averagePars",
      sel: true,
      type: "holes",
    },
    {
      label: "Average Bogies",
      value: "averageBogies",
      sel: true,
      type: "holes",
    },
    {
      label: "Average Doubles",
      value: "averageDoubles",
      sel: true,
      type: "holes",
    },
    {
      label: "Average Others",
      value: "averageOthers",
      sel: true,
      type: "holes",
    },
    {
      label: "GIR",
      value: net ? "averageNetGIR" : "averageGIR",
      sel: true,
      type: "approach",
    },
    {
      label: "GIR - Par 3s",
      value: net
        ? "parThreeNetGIRScoringAverage"
        : "parThreeGrossGIRScoringAverage",
      sel: true,
      type: "approach",
    },
    {
      label: "GIR - Par 4s",
      value: net
        ? "parFourNetGIRScoringAverage"
        : "parFourGrossGIRScoringAverage",
      sel: true,
      type: "approach",
    },
    {
      label: "GIR - Par 5s",
      value: net
        ? "parFiveNetGIRScoringAverage"
        : "parFiveGrossGIRScoringAverage",
      sel: true,
      type: "approach",
    },
    {
      label: "Par 3 Average (GIR)",
      value: net
        ? "parThreeNetGIRScoringAverage"
        : "parThreeGrossGIRScoringAverage",
      sel: true,
      type: "approach",
    },
    {
      label: "Par 4 Average (GIR)",
      value: net
        ? "parFourNetGIRScoringAverage"
        : "parFourGrossGIRScoringAverage",
      sel: true,
      type: "approach",
    },
    {
      label: "Par 5 Average (GIR)",
      value: net
        ? "parFiveNetGIRScoringAverage"
        : "parFiveGrossGIRScoringAverage",
      sel: true,
      type: "approach",
    },
    {
      label: "Sand Saves",
      value: "sandSave",
      sel: true,
      type: "approach",
    },
    {
      label: "FIR",
      value: "averageFIR",
      sel: true,
      type: "offTheTee",
    },
    {
      label: "Par 4 Average (FIR)",
      value: net
        ? "parFourNetFIRScoringAverage"
        : "parFourGrossFIRScoringAverage",
      sel: true,
      type: "offTheTee",
    },
    {
      label: "Par 5 Average (FIR)",
      value: net
        ? "parFiveNetFIRScoringAverage"
        : "parFiveGrossFIRScoringAverage",
      sel: true,
      type: "offTheTee",
    },
    {
      label: "Putts / Round",
      value: "averagePutts",
      sel: true,
      type: "putting",
    },
    {
      label: "Putts / Hole",
      value: "puttsPerHole",
      sel: true,
      type: "putting",
    },
    {
      label: "1 Putts / Round",
      value: "averageOnePutts",
      sel: true,
      type: "putting",
    },
    {
      label: "3 Putts / Round",
      value: "averageThreePutts",
      sel: true,
      type: "putting",
    },
  ]);

  const [loading, setLoading] = useState(false);

  function generateStats() {
    setLoading(true);

    let headerRow = selectedStats.map((stat) => (stat.sel ? stat.label : null));

    headerRow = headerRow.filter((r) => r !== null);

    const csvArr = [];

    for (let i = 0; i < selectedStats.length; i++) {
      const stat = selectedStats[i];
      if (stat.sel !== true) {
        continue;
      }

      const value = stat.value;

      if (stats[value] !== undefined) {
        csvArr.push(stats[value]);
      } else if (grossStats[value] !== undefined) {
        csvArr.push(grossStats[value]);
      } else {
        // console.log(value);

        if (value === "birdieConversion") {
          if (net) {
            const birdieConversion = Math.round(
              (stats.totalBirdies / stats.totalNetGir) * 100
            );
            csvArr.push(birdieConversion);
            continue;
          } else {
            const birdieConversion = Math.round(
              (grossStats.totalBirdies / stats.totalGIR) * 100
            );
            csvArr.push(birdieConversion);
            continue;
          }
        } else if (value === "puttsPerHole") {
          const pph = Math.round((stats.averagePutts / 18) * 100) / 100;
          csvArr.push(pph);
          continue;
        } else if (value === "averageOnePutts") {
          const aop =
            Math.round((stats.onePutts / stats.totalRounds) * 100) / 100;
          csvArr.push(aop);
          continue;
        } else if (value === "averageThreePutts") {
          const atp = Math.round((stats.threePutts / 18) * 100) / 100;
          csvArr.push(atp);
          continue;
        }

        csvArr.push(value);
      }
    }

    const expArr = [headerRow, csvArr];

    console.log(expArr);

    dl(expArr);

    setLoading(false);
  }

  return (
    <div className="csv-ss">
      <Dialog open onClose={close}>
        <div className="css-ctt pd-20">
          <PageHeader text="Stats Export" close={close} showClose />
          {loading ? (
            <BackdropLoader />
          ) : (
            <>
              <div className="ph-msg">
                <p>Select which stats you would like this report to include.</p>
              </div>
              <div className="neh-box mt-20">
                <h2 className="ne-header">Scoring</h2>
              </div>
              <div className="flex flex-wrap">
                {selectedStats.map((stat, i) => {
                  if (stat.type !== "scoring") {
                    return null;
                  }

                  return (
                    <div className="cao-item" key={i}>
                      <Checkbox.Root
                        className="CheckboxRoot"
                        checked={stat.sel}
                        onCheckedChange={() => {
                          setSelectedStats((c) => {
                            c[i].sel = !c[i].sel;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      >
                        <Checkbox.Indicator className="CheckboxIndicator">
                          {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                          {stat.sel === true && <CheckIcon />}
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <p>{stat.label}</p>
                    </div>
                  );
                })}
              </div>
              <div className="neh-box mt-20">
                <h2 className="ne-header">Holes</h2>
              </div>
              <div className="flex flex-wrap">
                {selectedStats.map((stat, i) => {
                  if (stat.type !== "holes") {
                    return null;
                  }

                  return (
                    <div className="cao-item" key={i}>
                      <Checkbox.Root
                        className="CheckboxRoot"
                        checked={stat.sel}
                        onCheckedChange={() => {
                          setSelectedStats((c) => {
                            c[i].sel = !c[i].sel;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      >
                        <Checkbox.Indicator className="CheckboxIndicator">
                          {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                          {stat.sel === true && <CheckIcon />}
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <p>{stat.label}</p>
                    </div>
                  );
                })}
              </div>
              <div className="neh-box mt-20">
                <h2 className="ne-header">Approach</h2>
              </div>
              <div className="flex flex-wrap">
                {selectedStats.map((stat, i) => {
                  if (stat.type !== "approach") {
                    return null;
                  }

                  return (
                    <div className="cao-item" key={i}>
                      <Checkbox.Root
                        className="CheckboxRoot"
                        checked={stat.sel}
                        onCheckedChange={() => {
                          setSelectedStats((c) => {
                            c[i].sel = !c[i].sel;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      >
                        <Checkbox.Indicator className="CheckboxIndicator">
                          {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                          {stat.sel === true && <CheckIcon />}
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <p>{stat.label}</p>
                    </div>
                  );
                })}
              </div>
              <div className="neh-box mt-20">
                <h2 className="ne-header">Off The Tee</h2>
              </div>
              <div className="flex flex-wrap">
                {selectedStats.map((stat, i) => {
                  if (stat.type !== "offTheTee") {
                    return null;
                  }

                  return (
                    <div className="cao-item" key={i}>
                      <Checkbox.Root
                        className="CheckboxRoot"
                        checked={stat.sel}
                        onCheckedChange={() => {
                          setSelectedStats((c) => {
                            c[i].sel = !c[i].sel;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      >
                        <Checkbox.Indicator className="CheckboxIndicator">
                          {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                          {stat.sel === true && <CheckIcon />}
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <p>{stat.label}</p>
                    </div>
                  );
                })}
              </div>
              <div className="neh-box mt-20">
                <h2 className="ne-header">Putting</h2>
              </div>
              <div className="flex flex-wrap">
                {selectedStats.map((stat, i) => {
                  if (stat.type !== "putting") {
                    return null;
                  }

                  return (
                    <div className="cao-item" key={i}>
                      <Checkbox.Root
                        className="CheckboxRoot"
                        checked={stat.sel}
                        onCheckedChange={() => {
                          setSelectedStats((c) => {
                            c[i].sel = !c[i].sel;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      >
                        <Checkbox.Indicator className="CheckboxIndicator">
                          {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                          {stat.sel === true && <CheckIcon />}
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <p>{stat.label}</p>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-center mt-40">
                <button
                  onClick={generateStats}
                  className="default-button shadow-md"
                >
                  Generate
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default CSVStatsSelector;
