import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useClickTheme } from "../../contexts/MenuContext";
import { useNavigate, useLocation, Link } from "react-router-dom";

// Icons

// Components
import FixturePreview from "../../components/display/FixturePreview/FixturePreview";

function HomeActiveEvents() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, portalSettings, userDetails } = useAuth();
  const showClick = useClickTheme();

  // State
  const [activeEvents, setActiveEvents] = useState([]);

  useEffect(() => {
    if (portalSettings) {
      if (portalSettings.clubID) {
        checkActiveClubEvents();
      } else {
        checkActiveEvents();
      }
    }
  }, [portalSettings]);

  async function checkActiveClubEvents() {
    const events = await db
      .collection("events")
      .where("roundIDs", "!=", [])
      .where("completed", "==", false)
      .where("clubID", "==", portalSettings.clubID)
      .get();

    const teamEvents = await db
      .collection("teamEvents")
      .where("roundIDs", "!=", [])
      .where("completed", "==", false)
      .where("clubID", "==", portalSettings.clubID)
      .get();

    const cups = await db
      .collection("cups")
      .where("roundIDs", "!=", [])
      .where("completed", "==", false)
      .where("admins", "array-contains", currentUser.uid)
      .get();

    const arr = [];
    for (let i = 0; i < cups.docs.length; i++) {
      const eventDoc = cups.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      event.cupID = eventID;
      event.dateObj = new Date(event.cupDate.toDate());
      event.cup = true;
      const dateString = new Date(event.cupDate.toDate()).toLocaleDateString(
        "en-us",
        { dateStyle: "full" }
      );
      event.dateString = dateString;
      event.eventName = event.cupName;
      arr.push(event);
    }
    for (let i = 0; i < teamEvents.docs.length; i++) {
      const eventDoc = teamEvents.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      event.eventID = eventID;
      event.dateObj = new Date(event.eventDate.toDate());

      const dateString = new Date(event.eventDate.toDate()).toLocaleDateString(
        "en-us",
        { dateStyle: "full" }
      );
      event.dateString = dateString;
      event.teamEvent = true;
      arr.push(event);
    }

    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      if (event.portalComplete) {
        continue;
      }
      event.eventID = eventID;
      event.dateObj = new Date(event.eventDate.toDate());
      const dateString = new Date(event.eventDate.toDate()).toLocaleDateString(
        "en-us",
        { dateStyle: "full" }
      );
      event.dateString = dateString;
      arr.push(event);
    }

    const sorted = arr.sort((a, b) => b.dateObj - a.dateObj);

    setActiveEvents(sorted);
  }

  async function checkActiveEvents() {
    // const leagues = await db
    //   .collection("leagues")
    //   .where("admins", "array-contains", currentUser.uid)
    //   .get();
    // const leagueIDs = leagues.docs.map((league) => league.id);
    // if (leagueIDs.length === 0) {
    //   return;
    // }

    let events;

    if (userDetails.accountType === "society") {
      events = await db
        .collection("events")
        .where("roundIDs", "!=", [])
        .where("completed", "==", false)
        .where("leagueID", "==", portalSettings.clubID)
        // .where("leagueID", "in", leagueIDs)
        .get();
      alert(events.docs.length);
    } else {
      events = await db
        .collection("events")
        .where("roundIDs", "!=", [])
        .where("completed", "==", false)
        .where("adminIDs", "array-contains", currentUser.uid)
        // .where("leagueID", "in", leagueIDs)
        .get();
    }
    const arr = [];
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      if (event.portalComplete) {
        continue;
      }
      event.eventID = eventID;
      const dateString = new Date(event.eventDate.toDate()).toLocaleDateString(
        "en-us",
        { dateStyle: "full" }
      );
      event.dateString = dateString;
      arr.push(event);
    }
    setActiveEvents(arr);
  }

  return (
    <div className="home-active-events">
      {activeEvents.length > 0 && (
        <>
          <div className="sub-header">
            <h5>Active Events</h5>
          </div>
          <div className="hae-events">
            {activeEvents.map((event) => {
              return (
                <Link
                  key={`active${event.eventID}`}
                  to={
                    event.teamEvent
                      ? `/team-evt-dash/${event.eventID}`
                      : event.cup
                      ? `/cup-dash/${event.cupID}`
                      : `/evt-dash/${event.eventID}`
                  }
                >
                  <FixturePreview
                    past
                    event={event}
                    hideIcons
                    hidePerson={event.teamEvent}
                    // showDetails={() => {
                    //   if (event.teamEvent) {
                    //     navigate("/calendar", {
                    //       state: {
                    //         type: "team-active",
                    //         eventID: event.eventID,
                    //       },
                    //     });
                    //     if (location.pathname === "/calendar") {
                    //       showClick({
                    //         type: "active-team-event",
                    //         eventID: event.eventID,
                    //       });
                    //     }
                    //   } else if (event.cup) {
                    //     navigate("/calendar", {
                    //       state: {
                    //         type: "cup-active",
                    //         eventID: event.cupID,
                    //       },
                    //     });
                    //     if (location.pathname === "/calendar") {
                    //       showClick({
                    //         type: "active-cup",
                    //         eventID: event.cupID,
                    //       });
                    //     }
                    //   } else {
                    //     navigate("/calendar", {
                    //       state: {
                    //         type: "active",
                    //         eventID: event.eventID,
                    //       },
                    //     });
                    //     if (location.pathname === "/calendar") {
                    //       showClick({
                    //         type: "active-event",
                    //         eventID: event.eventID,
                    //       });
                    //     }
                    //   }
                    // }}
                    // key={`active${event.eventID}`}
                  />
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default HomeActiveEvents;
