import React, { useState, useEffect } from "react";
import "./CupFormatPicker.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PageHeader from "../display/Rows/PageHeader";

function CupFormatPicker({ goBack, chosenFormat = "", setFormat }) {
  const formats = [
    {
      value: "Matchplay",
      name: "Match Play",
      description:
        "In this format, two players take each other on in classic Net Matchplay.",
    },
    {
      value: "Fourball",
      name: "Fourball",
      description:
        "Two teams of two golfers go up against each other in a game of match play, with each team counting the lowest of their two individual scores. Golfers play their own balls",
    },
    // {
    //     value: 'Foursomes',
    //     name: 'Foursomes',
    //     description: 'Two teams of two golfers go up against each other in a game of match play. Teammates alternate playing the same ball on a given hole, as well as alternating tee shots.',
    // }
  ];

  useEffect(() => {
    // console.log(players)
    window.scrollTo(0, 0);
  }, []);

  const [selected, setSelected] = useState(chosenFormat);

  return (
    <div className="cup-format-picker format-picker">
      <div
        onClick={() => {
          // console.log(selected);
          setFormat(selected);
          goBack();
        }}
        className="back-row"
      >
        <ArrowBack />
        <p>Back</p>
      </div>
      <PageHeader text="Choose Format" />

      <div className="formats-list">
        {formats.map((format) => {
          let disabled = false;

          return (
            <div
              key={format.name}
              style={{ opacity: disabled ? "0.5" : "1" }}
              onClick={() => {
                if (disabled !== true) {
                  setSelected(format.value);
                }
              }}
              className={
                selected === format.value
                  ? "format-item fi-selected"
                  : "format-item"
              }
            >
              <h2>{format.name}</h2>
              <p>{format.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CupFormatPicker;
