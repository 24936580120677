import React, { useEffect, useState } from "react";
import "./BracketDisplay.css";

// Icons

// Components
import PageHeader from "../../components/display/Rows/PageHeader";
import BackRow from "../../components/display/Rows/BackRow";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import { db } from "../../firebase";

function getRound(currentRound, totalRounds) {
  const roundNames = [
    "First Round",
    "Round of 32",
    "Round of 16",
    "Quarter-finals",
    "Semi-Finals",
    "Final",
  ];

  // Check if the current round is within the range of the provided rounds
  if (currentRound > totalRounds || currentRound < 1) {
    return "Invalid round";
  }

  // Calculate the maximum number of rounds that can be represented with the available round names
  const maxRounds = roundNames.length;

  // Calculate the number of remaining rounds after the current round
  const remainingRounds = totalRounds - currentRound;

  // Calculate the index of the round name based on the remaining rounds
  const roundIndex = Math.min(maxRounds - remainingRounds, maxRounds - 1);

  // Return the corresponding round name
  return roundNames[roundIndex];
}

function BracketDisplay({ tournamentID = "", goBack = () => {} }) {
  const [loading, setLoading] = useState(true);

  const [bracket, setBracket] = useState([]);

  const [final, setFinal] = useState();

  useEffect(() => {
    loadBracket();
  }, []);

  async function loadBracket() {
    console.log(tournamentID);
    const tournamentDoc = await db
      .collection("tournaments")
      .doc(tournamentID)
      .get();
    const tournament = tournamentDoc.data();
    const { bracket } = tournament;

    if (!bracket || bracket.length === 0) {
      return setLoading(false);
    }

    setFinal(bracket[bracket.length - 1].matches[0]);

    setBracket(bracket);
    setLoading(false);
  }

  return (
    <div className="bracket-display">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Bracket Display" />

      <div className="bd-box">
        <div className="bd-left">
          {bracket.map((round, index) => {
            if (index === bracket.length - 1) {
              return null;
            }
            const matches = round.matches;
            console.log(matches);
            return (
              <div className="bd-round" key={`${index}bdleft`}>
                <div className="bdr-header">
                  <p>{getRound(index + 1, bracket.length + 1)}</p>
                </div>
                {matches.map((match, index2) => {
                  if (index2 >= matches.length / 2) {
                    return null;
                  }
                  const { players } = match;
                  console.log(players);
                  return (
                    <div className="bd-match" key={`${index2}bdlmatch`}>
                      <p className="match-pee">Match {match.match}</p>
                      <div className="bdm-box">
                        <div
                          className={
                            match.winnerID === players[0].playerID
                              ? "bdm-player bdm-active"
                              : "bdm-player"
                          }
                        >
                          <h1>{players[0].name}</h1>
                        </div>
                        <div className="bdm-vs">
                          {match.completed && match.rem === 0 ? (
                            <p>{match.by} UP</p>
                          ) : match.completed && match.rem > 0 ? (
                            <p>
                              {match.by} & {match.rem}
                            </p>
                          ) : (
                            <p>vs</p>
                          )}
                        </div>
                        <div
                          className={
                            match.winnerID === players[1].playerID
                              ? "bdm-player bdm-active"
                              : "bdm-player"
                          }
                        >
                          <h1>{players[1].name}</h1>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="bd-final">
          {final && (
            <div className="bd-round">
              <div className="bdr-header">
                <p>FINAL</p>
              </div>
              <div className="bd-final bd-match">
                <div className="bdm-box">
                  <div
                    className={
                      final.winnerID === final.players[0].playerID
                        ? "bdm-player bdm-active"
                        : "bdm-player"
                    }
                  >
                    <h1> {final.players[0].name}</h1>
                  </div>
                  <div className="bdm-vs">
                    {final.completed && final.rem === 0 ? (
                      <p>{final.by} UP</p>
                    ) : final.completed && final.rem > 0 ? (
                      <p>
                        {final.by} & {final.rem}
                      </p>
                    ) : (
                      <p>vs</p>
                    )}
                  </div>
                  <div
                    className={
                      final.winnerID === final.players[1].playerID
                        ? "bdm-player bdm-active"
                        : "bdm-player"
                    }
                  >
                    <h1> {final.players[1].name}</h1>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="bd-right">
          {bracket.map((round, index) => {
            if (index === bracket.length - 1) {
              return null;
            }
            const matches = round.matches;
            return (
              <div className="bd-round" key={`${index}bdleft`}>
                <div className="bdr-header">
                  <p>{getRound(index + 1, bracket.length + 1)}</p>
                </div>{" "}
                {matches.map((match, index2) => {
                  if (index2 < matches.length / 2) {
                    return null;
                  }
                  const { players } = match;
                  console.log(players);
                  return (
                    <div className="bd-match" key={`${index2}bdlmatch`}>
                      <p className="match-pee">Match {match.match}</p>
                      <div className="bdm-box">
                        <div
                          className={
                            match.winnerID === players[0].playerID
                              ? "bdm-player bdm-active"
                              : "bdm-player"
                          }
                        >
                          <h1>{players[0].name}</h1>
                        </div>
                        <div className="bdm-vs">
                          {match.completed && match.rem === 0 ? (
                            <p>{match.by} UP</p>
                          ) : match.completed && match.rem > 0 ? (
                            <p>
                              {match.by} & {match.rem}
                            </p>
                          ) : (
                            <p>vs</p>
                          )}
                        </div>
                        <div
                          className={
                            match.winnerID === players[1].playerID
                              ? "bdm-player bdm-active"
                              : "bdm-player"
                          }
                        >
                          <h1>{players[1].name}</h1>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BracketDisplay;
