import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { useCookies } from "react-cookie";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// Comps
import BackRow from "../../display/Rows/BackRow";
import BackdropLoader from "../../Loaders/BackdropLoader";
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Checkbox, Dialog } from "@mui/material";
import ScoreInput from "../ScoreInput/ScoreInput";
import TEDResultInput from "./TEDResultInput";
import TeamResultUpdate from "./TeamResultUpdate";
import LiveTeeChange from "./LiveTeeChange";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import EventHandicapEdit from "./EventHandicapEdit";
import MatchplayHoleResults from "./MatchplayHoleResults";
import BBSResult from "./BBSResult";
import { Settings } from "@mui/icons-material";

function TeamLiveGroups({
  roundID = "",
  goBack = () => {},
  eventID = "",
  eventName = "",
  scorerID = "",
  roundIDs = [],
}) {
  useEffect(() => {
    loadMatches({ roundID });
  }, []);
  const [cookies, setCookie, removeCookie] = useCookies(["lg-settings"]);

  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bbs, setBBS] = useState(false);
  const [scratchMode, setScratchode] = useState(false);
  const [settings, setSettings] = useState({
    showTeam: true,
    hideComplete: false,
    hideTees: false,
  });
  const [teamMap, setTeamMap] = useState({});
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    // console.log(roundIDs);
    const arr = [];
    for (let i = 0; i < roundIDs.length; i++) {
      const roundID = roundIDs[i];
      arr.push({ label: `Round ${i + 1}`, value: roundID });
    }
    setRounds(arr);
    setActiveRoundID(roundID);
  }, []);

  useEffect(() => {
    if (cookies["lg-settings"]) {
      // console.log(cookies["lg-settings"]);
      setSettings(cookies["lg-settings"]);
    }
  }, []);

  const [rounds, setRounds] = useState([]);
  const [activeRoundID, setActiveRoundID] = useState();

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState(false);

  const [groups, setGroups] = useState([]);

  const [error, setError] = useState("");

  const [scrollPos, setScrollPos] = useState(0);

  const [format, setFormat] = useState("");

  async function loadMatches({ roundID }) {
    setLoading(true);

    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const matchups = event.matchups ?? [];

    const mep = {};
    for (let i = 0; i < matchups.length; i++) {
      const matchup = matchups[i];
      const { teamOne, teamTwo } = matchup;
      mep[teamOne.teamID] = teamOne;
      mep[teamTwo.teamID] = teamTwo;
    }
    // console.log(mep);
    setTeamMap(mep);

    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (snapshot) => {
        const doc = snapshot.data();
        const { matches, format } = doc;
        setBBS(doc.betterballStableford);
        // console.log(format);
        setScratchode(doc.scratchMode ?? false);
        setFormat(format);
        setGroups(matches ?? []);

        setLoading(false);
      });
    setLoading(false);
  }

  return (
    <div className="live-groups team-live-groups">
      {show ? (
        toShow
      ) : (
        <>
          <Dialog
            open={showSettings}
            onClose={() => {
              setCookie("lg-settings", settings);
              setShowSettings(false);
            }}
          >
            <div className="sett-dia ">
              <PageHeader
                text="Settings"
                showClose
                close={() => {
                  setCookie("lg-settings", settings);
                  setShowSettings(false);
                }}
              />

              <div className="set-dia-ctt pd-20">
                <div className="eve-rds">
                  <div className="eve-rd-item">
                    <Checkbox
                      checked={settings.showTeam}
                      onChange={(e) => {
                        setSettings((c) => {
                          c.showTeam = !c.showTeam;
                          return c;
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>Display Team Names</p>
                  </div>

                  <div className="eve-rd-item">
                    <Checkbox
                      checked={settings.hideComplete}
                      onChange={(e) => {
                        setSettings((c) => {
                          c.hideComplete = !c.hideComplete;
                          return c;
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>Hide Complete Groups</p>
                  </div>
                  <div className="eve-rd-item">
                    <Checkbox
                      checked={settings.hideTees}
                      onChange={(e) => {
                        setSettings((c) => {
                          c.hideTees = !c.hideTees;
                          return c;
                        });
                        setUpdate((c) => c + 1);
                      }}
                    />
                    <p>Hide Tees</p>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <BackRow action={goBack} />
          {loading && <BackdropLoader />}
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          <div className="sett-ph flex flex-betw">
            <PageHeader text={`Live Group View - ${eventName}`} />
            <Settings
              className="dg-icon-bg icon-cursor sett-ph-ico"
              onClick={() => {
                setShowSettings(true);
              }}
            />
          </div>{" "}
          {rounds.length > 1 && (
            <div className="input-group fp-drop pl-20">
              <p className="ne-header">Select Round</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={activeRoundID}
                options={rounds}
                onChange={(e) => {
                  // console.log(e);
                  setActiveRoundID(e.value);
                  loadMatches({ roundID: e.value });
                }}
              />
            </div>
          )}
          <div className="drop-groups">
            {format === "Fourball" &&
              groups.map((group, i) => {
                // console.log(group);
                if (settings.hideComplete) {
                  if (group.completed) {
                    return null;
                  }
                }
                const { teamOne, teamTwo } = group;
                if (scorerID !== "") {
                  if (
                    group.teamOneID !== scorerID &&
                    group.teamTwoID !== scorerID
                  ) {
                    return null;
                  }
                }

                const { teamOneID, teamTwoID } = group;

                let team1 = teamMap[teamOneID] ? teamMap[teamOneID].name : "";
                let team2 = teamMap[teamTwoID] ? teamMap[teamTwoID].name : "";

                return (
                  <div
                    key={i}
                    className={
                      group.completed ? "drop-group dg-comp" : "drop-group"
                    }
                  >
                    <div className="group-header">
                      <div className="gh-flex">
                        <div className="flex-align-center">
                          <h2 className="ne-header">Match {i + 1}</h2>
                          <div className="flex-align-center ml-20 rpt-box">
                            <GolfCourseIcon className="dg-icon rp-thru-ico" />
                            <div className="rp-thru">
                              <p>{group.thru}</p>
                            </div>
                          </div>
                        </div>

                        {group.completed && (
                          <>
                            <CheckCircleIcon className="dg-icon mr-20 " />
                          </>
                        )}
                        <div className="flex-align-center">
                          {!bbs && (
                            <div
                              onClick={() => {
                                setToShow(
                                  <MatchplayHoleResults
                                    eventID={eventID}
                                    defaultMatch={group}
                                    format={format}
                                    goBack={() => setShow(false)}
                                    roundID={roundID}
                                  />
                                );
                                setShow(true);
                              }}
                              className="black-link mr-10 "
                            >
                              Holes
                            </div>
                          )}
                          {/* <div
                            onClick={() => {
                              setToShow(
                                <TeamResultUpdate
                                  defaultMatch={group}
                                  goBack={() => setShow(false)}
                                  format={format}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="black-link mr-10 "
                          >
                            Score
                          </div> */}
                          {!bbs && (
                            <div
                              onClick={() => {
                                setToShow(
                                  <TEDResultInput
                                    eventID={eventID}
                                    defaultMatch={group}
                                    goBack={() => setShow(false)}
                                    format={format}
                                    roundID={roundID}
                                  />
                                );
                                setShow(true);
                              }}
                              className="green-link mr-10 "
                            >
                              Result
                            </div>
                          )}
                          {bbs && (
                            <div
                              onClick={() => {
                                setToShow(
                                  <BBSResult
                                    eventID={eventID}
                                    defaultMatch={group}
                                    goBack={() => setShow(false)}
                                    roundID={roundID}
                                  />
                                );
                                setShow(true);
                              }}
                              className="green-link mr-10 "
                            >
                              Result
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dg-players">
                      {teamOne.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        const { thru } = group;
                        // console.log(thru);
                        return (
                          <RoundPlayer
                            team={settings.showTeam ? team1 : ""}
                            showBg={group.leader === "teamOne"}
                            hcpAction={() => {
                              setToShow(
                                <EventHandicapEdit
                                  defaultPlayer={player}
                                  teamEvt
                                  courseID={group.courseID}
                                  live
                                  hide={() => setShow(false)}
                                  roundID={roundID}
                                  eventID={eventID}
                                  // defaultSR={true}
                                />
                              );
                              setShow(true);
                            }}
                            teeAction={() => {
                              setToShow(
                                <LiveTeeChange
                                  teamEvt
                                  roundID={roundID}
                                  hide={() => setShow(false)}
                                  eventID={eventID}
                                  defaultPlayer={player}
                                />
                              );
                              setShow(true);
                            }}
                            onlyScore={thru > 0 || bbs}
                            finished={player.thru === 18}
                            guest={player.playerID.includes("guest")}
                            scoreAction={() => {
                              setScrollPos(window.scrollY);
                              setToShow(
                                <ScoreInput
                                  matchplay
                                  teamEvt
                                  roundID={roundID}
                                  eventID={eventID}
                                  eventName={eventName}
                                  defaultPlayer={player}
                                  goBack={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            // thru={thru === 0 || thru === 18 ? "" : thru}
                            liveGroup
                            hideTee={settings.hideTees}
                            name={player.name}
                            tee={player.tee}
                            handicap={scratchMode ? 0 : player.handicap}
                            key={player.playerID}
                          />
                        );
                      })}
                      {!bbs &&
                        group.thru !== 0 &&
                        !group.completed &&
                        group.leader !== "" && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p
                              className={
                                group.leader === "teamOne"
                                  ? "tlg-gr"
                                  : "tlg-red"
                              }
                            >
                              {group.score}{" "}
                              {group.leader === "teamOne" ? "Up" : "Down"}
                            </p>
                          </div>
                        )}
                      {bbs && group.thru !== 0 && !group.completed && (
                        <div className="tlg-ms flex-center vs-pee-pee">
                          <p
                            className={
                              group.leader === "teamOne"
                                ? "tlg-gr"
                                : group.leader === ""
                                ? "tlg-black"
                                : "tlg-red"
                            }
                          >
                            {group.teamOnePoints} / {group.teamTwoPoints}
                            {/* {group.leader === "teamOne" ? "Up" : "Down"} */}
                          </p>
                        </div>
                      )}
                      {group.completed && (
                        <div className="tlg-ms flex-center vs-pee-pee">
                          <p
                            className={
                              group.leader === "teamOne"
                                ? "tlg-gr"
                                : group.leader === "tied" || group.leader === ""
                                ? "tlg-black"
                                : "tlg-red"
                            }
                          >
                            {group.score === 0 && "Tied"}
                            {group.score === 1 && "1 Up"}
                            {group.score === 2 && group.thru === 18 && "2 Up"}
                            {group.score === 2 && group.thru === 17 && "2 & 1"}
                            {group.score > 2 &&
                              `${group.score} & ${18 - group.thru}`}
                          </p>
                        </div>
                      )}
                      {!bbs &&
                        group.thru !== 0 &&
                        !group.completed &&
                        group.leader === "" && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p className={"tlg-black"}>Tied</p>
                          </div>
                        )}
                      {group.thru === 0 && (
                        <div className="vs-pee-pee flex-center">
                          <p>vs</p>
                        </div>
                      )}
                      {teamTwo.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        const { thru } = group;
                        return (
                          <RoundPlayer
                            hideTee={settings.hideTees}
                            team={settings.showTeam ? team2 : ""}
                            showBg={group.leader === "teamTwo"}
                            hcpAction={() => {
                              setToShow(
                                <EventHandicapEdit
                                  defaultPlayer={player}
                                  teamEvt
                                  courseID={group.courseID}
                                  live
                                  hide={() => setShow(false)}
                                  roundID={roundID}
                                  eventID={eventID}
                                  // defaultSR={true}
                                />
                              );
                              setShow(true);
                            }}
                            teeAction={() => {
                              setToShow(
                                <LiveTeeChange
                                  teamEvt
                                  roundID={roundID}
                                  hide={() => setShow(false)}
                                  eventID={eventID}
                                  defaultPlayer={player}
                                />
                              );
                              setShow(true);
                            }}
                            onlyScore={thru > 0 || bbs}
                            finished={player.thru === 18}
                            guest={player.playerID.includes("guest")}
                            scoreAction={() => {
                              setScrollPos(window.scrollY);
                              setToShow(
                                <ScoreInput
                                  matchplay
                                  roundID={roundID}
                                  eventID={eventID}
                                  eventName={eventName}
                                  defaultPlayer={player}
                                  goBack={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            // thru={thru === 0 || thru === 18 ? "" : thru}
                            liveGroup
                            name={player.name}
                            tee={player.tee}
                            handicap={scratchMode ? 0 : player.handicap}
                            key={player.playerID}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}

            {format === "Matchplay" &&
              groups.map((group, i) => {
                const p1TI = group.playerOne.teamID ?? group.teamOneID;
                const p2TI = group.playerTwo.teamID ?? group.teamTwoID;

                // console.log(p1TI, p2TI, teamMap);

                let teamName1 = teamMap[p1TI] ? teamMap[p1TI].name : "";
                let teamName2 = teamMap[p2TI] ? teamMap[p2TI].name : "";
                if (settings.hideComplete) {
                  if (group.completed) {
                    return null;
                  }
                }
                return (
                  <div
                    key={i}
                    className={
                      group.completed ? "drop-group dg-comp" : "drop-group"
                    }
                  >
                    <div className="group-header">
                      <div className="gh-flex">
                        <div className="flex-align-center">
                          <h2 className="ne-header">Match {i + 1}</h2>
                          <div className="flex-align-center ml-20 rpt-box">
                            <GolfCourseIcon className="dg-icon rp-thru-ico" />
                            <div className="rp-thru">
                              <p>{group.thru}</p>
                            </div>
                          </div>
                        </div>

                        {group.completed && (
                          <>
                            <CheckCircleIcon className="dg-icon mr-20 " />
                          </>
                        )}
                        <div className="flex-align-center">
                          <div
                            onClick={() => {
                              setToShow(
                                <MatchplayHoleResults
                                  eventID={eventID}
                                  defaultMatch={group}
                                  format={format}
                                  goBack={() => setShow(false)}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="black-link mr-10 "
                          >
                            Holes
                          </div>
                          {/* <div
                            onClick={() => {
                              setToShow(
                                <TeamResultUpdate
                                  defaultMatch={group}
                                  goBack={() => setShow(false)}
                                  format={format}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="black-link mr-10 "
                          >
                            Score
                          </div> */}
                          <div
                            onClick={() => {
                              setToShow(
                                <TEDResultInput
                                  eventID={eventID}
                                  defaultMatch={group}
                                  goBack={() => setShow(false)}
                                  format={format}
                                  roundID={activeRoundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="green-link mr-10 "
                          >
                            Result
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dg-players">
                      <RoundPlayer
                        hideTee={settings.hideTees}
                        team={settings.showTeam ? teamName1 : ""}
                        hcpAction={() => {
                          setToShow(
                            <EventHandicapEdit
                              defaultPlayer={group.playerOne}
                              teamEvt
                              courseID={group.courseID}
                              live
                              hide={() => setShow(false)}
                              roundID={roundID}
                              eventID={eventID}

                              // defaultSR={true}
                            />
                          );
                          setShow(true);
                        }}
                        teeAction={() => {
                          setToShow(
                            <LiveTeeChange
                              teamEvt
                              roundID={roundID}
                              hide={() => setShow(false)}
                              eventID={eventID}
                              defaultPlayer={group.playerOne}
                            />
                          );
                          setShow(true);
                        }}
                        // onlyScore={group.thru > 0}
                        showBg={group.playerOne.playerID === group.leader}
                        finished={group.playerOne.thru === 18}
                        guest={group.playerOne.playerID.includes("guest")}
                        scoreAction={() => {
                          setScrollPos(window.scrollY);
                          setToShow(
                            <ScoreInput
                              matchplay
                              teamEvt
                              roundID={roundID}
                              eventID={eventID}
                              eventName={eventName}
                              defaultPlayer={group.playerOne}
                              goBack={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                            />
                          );
                          setShow(true);
                        }}
                        // thru={
                        //   group.thru === 0 || group.thru === 18
                        //     ? ""
                        //     : group.thru
                        // }
                        liveGroup
                        name={group.playerOne.name}
                        tee={group.playerOne.tee}
                        handicap={scratchMode ? 0 : group.playerOne.handicap}
                        key={group.playerOne.playerID}
                      />
                      {group.thru !== 0 &&
                        !group.completed &&
                        group.leader !== "" && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p
                              className={
                                group.leader === group.playerOne.playerID
                                  ? "tlg-gr"
                                  : "tlg-red"
                              }
                            >
                              {group.score}{" "}
                              {group.leader === group.playerOne.playerID
                                ? "Up"
                                : "Down"}
                            </p>
                          </div>
                        )}
                      {group.completed && (
                        <div className="tlg-ms flex-center vs-pee-pee">
                          <p
                            className={
                              group.leader === group.playerOne.playerID
                                ? "tlg-gr"
                                : group.leader === ""
                                ? "tlg-black"
                                : "tlg-red"
                            }
                          >
                            {group.score === 0 && "Tied"}
                            {group.score === 1 && "1 Up"}
                            {group.score === 2 && group.thru === 18 && "2 Up"}
                            {group.score === 2 && group.thru === 17 && "2 & 1"}
                            {group.score > 2 &&
                              `${group.score} & ${18 - group.thru}`}
                          </p>
                        </div>
                      )}
                      {group.thru !== 0 &&
                        group.leader === "" &&
                        !group.completed && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p className={"tlg-black"}>Tied</p>
                          </div>
                        )}
                      {group.thru === 0 && (
                        <div className="vs-pee-pee flex-center">
                          <p>vs</p>
                        </div>
                      )}
                      <RoundPlayer
                        hideTee={settings.hideTees}
                        team={settings.showTeam ? teamName2 : ""}
                        hcpAction={() => {
                          setToShow(
                            <EventHandicapEdit
                              defaultPlayer={group.playerTwo}
                              teamEvt
                              courseID={group.courseID}
                              live
                              hide={() => setShow(false)}
                              roundID={roundID}
                              eventID={eventID}
                              // defaultSR={true}
                            />
                          );
                          setShow(true);
                        }}
                        teeAction={() => {
                          setToShow(
                            <LiveTeeChange
                              teamEvt
                              roundID={roundID}
                              hide={() => setShow(false)}
                              eventID={eventID}
                              defaultPlayer={group.playerTwo}
                            />
                          );
                          setShow(true);
                        }}
                        // onlyScore={group.thru > 0}
                        showBg={group.playerTwo.playerID === group.leader}
                        finished={group.playerTwo.thru === 18}
                        guest={group.playerTwo.playerID.includes("guest")}
                        scoreAction={() => {
                          setScrollPos(window.scrollY);
                          setToShow(
                            <ScoreInput
                              matchplay
                              teamEvt
                              roundID={roundID}
                              eventID={eventID}
                              eventName={eventName}
                              defaultPlayer={group.playerTwo}
                              goBack={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                            />
                          );
                          setShow(true);
                        }}
                        // thru={
                        //   group.thru === 0 || group.thru === 18
                        //     ? ""
                        //     : group.thru
                        // }
                        liveGroup
                        name={group.playerTwo.name}
                        tee={group.playerTwo.tee}
                        handicap={scratchMode ? 0 : group.playerTwo.handicap}
                        key={group.playerTwo.playerID}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default TeamLiveGroups;
