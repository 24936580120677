import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import BackdropLoader from "../../Loaders/BackdropLoader";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ErrorModal from "../../ErrorModal/ErrorModal";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

function PlayerCourseSelector({
  eventID = "",
  goBack = () => {},
  defaultCourse,
}) {
  // State
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState("");
  const [upd, setUp] = useState(0);
  function update() {
    setUp((c) => c + 1);
  }
  const [search, setSearch] = useState("");
  const [courses, setCourses] = useState([]);

  const [rounds, setRounds] = useState([]);
  const [selRound, setSelRound] = useState(0);
  const [roundOpts, setRoundOpts] = useState([]);

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const { playerIDs, rounds } = event;

    const { courses } = event;
    const guests = event.guests ?? [];

    const courseOpts = courses.map((course) => {
      const tr = {
        value: course.courseID ?? course.value,
        label: course.name ?? course.label,
      };
      return tr;
    });
    console.log(courseOpts);
    setCourses(courseOpts);

    for (let i = 0; i < rounds.length; i++) {
      if (rounds[i].courseSelections === undefined) {
        rounds[i].courseSelections = {};
      }
    }

    // console.log(rounds);

    const ropts = rounds.map((rd, i) => {
      const toRef = {
        value: i,
        label: `Round ${i + 1}`,
      };
      return toRef;
    });
    console.log(ropts);
    setRoundOpts(ropts);
    // setSelRound(ropts[0]);

    setRounds(rounds);

    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);
    const arr = [];

    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName } = player;
      const name = `${firstName} ${lastName}`;
      arr.push({
        playerID,
        name,
      });
    }

    for (let j = 0; j < guests.length; j++) {
      const guest = guests[j];
      console.log(guest);
      arr.push({
        playerID: guest.playerID ?? guest.guestID,
        name: guest.name ?? `${guest.firstName} ${guest.lastName}`,
      });
    }

    // for (let i = 0; i < )

    setPlayers(arr);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      const eventDoc = await db.collection("events").doc(eventID).get();
      const event = eventDoc.data();
      const evtRounds = event.rounds;

      for (let i = 0; i < evtRounds.length; i++) {
        evtRounds[i].courseSelections = rounds[i].courseSelections;
      }

      await eventDoc.ref.update({
        rounds: evtRounds,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="player-course-selector ">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Player Course Selector" />
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <div className="ec-box">
        <div className="input-group">
          <p>Select Round</p>
          <Dropdown
            options={roundOpts}
            value={selRound}
            onChange={(e) => setSelRound(e.value)}
          />
        </div>
        <div className="input-group">
          <p>Search</p>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="default-input"
            placeholder="Search for player..."
          />
        </div>{" "}
      </div>

      <div className="pcs-players pd-20">
        {players.map((player, i) => {
          console.log(rounds[selRound].courseSelections[player.playerID]);

          if (search !== "") {
            if (!player.name.toLowerCase().includes(search.toLowerCase())) {
              return null;
            }
          }

          return (
            <div key={player.playerID} className="pcs-player">
              <PlayerItem
                name={player.name}
                key={player.playerID}
                hideIcons
                noLink
                hideImage
                hideHCP
              />
              <Dropdown
                options={courses}
                value={
                  rounds[selRound].courseSelections[player.playerID]
                    ? rounds[selRound].courseSelections[player.playerID]
                    : defaultCourse
                }
                onChange={(e) => {
                  setRounds((c) => {
                    c[selRound].courseSelections[player.playerID] = e.value;
                    console.log(c);
                    return c;
                  });
                  update();
                }}
              />
            </div>
          );
        })}
      </div>

      <div className="flex-center mt-20 mb-40">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default PlayerCourseSelector;
