import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";
import "./ScorecardCreator.css";
import classNames from "classnames";

// Icons

// Components
import { Checkbox, Dialog } from "@mui/material";
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import CustomDrop from "../CustomDrop/CustomDrop";

import RoundPlayer from "../RoundPlayer/RoundPlayer";

function ScorecardCreator({ eventID = "", goBack = () => {} }) {
  // Admin
  const cardCall = admin.functions().httpsCallable("printScorecard");

  // State
  const [loading, setLoading] = useState(true);
  const [selView, setSelView] = useState("groups");
  const [upd, setUpdate] = useState(0);
  function update() {
    setUpdate((c) => c + 1);
  }
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [courses, setCourses] = useState([]);
  const [courseMap, setCourseMap] = useState({});
  const [rounds, setRounds] = useState([]);
  const [singlesAllowance, setSinglesAllowance] = useState(100);
  const [teamAllowance, setTeamAllowance] = useState(100);

  const [handicapLimit, setHandicapLimit] = useState(36);
  const [handicapIndexLimit, setHandicapIndexLimit] = useState(54);

  const [adjusted, setAdjusted] = useState(false);

  const [selecting, setSelecting] = useState(false);
  const [selGroup, setSelGroup] = useState("");
  const [selPlayers, setSelPlayers] = useState([]);

  const [cardType, setCardType] = useState("portrait");

  const [settings, setSettings] = useState({
    displayYardages: true,
    displayStrokes: true,
    displayTime: false,
    teamHandicap: false,
    twoSpaces: false,
    displayDate: true,
    displayTitle: true,
    resultColumn: false,
    courseSlope: true,
    strokeDots: false,
    evtImage: false,
  });
  const [selRound, setSelRound] = useState("0");
  const [roundOpts, setRoundOpts] = useState([]);
  const [playerMap, setPlayerMap] = useState({});
  const [selCourse, setSelCourse] = useState({ teeArray: [] });
  const [selTees, setSelTees] = useState([]);
  const [players, setPlayers] = useState([]);

  // UE
  useEffect(() => {
    loadDetails();
  }, []);

  // Functions
  async function loadDetails() {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();

    const courses = [];
    const courseMap = {};

    setHandicapIndexLimit(event.handicapIndexLimit ?? 54);
    setHandicapLimit(event.handicapLimit ? parseInt(event.handicapLimit) : 36);
    setAdjusted(event.adjusted ?? false);

    setSettings((c) => {
      c.courseSlope = event.sr ?? false;
      return c;
    });

    if (event.courses) {
      for (let i = 0; i < event.courses.length; i++) {
        const course = event.courses[i];
        const courseID = course.courseID;
        const courseDoc = await db.collection("courses").doc(courseID).get();
        const courseData = courseDoc.data();
        const {
          firstNine,
          lastNine,
          frontNineLength,
          backNineLength,
          length,
          par,
          tees,
          teeArray,
          name,
          femSlope,
          femRatings,
        } = courseData;
        courseMap[courseID] = {
          firstNine,
          lastNine,
          frontNineLength,
          backNineLength,
          length,
          par,
          tees,
          teeArray,
          name,
          slope: courseData.slope,
          ratings: courseData.ratings,
          femSlope,
          courseID,
          femRatings,
        };
        courses.push({
          firstNine,
          lastNine,
          frontNineLength,
          backNineLength,
          length,
          par,
          tees,
          teeArray,
          name,
          courseID,
          slope: courseData.slope,
          ratings: courseData.ratings,
          femSlope,
          femRatings,
        });
      }
    } else {
      const courseID = event.courseID;
      const courseDoc = await db.collection("courses").doc(courseID).get();
      const courseData = courseDoc.data();
      const {
        firstNine,
        lastNine,
        frontNineLength,
        backNineLength,
        length,
        par,
        tees,
        teeArray,
        name,
      } = courseData;
      courseMap[courseID] = {
        firstNine,
        lastNine,
        frontNineLength,
        backNineLength,
        length,
        par,
        tees,
        teeArray,
        name,
        slope: courseData.slope ?? {},
        ratings: courseData.ratings ?? {},
        courseID,
      };
      courses.push({
        firstNine,
        lastNine,
        frontNineLength,
        backNineLength,
        length,
        par,
        tees,
        teeArray,
        name,
        courseID,
      });
    }
    setSinglesAllowance(event.singlesAllowance ?? 100);
    setTeamAllowance(event.teamAllowance ?? 100);
    setSelTees(courses[0].teeArray);
    setCourseMap(courseMap);
    setCourses(courses);
    setSelCourse(courses[0]);

    const { rounds, playerIDs } = event;
    const pmep = {};
    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);
    const arr = [];
    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName, profileImage } = player;
      const handicap = player.handicap;
      const name = `${firstName} ${lastName}`;
      arr.push({
        playerID,
        name,
        handicap,
        profileImage,
        gender: player.gender ?? "male",
      });
      pmep[playerID] = {
        playerID,
        name,
        handicap,
        profileImage,
        gender: player.gender ?? "male",
      };
    }

    for (let i = 0; i < rounds.length; i++) {
      const round = rounds[i];
      const timeMap = {};
      const groups = round.groups ?? [];
      for (let j = 0; j < groups.length; j++) {
        const group = groups[j];
        const { players, time } = group;
        for (let x = 0; x < players.length; x++) {
          const playerID = players[x].playerID;
          timeMap[playerID] = time;
        }
      }
      rounds[i].timeMap = timeMap;
    }

    const rdOpts = rounds.map((rd, i) => {
      const toRet = {
        label: `Round ${i + 1}`,
        value: i.toString(),
      };
      return toRet;
    });

    setRoundOpts(rdOpts);

    setPlayerMap(pmep);
    // console.log(rounds);
    setPlayers(arr);
    setRounds(rounds);
    setLoading(false);
  }

  async function callCard({ type = "", sel }) {
    console.log(sel);
    setLoading(true);
    try {
      const card = await cardCall({
        cardType,
        playerType: selView,
        course: selCourse,
        displayStrokes: settings.displayStrokes,
        displayTime: settings.displayTime,
        displayYardages: settings.displayYardages,
        teamHandicap: settings.teamHandicap,
        twoSpaces: settings.twoSpaces,
        displayDate: settings.displayDate,
        displayTitle: settings.displayTitle,
        resultColumn: settings.resultColumn,
        strokeDots: settings.strokeDots,
        showImage: settings.evtImage,
        type,
        sel,
        eventID,
        roundIndex: selRound,
        selectedTees: selTees,
      });
      const url = card.data;
      window.open(url, "_blank");
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="scorecard-creator">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <BackRow action={goBack} />
      <PageHeader text="Scorecard Creator" />
      <div className="ph-msg">
        <p>Create a custom scorecard for your players</p>
      </div>

      <div className="sc-set-box">
        {false && (
          <div>
            <div className="neh-box">
              <h3 className="ne-header msb">Card Type</h3>
            </div>
            <div className="selector-group pl-20">
              <button
                onClick={() => setCardType("portrait")}
                className={
                  cardType === "portrait" ? "sg-active sg-right" : "sg-right"
                }
              >
                Portrait
              </button>
              <button
                onClick={() => setCardType("landscape")}
                className={
                  cardType === "landscape" ? "sg-middle sg-active" : "sg-middle"
                }
              >
                Landscape
              </button>
            </div>
          </div>
        )}
        <div>
          <div className="neh-box">
            <h3 className="ne-header msb">Settings</h3>
          </div>
          <div className="sc-setts">
            <div className="eve-rds">
              {false && selCourse.slope !== undefined && (
                <div className="eve-rd-item">
                  <Checkbox
                    checked={settings.courseSlope}
                    onChange={(e) => {
                      setSettings((c) => {
                        c.courseSlope = !c.courseSlope;
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                  />
                  <p>Course Slope & Ratings</p>
                </div>
              )}
              {teamAllowance !== 100 && (
                <div
                  className="eve-rd-item"
                  onClick={() => {
                    setSettings((c) => {
                      c.teamHandicap = !c.teamHandicap;
                      return c;
                    });
                    setUpdate((c) => c + 1);
                  }}
                >
                  <Checkbox
                    checked={settings.teamHandicap}
                    // onChange={(e) => {
                    //   setSettings((c) => {
                    //     c.teamHandicap = !c.teamHandicap;
                    //     return c;
                    //   });
                    //   setUpdate((c) => c + 1);
                    // }}
                  />
                  <p>Team Allowance</p>
                </div>
              )}
              <div
                className="eve-rd-item"
                onClick={() => {
                  setSettings((c) => {
                    c.twoSpaces = !c.twoSpaces;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.twoSpaces}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.twoSpaces = !c.twoSpaces;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Two Blocks / Hole</p>
              </div>
              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.resultColumn = !c.resultColumn;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.resultColumn}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.resultColumn = !c.resultColumn;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Result Column</p>
              </div>
              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.strokeDots = !c.strokeDots;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.strokeDots}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.strokeDots = !c.strokeDots;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Stroke Circles</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="neh-box">
            <h3 className="ne-header msb">Display</h3>
          </div>

          <div className="sc-setts">
            <div className="eve-rds">
              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.displayStrokes = !c.displayStrokes;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.displayStrokes}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.displayStrokes = !c.displayStrokes;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Strokes</p>
              </div>
              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.displayYardages = !c.displayYardages;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.displayYardages}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.displayYardages = !c.displayYardages;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Yardages</p>
              </div>

              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.displayDate = !c.displayDate;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.displayDate}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.displayDate = !c.displayDate;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Date</p>
              </div>
              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.displayTime = !c.displayTime;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.displayTime}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.displayTime = !c.displayTime;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Tee Time</p>
              </div>
              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.displayTitle = !c.displayTitle;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.displayTitle}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.displayTitle = !c.displayTitle;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Event Title</p>
              </div>
              <div
                className="eve-rd-item"
                onClick={(e) => {
                  setSettings((c) => {
                    c.evtImage = !c.evtImage;
                    return c;
                  });
                  setUpdate((c) => c + 1);
                }}
              >
                <Checkbox
                  checked={settings.evtImage}
                  // onChange={(e) => {
                  //   setSettings((c) => {
                  //     c.evtImage = !c.evtImage;
                  //     return c;
                  //   });
                  //   setUpdate((c) => c + 1);
                  // }}
                />
                <p>Event Image</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="neh-box">
            <h2 className="ne-header msb">Tees</h2>
          </div>
          <div className="sc-setts">
            <div className="eve-rds">
              {selCourse.teeArray.map((tee, i) => {
                return (
                  <div
                    className="eve-rd-item"
                    key={tee}
                    onClick={(e) => {
                      setSelTees((c) => {
                        if (!c.includes(tee)) {
                          c.push(tee);

                          return c;
                        } else {
                          const filt = c.filter((item) => item !== tee);
                          return filt;
                        }
                      });
                      setUpdate((c) => c + 1);
                    }}
                  >
                    <Checkbox
                      checked={selTees.includes(tee)}
                      // onChange={(e) => {
                      //   setSelTees((c) => {
                      //     if (!c.includes(tee)) {
                      //       c.push(tee);

                      //       return c;
                      //     } else {
                      //       const filt = c.filter((item) => item !== tee);
                      //       return filt;
                      //     }
                      //   });
                      //   setUpdate((c) => c + 1);
                      // }}
                    />
                    <p style={{ textTransform: "capitalize" }}>{tee}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-align-center">
        {roundOpts.length > 1 && (
          <div className="input-group ml-20 mr-20">
            <p>Select Round</p>
            <CustomDrop
              value={selRound}
              onChange={(e) => setSelRound(e.value)}
              options={roundOpts}
            />
          </div>
        )}

        <div className="selector-group pl-20">
          <button
            onClick={() => setSelView("groups")}
            className={selView === "groups" ? "sg-active sg-right" : "sg-right"}
          >
            Groups
          </button>
          <button
            onClick={() => setSelView("players")}
            className={
              selView === "players" ? "sg-middle sg-active" : "sg-middle"
            }
          >
            Players
          </button>
        </div>

        <div className="input-group ml-40">
          <p>Search for player</p>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="default-input"
            placeholder="Start typing..."
          />
        </div>
      </div>

      {selView === "groups" && (
        <div className="drop-groups">
          {rounds[selRound] &&
            rounds[selRound].groups.map((group, i) => {
              const { players } = group;
              const round = rounds[selRound];

              // console.log(settings.courseSlope, selCourse);

              for (let j = 0; j < players.length; j++) {
                const pl = players[j];
                const { playerID } = pl;
                const player = playerMap[playerID];

                const teeMap = round.teeMap ?? {};
                const playerHandicaps = round.playerHandicaps ?? {};
                const playerIndexes = round.playerIndexes ?? {};

                let playingHandicap = "";
                let playerTee = teeMap[playerID] ?? "";

                if (playerHandicaps[playerID]) {
                  playingHandicap = playerHandicaps[playerID];
                } else {
                  if (selCourse.ratings && settings.courseSlope) {
                    const {
                      ratings,
                      slope,
                      teeArray,
                      par,
                      femSlope,
                      femRatings,
                    } = selCourse;

                    let hcpIndex = playerIndexes[playerID] ?? player.handicap;
                    if (hcpIndex > handicapIndexLimit) {
                      hcpIndex = handicapIndexLimit;
                    }
                    if (typeof hcpIndex === "string") {
                      hcpIndex = parseFloat(hcpIndex);
                    }
                    if (player.gender === "male") {
                      if (playerTee === "") {
                        if (teeArray.includes("white")) {
                          playerTee = "white";
                        } else {
                          playerTee = teeArray[0];
                        }
                      }

                      let ps = slope[playerTee];
                      if (typeof ps === "string") {
                        ps = parseFloat(ps);
                      }
                      let pr = ratings[playerTee];
                      if (typeof pr === "string") {
                        pr = parseFloat(pr);
                      }

                      playingHandicap = Math.round(
                        (hcpIndex * ps) / 113 + pr - par[playerTee]
                      );
                      // console.log(playingHandicap);
                    } else {
                      if (playerTee === "") {
                        if (teeArray.includes("red")) {
                          playerTee = "red";
                        } else {
                          playerTee = teeArray[0];
                        }
                      }

                      let ps = femSlope[playerTee];
                      if (typeof ps === "string") {
                        ps = parseFloat(ps);
                      }
                      let pr = femRatings[playerTee];
                      if (typeof pr === "string") {
                        pr = parseFloat(pr);
                      }

                      playingHandicap = Math.round(
                        (hcpIndex * ps) / 113 + pr - par[playerTee]
                      );
                    }
                  } else {
                    if (playerIndexes[playerID]) {
                      playingHandicap = Math.round(playerIndexes[playerID]);
                    } else {
                      playingHandicap = Math.round(player.handicap);
                    }
                  }
                }

                if (playingHandicap > handicapLimit) {
                  playingHandicap = handicapLimit;
                }

                if (singlesAllowance !== 100) {
                  playingHandicap = Math.round(
                    (playingHandicap * singlesAllowance) / 100
                  );
                }

                if (adjusted) {
                  playingHandicap = Math.round((playingHandicap + 4) * 0.8);
                }

                player.courseHandicap = playingHandicap;

                if (teamAllowance !== 100 && settings.teamHandicap) {
                  playingHandicap = Math.round(
                    (playingHandicap * teamAllowance) / 100
                  );
                }

                player.playingHandicap = playingHandicap;
                player.playerTee = playerTee;
                player.handicapIndex = player.handicap;
                players[j] = player;
              }

              if (search !== "") {
                let fd = false;
                const sl = search.toLowerCase();
                for (let j = 0; j < players.length; j++) {
                  const player = players[j];
                  const { name } = player;
                  if (name.toLowerCase().includes(sl)) {
                    fd = true;
                  }
                }
                if (!fd) {
                  return null;
                }
              }
              // console.log(players);

              return (
                <div
                  className={classNames("drop-group sc-dg", {})}
                  onClick={() => {
                    // setSelGroup(i);
                    // update();
                  }}
                  key={i}
                >
                  <div className="group-header">
                    <div className="gh-flex">
                      <h2 className="ne-header">
                        Group {i + 1}
                        {group.time ? ` - ${group.time}` : ""}
                      </h2>
                      <div className="flex-align-center">
                        {!selecting && (
                          <div
                            onClick={() => {
                              setSelecting(true);
                              setSelGroup(i);
                              update();
                            }}
                            className="green-link mr-20"
                          >
                            Select
                          </div>
                        )}
                        {selecting && selGroup === i && (
                          <div
                            onClick={() => {
                              setSelecting(false);
                              setSelPlayers([]);
                              update();
                            }}
                            className="green-link mr-20"
                          >
                            Done
                          </div>
                        )}
                        {/* {!selecting && ( */}
                        <div
                          onClick={() => {
                            if (selecting) {
                              callCard({
                                type: "group",
                                sel: {
                                  players: selPlayers,
                                  time: group.time,
                                },
                              });
                              setSelecting(false);
                              setSelPlayers([]);
                              update();
                            } else {
                              callCard({ type: "group", sel: group });
                            }
                          }}
                          className="black-link"
                        >
                          Print Card
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  <div className="test-class">
                    {players.map((player, i) => {
                      // if (i === 0) {
                      //   console.log(rounds[selRound]);
                      // }
                      const { playerID } = player;
                      // const player = playerMap[playerID];

                      // const teeMap = round.teeMap ?? {};
                      // const playerHandicaps = round.playerHandicaps ?? {};
                      // const playerIndexes = round.playerIndexes ?? {};

                      // let playingHandicap = "";
                      // let playerTee = teeMap[playerID] ?? "";

                      // if (playerHandicaps[playerID]) {
                      //   playingHandicap = playerHandicaps[playerID];
                      // } else {
                      //   if (selCourse.ratings) {
                      //     const {
                      //       ratings,
                      //       slope,
                      //       teeArray,
                      //       par,
                      //       femSlope,
                      //       femRatings,
                      //     } = selCourse;

                      //     const hcpIndex =
                      //       playerIndexes[playerID] ?? player.handicap;
                      //     if (player.gender === "male") {
                      //       if (playerTee === "") {
                      //         if (teeArray.includes("white")) {
                      //           playerTee = "white";
                      //         } else {
                      //           playerTee = teeArray[0];
                      //         }
                      //       }

                      //       playingHandicap = Math.round(
                      //         (hcpIndex * slope[playerTee]) / 113 +
                      //           ratings[playerTee] -
                      //           par[playerTee]
                      //       );
                      //     } else {
                      //       if (playerTee === "") {
                      //         if (teeArray.includes("red")) {
                      //           playerTee = "red";
                      //         } else {
                      //           playerTee = teeArray[0];
                      //         }
                      //       }
                      //       playingHandicap = Math.round(
                      //         (hcpIndex * femSlope[playerTee]) / 113 +
                      //           femRatings[playerTee] -
                      //           par[playerTee]
                      //       );
                      //     }
                      //   } else {
                      //     if (playerIndexes[playerID]) {
                      //       playingHandicap = Math.round(
                      //         playerIndexes[playerID]
                      //       );
                      //     } else {
                      //       playingHandicap = Math.round(player.handicap);
                      //     }
                      //   }
                      // }

                      // if (singlesAllowance !== 100) {
                      //   playingHandicap = Math.round(
                      //     (playingHandicap * singlesAllowance) / 100
                      //   );
                      // }

                      // if (teamAllowance !== 100 && settings.teamHandicap) {
                      //   playingHandicap = Math.round(
                      //     (playingHandicap * teamAllowance) / 100
                      //   );
                      // }

                      return (
                        <RoundPlayer
                          // hideVert
                          scorecardAction={() => {
                            const time = round.timeMap[playerID];
                            player.time = time;
                            player.tee = player.playerTee;
                            const sel = { players: [player], time };
                            callCard({ type: "player", sel });
                          }}
                          scorecard
                          swapClick={() => {
                            setSelPlayers((c) => {
                              if (
                                c.map((pl, i) => pl.playerID).includes(playerID)
                              ) {
                                const filt = c.filter(
                                  (pl) => pl.playerID !== playerID
                                );
                                return filt;
                              } else {
                                c.push(player);
                                return c;
                              }
                            });
                            update();
                          }}
                          swapMode={selecting}
                          showBg={selPlayers
                            .map((pl, i) => pl.playerID)
                            .includes(playerID)}
                          tee={player.playerTee}
                          handicap={player.playingHandicap}
                          key={player.playerID}
                          playerID={player.playerID}
                          name={player.name}
                          hideVert={selecting}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {selView === "players" && (
        <div className="sc-players">
          {players.map((player, i) => {
            const round = rounds[selRound];
            const { playerID } = player;

            if (search !== "") {
              const sl = search.toLowerCase();
              const nl = player.name.toLowerCase();

              if (!nl.includes(sl)) {
                return null;
              }
            }

            const teeMap = round.teeMap ?? {};
            const playerHandicaps = round.playerHandicaps ?? {};
            const playerIndexes = round.playerIndexes ?? {};

            let playingHandicap = "";
            let playerTee = teeMap[playerID] ?? "";

            const { ratings, slope, teeArray, par, femSlope, femRatings } =
              selCourse;

            if (player.gender === "male") {
              if (playerTee === "") {
                if (teeArray.includes("white")) {
                  playerTee = "white";
                } else {
                  playerTee = teeArray[0];
                }
              }
            } else {
              if (playerTee === "") {
                if (teeArray.includes("red")) {
                  playerTee = "red";
                } else {
                  playerTee = teeArray[0];
                }
              }
            }

            if (playerHandicaps[playerID]) {
              playingHandicap = playerHandicaps[playerID];
            } else {
              if (selCourse.ratings && settings.courseSlope) {
                let hcpIndex = playerIndexes[playerID] ?? player.handicap;
                if (hcpIndex > handicapIndexLimit) {
                  hcpIndex = handicapIndexLimit;
                }
                if (player.gender === "male") {
                  if (playerTee === "") {
                    if (teeArray.includes("white")) {
                      playerTee = "white";
                    } else {
                      playerTee = teeArray[0];
                    }
                  }

                  let ps = slope[playerTee];
                  if (typeof ps === "string") {
                    ps = parseFloat(ps);
                  }
                  let pr = ratings[playerTee];
                  if (typeof pr === "string") {
                    pr = parseFloat(pr);
                  }

                  playingHandicap = Math.round(
                    (hcpIndex * ps) / 113 + pr - par[playerTee]
                  );
                } else {
                  if (playerTee === "") {
                    if (teeArray.includes("red")) {
                      playerTee = "red";
                    } else {
                      playerTee = teeArray[0];
                    }
                  }
                  let ps = femSlope[playerTee];
                  if (typeof ps === "string") {
                    ps = parseFloat(ps);
                  }
                  let pr = femRatings[playerTee];
                  if (typeof pr === "string") {
                    pr = parseFloat(pr);
                  }
                  playingHandicap = Math.round(
                    (hcpIndex * ps) / 113 + pr - par[playerTee]
                  );
                }
              } else {
                if (playerIndexes[playerID]) {
                  playingHandicap = Math.round(playerIndexes[playerID]);
                } else {
                  playingHandicap = Math.round(player.handicap);
                }
              }
            }

            if (playingHandicap > handicapLimit) {
              playingHandicap = handicapLimit;
            }

            if (singlesAllowance !== 100) {
              playingHandicap = Math.round(
                (playingHandicap * singlesAllowance) / 100
              );
            }

            let courseHandicap = playingHandicap;

            if (teamAllowance !== 100 && settings.teamHandicap) {
              playingHandicap = Math.round(
                (playingHandicap * teamAllowance) / 100
              );
            }

            return (
              <div key={player.playerID} className="rp-box">
                <RoundPlayer
                  scorecard
                  scorecardAction={() => {
                    const time = round.timeMap[playerID];
                    player.time = time;
                    player.tee = playerTee;
                    player.handicapIndex = player.handicap;
                    player.playingHandicap = playingHandicap;
                    player.courseHandicap = courseHandicap;
                    const sel = { players: [player], time };
                    callCard({ type: "player", sel });
                  }}
                  tee={playerTee}
                  handicap={playingHandicap}
                  playerID={player.playerID}
                  name={player.name}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ScorecardCreator;
