import React, { useState } from "react";
import Cancel from "@mui/icons-material/Cancel";
import classNames from "classnames";

function DrillImage({
  imageURL = "",
  deleteAct = () => {},
  selected = false,
  setSelected = () => {},
  description = () => {},
  setDescription = () => {},
}) {
  return (
    <div
      className={classNames("drill-image", {
        "dri-selected": selected,
      })}
    >
      {" "}
      <Cancel
        className={"drop-img-ico shadow-md"}
        onClick={() => {
          deleteAct();
        }}
      />
      <div
        onClick={setSelected}
        className="di-ctt flex flex-col flex-align-center"
      >
        <img alt="description" src={imageURL} />
        <textarea
          value={description}
          className="def-ip-ta"
          placeholder="Add a short description..."
          onChange={(e) => setDescription(e.target.value)}
        />{" "}
      </div>
    </div>
  );
}

export default DrillImage;
