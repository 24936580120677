import React from "react";
import { Country } from "country-state-city";
import { Edit } from "@mui/icons-material";

function ClubListPlayer({ player = {}, editAct = () => {} }) {
  return (
    <tr className="cl-player">
      <td>{player.firstName}</td>
      <td>{player.lastName}</td>
      <td>{player.playerClubName}</td>
      <td style={{ textTransform: "capitalize" }}>{player.gender}</td>
      <td>{player.handicap < 0 ? `+${-player.handicap}` : player.handicap}</td>
      <td>{player.hnaID}</td>
      <td>{player.clubHNAID}</td>
      <td>{player.lowHI}</td>
      <td>{player.emailAddress}</td>
      <td>{player.dateJoined}</td>
      <td>{player.dobString}</td>
      <td>{player.city}</td>
      <td>{player.state}</td>
      <td>{player.country}</td>
      <td>
        <Edit
          onClick={editAct}
          className="dg-icon icon-cursor clp-ico hgb-ico"
        />
      </td>
    </tr>
  );
}

export default ClubListPlayer;
