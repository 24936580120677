import React from "react";
import "./Buttons.css";

function Pill({ text, onClick, red = false }) {
  return (
    <div
      onClick={onClick}
      className={red ? "pill-button red-pill-button" : "pill-button"}
    >
      <p>{text}</p>
    </div>
  );
}

export default Pill;
