/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./PlayerRound.css";

import GolfCourseIcon from "@mui/icons-material/GolfCourse";

import { Avatar } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import AdjustIcon from "@mui/icons-material/Adjust";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import BackRow from "../display/Rows/BackRow";

import { useAuth } from "../../contexts/AuthContext";

function MultiplePlayerRound({
  goBack,
  rounds = [],
  eventDetails,
  imageLink = "",
  playerDetails,
  activeRound = 0,
}) {
  const [showProfile, setShowProfile] = useState(false);
  const [units, setUnits] = useState("meters");
  const [selRound, setSelRound] = useState(activeRound);

  const { userSettings } = useAuth();

  useEffect(() => {
    if (userSettings) {
      setUnits(userSettings.units);
    }
  }, [userSettings]);

  return (
    <div className="player-round mp-round">
      {/* <div className="ts-header">
        <ArrowForwardIcon onClick={() => goBack()} className="ts-arrow" />
        <h2>Player Round</h2>
      </div> */}
      <BackRow action={goBack} />
      <div className="p-r-course">
        {/* <div onClick={goBack} className="back-arrow">
                <ArrowLeftIcon className='prh-icon' />
            </div> */}
        {/* <div onClick={() => setShowProfile(true)} className="p-r-course-left">
          <Avatar
            alt={eventDetails.courseName}
            src={playerDetails.profileImage}
          />
        </div> */}
        <div
          // onClick={() => setShowProfile(true)}
          className="p-r-course-right player-round-header mt-20"
        >
          {playerDetails.firstName && (
            <h2>
              {playerDetails.firstName} {playerDetails.lastName}
            </h2>
          )}
          <p>{eventDetails.courseName}</p>
        </div>
      </div>
      <div className="player-round-header">
        <h2>{eventDetails.eventName}</h2>
        <p>{eventDetails.leagueName}</p>
        {rounds[selRound] && (
          <h3>
            Handicap:{" "}
            {rounds[selRound].handicap < 0
              ? `+${Math.abs(rounds[selRound].handicap)}`
              : rounds[selRound].handicap}
          </h3>
        )}
      </div>

      <div className="round-select-circles">
        {rounds.map((round, index) => (
          <div
            key={`round${index}`}
            onClick={() => setSelRound(index)}
            className={selRound === index ? "rsc rsc-active" : "rsc"}
          >
            <p>{index + 1}</p>
          </div>
        ))}
      </div>
      <div className="score-table-container  scale-in-ps prtc">
        <table>
          <thead>
            <tr className="header-row prtc-header">
              <th>
                <GolfCourseIcon />
              </th>
              <th>Par</th>
              <th>Hcp</th>
              <th>Score</th>
              <th>Net</th>
              <th>Putts</th>
              <th>FIR</th>
              <th>GIR</th>
            </tr>
          </thead>
          <tbody>
            {rounds[selRound].holes.map((score, index) => {
              return (
                <>
                  {index === 9 && (
                    <tr className="header-row out-row">
                      <td className="hole-td">OUT</td>
                      <td className="par-td">
                        {rounds[selRound].frontNinePar}
                      </td>
                      <td></td>
                      <td className="normal-td bold-td">
                        {rounds[selRound].frontNineGross}
                      </td>
                      <td className="normal-td bold-td">
                        {rounds[selRound].frontNineNet}
                      </td>
                      <td className="normal-td">
                        {rounds[selRound].frontNinePutts}
                      </td>
                      <td className="normal-td">
                        {Math.round((rounds[selRound].frontNineFIR / 9) * 100)}%
                      </td>
                      <td className="normal-td">
                        {Math.round((rounds[selRound].frontNineGIR / 9) * 100)}%
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td className="normal-td">{index + 1}</td>
                    <td className="normal-td">{score.par}</td>
                    <td className="normal-td">{score.stroke}</td>
                    <td
                      className={
                        score.grossScore - score.par > 0
                          ? "score-td over-par"
                          : score.grossScore - score.par < 0
                          ? "score-td under-par"
                          : "score-td"
                      }
                    >
                      {score.grossScore}
                    </td>
                    <td
                      className={
                        score.toPar > 0
                          ? "score-td over-par"
                          : score.toPar < 0
                          ? "score-td under-par"
                          : "score-td"
                      }
                    >
                      {score.netScore}
                    </td>
                    <td className="normal-td">{score.putts}</td>
                    <td className="icon-td">
                      {score.fir ? (
                        <AdjustIcon className="hit" />
                      ) : (
                        <CloseIcon className="missed" />
                      )}
                    </td>
                    <td className="icon-td">
                      {score.gir ? (
                        <DoneIcon className="hit" />
                      ) : (
                        <CloseIcon className="missed" />
                      )}
                    </td>
                  </tr>
                  {index === 17 && (
                    <tr className="header-row in-row">
                      <td className="hole-td">IN</td>
                      <td className="par-td">{rounds[selRound].backNinePar}</td>
                      <td></td>
                      <td className="normal-td">
                        {rounds[selRound].backNineGross}
                      </td>
                      <td className="normal-td">
                        {rounds[selRound].backNineNet}
                      </td>
                      <td className="normal-td">
                        {rounds[selRound].backNinePutts}
                      </td>
                      <td className="normal-td">
                        {Math.round((rounds[selRound].backNineFIR / 9) * 100)}%
                      </td>
                      <td className="normal-td">
                        {Math.round((rounds[selRound].backNineGIR / 9) * 100)}%
                      </td>
                    </tr>
                  )}
                  {index === 17 && (
                    <tr className="header-row out-row">
                      <td className="hole-td">OUT</td>
                      <td className="par-td">
                        {rounds[selRound].frontNinePar}
                      </td>
                      <td></td>
                      <td className="normal-td">
                        {rounds[selRound].frontNineGross}
                      </td>
                      <td className="normal-td">
                        {rounds[selRound].frontNineNet}
                      </td>
                      <td className="normal-td">
                        {rounds[selRound].frontNinePutts}
                      </td>
                      <td className="normal-td">
                        {Math.round((rounds[selRound].frontNineFIR / 9) * 100)}%
                      </td>
                      <td className="normal-td">
                        {Math.round((rounds[selRound].frontNineGIR / 9) * 100)}%
                      </td>
                    </tr>
                  )}
                  {index === 17 && (
                    <tr className="header-row total-row">
                      <td className="hole-td">TOT</td>
                      <td className="par-td">{rounds[selRound].coursePar}</td>
                      <td></td>
                      <td className="player-td bold-td">
                        {rounds[selRound].totalGrossStrokes}
                      </td>
                      <td className="player-td bold-td">
                        {rounds[selRound].totalNetStrokes}
                      </td>
                      <td className="normal-td">
                        {rounds[selRound].totalPutts}
                      </td>
                      {/* {liveEvent && ( */}
                      <>
                        <td className="normal-td"></td>
                        <td className="normal-td"></td>
                      </>
                      {/* )} */}
                      {/* {!liveEvent && (
                        <>
                          <td className="normal-td">
                            {Math.round(
                              ((frontNineFIR + backNineFIR) / 18) * 100
                            )}
                            %
                          </td>
                          <td className="normal-td">
                            {Math.round(
                              ((frontNineGIR + backNineGIR) / 18) * 100
                            )}
                            %
                          </td>
                        </>
                      )} */}
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MultiplePlayerRound;
