import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { Dialog } from "@mui/material";
import BackRow from "../../components/display/Rows/BackRow";
import PageHeader from "../../components/display/Rows/PageHeader";

function ContestPlayerManager({
  contestID = "",
  players = [],
  playerMap = {},
  goBack = () => {},
  reload = () => {},
}) {
  const [loading, setLoading] = useState(true);
  const [manage, setManage] = useState(false);
  const [toManage, setToManage] = useState({
    name: "",
    playerID: "",
    entries: 0,
  });

  const [update, setUpdate] = useState(0);

  const [board, setBoard] = useState();
  const [entries, setEntries] = useState([]);

  const [showRemove, setShowRemove] = useState(false);

  useEffect(() => {
    console.log(players);
    load();
  }, []);

  async function load() {
    const contestDoc = await db.collection("contests").doc(contestID).get();
    const contest = contestDoc.data();

    setBoard(contest.leaderboard);
    setEntries(contest.playerEntries);

    setLoading(false);
  }

  async function removePlayer(playerID) {
    setLoading(true);
    const contestDoc = await db.collection("contests").doc(contestID).get();
    const contest = contestDoc.data();
    const { playerIDs, leaderboard } = contest;

    const filtered = playerIDs.filter((uid) => uid !== playerID);
    delete leaderboard[playerID];

    setBoard(leaderboard);

    await contestDoc.ref.update({
      playerIDs: filtered,
      leaderboard,
    });
    setToManage({ name: "", playerID: "", entries: 0 });
    setLoading(false);
  }

  async function addEntries(playerID) {
    setLoading(true);
    console.log(playerID);
    const player = playerMap[playerID];

    const currentEntries = player.entries;
    const changedEntries = toManage.entries;

    console.log(currentEntries, changedEntries);

    const diff = changedEntries - currentEntries;

    if (diff === 0) {
      return;
    }

    if (diff > 0) {
      const arr = [];
      for (let i = 0; i < diff; i++) {
        arr.push(playerID);
      }

      await db
        .collection("contests")
        .doc(contestID)
        .update({
          playerEntries: admin.firestore.FieldValue.arrayUnion(...arr),
        });
      reload();
    }

    if (diff < 0) {
      const newDiff = diff * -1;
      const arr = [];
      for (let i = 0; i < newDiff; i++) {
        arr.push(playerID);
      }
      await db
        .collection("contests")
        .doc(contestID)
        .update({
          playerEntries: admin.firestore.FieldValue.arrayRemove(...arr),
        });
      reload();
    }
    setManage(false);
    setLoading(false);
  }

  function roundToThree(n) {
    if (n > 0) return Math.ceil(n / 3.0) * 3;
    else if (n < 0) return Math.floor(n / 3.0) * 3;
    else return 3;
  }

  return (
    <div className="contest-manager">
      <Dialog
        className="cm-rem-dia"
        open={showRemove}
        onClose={() => setShowRemove(false)}
      >
        <div className="close-row">
          <CancelIcon
            onClick={() => setShowRemove(false)}
            className="dg-icon"
          />
        </div>
        {toManage && (
          <div className="flex-center-col conman-remove">
            <RemoveCircleIcon className="red-icon big-icon" />

            <p>
              Are you sure you want to remove {toManage.name} from this contest?
            </p>

            <div className="flex-center">
              <button
                onClick={() => removePlayer(toManage.playerID)}
                className="default-button"
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        className="cm-rem-dia"
        open={manage}
        onClose={() => setManage(false)}
      >
        <div className="close-row">
          <CancelIcon onClick={() => setManage(false)} className="dg-icon" />
        </div>

        <div className="flex-center-col conman-remove">
          <div className="conman-entries flex-center-col">
            <div className="con-ent-top">
              <h2>Total Entries</h2>
            </div>
            <div className="con-ent-bot">
              <RemoveCircleIcon
                onClick={() => {
                  setToManage((current) => {
                    const currentAttempts = current.lb.attempts.length;
                    const rounded = Math.ceil(currentAttempts / 3);
                    console.log(rounded);
                    if (current.entries > 1 && current.entries > rounded) {
                      current.entries -= 1;
                      return current;
                    } else {
                      return current;
                    }
                  });

                  setUpdate((current) => current + 1);
                }}
                className="dg-icon icon-cursor"
              />
              <h3>{toManage.entries}</h3>
              <AddCircleIcon
                onClick={() => {
                  setToManage((current) => {
                    current.entries += 1;
                    return current;
                  });
                  setUpdate((current) => current + 1);
                }}
                className="dg-icon icon-cursor"
              />
            </div>
          </div>

          <div className="flex-center">
            <button
              onClick={() => {
                addEntries(toManage.playerID);
              }}
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Contest Player Manager" />

      <div className="cpm-players">
        {players.map((player, index) => (
          <PlayerItem
            key={player.playerID}
            hcp={player.handicap}
            name={player.name}
            img={player.profileImage}
            hideMessage
            showAdd
            hideProfile
            showProfile={() => {}}
            editAction={() => {
              setToManage(player);
              setManage(true);
            }}
            removeAction={() => {
              setToManage(player);
              setShowRemove(true);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ContestPlayerManager;
