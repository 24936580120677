import React, { useState, useEffect } from "react";
import "./RoundCard.css";

// Icons
import { IoGolfSharp } from "react-icons/io5";
import { SiTarget } from "react-icons/si";
import { FaRegCheckCircle } from "react-icons/fa";
import { VscError } from "react-icons/vsc";
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import { Tabs, Tab } from "@mui/material";
import RoundSummary from "./RoundSummary";
import RoundGraphs from "../RoundGraphs/RoundGraphs";
import RoundStats from "./RoundStats";
import SmartStats from "../SmartStats/SmartStats";
import { Edit } from "@mui/icons-material";
import BackdropLoader from "../../Loaders/BackdropLoader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { db } from "../../../firebase";
import BackRow from "../../display/Rows/BackRow";
import classNames from "classnames";

function RoundCard({
  round,
  showBack = false,
  goBack = () => {},
  userID = "",
}) {
  // Admin

  // State
  const [sel, setSel] = useState("card");
  const [notes, setNotes] = useState(round.notes ?? "");
  const [editing, setEditing] = useState(false);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // UE
  useEffect(() => {
    setSel("card");
    // console.log(round.roundID);
  }, [round]);

  // Funcs
  async function saveText() {
    setLoading(true);
    try {
      await db
        .collection("rounds")
        .doc(round.roundID)
        .collection("scores")
        .doc(userID)
        .update({
          notes,
        });
      setEditing(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficult there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="round-card">
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      {loading && <BackdropLoader />}
      {showBack && <BackRow action={goBack} />}

      <div
        className={classNames("gre-meu", {
          "mt-20": showBack,
        })}
      >
        <Tabs value={sel} onChange={(e, v) => setSel(v)}>
          <Tab label="Scorecard" value={"card"} />
          <Tab label="Stats" value={"stats"} />
          <Tab label="Summary" value={"sum"} />
          <Tab label="Graphs" value={"graphs"} />
          <Tab label="Strokes" value={"strokes"} />
          <Tab label="Notes" value="notes" />
        </Tabs>
      </div>

      <div className="rc-ifo bs-subtle mt-20">
        <div className="rci-hcp">
          <p>Handicap Index:</p>
          <h5>{round.handicapIndex ?? round.handicap}</h5>
        </div>
        <div className="rci-hcp">
          <p>Playing Handicap:</p>
          <h5>{round.handicap}</h5>
        </div>
        {round.tees && (
          <div className="rci-hcp">
            <p>Tees:</p>
            <h5>{round.tees}</h5>
          </div>
        )}
        <div className="rci-hcp">
          <p>Course:</p>
          <h5>{round.courseName}</h5>
        </div>
        <div className="rci-hcp">
          <p>Date:</p>
          <h5>{round.dateString}</h5>
        </div>
      </div>

      {sel === "card" && (
        <div className="rc-sc bs-subtle">
          <table>
            <thead>
              <tr>
                <th className="rc-head-ico">
                  <IoGolfSharp />
                </th>
                <th>Par</th>
                <th>Stroke</th>
                <th>Strokes</th>
                <th>Gross</th>
                <th>Net</th>
                <th>Points</th>
                <th>Putts</th>
                <th>GIR</th>
                <th>FIR</th>
              </tr>
            </thead>
            <tbody>
              {round.holes.map((row, i) => {
                let ghc = "";
                let hc = "";

                const op = row.grossScore - row.par;
                const { toPar } = row;
                switch (op) {
                  case -3:
                    ghc = "eag";
                    break;
                  case -2:
                    ghc = "eag";
                    break;
                  case -1:
                    ghc = "bir";
                    break;
                  case -0:
                    ghc = "pa";
                    break;
                  case 1:
                    ghc = "bog";
                    break;
                  case 2:
                    ghc = "dbl";
                    break;
                  case 3:
                    ghc = "tri";
                    break;
                  default:
                    break;
                }

                if (op > 3) {
                  ghc = "oth";
                }

                switch (toPar) {
                  case -3:
                    hc = "eag";
                    break;
                  case -2:
                    hc = "eag";
                    break;
                  case -1:
                    hc = "bir";
                    break;
                  case -0:
                    hc = "pa";
                    break;
                  case 1:
                    hc = "bog";
                    break;
                  case 2:
                    hc = "dbl";
                    break;
                  case 3:
                    hc = "tri";
                    break;
                  default:
                    break;
                }

                if (toPar > 3) {
                  hc = "oth";
                }

                return (
                  <React.Fragment key={`${i}hole`}>
                    {i === 9 && (
                      <>
                        <tr className="sum-tr sum-out">
                          <td>OUT</td>
                          <td>{round.frontNinePar}</td>
                          <td></td>
                          <td></td>
                          <td>{round.frontNineGross}</td>
                          <td>{round.frontNineNet}</td>
                          <td>{round.frontNinePoints}</td>
                          <td>{round.frontNinePutts}</td>
                          <td>{round.frontNineGIR}</td>
                          <td>{round.frontNineFIR}</td>
                        </tr>
                      </>
                    )}

                    <tr className="rc-row">
                      <td>{i + 1}</td>
                      <td>{row.par}</td>
                      <td>{row.stroke}</td>
                      <td>{row.strokes}</td>
                      <td
                        className={
                          row.grossScore > row.par
                            ? "over-par"
                            : row.grossScore < row.par
                            ? "ud-par"
                            : "par"
                        }
                      >
                        <div className={"sc-box"}>
                          <div className={ghc}>{row.grossScore}</div>
                        </div>
                      </td>
                      <td
                        className={
                          row.netScore > row.par
                            ? "over-par"
                            : row.netScore < row.par
                            ? "ud-par"
                            : "par"
                        }
                      >
                        <div className="sc-box">
                          <div className={hc}>{row.netScore}</div>
                        </div>
                      </td>
                      <td
                        className={
                          row.netScore > row.par
                            ? "over-par"
                            : row.netScore < row.par
                            ? "ud-par"
                            : "par"
                        }
                      >
                        <div className="sc-box">
                          <div className={hc}>{row.points}</div>
                        </div>
                      </td>
                      <td
                        className={
                          row.putts > 2
                            ? "over-par"
                            : row.putts < 2
                            ? "ud-par"
                            : "par"
                        }
                      >
                        <div className="sc-box">{row.putts}</div>
                      </td>
                      <td>
                        <div className="ico-td">
                          {row.gir ? (
                            <SiTarget className="succ-ico" />
                          ) : (
                            <VscError className="fail-ico" />
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="ico-td">
                          {row.fir ? (
                            <FaRegCheckCircle className="succ-ico" />
                          ) : (
                            <VscError className="fail-ico" />
                          )}
                        </div>
                      </td>
                    </tr>

                    {i === 17 && (
                      <>
                        <tr className="sum-tr sum-i">
                          <td>IN</td>
                          <td>{round.backNinePar}</td>
                          <td></td>
                          <td></td>
                          <td>{round.backNineGross}</td>
                          <td>{round.backNineNet}</td>
                          <td>{round.backNinePoints}</td>
                          <td>{round.backNinePutts}</td>
                          <td>{round.backNineGIR}</td>
                          <td>{round.backNineFIR}</td>
                        </tr>
                      </>
                    )}
                    {i === 17 && (
                      <>
                        <tr className="sum-tr sum-ou">
                          <td>OUT</td>
                          <td>{round.frontNinePar}</td>
                          <td></td>
                          <td></td>
                          <td>{round.frontNineGross}</td>
                          <td>{round.frontNineNet}</td>
                          <td>{round.frontNinePoints}</td>
                          <td>{round.frontNinePutts}</td>
                          <td>{round.frontNineGIR}</td>
                          <td>{round.frontNineFIR}</td>
                        </tr>
                      </>
                    )}
                    {i === 17 && (
                      <>
                        <tr className="sum-tr sum-tot">
                          <td>TOT</td>
                          <td>{round.coursePar}</td>
                          <td></td>
                          <td></td>
                          <td>{round.totalGrossStrokes}</td>
                          <td>{round.totalNetStrokes}</td>
                          <td>{round.totalPoints}</td>
                          <td>{round.totalPutts}</td>
                          <td>{round.totalGIR}</td>
                          <td>{round.totalFIR}</td>
                        </tr>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {sel === "sum" && (
        <>
          <RoundSummary playerID={round.clientID} roundID={round.roundID} />
        </>
      )}

      {sel === "stats" && (
        <>
          <RoundStats
            userID={round.clientID}
            stats={round.stats}
            courseID={round.courseID}
            course={round.courseName}
            grossStats={round.grossStats}
          />
        </>
      )}
      {sel === "graphs" && (
        <>
          <RoundGraphs roundID={round.roundID} userID={round.clientID} />
        </>
      )}
      {sel === "strokes" && (
        <>
          <SmartStats
            courseID={round.courseID}
            roundID={round.roundID}
            userID={round.clientID}
          />
        </>
      )}

      {sel === "notes" && (
        <>
          {editing ? (
            <div className="rcn-ta">
              <textarea
                rows={10}
                placeholder="Enter notes here"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="default-input"
              />
              <button
                onClick={() => {
                  saveText();
                }}
                className="default-button mt-20 small-but"
              >
                Save Changes
              </button>
            </div>
          ) : (
            <>
              <div className="rc-notes">
                <div className="rcn-edit pl-20">
                  <Edit
                    className="dg-icon-bg icon-cursor"
                    onClick={() => {
                      setEditing(true);
                      setUpdate((c) => c + 1);
                    }}
                  />
                </div>
                <div className="rcn-box bs-subtle bss-white pd-20 pl-20">
                  <p>
                    {notes
                      ? notes
                      : "No notes have been added to this round yet."}
                  </p>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default RoundCard;
