/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import "./CustomRules.css";

import { db } from "../../firebase";
import BlenderIcon from "@mui/icons-material/Blender";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PageHeader from "../display/Rows/PageHeader";

const GradientIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      className="ps-icon dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientAddIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="cr-icon dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientBlendIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <BlenderIcon
      onClick={onClick}
      className="ps-icon dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function CustomRules({
  addNew = () => {},
  saveRules,
  goBack,
  defaultRules = [],
}) {
  const [rules, setRules] = useState([]);
  const [selRules, setSelRules] = useState(defaultRules);

  const [showNew, setShowNew] = useState(false);

  useEffect(() => {
    loadRules();
  }, []);

  async function loadRules() {
    const adminDoc = await db.collection("admin").doc("items").get();
    const doc = adminDoc.data();
    const { rules } = doc;
    console.log(rules);
    for (let i = 0; i < defaultRules.length; i++) {
      let found = false;
      for (let j = 0; j < rules.length; j++) {
        if (rules[j].name === defaultRules[i].name) {
          found = true;
        }
      }
      if (!found) {
        rules.push(defaultRules[i]);
      }
    }
    setRules(rules);
  }

  const titleRef = useRef();
  const ruleRef = useRef();

  function saveNew() {
    const name = titleRef.current.value;
    const rule = ruleRef.current.value;

    setRules((current) => [...current, { name, rule }]);
    setSelRules((current) => [...current, { name, rule }]);
    addNew((current) => [...current, { name, rule }]);
    setShowNew(false);
  }

  function shuffle(array) {
    // alert("everyday");
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  function randomize() {
    const temp = [...rules];
    const arr = shuffle(temp);
    const newArr = [];
    for (let i = 0; i < 3; i++) {
      newArr.push(arr[i]);
    }
    setSelRules(newArr);
  }

  return (
    <div className="custom-rules">
      <div
        onClick={() => {
          saveRules(selRules);
          goBack();
        }}
        className="back-row"
      >
        <ArrowBack />
        <p>Back</p>
      </div>
      {/* <div className="page-header">
        <h2>Custom Rules</h2>
      </div> */}

      <PageHeader text="Custom Rules" />

      {/* <div className="ts-header">
        <ArrowForwardIcon
          onClick={() => {
            saveRules(selRules);
            goBack();
          }}
          className="ts-arrow"
        />
        <h2>Custom Rules</h2>
      </div> */}

      <div style={{ paddingLeft: "20px" }} className="input-group">
        <div className="ig-header">
          <p>Add Rule</p>
          <AddCircleIcon
            onClick={() => setShowNew(true)}
            className="icon-cursor dg-icon-bg ml-20"
          />
        </div>
      </div>

      <div style={{ paddingLeft: "20px" }} className="input-group">
        <div className="ig-header">
          <p>Shuffle</p>
          <BlenderIcon
            className="icon-cursor dg-icon-bg ml-20"
            onClick={randomize}
          />
        </div>
      </div>

      {showNew && (
        <div className="scale-in-ver-top add-rule">
          <div className="input-group">
            <p>Title</p>
            <input placeholder="Rule title..." type={"text"} ref={titleRef} />
            <p className="mt-20">Rule</p>
            <textarea
              className="default-input"
              placeholder="Rule description..."
              rows={5}
              ref={ruleRef}
            />
          </div>
          <div className="flex-center">
            <button className="new-default-button" onClick={saveNew}>
              Add
            </button>
          </div>
        </div>
      )}
      <div className="rules-container">
        {rules.map((rule) => {
          let found = false;
          if (selRules.filter((item) => item.name === rule.name).length > 0) {
            found = true;
          }
          return (
            <div
              onClick={() =>
                setSelRules((current) => {
                  if (
                    current.filter((item) => item.name === rule.name).length ===
                    0
                  ) {
                    // Add rule
                    return [...current, rule];
                  } else {
                    // Remove rule
                    return current.filter((item) => item.name !== rule.name);
                  }
                })
              }
              className={found ? "rule-item ri-sel" : "rule-item"}
            >
              <div className="ri-left">
                {found && <GradientIcon style={{ fill: "#21c17c" }} />}
                {!found && <CancelIcon style={{ fill: "#c6c7c8" }} />}
              </div>
              <div className="ri-right">
                <h2>{rule.name}</h2>
                <p>{rule.rule}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CustomRules;
