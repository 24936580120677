import React, { useEffect, useState } from "react";
import { db } from "../../firebase";

// Icons

// Components
import BackRow from "../display/Rows/BackRow";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import PageHeader from "../display/Rows/PageHeader";
import { Check, Edit, Reply } from "@mui/icons-material";

function TeamLeagueTableEditor({
  leagueID = "",
  goBack = () => {},
  reload = () => {},
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [table, setTable] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editRow, setEditRow] = useState();
  const [updte, setUpdate] = useState(0);
  const [seasonID, setSeasonID] = useState("");
  const [bbs, setBBS] = useState(false);
  useEffect(() => {
    loadTable();
  }, []);

  async function loadTable() {
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    setBBS(league.betterballStableford);
    const teams = league.teams ?? [];
    setSeasonID(league.seasonID ?? "");
    for (let i = 0; i < teams.length; i++) {
      if (teams[i].leaguePoints === undefined) {
        teams[i].leaguePoints = 0;
      }
    }

    teams.sort((a, b) => b.leaguePoints - a.leaguePoints);

    setTable(teams);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      await db.collection("teamLeagues").doc(leagueID).update({
        teams: table,
      });
      await db.collection("seasons").doc(seasonID).update({
        teams: table,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we enountered a technical difficuly there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="tl-table-edit">
      <BackRow action={goBack} />
      {loading && <BackdropLoader />}
      <PageHeader text="Table Editor" />

      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <div className="pd-20">
        <div className="tte-box">
          <div className="tte-row tte-header">
            <div className="tte-tit">
              <p>Team</p>
            </div>
            <div className="tte-item">
              <p>Played</p>
            </div>
            <div className="tte-item">
              <p>Points</p>
            </div>
            <div className="tte-item">
              <p>Wins</p>
            </div>
            <div className="tte-item">
              <p>Ties</p>
            </div>
            {bbs && (
              <>
                <div className="tte-item">
                  <p>Stbf For</p>
                </div>
                <div className="tte-item">
                  <p>Stbf Against</p>
                </div>
              </>
            )}
            <div className="tte-ico tte-item">
              <p>Edit</p>
            </div>
          </div>
          {table.map((team, i) => {
            return (
              <div className="tte-row">
                <div className="tte-tit">
                  <p>{team.teamName}</p>
                </div>
                <div className="tte-item">
                  {editRow === i ? (
                    <div className="tte-ip">
                      <input
                        min={0}
                        className="default-input"
                        value={team.played}
                        type="number"
                        onChange={(e) => {
                          const val = e.target.valueAsNumber;
                          setTable((c) => {
                            c[i].played = val;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      />
                    </div>
                  ) : (
                    <p>{team.played}</p>
                  )}
                </div>
                <div className="tte-item">
                  {editRow === i ? (
                    <div className="tte-ip">
                      <input
                        min={0}
                        className="default-input"
                        value={team.leaguePoints}
                        type="number"
                        onChange={(e) => {
                          const val = parseFloat(e.target.value);
                          setTable((c) => {
                            c[i].leaguePoints = val;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      />
                    </div>
                  ) : (
                    <p>{team.leaguePoints}</p>
                  )}
                </div>
                <div className="tte-item">
                  {editRow === i ? (
                    <div className="tte-ip">
                      <input
                        min={0}
                        className="default-input"
                        value={team.won}
                        type="number"
                        onChange={(e) => {
                          const val = e.target.valueAsNumber;
                          setTable((c) => {
                            c[i].won = val;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      />
                    </div>
                  ) : (
                    <p>{team.won}</p>
                  )}
                </div>
                <div className="tte-item">
                  {editRow === i ? (
                    <div className="tte-ip">
                      <input
                        min={0}
                        className="default-input"
                        value={team.tied}
                        type="number"
                        onChange={(e) => {
                          const val = e.target.valueAsNumber;
                          setTable((c) => {
                            c[i].tied = val;
                            return c;
                          });
                          setUpdate((c) => c + 1);
                        }}
                      />
                    </div>
                  ) : (
                    <p>{team.tied}</p>
                  )}
                </div>
                {bbs && (
                  <div className="tte-item">
                    {editRow === i ? (
                      <div className="tte-ip">
                        <input
                          min={0}
                          className="default-input"
                          value={team.stableFor}
                          type="number"
                          onChange={(e) => {
                            const val = e.target.valueAsNumber;
                            setTable((c) => {
                              c[i].stableFor = val;
                              return c;
                            });
                            setUpdate((c) => c + 1);
                          }}
                        />
                      </div>
                    ) : (
                      <p>{team.stableFor}</p>
                    )}
                  </div>
                )}
                {bbs && (
                  <div className="tte-item">
                    {editRow === i ? (
                      <div className="tte-ip">
                        <input
                          min={0}
                          className="default-input"
                          value={team.stableAg}
                          type="number"
                          onChange={(e) => {
                            const val = e.target.valueAsNumber;
                            setTable((c) => {
                              c[i].stableAg = val;
                              return c;
                            });
                            setUpdate((c) => c + 1);
                          }}
                        />
                      </div>
                    ) : (
                      <p>{team.stableAg}</p>
                    )}
                  </div>
                )}
                <div className="tte-ico tte-item ">
                  {!editing && (
                    <Edit
                      className="dg-icon-bg icon-cursor"
                      onClick={() => {
                        setEditRow(i);
                        setEditing(true);
                      }}
                    />
                  )}
                  {editing && editRow === i && (
                    <Check
                      className="dg-icon-bg icon-cursor"
                      onClick={() => {
                        setEditRow();
                        setEditing(false);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex-center">
        <button
          disabled={editing}
          onClick={saveChanges}
          className="default-button"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default TeamLeagueTableEditor;
