import React, { useState, useRef } from "react";
import "./NewQueryModal.css";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
// Icons
import Close from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Components
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import PageHeader from "../../components/display/Rows/PageHeader";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
const GradientCircleIcon = ({ onClick }) => (
  <>
    <CheckCircleIcon
      onClick={onClick}
      className="big-tick dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function NewQueryModal({ uid = "", close = () => {}, reload = () => {} }) {
  const { currentUser, userDetails } = useAuth();
  // State
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  async function save() {
    const queryID = makeid(10);

    try {
      setLoading(true);
      const userName = `${userDetails.firstName} ${userDetails.lastName}`;
      await db.collection("queries").doc(queryID).set({
        userID: currentUser.uid,
        text: input,
        date: new Date(),
        status: "submitted",
        queryID,
        userName,
      });
      setFinished(true);
      setLoading(false);
      reload();
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered some technical difficulties there - please try again"
      );
    }
  }

  return (
    <div className="new-query">
      <Dialog open onClose={close}>
        <div className="new-query-modal">
          <PageHeader text="New Query" close={close} showClose />
          <div className="ph-msg">
            <p>
              Please let us know below what your issue is, and we will get back
              to you as soon as possible
            </p>
          </div>
          {loading ? (
            <>
              {" "}
              <div className="flex-center loading ui-waiting">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
                <p>Submitting...</p>
              </div>
            </>
          ) : finished ? (
            <>
              {" "}
              <div className="mpa-fin flex-center-col">
                <GradientCircleIcon />
                <p className="mb-20">
                  Query successfully submitted. One of our agents will be in
                  touch shortly.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="nqm-input pl-20">
                <textarea
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Enter your query..."
                  id=""
                  cols="30"
                  rows="10"
                  className="default-input"
                />
              </div>
              {errorMessage !== "" && (
                <div className="error-message-row">
                  <p>{errorMessage}</p>
                </div>
              )}
              <div className="default-button-row">
                <button
                  onClick={save}
                  disabled={input === ""}
                  className="default-button"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default NewQueryModal;
