/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import "./RoundInput.css";
import { Dialog } from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Steps } from "intro.js-react";
import Switch from "react-ios-switch";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { BallTriangle, Oval } from "react-loader-spinner";

import { db } from "../../../firebase";

import GroupSelector from "../GroupSelector/GroupSelector";
import CoursePicker from "../CoursePicker/CoursePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { useAuth } from "../../../contexts/AuthContext";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import moment from "moment";
import PageHeader from "../../display/Rows/PageHeader";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

const GradientInfoIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <InfoIcon
      onClick={onClick}
      className={className}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const roundOptions = [
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
];

function RoundInput({
  goBack,
  roundIndex,
  attLimit = 0,
  saveRound = () => {},
  courses = [],
  num,
  players,
  date,
  eventID,
  rounds = [],
  edit = false,
  eventName = "",
  defaultCourseName = "",
  defaultCourseID = "",
  defaultTees = "",
  defaultTypes = "",
  defaultDate = "",
  defaultTime = "",
  defaultIntervals = 8,
  defaultLimit = 4,
  defaultTimeType = "auto",
  defaultGroups = [],
  seasonID = "",
  participants = [],
  eventDate = new Date(),
  eventType = "",
  eventCourseID = "",
  eventCourseName = "",
  defaultImageLink = "",
  defaultCTPEnabled = false,
  defaultLDEnabled = false,
  defaultCTPHole = "",
  defaultLDHole = "",
  defaultOpen = false,
  defaultStartType = "regular",
  format = "",
}) {
  const { onboarding, setOnboard, currentUser } = useAuth();

  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState("");

  const [enableClosest, setEnableClosest] = useState(defaultCTPEnabled);
  const [ctpHole, setCTPHole] = useState(defaultCTPHole);
  const [ctpOptions, setCTPOptions] = useState([]);
  const [open, setOpen] = useState(defaultOpen ?? false);

  const [enableLD, setEnableLD] = useState(defaultLDEnabled);
  const [ldHole, setLDHole] = useState(defaultLDHole);
  const [ldOptions, setLDOptions] = useState([]);

  const [course, setCourse] = useState({
    label: defaultCourseName,
    value: defaultCourseID,
    imageLink: defaultImageLink,
  });
  const [groupTypes, setGroupTypes] = useState(defaultTypes);
  const [tees, setTees] = useState([]);
  const [limit, setLimit] = useState(defaultLimit.toString());
  const [minutes, setMinutes] = useState(defaultIntervals);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [chosen, setChosen] = useState(true);
  const [showGroups, setShowGroups] = useState(false);
  const [userDates, setUserDates] = useState([]);

  const [seasonDates, setSeasonDates] = useState([]);

  const [times, setTimes] = useState(defaultGroups);

  const [timeTypes, setTimeTypes] = useState(defaultTimeType);

  const [courseChanged, setCourseChanged] = useState(false);
  const [changeRounds, setChangeRounds] = useState(false);
  const [changeEvent, setChangeEvent] = useState(false);

  const [differentCourses, setDifferentCourses] = useState(false);

  const [startType, setStartType] = useState(defaultStartType);

  const startRef = useRef();
  const dateRef = useRef();

  useEffect(() => {
    console.log(course);
    if (onboarding) {
      if (onboarding.roundInput === false) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);

  const types = [
    "random",
    "handicap (lowest first)",
    "handicap (highest first)",
    "handicap (mixed)",
    "leaderboard",
    "custom",
    ...(format.includes("better") || format.includes("team") ? ["teams"] : ""),
  ];
  const typesTwo = [
    "random",
    "custom",
    "handicap (lowest first)",
    "handicap (highest first)",
    "handicap (mixed)",
    ...(eventType === "final" ? ["points table"] : ""),
    ...(format.includes("better") || format.includes("team") ? ["teams"] : ""),
  ];

  useEffect(() => {
    // console.log("changed", edit);
    if (edit) {
      let id = "";
      for (let i = 0; i < rounds.length; i++) {
        if (id === "") {
          id = rounds[i].courseID;
        }
        if (id !== rounds[i].courseID) {
          setDifferentCourses(true);
        }
      }
    } else {
      if (limit !== "") {
        console.log("herrrre");
        const amt = Math.ceil(players / parseInt(limit));
        return setTimes((current) => {
          let arr = [];
          for (let i = 0; i < amt; i++) {
            arr.push({
              time: "",
              players: [],
              startingTee: 1,
            });
          }
          return arr;
        });
      }
    }
    const amt = Math.ceil(players / parseInt(limit));
    if (times.length < amt) {
      console.log("here");
      const diff = amt - times.length;
      console.log(diff);
      return setTimes((current) => {
        const arr = [...current];
        for (let i = 0; i < diff; i++) {
          arr.push({
            time: "",
            players: [],
            startingTee: 1,
          });
        }
        return arr;
      });
    }
    // console.log(times.length, amt);
    if (times.length > amt) {
      setTimes((current) => {
        while (current.length > amt) {
          current.pop();
        }
        return current;
      });
      setUpdate((current) => current + 1);
    }
  }, [limit]);

  useEffect(() => {
    if (currentUser) {
      loadUserDates();
      loadSeasonEvents();
    }
  }, [currentUser]);

  useEffect(() => {
    // console.log(defaultGroups);
    loadCourseDetails();
    // console.log(players)
    // console.log(attLimit)
    // console.log(defaultDate, defaultTime)
    // dateRef.current.value = defaultDate
  }, []);

  useEffect(() => {
    const arr = [];
    const ldArr = [];

    if (course.tees) {
      const tee = course.teeArray[0];

      for (let i = 0; i < course.tees[tee].length; i++) {
        const hole = course.tees[tee][i];
        // console.log(hole);
        if (hole.par === 3) {
          arr.push({
            value: i,
            label: `Hole ${i + 1}`,
          });
        } else {
          ldArr.push({
            value: i,
            label: `Hole ${i + 1}`,
          });
        }
      }
      setCTPOptions(arr);
      setLDOptions(ldArr);
    }
  }, [course]);

  async function loadCourseDetails() {
    console.log(defaultCourseID);
    const courseDoc = await db.collection("courses").doc(defaultCourseID).get();
    const course = courseDoc.data();
    const {
      address,
      city,
      country,
      rating,
      location,
      name,
      distance,
      imageLink,
      roundArray,
      teeArray,
      tees,
      par,
      totalReviews,
      length,
    } = course;

    setCourse({
      address,
      city,
      country,
      rating,
      location,
      name,
      courseID: defaultCourseID,
      distance,
      imageLink,
      roundArray,
      teeArray,
      tees,
      par,
      totalReviews,
      length,
    });
  }

  async function loadUserDates() {
    const now = new Date();
    const cups = await db
      .collection("cups")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("cupDate", ">", now)
      .get();
    const events = await db
      .collection("events")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("eventDate", ">", now)
      .get();

    const dateArr = [];
    for (let i = 0; i < cups.docs.length; i++) {
      const cupDoc = cups.docs[i];
      const cup = cupDoc.data();
      const { rounds } = cup;
      for (let j = 0; j < rounds.length; j++) {
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      if (eventDoc.id === eventID) {
        continue;
      }
      const event = eventDoc.data();
      const { rounds } = event;
      for (let j = 0; j < rounds.length; j++) {
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    setUserDates(dateArr);
  }

  async function loadSeasonEvents() {
    if (seasonID === undefined) {
      return;
    }
    const dateArr = [];

    const today = new Date();

    const seasonRounds = await db
      .collection("events")
      .where("date", ">", today)
      .where("seasonID", "==", seasonID)
      .get();

    for (let i = 0; i < seasonRounds.docs.length; i++) {
      const event = seasonRounds.docs[i].data();
      if (seasonRounds.docs[i].id === eventID) {
        continue;
      }
      const { rounds } = event;
      for (let i = 0; i < rounds.length; i++) {
        const date = new Date(rounds[i].date.toDate());
        dateArr.push(date);
      }
    }

    setSeasonDates(dateArr);
  }

  function sameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  function validate() {
    // console.log(startRef.current.value);
    // setLoading(false);
    // return false;
    setError("");
    setUpdate((current) => current + 1);
    // Course
    if (course.label === "") {
      setError("Please choose a course");
      return false;
    }
    // Tees
    if (tees === "") {
      setError("Please choose tees");
      return false;
    }
    // Groups
    if (groupTypes === "") {
      setError("Please choose a group type");
      return false;
    }
    // Date
    if (dateRef.current.value === "") {
      setError("Please choose a date");
      return false;
    }
    // Conflict Checks
    const chosenDate = dateRef.current.valueAsDate;
    const dateMoment = moment(chosenDate);
    // for (let i = 0; i < userDates.length; i++) {
    //   const userDateMoment = moment(userDates[i]);
    //   if (userDateMoment.isSame(dateMoment, "date")) {
    //     setError("Sorry! This date conflicts with one of your future rounds");
    //     dateRef.current.focus();
    //     return false;
    //   }
    // }
    // Event
    for (let i = 0; i < rounds.length; i++) {
      const roundDate = new Date(rounds[i].date);
      if (i === roundIndex) {
        continue;
      }

      const roundMoment = moment(roundDate);
      if (dateMoment.isBefore(roundMoment) && i < roundIndex) {
        setError("The round cannot start before other rounds in this event");
        return false;
      }
    }
    // Season
    for (let i = 0; i < seasonDates.length; i++) {
      if (sameDay(chosenDate, seasonDates[i])) {
        setError("There is already an event for this league on that date");
        return false;
      }
    }

    if (
      chosenDate < eventDate &&
      chosenDate.getDate() !== eventDate.getDate()
    ) {
      setError("The round cannot start before the event");
      return false;
    }

    // Time
    if (groupTypes !== "custom") {
      if (timeTypes === "auto") {
        if (startRef.current.value === "") {
          setError("Please choose a starting time");
          return false;
        }
      }

      if (timeTypes === "manual") {
        for (let i = 0; i < times.length; i++) {
          if (times[i].time === "") {
            setError(`Please choose a starting time for group ${i + 1}`);
            return false;
          }
          if (
            i !== times.length - 1 &&
            times[i].time === times[i + 1].time &&
            times[i].startingTee === times[i + 1].startingTee
          ) {
            setError(`Group ${i + 1} and group ${i + 2} are conflicting`);
            return false;
          }
        }
      }

      if (enableClosest && ctpHole === "") {
        setError("Please select a hole for closest to the pin");
        return false;
      }

      if (enableLD && ldHole === "") {
        setError("Please select a hole for longest drive");
        return false;
      }
    }

    return true;
  }

  async function storeRound() {
    let time;
    if (startRef.current && (timeTypes === "auto" || startType === "shotgun")) {
      time = startRef.current.value;
    } else {
      time = defaultTime;
    }

    // setLoading(true);

    if (validate() === false) {
      return setLoading(false);
    }
    setLoading(true);

    // return
    setUpdate((current) => current + 1);
    // console.log(dateRef.current.value);
    const dv = dateRef.current.value;
    // setLoading(false);
    const id = course.courseID ?? course.value;
    console.log(id);

    const courseDoc = await db.collection("courses").doc(id).get();
    const courseData = courseDoc.data();
    const { imageLink, teeArray, length } = courseData;
    const date = new Date(dv);

    if (timeTypes === "auto") {
      const hours = parseInt(time.slice(0, 2));
      const minutes = parseInt(time.slice(3, 5));

      date.setHours(hours);
      date.setMinutes(minutes);
    }

    if (timeTypes === "manual") {
      if (times.length > 0) {
        time = times[0].time;
        const hours = parseInt(time.slice(0, 2));
        const minutes = parseInt(time.slice(3, 5));

        date.setHours(hours);
        date.setMinutes(minutes);
      }
    }

    let newCourseID = eventCourseID;
    let newCourseName = eventCourseName;
    if (changeEvent) {
      newCourseID = course.courseID ?? course.value;
      newCourseName = course.name;
    }

    if (changeRounds) {
      for (let i = 0; i < rounds.length; i++) {
        rounds[i].courseID = course.courseID ?? course.value;
        rounds[i].imageLink = course.imageLink;
      }
    }

    // return console.log(newCourseID, newCourseName);

    const groups = [];
    for (let i = 0; i < times.length; i++) {
      groups.push({
        time: times[i],
        players: [],
      });
    }

    // alert(course.name);

    if (!edit) {
      rounds.push({
        date,
        courseID: course.courseID,
        groupLimit: parseInt(limit),
        groupTypes,
        imageLink,
        intervals: minutes,
        timeTypes,
        groups: times,
        roundID: "",
        seasonID,
        teeArray,
        length,
        ctpEnabled: enableClosest,
        ctpHole,
        ldEnabled: enableLD,
        ldHole,
        open,
        startType,
        course: course.name,
      });
    } else {
      rounds[roundIndex] = {
        date,
        open,
        courseID: course.courseID,
        groupLimit: parseInt(limit),
        groupTypes,
        imageLink,
        startType,
        intervals: minutes,
        timeTypes,
        groups: times,
        seasonID,
        teeArray,
        length,
        ctpEnabled: enableClosest,
        ctpHole,
        ldEnabled: enableLD,
        ldHole,
        course: course.name,
      };
    }
    // return setLoading(false);
    // return ;
    try {
      await db.collection("events").doc(eventID).update({
        rounds,
        courseID: newCourseID,
        courseName: newCourseName,
      });
      if (roundIndex === 0) {
        const tasks = await db
          .collection("tasks")
          .where("eventID", "==", eventID)
          .where("type", "==", "roundCreation")
          .get();
        const articleTasks = await db
          .collection("tasks")
          .where("eventID", "==", eventID)
          .where("type", "==", "pre-event-winner")
          .get();
        await db.collection("events").doc(eventID).update({
          eventDate: date,
        });
        const task = tasks.docs[0];
        const performAt = new Date(date);
        performAt.setHours(performAt.getHours() - 5);
        if (task.exists) {
          task.ref.update({
            performAt,
          });
        }
        if (tasks.docs.length > 1) {
          const articleTask = articleTasks.docs[1];
          const articlePerformAt = new Date(date);
          articlePerformAt.setDate(articlePerformAt.getDate() - 3);
          if (articleTask.exists) {
            articleTask.ref.update({
              performAt: articlePerformAt,
            });
          }
        }
      }
      saveRound();
      toast.success("Changes were successfully saved", {
        icon: successIco,
      });
      // setTimeout(() => {
      //     goBack()
      // }, 2000)
      goBack();
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError("Could not store round, please try again");
    }
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);
  const steps = [
    {
      element: ".groups-hint",
      intro:
        "Select how tee times are sorted - randomly, by handicap, by leaderboard or simply select them yourself",
    },
    {
      element: ".limit-hint",
      intro: "Select how many players to be allocated per tee time",
    },
    {
      element: ".times-hint",
      intro:
        "Select the tee times manually or let them be auto generated, following the starting time",
    },
    {
      element: ".interval-hint",
      intro: "Select how many minutes should pass between tee times",
    },
  ];

  return (
    <div className="event-creator">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      {loading ? (
        <></>
      ) : !chosen ? (
        <CoursePicker
          round
          goBack={goBack}
          setChosen={setChosen}
          setCourseDetails={(e) => {
            setCourse(e);
            setCourseChanged(true);
          }}
        />
      ) : showGroups ? (
        <GroupSelector
          courseDetails={course}
          goBack={() => setShowGroups(false)}
          players={players}
          setOverall={() => {}}
        />
      ) : (
        <div className="round-input ">
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <div className="info-message">
              <div className="svg-cont">
                <GradientInfoIcon className="big-info-icon dg-icon-bg" />
              </div>

              <p>{dialog}</p>
            </div>
          </Dialog>
          <div onClick={goBack} className="back-row">
            <ArrowBack className="ts-arrow" />
            <p>Back</p>
          </div>
          <div style={{ paddingLeft: "0px" }} className="ph-top mt-20">
            <p>
              <>{eventName}</>
            </p>
          </div>

          <PageHeader text="Round Setup" plZero />
          <div className="ph-msg mt-10 pl-0">
            <p className="">Round No. {num}</p>
          </div>

          <div className="sub-header pl-0">
            <h3 className="ne-heder">Details</h3>
          </div>

          <div className="rs-box" style={{ alignItems: "flex-end" }}>
            <div className="input-group">
              <div className="ig-header">
                <p>Course</p>
                <div className="svg-cont">
                  <EditIcon
                    className="dg-icon-bg icon-cursor ml-20"
                    onClick={() => {
                      setChosen(false);
                    }}
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  setChosen(false);
                }}
              >
                <input disabled value={course.label ?? course.name} />
              </div>
            </div>
            {course.courseID !== eventCourseID &&
              course.value !== eventCourseID && (
                <div
                  className="input-group nassau-group"
                  style={{ alignItems: "center" }}
                >
                  <p className="mb-0">Change for overall event</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={changeEvent}
                    className={
                      changeEvent
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => setChangeEvent((current) => !current)}
                  />
                </div>
              )}
            {courseChanged &&
              ((!edit && rounds.length > 0) ||
                (edit && rounds.length > 1) ||
                differentCourses) && (
                <div
                  className="input-group nassau-group"
                  style={{ marginTop: "10px", alignItems: "center" }}
                >
                  <p className="mb-0">Change for all rounds</p>
                  <Switch
                    onColor="#209E73"
                    checked={changeRounds}
                    className={
                      changeRounds
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => setChangeRounds((current) => !current)}
                  />
                </div>
              )}

            {/* <div className="input-group">
            <p>Tees</p>
            <Dropdown options={tees} onChange={(e) => setTee(e)} value={tee} />
        </div> */}
            <div className="input-group">
              <p>Date</p>
              <input type="date" ref={dateRef} defaultValue={defaultDate} />
            </div>
            <div className="input-group groups-hint">
              <p>
                Group Types <span>(Editable at a later date)</span>
              </p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                onChange={(e) => setGroupTypes(e.value)}
                value={groupTypes}
                options={roundIndex === 0 ? typesTwo : types}
              />
            </div>
          </div>

          <div className="rs-box">
            {groupTypes !== "custom" && (
              <>
                <div className="input-group limit-hint">
                  <p>Group Limit (Max 4)</p>
                  {/* <input
                type={"number"}
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
                min={2}
                max={4}
                step={1}
              /> */}
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    value={limit}
                    options={roundOptions}
                    onChange={(e) => {
                      setLimit(e.value);
                    }}
                  />
                </div>
              </>
            )}

            {groupTypes === "custom" && (
              <div className="input-group times-hint">
                <p>Start Type</p>
                <div className="round-switch">
                  <RadioGroupTwo
                    active={startType}
                    setActive={setStartType}
                    buttons={[
                      { text: "Regular", value: "regular" },
                      { text: "Shotgun", value: "shotgun" },
                    ]}
                  />
                </div>
              </div>
            )}
            {groupTypes !== "custom" && (
              <div className="input-group times-hint">
                <p>Tee Times</p>
                <div className="round-switch">
                  <RadioGroupTwo
                    active={timeTypes}
                    setActive={setTimeTypes}
                    buttons={[
                      { text: "Manual", value: "manual" },
                      { text: "Auto", value: "auto" },
                    ]}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="sub-header pl-0 mt-20">
            <h3>Times</h3>
          </div>

          <div className="rs-box">
            <div className="input-group">
              <p>Starting Time</p>
              <input type="time" ref={startRef} defaultValue={defaultTime} />
            </div>

            {groupTypes !== "custom" && (
              <>
                {/* <p>not custom</p> */}
                {timeTypes === "auto" && (
                  <>
                    {startType !== "shotgun" && (
                      <div className="input-group interval-hint">
                        <p>Intervals (minutes)</p>
                        <input
                          defaultValue={defaultIntervals}
                          type={"number"}
                          onChange={(e) => setMinutes(e.target.value)}
                          value={minutes}
                          min={1}
                          max={15}
                          step={1}
                        />
                      </div>
                    )}
                  </>
                )}
                {timeTypes === "manual" &&
                  times.map((ting, index) => (
                    <div className="input-group">
                      <div className="ig-header">
                        <p>Group {index + 1}</p>{" "}
                        <div className="ighr">
                          <div className="ths">
                            <div
                              onClick={() => {
                                setTimes((current) => {
                                  const arr = current;
                                  arr[index].startingTee = 1;
                                  return arr;
                                });
                                setUpdate((current) => current + 1);
                              }}
                              className={
                                times[index].startingTee === 1
                                  ? "thsl tshl-active"
                                  : "thsl"
                              }
                            >
                              <p>1</p>
                            </div>
                            <div
                              onClick={() => {
                                setTimes((current) => {
                                  const arr = current;
                                  arr[index].startingTee = 10;
                                  return arr;
                                });
                                setUpdate((current) => current + 1);
                              }}
                              className={
                                times[index].startingTee === 10
                                  ? "thsr tshl-active"
                                  : "thsr"
                              }
                            >
                              <p>10</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        defaultValue={ting.time ?? ""}
                        onChange={(e) => {
                          setTimes((current) => {
                            const arr = [...current];
                            arr[index].time = e.target.value;
                            return arr;
                          });
                        }}
                        type="time"
                      />
                    </div>
                  ))}
              </>
            )}
            {groupTypes === "custom" && (
              <>
                <div className="input-group mt-20">
                  <div className="ig-header">
                    <p className="mr-20">Open Times</p>
                    <div className="svg-cont">
                      <InfoIcon
                        onClick={() => {
                          setDialog(
                            "Allow players to choose their own tee times via the event page"
                          );
                          setShowDialog(true);
                        }}
                        className="dg-icon-bg"
                      />
                    </div>
                    <Switch
                      className={
                        open
                          ? "scoring-switch switch-on mb-10"
                          : "mb-10 scoring-switch"
                      }
                      onColor="#1e7a69"
                      checked={open}
                      onChange={() => setOpen((current) => !current)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="sub-header pl-0 mt-20">
            <h3>Extras</h3>
          </div>

          <div className="rs-box">
            <div className="input-group ">
              <div className="ig-header">
                <p>Closest to the pin</p>
                <div className="svg-cont">
                  <GradientInfoIcon
                    onClick={() => {
                      setDialog(
                        "Enable a closest to the pin competition on a par 3 of your choice - the winner gets an additional 100 season points."
                      );
                      setShowDialog(true);
                    }}
                    className="info-icon dg-icon-bg"
                  />
                </div>

                <Switch
                  className={
                    enableClosest
                      ? "scoring-switch switch-on mb-10"
                      : "mb-10 scoring-switch"
                  }
                  onColor="#1e7a69"
                  checked={enableClosest}
                  onChange={() => setEnableClosest((current) => !current)}
                />
              </div>
              {/* {enableClosest && ( */}
              <Dropdown
                placeholder={"Select hole..."}
                value={ctpHole}
                onChange={(e) => setCTPHole(e)}
                options={ctpOptions}
                disabled={!enableClosest}
              />
              {/* )} */}
            </div>
            <div className="input-group mt-20">
              <div className="ig-header">
                <p>Longest Drive</p>
                <div className="svg-cont">
                  <GradientInfoIcon
                    onClick={() => {
                      setDialog(
                        "Enable a longest drive competition on a par 4 or 5 of your choice - the winner gets an additional 100 season points."
                      );
                      setShowDialog(true);
                    }}
                    className="info-icon dg-icon-bg"
                  />
                </div>

                <Switch
                  className={
                    enableLD
                      ? "scoring-switch switch-on mb-10"
                      : "mb-10 scoring-switch"
                  }
                  onColor="#1e7a69"
                  checked={enableLD}
                  onChange={() => setEnableLD((current) => !current)}
                />
              </div>
              <Dropdown
                placeholder={"Select hole..."}
                value={ldHole}
                onChange={(e) => setLDHole(e)}
                options={ldOptions}
                disabled={!enableLD}
              />
            </div>
          </div>

          {/* {error !== "" && (
            <div className="error-message-row mt-20">
              <p>{error}</p>
            </div>
          )} */}
          <div className="flex-center mt-20 default-button-row">
            {/* <button onClick={storeRound}>Round</button> */}
            <button className="default-button" onClick={storeRound}>
              Save Round
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoundInput;
