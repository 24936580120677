import React, { useEffect, useState } from "react";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// Components
import { Avatar, Menu } from "@mui/material";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import { Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#edefef" : "transparent",
    // paddingBottom: '20px',
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

function ETMTeam({
  team = {},
  teamCt = 0,
  live = false,
  teamName = "",
  setTeamToRemove = () => {},
  setShowRemoveTeam = () => {},
  setTeamToEdit = () => {},
  setGhostTeam = () => {},
  setShowDia = () => {},
  setShowGhost = () => {},
  setRemoved = () => {},
  setRemIndexes = () => {},
  setShowRemoved = () => {},
  setSwapIndices = () => {},
  setShowSwap = () => {},
  setTeams = () => {},
  teeMap = {},
  players = [],
  index = 0,
  groupLimit = 0,
  swapping = false,
  swapID = "",
  setSwapping = () => {},
  setSwapID = () => {},
  swapMode = false,
  swapIndices,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="etm-team">
      <div className="group-header">
        <h2 className="ne-header">{teamName}</h2>
        <div className="flex-align-center">
          {team.imageLink && <Avatar alt={teamName} src={team.imageLink} />}

          <MoreVertIcon
            className="icon-cursor mr-10"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              onClick={() => {
                setTeamToEdit(team);

                setShowDia(true);
                handleClose();
              }}
              className="rp-mi"
            >
              <p>Edit Details</p>
            </div>
            {/* {teamCt < groupLimit && ( */}
            {live && (
              <div
                onClick={() => {
                  console.log(index, team);
                  setGhostTeam(index);
                  setShowGhost(true);
                  handleClose();
                }}
                className="rp-mi"
              >
                <p>Add Ghost</p>
              </div>
            )}
            {/* )} */}
            <div
              onClick={() => {
                setTeamToRemove(index);
                setShowRemoveTeam(true);
                handleClose();
              }}
              className="rp-mi"
            >
              <p>Remove</p>
            </div>
          </Menu>
        </div>
      </div>
      {players.map((player, index2) => (
        <Draggable
          index={index2}
          key={`${player.playerID}${index}${index2}` ?? index2}
          draggableId={`${player.playerID}`}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              {player.name === "Empty" ? (
                <div className="gs-empty">
                  <AddCircleIcon
                    onClick={() => {
                      setRemIndexes({
                        i1: index,
                        i2: index2,
                      });
                      setShowRemoved(true);
                    }}
                    className="big-icon icon-cursor dg-icon"
                    sx={{ fill: "url(#linearColors)" }}
                  />
                </div>
              ) : (
                <RoundPlayer
                  hideVert={swapMode}
                  swapClick={() => {
                    if (!swapMode) {
                      return;
                    }
                    if (swapping) {
                      const { i1, i2 } = swapIndices;
                      setTeams((current) => {
                        const p1 = current[i1].players[i2];
                        let p2;
                        let found = false;
                        let p2group;
                        let p2index;
                        for (let i = 0; i < current.length; i++) {
                          if (found) {
                            break;
                          }
                          const group = current[i];
                          const players = group.players;
                          for (let j = 0; j < players.length; j++) {
                            if (players[j].playerID === player.playerID) {
                              p2 = players[j];
                              p2group = i;
                              p2index = j;
                              found = true;
                            }
                          }
                        }
                        if (p2 === undefined) {
                          p2 = player;
                          current[i1].players[i2] = p2;
                        } else {
                          current[p2group].players[p2index] = p1;
                          current[i1].players[i2] = p2;
                        }
                        return current;
                      });
                      setSwapID("");
                      setSwapping(false);
                    } else {
                      setSwapID(player.playerID);
                      setSwapIndices({
                        i1: index,
                        i2: index2,
                      });
                      setSwapping(true);
                    }
                  }}
                  selSwap={swapID === player.playerID && swapping}
                  swapMode={swapMode}
                  event
                  hideTee
                  swapAction={() => {
                    setSwapIndices({
                      i1: index,
                      i2: index2,
                    });
                    setShowSwap(true);
                  }}
                  removeAction={() => {
                    setRemoved((current) => {
                      if (player.ghost) {
                        // console.log(current);
                        return current;
                      } else {
                        return [...current, player];
                      }
                    });

                    setTeams((current) => {
                      current[index].players[index2] = {
                        name: "Empty",
                      };
                      // console.log(current);
                      return current;
                    });
                    // handleClose();
                  }}
                  live
                  // hideVert
                  handleSetup={() => {}}
                  border
                  tee={teeMap[player.playerID] ?? ""}
                  playerID={player.playerID}
                  key={`player${player.playerID}`}
                  handicap={player.handicap}
                  imageLink={player.profileImage}
                  name={player.name}
                />
              )}
            </div>
          )}
        </Draggable>
      ))}
    </div>
  );
}

export default ETMTeam;
