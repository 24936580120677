import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

// Icons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Components
import { Dialog } from "@mui/material";
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackdropLoader from "../Loaders/BackdropLoader";
import PlayerItem from "../display/PlayerItem/PlayerItem";
function TLEMatchup({ leagueID = "", eventID = "", goBack = () => {} }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((current) => current + 1);
  }

  const [filled, setFilled] = useState(false);

  const [putBack, setPutBack] = useState();

  const [teams, setTeams] = useState([]);
  const [matchups, setMatchups] = useState([]);

  const [editIndex, setEditIndex] = useState([]);

  const [used, setUsed] = useState([]);

  const [showSelect, setShowSelect] = useState(false);

  useState(() => {
    console.log("tragged");
    let f = true;

    for (let i = 0; i < matchups.length; i++) {
      const match = matchups[i];
      const { teamOne, teamTwo } = match;
      console.log(teamOne, teamTwo);
      if (teamOne.teamID === "" || teamTwo.teamID === "") {
        f = false;
      }
    }
    console.log(f);
    setFilled(f);
    upd();
  }, [update]);

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();

    const { teams } = league;

    setTeams(teams);

    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const matchups = event.matchups ?? [];

    const playing = [];

    for (let i = 0; i < matchups.length; i++) {
      const matchup = matchups[i];
      const { teamOne, teamTwo } = matchup;

      if (teamOne.teamID !== "") {
        playing.push(teamOne.teamID);
      }
      if (teamTwo.teamID !== "") {
        playing.push(teamTwo.teamID);
      }
    }

    setUsed(playing);

    setMatchups(matchups);
    setLoading(false);
  }

  function validate() {
    let f = true;

    for (let i = 0; i < matchups.length; i++) {
      const match = matchups[i];
      const { teamOne, teamTwo } = match;
      console.log(teamOne, teamTwo);
      if (teamOne.teamID === "" || teamTwo.teamID === "") {
        f = false;
      }
    }

    if (f === false) {
      return false;
    }
  }

  async function saveChanges() {
    setLoading(true);

    const val = validate();

    if (val === false) {
      setError("Please ensure all matchups are filled in");
      return setLoading(false);
    }

    try {
      await db.collection("teamEvents").doc(eventID).update({
        matchups,
      });
      goBack();
    } catch (err) {
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  function addMatch() {
    const newMatch = {
      teamOne: {
        name: "Empty",
        teamID: "",
        imageLink: "",
        score: 0,
      },
      teamTwo: {
        name: "Empty",
        teamID: "",
        imageLink: "",
        score: 0,
      },
    };
    setMatchups((current) => [...current, newMatch]);
    upd();
  }

  return (
    <div className="tle-matchups">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Team Matchups" />
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog
        open={showSelect}
        onClose={() => {
          setShowSelect(false);
          setPutBack();
          upd();
        }}
      >
        <PageHeader
          close={() => {
            setShowSelect(false);
            setPutBack();
            upd();
          }}
          text="Select Team"
          showClose
        />
        <div className="ph-msg">
          <p>Select a team for this matchup </p>
        </div>
        <div className="pd-20 flex-wrap">
          {teams.map((team, index) => {
            if (used.includes(team.teamID)) {
              return null;
            }
            return (
              <PlayerItem
                key={index}
                showProfile={() => {
                  const ret = {
                    name: team.teamName,
                    teamID: team.teamID,
                    imageLink: team.imageLink,
                  };
                  setMatchups((current) => {
                    if (editIndex[1] === 1) {
                      current[editIndex[0]].teamOne = ret;
                    } else {
                      current[editIndex[0]].teamTwo = ret;
                    }
                    console.log(current);
                    return current;
                  });
                  setEditIndex([]);
                  if (putBack) {
                    console.log(putBack.teamID);

                    setUsed((current) => {
                      const filt = current.filter(
                        (tm) => tm !== putBack.teamID
                      );
                      console.log(filt);
                      return filt;
                    });
                    setPutBack();
                  }
                  setUsed((current) => [...current, team.teamID]);
                  upd();
                  setShowSelect(false);
                }}
                name={team.teamName}
                img={team.imageLink}
                hideIcons
                noLink
                hideHCP
              />
            );
          })}
        </div>
      </Dialog>

      <div className="pd-20">
        <p onClick={addMatch} className="green-link">
          Add Match
        </p>
      </div>

      <div className="tlem-box pd-20">
        {matchups.map((mtch, index) => {
          const { teamOne, teamTwo } = mtch;
          return (
            <div
              key={`${index}matchup`}
              className="tlem mb-20 flex-align-center"
            >
              <div className="tlem-left">
                <PlayerItem
                  name={teamOne.name}
                  img={teamOne.imageLink}
                  noLink
                  hideProfile
                  showAdd
                  hideHCP
                  hideRemove
                  editAction={() => {
                    if (teamOne.teamID !== "") {
                      setPutBack(teamOne);
                    }
                    setEditIndex([index, 1]);
                    setShowSelect(true);
                  }}
                  hideMessage
                />
              </div>
              <div className="vs-pee">
                <p>vs</p>
              </div>
              <div className="tlem-right">
                <PlayerItem
                  name={teamTwo.name}
                  img={teamTwo.imageLink}
                  noLink
                  hideRemove
                  showAdd
                  hideProfile
                  hideHCP
                  hideMessage
                  editAction={() => {
                    if (teamTwo.teamID !== "") {
                      setPutBack(teamTwo);
                    }
                    setEditIndex([index, 2]);
                    setShowSelect(true);
                  }}
                />
              </div>
              <div className="ml-20">
                <DeleteForeverIcon
                  className="icon-cursor red-icon-bg ml-20"
                  onClick={() => {
                    setUsed((current) => {
                      const filt1 = current.filter(
                        (tm) => tm !== teamOne.teamID
                      );
                      const filt2 = filt1.filter((tm) => tm !== teamTwo.teamID);

                      return filt2;
                    });
                    setMatchups((current) => {
                      current.splice(index, 1);
                      return current;
                    });
                    upd();
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>

      {matchups.length > 0 && (
        <div className="flex-center mb-40">
          <button
            onClick={saveChanges}
            disabled={!filled}
            className="default-button"
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
}

export default TLEMatchup;
