import React, { useState, useEffect } from "react";
import "./Players.css";
import { db, admin } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import algoliasearch from "algoliasearch/lite";
import RadioGroup from "../../components/Buttons/RadioGroup/RadioGroup";
import Pill from "../../components/Buttons/Pill";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import CourseItem from "../../components/display/CourseItem/CourseItem";
import UserImport from "../../components/UserImport/UserImport";
import BulkImport from "../../components/UserImport/BulkImport";

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      onClick={onClick}
      className="big-tick"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function Players() {
  // Admin
  const searchClient = algoliasearch(
    "QF50LJZ12F",
    "729ebd0baad19471b95fc34f74b98202"
  );
  const playerIndex = searchClient.initIndex("uga_tour_players");
  const { currentUser, userDetails } = useAuth();

  // State

  const [loading, setLoading] = useState(true);
  const [friends, setFriends] = useState([]);
  const [addedPlayers, setAddedPlayers] = useState([]);
  const [active, setActive] = useState("friends");
  const [searchInput, setSearchInput] = useState("");
  const [showAddLeague, setShowAddLeague] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [playerToAdd, setPlayerToAdd] = useState({});
  const [selLeague, setSelLeague] = useState("");

  const [searchedPlayers, setSearchedPlayers] = useState([]);

  const [playerToRemove, setPlayerToRemove] = useState({});
  const [showRemove, setShowRemove] = useState(false);
  const [removed, setRemoved] = useState(false);

  const [saving, setSaving] = useState(false);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [added, setAdded] = useState([]);

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [notFound, setNotFound] = useState("");

  useEffect(() => {
    if (userDetails) {
      loadFriends();
    }
  }, [userDetails]);

  useEffect(() => {
    setSearchInput("");
  }, [active]);

  async function loadFriends() {
    const friends = userDetails.friends ?? [];
    const refs = friends.map((uid) => db.collection("users").doc(uid).get());

    const arr = [];
    const addedArr = [];
    const leagueArr = [];

    const docs = await Promise.all(refs);
    const leagues = await db
      .collection("leagues")
      .where("admins", "array-contains", currentUser.uid)
      .get();

    const players = await db
      .collection("users")
      .where("addedBy", "==", currentUser.uid)
      .get();

    for (let i = 0; i < docs.length; i++) {
      const userDoc = docs[i];
      const playerID = userDoc.id;

      const player = userDoc.data();
      if (!player) {
        continue;
      }

      const { firstName, lastName, handicap, profileImage, addedBy } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
      });
    }

    for (let i = 0; i < leagues.docs.length; i++) {
      const leagueDoc = leagues.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();
      const { leagueName, imageLink, city, country, playerIDs } = league;
      leagueArr.push({
        leagueName,
        imageLink,
        city,
        country,
        leagueID,
        playerIDs,
      });
    }

    for (let i = 0; i < players.docs.length; i++) {
      const playerDoc = players.docs[i];
      const playerID = playerDoc.id;
      const player = playerDoc.data();
      const { firstName, lastName, profileImage, handicap, dateJoined } =
        player;

      const djObject = new Date(dateJoined.toDate());
      const dateString = djObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });

      addedArr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        playerID,
        profileImage,
        dateString,
      });
    }

    setLeagues(leagueArr);
    setAddedPlayers(addedArr);
    setFriends(arr);
    setLoading(false);
  }

  async function addToLeague() {
    // return console.log(selLeague);
    setSaving(true);
    const { playerID } = playerToAdd;
    try {
      await db
        .collection("leagues")
        .doc(selLeague)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(playerID),
        });
      await db
        .collection("users")
        .doc(playerID)
        .update({
          leagueIDs: admin.firestore.FieldValue.arrayUnion(selLeague),
        });
      setAdded(true);
    } catch (err) {
      console.log(err);
    }
    setSaving(false);
  }

  async function removePlayerAsFriend(playerID) {
    setSaving(true);
    try {
      await db
        .collection("users")
        .doc(currentUser.uid)
        .update({
          friends: admin.firestore.FieldValue.arrayRemove(playerID),
        });
      setRemoved(true);
    } catch (err) {
      console.log(err);
    }
    loadFriends();
    setSaving(false);
  }
  async function addPlayerAsFriend(playerID) {
    setSaving(true);
    try {
      await db
        .collection("users")
        .doc(currentUser.uid)
        .update({
          friends: admin.firestore.FieldValue.arrayUnion(playerID),
        });
      setRemoved(true);
    } catch (err) {
      console.log(err);
    }
    loadFriends();
    setSaving(false);
  }

  async function directSearch() {
    setLoadingSearch(true);
    setNotFound(false);
    playerIndex
      .search(searchInput)
      .then(({ hits }) => {
        console.log(hits);
        const algoArr = [];

        for (let n = 0; n < hits.length; n++) {
          const algoCourse = hits[n];
          // console.log(algoCourse);
          const {
            objectID,
            handicap,
            profileImage,
            firstName,
            lastName,
            city,
            country,
            state,
          } = algoCourse;
          algoArr.push({
            playerID: objectID,
            name: `${firstName} ${lastName}`,
            profileImage,
            handicap,
            city,
            country,
            state,
          });
        }
        console.log(algoArr);
        setSearchedPlayers(algoArr);
        if (algoArr.length === 0) {
          setNotFound(true);
        }
        setLoadingSearch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="players-page">
      {loading && <BackdropLoader />}
      <Dialog open={showRemove} onClose={() => setShowRemove(false)}>
        <div className="friend-remove">
          <div className="page-header">
            <h2>Remove Friend</h2>
          </div>
          {!removed && (
            <>
              <div className="ph-msg">
                <p>
                  Are you sure you want to remove {playerToRemove.name} as a
                  friend?
                </p>
              </div>
              <div className="flex-center">
                <button
                  onClick={removePlayerAsFriend}
                  className="default-button"
                >
                  Confirm
                </button>
              </div>
            </>
          )}
          {removed ? (
            <>
              <div className="mpa-fin flex-center">
                <GradientCircleIcon />
              </div>
            </>
          ) : saving ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </Dialog>
      <Dialog open={showAddLeague} onClose={() => setShowAddLeague(false)}>
        <div className="au-league">
          <div className="page-header">
            <h2>Add to League</h2>
          </div>
          {!added && (
            <div className="ph-msg">
              <p>Select a league to add {playerToAdd.name} to</p>
            </div>
          )}
          {added ? (
            <>
              <div className="mpa-fin flex-center">
                <GradientCircleIcon />
              </div>
            </>
          ) : saving ? (
            <div className="flex-center loading">
              <Oval
                color="#1e7a69"
                secondaryColor="#ffffff"
                height={40}
                width={40}
              />
            </div>
          ) : (
            <>
              <div className="aul-box">
                {leagues.map((league) => {
                  if (league.playerIDs.includes(playerToAdd.playerID)) {
                    return null;
                  }

                  return (
                    <CourseItem
                      key={league.leagueID}
                      city={league.city}
                      country={league.country}
                      courseName={league.leagueName}
                      image={league.imageLink}
                      hideStar
                      showCourse={() => setSelLeague(league.leagueID)}
                      showBG={league.leagueID === selLeague}
                    />
                  );
                })}
              </div>
            </>
          )}
          {!saving && !added && (
            <div className="default-button-row">
              <button
                onClick={addToLeague}
                disabled={selLeague === ""}
                className="default-button"
              >
                Add Player To League
              </button>
            </div>
          )}
        </div>
      </Dialog>
      {show ? (
        toShow
      ) : (
        <>
          <div className="page-header">
            <h2>Players Home</h2>
          </div>
          <div className="ph-msg">
            <p>
              Interact with people you've added, or find new friends on the app
            </p>
          </div>
          <div className="sub-header">
            <h5>Quick Links</h5>
          </div>
          <div className="quick-links">
            <Pill
              text={"Add New User"}
              onClick={() => {
                setToShow(
                  <UserImport
                    goBack={() => setShow(false)}
                    edit={false}
                    // clubID={userDetails && userDetails.clubID}
                  />
                );
                setShow(true);
              }}
            />
            {userDetails &&
              (userDetails.accountType === "admin" ||
                userDetails.accountType === "club") && (
                <Pill
                  text={"Bulk Import"}
                  onClick={() => {
                    setToShow(<BulkImport goBack={() => setShow(false)} />);
                    setShow(true);
                  }}
                />
              )}
          </div>
          <RadioGroup
            active={active}
            buttons={[
              { text: "Friends", value: "friends" },
              { text: "Added", value: "added" },
              { text: "Search", value: "search" },
            ]}
            setActive={setActive}
          />

          {active === "friends" ? (
            <>
              <div className="sub-header">
                <h3>Your Friends</h3>
              </div>
              <div className="input-group mb-20 si-search">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="pp-friends">
                {friends.map((player, index) => {
                  if (searchInput !== "") {
                    const sl = searchInput.toLowerCase();
                    if (player.name.toLowerCase().includes(sl)) {
                      return (
                        <PlayerItem
                          removeAction={() => {
                            setRemoved(false);
                            setSaving(false);
                            setPlayerToRemove(player);
                            setShowRemove(true);
                          }}
                          key={player.playerID}
                          hcp={player.handicap}
                          hideEdit
                          name={player.name}
                          playerID={player.playerID}
                          img={player.profileImage}
                          addAction={() => {
                            setAdded(false);
                            setSaving(false);
                            setPlayerToAdd(player);
                            setShowAddLeague(true);
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                  return (
                    <PlayerItem
                      removeAction={() => {
                        setRemoved(false);
                        setSaving(false);
                        setPlayerToRemove(player);
                        setShowRemove(true);
                      }}
                      key={player.playerID}
                      hcp={player.handicap}
                      hideEdit
                      name={player.name}
                      playerID={player.playerID}
                      img={player.profileImage}
                      addAction={() => {
                        setAdded(false);
                        setSaving(false);
                        setPlayerToAdd(player);
                        setShowAddLeague(true);
                      }}
                    />
                  );
                })}
                {friends.length === 0 && (
                  <div className="no-msg">
                    <p>No users to display</p>
                  </div>
                )}
              </div>
            </>
          ) : active === "added" ? (
            <>
              <div className="sub-header">
                <h3>Added Players</h3>
              </div>
              <div className="input-group mb-20 si-search">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="pp-friends">
                {addedPlayers.map((player, index) => {
                  if (searchInput !== "") {
                    const sl = searchInput.toLowerCase();
                    if (player.name.toLowerCase().includes(sl)) {
                      return (
                        <PlayerItem
                          removeAction={() => {
                            setRemoved(false);
                            setSaving(false);
                            setPlayerToRemove(player);
                            setShowRemove(true);
                          }}
                          editAction={() => {
                            setToShow(
                              <UserImport
                                goBack={() => setShow(false)}
                                edit
                                userID={player.playerID}
                              />
                            );
                            setShow(true);
                          }}
                          key={player.playerID}
                          hcp={player.handicap}
                          hideRemove
                          name={player.name}
                          playerID={player.playerID}
                          img={player.profileImage}
                          addAction={() => {
                            setAdded(false);
                            setSaving(false);
                            setPlayerToAdd(player);
                            setShowAddLeague(true);
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                  return (
                    <PlayerItem
                      removeAction={() => {
                        setRemoved(false);
                        setSaving(false);
                        setPlayerToRemove(player);
                        setShowRemove(true);
                      }}
                      key={player.playerID}
                      hcp={player.handicap}
                      hideRemove
                      editAction={() => {
                        setToShow(
                          <UserImport
                            goBack={() => setShow(false)}
                            edit
                            userID={player.playerID}
                          />
                        );
                        setShow(true);
                      }}
                      name={player.name}
                      playerID={player.playerID}
                      img={player.profileImage}
                      addAction={() => {
                        setAdded(false);
                        setSaving(false);
                        setPlayerToAdd(player);
                        setShowAddLeague(true);
                      }}
                    />
                  );
                })}
                {addedPlayers.length === 0 && (
                  <div className="no-msg">
                    <p>No users to display</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="sub-header">
                <h3>Search for players</h3>
              </div>
              <div className="input-group mb-20 si-search">
                <p>Search</p>

                <div className="sis-ig">
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        directSearch();
                      }
                    }}
                    type="text"
                    placeholder="Search for player (min 3 characters)"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="default-input"
                  />
                  <button
                    disabled={searchInput.length < 3}
                    onClick={directSearch}
                    className="default-button"
                  >
                    Search
                  </button>
                </div>
              </div>

              {loadingSearch ? (
                <>
                  <div className="flex-center mb-20 sis-loading">
                    <Oval
                      color="#1e7a69"
                      secondaryColor="#ffffff"
                      height={40}
                      width={40}
                    />
                  </div>
                </>
              ) : (
                <div className="pp-friends">
                  {searchedPlayers.map((player, index) => {
                    console.log(userDetails.friends.includes(player.playerID));
                    return (
                      <PlayerItem
                        hideRemove
                        key={player.playerID}
                        hcp={player.handicap}
                        hideEdit
                        addIsFriend={true}
                        name={player.name}
                        showAdd={userDetails.friends.includes(player.playerID)}
                        playerID={player.playerID}
                        img={player.profileImage}
                        addAction={() => {
                          addPlayerAsFriend(player.playerID);
                        }}
                      />
                    );
                  })}
                  {notFound && (
                    <div className="no-msg">
                      <p>No players found</p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Players;
