import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Components
import MatchplayScore from "../../components/display/MatchplayScore/MatchplayScore";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import EditIcon from "@mui/icons-material/Edit";
import NewsPill from "./NewsPill";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { Dialog, Tooltip } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PageHeader from "../../components/display/Rows/PageHeader";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";

const GradientEditIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <EditIcon
      onClick={onClick}
      className={`dg-icon ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
// Mui

// Gradient Icons
function MatchplayBracket({
  tournID = "",
  goBack = () => {},
  edit = false,
  display = false,
  players = [],
  bracket = [],
  hideBack = false,
  reload = () => {},
  started = false,
}) {
  const [matches, setMatches] = useState(bracket);
  const [loading, setLoading] = useState(false);

  const [showSelector, setShowSelector] = useState(false);

  const [error, setError] = useState("");
  const [ri, setRI] = useState(0);

  const [matchEdit, setMatchEdit] = useState(0);
  const [playerToSwap, setPlayerToSwap] = useState();
  const [playerToExclude, setPlayerToExclude] = useState();

  const [showManual, setShowManual] = useState(false);
  const [manIndex, setManIndex] = useState([]);
  const [manMatch, setManMatch] = useState();
  const [selWinner, setSelWinner] = useState();

  const [manualBy, setManualBy] = useState(0);
  const [manualRem, setManualRem] = useState(0);

  const [changed, setChanged] = useState(false);

  const [update, setUpdate] = useState(0);

  const [pillFilter, setPillFilter] = useState("all");

  const [dis, setDis] = useState([]);

  useEffect(() => {
    if (!showManual) {
      setSelWinner();
      setManMatch();
    }
  }, [showManual]);

  function shuffle(array) {
    // alert("everyday");
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  async function saveChanges() {
    setLoading(true);
    await db.collection("tournaments").doc(tournID).update({
      bracket: matches,
    });
    reload();
    setLoading(false);
    goBack();
  }

  async function savePostChanges() {
    setLoading(true);
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tourn = tournDoc.data();

    const { bracket } = tourn;

    const brackRd = bracket[ri].matches;
    const matchRd = matches[ri].matches;

    for (let i = 0; i < brackRd.length; i++) {
      if (!brackRd[i].started && !brackRd[i].completed) {
        bracket[ri].matches[i] = matchRd[i];
      }
    }

    // console.log(bracket);
    await tournDoc.ref.update({
      bracket,
    });
    setLoading(false);
    goBack();
  }

  useEffect(() => {
    if (bracket.length === 0) {
      sortBracket();
    } else {
      if (false) {
        loadLiveRounds();
      } else {
        const arr = [];

        let rI = 0;

        for (let i = 0; i < bracket.length; i++) {
          const matches = bracket[i].matches;
          let rdCompleted = true;
          for (let j = 0; j < matches.length; j++) {
            const match = matches[j];

            if (!match.completed) {
              rdCompleted = false;
            }
          }
          if (!rdCompleted) {
            rI = i;
            break;
          }
        }
        setRI(rI);
        // console.log(players);

        for (let j = 0; j < rI; j++) {
          for (let i = 0; i < bracket[j].matches.length; i++) {
            const match = bracket[j].matches[i];
            const { winnerID, players } = match;
            if (winnerID === players[0].playerID) {
              arr.push(players[1].playerID);
            } else {
              arr.push(players[0].playerID);
            }
          }
        }
        for (let i = 0; i < bracket[rI].matches.length; i++) {
          const match = bracket[rI].matches[i];
          const { players, completed, started } = match;
          if (started || completed) {
            arr.push(players[0].playerID);
            arr.push(players[1].playerID);
          }
        }
        // console.log(arr);
        setDis(arr);
      }
    }
  }, []);

  async function loadLiveRounds() {
    const arr = [];
    for (let j = 0; j < bracket.length; j++) {
      for (let i = 0; i < bracket[j].matches.length; i++) {
        const match = bracket[j].matches[i];
        const { players, completed, started } = match;
        if (started || completed) {
          arr.push(players[0].playerID);
          arr.push(players[1].playerID);
        }

        if (match.roundID && !match.completed) {
          const roundDoc = await db
            .collection("rounds")
            .doc(match.roundID)
            .get();
          const round = roundDoc.data();
          const { leaderboard } = round;
          bracket[j].matches[i].winnerID = leaderboard.leader;
          bracket[j].matches[i].by = leaderboard.by;
          bracket[j].matches[i].rem = 0;
        }
      }
    }
    console.log(bracket);
    setMatches(bracket);
    setDis(arr);
  }

  function sortBracket() {
    const rounded = Math.ceil(players.length / 8) * 8;
    const rounds = Math.log2(players.length);
    const remainder = players.length % 2;
    // console.log(rounds, remainder);

    const shuffled = shuffle(players);

    const brackArr = [];

    let counter = 1;

    for (let i = 0; i < rounds; i++) {
      const temp = {
        matches: [],
      };
      let matches = Math.ceil(players.length / 2 / (i + 1));
      if (i === rounds - 1) {
        matches = 1;
      }
      console.log(matches);
      for (let x = 0; x < matches; x++) {
        temp.matches.push({
          match: counter,
          players: [],
          qualis: [],
          winnerID: "",
          winnerName: "",
          by: "",
        });
        counter++;
      }
      brackArr.push(temp);
    }

    if (brackArr.length === 0) {
      return;
    }

    for (let i = 0; i < shuffled.length; i++) {
      let found = false;
      for (let x = 0; x < brackArr[0].matches.length; x++) {
        const players = brackArr[0].matches[x].players;
        // console.log(players);
        if (found) {
          continue;
        }
        if (players.length < 2) {
          brackArr[0].matches[x].players.push(shuffled[i]);
          found = true;
        }
      }
    }

    for (let i = 0; i < brackArr[0].matches.length; i++) {
      if (brackArr[0].matches[i].players.length === 0) {
        brackArr[0].matches[i].players.push({
          name: "",
        });
        brackArr[0].matches[i].players.push({
          name: "",
        });
      } else if (brackArr[0].matches[i].players.length === 1) {
        brackArr[0].matches[i].players.push({
          name: "",
        });
      }
    }

    for (let i = 1; i < brackArr.length; i++) {
      const roundMatches = brackArr[i].matches;
      const prevRoundMatches = brackArr[i - 1].matches;
      const matchNums = prevRoundMatches.map((match) => match.match);
      // console.log(matchNums);
      for (let x = 0; x < roundMatches.length; x++) {
        brackArr[i].matches[x].players.push({
          name: `Winner Match ${matchNums[x * 2]}`,
        });
        let cnt1 = x * 2;
        brackArr[i].matches[x].qualis.push(matchNums[cnt1]);
        // brackArr[i].matches[x]
        brackArr[i].matches[x].players.push({
          name: `Winner Match ${matchNums[2 * x + 1]}`,
        });
        brackArr[i].matches[x].qualis.push(matchNums[2 * x + 1]);
      }
    }

    // console.log(brackArr);

    setMatches(brackArr);
  }

  function removeNumbersAndParseInt(text) {
    // Remove any non-digit characters from the text
    const cleanText = text.replace(/\D/g, "");

    // Convert the cleaned text to an integer
    const parsedNumber = parseInt(cleanText, 10);

    // Return the parsed number as an integer
    return parsedNumber;
  }

  async function completeMatch(i1, i2) {
    setLoading(true);
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tourn = tournDoc.data();

    const { bracket } = tourn;

    const match = bracket[i1].matches[i2];
    let winner;
    let winnerID = "";
    let winnerName = "";
    if (match.players[0].name === "") {
      winnerID = match.players[1].playerID;
      winnerName = match.players[1].name;
      winner = match.players[1];
    } else {
      winnerID = match.players[0].playerID;
      winnerName = match.players[0].name;
      winner = match.players[0];
    }

    bracket[i1].matches[i2].by = "BYE";
    bracket[i1].matches[i2].completed = true;
    bracket[i1].matches[i2].winnerID = winnerID;
    bracket[i1].matches[i2].winnerName = winnerName;
    const matchNum = match.match;

    const nextRound = bracket[i1 + 1];

    const nrMatches = nextRound.matches;

    for (let i = 0; i < nrMatches.length; i++) {
      const mtc = nrMatches[i];
      if (mtc.qualis.includes(matchNum)) {
        const { players } = mtc;
        const n1 = players[0].name;
        if (removeNumbersAndParseInt(n1) === matchNum) {
          bracket[i1 + 1].matches[i].players[0] = winner;
        } else {
          bracket[i1 + 1].matches[i].players[1] = winner;
        }
      }
    }

    // return console.log(bracket);
    try {
      await tournDoc.ref.update({
        bracket,
      });
      setMatches(bracket);
      setUpdate((current) => current + 1);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  async function manualFinish(i1, i2) {
    setShowManual(false);
    setLoading(true);
    const bracket = [...matches];
    const match = bracket[i1].matches[i2];
    const matchNum = match.match;
    bracket[i1].matches[i2].winnerID = selWinner.playerID;
    bracket[i1].matches[i2].manual = true;
    bracket[i1].matches[i2].by = parseInt(manualBy);
    bracket[i1].matches[i2].rem = parseInt(manualRem);
    bracket[i1].matches[i2].winnerName = selWinner.name;
    bracket[i1].matches[i2].completed = true;
    let nrMatches = [];
    if (i1 !== bracket.length - 1) {
      const nextRound = bracket[i1 + 1];
      nrMatches = nextRound.matches;
    }

    const winner = selWinner;
    let loser;

    for (let i = 0; i < nrMatches.length; i++) {
      const mtc = nrMatches[i];
      if (mtc.qualis.includes(matchNum)) {
        const { players } = mtc;
        const n1 = players[0].name;
        console.log(removeNumbersAndParseInt(n1));
        if (removeNumbersAndParseInt(n1) === matchNum) {
          bracket[i1 + 1].matches[i].players[0] = winner;
        } else {
          bracket[i1 + 1].matches[i].players[1] = winner;
        }
      }
    }

    const res = {
      date: new Date(),
      playerOne: match.players[0],
      playerTwo: match.players[1],
      by: 1,
      winner,
      roundID: match.roundID ?? "",
    };
    // setLoading(false);
    // return console.log(bracket);

    try {
      if (i1 === bracket.length - 1) {
        await db
          .collection("tournaments")
          .doc(tournID)
          .update({
            bracket,
            winnerID: winner.playerID,
            winnerName: winner.winnerName,
            completed: true,
            results: admin.firestore.FieldValue.arrayUnion(res),
          });
      } else {
        await db
          .collection("tournaments")
          .doc(tournID)
          .update({
            bracket,
            results: admin.firestore.FieldValue.arrayUnion(res),
          });
      }

      if (match.roundID) {
        for (let i = 0; i < match.players.length; i++) {
          const player = match.players[i];
          await db.collection("users").doc(player.playerID).update({
            activeRoundID: "",
          });
        }
      }
      setManualBy(0);
      setManualRem(0);
      setManMatch();
      setManIndex([]);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  return (
    <div className="mpl-bracket">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <Dialog open={showManual} onClose={() => setShowManual(false)}>
        <div className="mpb-manual pd-40">
          <PageHeader
            close={() => setShowManual(false)}
            text="Manual Input"
            showClose
          />
          <div className="ph-msg mb-20">
            <p>Select the player who won this game </p>
          </div>
          {manMatch && (
            <div className="mpb-match">
              <PlayerItem
                showBg={
                  selWinner &&
                  selWinner.playerID === manMatch.players[0].playerID
                }
                showProfile={() => setSelWinner(manMatch.players[0])}
                hideRemove
                hcp={manMatch.players[0].handicap}
                hideImage
                showAdd
                hideMessage
                hideProfile
                noLink
                hideIcons
                name={manMatch.players[0].name}
              />
              <div className="mp-cir-box">
                <div className="mpbm-circle">
                  <p>vs</p>
                </div>
              </div>

              <PlayerItem
                showBg={
                  selWinner &&
                  selWinner.playerID === manMatch.players[1].playerID
                }
                showProfile={() => setSelWinner(manMatch.players[1])}
                hideRemove
                hcp={manMatch.players[1].handicap}
                hideImage
                hideIcons
                showAdd
                hideMessage
                hideProfile
                noLink
                name={manMatch.players[1].name}
              />
            </div>
          )}
          <div className="mp-man-holes">
            <div className="input-group">
              <p>Holes Up</p>
              <input
                type="number"
                onChange={(e) => setManualBy(e.target.value)}
                value={manualBy}
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Holes Remaining</p>
              <input
                type="number"
                onChange={(e) => setManualRem(e.target.value)}
                value={manualRem}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center">
            <button
              disabled={
                selWinner === undefined || (manualRem === 0 && manualBy === 0)
              }
              className="default-button"
              onClick={() => manualFinish(manIndex[0], manIndex[1])}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showSelector} onClose={() => setShowSelector(false)}>
        <div className="mpl-selector">
          <PageHeader text="Select a player for this slot" />

          <div className="mpls-players">
            {players.map((player) => {
              if (dis.includes(player.playerID)) {
                return null;
              }
              if (playerToExclude) {
                if (
                  player.playerID === playerToExclude.playerID ||
                  playerToSwap.playerID === player.playerID
                ) {
                  return null;
                }
              }
              return (
                <PlayerItem
                  key={`${player.playerID}sel`}
                  hideImage
                  hideIcons
                  hideRemove
                  hcp={player.handicap}
                  name={player.name}
                  img={player.profileImage}
                  showProfile={() => {
                    setMatches((current) => {
                      const temp = [...current];
                      for (let i = 0; i < temp[ri].matches.length; i++) {
                        const match = temp[ri].matches[i];
                        if (match.match === matchEdit) {
                          // console.log(match);
                          if (
                            match.players[0].playerID === playerToSwap.playerID
                          ) {
                            // console.log("a");
                            // console.log(player);
                            temp[ri].matches[i].players[0] = player;
                          } else if (
                            match.players[1].playerID === playerToSwap.playerID
                          ) {
                            temp[ri].matches[i].players[1] = player;
                            // console.log("b");
                          }
                        }
                      }

                      let found = false;

                      for (let i = 0; i < temp[ri].matches.length; i++) {
                        const match = temp[ri].matches[i];
                        if (found || match.match === matchEdit) {
                          continue;
                        }
                        if (match.players[0].playerID === player.playerID) {
                          // console.log("aye", match.match);
                          temp[ri].matches[i].players[0] = playerToSwap;
                          found = true;
                        } else if (
                          match.players[1].playerID === player.playerID
                        ) {
                          console.log("bee", match.match);
                          temp[ri].matches[i].players[1] = playerToSwap;
                          found = true;
                        }
                      }
                      console.log(temp);
                      return temp;
                    });
                    setUpdate((current) => current + 1);
                    setShowSelector(false);
                    setChanged(true);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Dialog>

      {loading && <BackdropLoader />}
      {!hideBack && (
        <div onClick={() => goBack()} className="back-row">
          <ArrowBack />
          <p>Back</p>
        </div>
      )}

      <PageHeader text="Tournament Bracket" />
      {!display && !started && (
        <div className="input-group pl-20">
          <p
            onClick={() => {
              sortBracket();
            }}
            className="green-link shuffle-players"
          >
            Shuffle
          </p>
        </div>
      )}
      <div className="mtp-pills">
        <NewsPill
          text={"All"}
          active={pillFilter === "all"}
          handleClick={() => setPillFilter("all")}
        />
        {matches.map((round, index) => (
          <NewsPill
            text={index === matches.length - 1 ? "Final" : `Round ${index + 1}`}
            active={pillFilter === index}
            handleClick={() => setPillFilter(index)}
            key={`${index}pill`}
          />
        ))}
      </div>
      <div className="mpl-fixtures">
        {!edit &&
          matches.map((round, index) => {
            console.log("this one");
            const roundMatches = round.matches;
            if (pillFilter !== "all") {
              if (pillFilter !== index) {
                return null;
              }
            }
            return (
              <>
                <div className="mpl-round-header">
                  {index === matches.length - 1 ? (
                    <h2>Final</h2>
                  ) : (
                    <h2>Round {index + 1}</h2>
                  )}
                </div>
                <div className="mpl-matches">
                  {roundMatches.map((match, index2) => {
                    const p1 = match.players[0];
                    const p2 = match.players[1];

                    if (p1.name === "") {
                      p1.name = "BYE";
                    }

                    if (p2.name === "") {
                      p2.name = "BYE";
                    }

                    if (match.players.length === 0) {
                      return (
                        <div className="mpl-mps ">
                          <p>Match {match.match}</p>{" "}
                          <MatchplayScore hideThru preMatch />
                        </div>
                      );
                    }

                    return (
                      <div className="mpl-mps">
                        <p>Match {match.match}</p>
                        <MatchplayScore
                          hcpOne={p1.handicap}
                          hideThru
                          preMatch
                          hcpTwo={p2.handicap}
                          nameOne={p1.name}
                          nameTwo={p2.name}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        {edit &&
          matches.map((round, index) => {
            const roundMatches = round.matches;
            if (pillFilter !== "all") {
              if (index !== pillFilter) {
                return null;
              }
            }
            return (
              <div key={index} className="mpl-edit">
                <div className="mpl-round-header">
                  {index === matches.length - 1 ? (
                    <h2>Final</h2>
                  ) : (
                    <h2>Round {index + 1}</h2>
                  )}
                </div>
                <div className="mpl-matches">
                  {roundMatches.map((match, index2) => {
                    const p1 = match.players[0];
                    const p2 = match.players[1];

                    let showBye = false;

                    if (p1.name === "") {
                      p1.name = "BYE";
                    }

                    if (p2.name === "") {
                      p2.name = "BYE";
                    }

                    if (p1.name === "BYE" || p2.name === "BYE") {
                      showBye = true;
                    }

                    if (match.completed) {
                      showBye = false;
                    }

                    let showEdit = true;
                    if (
                      p1.name === "BYE" ||
                      p2.name === "BYE" ||
                      p2.name.includes("Winner Match") ||
                      p1.name.includes("Winner Match") ||
                      match.completed === true
                    ) {
                      showEdit = false;
                    }

                    if (match.players.length === 0) {
                      return (
                        <div className="mpl-mps">
                          <p>Match {match.match}</p>{" "}
                          <MatchplayScore hideThru preMatch />
                        </div>
                      );
                    }

                    return (
                      <div className="mpbm-box">
                        <p className="match-pee mb-10">Match {match.match}</p>

                        <div className="mpb-match">
                          <PlayerItem
                            showBg={match.winnerID === p1.playerID}
                            hideRemove
                            hideEdit={
                              match.winnerID !== "" ||
                              match.started ||
                              index !== ri
                            }
                            hcp={p1.handicap}
                            hideImage
                            showAdd
                            hideHCP={isNaN(p1.handicap)}
                            hideMessage
                            hideProfile
                            noLink
                            editAction={() => {
                              setMatchEdit(match.match);
                              setPlayerToSwap(p1);
                              setPlayerToExclude(p2);
                              setShowSelector(true);
                            }}
                            name={p1.name}
                          />
                          <div className="mp-cir-box">
                            {showBye && (
                              <Tooltip title="Complete Match">
                                <p
                                  onClick={() => completeMatch(index, index2)}
                                  className="green-link mb-10"
                                >
                                  <CheckCircle />
                                </p>
                              </Tooltip>
                            )}
                            {match.roundID && !match.completed && (
                              <RssFeedIcon
                                onClick={() =>
                                  window.open(
                                    `https://www.theugatour.com/rounds/${match.roundID}`,
                                    "_blank"
                                  )
                                }
                                className="dg-icon-bg icon-cursor mb-10"
                              />
                            )}
                            {match.roundID && match.completed && (
                              <GolfCourseIcon
                                onClick={() =>
                                  window.open(
                                    `https://www.theugatour.com/rounds/${match.roundID}`,
                                    "_blank"
                                  )
                                }
                                className="dg-icon icon-cursor mb-10"
                              />
                            )}
                            <div className="mpbm-circle">
                              {match.winnerID === "" ? (
                                <p>vs</p>
                              ) : match.by === "BYE" ? (
                                <p>BYE</p>
                              ) : (
                                <>
                                  {match.winnerID === p1.playerID ? (
                                    <p>
                                      {match.rem === 0
                                        ? `${match.by} UP`
                                        : `${match.by} & ${match.rem}`}
                                    </p>
                                  ) : (
                                    <p>
                                      {match.rem === 0
                                        ? `${match.by} DN`
                                        : `${match.by} & ${match.rem}`}
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                            {started && showEdit && (
                              <EditIcon
                                onClick={() => {
                                  setManMatch(match);
                                  setManIndex([index, index2]);
                                  setShowManual(true);
                                }}
                                className="icon-cursor dg-icon-bg mt-10"
                              />
                            )}
                          </div>

                          <PlayerItem
                            showBg={match.winnerID === p2.playerID}
                            hideRemove
                            hcp={p2.handicap}
                            hideImage
                            hideEdit={
                              match.winnerID !== "" ||
                              match.started ||
                              index !== ri
                            }
                            hideHCP={isNaN(p2.handicap)}
                            showAdd
                            hideMessage
                            hideProfile
                            noLink
                            name={p2.name}
                            editAction={() => {
                              setMatchEdit(match.match);
                              setPlayerToSwap(p2);
                              setPlayerToExclude(p1);
                              setShowSelector(true);
                            }}
                          />
                        </div>
                      </div>
                    );
                    return (
                      <div className="mpl-mps">
                        <p>Match {match.match}</p>
                        <div className="mpl-mpc">
                          <GradientEditIcon
                            onClick={() => {
                              setMatchEdit(match.match);
                              setPlayerToSwap(p1);
                              setShowSelector(true);
                            }}
                            className={"mpl-mpc-icon mr-10"}
                          />
                          <MatchplayScore
                            hcpOne={p1.handicap}
                            hideThru
                            preMatch
                            hcpTwo={p2.handicap}
                            nameOne={p1.name}
                            nameTwo={p2.name}
                          />
                          <GradientEditIcon
                            onClick={() => {
                              setMatchEdit(match.match);
                              setPlayerToSwap(p2);
                              setShowSelector(true);
                            }}
                            className={"mpl-mpc-icon ml-10"}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      {!display && !started && (
        <div className="flex-center mt-40 mb-40">
          <button
            className="default-button"
            onClick={() => {
              saveChanges();
            }}
          >
            Save Matches
          </button>
        </div>
      )}
      {!display && started && changed && (
        <div className="flex-center mt-40 mb-40">
          <button
            className="default-button"
            onClick={() => {
              savePostChanges();
            }}
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
}

export default MatchplayBracket;
