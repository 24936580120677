import React, { useEffect, useState } from "react";
import "./TeamScorecard.css";
import { db } from "../../../firebase";
import BackRow from "../../display/Rows/BackRow";
// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import classNames from "classnames";
function TeamScorecard({
  goBack = () => {},
  group = { playerIDs: [] },
  roundID = "",
  format = "",
  eventName = "",
  roundFormat = "",
}) {
  function returnFormat(fmt) {
    if (fmt === "two") {
      return "Better Ball - Two to Count";
    }

    if (fmt === "bogey") {
      return "Bogey Plus";
    }

    if (fmt === "bogey++") {
      return "Bogey Double Plus";
    }

    if (fmt === "net-strokeplay") {
      return "Net Strokeplay";
    }
    if (fmt === "team-net-strokeplay") {
      return "Team Net Strokeplay";
    }
    if (fmt === "average-ball") {
      return "Average Ball";
    }
    if (fmt === "worst-ball") {
      return "Worst Ball";
    }
    if (fmt === "best-ball") {
      return "Best Ball";
    }
    if (fmt === "match-play") {
      return "Match Play";
    }
    if (fmt === "ab-match-play") {
      return "AB Match Play";
    }
    if (fmt === "bb-match-play") {
      return "BB Match Play";
    }
    if (fmt === "wb-match-play") {
      return "WB Match Play";
    }
    if (fmt === "stableford") {
      return "Stableford";
    }
    if (fmt === "mod-stableford") {
      return "Modified Stableford";
    }
    if (fmt === "skins-normal") {
      return "Normal Skins";
    }
    if (fmt === "skins-expo") {
      return "Expo Skins";
    }

    return fmt;
  }

  // State
  const [playerCards, setPlayerCards] = useState([]);
  const [initials, setInitials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [overallTeamScores, setOverallTeamScores] = useState(2);
  const [playerList, setPlayerList] = useState({});
  const [playerArr, setPlayerArr] = useState([]);

  const [combined, setCombined] = useState([]);
  const [prog, setProg] = useState([]);

  const [couseCard, setCourseCard] = useState([]);

  const [groupState, setGroupState] = useState([]);

  const [update, setUpdate] = useState(0);
  const [showSS, setShowSS] = useState(false);

  const [teamAllowance, setTeamAllowance] = useState(100);

  useEffect(() => {
    console.log(group);
    loadCards();
  }, []);

  async function loadCards() {
    const { playerIDs, holes } = group;
    const arr = [];
    const inits = [];

    let guestCount = 1;

    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());
    const docs = await Promise.all(refs);
    const playerMap = {};

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      if (playerDoc.exists) {
        const player = playerDoc.data();
        const { firstName, lastName } = player;
        const playerID = playerDoc.id;
        playerMap[playerID] = {
          firstName,
          lastName,
          name: `${firstName} ${lastName}`,
        };
        const initials = `${firstName[0]}${lastName[0]}`;
        inits.push(initials);
      } else {
        inits.push(`G${1}`);
        guestCount += 1;
      }
    }

    setInitials(inits);

    const rdDoc = await db.collection("rounds").doc(roundID).get();

    if (format === "Overall") {
      setOverallTeamScores(rdDoc.data().overallTeamScores);
    }
    setShowSS(
      rdDoc.data().roundIndex > 0 || rdDoc.data().eventType === "final"
    );

    for (let i = 0; i < playerIDs.length; i++) {
      const scoreDoc = await db
        .collection("rounds")
        .doc(roundID)
        .collection("scores")
        .doc(playerIDs[i])
        .get();
      const score = scoreDoc.data();
      const { holes } = score;
      const playerID = playerIDs[i];

      if (playerMap[playerID]) {
        if (score.holesCompleted === 0) {
          playerMap[playerID].currentScore = 0;
          playerMap[playerID].thru = score.holesCompleted;
          playerMap[playerID].totalStrokes =
            roundFormat === "medal"
              ? score.totalGrossStrokes
              : roundFormat === "stableford"
              ? score.totalPoints ?? 0
              : score.totalNetStrokes;
        } else {
          const startingGrossScore = score.startingGrossScore ?? 0;
          const startingNetScore = score.startingNetScore ?? 0;
          const startingPoints = score.startingPoints ?? 0;

          playerMap[playerID].currentScore =
            roundFormat === "medal"
              ? score.currentGrossScore - startingGrossScore
              : roundFormat === "stableford"
              ? score.totalPoints - startingPoints
              : score.currentScore - startingNetScore;
          playerMap[playerID].thru = score.holesCompleted;
          playerMap[playerID].totalStrokes =
            roundFormat === "medal"
              ? score.totalGrossStrokes
              : score.totalNetStrokes;
        }
      }

      if (i === 0) {
        if (score.teamAllowance) {
          setTeamAllowance(score.teamAllowance);
        }
      }

      if (i === 0) {
        const courseHoles = holes.map((hole) => {
          const { par, stroke } = hole;
          const returnMap = {
            par,
            stroke,
          };
          return returnMap;
        });
        setCourseCard(courseHoles);
      }
      arr.push(holes);
    }

    if (format === "Overall") {
      setGroupState(group);
    }

    if (format === "two") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        let { lowest, secondLowest } = hole;
        if (roundFormat === "net-strokeplay" || roundFormat === "medal") {
          if (lowest === "") {
            lowest = 0;
          }
          if (secondLowest === "") {
            secondLowest = 0;
          }
          const comb = lowest + secondLowest;
          if (i === 0) {
            prog[i] = comb;
          } else {
            prog[i] = prog[i - 1] + comb;
          }
        } else if (roundFormat === "mod-stableford") {
          let scorePoints = 0;
          let scorePointsTwo = 0;
          switch (lowest) {
            case lowest > 2:
              scorePoints += -5;
              break;
            case 2:
              scorePoints += -3;
              break;
            case 1:
              scorePoints += -1;
              break;
            case 0:
              break;
            case -1:
              scorePoints += 2;
              break;
            case -2:
              scorePoints += 5;
              break;
            case lowest < -2:
              scorePoints += 8;
              break;
            default:
              break;
          }
          switch (secondLowest) {
            case secondLowest > 2:
              scorePointsTwo += -5;
              break;
            case 2:
              scorePointsTwo += -3;
              break;
            case 1:
              scorePointsTwo += -1;
              break;
            case 0:
              break;
            case -1:
              scorePointsTwo += 2;
              break;
            case -2:
              scorePointsTwo += 5;
              break;
            case secondLowest < -2:
              scorePointsTwo += 8;
              break;
            default:
              break;
          }
          const comb = scorePoints + scorePointsTwo;
          group.holes[i].total = comb;
          if (i === 0) {
            prog[i] = comb;
          } else {
            prog[i] = prog[i - 1] + comb;
          }
        } else {
          const lowPoint = 2 - lowest;
          const secLowPoint = 2 - secondLowest;
          let comb = 0;
          if (lowest !== "") {
            comb += lowPoint;
          }
          if (secondLowest !== "") {
            comb += secLowPoint;
          }

          group.holes[i].total = comb;

          if (i === 0) {
            prog[i] = comb;
          } else {
            prog[i] = prog[i - 1] + comb;
          }
        }
      }
      setGroupState(group);
      setProg(prog);
      setUpdate((current) => current + 1);
    }

    if (format === "bogey") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        const { best } = hole;
        if (i === 0) {
          prog[i] = best > 0 ? 1 : best < 0 ? -1 : 0;
        } else {
          const add = best > 0 ? 1 : best < 0 ? -1 : 0;
          prog[i] = prog[i - 1] + add;
        }
      }
      setGroupState(group);
      setProg(prog);
      setUpdate((current) => current + 1);
    }

    if (format === "bogey++") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        const { best } = hole;
        if (i === 0) {
          prog[i] = best > 0 ? 1 : best < -1 ? -2 : best < 0 ? -1 : 0;
        } else {
          const add = best > 0 ? 1 : best < -1 ? -2 : best < 0 ? -1 : 0;
          prog[i] = prog[i - 1] + add;
        }
      }
      setGroupState(group);
      setProg(prog);
      setUpdate((current) => current + 1);
    }

    if (format === "average-ball") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        let { lowest, highest } = hole;
        if (lowest === "") {
          lowest = 0;
        }
        if (highest === "") {
          highest = 0;
        }
        const comb = lowest + highest;
        if (i === 0) {
          prog[i] = comb;
        } else {
          prog[i] = prog[i - 1] + comb;
        }
      }
      setGroupState(group);
      setProg(prog);
    }

    if (format === "best-ball" || format === "worst-ball") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        let { lowest } = hole;
        if (lowest === "") {
          lowest = 0;
        }
        if (i === 0) {
          prog[i] = lowest;
        } else {
          prog[i] = prog[i - 1] + lowest;
        }
      }
      setGroupState(group);

      setProg(prog);
    }

    if (format === "team-net-strokeplay") {
      const comb = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < arr.length; i++) {
        const holes = arr[i];
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          comb[x] += hole.toPar;
        }
      }
      for (let i = 0; i < comb.length; i++) {
        if (i === 0) {
          prog[0] = comb[0];
        } else {
          prog[i] = prog[i - 1] + comb[i];
        }
      }
      setProg(prog);
      setCombined(comb);
    }

    if (format === "combined") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const { playerIDs } = group;
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        let { score } = hole;
        if (roundFormat === "net-strokeplay" || roundFormat === "medal") {
          if (score === "") {
            score = 0;
          }
          if (i === 0) {
            prog[i] = score;
          } else {
            prog[i] = prog[i - 1] + score;
          }
        } else {
          let scorePoints = 0;
          for (let n = 0; n < playerIDs.length; n++) {
            const playerID = playerIDs[n];
            const playerScore = hole[playerID];
            if (playerScore !== "") {
              if (roundFormat === "mod-stableford") {
                switch (playerScore) {
                  case playerScore > 2:
                    scorePoints += -5;
                    break;
                  case 2:
                    scorePoints += -3;
                    break;
                  case 1:
                    scorePoints += -1;
                    break;
                  case 0:
                    break;
                  case -1:
                    scorePoints += 2;
                    break;
                  case -2:
                    scorePoints += 5;
                    break;
                  case playerScore < -2:
                    scorePoints += 8;
                    break;
                  default:
                    break;
                }
              } else {
                scorePoints += 2 - playerScore;
              }
            }
          }
          group.holes[i].total = scorePoints;
          // if (score !== "") {
          //   scorePoints = 2 - score;
          //   group.holes[i].total = scorePoints;
          // } else {
          //   group.holes[i].total = scorePoints;
          // }

          if (i === 0) {
            prog[i] = scorePoints;
          } else {
            prog[i] = prog[i - 1] + scorePoints;
          }
        }
      }
      setGroupState(group);
      setProg(prog);
      setUpdate((current) => current + 1);
    }

    if (format === "grwbfd") {
      const playerIDs = group.playerIDs;
      const comb = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < arr.length; i++) {
        const holes = arr[i];
        for (let x = 0; x < holes.length; x++) {
          let holeScore = 0;
          for (let n = 0; n < playerIDs.length; n++) {
            const playerID = playerIDs[n];
            if (roundFormat === "stableford") {
              if (holes[x][playerID] !== "") {
                holeScore += 2 - holes[x][playerID];
              }
            } else {
              if (holes[x][playerID] !== "") {
                holeScore += holes[x][playerID];
              }
            }
          }
          comb[x] += holeScore;
        }
      }
      for (let i = 0; i < comb.length; i++) {
        if (i === 0) {
          prog[0] = comb[0];
        } else {
          prog[i] = prog[i - 1] + comb[i];
        }
      }
      setProg(prog);
      setCombined(comb);
      setGroupState(group);
      setUpdate((current) => current + 1);
    }
    if (format === "scramble") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        let { best } = hole;
        if (best === "") {
          continue;
          best = 0;
        }
        if (roundFormat === "stableford") {
          const bestPoint = 2 - best;
          if (i === 0) {
            prog[i] = bestPoint;
          } else {
            prog[i] = prog[i - 1] + bestPoint;
          }
        } else if (roundFormat === "mod-stableford") {
          let scorePoints = 0;
          const playerScore = hole.best;
          switch (playerScore) {
            case playerScore > 2:
              scorePoints += -5;
              break;
            case 2:
              scorePoints += -3;
              break;
            case 1:
              scorePoints += -1;
              break;
            case 0:
              break;
            case -1:
              scorePoints += 2;
              break;
            case -2:
              scorePoints += 5;
              break;
            case playerScore < -2:
              scorePoints += 8;
              break;
            default:
              break;
          }
          group.holes[i].mod = scorePoints;
          if (i === 0) {
            prog[i] = scorePoints;
          } else {
            prog[i] = prog[i - 1] + scorePoints;
          }
        } else {
          if (i === 0) {
            prog[i] = best;
          } else {
            prog[i] = prog[i - 1] + best;
          }
        }
      }

      setGroupState(group);
      setProg(prog);
      setUpdate((current) => current + 1);
    }

    if (format === "custom") {
      const prog = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const gss = group.startingScore ?? 0;
      for (let i = 0; i < group.holes.length; i++) {
        const hole = group.holes[i];
        let { score } = hole;
        if (score === "") {
          score = 0;
          if (i > 0) {
            prog[i] = prog[i - 1];
          } else {
            prog[i] = 0 + gss;
          }
          continue;
        }
        if (roundFormat === "stableford") {
          const { score, toCount, scores } = hole;
          let counter = toCount;
          if (counter > scores.length) {
            counter = scores.length;
          }
          let totalPoints = 0;
          for (let g = 0; g < counter; g++) {
            const score = scores[g];
            if (score !== "") {
              const points = 2 - score;
              totalPoints += points;
            }
          }
          group.holes[i].score = totalPoints;

          // const bestPoint = 2 - score;
          if (i === 0) {
            prog[i] = totalPoints;
          } else {
            prog[i] = prog[i - 1] + totalPoints;
          }
        } else if (roundFormat === "mod-stableford") {
          let scorePoints = 0;
          const playerScore = hole.score;
          switch (playerScore) {
            case playerScore > 2:
              scorePoints += -5;
              break;
            case 2:
              scorePoints += -3;
              break;
            case 1:
              scorePoints += -1;
              break;
            case 0:
              break;
            case -1:
              scorePoints += 2;
              break;
            case -2:
              scorePoints += 5;
              break;
            case playerScore < -2:
              scorePoints += 8;
              break;
            default:
              break;
          }
          group.holes[i].mod = scorePoints;
          if (i === 0) {
            prog[i] = scorePoints;
          } else {
            prog[i] = prog[i - 1] + scorePoints;
          }
        } else {
          if (i === 0) {
            prog[i] = score + gss;
          } else {
            prog[i] = prog[i - 1] + score;
          }
        }
      }

      setGroupState(group);
      setProg(prog);
      setUpdate((current) => current + 1);
    }
    setPlayerList(playerMap);

    const playerArr = Object.values(playerMap);

    if (roundFormat === "stableford") {
      playerArr.sort((a, b) => b.currentScore - a.currentScore);
    } else {
      playerArr.sort((a, b) => a.currentScore - b.currentScore);
    }
    if (format === "Overall") {
      playerArr.sort((a, b) => {
        if (b.thru === 0) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    setPlayerArr(playerArr);

    setPlayerCards(arr);
    setLoading(false);
  }

  return (
    <div className="team-scorecard">
      {loading && <BackdropLoader />}
      {/* <div className="ts-header">
        <ArrowForwardIcon onClick={() => goBack()} className="ts-arrow" />
        <h2>Back</h2>
      </div> */}
      <BackRow action={goBack} />
      <div className="player-round-header mt-20">
        <h2 className="" style={{ textTransform: "capitalize" }}>
          {returnFormat(format)}
          {format === "Overall" && ` - Best ${overallTeamScores} Scores`}
          {teamAllowance !== 100 && ` (${teamAllowance}% Allowance)`}
        </h2>
        <p className="mb-10">{group.groupName ?? group.name}</p>
      </div>
      {showSS && format !== "Overall" && (
        <div className="ove-sc-list ">
          <div className="osl-box">
            <div className="osl-ove ove-sc-sml osl-pl ml-10">
              <h3>Starting Score</h3>
              <p>
                {group.startingScore === 0 && roundFormat !== "stableford"
                  ? "E"
                  : group.startingScore > 0 && roundFormat !== "stableford"
                  ? `+${group.startingScore}`
                  : group.startingScore}
              </p>
            </div>
          </div>
        </div>
      )}{" "}
      {format === "Overall" && (
        <div className="ove-sc-list">
          {/* <div className="neh-box">
            <h2 className="ne-header">Players</h2>
          </div> */}
          <div className="osl-box">
            {showSS && (
              <div className="osl-pl">
                <h3>Starting Score</h3>
                <p>
                  {roundFormat !== "stableford" && (
                    <>
                      {group.startingScore === 0
                        ? "E"
                        : group.startingScore > 0
                        ? `+${group.startingScore}`
                        : group.startingScore}
                    </>
                  )}
                  {roundFormat === "stableford" && <>{group.startingScore}</>}
                  {roundFormat !== "stableford" && (
                    <strong> | ({group.startingStrokes})</strong>
                  )}
                </p>
              </div>
            )}
            {showSS && (
              <div className="osl-pl mb-20">
                <h3>Today</h3>
                <p>
                  {group.today === 0 && roundFormat !== "stableford"
                    ? "E"
                    : group.today > 0 && roundFormat !== "stableford"
                    ? `+${group.today}`
                    : group.today}
                  {roundFormat !== "stableford" && (
                    <strong>
                      {" "}
                      | ({group.totalStrokes - group.startingStrokes})
                    </strong>
                  )}
                </p>
              </div>
            )}
            <div className="osl-pl osl-ove">
              <h3>Overall</h3>
              <p>
                {roundFormat !== "stableford" && (
                  <>
                    {group.score === 0
                      ? "E"
                      : group.score > 0
                      ? `+${group.score}`
                      : group.score}
                  </>
                )}
                {roundFormat === "stableford" && <>{group.score}</>}
                {roundFormat !== "stableford" && (
                  <strong> | ({group.totalStrokes ?? 0})</strong>
                )}
              </p>
            </div>
            {playerArr.map((pl, i) => {
              const opa = i >= overallTeamScores;

              return (
                <div
                  className={classNames("osl-pl", {
                    "osl-opa": opa,
                  })}
                  key={`${i}pl`}
                >
                  <h3>{pl.name}:</h3>
                  <p>
                    {pl.currentScore === 0 && roundFormat !== "stableford"
                      ? "E"
                      : pl.currentScore > 0 && roundFormat !== "stableford"
                      ? `+${pl.currentScore}`
                      : pl.currentScore}
                    {roundFormat !== "stableford" && (
                      <strong> | ({pl.totalStrokes})</strong>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="score-table-container">
        <table>
          <thead>
            <tr className="header-row main-header">
              <th>
                <GolfCourseIcon />
              </th>
              <th>Par</th>
              <th>Hcp</th>
              {initials.map((initial, index) => (
                <th key={initial}>{initial}</th>
              ))}
              {format !== "Overall" && (
                <th>{format.includes("bogey") ? "Result" : "Total"}</th>
              )}
              {format !== "Overall" && <th>Score</th>}
              {format === "custom" && <th>Count</th>}
            </tr>
          </thead>
          <tbody>
            {!loading &&
              format === "Overall" &&
              groupState.holes &&
              groupState.holes.map((hole, index) => {
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      return (
                        <td
                          //   classNaame={
                          //     pc[index].netScore > couseCard[index].par
                          //       ? "player-td over-par"
                          //       : pc[index].netScore < couseCard[index].par
                          //       ? "player-td under-par"
                          //       : "player-td"
                          //   }
                          className={"player-td"}
                          key={`pc${i2}`}
                        >
                          <p>
                            {roundFormat === "stableford"
                              ? pc[index].points
                              : roundFormat === "mod-stableford"
                              ? pc[index].modPoints
                              : roundFormat === "medal"
                              ? pc[index].grossScore
                              : pc[index].netScore}
                          </p>{" "}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {!loading &&
              format === "two" &&
              groupState.holes.map((hole, index) => {
                const lowest = hole.lowest;
                const secondLowest = hole.secondLowest;
                let comb = lowest + secondLowest;
                if (comb === "") {
                  comb = "-";
                }
                if (
                  roundFormat === "stableford" ||
                  roundFormat === "mod-stableford"
                ) {
                  comb = hole.total;
                }
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];

                      let comp =
                        teamAllowance !== 100 && playerHole.teamNetDiff
                          ? playerHole.teamNetDiff
                          : playerHole.toPar;
                      if (roundFormat === "medal") {
                        comp = playerHole.toPar + playerHole.strokes;
                      }
                      return (
                        <td
                          //   classNaame={
                          //     pc[index].netScore > couseCard[index].par
                          //       ? "player-td over-par"
                          //       : pc[index].netScore < couseCard[index].par
                          //       ? "player-td under-par"
                          //       : "player-td"
                          //   }
                          className={
                            comp === lowest && playerHole.grossScore !== 0
                              ? "tsc-low player-td"
                              : comp === secondLowest &&
                                playerHole.grossScore !== 0
                              ? "tsc-low player-td"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>
                            {roundFormat === "stableford" &&
                            teamAllowance !== 100
                              ? pc[index].teamPoints
                              : roundFormat === "stableford"
                              ? pc[index].points
                              : roundFormat === "mod-stableford"
                              ? pc[index].modPoints
                              : roundFormat === "medal"
                              ? pc[index].grossScore
                              : teamAllowance !== 100
                              ? pc[index].teamNet
                              : pc[index].netScore}
                          </p>
                        </td>
                      );
                    })}
                    {roundFormat === "stableford" && (
                      <td
                        className={
                          group.thru < index
                            ? "player-td"
                            : comb < 4
                            ? "player-td over-par"
                            : comb > 4
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {comb}
                      </td>
                    )}
                    {roundFormat === "mod-stableford" && (
                      <td
                        className={
                          comb < 0
                            ? "player-td over-par"
                            : comb > 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {comb}
                      </td>
                    )}
                    {roundFormat !== "stableford" &&
                      roundFormat !== "mod-stableford" && (
                        <td
                          className={
                            comb > 0
                              ? "player-td over-par"
                              : comb < 0
                              ? "player-td under-par"
                              : "player-td"
                          }
                        >
                          {comb === 0 ? "E" : comb > 0 ? `+${comb}` : comb}
                        </td>
                      )}
                    {roundFormat === "stableford" && (
                      <td
                        className={
                          prog[index] === 0
                            ? "player-td"
                            : prog[index] < (index + 1) * 4
                            ? "player-td over-par"
                            : prog[index] > (index + 1) * 4
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {prog[index]}
                      </td>
                    )}
                    {roundFormat === "mod-stableford" && (
                      <td
                        className={
                          prog[index] < 0
                            ? "player-td over-par"
                            : prog[index] > 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {prog[index]}
                      </td>
                    )}
                    {roundFormat !== "stableford" &&
                      roundFormat !== "mod-stableford" && (
                        <td
                          className={
                            prog[index] === 0
                              ? "player-td"
                              : prog[index] > 0
                              ? "player-td over-par"
                              : prog[index] < 0
                              ? "player-td under-par"
                              : "player-td"
                          }
                        >
                          {prog[index] === 0 ? (
                            "E"
                          ) : (
                            <>
                              {prog[index] > 0 &&
                                roundFormat !== "stableford" &&
                                "+"}
                              {prog[index]}
                            </>
                          )}
                        </td>
                      )}
                  </tr>
                );
              })}
            {!loading &&
              format === "custom" &&
              groupState.holes.map((hole, index) => {
                const lowest = hole.lowest ?? 0;
                const secondLowest = hole.secondLowest ?? 0;
                let comb = lowest + secondLowest;

                const { score, toCount, scores } = hole;
                const type = hole.type ?? "best";

                let counter = toCount;
                if (counter > scores.length) {
                  counter = scores.length;
                }

                if (comb === "") {
                  comb = "-";
                }
                if (
                  roundFormat === "stableford" ||
                  roundFormat === "mod-stableford"
                ) {
                  comb = hole.total;
                }
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      if (index === 0) {
                        console.log(playerHole);
                      }
                      const comp =
                        teamAllowance !== 100 && playerHole.teamNetDiff
                          ? playerHole.teamNetDiff
                          : playerHole.toPar;
                      return (
                        <td
                          //   classNaame={
                          //     pc[index].netScore > couseCard[index].par
                          //       ? "player-td over-par"
                          //       : pc[index].netScore < couseCard[index].par
                          //       ? "player-td under-par"
                          //       : "player-td"
                          //   }
                          className={
                            comp <= scores[counter - 1] &&
                            playerHole.grossScore !== 0
                              ? "tsc-low player-td"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>
                            {roundFormat === "stableford" &&
                            teamAllowance !== 100
                              ? pc[index].teamPoints
                              : roundFormat === "stableford"
                              ? pc[index].points
                              : roundFormat === "mod-stableford"
                              ? pc[index].modPoints
                              : roundFormat === "medal"
                              ? pc[index].grossScore
                              : teamAllowance !== 100
                              ? pc[index].teamNet
                              : pc[index].netScore}
                          </p>
                        </td>
                      );
                    })}
                    {roundFormat === "stableford" && (
                      <td
                        className={
                          score < counter * 2
                            ? "player-td over-par"
                            : score > counter * 2
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {score === "" ? "-" : score}
                      </td>
                    )}
                    {roundFormat === "mod-stableford" && (
                      <td
                        className={
                          score < 0
                            ? "player-td over-par"
                            : score > 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {score}
                      </td>
                    )}
                    {roundFormat !== "stableford" &&
                      roundFormat !== "mod-stableford" && (
                        <td
                          className={
                            score > 0
                              ? "player-td over-par"
                              : score < 0
                              ? "player-td under-par"
                              : "player-td"
                          }
                        >
                          {score === "" || score === 0
                            ? "E"
                            : score > 0
                            ? `+${score}`
                            : score}
                        </td>
                      )}
                    {roundFormat === "stableford" && (
                      <td
                        className={
                          prog[index] === 0
                            ? "player-td"
                            : prog[index] < (index + 1) * 2 * 2
                            ? "player-td over-par"
                            : prog[index] > (index + 1) * 2 * 2
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {prog[index]}
                      </td>
                    )}
                    {roundFormat === "mod-stableford" && (
                      <td
                        className={
                          prog[index] < 0
                            ? "player-td over-par"
                            : prog[index] > 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {prog[index]}
                      </td>
                    )}
                    {roundFormat !== "stableford" &&
                      roundFormat !== "mod-stableford" && (
                        <td
                          className={
                            prog[index] === 0
                              ? "player-td"
                              : prog[index] > 0
                              ? "player-td over-par"
                              : prog[index] < 0
                              ? "player-td under-par"
                              : "player-td"
                          }
                        >
                          {prog[index] === 0 ? (
                            "E"
                          ) : (
                            <>
                              {prog[index] > 0 &&
                                roundFormat !== "stableford" &&
                                "+"}
                              {prog[index]}
                            </>
                          )}
                        </td>
                      )}
                    <td className="cus-td">
                      {toCount} {type === "comb" ? "Combined" : type}
                    </td>
                  </tr>
                );
              })}
            {!loading &&
              format === "combined" &&
              group.holes.map((hole, index) => {
                const score = hole.score;
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      let comp =
                        teamAllowance !== 100 && playerHole.teamNetDiff
                          ? playerHole.teamNetDiff
                          : playerHole.toPar;
                      return (
                        <td
                          //   classNaame={
                          //     pc[index].netScore > couseCard[index].par
                          //       ? "player-td over-par"
                          //       : pc[index].netScore < couseCard[index].par
                          //       ? "player-td under-par"
                          //       : "player-td"
                          //   }
                          className={
                            comp < 0
                              ? "tsc-low player-td"
                              : comp > 0
                              ? "tsc-high player-td"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>
                            {roundFormat === "stableford"
                              ? pc[index].points
                              : roundFormat === "mod-stableford"
                              ? pc[index].modPoints
                              : pc[index].netScore}
                          </p>
                        </td>
                      );
                    })}
                    {roundFormat === "stableford" && (
                      <td
                        className={
                          score < group.playerIDs.length * 2
                            ? "player-td over-par"
                            : score > group.playerIDs.length * 2
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {score}
                      </td>
                    )}
                    {!roundFormat.includes("stableford") && (
                      <td
                        className={
                          score > 0
                            ? "player-td over-par"
                            : score < 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {score === 0 ? "E" : score > 0 ? `+${score}` : score}
                      </td>
                    )}
                    {roundFormat === "mod-stableford" && (
                      <td
                        className={
                          score < 0
                            ? "player-td over-par"
                            : score > 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {score}
                      </td>
                    )}
                    {roundFormat === "stableford" && (
                      <td
                        className={
                          prog[index] === 0
                            ? "player-td"
                            : prog[index] <
                              group.playerIDs.length * 2 * (index + 1)
                            ? "player-td over-par"
                            : prog[index] >
                              group.playerIDs.length * 2 * (index + 1)
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {prog[index] === 0 ? (
                          "E"
                        ) : (
                          <>
                            {prog[index] > 0 &&
                              roundFormat !== "stableford" &&
                              "+"}
                            {prog[index]}
                          </>
                        )}
                      </td>
                    )}
                    {roundFormat === "mod-stableford" && (
                      <td
                        className={
                          prog[index] === 0
                            ? "player-td"
                            : prog[index] < 0
                            ? "player-td over-par"
                            : prog[index] > 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {prog[index] === 0 ? "E" : <>{prog[index]}</>}
                      </td>
                    )}
                    {!roundFormat.includes("stableford") && (
                      <td
                        className={
                          prog[index] === 0
                            ? "player-td"
                            : prog[index] > 0
                            ? "player-td over-par"
                            : prog[index] < 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {prog[index] === 0 ? (
                          "E"
                        ) : (
                          <>
                            {prog[index] > 0 &&
                              roundFormat !== "stableford" &&
                              "+"}
                            {prog[index]}
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            {!loading &&
              format === "scramble" &&
              groupState.holes.map((hole, index) => {
                const { thru } = groupState;
                let holeLowest = hole.best === "" ? 0 : hole.best;
                if (roundFormat === "stableford" && hole.best !== "") {
                  holeLowest = 2 - holeLowest;
                }
                const mod = hole.mod;

                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      let comp =
                        teamAllowance === 100
                          ? playerHole.netDiff
                          : playerHole.teamNetDiff ?? playerHole.toPar;
                      if (roundFormat === "stableford") {
                        comp = playerHole.teamPoints ?? playerHole.points;
                      }
                      return (
                        <>
                          {roundFormat === "stableford" ? (
                            <td
                              //   classNaame={
                              //     pc[index].netScore > couseCard[index].par
                              //       ? "player-td over-par"
                              //       : pc[index].netScore < couseCard[index].par
                              //       ? "player-td under-par"
                              //       : "player-td"
                              //   }
                              className={
                                playerHole.grossScore === 0
                                  ? "player-td"
                                  : comp === holeLowest
                                  ? "tsc-low player-td"
                                  : "player-td"
                              }
                              key={`pc${i2}`}
                            >
                              <p>
                                {roundFormat === "stableford" &&
                                pc[index].teamPoints
                                  ? pc[index].teamPoints
                                  : roundFormat === "stableford"
                                  ? pc[index].points
                                  : roundFormat === "mod-stableford"
                                  ? pc[index].modPoints
                                  : pc[index].teamNet
                                  ? pc[index].teamNet
                                  : pc[index].netScore}
                              </p>
                            </td>
                          ) : roundFormat === "mod-stableford" ? (
                            <>
                              <td
                                //   classNaame={
                                //     pc[index].netScore > couseCard[index].par
                                //       ? "player-td over-par"
                                //       : pc[index].netScore < couseCard[index].par
                                //       ? "player-td under-par"
                                //       : "player-td"
                                //   }
                                className={
                                  playerHole.grossScore === 0
                                    ? "player-td"
                                    : playerHole.points === holeLowest
                                    ? "tsc-low player-td"
                                    : "player-td"
                                }
                                key={`pc${i2}`}
                              >
                                <p>{pc[index].modPoints}</p>
                              </td>
                            </>
                          ) : (
                            <td
                              //   classNaame={
                              //     pc[index].netScore > couseCard[index].par
                              //       ? "player-td over-par"
                              //       : pc[index].netScore < couseCard[index].par
                              //       ? "player-td under-par"
                              //       : "player-td"
                              //   }
                              className={
                                playerHole.grossScore === 0
                                  ? "player-td"
                                  : playerHole.toPar === holeLowest
                                  ? "tsc-low player-td"
                                  : "player-td"
                              }
                              key={`pc${i2}`}
                            >
                              <p>
                                {roundFormat === "stableford"
                                  ? pc[index].points
                                  : pc[index].netScore}
                              </p>
                            </td>
                          )}
                        </>
                      );
                    })}
                    {roundFormat === "stableford" ? (
                      <td
                        className={
                          holeLowest < 2 && hole.best !== ""
                            ? "player-td over-par"
                            : holeLowest > 2
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {holeLowest}
                      </td>
                    ) : roundFormat === "mod-stableford" ? (
                      <>
                        <td
                          className={
                            holeLowest > 0
                              ? "player-td over-par"
                              : holeLowest < 0
                              ? "player-td under-par"
                              : "player-td"
                          }
                        >
                          {mod}
                        </td>
                      </>
                    ) : (
                      <td
                        className={
                          holeLowest > 0
                            ? "player-td over-par"
                            : holeLowest < 0
                            ? "player-td under-par"
                            : "player-td"
                        }
                      >
                        {holeLowest === 0
                          ? "E"
                          : holeLowest > 0
                          ? `+${holeLowest}`
                          : holeLowest}
                      </td>
                    )}
                    <td
                      className={
                        roundFormat === "mod-stableford" && prog[index] > 0
                          ? "player-td under-par"
                          : roundFormat === "mod-stableford" && prog[index] < 0
                          ? "player-td over-par"
                          : roundFormat === "stableford" &&
                            thru > index &&
                            prog[index] / (index + 1) > 2
                          ? "player-td under-par"
                          : roundFormat === "stableford" &&
                            thru > index &&
                            prog[index] / (index + 1) < 2
                          ? "player-td over-par"
                          : prog[index] === 0
                          ? "player-td"
                          : prog[index] > 0 && roundFormat !== "stableford"
                          ? "player-td over-par"
                          : prog[index] < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {prog[index] === 0 && roundFormat !== "stableford" ? (
                        "E"
                      ) : (
                        <>
                          {prog[index] > 0 &&
                            !roundFormat.includes("stableford") &&
                            "+"}
                          {prog[index]}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            {!loading &&
              format === "average-ball" &&
              groupState.holes.map((hole, index) => {
                const holeLowest = hole.lowest;
                const holeHighest = hole.highest;
                let comb = holeLowest + holeHighest;
                if (comb > 0) {
                  comb = `+${comb}`;
                }
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      return (
                        <td
                          //   classNaame={
                          //     pc[index].netScore > couseCard[index].par
                          //       ? "player-td over-par"
                          //       : pc[index].netScore < couseCard[index].par
                          //       ? "player-td under-par"
                          //       : "player-td"
                          //   }
                          className={
                            playerHole.grossScore === 0
                              ? "player-td"
                              : playerHole.toPar === holeLowest
                              ? "tsc-low player-td"
                              : playerHole.toPar === holeHighest
                              ? "tsc-high player-td"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>{pc[index].netScore}</p>
                        </td>
                      );
                    })}
                    <td
                      className={
                        comb > 0
                          ? "player-td over-par"
                          : comb < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {comb}
                    </td>
                    <td
                      className={
                        prog[index] === 0
                          ? "player-td"
                          : prog[index] > 0
                          ? "player-td over-par"
                          : prog[index] < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {prog[index] === 0 ? (
                        "E"
                      ) : (
                        <>
                          {prog[index] > 0 && "+"}
                          {prog[index]}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            {!loading &&
              (format === "bogey" || format === "bogey++") &&
              groupState.holes.map((hole, index) => {
                let best = hole.best;
                const bestPoint = 2 - best;

                // const result
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];

                      let comp = playerHole.teamNetDiff ?? playerHole.toPar;

                      return (
                        <td
                          className={
                            playerHole.grossScore === 0
                              ? "player-td"
                              : comp === best
                              ? "tsc-low player-td"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>
                            <p>
                              {roundFormat === "stableford" &&
                              pc[index].teamPoints
                                ? pc[index].teamPoints
                                : roundFormat === "stableford"
                                ? pc[index].points
                                : roundFormat === "mod-stableford"
                                ? pc[index].modPoints
                                : pc[index].teamNet
                                ? pc[index].teamNet
                                : pc[index].netScore}
                            </p>
                          </p>
                        </td>
                      );
                    })}
                    <td
                      className={
                        best > 0
                          ? "player-td over-par"
                          : best < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {best > 0 ? "L" : best < 0 ? "W" : "T"}
                    </td>
                    <td
                      className={
                        prog[index] === 0
                          ? "player-td"
                          : prog[index] > 0
                          ? "player-td over-par"
                          : prog[index] < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {prog[index] === 0 ? (
                        "AS"
                      ) : (
                        <>
                          {prog[index] < 0 ? -prog[index] : prog[index]}
                          {prog[index] < 0
                            ? " UP"
                            : prog[index] > 0
                            ? " DN"
                            : ""}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            {!loading &&
              format === "best-ball" &&
              groupState.holes.map((hole, index) => {
                const holeLowest = hole.lowest === "" ? 0 : hole.lowest;
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      return (
                        <td
                          //   classNaame={
                          //     pc[index].netScore > couseCard[index].par
                          //       ? "player-td over-par"
                          //       : pc[index].netScore < couseCard[index].par
                          //       ? "player-td under-par"
                          //       : "player-td"
                          //   }
                          className={
                            playerHole.grossScore === 0
                              ? "player-td"
                              : playerHole.toPar === holeLowest
                              ? "tsc-low player-td"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>{pc[index].netScore}</p>
                        </td>
                      );
                    })}
                    <td
                      className={
                        holeLowest > 0
                          ? "player-td over-par"
                          : holeLowest < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {holeLowest === 0
                        ? "E"
                        : holeLowest > 0
                        ? `+${holeLowest}`
                        : holeLowest}
                    </td>
                    <td
                      className={
                        prog[index] === 0
                          ? "player-td"
                          : prog[index] > 0
                          ? "player-td over-par"
                          : prog[index] < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {prog[index] === 0 ? (
                        "E"
                      ) : (
                        <>
                          {prog[index] > 0 && "+"}
                          {prog[index]}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            {!loading &&
              format === "worst-ball" &&
              groupState.holes.map((hole, index) => {
                const highest = hole.lowest === "" ? 0 : hole.lowest;
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      return (
                        <td
                          //   classNaame={
                          //     pc[index].netScore > couseCard[index].par
                          //       ? "player-td over-par"
                          //       : pc[index].netScore < couseCard[index].par
                          //       ? "player-td under-par"
                          //       : "player-td"
                          //   }
                          className={
                            playerHole.grossScore === 0
                              ? "player-td"
                              : playerHole.toPar === highest
                              ? "tsc-high player-td"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>{pc[index].netScore}</p>
                        </td>
                      );
                    })}
                    <td
                      className={
                        highest > 0
                          ? "player-td over-par"
                          : highest < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {highest === 0
                        ? "E"
                        : highest > 0
                        ? `+${highest}`
                        : highest}
                    </td>
                    <td
                      className={
                        prog[index] === 0
                          ? "player-td"
                          : prog[index] > 0
                          ? "player-td over-par"
                          : prog[index] < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {prog[index] === 0 ? (
                        "E"
                      ) : (
                        <>
                          {prog[index] > 0 && "+"}
                          {prog[index]}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}

            {!loading &&
              format === "team-net-strokeplay" &&
              couseCard.map((hole, index) => {
                let comb = 0;
                return (
                  <tr key={index}>
                    <td className="hole-td">{index + 1}</td>
                    <td className="par-td">{couseCard[index].par}</td>
                    <td className="hole-td">{couseCard[index].stroke}</td>
                    {playerCards.map((pc, i2) => {
                      const playerHole = pc[index];
                      return (
                        <td
                          className={
                            pc[index].netScore === 0
                              ? "player-td"
                              : pc[index].netScore > couseCard[index].par
                              ? "player-td over-par"
                              : pc[index].netScore < couseCard[index].par
                              ? "player-td under-par"
                              : "player-td"
                          }
                          key={`pc${i2}`}
                        >
                          <p>{pc[index].netScore}</p>
                        </td>
                      );
                    })}
                    <td
                      className={
                        combined[index] > 0
                          ? "player-td tsc-high"
                          : combined[index] < 0
                          ? "tsc-low player-td"
                          : "player-td"
                      }
                    >
                      <p>{combined[index]}</p>
                    </td>
                    <td
                      className={
                        prog[index] === 0
                          ? "player-td"
                          : prog[index] > 0
                          ? "player-td over-par"
                          : prog[index] < 0
                          ? "player-td under-par"
                          : "player-td"
                      }
                    >
                      {prog[index] === 0 ? (
                        "E"
                      ) : (
                        <>
                          {prog[index] > 0 && "+"}
                          {prog[index]}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TeamScorecard;
