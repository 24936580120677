import React, { useState, useEffect, useRef } from "react";
import "./StandardDeviation.css";
import { db } from "../../../firebase";
import * as ss from "simple-statistics";
import classNames from "classnames";

// Icons

// Components
import Chart from "react-apexcharts";
import BackdropLoader from "../../Loaders/BackdropLoader";

function StandardDeviation({ rounds = [], userID = "" }) {
  // Admin
  const scrollRef = useRef();
  const valProp = [
    {
      label: "Overall",
      values: [
        {
          label: "Gross Score",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Net Score",
          graphData: [],
          value: 0,
          percentage: 0,
        },
      ],
    },
    {
      label: "Scoring",
      values: [
        {
          label: "Birdies",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Pars",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Bogeys",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Doubles",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Other",
          graphData: [],
          value: 0,
          percentage: 0,
        },
      ],
    },
    {
      label: "Holes",
      values: [
        {
          label: "Par 3s",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Par 4s",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Par 5s",
          graphData: [],
          value: 0,
          percentage: 0,
        },
      ],
    },
    {
      label: "Putting",
      values: [
        {
          label: "Total Putts",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "1 Putts",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "3 Putts",
          graphData: [],
          value: 0,
          percentage: 0,
        },
      ],
    },
    {
      label: "Other",
      values: [
        {
          label: "Greens In Regulation",
          graphData: [],
          value: 0,
          percentage: 0,
        },
        {
          label: "Fairways In Regulation",
          graphData: [],
          value: 0,
          percentage: 0,
        },
      ],
    },
  ];

  const valMap = {
    gross: "Gross Score",
    net: "Net Score",
    birdies: "Birdies",
    pars: "Pars",
    bogies: "Bogies",
    doubles: "Double Bogies",
    other: "Others",
    par3s: "Par 3s",
    par4s: "Par 4s",
    par5s: "Par 5s",
    gir: "Green In Regulation",
    fir: "Fairway In Regulation",
    putts: "Putts",
    onePutts: "1-Putts",
    threePutts: "3-Putts",
  };

  // State
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(valProp);
  const [series, setSeries] = useState([]);
  const [update, setUpdate] = useState(0);
  const [dates, setDates] = useState([]);

  const [selCat, setSelCat] = useState(0);
  const [selStat, setSelStat] = useState(0);

  const proptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },

      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    colors: ["#1e7a69", "#232c2e", "#A9363F"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: "date",
      categories: dates,
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      intersect: true,
      shared: false,
    },
  };

  // UE
  useEffect(() => {
    sortDefaults();
  }, []);

  // Funcs
  function sortDefaults() {
    if (rounds.length === 0) {
      return setLoading(false);
    }

    const grossScoreArr = [];
    const netScoreArr = [];
    const birdieArr = [];
    const parArr = [];
    const bogeyArr = [];
    const doubleArr = [];
    const otherArr = [];

    const parThreeAverageArr = [];
    const parFourAverageArr = [];
    const parFiveAverageArr = [];

    const girArr = [];
    const firArr = [];

    const puttAverageArr = [];
    const onePuttArr = [];
    const threePuttArr = [];

    const grossDevArr = [];
    const netDevArr = [];
    const birdDevArr = [];
    const parDevArr = [];
    const bogeyDevArr = [];
    const doubleDevArr = [];
    const otherDevArr = [];

    const par3DevArr = [];
    const par4DevArr = [];
    const par5DevArr = [];

    const girDevArr = [];
    const firDevArr = [];

    const puttDevArr = [];
    const onePuttDevArr = [];
    const threePuttdevArr = [];

    for (let i = 0; i < rounds.length; i++) {
      const round = rounds[i];
      // console.log(round);
      grossScoreArr.push(round.totalGrossStrokes);
      netScoreArr.push(round.totalNetStrokes);
      birdieArr.push(round.totalBirdies);
      parArr.push(round.totalPars);
      bogeyArr.push(round.totalBogies);
      doubleArr.push(round.totalDoubles);
      const triples = round.totalTriples ?? 0;
      otherArr.push(triples);

      parThreeAverageArr.push(round.averageParThrees);
      parFourAverageArr.push(round.averageParFours);
      parFiveAverageArr.push(round.averageParFives);

      girArr.push(round.totalGIR);
      firArr.push(round.totalFIR);

      puttAverageArr.push(round.totalPutts);
      onePuttArr.push(round.onePutts);
      threePuttArr.push(round.threePutts);

      if (i === 0) {
        grossDevArr.push(0);
        netDevArr.push(0);

        birdDevArr.push(0);
        parDevArr.push(0);
        bogeyDevArr.push(0);
        doubleDevArr.push(0);
        otherDevArr.push(0);

        par3DevArr.push(0);
        par4DevArr.push(0);
        par5DevArr.push(0);

        girDevArr.push(0);
        firDevArr.push(0);

        puttDevArr.push(0);
        onePuttDevArr.push(0);
        threePuttdevArr.push(0);
      } else {
        const gsDev = ss.sampleStandardDeviation(grossScoreArr).toFixed(2);
        grossDevArr.push(gsDev);
        const nsDev = ss.sampleStandardDeviation(netScoreArr).toFixed(2);
        netDevArr.push(nsDev);

        const birdDev = ss.sampleStandardDeviation(birdieArr).toFixed(2);
        birdDevArr.push(birdDev);
        const parDev = ss.sampleStandardDeviation(parArr).toFixed(2);
        parDevArr.push(parDev);
        const bogeyDev = ss.sampleStandardDeviation(bogeyArr).toFixed(2);
        bogeyDevArr.push(bogeyDev);
        const doubleDev = ss.sampleStandardDeviation(doubleArr).toFixed(2);
        doubleDevArr.push(doubleDev);
        const otherDev = ss.sampleStandardDeviation(otherArr).toFixed(2);
        otherDevArr.push(otherDev);

        const par3Dev = ss
          .sampleStandardDeviation(parThreeAverageArr)
          .toFixed(2);
        par3DevArr.push(par3Dev);
        const par4Dev = ss
          .sampleStandardDeviation(parFourAverageArr)
          .toFixed(2);
        par4DevArr.push(par4Dev);
        const par5Dev = ss
          .sampleStandardDeviation(parFiveAverageArr)
          .toFixed(2);
        par5DevArr.push(par5Dev);

        const puttDev = ss.sampleStandardDeviation(puttAverageArr).toFixed(2);
        puttDevArr.push(puttDev);
        const onePuttDev = ss.sampleStandardDeviation(onePuttArr).toFixed(2);
        onePuttDevArr.push(onePuttDev);
        const threePuttDev = ss
          .sampleStandardDeviation(threePuttArr)
          .toFixed(2);
        threePuttdevArr.push(threePuttDev);

        const girDev = ss.sampleStandardDeviation(girArr).toFixed(2);
        girDevArr.push(girDev);
        const firDev = ss.sampleStandardDeviation(firArr).toFixed(2);
        firDevArr.push(firDev);
      }
    }

    const grossSS = ss.standardDeviation(grossScoreArr).toFixed(2);
    const grossAve = ss.average(grossScoreArr).toFixed(2);
    const netSS = ss.standardDeviation(netScoreArr).toFixed(2);
    const netAve = ss.average(netScoreArr).toFixed(2);

    const grossVal = (grossSS / grossAve).toFixed(2);
    const netVal = (netSS / netAve).toFixed(2);

    const birdieSS = ss.standardDeviation(birdieArr).toFixed(2);
    const birdieAve = ss.average(birdieArr).toFixed(2);
    const parSS = ss.standardDeviation(parArr).toFixed(2);
    const parAve = ss.average(parArr).toFixed(2);
    const bogeySS = ss.standardDeviation(bogeyArr).toFixed(2);
    const bogeyAve = ss.average(bogeyArr).toFixed(2);
    const doubleSS = ss.standardDeviation(doubleArr).toFixed(2);
    const doubleAve = ss.average(doubleArr).toFixed(2);
    const otherSS = ss.standardDeviation(otherArr).toFixed(2);
    const otherAve = ss.average(otherArr).toFixed(2);

    const birdieVal = (birdieSS / birdieAve).toFixed(2);
    const parVal = (parSS / parAve).toFixed(2);
    const bogeyVal = (bogeySS / bogeyAve).toFixed(2);
    const doubleVal = (doubleSS / doubleAve).toFixed(2);
    const otherVal = (doubleSS / otherAve).toFixed(2);

    const par3SS = ss.standardDeviation(parThreeAverageArr).toFixed(2);
    const par4SS = ss.standardDeviation(parFourAverageArr).toFixed(2);
    const par5SS = ss.standardDeviation(parFiveAverageArr).toFixed(2);
    const par3Ave = ss.average(parThreeAverageArr).toFixed(2);
    const par4Ave = ss.average(parFourAverageArr).toFixed(2);
    const par5Ave = ss.average(parFiveAverageArr).toFixed(2);

    const par3Val = (par3SS / par3Ave).toFixed(2);
    const par4Val = (par4SS / par4Ave).toFixed(2);
    const par5Val = (par4SS / par5Ave).toFixed(2);

    const girSS = ss.standardDeviation(girArr).toFixed(2);
    const firSS = ss.standardDeviation(firArr).toFixed(2);
    const girAve = ss.average(girArr).toFixed(2);
    const firAve = ss.average(firArr).toFixed(2);

    const girVal = (girSS / firAve).toFixed(2);
    const firVal = (firSS / girAve).toFixed(2);

    // console.log(girSS, firSS);

    const puttSS = ss.standardDeviation(puttAverageArr).toFixed(2);
    const onePuttSS = ss.standardDeviation(onePuttArr).toFixed(2);
    const threePuttSS = ss.standardDeviation(threePuttArr).toFixed(2);

    const puttAve = ss.average(puttAverageArr).toFixed(2);
    const onePuttAve = ss.average(onePuttArr).toFixed(2);
    const threePuttAve = ss.average(threePuttArr).toFixed(2);

    const puttVal = (puttSS / puttAve).toFixed(2);
    const onePuttVal = (onePuttSS / onePuttAve).toFixed(2);
    const threePuttVal = (threePuttSS / threePuttAve).toFixed(2);

    const valProp = [
      {
        label: "Overall",
        values: [
          {
            label: "Gross Score",
            graphData: grossDevArr,
            value: grossSS,
            percentage: grossVal,
          },
          {
            label: "Net Score",
            graphData: netDevArr,
            value: netSS,
            percentage: netVal,
          },
        ],
      },
      {
        label: "Scoring",
        values: [
          {
            label: "Birdies",
            graphData: birdDevArr,
            value: birdieSS,
            percentage: birdieVal,
          },
          {
            label: "Pars",
            graphData: parDevArr,
            value: parSS,
            percentage: parVal,
          },
          {
            label: "Bogeys",
            graphData: bogeyDevArr,
            value: bogeySS,
            percentage: bogeyVal,
          },
          {
            label: "Doubles",
            graphData: doubleDevArr,
            value: doubleSS,
            percentage: doubleVal,
          },
          {
            label: "Other",
            graphData: otherDevArr,
            value: otherSS,
            percentage: otherVal,
          },
        ],
      },
      {
        label: "Holes",
        values: [
          {
            label: "Par 3s",
            graphData: par3DevArr,
            value: par3SS,
            percentage: par3Val,
          },
          {
            label: "Par 4s",
            graphData: par4DevArr,
            value: par4SS,
            percentage: par4Val,
          },
          {
            label: "Par 5s",
            graphData: par5DevArr,
            value: par5SS,
            percentage: par5Val,
          },
        ],
      },
      {
        label: "Putting",
        values: [
          {
            label: "Total Putts",
            graphData: puttDevArr,
            value: puttSS,
            percentage: puttVal,
          },
          {
            label: "1 Putts",
            graphData: onePuttDevArr,
            value: onePuttSS,
            percentage: onePuttVal,
          },
          {
            label: "3 Putts",
            graphData: threePuttdevArr,
            value: threePuttSS,
            percentage: threePuttVal,
          },
        ],
      },
      {
        label: "Other",
        values: [
          {
            label: "Greens In Regulation",
            graphData: girDevArr,
            value: girSS,
            percentage: girVal,
          },
          {
            label: "Fairways In Regulation",
            graphData: firDevArr,
            value: firSS,
            percentage: firVal,
          },
        ],
      },
    ];

    setValues(valProp);

    // setValues((c) => {
    //   c.gross = grossSS;
    //   c.net = netSS;
    //   c.birdies = birdieSS;
    //   c.pars = parSS;
    //   c.bogies = bogeySS;
    //   c.doubles = doubleSS;
    //   c.other = otherSS;
    //   c.par3s = par3SS;
    //   c.par4s = par4SS;
    //   c.par5s = par5SS;
    //   c.gir = girSS;
    //   c.fir = firSS;
    //   c.putts = puttSS;
    //   c.onePutts = onePuttSS;
    //   c.threePutts = threePuttSS;

    //   return c;
    // });

    setLoading(false);
  }

  return (
    <div className="std-dev stats-correl">
      {loading && <BackdropLoader />}
      <div className="ph-msg bss-white bs-subtle pd-10">
        <p>
          Standard Deviation expresses how much variablity is associated with a
          particular statistic - the values below indicate how far each
          statistic strays from the overall average. A higher value means higher
          variability from round to round.
        </p>
      </div>
      <div className="bs-subtle bss-white mb-20 mt-40">
        <div className="">
          {values.map((cat, i) => {
            const values = cat.values;
            return (
              <div key={i} className="std-dev-cat ">
                <div className="neh-box mt-2">
                  <h2 className="ne-header">{cat.label}</h2>
                </div>
                <div className="sc-box flex-wrap">
                  {values.map((val, j) => {
                    return (
                      <div
                        onClick={() => {
                          setSelCat(i);
                          setSelStat(j);
                          const graphVals = val.graphData;
                          setSeries([
                            {
                              name: val.label,
                              data: graphVals,
                            },
                          ]);
                          scrollRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                        className={classNames(
                          "stats-item correl-item flex flex-align-center",
                          {
                            "correl-item-sel": i === selCat && j === selStat,
                          }
                        )}
                        key={`${i}${j}stddev`}
                      >
                        <div className="si-left">
                          <div className="si-tit">
                            <h3>{val.label}</h3>
                          </div>
                        </div>
                        <div className="si-right correl-right">
                          <div
                            className={classNames("si-sta", {
                              "ssa-neg": val.value > 1,
                              "ssa-pos": val.value < 1,
                            })}
                          >
                            {" "}
                            <p>{val.value}</p>
                          </div>
                        </div>
                        <div className="si-rds">
                          <p>({(val.percentage * 100).toFixed(0)}%)</p>
                        </div>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div ref={scrollRef} className="correl-graphs bss-white bs-subtle">
        <div className="neh-box mt-20">
          <h2 className="ne-header">{values[selCat].values[selStat].label}</h2>
        </div>
        <Chart options={proptions} series={series} type={"line"} height={320} />
      </div>
    </div>
  );
}

export default StandardDeviation;
