import React, { useEffect } from "react";
import "./EventRound.css";

import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SportsGolfIcon from "@mui/icons-material/SportsGolf";
import { Oval } from "react-loader-spinner";
const GradientEditIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <EditIcon
      onClick={onClick}
      className="ccr-icon icon-cursor dg-icon-bg"
      sx={{
        fill: "url(#linearColors)",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    />
  </>
);

const GradientDeleteForeverIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorrs" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <DeleteForeverIcon
      onClick={onClick}
      className="ccr-icon"
      sx={{ fill: "url(#linearColorrs)" }}
    />
  </>
);
const GradientTimeIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AccessTimeFilledIcon
      onClick={onClick}
      className="ccr-icon icon-cursor dg-icon-bg"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientRemoveCircleIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorss" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <DeleteForeverIcon
      className="icon-cursor red-icon-bg"
      onClick={onClick}
      sx={{ fill: "url(#linearColorss)" }}
    />
  </>
);

function EventRound({
  img,
  edit = () => {},
  remove,
  num,
  date,
  editGroups = () => {},
  editTimes,
  editTees = () => {},
  custom = false,
  hideIcons = false,
  liveCup = false,
  hideTees = false,
  loading = false,
}) {
  const GradientSportIcon = ({ onClick }) => (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#21c17c" />
          <stop offset={5} stopColor="#1e7a69" />
        </linearGradient>
      </svg>
      <SportsGolfIcon
        onClick={onClick}
        className={
          custom
            ? "ccr-icon icon-cursor mr-10 dg-icon-bg"
            : "ccr-icon icon-cursor dg-icon-bg"
        }
        sx={{ fill: "url(#linearColors)" }}
      />
    </>
  );

  return (
    <div className="event-round">
      <div className="er-left">
        <Avatar alt={""} src={img} />
      </div>
      <div className="er-right">
        <p>
          Round {num} -{" "}
          {typeof date.getMonth === "function"
            ? date.toLocaleDateString("en-us", {
                dateStyle: "full",
              })
            : new Date(date.toDate()).toLocaleDateString("en-us", {
                dateStyle: "full",
              })}
        </p>

        {liveCup && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <GradientSportIcon
              onClick={() => {
                editGroups();
              }}
              className="er-edit"
            />
            <GradientEditIcon
              onClick={() => {
                edit();
              }}
              className="er-edit"
            />
            {/* <GradientDeleteForeverIcon onClick={remove} className="er-delete" /> */}
          </div>
        )}
        {loading && (
          <div className="flex-center loading">
            <Oval
              color="#1e7a69"
              secondaryColor="#ffffff"
              height={40}
              width={40}
            />
          </div>
        )}
        {!hideIcons && !loading && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!hideTees && <GradientSportIcon onClick={editTees} />}
            {custom && (
              <GradientTimeIcon onClick={editTimes} className="er-edit" />
            )}
            <GradientEditIcon
              onClick={() => {
                edit();
              }}
              className="er-edit"
            />
            <GradientRemoveCircleIcon onClick={remove} className="er-delete" />
            {/* <GradientDeleteForeverIcon onClick={remove} className="er-delete" /> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default EventRound;
