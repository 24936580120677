/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./NassauScore.css";
import MatchplayScore from "../MatchplayScore/MatchplayScore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import MatchplayHoles from "../MatchplayHoles/MatchplayHoles";

function NassauScore({
  nameOne,
  nameTwo,
  imgOne,
  imgTwo,
  idOne,
  idTwo,
  score,
  holes,
  leader,
  leaderboard,
  players,
  showBack = false,
  roundID = "",
  matchNum,
  teamOneColor = "#1e7a69",
  teamTwoColor = "#21c17c",
  goBack = () => {},
}) {
  const [oneScore, setOneScore] = useState(0);
  const [twoScore, setTwoScore] = useState(0);

  const [showHoles, setShowHoles] = useState(false);

  useEffect(() => {
    // console.log(nameOne, nameTwo);
    // console.log(score);
    // console.log(leaderboard);
    // console.log(teamOneColor, teamTwoColor);
    // console.log(roundID);
    let one = 0;
    let two = 0;

    if (leaderboard.leader === idOne) {
      one += 1;
    }
    if (leaderboard.backNine === idOne) {
      one += 1;
    }
    if (leaderboard.frontNine === idOne) {
      one += 1;
    }
    if (leaderboard.leader === idTwo) {
      two += 1;
    }
    if (leaderboard.backNine === idTwo) {
      two += 1;
    }
    if (leaderboard.frontNine === idTwo) {
      two += 1;
    }
    if (leaderboard.leader === "" && holes > 0) {
      one += 0.5;
      two += 0.5;
    }
    if (leaderboard.backNine === "" && holes > 9) {
      one += 0.5;
      two += 0.5;
    }
    if (leaderboard.frontNine === "" && holes > 0) {
      one += 0.5;
      two += 0.5;
    }

    setOneScore(one);
    setTwoScore(two);
  }, [leader]);

  return (
    <div className="nassau-score scale-in-ps">
      {showHoles ? (
        <MatchplayHoles
          showBack
          goBack={() => setShowHoles(false)}
          roundID={roundID}
          nassau
          matchNum={matchNum}
          teamOneColor={teamOneColor}
          teamTwoColor={teamTwoColor}
        />
      ) : (
        <>
          {showBack && (
            <div className="ls-header">
              <ArrowForwardIcon
                className="ts-arrow"
                onClick={goBack}
                style={{ marginRight: "20px" }}
              />
              <h1 style={{ fontSize: "14px" }}>Details</h1>
            </div>
          )}

          <div
            style={{
              backgroundImage: `linear-gradient(90deg, ${teamOneColor} 0%, ${teamTwoColor} 100%)`,
            }}
            className="score-details"
          >
            <div className="s-d-left">
              <h2>{oneScore}</h2>
            </div>
            <div className="s-d-mid">
              <h2>-</h2>
            </div>
            <div className="s-d-right">
              <h2>{twoScore}</h2>
            </div>
          </div>
          <div className="c-s-notice">
            <p>Tap to view holes</p>
          </div>
          <div className="ns-header">
            <p className="ne-header">Overall</p>
          </div>
          <div onClick={() => setShowHoles(true)}>
            <MatchplayScore
              nameOne={nameOne}
              nameTwo={nameTwo}
              imgOne={imgOne}
              imgTwo={imgTwo}
              idOne={idOne}
              idTwo={idTwo}
              score={score}
              holes={holes}
              leader={leader}
              teamOneColor={teamOneColor}
              teamTwoColor={teamTwoColor}
            />
            <div className="ns-header">
              <p className="ne-header">Front Nine</p>
            </div>
            <MatchplayScore
              nameOne={nameOne}
              nameTwo={nameTwo}
              leader={leaderboard.frontNine}
              score={
                leaderboard.frontNineBy === "" ||
                leaderboard.frontNineBy === undefined
                  ? 0
                  : leaderboard.frontNineBy
              }
              hideThru
              holes={holes}
              idOne={idOne}
              idTwo={idTwo}
              teamOneColor={teamOneColor}
              teamTwoColor={teamTwoColor}
            />
            <div className="ns-header">
              <p className="ne-header">Back Nine</p>
            </div>
            <MatchplayScore
              nameOne={nameOne}
              nameTwo={nameTwo}
              leader={leaderboard.backNine}
              score={
                leaderboard.backNineBy === "" ||
                leaderboard.backNineBy === undefined
                  ? 0
                  : leaderboard.backNineBy
              }
              hideThru
              holes={holes}
              idOne={idOne}
              idTwo={idTwo}
              teamOneColor={teamOneColor}
              teamTwoColor={teamTwoColor}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default NassauScore;
