import React, { useState, useEffect, useRef } from "react";
import "./GroupSelector.css";
import { db } from "../../../firebase";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import { Dialog } from "@mui/material";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Oval } from "react-loader-spinner";

// Gradients
const GradientAddIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="cr-icon icon-cursor"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientMinusIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <RemoveCircleIcon
      onClick={onClick}
      className="cr-icon icon-cursor"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function EventDashGroupSelector({ roundIDs = [], hide = () => {} }) {
  // Admin
  const getItemStyle = (isDragging, draggableStyle) => {
    console.log();
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      // padding: grid * 2,
      // margin: `0 0 ${grid}px 0`,

      // change background colour if dragging
      background: isDragging ? "#edefef" : "transparent",
      // paddingBottom: '20px',
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };
  // State
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [groupLimit, setGroupLimit] = useState(4);

  const [formatted, setFormatted] = useState([]);

  const [rounds, setRounds] = useState([]);
  const [selRound, setSelRound] = useState(0);

  // UE
  useEffect(() => {
    loadGroups();
  }, []);

  // Functions
  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  function shuffle(array) {
    // alert('everyday')
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  async function loadGroups() {
    const arr = [];
    let longest = 0;
    for (let i = 0; i < roundIDs.length; i++) {
      const roundID = roundIDs[i];
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const { teeTimes, leaderboard } = round;
      const lbMap = convertArrayToObject(leaderboard, "playerID");

      for (let j = 0; j < teeTimes.length; j++) {
        const group = teeTimes[j];
        if (typeof group.time !== "string") {
          const date = new Date(group.time.toDate());
          const time = date.toLocaleTimeString();
          teeTimes[j].time = time;
        }
        const players = group.players;
        if (players.length > longest) {
          longest = players.length;
        }
        for (let x = 0; x < players.length; x++) {
          const player = players[x];
          teeTimes[j].players[x] = lbMap[player];
        }
      }

      arr.push(teeTimes);
    }

    const options = arr.map((round, index) => {
      const rnd = {
        value: `${index}`,
        label: `Round ${index + 1}`,
      };
      return rnd;
    });
    setFormatted(arr);
    setGroupLimit(longest);
    setGroups(arr);
    setRounds(options);
    setLoading(false);
  }

  async function saveChanges() {}

  return (
    <div className="ed-group-selector">
      <Dialog open onClose={hide}>
        <div className="group-selector ed-gs">
          <div className="page-header">
            <h2>Edit Groups</h2>
          </div>
          <div className="ph-msg">
            <p>Drag n Drop players to change groups</p>
          </div>
          {loading ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#21c17c"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : (
            <>
              <div className="input-group pl-20">
                <p>Select Round</p>
                <Dropdown
                  options={rounds}
                  onChange={(e) => setSelRound(parseInt(e.value))}
                />
              </div>
              <div className="p-20">
                <div className="input-group ">
                  <p className="ne-header">Group Limit</p>
                </div>
                <div className="tee-numbers">
                  <div className="svg-cont">
                    <GradientMinusIcon
                      onClick={() =>
                        setGroupLimit((current) => {
                          if (current === 2) {
                            return current;
                          } else {
                            return current - 1;
                          }
                        })
                      }
                    />
                    <p>{groupLimit}</p>
                    <GradientAddIcon
                      onClick={() =>
                        setGroupLimit((current) => {
                          if (current === 4) {
                            return current;
                          } else {
                            return current + 1;
                          }
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="input-group pl-20 mt-0">
                <p onClick={() => {}} className="green shuffle-players">
                  Shuffle
                </p>
              </div>

              {true && (
                <div className="drop-groups">
                  <DragDropContext
                    onDragEnd={(e) => {
                      console.log(e);
                    }}
                  >
                    {groups[selRound].map((group, index) => {
                      const players = group.players;
                      return (
                        <Droppable
                          key={`${index}group`}
                          droppableId={`${index}`}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="test-class drop-group"
                            >
                              <div className="group-header">
                                <h2 className="ne-header">Group {index + 1}</h2>
                                <div className="gh-options">
                                  <input
                                    className="default-input"
                                    defaultValue={group.time}
                                    type={"time"}
                                    onChange={(e) => {}}
                                  />
                                  <div
                                    className={
                                      index === 0 ? "ighr tee-select" : "ighr"
                                    }
                                  >
                                    <div className="ths">
                                      <div
                                        onClick={() => {}}
                                        className={
                                          group.startingTee === 1
                                            ? "thsl tshl-active"
                                            : "thsl"
                                        }
                                      >
                                        <p>1</p>
                                      </div>
                                      <div
                                        onClick={() => {}}
                                        className={
                                          group.startingTee === 10
                                            ? "thsr tshl-active"
                                            : "thsr"
                                        }
                                      >
                                        <p>10</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {players.map((player, index2) => (
                                <Draggable
                                  index={index2}
                                  key={player.playerID}
                                  draggableId={`${player.playerID}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <RoundPlayer
                                        hideVert
                                        border
                                        playerID={player.playerID}
                                        key={player.playerID}
                                        handicap={player.handicap}
                                        imageLink={player.profileImage}
                                        name={player.name}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      );
                    })}
                  </DragDropContext>
                </div>
              )}

              {errorMessage !== "" && (
                <div className="error-message-row">
                  <p>{errorMessage}</p>
                </div>
              )}
              <div className="default-button-row flex-center">
                <button className="default-button">Save Changes</button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default EventDashGroupSelector;
