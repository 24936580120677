import React, { useState, useRef, useEffect } from "react";
import "./CourseSearch.css";
import { db } from "../../../firebase";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CourseItem from "../../../components/display/CourseItem/CourseItem";
import { BallTriangle } from "react-loader-spinner";
import BackdropLoader from "../../../components/Loaders/BackdropLoader";
import algoliasearch from "algoliasearch/lite";
import { useAuth } from "../../../contexts/AuthContext";
import { Country } from "country-state-city";
import PageHeader from "../../display/Rows/PageHeader";
const searchClient = algoliasearch(
  "QF50LJZ12F",
  "729ebd0baad19471b95fc34f74b98202"
);
const index = searchClient.initIndex("uga_tour_courses");

function CourseSearch({
  setCourseDetails,
  setChosen,
  fullBack,
  multiple = false,
  goBack = () => {},
  defaultSelected = [],
}) {
  const { userDetails } = useAuth();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputState, setInputState] = useState("");
  const [update, setUpdate] = useState(0);

  const [selected, setSelected] = useState([]);

  const searchRef = useRef();

  async function algoliCourse() {
    const arr = [];
    setLoading(true);
    index
      .search(inputState, {
        hitsPerPage: 100,
      })
      .then(async ({ hits }) => {
        const algoArr = [];
        setSelected([]);
        const linkMap = hits.map((hit) =>
          db.collection("courses").doc(hit.objectID).get()
        );
        const courseDocs = await Promise.all(linkMap);
        for (let n = 0; n < hits.length; n++) {
          const algoCourse = hits[n];
          // console.log(algoCourse);
          const { objectID, name, rating, city, country, state } = algoCourse;
          const courseDoc = courseDocs[n];
          const courseData = courseDoc.data();
          if (!courseDoc.exists || defaultSelected.includes(objectID)) {
            continue;
          }
          const {
            roundArray,
            tees,
            length,
            par,
            teeArray,
            address,
            location,
            imageLink,
          } = courseData;

          algoArr.push({
            courseID: objectID,
            name,
            imageLink,
            rating,
            city,
            country,
            state,
            roundArray,
            tees,
            length,
            par,
            teeArray,
            address,
            location,
          });
        }
        console.log(userDetails.country);
        const userCountry = Country.getCountryByCode(userDetails.country);
        const uc = userCountry ? userCountry.name : "";
        algoArr.sort((a, b) => {
          if (uc === b.country) {
            return 1;
          } else {
            return -1;
          }
        });
        console.log(uc, algoArr);
        setCourses(algoArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function searchCourse() {
    const input = searchRef.current.value;
    setInputState(input);
    setLoading(true);
    const courses = await db
      .collection("courses")
      .where("name", ">=", input)
      .where("name", "<=", input + "~")
      .get();
    console.log(courses.docs.length);
    const arr = [];
    for (let i = 0; i < courses.docs.length; i++) {
      const courseID = courses.docs[i].id;
      const course = courses.docs[i].data();

      const {
        address,
        city,
        country,
        location,
        name,
        imageLink,
        teeArray,
        tees,
        par,
        length,
      } = course;
      const roundArray = course.roundArray ?? [];
      const totalReviews = course.totalReviews ?? 0;
      const rating = course.rating ?? 5;
      arr.push({
        address,
        city,
        country,
        rating,
        location,
        name,
        courseID,
        imageLink,
        roundArray,
        teeArray,
        tees,
        par,
        totalReviews,
        length,
      });
    }
    setCourses(arr);
    setLoading(false);
  }

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  return (
    <div className="course-search scale-in-ps">
      {loading && <BackdropLoader />}
      <div
        onClick={() => {
          if (multiple) {
            const arr = [];
            for (let i = 0; i < courses.length; i++) {
              const course = courses[i];
              if (selected.includes(course.courseID)) {
                arr.push(course);
              }
            }
            setCourseDetails(arr);
            goBack();
          } else {
            goBack();
          }
        }}
        className="back-row"
      >
        <ArrowBack className="ts-arrow" />
        <p>Back</p>
      </div>
      <PageHeader text="Course Search" />
      <div className="cp-search cps-search mt-20">
        {/* <SearchIcon className="cps-svg" /> */}
        <input
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              console.log(inputState.length);
              if (inputState.length > 2) {
                algoliCourse();
              }
            }
          }}
          onChange={(e) => setInputState(e.target.value)}
          defaultValue={inputState}
          placeholder="Type to search... (min 3 letters)"
          ref={searchRef}
          className="default-input"
          type="search"
        />
        <button disabled={inputState.length < 3} onClick={algoliCourse}>
          Go
        </button>
        {selected.length > 0 && (
          <button
            onClick={() => {
              const arr = [];
              for (let i = 0; i < courses.length; i++) {
                const course = courses[i];
                if (selected.includes(course.courseID)) {
                  arr.push(course);
                }
              }
              setCourseDetails(arr);
              goBack();
            }}
          >
            Save
          </button>
        )}
      </div>
      {loading ? (
        <div className="loading-container cpl">
          <p>Loading courses...</p>
        </div>
      ) : (
        <div className="cs-courses">
          {courses.map((course) => {
            return (
              <CourseItem
                key={course.courseID}
                showBG={selected.includes(course.courseID)}
                showCourse={() => {
                  if (multiple) {
                    setSelected((current) => {
                      if (current.includes(course.courseID)) {
                        const filtered = current.filter(
                          (courseID) => courseID !== course.courseID
                        );
                        return filtered;
                      } else {
                        current.push(course.courseID);
                        return current;
                      }
                    });
                    setUpdate((current) => current + 1);
                  } else {
                    setCourseDetails({
                      name: course.name,
                      city: course.city,
                      country: course.country,
                      rating: course.rating,
                      par: course.par[course.teeArray[0]],
                      totalReviews: course.totalReviews,
                      roundArray: course.roundArray,
                      tees: course.tees,
                      teeArray: course.teeArray,
                      //  par: course.par,
                      imageLink: course.imageLink,
                      length: course.length,
                      courseID: course.courseID,
                    });
                    setChosen(true);
                    goBack();
                  }
                  // fullBack();
                }}
                city={course.city}
                country={course.country}
                courseName={course.name}
                image={course.imageLink}
                rating={course.rating}
              />
            );
          })}
          {courses.length === 0 && (
            <div className="cs-no-courses">
              <h1 style={{ margin: "0px" }}>No courses to display</h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CourseSearch;
