import React, { useEffect, useState, createRef } from "react";
import { db } from "../../firebase";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// Icons
import GolfCourse from "@mui/icons-material/GolfCourse";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackRow from "../../components/display/Rows/BackRow";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import ClubCourse from "./ClubCourse";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import { CSVLink, CSVDownload } from "react-csv";
import { DateRangePicker, Calendar } from "react-date-range";
import moment from "moment";
import ReactToPdf from "react-to-pdf";
import { useNavigate, useParams } from "react-router-dom";

function ClubStats({}) {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  const clubID = useParams().id;

  // State
  const [loading, setLoading] = useState(true);
  const [showCourses, setShowCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selCourse, setSelCourse] = useState("");
  const [courseStats, setCourseStats] = useState({});
  const [error, setError] = useState("");
  const [selected, setSelected] = useState("overall");
  const [update, setUpdate] = useState(0);
  const [holeSorter, setHoleSorter] = useState("holes");
  const [upDown, setUpdown] = useState("");
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const ref = createRef();

  const [totalRounds, setTotalRounds] = useState(0);

  const [holeCSV, setHoleCSV] = useState([]);
  const [overCSV, setOverCSV] = useState([]);

  const [selGen, setSelGen] = useState("holes");
  const [showGenerator, setShowGenerator] = useState(false);

  // UE
  useEffect(() => {
    loadStats();
  }, []);

  async function loadStats() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const { courseIDs } = club;
    if (courseIDs.length > 0) {
      // setShowCourses(true);
    } else {
      setSelCourse(courseIDs[0]);
    }
    const refs = courseIDs.map((id) => db.collection("courses").doc(id).get());
    const courseDocs = await Promise.all(refs);
    const arr = [];
    const mep = {};
    for (let i = 0; i < courseDocs.length; i++) {
      const courseDoc = courseDocs[i];
      const courseID = courseDoc.id;
      const course = courseDoc.data();
      console.log(course);
      course.courseID = courseID;
      const { name, roundArray } = course;
      const holeStats = course.holeStats ?? [];
      const stats = course.stats ?? {};
      setTotalRounds(stats.rounds ?? 0);
      for (let x = 0; x < holeStats.length; x++) {
        delete holeStats[x].dtpHoles;
        holeStats[x].hole = x + 1;
        holeStats[x].netToPar =
          Math.round((holeStats[x].netAverage - holeStats[x].par) * 100) / 100;
        holeStats[x].grossToPar =
          Math.round((holeStats[x].grossAverage - holeStats[x].par) * 100) /
          100;
      }
      mep[courseID] = {
        courseID,
        stats,
        holeStats,
        name,
        roundArray,
      };
      arr.push(course);
    }

    // console.log(mep);

    setSelCourse(courseIDs[0]);

    setCourseStats(mep);
    setCourses(arr);
    createHoleCSV(
      courseIDs[0],
      mep[courseIDs[0]].holeStats,
      mep[courseIDs[0]].stats
    );

    setLoading(false);
  }

  function sortHoles(header, up) {
    if (up) {
      setCourseStats((current) => {
        const sorted = current[selCourse].holeStats.sort(
          (a, b) => a[header] - b[header]
        );
        current[selCourse].holeStats = sorted;
        return current;
      });
    } else {
      setCourseStats((current) => {
        const sorted = current[selCourse].holeStats.sort(
          (a, b) => b[header] - a[header]
        );
        current[selCourse].holeStats = sorted;
        return current;
      });
    }
  }

  function createHoleCSV(courseID, arr = [], defStats) {
    console.log(defStats, arr);
    const holeStats = courseStats[courseID]
      ? courseStats[courseID].holeStats
      : arr;
    console.log(courseStats, courseID);
    if (holeStats.length === 0) {
      return;
    }
    const csvArr = [];
    const headerRow = Object.keys(holeStats[0]);
    csvArr.push(headerRow);

    for (let i = 0; i < holeStats.length; i++) {
      const row = Object.values(holeStats[i]);
      csvArr.push(row);
    }
    console.log(csvArr);
    setHoleCSV(csvArr);

    const overArr = [];
    const stats = courseStats[courseID]
      ? courseStats[courseID].stats
      : defStats;
    const headerHow = Object.keys(stats);
    overArr.push(headerHow);
    const statsRow = Object.values(stats);
    overArr.push(statsRow);
    setOverCSV(overArr);
  }

  function handleRange(e) {
    console.log(e);
  }

  async function generateHoleStats() {
    setLoading(true);
    setShowGenerator(false);

    const { startDate, endDate } = selectionRange[0];
    console.log(startDate);
    const roundDocs = await db
      .collection("rounds")
      .where("date", ">=", startDate)
      .where("date", "<=", endDate)
      .where("courseID", "==", selCourse)
      .get();

    console.log(roundDocs.docs.length);

    const roundIDs = roundDocs.docs.map((roundDoc) => roundDoc.id);
    const roundRefs = roundIDs.map((id) =>
      db.collection("rounds").doc(id).collection("scores").get()
    );
    const roundCollections = await Promise.all(roundRefs);

    console.log(roundCollections.length);

    const holeStats = courseStats[selCourse].holeStats;

    for (let i = 0; i < holeStats.length; i++) {
      const hole = holeStats[i];
      for (const [key, value] of Object.entries(hole)) {
        if (key === "par" || key === "stroke" || key === "hole") {
          continue;
        }
        hole[key] = 0;
      }
      holeStats[i] = hole;
    }

    let totalRounds = 0;

    for (let i = 0; i < roundCollections.length; i++) {
      const roundCollection = roundCollections[i];

      for (let j = 0; j < roundCollection.docs.length; j++) {
        const roundDoc = roundCollection.docs[j];
        const doc = roundDoc.data();
        totalRounds += 1;
        const { holes, frontNineGross, backNineGross, totalGrossStrokes } = doc;
        let totalPuttLength = 0;
        if (
          totalGrossStrokes === 0 ||
          frontNineGross === 0 ||
          backNineGross === 0
        ) {
          continue;
        }

        // Hole Scores
        let roundLongestDrive = 0;
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          if (hole.grossScore === 0) {
            continue;
          }
          let puttLength = hole.puttLength;
          if (puttLength === undefined) {
            puttLength = Math.round(Math.random() * 10);
          }
          totalPuttLength += puttLength;
          const fir = hole.fir === true ? 1 : 0;
          const gir = hole.gir === true ? 1 : 0;
          let netGir = hole.netGir;
          if (netGir === undefined) {
            netGir = hole.netScore - hole.putts < hole.par + hole.strokes - 2;
            // netGir = Math.random() < 0.5;
          }
          netGir = netGir > 0.5 ? 1 : 0;
          const toPar = hole.toPar;
          const grossToPar = toPar + hole.strokes;
          holeStats[x].par = hole.par;
          holeStats[x].stroke = hole.stroke;
          const played = holeStats[x].played;
          if (i === 0) {
            console.log(hole.toPar);
            console.log(grossToPar);
          }
          if (hole.par === 3) {
            if (hole.netGir) {
              holeStats[x].dtpHoles += 1;
            }
          }
          const holeDTP = hole.dtp === undefined ? 10 : hole.dtp;

          // Driving Distance
          if (hole.par === 5 && hole.drd !== 0 && hole.drd !== undefined) {
            holeStats[x].drivesCounted += 1;
            holeStats[x].totalDrives += hole.drd;
            if (holeStats[x].longestDrive < hole.drd) {
              holeStats[x].longestDrive = hole.drd;
            }
            if (roundLongestDrive < hole.drd) {
              roundLongestDrive = hole.drd;
            }
            if (holeStats[x].totalDrives !== 0) {
              holeStats[x].drivingDistance = Math.round(
                holeStats[x].totalDrives / holeStats[x].totalDrives
              );
            }
          }
          if (holeStats[x].dtpHoles > 0) {
            holeStats[x].averageDTP = Math.round(
              (holeStats[x].averageDTP * (holeStats[x].dtpHoles - 1) +
                holeDTP) /
                holeStats[x].dtpHoles
            );
          }
          holeStats[x].netAverage =
            Math.round(
              ((holeStats[x].netAverage * played + hole.netScore) /
                (played + 1)) *
                100
            ) / 100;
          holeStats[x].grossAverage =
            Math.round(
              ((holeStats[x].grossAverage * played + hole.grossScore) /
                (played + 1)) *
                100
            ) / 100;
          holeStats[x].averagePutts =
            Math.round(
              ((holeStats[x].averagePutts * played + hole.putts) /
                (played + 1)) *
                100
            ) / 100;
          holeStats[x].netGIRAverage =
            Math.round(
              ((holeStats[x].netGIRAverage * played + netGir) / (played + 1)) *
                100
            ) / 100;
          holeStats[x].girAverage =
            Math.round(
              ((holeStats[x].girAverage * played + gir) / (played + 1)) * 100
            ) / 100;
          holeStats[x].firAverage =
            Math.round(
              ((holeStats[x].firAverage * played + fir) / (played + 1)) * 100
            ) / 100;
          holeStats[x].averagePuttLength =
            Math.round(
              ((holeStats[x].averagePuttLength * played + puttLength) /
                (played + 1)) *
                100
            ) / 100;
          // if (toPar < -1) {
          //   holeStats[x].netEagles += 1;
          // }
          // if (grossToPar < -1) {
          //   holeStats[x].grossEagles += 1;
          // }
          switch (toPar) {
            case -2:
              holeStats[x].netEagles += 1;
              break;
            case -1:
              holeStats[x].netBirdies += 1;
              break;
            case 0:
              holeStats[x].netPars += 1;
              break;
            case 1:
              holeStats[x].netBogies += 1;
              break;
            case 2:
              holeStats[x].netDoubles += 1;
              break;
          }
          switch (grossToPar) {
            case -2:
              holeStats[x].grossEagles += 1;
              break;
            case -1:
              holeStats[x].grossBirdies += 1;
              break;
            case 0:
              holeStats[x].grossPars += 1;
              break;
            case 1:
              holeStats[x].grossBogies += 1;
              break;
            case 2:
              holeStats[x].grossDoubles += 1;
              break;
          }
          holeStats[x].played += 1;
        }
      }
    }

    for (let x = 0; x < holeStats.length; x++) {
      holeStats[x].netToPar =
        Math.round((holeStats[x].netAverage - holeStats[x].par) * 100) / 100;
      holeStats[x].grossToPar =
        Math.round((holeStats[x].grossAverage - holeStats[x].par) * 100) / 100;
    }

    console.log(holeStats);

    setTotalRounds(totalRounds);

    const csvArr = [];
    const headerRow = Object.keys(holeStats[0]);
    csvArr.push(headerRow);

    for (let i = 0; i < holeStats.length; i++) {
      const row = Object.values(holeStats[i]);
      csvArr.push(row);
    }
    console.log(csvArr);
    setHoleCSV(csvArr);
    console.log(selCourse);
    setCourseStats((current) => {
      current[selCourse].holeStats = holeStats;
      return current;
    });
    setSelected("holes");

    setLoading(false);
  }

  async function generateOverallStats() {
    setLoading();
    setShowGenerator(false);
    const { startDate, endDate } = selectionRange[0];
    const roundArray = courseStats[selCourse].roundArray;

    const stMoment = moment(startDate);
    const enMoment = moment(endDate);

    const statsProp = courseStats[selCourse].stats;
    for (const [key, object] of Object.entries(statsProp)) {
      statsProp[key] = 0;
    }

    let totalGross = 0;
    let totalNet = 0;
    let totalPutts = 0;
    let totalBackGross = 0;
    let totalFrontGross = 0;
    let totalBackNet = 0;
    let totalFrontNet = 0;
    let totalFIR = 0;
    let totalGIR = 0;
    let totalNetGIR = 0;

    for (let i = 0; i < roundArray.length; i++) {
      if (i === 0) {
        console.log(roundArray[i]);
      }
      const round = roundArray[i];
      const roundDate = new Date(round.roundDate.toDate());
      const roundMoment = moment(roundDate);
      const {
        backNineGross,
        backNineNet,
        frontNineGross,
        frontNineNet,
        totalGrossStrokes,
        totalNetStrokes,
      } = round;
      if (!roundMoment.isBetween(stMoment, enMoment)) {
        continue;
      }
      statsProp.rounds += 1;

      totalGross += totalGrossStrokes;
      totalNet += totalNetStrokes;
      totalPutts += round.totalPutts;
      totalFIR += round.totalFIR;
      totalGIR += round.totalGIR;
      totalNetGIR += round.totalNetGIR;
      totalBackGross += backNineGross;
      totalFrontGross += frontNineGross;
      totalBackNet += backNineNet;
      totalFrontNet += frontNineNet;

      if (
        statsProp.backNineLowestGross === 0 ||
        statsProp.backNineLowestGross > backNineGross
      ) {
        statsProp.backNineLowestGross = backNineGross;
      }
      if (
        statsProp.backNineLowestNet === 0 ||
        statsProp.backNineLowestNet > backNineNet
      ) {
        statsProp.backNineLowestNet = backNineNet;
      }
      if (
        statsProp.frontNineLowestGross === 0 ||
        statsProp.frontNineLowestGross > frontNineGross
      ) {
        statsProp.frontNineLowestGross = frontNineGross;
      }
      if (
        statsProp.frontNineLowestNet === 0 ||
        statsProp.frontNineLowestNet > frontNineNet
      ) {
        statsProp.frontNineLowestNet = frontNineNet;
      }

      if (
        statsProp.lowestGross === 0 ||
        statsProp.lowestGross > totalGrossStrokes
      ) {
        statsProp.lowestGross = totalGrossStrokes;
      }

      if (statsProp.lowestNet === 0 || statsProp.lowestNet > totalNetStrokes) {
        statsProp.lowestNet = totalNetStrokes;
      }

      if (
        statsProp.backNineHighestGross === 0 ||
        statsProp.backNineHighestGross < backNineGross
      ) {
        statsProp.backNineHighestGross = backNineGross;
      }
      if (
        statsProp.backNineHighestNet === 0 ||
        statsProp.backNineHighestNet < backNineNet
      ) {
        statsProp.backNineHighestNet = backNineNet;
      }
      if (
        statsProp.frontNineHighestGross === 0 ||
        statsProp.frontNineHighestGross < frontNineGross
      ) {
        statsProp.frontNineHighestGross = frontNineGross;
      }
      if (
        statsProp.frontNineHighestNet === 0 ||
        statsProp.frontNineHighestNet < frontNineNet
      ) {
        statsProp.frontNineHighestNet = frontNineNet;
      }

      if (
        statsProp.highestGross === 0 ||
        statsProp.highestGross < totalGrossStrokes
      ) {
        statsProp.highestGross = totalGrossStrokes;
      }

      if (
        statsProp.highestNet === 0 ||
        statsProp.highestNet < totalNetStrokes
      ) {
        statsProp.highestNet = totalNetStrokes;
      }
    }
    console.log(statsProp.rounds);
    const total = statsProp.rounds;
    statsProp.averageGross = Math.round((totalGross / total) * 100) / 100;
    statsProp.averageNet = Math.round((totalNet / total) * 100) / 100;
    statsProp.averagePutts = Math.round((totalPutts / total) * 100) / 100;
    statsProp.backNineGrossAverage =
      Math.round((totalBackGross / total) * 100) / 100;
    statsProp.backNineNetAverage =
      Math.round((totalBackNet / total) * 100) / 100;
    statsProp.frontNineGrossAverage =
      Math.round((totalFrontGross / total) * 100) / 100;
    statsProp.frontNineNetAverage =
      Math.round((totalFrontNet / total) * 100) / 100;
    statsProp.firAve = Math.round((totalFIR / 14) * 100) / 100;
    statsProp.girAve = Math.round((totalGIR / 18) * 100) / 100;
    statsProp.netGirAve = Math.round((totalNetGIR / 18) * 100) / 100;
    console.log(statsProp);
    const csvArr = [];
    const headerRow = Object.keys(statsProp);
    const statsRow = Object.values(statsProp);
    csvArr.push(headerRow);
    csvArr.push(statsRow);
    setCourseStats((current) => {
      current[selCourse].stats = statsProp;
      return current;
    });
    setOverCSV(csvArr);
    setUpdate((current) => current + 1);
    setSelected("overall");
    setLoading(false);
  }

  return (
    <div className="club-stats">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog open={showCourses} onClose={() => setShowCourses(false)}>
        <div className="club-stats-picker pd-20">
          <div className="page-header">
            <h2>Select Course</h2>
            {selCourse !== "" && (
              <Close
                className="icon-cursor"
                onClick={() => setShowCourses(false)}
              />
            )}
          </div>
          <div className="csp-courses pd-20">
            {courses.map((course, index) => (
              <ClubCourse
                key={course.courseID}
                select
                setSelected={() => {
                  setSelCourse(course.courseID);
                  createHoleCSV(course.courseID);
                  setShowCourses(false);
                  console.log(courseStats[course.courseID]);
                }}
                course={course}
                hideRight
              />
            ))}
          </div>
        </div>
      </Dialog>

      <Dialog open={showGenerator} onClose={() => setShowGenerator(false)}>
        <div className="stats-generator">
          <div className="page-header">
            <h2>Stats Generator</h2>
            <Close
              className="icon-cursor"
              onClick={() => setShowGenerator(false)}
            />
          </div>
          <RadioGroupTwo
            active={selGen}
            setActive={setSelGen}
            buttons={[
              { text: "Overall", value: "overall" },
              { text: "Holes", value: "holes" },
            ]}
          />
          <div className="dr-picker">
            <DateRangePicker
              maxDate={new Date()}
              color={"#1e7a69"}
              showMonthAndYearPickers={false}
              ranges={selectionRange}
              rangeColors={["#1e7a69"]}
              onChange={(e) => {
                console.log(e);
                setSelectionRange([e.selection]);
              }}
            />
          </div>
          <div className="flex-center mb-20">
            <button
              onClick={() => {
                if (selGen === "overall") {
                  generateOverallStats();
                } else {
                  generateHoleStats();
                }
              }}
              className="default-button"
            >
              Generate
            </button>
          </div>
        </div>
      </Dialog>

      <BackRow action={goBack} />

      <PageHeader text="Course Stats" />
      {courseStats[selCourse] && (
        <div className="ph-msg mb-20 mt-0">
          <p>{courseStats[selCourse].name}</p>
        </div>
      )}
      <div className="sdb-box">
        {courses.length > 1 && (
          <button
            onClick={() => setShowCourses(true)}
            className="default-button"
          >
            Change Course
          </button>
        )}
        <CSVLink
          data={selected === "holes" ? holeCSV : overCSV}
          className="default-button csv-sb "
        >
          Export CSV
        </CSVLink>
        {selected === "holes" && (
          <ReactToPdf
            options={{ orientation: "landscape", quality: 5 }}
            //   scale={5}
            //   quality={5}
            targetRef={ref}
            filename="stats.pdf"
          >
            {({ toPdf }) => (
              <button className="default-button ml-20" onClick={toPdf}>
                Export pdf
              </button>
            )}
          </ReactToPdf>
        )}
        <button
          onClick={() => setShowGenerator(true)}
          className="default-button ml-20"
        >
          Generator
        </button>
      </div>

      <RadioGroupTwo
        active={selected}
        setActive={setSelected}
        buttons={[
          { text: "Overall", value: "overall" },
          { text: "Holes", value: "holes" },
        ]}
      />
      {selCourse !== "" && (
        <>
          <div className="ph-msg">
            <p>{courseStats[selCourse].name}</p>
          </div>
        </>
      )}
      {selected === "overall" && courseStats[selCourse] && (
        <>
          <div ref={ref} className="home-dash">
            <div className="hd-item">
              <PageHeader text="General" />
              <div className="cs-overall">
                <div className="cso-item">
                  <p>Total Rounds</p>
                  <h5>{courseStats[selCourse].stats.rounds}</h5>
                </div>
                <div className="cso-item">
                  <p>Average Net</p>
                  <h5>{courseStats[selCourse].stats.averageNet}</h5>
                </div>
                <div className="cso-item">
                  <p>Average Gross</p>
                  <h5>{courseStats[selCourse].stats.averageGross}</h5>
                </div>
                <div className="cso-item">
                  <p>Average Putts</p>
                  <h5>{courseStats[selCourse].stats.averagePutts}</h5>
                </div>
                <div className="cso-item">
                  <p>Average FIR</p>
                  <h5>{courseStats[selCourse].stats.firAve}%</h5>
                </div>
                <div className="cso-item">
                  <p>Average GIR</p>
                  <h5>{courseStats[selCourse].stats.girAve / 100}%</h5>
                </div>
                <div className="cso-item">
                  <p>Average Net GIR</p>
                  <h5>{courseStats[selCourse].stats.netGirAve / 100}%</h5>
                </div>
                <div className="cso-item">
                  <p>Lowest Gross</p>
                  <h5>{courseStats[selCourse].stats.lowestGross}</h5>
                </div>
                <div className="cso-item">
                  <p>Lowest Net</p>
                  <h5>{courseStats[selCourse].stats.lowestNet}</h5>
                </div>
                <div className="cso-item">
                  <p>Highest Gross</p>
                  <h5>{courseStats[selCourse].stats.highestGross}</h5>
                </div>
                <div className="cso-item">
                  <p>Highest Net</p>
                  <h5>{courseStats[selCourse].stats.highestNet}</h5>
                </div>
              </div>
            </div>
            <div className="hd-item">
              <PageHeader text="Nines" />
              <div className="cso-overall">
                <div className="cso-item">
                  <p>Front Nine Average (Gross)</p>
                  <h5>{courseStats[selCourse].stats.frontNineGrossAverage}</h5>
                </div>
                <div className="cso-item">
                  <p>Front Nine Average (Net)</p>
                  <h5>{courseStats[selCourse].stats.frontNineNetAverage}</h5>
                </div>
                <div className="cso-item">
                  <p>Back Nine Average (Gross)</p>
                  <h5>{courseStats[selCourse].stats.backNineGrossAverage}</h5>
                </div>
                <div className="cso-item">
                  <p>Back Nine Average (Net)</p>
                  <h5>{courseStats[selCourse].stats.backNineNetAverage}</h5>
                </div>
                <div className="cso-item">
                  <p>Front Nine Lowest (Gross)</p>
                  <h5>{courseStats[selCourse].stats.frontNineLowestGross}</h5>
                </div>
                <div className="cso-item">
                  <p>Front Nine Lowest (Net)</p>
                  <h5>{courseStats[selCourse].stats.frontNineLowestNet}</h5>
                </div>
                <div className="cso-item">
                  <p>Front Nine Highest (Gross)</p>
                  <h5>{courseStats[selCourse].stats.frontNineHighestGross}</h5>
                </div>
                <div className="cso-item">
                  <p>Front Nine Highest (Net)</p>
                  <h5>{courseStats[selCourse].stats.frontNineHighestNet}</h5>
                </div>
                <div className="cso-item">
                  <p>Back Nine Lowest (Gross)</p>
                  <h5>{courseStats[selCourse].stats.backNineLowestGross}</h5>
                </div>
                <div className="cso-item">
                  <p>Back Nine Lowest (Net)</p>
                  <h5>{courseStats[selCourse].stats.backNineLowestNet}</h5>
                </div>
                <div className="cso-item">
                  <p>Back Nine Highest (Gross)</p>
                  <h5>{courseStats[selCourse].stats.backNineHighestGross}</h5>
                </div>
                <div className="cso-item">
                  <p>Back Nine Highest (Net)</p>
                  <h5>{courseStats[selCourse].stats.backNineHighestNet}</h5>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {selected === "holes" && (
        <>
          <div className="cs-rounds-info">
            <p>Total Rounds:</p>
            <h5>{totalRounds}</h5>
          </div>
          <div className="csh-box">
            <div
              // style={{ width: "100%" }}
              ref={ref}
              className="course-stats-holes pd-20"
            >
              <div className="csh-hole csh-header">
                <p>
                  <GolfCourse />
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "par") {
                      if (upDown === "up") {
                        sortHoles("par", false);
                        setUpdown("down");
                      } else {
                        sortHoles("par", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("par");
                      sortHoles("par", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                  className={holeSorter === "par" ? "csh-active" : ""}
                >
                  Par
                </p>
                <p
                  className={holeSorter === "stroke" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "stroke") {
                      if (upDown === "up") {
                        sortHoles("stroke", false);
                        setUpdown("down");
                      } else {
                        sortHoles("stroke", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("stroke");
                      sortHoles("stroke", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Stroke
                </p>
                <p
                  className={holeSorter === "netAverage" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "netAverage") {
                      if (upDown === "up") {
                        sortHoles("netAverage", false);
                        setUpdown("down");
                      } else {
                        sortHoles("netAverage", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("netAverage");
                      sortHoles("netAverage", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Net
                </p>
                <p
                  className={holeSorter === "grossAverage" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "grossAverage") {
                      if (upDown === "up") {
                        sortHoles("grossAverage", false);
                        setUpdown("down");
                      } else {
                        sortHoles("grossAverage", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("grossAverage");
                      sortHoles("grossAverage", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Gross
                </p>
                <p
                  className={holeSorter === "grossToPar" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "grossToPar") {
                      if (upDown === "up") {
                        sortHoles("grossToPar", false);
                        setUpdown("down");
                      } else {
                        sortHoles("grossToPar", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("grossToPar");
                      sortHoles("grossToPar", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Gross To Par
                </p>
                <p
                  className={holeSorter === "netToPar" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "netToPar") {
                      if (upDown === "up") {
                        sortHoles("netToPar", false);
                        setUpdown("down");
                      } else {
                        sortHoles("netToPar", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("netToPar");
                      sortHoles("netToPar", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Net To Par
                </p>
                <p
                  className={holeSorter === "averagePutts" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "averagePutts") {
                      if (upDown === "up") {
                        sortHoles("averagePutts", false);
                        setUpdown("down");
                      } else {
                        sortHoles("averagePutts", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("averagePutts");
                      sortHoles("averagePutts", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Average Putts
                </p>
                <p
                  className={holeSorter === "firAverage" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "firAverage") {
                      if (upDown === "up") {
                        sortHoles("firAverage", false);
                        setUpdown("down");
                      } else {
                        sortHoles("firAverage", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("firAverage");
                      sortHoles("firAverage", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  FIR
                </p>
                <p
                  className={holeSorter === "girAverage" ? "csh-active" : ""}
                  onClick={() => {
                    if (holeSorter === "girAverage") {
                      if (upDown === "up") {
                        sortHoles("girAverage", false);
                        setUpdown("down");
                      } else {
                        sortHoles("girAverage", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("girAverage");
                      sortHoles("girAverage", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  GIR
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "netEagles") {
                      if (upDown === "up") {
                        sortHoles("netEagles", false);
                        setUpdown("down");
                      } else {
                        sortHoles("netEagles", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("netEagles");
                      sortHoles("netEagles", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Net Eagles
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "netBirdies") {
                      if (upDown === "up") {
                        sortHoles("netBirdies", false);
                        setUpdown("down");
                      } else {
                        sortHoles("netBirdies", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("netBirdies");
                      sortHoles("netBirdies", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Net Birdies
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "netPars") {
                      if (upDown === "up") {
                        sortHoles("netPars", false);
                        setUpdown("down");
                      } else {
                        sortHoles("netPars", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("netPars");
                      sortHoles("netPars", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Net Pars
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "netBogies") {
                      if (upDown === "up") {
                        sortHoles("netBogies", false);
                        setUpdown("down");
                      } else {
                        sortHoles("netBogies", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("netBogies");
                      sortHoles("netBogies", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Net Bogeys
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "netDoubles") {
                      if (upDown === "up") {
                        sortHoles("netDoubles", false);
                        setUpdown("down");
                      } else {
                        sortHoles("netDoubles", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("netDoubles");
                      sortHoles("netDoubles", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Net Doubles
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "grossEagles") {
                      if (upDown === "up") {
                        sortHoles("grossEagles", false);
                        setUpdown("down");
                      } else {
                        sortHoles("grossEagles", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("grossEagles");
                      sortHoles("grossEagles", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Gross Eagles
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "grossBirdies") {
                      if (upDown === "up") {
                        sortHoles("grossBirdies", false);
                        setUpdown("down");
                      } else {
                        sortHoles("grossBirdies", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("grossBirdies");
                      sortHoles("grossBirdies", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Gross Birdies
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "grossPars") {
                      if (upDown === "up") {
                        sortHoles("grossPars", false);
                        setUpdown("down");
                      } else {
                        sortHoles("grossPars", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("grossPars");
                      sortHoles("grossPars", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Gross Pars
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "grossBogies") {
                      if (upDown === "up") {
                        sortHoles("grossBogies", false);
                        setUpdown("down");
                      } else {
                        sortHoles("grossBogies", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("grossBogies");
                      sortHoles("grossBogies", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Gross Bogies
                </p>
                <p
                  onClick={() => {
                    if (holeSorter === "grossDoubles") {
                      if (upDown === "up") {
                        sortHoles("grossDoubles", false);
                        setUpdown("down");
                      } else {
                        sortHoles("grossDoubles", true);
                        setUpdown("up");
                      }
                    } else {
                      setHoleSorter("grossDoubles");
                      sortHoles("grossDoubles", false);
                    }
                    setUpdate((current) => current + 1);
                  }}
                >
                  Gross Doubles
                </p>
              </div>
              {courseStats[selCourse].holeStats.map((hole, index) => (
                <div className="csh-hole">
                  <p>{hole.hole}</p>
                  <p className={holeSorter === "par" ? "csh-active" : ""}>
                    {hole.par}
                  </p>
                  <p className={holeSorter === "stroke" ? "csh-active" : ""}>
                    {hole.stroke}
                  </p>
                  <p
                    className={holeSorter === "netAverage" ? "csh-active" : ""}
                  >
                    {hole.netAverage}
                  </p>
                  <p
                    className={
                      holeSorter === "grossAverage" ? "csh-active" : ""
                    }
                  >
                    {hole.grossAverage}
                  </p>
                  <p
                    className={holeSorter === "grossToPar" ? "csh-active" : ""}
                  >
                    {hole.grossToPar}
                  </p>
                  <p className={holeSorter === "netToPar" ? "csh-active" : ""}>
                    {hole.netToPar}
                  </p>
                  <p
                    className={
                      holeSorter === "averagePutts" ? "csh-active" : ""
                    }
                  >
                    {hole.averagePutts}
                  </p>
                  <p
                    className={holeSorter === "firAverage" ? "csh-active" : ""}
                  >
                    {Math.round(hole.firAverage * 100)}%
                  </p>
                  <p
                    className={holeSorter === "girAverage" ? "csh-active" : ""}
                  >
                    {Math.round(hole.girAverage * 100)}%
                  </p>
                  <p className={holeSorter === "netEagles" ? "csh-active" : ""}>
                    {hole.netEagles}
                  </p>
                  <p
                    className={holeSorter === "netBirdies" ? "csh-active" : ""}
                  >
                    {hole.netBirdies}
                  </p>
                  <p className={holeSorter === "netPars" ? "csh-active" : ""}>
                    {hole.netPars}
                  </p>
                  <p className={holeSorter === "netBogies" ? "csh-active" : ""}>
                    {hole.netBogies}
                  </p>
                  <p
                    className={holeSorter === "netDoubles" ? "csh-active" : ""}
                  >
                    {hole.netDoubles}
                  </p>
                  <p
                    className={holeSorter === "grossEagles" ? "csh-active" : ""}
                  >
                    {hole.grossEagles}
                  </p>
                  <p
                    className={
                      holeSorter === "grossBirdies" ? "csh-active" : ""
                    }
                  >
                    {hole.grossBirdies}
                  </p>
                  <p className={holeSorter === "grossPars" ? "csh-active" : ""}>
                    {hole.grossPars}
                  </p>
                  <p
                    className={holeSorter === "grossBogies" ? "csh-active" : ""}
                  >
                    {hole.grossBogies}
                  </p>
                  <p
                    className={
                      holeSorter === "grossDoubles" ? "csh-active" : ""
                    }
                  >
                    {hole.grossDoubles}
                  </p>
                </div>
              ))}
            </div>{" "}
          </div>
        </>
      )}
    </div>
  );
}

export default ClubStats;
