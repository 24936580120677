import React, { useState, useEffect, useCallback } from "react";
import { admin, db } from "../../../firebase";
import Papa from "papaparse";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import PageHeader from "../../display/Rows/PageHeader";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import { useDropzone } from "react-dropzone";

// Icons
import SaveIcon from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";

import csvexample from "../../../assets/GuestExample.csv";
import { isMobile } from "react-device-detect";

function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function EventGuests({
  eventID = "",
  customEvtID = "",
  goBack = () => {},
  defaultGuests = [],
  reload = () => {},
  disableChanges = false,
  live = false,
}) {
  const [guests, setGuests] = useState(defaultGuests);

  const [evtID, setEvtID] = useState(customEvtID);

  useEffect(() => {
    console.log(defaultGuests);
  }, []);

  const [showNew, setShowNew] = useState(false);
  const [update, setUpdate] = useState(0);

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [showEdit, setShowEdit] = useState(false);

  const [showUpload, setShowUpload] = useState(false);

  const [upload, setUpload] = useState([]);
  const [uploadJSON, setUploadJSON] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [idNum, setIDNum] = useState("");
  const [handicap, setHandicap] = useState(18);
  const [customID, setCustomID] = useState("");
  const [editID, setEditID] = useState("");

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editIDNum, setEditIDNum] = useState("");
  const [editHandicap, setEditHandicap] = useState(18);

  const [changed, setChanged] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [fileName, setFileName] = useState("");
  const [upg, setUPG] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        console.log(res.data);
        const arr = [];
        const headerRow = res.data[0];
        let fnCol = "";
        let lnCol = "";
        let hcpCol = "";
        let gidCol = "";
        let genCol = "";

        for (let i = 0; i < headerRow.length; i++) {
          if (headerRow[i] === "firstName") {
            fnCol = i;
          } else if (headerRow[i] === "lastName") {
            lnCol = i;
          } else if (headerRow[i] === "handicap") {
            hcpCol = i;
          } else if (headerRow[i] === "guestID") {
            gidCol = i;
          } else if (headerRow[i] === "gender") {
            genCol = i;
          }
        }

        if (fnCol === "" || lnCol === "" || hcpCol === "") {
          return setError(
            "Sorry, we couldn't quite sort that out - please check your column headers!"
          );
        }

        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];
          const firstName = row[fnCol];
          const lastName = row[lnCol];
          const handicap = row[hcpCol] ?? 10;
          const guestID = row[gidCol] ?? "";
          const gender = row[genCol] ?? "male";
          arr.push({
            firstName,
            lastName,
            handicap: parseInt(handicap),
            guestID: `guest${makeID(15)}`,
            customGuestID: guestID,
            gender,
          });
        }
        setUPG(arr);
        console.log(arr);
        setUpload(res);
        setUploadJSON(arr);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  async function lookupGolfer() {
    let userHandicap;

    if (showEdit) {
      setEditHandicap(userHandicap);
    } else {
      setHandicap(userHandicap);
    }
  }

  async function saveChanges() {
    setLoading(true);

    try {
      if (live) {
        const guestIDs = guests.map((g) => g.customGuestID ?? g.guestID);
        // console.log(guests, guestIDs);
        // return setLoading(false);
        await db.collection("events").doc(eventID).update({
          guests,
          guestIDs,
        });
      } else {
        await db.collection("events").doc(eventID).update({
          guests,
        });
      }
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again"
      );
    }
    setLoading(false);
  }

  async function saveUPG() {
    setLoading(true);
    try {
      await db
        .collection("events")
        .doc(eventID)
        .update({
          guests: admin.firestore.FieldValue.arrayUnion(...upg),
        });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there. Please try again"
      );
    }
    setLoading(false);
  }

  function add() {
    if (firstName === "" || lastName === "") {
      return setError("Please include the player's name");
    }

    const newGuest = {
      firstName,
      lastName,
      idNum,
      handicap,
      guestID: `guest${makeID(15)}`,
      customGuestID: customID,
    };
    setGuests((current) => [...current, newGuest]);
    setShowNew(false);
    setFirstName("");
    setLastName("");
    setIDNum("");
    setHandicap(18);
    setChanged(true);
  }

  async function saveCustomID() {
    setLoading(true);
    await db.collection("events").doc(eventID).update({
      guestEventID: evtID,
    });
    setLoading(false);
  }

  return (
    <div className="event-guests">
      {loading && <BackdropLoader />}
      <BackRow
        action={() => {
          if (showUpload) {
            setShowUpload(false);
          } else {
            goBack();
          }
        }}
      />
      <PageHeader
        text={
          live ? "Manage Guests" : showUpload ? "Upload Guests" : "Add Guests"
        }
      />
      {!showUpload && (
        <div className="flex-align-center">
          <div className="input-group pl-20 mb-40 eg-di">
            <p>
              Guest Event ID (for guests to find the event on The UGA Tour App)
            </p>
            <input
              className="default-input"
              value={evtID}
              onChange={(e) => setEvtID(e.target.value)}
              placeholder="Start typing..."
            />
          </div>
          <button onClick={saveCustomID} className="green-link">
            Save
          </button>
        </div>
      )}

      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {showUpload ? (
        <>
          <div className="bi-box">
            <div className="pd-20 bi-drop">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-sel">
                  {fileName ? (
                    <h5 className="filename">{fileName}</h5>
                  ) : (
                    <h5>
                      Drag 'n' drop a .csv file here, or click to open up the
                      file explorer
                    </h5>
                  )}
                </div>
              </div>
            </div>
            <div className="bi-info">
              <p>
                In order for the players to be added correctly, formatting is
                important but simple! Only a few fields are needed, the platform
                name and the relevant platform ID, as well as other optional
                fields. <br />
                <br />
                <strong>
                  The first row (header row) will be included.
                </strong>{" "}
                Name your colums as follows (letter case is important):
              </p>
              <ul>
                <li>firstName</li>
                <li>lastName</li>
                <li>handicap</li>
                <li>gender</li>
                <li>guestID</li>
              </ul>
              <a href={csvexample} className="green-link mt-20">
                Download Sample
              </a>
              <p className="mt-20">
                If you choose to upload tee times, the starting tee and round
                defaults to 1 if not provided.
              </p>
            </div>
          </div>
          <div className="upg-players pd-20">
            {upg.map((player, index) => {
              const name = `${player.firstName} ${player.lastName}`;
              return (
                <div key={player.guestID} className={"flex-align-center"}>
                  <p style={{ width: "30vw" }}>
                    {name}{" "}
                    {player.handicap !== "" && `(hcp ${player.handicap})`}{" "}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="pd-20">
            <button
              disabled={upg.length === 0}
              onClick={saveUPG}
              className="default-button"
            >
              Save Changes
            </button>
          </div>
        </>
      ) : (
        <>
          {!live && (
            <div className="pl-20 flex-align-center">
              <p onClick={() => setShowNew(true)} className="green-link">
                Add Guest
              </p>
              {!isMobile && (
                <p
                  onClick={() => setShowUpload(true)}
                  className="green-link ml-20"
                >
                  Upload Guests
                </p>
              )}
            </div>
          )}

          <div className="input-group pd-20 eg-di">
            <p>Search for player</p>
            <input
              type="text"
              placeholder="Start typing..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="default-input"
            />
          </div>

          <Dialog open={showEdit} onClose={() => setShowEdit(false)}>
            <div className="eg-edit pd-20">
              <PageHeader
                text="Edit Guest"
                showClose
                close={() => setShowEdit(false)}
              />
              <div className="new-guest-row edit-guest-row flex-align-center pd-20">
                <div className="input-group">
                  <p>First Name</p>
                  <input
                    type="text"
                    disabled={live}
                    value={editFirstName}
                    onChange={(e) => setEditFirstName(e.target.value)}
                    className="default-input"
                    placeholder="Start typing..."
                  />
                </div>
                <div className="input-group">
                  <p>Last Name</p>
                  <input
                    disabled={live}
                    type="text"
                    value={editLastName}
                    onChange={(e) => setEditLastName(e.target.value)}
                    className="default-input"
                    placeholder="Start typing..."
                  />
                </div>
                <div className="input-group">
                  <p>Custom Guest ID</p>
                  <div className="flex-align-center golf-id">
                    <input
                      type="text"
                      value={editIDNum}
                      onChange={(e) => setEditIDNum(e.target.value)}
                      className="default-input"
                      placeholder="Start typing..."
                    />
                  </div>
                </div>
                {!live && (
                  <div className="input-group">
                    <p>Handicap</p>
                    <input
                      type="number"
                      value={editHandicap}
                      min={-10}
                      max={54}
                      onChange={(e) => setEditHandicap(e.target.value)}
                      className="default-input"
                      placeholder="Start typing..."
                    />
                  </div>
                )}
              </div>

              <div className="flex-center">
                <button
                  onClick={() => {
                    setGuests((current) => {
                      for (let i = 0; i < current.length; i++) {
                        if (current[i].guestID === editID) {
                          current[i].firstName = editFirstName;
                          current[i].lastName = editLastName;
                          current[i].handicap = editHandicap;
                          current[i].customGuestID = editIDNum;
                        }
                      }
                      return current;
                    });
                    setEditFirstName("");
                    setEditLastName("");
                    setEditHandicap(18);
                    setEditIDNum("");
                    setShowEdit(false);
                    setChanged(true);
                    setUpdate((current) => current + 1);
                  }}
                  className="default-button"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Dialog>

          {showNew && (
            <div className="new-guest-row pd-20 flex-align-center">
              <div className="input-group">
                <p>First Name</p>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>
              <div className="input-group">
                <p>Last Name</p>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>

              <div className="input-group">
                <p>Custom Guest ID</p>
                <input
                  type="text"
                  value={customID}
                  onChange={(e) => setCustomID(e.target.value)}
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>
              <div className="input-group">
                <p>Handicap</p>
                <input
                  type="number"
                  value={handicap}
                  min={-10}
                  max={54}
                  onChange={(e) => setHandicap(e.target.value)}
                  className="default-input"
                  placeholder="Start typing..."
                />
              </div>
              <div className="eg-icos">
                <SaveIcon
                  className="dg-icon-bg icon-cursor ml-20"
                  onClick={() => {
                    add();
                  }}
                />
                <Cancel
                  onClick={() => {
                    setShowNew(false);
                    setFirstName("");
                    setLastName("");
                    setIDNum("");
                    setCustomID("");
                    setHandicap(18);
                  }}
                  className="red-icon-bg ml-10 icon-cursor"
                />{" "}
              </div>
            </div>
          )}

          <div className="eg-guests pd-20">
            {guests.map((guest, index) => {
              if (searchInput !== "") {
                const sl = searchInput.toLowerCase();
                const sn = `${guest.firstName} ${guest.lastName}`.toLowerCase();

                if (!sn.includes(sl)) {
                  return null;
                }
              }

              return (
                <PlayerItem
                  key={index}
                  name={`${guest.firstName} ${guest.lastName}`}
                  hideMessage
                  hideImage
                  hcp={guest.handicap}
                  showAdd
                  noLink
                  guestID={guest.customGuestID ?? guest.guestID}
                  hideProfile
                  hideIcons={disableChanges}
                  editAction={() => {
                    setEditFirstName(guest.firstName);
                    setEditLastName(guest.lastName);
                    setEditIDNum(guest.customGuestID);
                    setEditHandicap(guest.handicap);
                    setEditID(guest.guestID);
                    setShowEdit(true);
                  }}
                  removeAction={() => {
                    setGuests((current) =>
                      current.filter((gst) => gst.guestID !== guest.guestID)
                    );
                    setChanged(true);
                    setUpdate((current) => current + 1);
                  }}
                />
              );
            })}
          </div>

          <div className="pd-20">
            <button
              disabled={!changed}
              onClick={saveChanges}
              className="default-button"
            >
              Save Changes
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default EventGuests;
