import React, { useEffect, useState } from "react";

// Icos

// Compos
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import TimeItem from "../../display/TeeTimes/TimeItem";
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { db } from "../../../firebase";

function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function EventAddGuest({ eventID = "", close = () => {} }) {
  useEffect(() => {}, []);

  // State
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [times, setTimes] = useState([]);
  const [selGroup, setSelGroup] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [handicap, setHandicap] = useState(18);
  const [gender, setGender] = useState("male");
  const [selectingGroup, setSelectingGroup] = useState(false);
  const [customGuestID, setCustomGuestID] = useState("");

  const [complete, setComplete] = useState(false);

  useEffect(() => {
    loadTeeTimes();
  }, []);

  async function loadTeeTimes() {
    const evtDoc = await db.collection("events").doc(eventID).get();
    const evt = evtDoc.data();
    const { roundIDs } = evt;
    let arr = [];

    for (let i = 0; i < roundIDs.length; i++) {
      const roundID = roundIDs[i];

      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const { teeTimes, leaderboard } = round;

      // Check types
      const firstGroup = teeTimes[0];
      const { players } = firstGroup;
      const firstPlayer = players[0];
      let convertTime = false;
      const firstTime = firstGroup.time;
      if (typeof firstTime === "string") {
      } else {
        convertTime = true;
      }

      if (convertTime) {
        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];
          const timeObj = new Date(group.time.toDate());
          const timeString = timeObj.toLocaleTimeString("en-us", {
            timeStyle: "short",
          });
          teeTimes[i].time = timeString;
        }
      }

      // Load details from leaderboard
      if (typeof firstPlayer === "string") {
        const playerMap = {};
        leaderboard.forEach((player) => (playerMap[player.playerID] = player));

        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];
          const { players } = group;
          for (let j = 0; j < players.length; j++) {
            const uid = players[j];
            teeTimes[i].players[j] = playerMap[uid].name;
          }
        }
        arr = teeTimes;
      } else {
        arr = teeTimes;
      }
    }
    console.log(arr);
    setTimes(arr);
    setLoading(false);
  }

  async function saveChanges() {
    console.log("trigged");
    setLoading(true);
    try {
      const guest = {
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        handicap,
        gender,
        guestID: `guest${makeID(10)}`,
        customGuestID,
      };
      await db.collection("triggers").add({
        type: "evt-add-guest",
        guest,
        dateAdded: new Date(),
        eventID,
        group: selGroup,
      });
      setComplete(true);
      setLoading(false);
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <Dialog open onClose={close} className="mpa-dia eag-dia">
      {loading && <BackdropLoader />}
      <div className="evt-add-guest">
        <PageHeader
          showClose
          close={close}
          text={complete ? "Successfully Added" : "Add Guest"}
        />
        {!complete && (
          <div className="im-msg">
            {selectingGroup ? (
              <p>Select a group for {firstName} to join</p>
            ) : (
              <p>Select a play to add to this event</p>
            )}
          </div>
        )}
        {complete && (
          <div className="im-msg mb-40">
            <p>
              {firstName} has been added to your event. Changes will reflect in
              a few moments
            </p>
          </div>
        )}
        {!selectingGroup && (
          <div className="ec-box">
            <div className="input-group">
              <p>First Name</p>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="default-input"
                placeholder="Start typing..."
              />
            </div>
            <div className="input-group">
              <p>Last Name</p>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="default-input"
                placeholder="Start typing..."
              />
            </div>
            <div className="input-group">
              <p>Custom Guest ID</p>
              <input
                type="text"
                value={customGuestID}
                onChange={(e) => setLastName(e.target.value)}
                className="default-input"
                placeholder="Start typing..."
              />
            </div>

            <div className="input-group">
              <p>Handicap</p>
              <input
                type="number"
                value={handicap}
                min={-10}
                max={54}
                onChange={(e) => setHandicap(e.target.value)}
                className="default-input"
                placeholder="Start typing..."
              />
            </div>
            <div className="input-group">
              <p>Gender</p>
              <div className="selector-group">
                <button
                  onClick={() => setGender("male")}
                  className={
                    gender === "male" ? "sg-left sg-active" : "sg-left"
                  }
                >
                  Male
                </button>
                <button
                  onClick={() => setGender("female")}
                  className={
                    gender === "female" ? "sg-left sg-active" : "sg-left"
                  }
                >
                  Female
                </button>
              </div>
            </div>
          </div>
        )}
        {selectingGroup && !complete && (
          <>
            <BackRow action={() => setSelectingGroup(false)} />

            <div className="apm-times">
              {times.map((time, index) => {
                const amt = time.players.filter(
                  (pl) => pl.name !== "Empty"
                ).length;
                return (
                  <TimeItem
                    unselectable={amt === 4}
                    selectable={amt < 4}
                    showBG={index === selGroup}
                    onClick={() => {
                      setSelGroup((current) => {
                        if (index === current) {
                          return null;
                        } else {
                          return index;
                        }
                      });
                    }}
                    tee={time.tee}
                    time={time.time}
                    key={`${index}time`}
                    players={time.players}
                  />
                );
              })}
            </div>
          </>
        )}
        {!selectingGroup && !complete && (
          <div className="flex-center mb-40 mt-20">
            <button
              onClick={() => setSelectingGroup(true)}
              disabled={firstName === "" || lastName === ""}
              className="default-button"
            >
              Select Group
            </button>
          </div>
        )}
        {selectingGroup && !complete && (
          <div className="flex-center mb-40 mt-20">
            <button
              onClick={saveChanges}
              disabled={!selGroup || loading}
              className="default-button"
            >
              Save
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default EventAddGuest;
