import React, { useEffect, useState, useCallback } from "react";
import "./EventSponsorManager.css";
import { db, storage, admin } from "../../../firebase";
// Icons
import Close from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";

// Components
import uuid from "react-uuid";
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import ClubSponsor from "../../../pages/Clubs/Sponsors/ClubSponsor";
import Pill from "../../Buttons/Pill";
import Compressor from "compressorjs";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

function EventSponsorManager({
  goBack = () => {},
  clubID = "",
  eventID = "",
  reload = () => {},
  selectedSponsors = [],
  teamEvent = false,
}) {
  // State
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [selected, setSelected] = useState(selectedSponsors);
  const [showNew, setShowNew] = useState(false);

  const [update, setUpdate] = useState(0);

  const [sponsorIDs, setSponsorIDs] = useState([]);

  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [link, setLink] = useState("");
  const [sponsorName, setSponsorName] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        setImage(res);
      },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  // useEffect(() => {
  //   console.log(selected);
  //   const mapped = selected.map((sponsor) => sponsor.sponsorID);
  //   console.log(mapped);
  //   setSponsorIDs(mapped);
  //   setUpdate((current) => current + 1);
  // }, [selected]);

  useEffect(() => {
    const mapped = selectedSponsors.map((spo) => spo.sponsorID);
    setSponsorIDs(mapped);
    loadSponsors();
  }, []);

  async function loadSponsors() {
    setLoading(true);
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    console.log(club.sponsors);
    setSponsors(club.sponsors);
    setLoading(false);
  }

  async function saveChanges() {
    console.log(selected);
    setLoading(true);
    const type = teamEvent ? "teamEvents" : "events";
    try {
      await db.collection(type).doc(eventID).update({
        sponsors: selected,
      });
      reload();
      toast.success("Sponsors were successfully saved", {
        icon: successIco,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function saveSponsor() {
    setLoading(true);
    setShowNew(false);

    const task = storage.ref().child(`${clubID}/${image.name}`).put(image);

    try {
      await task.then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then(async (url) => {
          const newSponsor = {
            imageLink: url,
            sponsorName,
            link,
            sponsorID: uuid(),
          };
          await db
            .collection("clubs")
            .doc(clubID)
            .update({
              sponsors: admin.firestore.FieldValue.arrayUnion(newSponsor),
            });
          await db
            .collection("events")
            .doc(eventID)
            .update({
              sponsors: admin.firestore.FieldValue.arrayUnion(newSponsor),
            });
          setSponsorName("");
          setImage("");
          setImageURL("");
          setLink("");
          setSponsors((current) => [...current, newSponsor]);
        });
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }

    setLoading(false);
  }

  return (
    <div className="event-sponsor-manager">
      <Dialog open={showNew} onClose={() => setShowNew(false)}>
        <div className="new-sponsor">
          <PageHeader
            text="New Sponsor"
            close={() => setShowNew(false)}
            showClose
          />
          <div className="ec-box">
            <div className="ig-group">
              <div className="input-group">
                <p>Sponsor Name</p>
                <input
                  type="text"
                  placeholder="Enter sponsor name..."
                  value={sponsorName}
                  onChange={(e) => setSponsorName(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="input-group">
                <p>Online Link</p>
                <input
                  type="url"
                  placeholder="Enter link..."
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  className="default-input"
                />
              </div>
            </div>

            <div className="input-group mb-20">
              <p>Sponsor Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imageURL} />
                      </div>
                    )}
                    {imageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center mb-40">
            <button
              onClick={saveSponsor}
              disabled={imageURL === "" || sponsorName === ""}
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
      ;
      <BackRow action={goBack} />
      <PageHeader text="Sponsor Manager" />
      <div className="ph-msg">
        <p>Select your sponsor(s) for this event</p>
      </div>
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {sponsors.length === 0 && (
        <div className="no-msg">
          <p>No sponsors to display</p>
        </div>
      )}
      <div className="quick-links">
        <Pill onClick={() => setShowNew(true)} text={"New Sponsor"} />
      </div>
      <div className="pl-20 mt-20 mb-20">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
      <div className="sub-header">
        <h5>Available</h5>
      </div>
      {sponsors.length > 0 && (
        <div className="cs-box flex-wrapper">
          {sponsors.map((sponsor, index) => (
            <ClubSponsor
              sponsor={sponsor}
              select
              hideIcons
              active={sponsorIDs.includes(sponsor.sponsorID)}
              selectAction={() => {
                setSelected((current) => {
                  if (sponsorIDs.includes(sponsor.sponsorID)) {
                    const filtered = current.filter(
                      (spo) => spo.sponsorID !== sponsor.sponsorID
                    );
                    console.log(filtered);
                    return filtered;
                  } else {
                    current.push(sponsor);
                    console.log(current);
                    return current;
                  }
                });
                setSponsorIDs((current) => {
                  if (current.includes(sponsor.sponsorID)) {
                    const filtered = current.filter(
                      (item) => item !== sponsor.sponsorID
                    );
                    return filtered;
                  } else {
                    current.push(sponsor.sponsorID);
                    return current;
                  }
                });
                setUpdate((current) => current + 1);
              }}
              // deleteAction={() => {
              //   setToDelete(sponsor);
              //   setShowDelete(true);
              // }}
              // editAction={() => {
              //   setEditSponsorName(sponsor.sponsorName);
              //   setEditImageURL(sponsor.imageLink);
              //   setToEdit(sponsor);
              //   setShowEdit(true);
              // }}
              key={sponsor.sponsorID}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default EventSponsorManager;
