import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Components
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import { Dialog } from "@mui/material";
import PageHeader from "../../components/display/Rows/PageHeader";
import { DeleteForever } from "@mui/icons-material";

function ContestInput({
  contestID = "",
  userID = "",
  userName = "",
  goBack = () => {},
  showBack = false,
}) {
  const { currentUser, userDetails } = useAuth();

  const [loading, setLoading] = useState(true);

  const [units, setUnits] = useState("");
  const [update, setUpdate] = useState(0);
  const [attempts, setAttempts] = useState([]);
  const [allowed, setAllowed] = useState(0);

  const [scoreInput, setScoreInput] = useState("");
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (currentUser) {
      load();
    }
  }, [currentUser]);

  async function load() {
    const contestDoc = await db.collection("contests").doc(contestID).get();
    const contest = contestDoc.data();

    const { leaderboard, units, playerEntries } = contest;

    setUnits(units);

    let row;

    if (userID !== "") {
      row = leaderboard[userID];
    } else {
      row = leaderboard[currentUser.uid];
    }

    let uid = currentUser.uid;
    if (userID !== "") {
      uid = userID;
    }

    const filt = playerEntries.filter((id) => id === uid);
    const additionals = filt.length;
    console.log(additionals);

    const attempts = parseInt(contest.attempts) + additionals * 3;
    setAllowed(attempts);

    const takenAttempts = row.attempts;
    setAttempts(takenAttempts);
    setLoading(false);
  }

  async function saveScore() {
    setLoading(true);
    const contestDoc = await db.collection("contests").doc(contestID).get();
    const contest = contestDoc.data();
    let inp = "";
    if (scoreInput !== "") {
      inp = parseInt(scoreInput);
    }

    const { leaderboard, started } = contest;

    const attemptMap = {
      playerID: userID === "" ? currentUser.uid : userID,
      dateAdded: new Date(),
    };

    let row;
    if (userID !== "") {
      row = leaderboard[userID];
    } else {
      row = leaderboard[currentUser.uid];
    }
    row.attempts = attempts;
    if (inp !== "") {
      row.attempts.push(inp);

      if (row.best === "" || inp < row.best) {
        row.best = inp;
      }
    }

    let best = "";

    for (let i = 0; i < row.attempts.length; i++) {
      const att = row.attempts[i];
      if (best === "" || att < best) {
        best = att;
      }
    }

    row.best = best;

    console.log(row);

    if (userID !== "") {
      leaderboard[userID] = row;
    } else {
      leaderboard[currentUser.uid] = row;
    }
    if (!started) {
      await contestDoc.ref.update({
        leaderboard,
      });
    } else {
      await contestDoc.ref.update({
        leaderboard,
        started: true,
        playerAttempts: admin.firestore.FieldValue.arrayUnion(attemptMap),
      });
    }

    setAttempts(row.attempts);
    setScoreInput("");
    setShowInput(false);
    setLoading(false);
    goBack();
  }

  return (
    <Dialog open onClose={() => goBack()} className="coi-dia">
      <div className="contest-input">
        {loading && <BackdropLoader />}

        {showBack && !showInput && (
          <PageHeader text="Score Input" showClose close={goBack} />
        )}

        {showInput ? (
          <>
            <div className="ts-header">
              <ArrowForwardIcon
                onClick={() => setShowInput(false)}
                className="ts-arrow"
              />
              <h2>Back</h2>
            </div>
            <div className="flex-center">
              <h2 className="new-header">Input Score</h2>
            </div>

            <div className="ci-input flex-col-center mt-20 mb-20">
              <p>Measurement: {units}</p>
              <input
                type="number"
                value={scoreInput}
                placeholder="Enter score here..."
                onChange={(e) => setScoreInput(e.target.value)}
                className="default-input"
              />
            </div>

            <div className="flex-center">
              <button
                onClick={saveScore}
                disabled={scoreInput === ""}
                className="new-default-button"
              >
                Submit
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="neh-box mt-20">
              <h2 className="ne-header">{userName}</h2>
            </div>
            <div className="flex-center">
              <h2 className="new-header">Remaining Attempts</h2>
            </div>

            <div className="flex-center mb-20">
              <h1>{allowed - attempts.length}</h1>
            </div>

            <div className="ci-attempts">
              {attempts.map((att, index) => (
                <div key={index} className="ci-attempt-box">
                  <div className="ci-attempt">
                    <p>{att}</p>
                  </div>
                  <DeleteForever
                    onClick={() => {
                      setAttempts((c) => {
                        c.splice(index, 1);
                        console.log(c);
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                    className="red-icon-bg icon-cursor"
                  />
                </div>
              ))}
            </div>

            {allowed - attempts.length !== 0 && (
              <>
                {" "}
                {false && (
                  <div className="flex-center">
                    <h2 className="new-header">Input Score</h2>
                  </div>
                )}
                <div className="ci-input flex-col-center mt-20 mb-20">
                  <p>Measurement: {units}</p>
                  <input
                    type="number"
                    value={scoreInput}
                    placeholder="Enter score here..."
                    onChange={(e) => setScoreInput(e.target.value)}
                    className="default-input"
                  />
                </div>
                <div className="flex-center mb-20 mt-20">
                  <button
                    onClick={saveScore}
                    // disabled={scoreInput === ""}
                    className="default-button"
                  >
                    Save
                  </button>
                </div>
              </>
            )}

            {allowed - attempts.length !== 0 && false && (
              <div className="flex-center">
                <button
                  onClick={() => setShowInput(true)}
                  className="default-button"
                >
                  Enter Score
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
}

export default ContestInput;
