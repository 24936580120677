import React, { useState, useEffect, useRef } from "react";
import "./Leagues.css";

// Admin
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import { useMenuTheme } from "../../contexts/MenuContext";
import { Link, useLocation } from "react-router-dom";

// Components
import CourseItem from "../../components/display/CourseItem/CourseItem.js";
import LeagueEdit from "../../components/Leagues/LeagueEdit/LeagueEdit";
import Pill from "../../components/Buttons/Pill";
import LeagueCreator from "../../components/Leagues/LeagueCreator/LeagueCreator";
// import League

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TeamLeagueHome from "../../components/ClubLeagues/TeamLeagueHome";

// Gradient Icons
const GradientAddIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className="cr-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function Leagues() {
  const location = useLocation();

  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showLeague, setShowLeague] = useState(false);
  const [league, setLeague] = useState();

  const { currentUser, portalSettings, userDetails } = useAuth();

  const clicked = useMenuTheme();

  const [accountType, setAccountType] = useState("");

  useEffect(() => {
    if (currentUser && portalSettings && userDetails) {
      loadLeagues();
      setAccountType(userDetails.accountType);
    }
  }, [currentUser, portalSettings, userDetails]);

  useEffect(() => {
    if (clicked.includes("leagues")) {
      setShowLeague(false);
    }
  }, [clicked]);

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      const { type, leagueID } = location.state;
      if (type === "league-home") {
        setLeague(
          <LeagueEdit leagueID={leagueID} goBack={() => setShowLeague(false)} />
        );
        setShowLeague(true);
        window.history.replaceState({}, document.title);
      }
    }
  }, []);

  async function loadLeagues() {
    let leagues;

    if (portalSettings.clubID && userDetails.accountType !== "society") {
      leagues = await db
        .collection("leagues")
        .where("admins", "array-contains", currentUser.uid)
        .where("clubID", "==", portalSettings.clubID)
        .get();
    } else if (userDetails.accountType === "admin") {
      leagues = await db
        .collection("leagues")
        // .where("admins", "array-contains", currentUser.uid)
        .get();
    } else {
      leagues = await db
        .collection("leagues")
        .where("admins", "array-contains", currentUser.uid)
        .get();
    }

    const teamLeagues = await db
      .collection("teamLeagues")
      .where("adminIDs", "array-contains", currentUser.uid)
      .get();

    const leagueArr = [];

    for (let i = 0; i < leagues.docs.length; i++) {
      const doc = leagues.docs[i];
      const leagueID = doc.id;
      const league = doc.data();
      if (league.deleted) {
        continue;
      }
      const {
        leagueName,
        imageLink,
        city,
        country,
        type,
        invitedIDs,
        playerIDs,
        description,
        joinCode,
        admins,
      } = league;
      leagueArr.push({
        label: leagueName,
        value: leagueID,
        imageLink,
        admins,
        leagueID,
        city,
        joinCode,
        country,
        description,
        type,
        invitedIDs,
        playerIDs,
      });
    }
    console.log(teamLeagues.docs.length);
    for (let i = 0; i < teamLeagues.docs.length; i++) {
      const leagueDoc = teamLeagues.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();
      league.leagueID = leagueID;
      league.matchplay = true;
      leagueArr.push(league);
    }

    setLeagues(leagueArr);
    setLoading(false);
  }

  return (
    <div className="leagues">
      {showLeague ? (
        league
      ) : (
        <>
          {loading && <BackdropLoader />}

          <div className="page-header">
            <h2>My Leagues</h2>
          </div>

          <div className="sub-header">
            <h3>Quick Links</h3>
          </div>

          {accountType !== "society" && (
            <div className="quick-links">
              <Pill
                text={"Create League"}
                onClick={() => {
                  setLeague(
                    <LeagueCreator
                      defaultClubID={
                        portalSettings.clubID ? portalSettings.clubID : ""
                      }
                      goBack={() => setShowLeague(false)}
                      reload={() => loadLeagues()}
                      edit={false}
                    />
                  );
                  setShowLeague(true);
                }}
              />
            </div>
          )}

          <div className="leagues-container">
            {leagues.map((league, index) => {
              console.log(league.matchplay);
              return (
                <Link
                  key={league.leagueID}
                  to={
                    league.matchplay
                      ? `/team-leagues/${league.leagueID}`
                      : `/leagues/${league.leagueID}`
                  }
                >
                  <CourseItem
                    city={league.city}
                    country={league.country}
                    courseName={league.label ?? league.leagueName}
                    hideStar
                    members={league.playerIDs.length}
                    leaderName={league.leaderName}
                    image={league.imageLink}
                    // showCourse={() => {
                    //   if (league.matchplay) {
                    //     setLeague(
                    //       <TeamLeagueHome
                    //         goBack={() => setShowLeague(false)}
                    //         leagueID={league.leagueID}
                    //       />
                    //     );
                    //     setShowLeague(true);
                    //   } else {
                    //     setLeague(
                    //       <LeagueEdit
                    //         goBack={() => setShowLeague(false)}
                    //         leagueID={league.leagueID}
                    //       />
                    //     );
                    //     setShowLeague(true);
                    //   }
                    // }}
                  />
                </Link>
              );
            })}
            {leagues.length === 0 && (
              <div className="no-msg">
                <p>No leagues to display</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Leagues;
