import React, { useState, useEffect } from "react";
import "./Contests.css";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import BackdropLoader from "../../components/Loaders/BackdropLoader";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBack from "@mui/icons-material/ArrowBack";

// Components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import PlayerSelector from "../../components/PlayerSelector/PlayerSelector";
import PageHeader from "../../components/display/Rows/PageHeader";

const unitOptions = [
  {
    value: "inches",
    label: "Inches",
  },
  {
    value: "feet",
    label: "Feet",
  },
  {
    value: "yards",
    label: "Yards",
  },
  {
    value: "meters",
    label: "Meters",
  },
];

function NewContest({
  goBack = () => {},
  reload = () => {},
  edit = false,
  defaultContestID = "",
}) {
  const { currentUser, userDetails } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [showSelector, setShowSelector] = useState(false);

  const [prizeType, setPrizeType] = useState("purse");

  const [adminOnly, setAdminOnly] = useState(false);
  const [joinCode, setJoinCode] = useState("");

  const [update, setUpdate] = useState(0);

  const [attempts, setAttempts] = useState(0);

  const [percs, setPercs] = useState([50, 30, 20]);

  const [started, setStarted] = useState(false);

  const [prizes, setPrizes] = useState([0, 0, 0]);
  const [name, setName] = useState("");
  const [units, setUnits] = useState("feet");
  const [entrance, setEntrance] = useState(0);
  const [error, setError] = useState("");

  const [generalOpen, setGeneralOpen] = useState(true);
  const [attendanceOpen, setAttendanceOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    if (userDetails && !edit) {
      loadFriends();
    }
    if (edit) {
      loadContest();
    }
  }, [userDetails]);

  async function loadFriends(playerIDs = []) {
    const { friends } = userDetails;
    const friendRefs = friends.map((uid) =>
      db.collection("users").doc(uid).get()
    );
    const friendDocs = await Promise.all(friendRefs);

    const arr = [];

    const selArr = [];

    for (let i = 0; i < friendDocs.length; i++) {
      const playerID = friends[i];
      const playerDoc = friendDocs[i];
      if (!playerDoc.exists) {
        continue;
      }
      const player = playerDoc.data();
      const playerName = `${player.firstName} ${player.lastName}`;
      const { handicap, profileImage, activeRoundID, status } = player;
      if (status === "disabled") {
        continue;
      }
      const city = player.city ?? "Cape Town";
      const country = player.country ?? "South Africa";

      const settings = player.settings ?? "";
      // If player accepts invites from friends onbly
      // if (settings !== '') {
      //     if (settings.friendsOnly === true && !friendIDs.includes(currentUser.uid)) {
      //         continue;
      //     }
      // }

      arr.push({
        name: playerName,
        handicap,
        profileImage,
        playerID,
        city,
        entrance: parseInt(entrance),
        country,
        lastName: player.lastName,
        group: 1,
        guest: false,
      });

      if (playerIDs.includes(playerID)) {
        selArr.push({
          name: playerName,
          handicap,
          profileImage,
          playerID,
          city,
          entrance: parseInt(entrance),
          country,
          lastName: player.lastName,
          group: 1,
          guest: false,
        });
      }
    }

    setSelectedPlayers(selArr);

    setPlayers(arr);
    setLoading(false);
  }

  async function loadContest() {
    const contestDoc = await db
      .collection("contests")
      .doc(defaultContestID)
      .get();
    const contest = contestDoc.data();

    const { playerIDs } = contest;

    loadFriends(playerIDs);

    setAdminOnly(contest.adminOnly);
    setAttempts(contest.attempts);
    setJoinCode(contest.joinCode);
    setName(contest.name);
    setPrizeType(contest.prizeType);
    setPercs(contest.percs);
    setPrizes(contest.prizes);
    setEntrance(contest.entrance);
    setUnits(contest.units);
    setStarted(contest.started);
  }

  function validate() {
    if (name === "") {
      setError("Please select a name");
      return false;
    }

    return true;
  }

  async function saveContest() {
    setLoading(true);

    if (validate() === false) {
      return setLoading(false);
    }

    const playerIDs = selectedPlayers.map((player) => player.playerID);
    playerIDs.push(currentUser.uid);

    const leaderboard = {};

    for (let i = 0; i < playerIDs.length; i++) {
      const playerID = playerIDs[i];
      leaderboard[playerID] = {
        attempts: [],
        best: "",
      };
    }

    try {
      await db.collection("contests").add({
        adminIDs: [currentUser.uid],
        entrance,
        playerIDs,
        joinCode,
        name,
        attempts,
        adminOnly,
        units,
        percs,
        prizes,
        prizeType,
        dateCreated: new Date(),
        leaderboard,
        started: false,
        playerAttempts: [],
        playerEntries: [],
        completed: false,
      });

      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered some technical difficulties there - please try again"
      );
      setLoading(false);
    }
  }

  async function saveChanges() {
    setLoading(true);

    if (validate() === false) {
      return setLoading(false);
    }

    const playerIDs = selectedPlayers.map((player) => player.playerID);
    playerIDs.push(currentUser.uid);

    const ctDoc = await db.collection("contests").doc(defaultContestID).get();
    const ctData = ctDoc.data();
    const leaderboard = ctData.leaderboard ?? {};

    for (let i = 0; i < playerIDs.length; i++) {
      const playerID = playerIDs[i];
      if (leaderboard[playerID] === undefined) {
        leaderboard[playerID] = {
          attempts: [],
          best: "",
        };
      }
    }

    try {
      if (started) {
        await db.collection("contests").doc(defaultContestID).update({
          joinCode,
          name,
          attempts,
          adminOnly,
          units,
          percs,
          prizes,
          prizeType,
          entrance,
        });
      } else {
        await db.collection("contests").doc(defaultContestID).update({
          playerIDs,
          joinCode,
          name,
          attempts,
          adminOnly,
          units,
          percs,
          prizes,
          prizeType,
          leaderboard,
          entrance,
        });
      }
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered some technical difficulties there - please try again"
      );
      setLoading(false);
    }
    setLoading(false);
  }

  async function finishContest() {
    setLoading(true);
    await db.collection("contests").doc(defaultContestID).update({
      completed: true,
    });
    reload();
    goBack();
  }

  return (
    <div className="new-contest">
      {loading && <BackdropLoader />}

      {showSelector ? (
        <PlayerSelector
          goBack={() => setShowSelector(false)}
          selPlayers={selectedPlayers}
          setPlayers={setSelectedPlayers}
          players={players}
          event
          courseDetails={{ teeArray: [""] }}
        />
      ) : (
        <>
          <div onClick={() => goBack()} className="back-row mb-20">
            <ArrowBack className="ts-arrow" />
            <p>Back</p>
          </div>

          <PageHeader text={edit ? "Edit Contest" : "New Contest"} />

          <div className="new-contest-inputt">
            <div className="sub-header">
              <h3 className="">General</h3>
            </div>
            <div className="ec-box">
              <div className="input-group">
                <p>Name</p>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="input-group">
                <p>Joining Code</p>
                <input
                  type="text"
                  placeholder="Joining Code"
                  value={joinCode}
                  onChange={(e) => setJoinCode(e.target.value)}
                  className="default-input"
                />
              </div>
              <div className="input-group">
                <p>Measurement</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  onChange={(e) => setUnits(e.value)}
                  value={units}
                  options={unitOptions}
                />
              </div>
            </div>

            <div className="ec-box">
              <div className="input-group">
                <p>Score Input</p>
                <div className="selector-group sg-small">
                  <button
                    onClick={() => setAdminOnly(true)}
                    className={adminOnly ? "sg-left sg-active" : "sg-left"}
                  >
                    Admin Only
                  </button>
                  <button
                    onClick={() => setAdminOnly(false)}
                    className={!adminOnly ? "sg-right sg-active" : "sg-right"}
                  >
                    Players
                  </button>
                </div>
              </div>
            </div>

            <div className="sub-header">
              <h3 className="ne-header">Prizes</h3>
            </div>

            <div className="ec-box">
              <div className="input-group">
                <p>Entrance Fee</p>
                <input
                  type="number"
                  placeholder="Entrance"
                  value={entrance}
                  onChange={(e) => setEntrance(e.target.value)}
                  className="default-input"
                />
              </div>

              <div className="input-group">
                <p>Attempts (Leave 0 for unlimited)</p>
                <input
                  type="number"
                  placeholder="Entrance"
                  value={attempts}
                  onChange={(e) => setAttempts(e.target.value)}
                  className="default-input"
                />
              </div>

              <div className="input-group">
                <p>Prize Type</p>
                <div className="selector-group sg-small">
                  <button
                    onClick={() => setPrizeType("purse")}
                    className={
                      prizeType === "purse" ? "sg-left sg-active" : "sg-left"
                    }
                  >
                    Purse
                  </button>
                  <button
                    onClick={() => setPrizeType("accum")}
                    className={
                      prizeType === "accum" ? "sg-right sg-active" : "sg-right"
                    }
                  >
                    Percentage
                  </button>
                </div>
              </div>
            </div>

            <div className="ec-box">
              {prizeType === "purse" && (
                <div className="input-group">
                  <div className="comp-prizes">
                    {prizes.map((prize, index) => (
                      <div className="comp-prize">
                        <p>{index + 1}.</p>
                        <input
                          className="default-input mb-20"
                          type={"number"}
                          value={prizes[index]}
                          style={{ width: "50vw" }}
                          onChange={(e) => {
                            setPrizes((current) => {
                              current[index] = e.target.value;
                              return current;
                            });
                            setUpdate((current) => current + 1);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {prizeType === "accum" && (
                <div className="input-group">
                  <div className="comp-prizes">
                    {percs.map((perc, index) => (
                      <div className="comp-prize">
                        <p>{index + 1}.</p>
                        <input
                          className="default-input mb-20"
                          type={"number"}
                          value={percs[index]}
                          style={{ width: "50vw" }}
                          onChange={(e) => {
                            setPercs((current) => {
                              current[index] = e.target.value;
                              return current;
                            });
                            setUpdate((current) => current + 1);
                          }}
                        />
                        <span className="mb-20 ml-20">%</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {selectedPlayers.length > 0 && (
              <div className="sub-header">
                <h3 className="ne-header">Players</h3>
              </div>
            )}

            <div className="new-contest-players pl-20 mt-20">
              {selectedPlayers.map((player, index) => {
                return <p>{player.name}</p>;
              })}
            </div>
            <div className="flex-center mt-20">
              <button
                onClick={() => setShowSelector(true)}
                className="default-button black-button"
              >
                Add Players
              </button>
            </div>
          </div>

          {error !== "" && (
            <div className="error-message-row">
              <p>{error}</p>
            </div>
          )}

          <div className={started ? "flex-center" : "flex-center mb-20"}>
            <button
              onClick={edit ? saveChanges : saveContest}
              className="default-button mt-20"
            >
              Save
            </button>
          </div>
          {started && (
            <div className="flex-center mb-20 mt-20">
              <button
                onClick={finishContest}
                className="default-button red-button"
              >
                Complete
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default NewContest;
