import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import {
  drillCategories,
  drillDifficulties,
  extractIdFromUrl,
  sortDrillsByDifficulty,
} from "../HelperFunctions";
import "./Drills.css";
import { toast } from "react-toastify";

// Icons
import { CheckIcon, DividerHorizontalIcon } from "@radix-ui/react-icons";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackdropLoader from "../../Loaders/BackdropLoader";
import DrillCreator from "./DrillCreator";
import { Tabs, Tab } from "@mui/material";
import DropMenu from "../DropMenu/DropMenu";
import DrillItem from "./DrillItem";
import ErrorModal from "../../ErrorModal/ErrorModal";
import * as Checkbox from "@radix-ui/react-checkbox";
import CustomDropdown from "../CustomDropdown";
import classNames from "classnames";
import Pill from "../../Buttons/Pill";
import DrillDetails from "./DrillDetails";
import { CheckCircle } from "@mui/icons-material";

function ClubDrills({ clubID = "" }) {
  // Admin
  const navigate = useNavigate();
  const dropts = [
    {
      label: "Actions",
      opts: ["Create Drill"],
    },
  ];

  const sortOpts = [
    { label: "Difficulty (Easy to Hard)", value: "difficultyUp", dir: "up" },
    { label: "Difficulty (Hard to Easy)", value: "difficultyDn", dir: "dn" },
    { label: "Popularity", value: "popularity", dir: "dn" },
  ];

  const successIco = (
    <>
      <CheckCircle className="toast-success-ico" />
    </>
  );

  // State
  const [loading, setLoading] = useState(true);
  const [presetDrills, setPresetDrills] = useState([]);
  const [acDrills, setAcDrills] = useState([]);
  const [acDrillIDs, setAcDrillIDs] = useState([]);
  const [createdDrills, setCreatedDrills] = useState([]);
  const [bcs, setBCs] = useState([]);
  // const [clubID, setClubID] = useState("");
  const [selPage, setSelPage] = useState("library");
  const [error, setError] = useState("");
  const [upd, setUpd] = useState(0);
  const [sortOpt, setSortOpt] = useState("");
  function update() {
    setUpd((c) => c + 1);
  }

  const [search, setSearch] = useState("");
  const [selDiffs, setSelDiffs] = useState(drillDifficulties);
  const [selCats, setSelCats] = useState(drillCategories);

  const [pageToShow, setPageToShow] = useState();
  const [showPage, setShowPage] = useState(false);
  const [hide, setHide] = useState(0);

  // UE
  useEffect(() => {
    loadClubDrills({ clubID });
    loadPresetDrills();
  }, []);

  // Funcs
  function sortArrs(sorter) {
    if (sorter === "difficultyUp") {
      setPresetDrills((c) => {
        const sorted = sortDrillsByDifficulty(c, "up");
        return sorted;
      });
    }
    if (sorter === "difficultyDn") {
      setPresetDrills((c) => {
        const sorted = sortDrillsByDifficulty(c, "dn");
        return sorted;
      });
    }
    if (sorter === "popularity") {
      setPresetDrills((c) => {
        c.sort((a, b) => b.attempts.length - a.attempts.length);
        return c;
      });
    }
  }

  async function loadPresetDrills() {
    const presetDrills = await db
      .collection("admin")
      .doc("academy")
      .collection("drills")
      .get();
    const arr = [];

    for (let i = 0; i < presetDrills.docs.length; i++) {
      const drillDoc = presetDrills.docs[i];
      const drill = drillDoc.data();
      arr.push(drill);
    }
    const sortedArr = sortDrillsByDifficulty(arr);
    setPresetDrills(sortedArr);
  }

  async function loadClubDrills({ clubID }) {
    console.log("LOAD DRILLS");
    setLoading(true);
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const { name } = club;
    sortBreadcrumbs({ acID: clubID, acTitle: name });

    const drillDocs = await clubDoc.ref
      .collection("drills")
      .orderBy("dateAdded", "desc")
      .get();

    const arr = [];
    const ids = [];
    const customArr = [];

    for (let i = 0; i < drillDocs.docs.length; i++) {
      const drillDoc = drillDocs.docs[i];
      const drill = drillDoc.data();

      const dateAddedObject = new Date(drill.dateAdded.toDate());
      const dateAddedString = dateAddedObject.toLocaleDateString("en-us", {
        dateStyle: "long",
      });
      drill.dateAddedObject = dateAddedObject;
      drill.dateAddedString = dateAddedString;

      ids.push(drill.drillID);

      arr.push(drill);

      if (drill.custom) {
        customArr.push(drill);
      }
    }

    setAcDrillIDs(ids);

    setCreatedDrills(customArr);
    setAcDrills(arr);
    setLoading(false);
  }

  function sortBreadcrumbs({ acTitle = "", acID = "" }) {
    const arr = [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Academies",
        to: "/academies",
      },
      {
        text: acTitle,
        to: `/academies/${acID}`,
      },
    ];
    setBCs(arr);
  }

  function handleDrop(a, b, c) {
    if (b === "Create Drill") {
      setPageToShow(
        <DrillCreator defaultClubID={clubID} back={() => setShowPage(false)} />
      );
      setShowPage(true);
    }
  }

  async function addToLibrary(drill) {
    setLoading(true);
    try {
      drill.attempts = [];
      drill.highScore = 0;
      await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drill.drillID)
        .set({
          ...drill,
          custom: false,
          dateAdded: new Date(),
        });
      setAcDrillIDs((c) => {
        c.push(drill.drillID);
        return c;
      });
      setAcDrills((c) => {
        c.push(drill);
        return c;
      });
      update();
      toast.success("Drill successfully added to library", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficult there - please try again."
      );
    }
    setLoading(false);
  }

  async function deleteDrill(drillID) {
    setLoading(true);
    try {
      await db
        .collection("clubs")
        .doc(clubID)
        .collection("drills")
        .doc(drillID)
        .delete();
      setAcDrills((c) => {
        const filt = c.filter((d) => d.drillID !== drillID);
        return filt;
      });
      setAcDrillIDs((c) => {
        const filt = c.filter((id) => id !== drillID);
        return filt;
      });
      setCreatedDrills((c) => {
        const filt = c.filter((d) => d.drillID !== drillID);
        return filt;
      });
      toast.success("Drill successfully deleted.", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficult there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div
      onClick={(e) => {
        if (e.target.classList) {
          const arr = Array.from(e.target.classList);
          const arrStr = arr.join("");
          if (arrStr !== "") {
            setHide((c) => c + 1);
          }
        }
      }}
      className="academy-drills sig-page"
    >
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {loading && <BackdropLoader />}
      {showPage ? (
        pageToShow
      ) : (
        <>
          {" "}
          {false && (
            <div className="flex flex-align-center">
              <PageHeader
                showBack={true}
                back={() => navigate(-1)}
                text="Drills"
              />
              {false && (
                <div className="cp-dr-bo">
                  <DropMenu
                    hide={hide}
                    opts={dropts}
                    handleClick={handleDrop}
                  />
                </div>
              )}
            </div>
          )}
          <div className="mt-20 flex-ac">
            <div className="dash-tab ml-20">
              <Tabs
                value={selPage}
                onChange={(e, v) => setSelPage(v)}
                className="shadow-md dt-tabs"
              >
                <Tab value={"library"} label={"Library"} />
                <Tab value={"created"} label={"Created"} />
                <Tab value={"presets"} label={"Presets"} />
              </Tabs>
            </div>
            <div className=" ml-40">
              <Pill
                text={"Create Drill"}
                onClick={() => {
                  setPageToShow(
                    <DrillCreator
                      defaultClubID={clubID}
                      back={() => setShowPage(false)}
                    />
                  );
                  setShowPage(true);
                }}
              />
            </div>
          </div>
          <div className="ac-dri-box pd-20">
            <div className="ad-filters flex wbg pd-20">
              <div className="input-group mr-20">
                <p>Search by title</p>
                <input
                  value={search}
                  className="default-input"
                  placeholder="Start typing..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="input-group mr-20">
                <p>Sort By</p>
                <CustomDropdown
                  opts={sortOpts}
                  value={sortOpt}
                  handleChange={(e) => {
                    setSortOpt(e.value);
                    console.log(e);
                    sortArrs(e.value);
                  }}
                />
              </div>
              {/* </div>
            <div className="ad-filters flex flex- wbg pd-20"> */}
              <div className="input-group">
                <p>Difficulties</p>
                <div className="flex flex-align-center flex-wrap ac-dr-ch mt-10">
                  {drillDifficulties.map((diff, i) => {
                    const checked = selDiffs.includes(diff);

                    return (
                      <div
                        className="cao-item "
                        onClick={() => {
                          setSelDiffs((c) => {
                            if (c.includes(diff)) {
                              const filt = c.filter((it) => it !== diff);
                              return filt;
                            } else {
                              c.push(diff);
                              return c;
                            }
                          });
                          update();
                        }}
                        key={diff}
                      >
                        <Checkbox.Root
                          className="CheckboxRoot"
                          checked={selDiffs.includes(diff)}
                          onCheckedChange={() => {
                            // setSelDiffs((c) => {
                            //   if (c.includes(diff)) {
                            //     const filt = c.filter((it) => it !== diff);
                            //     return filt;
                            //   } else {
                            //     c.push(diff);
                            //     return c;
                            //   }
                            // });
                            // update();
                          }}
                        >
                          <Checkbox.Indicator className="CheckboxIndicator">
                            {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                            {checked === true && <CheckIcon />}
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <p>{diff}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="input-group">
                <p>Categories</p>
                <div className="flex flex-align-center flex-wrap ac-dr-ch mt-10">
                  {drillCategories.map((cat, i) => {
                    const checked = selCats.includes(cat);

                    return (
                      <div
                        className="cao-item "
                        onClick={() => {
                          setSelCats((c) => {
                            if (c.includes(cat)) {
                              const filt = c.filter((it) => it !== cat);
                              console.log(filt);
                              return filt;
                            } else {
                              c.push(cat);
                              return c;
                            }
                          });
                          update();
                        }}
                        key={cat}
                      >
                        <Checkbox.Root
                          className="CheckboxRoot"
                          checked={selCats.includes(cat)}
                          onCheckedChange={() => {
                            // setSelCats((c) => {
                            //   if (c.includes(cat)) {
                            //     const filt = c.filter((it) => it !== cat);
                            //     console.log(filt);
                            //     return filt;
                            //   } else {
                            //     c.push(cat);
                            //     return c;
                            //   }
                            // });
                            // update();
                          }}
                        >
                          <Checkbox.Indicator className="CheckboxIndicator">
                            {/* {checked === "indeterminate" && <DividerHorizontalIcon />} */}

                            {checked === true && <CheckIcon />}
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <p>{cat}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={classNames("wbg drill-box flex flex-wrap pd-20")}>
              {selPage === "presets" &&
                presetDrills.map((drill, i) => {
                  const { title, category, difficulty } = drill;

                  if (search !== "") {
                    const sl = search.toLowerCase();
                    const tl = title.toLowerCase();

                    // console.log(category, selCats);

                    if (!tl.includes(sl)) {
                      return null;
                    }
                  }

                  if (!selCats.includes(category)) {
                    return null;
                  }

                  if (!selDiffs.includes(difficulty)) {
                    return null;
                  }

                  return (
                    <DrillItem
                      addAct={() => addToLibrary(drill)}
                      viewAct={() => {
                        setPageToShow(
                          <DrillDetails
                            clubID={clubID}
                            goBack={() => setShowPage(false)}
                            id={drill.drillID}
                          />
                        );
                        setShowPage(true);
                      }}
                      key={drill.drillID}
                      drill={drill}
                      showRemove={acDrillIDs.includes(drill.drillID)}
                      removeAct={() => {
                        deleteDrill(drill.drillID);
                      }}
                      showAdded={acDrillIDs.includes(drill.drillID)}
                      showAdd={!acDrillIDs.includes(drill.drillID)}
                      clubID={clubID}
                    />
                  );
                })}
              {selPage === "library" && acDrills.length === 0 && (
                <>
                  <div className="zer-reqs mt-0">
                    <p>
                      You haven't added any drills to your library yet. Header
                      over to the presets tab or create your own!
                    </p>
                  </div>
                </>
              )}
              {selPage === "library" &&
                acDrills.map((drill, i) => {
                  const { title, category, difficulty } = drill;

                  if (search !== "") {
                    const sl = search.toLowerCase();
                    const tl = title.toLowerCase();

                    // console.log(category, selCats);

                    if (!tl.includes(sl)) {
                      return null;
                    }
                  }

                  if (!selCats.includes(category)) {
                    return null;
                  }

                  if (!selDiffs.includes(difficulty)) {
                    return null;
                  }

                  return (
                    <DrillItem
                      // addAct={() => addToLibrary(drill)}
                      key={drill.drillID}
                      viewAct={() => {
                        setPageToShow(
                          <DrillDetails
                            clubID={clubID}
                            goBack={() => setShowPage(false)}
                            id={drill.drillID}
                          />
                        );
                        setShowPage(true);
                      }}
                      drill={drill}
                      custom={drill.custom}
                      showAdd={false}
                      showRemove
                      // showAdd={!acDrillIDs.includes(drill.drillID)}
                      clubID={clubID}
                      removeAct={() => deleteDrill(drill.drillID)}
                    />
                  );
                })}
              {selPage === "created" &&
                createdDrills.map((drill, i) => {
                  const { title, category, difficulty } = drill;

                  if (search !== "") {
                    const sl = search.toLowerCase();
                    const tl = title.toLowerCase();

                    // console.log(category, selCats);

                    if (!tl.includes(sl)) {
                      return null;
                    }
                  }

                  if (!selCats.includes(category)) {
                    return null;
                  }

                  if (!selDiffs.includes(difficulty)) {
                    return null;
                  }

                  return (
                    <DrillItem
                      // addAct={() => addToLibrary(drill)}
                      key={drill.drillID}
                      viewAct={() => {
                        setPageToShow(
                          <DrillDetails
                            clubID={clubID}
                            goBack={() => setShowPage(false)}
                            id={drill.drillID}
                          />
                        );
                        setShowPage(true);
                      }}
                      drill={drill}
                      showAdd={false}
                      custom
                      showRemove
                      editAct={() => {
                        setPageToShow(
                          <DrillCreator
                            back={() => setShowPage(false)}
                            // defaultclubID={clubID}
                            edit
                            defaultClubID={clubID}
                            defaults={drill}
                            reload={loadClubDrills}
                          />
                        );
                        setShowPage(true);
                      }}
                      removeAct={() => deleteDrill(drill.drillID)}
                      // showAdd={!acDrillIDs.includes(drill.drillID)}
                      clubID={clubID}
                    />
                  );
                })}
              {selPage === "created" && createdDrills.length === 0 && (
                <>
                  <div className="zer-reqs mt-0">
                    <p>You haven't created any drills yet.</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ClubDrills;
