import React from "react";

function EclecticHeader({ move = false }) {
  return (
    <div className="player-score log-header log-item stats-header">
      <div className="ps-left"></div>
      <div className="ps-right">
        <div className="ps-right-left">
          <div className="hcp-container"></div>
        </div>
        {/* <div className="ps-right-middle">
          <p>Pld</p>
        </div> */}
        <div className="ps-right-right">
          <p>Wins</p>
        </div>
        <div className="ps-right-right">
          <p>Net</p>
        </div>
        <div className="ps-right-right">
          <p>Gross</p>
        </div>
        <div className="ps-right-right">
          <p>Points</p>
        </div>
        {/* {move && (
          <div className="ps-right-right">
            <p>Mvmt</p>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default EclecticHeader;
