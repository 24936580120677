import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import "./EventHandicapEdit.css";

// Icons
import Close from "@mui/icons-material/Close";
import ArrowBack from "@mui/icons-material/ArrowBack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import { Dialog, Checkbox } from "@mui/material";
import { Oval } from "react-loader-spinner";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Switch from "react-ios-switch/lib/Switch";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { defaults } from "lodash";
import PageHeader from "../../display/Rows/PageHeader";

function EventHandicapEdit({
  hide = () => {},
  defaultPlayers = [],
  eventID = "",
  roundID = "",
  courseID = "",
  live = true,
  cupID = "",
  teeMap = {},
  defaultPreset = "",
  defaultSR = false,
  defaultPercentage = 90,
  teamAllowance = 100,
  defaultPlayer,
  teamEvt = false,
  rounds = [],
}) {
  useEffect(() => {
    // console.log(defaultPlayer);
  }, []);

  // State
  const [showEdit, setShowEdit] = useState(false);
  const [finished, setFinished] = useState(false);
  const [selected, setSelected] = useState({
    name: "",
    handicap: "",
    playerID: "",
    profileImage: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [teeArray, setTeeArray] = useState([]);
  const [parArray, setParArray] = useState([]);

  const [selPreset, setSelPreset] = useState(defaultPreset);

  const [percentage, setPercentage] = useState(defaultPercentage);

  const [loading, setLoading] = useState(false);

  const [selRds, setSelRds] = useState([]);

  const [slope, setSlope] = useState({});
  const [rating, setRating] = useState({});

  const [calc, setCalc] = useState(false);
  const [showCalc, setShowCalc] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const [changes, setChanges] = useState(defaultPlayers);

  const [storedPreset, setStoredPreset] = useState("");

  const [update, setUpdate] = useState(0);

  const [enableSR, setEnableSR] = useState(false);

  const [sr, setSR] = useState(defaultSR);

  const options = [
    "Original Handicaps",
    "% of Handicap",
    "Adjusted Handicaps",
    "Low HI",
    ...(enableSR ? ["Course Handicaps"] : []),
  ];

  async function checkCourse() {
    setLoading(true);
    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const { slope, ratings } = course;
    console.log(course);
    if (slope && ratings) {
      setSlope(slope);
      setRating(ratings);
      setEnableSR(true);
      setParArray(course.par);
      setTeeArray(course.teeArray);
    }
    setLoading(false);
  }

  useEffect(() => {
    console.log(courseID);
    if (courseID) {
      checkCourse();
    }

    setSelRds(rounds);
  }, []);

  useEffect(() => {
    if (defaultPlayer) {
      setSelected(defaultPlayer);
      setShowEdit(true);
    }
  }, []);

  async function saveTrigger() {
    // return console.log(selected, roundID);

    setLoading(true);
    setShowEdit(false);
    try {
      if (cupID) {
        await db.collection("triggers").add({
          dateAdded: new Date(),
          type: "cup-hcp-change",
          roundID,
          cupID,
          roundIDs: selRds,
          playerID: selected.playerID,
          handicap:
            typeof selected.handicap === "string"
              ? parseInt(selected.handicap)
              : selected.handicap,
        });
      } else {
        if (teamEvt) {
          await db.collection("triggers").add({
            dateAdded: new Date(),
            type: "team-evt-hcp",
            roundID,
            eventID,
            courseID,
            playerID: selected.playerID,
            roundIDs: selRds,
            handicap:
              typeof selected.handicap === "string"
                ? parseInt(selected.handicap)
                : selected.handicap,
          });
        } else {
          await db.collection("triggers").add({
            dateAdded: new Date(),
            type: "event-hcp-change",
            roundID,
            eventID,
            playerID: selected.playerID,
            handicap:
              typeof selected.handicap === "string"
                ? parseInt(selected.handicap)
                : selected.handicap,
            roundIDs: selRds,
          });
        }
        await db
          .collection("rounds")
          .doc(roundID)
          .collection("scores")
          .doc(selected.playerID)
          .update({
            handicapIndex:
              typeof selected.handicap === "string"
                ? parseInt(selected.handicap)
                : selected.handicap,
          });
      }

      setTimeout(() => {
        setFinished(true);
        setLoading(false);
      }, 1500);
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
      setShowEdit(true);
      setLoading(false);
    }
  }

  async function saveChanges() {
    setLoading(true);
    const eventDoc = await db
      .collection(teamEvt ? "teamEvents" : "events")
      .doc(eventID)
      .get();
    const event = eventDoc.data();
    const playerHandicaps = event.playerHandicaps ?? {};
    for (let i = 0; i < changes.length; i++) {
      const player = changes[i];
      if (player.changed) {
        playerHandicaps[player.playerID] = player.handicap;
      }
    }

    const rounds = event.rounds;

    for (let j = 0; j < rounds.length; j++) {
      if (rounds[j].playerHandicaps === undefined) {
        rounds[j].playerHandicaps = {};
      }

      for (let i = 0; i < changes.length; i++) {
        const player = changes[i];
        if (player.changed) {
          rounds[j].playerHandicaps[player.playerID] = player.handicap;
        }
      }
    }

    console.log(rounds);

    try {
      await eventDoc.ref.update({
        playerHandicaps,
        sr,
        percentage,
        presetType: storedPreset,
        rounds,
      });
      setFinished(true);
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function saveCupChanges() {
    setLoading(true);
    const cupDoc = await db.collection("cups").doc(cupID).get();
    const cup = cupDoc.data();
    const playerHandicaps = cup.playerHandicaps ?? {};
    for (let i = 0; i < changes.length; i++) {
      const player = changes[i];
      if (player.changed) {
        playerHandicaps[player.playerID] = player.handicap;
      }
    }

    try {
      await cupDoc.ref.update({
        playerHandicaps,
        sr,
      });
      setFinished(true);
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  function preset({ perc }) {
    setStoredPreset(selPreset);
    if (selPreset === "Original Handicaps") {
      setChanges((current) => {
        const temp = [...current];
        for (let i = 0; i < temp.length; i++) {
          // console.log(current[i]);
          temp[i].handicap = temp[i].originalHandicap;
          temp[i].changed = true;
        }
        return temp;
      });
      setUpdate((current) => current + 1);
    }

    if (selPreset === "Course Handicaps") {
      setChanges((current) => {
        for (let i = 0; i < current.length; i++) {
          const player = current[i];
          const tee = teeMap[player.playerID] ?? teeArray[0];
          // console.log(slope[(tee, rating[tee], parArray[tee])]);
          let sl = slope[tee];
          let ra = rating[tee];
          if (typeof sl === "string") {
            sl = parseFloat(sl);
          }
          if (typeof ra === "string") {
            ra = parseFloat(ra);
          }
          const par = parArray[tee];
          console.log(player.handicap, sl, ra, par);
          current[i].handicap = Math.round(
            (player.handicap * sl) / 113 + (ra - par)
          );
          current[i].changed = true;
        }
        return current;
      });
      setUpdate((current) => current + 1);
    }

    if (selPreset === "% of Handicap") {
      setChanges((current) => {
        for (let i = 0; i < current.length; i++) {
          current[i].handicap =
            Math.round(current[i].originalHandicap * perc * 10) / 10;
          current[i].changed = true;
        }
        return current;
      });
      setUpdate((current) => current + 1);
    }

    if (selPreset === "Adjusted Handicaps") {
      setChanges((current) => {
        for (let i = 0; i < current.length; i++) {
          current[i].handicap = Math.round(
            (current[i].originalHandicap + 4) * 0.8
          );
          current[i].changed = true;
        }
        return current;
      });
      setUpdate((current) => current + 1);
    }

    if (selPreset === "Low HI") {
      setChanges((c) => {
        for (let i = 0; i < c.length; i++) {
          c[i].handicap = c[i].lowHI ?? c[i].handicap;
        }
        return c;
      });
    }
  }

  function handleSR(bool) {
    if (bool) {
      setChanges((current) => {
        for (let i = 0; i < current.length; i++) {
          console.log(current[i]);
          current[i].handicap = current[i];
        }
        return current;
      });
    } else {
    }
  }

  return (
    <Dialog open onClose={hide} className="ehe-dia">
      {loading && <BackdropLoader />}
      <div
        className={live ? "event-handicap-edit" : "event-handicap-edit ehel"}
      >
        {showEdit && (
          <div
            onClick={() => {
              if (defaultPlayer) {
                hide();
              } else {
                setShowEdit(false);
              }
            }}
            className="back-row"
          >
            <ArrowBack />
            <p>Back</p>
          </div>
        )}

        <PageHeader
          showClose
          close={hide}
          text={finished ? "Succesfully changed" : "Edit Handicaps"}
        />
        <div className="im-msg">
          {finished ? (
            <p>Changes will reflect in a few moments</p>
          ) : defaultPlayer ? (
            <p>Choose a new handicap for {selected.name} </p>
          ) : live ? (
            <p>Select a player to edit their handicap for this event.</p>
          ) : (
            <p>
              Select a player to edit their <strong>playing handicap</strong>{" "}
              for this event.
            </p>
          )}
        </div>
        {loading ? (
          <div className="flex-center loading">
            <Oval
              color="#21c17c"
              secondaryColor="#ffffff"
              height={40}
              width={40}
            />
          </div>
        ) : finished ? (
          <></>
        ) : showEdit ? (
          <>
            <div className="input-group pl-20">
              <p>Handicap</p>
              <input
                type="number"
                value={selected.handicap}
                onChange={(e) => {
                  setSelected((current) => {
                    current.handicap = e.target.value;
                    current.teamHandicap = Math.round(
                      (e.target.value * teamAllowance) / 100
                    );
                    return current;
                  });
                  setUpdate((current) => current + 1);
                }}
                className="default-input"
              />
            </div>
            {teamAllowance !== 100 && (
              <div className="pl-20 mt-20 ltc-handicaps">
                <p>
                  {selected.firstName}'s <strong>new team handicap</strong> will
                  be <strong>{selected.teamHandicap}</strong>
                </p>
              </div>
            )}

            {rounds.length > 1 &&
              rounds.indexOf(roundID) !== rounds.length - 1 && (
                <>
                  <div className="eve-rds">
                    {rounds.map((rd, i) => {
                      const idx = rounds.indexOf(roundID);

                      if (idx > i) {
                        return null;
                      }

                      return (
                        <div key={`${i}ev`} className="eve-rd-item">
                          <Checkbox
                            checked={selRds.includes(rd)}
                            onChange={(e) => {
                              setSelRds((c) => {
                                if (c.includes(rd)) {
                                  const filt = c.filter((id) => rd !== id);
                                  return filt;
                                } else {
                                  c.push(rd);
                                  return c;
                                }
                              });
                              setUpdate((c) => c + 1);
                            }}
                          />
                          <p>Round {i + 1}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

            {errorMessage !== "" && (
              <div className="error-message-row">
                <p>{errorMessage}</p>
              </div>
            )}
            <div className="default-button-row">
              <button
                onClick={() => {
                  if (live) {
                    saveTrigger();
                  } else if (cupID !== "") {
                    saveCupChanges();
                  } else {
                    saveChanges();
                  }
                }}
                className="default-button"
              >
                Save Changes
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="ec-box">
              <div className="input-group si-search">
                <p>Search</p>
                <input
                  type="text"
                  placeholder="Search for player"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="default-input"
                />
              </div>
              {showCalc && (
                <div className="input-group mt-0 nassau-group mr-5v">
                  <p></p>
                  <Switch
                    onColor="#1e7a69"
                    checked={calc}
                    className={
                      calc ? "scoring-switch switch-on" : "scoring-switch"
                    }
                    onChange={() =>
                      setCalc((current) => {
                        return !current;
                      })
                    }
                  />
                </div>
              )}

              {!live && (
                <div className="input-group">
                  <p>Handicap Presets</p>

                  <div className="ig-row">
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      onChange={(e) => {
                        setSelPreset(e.value);
                      }}
                      value={selPreset}
                      options={options}
                    />
                    {selPreset === "% of Handicap" && (
                      <input
                        type={"number"}
                        className="default-input di-num ml-20"
                        value={percentage}
                        onChange={(e) => setPercentage(e.target.valueAsNumber)}
                      />
                    )}
                    <button
                      onClick={() => {
                        const perc = percentage / 100;
                        preset({ perc });
                      }}
                      disabled={selPreset === ""}
                      className="default-button"
                    >
                      Go
                    </button>
                  </div>
                </div>
              )}
            </div>

            {live && (
              <div className="ehe-players pl-20 pb-20">
                {defaultPlayers.map((player) => {
                  if (player.thru > 0 && cupID !== "") {
                    return null;
                  }

                  if (searchInput !== "") {
                    const sl = searchInput.toLowerCase();
                    if (player.name.toLowerCase().includes(sl)) {
                      return (
                        <PlayerItem
                          showProfile={() => {
                            player.teamHandicap = Math.round(
                              (player.handicap * teamAllowance) / 100
                            );
                            console.log(player.teamHandicap);
                            setSelected(player);
                            setShowEdit(true);
                          }}
                          hcp={player.handicap}
                          img={player.profileImage}
                          name={player.name}
                          playerID={player.playerID}
                          hideIcons
                          noLink
                          key={player.playerID}
                        />
                      );
                    } else {
                      return null;
                    }
                  }

                  return (
                    <PlayerItem
                      key={player.playerID}
                      hideIcons
                      img={player.profileImage}
                      noLink
                      name={player.name}
                      hcp={player.handicap}
                      showProfile={() => {
                        player.teamHandicap = Math.round(
                          (player.handicap * teamAllowance) / 100
                        );
                        console.log(player.teamHandicap);
                        setSelected(player);
                        setShowEdit(true);
                      }}
                    />
                  );
                })}
              </div>
            )}
            {!live && (
              <div className="default-button-row">
                <button
                  onClick={() => {
                    if (cupID !== "") {
                      saveCupChanges();
                    } else {
                      saveChanges();
                    }
                  }}
                  className="default-button"
                >
                  Save Changes
                </button>
              </div>
            )}

            {!live && enableSR && (
              <div className="input-group nassau-group pd-20">
                <div className="ig-col">
                  <p>Enable Course Rating & Slope</p>
                  <span>*Will be applied to final handicaps</span>
                </div>
                <Switch
                  onColor="#1e7a69"
                  checked={sr}
                  className={sr ? "scoring-switch switch-on" : "scoring-switch"}
                  onChange={() => {
                    setSR((current) => {
                      // handleSR(!current);
                      return !current;
                    });
                  }}
                />
              </div>
            )}

            {!live && (
              <div className="pl-20 pb-20 ehel-players">
                {changes.map((player, index) => {
                  if (player.thru > 0) {
                    return null;
                  }

                  if (searchInput !== "") {
                    const sl = searchInput.toLowerCase();
                    if (player.name.toLowerCase().includes(sl)) {
                      return (
                        <PlayerItem
                          showProfile={() => {}}
                          hcp={player.handicap}
                          img={player.profileImage}
                          name={player.name}
                          playerID={player.playerID}
                          hideIcons
                          key={player.playerID}
                          hcpEdit
                          hideHCP
                          noLink
                          changeHcp={(e) => {
                            setChanges((current) => {
                              current[index].handicap = e;
                              current[index].changed = true;
                              return current;
                            });
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  }

                  return (
                    <PlayerItem
                      key={player.playerID}
                      hideIcons
                      img={player.profileImage}
                      noLink
                      hcpEdit
                      hideHCP
                      name={player.name}
                      hcp={player.handicap}
                      showProfile={() => {}}
                      changeHcp={(e) => {
                        setChanges((current) => {
                          current[index].handicap = e;
                          current[index].changed = true;
                          return current;
                        });
                      }}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
}

export default EventHandicapEdit;
