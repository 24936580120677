import React, { useEffect } from "react";

function MatchplayHoleSlider({
  holes = [],
  teamOneColor = "#1e7a69",
  teamTwoColor = "#232c2e",
  idOne = "",
  idTwo = "",
  by = 0,
  thru = 0,
  mb = false,
}) {
  return (
    <div
      className="mp-hole-slider"
      style={{ marginBottom: mb ? "20px" : "0px" }}
    >
      <div className="mphs-box">
        {holes.map((hole, index) => {
          /* console.log(hole.winner); */

          let irr = false;
          const over = 18 - thru < by && thru !== 0;

          if (over) {
            const currentHole = index;
            if (18 - by < currentHole && index !== 17) {
              irr = true;
            }
          }
          // console.log(hole.winner === "", thru - 1, index);

          const tied =
            (hole.winner === "" && thru - 1 >= index) ||
            hole.winner === "tied" ||
            (over && irr);

          return (
            <div
              style={{
                backgroundColor: irr
                  ? ""
                  : hole.winner === idOne
                  ? teamOneColor
                  : hole.winner === idTwo
                  ? teamTwoColor
                  : "",
                borderColor: irr
                  ? ""
                  : hole.winner === idOne
                  ? teamOneColor
                  : hole.winner === idTwo
                  ? teamTwoColor
                  : "",
              }}
              key={index}
              className={irr ? "mphs-hole irr" : "mphs-hole"}
            >
              <p
                style={{
                  color: irr
                    ? "#232c2e"
                    : hole.winner !== "" && hole.winner !== "tied"
                    ? "white"
                    : "#232c2e",
                }}
              >
                {index + 1}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MatchplayHoleSlider;
