import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import "./Account.css";

// Icons

// Components
import BackdropLoader from "../Loaders/BackdropLoader";
import PageHeader from "../display/Rows/PageHeader";
import ErrorModal from "../ErrorModal/ErrorModal";
import { Tabs, Tab } from "@mui/material";
import AccountCosts from "./AccountCosts";
import FeaturePicker from "./FeaturePicker";
import SubscriptionCreator from "../PayFast/SubscriptionCreator";

function Account() {
  // Admin
  const { currentUser, portalSettings } = useAuth();
  const moduleOptions = [
    {
      label: "Events",
      value: "events",
    },
    {
      label: "Leagues",
      value: "leagues",
    },
    {
      label: "Matchplay Leaguues",
      value: "mpLeagues",
    },
    {
      label: "Matchplay Tournaments",
      value: "mpTournaments",
    },
    {
      label: "Cups",
      value: "cups",
    },
    {
      label: "Contests",
      value: "contests",
    },
  ];

  const addOnOptions = [
    {
      label: "User Uploads",
      value: "userUploads",
    },
    {
      label: "Handicap Uploads",
      value: "hcpUploads",
    },
  ];

  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selTab, setSelTab] = useState("details");

  const [memberLimit, setMemberLimit] = useState(0);
  const [eventLimit, setEventLimit] = useState(0);
  const [enabledModules, setEnabledModules] = useState(["events", "leagues"]);

  const [enabledAddOns, setEnabledAddOns] = useState([]);

  const [pastPayments, setPastPayments] = useState([]);

  const [currentFee, setCurrentFee] = useState(0);
  const [clubID, setClubID] = useState("");

  // UE
  useEffect(() => {
    if (currentUser) {
      loadDetails();
    }
  }, [currentUser]);

  useEffect(() => {
    if (portalSettings) {
      setClubID(portalSettings.clubID);
    }
  }, [portalSettings]);

  // Funcs
  async function loadDetails() {
    const userDoc = await db.collection("users").doc(currentUser.uid).get();
    const user = userDoc.data();
    if (user.accountSettings) {
      const { memberLimit, eventLimit, enabledModules, enabledAddOns } = user;
      setMemberLimit(memberLimit);
      setEventLimit(eventLimit);
      setEnabledAddOns(enabledAddOns);
      setEnabledModules(enabledModules);
    } else {
    }

    const userPaymentDocs = await userDoc.ref
      .collection("payments")
      .orderBy("date")
      .get();

    const arr = [];

    for (let i = 0; i < userPaymentDocs.docs.length; i++) {
      const upDoc = userPaymentDocs.docs[i];
      const upData = upDoc.data();

      const { date } = upData;
      const dateObject = new Date(date.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "long",
      });
      upData.docID = upDoc.id;
      upData.dateObject = dateObject;
      upData.dateString = dateString;

      arr.push(upData);
    }

    setPastPayments(arr);

    setLoading(false);
  }

  return (
    <div className="account-page">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {loading && <BackdropLoader />}
      {<PageHeader text="Account Settings" />}

      <div className="ec-tabs">
        <Tabs value={selTab} onChange={(e, v) => setSelTab(v)}>
          <Tab label={"Details"} value={"details"} />
          <Tab label={"Features"} value={"features"} />
          <Tab label={"Usage"} value={"charges"} />
          <Tab label={"Payments"} value={"payments"} />
        </Tabs>
      </div>

      {selTab === "details" && (
        <div className="acc-subs">
          <div className="sub-header">
            <h3>Account Details</h3>
          </div>

          <div className="sub-box"></div>
          <SubscriptionCreator />
        </div>
      )}
      {selTab === "charges" && <AccountCosts clubID={clubID} />}
      {selTab === "features" && <FeaturePicker clubID={clubID} />}
    </div>
  );
}

export default Account;
