import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./HandicapDivisions.css";
import { useAuth } from "../../../contexts/AuthContext";
import { isMobile } from "react-device-detect";
// Icons
import Close from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// Components
import BackRow from "../../display/Rows/BackRow";
import PageHeader from "../../display/Rows/PageHeader";
import Pill from "../../Buttons/Pill";
import { Dialog, Slider, Tooltip } from "@mui/material";
import BackdropLoader from "../../Loaders/BackdropLoader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { db } from "../../../firebase";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import DivisionUpload from "./DivisionUpload";

function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function HandicapDivisions({
  goBack = () => {},
  defaultDivisions = [],
  save = () => {},
  eventCreator = false,
  eventID = "",
  reload = () => {},
  toAdd = [],
  clubID = "",
  stdl = false,
}) {
  const { currentUser, userDetails } = useAuth();

  const formatOptions = [
    {
      label: "Medal",
      value: "medal",
    },
    {
      label: "Net Strokeplay",
      value: "net-strokeplay",
    },
    {
      label: "Stableford",
      value: "stableford",
    },

    // {
    //   value: "stable-medal",
    //   label: "Medal Stableford",
    // },
  ];
  const teamFormatOptions = [
    {
      label: "Medal",
      value: "medal",
    },
    {
      label: "Net Strokeplay",
      value: "net-strokeplay",
    },
    {
      label: "Stableford",
      value: "stableford",
    },

    // {
    //   value: "stable-medal",
    //   label: "Medal Stableford",
    // },
  ];

  function returnfmt(fmt) {
    if (fmt === "net-strokeplay") {
      return "Net Strokeplay";
    }
    if (fmt === "mod-stableford") {
      return "Modified Stableford";
    }
    if (fmt === "stable-medal") {
      return "Medal Stableford";
    }
    return fmt;
  }

  const playerOptions = [
    {
      label: "Singles",
      value: "singles",
    },
    {
      label: "Teams",
      value: "teams",
    },
  ];

  const divOptions = [
    {
      label: "Handicap",
      value: "handicap",
    },
    // {
    //   label: "Age",
    //   value: "age",
    // },
    {
      label: "Custom",
      value: "custom",
    },
    {
      label: "Time",
      value: "time",
    },
    {
      label: "Gender",
      value: "gender",
    },
    // ...(stdl
    //   ? [
    //       {
    //         label: "League",
    //         value: "league",
    //       },
    //     ]
    //   : []),
  ];

  const teamDivOptions = [
    {
      label: "Custom",
      value: "custom",
    },
    {
      label: "Time",
      value: "time",
    },
    // {
    //   label: "Gender",
    //   value: "gender",
    // },
  ];

  const genderOpts = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
  ];

  const [divs, setDivs] = useState(defaultDivisions);
  const [showDialog, setShowDialog] = useState(false);
  const [showTeams, setShowTeams] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [hcpRange, setHcpRange] = useState([-5, 54]);

  const [edit, setEdit] = useState(false);
  const [toEdit, setToEdit] = useState();

  const [upd, setUpdate] = useState(0);
  function update() {
    setUpdate((current) => current + 1);
  }

  const [evtFormat, setEvtFormat] = useState("");

  const [showPlayers, setShowPlayers] = useState(false);

  const [playerType, setPlayerType] = useState("singles");

  const [adding, setAdding] = useState(0);

  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState();
  const [selGender, setSelGender] = useState("male");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [nameInput, setNameInput] = useState("");
  const [format, setFormat] = useState("");
  const [teamFormat, setTeamFormat] = useState("");
  const [divType, setDivType] = useState("");

  const [minPut, setMinPut] = useState(0);
  const [maxPut, setMaxPut] = useState(20);

  const [leagueOpts, setLeagueOpts] = useState([]);
  const [leagueMap, setLeagueMap] = useState({});

  const [selLeague, setSelLeague] = useState();
  const [teams, setTeams] = useState([]);
  const [startingTime, setStartingTime] = useState("");
  const [endingTime, setEndingTime] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currentUser) {
      loadLeagues();
    }
  }, [currentUser]);

  async function loadLeagues() {
    const leagueDocs = await db
      .collection("leagues")
      .where("admins", "array-contains", currentUser.uid)
      .get();
    const arr = [];
    const mep = {};

    for (let i = 0; i < leagueDocs.docs.length; i++) {
      const doc = leagueDocs.docs[i];
      const league = doc.data();
      const { leagueName } = league;
      const leagueID = doc.id;
      arr.push({
        value: leagueID,
        label: leagueName,
      });
      mep[leagueID] = leagueName;
    }

    setLeagueMap(mep);
    setLeagueOpts(arr);

    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    setEvtFormat(event.format);
    const teams = event.teams ?? [];
    setTeams(teams);

    setLoading(false);
  }

  async function saveDivision() {
    setShowDialog(false);
    const divisionName = nameInput;

    const leagueName = leagueMap[selLeague];

    const newDiv = {
      divisionName: divType === "league" ? leagueName : divisionName,
      leagueID: selLeague,
      format: playerType === "teams" ? evtFormat : format,
      divType,
      //   min: parseFloat(minPut),
      //   max: parseFloat(maxPut),
      min: parseFloat(minPut),
      max: parseFloat(maxPut),
      leaderboardID: divType === "league" ? selLeague : makeID(10),
      players: [],
      startingTime,
      endingTime,
      playerType,
      gender: selGender,
    };

    if (eventCreator) {
      save((current) => [...current, newDiv]);
      setDivs((current) => [...current, newDiv]);
      setNameInput("");
      setFormat("");
      setDivType("");
      setMinPut(0);
      setMaxPut(20);
    } else {
      setLoading(true);
      const divvies = [...divs];
      divvies.push(newDiv);
      try {
        await db.collection("events").doc(eventID).update({
          divisions: divvies,
        });
        setDivs((current) => [...current, newDiv]);
        reload();
      } catch (err) {
        console.log(err);
        setError(
          "Sorry - we encountered a technical difficulty there, please try again."
        );
      }
      setLoading(false);
    }
  }

  async function savePlayers() {
    setLoading(true);
    setShowPlayers(false);
    try {
      const temp = [...divs];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].leaderboardID === adding.leaderboardID) {
          temp[i].players = adding.players;
        }
      }
      console.log(temp);
      await db.collection("events").doc(eventID).update({
        divisions: temp,
      });
      reload();

      setAdding(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function editDivision() {
    if (eventCreator) {
      save((current) => {
        for (let i = 0; i < current.length; i++) {
          if (current[i].leaderboardID === toEdit.leaderboardID) {
            current[i].divisionName = nameInput;
            current[i].max = maxPut;
            current[i].min = minPut;
            current[i].divType = divType;
            current[i].format = format;
            current[i].playerType = playerType;
          }
        }
        return current;
      });
      setDivs((current) => {
        for (let i = 0; i < current.length; i++) {
          if (current[i].leaderboardID === toEdit.leaderboardID) {
            current[i].divisionName = nameInput;
            current[i].max = maxPut;
            current[i].min = minPut;
            current[i].divType = divType;
            current[i].format = format;
            current[i].playerType = playerType;
          }
        }
        return current;
      });
      setToEdit();
      setEdit(false);
      setNameInput("");
      setFormat("");
      setDivType("");
      setMinPut(0);
      setMaxPut(20);
    } else {
      setLoading(true);
      try {
        let temp = [...divs];
        console.log(toEdit);
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].leaderboardID === toEdit.leaderboardID) {
            temp[i].divisionName = nameInput;
            temp[i].max = maxPut;
            temp[i].min = minPut;
            temp[i].divType = divType;
            temp[i].format = playerType === "teams" ? evtFormat : format;
            temp[i].playerType = playerType;
          }
        }

        setDivs((current) => {
          console.log(current);
          for (let i = 0; i < current.length; i++) {
            if (current[i].leaderboardID === toEdit.leaderboardID) {
              current[i].divisionName = nameInput;
              current[i].max = maxPut;
              current[i].min = minPut;
              current[i].divType = divType;
              current[i].format = playerType === "teams" ? evtFormat : format;
              current[i].playerType = playerType;
            }
          }
          return current;
        });
        console.log(temp);
        await db.collection("events").doc(eventID).update({
          divisions: temp,
        });
        setToEdit();
        setEdit(false);
        setNameInput("");
        setFormat("");
        setDivType("");
        setMinPut(0);
        setMaxPut(20);
        reload();
        setShowDialog(false);
      } catch (err) {
        console.log(err);
        setError(
          "Sorry - we encountered a technical difficulty there, please try again."
        );
      }
    }
    setLoading(false);
  }

  async function deleteDivision() {
    setShowDelete(false);
    if (eventCreator) {
      setDivs((current) =>
        current.filter((div) => div.leaderboardID !== toDelete.leaderboardID)
      );
      save((current) =>
        current.filter((div) => div.leaderboardID !== toDelete.leaderboardID)
      );
      setToDelete();
    } else {
      setLoading(true);
      try {
        const filtered = divs.filter(
          (div) => div.leaderboardID !== toDelete.leaderboardID
        );
        await db.collection("events").doc(eventID).update({
          divisions: filtered,
        });
        setDivs((current) =>
          current.filter((div) => div.leaderboardID !== toDelete.leaderboardID)
        );
        setToDelete();
        reload();
        setLoading(false);
      } catch (err) {
        setError(
          "Sorry - we encountered a technical difficulty there, please try again."
        );
        setLoading(false);
      }
      setLoading(false);
    }
  }

  const [showUpload, setShowUpload] = useState(false);
  const [divID, setDivID] = useState("");

  return (
    <div className="handicap-divisions">
      {showUpload ? (
        <DivisionUpload
          clubID={clubID}
          reload={() => {
            reload();
            goBack();
          }}
          close={() => setShowUpload(false)}
          divisionID={divID}
          eventID={eventID}
        />
      ) : (
        <>
          {" "}
          {loading && <BackdropLoader />}
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <div className="new-division-modal">
              <PageHeader
                text={toEdit ? "Edit Division" : "New Division"}
                showClose
                close={() => setShowDialog(false)}
              />
              <div className="ec-box">
                <div className="input-group">
                  <p>Player Type</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={playerOptions}
                    value={playerType}
                    onChange={(e) => {
                      setPlayerType(e.value);
                      if (
                        divType === "handicap" ||
                        divType === "age" ||
                        divType === "league"
                      ) {
                        setDivType("custom");
                      }
                    }}
                  />
                </div>
                <div className="input-group">
                  <p>Division Type</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={
                      playerType === "teams" ? teamDivOptions : divOptions
                    }
                    value={divType}
                    onChange={(e) => {
                      setDivType(e.value);
                    }}
                  />
                </div>

                {playerType !== "teams" && (
                  <div className="input-group">
                    <p>Format</p>
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      options={formatOptions}
                      value={format}
                      onChange={(e) => {
                        setFormat(e.value);
                      }}
                    />
                  </div>
                )}

                {playerType === "teams" &&
                  currentUser &&
                  (currentUser.admin || currentUser.test) && (
                    <div className="input-group">
                      <p>Format</p>
                      <Dropdown
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        options={teamFormatOptions}
                        value={teamFormat}
                        onChange={(e) => {
                          setFormat(e.value);
                        }}
                      />
                    </div>
                  )}
              </div>
              <div className="ec-box">
                {divType !== "league" && (
                  <div className="input-group">
                    <p>Division Name</p>
                    <input
                      type="text"
                      value={nameInput}
                      placeholder="Enter name here..."
                      onChange={(e) => setNameInput(e.target.value)}
                      className="default-input"
                    />
                  </div>
                )}
                {divType === "league" && (
                  <div className="input-group">
                    <p>League</p>
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      options={leagueOpts}
                      value={selLeague}
                      onChange={(e) => {
                        setSelLeague(e.value);
                      }}
                    />
                  </div>
                )}
                {divType === "gender" && (
                  <div className="input-group">
                    <p>Gender</p>
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      options={genderOpts}
                      value={selGender}
                      onChange={(e) => {
                        setSelGender(e.value);
                      }}
                    />
                  </div>
                )}
                {divType === "time" && (
                  <>
                    <div className="input-group">
                      <p>Starting Time</p>
                      <input
                        type="time"
                        value={startingTime}
                        onChange={(e) => setStartingTime(e.target.value)}
                        className="default-input"
                      />
                    </div>
                    <div className="input-group">
                      <p>Ending Time</p>
                      <input
                        type="time"
                        value={endingTime}
                        onChange={(e) => setEndingTime(e.target.value)}
                        className="default-input"
                      />
                    </div>
                  </>
                )}
                {divType === "handicap" && (
                  <>
                    <div className="input-group">
                      <p>Min</p>
                      <input
                        type="number"
                        value={minPut}
                        onChange={(e) => setMinPut(e.target.value)}
                        className="default-input"
                      />
                    </div>
                    <div className="input-group">
                      <p>Max</p>
                      <input
                        type="number"
                        value={maxPut}
                        onChange={(e) => setMaxPut(e.target.value)}
                        className="default-input"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex-center mb-20">
                <button
                  onClick={edit ? editDivision : saveDivision}
                  disabled={
                    (nameInput === "" && divType !== "league") ||
                    (playerType !== "teams" && format === "") ||
                    divType === ""
                  }
                  className="default-button"
                >
                  Save
                </button>
              </div>
            </div>
          </Dialog>
          <Dialog open={showPlayers} onClose={() => setShowPlayers(false)}>
            <PageHeader
              close={() => setShowPlayers(false)}
              showClose
              text="Manage Players"
            />
            <div className="input-group pl-20">
              <p>Search for player</p>
              <input
                type="text"
                placeholder="Start typing..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="default-input"
              />
              <div className="div-filters"></div>
            </div>

            <div className="mpaf-cont">
              <div className="mpa-filters">
                <div className="mpaf-header">
                  <p>Handicap</p>
                </div>
                <div className="mpaf-slider">
                  <Slider
                    value={hcpRange}
                    min={-5}
                    step={0.1}
                    max={54}
                    disableSwap
                    valueLabelDisplay="on"
                    onChange={(e, newValue) => {
                      // console.log(newValue);
                      const filt = toAdd.filter(
                        (pl) =>
                          pl.handicap > newValue[0] && pl.handicap < newValue[1]
                      );
                      // console.log(filt);
                      setAdding((c) => {
                        c.players = filt;
                        return c;
                      });
                      setHcpRange(newValue);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="dv-players pd-20">
              {toAdd.map((player, index) => {
                let incl = false;

                const sl = searchInput.toLowerCase();
                let nl = player.name ? player.name : "";
                if (player.firstName && player.lastName) {
                  nl = `${player.firstName} ${player.lastName}`;
                }
                nl = nl.toLowerCase();
                if (!nl.includes(sl)) {
                  return null;
                }

                if (adding) {
                  const { players } = adding;
                  for (let i = 0; i < players.length; i++) {
                    if (players[i].playerID === player.playerID) {
                      incl = true;
                    }
                  }
                }

                return (
                  <PlayerItem
                    key={player.playerID ?? `${index}pl`}
                    hcp={player.handicap}
                    img={player.profileImage}
                    name={
                      player.name ?? `${player.firstName} ${player.lastName}`
                    }
                    noLink
                    hideIcons
                    showProfile={() => {
                      if (incl) {
                        setAdding((current) => {
                          current.players = current.players.filter(
                            (pl) => pl.playerID !== player.playerID
                          );
                          return current;
                        });
                      } else {
                        setAdding((current) => {
                          current.players.push(player);
                          return current;
                        });
                      }
                      update();
                    }}
                    showBg={incl}
                  />
                );
              })}
            </div>
            <div className="flex-center mb-20">
              <button onClick={savePlayers} className="default-button">
                Save Changes
              </button>
            </div>
          </Dialog>
          <Dialog
            open={showTeams}
            onClose={() => {
              setShowTeams(false);
            }}
          >
            <PageHeader
              close={() => {
                setShowTeams(false);
              }}
              showClose
              text={"Add Teams"}
            />
            <div className="input-group pl-20">
              <input
                type="text"
                placeholder="Start typing..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="default-input"
              />
            </div>
            <div className="dv-players pd-20">
              {teams.map((team, index) => {
                let incl = false;
                // console.log(team);
                const { teamID, players } = team;
                let name = team.name ?? "";
                if (name === "") {
                  for (let i = 0; i < players.length; i++) {
                    const player = players[i];
                    if (i === players.length - 1) {
                      name += player.name;
                    } else {
                      name += `${player.name} / `;
                    }
                  }
                }
                // console.log(adding);
                // const sl = searchInput.toLowerCase();
                // const nl = team.name.toLowerCase();
                // if (!nl.includes(sl)) {
                //   return null;
                // }

                // if (adding && adding.teamIDs) {
                //   if (adding.teamIDs.includes(teamID)) {
                //     return null;
                //   }
                // }
                // if (incl && adding) {
                //   return null;
                // }

                let sel = false;

                if (adding.players) {
                  for (let i = 0; i < adding.players.length; i++) {
                    if (adding.players[i].teamID === team.teamID) {
                      sel = true;
                    }
                  }
                }

                return (
                  <PlayerItem
                    key={team.teamID}
                    hideHCP
                    name={name}
                    noLink
                    hideIcons
                    showProfile={() => {
                      if (sel) {
                        setAdding((current) => {
                          current.players = current.players.filter(
                            (pl) => pl.teamID !== team.teamID
                          );
                          return current;
                        });
                      } else {
                        setAdding((current) => {
                          console.log(current);
                          current.players.push(team);
                          console.log(current);
                          return current;
                        });
                      }
                      update();
                    }}
                    showBg={sel}
                  />
                );
              })}
            </div>
            <div className="flex-center mb-20">
              <button
                onClick={() => {
                  savePlayers();
                  setShowTeams(false);
                }}
                className="default-button"
              >
                Save Changes
              </button>
            </div>
          </Dialog>
          <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
            <div className="cs-delete">
              <PageHeader
                text="Delete Division"
                close={() => setShowDelete(false)}
                showClose
              />
              <p>Are you sure you want to remove this division?</p>
              <div className="flex-center">
                <button onClick={deleteDivision} className="delete-button">
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>
          <BackRow action={goBack} />
          <PageHeader text="Leaderboard Divisions" />
          <div className="ph-msg">
            <p>Split your event into separate divisions (max 5)</p>
          </div>
          {divs.length < 6 && (
            <div className="quick-links mt-10">
              <Pill onClick={() => setShowDialog(true)} text={"Add Division"} />
            </div>
          )}
          {divs.length === 0 && (
            <div className="no-msg">
              <p>No items to display</p>
            </div>
          )}
          <div className="flex-wrapper pd-20">
            {divs.map((div, index) => {
              // console.log(div);
              return (
                <div key={index} className="ld-division">
                  <div className="ldv-left">
                    <h2>{div.divisionName}</h2>
                    <p>
                      {div.playerType !== "teams" && returnfmt(div.format)}
                      {div.playerType !== "teams" && ":"} {div.divType}{" "}
                      {div.divType !== "custom" &&
                        div.divType !== "league" &&
                        div.divType !== "time" &&
                        div.divType !== "gender" && (
                          <>
                            {div.min < 0 ? `+${-div.min}` : div.min} - {div.max}
                          </>
                        )}
                      {div.divType === "time" && (
                        <>
                          {div.startingTime} - {div.endingTime}
                        </>
                      )}
                    </p>
                  </div>
                  <div className="ldv-right">
                    <Tooltip title="Edit Division">
                      <EditIcon
                        className="dg-icon-bg icon-cursor mb-10"
                        // onClick={editAction}
                        onClick={() => {
                          setToEdit(div);
                          setNameInput(div.divisionName);
                          setFormat(div.format);
                          setDivType(div.divType);
                          setPlayerType(div.playerType);
                          setMinPut(div.min);
                          setMaxPut(div.max);
                          setStartingTime(div.startingTime ?? "");
                          setEndingTime(div.endingTime ?? "");
                          setEdit(true);
                          setShowDialog(true);
                        }}
                      />
                    </Tooltip>
                    {div.divType === "custom" && (
                      <Tooltip title="Manage Players">
                        <PersonAddAlt1Icon
                          onClick={() => {
                            // setAdding(div);
                            if (div.playerType === "teams") {
                              setAdding(div);
                              setShowTeams(true);
                            } else {
                              setAdding(div);

                              setShowPlayers(true);
                            }
                          }}
                          className="dg-icon-bg icon-cursor mb-10"
                        />
                      </Tooltip>
                    )}
                    {div.divType === "custom" &&
                      !isMobile &&
                      div.playerType !== "teams" && (
                        <Tooltip title="Upload Players">
                          <FileUploadIcon
                            onClick={() => {
                              setDivID(div.leaderboardID);
                              setShowUpload(true);
                            }}
                            className="dg-icon-bg icon-cursor mb-10"
                          />
                        </Tooltip>
                      )}
                    <Tooltip title="Remove Division">
                      <DeleteForeverIcon
                        className="red-icon-bg icon-cursor"
                        onClick={() => {
                          setToDelete(div);
                          setShowDelete(true);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default HandicapDivisions;
