import React, { useState } from "react";
import "./Login.css";
import { useAuth } from "../../contexts/AuthContext";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
// import Oval

import { auth, db } from "../../firebase";

import Close from "@mui/icons-material/Close";

// Images
import logo from "../../assets/images/comwide.png";
function Login({ hide = () => {} }) {
  const { logIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [showForgot, setShowForgot] = useState(false);
  const [forgot, setForgot] = useState("");

  const [sent, setSent] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function passwordReset() {
    await db.collection("triggers").add({
      date: new Date(),
      email: forgot,
      type: "passwordReset",
    });

    setSent(true);
  }

  async function handleLogin() {
    setErrorMessage("");
    setLoading(true);
    const mail = email.trim();
    try {
      await auth.signInWithEmailAndPassword(mail, password);
      navigate("/");
      window.location.reload();

      hide();
    } catch (err) {
      console.log(err.code);
      if (err.code === "auth/user-not-found") {
        setErrorMessage("Sorry - this user does not exist");
      } else if (err.code === "auth/wrong-password") {
        setErrorMessage(
          "Incorrect email/password combination, please try again"
        );
      } else {
        setErrorMessage("Could not log you in, please try again");
      }
    }

    setLoading(false);
  }

  return (
    <Dialog open className="login-dia" onClose={() => hide()}>
      <div className="login">
        {loading && <BackdropLoader />}
        <div className="close-row">
          <Close onClick={hide} className="icon-cursor hbg-ico" />
        </div>
        <div className="login-image">
          <img alt="logo" src={logo} />
          {/* <h3>Management Portal</h3> */}
        </div>
        {showForgot ? (
          <>
            <div className="login-box">
              {!sent && <h5 className="">Recover your account</h5>}
              {!sent ? (
                <>
                  <div className="input-group">
                    <p>Email Address</p>
                    <input
                      type="email"
                      value={forgot}
                      onChange={(e) => setForgot(e.target.value)}
                      placeholder="Enter your email..."
                      className="default-input"
                    />
                  </div>
                  <div className="forgot-password mt-20">
                    <p onClick={() => setShowForgot(false)}>
                      Never mind, found it
                    </p>
                  </div>
                  <div className="default-button-row">
                    <button onClick={passwordReset} className="default-button">
                      Reset PAssword
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className="pw-reset mt-20">
                    Keep an eye out for an email from us - it may end up in the
                    spam folder.
                  </p>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="login-box">
            <h5>Login to your account</h5>
            <div className="input-group">
              <p>Email Address</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email..."
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Password</p>
              <input
                type="password"
                value={password}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="******"
                className="default-input"
              />
            </div>
            <div className="forgot-password mt-20">
              <p onClick={() => setShowForgot(true)}>Forgot your password?</p>
            </div>
            {errorMessage !== "" && (
              <div className="error-message-row">
                <p>{errorMessage}</p>
              </div>
            )}
            <div className="default-button-row">
              <button
                onClick={() => {
                  setLoading(true);
                  handleLogin();
                }}
                className="default-button"
              >
                Login
              </button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default Login;
