import React from "react";
import "./RoundItem.css";

// Icons

// Components

function RoundItem({ round = {}, clickAct = () => {}, active = false }) {
  return (
    <div
      onClick={clickAct}
      className={active ? "round-item ri-act" : "round-item"}
    >
      <div className="ri-left">
        <h2>{round.dateString}</h2>
        <h3>{round.courseName}</h3>
      </div>
      <div className="ri-right">
        <div className="rir-item">
          <p>Gross</p>
          <h4>{round.totalGrossStrokes ?? round.grossScore}</h4>
        </div>
        <div className="rir-item">
          <p>Net</p>
          <h4>{round.totalNetStrokes ?? round.netScore}</h4>
        </div>
        {round.totalPoints && (
          <div className="rir-item">
            <p>Points</p>
            <h4>
              {round.startingPoints
                ? round.totalPoints - round.startingPoints
                : round.totalPoints}
            </h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default RoundItem;
