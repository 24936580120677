import React, { useState } from "react";
import "./ImageSelector.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import event1 from "../../../assets/presets/events/1.jpg";
import event2 from "../../../assets/presets/events/2.jpg";
import event3 from "../../../assets/presets/events/3.jpg";
import event4 from "../../../assets/presets/events/4.jpg";
import event5 from "../../../assets/presets/events/5.jpg";
import event6 from "../../../assets/presets/events/6.jpg";
import event7 from "../../../assets/presets/events/7.jpg";
import event8 from "../../../assets/presets/events/8.jpg";
import event9 from "../../../assets/presets/events/9.jpg";
import event10 from "../../../assets/presets/events/10.jpg";
import event11 from "../../../assets/presets/events/11.jpg";
import event12 from "../../../assets/presets/events/12.jpg";
import event13 from "../../../assets/presets/events/13.jpg";
import event14 from "../../../assets/presets/events/14.jpg";
import event15 from "../../../assets/presets/events/15.jpg";
import event16 from "../../../assets/presets/events/16.jpg";
import event17 from "../../../assets/presets/events/17.jpg";
import event18 from "../../../assets/presets/events/18.jpg";
import event19 from "../../../assets/presets/events/19.jpg";
import event20 from "../../../assets/presets/events/20.jpg";
import event21 from "../../../assets/presets/events/21.jpg";
import event22 from "../../../assets/presets/events/22.jpg";
import event23 from "../../../assets/presets/events/23.jpg";
import event24 from "../../../assets/presets/events/24.jpg";

import league1 from "../../../assets/presets/leagues/1.png";
import league2 from "../../../assets/presets/leagues/2.png";
import league3 from "../../../assets/presets/leagues/3.png";
import league4 from "../../../assets/presets/leagues/4.png";
import league5 from "../../../assets/presets/leagues/5.png";
import league6 from "../../../assets/presets/leagues/6.png";
import league7 from "../../../assets/presets/leagues/7.png";
import league8 from "../../../assets/presets/leagues/8.png";
import league9 from "../../../assets/presets/leagues/9.png";
import league10 from "../../../assets/presets/leagues/10.png";
import league11 from "../../../assets/presets/leagues/11.png";
import league12 from "../../../assets/presets/leagues/12.png";

import trophy1 from "../../../assets/presets/trophies/1.png";
import trophy2 from "../../../assets/presets/trophies/2.png";
import trophy3 from "../../../assets/presets/trophies/3.png";
import trophy4 from "../../../assets/presets/trophies/4.png";
import trophy5 from "../../../assets/presets/trophies/5.png";
import trophy6 from "../../../assets/presets/trophies/6.png";
import trophy7 from "../../../assets/presets/trophies/7.png";
import trophy8 from "../../../assets/presets/trophies/8.png";
import trophy9 from "../../../assets/presets/trophies/9.png";
import trophy10 from "../../../assets/presets/trophies/10.png";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ImageSelector({
  selectedImage = "",
  setSelectedImage = () => {},
  goBack,
  type = "",
}) {
  const [sel, setSel] = useState(selectedImage);

  return (
    <div className="image-selector-comp">
      <div
        onClick={() => {
          if (sel !== selectedImage) {
            setSelectedImage(sel);
          }
          goBack();
        }}
        className="back-row"
      >
        <ArrowBackIcon className="ts-arrow" />
        <p>Back</p>
      </div>
      <div className="neh-box">
        <h2 className="ne-header">Select Image</h2>
      </div>

      <div className="is-boxes">
        {type === "trophy" && (
          <>
            <div
              onClick={() => setSel(trophy1)}
              className={sel === trophy1 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy1} alt="trophy1" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy2)}
              className={sel === trophy2 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy2} alt="trophy2" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy3)}
              className={sel === trophy3 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy3} alt="trophy3" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy4)}
              className={sel === trophy4 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy4} alt="trophy4" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy5)}
              className={sel === trophy5 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy5} alt="trophy5" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy6)}
              className={sel === trophy6 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy6} alt="trophy6" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy7)}
              className={sel === trophy7 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy7} alt="trophy7" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy8)}
              className={sel === trophy8 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy8} alt="trophy8" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(trophy9)}
              className={sel === trophy9 ? "is-image isi-active" : "is-image"}
            >
              <img src={trophy9} alt="trophy9" loading="lazy" />
            </div>
          </>
        )}
        {type === "league" && (
          <>
            <div
              onClick={() => setSel(league1)}
              className={sel === league1 ? "is-image isi-active" : "is-image"}
            >
              <img src={league1} alt="league1" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league2)}
              className={sel === league2 ? "is-image isi-active" : "is-image"}
            >
              <img src={league2} alt="league2" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league3)}
              className={sel === league3 ? "is-image isi-active" : "is-image"}
            >
              <img src={league3} alt="league3" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league4)}
              className={sel === league4 ? "is-image isi-active" : "is-image"}
            >
              <img src={league4} alt="league4" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league5)}
              className={sel === league5 ? "is-image isi-active" : "is-image"}
            >
              <img src={league5} alt="league5" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league6)}
              className={sel === league6 ? "is-image isi-active" : "is-image"}
            >
              <img src={league6} alt="league6" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league7)}
              className={sel === league7 ? "is-image isi-active" : "is-image"}
            >
              <img src={league7} alt="league7" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league8)}
              className={sel === league8 ? "is-image isi-active" : "is-image"}
            >
              <img src={league8} alt="league8" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league9)}
              className={sel === league9 ? "is-image isi-active" : "is-image"}
            >
              <img src={league9} alt="league9" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league10)}
              className={sel === league10 ? "is-image isi-active" : "is-image"}
            >
              <img src={league10} alt="league10" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league11)}
              className={sel === league11 ? "is-image isi-active" : "is-image"}
            >
              <img src={league11} alt="league11" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(league12)}
              className={sel === league12 ? "is-image isi-active" : "is-image"}
            >
              <img src={league12} alt="league12" loading="lazy" />
            </div>
          </>
        )}
        {type === "event" && (
          <>
            <div
              onClick={() => setSel(event1)}
              className={sel === event1 ? "is-image isi-active" : "is-image"}
            >
              <img src={event1} alt="event1" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event2)}
              className={sel === event2 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event2} alt="event2" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event3)}
              className={sel === event3 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event3} alt="event3" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event4)}
              className={sel === event4 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event4} alt="event4" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event5)}
              className={sel === event5 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event5} alt="event5" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event6)}
              className={sel === event6 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event6} alt="event6" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event7)}
              className={sel === event7 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event7} alt="event7" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event8)}
              className={sel === event8 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event8} alt="event8" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event9)}
              className={sel === event9 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event9} alt="event9" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event10)}
              className={sel === event10 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event10} alt="event9" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event11)}
              className={sel === event11 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event11} alt="event9" loading="lazy" />
            </div>
            <div
              onClick={() => setSel(event12)}
              className={sel === event12 ? "is-image isi-active" : "is-image"}
            >
              {" "}
              <img src={event12} alt="event9" loading="lazy" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ImageSelector;
