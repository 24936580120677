import React, { useEffect, useState } from "react";
import "./Clubs.css";
import { db, admin } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useHelperClickTheme } from "../../contexts/HelperContext";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

// Components
import { isMobile } from "react-device-detect";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useCookies } from "react-cookie";
import Pill from "../../components/Buttons/Pill";
import ClubTournaments from "./ClubTournaments";
import MatchPlayTournamentEditor from "../MatchplayTournaments/MatchPlayTournamentEditor";
import EventCreator from "../../components/Events/EventCreator/EventCreator";
import LeagueCreator from "../../components/Leagues/LeagueCreator/LeagueCreator";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import ClubCourse from "./ClubCourse";
import ClubEditor from "./ClubEditor";
import MessageBoard from "./MessageBoard/MessageBoard";
import ClubSponsors from "./Sponsors/ClubSponsors";
import EventRounds from "../../components/Events/EventCreator/EventRounds";
import InviteModal from "../../components/Events/InviteModal/InviteModal";
import EventHome from "../../components/Events/EventCreator/EventHome";
import FixturePreview from "../../components/display/FixturePreview/FixturePreview";
import ClubEvents from "./ClubEvents";
import ClubPlayers from "./ClubPlayers";
import { Dialog } from "@mui/material";
import PageHeader from "../../components/display/Rows/PageHeader";
import CourseItem from "../../components/display/CourseItem/CourseItem";
import LeagueEdit from "../../components/Leagues/LeagueEdit/LeagueEdit";
import { Oval } from "react-loader-spinner";
import ClubLeagues from "./ClubLeagues";
import ManualPlayerAdd from "../../components/Events/EventCreator/ManualPlayerAdd";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import ClubStats from "./ClubStats";
import TeamLeagueEditor from "../../components/ClubLeagues/TeamLeagueEditor";
import TeamLeagueEventHome from "../../components/ClubLeagues/TeamLeagueEventHome";
import BoardSettings from "./BoardSettings";
import Matchdays from "../../components/Matchdays/Matchdays";
import MatchdayHome from "../../components/Matchdays/MatchdayHome";
import TeamLeagueHome from "../../components/ClubLeagues/TeamLeagueHome";
import MenuSwitcher from "../../components/MenuSwitcher/MenuSwitcher";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const featuresProp = {
  events: true,
  leagues: true,
  proStats: false,
  playerUploads: true,
  hcpUploads: true,
  teamLeagues: true,
  matchplayTournaments: true,
  cups: true,
  contests: true,
};

function ClubHome({ clubID = "", goBack = () => {}, hideBack = false }) {
  const navigate = useNavigate();
  const showHelperClick = useHelperClickTheme();
  const { userDetails, portalSettings, clubFeatures } = useAuth();

  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [leagues, setLeagues] = useState([]);
  const [events, setEvents] = useState([]);

  const [error, setError] = useState("");
  const [tournaments, setTournaments] = useState([]);

  const [accountType, setAccountType] = useState("");

  const [modal, setModal] = useState();
  const [showModal, setShowModal] = useState(false);

  const [showID, setShowID] = useState(false);
  const [idType, setIDType] = useState("");

  const [courses, setCourses] = useState([]);

  const [friends, setFriends] = useState([]);
  const [playerMap, setPlayerMap] = useState({});

  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingLeagues, setLoadingLeagues] = useState(true);

  const [showCourseSelect, setShowCourseSelect] = useState(false);

  const [tournSelect, setTournSelect] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  const [features, setFeatures] = useState(featuresProp);

  const [selCourse, setSelCourse] = useState({
    courseID: "",
    name: "",
  });

  useEffect(() => {
    if (userDetails) {
      // console.log(userDetails.accountType);
      setAccountType(userDetails.accountType);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      loadDetails();
      loadLeagues();
      loadEvents();
    }
  }, [userDetails]);

  useEffect(() => {
    if (clubFeatures) {
      console.log(clubFeatures);
      setFeatures(clubFeatures);
    }
  }, []);

  async function loadFriends({ playerIDs = [] }) {
    let { friends } = userDetails;
    friends = friends.filter((playerID) => !playerIDs.includes(playerID));
    const links = friends.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );
    const docs = await Promise.all(links);

    const arr = [];

    const mep = {};

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const player = playerDoc.data();
      if (player === undefined) {
        continue;
      }
      const playerID = playerDoc.id;

      const { firstName, lastName, profileImage, handicap } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
      });
      mep[playerID] = {
        name: `${firstName} ${lastName}`,
        playerID,
        profileImage,
        handicap,
      };
    }

    setPlayerMap(mep);
    setFriends(arr);
  }

  async function loadDetails() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const { clubName, dateCreated, courseIDs, sponsors, memberIDs } = club;
    const courseRefs = courseIDs.map((courseID) =>
      db.collection("courses").doc(courseID).get()
    );

    const courseDocs = await Promise.all(courseRefs);
    const carr = [];

    // loadFriends({ playerIDs: memberIDs });

    for (let i = 0; i < courseDocs.length; i++) {
      const courseDoc = courseDocs[i];
      const courseID = courseDoc.id;
      const course = courseDoc.data();
      const { name, country, city, imageLink } = course;
      carr.push({
        name,
        country,
        city,
        imageLink,
        courseID,
      });
      setCourses(carr);
    }

    setDetails({
      clubName,
      sponsors,
    });
    setLoading(false);
  }

  async function loadEvents() {
    const now = new Date();

    const eventDocs = await db
      .collection("events")
      .where("clubID", "==", clubID)
      .where("eventDate", ">", now)
      .orderBy("eventDate", "asc")
      .limit(20)
      .get();

    const teamEventDocs = await db
      .collection("teamEvents")
      .where("clubID", "==", clubID)
      .where("eventDate", ">", now)
      .orderBy("eventDate")
      .limit(20)
      .get();

    const arr = [];

    for (let i = 0; i < eventDocs.docs.length; i++) {
      const eventDoc = eventDocs.docs[i];
      const event = eventDoc.data();
      const eventID = eventDoc.id;
      const dateObj = new Date(event.eventDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      event.eventID = eventID;
      event.dateObj = dateObj;
      event.dateString = dateString;
      arr.push(event);
    }

    for (let i = 0; i < teamEventDocs.docs.length; i++) {
      const eventDoc = teamEventDocs.docs[i];
      const event = eventDoc.data();
      event.teamEvent = true;
      const eventID = eventDoc.id;
      const dateObj = new Date(event.eventDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      event.eventID = eventID;
      event.dateObj = dateObj;
      event.dateString = dateString;
      arr.push(event);
    }

    arr.sort((a, b) => a.dateObj - b.dateObj);

    setEvents(arr);
    setLoadingEvents(false);
  }

  async function loadLeagues() {
    const leagueDocs = await db
      .collection("leagues")
      .where("clubID", "==", clubID)
      .get();
    const teamLeagueDocs = await db
      .collection("teamLeagues")
      .where("clubID", "==", clubID)
      .get();
    const arr = [];

    // console.log(leagueDocs.docs.length);

    for (let i = 0; i < leagueDocs.docs.length; i++) {
      const leagueDoc = leagueDocs.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();
      const { leagueName, playerIDs, city, country, imageLink } = league;
      arr.push({
        leagueID,
        leagueName,
        playerIDs,
        city,
        country,
        imageLink,
      });
    }

    for (let i = 0; i < teamLeagueDocs.docs.length; i++) {
      const leagueDoc = teamLeagueDocs.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();
      const { leagueName, playerIDs, city, country, imageLink } = league;
      arr.push({
        leagueID,
        leagueName,
        playerIDs,
        city,
        country,
        imageLink,
        team: true,
      });
    }

    arr.sort((a, b) => a.leagueName.localeCompare(b.leagueName));

    setLeagues(arr);
    setLoadingLeagues(false);
  }

  async function addMembers(playerIDs = []) {
    setLoading(true);
    try {
      await db
        .collection("clubs")
        .doc(clubID)
        .update({
          memberIDs: admin.firestore.FieldValue.arrayUnion(...playerIDs),
        });
      setFriends((current) => {
        const filtered = current.filter(
          (player) => !playerIDs.includes(player.playerID)
        );
        return filtered;
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }
    setLoading(false);
  }

  return (
    <div className="club-home">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {false && (
        <Helmet>
          <title>The Commissioner - {details.clubName}</title>
        </Helmet>
      )}
      <Dialog
        open={showCourseSelect}
        onClose={() => setShowCourseSelect(false)}
      >
        <div className="ch-cs pd-20">
          <div className="page-header">
            <h2>Course Selection</h2>
            <Close
              className="icon-cursor"
              onClick={() => setShowCourseSelect(false)}
            />
          </div>
          <div className="ph-msg">
            <p>Select a course for this event</p>
          </div>
          <div className="pd-20 flex-wrapper">
            {courses.map((course, index) => {
              return (
                <ClubCourse
                  select
                  setSelected={() => {
                    setSelCourse(course);
                  }}
                  course={course}
                  key={course.courseID}
                  hideRight
                  selected={selCourse.courseID === course.courseID}
                />
              );
            })}
          </div>
          <div className="flex-center mb-20">
            <button
              onClick={() => {
                if (tournSelect) {
                  setToShow(
                    <MatchPlayTournamentEditor
                      edit={false}
                      defaultClubID={clubID}
                      defaultCourseData={selCourse}
                      goBack={() => setShow(false)}
                    />
                  );
                } else {
                  setToShow(
                    <EventCreator
                      edit={false}
                      defaultClubID={clubID}
                      defaultCourseData={selCourse}
                      reload={loadEvents}
                      goBack={() => setShow(false)}
                    />
                  );
                }
                setShowCourseSelect(false);
                setTournSelect(false);
                setShow(true);
              }}
              className="default-button"
            >
              Select
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog onClose={() => setShowID(false)} open={showID}>
        <div className="ch-di-ctt pd-20">
          <div className="close-row">
            <Close onClick={() => setShowID(false)} className="grey-ico" />
          </div>
          <div className="svg-cont">
            <InfoIcon className="big-info-icon dg-icon" />
          </div>
          <div className="cdc-txt">
            {idType === "fixtures" && (
              <>
                <p>
                  View all your upcoming and past events in one place, whether
                  in the calendar or list view.
                </p>
              </>
            )}
            {idType === "leagues" && (
              <>
                <p>
                  Create either an Order of Merit for individuals or teams, an
                  Eclectic style league for individuals, or create a Matchplay
                  League with Fourball and Matchplay events between teams/clubs.
                </p>
              </>
            )}
            {idType === "mp" && (
              <>
                <p>
                  Matchplay Tournaments can be played as a bracket, round robin
                  or world cup style format - perfect for your annual matchplay
                  draw.
                </p>
              </>
            )}
            {idType === "players" && (
              <>
                <p>
                  Add, remove or edit your club members here, or view their
                  stats and data.
                </p>
              </>
            )}
            {idType === "ge" && (
              <>
                <p>
                  Edit your club details, communicate with your members, manage
                  your sponsors and more.
                </p>
              </>
            )}
          </div>
        </div>
      </Dialog>

      {loading && <BackdropLoader />}
      {showModal && modal}
      {show ? (
        toShow
      ) : (
        <>
          {!hideBack && (
            <div className="back-row" onClick={goBack}>
              <ArrowBack />
              <p>Back</p>
            </div>
          )}
          <div className="ph-me-opt">
            <PageHeader text={details.clubName} />
            {/* <div className="page-header">
              <h2>{details.clubName}</h2>
            </div> */}
            <MenuSwitcher />
          </div>

          {cookies["menu-type"] === "drop" && (
            <>
              <div className="flex flex-align-center mb-20 mt-10 ddmb-box">
                <div className="ddm-box">
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                      <button className="ddm-but">
                        <span>General</span>
                        <KeyboardArrowDown className="ddm-ico" />
                      </button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Portal>
                      <DropdownMenu.Content
                        className="DropdownMenuContent"
                        sideOffset={5}
                      >
                        <DropdownMenu.Item
                          onClick={() => {
                            setToShow(
                              <ClubEditor
                                clubID={clubID}
                                edit
                                goBack={() => {
                                  setShow(false);
                                  showHelperClick("home");
                                }}
                                reload={loadDetails}
                              />
                            );
                            setShow(true);
                            showHelperClick("club-edit");
                          }}
                          className="DropdownMenuItem"
                        >
                          Edit Details
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => {
                            // setToShow(
                            //   <MessageBoard
                            //     clubID={clubID}
                            //     goBack={() => setShow(false)}
                            //   />
                            // );
                            // setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          <NavLink to={`/message-board/${clubID}`}>
                            Message Board
                          </NavLink>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => {
                            // setToShow(
                            //   <ClubSponsors
                            //     reload={loadDetails}
                            //     defaultSponsors={details.sponsors}
                            //     clubID={clubID}
                            //     goBack={() => {
                            //       setShow(false);
                            //     }}
                            //   />
                            // );
                            // setShow(true);
                          }}
                          className="DropdownMenuItem"
                        >
                          <NavLink to={`/club-sponsors/${clubID}`}>
                            Manage Sponsors
                          </NavLink>
                        </DropdownMenu.Item>
                        {true && (
                          <DropdownMenu.Item
                            onClick={() => {
                              setToShow(
                                <BoardSettings
                                  clubID={clubID}
                                  goBack={() => {
                                    setShow(false);
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            className="DropdownMenuItem"
                          >
                            TV Board Settings{" "}
                          </DropdownMenu.Item>
                        )}
                        <DropdownMenu.Item
                          onClick={() => {
                            window.open(
                              `https://www.theugatour.com/clubs/${clubID}`
                            );
                          }}
                          className="DropdownMenuItem"
                        >
                          Public Page
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                </div>
                <div className="ddm-box">
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                      <button className="ddm-but">
                        <span>Players</span>
                        <KeyboardArrowDown className="ddm-ico" />
                      </button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Portal>
                      <DropdownMenu.Content
                        className="DropdownMenuContent"
                        sideOffset={5}
                      >
                        <DropdownMenu.Item
                          onClick={() => {}}
                          className="DropdownMenuItem"
                        >
                          {" "}
                          <NavLink to={`/members/${clubID}`}>
                            Manage Players
                          </NavLink>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => {}}
                          className="DropdownMenuItem"
                        >
                          <NavLink to={`/player-stats/${clubID}`}>
                            Player Stats
                          </NavLink>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                </div>
                {features.events && (
                  <div className="ddm-box">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Fixtures</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          <DropdownMenu.Item
                            onClick={() => {}}
                            className="DropdownMenuItem"
                          >
                            {" "}
                            <NavLink to={`/calendar`}>View Calendar</NavLink>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={() => {}}
                            className="DropdownMenuItem"
                          >
                            {" "}
                            <NavLink to={`/events/${clubID}`}>
                              View Events
                            </NavLink>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={() => {
                              if (courses.length === 0) {
                                setToShow(
                                  <EventCreator
                                    edit={false}
                                    defaultClubID={clubID}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              } else if (courses.length === 1) {
                                // console.log(courses[0].courseID);

                                setToShow(
                                  <EventCreator
                                    edit={false}
                                    defaultCourseData={courses[0]}
                                    // defaultCourseID={courses[0].courseID}
                                    defaultClubID={clubID}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              } else {
                                setShowCourseSelect(true);
                              }
                              showHelperClick("eve-crea");
                            }}
                            className="DropdownMenuItem"
                          >
                            New Event
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                )}
                {(features.leagues || features.teamLeagues) && (
                  <div className="ddm-box ">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Leagues</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          <DropdownMenu.Item
                            onClick={() => {}}
                            className="DropdownMenuItem"
                          >
                            <NavLink to={`/clubleagues/${clubID}`}>
                              View Leagues
                            </NavLink>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={() => {
                              setToShow(
                                <LeagueCreator
                                  defaultClubID={clubID}
                                  goBack={() => setShow(false)}
                                  reload={loadLeagues}
                                />
                              );
                              setShow(true);
                            }}
                            className="DropdownMenuItem"
                          >
                            New League
                          </DropdownMenu.Item>
                          {features.teamLeagues && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <TeamLeagueEditor
                                    defaultClubID={clubID}
                                    goBack={() => setShow(false)}
                                    reload={loadLeagues}
                                  />
                                );
                                setShow(true);
                                showHelperClick("league-crea");
                              }}
                              className="DropdownMenuItem"
                            >
                              New Matchplay League
                            </DropdownMenu.Item>
                          )}
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                )}
                {features.matchplayTournaments && (
                  <div className="ddm-box ">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Matchplay Tournaments</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          <DropdownMenu.Item
                            onClick={() => {}}
                            className="DropdownMenuItem"
                          >
                            <NavLink to={`/clubtournaments/${clubID}`}>
                              View Tournaments
                            </NavLink>
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={() => {
                              if (courses.length > 1) {
                                setTournSelect(true);

                                setShowCourseSelect(true);
                              } else if (courses.length === 1) {
                                setToShow(
                                  <MatchPlayTournamentEditor
                                    defaultClubID={clubID}
                                    defaultCourseID={""}
                                    defaultCourseData={courses[0]}
                                    edit={false}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              } else {
                                setToShow(
                                  <MatchPlayTournamentEditor
                                    defaultClubID={clubID}
                                    defaultCourseID={""}
                                    edit={false}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              }
                              showHelperClick("tour-crea");
                            }}
                            className="DropdownMenuItem"
                          >
                            New Tournament
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                )}
                {courses.length > 0 && (
                  <div className="ddm-box">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Stats</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          <DropdownMenu.Item
                            onClick={() => {
                              // setToShow(
                              //   <ClubStats
                              //     goBack={() => setShow(false)}
                              //     clubID={clubID}
                              //   />
                              // );
                              // setShow(true);
                            }}
                            className="DropdownMenuItem"
                          >
                            <NavLink to={`/club-stats/${clubID}`}>
                              Course Stats
                            </NavLink>
                          </DropdownMenu.Item>
                          {userDetails &&
                            (userDetails.test || userDetails.admin) && (
                              <DropdownMenu.Item
                                onClick={() => {
                                  // setToShow(
                                  //   <ClubStats
                                  //     goBack={() => setShow(false)}
                                  //     clubID={clubID}
                                  //   />
                                  // );
                                  // setShow(true);
                                }}
                                className="DropdownMenuItem"
                              >
                                <NavLink to={`/club-analytics/${clubID}`}>
                                  Club Analytics
                                </NavLink>
                              </DropdownMenu.Item>
                            )}
                          {((userDetails &&
                            (userDetails.test || userDetails.admin)) ||
                            features.proStats) && (
                            <DropdownMenu.Item
                              onClick={() => {
                                // setToShow(
                                //   <ClubStats
                                //     goBack={() => setShow(false)}
                                //     clubID={clubID}
                                //   />
                                // );
                                // setShow(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              <NavLink to={`/pl-stats-home/${clubID}`}>
                                Pro Stats
                              </NavLink>
                            </DropdownMenu.Item>
                          )}
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                )}
              </div>
            </>
          )}

          {cookies["menu-type"] !== "drop" && (
            <div className="ch-links">
              <div className="ch-link">
                <div className="sub-header">
                  <h5>General</h5>
                  <InfoIcon
                    onClick={() => {
                      setIDType("ge");
                      setShowID(true);
                    }}
                    className="dg-icon icon-cursor"
                  />
                </div>
                <div className="quick-links">
                  {(accountType === "club" || accountType === "admin") && (
                    <Pill
                      text={"Edit Details"}
                      onClick={() => {
                        setToShow(
                          <ClubEditor
                            clubID={clubID}
                            edit
                            goBack={() => {
                              setShow(false);
                              showHelperClick("home");
                            }}
                            reload={loadDetails}
                          />
                        );
                        setShow(true);
                        showHelperClick("club-edit");
                      }}
                    />
                  )}
                  <NavLink to={`/message-board/${clubID}`}>
                    <Pill
                      text={"Message Board"}
                      onClick={() => {
                        // setToShow(
                        //   <MessageBoard
                        //     clubID={clubID}
                        //     goBack={() => setShow(false)}
                        //   />
                        // );
                        // setShow(true);
                      }}
                    />
                  </NavLink>
                  <NavLink to={`/club-sponsors/${clubID}`}>
                    <Pill
                      text={"Manage Sponsors"}
                      onClick={() => {
                        // setToShow(
                        //   <ClubSponsors
                        //     reload={loadDetails}
                        //     defaultSponsors={details.sponsors}
                        //     clubID={clubID}
                        //     goBack={() => {
                        //       setShow(false);
                        //     }}
                        //   />
                        // );
                        // setShow(true);
                      }}
                    />
                  </NavLink>
                  {true && (
                    <Pill
                      text={"TV Board Settings"}
                      onClick={() => {
                        setToShow(
                          <BoardSettings
                            clubID={clubID}
                            goBack={() => {
                              setShow(false);
                            }}
                          />
                        );
                        setShow(true);
                      }}
                    />
                  )}
                  <Pill
                    text={"Public Page"}
                    onClick={() => {
                      window.open(`https://www.theugatour.com/clubs/${clubID}`);
                    }}
                  />
                </div>
              </div>
              <div className="ch-link">
                <div className="sub-header">
                  <h5>Players</h5>
                  <InfoIcon
                    onClick={() => {
                      setIDType("players");
                      setShowID(true);
                    }}
                    className="dg-icon icon-cursor"
                  />
                </div>
                <div className="quick-links">
                  <NavLink to={`/members/${clubID}`}>
                    {" "}
                    <Pill
                      text={"Manage Players"}
                      onClick={() => {
                        // navigate(`/members/${clubID}`);
                        // setToShow(
                        //   <ClubPlayers
                        //     clubID={clubID}
                        //     reload={() => loadDetails()}
                        //     goBack={() => setShow(false)}
                        //   />
                        // );
                        // setShow(true);
                      }}
                    />
                  </NavLink>
                  <NavLink to={`/player-stats/${clubID}`}>
                    {" "}
                    <Pill
                      text={"Player Stats"}
                      onClick={() => {
                        // navigate(`/members/${clubID}`);
                        // setToShow(
                        //   <ClubPlayers
                        //     clubID={clubID}
                        //     reload={() => loadDetails()}
                        //     goBack={() => setShow(false)}
                        //   />
                        // );
                        // setShow(true);
                      }}
                    />
                  </NavLink>

                  {/* <Pill
                  text={"Add Player(s)"}
                  onClick={() => {
                    setToShow(
                      <ManualPlayerAdd
                        hideFilters
                        hide={() => setShow(false)}
                        custom
                        players={friends}
                        customAction={(e) => {
                          addMembers(e);
                        }}
                        reload={loadDetails}
                      />
                    );
                    setShow(true);
                  }}
                /> */}
                </div>
              </div>
              {features.events && (
                <div className="ch-link">
                  <div className="sub-header">
                    <h5>Fixtures</h5>
                    <InfoIcon
                      onClick={() => {
                        setIDType("fixtures");
                        setShowID(true);
                      }}
                      className="dg-icon icon-cursor"
                    />
                  </div>
                  <div className="quick-links">
                    <NavLink to={`/calendar`}>
                      <Pill
                        text={"View Calendar"}
                        onClick={() => {
                          // navigate(`/events/${clubID}`);
                          // setToShow(
                          //   <ClubEvents
                          //     courses={courses}
                          //     clubID={clubID}
                          //     goBack={() => setShow(false)}
                          //   />
                          // );
                          // setShow(true);
                        }}
                      />
                    </NavLink>
                    <NavLink to={`/events/${clubID}`}>
                      <Pill
                        text={"View Events"}
                        onClick={() => {
                          // navigate(`/events/${clubID}`);
                          // setToShow(
                          //   <ClubEvents
                          //     courses={courses}
                          //     clubID={clubID}
                          //     goBack={() => setShow(false)}
                          //   />
                          // );
                          // setShow(true);
                        }}
                      />
                    </NavLink>
                    <Pill
                      text={"New Event"}
                      onClick={() => {
                        if (courses.length === 0) {
                          setToShow(
                            <EventCreator
                              edit={false}
                              defaultClubID={clubID}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        } else if (courses.length === 1) {
                          // console.log(courses[0].courseID);

                          setToShow(
                            <EventCreator
                              edit={false}
                              defaultCourseData={courses[0]}
                              // defaultCourseID={courses[0].courseID}
                              defaultClubID={clubID}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        } else {
                          setShowCourseSelect(true);
                        }
                        showHelperClick("eve-crea");
                      }}
                    />
                    {userDetails && userDetails.accountType === "admin" && (
                      <Pill
                        text={"Matchday"}
                        onClick={() => {
                          setToShow(
                            <MatchdayHome
                              clubID={clubID}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {(features.leagues || features.teamLeagues) && (
                <div className="ch-link">
                  <div className="sub-header">
                    <h5>Leagues</h5>
                    <InfoIcon
                      onClick={() => {
                        setIDType("leagues");
                        setShowID(true);
                      }}
                      className="dg-icon icon-cursor"
                    />
                  </div>

                  <div className="quick-links">
                    <NavLink to={`/clubleagues/${clubID}`}>
                      <Pill
                        text={"View Leagues"}
                        onClick={() => {
                          // navigate(`/clubleagues/${clubID}`);
                          // setToShow(
                          //   <ClubLeagues
                          //     courses={courses}
                          //     clubID={clubID}
                          //     goBack={() => setShow(false)}
                          //     reload={loadLeagues}
                          //   />
                          // );
                          // setShow(true);
                        }}
                      />
                    </NavLink>
                    <Pill
                      text={"New League"}
                      onClick={() => {
                        setToShow(
                          <LeagueCreator
                            defaultClubID={clubID}
                            goBack={() => setShow(false)}
                            reload={loadLeagues}
                          />
                        );
                        setShow(true);
                      }}
                    />
                    {features.teamLeagues && (
                      <Pill
                        text={"New Matchplay League"}
                        onClick={() => {
                          setToShow(
                            <TeamLeagueEditor
                              defaultClubID={clubID}
                              goBack={() => setShow(false)}
                              reload={loadLeagues}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {features.matchplayTournaments && (
                <div className="ch-link">
                  <div className="sub-header">
                    <h5>Matchplay Tournaments</h5>
                    <InfoIcon
                      onClick={() => {
                        setIDType("mp");
                        setShowID(true);
                      }}
                      className="dg-icon icon-cursor"
                    />
                  </div>
                  <div className="quick-links">
                    <NavLink to={`/clubtournaments/${clubID}`}>
                      {" "}
                      <Pill
                        text={"View Tournaments"}
                        onClick={() => {
                          // setToShow(
                          //   <ClubTournaments
                          //     goBack={() => setShow(false)}
                          //     clubID={clubID}
                          //   />
                          // );
                          // setShow(true);
                        }}
                      />
                    </NavLink>

                    <Pill
                      text="New Tournament"
                      onClick={() => {
                        if (courses.length > 1) {
                          setTournSelect(true);

                          setShowCourseSelect(true);
                        } else if (courses.length === 1) {
                          setToShow(
                            <MatchPlayTournamentEditor
                              defaultClubID={clubID}
                              defaultCourseID={""}
                              defaultCourseData={courses[0]}
                              edit={false}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        } else {
                          setToShow(
                            <MatchPlayTournamentEditor
                              defaultClubID={clubID}
                              defaultCourseID={""}
                              edit={false}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              {courses.length > 0 && (
                <div className="ch-link">
                  <div className="sub-header">
                    <h5>Stats</h5>
                  </div>
                  <div className="quick-links">
                    <NavLink to={`/club-stats/${clubID}`}>
                      <Pill
                        text={"Course Stats"}
                        onClick={() => {
                          // setToShow(
                          //   <ClubStats
                          //     goBack={() => setShow(false)}
                          //     clubID={clubID}
                          //   />
                          // );
                          // setShow(true);
                        }}
                      />
                    </NavLink>
                    {(features.proStats ||
                      (userDetails &&
                        (userDetails.admin || userDetails.test))) && (
                      <NavLink to={`/pl-stats-home/${clubID}`}>
                        <Pill
                          text={"Pro Stats"}
                          onClick={() => {
                            // setToShow(
                            //   <ClubStats
                            //     goBack={() => setShow(false)}
                            //     clubID={clubID}
                            //   />
                            // );
                            // setShow(true);
                          }}
                        />
                      </NavLink>
                    )}
                    {userDetails && (userDetails.test || userDetails.admin) && (
                      <NavLink to={`/club-analytics/${clubID}`}>
                        <Pill
                          text={"Club Analytics"}
                          onClick={() => {
                            // setToShow(
                            //   <ClubStats
                            //     goBack={() => setShow(false)}
                            //     clubID={clubID}
                            //   />
                            // );
                            // setShow(true);
                          }}
                        />
                      </NavLink>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {courses.length > 0 && (
            <>
              <div className="sub-header">
                <h5 className="msb">Courses</h5>
              </div>
              <div className="pd-20 flex-wrapper">
                {courses.map((course, index) => {
                  return (
                    <ClubCourse
                      course={course}
                      key={course.courseID}
                      hideRight
                    />
                  );
                })}
              </div>
            </>
          )}

          <div className="home-dash">
            <div className="hd-item">
              <PageHeader text="Upcoming Events" />
              {loadingEvents ? (
                <div className="he-loader">
                  <Oval
                    color="#1e7a69"
                    secondaryColor="#ffffff"
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <>
                  {events.length > 0 && (
                    <div className="scale-in-ps pb-20">
                      {events.map((event) => (
                        <FixturePreview
                          showLive={event.roundIDs.length > 0}
                          key={event.eventID}
                          event={event}
                          hideRounds={
                            event.teamEvent || event.roundIDs.length > 0
                          }
                          hidePerson={
                            event.teamEvent || event.roundIDs.length > 0
                          }
                          timeAction={() => {
                            setToShow(
                              <EventRounds
                                eventID={event.eventID}
                                goBack={() => setShow(false)}
                              />
                            );
                            setShow(true);
                          }}
                          editAction={() => {
                            if (event.teamEvent) {
                              setToShow(
                                <TeamLeagueEventHome
                                  eventID={event.eventID}
                                  goBack={() => setShow(false)}
                                />
                              );
                            } else {
                              setToShow(
                                <EventHome
                                  eventID={event.eventID}
                                  goBack={() => setShow(false)}
                                />
                              );
                            }

                            setShow(true);
                          }}
                          personAction={() => {
                            setModal(
                              <InviteModal
                                eventID={event.eventID}
                                hide={() => setShowModal(false)}
                              />
                            );
                            setShowModal(true);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {events.length === 0 && (
                    <div className="no-msg">
                      <p>No upcoming events</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="hd-item">
              <PageHeader text="Current Leagues" />
              {loadingLeagues ? (
                <div className="he-loader">
                  <Oval
                    color="#1e7a69"
                    secondaryColor="#ffffff"
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <>
                  {leagues.length > 0 && (
                    <div className="scale-in-ps ch-leagues pb-20">
                      {leagues.map((league, index) => (
                        <Link
                          key={league.leagueID}
                          to={
                            league.team
                              ? `/team-leagues/${league.leagueID}`
                              : `/leagues/${league.leagueID}`
                          }
                        >
                          <CourseItem
                            // key={league.leagueID}
                            city={league.city}
                            // showCourse={() => {
                            //   if (league.team) {
                            //     setToShow(
                            //       <TeamLeagueHome
                            //         goBack={() => setShow(false)}
                            //         leagueID={league.leagueID}
                            //       />
                            //     );
                            //   } else {
                            //     setToShow(
                            //       <LeagueEdit
                            //         defaultCourses={courses}
                            //         clubID={clubID}
                            //         leagueID={league.leagueID}
                            //         goBack={() => setShow(false)}
                            //       />
                            //     );
                            //   }
                            //   setShow(true);
                            // }}
                            country={league.country}
                            courseName={league.leagueName}
                            image={league.imageLink}
                            members={league.playerIDs.length}
                            hideStar
                          />
                        </Link>
                      ))}
                    </div>
                  )}
                  {leagues.length === 0 && (
                    <div className="no-msg">
                      <p>No league to display</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ClubHome;
