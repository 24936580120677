import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { Oval } from "react-loader-spinner";
import { Check } from "@mui/icons-material";
import classNames from "classnames";
// ICons

// Components

function SmallScoreInput({
  roundID = "",
  playerID = "",
  goBack = () => {},
  setLoad = () => {},
}) {
  // Admin

  // State
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [ring, setRing] = useState(2);
  const [details, setDetails] = useState({});
  const [holes, setHoles] = useState([]);
  const [scoreMode, setScoreMode] = useState("input");
  const [update, setUpdate] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(false);

  // UE
  useEffect(() => {
    loadHoles(playerID);
  }, []);

  // Funcs
  async function loadHoles(playerID) {
    db.collection("rounds")
      .doc(roundID)
      .collection("scores")
      .doc(playerID)
      .onSnapshot(async (roundDoc) => {
        setLoading(true);
        const round = roundDoc.data();
        const {
          holes,
          backNineFIR,
          backNineGIR,
          frontNineFIR,
          frontNineGIR,
          totalFIR,
          coursePar,
          totalGIR,
          frontNineGross,
          backNineGross,
          frontNineNet,
          backNineNet,
          backNinePar,
          frontNinePar,
          frontNinePutts,
          backNinePutts,
          totalPutts,
          totalGrossStrokes,
          totalNetStrokes,
          currentGrossScore,
          currentScore,
          totalPoints,
          totalModPoints,
          backNinePoints,
          frontNinePoints,
          format,
          startingScore,
          startingPoints,
        } = round;
        // console.log(playerID);
        // alert(round.courseID);

        // console.log(
        //   totalPoints,
        //   totalModPoints,
        //   currentGrossScore,
        //   currentScore
        // );

        const mainRoundDoc = await db.collection("rounds").doc(roundID).get();
        const mainRound = mainRoundDoc.data();
        const { ldEnabled, ctpEnabled, ldMap, ctpMap, scratchMode } = mainRound;
        const courseID = round.courseID ?? mainRound.courseID;
        setRing(mainRound.ring ?? 2);

        const courseDoc = await db.collection("courses").doc(courseID).get();
        const course = courseDoc.data();
        const { tees, teeArray, name } = course;
        const courseHoles = tees[teeArray[0]];
        for (let i = 0; i < courseHoles.length; i++) {
          const hole = courseHoles[i];
          holes[i].par = hole.par;
          holes[i].stroke = hole.stroke;
          holes[i].length = hole.length;
        }

        setDetails({
          scratchMode,
          backNineFIR,
          backNineGIR,
          frontNineFIR,
          frontNineGIR,
          totalFIR,
          totalGIR,
          frontNineGross,
          backNineGross,
          frontNineNet,
          backNineNet,
          backNinePar,
          coursePar,
          frontNinePar,
          frontNinePutts,
          backNinePutts,
          totalPutts,
          totalGrossStrokes,
          totalNetStrokes,
          format,
          courseName: name,
          backNinePoints,
          frontNinePoints,
          currentScore,
          currentGrossScore,
          totalPoints,
          totalModPoints,
          startingPoints,
          startingScore,
        });
        setHoles(holes);
        const newHoles = roundDoc.data().holes;
        // setSavedHoles(newHoles);
        console.log(holes);
        console.log("SET SH");
        setLoading(false);
      });
  }

  function handleGrossChange(e, hole) {
    // console.log(e.target.valueAsNumber, hole);
    // console.log("hurr");
    let score;
    if (scoreMode === "selector") {
      score = e;
    } else {
      score = e.target.valueAsNumber;
    }
    setHoles((current) => {
      const temp = [...current];
      const holeToChange = temp[hole];
      const strokesOnHole = holeToChange.strokes;
      const netScore = score === 0 ? 0 : score - strokesOnHole;
      const holePar = holeToChange.par;
      const puttsOnHole = holeToChange.putts;
      const toPar = score === 0 ? 0 : netScore - holePar;

      temp[hole].grossScore = score;
      temp[hole].netScore = netScore;
      temp[hole].toPar = toPar;

      // Team stuff
      if (temp[hole].teamStrokes !== undefined) {
        const holeChange = temp[hole];
        // console.log(holeChange);
        let teamStrokes = holeChange.teamStrokes;
        if (teamStrokes === undefined) {
          teamStrokes = holeChange.strokes;
        }

        // console.log(hole, teamStrokes);
        const teamNetPar = holeToChange.par + teamStrokes;
        const teamNetScore = score === 0 ? 0 : score - teamStrokes;
        const teamNetDiff = score === 0 ? 0 : score - teamNetPar;
        let teamPoints = 0;

        switch (teamNetDiff) {
          case teamNetDiff > 2:
            teamPoints = 0;
            break;
          case 2:
            teamPoints = 0;
            break;
          case 1:
            teamPoints = 1;
            break;
          case 0:
            teamPoints = 2;
            break;
          case -1:
            teamPoints = 3;
            break;
          case -2:
            teamPoints = 4;
            break;
          case teamNetDiff < -2:
            teamPoints = 5;
            break;
          default:
            break;
        }
        if (teamNetDiff < -2) {
          teamPoints = -(teamNetDiff - 2);
        }
        holes[hole].teamPoints = teamPoints;
        holes[hole].teamNet = teamNetScore;
        holes[hole].teamNetDiff = teamNetDiff;
      }

      if (temp[hole].putts === 0 && score !== 0) {
        temp[hole].putts = 2;
      }

      if (score === 0) {
        temp[hole].putts = 0;
      }

      if (score !== 0) {
        let points = 0;

        switch (toPar) {
          case toPar > 2:
            points = 0;
            break;
          case 2:
            points = 0;
            break;
          case 1:
            points = 1;
            break;
          case 0:
            points = 2;
            break;
          case -1:
            points = 3;
            break;
          case -2:
            points = 4;
            break;
          case toPar < -2:
            points = 5;
            break;
          default:
            break;
        }
        temp[hole].points = points;
      }

      // GIR
      if (score - puttsOnHole <= holePar - 2 && score !== 0) {
        temp[hole].gir = true;
      } else {
        temp[hole].gir = false;
      }
      totals(temp);
      return temp;
    });
    // setUpdate(current => current + 1);
  }

  function totals(arr) {
    let fnGross = 0;
    let bnGross = 0;
    let fnNet = 0;
    let bnNet = 0;
    let tg = 0;
    let tn = 0;
    let fgir = 0;
    let bgir = 0;
    let tgir = 0;
    let fStab = 0;
    let bStab = 0;
    let tStab = 0;

    for (let i = 0; i < arr.length; i++) {
      const hole = arr[i];
      const { netScore, grossScore, gir, points } = hole;
      if (i < 9) {
        fStab += points;
        fnGross += grossScore;
        fnNet += netScore;
        if (gir) {
          fgir += 1;
        }
      } else {
        bStab += points;
        bnGross += grossScore;
        bnNet += netScore;
        if (gir) {
          bgir += 1;
        }
      }

      if (gir) {
        tgir += 1;
      }

      tg += grossScore;
      tn += netScore;
      tStab += points;
    }

    if (isNaN(tg)) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }

    setDetails((current) => {
      current.frontNineGross = fnGross;
      current.backNineGross = bnGross;
      current.frontNineNet = fnNet;
      current.backNineNet = bnNet;
      current.totalGrossStrokes = tg;
      current.totalNetStrokes = tn;
      current.backNineGIR = bgir;
      current.frontNineGIR = fgir;
      current.totalGIR = tgir;
      current.totalPoints = tStab;
      current.backNinePoints = bStab;
      current.frontNinePoints = fStab;
      // console.log(current);
      return current;
    });

    setUpdate((current) => current + 1);
  }

  async function saveChanges() {
    // console.log(roundID);
    setLoading2(true);
    setLoad(true);
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    try {
      if (
        round.enableTeams ||
        round.bbLeaderboard ||
        round.betterballStableford
      ) {
        let { teamLeaderboard, teamType } = round;
        let stableScore = 0;
        if (teamType === undefined) {
          teamType = "scramble";
        }
        if (!round.newTeamboard) {
          for (let i = 0; i < teamLeaderboard.length; i++) {
            const team = teamLeaderboard[i];
            if (team.playerIDs.includes(playerID)) {
              for (let x = 0; x < 18; x++) {
                if (holes[x].grossScore !== 0) {
                  if (round.betterballStableford) {
                    teamLeaderboard[i].holes[x][playerID] = holes[x].toPar;
                  } else {
                    if (round.format === "medal") {
                      teamLeaderboard[i].holes[x][playerID] =
                        holes[x].grossScore - holes[x].par;
                    } else {
                      teamLeaderboard[i].holes[x][playerID] =
                        holes[x].teamNetDiff ?? holes[x].toPar;
                    }
                  }
                } else {
                  teamLeaderboard[i].holes[x][playerID] = "";
                }
                const holeScores = teamLeaderboard[i].holes[x];

                if (teamType === "two") {
                  let holeScoreArr = [];
                  for (const [key, object] of Object.entries(holeScores)) {
                    // console.log(`key is ${key}, obj is ${object}`);
                    if (key.includes("lowest") || key.includes("Lowest")) {
                      continue;
                    }
                    holeScoreArr.push({
                      playerID: key,
                      score: object,
                    });
                  }
                  holeScoreArr = holeScoreArr.sort((a, b) => {
                    if (a.score === "") {
                      return 1;
                    }
                    if (b.score === "") {
                      return -1;
                    }
                    return a.score - b.score;
                  });
                  teamLeaderboard[i].holes[x].lowest = holeScoreArr[0].score;
                  teamLeaderboard[i].holes[x].secondLowest =
                    holeScoreArr[1].score;
                }
                if (teamType === "bogey" || teamType === "bogey++") {
                  let holeScoreArr = [];
                  for (const [key, object] of Object.entries(holeScores)) {
                    if (key.includes("best")) {
                      continue;
                    }
                    holeScoreArr.push({
                      playerID: key,
                      score: object,
                    });
                  }
                  holeScoreArr = holeScoreArr.sort((a, b) => {
                    if (a.score === "") {
                      return 1;
                    }
                    if (b.score === "") {
                      return -1;
                    }
                    return a.score - b.score;
                  });
                  teamLeaderboard[i].holes[x].best = holeScoreArr[0].score;
                }
                if (teamType === "custom") {
                  const holeToChange = teamLeaderboard[i].holes[x];
                  let { toCount } = holeToChange;

                  const type = holeToChange.type ? holeToChange.type : "best";
                  let holeScore = 0;
                  let scoreArr = [];
                  for (let x = 0; x < team.playerIDs.length; x++) {
                    const id = team.playerIDs[x];
                    if (holeToChange[id] !== "") {
                      scoreArr.push(holeToChange[id]);
                    }
                  }

                  // Sort arr
                  if (type === "best" || type === "comb") {
                    scoreArr = scoreArr.sort((a, b) => a - b);
                  } else if (type === "worst") {
                    scoreArr = scoreArr.sort((a, b) => b - a);
                  }

                  // console.log(scoreArr);

                  if (type === "comb") {
                    if (scoreArr.length === 0) {
                      holeScore = "";
                    } else if (scoreArr.length === 1) {
                      holeScore += scoreArr[0];
                    } else {
                      holeScore += scoreArr[0];
                      holeScore += scoreArr[scoreArr.length - 1];
                    }
                  } else {
                    if (scoreArr.length < toCount) {
                      toCount = scoreArr.length;
                    }
                    if (scoreArr.length === 0) {
                      holeScore = "";
                    }

                    for (let x = 0; x < toCount; x++) {
                      holeScore += scoreArr[x];
                    }
                  }

                  teamLeaderboard[i].holes[x].score = holeScore;
                  teamLeaderboard[i].holes[x].scores = scoreArr;
                }
                if (teamType === "scramble") {
                  let best = "";

                  for (const [key, value] of Object.entries(
                    teamLeaderboard[i].holes[x]
                  )) {
                    if (key === "best" || value === "") {
                      continue;
                    }

                    if (best === "" || best > value) {
                      best = value;
                    }
                  }
                  stableScore += 2 - holes[x].best;

                  teamLeaderboard[i].holes[x].best = best;
                }
              }
            }
          }
          await roundDoc.ref.update({
            teamLeaderboard,
          });
        } else {
          alert("new board");
        }
      }
      if (round.jackpot) {
        const { jackpotLeaderboard, jackpotHoles } = round;
        for (let i = 0; i < jackpotLeaderboard.length; i++) {
          const row = jackpotLeaderboard[i];
          if (row.playerID !== playerID) {
            continue;
          }

          const { jackpotResults } = row;

          // jackpotResults[hole] = score;

          for (let x = 0; x < jackpotHoles.length; x++) {
            const hole = jackpotHoles[x];
            const scoreHole = holes[hole];
            const { toPar, points } = scoreHole;
            if (scoreHole.grossScore === 0) {
              jackpotResults[hole] = "";
            } else {
              if (round.format === "stableford") {
                jackpotResults[hole] = points;
              } else {
                jackpotResults[hole] = toPar;
              }
            }
          }

          let scr = 0;
          let jackthru = 0;

          for (const [key, value] of Object.entries(jackpotResults)) {
            if (value !== "") {
              jackthru += 1;
              scr += value;
            }
          }

          jackpotLeaderboard[i].score = scr;
          jackpotLeaderboard[i].jackpotResults = jackpotResults;
          jackpotLeaderboard[i].thru = jackthru;

          break;
        }
        if (round.format === "stableford") {
          jackpotLeaderboard.sort((a, b) => b.score - a.score);
        } else {
          jackpotLeaderboard.sort((a, b) => a.score - b.score);
        }

        let currentTies = 0;
        for (let i = 0; i < jackpotLeaderboard.length; i++) {
          let ranking = i + 1;
          const row = jackpotLeaderboard[i];
          if (i > 0) {
            if (jackpotLeaderboard[i - 1].score === row.score) {
              ranking = i - currentTies;
              currentTies += 1;
            } else {
              currentTies = 0;
            }
          }

          jackpotLeaderboard[i].rank = ranking;
        }

        await roundDoc.ref.update({
          jackpotLeaderboard,
        });
      }

      await db
        .collection("rounds")
        .doc(roundID)
        .collection("scores")
        .doc(playerID)
        .update({
          holes,
          input: true,
          track: false,
        });
      setTimeout(() => {
        setLoading2(false);
        setLoad(false);
        goBack();
      }, 1000);
      // alert("do/ne");
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again"
      );
      setLoading2(false);
      setLoad(false);
    }

    // loadHoles()
  }

  return (
    <div className="small-score-ip">
      {(loading || loading2) && (
        <div className="flex-center loading">
          <Oval
            color="#1e7a69"
            secondaryColor="#ffffff"
            height={20}
            width={20}
          />
        </div>
      )}
      {!loading && !loading2 && (
        <div className="ssi-holes">
          {holes.map((hole, i) => {
            return (
              <React.Fragment key={`${i}ssi`}>
                {i === 9 && (
                  <div className="ssi-hole ssi-tot">
                    <div className="ssi-top">
                      <p>Out</p>
                    </div>
                    <div className="ssi-bot ssi-bt">
                      <p>{details.frontNineGross}</p>
                    </div>
                  </div>
                )}

                <div className="ssi-hole">
                  <div className="ssi-top">
                    <p>
                      {i + 1} ({hole.par})
                    </p>
                  </div>
                  <div className="ssi-bot">
                    <input
                      type="number"
                      min={0}
                      max={
                        details.format === "medal"
                          ? 20
                          : hole.par + hole.strokes + ring
                      }
                      value={hole.grossScore}
                      className="default-input"
                      onChange={(e) => {
                        handleGrossChange(e, i);
                      }}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>
                {i === 17 && (
                  <div className="ssi-hole ssi-tot">
                    <div className="ssi-top">
                      <p>In</p>
                    </div>
                    <div className="ssi-bot ssi-bt">
                      <p>{details.backNineGross}</p>
                    </div>
                  </div>
                )}
                {i === 17 && (
                  <div className="ssi-hole ssi-tot">
                    <div className="ssi-top">
                      <p>Out</p>
                    </div>
                    <div className="ssi-bot ssi-bt">
                      <p>{details.frontNineGross}</p>
                    </div>
                  </div>
                )}
                {i === 17 && (
                  <div className="ssi-hole ssi-tot">
                    <div className="ssi-top">
                      <p>Tot</p>
                    </div>
                    <div className="ssi-bot ssi-bt">
                      <p>{details.totalGrossStrokes}</p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
          <div className="ssi-save">
            <Check
              onClick={() => saveChanges()}
              className={classNames("dg-icon-bg icon-cursor", {
                "ico-dis": disableSave,
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SmallScoreInput;
