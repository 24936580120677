import React, { useState, useEffect } from "react";
import { admin, db } from "../../../firebase";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import Close from "@mui/icons-material/Close";

// Components
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import ErrorModal from "../../ErrorModal/ErrorModal";
import PageHeader from "../../display/Rows/PageHeader";
import { Dialog } from "@mui/material";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Pill from "../../Buttons/Pill";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

function LiveDivisionManager({
  roundID = "",
  roundIDs = [],
  eventID = "",
  goBack = () => {},
  reload = () => {},
}) {
  const formatOptions = [
    {
      label: "Medal",
      value: "medal",
    },
    {
      label: "Net Strokeplay",
      value: "net-strokeplay",
    },
    {
      label: "Stableford",
      value: "stableford",
    },
    // {
    //   value: "stable-medal",
    //   label: "Medal Stableford",
    // },
  ];

  const playerOptions = [
    {
      label: "Singles",
      value: "singles",
    },
    {
      label: "Teams",
      value: "teams",
    },
  ];

  function calcTeamStablefordScore({
    holes = [],
    teamType = "",
    playerIDs = [],
    format = "",
  }) {
    let totalPoints = 0;
    if (format === "stableford") {
      if (teamType === "two") {
        // console.log(teamLeaderboard[2]);
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          const { lowest, secondLowest } = hole;
          if (lowest !== "") {
            // console.log(lowest, x);
            const lowestPoints = 2 - lowest;
            totalPoints += lowestPoints;
          }
          if (secondLowest !== "") {
            const secondLowestPoints = 2 - secondLowest;

            totalPoints += secondLowestPoints;
          }
        }
      }
      if (teamType === "scramble") {
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          const { best } = hole;
          if (best !== "") {
            const lowestPoints = 2 - best;
            totalPoints += lowestPoints;
          }
        }
      }

      if (teamType === "custom") {
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          const { score, toCount, scores } = hole;
          let counter = toCount;
          if (counter > scores.length) {
            counter = scores.length;
          }
          for (let g = 0; g < counter; g++) {
            const score = scores[g];
            if (score !== "") {
              const points = 2 - score;
              totalPoints += points;
            }
          }
        }
      }

      if (teamType === "combined") {
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          let holePoints = 0;

          for (let g = 0; g < playerIDs.length; g++) {
            const scr = hole[playerIDs[g]];
            if (scr !== "") {
              holePoints += 2 - scr;
            }
          }
          totalPoints += holePoints;
        }
      }
    }
    if (format === "mod-stableford") {
      if (teamType === "two") {
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          const { lowest, secondLowest } = hole;
          if (lowest !== "") {
            const lowestPoints = 2 - lowest;
            let scorePoints = 0;
            switch (lowest) {
              case lowest > 2:
                scorePoints = -5;
                break;
              case 2:
                scorePoints = -3;
                break;
              case 1:
                scorePoints = -1;
                break;
              case 0:
                break;
              case -1:
                scorePoints = 2;
                break;
              case -2:
                scorePoints = 5;
                break;
              case lowest < -2:
                scorePoints = 8;
                break;
              default:
                break;
            }
            totalPoints += scorePoints;
          }
          if (secondLowest !== "") {
            const secondLowestPoints = 2 - secondLowest;
            let scorePoints = 0;
            switch (secondLowest) {
              case secondLowest > 2:
                scorePoints = -5;
                break;
              case 2:
                scorePoints = -3;
                break;
              case 1:
                scorePoints = -1;
                break;
              case 0:
                break;
              case -1:
                scorePoints = 2;
                break;
              case -2:
                scorePoints = 5;
                break;
              case secondLowest < -2:
                scorePoints = 8;
                break;
              default:
                break;
            }
            totalPoints += scorePoints;
          }
        }
      }
      if (teamType === "scramble") {
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          const { best } = hole;
          if (best !== "") {
            const lowestPoints = 2 - best;
            let scorePoints = 0;
            switch (best) {
              case best > 2:
                scorePoints = -5;
                break;
              case 2:
                scorePoints = -3;
                break;
              case 1:
                scorePoints = -1;
                break;
              case 0:
                break;
              case -1:
                scorePoints = 2;
                break;
              case -2:
                scorePoints = 5;
                break;
              case best < -2:
                scorePoints = 8;
                break;
              default:
                break;
            }
            totalPoints += scorePoints;
          }
        }
      }
      if (teamType === "custom") {
        for (let x = 0; x < holes.length; x++) {
          const hole = holes[x];
          const { score } = hole;
          if (score !== "") {
            let scorePoints = 0;
            switch (score) {
              case score > 2:
                scorePoints = -5;
                break;
              case 2:
                scorePoints = -3;
                break;
              case 1:
                scorePoints = -1;
                break;
              case 0:
                break;
              case -1:
                scorePoints = 2;
                break;
              case -2:
                scorePoints = 5;
                break;
              case score < -2:
                scorePoints = 8;
                break;
              default:
                break;
            }
            totalPoints += scorePoints;
          }
        }
      }
    }
    return totalPoints;
  }

  function returnfmt(fmt) {
    if (fmt === "net-strokeplay") {
      return "Net Strokeplay";
    }
    if (fmt === "mod-stableford") {
      return "Modified Stableford";
    }
    if (fmt === "stable-medal") {
      return "Medal Stableford";
    }
    return fmt;
  }

  const [upd, setUpdate] = useState(0);
  function update() {
    setUpdate((current) => current + 1);
  }
  const divOptions = [
    {
      label: "Handicap",
      value: "handicap",
    },
    {
      label: "Custom",
      value: "custom",
    },
    {
      label: "Gender",
      value: "gender",
    },
  ];

  const genderOpts = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
  ];

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const [toEdit, setToEdit] = useState();
  const [divisions, setDivisions] = useState([]);
  const [playerType, setPlayerType] = useState("singles");
  const [selGender, setSelGender] = useState("male");

  const [showDialog, setShowDialog] = useState(false);
  const [showPlayers, setShowPlayers] = useState(false);

  const [showTeamDialog, setShowTeamDialog] = useState(false);
  const [showTeams, setShowTeams] = useState(false);
  const [adding, setAdding] = useState(0);

  const [teams, setTeams] = useState([]);

  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState();

  const [removing, setRemoving] = useState();

  const [players, setPlayers] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [nameInput, setNameInput] = useState("");
  const [format, setFormat] = useState("");
  const [divType, setDivType] = useState("");

  const [minPut, setMinPut] = useState(0);
  const [maxPut, setMaxPut] = useState(20);

  const [selPlayers, setSelPlayers] = useState([]);

  useEffect(() => {
    loadDivisions();
  }, []);

  function makeID(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  async function loadDivisions() {
    setLoading(true);
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { divisions, leaderboard } = round;
    const mep = {};
    for (let i = 0; i < leaderboard.length; i++) {
      const pl = leaderboard[i];
      mep[pl.playerID] = pl.lastName ?? pl.name;
    }
    if (round.newTeamboard) {
      const teamDocs = await roundDoc.ref.collection("teamLeaderboard").get();
      const teamLeaderboard = [];
      for (let i = 0; i < teamDocs.docs.length; i++) {
        const teamDoc = teamDocs.docs[i];
        const teamData = teamDoc.data();
        teamLeaderboard.push(teamData);
      }
      console.log(teamLeaderboard);
      for (let i = 0; i < teamLeaderboard.length; i++) {
        const team = teamLeaderboard[i];
        let name = "";
        for (let x = 0; x < team.playerIDs.length; x++) {
          const id = team.playerIDs[x];
          const ln = mep[id];
          if (x === team.playerIDs.length - 1) {
            name += `${ln}`;
          } else {
            name += `${ln} / `;
          }
        }
        if (!teamLeaderboard[i].name) {
          teamLeaderboard[i].name = name;
        }
      }
      if (round.enableTeams) {
        setTeams(teamLeaderboard);
      }
    } else {
      const teamLeaderboard = round.teamLeaderboard ?? [];

      for (let i = 0; i < teamLeaderboard.length; i++) {
        const team = teamLeaderboard[i];
        let name = "";
        for (let x = 0; x < team.playerIDs.length; x++) {
          const id = team.playerIDs[x];
          const ln = mep[id];
          if (x === team.playerIDs.length - 1) {
            name += `${ln}`;
          } else {
            name += `${ln} / `;
          }
        }
        teamLeaderboard[i].name = name;
      }
      if (round.enableTeams) {
        setTeams(teamLeaderboard);
      }
    }
    for (let i = 0; i < divisions.length; i++) {
      const id = divisions[i].leaderboardID;
      const board = round[id];
      if (board.teamLeaderboard) {
        divisions[i].teamIDs = board.teamIDs;
      }
      if (divisions[i].divType !== "custom") {
        divisions[i].players = round[id].leaderboard;
      }
    }
    setPlayers(leaderboard);
    setDivisions(divisions);
    setLoading(false);
  }

  async function saveNewDivision() {
    setLoading(true);
    setShowDialog(false);
    try {
      await db.collection("triggers").add({
        dateAdded: new Date(),
        eventID,
        roundID,
        min: minPut,
        max: maxPut,
        divType: playerType === "teams" ? "custom" : divType,
        divisionName: nameInput,
        type: "add-live-div",
        format,
        playerType,
      });

      setTimeout(() => {
        loadDivisions();
        toast.success(
          "Division successfully created - changes will reflect in a few moments",
          {
            icon: successIco,
          }
        );
      }, 4000);

      // const divisionName = nameInput;

      // const leaderbox§ardID = makeID(10);

      // const newDiv = {
      //   divisionName,
      //   format,
      //   divType,
      //   min: minPut,
      //   max: maxPut,
      //   leaderboardID,
      //   players: [],
      // };

      // const leaderboard = [];

      // const div = {
      //   leaderboard,
      //   divisionName,
      //   playerIDs: [],
      // };

      // const crIX = roundIDs.indexOf(roundID);

      // if (divType === "custom") {
      //   for (let i = 0; i < roundIDs.length; i++) {
      //     await db
      //       .collection("rounds")
      //       .doc(roundIDs[i])
      //       .update({
      //         divisions: admin.firestore.FieldValue.arrayUnion(newDiv),
      //         [`${leaderboardID}`]: div,
      //         leaderboardIDs:
      //           admin.firestore.FieldValue.arrayUnion(leaderboardID),
      //       });
      //   }
      //   if (eventID !== "") {
      //     await db
      //       .collection("events")
      //       .doc(eventID)
      //       .update({
      //         divisions: admin.firestore.FieldValue.arrayUnion(newDiv),
      //       });
      //   }
      // } else {
      //   for (let i = 0; i < roundIDs.length; i++) {
      //     const roundDoc = await db.collection("rounds").doc(roundIDs[i]).get();
      //     const round = roundDoc.data();
      //     const { leaderboard } = round;
      //     const filtBoard = leaderboard.filter((player) => {
      //       const { handicap } = player;
      //       if (handicap <= maxPut && handicap >= minPut) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     });
      //     if (format !== round.format) {
      //       const refs = filtBoard.map((row) =>
      //         roundDoc.ref.collection("scores").doc(row.playerID).get()
      //       );
      //       const docs = await Promise.all(refs);
      //       const mep = {};
      //       for (let i = 0; i < docs.length; i++) {
      //         const doc = docs[i].data();
      //         const playerID = docs[i].id;
      //         if (i === 0) {
      //           // console.log(doc);
      //         }
      //         if (format === "net-strokeplay") {
      //           mep[playerID] = doc.currentScore;
      //         } else if (format === "stable-medal") {
      //           mep[playerID] = doc.medalStablefordPoints;
      //         } else if (format === "medal") {
      //           mep[playerID] = doc.currentGrossScore;
      //         } else {
      //           mep[playerID] = doc.totalPoints ?? 0;
      //         }
      //       }
      //       // console.log(mep);
      //       for (let i = 0; i < filtBoard.length; i++) {
      //         const id = filtBoard[i].playerID;
      //         const score = mep[id];
      //         filtBoard[i].score = score;
      //       }
      //       if (format.includes("stable")) {
      //         filtBoard.sort((a, b) => b.score - a.score);
      //       } else {
      //         filtBoard.sort((a, b) => a.score - b.score);
      //       }
      //       let currentTies = 0;
      //       for (let i = 0; i < filtBoard.length; i++) {
      //         let ranking = i + 1;
      //         const row = filtBoard[i];
      //         if (i > 0) {
      //           if (filtBoard[i - 1].score === row.score) {
      //             ranking = i - currentTies;
      //             currentTies += 1;
      //           } else {
      //             currentTies = 0;
      //           }
      //         }
      //         row.rank = ranking;
      //       }

      //       div.leaderboard = filtBoard;
      //       div.playerIDs = filtBoard.map((row) => row.playerID);
      //       newDiv.players = filtBoard;
      //       //   return setLoading(false);
      //       // for (let i = 0; i < roundIDs.length; i++) {
      //       await db
      //         .collection("rounds")
      //         .doc(roundIDs[i])
      //         .update({
      //           divisions: admin.firestore.FieldValue.arrayUnion(newDiv),
      //           [`${leaderboardID}`]: div,
      //           leaderboardIDs:
      //             admin.firestore.FieldValue.arrayUnion(leaderboardID),
      //         });
      //       // }
      //     } else {
      //       div.leaderboard = filtBoard;
      //       div.playerIDs = filtBoard.map((row) => row.playerID);
      //       newDiv.players = filtBoard;
      //       // for (let i = 0; i < roundIDs.length; i++) {
      //       await db
      //         .collection("rounds")
      //         .doc(roundIDs[i])
      //         .update({
      //           divisions: admin.firestore.FieldValue.arrayUnion(newDiv),
      //           [`${leaderboardID}`]: div,
      //           leaderboardIDs:
      //             admin.firestore.FieldValue.arrayUnion(leaderboardID),
      //         });
      //       // }
      //     }
      //   }

      //   if (eventID !== "") {
      //     await db
      //       .collection("events")
      //       .doc(eventID)
      //       .update({
      //         divisions: admin.firestore.FieldValue.arrayUnion(newDiv),
      //       });
      //   }
      //   loadDivisions();
      // }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
      setLoading(false);
    }
  }

  async function removeTeams() {
    setLoading(true);
    setShowTeams(false);
    try {
      const { leaderboardID } = removing;
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const div = round[leaderboardID];

      const { format, divisions } = round;
      let { leaderboard, playerIDs, teamIDs } = div;

      const remIDs = selPlayers.map((t) => t.teamID);

      let remPIDs = [];

      for (let i = 0; i < selPlayers.length; i++) {
        const team = selPlayers[i];
        const { playerIDs } = team;
        remPIDs = [...remPIDs, ...playerIDs];
      }

      console.log(remPIDs);

      leaderboard = leaderboard.filter((team) => !remIDs.includes(team.teamID));
      teamIDs = teamIDs.filter((team) => !remIDs.includes(team));
      playerIDs = playerIDs.filter((id) => !remPIDs.includes(id));

      console.log(playerIDs);

      let currentTies = 0;
      if (format === "stableford") {
        leaderboard.sort((a, b) => b.score - a.score);
      } else {
        leaderboard.sort((a, b) => a.score - b.score);
      }
      for (let i = 0; i < leaderboard.length; i++) {
        let ranking = i + 1;

        const row = leaderboard[i];
        if (i > 0) {
          if (leaderboard[i - 1].score === row.score) {
            ranking = i - currentTies;
            currentTies += 1;
            // console.log(i, ranking);
          } else {
            currentTies = 0;
          }
        }
        leaderboard[i].rank = ranking;
      }
      div.leaderboard = leaderboard;
      // div.leaderboard = leaderboard;
      div.teamIDs = teamIDs;
      div.playerIDs = playerIDs;
      // return setLoading(false);

      for (let i = 0; i < divisions.length; i++) {
        if (divisions[i].leaderboardID === leaderboardID) {
          divisions[i].players = divisions[i].players.filter(
            (t) => !remIDs.includes(t.teamID)
          );
        }
      }

      await roundDoc.ref.update({
        [`${leaderboardID}`]: div,
        divisions,
      });
      setRemoving();
      setShowTeams(false);
      loadDivisions();
      setSelPlayers([]);
      toast.success("Teams successfully removed from division", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
  }

  async function removePlayers() {
    setLoading(true);
    setShowPlayers(false);
    try {
      const { leaderboardID } = removing;
      for (let i = 0; i < roundIDs.length; i++) {
        const roundDoc = await db.collection("rounds").doc(roundIDs[i]).get();
        const round = roundDoc.data();
        const div = round[leaderboardID];
        const board = div.leaderboard;
        const { playerIDs } = div;
        const { divisions } = round;
        const selIDs = selPlayers.map((pl) => pl.playerID);

        const filteredPlayers = playerIDs.filter((pl) => !selIDs.includes(pl));
        const filteredBoard = board.filter(
          (pl) => !selIDs.includes(pl.playerID)
        );

        if (format.includes("stable")) {
          filteredBoard.sort((a, b) => b.score - a.score);
        } else {
          filteredBoard.sort((a, b) => a.score - b.score);
        }
        let currentTies = 0;
        for (let i = 0; i < filteredBoard.length; i++) {
          let ranking = i + 1;
          const row = filteredBoard[i];
          if (i === 0) {
            filteredBoard[i].rank = 1;
          }
          if (i > 0) {
            if (filteredBoard[i - 1].score === row.score) {
              ranking = i - currentTies;
              currentTies += 1;
            } else {
              currentTies = 0;
            }
          }
          row.rank = ranking;
        }

        div.playerIDs = filteredPlayers;
        div.leaderboard = filteredBoard;

        for (let i = 0; i < divisions.length; i++) {
          const division = divisions[i];
          if (division.leaderboardID === leaderboardID) {
            divisions[i].players = divisions[i].players.filter(
              (pl) => !selIDs.includes(pl.playerID)
            );
          }
        }

        //   console.log(div, divisions);
        //   return setLoading(false);

        await roundDoc.ref.update({
          [`${leaderboardID}`]: div,
          divisions,
        });
      }
      setRemoving();
      setShowPlayers(false);
      loadDivisions();
      setSelPlayers([]);
      toast.success("Players successfully removed from division", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function addTeams() {
    // return console.log(selPlayers);
    setLoading(true);
    setShowTeams(false);
    try {
      const { leaderboardID } = adding;
      const roundDoc = await db.collection("rounds").doc(roundID).get();
      const round = roundDoc.data();
      const div = round[leaderboardID];
      const { divisions, newTeamBoard, teamType, format } = round;
      const { leaderboard } = div;

      let teamLeaderboard = [];

      if (newTeamBoard) {
        const teamDocs = await roundDoc.ref.collection("teamLeaderboard").get();
        const teamLeaderboard = [];
        for (let i = 0; i < teamDocs.docs.length; i++) {
          const teamDoc = teamDocs.docs[i];
          const teamData = teamDoc.data();
          teamLeaderboard.push(teamData);
        }
      } else {
        teamLeaderboard = round.teamLeaderboard;
      }

      const toAdd = [];

      for (let j = 0; j < selPlayers.length; j++) {
        const selTeam = selPlayers[j];
        console.log(selTeam);

        for (let i = 0; i < teamLeaderboard.length; i++) {
          const team = teamLeaderboard[i];
          if (team.teamID === selTeam.teamID) {
            if (format === "stableford") {
              const { holes, playerIDs } = team;
              const stableScore = calcTeamStablefordScore({
                format,
                holes,
                playerIDs,
                teamType,
              });
              team.score = stableScore;
            }
            team.name = selTeam.name;
            toAdd.push(team);

            // console.log(team);
            leaderboard.push(team);
            div.teamIDs.push(team.teamID);
            div.playerIDs = [...div.playerIDs, ...team.playerIDs];
          }
        }
      }
      let currentTies = 0;
      if (format === "stableford") {
        leaderboard.sort((a, b) => b.score - a.score);
      } else {
        leaderboard.sort((a, b) => a.score - b.score);
      }
      for (let i = 0; i < leaderboard.length; i++) {
        let ranking = i + 1;

        const row = leaderboard[i];
        if (i > 0) {
          if (leaderboard[i - 1].score === row.score) {
            ranking = i - currentTies;
            currentTies += 1;
            // console.log(i, ranking);
          } else {
            currentTies = 0;
          }
        }
        leaderboard[i].rank = ranking;
      }
      div.leaderboard = leaderboard;
      console.log(div);

      for (let i = 0; i < divisions.length; i++) {
        const division = divisions[i];
        if (division.leaderboardID === leaderboardID) {
          divisions[i].players = [...divisions[i].players, ...toAdd];
        }
      }

      // return setLoading(false);
      await roundDoc.ref.update({
        [`${div.leaderboardID}`]: div,
        divisions,
      });
      toast.success("Teams successfully added to division", {
        icon: successIco,
      });
      setAdding();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function addPlayers() {
    setShowPlayers(false);
    setLoading(true);
    try {
      const { leaderboardID } = adding;

      for (let i = 0; i < roundIDs.length; i++) {
        const roundDoc = await db.collection("rounds").doc(roundIDs[i]).get();
        const round = roundDoc.data();
        const div = round[leaderboardID];
        const { divisions, roundIndex } = round;
        const { leaderboard, playerIDs, format } = div;
        console.log(format);
        const refs = selPlayers.map((pl) =>
          db
            .collection("rounds")
            .doc(roundIDs[i])
            .collection("scores")
            .doc(pl.playerID)
            .get()
        );

        const docs = await Promise.all(refs);
        for (let i = 0; i < docs.length; i++) {
          const doc = docs[i].data();

          const player = selPlayers[i];
          playerIDs.push(player.playerID);
          if (format === "stable-medal") {
            player.score = doc.medalStablefordPoints ?? 0;
          } else if (format === "stableford") {
            player.score = doc.totalPoints ?? 0;
          } else if (format === "medal") {
            console.log("this one");
            console.log(doc.currentGrossScore);
            player.score = doc.currentGrossScore;
          } else {
            console.log(doc.currentScore);
            player.score = doc.currentScore;
          }

          if (roundIndex > 0) {
            if (format === "stableford" || format === "stable-medal") {
              player.startingScore = doc.startingPoints;
            } else if (format === "medal") {
              player.startingScore =
                doc.startingGrossScore ?? doc.startingScore;
            } else {
              player.startingScore = doc.startingNetScore ?? doc.startingScore;
            }
          }

          player.rankingsTracker = [];

          leaderboard.push(player);
        }

        if (format.includes("stable")) {
          leaderboard.sort((a, b) => b.score - a.score);
        } else {
          leaderboard.sort((a, b) => a.score - b.score);
        }
        let currentTies = 0;
        for (let i = 0; i < leaderboard.length; i++) {
          let ranking = i + 1;
          const row = leaderboard[i];
          if (i > 0) {
            if (leaderboard[i - 1].score === row.score) {
              ranking = i - currentTies;
              currentTies += 1;
            } else {
              currentTies = 0;
            }
          }
          row.rank = ranking;
        }

        div.leaderboard = leaderboard;
        div.playerIDs = playerIDs;

        for (let i = 0; i < divisions.length; i++) {
          if (divisions[i].leaderboardID === leaderboardID) {
            divisions[i].players = [...divisions[i].players, ...selPlayers];
          }
        }

        //   console.log(div, divisions);
        //   return setLoading(false);

        await roundDoc.ref.update({
          [`${leaderboardID}`]: div,
          divisions,
        });
      }
      loadDivisions();
      setShowPlayers(false);
      setAdding();
      setSelPlayers([]);
      toast.success("Players successfully added to division", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function changeDivision() {
    // return console.log(toEdit);
    setShowDialog(false);
    setLoading(true);
    try {
      const eventDoc = await db.collection("events").doc(eventID).get();
      const evt = eventDoc.data();

      const { divisions, roundIDs } = evt;
      for (let i = 0; i < divisions.length; i++) {
        if (divisions[i].leaderboardID === toEdit.leaderboardID) {
          divisions[i].divisionName = nameInput;
        }
      }

      // console.log(divisions);

      await eventDoc.ref.update({
        divisions,
      });

      for (let i = 0; i < roundIDs.length; i++) {
        const roundID = roundIDs[i];
        const roundDoc = await db.collection("rounds").doc(roundID).get();
        const round = roundDoc.data();

        const { divisions } = round;
        const div = round[toEdit.leaderboardID];
        for (let i = 0; i < divisions.length; i++) {
          if (divisions[i].leaderboardID === toEdit.leaderboardID) {
            divisions[i].divisionName = nameInput;
          }
        }
        div.divisionName = nameInput;

        // console.log(divisions);
        // console.log(div);

        await roundDoc.ref.update({
          [`${toEdit.leaderboardID}`]: div,
          divisions,
        });

        setToEdit();
        setNameInput("");
        loadDivisions();
      }
      toast.success("Division successfully edited", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
      setLoading(false);
    }
  }

  async function deleteDivision() {
    console.log(eventID);
    setShowDelete(false);
    setLoading(true);
    try {
      const { leaderboardID } = toDelete;
      for (let i = 0; i < roundIDs.length; i++) {
        const roundDoc = await db.collection("rounds").doc(roundIDs[i]).get();
        const round = roundDoc.data();
        const { divisions } = round;
        const filt = divisions.filter(
          (div) => div.leaderboardID !== leaderboardID
        );
        //   console.log(filt);
        //   return setLoading(false);
        await roundDoc.ref.update({
          divisions: filt,
          leaderboardIDs: admin.firestore.FieldValue.arrayRemove(leaderboardID),
        });
      }
      const evtDoc = await db.collection("events").doc(eventID).get();
      const evt = evtDoc.data();
      const { divisions } = evt;
      const filt = divisions.filter(
        (div) => div.leaderboardID !== leaderboardID
      );
      //   console.log(filt);
      //   return setLoading(false);
      await evtDoc.ref.update({
        divisions: filt,
      });
      setToDelete();
      setShowDelete(false);
      loadDivisions();
      toast.success("Division successfully removed", {
        icon: successIco,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="handicap-divisions live-dm">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <Dialog
        open={showPlayers}
        onClose={() => {
          setAdding();
          setRemoving();
          setSelPlayers([]);
          setShowPlayers(false);
        }}
      >
        <PageHeader
          close={() => {
            setAdding();
            setRemoving();
            setSelPlayers([]);

            setShowPlayers(false);
          }}
          showClose
          text={adding ? "Add Players" : "Remove Players"}
        />
        <div className="input-group pl-20">
          <input
            type="text"
            placeholder="Start typing..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="default-input"
          />
        </div>
        <div className="dv-players pd-20">
          {players.map((player, index) => {
            let incl = false;

            const sl = searchInput.toLowerCase();
            const nl = player.name.toLowerCase();
            if (!nl.includes(sl)) {
              return null;
            }

            if (adding) {
              const { players } = adding;
              for (let i = 0; i < players.length; i++) {
                if (players[i].playerID === player.playerID) {
                  incl = true;
                }
              }
            }

            if (incl && adding) {
              return null;
            }

            let sel = false;

            if (adding) {
              for (let i = 0; i < selPlayers.length; i++) {
                if (selPlayers[i].playerID === player.playerID) {
                  sel = true;
                }
              }
            }
            if (removing) {
              const { players } = removing;
              // console.log(players);
              for (let i = 0; i < players.length; i++) {
                // console.log(players[i].playerID, player.playerID);
                if (players[i].playerID === player.playerID) {
                  incl = true;
                }
              }
            }

            if (removing && !incl) {
              return null;
            }

            if (removing) {
              for (let i = 0; i < selPlayers.length; i++) {
                if (selPlayers[i].playerID === player.playerID) {
                  sel = true;
                }
              }
            }

            return (
              <PlayerItem
                key={player.playerID}
                hcp={player.handicap}
                img={player.profileImage}
                name={player.name}
                noLink
                hideIcons
                showProfile={() => {
                  if (sel) {
                    setSelPlayers((current) => {
                      current = current.filter(
                        (pl) => pl.playerID !== player.playerID
                      );
                      return current;
                    });
                  } else {
                    setSelPlayers((current) => {
                      current.push(player);
                      return current;
                    });
                  }
                  update();
                }}
                showBg={sel}
              />
            );
          })}
        </div>
        <div className="flex-center mb-20">
          <button
            onClick={() => {
              if (adding) {
                addPlayers();
              } else {
                removePlayers();
              }
            }}
            className="default-button"
          >
            Save Changes
          </button>
        </div>
      </Dialog>
      <Dialog
        open={showTeams}
        onClose={() => {
          setAdding();
          setRemoving();
          setSelPlayers([]);
          setShowTeams(false);
        }}
      >
        <PageHeader
          close={() => {
            setAdding();
            setRemoving();
            setSelPlayers([]);

            setShowTeams(false);
          }}
          showClose
          text={adding ? "Add Teams" : "Remove Teams"}
        />
        <div className="input-group pl-20">
          <input
            type="text"
            placeholder="Start typing..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="default-input"
          />
        </div>
        <div className="dv-players pd-20">
          {teams.map((team, index) => {
            let incl = false;
            const { teamID } = team;
            // console.log(adding);
            const sl = searchInput.toLowerCase();
            const nl = team.name.toLowerCase();
            if (!nl.includes(sl)) {
              return null;
            }

            if (adding) {
              if (adding.teamIDs.includes(teamID)) {
                return null;
              }
            }
            if (incl && adding) {
              return null;
            }

            let sel = false;

            if (adding) {
              for (let i = 0; i < selPlayers.length; i++) {
                if (selPlayers[i].teamID === team.teamID) {
                  sel = true;
                }
              }
            }
            if (removing) {
              const { players } = removing;
              console.log(players);
              for (let i = 0; i < players.length; i++) {
                // console.log(players[i].playerID, player.playerID);
                if (players[i].teamID === team.teamID) {
                  incl = true;
                }
              }
            }

            // console.log(incl);

            if (removing && !incl) {
              return null;
            }

            if (removing) {
              for (let i = 0; i < selPlayers.length; i++) {
                if (selPlayers[i].teamID === team.teamID) {
                  sel = true;
                }
              }
            }

            return (
              <PlayerItem
                key={team.teamID}
                hideHCP
                name={team.name}
                noLink
                hideIcons
                showProfile={() => {
                  if (sel) {
                    setSelPlayers((current) => {
                      current = current.filter(
                        (pl) => pl.teamID !== team.teamID
                      );
                      return current;
                    });
                  } else {
                    setSelPlayers((current) => {
                      current.push(team);
                      console.log(current);
                      return current;
                    });
                  }
                  update();
                }}
                showBg={sel}
              />
            );
          })}
        </div>
        <div className="flex-center mb-20">
          <button
            onClick={() => {
              if (adding) {
                addTeams();
              } else {
                removeTeams();
              }
            }}
            className="default-button"
          >
            Save Changes
          </button>
        </div>
      </Dialog>
      <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
        <div className="cs-delete">
          <div className="page-header">
            <h2>Delete Division</h2>
            <Close
              onClick={() => setShowDelete(false)}
              className="icon-cursor"
            />
          </div>
          <p>Are you sure you want to remove this division?</p>
          <div className="flex-center">
            <button onClick={deleteDivision} className="delete-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="new-division-modal">
          <div className="page-header">
            {toEdit ? <h2>Edit Division</h2> : <h2>New Division</h2>}
            <Close
              onClick={() => setShowDialog(false)}
              className={"icon-cursor"}
            />
          </div>
          <div className="ec-box">
            <div className="input-group">
              <p>Player Type</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                options={playerOptions}
                value={playerType}
                onChange={(e) => {
                  setPlayerType(e.value);
                  if (
                    divType === "handicap" ||
                    divType === "age" ||
                    divType === "league"
                  ) {
                    setDivType("custom");
                  }
                }}
              />
            </div>
            <div className="input-group">
              <p>Division Name</p>
              <input
                type="text"
                value={nameInput}
                placeholder="Enter name here..."
                onChange={(e) => setNameInput(e.target.value)}
                className="default-input"
              />
            </div>
            {!edit && playerType !== "teams" && (
              <div className="input-group">
                <p>Format</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={formatOptions}
                  value={format}
                  onChange={(e) => {
                    setFormat(e.value);
                  }}
                />
              </div>
            )}
          </div>
          {!edit && playerType !== "teams" && (
            <div className="ec-box">
              <div className="input-group">
                <p>Division Type</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={divOptions}
                  value={divType}
                  onChange={(e) => {
                    setDivType(e.value);
                  }}
                />
              </div>

              {divType === "gender" && (
                <div className="input-group">
                  <p>Gender</p>
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={genderOpts}
                    value={selGender}
                    onChange={(e) => {
                      setSelGender(e.value);
                    }}
                  />
                </div>
              )}

              {divType === "handicap" && (
                <>
                  <div className="input-group">
                    <p>Min</p>
                    <input
                      type="number"
                      value={minPut}
                      onChange={(e) => setMinPut(e.target.valueAsNumber)}
                      className="default-input"
                    />
                  </div>
                  <div className="input-group">
                    <p>Max</p>
                    <input
                      type="number"
                      value={maxPut}
                      onChange={(e) => setMaxPut(e.target.valueAsNumber)}
                      className="default-input"
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <div className="flex-center mb-20">
            <button
              onClick={() => {
                if (edit) {
                  changeDivision();
                } else {
                  saveNewDivision();
                }
              }}
              disabled={
                (edit && nameInput === "") ||
                (!edit &&
                  playerType !== "teams" &&
                  (nameInput === "" || format === "" || divType === "")) ||
                (!edit && playerType === "teams" && nameInput === "")
              }
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader text="Leaderboard Divisions" />
      <div className="ph-msg">
        <p>Adjust your divisions for this event</p>
      </div>
      {divisions.length < 6 && (
        <div className="quick-links mt-10">
          <Pill
            onClick={() => {
              setEdit(false);
              setShowDialog(true);
            }}
            text={"Add Division"}
          />
        </div>
      )}
      {divisions.length === 0 && (
        <div className="no-msg">
          <p>No items to display</p>
        </div>
      )}
      <div className="flex-wrapper pd-20">
        {divisions.map((div, index) => {
          return (
            <div key={div.leaderboardID} className="ld-division">
              <div className="ldv-left">
                <h2>{div.divisionName}</h2>
                <p>
                  {div.playerType !== "teams" && <>{returnfmt(div.format)}: </>}
                  {div.divType}{" "}
                  {div.divType === "handicaps" && (
                    <>
                      {div.min < 0 ? `+${-div.min}` : div.min} - {div.max}
                    </>
                  )}
                </p>
              </div>
              <div className="ldv-right">
                <EditIcon
                  className="dg-icon hgb-ico icon-cursor mb-10"
                  // onClick={editAction}
                  onClick={() => {
                    setToEdit(div);
                    setNameInput(div.divisionName);
                    // setFormat(div.format);
                    // setDivType(div.divType);
                    // setMinPut(div.min);
                    // setMaxPut(div.max);
                    setEdit(true);
                    setShowDialog(true);
                  }}
                />

                <PersonAddAlt1Icon
                  onClick={() => {
                    setAdding(div);
                    if (div.playerType === "teams") {
                      setShowTeams(true);
                    } else {
                      setShowPlayers(true);
                    }
                  }}
                  className="dg-icon hgb-ico icon-cursor mb-10"
                />
                <PersonRemoveAlt1Icon
                  onClick={() => {
                    setRemoving(div);
                    if (div.playerType === "teams") {
                      setShowTeams(true);
                    } else {
                      setShowPlayers(true);
                    }
                  }}
                  className="red-icon icon-cursor mb-10"
                />

                <DeleteForeverIcon
                  className="red-icon icon-cursor"
                  onClick={() => {
                    setToDelete(div);
                    setShowDelete(true);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LiveDivisionManager;
