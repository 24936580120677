import { Close } from "@mui/icons-material";
import classNames from "classnames";
import React from "react";

function BIPlayer({ player = {}, removeAct = () => {} }) {
  return (
    <div
      className={classNames("bi-player", {
        "bi-exists": player.exists,
      })}
    >
      <div className="bip-item">
        <p>{player.firstName}</p>
      </div>
      <div className="bip-item">
        <p>{player.lastName}</p>
      </div>
      <div className="bip-item bip-mail">
        <p>{player.emailAddress}</p>
      </div>
      <div className="bip-item">
        <p>{player.handicap}</p>
      </div>
      <div className="bip-item">
        <p>{player.password}</p>
      </div>
      <div className="bip-item">
        <p>{player.dob}</p>
      </div>
      <div className="bip-item">
        <p>{player.country}</p>
      </div>
      <div className="bip-item">
        <p>{player.state}</p>
      </div>
      <div className="bip-item">
        <p>{player.city}</p>
      </div>
      <div className="bip-item">
        <p>{player.hnaID}</p>
      </div>
      <div className="bip-item">
        <p>{player.platform}</p>
      </div>
      <div className="bip-item">
        <p>{player.platformID}</p>
      </div>
      <div className="bip-item">
        <p>{player.gender}</p>
      </div>
      <div className="bip-item">
        <Close onClick={removeAct} className="icon-cursor red-icon" />
      </div>
    </div>
  );
}

export default BIPlayer;
