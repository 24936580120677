import React, { useState, useEffect } from "react";
import { db, admin } from "../../../firebase";
import "./EventExports.css";
import { CSVDownload } from "react-csv";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import { Dialog } from "@mui/material";
import PageHeader from "../../display/Rows/PageHeader";
import PlayerItem from "../../display/PlayerItem/PlayerItem";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Checkbox } from "@mui/material";
import BackdropLoader from "../../Loaders/BackdropLoader";

function EventExports({
  eventID = "",
  close = () => {},
  rounds = [],
  roundIDs = [],
  enableTeams = false,
  divisions = [],
  teams = [],
}) {
  const evtExport = admin.functions().httpsCallable("evtExport");
  const sortArr = [
    {
      label: "Tee Time",
      value: "teeTime",
    },
    {
      label: "Handicap",
      value: "handicap",
    },
  ];
  const opts = [
    {
      label: "Players",
      value: "players",
    },
    {
      label: "Tee Times",
      value: "teeTimes",
    },
    ...(enableTeams
      ? [
          {
            label: "Teams",
            value: "Teams",
          },
        ]
      : []),
  ];

  const subOpts = {
    players: [
      { value: "course", label: "Course" },
      { value: "handicap", label: "Handicap Index" },
      { value: "playingHandicap", label: "Playing Handicap" },
      { value: "teeTime", label: "Tee Time" },
      { value: "tee", label: "Tee" },
      {
        value: "startingTee",
        label: "Starting Tee",
      },
      {
        value: "emailAddress",
        label: "Email Address",
      },
      {
        value: "handicapID",
        label: "Handicap ID",
      },
    ],
  };

  // State
  const [loading, setLoading] = useState(false);
  const [exportType, setExportType] = useState("csv");
  const [selSort, setSelSort] = useState("teeTime");
  const [playerOpts, setPlayerOpts] = useState(subOpts.players);
  const [divArr, setDivArr] = useState([]);
  const [selDiv, setSelDiv] = useState([]);
  const [selOpt, setSelOpt] = useState("players");

  const [error, setError] = useState("");
  const [selRound, setSelRound] = useState([]);
  const [roundOpts, setRoundOpts] = useState([]);

  const [dlCSV, setDLCSV] = useState(false);
  const [csvArr, setCSVArr] = useState([]);

  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  // UE
  useEffect(() => {
    console.log(teams);
    const arr = [{ value: "overall", label: "Overall" }];
    for (let i = 0; i < divisions.length; i++) {
      const division = divisions[i];
      const { divisionName, leaderboardID } = division;
      arr.push({
        value: leaderboardID,
        label: divisionName,
      });
    }
    if (teams.length > 0) {
      arr.push({
        value: "teams",
        label: "Teams",
      });
    }
    setDivArr(arr);
    setSelDiv("overall");
    const temp = [];
    // const temp = [{ label: "Overall", value: "overall" }];
    for (let i = 0; i < rounds.length; i++) {
      temp.push({
        value: i.toString(),
        label: `Round ${i + 1}`,
      });
    }
    setRoundOpts(temp);
    if (rounds.length > 0) {
      setSelRound("0");
    }
  }, []);

  useEffect(() => {
    if (dlCSV) {
      setTimeout(() => {
        setDLCSV(false);
        // alert("set false");
      }, 4000);
    }
  }, [dlCSV]);

  async function callExport() {
    setError("");
    setLoading(true);
    try {
      const poVals = playerOpts.map((po) => po.value);
      const temp = [...subOpts.players];

      const filt = temp.filter((item) => poVals.includes(item.value));
      if (exportType === "csv") {
        const csvArr = await evtExport({
          eventID,
          selRound,
          exportType,
          selOpt,
          playerOpts: filt,
          selSort,
          selDiv,
        });
        // console.log(csvArr);
        setCSVArr(csvArr.data);
        setDLCSV(true);
      } else {
        const url = await evtExport({
          eventID,
          selRound,
          exportType,
          selOpt,
          playerOpts: filt,
          selSort,
          selDiv,
        });
        // console.log(url.data);
        window.open(url.data, "_blank");
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered some technical difficulties there - please try again,"
      );
    }
    setLoading(false);
  }

  return (
    <Dialog open onClose={close}>
      {loading && <BackdropLoader />}
      {dlCSV && <CSVDownload data={csvArr} />}
      <div className="evt-exports">
        <PageHeader showClose close={close} text="Event Exports" />

        <div className="ph-msg">
          <p>Export some of your event information in csv or pdf format</p>
        </div>

        <div className="ec-box">
          <div className="input-group">
            <p>Export Type</p>
            <div className="selector-group">
              <button
                onClick={() => {
                  setExportType("csv");
                  setSelOpt("players");
                }}
                className={
                  exportType === "csv" ? "sg-left sg-active" : "sg-left"
                }
              >
                CSV
              </button>

              <button
                onClick={() => setExportType("pdf")}
                className={
                  exportType === "pdf" ? "sg-right sg-active" : "sg-right"
                }
              >
                PDF
              </button>
            </div>
          </div>
          {selDiv !== "teams" && (
            <div className="input-group">
              <p>Select Round</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={selRound}
                options={roundOpts}
                onChange={(e) => {
                  setSelRound(e.value);
                }}
              />
            </div>
          )}
          {divArr.length > 1 && selOpt === "players" && (
            <div className="input-group">
              <p>Select Division</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                value={selDiv}
                options={divArr}
                onChange={(e) => {
                  setSelDiv(e.value);
                }}
              />
            </div>
          )}
        </div>
        {true && (
          <div className="ec-box">
            {opts.map((opt, i) => {
              //   if (exportType === "csv") {
              //     if (opt.value === "teeTimes") {
              //       return null;
              //     }
              //   }

              return (
                <PlayerItem
                  key={i}
                  showBg={selOpt === opt.value}
                  showProfile={() => {
                    setSelOpt(opt.value);
                    update();
                  }}
                  noLink
                  hideImage
                  hideIcons
                  hideHCP
                  name={opt.label}
                />
              );
            })}
          </div>
        )}
        {selOpt === "players" && selDiv !== "teams" && (
          <>
            <div className="pl-20 input-group">
              <p>Select Values</p>
            </div>
            <div className="eve-rds csv-vals">
              {subOpts.players.map((value, i) => {
                const selOpts = playerOpts.map((val) => val.value);

                return (
                  <div key={value.value} className="eve-rd-item">
                    <Checkbox
                      checked={selOpts.includes(value.value)}
                      onChange={(e) => {
                        setPlayerOpts((c) => {
                          if (selOpts.includes(value.value)) {
                            const filt = c.filter(
                              (val) => val.value !== value.value
                            );
                            return filt;
                          } else {
                            c.push(value);
                            return c;
                          }
                        });
                        update();
                      }}
                    />
                    <p>{value.label}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {selOpt === "players" && selDiv !== "teams" && (
          <div className="input-group pl-20">
            <p>Sort By</p>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={selSort}
              options={sortArr}
              onChange={(e) => {
                setSelSort(e.value);
              }}
            />
          </div>
        )}

        {error !== "" && (
          <div className="error-message-row">
            <p>{error}</p>
          </div>
        )}

        <div className="flex-center mt-20 mb-40">
          <button onClick={callExport} className="default-button">
            Generate
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default EventExports;
