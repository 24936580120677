import React from "react";
import "./ChatMessage.css";

function GroupChatMessage({ message, time, sent, unread = false, name = "" }) {
  return (
    <div className={sent ? "cmc-sent" : "cmc-rec"}>
      <div className="gcm-box">
        {name && (
          <div className="cm-name">
            <p>{name}</p>
          </div>
        )}
        <div
          style={{
            boxShadow:
              unread && !sent ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none",
          }}
          className={sent ? "chat-message cm-sent" : "chat-message cm-received"}
        >
          <div className="cm-time">
            <p>{time}</p>
          </div>
          <div className="cm-msg">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupChatMessage;
