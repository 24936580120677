import React, { useEffect, useState } from "react";
import { db, admin } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Icons

// Comps

function SubscriptionCreator() {
  // Admin
  const { userDetails, portalSettings } = useAuth();
  const generatePFSub = admin.functions().httpsCallable("payfastSubSignature");

  // State
  const [formHTML, setFormHTML] = useState();

  // UE
  useEffect(() => {
    // callPayfastSub();
  }, []);

  // Funcs

  async function exchangeRate(amount) {
    try {
      const rate = await fetch(
        `https://api.exchangerate.host/convert?from=USD&to=ZAR&amount=${amount}`
      );
      const jsRate = await rate.json();
      console.log(jsRate);
      // console.log(Math.round(jsRate.result), typeof jsRate.result);
      const rounded = Math.round(jsRate.result);
      return rounded;
      // return Math.round(jsRate.result);
    } catch (err) {
      console.log(err);
    }
  }

  async function callPayfastSub() {
    const now = new Date();
    const value = "50";
    // const exchtotal = await exchangeRate();
    // return;
    // const total = exchtotal.toString();
    // const total = "5";
    const { firstName, lastName, emailAddress } = userDetails;
    const formattedDate = now.toISOString().split("T")[0];
    let html = ``;

    const htmlData = await generatePFSub({
      total: "10.00",
      firstName,
      lastName,
      emailAddress,
      type: "Subscription",
      initialAmount: "0",
      frequency: "3",
      custom_str1: portalSettings.clubID,
    });
    html += htmlData.data;
    console.log(html);
    setFormHTML(html);
  }

  const temp = `<form action="https://sandbox.payfast.co.za/eng/process" method="post">
<input type="hidden" name="merchant_id" value="10027035">
<input type="hidden" name="merchant_key" value="loeu9mukcphfi">
<input type="hidden" name="return_url" value="https://www.theugatour.com">
<input type="hidden" name="cancel_url" value="https://www.theugatour.com">
<input type="hidden" name="notify_url" value="https://us-central1-uga-tour.cloudfunctions.net/payfastWebhookReceiver">
<input type="hidden" name="name_first" value="UGA">
<input type="hidden" name="name_last" value="Admin">
<input type="hidden" name="email_address" value="admin@theugatour.com">
<input type="hidden" name="amount" value="10.00">
<input type="hidden" name="item_name" value="Subscription">
<input type="hidden" name="item_description" value="Subscription2">
<input type="hidden" name="subscription_type" value="2"> 
<input type="hidden" name="signature" value="30e3c4b2b1ee1d76b88be58341043252"> 
<input type="submit">
</form> 
`;

  return (
    <div className="subs-creator">
      <button onClick={callPayfastSub} className="default-button">
        Call
      </button>
      <div
        className="payfast-form"
        dangerouslySetInnerHTML={{ __html: temp }}
      ></div>
      <div
        className="payfast-form"
        dangerouslySetInnerHTML={{ __html: formHTML }}
      ></div>
    </div>
  );
}

export default SubscriptionCreator;
