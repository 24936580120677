import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";

// Icons
import MatchplayScore from "../../components/display/MatchplayScore/MatchplayScore";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import EditIcon from "@mui/icons-material/Edit";
import NewsPill from "./NewsPill";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { Dialog, Tooltip } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PageHeader from "../../components/display/Rows/PageHeader";

// Components
import ArrowBack from "@mui/icons-material/ArrowBack";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import BackRow from "../../components/display/Rows/BackRow";

function MatchplayTeamBracket({
  bracket = [],
  goBack = () => {},
  tournID = "",
  reload = () => {},
  started = false,
  players = [],
  teams = [],
}) {
  const [matches, setMatches] = useState(bracket);
  const [loading, setLoading] = useState(false);
  const [ri, setRI] = useState(0);

  const [showSelector, setShowSelector] = useState(false);
  const [changed, setChanged] = useState(false);

  const [matchEdit, setMatchEdit] = useState(0);
  const [playerToSwap, setPlayerToSwap] = useState();
  const [playerToExclude, setPlayerToExclude] = useState();

  const [error, setError] = useState("");
  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((current) => current + 1);
  }

  const [showManual, setShowManual] = useState(false);
  const [manIndex, setManIndex] = useState([]);
  const [manMatch, setManMatch] = useState();
  const [selWinner, setSelWinner] = useState();

  const [manualBy, setManualBy] = useState(0);
  const [manualRem, setManualRem] = useState(0);
  const [pillFilter, setPillFilter] = useState("all");

  const [dis, setDis] = useState([]);

  useEffect(() => {
    if (matches.length === 0) {
      generateBracket();
    }
  }, []);

  useEffect(() => {
    if (bracket.length === 0) {
    } else {
      if (false) {
      } else {
        const arr = [];

        let rI = 0;

        for (let i = 0; i < bracket.length; i++) {
          const matches = bracket[i].matches;
          let rdCompleted = true;
          for (let j = 0; j < matches.length; j++) {
            const match = matches[j];

            if (!match.completed) {
              rdCompleted = false;
            }
          }
          if (!rdCompleted) {
            rI = i;
            break;
          }
        }
        setRI(rI);
        // console.log(players);

        for (let j = 0; j < rI; j++) {
          for (let i = 0; i < bracket[j].matches.length; i++) {
            const match = bracket[j].matches[i];
            const { winnerID, teams } = match;
            if (winnerID === teams[0].teamID) {
              arr.push(teams[1].teamID);
            } else {
              arr.push(teams[0].teamID);
            }
          }
        }
        for (let i = 0; i < bracket[rI].matches.length; i++) {
          const match = bracket[rI].matches[i];
          const { teams, completed, started } = match;
          if (started || completed) {
            arr.push(teams[0].teamID);
            arr.push(teams[1].teamID);
          }
        }
        console.log(arr);
        setDis(arr);
      }
    }
  }, []);

  function generateBracket() {
    const shuffled = shuffle(teams);
    const rounded = Math.ceil(shuffled.length / 8) * 8;
    const rounds = Math.log2(shuffled.length);

    let counter = 1;
    const brackArr = [];

    for (let i = 0; i < rounds; i++) {
      const temp = {
        matches: [],
      };
      let matches = Math.ceil(shuffled.length / 2 / (i + 1));
      if (i === rounds - 1) {
        matches = 1;
      }
      for (let x = 0; x < matches; x++) {
        temp.matches.push({
          match: counter,
          teams: [],
          qualis: [],
          winnerID: "",
          winnerName: "",
          by: "",
          completed: false,
        });
        counter++;
      }
      brackArr.push(temp);
    }

    if (brackArr.length === 0) {
      return;
    }

    for (let i = 0; i < shuffled.length; i++) {
      let found = false;
      for (let x = 0; x < brackArr[0].matches.length; x++) {
        const teams = brackArr[0].matches[x].teams;
        if (found) {
          continue;
        }
        if (teams.length < 2) {
          brackArr[0].matches[x].teams.push(shuffled[i]);
          found = true;
        }
      }
    }

    for (let i = 0; i < brackArr[0].matches.length; i++) {
      if (brackArr[0].matches[i].teams.length === 0) {
        brackArr[0].matches[i].teams.push({
          players: [
            {
              name: "",
            },
            { name: "" },
          ],
        });
        brackArr[0].matches[i].teams.push({
          players: [
            {
              name: "",
            },
            { name: "" },
          ],
        });
      } else if (brackArr[0].matches[i].teams.length === 1) {
        brackArr[0].matches[i].teams.push({
          players: [
            {
              name: "",
            },
            { name: "" },
          ],
        });
      }
    }

    for (let i = 1; i < brackArr.length; i++) {
      const roundMatches = brackArr[i].matches;
      const prevRoundMatches = brackArr[i - 1].matches;
      const matchNums = prevRoundMatches.map((match) => match.match);
      for (let x = 0; x < roundMatches.length; x++) {
        brackArr[i].matches[x].teams.push({
          players: [
            { name: `Winner Match ${matchNums[x * 2]}` },
            { name: `Winner Match ${matchNums[x * 2]}` },
          ],
        });
        let cnt1 = x * 2;
        brackArr[i].matches[x].qualis.push(matchNums[cnt1]);
        brackArr[i].matches[x].teams.push({
          players: [
            { name: `Winner Match ${matchNums[2 * x + 1]}` },
            { name: `Winner Match ${matchNums[2 * x + 1]}` },
          ],
        });
        brackArr[i].matches[x].qualis.push(matchNums[2 * x + 1]);
      }
    }
    console.log(brackArr);
    setMatches(brackArr);
  }

  function shuffle(array) {
    // alert("everyday");
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }
  function removeNumbersAndParseInt(text) {
    // Remove any non-digit characters from the text
    const cleanText = text.replace(/\D/g, "");

    // Convert the cleaned text to an integer
    const parsedNumber = parseInt(cleanText, 10);

    // Return the parsed number as an integer
    return parsedNumber;
  }

  async function saveChanges() {
    setLoading(true);
    try {
      await db.collection("tournaments").doc(tournID).update({
        bracket: matches,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function completeMatch() {}

  async function manualFinish(i1, i2) {
    setShowManual(false);
    setLoading(true);
    const bracket = [...matches];
    const match = bracket[i1].matches[i2];
    const matchNum = match.match;
    let wt;
    if (match.teams[0].teamID === selWinner.teamID) {
      wt = match.teams[0];
    } else {
      wt = match.teams[1];
    }
    bracket[i1].matches[i2].winnerID = selWinner.teamID;
    bracket[i1].matches[i2].manual = true;
    bracket[i1].matches[i2].by = parseInt(manualBy);
    bracket[i1].matches[i2].rem = parseInt(manualRem);
    bracket[i1].matches[
      i2
    ].winnerName = `${selWinner.players[0].name} / ${selWinner.players[1].name}`;
    bracket[i1].matches[i2].completed = true;
    let nrMatches = [];
    if (i1 !== bracket.length - 1) {
      const nextRound = bracket[i1 + 1];
      nrMatches = nextRound.matches;
    }

    const winner = selWinner;

    for (let i = 0; i < nrMatches.length; i++) {
      const mtc = nrMatches[i];
      if (mtc.qualis.includes(matchNum)) {
        const { teams } = mtc;
        console.log(teams);
        const n1 = teams[0].players[0].name;
        console.log(removeNumbersAndParseInt(n1));
        if (removeNumbersAndParseInt(n1) === matchNum) {
          bracket[i1 + 1].matches[i].teams[0] = wt;
        } else {
          bracket[i1 + 1].matches[i].teams[1] = wt;
        }
      }
    }

    if (i1 === bracket.length - 1) {
      const { players } = wt;
      const wn = `${players[0].name} / ${players[1].name}`;
      selWinner.name = wn;
    }

    const res = {
      date: new Date(),
      teamOne: match.teams[0],
      teamTwo: match.teams[1],
      by: parseInt(manualBy),
      winner,
      roundID: match.roundID ?? "",
    };
    // setLoading(false);
    // return console.log(bracket);

    try {
      if (i1 === bracket.length - 1) {
        await db
          .collection("tournaments")
          .doc(tournID)
          .update({
            bracket,
            winnerID: selWinner.teamID,
            winnerName: selWinner.name,
            completed: true,
            results: admin.firestore.FieldValue.arrayUnion(res),
          });
      } else {
        await db
          .collection("tournaments")
          .doc(tournID)
          .update({
            bracket,
            results: admin.firestore.FieldValue.arrayUnion(res),
          });
      }

      if (match.roundID) {
        for (let i = 0; i < match.teams.length; i++) {
          const team = match.teams[i];
          for (let x = 0; x < team.players.length; x++) {
            const player = team.players[x];
            await db.collection("users").doc(player.playerID).update({
              activeRoundID: "",
            });
          }
        }
      }
      setManualBy(0);
      setManualRem(0);
      setManMatch();
      setManIndex([]);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  async function savePostChanges() {
    setLoading(true);
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tourn = tournDoc.data();

    const { bracket } = tourn;

    const brackRd = bracket[ri].matches;
    const matchRd = matches[ri].matches;

    for (let i = 0; i < brackRd.length; i++) {
      if (!brackRd[i].started && !brackRd[i].completed) {
        bracket[ri].matches[i] = matchRd[i];
      }
    }

    // console.log(bracket);
    await tournDoc.ref.update({
      bracket,
    });
    setLoading(false);
    goBack();
  }

  return (
    <div className="mpl-bracket mpl-team-bracket">
      <Dialog open={showManual} onClose={() => setShowManual(false)}>
        <div className="mpb-manual pd-40">
          <PageHeader
            close={() => setShowManual(false)}
            text="Manual Input"
            showClose
          />
          <div className="ph-msg mb-20">
            <p>Select the player who won this game </p>
          </div>
          {manMatch && (
            <div className="mpb-match">
              <PlayerItem
                showBg={
                  selWinner && selWinner.teamID === manMatch.teams[0].teamID
                }
                showProfile={() => setSelWinner(manMatch.teams[0])}
                hideRemove
                // hcp={manMatch.players[0].handicap}
                hideImage
                hideHCP
                showAdd
                hideMessage
                hideProfile
                noLink
                hideIcons
                name={`${manMatch.teams[0].players[0].name} / ${manMatch.teams[0].players[1].name}`}
              />
              <div className="mp-cir-box">
                <div className="mpbm-circle">
                  <p>vs</p>
                </div>
              </div>

              <PlayerItem
                showBg={
                  selWinner && selWinner.teamID === manMatch.teams[1].teamID
                }
                hideHCP
                showProfile={() => {
                  setSelWinner(manMatch.teams[1]);
                }}
                hideRemove
                // hcp={manMatch.players[1].handicap}
                hideImage
                hideIcons
                showAdd
                hideMessage
                hideProfile
                noLink
                name={`${manMatch.teams[1].players[0].name} / ${manMatch.teams[1].players[1].name}`}
              />
            </div>
          )}
          <div className="mp-man-holes">
            <div className="input-group">
              <p>Holes Up</p>
              <input
                type="number"
                onChange={(e) => setManualBy(e.target.value)}
                value={manualBy}
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Holes Remaining</p>
              <input
                type="number"
                onChange={(e) => setManualRem(e.target.value)}
                value={manualRem}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center">
            <button
              disabled={
                selWinner === undefined || (manualRem === 0 && manualBy === 0)
              }
              className="default-button"
              onClick={() => manualFinish(manIndex[0], manIndex[1])}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={showSelector} onClose={() => setShowSelector(false)}>
        <div className="mpl-selector">
          <PageHeader
            text="Select a team for this slot"
            showClose
            close={() => setShowSelector(false)}
          />
          <div className="mpls-players">
            {playerToExclude &&
              playerToSwap &&
              teams.map((team) => {
                if (dis.includes(team.teamID)) {
                  return null;
                }
                if (playerToExclude) {
                  //   console.log(playerToExclude.teamID === team.teamID);

                  if (
                    team.teamID === playerToExclude.teamID ||
                    playerToSwap.teamID === team.teamID
                  ) {
                    return null;
                  }
                }

                const teamName = `${team.players[0].name} / ${team.players[1].name}`;

                return (
                  <PlayerItem
                    key={`${team.teamID}sel`}
                    hideImage
                    hideIcons
                    noLink
                    hideRemove
                    hideHCP
                    //   hcp={player.handicap}
                    name={teamName}
                    //   img={player.profileImage}
                    showProfile={() => {
                      console.log(matchEdit);
                      setMatches((current) => {
                        const temp = [...current];
                        // console.log(temp);
                        for (let i = 0; i < temp[0].matches.length; i++) {
                          const match = temp[0].matches[i];
                          if (match.match === matchEdit) {
                            // console.log(match);
                            if (match.teams[0].teamID === playerToSwap.teamID) {
                              // console.log("a");
                              // console.log(player);
                              temp[0].matches[i].teams[0] = team;
                            } else if (
                              match.teams[1].teamID === playerToSwap.teamID
                            ) {
                              temp[0].matches[i].teams[1] = team;
                              // console.log("b");
                            }
                          }
                        }

                        let found = false;

                        for (let i = 0; i < temp[0].matches.length; i++) {
                          const match = temp[0].matches[i];
                          if (found || match.match === matchEdit) {
                            continue;
                          }
                          if (match.teams[0].teamID === team.teamID) {
                            // console.log("aye", match.match);
                            temp[0].matches[i].teams[0] = playerToSwap;
                            found = true;
                          } else if (match.teams[1].teamID === team.teamID) {
                            console.log("bee", match.match);
                            temp[0].matches[i].teams[1] = playerToSwap;
                            found = true;
                          }
                        }
                        // console.log(temp);
                        return temp;
                      });
                      setUpdate((current) => current + 1);
                      setShowSelector(false);
                    }}
                  />
                );
              })}
          </div>
        </div>
      </Dialog>
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Tournament Bracket" />
      <div className="mtp-pills">
        <NewsPill
          text={"All"}
          active={pillFilter === "all"}
          handleClick={() => setPillFilter("all")}
        />
        {matches.map((round, index) => (
          <NewsPill
            text={index === matches.length - 1 ? "Final" : `Round ${index + 1}`}
            active={pillFilter === index}
            handleClick={() => setPillFilter(index)}
            key={`${index}pill`}
          />
        ))}
      </div>
      {!started && (
        <>
          <div className="input-group pl-20">
            <p
              onClick={() => {
                generateBracket();
              }}
              className="green-link shuffle-players"
            >
              Shuffle
            </p>
          </div>
        </>
      )}
      {!started && (
        <div className="mb-0 ml-20">
          <button className="default-button" onClick={saveChanges}>
            Save Changes
          </button>
        </div>
      )}
      {started && changed && (
        <div className="flex-center mt-40 mb-40">
          <button
            className="default-button"
            onClick={() => {
              savePostChanges();
            }}
          >
            Save Changes
          </button>
        </div>
      )}
      <div className="mpl-fixtures">
        {matches.map((round, index) => {
          const roundMatches = round.matches;
          if (pillFilter !== "all") {
            if (index !== pillFilter) {
              return null;
            }
          }
          return (
            <div className="mpl-edit" key={`${index}round`}>
              <div className="mpl-round-header">
                {index === matches.length - 1 ? (
                  <h2>Final</h2>
                ) : (
                  <h2>Round {index + 1}</h2>
                )}
              </div>
              <div className="mpl-matches">
                {roundMatches.map((match, index2) => {
                  //   console.log(match);
                  const t1 = match.teams[0];
                  const t2 = match.teams[1];
                  //   console.log(t1, t2);

                  let nameOne = "";
                  let nameTwo = "";
                  let showEdit = false;
                  if (match.completed === false) {
                    showEdit = true;
                  }

                  nameOne = `${t1.players[0].name} / ${t1.players[1].name}`;
                  nameTwo = `${t2.players[0].name} / ${t2.players[1].name}`;

                  if (t1.players[0].name.includes("Winner Match")) {
                    nameOne = t1.players[0].name;
                    showEdit = false;
                  }
                  if (t2.players[0].name.includes("Winner Match")) {
                    nameTwo = t2.players[0].name;
                    showEdit = false;
                  }

                  let showBye = false;

                  return (
                    <div
                      className="mpl-doubles-match mpb-match"
                      key={`${index2}match`}
                    >
                      <PlayerItem
                        noLink
                        hideHCP
                        name={nameOne}
                        showAdd
                        hideProfile
                        hideRemove
                        hideEdit={
                          match.winnerID !== "" || match.started || index !== ri
                        }
                        hideMessage
                        editAction={() => {
                          setMatchEdit(match.match);
                          setPlayerToSwap(t1);
                          setPlayerToExclude(t2);
                          setShowSelector(true);
                        }}
                      />
                      <div className="mp-cir-box">
                        {showBye && (
                          <Tooltip title="Complete Match">
                            <p
                              onClick={() => completeMatch(index, index2)}
                              className="green-link mb-10"
                            >
                              <CheckCircle />
                            </p>
                          </Tooltip>
                        )}
                        {match.roundID && !match.completed && (
                          <RssFeedIcon
                            onClick={() =>
                              window.open(
                                `https://www.theugatour.com/rounds/${match.roundID}`,
                                "_blank"
                              )
                            }
                            className="dg-icon icon-cursor mb-10"
                          />
                        )}
                        {match.roundID && match.completed && (
                          <GolfCourseIcon
                            onClick={() =>
                              window.open(
                                `https://www.theugatour.com/rounds/${match.roundID}`,
                                "_blank"
                              )
                            }
                            className="dg-icon icon-cursor mb-10"
                          />
                        )}
                        <div className="mpbm-circle">
                          {match.winnerID === "" ? (
                            <p>vs</p>
                          ) : match.by === "BYE" ? (
                            <p>BYE</p>
                          ) : (
                            <>
                              {match.winnerID === t1.teamID ? (
                                <p>
                                  {match.rem === 0
                                    ? `${match.by} UP`
                                    : `${match.by} & ${match.rem}`}
                                </p>
                              ) : (
                                <p>
                                  {match.rem === 0
                                    ? `${match.by} DN`
                                    : `${match.by} & ${match.rem}`}
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        {showEdit && started && (
                          <EditIcon
                            onClick={() => {
                              setManMatch(match);
                              setManIndex([index, index2]);
                              setShowManual(true);
                            }}
                            className="icon-cursor dg-icon-bg mt-10"
                          />
                        )}
                      </div>
                      <PlayerItem
                        hideHCP
                        noLink
                        name={nameTwo}
                        showAdd
                        hideProfile
                        hideRemove
                        hideEdit={
                          match.winnerID !== "" || match.started || index !== ri
                        }
                        hideMessage
                        editAction={() => {
                          setMatchEdit(match.match);
                          setPlayerToSwap(t2);
                          setPlayerToExclude(t1);
                          setShowSelector(true);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MatchplayTeamBracket;
