import React, { useEffect, useState } from "react";
import "./CustomFormats.css";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Close from "@mui/icons-material/Close";
import { Save } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// Components
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import RadioGroupTwo from "../Buttons/RadioGroup/RadioGroupTwo";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Dialog, Tooltip } from "@mui/material";
import ErrorModal from "../ErrorModal/ErrorModal";
import RadioGroup from "../Buttons/RadioGroup/RadioGroup";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function CustomFormats({
  goBack = () => {},
  eventID = "",
  courseID = "",
  defaultSelected = "formula",
  defaultToCount = 2,
  defaultFormulas = [],
  defaultCourseCard = [],
  saveChanges = () => {},
  reload = () => {},
}) {
  const { currentUser } = useAuth();
  // State
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(defaultSelected);
  const [update, setUpdate] = useState(0);
  const [error, setError] = useState("");
  const [showAdd, setShowAdd] = useState(false);

  const [selPreset, setSelPreset] = useState("");
  const [ownPresets, setOwnPresets] = useState([]);
  const [ownMap, setOwnMap] = useState({});
  const [showEdit, setShowEdit] = useState(false);

  const [selPreMade, setSelPreMade] = useState("");

  const [nameInput, setNameInput] = useState("");

  // Formula State
  const [toCount, setToCount] = useState(defaultToCount);
  const [formulas, setFormulas] = useState(defaultFormulas);
  const [selectedPars, setSelectedPars] = useState([]);

  // Hole by hole state

  const [courseCard, setCourseCard] = useState(defaultCourseCard);

  const presets = [
    {
      label: "1, 2, 3",
      value: "123",
    },
    {
      label: "6, 6, 6",
      value: "666",
    },
    {
      label: "Random",
      value: "random",
    },
    {
      label: "Average",
      value: "average",
    },
  ];

  const parOptions = [
    {
      value: "par3",
      label: "Par 3s",
    },
    {
      value: "par4",
      label: "Par 4s",
    },
    {
      value: "par5",
      label: "Par 5s",
    },
  ];

  // UE
  useEffect(() => {
    console.log(defaultCourseCard.length);
    if (defaultCourseCard.length === 0) {
      load();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (defaultSelected === "") {
      setSelected("holes");
    }
    if (currentUser) {
      loadPresets();
    }
  }, []);

  async function load() {
    // CourseDoc
    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const { tees, teeArray } = course;

    const card = tees[teeArray[0]];

    for (let i = 0; i < card.length; i++) {
      card[i].toCount = 2;
      card[i].type = "best";
    }

    setCourseCard(card);

    setLoading(false);
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function randomHoles() {
    const arr = ["best", "worst", "comb"];
    setCourseCard((current) => {
      for (let i = 0; i < current.length; i++) {
        let toCount = getRandomInt(1, 4);
        const type = getRandomInt(0, 2);
        if (arr[type] === "comb") {
          toCount = 2;
        }
        current[i].toCount = toCount;
        current[i].type = arr[type];
      }
      return current;
    });
    setUpdate((current) => current + 1);
  }

  function handlePreset(preset) {
    if (preset === "random") {
      return randomHoles();
    }

    if (preset === "average") {
      setCourseCard((current) => {
        for (let i = 0; i < current.length; i++) {
          current[i].type = "comb";
        }
        return current;
      });
    }

    if (preset === "123") {
      setCourseCard((current) => {
        let currentCount = 0;
        for (let i = 0; i < current.length; i++) {
          current[i].type = "best";
          if (currentCount === 0) {
            current[i].toCount = 1;
            currentCount += 1;
            continue;
          }
          if (currentCount === 1) {
            current[i].toCount = 2;
            currentCount += 1;
            continue;
          }
          if (currentCount === 2) {
            current[i].toCount = 3;
            currentCount = 0;
            continue;
          }
        }
        return current;
      });
    }

    if (preset === "666") {
      setCourseCard((current) => {
        for (let i = 0; i < current.length; i++) {
          current[i].type = "best";

          if (i < 6) {
            current[i].toCount = 1;
          }
          if (i > 5 && i < 12) {
            current[i].toCount = 2;
          }
          if (i > 11) {
            current[i].toCount = 3;
          }
        }
        return current;
      });
    }

    setUpdate((current) => current + 1);
  }

  async function saveFormula() {
    setLoading(true);

    try {
      await db.collection("events").doc(eventID).update({
        teamType: "custom",
        customType: selected,
        formulas,
        toCount,
      });
      goBack();
      reload();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
  }

  async function saveHoles() {
    setLoading(true);
    try {
      await db.collection("events").doc(eventID).update({
        teamType: "custom",
        customType: selected,
        customHoles: courseCard,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  function handleOwnPreset(preset) {
    const pre = ownMap[preset];
    setSelected(pre.customType);
    if (pre.customType === "formula") {
      setFormulas(pre.formulas);
      setToCount(pre.toCount);
    } else {
      setCourseCard(pre.customHoles);
    }
  }

  async function loadPresets() {
    const userDoc = await db.collection("users").doc(currentUser.uid).get();
    const user = userDoc.data();
    const formatPresets = user.customFormats ?? [];
    const mep = {};
    for (let i = 0; i < formatPresets.length; i++) {
      const preset = formatPresets[i];
      const { value } = preset;
      mep[value] = preset;
    }
    console.log(formatPresets);
    setOwnMap(mep);
    setOwnPresets(formatPresets);
  }

  async function savePreset() {
    setLoading(true);
    setShowAdd(false);
    const id = makeID(10);
    const newPreset = {
      customType: selected,
      label: nameInput,
      value: id,
      presetID: id,
      customHoles: courseCard,
      formulas,
      toCount,
    };
    try {
      await db
        .collection("users")
        .doc(currentUser.uid)
        .update({
          customFormats: admin.firestore.FieldValue.arrayUnion(newPreset),
        });
      setNameInput("");
      setOwnPresets((current) => [...current, newPreset]);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function editPreset(presetID) {
    setLoading(true);
    try {
      let newPresets;
      setOwnPresets((current) => {
        for (let i = 0; i < current.length; i++) {
          if (current[i].label === presetID) {
            current[i].toCount = toCount;
            current[i].customHoles = courseCard;
            current[i].customType = selected;
            current[i].formulas = formulas;
          }
        }
        newPresets = current;
        return current;
      });
      await db.collection("users").doc(currentUser.uid).update({
        customFormats: newPresets,
      });
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  function resetValues() {
    setLoading(true);
    setCourseCard((current) => {
      for (let i = 0; i < current.length; i++) {
        current[i].toCount = 2;
      }
      return current;
    });
    setFormulas([]);
    setSelPreset("");
    setSelPreMade("");
    setToCount(2);
    setLoading(false);
  }

  async function deletePreset(presetID) {
    setShowEdit(false);
    setLoading(true);
    try {
      const newPresets = ownPresets.filter((pre) => pre.presetID !== presetID);

      await db.collection("users").doc(currentUser.uid).update({
        customFormats: newPresets,
      });

      setOwnPresets(newPresets);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
    setUpdate((c) => c + 1);
  }

  return (
    <div className="custom-formats">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <BackRow action={goBack} />
      <PageHeader text="Format Customizer" />
      <div className="ph-msg mb-20">
        <p>Customize your team format for this event</p>
      </div>
      <div className="flex-align-center mb-40 cf-opts">
        <RadioGroupTwo
          active={selected}
          setActive={setSelected}
          buttons={[
            { text: "Formula", value: "formula" },
            { text: "Hole By Hole", value: "holes" },
          ]}
        />
        {ownPresets.length > 0 && (
          <div className="input-group pl-20 ml-40 mt-0">
            <p>Own Presets</p>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              onChange={(e) => {
                handleOwnPreset(e.value);
                setSelPreset(e.value);
              }}
              value={selPreset}
              // value={formula.par}
              options={ownPresets}
            />
          </div>
        )}

        <div className="cf-tools">
          <Tooltip title="Save New Preset">
            <Save
              onClick={() => setShowAdd(true)}
              className={classNames("icon-cursor dg-icon-bg mt-10", {
                "ml-40": !isMobile,
              })}
            />
          </Tooltip>
          {selPreset !== "" && (
            <Tooltip title="Overwrite Preset">
              <SaveAsIcon
                onClick={() => editPreset(selPreset)}
                className="icon-cursor dg-icon-bg ml-20 mt-10"
              />
            </Tooltip>
          )}
          <Tooltip title="Edit Presets">
            <Edit
              onClick={() => setShowEdit(true)}
              className="icon-cursor red-icon-bg ml-20 mt-10"
            />
          </Tooltip>
          {selPreset !== "" && (
            <Tooltip title="New Format">
              <RestartAltIcon
                onClick={() => resetValues()}
                className="icon-cursor reset-ico dg-icon-bg ml-20 mt-10"
              />
            </Tooltip>
          )}
        </div>
      </div>

      <Dialog open={showEdit} onClose={() => setShowEdit(false)}>
        <div className="edit-presets pd-20">
          <PageHeader
            text="Edit Presets"
            close={() => setShowEdit(false)}
            showClose
          />
          <div className="ep-items pd-20">
            {ownPresets.map((pre, index) => (
              <div key={`${index}preset`} className="ep-item mb-10">
                <h5>{pre.label}</h5>
                <DeleteForeverIcon
                  onClick={() => {
                    console.log(pre);
                    deletePreset(pre.presetID);
                  }}
                  className=" icon-cursor red-icon-bg ml-20"
                />
              </div>
            ))}
            {ownPresets.length === 0 && (
              <div className="no-msg">
                <p>No items to display</p>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <Dialog open={showAdd} onClose={() => setShowAdd(false)}>
        <div className="add-preset">
          <PageHeader
            text="Save Format"
            close={() => setShowAdd(false)}
            showClose
          />
          <div className="ec-box">
            <div className="input-group">
              <p>Preset Name</p>
              <input
                className="default-input"
                type={"text"}
                placeholder="Enter name here..."
                value={nameInput}
                onChange={(e) => setNameInput(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-center mb-40">
            <button
              onClick={savePreset}
              disabled={nameInput === ""}
              className="default-button"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>

      {selected === "formula" && (
        <>
          <div className="cff-top">
            <div className="cf-default input-group nassau-group">
              <p>Default to count per hole</p>
              <div className="cf-dc">
                <RemoveCircleIcon
                  onClick={() =>
                    setToCount((current) => {
                      if (current === 1) {
                        return 1;
                      } else {
                        return current - 1;
                      }
                    })
                  }
                  className="icon-cursor dg-icon"
                />
                <h5>{toCount}</h5>
                <AddCircleIcon
                  onClick={() => {
                    setToCount((current) => {
                      if (current === 4) {
                        return 4;
                      } else {
                        return current + 1;
                      }
                    });
                  }}
                  className="icon-cursor dg-icon"
                />
              </div>
            </div>
            <button
              onClick={() => {
                const newForm = {
                  toCount: 2,
                  par: "",
                };
                setFormulas((current) => [...current, newForm]);
              }}
              className="default-button cf-button"
            >
              Add Rule
            </button>
          </div>
          <div className="cf-formulas">
            {formulas.map((formula, index) => {
              return (
                <div key={`${index}formula`} className="cf-formula">
                  <div className="cf-default input-group nassau-group mr-5v">
                    <p>Count</p>
                    <div className="cf-dc">
                      <RemoveCircleIcon
                        onClick={() => {
                          if (formula.toCount > 1) {
                            setFormulas((current) => {
                              current[index].toCount -= 1;
                              return current;
                            });
                            setUpdate((current) => current + 1);
                          }
                        }}
                        className="icon-cursor dg-icon"
                      />
                      <h5>{formula.toCount}</h5>
                      <AddCircleIcon
                        onClick={() => {
                          if (formula.toCount < 4) {
                            setFormulas((current) => {
                              current[index].toCount += 1;
                              return current;
                            });
                            setUpdate((current) => current + 1);
                          }
                        }}
                        className="icon-cursor dg-icon"
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <Dropdown
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      onChange={(e) => {
                        setFormulas((current) => {
                          current[index].par = e.value;
                          return current;
                        });
                        setUpdate((current) => current + 1);
                      }}
                      value={formula.par}
                      options={parOptions}
                    />
                  </div>
                  <div className="flex-center cf-icon">
                    <DeleteForeverIcon
                      onClick={() => {
                        console.log("splicing");
                        setFormulas((current) => {
                          current.splice(index, 1);
                          console.log(current);
                          return current;
                        });
                        setUpdate((current) => current + 1);
                      }}
                      className="red-icon icon-cursor"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <button
            onClick={() => {
              const usedArr = [];
              for (let i = 0; i < formulas.length; i++) {
                const { par } = formulas[i];
                if (usedArr.includes(par)) {
                  return setError(
                    "Please ensure to use different pars for each rule"
                  );
                } else if (par === "") {
                  return setError(
                    "Please ensure to select a par for each rule"
                  );
                } else {
                  usedArr.push(par);
                }
              }

              if (eventID) {
                saveFormula();
              }
            }}
            className="default-button ml-20"
          >
            Save Changes
          </button>
        </>
      )}

      {selected === "holes" && (
        <>
          <div className="input-group pl-20 pres-group">
            <p>Default Presets</p>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              onChange={(e) => {
                setSelPreMade(e.value);
                handlePreset(e.value);
              }}
              value={selPreMade}
              options={presets}
            />
          </div>

          <div className="cf-holes">
            {courseCard.map((hole, index) => {
              if (index === 0) {
                // console.log(hole);
              }
              return (
                <div key={`hole${index}`} className="cf-hole">
                  <div className="cfh-deets">
                    {isMobile ? <p>{index + 1}.</p> : <p>Hole {index + 1}</p>}
                    <p>Par {hole.par}</p>
                    <p className="cf-stroke">Stroke {hole.stroke}</p>
                  </div>

                  <div className="cf-default input-group nassau-group mr-5v">
                    <p className="cf-stroke">Count</p>
                    <div className="cf-dc">
                      <RemoveCircleIcon
                        onClick={() => {
                          if (hole.toCount > 1 && hole.type !== "comb") {
                            setCourseCard((current) => {
                              current[index].toCount -= 1;
                              return current;
                            });
                            setUpdate((current) => current + 1);
                          }
                        }}
                        className="icon-cursor dg-icon"
                      />
                      <h5>{hole.toCount}</h5>
                      <AddCircleIcon
                        onClick={() => {
                          if (hole.toCount < 4 && hole.type !== "comb") {
                            setCourseCard((current) => {
                              current[index].toCount += 1;
                              return current;
                            });
                            setUpdate((current) => current + 1);
                          }
                        }}
                        className="icon-cursor dg-icon"
                      />
                    </div>
                  </div>
                  <div className="cfh-type">
                    <RadioGroup
                      setActive={(e) => {
                        setCourseCard((current) => {
                          if (e === "comb") {
                            current[index].type = e;
                            current[index].toCount = 2;
                          } else {
                            current[index].type = e;
                          }
                          return current;
                        });
                        setUpdate((current) => current + 1);
                      }}
                      buttons={[
                        { text: "Best", value: "best" },
                        { text: "Worst", value: "worst" },
                        { text: "Combined", value: "comb" },
                      ]}
                      active={hole.type ?? "best"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <button
            onClick={() => {
              console.log(eventID);
              if (eventID) {
                saveHoles();
              } else {
                saveChanges(selected, toCount, formulas, courseCard);
              }
            }}
            className="default-button ml-20 mb-40"
          >
            Save Changes
          </button>
        </>
      )}
    </div>
  );
}

export default CustomFormats;
