import React, { useState } from "react";
import "./Eclectic.css";
// Icons

// Components
import EclecticHeader from "./EclecticHeader";
import LogItem from "../display/LeagueLogs/LogItem/LogItem";

function EclecticLog({ leagueLog = [], courseHoles = [] }) {
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  // console.log(leagueLog)
  return (
    <div className="eclectic-log pl-20">
      {show ? (
        toShow
      ) : (
        <>
          <EclecticHeader />
          {leagueLog.map((player, index) => {
            return (
              <LogItem
                key={player.playerID}
                rank={player.ranking ?? index + 1}
                name={player.userName}
                played={player.wins}
                wins={player.gross}
                points={player.net}
                ave={player.stableford}
                handleClick={() => {}}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

export default EclecticLog;
