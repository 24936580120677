import React, { useState, useEffect } from "react";
import "./Home.css";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Icons
import BorderAllIcon from "@mui/icons-material/BorderAll";

// Components
import { Oval } from "react-loader-spinner";
import FixturePreview from "../../components/display/FixturePreview/FixturePreview";
import InviteModal from "../../components/Events/InviteModal/InviteModal";
import EventHome from "../../components/Events/EventCreator/EventHome";
import EventRounds from "../../components/Events/EventCreator/EventRounds";
import TeamLeagueEventHome from "../../components/ClubLeagues/TeamLeagueEventHome";

function HomeEvents({ setToShow = () => {}, setShow = () => {} }) {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      loadEvents();
    }
  }, [currentUser]);

  async function loadEvents() {
    const leagues = await db
      .collection("leagues")
      .where("admins", "array-contains", currentUser.uid)
      .get();

    const now = new Date();

    const standalones = await db
      .collection("events")
      .where("standalone", "==", true)
      .where("adminID", "==", currentUser.uid)
      .where("eventDate", ">", now)
      .get();

    const arr = [];

    for (let i = 0; i < leagues.docs.length; i++) {
      const leagueDoc = leagues.docs[i];
      const leagueID = leagueDoc.id;

      const events = await db
        .collection("events")
        .where("leagueID", "==", leagueID)
        .where("eventDate", ">", now)
        .get();
      for (let j = 0; j < events.docs.length; j++) {
        const eventDoc = events.docs[j];
        const eventID = eventDoc.id;
        const event = eventDoc.data();
        if (event.cancelled) {
          continue;
        }
        const eventDate = new Date(event.eventDate.toDate());
        const dateString = eventDate.toLocaleDateString("en-us", {
          dateStyle: "medium",
        });
        event.eventDate = eventDate;
        event.dateString = dateString;
        event.eventID = eventID;
        arr.push(event);
      }
    }

    for (let j = 0; j < standalones.docs.length; j++) {
      const eventDoc = standalones.docs[j];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      if (event.cancelled) {
        continue;
      }
      const eventDate = new Date(event.eventDate.toDate());
      const eventDateString = eventDate.toLocaleDateString("en-us", {
        dateStyle: "medium",
      });
      event.eventDate = eventDate;
      event.dateString = eventDateString;
      event.eventDateString = eventDateString;
      event.start = eventDate;
      const end = new Date(eventDate);
      end.setHours(end.getHours() + 6);
      event.end = end;
      event.title = event.eventName;
      event.eventID = eventID;
      if (event.roundIDs.length > 0 && event.completed === false) {
        event.active = true;
      } else {
        event.active = false;
      }

      arr.push(event);
    }

    const sorted = arr.sort((a, b) => a.eventDate - b.eventDate);
    setEvents(sorted);
    setLoading(false);
  }

  return (
    <div className="home-events">
      <div
        onClick={() => navigate("/calendar")}
        className="page-header ph-link"
      >
        <h2>Upcoming Events</h2>
      </div>
      {showModal && modal}
      {loading ? (
        <div className="he-loader">
          <Oval
            color="#1e7a69"
            secondaryColor="#ffffff"
            height={40}
            width={40}
          />
        </div>
      ) : (
        <>
          {events.length > 0 && (
            <div className="scale-in-ps pb-20">
              {events.map((event) => (
                <FixturePreview
                  showLive={event.roundIDs.length > 0}
                  key={event.eventID}
                  event={event}
                  hideRounds={event.teamEvent}
                  timeAction={() => {
                    setToShow(
                      <EventRounds
                        eventID={event.eventID}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                  editAction={() => {
                    if (event.teamEvent) {
                      setToShow(
                        <TeamLeagueEventHome
                          eventID={event.eventID}
                          goBack={() => setShow(false)}
                        />
                      );
                    } else {
                      setToShow(
                        <EventHome
                          eventID={event.eventID}
                          goBack={() => setShow(false)}
                        />
                      );
                    }
                    setShow(true);
                  }}
                  personAction={() => {
                    setModal(
                      <InviteModal
                        eventID={event.eventID}
                        hide={() => setShowModal(false)}
                      />
                    );
                    setShowModal(true);
                  }}
                />
              ))}
            </div>
          )}
          {events.length === 0 && (
            <div className="no-msg">
              <p>No upcoming events</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default HomeEvents;
