import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { auth, db } from "../../firebase";
import Papa from "papaparse";
import "./UserImport.css";
import { useAuth } from "../../contexts/AuthContext";
import { ctCodes } from "../HelperFunctions";
// Icons

// Components
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import csvexample from "../../assets/BulkExample.csv";
import BIPlayer from "./BIPlayer";
import { Dialog } from "@mui/material";

function BulkImport({ goBack = () => {}, clubID = "" }) {
  const { currentUser, userDetails } = useAuth();

  // State
  const [upload, setUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadName, setUploadName] = useState("");
  const [error, setError] = useState("");
  const [uploadJSON, setUploadJSON] = useState([]);
  const [complete, setComplete] = useState(false);
  const [update, setUpdate] = useState(0);
  const [showCodes, setShowCodes] = useState(false);
  const [codeSearch, setCodeSearch] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setLoading(true);
    const file = acceptedFiles[0];
    console.log(acceptedFiles[0].type);
    if (acceptedFiles[0].type !== "text/csv") {
      return setError("Please ensure to upload only .csv files");
    }
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        const arr = [];
        if (res.data.length > 100 && userDetails.accountType !== "admin") {
          return setError("Maximum 100 players");
        }
        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];
          // console.log(row);
          // console.log(row.length);
          // console.log(row.length < 12);
          if (row.length < 12) {
            continue;
            // return setError("Please ensure your file has at least 12 columns");
          }
          if (row[6].length > 2) {
            return setError(
              "Please ensure your country codes consist of only two letters."
            );
          }

          let gd = row[12] ?? "male";
          if (gd !== "male" && gd !== "female") {
            gd = "male";
          }

          const lowHI = row[13] ? parseFloat(row[13]) : parseFloat(row[2]);
          const playerClubName = row[14] ?? "";

          const temp = {
            firstName: row[0],
            lastName: row[1],
            emailAddress: row[2],
            handicap: parseFloat(row[3]),
            password: row[4],
            dob: row[5],
            country: row[6],
            state: row[7],
            city: row[8],
            hnaID: row[9],
            platform: row[10],
            platformID: row[11],
            clubID,
            addedBy: currentUser.uid,
            // clubHNAID: row[12] ?? "",
            gender: gd,
            lowHI,
            playerClubName,
          };
          arr.push(temp);
        }
        console.log(arr);
        setUploadName(file.name);

        setUpload(res);
        // setUploadJSON(arr);
        setUpdate((c) => c + 1);
        checkEmails(arr);
      },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "text/csv": [] },
  });

  async function saveImport() {
    setLoading(true);

    try {
      await db.collection("admin").doc("items").collection("imports").add({
        dateAdded: new Date(),
        imports: uploadJSON,
        userID: currentUser.uid,
      });
      setComplete(true);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function checkEmails(players = []) {
    // const players = [...uploadJSON];
    console.log(players.length);

    const promises = players.map(async (player) => {
      const signInMethods = await auth.fetchSignInMethodsForEmail(
        player.emailAddress
      );
      return { email: player.emailAddress, exists: signInMethods.length > 0 };
    });

    const trig = await Promise.all(promises);
    console.log(trig);

    for (let i = 0; i < trig.length; i++) {
      const res = trig[i];
      if (res.exists === true) {
        players[i].exists = true;
      }
    }
    console.log(players);
    setUploadJSON(players);

    setLoading(false);

    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      const { emailAddress } = player;
      // const signInMethods = await auth.fetchSignInMethodsForEmail(emailAddress);
      // console.log(signInMethods);
    }
  }

  return (
    <div className="bulk-import">
      {loading && <BackdropLoader />}
      <Dialog open={showCodes} onClose={() => setShowCodes(false)}>
        <div className="bi-codes">
          <PageHeader
            text="Country Codes"
            showClose
            close={() => setShowCodes(false)}
          />
          <div className="input-group pl-20">
            <p>Search for Country</p>
            <input
              value={codeSearch}
              onChange={(e) => setCodeSearch(e.target.value)}
              className="default-input"
              placeholder="Search..."
            />
          </div>

          <div className="bic-cts pd-20">
            <div className="bicc-item bicc-head">
              <div className="bicc-ct">
                <p>Country</p>
              </div>
              <div className="bicc-cd">
                <p>Code</p>
              </div>
            </div>
            {Object.entries(ctCodes).map((code, i) => {
              const country = code[0];
              const cd = code[1];

              if (codeSearch !== "") {
                const sl = codeSearch.toLowerCase();
                const cl = country.toLowerCase();

                if (!cl.includes(sl)) {
                  return null;
                }
              }

              return (
                <div className="bicc-item">
                  <div className="bicc-ct">
                    <p>{country}</p>
                  </div>
                  <div className="bicc-cd">
                    <p>{cd}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader text="Bulk User Import" />
      {clubID && (
        <div className="ph-msg">
          <p>Players will automatically be added to your club</p>
        </div>
      )}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {complete ? (
        <>
          <div className="bi-complete flex-center-col">
            <h1 className="big-green-header mt-40">Successfully Uploaded</h1>
            <p className="mt-20">
              You will receive a notification once the process is complete
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="bi-box">
            <div className="bi-drop pd-20">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-sel">
                  <h5>
                    {uploadName
                      ? uploadName
                      : "Drag 'n' drop a .csv file here, or click to open up the file explorer"}
                  </h5>
                </div>
              </div>
            </div>
            <div className="bi-info">
              <p>
                In order for the accounts to be created correctly, formatting is
                important! Please ensure that each row has 12 fields, as
                indicated below. The first row (header row) of your file{" "}
                <strong>will be ignored.</strong>
              </p>
              <ul>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Email Address</li>
                <li>Handicap</li>
                <li>Password</li>
                <li>Date of birth (YYYY/MM/DD)</li>
                <li>2-letter Country Code (e.g. ZA for South Africa)</li>
                <li>State / Province</li>
                <li>City</li>
                <li>Handicap ID</li>
                <li>Software Platform (e.g. Clubmaster)</li>
                <li>Platform ID</li>
                <li>Gender (male/female)</li>
              </ul>
              <div className="flex-ac mt-20">
                <a href={csvexample} className="green-link">
                  Download Sample
                </a>
                <p
                  onClick={() => setShowCodes(true)}
                  className="green-link ml-20"
                  style={{ width: "max-content" }}
                >
                  View Country Codes
                </p>
              </div>
            </div>
          </div>
          <div className="flex-center mb-40 mt-40">
            <button
              onClick={saveImport}
              disabled={uploadJSON.length === 0}
              className="default-button"
            >
              Save
            </button>
          </div>
          {uploadJSON.length > 0 && (
            <>
              <div className="pn-header pl-20">
                <h1>{uploadJSON.length} players</h1>
              </div>
            </>
          )}

          {false && (
            <div className="player-names">
              {uploadJSON.map((player, index) => (
                <p key={index}>
                  {player.firstName} {player.lastName}
                </p>
              ))}
            </div>
          )}

          {uploadJSON.length > 0 && (
            <div className="sub-msg mt-20 ml-20">
              <p>
                Players with a red background indicate an email address that
                already exists - these players will be ignored in the upload
              </p>
            </div>
          )}

          {uploadJSON.length > 0 && (
            <div className="bi-players">
              <div className="bi-player bi-header">
                <div className="bip-item">
                  <p>First Name</p>
                </div>
                <div className="bip-item">
                  <p>Last Name</p>
                </div>
                <div className="bip-item bip-mail">
                  <p>Email</p>
                </div>
                <div className="bip-item">
                  <p>Handicap</p>
                </div>
                <div className="bip-item">
                  <p>Password</p>
                </div>
                <div className="bip-item">
                  <p>Date of Birth</p>
                </div>
                <div className="bip-item">
                  <p>Country</p>
                </div>
                <div className="bip-item">
                  <p>State</p>
                </div>
                <div className="bip-item">
                  <p>City</p>
                </div>
                <div className="bip-item">
                  <p>Handicap ID</p>
                </div>
                <div className="bip-item">
                  <p>Platform</p>
                </div>
                <div className="bip-item">
                  <p>Platform ID</p>
                </div>
                <div className="bip-item">
                  <p>Gender</p>
                </div>
                <div className="bip-item">
                  <p>Remove</p>
                </div>
              </div>
              {uploadJSON.map((player, i) => {
                return (
                  <BIPlayer
                    key={`bip${i}`}
                    player={player}
                    removeAct={() => {
                      setUploadJSON((c) => {
                        c.splice(i, 1);
                        return c;
                      });
                      setUpdate((c) => c + 1);
                    }}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BulkImport;
