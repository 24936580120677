import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import { Tooltip } from "@mui/material";
import { DeleteForever, Visibility } from "@mui/icons-material";

function PGItem({
  group,
  viewAction = () => {},
  deleteAction = () => {},
  editAction = () => {},
}) {
  return (
    <div className="pg-item">
      <div className="pgi-left">
        <div className="pgi-tit">
          <h3>{group.name}</h3>
        </div>
        <div className="pgi-dets flex flex-align-center">
          <p>{group.groupType}</p>
          {group.groupType === "age" && (
            <p>
              : {group.ageRange[0]} - {group.ageRange[1]}
            </p>
          )}
          {group.groupType === "handicap" && (
            <p>
              :{" "}
              {group.hcpRange[0] < 0
                ? `+${-group.hcpRange[0]}`
                : group.hcpRange[0]}{" "}
              - {group.hcpRange[1]}
            </p>
          )}
          {group.groupType === "custom" && (
            <p>
              : {group.playerIDs.length}{" "}
              {group.playerIDs.length === 1 ? "Member" : "Members"}
            </p>
          )}
        </div>
      </div>
      <div className="pgi-right">
        <Tooltip placement="right" title={"View Group"}>
          <Visibility className="dg-icon-bg icon-cursor" onClick={viewAction} />
        </Tooltip>
        <Tooltip placement="right" title={"Edit Group"}>
          <EditIcon
            className="dg-icon-bg icon-cursor mr-10 ml-10"
            onClick={editAction}
          />
        </Tooltip>
        <Tooltip placement="right" title={"Delete Group"}>
          <DeleteForever
            className="red-icon-bg icon-cursor"
            onClick={deleteAction}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default PGItem;
