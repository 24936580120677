import React from "react";
import "./ChatPreview.css";

import { Avatar } from "@mui/material";

function ChatPreview({ image, goTo, name, message, unreads = 0, time, sent }) {
  return (
    <div onClick={goTo} className="chat-preview">
      <div className="cp-left">
        <Avatar alt={name} src={image} />
      </div>
      <div className="cp-middle">
        <h3>{name}</h3>
        <p>
          {sent && "You: "}
          {message}
        </p>
      </div>
      <div className="cp-right">
        <h5>{time}</h5>
        {unreads !== 0 && (
          <div className="cpr-unreads">
            <p>{unreads}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatPreview;
