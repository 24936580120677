import React, { useState, useRef, useEffect } from "react";
import { db } from "../../../firebase";

// Icons
import ErrorIcon from "@mui/icons-material/Error";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AddCircle from "@mui/icons-material/AddCircle";
// Components
import RoundInput from "./RoundInput";
import EventRound from "./EventRound";
import BackdropLoader from "../../Loaders/BackdropLoader";
import GroupSelector from "../GroupSelector/GroupSelector";
import { Dialog } from "@mui/material";
import PlayerTeeSelector from "./PlayerTeeSelector";

const GradientErrorIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorss" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <ErrorIcon
      onClick={onClick}
      className=""
      sx={{ fill: "url(#linearColorss)" }}
    />
  </>
);

const GradientAddIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircle
      onClick={onClick}
      className="cr-icon icon-cursor  dg-icon-bg"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function EventRounds({
  eventID,
  goBack = () => {},
  defaultRounds = [],
  defaultPlayers = [],
}) {
  // State
  const [rounds, setRounds] = useState(defaultRounds);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [players, setPlayers] = useState(defaultPlayers);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteIndex, setDeleteIndex] = useState(0);

  const [showPop, setShowPop] = useState(false);

  // Use Effect
  useEffect(() => {
    if (defaultRounds.length === 0 || defaultPlayers.length === 0) {
      loadAll();
    }
  }, []);

  async function loadAll() {
    setLoading(true);

    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const {
      playerIDs,
      rounds,
      eventName,
      eventDate,
      courseName,
      seasonID,
      restrictions,
      leagueID,
      eventType,
      courseID,
      limit,
    } = event;

    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());

    const docs = await Promise.all(refs);

    const arr = [];

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      const playerID = playerDoc.id;
      const player = playerDoc.data();

      const { firstName, lastName, profileImage, handicap } = player;

      arr.push({
        name: `${firstName} ${lastName}`,
        profileImage,
        handicap,
        playerID,
      });
    }

    setPlayers(arr);
    setRounds(rounds);
    setDetails({
      eventName,
      limit,
      date: new Date(eventDate.toDate()),
      courseName,
      seasonID,
      courseID,
      leagueID,
      eventType,
      playerIDs,
      rounds,
      restrictions,
    });

    setLoading(false);
  }

  async function saveChanges(index, groups, open = false) {
    // return console.log(groups, index);

    if (open) {
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        const { players } = group;
        const filtered = players.filter((player) => player.name !== "Empty");
        console.log(filtered);
        groups[i].players = filtered;
      }
    }

    // return console.log(groups);

    // alert("doing");
    rounds[index].groups = groups;
    setLoading(true);
    setShow(false);
    try {
      await db.collection("events").doc(eventID).update({
        rounds,
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  async function removeRound(index) {
    const rounds = details.rounds;
    rounds.splice(index, 1);

    try {
      await db.collection("events").doc(eventID).update({
        rounds,
      });
      setShowPop(false);
      loadAll();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="event-rounds">
      {loading && <BackdropLoader />}
      <Dialog open={showPop} onClose={() => setShowPop(false)}>
        <div className="delete-popup">
          <GradientErrorIcon
            className="err-icon"
            style={{ color: "#E24955" }}
          />
          <p>Are you sure you want to delete this round?</p>
          <div
            onClick={() => removeRound(deleteIndex)}
            className="delete-button"
          >
            Delete
          </div>
        </div>
      </Dialog>
      {show ? (
        toShow
      ) : (
        <>
          <div onClick={goBack} className="back-row">
            <ArrowBack className="icon-cursor" />
            <p>Back</p>
          </div>
          <div className="page-header">
            <div>
              <h2>Manage Rounds</h2>
              <div className="aboh-border"></div>
            </div>
            <GradientAddIcon
              onClick={() => {
                setToShow(
                  <RoundInput
                    eventName={details.eventName}
                    eventCourseName={details.courseName}
                    eventType={details.eventType}
                    eventDate={details.date}
                    defaultCourseID={details.courseID}
                    defaultCourseName={details.courseName}
                    seasonID={details.seasonID}
                    rounds={details.rounds}
                    roundIndex={details.rounds.length}
                    defaultDate={details.date.toISOString().split("T")[0]}
                    eventID={eventID}
                    goBack={() => setShow(false)}
                    saveRound={() => {
                      loadAll();
                      setShow(false);
                    }}
                    players={details.playerIDs.length}
                    num={details.rounds.length + 1}
                    eventCourseID={details.courseID}
                  />
                );
                setShow(true);
              }}
            />
          </div>
          <div className="er-rounds pl-20">
            {rounds.map((round, index) => {
              return (
                <EventRound
                  custom={round.groupTypes === "custom"}
                  key={index}
                  editTees={() => {
                    setToShow(
                      <PlayerTeeSelector
                        round={round}
                        eventID={eventID}
                        eventName={details.eventName}
                        roundIndex={index}
                        rounds={rounds}
                        players={players}
                        // defaultMap={}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                  editTimes={() => {
                    setToShow(
                      <GroupSelector
                        defaultShotgun={round.startType === "shotgun"}
                        open={round.open ?? false}
                        eventDate={details.date}
                        leagueID={details.leagueID}
                        defaultLimit={round.groupLimit ?? 0}
                        defaultGroups={round.groups}
                        event
                        saveChanges={(groups) => {
                          saveChanges(index, groups, round.open);
                        }}
                        courseDetails={round}
                        players={players}
                        goBack={() => setShow(false)}
                        setOverall={() => {}}
                      />
                    );
                    // setRound(<TeeTimeEditor reload={loadEvent} eventID={eventID} roundIndex={index} goBack={() => setShowRound(false)} limit={round.groupLimit} players={selectedPlayers} groups={round.groups} />)
                    setShow(true);
                  }}
                  edit={() => {
                    setToShow(
                      <RoundInput
                        defaultStartType={round.startType}
                        defaultOpen={round.open}
                        defaultCTPEnabled={round.ctpEnabled}
                        defaultLDEnabled={round.ldEnabled}
                        defaultCTPHole={round.ctpHole}
                        defaultLDHole={round.ldHole}
                        defaultImageLink={round.imageLink}
                        eventType={details.eventType}
                        eventCourseName={details.courseName}
                        eventDate={details.date}
                        seasonID={details.seasonID}
                        roundIndex={index}
                        defaultGroups={round.groups ?? []}
                        defaultTimeType={round.timeTypes}
                        attLimit={details.limit}
                        edit={true}
                        eventID={eventID}
                        goBack={() => setShow(false)}
                        num={index + 1}
                        saveRound={() => {}}
                        defaultCourseName={details.courseName}
                        defaultCourseID={round.courseID}
                        eventCourseID={details.courseID}
                        defaultDate={
                          new Date(round.date.toDate())
                            .toISOString()
                            .split("T")[0]
                        }
                        defaultIntervals={round.intervals}
                        defaultLimit={round.groupLimit}
                        defaultTypes={round.groupTypes}
                        defaultTees={round.tees}
                        defaultTime={new Date(
                          round.date.toDate()
                        ).toLocaleTimeString()}
                        open={details.restrictions === "open"}
                        rounds={details.rounds}
                        players={details.playerIDs.length}
                      />
                    );
                    setShow(true);
                  }}
                  remove={() => {
                    setDeleteIndex(index);
                    setShowPop(true);
                  }}
                  img={round.imageLink}
                  date={round.date}
                  num={index + 1}
                />
              );
            })}
          </div>
          {!loading && rounds.length === 0 && (
            <div className="no-msg">
              <p>No rounds to display</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EventRounds;
