import React from "react";
import "./StatsHeader.css";

function StatsHeader({
  title,
  one = "",
  oneClick,
  two = "",
  twoClick,
  three = "",
  threeClick,
  four = "",
  fourClick,
  five = "",
  fiveClick,
  six = "",
  sixClick,
  selected = "one",
  dir = "down",
  options = [],
  handleClick = () => {},
}) {
  return (
    <div className="player-score log-item stats-header">
      <div className="ps-left">
        <p>#</p>
      </div>
      <div style={{ paddingRight: "10px" }} className="ps-right">
        <div className="ps-right-left">
          <h3>Player</h3>
        </div>
        {options.map((opt, i) => {
          return (
            <div
              key={opt.label}
              onClick={() => handleClick(opt)}
              className="ps-right-right"
            >
              <p>{opt.label}</p>
            </div>
          );
        })}
        {one !== "" && (
          <div onClick={oneClick} className={"ps-right-right"}>
            <p
              className={
                selected === "one" && dir === "up"
                  ? "lsr-pee-down"
                  : selected === "one" && dir === "down"
                  ? "lsr-pee-up"
                  : ""
              }
            >
              {one}
            </p>
            {/* {selected === "one" && dir === "down" && (
              <ArrowDropDownIcon className="sh-icon" />
            )}
            {selected === "one" && dir === "up" && (
              <ArrowDropUpIcon className="sh-icon" />
            )} */}
          </div>
        )}
        {two !== "" && (
          <div onClick={twoClick} className="ps-right-right">
            <p
              className={
                selected === "two" && dir === "up"
                  ? "lsr-pee-down"
                  : selected === "two" && dir === "down"
                  ? "lsr-pee-up"
                  : ""
              }
            >
              {two}
            </p>
            {/* {selected === "two" && dir === "down" && (
              <ArrowDropDownIcon className="sh-icon" />
            )}
            {selected === "two" && dir === "up" && (
              <ArrowDropUpIcon className="sh-icon" />
            )} */}
          </div>
        )}
        {three !== "" && (
          <div onClick={threeClick} className="ps-right-right">
            <p
              className={
                selected === "three" && dir === "up"
                  ? "lsr-pee-down"
                  : selected === "three" && dir === "down"
                  ? "lsr-pee-up"
                  : ""
              }
            >
              {three}
            </p>
          </div>
        )}
        {four !== "" && (
          <div onClick={fourClick} className="ps-right-right">
            <p
              className={
                selected === "four" && dir === "up"
                  ? "lsr-pee-down"
                  : selected === "four" && dir === "down"
                  ? "lsr-pee-up"
                  : ""
              }
            >
              {four}
            </p>
            {/* {selected === "four" && dir === "down" && <ArrowDropDownIcon />}
            {selected === "four" && dir === "up" && <ArrowDropUpIcon />} */}
          </div>
        )}
        {five !== "" && (
          <div onClick={fiveClick} className="ps-right-right">
            <p>{five}</p>
          </div>
        )}
        {six !== "" && (
          <div onClick={sixClick} className="ps-right-right">
            <p>{six}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default StatsHeader;
