import React, { useState, useEffect } from "react";
import "./InviteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../contexts/AuthContext";

import BackdropLoader from "../../Loaders/BackdropLoader";
import { Oval } from "react-loader-spinner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import PlayerItem from "../../display/PlayerItem/PlayerItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Pill from "../../Buttons/Pill";
import Switch from "react-ios-switch/lib/Switch";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Slide from "@mui/material/Slide";

import {
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
} from "@mui/material";
import { admin, db } from "../../../firebase";
import PageHeader from "../../display/Rows/PageHeader";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
import classNames from "classnames";
import PlayerListItem from "../../display/PlayerItem/PlayerListItem";

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      onClick={onClick}
      className="big-tick"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function InviteModal({
  eventID,
  leagueID,
  tournamentID = "",
  hide = () => {},
  defaultPlayers = [],
  reload = () => {},
  creation = false,
  add = () => {},
  defaultSelected = [],
  league = false,
  hideFilters = false,
  leagueOptions = [],
  leagueMax = 1,
}) {
  const [selected, setSelected] = useState(defaultSelected);

  const [players, setPlayers] = useState(defaultPlayers);
  const [selLeagueID, setLeagueID] = useState("all");

  const [searchInput, setSearchInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(true);

  const { userDetails } = useAuth();
  const [sorter, setSorter] = useState("");
  const [sortDir, setSortDir] = useState("");
  const [rankEnabled, setRankEnabled] = useState(false);
  const [hcpEnabled, setHcpEnabled] = useState(false);
  const [ageEnabled, setAgeEnabled] = useState(false);
  const [hcpRange, setHcpRange] = useState([-5, 54]);
  const [ageRange, setAgeRange] = useState([0, 100]);
  const [minRank, setMinRank] = useState(leagueMax);
  const [viewType, setViewType] = useState("items");
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  useEffect(() => {
    console.log(defaultSelected);
    if (defaultSelected.length > 0) {
      if (typeof defaultSelected === "object") {
        const mep = defaultSelected.map((player) => player.playerID);
        setSelected(mep);
      }
    }

    if (userDetails && defaultPlayers.length === 0) {
      loadOptions();
    }
    if (defaultPlayers.length > 0) {
      setLoading(false);
    }
  }, [userDetails]);

  async function loadOptions() {
    let friendIDs = userDetails.friends;

    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const { playerIDs, invitedIDs, leagueID } = event;
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    let leagueIDs = [];
    if (leagueDoc.exists) {
      const league = leagueDoc.data();
      leagueIDs = league.playerIDs;
    }

    for (let i = 0; i < playerIDs.length; i++) {
      const id = playerIDs[i];
      if (!friendIDs.includes(id)) {
        friendIDs.push(id);
      }
    }

    for (let i = 0; i < invitedIDs.length; i++) {
      const id = invitedIDs[i];
      if (!friendIDs.includes(id)) {
        friendIDs.push(id);
      }
    }

    for (let i = 0; i < leagueIDs.length; i++) {
      const id = leagueIDs[i];
      if (!friendIDs.includes(id)) {
        friendIDs.push(id);
      }
    }

    friendIDs = friendIDs.filter((id) => {
      if (playerIDs.includes(id) || invitedIDs.includes(id)) {
        return false;
      } else {
        return true;
      }
    });

    if (leagueID !== "standalone") {
      friendIDs = friendIDs.filter((id) => leagueIDs.includes(id));
    }

    // console.log(friendIDs);
    // To Invite
    const refs = friendIDs.map((id) => db.collection("users").doc(id).get());

    const arr = [];

    const docs = await Promise.all(refs);
    for (let i = 0; i < docs.length; i++) {
      const userDoc = docs[i];
      const playerID = userDoc.id;
      const player = userDoc.data();
      if (!player) {
        continue;
      }
      const { firstName, lastName, handicap, profileImage } = player;
      arr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
      });
    }
    setPlayers(arr);

    setLoading(false);
  }

  async function invitePlayers() {
    setLoading(true);
    try {
      if (eventID) {
        await db
          .collection("events")
          .doc(eventID)
          .update({
            invitedIDs: admin.firestore.FieldValue.arrayUnion(...selected),
          });
      } else if (tournamentID) {
        await db
          .collection("tournaments")
          .doc(tournamentID)
          .update({
            invitedIDs: admin.firestore.FieldValue.arrayUnion(...selected),
          });
      } else {
        await db
          .collection("leagues")
          .doc(leagueID)
          .update({
            invitedIDs: admin.firestore.FieldValue.arrayUnion(...selected),
          });
      }
      toast.success("Players were successfully invited", {
        icon: successIco,
      });
      setFinished(true);
      hide();
      reload();
    } catch (err) {
      setErrorMessage(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  function sortPlayers({ sorter = "", dir = "" }) {
    setSorter(sorter);
    setSortDir(dir);
    if (dir === "up") {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => a[sorter] - b[sorter]);
        } else {
          c.sort((a, b) => a[sorter].localeCompare(b[sorter]));
        }
        return c;
      });
    } else {
      setPlayers((c) => {
        if (sorter === "handicap") {
          c.sort((a, b) => b[sorter] - a[sorter]);
        } else {
          c.sort((a, b) => b[sorter].localeCompare(a[sorter]));
        }
        return c;
      });
    }
    update();
  }

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  return (
    <div
      onClick={(e) => {
        // hide()
        console.log(e);
        if (e.target.className) {
          if (e.target.className.includes("Mui")) {
            // hide();
          }
        }
      }}
      className="invite-modal"
    >
      <Dialog open onClose={hide} className="mpa-dia">
        <div className="im-dialog">
          {loading ? (
            <div className="ui-waiting">
              <div className="uiw-spinner">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
              <p>Loading...</p>
            </div>
          ) : finished ? (
            <>
              <PageHeader text="Invite Players" showClose close={hide} />
              <div className="im-msg">
                <p>Your selected players have been invited</p>
              </div>
              <div className="mpa-fin flex-center">
                <GradientCircleIcon />
              </div>
            </>
          ) : (
            <>
              <PageHeader text="Invite Players" showClose close={hide} />

              <div className="im-msg">
                <p>Click on a player to select them - don't forget to save!</p>
              </div>
              <div className="ig-group mb-20 mt-20">
                <div className="input-group mt-0 si-search">
                  {/* <p>Search</p> */}
                  <div className="flex-align-center">
                    <input
                      type="text"
                      placeholder="Search for player"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className="default-input"
                    />
                    <HighlightOffIcon
                      className="icon-cursor red-icon-bg ml-20 ipm-search-ico"
                      onClick={() => setSearchInput("")}
                    />
                  </div>
                </div>
                <div className="ml-20">
                  <Pill
                    text={"Select All"}
                    onClick={() => {
                      const ids = players.map((player) => {
                        if (hcpEnabled) {
                          if (
                            player.handicap < hcpRange[0] ||
                            player.handicap > hcpRange[1]
                          ) {
                            return null;
                          }
                        }

                        if (ageEnabled) {
                          if (
                            player.age < ageRange[0] ||
                            player.age > ageRange[1]
                          ) {
                            return null;
                          }
                        }

                        if (selLeagueID !== "all") {
                          const leagueIDs = player.leagueIDs ?? [];
                          if (!leagueIDs.includes(selLeagueID)) {
                            return null;
                          }
                        }

                        if (rankEnabled) {
                          if (player.rank > minRank || player.rank === 0) {
                            return null;
                          }
                        }

                        return player.playerID;
                      });
                      setSelected((current) => [...current, ...ids]);
                    }}
                  />
                </div>

                <Pill
                  red
                  text={"Deselect All"}
                  onClick={() => {
                    setSelected((current) => []);
                  }}
                />
                <RadioGroupTwo
                  active={viewType}
                  buttons={[
                    {
                      value: "items",
                      text: "Items",
                    },
                    {
                      value: "list",
                      text: "List",
                    },
                  ]}
                  setActive={() => {
                    setViewType((c) => {
                      if (c === "list") {
                        return "items";
                      } else {
                        return "list";
                      }
                    });
                  }}
                />
              </div>

              {!hideFilters && (
                <Accordion
                // expanded={showFilters}
                // onChange={() => setShowFilters(false)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="mmt-20"
                  >
                    <div className="se-header">
                      <h2 className="ne-header">Filters</h2>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mpaf-cont">
                      <div className="mpa-filters">
                        <div className="mpaf-pill">
                          <Pill
                            text="Reset"
                            red
                            onClick={() => {
                              setAgeRange([0, 100]);
                              setHcpRange([-5, 54]);
                              setMinRank(leagueMax);
                              setRankEnabled(false);
                              setAgeEnabled(false);
                              setHcpEnabled(false);
                              setLeagueID("all");
                            }}
                          />
                        </div>
                        <div className="mpafs">
                          <div className="mpaf">
                            <div className="mpaf-header">
                              <p>Handicap</p>
                              <Switch
                                onColor="#1e7a69"
                                checked={hcpEnabled}
                                className={
                                  hcpEnabled
                                    ? "scoring-switch switch-on"
                                    : "scoring-switch"
                                }
                                onChange={() =>
                                  setHcpEnabled((current) => !current)
                                }
                              />
                            </div>
                            <div className="mpaf-slider">
                              <Slider
                                disabled={!hcpEnabled}
                                value={hcpRange}
                                min={-5}
                                max={54}
                                disableSwap
                                valueLabelDisplay="on"
                                onChange={(e, newValue) =>
                                  setHcpRange(newValue)
                                }
                              />
                            </div>
                          </div>
                          <div className="mpaf">
                            <div className="mpaf-header">
                              <p>Age</p>
                              <Switch
                                onColor="#1e7a69"
                                checked={ageEnabled}
                                className={
                                  ageEnabled
                                    ? "scoring-switch switch-on"
                                    : "scoring-switch"
                                }
                                onChange={() =>
                                  setAgeEnabled((current) => !current)
                                }
                              />
                            </div>
                            <div className="mpaf-slider">
                              <Slider
                                value={ageRange}
                                min={0}
                                max={100}
                                disableSwap
                                disabled={!ageEnabled}
                                valueLabelDisplay="on"
                                onChange={(e, newValue) =>
                                  setAgeRange(newValue)
                                }
                              />
                            </div>
                          </div>

                          {league && (
                            <div className="mpaf">
                              <div className="mpaf-header">
                                <p>League Rank</p>
                                <Switch
                                  onColor="#1e7a69"
                                  checked={rankEnabled}
                                  className={
                                    rankEnabled
                                      ? "scoring-switch switch-on"
                                      : "scoring-switch"
                                  }
                                  onChange={() =>
                                    setRankEnabled((current) => !current)
                                  }
                                />
                              </div>
                              <div className="mpaf-slider">
                                <Slider
                                  value={minRank}
                                  min={1}
                                  max={leagueMax}
                                  disableSwap
                                  disabled={!rankEnabled}
                                  valueLabelDisplay="on"
                                  onChange={(e, newValue) =>
                                    setMinRank(newValue)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {leagueOptions.length > 0 && (
                            <div className="mpaf mpaf-drop">
                              <div className="mpaf-header">
                                <p>League</p>
                              </div>
                              <div className="mpaf-drop">
                                <Dropdown
                                  arrowClosed={
                                    <KeyboardArrowDownIcon className="drop-icon" />
                                  }
                                  arrowOpen={
                                    <KeyboardArrowUpIcon className="drop-icon" />
                                  }
                                  options={leagueOptions}
                                  value={selLeagueID}
                                  onChange={(e) => {
                                    console.log(e.value);
                                    setLeagueID(e.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              {errorMessage !== "" && (
                <div className="error-message-row">
                  <p>{errorMessage}</p>
                </div>
              )}
              <div className="pl-20 flex-center">
                <button
                  disabled={selected.length === 0}
                  onClick={() => {
                    if (creation) {
                      add(selected);
                      hide();
                    } else {
                      invitePlayers();
                      // hide();
                    }
                  }}
                  className="default-button"
                >
                  Invite {selected.length}{" "}
                  {selected.length === 1 ? "Player" : "Players"}
                </button>
              </div>

              <div
                style={{
                  justifyContent:
                    players.length > 2 ? "space-between" : "flex-start",
                }}
                className="im-players mpa-players"
              >
                {viewType === "list" && (
                  <>
                    <div className="player-list-item pli-head pb-20">
                      <div
                        onClick={() => {
                          if (sorter === "firstName") {
                            if (sortDir === "up") {
                              sortPlayers({ sorter: "firstName", dir: "down" });
                            } else {
                              sortPlayers({ sorter: "firstName", dir: "up" });
                            }
                          } else {
                            sortPlayers({ sorter: "firstName", dir: "down" });
                          }
                        }}
                        className={classNames("pli-item", {
                          "plih-sel-up":
                            sorter === "firstName" && sortDir === "up",
                          "plih-sel-dw":
                            sorter === "firstName" && sortDir !== "up",
                        })}
                      >
                        <p>First Name</p>
                      </div>
                      <div
                        onClick={() => {
                          if (sorter === "lastName") {
                            if (sortDir === "up") {
                              sortPlayers({ sorter: "lastName", dir: "down" });
                            } else {
                              sortPlayers({ sorter: "lastName", dir: "up" });
                            }
                          } else {
                            sortPlayers({ sorter: "lastName", dir: "down" });
                          }
                        }}
                        className={classNames("pli-item", {
                          "plih-sel-up":
                            sorter === "lastName" && sortDir === "up",
                          "plih-sel-dw":
                            sorter === "lastName" && sortDir !== "up",
                        })}
                      >
                        <p>Last Name</p>
                      </div>
                      <div
                        onClick={() => {
                          if (sorter === "handicap") {
                            if (sortDir === "up") {
                              sortPlayers({ sorter: "handicap", dir: "down" });
                            } else {
                              sortPlayers({ sorter: "handicap", dir: "up" });
                            }
                          } else {
                            sortPlayers({ sorter: "handicap", dir: "down" });
                          }
                        }}
                        className={classNames("pli-item", {
                          "plih-sel-up":
                            sorter === "handicap" && sortDir === "up",
                          "plih-sel-dw":
                            sorter === "handicap" && sortDir !== "up",
                        })}
                      >
                        <p>Hcp</p>
                      </div>
                      <div className="pli-item">
                        <p>Selected</p>
                      </div>
                    </div>
                  </>
                )}
                {players.map((player) => {
                  if (searchInput !== "") {
                    const sl = searchInput.toLowerCase();
                    if (!player.name.toLowerCase().includes(sl)) {
                      return null;
                    }
                  }

                  if (selLeagueID !== "all") {
                    const leagueIDs = player.leagueIDs ?? [];
                    if (!leagueIDs.includes(selLeagueID)) {
                      return null;
                    }
                  }

                  if (rankEnabled) {
                    if (player.rank > minRank || player.rank === 0) {
                      return null;
                    }
                  }

                  if (hcpEnabled) {
                    const minHcp = hcpRange[0];
                    const maxHcp = hcpRange[1];

                    if (player.handicap < minHcp || player.handicap > maxHcp) {
                      return null;
                    }
                  }

                  if (ageEnabled) {
                    const minAge = ageRange[0];
                    const maxAge = ageRange[1];
                    if (player.age < minAge || player.age > maxAge) {
                      return null;
                    }
                  }

                  if (viewType === "list") {
                    return (
                      <PlayerListItem
                        player={player}
                        key={player.playerID}
                        selected={selected.includes(player.playerID)}
                        select={() => {
                          setSelected((current) => {
                            if (current.includes(player.playerID)) {
                              const filt = current.filter(
                                (uid) => uid !== player.playerID
                              );
                              return filt;
                            } else {
                              return [...current, player.playerID];
                            }
                          });
                        }}
                      />
                    );
                  } else {
                    return (
                      <PlayerItem
                        noLink
                        showProfile={() =>
                          setSelected((current) => {
                            if (current.includes(player.playerID)) {
                              const filt = current.filter(
                                (uid) => uid !== player.playerID
                              );
                              return filt;
                            } else {
                              setSearchInput("");
                              return [...current, player.playerID];
                            }
                          })
                        }
                        hcp={player.handicap}
                        img={player.profileImage}
                        name={player.name}
                        playerID={player.playerID}
                        hideIcons
                        showBg={selected.includes(player.playerID)}
                      />
                    );
                  }
                })}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default InviteModal;
