import React, { useState } from "react";
import "./LandingPage.css";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import LPTwo from "./LPTwo";
import sc1 from "../../assets/images/newsc1.png";
import sc2 from "../../assets/images/newsc2.png";
import sc3 from "../../assets/images/newsc3.png";
import sc4 from "../../assets/images/newsc4.png";
import sc5 from "../../assets/images/newsc5.png";
import sc6 from "../../assets/images/newsc6.png";
import log from "../../assets/images/logogen.png";
import log2 from "../../assets/images/comish.png";
import log1 from "../../assets/images/logocomish.png";

import ft1 from "../../assets/images/f3.png";
import ft2 from "../../assets/images/ab4.png";
import ft3 from "../../assets/images/ft3.png";

// Icons

// Components
import SignUp from "../SignUp/SignUp";
import Login from "../../pages/Login/Login";
import { Dialog } from "@mui/material";
import Onboarding from "../SignUp/Onboarding";
import { Helmet } from "react-helmet";

function LandingPage() {
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  // State
  const [showSign, setShowSign] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [showOnboard, setShowOnboard] = useState(false);

  return (
    <div className="landing-page">
      {showLogin && <Login hide={() => setShowLogin(false)} />}
      <Helmet>
        <title>The Commissioner | Home</title>
      </Helmet>
      {showOnboard ? (
        <Onboarding goBack={() => setShowOnboard(false)} />
      ) : showSign ? (
        <SignUp goBack={() => setShowSign(false)} />
      ) : (
        <>
          <div className="lp-top ">
            <div className="ltp-left animate__animated animate__slideInLeft">
              <h2>The Commissioner</h2>
              <h5>Golf Management Software</h5>
              <h3>Powered by The UGA</h3>
              <p>
                Managing your golf clubs and societies has never been easier.
                Event creation & management, player communication and much more
                - all at your fingertips.
              </p>
              {currentUser && false && (
                <button className="btn-20" onClick={() => setShowSign(true)}>
                  Sign Up
                </button>
              )}
              {currentUser && false === null && (
                <button
                  className="btn-20"
                  onClick={() => navigate("/onboarding")}
                >
                  Create Account
                </button>
              )}
              {currentUser === null && (
                <button className="btn-20" onClick={() => setShowLogin(true)}>
                  Log In
                </button>
              )}
            </div>

            <div className="ltp-right flex-center animate__animated animate__slideInRight">
              <img src={log2} />
            </div>
          </div>

          <div className="lp-divider flex-center ltp-left animate__animated  animate__slideInUp pb-20">
            <h2>Retire the pencil</h2>
            <div className="aboh-border"></div>
          </div>
          <LPTwo />
          {false && (
            <>
              <div className="lp-features">
                <AnimationOnScroll
                  animateOnce
                  className="flex-center"
                  animateIn="animate__slideInLeft"
                >
                  <div className="lp-left flex-center">
                    <div className="lpl-box">
                      <div className="lpl-top flex-center">
                        <img className="jello-diagonlal-1" src={ft1} />
                      </div>
                      <div className="lpl-bot">
                        <h1>Event creation made simple</h1>
                        <p>
                          Courses, formats, tee times and many other details -
                          all easily customizable. Invite or add players in an
                          instant, and watch your league grow.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
                <div className="lp-right">
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__slideInRight"
                  >
                    <div className="lpr-box lprb-top">
                      <div className="lrp-top flex-center">
                        <img className="jello-diagonlal-1" src={ft2} />
                      </div>
                      <div className="lpr-bot">
                        <h1>Player management at your fingertips </h1>
                        <p>
                          Avoid those pesky phone calls and follow up messages -
                          communicate with and manage your players with the
                          click fo a button.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__slideInRight"
                  >
                    <div className="lpr-box lprb-bot">
                      <div className="lrp-top flex-center">
                        <img className="jello-diagonlal-1" src={ft3} />
                      </div>
                      <div className="lpr-bot">
                        <h1>Personalised Support</h1>
                        <p>
                          Got a question? Get in touch with a support agent
                          directly through our chat portal.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="lp-divider flex-center ltp-left mb-20">
                <h2>Transform your events</h2>
                <div className="aboh-border"></div>
              </div>
              <div className="lp-slider">
                <div className="lps-left ltp-left">
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__slideInLeft"
                  >
                    <h2>Be in charge</h2>
                    <h3>Tournament management made easy</h3>
                  </AnimationOnScroll>
                </div>
                <div className="lps-right">
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__slideInRight"
                  >
                    <div className="lps-box">
                      <Carousel
                        thumbWidth={300}
                        showThumbs={false}
                        interval={2000}
                        showArrows
                        swipeable
                        showStatus={false}
                        autoPlay
                      >
                        <div>
                          <img src={sc1} alt="ios-one" />
                        </div>
                        <div>
                          <img src={sc2} alt="ios-two" />
                        </div>
                        <div>
                          <img src={sc3} alt="ios-two" />
                        </div>
                        <div>
                          <img src={sc4} alt="ios-one" />
                        </div>
                        <div>
                          <img src={sc5} alt="ios-two" />
                        </div>
                        <div>
                          <img src={sc6} alt="ios-two" />
                        </div>
                      </Carousel>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="lp-divider flex-center ltp-left">
                <h2>Create that tour feel</h2>
                <div className="aboh-border"></div>
              </div>
            </>
          )}
          <div className="lp-video">
            <AnimationOnScroll
              className="lp-left"
              animateOnce
              animateIn="animate__slideInLeft"
            >
              {/* <div className="lpv-left"> */}
              <div className="lpv-frame">
                <iframe
                  width="660"
                  height="415"
                  src="https://www.youtube.com/embed/k-9TXi41hwo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              {/* </div> */}
            </AnimationOnScroll>
            <AnimationOnScroll
              animateOnce
              className=""
              animateIn="animate__slideInRight"
            >
              <div className="pd-20">
                <div className="abo-header">
                  <h1>Free Live Scoring App - The UGA Tour</h1>
                  <div className="aboh-border"></div>
                </div>
                <div className="abo-ib-text">
                  <p>
                    Live leaderboards, stats tracking & more - completely free
                    to use and integrated seamlessly with The Comissioner
                  </p>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </>
      )}
    </div>
  );
}

export default LandingPage;
