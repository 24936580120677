import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

// Icons

// Components
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackdropLoader from "../Loaders/BackdropLoader";
import FixturePreview from "../display/FixturePreview/FixturePreview";

function MatchdaySelector({
  clubID = "",
  matchdayID = "",
  goBack = () => {},
  reload = () => {},
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [upd, setUpdate] = useState(0);
  function update() {
    setUpdate((current) => current + 1);
  }

  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    loadOptions();
  }, []);

  async function loadOptions() {
    const now = new Date();

    now.setMonth(now.getMonth() - 4);

    const past = new Date();
    const fut = new Date();

    const matchDoc = await db.collection("matchdays").doc(matchdayID).get();
    const match = matchDoc.data();
    const { eventIDs } = match;
    setSelectedEvents(eventIDs);

    const eventDocs = await db
      .collection("events")
      .where("clubID", "==", clubID)
      .where("eventDate", ">", now)
      .orderBy("eventDate", "desc")
      .get();
    const arr = [];

    const idArr = [];

    for (let i = 0; i < eventDocs.docs.length; i++) {
      const eventDoc = eventDocs.docs[i];
      const event = eventDoc.data();
      const eventID = eventDoc.id;
      const { eventName, eventDate, playerIDs, imageLink } = event;
      if (event.roundIDs.length > 0) {
        // continue;
      }
      const dateObj = new Date(eventDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      arr.push({
        eventID,
        eventName,
        dateObj,
        dateString,
        playerIDs,
        imageLink,
      });
      idArr.push(eventID);
    }

    const refs = [];
    for (let i = 0; i < eventIDs.length; i++) {
      if (!idArr.includes(eventIDs[i])) {
        refs.push(db.collection("events").doc(eventIDs[i]).get());
      }
    }

    const docs = await Promise.all(refs);

    for (let i = 0; i < docs.length; i++) {
      const eventDoc = docs[i];
      const event = eventDoc.data();
      const eventID = eventDoc.id;
      const { eventName, eventDate, playerIDs, imageLink } = event;

      const dateObj = new Date(eventDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      arr.unshift({
        eventID,
        eventName,
        dateObj,
        dateString,
        playerIDs,
        imageLink,
      });
    }
    setEvents(arr);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      await db.collection("matchdays").doc(matchdayID).update({
        eventIDs: selectedEvents,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError("Could not save changes, please try again");
    }
    setLoading(false);
  }

  return (
    <div className="matchday-selector">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text={"Event Selector"} />
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <div className="input-group pl-20 mb-20">
        <p>Search</p>
        <input
          type="text"
          value={searchInput}
          placeholder="Search for event..."
          onChange={(e) => setSearchInput(e.target.value)}
          className="default-input"
        />
      </div>
      <div className="ml-20 mb-20">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
      <div className="ms-events">
        {events.map((event, index) => {
          if (searchInput !== "") {
            const sl = searchInput.toLowerCase();
            const nl = event.eventName.toLowerCase();
            if (!nl.includes(sl)) {
              return null;
            }
          }

          return (
            <FixturePreview
              showDetails={() => {
                if (selectedEvents.includes(event.eventID)) {
                  setSelectedEvents((current) => {
                    const filt = current.filter((id) => id !== event.eventID);
                    return filt;
                  });
                } else {
                  if (selectedEvents.length === 4) {
                    return;
                  } else {
                    setSelectedEvents((current) => [...current, event.eventID]);
                  }
                }
                update();
              }}
              showBG={selectedEvents.includes(event.eventID)}
              key={event.eventID}
              hideIcons
              event={event}
            />
          );
        })}
      </div>
    </div>
  );
}

export default MatchdaySelector;
