import { Cancel, Edit, Remove, Reply } from "@mui/icons-material";
import React, { useState } from "react";
import SmallScoreInput from "./SmallScoreInput";
import classNames from "classnames";

function ListGroupPlayer({
  player = {},
  showScore = false,
  setShowScore = () => {},
  showToday = false,
  roundID = "",
  hideEdit = false,
  hideScore = () => {},
  header = false,
}) {
  const [loading, setLoading] = useState(false);

  return (
    <div
      className={classNames("list-group-player", {
        "lgp-head": header,
        "lgp-comp": player.thru === 18,
      })}
    >
      <div className="lgp-left">
        <div className="lgp-pl">
          <p>{player.name}</p>
        </div>
        <div className="lgp-sc">
          <p>
            {player.handicap < 0
              ? `+${Math.abs(player.handicap)}`
              : player.handicap}
          </p>
        </div>
        <div className="lgp-sc">
          <p>
            {player.score === 0
              ? "E"
              : player.score > 0
              ? `+${player.score}`
              : player.score}
          </p>
        </div>
        <div className="lgp-sc">
          <p>{player.thru}</p>
        </div>
        {showToday && (
          <div className="lgp-sc lgp-today">
            <p>
              {player.today === 0
                ? "E"
                : player.today > 0
                ? `+${player.today}`
                : player.today}
            </p>
          </div>
        )}
        {!header && (
          <div className="lgp-ico">
            {!showScore && (
              <Edit
                onClick={() => {
                  if (!hideEdit) {
                    setShowScore();
                  }
                }}
                className={classNames("dg-icon-bg icon-cursor", {
                  "ico-dis": hideEdit,
                })}
              />
            )}
            {showScore && (
              <Reply
                className={classNames("red-icon-bg icon-cursor", {
                  "ico-dis": loading,
                })}
                onClick={() => {
                  if (!loading) {
                    hideScore();
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="lgp-right">
        {showScore && (
          <SmallScoreInput
            setLoad={(e) => setLoading(e)}
            goBack={() => hideScore()}
            roundID={roundID}
            playerID={player.playerID}
          />
        )}
      </div>
    </div>
  );
}

export default ListGroupPlayer;
