import React, { useEffect, useState } from "react";
import "./AddedUsers.css";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Components
import BackdropLoader from "../Loaders/BackdropLoader";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import { Dialog } from "@mui/material";
import CourseItem from "../display/CourseItem/CourseItem";
import { Oval } from "react-loader-spinner";
import UserImport from "../UserImport/UserImport";

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      onClick={onClick}
      className="big-tick"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function AddedUsers({ goBack = () => {} }) {
  // Admin
  const { currentUser } = useAuth();

  // STate
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddLeague, setShowAddLeague] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [playerToAdd, setPlayerToAdd] = useState({});
  const [selLeague, setSelLeague] = useState("");

  const [saving, setSaving] = useState(false);
  const [added, setAdded] = useState(false);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  // UE
  useEffect(() => {
    if (currentUser) {
      loadPlayers();
    }
  }, [currentUser]);

  // Functions
  async function loadPlayers() {
    const players = await db
      .collection("users")
      .where("addedBy", "==", currentUser.uid)
      .get();
    const leagues = await db
      .collection("leagues")
      .where("admins", "array-contains", currentUser.uid)
      .get();

    const arr = [];
    const leagueArr = [];

    for (let i = 0; i < leagues.docs.length; i++) {
      const leagueDoc = leagues.docs[i];
      const leagueID = leagueDoc.id;
      const league = leagueDoc.data();
      const { leagueName, imageLink, city, country, playerIDs } = league;
      leagueArr.push({
        leagueName,
        imageLink,
        city,
        country,
        leagueID,
        playerIDs,
      });
    }

    for (let i = 0; i < players.docs.length; i++) {
      const playerDoc = players.docs[i];
      const playerID = playerDoc.id;
      const player = playerDoc.data();
      const { firstName, lastName, profileImage, handicap, dateJoined } =
        player;

      const djObject = new Date(dateJoined.toDate());
      const dateString = djObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });

      arr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        playerID,
        profileImage,
        dateString,
      });
    }

    setLeagues(leagueArr);

    setUsers(arr);
    setLoading(false);
  }

  async function addToLeague() {
    setSaving(true);
    const { playerID } = playerToAdd;
    try {
      await db
        .collection("leagues")
        .doc(selLeague.leagueID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayUnion(...playerID),
        });
      await db
        .collection("users")
        .doc(playerID)
        .update({
          leagueIDs: admin.firestore.FieldValue.arrayUnion(
            ...selLeague.leagueID
          ),
        });
      setAdded(true);
    } catch (err) {
      console.log(err);
    }
    setSaving(false);
  }

  return (
    <div className="added-users">
      {show ? (
        toShow
      ) : (
        <>
          {loading && <BackdropLoader />}
          <Dialog open={showAddLeague} onClose={() => setShowAddLeague(false)}>
            <div className="au-league">
              <div className="page-header">
                <h2>Add to League</h2>
              </div>
              {!added && (
                <div className="ph-msg">
                  <p>Select a league to add {playerToAdd.name} to</p>
                </div>
              )}
              {added ? (
                <>
                  <div className="mpa-fin flex-center">
                    <GradientCircleIcon />
                  </div>
                </>
              ) : saving ? (
                <div className="flex-center loading">
                  <Oval
                    color="#1e7a69"
                    secondaryColor="#ffffff"
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <>
                  <div className="aul-box">
                    {leagues.map((league) => (
                      <CourseItem
                        key={league.leagueID}
                        city={league.city}
                        country={league.country}
                        courseName={league.leagueName}
                        image={league.imageLink}
                        showCourse={() => setSelLeague(league.leagueID)}
                        showBG={league.leagueID === selLeague}
                      />
                    ))}
                  </div>
                </>
              )}
              {!saving && !added && (
                <div className="default-button-row">
                  <button
                    onClick={addToLeague}
                    disabled={selLeague === ""}
                    className="default-button"
                  >
                    Add Player To League
                  </button>
                </div>
              )}
            </div>
          </Dialog>
          <div onClick={goBack} className="back-row">
            <ArrowBack />
            <p>Back</p>
          </div>
          <div className="page-header">
            <h2>Added Users</h2>
          </div>
          <div className="ph-msg">
            <p>Manage your added players here</p>
          </div>
          <div className="au-box pl-20 mt-20">
            {users.map((player, index) => (
              <PlayerItem
                key={player.playerID}
                hcp={player.handicap}
                img={player.profileImage}
                name={player.name}
                playerID={player.playerID}
                hideProfile
                hideRemove
                editAction={() => {
                  setToShow(
                    <UserImport
                      goBack={() => setShow(false)}
                      userID={player.playerID}
                      edit
                    />
                  );
                  setShow(true);
                }}
                addAction={() => {
                  setAdded(false);
                  setSaving(false);
                  setPlayerToAdd(player);
                  setShowAddLeague(true);
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AddedUsers;
