import React from "react";

function RoundSummaryBox({
  textOne = "",
  textTwo = "",
  textThree = "",
  icon = "",
  reverse = false,
  type = "",
}) {
  return (
    <div style={{ flexDirection: reverse ? "row" : "row" }} className="rs-box">
      <div className="rsb-text">
        <p>
          {`${textOne} `}
          <span className={`rs-${type}`}> {textTwo}</span>
          {textThree}
        </p>
      </div>
      <div className="rsb-icon"></div>
    </div>
  );
}

export default RoundSummaryBox;
