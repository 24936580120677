/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./ScoreModal.css";
import { db } from "../../../firebase";
import { BallTriangle } from "react-loader-spinner";
import { Avatar } from "@mui/material";

import GolfCourseIcon from "@mui/icons-material/GolfCourse";

function ScoreModal({ playerID, eventID, closeModal, roundID }) {
  const [playerDetails, setPlayerDetails] = useState([]);
  const [frontNineScores, setFrontNineScores] = useState([]);
  const [backNineScores, setBackNineScores] = useState([]);
  const [frontNineTotal, setFrontNineTotal] = useState(0);
  const [backNineTotal, setBackNineTotal] = useState(0);
  const [totalStrokes, setTotalStrokes] = useState(0);
  const [liveScore, setCurrentScore] = useState(0);
  const [frontPars, setFrontPars] = useState([]);
  const [backPars, setBackPars] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDetails();
  }, []);

  async function loadDetails() {
    // Player details
    const playerDoc = await db.collection("users").doc(playerID).get();
    const player = playerDoc.data();
    const { profileImage, firstName, lastName } = player;
    setPlayerDetails({
      profileImage,
      firstName,
      lastName,
    });
    // const {courseID,tees} = event;

    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { courseID, tees } = round;

    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const courseCards = course.tees;
    const scoreCard = courseCards[tees];
    const frontParArray = [];
    const backParArray = [];
    for (let i = 0; i < scoreCard.length; i++) {
      if (i < 9) {
        frontParArray.push(scoreCard[i].par);
      } else {
        backParArray.push(scoreCard[i].par);
      }
    }
    setFrontPars(frontParArray);
    setBackPars(backParArray);
    const scoreDoc = await db
      .collection("rounds")
      .doc(roundID)
      .collection("scores")
      .doc(playerID)
      .get();
    const scoreData = scoreDoc.data();
    const { holes, frontNineNet, backNineNet, totalNetStrokes } = scoreData;
    const firstNine = [];
    const backNine = [];
    let currentScore = scoreData.currentScore;
    if (currentScore === 0) {
      currentScore = "E";
    }

    for (let i = 0; i < holes.length; i++) {
      if (i < 9) {
        firstNine.push({
          netScore: holes[i].netScore,
          toPar: holes[i].toPar,
        });
      } else {
        backNine.push({
          netScore: holes[i].netScore,
          toPar: holes[i].toPar,
        });
      }
    }
    setTotalStrokes(totalNetStrokes);
    setFrontNineTotal(frontNineNet);
    setBackNineTotal(backNineNet);
    setFrontNineScores(firstNine);
    setBackNineScores(backNine);
    setCurrentScore(currentScore);
    setLoading(false);
  }

  return (
    <div
      onClick={(e) => {
        if (e.target.className === "score-modal") {
          closeModal();
        }
      }}
      className="score-modal"
    >
      {loading ? (
        <div className="loading-container">
          <BallTriangle color="#21c17c" height={80} width={80} />
        </div>
      ) : (
        <div className="score-modal-content">
          <div className="score-modal-profile">
            {/* <img alt={playerDetails.firstName} src={playerDetails.profileImage} /> */}
            <Avatar
              alt={playerDetails.firstName}
              src={playerDetails.profileImage}
            />
            <h2>
              {playerDetails.firstName} {playerDetails.lastName}
            </h2>
            <p>Current: {liveScore}</p>
          </div>

          <div className="score-modal-table">
            <table>
              <tbody>
                <tr className="header-row">
                  <td className="normal-td">
                    <GolfCourseIcon />
                  </td>
                  <td className="normal-td">1</td>
                  <td className="normal-td ">2</td>
                  <td className="normal-td ">3</td>
                  <td className="normal-td ">4</td>
                  <td className="normal-td ">5</td>
                  <td className="normal-td ">6</td>
                  <td className="normal-td ">7</td>
                  <td className="normal-td ">8</td>
                  <td className="normal-td ">9</td>
                  <td className="bold-td normal-td">Out</td>
                </tr>
                <tr className="par-row">
                  <td className="normal-td">Par</td>
                  {frontPars.map((par) => (
                    <td className="normal-td">{par}</td>
                  ))}
                </tr>
                <tr className="score-row">
                  <td className="normal-td">Score</td>
                  {frontNineScores.map((score) => (
                    <td
                      className={
                        score.toPar > 0
                          ? "score-td over-par"
                          : score.toPar < 0
                          ? "score-td under-par"
                          : "score-td"
                      }
                    >
                      {score.netScore}
                    </td>
                  ))}
                  <td className="bold-td normal-td">{frontNineTotal}</td>
                </tr>
                <tr className="header-row">
                  <td className="normal-td">Hole</td>
                  <td className="normal-td">10</td>
                  <td className="normal-td">11</td>
                  <td className="normal-td">12</td>
                  <td className="normal-td">13</td>
                  <td className="normal-td">14</td>
                  <td className="normal-td">15</td>
                  <td className="normal-td">16</td>
                  <td className="normal-td">17</td>
                  <td className="normal-td">18</td>
                  <td className="bold-td normal-td">In</td>
                  <td className="bold-td normal-td">Tot</td>
                </tr>
                <tr className="par-row">
                  <td className="normal-td">Par</td>
                  {backPars.map((par) => (
                    <td className="normal-td">{par}</td>
                  ))}
                </tr>
                <tr className="score-row">
                  <td className="normal-td">Score</td>
                  {backNineScores.map((score) => (
                    <td
                      className={
                        score.toPar > 0
                          ? "score-td over-par"
                          : score.toPar < 0
                          ? "score-td under-par"
                          : "score-td"
                      }
                    >
                      {score.netScore}
                    </td>
                  ))}
                  <td className="bold-td normal-td">{backNineTotal}</td>
                  <td className="bold-td normal-td">{totalStrokes}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default ScoreModal;
