import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TodayIcon from "@mui/icons-material/Today";

const GradientBackIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <ArrowBackIcon
      onClick={onClick}
      className="rbct-icon dg-icon-bg"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
const GradientForwardIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <ArrowForwardIcon
      onClick={onClick}
      className="rbct-icon dg-icon-bg"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function CustomToolBar(props) {
  const { label, date, view, views, onView, onNavigate } = props;
  const [month, setMonth] = useState("January");
  const [year, setYear] = useState(new Date().getFullYear());
  const mMonth = moment(date).format("MMMM");
  const mYear = moment(date).format("YYYY");
  const months = moment.months();
  const yearsFunc = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
  };

  const [years, setYears] = useState([]);

  useEffect(() => {
    const now = new Date();
    const diff = now.getFullYear() - 2021;
    const yearsCalc = yearsFunc(diff);
    setYears(yearsCalc);
  }, []);

  useEffect(() => {
    setMonth(mMonth);
  }, [mMonth]);

  useEffect(() => {
    setYear(mYear);
  }, [mYear]);

  const onChange = (event) => {
    const current = event.target.value;
    onNavigate("DATE", moment().month(current).toDate());
    setMonth(current);
  };

  const yearChange = (event) => {
    const current = event.target.value;
    onNavigate("DATE", moment().year(current).toDate());
    setYear(current);
  };

  const goToView = (view) => {
    onView(view);
  };

  const goToBack = () => {
    onNavigate("PREV");
  };
  const goToNext = () => {
    onNavigate("NEXT");
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };
  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group rbc-icons">
        <GradientBackIcon className="rbct-icon" onClick={goToBack} />
        <p className="rbct-today" onClick={goToToday}>
          Today
        </p>

        <GradientForwardIcon className="rbct-icon" onClick={goToNext} />
      </div>
      <div className="rbc-toolbar-label">
        {view === "month" ? (
          <>
            <select
              className="rbc-dropdown mr-20"
              value={month}
              onChange={onChange}
            >
              {months?.map((month) => (
                <option
                  value={month}
                  className="rbc-dropdown-option" //custom class
                  key={month}
                >
                  {month}
                </option>
              ))}
            </select>
            <select className="rbc-dropdown" value={year} onChange={yearChange}>
              {years?.map((year) => (
                <option
                  value={year}
                  className="rbc-dropdown-option" //custom class
                  key={year}
                >
                  {year}
                </option>
              ))}
            </select>
          </>
        ) : (
          label
        )}
      </div>
      {/* <div className="rbc-btn-group">
        <p>Something will go here</p>
      </div> */}
    </div>
  );
}

export default CustomToolBar;
