import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

// Icons
import { Edit } from "@mui/icons-material";

// Comps
import PageHeader from "../../display/Rows/PageHeader";
import BackRow from "../../display/Rows/BackRow";
import BackdropLoader from "../../Loaders/BackdropLoader";
import RadioGroup from "../../Buttons/RadioGroup/RadioGroup";
import RadioGroupTwo from "../../Buttons/RadioGroup/RadioGroupTwo";
function RyderTeams({
  eventID = "",
  goBack = () => {},
  reload = () => {},
  players = [],
}) {
  useEffect(() => {
    loadDetails();
  }, []);

  const [intTeam, setIntTeam] = useState([]);
  const [saTeam, setSATeam] = useState([]);
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(true);

  const [showEdit, setShowEdit] = useState(false);

  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  async function loadDetails() {
    const eventDoc = await db.collection("events").doc(eventID).get();
    const evt = eventDoc.data();
    const ryderMap = evt.ryderMap ?? {};
    const itArr = [];
    const saArr = [];

    if (ryderMap === {}) {
      console.log(players);
    }

    for (const [key, value] of Object.entries(ryderMap)) {
      if (value === "southAfrica") {
        saArr.push(key);
      } else {
        itArr.push(key);
      }
    }

    setIntTeam(itArr);
    setSATeam(saArr);
    setLoading(false);
  }

  async function saveChanges() {
    const mep = {};
    setLoading(true);
    for (let i = 0; i < intTeam.length; i++) {
      mep[intTeam[i]] = "international";
    }
    for (let i = 0; i < saTeam.length; i++) {
      mep[saTeam[i]] = "southAfrica";
    }

    try {
      await db.collection("events").doc(eventID).update({
        ryderMap: mep,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="ryder-teams">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Ryder Cup Teams" />

      <p className="green-link ml-20" onClick={() => setShowEdit(true)}>
        Edit
      </p>

      {!showEdit && (
        <>
          <div className="rt-display">
            <div className="rt-col rt-left">
              <div className="rt-header">
                <h3>International</h3>
              </div>
              <div className="rt-players">
                {players.map((pl, i) => {
                  if (!intTeam.includes(pl.playerID)) {
                    return null;
                  }
                  return (
                    <div className="rt-players">
                      <p>{pl.name}</p>{" "}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="rt-col rt-right">
              <div className="rt-header">
                <h3>South Africa</h3>
              </div>
              <div className="rt-players">
                {players.map((pl, i) => {
                  if (!saTeam.includes(pl.playerID)) {
                    return null;
                  }
                  return (
                    <div key={`${pl.playerID}`} className="rt-players">
                      <p>{pl.name}</p>{" "}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
      {showEdit && (
        <>
          <button
            onClick={saveChanges}
            className="default-button mt-20 ml-20 mb-40"
          >
            Save Changes
          </button>
          <div className="rt-edit">
            {players.map((player, i) => {
              let team = "";

              if (saTeam.includes(player.playerID)) {
                team = "southAfrica";
              }
              if (intTeam.includes(player.playerID)) {
                team = "international";
              }

              console.log(team);

              return (
                <div className="rt-edit-player" key={player.playerID}>
                  <div className="rt-ep-pl">
                    <p>{player.name}</p>
                  </div>

                  <div className="rt-ep-sel">
                    <RadioGroupTwo
                      active={team}
                      buttons={[
                        { text: "International", value: "international" },
                        { text: "South Africa", value: "southAfrica" },
                      ]}
                      setActive={(e) => {
                        if (e === "international") {
                          if (!intTeam.includes(player.playerID)) {
                            setIntTeam((c) => {
                              c.push(player.playerID);
                              return c;
                            });
                          }
                          if (saTeam.includes(player.playerID)) {
                            setSATeam((c) => {
                              const filt = c.filter(
                                (pl) => pl !== player.playerID
                              );
                              return filt;
                            });
                          }
                        }
                        if (e === "southAfrica") {
                          if (!saTeam.includes(player.playerID)) {
                            setSATeam((c) => {
                              c.push(player.playerID);
                              return c;
                            });
                          }
                          if (intTeam.includes(player.playerID)) {
                            setIntTeam((c) => {
                              const filt = c.filter(
                                (pl) => pl !== player.playerID
                              );
                              return filt;
                            });
                          }
                        }
                        update();
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default RyderTeams;
