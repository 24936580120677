import { Info } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React from "react";
import PageHeader from "../display/Rows/PageHeader";

function InfoModal({ text = "", hide = () => {} }) {
  return (
    <Dialog open onClose={hide}>
      <div className="info-message">
        <PageHeader text="" showClose close={hide} />
        <div className="svg-cont">
          <Info className="big-info-icon dg-icon" />
        </div>

        <p>{text}</p>
      </div>
    </Dialog>
  );
}

export default InfoModal;
