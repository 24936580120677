import React, { useState, useEffect } from "react";
import "./BroacastModal.css";
import { db } from "../../firebase";
import BackdropLoader from "../Loaders/BackdropLoader";
import { useClickTheme } from "../../contexts/MenuContext";
import { useAuth } from "../../contexts/AuthContext";

// Icons
import Close from "@mui/icons-material/Close";

// Components
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import RadioGroupTwo from "../Buttons/RadioGroup/RadioGroupTwo";
import CourseItem from "../display/CourseItem/CourseItem";
import FixturePreview from "../display/FixturePreview/FixturePreview";
import PageHeader from "../display/Rows/PageHeader";

function BroadcastModal({
  close = () => {},
  eventID = "",
  teamEvent = false,
  name = "",
  leagueID = "",
  cupID = "",
  showChat = () => {},
}) {
  const showClick = useClickTheme();
  const { currentUser } = useAuth();

  // State
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [text, setText] = useState("");
  const [chatType, setChatType] = useState("group");

  const [leagues, setLeagues] = useState([]);
  const [events, setEvents] = useState([]);
  const [selected, setSelected] = useState();
  const [selType, setSelType] = useState("");

  const [eventSearch, setEventSearch] = useState("");

  // UE
  useEffect(() => {
    if (eventID || leagueID) {
      setLoading(false);
    } else if (currentUser) {
      loadOptions();
    }
  }, [currentUser]);

  // Functions
  async function createEventChat(eventID) {
    setLoading(true);
    const eventDoc = await db.collection("events").doc(eventID).get();
    const event = eventDoc.data();
    const { playerIDs, imageLink, adminID, leagueID } = event;
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { admins } = league;
    const lastMessage = {
      read: [],
      sender: currentUser.uid,
      unread: playerIDs,
      text,
      sentAt: new Date(),
    };
    // const filtered = playerIDs.filter((uid) => !admins.includes(uid));
    try {
      const chatDoc = await db.collection("chats").add({
        eventID,
        isGroupChat: true,
        chatName:
          chatType === "admin"
            ? `${event.eventName} (Broadcast)`
            : `${event.eventName} (Group)`,
        lastMessage,
        imageLink,
        userIDs: playerIDs,
        chatType,
        adminIDs: admins,
      });
      await chatDoc.collection("messages").add({
        read: [],
        sender: currentUser.uid,
        text,
        sentAt: new Date(),
        unread: playerIDs,
      });
      await db.collection("events").doc(eventID).update({
        chatID: chatDoc.id,
      });
      setTimeout(() => {
        showClick({
          type: "chat",
          chatID: chatDoc.id,
        });
      }, 1000);
      // Open chat from here
      close();
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Sorry - we encountered a technical difficulty there. Please try again"
      );
    }
    setLoading(false);
  }

  async function createTeamEventChat(eventID) {
    setLoading(true);
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();
    const { playerIDs, imageLink, adminID, leagueID } = event;
    const leagueDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { admins } = league;
    const lastMessage = {
      read: [],
      unread: playerIDs,
      sender: currentUser.uid,
      text,
      sentAt: new Date(),
    };
    // const filtered = playerIDs.filter((uid) => !admins.includes(uid));
    try {
      const chatDoc = await db.collection("chats").add({
        eventID,
        isGroupChat: true,
        chatName:
          chatType === "admin"
            ? `${event.eventName} (Broadcast)`
            : `${event.eventName} (Group)`,

        lastMessage,
        imageLink,
        userIDs: playerIDs,
        chatType,
        adminIDs: admins,
      });
      await chatDoc.collection("messages").add({
        read: [],
        sender: currentUser.uid,
        text,
        unread: playerIDs,
        sentAt: new Date(),
      });
      await db.collection("teamEvents").doc(eventID).update({
        chatID: chatDoc.id,
      });
      setTimeout(() => {
        showClick({
          type: "chat",
          chatID: chatDoc.id,
        });
      }, 1000);
      // Open chat from here
      close();
    } catch (err) {
      setErrorMessage(
        "Sorry - we encountered a technical difficulty there. Please try again"
      );
    }
    setLoading(false);
  }

  async function createLeagueChat(leagueID) {
    setLoading(true);
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    const league = leagueDoc.data();
    const { playerIDs, imageLink, admins } = league;
    const lastMessage = {
      read: [],
      unread: playerIDs,
      sender: currentUser.uid,
      text,
      sentAt: new Date(),
    };
    // const filtered = playerIDs.filter((uid) => !admins.includes(uid));
    try {
      const chatDoc = await db.collection("chats").add({
        leagueID,
        isGroupChat: true,
        chatName:
          chatType === "admin"
            ? `${league.leagueName} (Broadcast)`
            : `${league.leagueName} (Group)`,
        lastMessage,
        imageLink,
        userIDs: playerIDs,
        chatType,
        adminIDs: admins,
      });
      await chatDoc.collection("messages").add({
        read: [],
        text,
        sender: currentUser.uid,
        unread: playerIDs,
        sentAt: new Date(),
      });
      await db.collection("leagues").doc(leagueID).update({
        chatID: chatDoc.id,
      });
      setTimeout(() => {
        showClick({
          type: "chat",
          chatID: chatDoc.id,
        });
      }, 1000);
      // Open chat from here
      close();
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Sorry - we encountered a technical difficulty there. Please try again"
      );
    }
    setLoading(false);
  }

  async function loadOptions() {
    const leagueArr = [];
    const eventArr = [];

    const date = new Date();
    date.setMonth(date.getMonth() - 1);

    const leagues = await db
      .collection("leagues")
      .where("admins", "array-contains", currentUser.uid)
      .get();

    for (let i = 0; i < leagues.docs.length; i++) {
      const leagueDoc = leagues.docs[i];
      const league = leagueDoc.data();
      const leagueID = leagueDoc.id;
      const { leagueName, imageLink, playerIDs, city, country, chatID } =
        league;

      leagueArr.push({
        leagueName,
        imageLink,
        playerIDs,
        leagueID,
        city,
        country,
        chatID,
      });

      // Events
      const eventDocs = await db
        .collection("events")
        .where("leagueID", "==", leagueID)
        .where("eventDate", ">", date)
        .orderBy("eventDate", "desc")
        .get();

      for (let j = 0; j < eventDocs.docs.length; j++) {
        const eventDoc = eventDocs.docs[j];
        const eventID = eventDoc.id;
        const event = eventDoc.data();
        const { eventName, imageLink, eventDate, chatID } = event;
        const dateObj = new Date(eventDate.toDate());
        const dateString = dateObj.toLocaleDateString("en-us", {
          dateStyle: "full",
        });
        eventArr.push({
          eventID,
          eventName,
          imageLink,
          dateString,
          dateObj,
          chatID,
        });
      }
    }
    const sorted = eventArr.sort((a, b) => (a.dateObj > b.dateObj ? 1 : -1));
    setEvents(sorted);
    setLeagues(leagueArr);
    setLoading(false);
  }

  return (
    <div className="broadcast-modal">
      <Dialog onClose={close} open className="bcm-dia">
        <PageHeader text="Broadcast Message" showClose close={close} />
        <div
          style={{ width: leagueID || eventID ? "50vw" : "80vw" }}
          className="bm-dia"
        >
          {loading ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : leagueID === "" && eventID === "" ? (
            <>
              {selected ? (
                <>
                  <div className="ph-msg mb-20">
                    {selType === "event" ? (
                      <p>
                        Send a message to all attendees of {selected.eventName}
                      </p>
                    ) : selType === "league" ? (
                      <p>
                        Send a message to all members of {selected.leagueName}
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>
                  <RadioGroupTwo
                    buttons={[
                      { text: "Group Chat", value: "group" },
                      { text: "Broadcast", value: "admin" },
                    ]}
                    active={chatType}
                    setActive={setChatType}
                  />

                  <div className="chat-type">
                    {chatType === "admin" ? (
                      <p>Only admins will be allowed to send messages</p>
                    ) : (
                      <p>
                        Any member of the group will be able to send messages
                      </p>
                    )}
                  </div>

                  <div className="bm-input pl-20">
                    <textarea
                      placeholder="Enter message..."
                      className="default-input"
                      rows={"10"}
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    ></textarea>
                  </div>
                  {errorMessage !== "" && (
                    <div className="error-message-row">
                      <p>{errorMessage}</p>
                    </div>
                  )}
                  <div className="default-button-row flex-center">
                    <button
                      onClick={() => {
                        if (selType === "event") {
                          createEventChat(selected.eventID);
                        }
                        if (selType === "league") {
                          createLeagueChat(selected.leagueID);
                        }
                      }}
                      disabled={text === ""}
                      className="default-button"
                    >
                      Send
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="ph-msg">
                    <p>Select a league or event to message</p>
                  </div>
                  <div className="sub-header">
                    <h5>Leagues</h5>
                  </div>
                  <div className="bm-leagues">
                    {leagues.map((league, index) => (
                      <CourseItem
                        key={league.leagueID}
                        city={league.city}
                        country={league.country}
                        courseName={league.leagueName}
                        hideStar
                        members={league.playerIDs.length}
                        leaderName={league.leaderName}
                        image={league.imageLink}
                        showCourse={() => {
                          setSelected(league);
                          setSelType("league");
                        }}
                      />
                    ))}{" "}
                  </div>
                  <div className="sub-header">
                    <h5>Events</h5>
                  </div>
                  <div className="input-group pl-20">
                    <p>Search</p>
                    <input
                      type="text"
                      value={eventSearch}
                      onChange={(e) => setEventSearch(e.target.value)}
                      className="default-input"
                    />
                  </div>
                  <div className="bm-events">
                    {events.map((event, index) => {
                      if (eventSearch !== "") {
                        const sl = eventSearch.toLowerCase();
                        const nl = event.eventName.toLowerCase();
                        if (nl.includes(sl)) {
                          return (
                            <FixturePreview
                              past
                              event={event}
                              showDetails={() => {
                                setSelected(event);
                                setSelType("event");
                              }}
                            />
                          );
                        } else {
                          return null;
                        }
                      }

                      return (
                        <FixturePreview
                          past
                          event={event}
                          showDetails={() => {
                            console.log(event.chatID);
                            if (event.chatID) {
                              showChat(event.chatID);
                              close();
                            }
                            setSelected(event);
                            setSelType("event");
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="ph-msg mb-20">
                {eventID ? (
                  <p>Send a message to all attendees of {name}</p>
                ) : leagueID ? (
                  <p>Send a message to all members of {name}</p>
                ) : (
                  <p></p>
                )}
              </div>
              <RadioGroupTwo
                buttons={[
                  { text: "Group Chat", value: "group" },
                  { text: "Broadcast", value: "admin" },
                ]}
                active={chatType}
                setActive={setChatType}
              />

              <div className="chat-type">
                {chatType === "admin" ? (
                  <p>Only admins will be allowed to send messages</p>
                ) : (
                  <p>Any member of the group will be able to send messages</p>
                )}
              </div>

              <div className="bm-input pl-20">
                <textarea
                  placeholder="Enter message..."
                  className="default-input"
                  rows={"10"}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
              </div>
              {errorMessage !== "" && (
                <div className="error-message-row">
                  <p>{errorMessage}</p>
                </div>
              )}
              <div className="default-button-row flex-center">
                <button
                  onClick={() => {
                    if (eventID) {
                      if (teamEvent) {
                        createTeamEventChat(eventID);
                      } else {
                        createEventChat(eventID);
                      }
                    }
                    if (leagueID) {
                      createLeagueChat(leagueID);
                    }
                  }}
                  disabled={text === ""}
                  className="default-button"
                >
                  Send
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default BroadcastModal;
