import React from "react";

function FairUse() {
  return (
    <div className="fair-use">
      <h1>ACCEPTABLE USE POLICY</h1>
      <h2>
        Acceptable Use Policy for The Commissioner Tournament Management &amp;
        live scoring platform
      </h2>

      <h3>1. Introduction</h3>
      <p>
        Welcome to The Commissioner Tournament Management &amp; live scoring
        platform ("the Software"). This Acceptable Use Policy outlines the terms
        and conditions for using our software. By accessing and using our
        software, you agree to comply with this policy and any applicable laws
        and regulations. Failure to comply with this policy may result in the
        termination of your account or access to the software.
      </p>

      <h3>2. Permitted Use</h3>
      <p>
        Our software is designed to facilitate the management and participation
        in golf tournaments. Users are permitted to:
      </p>
      <ul>
        <li>
          Register and create an account for legitimate use of the software.
        </li>
        <li>Participate in golf tournaments hosted on the platform.</li>
        <li>
          Access and use the features and functionalities provided by the
          software.
        </li>
        <li>Interact with other users in a respectful and lawful manner.</li>
      </ul>

      <h3>3. Prohibited Use</h3>
      <p>
        Users must not engage in any activities that violate this Acceptable Use
        Policy or any applicable laws. Prohibited activities include but are not
        limited to:
      </p>
      <ul>
        <li>
          Using the software for any unlawful, fraudulent, or malicious
          purposes.
        </li>
        <li>
          Sharing, posting, or transmitting any content that is defamatory,
          abusive, obscene, or offensive.
        </li>
        <li>
          Engaging in any activity that may compromise the security or integrity
          of the software or its users' data.
        </li>
        <li>
          Attempting to gain unauthorized access to the software or other users'
          accounts.
        </li>
      </ul>

      <h3>4. Content Ownership and Responsibility</h3>
      <p>
        Users are solely responsible for the content they upload, share, or
        transmit through the software. By submitting content, users affirm that
        they have the necessary rights and permissions to do so and grant the
        software operators a non-exclusive license to use, display, and
        distribute the content in connection with the software's operation.
      </p>

      <h3>5. Enforcement and Consequences</h3>
      <p>
        We reserve the right to monitor user activity and content on the
        software to ensure compliance with this policy. In case of any
        violations, we may take appropriate actions, including but not limited
        to:
      </p>
      <ul>
        <li>Issuing warnings to users.</li>
        <li>Temporarily suspending or restricting access to the software.</li>
        <li>Terminating user accounts and access permanently.</li>
      </ul>

      <h3>6. Reporting Violations</h3>
      <p>
        If you believe that a user has violated this Acceptable Use Policy,
        please promptly report the incident to our support team at{" "}
        <a href="mailto:info@theugatour.com">info@theugatour.com</a>
      </p>

      <h3>7. Policy Updates</h3>
      <p>
        This Acceptable Use Policy may be updated from time to time to reflect
        changes in the software or its usage. Users will be notified of
        significant policy changes through appropriate communication channels.
      </p>

      <p>
        By using our software, you acknowledge that you have read, understood,
        and agree to abide by this Acceptable Use Policy. If you do not agree
        with any part of this policy, please refrain from using the software.
      </p>
    </div>
  );
}

export default FairUse;
