import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { CSVLink, CSVDownload } from "react-csv";
import { useNavigate, useParams } from "react-router-dom";

// Icons

// Components
import { Oval } from "react-loader-spinner";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import BackRow from "../../components/display/Rows/BackRow";
import StatsHeader from "../../components/Leagues/LeagueEdit/StatsHeader";
import StatsItem from "../../components/Leagues/LeagueEdit/StatsItem";
import { Dialog } from "@mui/material";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";

function ClubPlayerStats() {
  // Admi
  const droptions = [
    { label: "General", value: "General" },
    { label: "Scoring", value: "Scoring" },
    { label: "Holes", value: "Holes" },
    { label: "Matchplay", value: "Matchplay" },
  ];
  const scoreOptions = [
    {
      label: "Eagles (Net)",
      value: "averageEagles",
      type: "net",
    },
    {
      label: "Eagles (Gross)",
      value: "averageEagles",
      type: "gross",
    },
    {
      label: "Birdies (Net)",
      value: "averageBirdies",
      type: "net",
    },
    {
      label: "Birdies (Gross)",
      value: "averageBirdies",
      type: "gross",
    },
    {
      label: "Pars (Net)",
      value: "averagePars",
      type: "net",
    },
    {
      label: "Pars (Gross)",
      value: "averagePars",
      type: "gross",
    },
    {
      label: "Bogies (Net)",
      value: "averageBogies",
      type: "net",
    },
    {
      label: "Bogies (Gross)",
      value: "averageBogies",
      type: "gross",
    },
    {
      label: "Doubles (Net)",
      value: "averageDoubles",
      type: "net",
    },
    {
      label: "Doubles (Gross)",
      value: "averageDoubles",
      type: "gross",
    },
  ];
  const holeOptions = [
    {
      label: "Par 3s (Net)",
      value: "averageParThrees",
      type: "net",
    },
    {
      label: "Par 3s (Gross)",
      value: "averageParThrees",
      type: "gross",
    },
    {
      label: "Par 4s (Net)",
      value: "averageParFours",
      type: "net",
    },
    {
      label: "Par 4s (Gross)",
      value: "averageParFours",
      type: "gross",
    },
    {
      label: "Par 5s (Net)",
      value: "averageParFives",
      type: "net",
    },
    {
      label: "Par 5s (Gross)",
      value: "averageParFives",
      type: "gross",
    },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
    // {
    //   label: "",
    //   value: "",
    //   type: "",
    // },
  ];
  const generalOptions = [
    {
      label: "Net Ave",
      value: "averageNet",
      type: "net",
    },
    {
      label: "Gross Ave",
      value: "averageGross",
      type: "net",
    },
    {
      label: "Putts",
      value: "averagePutts",
      type: "net",
    },
    {
      label: "FIR",
      value: "averageFIR",
      type: "net",
    },
    {
      label: "GIR",
      value: "averageGIR",
      type: "net",
    },
    {
      label: "Net GIR",
      value: "averageNetGIR",
      type: "net",
    },
    {
      label: "Wins",
      value: "wins",
      type: "net",
    },
    {
      label: "Top 3s",
      value: "topThreeFinishes",
      type: "net",
    },
    {
      label: "Top 10s",
      value: "topTenFinishes",
      type: "net",
    },
    {
      label: "Top 20s",
      value: "topTwentyFinishes",
      type: "net",
    },
  ];

  const mpOptions = [
    {
      label: "Win %",
      value: "winPercentage",
      type: "mp",
    },
    {
      label: "Loss %",
      value: "lossPercentage",
      type: "mp",
    },
    {
      label: "MP Win %",
      value: "matchplayWinPercentage",
      type: "mp",
    },
    {
      label: "FB Win %",
      value: "fourballWinPercentage",
      type: "mp",
    },
    {
      label: "Hole Win %",
      value: "holeWinPercentage",
      type: "mp",
    },
    {
      label: "Hole Loss %",
      value: "holeLossPercentage",
      type: "mp",
    },
    {
      label: "Par 3s",
      value: "averageGrossParThrees",
      type: "mp",
    },
    {
      label: "Par 4s",
      value: "averageGrossParFours",
      type: "mp",
    },
    {
      label: "Par 5s",
      value: "averageGrossParFives",
      type: "mp",
    },
    {
      label: "1 Putt Wins",
      value: "onePuttWins",
      type: "mp",
    },
  ];

  const statsProp = {
    handicap: 0,
    averageAlbatrosses: 0,
    averageTriples: 0,
    averageOther: 0,
    eventRounds: 0,
    lowestEventNet: 0,
    lowestEventGross: 0,
    eventNetAverage: 0,
    eventGrossAverage: 0,
    customNetAverage: 0,
    customGrossAverage: 0,
    playoffsContested: 0,
    playoffsWon: 0,
    puttsPerGIR: 0,
    currentNoThreePuttStreak: 0,
    netBogeyFreeRounds: 0,
    grossBogeyFreeRounds: 0,
    sandHoleouts: 0,
    sandies: 0,
    sandyAttempts: 0,
    // averageDTP: 0,
    // mostPuttsMade: 0,
    // longestPutt: 0,
    averageBirdies: 0,
    averageBogies: 0,
    averageDoubles: 0,
    averageEagles: 0,
    averageFIR: 0,
    averageGIR: 0,
    // averageNetGIR: 0,
    averageGross: 0,
    averageNet: 0,
    averageParFives: 0,
    averageParFours: 0,
    averageParThrees: 0,
    averagePars: 0,
    averagePutts: 0,
    totalRounds: 0,
    totalHoles: 0,
    totalBirdies: 0,
    totalBogies: 0,
    totalDoubles: 0,
    totalEagles: 0,
    totalPars: 0,
    totalPutts: 0,
    // netGIRPutts: 0,
    grossGIRPutts: 0,
    wins: 0,
    topTwentyFinishes: 0,
    topTenFinishes: 0,
    topThreeFinishes: 0,
    parThreeGrossGIRScoringAverage: 0,
    parFourGrossGIRScoringAverage: 0,
    parFiveGrossGIRScoringAverage: 0,
    parFourGrossFIRScoringAverage: 0,
    parFiveGrossFIRScoringAverage: 0,
    // girGrossAverageToPar: 0,
    // firGrossAverageToPar: 0,
    parThreeNetGIRScoringAverage: 0,
    parFourNetGIRScoringAverage: 0,
    parFiveNetGIRScoringAverage: 0,
    parFourNetFIRScoringAverage: 0,
    parFiveNetFIRScoringAverage: 0,
    // girNetAverageToPar: 0,
    // firNetAverageToPar: 0,
    lowestGrossNineHoleScore: 0,
    lowestNetNineHoleScore: 0,
    totalGrossScrambleAttempts: 0,
    totalNetScrambleAttempts: 0,
    totalGrossScrambles: 0,
    totalNetScrambles: 0,
    holeOuts: 0,
    onePutts: 0,
    threePutts: 0,
    grossBounceBackBirdies: 0,
    netBounceBackBirdies: 0,
    // percentagePointsWon: 0,
    grossBirdieStreak: 0,
    netBirdieStreak: 0,
    highestGirStreak: 0,
    highestFirStreak: 0,
    highestNetGirStreak: 0,
    currentOnePuttStreak: 0,
    highestNoThreePuttStreak: 0,
    highestOnePuttStreak: 0,
    // currentGrossSubParRoundsStreak: 0,
    // currentNetSubParRoundsStreak: 0,
    highestGrossSubParRoundsStreak: 0,
    highestNetSubParRoundsStreak: 0,
    // grossSubParHolesStreak: 0,
    // netSubParHolesStreak: 0,
    // averagePuttLength: 0,
    netBirdieAttempts: 0,
    grossBirdieAttempts: 0,
    lowestRound: 0,
    lowestRoundGross: 0,
    highestRound: 0,
    highestRoundGross: 0,
    fewestPutts: 0,
    mostPutts: 0,
    drivingDistance: 0,
    longestDrive: 0,
  };

  const grossStatsProp = {
    averageBirdies: 0,
    averageBogies: 0,
    averageDoubles: 0,
    averageEagles: 0,
    averageOthers: 0,
    averageParFives: 0,
    averageParFours: 0,
    averageParThrees: 0,
  };

  const mpStatsProp = {
    totalRounds: 0,
    totalMatchplayRounds: 0,
    totalFourballRounds: 0,
    totalWins: 0,
    totalLosses: 0,
    totalTies: 0,
    winPercentage: 0,
    lossPercentage: 0,
    tiePercentage: 0,
    holeWinPercentage: 0,
    holeLossPercentage: 0,
    holeTiePercentage: 0,
    matchplayHoleTiePercentage: 0,
    matchplayHoleWinPercentage: 0,
    matchplayHoleLossPercentage: 0,
    fourballHoleTiePercentage: 0,
    fourballHoleWinPercentage: 0,
    fourballHoleLossPercentage: 0,
    matchplayWinPercentage: 0,
    matchplayLossPercentage: 0,
    matchplayTiePercentage: 0,
    fourballWinPercentage: 0,
    fourBallLossPercentage: 0,
    fourballTiePercentage: 0,
    totalMatchplayWins: 0,
    totalMatchplayLosses: 0,
    totalMatchplayTies: 0,
    totalFourballWins: 0,
    totalFourballLosses: 0,
    totalFourballTies: 0,
    holesPlayed: 0,
    matchplayHolesPlayed: 0,
    fourballHolesPlayed: 0,
    totalPutts: 0,
    threePutts: 0,
    onePutts: 0,
    onePuttWins: 0,
    puttsPerHole: 0,
    totalFIR: 0,
    totalGIR: 0,
    totalNetGIR: 0,
    holesWon: 0,
    holesLost: 0,
    holesTied: 0,
    matchplayHolesWon: 0,
    matchplayHolesLost: 0,
    matchplayHolesTied: 0,
    fourballHolesWon: 0,
    fourballHolesLost: 0,
    fourballHolesTied: 0,
    girHolesWon: 0,
    firHolesWon: 0,
    netGIRHolesWon: 0,
    totalParThreesPlayed: 0,
    totalParFoursPlayed: 0,
    totalParFivesPlayed: 0,
    totalParThrees: 0,
    totalParFours: 0,
    totalParFives: 0,
    totalGrossParThrees: 0,
    totalGrossParFours: 0,
    totalGrossParFives: 0,
    averageParThrees: 0,
    averageParFours: 0,
    averageParFives: 0,
    averageGrossParThrees: 0,
    averageGrossParFours: 0,
    averageGrossParFives: 0,
    totalEagles: 0,
    totalBirdies: 0,
    totalPars: 0,
    totalBogies: 0,
    totalDoubles: 0,
    totalOthers: 0,
    totalGrossEagles: 0,
    totalGrossBirdies: 0,
    totalGrossPars: 0,
    totalGrossBogies: 0,
    totalGrossDoubles: 0,
    totalGrossOther: 0,
    totalScrambleAttempts: 0,
    totalNetScrambleAttempts: 0,
    totalScrambles: 0,
    totalNetScrambles: 0,
    scrambleWins: 0,
    sandyAttempts: 0,
    sandies: 0,
    sandyWins: 0,
    totalHoleouts: 0,
    holeOutWins: 0,
    totalToPar: 0,
    averageToPar: 0,
    totalNetToPar: 0,
    averageNetToPar: 0,
    parThreesWon: 0,
    parFoursWon: 0,
    parFivesWon: 0,
  };

  // State
  const [selected, setSelected] = useState("averageNet");
  const [selectedType, setSelectedType] = useState("net");
  const [selectedTitle, setSelectedTitle] = useState("General");
  const [selectedOptions, setSelectedOptions] = useState(generalOptions);
  const [dir, setDir] = useState("down");
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [upd, setUpd] = useState((c) => c + 1);
  function update() {
    setUpd((c) => c + 1);
  }
  const [clubName, setClubName] = useState("");
  const [groups, setGroups] = useState([]);
  const [groupCSV, setGroupCSV] = useState([]);
  const [selGroup, setSelGroup] = useState("");

  const [dlCSV, setDLCSV] = useState(false);

  const [showGroups, setShowGroups] = useState(false);

  const [csv, setCSV] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  // UE
  useEffect(() => {
    loadStats();
  }, []);

  // Functions
  async function loadStats() {
    // console.log(id);
    const clubDoc = await db.collection("clubs").doc(id).get();
    const club = clubDoc.data();
    // console.log(club);
    const { memberIDs } = club;
    const statsArr = [];
    setClubName(club.clubName);
    const statsRefs = memberIDs.map((id) =>
      db.collection("users").doc(id).get()
    );
    const statsDocs = await Promise.all(statsRefs);

    const csvArr = [];

    // LOAD GROUPS
    const memberGroups = club.memberGroups ?? [];
    memberGroups.unshift({
      name: "All",
      groupType: "all",
    });

    setGroups(memberGroups);

    const shRow = Object.keys(statsProp).length;
    // console.log(shRow);
    let headerRow = Object.keys(statsProp);
    headerRow.unshift("gender");
    headerRow.unshift("Player");
    headerRow.unshift("uid");

    const grossStatsHeaderRow = Object.keys(grossStatsProp);
    for (let i = 0; i < grossStatsHeaderRow.length; i++) {
      headerRow.push(`gross${grossStatsHeaderRow[i]}`);
    }
    // const mpStatsHeaderRow = Object.keys(mpStatsProp);
    // for (let i = 0; i < mpStatsHeaderRow.length; i++) {
    //   headerRow.push(`gross${mpStatsHeaderRow[i]}`);
    // }
    csvArr.push(headerRow);

    for (let i = 0; i < statsDocs.length; i++) {
      const statsDoc = statsDocs[i];
      const playerID = statsDoc.id;
      const statsData = statsDoc.data();

      if (statsData === undefined) {
        continue;
      }

      // const { stats, grossStats } = statsData;
      const stats = statsData.stats ?? statsProp;
      stats.lowestRound = statsData.lowestRound ?? 0;
      stats.lowestRoundGross = statsData.lowestRoundGross ?? 0;
      // console.log(stats);
      const grossStats = statsData.grossStats ?? grossStatsProp;
      const matchplayStats = statsData.matchplayStats ?? mpStatsProp;

      if (i === 0) {
      }

      const userName = `${statsData.firstName} ${statsData.lastName}`;

      statsArr.push({
        matchplayStats,
        netStats: stats,
        grossStats,
        player: {
          userName: `${statsData.firstName} ${statsData.lastName}`,
          handicap: statsData.handicap,
        },
        playerID,
        gender: statsData.gender ?? "male",
      });

      const statArr = [];

      for (let j = 0; j < csvArr[0].length; j++) {
        let headerVal = csvArr[0][j];

        if (j === 1) {
          statArr.push(userName);
          continue;
        }

        if (j === 0) {
          statArr.push(playerID);
          continue;
        }
        if (j === 3) {
          statArr.push(statsData.handicap);
          continue;
        }
        if (j === 2) {
          statArr.push(statsData.gender ?? "male");
          continue;
        }

        if (j <= shRow + 1) {
          const val = stats[headerVal] ?? 0;
          if (!isNaN(val)) {
            statArr.push(val);
          } else {
            statArr.push(0);
          }

          if (i === 0) {
            // console.log(headerVal, stats[headerVal]);
          }
        } else if (
          j > shRow + 1 &&
          j < shRow + grossStatsHeaderRow.length + 3
        ) {
          const sliced = headerVal.slice(5);

          const val = grossStats[sliced] ?? "";
          if (!isNaN(val)) {
            statArr.push(val);
          } else {
            statArr.push(0);
          }
        }

        if (false) {
          const val = matchplayStats[headerVal] ?? "";
          statArr.push(val);
        }
      }

      csvArr.push(statArr);

      if (stats.totalRounds !== 0 && false) {
        let statArr = Object.values(stats);
        statArr.unshift(userName);
        const grossStatArr = Object.values(grossStats);
        statArr = [...statArr, ...grossStatArr];
        const mpStatArr = Object.values(matchplayStats);
        statArr = [...statArr, ...mpStatArr];

        csvArr.push(statArr);
      }
    }

    console.log(csvArr);
    setCSV(csvArr);
    setStats(statsArr);
    setLoading(false);
  }

  function sortStats(item, type) {
    console.log(item, type);
    setLoading(true);
    setStats((current) => {
      if (type === "net") {
        if (dir === "down") {
          current.sort((a, b) =>
            a.netStats[item] > b.netStats[item] || a.netStats[item] === 0
              ? 1
              : -1
          );
        } else {
          current.sort((a, b) =>
            a.netStats[item] < b.netStats[item] ? 1 : -1
          );
        }
      } else if (type === "mp") {
        if (dir === "up") {
          current.sort((a, b) =>
            a.matchplayStats[item] > b.matchplayStats[item] ||
            a.matchplayStats[item] === 0
              ? 1
              : -1
          );
        } else {
          current.sort((a, b) =>
            a.matchplayStats[item] < b.matchplayStats[item] ? 1 : -1
          );
        }
      } else {
        if (dir === "up") {
          current.sort((a, b) =>
            a.grossStats[item] > b.grossStats[item] || a.netStats[item] === 0
              ? 1
              : -1
          );
        } else {
          current.sort((a, b) =>
            a.grossStats[item] < b.grossStats[item] ? 1 : -1
          );
        }
      }
      return current;
    });
    setLoading(false);
  }

  function sortGroupCSV(groupID) {
    // return console.log(csv);

    setDLCSV(false);
    // console.log(groupID);
    setShowGroups(false);
    setLoading(true);
    let arr = csv;
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (group.groupID === groupID) {
        const { groupType } = group;
        if (groupType === "custom") {
          const { playerIDs } = group;

          arr = csv.filter((row) => playerIDs.includes(row[0]));
          arr.unshift(csv[0]);
          // for (let j = 0; j < stats.length; j++) {
          //   if (playerIDs.includes(stats[j].playerID)) {
          //     const player = stats[j];
          //     const { grossStats, netStats } = player;

          //     if (arr.length === 0) {
          //       let headerRow = Object.keys(netStats);
          //       const grossStatsHeaderRow = Object.keys(grossStats);
          //       for (let i = 0; i < grossStatsHeaderRow.length; i++) {
          //         headerRow.push(`gross${grossStatsHeaderRow[i]}`);
          //       }
          //       headerRow.unshift("name");
          //       arr.push(headerRow);
          //     }

          //     let statArr = Object.values(netStats);
          //     const grossStatArr = Object.values(grossStats);
          //     statArr = [...statArr, ...grossStatArr];
          //     statArr.unshift(player.player.userName);
          //     arr.push(statArr);
          //   }
          // }
        }

        if (groupType === "gender") {
          const { gender } = group;
          arr = csv.filter((row) => row[2] === gender);
          arr.unshift(csv[0]);
        }

        if (groupType === "handicap") {
          const { hcpRange } = group;
          const min = hcpRange[0];
          const max = hcpRange[1];
          console.log(min, max, csv[2][3]);
          arr = csv.filter((row) => row[3] >= min && row[3] <= max);
          arr.unshift(csv[0]);
          // for (let j = 0; j < stats.length; j++) {
          //   const player = stats[j];
          //   const hcp = player.player.handicap;

          //   if (hcp > min && hcp < max) {
          //     const { grossStats, netStats } = player;

          //     if (arr.length === 0) {
          //       let headerRow = Object.keys(netStats);
          //       const grossStatsHeaderRow = Object.keys(grossStats);
          //       for (let i = 0; i < grossStatsHeaderRow.length; i++) {
          //         headerRow.push(`gross${grossStatsHeaderRow[i]}`);
          //       }
          //       headerRow.unshift("name");

          //       arr.push(headerRow);
          //     }

          //     let statArr = Object.values(netStats);
          //     const grossStatArr = Object.values(grossStats);
          //     statArr = [...statArr, ...grossStatArr];
          //     statArr.unshift(player.player.userName);

          //     arr.push(statArr);
          //   }
          // }
        }
      }
    }
    setLoading(false);

    for (let i = 0; i < arr.length; i++) {
      arr[i].shift();
    }

    setGroupCSV(arr);
    update();
    console.log(arr);
    setDLCSV(true);
    update();
    // update();
    // setDLCSV(false);
  }

  return (
    <div className="club-player-stats">
      <Dialog open={showGroups} onClose={() => setShowGroups(false)}>
        <div className="lb-pdfs">
          <PageHeader
            showClose
            close={() => setShowGroups(false)}
            text="Select Group"
          />
          <div className="ph-msg">
            <p>Select which group's stats you would like to export</p>
          </div>
          <div className="pdf-opts pd-20">
            {groups.map((group, i) => {
              return (
                <PlayerItem
                  key={group.groupID ?? "all"}
                  noLink
                  hideHCP
                  hideImage
                  hideIcons
                  name={group.name}
                  showBg={group.groupID === selGroup}
                  showProfile={() => {
                    setSelGroup((c) => {
                      if (c === group.groupID) {
                        return "";
                      } else {
                        return group.groupID;
                      }
                    });
                    update();
                  }}
                />
              );
            })}
          </div>
          <div className="flex-center mb-20 mt-20">
            <button
              onClick={() => sortGroupCSV(selGroup)}
              disabled={selGroup === ""}
              className="default-button"
            >
              Generate
            </button>
          </div>
        </div>
      </Dialog>

      {dlCSV && <CSVDownload data={groupCSV} target="_blank" />}

      {loading && <BackdropLoader />}
      <BackRow action={() => navigate(-1)} />
      <PageHeader text="Club Player Stats" />
      <div className="sub-header">
        {groups.length > 1 ? (
          <button
            onClick={() => setShowGroups(true)}
            className="default-button"
          >
            Export CSV
          </button>
        ) : (
          <button onClick={() => sortGroupCSV("")} className="default-button">
            Export CSV
          </button>
        )}
      </div>
      {false && (
        <CSVLink className="default-button" data={csv}>
          Export
        </CSVLink>
      )}
      <div className="input-group pl-20">
        <p>Select</p>
        <Dropdown
          options={droptions}
          value={selectedTitle}
          onChange={(e) => {
            setSelectedTitle(e.label);
            if (e.value === "General") {
              setSelectedOptions(generalOptions);
            }
            if (e.value === "Scoring") {
              setSelectedOptions(scoreOptions);
            }
            if (e.value === "Holes") {
              setSelectedOptions(holeOptions);
            }
            if (e.value === "Matchplay") {
              setSelectedOptions(mpOptions);
            }
          }}
        />
      </div>
      <div className="stats-container">
        <div className="stats-box">
          {loading ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : (
            <>
              <StatsHeader
                selected={selected}
                title={selectedTitle}
                dir={dir}
                selectedType={selectedType}
                handleClick={(e) => {
                  setSelectedType(e.type);
                  setSelected((current) => {
                    if (current === e.value) {
                      setDir((current) => {
                        if (current === "up") {
                          return "down";
                        } else {
                          return "up";
                        }
                      });
                    }
                    return e.value;
                  });
                  sortStats(e.value, e.type);
                }}
                options={selectedOptions}
              />
              {stats.map((player, index) => (
                <StatsItem
                  key={player.playerID}
                  player={player}
                  items={selectedOptions}
                  rank={index + 1}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClubPlayerStats;
