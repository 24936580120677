import React, { useCallback, useRef, useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import Compressor from "compressorjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { AddCircle, Close } from "@mui/icons-material";
// Components
import PageHeader from "../display/Rows/PageHeader";
import BackRow from "../display/Rows/BackRow";
import BackdropLoader from "../Loaders/BackdropLoader";
import ErrorModal from "../ErrorModal/ErrorModal";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Switch from "react-ios-switch/lib/Switch";
import CourseItem from "../display/CourseItem/CourseItem";
import { Backdrop, Dialog } from "@mui/material";
import CoursePicker from "../Events/CoursePicker/CoursePicker";
import StatsSelector from "../StatsSelector/StatsSelector";
import ImageSelector from "../Events/ImageSelector/ImageSelector";
import CustomRules from "../CustomRules/CustomRules";
import Pill from "../Buttons/Pill";
import ClubCourse from "../../pages/Clubs/ClubCourse";
import { useDropzone } from "react-dropzone";

function TeamLeagueEventEditor({
  edit = false,
  eventID = "",
  leagueID = "",
  leagueName = "",
  goBack = () => {},
  reload = () => {},
  disableChanges = false,
  hideBack = false,
}) {
  const now = new Date();
  const navigate = useNavigate();

  const nowString = new Date().toISOString().split("T")[0];

  const { portalSettings, currentUser } = useAuth();

  const [leagues, setLeagues] = useState([]);
  const [selLeague, setSelLeague] = useState();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [chosen, setChosen] = useState(edit);
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [scrambleHandicaps, setScrambleHandicaps] = useState(false);
  const [seasonMap, setSeasonMap] = useState({});
  const [completion, setCompletion] = useState("auto");
  const [trigger, setTrigger] = useState("manual");

  // Details
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [selectedCourse, setSelectedCourse] = useState({
    value: "",
    label: "",
  });
  const [addingCourses, setAddingCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const [clubID, setClubID] = useState("");
  const [clubOptions, setClubOptions] = useState([]);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [image, setImage] = useState("");
  const [imgageURL, setImageURL] = useState("");
  const [description, setDescription] = useState("");

  const [scratchMode, setScratchMode] = useState(false);
  const [adjusted, setAdjusted] = useState(false);
  const [rules, setRules] = useState([]);
  const [showRules, setShowRules] = useState(false);
  const [ring, setRing] = useState(2);
  const [handicapLimit, setHandicapLimit] = useState(36);
  const [enabledStats, setEnabledStats] = useState([]);
  const [showStats, setShowStats] = useState(false);
  const [scratchPlay, setScratchplay] = useState(false);

  const [hideMatches, setHideMatches] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
    new Compressor(acceptedFiles[0], {
      convertSize: 1000000,
      success: (res) => {
        console.log(res);
        setImage(res);
        setShowCrop(true);
      },
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  const [showCrop, setShowCrop] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});

  async function cropImage() {
    const image = new Image();
    image.src = imgageURL;
    const crop = fc;
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }

  useEffect(() => {
    if (!portalSettings) {
      return;
    }
    if (!edit) {
      loadOptions();
    } else {
      loadDetails();
    }
  }, [portalSettings]);

  async function loadOptions() {
    // Clubs
    const clubDocs = await db
      .collection("clubs")
      .where("adminIDs", "array-contains", currentUser.uid)
      .get();
    const clubArr = [];

    for (let i = 0; i < clubDocs.docs.length; i++) {
      const clubDoc = clubDocs.docs[i];
      const clubID = clubDoc.id;
      const club = clubDoc.data();
      const { clubName } = club;
      clubArr.push({
        clubName,
        clubID,
        value: clubID,
        label: clubName,
      });
    }
    setClubOptions(clubArr);
    setClubID(portalSettings.clubID);

    // Seasons
    if (leagueID === "") {
      const leagueDocs = await db
        .collection("teamLeagues")
        .where("adminIDs", "array-contains", currentUser.uid)
        .get();
      const larrr = [];
      const smap = {};
      for (let i = 0; i < leagueDocs.docs.length; i++) {
        const leagueDoc = leagueDocs.docs[i];
        const league = leagueDoc.data();
        const { leagueName } = league;
        const leagueID = leagueDoc.id;
        larrr.push({ value: leagueID, label: leagueName });

        const seasonDocs = await db
          .collection("seasons")
          .where("leagueID", "==", leagueID)
          .where("completed", "==", false)
          .orderBy("seasonIndex", "desc")
          .get();

        console.log(seasonDocs.docs.length);
        const sarr = [];

        for (let i = 0; i < seasonDocs.docs.length; i++) {
          const seasonDoc = seasonDocs.docs[i];
          const season = seasonDoc.data();
          const seasonID = seasonDoc.id;
          if (season.completed) {
            continue;
          }
          const { seasonName } = season;

          sarr.push({
            value: seasonID,
            label: seasonName,
          });
        }

        smap[leagueID] = sarr;
      }
      setSeasonMap(smap);
      setLeagues(larrr);
      // setSeasonOptions(sarr);
    } else {
      const seasonDocs = await db
        .collection("seasons")
        .where("leagueID", "==", leagueID)
        .where("completed", "==", false)
        .orderBy("seasonIndex", "desc")
        .get();

      console.log(seasonDocs.docs.length);

      const sarr = [];

      for (let i = 0; i < seasonDocs.docs.length; i++) {
        const seasonDoc = seasonDocs.docs[i];
        const season = seasonDoc.data();
        const seasonID = seasonDoc.id;
        if (season.completed) {
          continue;
        }
        const { seasonName } = season;
        sarr.push({
          value: seasonID,
          label: seasonName,
        });
      }

      setSeasonOptions(sarr);
    }
  }

  async function loadDetails() {
    await loadOptions();

    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const {
      eventName,
      eventDate,
      courseID,
      courseName,
      courses,
      rules,
      enabledStats,
      clubID,
      seasonID,
      imageLink,
      scratchMode,
    } = event;
    setScratchplay(event.scratchPlay ?? false);
    setTrigger(event.trigger ?? "manual");
    setHandicapLimit(event.handicapLimit ?? 36);
    setScrambleHandicaps(event.scrambleHandicaps ?? false);
    setCompletion(event.completion ?? "auto");
    const dateObj = new Date(eventDate.toDate());
    const defDate = moment(dateObj).toISOString(true).split(".")[0];
    console.log(defDate);
    setEventDate(defDate);
    setSelectedCourse(courses[0]);
    const dateString = dateObj.toLocaleDateString("en-us", {
      dateStyle: "full",
    });
    setHideMatches(event.hideMatches ?? false);
    setEventName(eventName);
    setCourses(courses);
    setEnabledStats(enabledStats);
    setClubID(clubID);
    setSelectedSeason(seasonID);
    setRules(rules);
    setImageURL(imageLink);
    setLoading(false);
    setScratchMode(scratchMode);
    if (!edit) {
      setLoading(false);
    }
  }

  function validate() {
    if (eventName === "") {
      setError("Please enter a name for your event");
      return false;
    }

    if (eventDate === "") {
      setError("Please select a date for this event");
      return false;
    }

    if (selectedSeason === "") {
      setError("Please select a season for this event");
      return false;
    }

    if (image === "" && !edit) {
      setError("Please select an image for this event");
      return false;
    }
    const dateVal = new Date(eventDate);
    if (dateVal < new Date()) {
      setError("Please ensure the date is in the future");
      return false;
    }
  }

  async function saveEvent() {
    // return console.log(selLeague);
    setLoading(true);

    const val = validate();

    if (val === false) {
      return setLoading(false);
    }

    try {
      const eventDoc = await db.collection("teamEvents").add({
        dateCreated: new Date(),
        handicapLimit,
        hideMatches,
        playerIDs: [],
        completion,
        rounds: [],
        sponsors: [],
        roundIDs: [],
        completed: false,
        leagueID: leagueID !== "" ? leagueID : selLeague.value,
        playerHandicaps: {},
        leagueName: leagueName !== "" ? leagueName : selLeague.label,
        seasonID: selectedSeason,
        clubID,
        courseID: selectedCourse.courseID,
        courseName: selectedCourse.name,
        courses,
        adminIDs: [currentUser.uid],
        eventName,
        eventDate: new Date(eventDate),
        scratchMode,
        ring: parseInt(ring),
        enabledStats,
        rules,
        matchups: [],
        widthdrawnIDs: [],
        description,
        imageLink: "",
        teeMap: {},
        scrambleHandicaps,
        trigger,
        scratchPlay,
      });

      if (trigger === "auto") {
        const dt = new Date(eventDate);
        dt.setHours(dt.getHours() - 5);

        await db.collection("tasks").add({
          performAt: dt,
          eventID: eventDoc.id,
          type: "teamRoundCreation",
          leagueID,
          status: "pending",
        });
      } else {
        await db.collection("tasks").add({
          performAt: "",
          eventID: eventDoc.id,
          type: "teamRoundCreation",
          leagueID,
          status: "pending",
        });
      }

      const eventID = eventDoc.id;

      if (image !== "") {
        console.log("4");

        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "eventImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${eventID}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                await db.collection("teamEvents").doc(eventDoc.id).update({
                  imageLink: url,
                });
                reload();
                navigate(`/team-event-home/${eventID}`);

                goBack();
                setLoading(false);
              });
            });
          });
        } else {
          console.log("5");

          const task = storage
            .ref()
            .child(`${eventID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              await db.collection("teamEvents").doc(eventDoc.id).update({
                imageLink: url,
              });
              reload();
              navigate(`/team-event-home/${eventID}`);

              goBack();
              setLoading(false);
            });
          });
        }
      } else {
        reload();

        goBack();
        setLoading(false);
      }
    } catch (err) {
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
      console.log(err);
      setLoading(false);
    }
  }

  async function saveChanges() {
    setLoading(true);

    const val = validate();

    if (val === false) {
      return setLoading(false);
    }

    try {
      await db
        .collection("teamEvents")
        .doc(eventID)
        .update({
          handicapLimit,
          seasonID: selectedSeason,
          clubID,
          courseID: selectedCourse.courseID,
          courseName: selectedCourse.courseName,
          courses,
          adminIDs: [currentUser.uid],
          eventName,
          eventDate: new Date(eventDate),
          scratchMode,
          ring: parseInt(ring),
          enabledStats,
          rules,
          description,
          completion,
          scrambleHandicaps,
          scratchPlay,
          hideMatches,
        });

      if (image !== "") {
        console.log("4");

        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "eventImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${eventID}/${image.name}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                await db.collection("teamEvents").doc(eventID).update({
                  imageLink: url,
                });
                reload();

                goBack();
              });
            });
          });
        } else {
          console.log("5");

          const task = storage
            .ref()
            .child(`${eventID}/${image.name}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              await db.collection("teamEvents").doc(eventID).update({
                imageLink: url,
              });
              reload();

              goBack();
            });
          });
        }
      } else {
        goBack();
      }

      if (trigger === "manual") {
        const taskDocs = await db
          .collection("tasks")
          .where("eventID", "==", eventID)
          .where("type", "==", "teamRoundCreation")
          .get();

        if (taskDocs.docs.length === 1) {
          const dt = new Date(eventDate);
          dt.setHours(dt.getHours() - 5);
          const taskDoc = taskDocs.docs[0];
          await taskDoc.ref.update({
            performAt: "",
          });
        }
      } else {
        const taskDocs = await db
          .collection("tasks")
          .where("eventID", "==", eventID)
          .where("type", "==", "teamRoundCreation")
          .get();

        if (taskDocs.docs.length === 1) {
          const dt = new Date(eventDate);
          dt.setHours(dt.getHours() - 5);
          const taskDoc = taskDocs.docs[0];
          await taskDoc.ref.update({
            performAt: dt,
          });
        }
      }
    } catch (err) {
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
      console.log(err);
      setLoading(false);
    }
  }

  return (
    <div className="tl-event-editor event-creator">
      {showCrop && (
        <Backdrop className="crop-drop" open>
          <div className="crop-co">
            <div className="cd-close">
              <Close onClick={() => setShowCrop(false)} />
            </div>
            <div className="crop-box">
              <Cropper
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                image={imgageURL}
              />
            </div>
            <div className="flex-center crop-but">
              <button
                onClick={() => {
                  cropImage();
                  setShowCrop(false);
                }}
                className="default-button"
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {show ? (
        toShow
      ) : showStats ? (
        <StatsSelector
          goBack={() => {
            setShowStats(false);
          }}
          selected={enabledStats}
          setSelected={setEnabledStats}
        />
      ) : showImages ? (
        <ImageSelector
          type="event"
          goBack={() => {
            setShowImages(false);
          }}
          selectedImage={image}
          setSelectedImage={(e) => {
            setImage(e);
            setImageURL(e);
          }}
        />
      ) : showRules ? (
        <CustomRules
          goBack={() => {
            setShowRules(false);
          }}
          defaultRules={rules}
          saveRules={setRules}
        />
      ) : !chosen ? (
        <CoursePicker
          goBack={() => {
            if (selectedCourse.value === "") {
              goBack();
            } else {
              setChosen(true);
            }
          }}
          setChosen={setChosen}
          setCourseDetails={(e) => {
            if (addingCourses) {
              setAddingCourses(false);
              setCourses((current) => {
                console.log(current);
                current.push(e);
                return current;
              });
            } else {
              setSelectedCourse(e);
              setCourses((current) => {
                let found = false;
                for (let i = 0; i < current.length; i++) {
                  if (current[i].courseID === e.courseID) {
                    found = true;
                  }
                }
                if (!found) {
                  current.push(e);
                }
                return current;
              });
            }
            setLoading(false);
          }}
        />
      ) : (
        <>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <div className="info-message">
              <div className="svg-cont">
                <InfoIcon className="big-info-icon dg-icon" />
              </div>

              <p>{dialog}</p>
            </div>
          </Dialog>
          {!hideBack && <BackRow action={goBack} />}
          <PageHeader
            text={edit ? "Team Event Editor" : "Team Event Creator"}
          />
          {loading && <BackdropLoader />}
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          <div className="sub-header">
            <h2 className="ne-header">General</h2>
          </div>

          <div className="ec-box">
            {false && (
              <div className="input-group league-hint ">
                <div className="ig-header">
                  <p>Club</p>
                </div>

                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={clubOptions}
                  value={clubID}
                  onChange={(e) => {
                    console.log(e.value);
                    setClubID(e.value);
                  }}
                />
              </div>
            )}
            {leagueID === "" && (
              <div className="input-group">
                <p>League</p>
                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={leagues}
                  placeholder={"Select..."}
                  value={selLeague}
                  onChange={(e) => {
                    const leagueID = e.value;
                    const seasons = seasonMap[leagueID] ?? [];
                    console.log(seasons);
                    setSeasonOptions(seasons);
                    setSelLeague(e);
                  }}
                />
              </div>
            )}
            <div className="input-group">
              <p>Season</p>
              <Dropdown
                arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                options={seasonOptions}
                value={selectedSeason}
                onChange={(e) => setSelectedSeason(e.value)}
              />
            </div>
            <div className="input-group">
              <div className="ig-header">
                <p>Course</p>
                {!disableChanges && (
                  <EditIcon
                    className="dg-icon-bg icon-cursor ml-20"
                    onClick={() => {
                      if (!disableChanges) {
                        setChosen(false);
                      }
                    }}
                  />
                )}
                {!disableChanges && (
                  <AddCircle
                    onClick={() => {
                      setAddingCourses(true);
                      setChosen(false);
                    }}
                    className="dg-icon-bg icon-cursor ml-20"
                  />
                )}
              </div>

              {courses.length > 0 && (
                <div className="tle-courses">
                  {courses.map((course, index) => (
                    <ClubCourse
                      course={course}
                      hideRight={courses.length === 1 || disableChanges}
                      removeAction={() => {
                        setCourses((currentt) => {
                          const filt = currentt.filter(
                            (crs) => crs.courseID !== course.courseID
                          );
                          return filt;
                        });
                      }}
                    />
                  ))}
                </div>
              )}

              {false && (
                <div>
                  <input
                    disabled
                    className="default-input mt-10"
                    value={selectedCourse.label ?? selectedCourse.name}
                  />
                </div>
              )}
            </div>
            <div className={"input-group"}>
              <div className="ig-header">
                <p>Event Name</p>
              </div>
              <input
                className="default-input"
                onChange={(e) => setEventName(e.target.value)}
                defaultValue={eventName}
                type="text"
                placeholder="Start typing..."
              />
            </div>

            <div className={"input-group"}>
              <p>Event Date</p>
              <input
                className="default-input"
                disabled={disableChanges}
                onChange={(e) => setEventDate(e.target.value)}
                value={eventDate}
                max={"9999-12-31"}
                min={nowString}
                type="datetime-local"
              />
            </div>
          </div>
          <div className="sub-header">
            <h2 className="ne-header">Details</h2>
          </div>
          <div className="ec-box">
            <div className="input-group">
              <p>Description</p>
              <textarea
                placeholder="Type something..."
                defaultValue={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={6}
              />
            </div>

            <div className="input-group mb-20">
              <p>Event Picture</p>
              <div className="image-buttons">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-sel">
                    {imgageURL !== "" && (
                      <div className="event-img">
                        <img className="" alt="profile" src={imgageURL} />
                      </div>
                    )}
                    {imgageURL === "" && (
                      <h5>
                        Drag 'n' drop an image here, or click to open up the
                        file explorer
                      </h5>
                    )}
                  </div>
                  <div className="dz-or">
                    <p className="">Or</p>
                  </div>
                </div>
                <Pill
                  text={"Choose Preset Image"}
                  onClick={() => setShowImages(true)}
                />
              </div>
            </div>
          </div>
          <div className="sub-header">
            <h2 className="ne-header">Admin</h2>
          </div>
          <div className="ec-box">
            <div className="input-group nassau-group mt-10">
              <div className="ig-header">
                <p>Hide Matches</p>
                <InfoIcon
                  className="info-icon dg-icon-bg"
                  onClick={() => {
                    setDialog(
                      "Enabling this will hide participants and matches before the event has gone live."
                    );
                    setShowDialog(true);
                  }}
                />
              </div>
              <Switch
                onColor="#1e7a69"
                // disabled={adjusted}
                checked={hideMatches}
                className={
                  hideMatches ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => {
                  setHideMatches((current) => !current);
                }}
              />
            </div>
            {!scratchMode && (
              <div className="input-group nassau-group mt-10">
                <div className="ig-header">
                  <p>Scramble Handicaps</p>
                  <InfoIcon
                    className="info-icon dg-icon-bg"
                    onClick={() => {
                      setDialog(
                        "Enabling this will set combine partner's handicaps to one (50% of combined)"
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <Switch
                  onColor="#1e7a69"
                  disabled={disableChanges}
                  checked={scrambleHandicaps}
                  className={
                    scrambleHandicaps
                      ? "scoring-switch switch-on"
                      : "scoring-switch"
                  }
                  onChange={() => {
                    setScrambleHandicaps((current) => !current);
                  }}
                />
              </div>
            )}
            {!scratchMode && (
              <div className="input-group nassau-group mt-10">
                <div className="ig-header">
                  <p>"Scratch" Matchplay</p>
                  <InfoIcon
                    className="info-icon dg-icon-bg"
                    onClick={() => {
                      setDialog(
                        "Enabling this will set the player with the lower handicap to scratch, and set the higher handicapped player to the difference between the two handicaps. E.g. if a 5 plays against a 9, the 5 will drop down to 0, and the 9 down to 4. Applies to Matchplay format only"
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <Switch
                  onColor="#1e7a69"
                  disabled={disableChanges}
                  checked={scratchPlay}
                  className={
                    scratchPlay ? "scoring-switch switch-on" : "scoring-switch"
                  }
                  onChange={() => {
                    setScratchplay((current) => !current);
                  }}
                />
              </div>
            )}

            <div className="input-group nassau-group mt-10">
              <div className="ig-header">
                <p>Scratch Mode</p>
                <InfoIcon
                  className="info-icon dg-icon-bg"
                  onClick={() => {
                    setDialog(
                      "Enabling this will set every handicap to zero, allowing everyone to play off scratch. Caution is advised..."
                    );
                    setShowDialog(true);
                  }}
                />
              </div>
              <Switch
                onColor="#1e7a69"
                disabled={adjusted || disableChanges}
                checked={scratchMode}
                className={
                  scratchMode ? "scoring-switch switch-on" : "scoring-switch"
                }
                onChange={() => {
                  setScratchMode((current) => !current);
                  setAdjusted(false);
                  setScrambleHandicaps(false);
                  setScratchplay(false);
                }}
              />
            </div>
            {!scratchMode && !disableChanges && (
              <div className="input-group stats-hint ">
                <div className="ig-header mb-10">
                  <p>Stroke Limit</p>
                  <InfoIcon
                    className="info-icon dg-icon-bg"
                    onClick={() => {
                      setDialog(
                        "By default, players will ring out at a net double bogey (net +2). Choose whether or not players have a higher stroke limit for this tournament."
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <input
                  value={ring}
                  step={1}
                  min={2}
                  max={10}
                  className="default-input"
                  onChange={(e) => {
                    const val = e.target.value;
                    setRing(val);
                  }}
                  type={"number"}
                />
              </div>
            )}
            {!scratchMode && !disableChanges && (
              <div className="input-group stats-hint">
                <div className="ig-header">
                  <p>Handicap Limit</p>
                  <InfoIcon
                    className="info-icon dg-icon-bg"
                    onClick={() => {
                      setDialog(
                        "Set the highest handicap index allowed for this event. Players with a handicap higher than the limit will play off the handicap limit for this event."
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <input
                  value={handicapLimit}
                  step={1}
                  min={2}
                  max={10}
                  className="default-input"
                  onChange={(e) => {
                    const val = e.target.value;
                    setHandicapLimit(val);
                  }}
                  type={"number"}
                />
              </div>
            )}
            {!disableChanges && (
              <div className="input-group  mt-10">
                <div className="ig-header">
                  <p>Round Completion</p>
                  <InfoIcon
                    className="info-icon dg-icon-bg"
                    onClick={() => {
                      setDialog(
                        "If set to auto, rounds will close as soon as the final match is completed, and either switch to the next round or close the event. If set to manual, this has to be triggered manually from the Live Event Dashboard"
                      );
                      setShowDialog(true);
                    }}
                  />
                </div>
                <div className="selector-group">
                  <button
                    onClick={() => setCompletion("auto")}
                    className={
                      completion === "auto" ? "sg-active sg-right" : "sg-right"
                    }
                  >
                    Auto
                  </button>
                  <button
                    onClick={() => setCompletion("manual")}
                    className={
                      completion === "manual"
                        ? "sg-middle sg-active"
                        : "sg-middle"
                    }
                  >
                    Manual
                  </button>
                </div>
              </div>
            )}
            {!disableChanges && (
              <div className="input-group  mt-10">
                <div className="ig-header">
                  <p>Round Creation</p>
                  {/* <InfoIcon
                  className="info-icon dg-icon-bg"
                  onClick={() => {
                    setDialog(
                      "If set to auto, rounds will close as soon as the final match is completed, and either switch to the next round or close the event. If set to manual, this has to be triggered manually from the Live Event Dashboard"
                    );
                    setShowDialog(true);
                  }}
                /> */}
                </div>
                <div className="selector-group">
                  <button
                    onClick={() => setTrigger("auto")}
                    className={
                      trigger === "auto" ? "sg-active sg-right" : "sg-right"
                    }
                  >
                    Auto
                  </button>
                  <button
                    onClick={() => setTrigger("manual")}
                    className={
                      trigger === "manual" ? "sg-middle sg-active" : "sg-middle"
                    }
                  >
                    Manual
                  </button>
                </div>
              </div>
            )}
            {!disableChanges && (
              <div className="input-group stats-hint mt-10">
                <div className="ig-header">
                  <p>Enabled Stats ({enabledStats.length})</p>
                  <AddCircle
                    onClick={() => setShowStats(true)}
                    className="info-icon dg-icon-bg"
                  />
                </div>
                <div className="ec-stats">
                  <ul>
                    {enabledStats.map((stat, index) => (
                      <li>{stat}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="input-group rules-hint mt-10">
              <div className="ig-header">
                <p>Custom Rules ({rules.length})</p>
                <AddCircle
                  onClick={() => setShowRules(true)}
                  className="info-icon dg-icon-bg"
                />
              </div>
              <div className="ec-stats">
                <ul>
                  {rules.map((rule, index) => (
                    <li>{rule.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {!edit && (
            <div className="flex-center mb-40 mt-40">
              <button onClick={saveEvent} className="default-button">
                Save Event
              </button>
            </div>
          )}

          {edit && (
            <div className="flex-align-center mb-40 mt-40 tlee-buts">
              <button
                onClick={saveChanges}
                className="default-button mr-5v ml-20"
              >
                Save Changes
              </button>
              <button className="default-button delete-button">
                Delete Event
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TeamLeagueEventEditor;
