/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./PlayerScore.css";
// MUI
import Badge from "@mui/material/Badge";
import { Avatar } from "@mui/material";
import DeblurIcon from "@mui/icons-material/Deblur";

import { db } from "../../../firebase";
import { CheckCircle } from "@mui/icons-material";

const GradientAddIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <DeblurIcon className="dg-icon" sx={{ fill: "url(#linearColors)" }} />
  </>
);

function PlayerScore({
  name = "",
  dq = false,
  wd = false,
  points = false,
  skins = false,
  hideHcp = false,
  animate = false,
  animateSlow = false,
  hcp,
  img,
  score = "",
  thru,
  rank,
  eventID,
  playerID,
  roundID,
  showRound,
  isHeader = false,
  format = "",
  notFirst = false,
  today = "",
  playoff = false,
  showBg = false,
  hideImage = false,
  hideScore = false,
  hideThru = false,
  pointsScored = 0,
  showBadge = false,
  bogey = false,
  submitted = false,
}) {
  //   console.log(today);
  // eslint-disable-next-line no-unused-vars
  const [round, setRound] = useState({});

  useEffect(() => {
    // console.log(showBadge);
    // console.log(format);
    if (name !== "" && !name.includes("Player") && format !== "average-ball") {
      loadScoreCard();
    }
  }, []);

  async function loadScoreCard() {
    if (playoff || roundID === undefined) {
      return;
    }
    // console.log(roundID);
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { courseID, date, eventID, leagueID, tees } = round;
    const dateString = new Date(date.toDate()).toLocaleDateString("en-gb", {
      dateStyle: "full",
    });

    // Course
    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const { name, imageLink, firstNine, lastNine, par } = course;

    // Score
    const scoreDoc = await db
      .collection("rounds")
      .doc(roundID)
      .collection("scores")
      .doc(playerID)
      .get();
    if (!scoreDoc.exists) {
      return;
    }
    const scoreData = scoreDoc.data();
    const {
      totalGrossStrokes,
      handicap,
      frontNineNet,
      frontNineGross,
      backNineNet,
      backNineGross,
      totalNetStrokes,
      frontNinePutts,
      backNinePutts,
      backNineGIR,
      frontNineGIR,
      backNineFIR,
      frontNineFIR,
      totalPutts,
    } = scoreData;
    // League
    let leagueName = "";
    let eventName = "";

    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    if (leagueDoc.exists) {
      const league = leagueDoc.data();
      leagueName = league.leagueName;
    }

    // Event
    const eventDoc = await db.collection("events").doc(eventID).get();
    if (eventDoc.exists) {
      const event = eventDoc.data();
      eventName = event.eventName;
    }
    setRound({
      roundID,
      date: dateString,
      courseName: name,
      score: totalGrossStrokes,
      handicap,
      imageLink,
      scoreCard: scoreData.holes,
      leagueName,
      eventName,
      tees,
      frontNinePar: firstNine[tees],
      backNinePar: lastNine[tees],
      coursePar: par[tees],
      frontNineNet,
      frontNineGross,
      backNineNet,
      backNineGross,
      totalNetStrokes,
      totalGrossStrokes,
      frontNinePutts,
      backNinePutts,
      frontNineGIR,
      backNineGIR,
      frontNineFIR,
      backNineFIR,
      totalPutts,
    });
  }

  return (
    <div
      style={{
        width: playoff ? "auto" : "90%",
        backgroundColor: showBg ? "#E9F8F2" : "transparent",
      }}
      onClick={(e) => {
        if (e.target.className !== "score-modal" && !isHeader) {
          showRound();
        }
      }}
      className={
        animate
          ? "player-score scale-in-ps"
          : animateSlow
          ? "player-score slide-in-top-long"
          : "player-score scale-in-ps"
      }
    >
      <div
        style={{ flex: hideImage ? "0.1" : "0.2" }}
        className={showBadge ? "ps-left ps-bg-badge" : "ps-left"}
      >
        <Badge max={200} color="default" badgeContent={rank}>
          {!hideImage && <Avatar alt={name} src={img} />}
        </Badge>
      </div>
      <div
        style={{
          flex: playoff ? "0.7" : hideImage ? "0.9" : "0.8",
          justifyContent: playoff ? "flex-start" : "space-between",
        }}
        className="ps-right"
      >
        <div className="ps-right-left">
          <h3>{name}</h3>
          {!hideHcp && (
            <div className="hcp-container">
              {/* <div className="svg-cont">
                <GradientAddIcon
                  className="ps-icon"
                  style={{ color: "#21C17C", height: "16px" }}
                />
              </div> */}

              <p>{hcp >= 0 ? hcp : `+${hcp * -1}`} hcp</p>
            </div>
          )}
        </div>
        {!hideScore && (
          <>
            <div className="ps-right-middle">
              <h3>
                {dq ? (
                  "DQ"
                ) : wd ? (
                  "WD"
                ) : (
                  <>
                    {!bogey && (
                      <>
                        {score === 0 && points
                          ? score
                          : score === 0 && !skins && !format.includes("stable")
                          ? "E"
                          : score === 0 && skins
                          ? 0
                          : score > 0 && !format.includes("stable")
                          ? `+${score}`
                          : score}
                      </>
                    )}
                    {bogey && (
                      <>
                        {score > 0
                          ? `${score} UP`
                          : score < 0
                          ? `${Math.abs(score)} DN`
                          : "AS"}
                      </>
                    )}
                  </>
                )}
              </h3>
              {points ? <p>Points</p> : skins ? <p>Skins</p> : <p>Score</p>}
            </div>
          </>
        )}

        {!playoff && !hideThru && !hideScore && pointsScored === 0 && (
          <div className="ps-right-right">
            <h3>{thru}</h3>
            <p>Thru</p>
          </div>
        )}
        {pointsScored !== 0 && (
          <div className="ps-right-right">
            <h3>{pointsScored}</h3>
            <p>Points</p>
          </div>
        )}
        {notFirst && !hideScore && (
          <div className="ps-right-right">
            <h3>{today === 0 ? "E" : today > 0 ? `+${today}` : today}</h3>
            <p>Today</p>
          </div>
        )}
        {submitted && !dq && !wd && (
          <div className="ps-sub">
            <CheckCircle className="dg-icon" />
          </div>
        )}
        {(!submitted || dq || wd) && (
          <div className="ps-sub">
            <CheckCircle className="dg-icon iv-ico" />
          </div>
        )}
      </div>
    </div>
  );
}

export default PlayerScore;
