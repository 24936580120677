import React from "react";
import "./LeagueEdit.css";

// Icons
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
// Components
import { Avatar } from "@mui/material";

const GradientIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AccessTimeIcon sx={{ fill: "url(#linearColors)" }} />
  </>
);

function LeagueMemberJoined({ player, handleClick = () => {} }) {
  return (
    <div className="notification-item lm-joined">
      <div className="ni-left flex-center">
        <Avatar src={player.profileImage} alt={player.name} />
      </div>
      <div className="ni-right">
        <div className="nir-top">
          <GradientIcon />
          <h6>{player.timeSince}</h6>
        </div>
        <h5>{player.name}</h5>
      </div>
    </div>
  );
}

export default LeagueMemberJoined;
