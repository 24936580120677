import React, { useState, useEffect, createRef } from "react";
import "./EventDashboard.css";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import BackdropLoader from "../../Loaders/BackdropLoader";
import { useParams, useNavigate } from "react-router-dom";

// Icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import Close from "@mui/icons-material/Close";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Components
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useCookies } from "react-cookie";
import Pill from "../../Buttons/Pill";
import EventDashTeeTimes from "./EventDashTeeTimes";
import EventRemovePlayer from "./EventRemovePlayer";
import EventAddPlayer from "./EventAddPlayer";
import ScoreInput from "../ScoreInput/ScoreInput";
import EventHandicapEdit from "./EventHandicapEdit";
import EventDashGroupSelector from "../GroupSelector/EventDashGroupSelector";
import GroupSelector from "../GroupSelector/GroupSelector";
import { Dialog, Radio } from "@mui/material";
import { Oval } from "react-loader-spinner";
import BroadcastModal from "../../BroadcastModal/BroadcastModal";
import EventTeamManager from "../EventCreator/EventTeamManager";
import BackRow from "../../display/Rows/BackRow";
import PageHeader from "../../display/Rows/PageHeader";
import TeamEventMatches from "../../ClubLeagues/TeamEventMatches";
import TEDResultInput from "./TEDResultInput";
import TeamLeagueRound from "../../ClubLeagues/TeamLeagueRound";
import TeamLiveGroups from "./TeamLiveGroups";
import MatchplayHoleResults from "./MatchplayHoleResults";
import TeamLeagueEventPlayers from "../../ClubLeagues/TeamLeagueEventPlayers";
import MatchupResult from "./MatchupResult";
import MenuSwitcher from "../../MenuSwitcher/MenuSwitcher";
import { KeyboardArrowDown } from "@mui/icons-material";

const GradientInfoIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <InfoIcon
      onClick={onClick}
      className={`dg-icon ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientCircleIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <CheckCircleIcon
      onClick={onClick}
      className="big-tick dg-icon"
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function TeamEventDashboard() {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const eventID = useParams().id;
  const [cookies, setCookie, removeCookie] = useCookies(["menu-type"]);

  const [loading, setLoading] = useState(true);
  const [accountType, setAccountType] = useState("");

  const [details, setDetails] = useState({
    eventName: "",
  });
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [showAdd, setShowAdd] = useState(false);

  const [showBC, setShowBC] = useState(false);
  const [chatID, setChatID] = useState("");
  const [leadOpen, setLeadOpen] = useState(false);
  const [timesOpen, setTimesOpen] = useState(false);

  const [matches, setMatches] = useState([]);

  const [round, setRound] = useState({});
  const [disable, setDisable] = useState(false);

  const [players, setPlayers] = useState([]);
  const [scorerID, setScorerID] = useState("");

  const [manComp, setManComp] = useState(false);

  const [bbs, setBBS] = useState(false);

  const [showMC, setShowMC] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const { userDetails } = useAuth();
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  useEffect(() => {
    update();
    setShow(false);
    setLoading(true);
    loadDetails();
    update();
  }, [eventID]);

  useEffect(() => {
    if (userDetails) {
      // console.log(userDetails.teamID);
      setScorerID(userDetails.teamID);
      setAccountType(userDetails.accountType);
    }
  }, [userDetails]);

  async function submitCards() {
    setSubmitting(true);
    try {
      await db.collection("triggers").add({
        roundID: details.activeRoundID,
        type: "team-rd-comp",
      });
      setManComp(false);
      setSubmitted(true);
      setDisable(true);
    } catch (err) {
      setSubmitError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }

    setSubmitting(false);
  }

  async function loadDetails() {
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const {
      eventName,
      playerIDs,
      roundIDs,
      playerHandicaps,
      activeRoundID,
      leagueID,
      completed,
    } = event;

    console.log(activeRoundID);

    const tlDoc = await db.collection("teamLeagues").doc(leagueID).get();
    const tlData = tlDoc.data();
    const { teams } = tlData;

    const tidMap = {};

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const { players, teamID } = team;
      for (let x = 0; x < players.length; x++) {
        const player = players[x];
        const { playerID } = player;
        tidMap[playerID] = teamID;
      }
    }

    const roundDoc = await db.collection("rounds").doc(activeRoundID).get();
    const round = roundDoc.data();
    const { matches, format, date } = round;
    setBBS(round.betterballStableford ?? false);
    if (round.completion === "manual") {
      completionListener({ roundID: activeRoundID });
    }
    setRound({
      matches,
      format,
      date,
    });
    setMatches(matches);

    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);
    const arr = [];
    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName, profileImage } = player;
      const handicap = playerHandicaps[playerID] ?? player.handicap;
      const name = `${firstName} ${lastName}`;
      arr.push({
        playerID,
        name,
        handicap,
        profileImage,
        originalHandicap: player.handicap,
        teamID: tidMap[playerID],
      });
    }

    setPlayers(arr);

    setDetails({
      eventName,
      playerIDs,
      activeRoundID,
      roundIDs,
      format,
      completed,
      roundIndex: round.roundIndex,
    });

    setLoading(false);
  }

  function completionListener({ roundID }) {
    db.collection("rounds")
      .doc(roundID)
      .onSnapshot((snap) => {
        const doc = snap.data();
        const { betterballStableford } = doc;
        const matches = doc.matches ?? [];
        if (doc.calculating || doc.completed) {
          setDisable(true);
        } else {
          setDisable(false);
        }

        let incomp = false;
        console.log(matches);
        if (betterballStableford) {
          for (let i = 0; i < matches.length; i++) {
            if (incomp) {
              break;
            }

            if (matches[i].thru !== 18) {
              incomp = true;
            }
          }
        } else {
          // console.log(doc);
          for (let i = 0; i < matches.length; i++) {
            if (incomp) {
              break;
            }
            if (matches[i].completed !== true && matches[i].complete !== true) {
              incomp = true;
            }
          }
        }

        if (!incomp) {
          setManComp(true);
        } else {
          setManComp(false);
        }
      });
  }

  return (
    <div className="event-dash te-dash">
      {loading && <BackdropLoader />}

      {/* {showAdd && (
        <EventAddPlayer
          teamEvent
          hide={() => setShowAdd(false)}
          eventID={eventID}
        />
      )} */}

      {showBC && (
        <BroadcastModal
          name={details.eventName}
          close={() => setShowBC(false)}
          eventID={eventID}
        />
      )}
      <Dialog open={showMC} onClose={() => setShowMC(false)}>
        <div className="submit-dia">
          <PageHeader
            close={() => setShowMC(false)}
            showClose
            text="Complete Round"
          />
          {submitting ? (
            <>
              <div className="flex-center loading">
                <Oval
                  color="#1e7a69"
                  secondaryColor="#ffffff"
                  height={40}
                  width={40}
                />
              </div>
            </>
          ) : submitted ? (
            <>
              <div className="mpa-fin flex-center">
                <GradientCircleIcon />
              </div>
              <p>
                Scorecards have been submitted. Stats and highlights will appear
                shortly.
              </p>
            </>
          ) : (
            <>
              <GradientInfoIcon className={"big-icon"} onClick={() => {}} />
              <p>
                This will close this round, and this event should this be the
                final round.
              </p>
              {submitError !== "" && (
                <div className="error-message-row">
                  <p>{submitError}</p>
                </div>
              )}
              <div className="default-button-row">
                <button onClick={submitCards} className="default-button">
                  Confirm
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>

      {show ? (
        toShow
      ) : (
        <>
          <BackRow action={goBack} />
          <div className="ph-me-opt">
            <PageHeader text={`Event Dashboard - ${details.eventName}`} />
            <MenuSwitcher />
          </div>

          {cookies["menu-type"] === "drop" && (
            <>
              <div className="flex flex-align-center ddmb-box mb-20 mt-20">
                <div className="ddm-box">
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                      <button className="ddm-but">
                        <span>General</span>
                        <KeyboardArrowDown className="ddm-ico" />
                      </button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Portal>
                      <DropdownMenu.Content
                        className="DropdownMenuContent"
                        sideOffset={5}
                      >
                        <DropdownMenu.Item
                          onClick={() => {
                            navigate(`/team-event-home/${eventID}`);
                          }}
                          className="DropdownMenuItem"
                        >
                          Event Home
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => {
                            window.open(
                              `https://www.theugatour.com/events/${eventID}`,
                              "_blank"
                            );
                          }}
                          className="DropdownMenuItem"
                        >
                          Public Link
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => {
                            window.open(
                              `https://www.theugatour.com/leaderboards/${eventID}`,
                              "_blank"
                            );
                          }}
                          className="DropdownMenuItem"
                        >
                          TV Leaderboard
                        </DropdownMenu.Item>

                        {details.seasonIDs && (
                          <DropdownMenu.Item
                            onClick={() => {}}
                            className="DropdownMenuItem"
                          >
                            New Event
                          </DropdownMenu.Item>
                        )}
                      </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                </div>
                {!details.completed && (
                  <div className="ddm-box">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Players</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          <DropdownMenu.Item
                            onClick={() => {
                              setToShow(
                                <TeamLeagueEventPlayers
                                  eventID={eventID}
                                  goBack={() => setShow(false)}
                                  live
                                />
                              );
                              setShow(true);
                            }}
                            className="DropdownMenuItem"
                          >
                            Add Player
                          </DropdownMenu.Item>

                          <DropdownMenu.Item
                            onClick={() => {
                              setToShow(
                                <TeamLeagueRound
                                  roundIndex={details.roundIndex}
                                  goBack={() => setShow(false)}
                                  eventID={eventID}
                                  edit
                                  round={round}
                                  live
                                />
                              );
                              setShow(true);
                            }}
                            className="DropdownMenuItem"
                          >
                            Edit Matches
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                )}
                {!details.completed && (
                  <div className="ddm-box ">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Scoring</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          <DropdownMenu.Item
                            onClick={() => {
                              setToShow(
                                <TeamLiveGroups
                                  scorerID={scorerID}
                                  roundIDs={details.roundIDs}
                                  eventID={eventID}
                                  eventName={details.eventName}
                                  goBack={() => setShow(false)}
                                  roundID={details.activeRoundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="DropdownMenuItem"
                          >
                            Group View
                          </DropdownMenu.Item>

                          {!bbs && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <MatchplayHoleResults
                                    scorerID={scorerID}
                                    format={details.format}
                                    goBack={() => setShow(false)}
                                    matches={matches}
                                    roundID={details.activeRoundID}
                                  />
                                );
                                setShow(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Hole Input
                            </DropdownMenu.Item>
                          )}
                          {!bbs && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <ScoreInput
                                    scorerID={scorerID}
                                    matchplay
                                    teamEvt
                                    eventName={details.eventName}
                                    players={players}
                                    roundIDs={details.roundIDs}
                                    eventID={eventID}
                                    roundID={details.activeRoundID}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Score Input
                            </DropdownMenu.Item>
                          )}
                          {!bbs && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <TEDResultInput
                                    scorerID={scorerID}
                                    format={details.format}
                                    goBack={() => setShow(false)}
                                    matches={matches}
                                    roundID={details.activeRoundID}
                                  />
                                );
                                setShow(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Result Input
                            </DropdownMenu.Item>
                          )}
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                )}
                {accountType === "club" && (
                  <div className="ddm-box">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ddm-but">
                          <span>Admin</span>
                          <KeyboardArrowDown className="ddm-ico" />
                        </button>
                      </DropdownMenu.Trigger>

                      <DropdownMenu.Portal>
                        <DropdownMenu.Content
                          className="DropdownMenuContent"
                          sideOffset={5}
                        >
                          {chatID === "" && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setShowBC(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Group Message
                            </DropdownMenu.Item>
                          )}

                          {manComp && !details.completed && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setShowMC(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Complete Round
                            </DropdownMenu.Item>
                          )}
                          {!details.completed && (
                            <DropdownMenu.Item
                              onClick={() => {
                                setToShow(
                                  <MatchupResult
                                    betterballStableford={bbs}
                                    format={details.format}
                                    eventID={eventID}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              }}
                              className="DropdownMenuItem"
                            >
                              Matchup Result
                            </DropdownMenu.Item>
                          )}
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                )}
              </div>
            </>
          )}

          {cookies["menu-type"] !== "drop" && (
            <div className="ch-links">
              <div className="ch-link">
                <div className="sub-header">
                  <h5>General</h5>
                </div>
                <div className="quick-links">
                  <Pill
                    text={"Event Home"}
                    onClick={() => {
                      navigate(`/team-event-home/${eventID}`);
                    }}
                  />
                  <Pill
                    text={"Public Link"}
                    onClick={() => {
                      window.open(
                        `https://www.theugatour.com/events/${eventID}`,
                        "_blank"
                      );
                    }}
                  />
                  <Pill
                    text={"TV Leaderboard"}
                    onClick={() => {
                      window.open(
                        `https://www.theugatour.com/leaderboards/${eventID}`,
                        "_blank"
                      );
                    }}
                  />
                </div>
              </div>
              {/* {userDetails && (userDetails.test || userDetails.admin) && ( */}
              {!details.completed && (
                <div className="ch-link">
                  <div className="sub-header">
                    <h5>Players</h5>
                  </div>
                  <div className="quick-links">
                    <Pill
                      onClick={() => {
                        setToShow(
                          <TeamLeagueEventPlayers
                            eventID={eventID}
                            goBack={() => setShow(false)}
                            live
                          />
                        );
                        setShow(true);
                      }}
                      text={"Add Player"}
                    />
                    <Pill
                      text={"Edit Matches"}
                      onClick={() => {
                        setToShow(
                          <TeamLeagueRound
                            roundIndex={details.roundIndex}
                            goBack={() => setShow(false)}
                            eventID={eventID}
                            edit
                            round={round}
                            live
                          />
                        );
                        setShow(true);
                      }}
                    />
                  </div>
                </div>
              )}
              {/* )} */}
              {!details.completed && (
                <>
                  <div className="ch-link">
                    <div className="sub-header">
                      <h5>Scoring</h5>
                    </div>
                    <div className="quick-links">
                      <Pill
                        text={"Group View"}
                        onClick={() => {
                          setToShow(
                            <TeamLiveGroups
                              scorerID={scorerID}
                              roundIDs={details.roundIDs}
                              eventID={eventID}
                              eventName={details.eventName}
                              goBack={() => setShow(false)}
                              roundID={details.activeRoundID}
                            />
                          );
                          setShow(true);
                        }}
                      />
                      {!bbs && (
                        <Pill
                          text={"Hole Input"}
                          onClick={() => {
                            setToShow(
                              <MatchplayHoleResults
                                scorerID={scorerID}
                                format={details.format}
                                goBack={() => setShow(false)}
                                matches={matches}
                                roundID={details.activeRoundID}
                              />
                            );
                            setShow(true);
                          }}
                        />
                      )}
                      {!bbs && (
                        <Pill
                          text={"Score Input"}
                          onClick={() => {
                            setToShow(
                              <ScoreInput
                                scorerID={scorerID}
                                matchplay
                                teamEvt
                                eventName={details.eventName}
                                players={players}
                                roundIDs={details.roundIDs}
                                eventID={eventID}
                                roundID={details.activeRoundID}
                                goBack={() => setShow(false)}
                              />
                            );
                            setShow(true);
                          }}
                        />
                      )}
                      {/* <Pill
                  text={"Score Update"}
                  onClick={() => {
                    setToShow(
                      <TEDResultInput
                        format={details.format}
                        goBack={() => setShow(false)}
                        matches={matches}
                        roundID={details.activeRoundID}
                      />
                    );
                    setShow(true);
                  }}
                /> */}
                      {!bbs && (
                        <Pill
                          text={"Result Input"}
                          onClick={() => {
                            setToShow(
                              <TEDResultInput
                                scorerID={scorerID}
                                format={details.format}
                                goBack={() => setShow(false)}
                                matches={matches}
                                roundID={details.activeRoundID}
                              />
                            );
                            setShow(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              {accountType === "club" && (
                <div className="ch-link">
                  <div className="sub-header">
                    <h5>Admin</h5>
                  </div>
                  <div className="quick-links">
                    <Pill
                      text={"Group Message"}
                      onClick={() => {
                        if (chatID === "") {
                          setShowBC(true);
                        }
                      }}
                    />
                    {/* <Pill
                    text={"Edit Matches"}
                    onClick={() => {
                      setToShow(
                        <TeamLeagueRound
                          edit
                          eventID={eventID}
                          goBack={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                  /> */}
                    {manComp && !details.completed && (
                      <Pill
                        text={"Complete Round"}
                        onClick={() => {
                          setShowMC(true);
                        }}
                      />
                    )}
                    {!details.completed && (
                      <Pill
                        text={"Matchup Result"}
                        onClick={() => {
                          setToShow(
                            <MatchupResult
                              betterballStableford={bbs}
                              format={details.format}
                              eventID={eventID}
                              goBack={() => setShow(false)}
                            />
                          );
                          setShow(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="edb-box">
            <div className="ed-boxes">
              {!timesOpen && (
                <div className={leadOpen ? "ed-box ed-box-open" : "ed-box"}>
                  <div className="sub-header mb-20">
                    <h5>Matches</h5>
                    <OpenInFullIcon
                      onClick={() => setLeadOpen((current) => !current)}
                      className="dg-icon icon-cursor"
                    />
                  </div>
                  <div className="ed-lb">
                    <TeamEventMatches eventID={eventID} />
                  </div>
                </div>
              )}
              {!leadOpen && (
                <div className={timesOpen ? "ed-box ed-box-open" : "ed-box"}>
                  <div className="sub-header mb-20">
                    <h5>Tee Times</h5>
                    <div className="ed-box-icons">
                      {timesOpen && (
                        <EditIcon
                          onClick={() => {
                            setToShow(
                              <GroupSelector
                                reload={loadDetails}
                                roundID={details.activeRoundID}
                                defaultGroups={details.teeTimes}
                                goBack={() => setShow(false)}
                                live
                                courseDetails={{
                                  teeArray: [""],
                                }}
                                // players={players}
                              />
                            );
                            setShow(true);
                          }}
                          className={"mr-10"}
                        />
                      )}
                      <OpenInFullIcon
                        className="dg-icon icon-cursor"
                        onClick={() => setTimesOpen((current) => !current)}
                      />
                    </div>
                  </div>
                  {/* <div className="ed-lb"> */}
                  {!loading && (
                    <EventDashTeeTimes
                      eventID={eventID}
                      roundID={details.activeRoundID}
                    />
                  )}
                  {/* </div> */}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TeamEventDashboard;
