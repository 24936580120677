import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons

// Components
import { Dialog } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { Oval } from "react-loader-spinner";
import PageHeader from "../../display/Rows/PageHeader";

function ScoreSubmission({
  roundID = "",
  playerID = "",
  hcp = 0,
  courseID = "",
  close = () => {},
}) {
  // State
  const [grossInput, setGrossInput] = useState(0);
  const [netInput, setnetInput] = useState(0);
  const [stableInput, setStableInput] = useState(0);
  const [loading, setLoading] = useState(true);
  const [coursePar, setCoursePar] = useState(72);

  useEffect(() => {
    loadCourse();
  }, []);

  async function loadCourse() {
    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const { par, teeArray } = course;
    setCoursePar(par[teeArray[0]]);
    setLoading(false);
  }

  async function saveScore() {
    setLoading(true);
    await db.collection("triggers").add({
      type: "manual-score-sub",
      dateAdded: new Date(),
      playerID,
      handicap: hcp,
      coursePar,
      roundID,
      grossScore: grossInput,
      netScore: netInput,
      stablefordPoints: stableInput,
    });
    // const roundDoc = await db.collection("rounds").doc(roundID).get();

    // const round = roundDoc.data();
    // const { format } = round;

    // const { leaderboard } = round;

    // for (let i = 0; i < leaderboard.length; i++) {
    //   if (leaderboard[i].playerID === playerID) {
    //     leaderboard[i].grossScore = grossInput - coursePar;
    //     leaderboard[i].netScore = netInput - coursePar;
    //     leaderboard[i].manualSubmission = true;
    //     leaderboard[i].totalGrossStrokes = grossInput;
    //     leaderboard[i].totalNetStrokes = netInput;
    //     leaderboard[i].points = stableInput;
    //     if (format === "net-strokeplay") {
    //       leaderboard[i].score = grossInput;
    //     } else if (format.includes("stable")) {
    //       leaderboard[i].score = stableInput;
    //     } else {
    //       leaderboard[i].score = netInput;
    //     }
    //   }
    // }

    // if (format === "stableford") {
    //   leaderboard.sort((a, b) => b.score - a.score);
    // } else {
    //   leaderboard.sort((a, b) => a.score - b.score);
    // }
    // let currentTies = 0;
    // for (let i = 0; i < leaderboard.length; i++) {
    //   let ranking = i + 1;

    //   const row = leaderboard[i];
    //   if (i > 0) {
    //     if (leaderboard[i - 1].score === row.score) {
    //       ranking = i - currentTies;
    //       currentTies += 1;
    //       // console.log(i, ranking);
    //     } else {
    //       currentTies = 0;
    //     }
    //   }

    //   leaderboard[i].rank = ranking;
    // }

    // await roundDoc.ref.update({
    //   leaderboard,
    // });

    close();
    setLoading(false);
  }

  return (
    <Dialog open onClose={close}>
      <div className="sub-score-dia pd-20">
        <PageHeader showClose close={close} text="Manual Score Input" />
        <div className="ph-msg">
          <p className="">Enter Overall Score</p>
        </div>

        {loading ? (
          <>
            {" "}
            <div className="flex-center loading">
              <Oval
                color="#1e7a69"
                secondaryColor="#ffffff"
                height={40}
                width={40}
              />
            </div>
          </>
        ) : (
          <>
            <div className="input-group pl-20">
              <p>Handicap: {hcp}</p>
            </div>
            <div className="ec-box">
              <div className="input-group">
                <p>Gross Score</p>
                <input
                  className="default-input"
                  value={grossInput}
                  onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value === "") {
                      setGrossInput("");
                      return;
                    }

                    const score = e.target.value;
                    console.log(score);
                    const net = score - hcp;
                    const netToPar = net - coursePar;
                    const stable = 36 - netToPar;
                    setGrossInput(score);
                    setnetInput(net);
                    setStableInput(stable);
                  }}
                />
              </div>
              <div className="input-group">
                <p>Net Score</p>
                <input
                  className="default-input"
                  value={netInput}
                  onChange={(e) => {
                    const score = e.target.value;
                    setnetInput(score);
                  }}
                />
              </div>
              <div className="input-group">
                <p>Stableford Points</p>
                <input
                  className="default-input"
                  value={stableInput}
                  onChange={(e) => {
                    const score = e.target.value;
                    setStableInput(score);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-center">
              <button
                onClick={saveScore}
                disabled={grossInput === 0 || grossInput === "" || netInput < 0}
                className="default-button"
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default ScoreSubmission;
