import React, { useState, useEffect } from "react";
import "./Contests.css";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import BackdropLoader from "../../components/Loaders/BackdropLoader";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
// Components
import NewContest from "./NewContest";
import ContestItem from "./ContestItem";
import ContestPage from "./ContestPage";
import PageHeader from "../../components/display/Rows/PageHeader";

function Contests({ goBack = () => {} }) {
  const { currentUser } = useAuth();
  const [contests, setContests] = useState([]);
  const [oldContests, setOldContests] = useState([]);
  const [contestID, setContestID] = useState("");
  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showCode, setShowCode] = useState(false);

  const [codeInput, setCodeInput] = useState("");

  const [error, setError] = useState("");

  const [active, setActive] = useState("current");

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  useEffect(() => {
    if (currentUser) {
      loadContests();
    }
  }, [currentUser]);

  async function loadContests() {
    const contestDocs = await db
      .collection("contests")
      .where("playerIDs", "array-contains", currentUser.uid)
      .get();

    const carr = [];
    const parr = [];

    for (let i = 0; i < contestDocs.docs.length; i++) {
      const contestDoc = contestDocs.docs[i];
      const contestID = contestDoc.id;
      const contest = contestDoc.data();
      const dateObject = new Date(contest.dateCreated.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      contest.contestID = contestID;
      contest.dateObject = dateObject;
      contest.dateString = dateString;

      if (contest.completed) {
        parr.push(contest);
      } else {
        carr.push(contest);
      }
    }
    setContests(carr);
    setOldContests(parr);
    setLoading(false);
  }

  async function joinComp() {
    setLoading(true);
    const compDoc = await db
      .collection("contests")
      .where("joinCode", "==", codeInput)
      .get();

    if (compDoc.docs.length > 0) {
      const comp = compDoc.docs[0].data();
      const leaderboard = comp.leaderboard;
      leaderboard[currentUser.uid] = {
        attempts: [],
        best: "",
      };
      await compDoc.docs[0].ref.update({
        playerIDs: admin.firestore.FieldValue.arrayUnion(currentUser.uid),
        leaderboard,
      });
      loadContests();

      setShowCode(false);
    }
    setError("Sorry - we couldn't find that contest");
    setLoading(false);
  }

  return (
    <div className="contest-page">
      {loading && <BackdropLoader />}

      {show ? (
        toShow
      ) : showNew ? (
        <NewContest reload={loadContests} goBack={() => setShowNew(false)} />
      ) : showCode ? (
        <>
          <div className="ts-header">
            <ArrowBack
              onClick={() => setShowCode(false)}
              className="ts-arrow"
            />
            <h2>Enter Code</h2>
          </div>
          <div className="cp-code">
            <p>Enter your code below to join the contest</p>
            <input
              placeholder="Enter code"
              value={codeInput}
              onChange={(e) => setCodeInput(e.target.value)}
              type="text"
              className="default-input"
            />
            {error !== "" && (
              <div className="error-message-row">
                <p>{error}</p>
              </div>
            )}
            <button onClick={joinComp} className="new-default-button">
              Join
            </button>
          </div>
        </>
      ) : (
        <div className="scale-in-ps">
          <PageHeader text="Contests" />

          {/* <div className="cp-current">
            <div className="neh-box">
              <h2 className="ne-header">Active Compeitions</h2>
            </div>
          </div> */}
          <div className="cp-buttons">
            <button onClick={() => setShowNew(true)} className="default-button">
              New Contest
            </button>
          </div>
          <div className="switch-row mt-20">
            <div className="selector-group sg-small">
              <button
                onClick={() => setActive("current")}
                className={
                  active === "current" ? "sg-left sg-active" : "sg-left"
                }
              >
                Current
              </button>
              <button
                onClick={() => setActive("past")}
                className={
                  active === "past" ? "sg-right sg-active" : "sg-right"
                }
              >
                Past
              </button>
            </div>
          </div>
          <div className="contest-list">
            {active === "current" &&
              contests.map((cont, index) => (
                <ContestItem
                  key={cont.contestID}
                  contest={cont}
                  handleClick={() => {
                    // setToShow(
                    //   <ContestPage
                    //     reload={loadContests}
                    //     contestID={cont.contestID}
                    //     goBack={() => setShow(false)}
                    //   />
                    // );
                    // setShow(true);
                  }}
                />
              ))}
            {active === "current" && contests.length === 0 && (
              <div className="no-msg">
                <p>No contests to display</p>
              </div>
            )}
            {active === "past" &&
              oldContests.map((cont, index) => (
                <ContestItem
                  key={cont.contestID}
                  contest={cont}
                  handleClick={() => {
                    setToShow(
                      <ContestPage
                        reload={loadContests}
                        contestID={cont.contestID}
                        goBack={() => setShow(false)}
                      />
                    );
                    setShow(true);
                  }}
                />
              ))}
            {active === "past" && oldContests.length === 0 && (
              <div className="no-msg">
                <p>No contests to display</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Contests;
