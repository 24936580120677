/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useCallback } from "react";
import "./EventCreator.css";
import BackdropLoader from "../../Loaders/BackdropLoader";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import isMobile from "is-mobile";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { ToggleButton, Tabs, Tab, Backdrop } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageSelector from "../ImageSelector/ImageSelector";
import InfoIcon from "@mui/icons-material/Info";
import { Dialog, TextField } from "@mui/material";
import { BallTriangle } from "react-loader-spinner";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { admin, db, storage } from "../../../firebase";
import { Steps } from "intro.js-react";
import { useDropzone } from "react-dropzone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClubCourse from "../../../pages/Clubs/ClubCourse";
import Switch from "react-ios-switch/lib/Switch";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import CoursePicker from "../CoursePicker/CoursePicker";
import EventPlayerSelector from "./EventPlayerSelector";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import CustomRules from "../../CustomRules/CustomRules";
import StatsSelector from "../../StatsSelector/StatsSelector";

import ErrorModal from "../../ErrorModal/ErrorModal";

import HoleSelector from "./HoleSelector";

import { useAuth } from "../../../contexts/AuthContext";

import moment from "moment";
import Pill from "../../Buttons/Pill";
import RadioGroup from "../../Buttons/RadioGroup/RadioGroup";

import InviteModal from "../InviteModal/InviteModal";
import HandicapDivisions from "./HandicapDivisions";
import CustomFormats from "../../CustomFormats/CustomFormats";
import _ from "lodash";
import JackpotHoles from "./JackpotHoles";
import { AddCircle, Close, RemoveCircle } from "@mui/icons-material";
import classNames from "classnames";
import PageHeader from "../../display/Rows/PageHeader";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

const GradientInfoIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <InfoIcon
      onClick={onClick}
      className={`dg-icon ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);
const GradientAddCircleIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className={`dg-icon ${className}`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function EventCreator({
  goBack,
  reload = () => {},
  goToEvent = () => {},
  edit,
  disableChanges = false,
  event = {
    rounds: [],
    seasonID: "",
  },
  eventID,
  playing = [],
  defaultLeagueID = "",
  defaultClubID = "",
  defaultCourseData = {
    courseID: "",
    name: "",
  },
  hideBack = false,
  defaultLeagueName = "",
  noCoursEdit = false,
  defaultEclectic = false,
}) {
  const betterballOptions = [
    {
      label: "Two to count",
      value: "two",
    },
    {
      label: "Bogey +",
      value: "bogey",
    },
    {
      label: "Bogey ++",
      value: "bogey++",
    },
    // {
    //   label: "Combined Scores",
    //   value: "combined",
    // },
  ];

  const playoffOptions = [
    {
      label: "Shared",
      value: "share",
    },
    {
      label: "Playoff",
      value: "playoff",
    },
    {
      label: "Puttoff",
      value: "puttoff",
    },
    {
      label: "Countout",
      value: "countout",
    },
  ];

  const teamOptions = [
    {
      label: "Combined Scores",
      value: "combined",
    },
    {
      label: "One to count",
      value: "scramble",
    },
    {
      label: "Two to count",
      value: "two",
    },
    {
      label: "Bogey +",
      value: "bogey",
    },
    {
      label: "Bogey ++",
      value: "bogey++",
    },
    // {
    //   label: "Average Ball",
    //   value: "average-ball",
    // },
    {
      label: "Custom",
      value: "custom",
    },
    {
      label: "Overall Scores",
      value: "Overall",
    },
    // {
    //   label: "Better Ball",
    //   value: "bb",
    // },
  ];

  const { currentUser, onboarding, setOnboard, userDetails } = useAuth();

  const scrollRef = useRef();

  const picScrollRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    // console.log(playing);
    // console.log(defaultCourseData);

    // console.log(event);
    if (userDetails) {
      if (userDetails.premium) {
        setPremium(true);
      }
    }
  }, [userDetails]);

  const [scratchMode, setScratchMode] = useState(event.scratchMode ?? false);
  const [adjusted, setAdjusted] = useState(event.adjusted ?? false);
  const [addingCourses, setAddingCourses] = useState(false);
  const [courses, setCourses] = useState(event.courses ?? []);
  const [ring, setRing] = useState(event.ring ?? 2);
  const [handicapLimit, setHandicapLimit] = useState(event.handicapLimit ?? 36);
  const [handicapIndexLimit, setHandicapIndexLimit] = useState(
    event.handicapIndexLimit ?? 54
  );

  const [clubImageLink, setClubImageLink] = useState("");
  const [clubImageUsed, setClubImageUsed] = useState(false);

  const [selTab, setSelTab] = useState("general");

  const [bbType, setBBType] = useState("two");

  const [eclectic, setEclectic] = useState(defaultEclectic);

  const [initialID, setInitialID] = useState("");
  const [previousIDArray, setPreviousIDArray] = useState([]);

  const [overallTeamScores, setOverallTeamScores] = useState(
    event.overallTeamScores ?? 2
  );

  const [image, setImage] = useState("");

  const [teamPoints, setTeamPoints] = useState(event.teamPoints ?? false);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);

    setImageURL(url);
    setClubImageUsed(false);
    setShowCrop(true);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
  });

  const now = new Date().toISOString().split("T")[0];
  const [showAdmins, setShowAdmins] = useState(false);
  const [selAdmins, setSelAdmins] = useState(event.adminIDs ?? []);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [error, setError] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState("");
  const [showPlayoffPicker, setShowPlayoffPicker] = useState(false);
  const [playoffHoles, setPlayoffHoles] = useState(event.playoffHoles ?? []);
  const [imgageURL, setImageURL] = useState(event.imageLink ?? "");
  const [prevEvents, setPrevEvents] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setFC(croppedAreaPixels);
  };
  const [fc, setFC] = useState({});

  const [teamAllowance, setTeamAllowance] = useState(
    event.teamAllowance ?? 100
  );
  const [singlesAllowance, setSinglesAllowance] = useState(
    event.singlesAllowance ?? 100
  );
  const [enableSubmission, setEnableSubmission] = useState(
    event.enableSubmission ?? false
  );
  const [jackpot, setJackpot] = useState(event.jackpot ?? false);
  const [jackpotHoles, setJackpotHoles] = useState(event.jackpotHoles ?? []);

  const [showImage, setShowImages] = useState(false);

  const [courseChanged, setCourseChanged] = useState(false);
  const [changeRounds, setChangeRounds] = useState(false);

  const [seasonFinalDate, setSeasonFinalDate] = useState("");

  const [leagueFriends, setLeagueFriends] = useState([]);

  const [standalone, setStandalone] = useState(event.standalone ?? false);

  const [seasonStarts, setSeasonStarts] = useState({});

  const [enabledStats, setEnabledStats] = useState(event.enabledStats ?? []);
  const [showStats, setShowStats] = useState(false);

  const [enableDivisions, setEnableDivisions] = useState(
    event.enableDivisions ?? false
  );

  const [divisions, setDivisions] = useState([]);

  const [chosen, setChosen] = useState(
    event.courseID ? true : defaultCourseData.courseID !== "" ? true : false
  );

  const [clubID, setClubID] = useState(event.clubID ?? defaultClubID ?? "");
  const [clubOptions, setClubOptions] = useState([]);

  const [major, setMajor] = useState(false);
  const [final, setFinal] = useState(false);

  const [seasonDates, setSeasonDates] = useState([]);

  const [premium, setPremium] = useState(false);

  const [scrambleHandicaps, setScrambleHandicaps] = useState(
    event.scrambleHandicaps ?? false
  );

  const [leagues, setLeagues] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [format, setFormat] = useState(event.format ?? "net-strokeplay");
  const [playoff, setPlayoff] = useState("countout");
  const [eventType, setEventType] = useState(event.eventType ?? "regular");

  const [triggerHours, setTriggerHours] = useState(event.triggerHours ?? 5);

  const [useLowHI, setUseLowHI] = useState(event.useLowHI ?? false);

  const [selectedPlayers, setSelectedPlayers] = useState(
    event.invitedIDs ?? []
  );
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedLeague, setSelectedLeague] = useState({
    value: event.leagueID ?? "",
    label: event.leagueName ?? "",
  });
  const [selectedRestrictions, setSelectedRestrictions] = useState(
    event.restrictions ?? "open"
  );
  const [selectedCourse, setSelectedCourse] = useState({
    value: event.courseID ?? defaultCourseData.courseID ?? "",
    label: event.courseName ?? defaultCourseData.name ?? "",
  });

  const [rounds, setRounds] = useState([]);

  const [loaded, setLoaded] = useState(false);

  const [showPlayers, setShowPlayers] = useState(false);

  const [update, setUpdate] = useState(0);

  const [rules, setRules] = useState(event.rules ?? []);
  const [showRules, setShowRules] = useState(false);

  const nameRef = useRef();
  const dateRef = useRef();
  const descRef = useRef();
  const cutoffRef = useRef();
  const limitRef = useRef();
  const priceRef = useRef();

  const [previousEventIDState, setPreviousEventIDState] = useState(
    event.previousEventID ?? ""
  );
  const [previousEventNameState, setPreviousEventNameState] = useState(
    event.previousEventID ?? ""
  );
  const [joinCode, setJoinCode] = useState(event.joinCode ?? "");
  const [joinLive, setJoinLive] = useState(event.joinLive ?? false);

  const [nameState, setNameState] = useState(event.eventName ?? "");
  const [dateState, setDateState] = useState(event.defaultDate ?? "");
  const [priceState, setPriceState] = useState(event.entranceFee ?? 0);
  const [descState, setDescState] = useState(event.description ?? "");
  const [attState, setAttState] = useState(event.limit ?? 12);
  const [cutState, setCutState] = useState(event.cutoff ?? 2);
  const [userDates, setUserDates] = useState([]);

  const [toCount, setToCount] = useState(event.toCount ?? 2);
  const [formulas, setFormulas] = useState(event.formulas ?? []);
  const [customHoles, setCustomHoles] = useState(event.customHole ?? []);
  const [customType, setCustomType] = useState(event.customType ?? "holes");

  const [twoClub, setTwoClub] = useState(event.twoClub ?? false);
  const [showCrop, setShowCrop] = useState(false);

  const [roundCreation, setRoundCreation] = useState(
    event.roundCreation ?? "auto"
  );

  const [manualFinish, setManualFinish] = useState(event.manualFinish ?? false);

  const [finalHoleCutoff, setFinalHoleCutoff] = useState(
    event.finalHoleCutoff ?? 2
  );

  const [teamType, setTeamType] = useState(event.teamType ?? "combined");

  const [pointsType, setPointsType] = useState(event.pointsType ?? "singles");

  const [enableTeams, setEnableTeams] = useState(event.enableTeams ?? false);

  const [prevMap, setPrevMap] = useState({});

  const [selectedEdition, setSelectedEdition] = useState({
    value: event.previousEventID ?? "",
    label: event.previousEventName ?? "",
  });

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState([]);

  const [noSeasons, setNoSeasons] = useState(false);

  const [progress, setProgress] = useState(0);

  async function cropImage() {
    const image = new Image();
    // console.log(imgageURL);
    image.src = imgageURL;
    const crop = fc;
    // console.log(image);
    await new Promise((resolve) => {
      image.onload = () => resolve();
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL("image/jpeg");

    // console.log(croppedImage);
    // Create a new image element
    const newImage = new Image();

    // Set the source of the new image element to the cropped image data URL
    newImage.src = croppedImage;
    // Set croppedImage to a variable, use it within the function, but don't return it
    // console.log(newImage);
    setImage(croppedImage);
    setImageURL(croppedImage);
  }

  useEffect(() => {
    if (eclectic && !edit) {
      setCourses([
        {
          courseID: defaultCourseData.courseID,
          name: defaultCourseData.name,
        },
      ]);
    }

    if (
      !eclectic &&
      !edit &&
      defaultCourseData.courseID !== undefined &&
      defaultCourseData.courseID !== ""
    ) {
      setCourses([
        {
          courseID: defaultCourseData.courseID,
          name: defaultCourseData.name,
        },
      ]);
    }

    // setCourses(
    //   event.courses ?? [{ courseID: event.courseID, name: event.courseName }]
    // );
  }, []);

  useEffect(() => {
    if (onboarding) {
      if (onboarding.eventCreator === false) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);

  useEffect(() => {
    if (loaded) {
      filterPlayers(selectedLeague.value);
      if (
        selectedLeague.value === "standalone" ||
        selectedLeague.value === "Standalone"
      ) {
        setStandalone(true);
        setEventType("regular");
      } else {
        setStandalone(false);
      }
    }
  }, [selectedLeague, loaded]);

  useEffect(() => {
    // console.log(event.clubID);
    if (userDetails) {
      loadAll(userDetails.premium);
      loadUserDates();
      if (edit) {
        checkSeason(event.seasonID);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    setUpdate((current) => current + 1);
  }, [playoffHoles]);

  useEffect(() => {
    checkSeason(selectedSeason);
  }, [selectedSeason]);

  function filterPlayers(leagueID) {
    if (
      leagueID !== "standalone" &&
      leagueID !== "" &&
      leagueID !== undefined
    ) {
      const ids = leagueFriends[leagueID];
      setFilteredPlayers((current) => {
        const newArr = [];
        for (let i = 0; i < players.length; i++) {
          const player = players[i];
          if (ids.includes(player.value)) {
            newArr.push(player);
          }
        }
        return newArr;
      });
      setSelectedPlayers((current) => {
        const newArr = [];
        for (let i = 0; i < current.length; i++) {
          const playerID = current[i].value;
          if (ids.includes(playerID)) {
            newArr.push(current[i]);
          }
        }
        newArr.sort(compare);
        return newArr;
      });
    }
    if (leagueID === "standalone") {
      setFilteredPlayers(players);
    }
  }

  function makeImageID(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  function makeID() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 12; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log(result);
    return setJoinCode(result);
  }

  async function checkSeason(seasonID) {
    const today = new Date();
    const seasonEvents = await db
      .collection("events")
      // .where("date", ">", today)
      .where("seasonID", "==", seasonID)
      .where("seasonID", "!=", "")
      .get();

    let major = false;
    let final = false;

    let foundFinal = false;

    let totalRegs = 0;
    let totalMajors = 0;

    const dateArr = [];

    let finalDate = "";

    for (let i = 0; i < seasonEvents.docs.length; i++) {
      const event = seasonEvents.docs[i].data();
      const thisEventID = seasonEvents.docs[i].id;
      if (thisEventID === eventID) {
        continue;
      }
      if (event.cancelled === true) {
        continue;
      }
      if (event.eventType === "regular") {
        totalRegs += 1;
      }
      if (event.eventType === "major") {
        totalMajors = 0;
      }
      if (event.eventType === "final") {
        foundFinal = true;
        finalDate = new Date(event.eventDate.toDate());
      }

      // Dates
      const eventDate = new Date(event.eventDate.toDate());
      dateArr.push(eventDate);
      const rounds = event.rounds ?? [];
      for (let j = 0; j < rounds.length; j++) {
        // console.log(rounds[j]);
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }

    if (totalRegs + totalMajors >= 3) {
      final = true;
    }
    if (
      (totalRegs / totalMajors >= 3 && totalMajors !== 0) ||
      (totalMajors === 0 && totalRegs > 2)
    ) {
      major = true;
    }
    if (totalRegs < 3 && totalMajors === 0) {
      major = true;
    }
    if (foundFinal) {
      final = false;
      setSeasonFinalDate(finalDate);
    } else {
      setSeasonFinalDate("");
    }

    setFinal(true);
    setMajor(major);
    setSeasonDates(dateArr);
  }

  function compare(a, b) {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  }

  async function loadAll(isPremium) {
    const playerArr = [];
    const leagueArr = [];
    // if (isPremium) {
    leagueArr.push({
      label: "Standalone",
      value: "standalone",
    });
    // }
    const seasonArr = {};

    const selArr = [];

    let playerIDArr = [currentUser.uid];

    const leagueIDMap = {};

    const clubDocs = await db
      .collection("clubs")
      .where("adminIDs", "array-contains", currentUser.uid)
      .get();
    const clubArr = [];

    for (let i = 0; i < clubDocs.docs.length; i++) {
      const clubDoc = clubDocs.docs[i];
      const clubID = clubDoc.id;
      const club = clubDoc.data();
      const { clubName } = club;
      clubArr.push({
        clubName,
        clubID,
        value: clubID,
        label: clubName,
      });
    }
    setClubOptions(clubArr);

    if (defaultClubID || event.clubID) {
      const cid = defaultClubID || event.clubID;
      const clubDoc = await db.collection("clubs").doc(cid).get();
      const cdata = clubDoc.data();
      const { imageLink } = cdata;
      setClubImageLink(imageLink);
    }

    const userDoc = await db.collection("users").doc(currentUser.uid).get();
    const user = userDoc.data();
    const friends = user.friends ?? [];
    for (let i = 0; i < friends.length; i++) {
      playerIDArr.push(friends[i]);
    }

    // console.log(1);
    // console.log(defaultCourseID);
    // Queries

    if (event.courseID) {
      const defaultCourse = await db
        .collection("courses")
        .doc(event.courseID)
        .get();
      const course = defaultCourse.data();
      const { name, teeArray, length, tees, city, country, imageLink } = course;
      setSelectedCourse({
        label: name,
        value: event.courseID,
        teeArray,
        length,
        tees,
        city,
        country,
        imageLink,
      });

      if (event.courses) {
        setCourses(courses);
      } else {
        setCourses([
          {
            label: name,
            value: event.courseID,
            teeArray,
            length,
            tees,
            city,
            country,
            imageLink,
          },
        ]);
      }
    }

    // const players = await db
    //   .collection("users")
    //   .orderBy("lastName", "asc")
    //   .get();
    let leagues;
    if (clubID) {
      leagues = await db
        .collection("leagues")
        .where("clubID", "==", clubID)
        .get();
    } else {
      leagues = await db
        .collection("leagues")
        .where("admins", "array-contains", currentUser.uid)
        .get();
    }
    setProgress((current) => current + 25);
    setUpdate((current) => current + 1);
    const starMaps = {};
    let curSea = "";

    for (let i = 0; i < leagues.docs.length; i++) {
      const doc = leagues.docs[i];
      const leagueID = doc.id;
      const league = doc.data();

      const { leagueName, seasonIDs, playerIDs } = league;

      leagueIDMap[leagueID] = playerIDs;
      for (let i = 0; i < playerIDs.length; i++) {
        if (!playerIDArr.includes(playerIDs[i])) {
          playerIDArr.push(playerIDs[i]);
        }
      }
      const arr = [];
      for (let i = 0; i < seasonIDs.length; i++) {
        const seasonID = seasonIDs[i];
        // console.log(seasonID);
        const seasonDoc = await db.collection("seasons").doc(seasonID).get();
        const season = seasonDoc.data();
        if (season.completed) {
          continue;
        }
        const { seasonName, completed, startDate } = season;
        const startObj = new Date(startDate.toDate());
        starMaps[seasonID] = startObj;
        if (completed === true) {
          continue;
        }
        if (seasonID === event.seasonID) {
          // setSelectedSeason(seasonID);
          curSea = seasonID;
        }
        arr.push({
          value: seasonID,
          label: seasonName,
        });
      }

      seasonArr[leagueID] = arr;
      if (league.leagueType !== "eclectic") {
        // continue;
        leagueArr.push({
          label: leagueName,
          value: leagueID,
        });
      }
    }
    setSeasonStarts(starMaps);

    setProgress((current) => current + 25);
    setUpdate((current) => current + 1);

    // console.log(playerIDArr);

    playerIDArr = playerIDArr.filter((a) => a !== null);

    playerIDArr = [];

    const refs = playerIDArr.map((uid) =>
      db.collection("users").doc(uid).get()
    );
    const userDocs = await Promise.all(refs);
    for (let i = 0; i < userDocs.length; i++) {
      const userDoc = userDocs[i];
      const player = userDoc.data();
      const playerID = userDoc.id;
      if (!player) {
        continue;
      }
      const playerName = `${player.firstName} ${player.lastName}`;
      const { handicap, profileImage, status } = player;
      if (status === "disabled") {
        continue;
      }

      const settings = player.settings ?? "";
      // If player accepts invites from friends onbly
      // if (settings !== '') {
      //     if (settings.friendsOnly === true && !friendIDs.includes(currentUser.uid)) {
      //         continue;
      //     }
      // }

      setProgress((current) => 50 + (i / playerIDArr.length) * 50);
      setUpdate((current) => current + 1);

      if (!edit) {
        playerArr.push({
          label: playerName,
          value: playerID,
          handicap,
          profileImage,
          name: playerName,
          playerID,
          lastName: player.lastName,
        });
      }

      if (edit) {
        if (event.invitedIDs.includes(playerID)) {
          selArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
            name: playerName,
            lastName: player.lastName,
          });
        } else if (!event.playerIDs.includes(playerID)) {
          // console.log(playerID)
          playerArr.push({
            label: playerName,
            value: playerID,
            handicap,
            profileImage,
            name: playerName,
            lastName: player.lastName,
          });
        }
      }
    }
    selArr.sort(compare);
    playerArr.sort(compare);
    if (edit) {
      setSelectedPlayers(selArr);
    }
    // console.log(seasonArr);
    setSeasons(seasonArr);

    setLeagueFriends(leagueIDMap);
    setFilteredPlayers(playerArr);
    setPlayers(playerArr);
    setLeagues(leagueArr);
    // console.log(defaultLeagueID);
    if (defaultLeagueID) {
      const arr = seasonArr[defaultLeagueID];
      setSeasonOptions(arr);
      setSelectedSeason(curSea);
      setSelectedLeague({
        value: defaultLeagueID,
        label: defaultLeagueName ?? event.leagueName,
      });
      loadPreviousSeasonEvents(defaultLeagueID);
    } else {
      // console.log(event);

      if (event.leagueID) {
        loadPreviousSeasonEvents(event.leagueID);
      }
    }
    setUpdate((current) => current + 1);

    setLoaded(true);
  }

  async function loadUserDates() {
    const now = new Date();
    const cups = await db
      .collection("cups")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("cupDate", ">", now)
      .get();
    const events = await db
      .collection("events")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("eventDate", ">", now)
      .get();

    const dateArr = [];
    for (let i = 0; i < cups.docs.length; i++) {
      const cupDoc = cups.docs[i];
      const cup = cupDoc.data();
      const { rounds } = cup;
      for (let j = 0; j < rounds.length; j++) {
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      if (events.docs[i].id === eventID) {
        continue;
      }
      const event = eventDoc.data();
      const { rounds } = event;
      for (let j = 0; j < rounds.length; j++) {
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    setUserDates(dateArr);
  }

  function sameDay(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  async function checkOtherEvents() {
    const chosenDate = dateState;
    // console.log(chosenDate);
    const now = moment(chosenDate);
    if (standalone) {
    } else {
      const events = await db
        .collection("events")
        .where("seasonID", "==", event.seasonID ?? "")
        .get();
      for (let i = 0; i < events.docs.length; i++) {
        if (events.docs[i].id === eventID) {
          continue;
        }
        const event = events.docs[i].data();
        const eventDate = new Date(event.eventDate.toDate());
        const { eventName } = event;
        const evtMoment = moment(eventDate);
        // if (evtMoment.isSame(now, "day")) {
        //   setError(
        //     `Please select another date - this conflicts with ${eventName}`
        //   );
        //   return false;
        // }
      }
    }
  }

  function validate() {
    // console.log("validting");
    setError("");
    // League
    if (selectedLeague.value === "") {
      setError("Please select a league");
      setUpdate((current) => current + 1);
      setSelTab("general");
      return false;
    }
    // Season
    // console.log(selectedSeason, standalone);
    if (selectedSeason === "" && !standalone) {
      setError("Please select a season");
      setSelTab("general");
      return false;
    }
    // Name
    if (selectedEdition.label === "") {
      if (nameState === "") {
        setError("Please enter a name for the event");
        setSelTab("general");
        return false;
      }
    }

    // Dates
    if (dateState === "") {
      setError("Please select a date for this event");
      setSelTab("general");
      return false;
    }
    const dateMoment = moment(dateState);

    // for (let i = 0; i < userDates.length; i++) {
    //   const userDateMoment = moment(userDates[i]);
    //   if (userDateMoment.isSame(dateMoment, "date")) {
    //     setError("Sorry! This date conflicts with one of your future rounds");
    //     dateRef.current.focus();
    //     return false;
    //   }
    // }

    // if (checkOtherEvents() === false) {
    //   return false;
    // }

    if (event.rounds.length > 1) {
      for (let i = 0; i < event.rounds.length; i++) {
        const roundDate = new Date(event.rounds[i].date.toDate());
        const roundMoment = moment(roundDate);
        const evtMoment = moment(dateState);
        if (evtMoment.isAfter(roundMoment)) {
          setError("The event cannot begin after the first round");
          return false;
        }
      }
    }

    const dateVal = new Date(dateState);
    for (let i = 0; i < seasonDates.length; i++) {
      // console.log(seasonDates[i].getDate())
      // if (sameDay(dateVal, seasonDates[i])) {
      //   setError("You already have an event scheduled for this date");
      //   return false;
      // }

      if (eventType === "final") {
        if (dateVal < seasonDates[i]) {
          setError("The season final cannot come before another event");
          return false;
        }
      }

      // if (dateVal < seasonStarts[selectedSeason]) {
      //   setError("This event cannot start before the season begins");
      //   return false;
      // }
    }

    if (dateVal < new Date() && !edit && roundCreation !== "auto") {
      setError("Please ensure the date is in the future");
      setSelTab("general");
      return false;
    }

    if (seasonFinalDate !== "") {
      if (dateVal > seasonFinalDate) {
        setSelTab("general");
        setError(
          "Please ensure this event takes place before the season final"
        );
        return false;
      }
    }

    // Date
    if (dateState === "") {
      setError("Please choose a date for the event");
      setSelTab("general");
      // dateRef.current.scrollIntoView({ behavior: "smooth" });
      return false;
    }

    // Format
    // if (format === '') {
    //     setError('Please choose a format for the event')
    //     return false
    // }
    // Description
    // if (descRef.current.value === "") {
    //   setError("Please enter a description for the event");
    //   return false;
    // }
    // Event Type
    if (!standalone && eventType === "") {
      setError("Please choose a type for the event");
      return false;
    }
    // Playoff
    if (playoff === "") {
      setError("Please select a playoff rule");
      return false;
    }
    if (playoff === "playoff" && playoffHoles.length === 0) {
      setError("Please select at least one playoff hole");
      return false;
    }
    // Course
    if (selectedCourse === "") {
      setError("Please choose a main course for the event");
      return false;
    }

    // Cutoff
    if (!isNumeric(cutState)) {
      setError("Please make sure to enter only numeric values for the cut off");
      setSelTab("attendance");
      return false;
    }

    // Cutoff
    if (cutState < 1) {
      setError("Please make sure to have a cut off of at least 1 day");
      setSelTab("attendance");
      return false;
    }

    // Limit
    if (!isNumeric(attState)) {
      setError(
        "Please make sure to enter only numeric values for attendance limit"
      );
      setSelTab("attendance");

      return false;
    }
    // if (attState < 4) {
    //   setError("Please ensure to have a player limit of at least 4");
    //   setSelTab("attendance")
    //   return false;
    // }

    // Price
    if (!isNumeric(priceState)) {
      setError(
        "Please make sure to enter only numeric values for the entrance fee"
      );
      // priceRef.current.scrollIntoView({ behavior: "smooth" });
      return false;
    }
    if (priceState < 0) {
      setError("Are you sure you want to be paying people to play this event?");
      return false;
    }

    // Final hole
    if (finalHoleCutoff < 2) {
      setError("Minimum for the final hole cutoff is 2 strokes");
      return false;
    }

    // Images
    if (image !== "") {
      // return console.log(image.type);
      if (image.type) {
        if (!image.type.includes("image")) {
          setError("Please make sure to only upload images");
          return false;
        }
      }
    }

    return true;
  }

  async function saveChanges() {
    // return console.log(selectedLeague);

    setError("");
    // return console.log(eventID)
    setProgress(0);
    setLoaded(false);
    // console.log(validate());
    if (validate() === false) {
      return setLoaded(true);
    }

    setLoadingText("Saving changes...");

    const playerIDs = selectedPlayers.map((item) => item.value);
    const playerHandicaps = {};
    for (let i = 0; i < selectedPlayers.length; i++) {
      const player = selectedPlayers[i];
      if (player.customHandicap) {
        playerHandicaps[player.value] = player.handicap;
      }
    }
    const eventDate = new Date(dateState);
    const performAt = new Date(eventDate);
    performAt.setHours(eventDate.getHours() - triggerHours);
    const articlePerformAt = new Date(eventDate);
    articlePerformAt.setDate(articlePerformAt.getDate() - 3);

    const teeMap = {};
    for (let i = 0; i < selectedPlayers.length; i++) {
      teeMap[selectedPlayers[i].value] = selectedPlayers[i].tee;
    }
    setProgress((current) => current + 25);

    if (changeRounds) {
      for (let i = 0; i < event.rounds.length; i++) {
        event.rounds[i].courseID =
          selectedCourse.courseID ?? selectedCourse.value;
        event.rounds[i].imageLink = selectedCourse.imageLink;
      }
    }

    if (event.rounds.length === 1) {
      event.rounds[0].date = eventDate;
    }

    let initialID = "";

    if (selectedEdition.value !== "" && selectedEdition.value !== "none") {
      initialID = prevMap[selectedEdition.label].initialID;
    }

    for (let i = 0; i < courses.length; i++) {
      if (courses[i].courseID === undefined) {
        courses[i].courseID = courses[i].value;
      }
    }

    try {
      await db
        .collection("events")
        .doc(eventID)
        .update({
          useLowHI,
          adminIDs: selAdmins,
          courses,
          overallTeamScores,
          joinLive,
          joinCode,
          imageLink: clubImageUsed ? clubImageLink : event.imageLink,
          enableSubmission,
          jackpot,
          jackpotHoles,
          teamAllowance,
          singlesAllowance,
          teamPoints,
          toCount,
          scrambleHandicaps,
          pointsType,
          formulas,
          customHoles,
          customType,
          clubID,
          enableDivisions,
          roundCreation,
          manualFinish,
          enableTeams,
          teamType,
          ring: parseInt(ring),
          handicapLimit,
          handicapIndexLimit,
          eventDate,
          initialID,
          previousEventID: prevMap[selectedEdition.label] ?? "",
          previousEventName: selectedEdition.label ?? "",
          eventName: nameState,
          invitedIDs: playerIDs,
          restrictions: selectedRestrictions,
          format,
          bbType,
          description: descState,
          courseID: selectedCourse.courseID ?? selectedCourse.value,
          courseName: selectedCourse.name ?? selectedCourse.label,
          cutoff: parseInt(cutState),
          limit: parseInt(attState),
          playoff,
          scratchMode,
          seasonID: selectedSeason,
          eventType,
          entranceFee: parseInt(priceState),
          rules,
          standalone,
          enabledStats,
          playoffHoles,
          rounds: event.rounds,
          playerHandicaps,
          finalHoleCutoff,
          adjusted,
          twoClub,
          leagueID: selectedLeague.value,
          leagueName: selectedLeague.label,
        });
      setProgress((current) => current + 25);

      const tasks = await db
        .collection("tasks")
        .where("eventID", "==", eventID)
        .where("type", "==", "roundCreation")
        .get();
      const articleTasks = await db
        .collection("tasks")
        .where("eventID", "==", eventID)
        .where("type", "==", "pre-event-interview")
        .get();

      const task = tasks.docs[0];
      console.log(roundCreation);
      if (roundCreation === "manual") {
        await task.ref.update({
          performAt: "",
        });
        // alert("did");
      } else {
        await task.ref.update({
          performAt,
        });
      }
      if (articleTasks.docs.length > 0) {
        const articleTask = articleTasks.docs[0];
        articleTask.ref.update({
          performAt: articlePerformAt,
        });
      }
      if (image !== "") {
        const imgID = makeImageID(10);
        console.log("IMAGE UPLOAD");
        setLoadingText("Uploading image...");
        if (typeof image === "string") {
          console.log("STR UPL");
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "eventImage.jpg", { contentType });
            const task = storage
              .ref()
              .child(`${eventID}/${image.name}${imgID}`)
              .put(file);
            // access file here
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                console.log(url);
                await db.collection("events").doc(eventID).update({
                  imageLink: url,
                });
                reload();
                setLoaded(true);
                console.log("GOT HERE 1");
                navigate(`/event-home/${eventID}`);
                window.location.reload();
              });
            });
          });
        } else {
          console.log("OBJ UPL");

          const task = storage
            .ref()
            .child(`${eventID}/${image.name}${imgID}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              await db.collection("events").doc(eventID).update({
                imageLink: url,
              });
              reload();
              setLoaded(true);
              console.log("GOT HERE 1");
              navigate(`/event-home/${eventID}`);
              window.location.reload();
            });
          });
        }
      } else {
        setLoadingText("Almost..");
        setProgress((current) => current + 50);

        reload();
        setLoaded(true);
        console.log("GOT HERE 1");
        navigate(`/event-home/${eventID}`);
        window.location.reload();
      }

      // goBack()
    } catch (err) {
      console.log(err);
      setError("Could not save changes, please try again");
      setLoaded(true);
    }
  }

  async function storeEvent() {
    // return console.log(courses);
    const eventName = nameState;
    const description = descState;
    const cutoff = parseInt(cutState);
    const limit = parseInt(attState);
    const entranceFee = priceState;
    setProgress(0);
    setLoaded(false);
    if (validate() === false) {
      return setLoaded(true);
    }

    setLoadingText("Creating event...");

    const playerIDs = selectedPlayers.map((item) => item.value);
    const teeMap = {};
    for (let i = 0; i < selectedPlayers.length; i++) {
      teeMap[selectedPlayers[i].value] =
        selectedPlayers[i].tee ?? selectedCourse.teeArray[0];
    }
    const eventDate = new Date(dateState);
    const performAt = new Date(eventDate);
    performAt.setHours(eventDate.getHours() - triggerHours);
    const performAtArticle = new Date(eventDate);
    performAtArticle.setDate(performAtArticle.getDate() - 3);

    // Check amount of events in current season
    const seasonEvents = await db
      .collection("events")
      .where("seasonID", "==", selectedSeason)
      .get();
    const eventNumber = seasonEvents.docs.length + 1;
    setProgress((current) => current + 25);

    const playerHandicaps = {};
    for (let i = 0; i < selectedPlayers.length; i++) {
      const player = selectedPlayers[i];
      if (player.customHandicap) {
        playerHandicaps[player.value] = player.handicap;
      }
    }

    let initialID = "";

    if (selectedEdition.value !== "" && selectedEdition.value !== "none") {
      initialID = prevMap[selectedEdition.label].initialID;
    }

    let eventAdmins = [currentUser.uid];

    if (clubID) {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const club = clubDoc.data();
      const { adminIDs } = club;
      eventAdmins = [...adminIDs, ...eventAdmins];
    }

    if (selectedLeague.value !== "standalone") {
      const leagueDoc = await db
        .collection("leagues")
        .doc(selectedLeague.value)
        .get();
      const league = leagueDoc.data();
      const { admins } = league;
      eventAdmins = [...admins, ...eventAdmins];
    }

    const uniqueAdmins = _.uniq(eventAdmins);
    console.log(roundCreation);
    try {
      const event = await db.collection("events").add({
        triggerHours,
        rules,
        teams: [],
        useLowHI,
        imageLink: clubImageUsed ? clubImageLink : "",
        joinLive,
        joinCode,
        enableSubmission,
        courses,
        jackpot,
        jackpotHoles,
        teamAllowance,
        singlesAllowance,
        overallTeamScores,
        teamPoints,
        enableTeams,
        scrambleHandicaps,
        teamType,
        eclectic,
        toCount,
        formulas,
        customHoles,
        customType,
        enableDivisions,
        clubID,
        ring: parseInt(ring),
        handicapLimit,
        handicapIndexLimit,
        declinedIDs: [],
        withdrawnIDs: [],
        initialID,
        bbType,
        enabledStats,
        completed: false,
        eventDate,
        eventName,
        leagueID: selectedLeague.value,
        leagueName: selectedLeague.label ?? selectedLeague.name,
        roundIDs: [],
        rounds: [],
        playerIDs: [],
        seasonID: selectedSeason,
        invitedIDs: playerIDs,
        scratchMode,
        restrictions: selectedRestrictions,
        format,
        finalHoleCutoff,
        courseID: selectedCourse.courseID ?? selectedCourse.value,
        courseName: selectedCourse.name ?? selectedCourse.label,
        description,
        cancelled: false,
        adjusted,
        cutoff,
        pointsType,
        limit,
        groups: [],
        guests: [],
        playoff,
        eventType: standalone ? "" : eventType,
        entranceFee,
        eventNumber,
        teeMap,
        standalone,
        adminIDs: uniqueAdmins,
        adminID: currentUser.uid,
        playoffHoles,
        playerHandicaps,
        roundCreation,
        manualFinish,
        twoClub,
        sr: clubID !== "",
      });

      if (initialID === "") {
        event.update({
          initialID: event.id,
        });
      }

      setProgress((current) => current + 25);
      if (roundCreation === "manual") {
        await db.collection("tasks").add({
          performAt: "",
          eventID: event.id,
          type: "roundCreation",
          leagueID: selectedLeague.value,
          status: "pending",
        });
      } else {
        await db.collection("tasks").add({
          performAt,
          eventID: event.id,
          type: "roundCreation",
          leagueID: selectedLeague.value,
          status: "pending",
        });
      }
      if (!standalone) {
        await db.collection("tasks").add({
          performAt: performAtArticle,
          eventID: event.id,
          type: "pre-event-interview",
          status: "pending",
        });
      }
      setProgress((current) => current + 25);

      if (!standalone) {
        // const seasonDoc = await db
        //   .collection("seasons")
        //   .doc(selectedSeason)
        //   .get();
        // const season = seasonDoc.data();
        // const { eventIDs } = season;
        // eventIDs.push(event.id);
        // await seasonDoc.ref.update({
        //   eventIDs,
        // });
        await db
          .collection("leagues")
          .doc(selectedLeague.value)
          .update({
            eventIDs: admin.firestore.FieldValue.arrayUnion(event.id),
          });
      }
      setLoadingText("Calibrating 3-putts...");
      console.log("3");

      if (image !== "") {
        console.log("4");
        const imgID = makeImageID(10);
        setLoadingText("Uploading image...");
        if (typeof image === "string") {
          fetch(image).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], "eventImage.jpg", { contentType });
            // access file here
            const task = storage
              .ref()
              .child(`${event.id}/${image.name}${imgID}`)
              .put(file);
            await task.then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((url) => {
                db.collection("events").doc(event.id).update({
                  imageLink: url,
                });
              });
            });
          });
        } else {
          console.log("5");

          const task = storage
            .ref()
            .child(`${event.id}/${image.name}${imgID}`)
            .put(image);
          await task.then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              db.collection("events").doc(event.id).update({
                imageLink: url,
              });
            });
          });
        }
      }
      setProgress((current) => current + 25);

      setLoadingText("Off to the first tee...");
      setProgress((current) => current + 25);
      // goToEvent(event.id);
      // goBack();
      navigate(`/event-home/${event.id}`);
      // setTimeout(() => {
      //     goBack()
      //     goToEvent('')
      // }, 2000)
    } catch (err) {
      setError("Could not create your event, please try again");
      setLoaded(true);
    }
  }

  async function loadPreviousSeasonEvents(leagueID) {
    if (leagueID === "standalone") {
      const eventDocs = await db
        .collection("events")
        .where("leagueID", "==", "standalaone")
        .where("adminIDs", "array-contains", currentUser.uid)
        .get();
      const arr = [
        {
          label: "None",
          value: "none",
        },
      ];
    }

    const prev = event.previousEventID;
    const leagueDoc = await db.collection("leagues").doc(leagueID).get();
    if (!leagueDoc.exists) {
      return setPrevEvents([]);
    }
    const league = leagueDoc.data();
    const { seasonIDs } = league;
    if (seasonIDs.length < 2) {
      return setPrevEvents([]);
    }
    const arr = [
      {
        label: "None",
        value: "none",
      },
    ];
    const nameArr = [];
    const now = new Date();
    const events = await db
      .collection("events")
      .where("leagueID", "==", leagueID)
      .where("eventDate", "<", now)
      .orderBy("eventDate", "desc")
      .get();
    const mep = {};
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      const eventID = eventDoc.id;
      const event = eventDoc.data();
      const { eventName } = event;
      const initialID = event.initialID ?? "";
      if (!nameArr.includes(eventName)) {
        nameArr.push(eventName);
        arr.push({
          label: eventName,
          value: eventID,
          eventName,
          eventID,
        });
        if (prev === eventID) {
          setSelectedEdition({
            label: eventName,
            value: eventID,
          });
        }
        mep[eventName] = {
          eventID,
          initialID,
        };
      }
    }
    setPrevMap(mep);
    setPrevEvents(arr);
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);
  const steps = [
    {
      element: "gnwrkbej",
      intro:
        "Let's get your first event up and running. The process is fairly straightforward, but here are a few tips to help you out.",
    },
    {
      element: ".league-hint",
      intro:
        "Make this event part of a league, or have it as a standalone event",
    },
    {
      element: ".stats-hint",
      intro: "Choose which stats will be tracked for this round",
      position: "top",
    },
    {
      element: ".rules-hint",
      intro:
        "Add custom rules for you round - they don't affect the score, but add some lively spice to your round!",
      position: "top",
    },
  ];

  return (
    <>
      <div
        onClick={() => {
          // setError('')
        }}
        className="event-creator onboarding"
      >
        {showCrop && (
          <Backdrop className="crop-drop" open>
            <div className="crop-co">
              <div className="cd-close">
                <Close onClick={() => setShowCrop(false)} />
              </div>
              <div className="crop-box">
                <Cropper
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  image={imgageURL}
                />
              </div>
              <div className="flex-center crop-but">
                <button
                  onClick={() => {
                    cropImage();
                    setShowCrop(false);
                  }}
                  className="default-button"
                >
                  Save
                </button>
              </div>
            </div>
          </Backdrop>
        )}
        {showPlayers && (
          <InviteModal
            creation
            defaultPlayers={players}
            defaultSelected={selectedPlayers}
            add={setSelectedPlayers}
            hide={() => setShowPlayers(false)}
          />
        )}
        {/* <h1>General</h1> */}
        {!loaded && <BackdropLoader />}

        {!loaded ? (
          <div className="loading-container elc">
            {/* <BallTriangle color="#21c17c" height={80} width={80} />
            <p className="mb-20">{loadingText}</p> */}
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          </div>
        ) : !chosen ? (
          <CoursePicker
            goBack={() => {
              if (selectedCourse.value === "") {
                goBack();
              } else {
                setChosen(true);
              }
            }}
            setChosen={setChosen}
            setCourseDetails={(e) => {
              if (addingCourses) {
                setCourses((c) => {
                  c.push(e);
                  return c;
                });
              } else {
                // alert("here");

                setSelectedCourse(e);
                setCourses((c) => {
                  // c.push(e);
                  return [e];
                });
              }
              if (edit) {
                setCourseChanged(true);
              }
              setAddingCourses(false);
            }}
          />
        ) : showImage ? (
          <ImageSelector
            type="event"
            goBack={() => {
              setShowImages(false);
              setTimeout(() =>
                picScrollRef.current.scrollIntoView({ behavior: "smooth" })
              );
              setClubImageUsed(false);
            }}
            selectedImage={image}
            setSelectedImage={(e) => {
              setImage(e);
              setImageURL(e);
            }}
          />
        ) : showRules ? (
          <CustomRules
            goBack={() => {
              setShowRules(false);
              setTimeout(() => {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}
            defaultRules={rules}
            saveRules={setRules}
          />
        ) : show ? (
          toShow
        ) : showStats ? (
          <StatsSelector
            goBack={() => {
              setShowStats(false);
              setTimeout(() => {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}
            selected={enabledStats}
            setSelected={setEnabledStats}
          />
        ) : false ? (
          <EventPlayerSelector
            max={attState === "" ? 60 : attState}
            courseDetails={selectedCourse}
            players={filteredPlayers}
            goBack={() => {
              setShowPlayers(false);
              setTimeout(() => {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}
            selPlayers={selectedPlayers}
            setPlayers={setSelectedPlayers}
          />
        ) : showPlayoffPicker ? (
          <HoleSelector
            goBack={() => {
              setShowPlayoffPicker(false);
              setTimeout(() => {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}
            selected={playoffHoles}
            setSelected={setPlayoffHoles}
          />
        ) : (
          <>
            {error !== "" && (
              <ErrorModal hide={() => setError("")} text={error} />
            )}
            <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
              <div className="info-message">
                <div className="svg-cont">
                  <GradientInfoIcon className="big-info-icon" />
                </div>

                <p>{dialog}</p>
              </div>
            </Dialog>
            <Dialog open={showAdmins} onClose={() => setShowAdmins(false)}>
              <div className="sel-ad-ctt">
                <PageHeader
                  showClose
                  close={() => setShowAdmins(false)}
                  text="Event Admins"
                />
                <div className="flex-wrap pd-20">
                  {playing.map((player, i) => {
                    return (
                      <PlayerItem
                        hideIcons
                        key={player.playerID}
                        noLink
                        playerID={player.playerID}
                        hideHCP
                        name={player.name}
                        showProfile={() => {
                          setSelAdmins((c) => {
                            if (c.includes(player.playerID)) {
                              const filt = c.filter(
                                (pl) => pl !== player.playerID
                              );
                              return filt;
                            } else {
                              c.push(player.playerID);
                              return c;
                            }
                          });
                          setUpdate((c) => c + 1);
                        }}
                        showBg={selAdmins.includes(player.playerID)}
                      />
                    );
                  })}
                </div>
              </div>
            </Dialog>
            {!hideBack && (
              <div onClick={() => goBack()} className="back-row">
                <ArrowBackIcon className="ts-arrow" />
                <p>Back</p>
              </div>
            )}

            <PageHeader text={edit ? "Event Editor" : "Event Creator"} />
            <div className="ec-tabs">
              <Tabs value={selTab} onChange={(e, value) => setSelTab(value)}>
                <Tab label="General" value="general" />
                <Tab label="Courses" value="courses" />
                <Tab label="Scoring" value="scoring" />
                <Tab label="Leaderboards" value="leaderboards" />
                <Tab label="Details" value="details" />
                <Tab label="Attendance" value="attendance" />
                <Tab label="Admin" value="admin" />
              </Tabs>
            </div>
            {selTab === "general" && (
              <>
                <div className="sub-header">
                  <h2 className="ne-header">General</h2>
                </div>
                <div className="ec-box" style={{ alignItems: "flex-end" }}>
                  {clubOptions.length > 1 && !eclectic && !disableChanges && (
                    <div className="input-group league-hint ">
                      <div className="ig-header">
                        <p>Club</p>
                      </div>

                      <Dropdown
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        options={clubOptions}
                        value={clubID}
                        onChange={(e) => {
                          console.log(e.value);
                          setClubID(e.value);
                        }}
                      />
                    </div>
                  )}
                  {!eclectic && (
                    <div className="input-group league-hint ">
                      <div className="ig-header">
                        <p>League</p>
                        {((seasonOptions !== undefined &&
                          selectedLeague.value !== "standalone" &&
                          selectedLeague.value !== undefined &&
                          seasonOptions.length === 0) ||
                          noSeasons) && (
                          <div className="svg-cont">
                            <GradientInfoIcon
                              onClick={() => {
                                setDialog(
                                  `To create an event for ${selectedLeague.label} please create a new season first`
                                );
                                setShowDialog(true);
                              }}
                              className="info-icon dg-icon-bg"
                            />
                          </div>
                        )}
                      </div>

                      <Dropdown
                        disabled={disableChanges}
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        options={leagues}
                        value={selectedLeague.value}
                        onChange={(e) => {
                          const arr = seasons[e.value];
                          setSeasonOptions(arr);
                          setSelectedLeague(e);
                          loadPreviousSeasonEvents(e.value);
                        }}
                      />
                    </div>
                  )}
                  {!standalone && !(eclectic && edit) && (
                    <div className="input-group">
                      <p>Season</p>
                      <Dropdown
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        disabled={
                          (seasonOptions !== undefined &&
                            selectedLeague.value !== "standalone" &&
                            selectedLeague.value !== undefined &&
                            seasonOptions.length === 0) ||
                          noSeasons
                        }
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        options={seasonOptions}
                        value={selectedSeason}
                        onChange={(e) => setSelectedSeason(e.value)}
                      />
                    </div>
                  )}
                  {false && (
                    <div className="input-group">
                      <div className="ig-header">
                        <p>Course</p>
                        {!disableChanges && !noCoursEdit && (
                          <EditIcon
                            className="dg-icon-bg icon-cursor ml-20"
                            onClick={() => {
                              if (!disableChanges) {
                                setChosen(false);
                              }
                            }}
                          />
                        )}
                        {!disableChanges && (
                          <AddCircle
                            onClick={() => {
                              setAddingCourses(true);
                              setChosen(false);
                            }}
                            className="dg-icon-bg icon-cursor ml-20"
                          />
                        )}
                      </div>

                      {/* <div>
                    <input
                      disabled
                      value={selectedCourse.label ?? selectedCourse.name}
                    />
                  </div> */}
                      {/* <ClubCourse course={selectedCourse} hideRight /> */}
                      {/* {courses.length > 0 && ( */}
                      <div className="tle-courses">
                        {courses.map((course, index) => (
                          <ClubCourse
                            course={course}
                            key={course.courseID ?? course.value}
                            hideRight={courses.length === 1 || disableChanges}
                            removeAction={() => {
                              setCourses((currentt) => {
                                const filt = currentt.filter(
                                  (crs) => crs.courseID !== course.courseID
                                );
                                setSelectedCourse(filt[0]);
                                return filt;
                              });
                            }}
                          />
                        ))}
                      </div>
                      {/* )} */}
                    </div>
                  )}
                  {false && courseChanged && edit && (
                    <div className="input-group nassau-group">
                      <p>Change for all rounds</p>
                      <Switch
                        onColor="#1e7a69"
                        checked={changeRounds}
                        className={
                          changeRounds
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() => setChangeRounds((current) => !current)}
                      />
                    </div>
                  )}
                  {!disableChanges && prevEvents.length > 0 && (
                    <div className="input-group">
                      <p>Event Edition</p>
                      <Dropdown
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        disabled={disableChanges || prevEvents.length === 0}
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        options={prevEvents}
                        value={selectedEdition}
                        onChange={(e) => {
                          console.log(e);
                          if (e.value === "none") {
                            return;
                          }
                          setSelectedEdition(e);
                          setNameState(e.label);
                        }}
                      />
                    </div>
                  )}
                  <div className={"input-group"}>
                    <div className="ig-header">
                      <p>Event Name</p>
                    </div>
                    <input
                      className="default-input"
                      placeholder="Start typing..."
                      onChange={(e) => setNameState(e.target.value)}
                      defaultValue={nameState}
                      type="text"
                    />
                  </div>

                  <div
                    className={
                      (standalone && prevEvents.length > 0) || !standalone
                        ? "input-group"
                        : "input-group"
                    }
                  >
                    <p>Event Date</p>
                    <input
                      disabled={disableChanges}
                      onChange={(e) => setDateState(e.target.value)}
                      defaultValue={dateState}
                      max={"9999-12-31"}
                      min={now}
                      className=" ec-date-ip"
                      type="datetime-local"
                    />
                  </div>
                </div>
              </>
            )}

            {selTab === "courses" && (
              <>
                <div className="sub-header">
                  <h2 className="ne-header">Courses</h2>
                </div>

                <div className="ec-box pl-20">
                  {true && (
                    <div className="input-group">
                      <div className="ig-header">
                        <p>Course</p>
                        {!disableChanges && !noCoursEdit && (
                          <EditIcon
                            className="dg-icon-bg icon-cursor ml-20"
                            onClick={() => {
                              if (!disableChanges) {
                                setChosen(false);
                              }
                            }}
                          />
                        )}
                        {!disableChanges && (
                          <AddCircle
                            onClick={() => {
                              setAddingCourses(true);
                              setChosen(false);
                            }}
                            className="dg-icon-bg icon-cursor ml-20"
                          />
                        )}
                      </div>

                      {/* <div>
                    <input
                      disabled
                      value={selectedCourse.label ?? selectedCourse.name}
                    />
                  </div> */}
                      {/* <ClubCourse course={selectedCourse} hideRight /> */}
                      {/* {courses.length > 0 && ( */}
                      <div className="tle-courses">
                        {courses.map((course, index) => (
                          <ClubCourse
                            course={course}
                            key={course.courseID ?? course.value}
                            hideRight={courses.length === 1 || disableChanges}
                            removeAction={() => {
                              setCourses((currentt) => {
                                const filt = currentt.filter(
                                  (crs) => crs.courseID !== course.courseID
                                );
                                setSelectedCourse(filt[0]);
                                return filt;
                              });
                            }}
                          />
                        ))}
                      </div>
                      {/* )} */}
                    </div>
                  )}
                  {courseChanged && edit && (
                    <div className="input-group nassau-group">
                      <p>Change for all rounds</p>
                      <Switch
                        onColor="#1e7a69"
                        checked={changeRounds}
                        className={
                          changeRounds
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() => setChangeRounds((current) => !current)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {selTab === "scoring" && (
              <>
                <div className="sub-header">
                  <h2 className="ne-header">Scoring</h2>
                </div>

                <div className="ec-box">
                  {eventType !== "final" && (
                    <div className="input-group">
                      <p>Singles Format</p>
                      <Dropdown
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        disabled={disableChanges}
                        options={[
                          { value: "net-strokeplay", label: "Net Strokeplay" },
                          {
                            value: "stableford",
                            label: "Stableford",
                          },
                          { value: "medal", label: "Strokeplay/Medal" },

                          ...(!standalone
                            ? [
                                {
                                  value: "team-net-strokeplay",
                                  label: "Team Net Strokeplay",
                                },
                              ]
                            : []),
                          // {
                          //   value: "mod-stableford",
                          //   label: "Modified Stableford",
                          // },
                          // ...(standalone
                          //   ? [{ value: "better-ball", label: "Better Ball" }]
                          //   : []),
                          // ...(standalone
                          //   ? [{ value: "scramble", label: "Scramble" }]
                          //   : []),
                        ]}
                        value={format}
                        onChange={(e) => {
                          setFormat(e.value);
                          if (e.value === "team-net-strokeplay") {
                            setPlayoff("shared");
                          }
                        }}
                      />
                    </div>
                  )}
                  {format === "better-ball" && (
                    <div className="input-group">
                      <p>Format</p>
                      <Dropdown
                        arrowClosed={
                          <KeyboardArrowDownIcon className="drop-icon" />
                        }
                        arrowOpen={
                          <KeyboardArrowUpIcon className="drop-icon" />
                        }
                        disabled={disableChanges}
                        options={betterballOptions}
                        value={bbType}
                        onChange={(e) => setBBType(e.value)}
                      />
                    </div>
                  )}
                  {format !== "medal" && (
                    <div className="input-group stats-hint ">
                      <div className="ig-header">
                        <p>Stroke Limit</p>
                        <GradientInfoIcon
                          className="info-icon dg-icon-bg"
                          onClick={() => {
                            setDialog(
                              "By default, players will ring out at a net double bogey (net +2). Choose whether or not players have a higher stroke limit for this tournament."
                            );
                            setShowDialog(true);
                          }}
                        />
                      </div>
                      <input
                        disabled={disableChanges}
                        value={ring}
                        step={1}
                        min={2}
                        max={10}
                        onChange={(e) => {
                          const val = e.target.value;
                          setRing(val);
                          if (finalHoleCutoff < val) {
                            setFinalHoleCutoff(val);
                          }
                        }}
                        type={"number"}
                      />
                    </div>
                  )}
                  {/* {format !== "medal" && ( */}
                  <div className="input-group stats-hint">
                    <div className="ig-header">
                      <p>Playing Handicap Limit</p>
                      <GradientInfoIcon
                        className="info-icon dg-icon-bg"
                        onClick={() => {
                          setDialog(
                            "Set the highest playing handicap allowed for this event. Players with a handicap higher than the limit will play off the handicap limit for this event."
                          );
                          setShowDialog(true);
                        }}
                      />
                    </div>
                    <input
                      disabled={disableChanges}
                      value={handicapLimit}
                      step={1}
                      min={-10}
                      max={54}
                      onChange={(e) => {
                        const val = e.target.value;
                        setHandicapLimit(val);
                      }}
                      type={"number"}
                    />
                  </div>
                  <div className="input-group stats-hint">
                    <div className="ig-header">
                      <p>Handicap Index Limit</p>
                      <GradientInfoIcon
                        className="info-icon dg-icon-bg"
                        onClick={() => {
                          setDialog(
                            "Set the highest handicap index allowed for this event. Players with a handicap index higher than the limit will have theirs reduced to the limit."
                          );
                          setShowDialog(true);
                        }}
                      />
                    </div>
                    <input
                      disabled={disableChanges}
                      value={handicapIndexLimit}
                      step={1}
                      min={-10}
                      max={54}
                      onChange={(e) => {
                        const val = e.target.value;
                        setHandicapIndexLimit(val);
                      }}
                      type={"number"}
                    />
                  </div>
                  {
                    <div className="input-group nassau-group">
                      <div className="ig-header">
                        <p>Use Low HI</p>
                        <GradientInfoIcon
                          className="info-icon dg-icon-bg"
                          onClick={() => {
                            setDialog(
                              "Enabling this means a player's Low Handicap Index value will be used for this event, overriding any other handicap values that are set."
                            );
                            setShowDialog(true);
                          }}
                        />
                      </div>
                      <Switch
                        onColor="#1e7a69"
                        disabled={disableChanges}
                        checked={useLowHI}
                        className={
                          useLowHI
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() => setUseLowHI((current) => !current)}
                      />
                    </div>
                  }
                  {/* )} */}
                  {/* {format !== "medal" && ( */}
                  <div className="input-group">
                    <p>Singles Handicap Allowance (%)</p>
                    <input
                      disabled={disableChanges}
                      type="number"
                      max={100}
                      min={0}
                      value={singlesAllowance}
                      onChange={(e) =>
                        setSinglesAllowance(parseInt(e.target.value))
                      }
                    />
                  </div>
                  {/* )} */}
                  {!enableTeams && userDetails && (
                    <div className="input-group nassau-group">
                      <div className="ig-header">
                        <p>Overall Score Submission</p>
                        <div className="svg-cont">
                          <GradientInfoIcon
                            onClick={() => {
                              setDialog(
                                `Allow players to submit an overall score after their round, as opposed to scoring hole by hole. This is disabled by default when team leaderboards are enabled.`
                              );
                              setShowDialog(true);
                            }}
                            className="info-icon dg-icon-bg"
                          />
                        </div>
                      </div>
                      <Switch
                        disabled={disableChanges}
                        onColor="#1e7a69"
                        checked={enableSubmission}
                        className={
                          enableSubmission
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() =>
                          setEnableSubmission((current) => !current)
                        }
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {selTab === "leaderboards" && (
              <>
                <div className="sub-header">
                  <h2 className="ne-header">Leaderboards</h2>
                </div>

                <div className="ec-box">
                  <div className="input-group nassau-group">
                    <div className="ig-header">
                      <p>Leaderboard Divisions</p>
                      <div className="svg-cont">
                        <GradientInfoIcon
                          onClick={() => {
                            setDialog(
                              `Split the leaderboard into different categories (eg handicap divisions), with different formats. These can be edited from your Event Home Dashboard.`
                            );
                            setShowDialog(true);
                          }}
                          className="info-icon dg-icon-bg"
                        />
                      </div>
                    </div>
                    <Switch
                      disabled={disableChanges}
                      onColor="#1e7a69"
                      checked={enableDivisions}
                      className={
                        enableDivisions
                          ? "scoring-switch switch-on"
                          : "scoring-switch"
                      }
                      onChange={() => setEnableDivisions((current) => !current)}
                    />
                  </div>
                  {!disableChanges && !edit && false && enableDivisions && (
                    <p
                      onClick={() => {
                        setToShow(
                          <HandicapDivisions
                            eventCreator
                            stdl={standalone}
                            goBack={() => setShow(false)}
                            defaultDivisions={divisions}
                            save={setDivisions}
                          />
                        );
                        setShow(true);
                      }}
                      className="green-link"
                    >
                      Manage
                    </p>
                  )}
                  {eventType !== "final" &&
                    format !== "team-net-strokeplay" &&
                    format !== "better-ball" && (
                      <div className="input-group nassau-group">
                        <div className="ig-header">
                          <p>Enable Teams</p>
                          <GradientInfoIcon
                            className="info-icon dg-icon-bg"
                            onClick={() => {
                              setDialog(
                                "Enabling this will split players into random groups of 2, 3 or 4 depending on the amount of players taking part. Each group's scores will be aggregated to form a new leaderboard."
                              );
                              setShowDialog(true);
                            }}
                          />
                        </div>
                        <Switch
                          disabled={disableChanges}
                          onColor="#1e7a69"
                          checked={enableTeams}
                          className={
                            enableTeams
                              ? "scoring-switch switch-on"
                              : "scoring-switch"
                          }
                          onChange={() => {
                            setEnableTeams((current) => {
                              if (!current) {
                                setEnableSubmission(false);
                              }

                              return !current;
                            });
                          }}
                        />
                      </div>
                    )}
                  {enableTeams &&
                    format !== "team-net-strokeplay" &&
                    format !== "better-ball" && (
                      <div className="input-group">
                        <div className="ig-header">
                          <p>Team Format</p>
                          <GradientInfoIcon
                            className="info-icon dg-icon-bg"
                            onClick={() => {
                              setDialog(
                                "Enabling this will split players into groups, with each group's scores aggregated to form a new leaderboard."
                              );
                              setShowDialog(true);
                            }}
                          />
                        </div>
                        <Dropdown
                          arrowClosed={
                            <KeyboardArrowDownIcon className="drop-icon" />
                          }
                          arrowOpen={
                            <KeyboardArrowUpIcon className="drop-icon" />
                          }
                          value={teamType}
                          options={teamOptions}
                          onChange={(e) => setTeamType(e.value)}
                          disabled={disableChanges}
                        />

                        {teamType === "custom" && !isMobile && (
                          <div className="flex-center mt-10">
                            <p
                              onClick={() => {
                                setToShow(
                                  <CustomFormats
                                    saveChanges={(a, b, c, d) => {
                                      setCustomType(a);
                                      setToCount(b);
                                      setFormulas(c);
                                      setCustomHoles(d);
                                      setShow(false);
                                    }}
                                    defaultCourseCard={customHoles}
                                    defaultFormulas={formulas}
                                    defaultSelected={customType}
                                    defaultToCount={toCount}
                                    courseID={selectedCourse.value}
                                    goBack={() => setShow(false)}
                                  />
                                );
                                setShow(true);
                              }}
                              className="green-link"
                            >
                              Customize
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                  {enableTeams && teamType === "Overall" && (
                    <>
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                        className="input-group etm-ig"
                      >
                        <p style={{ marginBottom: "0px" }}>Scores To Count</p>
                        <div className="tee-numbers pl-20">
                          <div className="svg-cont">
                            <RemoveCircle
                              className="dg-icon-bg icon-cursor"
                              onClick={() => {
                                setOverallTeamScores((c) => {
                                  if (c === 1) {
                                    return c;
                                  } else {
                                    return c - 1;
                                  }
                                });

                                setUpdate((current) => current + 1);
                              }}
                            />
                          </div>

                          <p>{overallTeamScores}</p>
                          <div className="svg-cont">
                            <AddCircle
                              className="dg-icon-bg icon-cursor"
                              onClick={() => {
                                setOverallTeamScores((c) => {
                                  return c + 1;
                                });
                                setUpdate((current) => current + 1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {enableTeams && false && !standalone && (
                    <div className="input-group nassau-group">
                      <div className="ig-header">
                        <p>Team Points</p>
                        <GradientInfoIcon
                          className="info-icon dg-icon-bg"
                          onClick={() => {
                            setDialog(
                              "Award points for this event depending on where teams finish, as opposed to players"
                            );
                            setShowDialog(true);
                          }}
                        />
                      </div>
                      <Switch
                        disabled={disableChanges}
                        onColor="#1e7a69"
                        checked={teamPoints}
                        className={
                          teamPoints
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() => setTeamPoints((current) => !current)}
                      />
                    </div>
                  )}
                  {enableTeams && teamType === "scramble" && (
                    <div className="input-group nassau-group">
                      <div className="ig-header">
                        <p>Scramble Handicaps</p>
                        <GradientInfoIcon
                          className="info-icon dg-icon-bg"
                          onClick={() => {
                            setDialog(
                              "Only possible for 2 players - uses 35% of the higher handicap, and 15% of the lower handicap, added together to form a new group handicap."
                            );
                            setShowDialog(true);
                          }}
                        />
                      </div>
                      <Switch
                        onColor="#1e7a69"
                        checked={scrambleHandicaps}
                        disabled={disableChanges}
                        className={
                          scrambleHandicaps
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() =>
                          setScrambleHandicaps((current) => !current)
                        }
                      />
                    </div>
                  )}
                  {enableTeams && (
                    <div className="input-group">
                      <p>Team Handicap Allowance (%)</p>
                      <input
                        type="number"
                        max={100}
                        disabled={disableChanges}
                        min={0}
                        value={teamAllowance}
                        onChange={(e) =>
                          setTeamAllowance(parseInt(e.target.value))
                        }
                      />
                    </div>
                  )}
                  <div className="input-group nassau-group">
                    <div className="ig-header">
                      <p>Two Club</p>
                      <GradientInfoIcon
                        className="info-icon dg-icon-bg"
                        onClick={() => {
                          setDialog(
                            "Displays the amount of 2s made by players on the course."
                          );
                          setShowDialog(true);
                        }}
                      />
                    </div>
                    <Switch
                      disabled={disableChanges}
                      onColor="#1e7a69"
                      checked={twoClub}
                      className={
                        twoClub ? "scoring-switch switch-on" : "scoring-switch"
                      }
                      onChange={() => setTwoClub((current) => !current)}
                    />
                  </div>

                  <div className="ld-ig">
                    <div className="input-group nassau-group">
                      <div className="ig-header">
                        <p>Jackpot Leaderboard</p>
                        <GradientInfoIcon
                          className="info-icon dg-icon-bg"
                          onClick={() => {
                            setDialog(
                              "Create a separate mini leaderboard of up to 4 holes"
                            );
                            setShowDialog(true);
                          }}
                        />
                      </div>
                      <Switch
                        disabled={disableChanges}
                        onColor="#1e7a69"
                        checked={jackpot}
                        className={
                          jackpot
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() => setJackpot((current) => !current)}
                      />
                    </div>
                    {jackpot && (
                      <>
                        <p
                          className="green-link"
                          onClick={() => {
                            setToShow(
                              <JackpotHoles
                                ec
                                saveHoles={(e) => {
                                  setJackpotHoles(e);
                                }}
                                goBack={() => setShow(false)}
                                defaultHoles={jackpotHoles}
                              />
                            );
                            setShow(true);
                          }}
                        >
                          Select Holes
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {selTab === "attendance" && (
              <>
                {true && (
                  <>
                    <div className="sub-header">
                      <h2 className="ne-header">Attendance</h2>
                    </div>
                    <div className="ec-box" style={{ alignItems: "flex-end" }}>
                      <div className="input-group">
                        <p>Entrance Fee</p>
                        <input
                          onChange={(e) => setPriceState(e.target.value)}
                          defaultValue={priceState}
                          type="text"
                        />
                      </div>
                      <div className="input-group">
                        <div className="ig-header">
                          <p>Attendance Limit</p>
                          <div className="svg-cont">
                            <GradientInfoIcon
                              className="info-icon dg-icon-bg"
                              onClick={() => {
                                setDialog(
                                  "How many entry spots are there for this event?"
                                );
                                setShowDialog(true);
                              }}
                            />
                          </div>
                        </div>
                        <input
                          type={"number"}
                          // min={4}
                          disabled={disableChanges}
                          defaultValue={attState}
                          onChange={(e) => setAttState(e.target.value)}
                        />
                      </div>

                      <div className="input-group">
                        <div className="ig-header">
                          <p>Cut Off (Days before)</p>
                          <GradientInfoIcon
                            className="info-icon dg-icon-bg"
                            onClick={() => {
                              setDialog(
                                "How many days before the event can players still join?"
                              );
                              setShowDialog(true);
                            }}
                          />
                        </div>
                        <input
                          type={"number"}
                          min={2}
                          disabled={disableChanges}
                          defaultValue={cutState}
                          onChange={(e) => setCutState(e.target.value)}
                          max={7}
                        />
                      </div>

                      <div
                        className={classNames(
                          "input-group mt-20 join-hint",
                          {}
                        )}
                      >
                        <div className="ig-header">
                          <p>Join Code</p>
                          {/* <ContentCopy
                        onClick={() => {
                          navigator.clipboard.writeText(joinCode);
                          setShowSnack(true);
                        }}
                        className="mb-10 info-icon"
                      /> */}
                          <button className="green-link" onClick={makeID}>
                            Generate
                          </button>
                        </div>
                        <input
                          onChange={(e) => setJoinCode(e.target.value)}
                          type={"text"}
                          value={joinCode}
                          placeholder={"Enter joining code"}
                        />
                      </div>
                      {joinCode !== "" &&
                        userDetails &&
                        (userDetails.test || userDetails.admin) && (
                          <>
                            <div className="input-group nassau-group">
                              <div className="ig-header">
                                <p>Live Joining</p>
                                <GradientInfoIcon
                                  className="info-icon dg-icon-bg"
                                  onClick={() => {
                                    setDialog(
                                      "Allow players to join after the event has gone live."
                                    );
                                    setShowDialog(true);
                                  }}
                                />
                              </div>
                              <Switch
                                onColor="#1e7a69"
                                checked={joinLive}
                                className={
                                  joinLive
                                    ? "scoring-switch switch-on"
                                    : "scoring-switch"
                                }
                                onChange={() =>
                                  setJoinLive((current) => !current)
                                }
                              />
                            </div>
                          </>
                        )}
                      {/* {selectedLeague.value !== "standalone" && ( */}
                      {!disableChanges && (
                        <div className="input-group ">
                          <div className="ig-header">
                            <p>Participation</p>
                            <GradientInfoIcon
                              className="info-icon dg-icon-bg"
                              onClick={() => {
                                setDialog(
                                  selectedLeague.value === "standalone"
                                    ? "Invite only or open to the public?"
                                    : "Open to all league members or invite only?"
                                );
                                setShowDialog(true);
                              }}
                            />
                          </div>
                          {/* <div className="tt-container">
                    <div className="tab-toggle">
                      <Tabs
                        value={selectedRestrictions}
                        onChange={(e, newValue) =>
                          setSelectedRestrictions(newValue)
                        }
                      >
                        <Tab
                          className="left-radius"
                          label="Open"
                          value={"open"}
                        />
                        <Tab
                          className="right-radius"
                          label="Restricted"
                          value={"restricted"}
                        />
                      </Tabs>
                    </div>
                  </div> */}
                          <div className="selector-group">
                            <button
                              onClick={() => setSelectedRestrictions("open")}
                              className={
                                selectedRestrictions === "open"
                                  ? "sg-left sg-active"
                                  : "sg-left"
                              }
                            >
                              Open
                            </button>
                            {clubID !== "" && (
                              <button
                                onClick={() =>
                                  setSelectedRestrictions("members")
                                }
                                className={
                                  selectedRestrictions === "members"
                                    ? "sg-middle sg-active"
                                    : "sg-middle"
                                }
                              >
                                Members
                              </button>
                            )}
                            <button
                              onClick={() =>
                                setSelectedRestrictions("restricted")
                              }
                              className={
                                selectedRestrictions === "restricted"
                                  ? "sg-right sg-active"
                                  : "sg-right"
                              }
                            >
                              Restricted
                            </button>
                          </div>
                        </div>
                      )}
                      {/* )} */}
                      {!standalone && !disableChanges && !eclectic && (
                        <div className="input-group">
                          <div className="ig-header">
                            <p>Event Type</p>
                            <GradientInfoIcon
                              className="info-icon dg-icon-bg"
                              onClick={() => {
                                setDialog([
                                  "One major is allowed for every 3 regular events - majors provide more points (700 vs 500 for the winner) and a bigger purse (1.2m vs 600k).",
                                  <br />,
                                  <br />,
                                  "In a final, players are given starting strokes according to their position in the league standings - the leader starts at -5, with players lower down getting given progressively less shots (bottom of the log starts at even par)",
                                ]);
                                setShowDialog(true);
                              }}
                            />
                          </div>
                          {/* <RadioGroup
                    active={eventType}
                    buttons={[
                      {
                        text: "Regular",
                        value: "regular",
                      },
                      {
                        text: "Major",
                        value: "major",
                      },
                      {
                        text: "Final",
                        value: "final",
                      },
                    ]}
                    setActive={setEventType}
                  /> */}
                          {/* <div className="tt-container">
                    <div className="tab-toggle">
                      <Tabs
                        value={eventType}
                        onChange={(e, newValue) => setEventType(newValue)}
                      >
                        <Tab
                          className="left-radius"
                          label={"Regular"}
                          value={"regular"}
                        />
                        <Tab
                          className={
                            eventType === "major" ? "ttb-sel" : "tt-border"
                          }
                          label={"Major"}
                          value={"major"}
                        />
                        <Tab
                          className="right-radius"
                          label={"Final"}
                          value={"final"}
                        />
                      </Tabs>
                    </div>
                  </div> */}

                          {!disableChanges && (
                            <div className="selector-group">
                              <button
                                onClick={() => setEventType("regular")}
                                className={
                                  eventType === "regular"
                                    ? "sg-left sg-active"
                                    : "sg-left"
                                }
                              >
                                Regular
                              </button>
                              <button
                                onClick={() => setEventType("major")}
                                disabled={!major}
                                className={
                                  eventType === "major"
                                    ? "sg-middle sg-active"
                                    : "sg-middle"
                                }
                              >
                                Major
                              </button>
                              <button
                                onClick={() => setEventType("final")}
                                disabled={!final}
                                className={
                                  eventType === "final"
                                    ? "sg-active sg-right"
                                    : "sg-right"
                                }
                              >
                                Final
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {selTab === "details" && (
              <>
                <div className="sub-header">
                  <h2 className="ne-header">Details</h2>
                </div>
                <div className="ec-box">
                  <div className="input-group">
                    <div className="ig-header">
                      <p>Description</p>
                      {false && (
                        <GradientInfoIcon
                          className="info-icon dg-icon-bg"
                          onClick={() => {
                            setDialog([
                              "Browsers can be a bit funny with lines - please type /n to start a new paragraph.",
                            ]);
                            setShowDialog(true);
                          }}
                        />
                      )}
                    </div>

                    <textarea
                      placeholder="Type something..."
                      defaultValue={descState}
                      onChange={(e) => setDescState(e.target.value)}
                      rows={6}
                    />
                  </div>
                  <div ref={picScrollRef} className="input-group mb-20">
                    <p>Event Picture</p>
                    <div className="image-buttons">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-sel">
                          {imgageURL !== "" && (
                            <div className="event-img">
                              <img className="" alt="profile" src={imgageURL} />
                            </div>
                          )}
                          {imgageURL === "" && (
                            <h5>
                              Drag 'n' drop an image here, or click to open up
                              the file explorer
                            </h5>
                          )}
                        </div>
                        <div className="dz-or">
                          <p className="">Or</p>
                        </div>
                      </div>
                      <Pill
                        text={"Choose Preset Image"}
                        onClick={() => setShowImages(true)}
                      />
                      {clubImageLink !== "" && (
                        <div className="mt-10">
                          <Pill
                            text={"Choose Club Image"}
                            onClick={() => {
                              setImageURL(clubImageLink);
                              setClubImageUsed(true);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {selTab === "admin" && (
              <>
                {!disableChanges && (
                  <>
                    <div className="sub-header">
                      <h2 className="ne-header">Admin & Stats</h2>
                    </div>
                    <div className="ec-box mb-0">
                      {false && (
                        <div className="input-group nassau-group mt-10">
                          <div className="ig-header">
                            <p>Scratch Mode</p>
                            <GradientInfoIcon
                              className="info-icon"
                              onClick={() => {
                                setDialog(
                                  "Enabling this will set every handicap to zero, allowing everyone to play off scratch. Caution is advised..."
                                );
                                setShowDialog(true);
                              }}
                            />
                          </div>
                          <Switch
                            onColor="#1e7a69"
                            disabled={adjusted}
                            checked={scratchMode}
                            className={
                              scratchMode
                                ? "scoring-switch switch-on"
                                : "scoring-switch"
                            }
                            onChange={() => {
                              setScratchMode((current) => !current);
                              setAdjusted(false);
                            }}
                          />
                        </div>
                      )}
                      <div className="input-group nassau-group mt-10">
                        <div className="ig-header">
                          <p>Adjusted Handicaps</p>
                          <GradientInfoIcon
                            className="info-icon dg-icon-bg"
                            onClick={() => {
                              setDialog(
                                "Enabling this will adjust every player's handicap slightly, by adding 4 strokes to each handicap and using 80% of that number as the playing handicap. E.g. a 24 handicap's adjusted handicap will be 80% of 28 (24 + 4), which is 22, whereas a 4 handicap would play off a 6."
                              );
                              setShowDialog(true);
                            }}
                          />
                        </div>
                        <Switch
                          onColor="#1e7a69"
                          checked={adjusted}
                          disabled={scratchMode}
                          className={
                            adjusted
                              ? "scoring-switch switch-on"
                              : "scoring-switch"
                          }
                          onChange={() => {
                            setAdjusted((current) => !current);
                            setScratchMode(false);
                          }}
                        />
                      </div>
                      <div className="input-group  mt-10">
                        <div className="ig-header">
                          <p>Round Creation</p>
                          <GradientInfoIcon
                            className="info-icon dg-icon-bg"
                            onClick={() => {
                              setDialog(
                                "Automatic round creation is performed 5 hours prior to the event starting, manual round creation needs to be triggered by you."
                              );
                              setShowDialog(true);
                            }}
                          />
                        </div>
                        <div className="selector-group">
                          <button
                            onClick={() => setRoundCreation("auto")}
                            className={
                              roundCreation === "auto"
                                ? "sg-active sg-right"
                                : "sg-right"
                            }
                          >
                            Auto
                          </button>
                          <button
                            onClick={() => setRoundCreation("manual")}
                            className={
                              roundCreation === "manual"
                                ? "sg-middle sg-active"
                                : "sg-middle"
                            }
                          >
                            Manual
                          </button>
                        </div>
                      </div>

                      {roundCreation === "auto" && (
                        <div className="input-group stats-hint mt-20">
                          <div className="ig-header">
                            <p>Hours Before</p>
                            <GradientInfoIcon
                              className="info-icon dg-icon-bg"
                              onClick={() => {
                                setDialog(
                                  "How many hours before the event would you like the rounds to go live?"
                                );
                                setShowDialog(true);
                              }}
                            />
                          </div>
                          <input
                            value={triggerHours}
                            step={1}
                            min={1}
                            max={10}
                            onChange={(e) => {
                              const val = e.target.value;
                              setTriggerHours(val);
                            }}
                            type={"number"}
                          />
                        </div>
                      )}

                      <div className="input-group nassau-group mt-10">
                        <div className="ig-header">
                          <p>Manual Round Finish</p>
                          <GradientInfoIcon
                            className="info-icon dg-icon-bg"
                            onClick={() => {
                              setDialog(
                                "Enabling this will disable the card submission functionality on the app, and leave the completion of any rounds up to you."
                              );
                              setShowDialog(true);
                            }}
                          />
                        </div>
                        <Switch
                          onColor="#1e7a69"
                          checked={manualFinish}
                          // disabled={scratchMode}
                          className={
                            manualFinish
                              ? "scoring-switch switch-on"
                              : "scoring-switch"
                          }
                          onChange={() => {
                            setManualFinish((current) => !current);
                          }}
                        />
                      </div>
                    </div>

                    <div className="ec-box mb-0">
                      <div className="input-group stats-hint mt-10">
                        <div className="ig-header">
                          <p>Enabled Stats ({enabledStats.length})</p>
                          <GradientAddCircleIcon
                            onClick={() => setShowStats(true)}
                            className="info-icon dg-icon-bg"
                          />
                        </div>
                        <div className="ec-stats">
                          <ul>
                            {enabledStats.map((stat, index) => (
                              <li>{stat}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="input-group rules-hint mt-10">
                        <div className="ig-header">
                          <p>Custom Rules ({rules.length})</p>
                          <GradientAddCircleIcon
                            onClick={() => setShowRules(true)}
                            className="info-icon dg-icon-bg"
                          />
                        </div>
                        <div className="ec-stats">
                          <ul>
                            {rules.map((rule, index) => (
                              <li>{rule.name}</li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {false && (
                        <div className="input-group mt-10">
                          <div className="ig-header">
                            {edit ? (
                              <p>Manage Players</p>
                            ) : (
                              <p>Invite Players ({selectedPlayers.length})</p>
                            )}
                            <GradientAddCircleIcon
                              onClick={() => setShowPlayers(true)}
                              className="info-icon dg-icon-bg"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ec-box mb-0 align-center">
                      {format !== "medal" && (
                        <div className="input-group stats-hint mt-20">
                          <div className="ig-header">
                            <p>Final Hole Cutoff</p>
                            <GradientInfoIcon
                              className="info-icon dg-icon-bg"
                              onClick={() => {
                                setDialog(
                                  "By default, players will ring out at a net double bogey (net +2). Choose whether or not players have a higher stroke limit on the last hole of the tournament, to keep things interesting down the home stretch."
                                );
                                setShowDialog(true);
                              }}
                            />
                          </div>
                          <input
                            value={finalHoleCutoff}
                            step={1}
                            min={2}
                            max={10}
                            onChange={(e) => {
                              const val = e.target.value;
                              setFinalHoleCutoff(val);
                            }}
                            type={"number"}
                          />
                        </div>
                      )}
                      {!disableChanges && false && (
                        <div className="input-group mt-10">
                          <div className="ig-header">
                            <p>Tied Event</p>
                            <GradientInfoIcon
                              className="info-icon dg-icon-bg"
                              onClick={() => {
                                setDialog(
                                  "Select what to do in the case of a tied event"
                                );
                                setShowDialog(true);
                              }}
                            />
                          </div>
                          <Dropdown
                            arrowClosed={
                              <KeyboardArrowDownIcon className="drop-icon" />
                            }
                            arrowOpen={
                              <KeyboardArrowUpIcon className="drop-icon" />
                            }
                            options={playoffOptions}
                            value={playoff}
                            onChange={(e) => {
                              console.log(e.value);
                              setPlayoff(e.value);
                            }}
                          />
                          {false && (
                            <div className="selector-group">
                              {eventType !== "final" && (
                                <button
                                  onClick={() => setPlayoff("share")}
                                  className={
                                    playoff === "share"
                                      ? "sg-left sg-active"
                                      : "sg-left"
                                  }
                                >
                                  Shared
                                </button>
                              )}
                              {eventType === "final" && (
                                <button
                                  onClick={() => setPlayoff("points")}
                                  className={
                                    playoff === "points"
                                      ? "sg-left sg-active"
                                      : "sg-left"
                                  }
                                >
                                  Points
                                </button>
                              )}
                              {format !== "team-net-strokeplay" && (
                                <button
                                  onClick={() => setPlayoff("playoff")}
                                  className={
                                    playoff === "playoff"
                                      ? "sg-middle sg-active"
                                      : "sg-middle"
                                  }
                                >
                                  Playoff
                                </button>
                              )}
                              {format !== "team-net-strokeplay" && (
                                <button
                                  onClick={() => setPlayoff("puttoff")}
                                  className={
                                    playoff === "puttoff"
                                      ? "sg-active sg-right"
                                      : "sg-right"
                                  }
                                >
                                  Puttoff
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {playoff === "playoff" && (
                        <div className="input-group">
                          <div className="ig-header">
                            <p>Playoff Holes ({playoffHoles.length})</p>
                            <GradientAddCircleIcon
                              onClick={() => setShowPlayoffPicker(true)}
                              className="info-icon dg-icon-bg"
                            />
                          </div>
                          <div className="ph-msg">
                            <span>
                              Selected Holes:{" "}
                              <strong>
                                {playoffHoles.map((hole, index) => {
                                  if (index === playoffHoles.length - 1) {
                                    return hole;
                                  } else {
                                    return `${hole}, `;
                                  }
                                })}
                              </strong>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="ec-box"></div> */}
                  </>
                )}

                {false && (
                  <>
                    <div className="sub-header">
                      <div className="sh-ig-header">
                        <h2 className="ne-header">Admins</h2>
                        <EditIcon
                          className="dg-icon-bg icon-cursor ml-20"
                          onClick={() => {
                            setShowAdmins(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap"></div>
                  </>
                )}
              </>
            )}

            {/* {error !== "" && (
              <div className="error-message-row">
                <p>{error}</p>
              </div>
            )} */}

            <div ref={scrollRef} className=""></div>
            <div className="default-button-row">
              {!edit ? (
                <button className="default-button" onClick={storeEvent}>
                  Create Event
                </button>
              ) : (
                <button className="default-button" onClick={saveChanges}>
                  Save Changes
                </button>
              )}
            </div>
            <div className="ec-box">{rounds.map((round) => round)}</div>
          </>
        )}
      </div>
    </>
  );
}

export default EventCreator;
