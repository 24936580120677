import React from "react";
import { Avatar, Tooltip } from "@mui/material";

// ICons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

function MessageItem({
  message,
  editAction = () => {},
  deleteAction = () => {},
}) {
  return (
    <div className="message-item">
      <div className="mi-text">
        <div className="mi-left">
          <Avatar src={message.imageLink} alt={message.title} />
        </div>
        <div className="mi-right">
          <h5>{message.dateString}</h5>
          <h2>{message.title}</h2>
          <p>{message.message}</p>
        </div>
      </div>

      <div className="mi-icons">
        <Tooltip title="Edit Message">
          <EditIcon
            onClick={editAction}
            className="dg-icon-bg icon-cursor mb-10"
          />
        </Tooltip>
        <Tooltip title="Delete Message">
          <DeleteForeverIcon
            onClick={deleteAction}
            className="red-icon-bg icon-cursor"
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default MessageItem;
