import { Avatar } from "@mui/material";
import React from "react";

function LeagueTeamLog({ teams = [], final = false }) {
  return (
    <div className="league-team-log">
      <div className="ltl-box">
        <div className="ltl-item ltl-head">
          <div className="ltl-rank">
            <p>Rank</p>
          </div>
          <div className="ltl-ava ltl-rank">
            <p>Image</p>
          </div>
          <div className="ltl-det">
            <p>Team</p>
          </div>
          <div className="ltl-pts">
            <p>Played</p>
          </div>
          <div className="ltl-pts">
            <p>{final ? "Final" : "Points"}</p>
          </div>
          <div className="ltl-pts">
            <p>Wins</p>
          </div>
        </div>
        <div className="divideher"></div>
        {teams.map((team, i) => (
          <div className="ltl-item" key={team.teamID}>
            <div className="ltl-rank">
              <p>{team.rank ?? i + 1}</p>
            </div>
            <div className="ltl-ava">
              <Avatar src={team.imageLink} alt={team.teamName ?? "team-img"} />
            </div>
            <div className="ltl-det">
              {/* <div className="ltld-top">
                <h3>{team.teamName}</h3>
              </div> */}
              <div className="ltld-top">
                {team.teamName !== undefined ? (
                  <h3>{team.teamName}</h3>
                ) : (
                  <>
                    {team.players.map((player, j) => {
                      if (player.name === "Empty") {
                        return null;
                      }

                      return (
                        <div className="ltl-pl" key={player.playerID}>
                          <h3>{player.name}</h3>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="ltl-pts">
              <p>{team.played ?? 0}</p>
            </div>
            <div className="ltl-pts">
              {final ? <p>{team.finalRank}</p> : <p>{team.points ?? 0}</p>}
            </div>
            <div className="ltl-pts">
              <p>{team.wins ?? 0}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LeagueTeamLog;
