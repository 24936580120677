import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import "./MatchPlayTournaments.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// Components
import MatchPlayTournamentEditor from "./MatchPlayTournamentEditor";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PlayerLoader from "../../components/Loaders/PlayerLoader";
import MatchplayGroups from "./MatchplayGroups";
import MatchplayBracket from "./MatchplayBracket";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import Pill from "../../components/Buttons/Pill";
import ManualPlayerAdd from "../../components/Events/EventCreator/ManualPlayerAdd";
import InviteModal from "../../components/Events/InviteModal/InviteModal";
import MatchplayLog from "./MatchplayLog";
import MatchplayTournamentFixtures from "./MatchplayTournamentFixtures";
import MatchplayUpload from "./MatchplayUpload";
import MatchplayTeamManager from "./MatchplayTeamManager";
import MatchplayTeamBracket from "./MatchplayTeamBracket";
// Mui
import MatchplayScore from "../../components/display/MatchplayScore/MatchplayScore";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import BracketDisplay from "./BracketDisplay";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Dialog, Avatar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TodayIcon from "@mui/icons-material/Today";
import InfoIcon from "@mui/icons-material/Info";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ErrorIcon from "@mui/icons-material/Error";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PageHeader from "../../components/display/Rows/PageHeader";
import MatchplayRobins from "./MatchplayRobins";
import BackRow from "../../components/display/Rows/BackRow";
import TeamBracketDisplay from "./TeamBracketDisplay";
import { useNavigate, useParams } from "react-router-dom";
import MatchplayRounds from "./MatchplayRounds";
import { EmojiEvents } from "@mui/icons-material";

const GradientErrorIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorss" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <ErrorIcon
      onClick={onClick}
      className={`red-icon ${className}`}
      sx={{ fill: "url(#linearColorss)" }}
    />
  </>
);
const GradientRemoveCircleIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColorss" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#F0A4AA" />
        <stop offset={5} stopColor="#70242A" />
      </linearGradient>
    </svg>
    <RemoveCircleIcon
      onClick={onClick}
      className={"red-icon"}
      sx={{ fill: "url(#linearColorss)" }}
    />
  </>
);

const GradientEditIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <EditIcon
      onClick={onClick}
      className={`${className} dg-icon-bg`}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

const GradientCircleIcon = ({ onClick, className }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#21c17c" />
        <stop offset={5} stopColor="#1e7a69" />
      </linearGradient>
    </svg>
    <AddCircleIcon
      onClick={onClick}
      className={className}
      sx={{ fill: "url(#linearColors)" }}
    />
  </>
);

function MatchplayTournamentHome({
  // tournID,
  // goBack = () => {},
  reload = () => {},
}) {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const tournID = useParams().id;
  const [details, setDetails] = useState({
    description: "",
    city: "",
    country: "",
    tournamentName: "",
    tournamentType: "",
    imageLink: "",
    fixtures: "",
    bracket: [],
  });

  const { userDetails, currentUser } = useAuth();

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [started, setStarted] = useState(true);
  const [selWinner, setSelWinner] = useState();
  const [error, setError] = useState("");

  const [knockoutManual, setKnockoutManual] = useState(false);

  const [teams, setTeams] = useState([]);

  const [manualBy, setManualBy] = useState(0);
  const [manualRem, setManualRem] = useState(0);

  const [selStart, setSelStart] = useState(null);

  const [starter, setStarter] = useState();
  const [opper, setOpper] = useState();

  const [matches, setMatches] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const [players, setPlayers] = useState([]);
  const [invited, setInvited] = useState([]);
  const [inviteable, setInvitable] = useState([]);
  const [showRemove, setShowRemove] = useState(false);
  const [playerRemove, setPlayerRemove] = useState("");
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const [playedMap, setPlayedMap] = useState({});

  const [groupMap, setGroupMap] = useState({});

  const [doubles, setDoubles] = useState(false);

  const [showAdd, setShowAdd] = useState(false);
  const [showInvite, setShowInvite] = useState(false);

  const [showStart, setShowStart] = useState(false);

  const [confirmStart, setConfirmStart] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [choosing, setChoosing] = useState(false);

  const [loading, setLoading] = useState(true);

  const [changed, setChanged] = useState(false);

  const [showManual, setShowManual] = useState(false);
  const [manChoosing, setManChoosing] = useState(false);
  const [manOne, setManOne] = useState();
  const [manTwo, setManTwo] = useState();

  const [putting, setPutting] = useState(false);

  const [resMap, setResMap] = useState({});

  useEffect(() => {
    if (userDetails && currentUser) {
      loadDetails();
    }
  }, [userDetails, currentUser]);

  async function loadDetails() {
    console.log("LOOOAD");
    setLoading(true);
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tournament = tournDoc.data();
    const {
      tournamentName,
      city,
      country,
      courseName,
      imageLink,
      playerIDs,
      invitedIDs,
      description,
      tournamentType,
      groupSize,
      fixtures,
      stage,
      clubID,
      knockout,
    } = tournament;
    setTeams(tournament.teams ?? []);
    setStarted(tournament.started ?? false);
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const memberIDs = club.memberIDs;
    const bracket = tournament.bracket ?? [];
    const groups = tournament.groups ?? [];
    const results = tournament.results ?? [];

    for (let i = 0; i < groups.length; i++) {
      groups[i].players.sort((a, b) => b.points - a.points);
    }

    console.log(groups);

    if (tournamentType === "knockout") {
      setMatches(bracket);
    }

    if (tournamentType === "tournament" && stage === "knockouts") {
      setMatches(knockout);
    }

    if (tournamentType === "robin") {
      const playedMap = tournament.playedMap ?? {};
      for (let i = 0; i < playerIDs.length; i++) {
        if (playedMap[playerIDs[i] === undefined]) {
          playedMap[playerIDs[i]] = [];
        }
      }
      setPlayedMap(playedMap);
    }

    const groupMap = {};

    if (
      tournamentType === "tournament" &&
      tournament.playerType !== "doubles"
    ) {
      const playedMap = tournament.playedMap ?? {};
      for (let i = 0; i < playerIDs.length; i++) {
        if (playedMap[playerIDs[i] === undefined]) {
          playedMap[playerIDs[i]] = [];
        }
      }

      if (stage === "groups") {
        const pldArr = playedMap[currentUser.uid] ?? [];
        const { groups } = tournament;
        for (let n = 0; n < groups.length; n++) {
          const { players } = groups[n];
          const playerIDs = players.map((pl) => pl.playerID);
          for (let q = 0; q < playerIDs.length; q++) {
            const id = playerIDs[q];
            const temp = [...playerIDs];
            const filt = temp.filter((uid) => uid !== id);
            groupMap[id] = filt;
          }
          const newArr = [];
          for (let x = 0; x < players.length; x++) {
            const player = players[x];
            if (
              !pldArr.includes(player.playerID) &&
              player.playerID !== currentUser.uid
            ) {
              newArr.push(player);
            }
          }
          groups[n].players.sort((a, b) => b.points - a.points);
          // console.log(newArr);
          // tourn.players = newArr;
        }
        console.log(playedMap);
        setPlayedMap(playedMap);
      } else {
      }
    }
    if (
      tournamentType === "tournament" &&
      tournament.playerType === "doubles"
    ) {
      const playedMap = tournament.playedMap ?? {};
      for (let i = 0; i < teams.length; i++) {
        if (playedMap[teams[i].teamID === undefined]) {
          playedMap[teams[i].teamID] = [];
        }
      }
      console.log(playedMap);
      setPlayedMap(playedMap);
      if (stage === "groups") {
        const pldArr = [];
        const groups = tournament.groups ?? [];
        for (let n = 0; n < groups.length; n++) {
          const { players } = groups[n];
          const teamIDs = players.map((pl) => pl.teamID);
          for (let q = 0; q < teamIDs.length; q++) {
            const id = teamIDs[q];
            const temp = [...teamIDs];
            const filt = temp.filter((uid) => uid !== id);
            groupMap[id] = filt;
          }
          const newArr = [];
          for (let x = 0; x < players.length; x++) {
            const player = players[x];
            if (!pldArr.includes(player.teamIDs)) {
              newArr.push(player);
            }
          }
          // console.log(newArr);
          // tourn.players = newArr;
        }
      } else {
      }
    }
    console.log(groupMap);
    setGroupMap(groupMap);
    const combinedIDs = [...playerIDs];

    for (let i = 0; i < invitedIDs.length; i++) {
      if (!combinedIDs.includes(invitedIDs[i])) {
        combinedIDs.push(invitedIDs[i]);
      }
    }
    for (let i = 0; i < userDetails.friends.length; i++) {
      if (!combinedIDs.includes(userDetails.friends[i])) {
        combinedIDs.push(userDetails.friends[i]);
      }
    }
    for (let i = 0; i < memberIDs.length; i++) {
      if (!combinedIDs.includes(memberIDs[i])) {
        combinedIDs.push(memberIDs[i]);
      }
    }

    console.log(combinedIDs);

    // Sort results
    const resMap = {};

    if (tournament.playerType === "doubles") {
      for (let i = 0; i < teams.length; i++) {
        resMap[teams[i].teamID] = [];
      }
    } else {
      for (let i = 0; i < playerIDs.length; i++) {
        resMap[playerIDs[i]] = [];
      }
    }

    if (tournament.playerType === "doubles") {
      for (let i = 0; i < results.length; i++) {
        const res = results[i];
        const { teamOne, teamTwo, by, rem, winner } = res;

        const winnerID = winner.teamID;

        // Player One
        const idOne = teamOne.teamID;
        const idTwo = teamTwo.teamID;

        if (resMap[idOne] === undefined) {
          resMap[idOne] = [];
        }
        if (resMap[idTwo] === undefined) {
          resMap[idTwo] = [];
        }

        let matchRes = "";
        let lossRes = "";
        if (rem > by) {
          matchRes = `${by} & ${rem}`;
          lossRes = `${by} & ${rem}`;
        } else {
          matchRes = `${by} Up`;
          lossRes = `${by} Down`;
        }

        if (winnerID === idOne) {
          resMap[idOne].push({
            text: `Beat ${teamTwo.name} ${matchRes}`,
            type: "win",
          });
          resMap[idTwo].push({
            text: `Lost to ${teamOne.name} ${lossRes}`,
            type: "loss",
          });
        } else if (winnerID === idTwo) {
          resMap[idOne].push({
            text: `Lost to ${teamTwo.name} ${lossRes}`,
            type: "loss",
          });
          resMap[idTwo].push({
            name: `Beat ${teamOne.name} ${matchRes}`,
            type: "win",
          });
        } else {
          resMap[idOne].push({
            text: `Tied with ${teamTwo.name}`,
            type: "tie",
          });
          resMap[idTwo].push({
            text: `Tied with ${teamOne.name}`,
            type: "tie",
          });
        }
      }
    } else {
      for (let i = 0; i < results.length; i++) {
        const res = results[i];
        const { playerOne, playerTwo, by, rem, winner } = res;

        const winnerID = winner.playerID;

        // Player One
        const idOne = playerOne.playerID;
        const idTwo = playerTwo.playerID;

        let matchRes = "";
        let lossRes = "";
        if (rem > by) {
          matchRes = `${by} & ${rem}`;
          lossRes = `${by} & ${rem}`;
        } else {
          matchRes = `${by} Up`;
          lossRes = `${by} Down`;
        }

        if (winnerID === idOne) {
          resMap[idOne].push({
            text: `Beat ${playerTwo.name} ${matchRes}`,
            type: "win",
          });
          resMap[idTwo].push({
            text: `Lost to ${playerOne.name} ${lossRes}`,
            type: "loss",
          });
        } else if (winnerID === idTwo) {
          resMap[idOne].push({
            text: `Lost to ${playerTwo.name} ${lossRes}`,
            type: "loss",
          });
          resMap[idTwo].push({
            name: `Beat ${playerOne.name} ${matchRes}`,
            type: "win",
          });
        } else {
          resMap[idOne].push({
            text: `Tied with ${playerTwo.name}`,
            type: "tie",
          });
          resMap[idTwo].push({
            text: `Tied with ${playerOne.name}`,
            type: "tie",
          });
        }
      }
    }

    console.log(resMap);
    setResMap(resMap);

    const playerRefs = combinedIDs.map((playerID) =>
      db.collection("users").doc(playerID).get()
    );

    const inv = [];
    const pla = [];
    const inviteableArr = [];

    Promise.all(playerRefs).then((docs) => {
      for (let i = 0; i < docs.length; i++) {
        const playerDoc = docs[i];
        const playerID = playerDoc.id;
        const player = playerDoc.data();
        if (!playerDoc.exists) {
          continue;
        }
        const { firstName, lastName, handicap, profileImage } = player;

        if (playerIDs.includes(playerID)) {
          pla.push({
            name: `${firstName} ${lastName}`,
            handicap,
            profileImage,
            playerID,
            label: `${firstName} ${lastName}`,
            value: playerID,
            firstName,
            lastName,
          });
        } else if (invitedIDs.includes(playerID)) {
          inv.push({
            name: `${firstName} ${lastName}`,
            handicap,
            profileImage,
            playerID,
            label: `${firstName} ${lastName}`,
            value: playerID,
            firstName,
            lastName,
          });
        } else {
          inviteableArr.push({
            name: `${firstName} ${lastName}`,
            handicap,
            profileImage,
            playerID,
            label: `${firstName} ${lastName}`,
            value: playerID,
            firstName,
            lastName,
          });
        }
      }
      if (
        !playerIDs.includes(currentUser.uid) &&
        !invitedIDs.includes(currentUser.uid) &&
        !combinedIDs.includes(currentUser.uid)
      ) {
        inviteableArr.push({
          name: `${userDetails.firstName} ${userDetails.lastName}`,
          label: `${userDetails.firstName} ${userDetails.lastName}`,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          handicap: userDetails.handicap,
          profileImage: userDetails.profileImage,
          playerID: currentUser.uid,
          value: currentUser.uid,
        });
      }
      // console.log(inviteableArr);
      setInvited(inv);
      setInvitable(inviteableArr);
      setPlayers(pla);
      setDoubles(tournament.playerType === "doubles");
      setDetails({
        courseName: courseName ?? "",
        tournamentName,
        city,
        bracket,
        country,
        imageLink,
        description,
        tournamentType,
        fixtures,
        knockout,
        groups,
        groupSize,
        playerType: tournament.playerType ?? "singles",
        stage: tournament.stage,
        groupMap,
        clubID,
      });
      setLoading(false);
    });
  }

  async function saveInvites() {
    setLoading(true);
    const invitedIDs = invited.map((player) => player.playerID);
    // const players =
    await db.collection("tournaments").doc(tournID).update({
      invitedIDs,
    });
    setLoading(false);
  }

  async function removePlayer(playerID) {
    setLoading(true);
    setShowRemove(false);
    const bracks = [...details.bracket];
    if (bracks.length > 0) {
      for (let i = 0; i < bracks[0].matches.length; i++) {
        const match = bracks[0].matches[i];
        if (match.players[0].playerID === playerID) {
          bracks[0].matches[i].players[0] = {
            name: "",
          };
        }
        if (match.players[1].playerID === playerID) {
          bracks[0].matches[i].players[1] = {
            name: "",
          };
        }
      }
    }
    // return console.log(bracks);
    await db
      .collection("tournaments")
      .doc(tournID)
      .update({
        playerIDs: admin.firestore.FieldValue.arrayRemove(playerID),
        bracket: bracks,
      });
    setPlayers((current) =>
      current.filter((player) => player.playerID !== playerID)
    );
    setLoading(false);
  }

  async function startTournament() {
    setConfirmStart(false);
    setLoading(true);
    await db.collection("tournaments").doc(tournID).update({
      started: true,
    });

    loadDetails();
  }

  async function cancelTournament() {
    setShowDelete(false);
    setLoading(true);
    await db.collection("tournaments").doc(tournID).delete();
    reload();
    goBack();
  }

  async function startMatch() {
    setLoading(true);
    setShowStart(false);

    if (details.tournamentType === "knockout") {
      await db.collection("triggers").add({
        type: "match-play",
        matchIndex: selStart,
        tournamentID: tournID,
        tournamentType: "knockout",
        dateAdded: new Date(),
      });
    } else if (
      details.tournamentType === "robin" ||
      (details.tournamentType === "tournament" && details.stage === "groups")
    ) {
      await db.collection("triggers").add({
        type: "match-play",
        players: [starter, opper],
        tournamentID: tournID,
        tournamentType: "robin",
        dateAdded: new Date(),
      });
    } else if (
      details.tournamentType === "tournament" &&
      details.stage === "knockouts"
    ) {
      await db.collection("triggers").add({
        type: "match-play",
        tournamentID: tournID,
        tournamentType: "tournament",
        stage: "knockout",
        matchIndex: selStart,
        dateAdded: new Date(),
      });
    }
    loadDetails();
    setSelStart(null);
    setStarter();
    setOpper();
    setLoading(false);
  }

  function returnTeamMatch() {
    if (selStart[0] === undefined) {
      return console.log("ret");
    }
    const i1 = selStart[0];
    const i2 = selStart[1];
    const round = matches[i1];
    const match = round.matches[i2];
    const teams = match.teams ?? match.players;

    const t1p1 = teams[0].players[0];
    const t1p2 = teams[0].players[1];
    const t2p1 = teams[1].players[0];
    const t2p2 = teams[1].players[1];

    const t1 = `${t1p1.name} / ${t1p2.name}`;
    const t2 = `${t2p1.name} / ${t2p2.name}`;

    return (
      <div className="mpb-match mpmb-full" onClick={() => setSelStart(null)}>
        <PlayerItem
          hideRemove
          hideEdit={true}
          hideImage
          showAdd
          hideHCP
          hideMessage
          hideProfile
          noLink
          editAction={() => {}}
          name={t1}
        />
        <div className="mpbm-circle">
          <p>vs</p>
        </div>

        <PlayerItem
          hideRemove
          hideImage
          hideEdit={true}
          hideHCP
          showAdd
          hideMessage
          hideProfile
          noLink
          name={t2}
          editAction={() => {}}
        />
      </div>
    );
  }

  function returnMatch() {
    if (selStart[0] === undefined) {
      return console.log("ret");
    }
    const i1 = selStart[0];
    const i2 = selStart[1];
    const round = matches[i1];
    const match = round.matches[i2];
    const p1 = match.players[0];
    const p2 = match.players[1];
    return (
      <div className="mpb-match mpmb-full" onClick={() => setSelStart(null)}>
        <PlayerItem
          hideRemove
          hideEdit={true}
          hcp={p1.handicap}
          hideImage
          showAdd
          hideHCP={isNaN(p1.handicap)}
          hideMessage
          hideProfile
          noLink
          editAction={() => {}}
          name={p1.name}
        />
        <div className="mpbm-circle">
          {match.winnerID === "" ? (
            <p>vs</p>
          ) : (
            <>
              {match.winnerID === p1.playerID ? (
                <p>{match.by} UP</p>
              ) : (
                <p>{match.by} DN</p>
              )}
            </>
          )}
        </div>

        <PlayerItem
          hideRemove
          hcp={p2.handicap}
          hideImage
          hideEdit={true}
          hideHCP={isNaN(p2.handicap)}
          showAdd
          hideMessage
          hideProfile
          noLink
          name={p2.name}
          editAction={() => {}}
        />
      </div>
    );
  }

  async function manualFinish({ players = [], roundID = "" }) {
    setLoading(true);
    setShowManual(false);
    try {
      const tournDoc = await db.collection("tournaments").doc(tournID).get();
      const tourn = tournDoc.data();

      const { table, pointsType } = tourn;

      const playedMap = tourn.playedMap ?? {};

      if (doubles) {
        const t1ID = players[0].teamID;
        const t2ID = players[1].teamID;
        if (playedMap[t1ID]) {
          playedMap[t1ID].push(t2ID);
        } else {
          playedMap[t1ID] = [t2ID];
        }

        if (playedMap[t2ID]) {
          playedMap[t2ID].push(t1ID);
        } else {
          playedMap[t2ID] = [t1ID];
        }

        if (selWinner.name === "tie") {
          for (let x = 0; x < table.length; x++) {
            if (table[x].teamID === t1ID || table[x].teamID === t2ID) {
              table[x].played += 1;
              if (pointsType === "normal") {
                table[x].points += 5;
              }
              table[x].tied ? (table[x].tied += 1) : (table[x].tied = 1);
            }
          }
        } else {
          const loserID = selWinner.teamID === t1ID ? t2ID : t1ID;
          for (let i = 0; i < table.length; i++) {
            if (table[i].teamID === loserID) {
              table[i].played += 1;
            }
            if (table[i].teamID === selWinner.teamID) {
              if (pointsType === "normal") {
                table[i].points += 10;
                table[i].played += 1;
              } else {
                table[i].points += manualBy;
              }
              table[i].wins += 1;
            }
          }
        }

        const res = {
          date: new Date(),
          teamOne: players[0],
          teamTwo: players[1],
          by: manualBy,
          winner: selWinner,
          rem: manualRem,
          roundID,
        };
        // console.log(table, res, playedMap);
        // return setLoading(false);

        await tournDoc.ref.update({
          results: admin.firestore.FieldValue.arrayUnion(res),
          table,
          playedMap,
        });
      } else {
        const p1ID = players[0].playerID;
        const p2ID = players[1].playerID;

        if (playedMap[p1ID]) {
          playedMap[p1ID].push(p2ID);
        } else {
          playedMap[p1ID] = [p2ID];
        }

        if (playedMap[p2ID]) {
          playedMap[p2ID].push(p1ID);
        } else {
          playedMap[p2ID] = [p1ID];
        }

        if (selWinner.name === "tie") {
          for (let i = 0; i < players.length; i++) {
            const player = players[i];
            const playerID = player.playerID;
            for (let x = 0; x < table.length; x++) {
              if (table[x].playerID === playerID) {
                table[x].played += 1;
                if (pointsType === "normal") {
                  table[x].points += 10;
                }
                table[x].tied ? (table[x].tied += 1) : (table[x].tied = 1);
              }
            }
          }
        } else {
          for (let i = 0; i < players.length; i++) {
            const player = players[i];
            const playerID = player.playerID;
            for (let x = 0; x < table.length; x++) {
              if (table[x].playerID === playerID) {
                if (selWinner.playerID === playerID) {
                  table[x].played += 1;
                  if (pointsType === "normal") {
                    table[x].points += 10;
                  } else {
                    table[x].points += manualBy;
                  }
                  table[x].wins += 1;
                } else {
                  table[x].played += 1;
                }
              }
            }
          }
        }

        const res = {
          date: new Date(),
          playerOne: players[0],
          playerTwo: players[1],
          by: manualBy,
          winner: selWinner,
          rem: manualRem,
          roundID,
        };

        // console.log(playedMap);

        // return setLoading(false);

        await tournDoc.ref.update({
          results: admin.firestore.FieldValue.arrayUnion(res),
          table,
          playedMap,
        });

        if (roundID !== "") {
          await db.collection("users").doc(players[0].playerID).update({
            activeRoundID: "",
          });
          await db.collection("users").doc(players[1].playerID).update({
            activeRoundID: "",
          });
          const rd = await db.collection("rounds").doc(roundID).get();
          const rdd = rd.data();
          const { leaderboard } = rdd;
          leaderboard.completed = true;
          leaderboard.manual = true;
          leaderboard.by = manualBy;
          leaderboard.thru = 18 - manualRem;
          leaderboard.leader = selWinner.playerID;
          await rd.ref.update({
            leaderboard,
          });
        }
      }
      setManChoosing(false);
      setManOne();
      setManTwo();
      setManualBy(0);
      setManualRem(0);
      setSelWinner();
      setPutting(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
    loadDetails();
  }

  async function manualGroupFinish({ players = [], roundID = "" }) {
    setLoading(true);
    setShowManual(false);
    try {
      const tournDoc = await db.collection("tournaments").doc(tournID).get();
      const tourn = tournDoc.data();
      const { pointsType, groups } = tourn;

      const playedMap = tourn.playedMap ?? {};

      const playerOne = players[0];
      const playerTwo = players[1];

      const p1ID = players[0].playerID;
      const p2ID = players[1].playerID;

      if (playedMap[p1ID]) {
        playedMap[p1ID].push(p2ID);
      } else {
        playedMap[p1ID] = [p2ID];
      }

      if (playedMap[p2ID]) {
        playedMap[p2ID].push(p1ID);
      } else {
        playedMap[p2ID] = [p1ID];
      }

      const res = {
        date: new Date(),
        playerOne: players[0],
        playerTwo: players[1],
        by: manualBy,
        winner: selWinner,
        rem: manualRem,
        roundID,
      };

      let tie = false;
      let loserID = "";
      if (playerOne.playerID === selWinner.playerID) {
        loserID = playerTwo.playerID;
      } else if (playerTwo.playerID === selWinner.playerID) {
        loserID = playerOne.playerID;
      } else {
        tie = true;
      }

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        const { players } = group;
        const playerIDs = players.map((player) => player.playerID);

        if (!playerIDs.includes(p1ID) && !playerIDs.includes(p2ID)) {
          continue;
        }
        if (tie) {
          for (let x = 0; x < players.length; x++) {
            if (
              players[x].playerID === playerOne.playerID ||
              players[x].playerID === playerTwo.playerID
            ) {
              if (pointsType === "normal") {
                groups[i].players[x].points += 5;
              }
              groups[i].players[x].played += 1;
              groups[i].players[x].ties += 1;
            }
          }
        } else {
          for (let x = 0; x < players.length; x++) {
            if (players[x].playerID === selWinner.playerID) {
              groups[i].players[x].played += 1;
              groups[i].players[x].wins += 1;
              if (pointsType === "normal") {
                groups[i].players[x].points += 10;
              } else {
                groups[i].players[x].points += parseInt(manualBy);
              }
            }
            if (players[x].playerID === loserID) {
              groups[i].players[x].played += 1;
            }
          }
        }
      }

      await tournDoc.ref.update({
        results: admin.firestore.FieldValue.arrayUnion(res),
        groups,
        playedMap,
      });

      if (roundID !== "") {
        await db.collection("users").doc(players[0].playerID).update({
          activeRoundID: "",
        });
        await db.collection("users").doc(players[1].playerID).update({
          activeRoundID: "",
        });
        const rd = await db.collection("rounds").doc(roundID).get();
        const rdd = rd.data();
        const { leaderboard } = rdd;
        leaderboard.completed = true;
        leaderboard.manual = true;
        leaderboard.by = parseInt(manualBy);
        leaderboard.thru = 18 - manualRem;
        leaderboard.leader = selWinner.playerID;
        await rd.ref.update({
          leaderboard,
        });
      }
      setManChoosing(false);
      setManOne();
      setManTwo();
      setManualBy(0);
      setManualRem(0);
      setSelWinner();
      setPutting(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
    loadDetails();
  }
  async function manualTeamGroupFinish({ players = [], roundID = "" }) {
    setLoading(true);
    setShowManual(false);
    try {
      const tournDoc = await db.collection("tournaments").doc(tournID).get();
      const tourn = tournDoc.data();
      const { pointsType, groups } = tourn;

      const playedMap = tourn.playedMap ?? {};

      const teamOne = players[0];
      const teamTwo = players[1];

      const t1ID = players[0].teamID;
      const t2ID = players[1].teamID;

      if (playedMap[t1ID]) {
        playedMap[t1ID].push(t2ID);
      } else {
        playedMap[t1ID] = [t2ID];
      }

      if (playedMap[t2ID]) {
        playedMap[t2ID].push(t1ID);
      } else {
        playedMap[t2ID] = [t1ID];
      }

      const res = {
        date: new Date(),
        teamOne: players[0],
        teamTwo: players[1],
        by: manualBy,
        winner: selWinner,
        rem: manualRem,
        roundID,
      };

      let tie = false;
      let loserID = "";
      if (teamOne.teamID === selWinner.teamID) {
        loserID = teamTwo.teamID;
      } else if (teamTwo.teamID === selWinner.teamID) {
        loserID = teamOne.teamID;
      } else {
        tie = true;
      }

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        const { players } = group;
        const teamIDs = players.map((player) => player.teamID);

        if (!teamIDs.includes(t1ID) && !teamIDs.includes(t2ID)) {
          continue;
        }
        if (tie) {
          for (let x = 0; x < players.length; x++) {
            if (
              players[x].teamID === teamOne.teamID ||
              players[x].teamID === teamTwo.teamID
            ) {
              if (pointsType === "normal") {
                groups[i].players[x].points += 5;
              }
              groups[i].players[x].played += 1;
              groups[i].players[x].ties += 1;
            }
          }
        } else {
          for (let x = 0; x < players.length; x++) {
            if (players[x].teamID === selWinner.teamID) {
              groups[i].players[x].played += 1;
              groups[i].players[x].wins += 1;
              if (pointsType === "normal") {
                groups[i].players[x].points += 10;
              } else {
                groups[i].players[x].points += parseInt(manualBy);
              }
            }
            if (players[x].teamID === loserID) {
              groups[i].players[x].played += 1;
            }
          }
        }
      }
      console.log(res);
      // console.log(groups);
      // console.log(playedMap);
      // return setLoading(false);

      await tournDoc.ref.update({
        results: admin.firestore.FieldValue.arrayUnion(res),
        groups,
        playedMap,
      });

      if (roundID !== "") {
        await db.collection("users").doc(players[0].playerID).update({
          activeRoundID: "",
        });
        await db.collection("users").doc(players[1].playerID).update({
          activeRoundID: "",
        });
        const rd = await db.collection("rounds").doc(roundID).get();
        const rdd = rd.data();
        const { leaderboard } = rdd;
        leaderboard.completed = true;
        leaderboard.manual = true;
        leaderboard.by = parseInt(manualBy);
        leaderboard.thru = 18 - manualRem;
        leaderboard.leader = selWinner.playerID;
        await rd.ref.update({
          leaderboard,
        });
      }
      setManChoosing(false);
      setManOne();
      setManTwo();
      setManualBy(0);
      setManualRem(0);
      setSelWinner();
      setPutting(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
    loadDetails();
  }

  async function manualKnockoutFinish() {
    setLoading(true);
    setKnockoutManual(false);
    const i1 = selStart[0];
    const i2 = selStart[1];

    const tournamentDoc = await db.collection("tournaments").doc(tournID).get();
    const tournDoc = tournamentDoc.data();

    const { knockout, bracket } = tournDoc;
    try {
      if (details.tournamentType === "tournament") {
        if (doubles) {
          const match = knockout[i1].matches[i2];
          match.winner = selWinner;
          match.completed = true;
          match.by = manualBy;
          match.rem = manualRem;
          match.winnerID = selWinner.teamID;
          match.winnerName = selWinner.name;
          const { matchIndex } = match;
          if (i1 !== knockout.length) {
            let found = false;
            const nextRound = knockout[i1 + 1];
            for (let i = 0; i < nextRound.matches.length; i++) {
              if (found) {
                continue;
              }
              const match = nextRound.matches[i];
              if (match.qualis.includes(matchIndex)) {
                if (matchIndex === match.qualis[0]) {
                  match.players[0] = selWinner;
                } else {
                  match.players[1] = selWinner;
                }
                found = true;
                knockout[i1 + 1].matches[i] = match;
              }
            }
          } else {
            await tournamentDoc.ref.update({
              winnerID: selWinner.playerID,
              winnerName: selWinner.name,
              winner: selWinner,
              completed: true,
            });
          }
          knockout[i1].matches[i2] = match;
        } else {
          const match = knockout[i1].matches[i2];
          match.winner = selWinner;
          match.completed = true;
          match.by = manualBy;
          match.rem = manualRem;
          match.winnerID = selWinner.playerID;
          match.winnerName = selWinner.name;
          const { matchIndex } = match;
          if (i1 !== knockout.length - 1) {
            let found = false;
            const nextRound = knockout[i1 + 1];
            for (let i = 0; i < nextRound.matches.length; i++) {
              if (found) {
                continue;
              }
              const match = nextRound.matches[i];
              if (match.qualis.includes(matchIndex)) {
                if (matchIndex === match.qualis[0]) {
                  match.players[0] = selWinner;
                } else {
                  match.players[1] = selWinner;
                }
                found = true;
                knockout[i1 + 1].matches[i] = match;
              }
            }
          } else {
            await tournamentDoc.ref.update({
              winnerID: selWinner.playerID,
              winnerName: selWinner.name,
              winner: selWinner,
              completed: true,
            });
          }
          knockout[i1].matches[i2] = match;
        }
        // console.log(knockout);
        // return setLoading(false);
        await tournamentDoc.ref.update({
          knockout,
        });
      } else {
        const match = bracket[i1].matches[i2];
        match.winner = selWinner;
        match.completed = true;
        match.by = manualBy;
        match.rem = manualRem;
        match.winnerID = selWinner.playerID;
        match.winnerName = selWinner.name;
        bracket[i1].matches[i2] = match;
        await tournamentDoc.ref.update({
          bracket,
        });
      }
      setManOne();
      setManTwo();
      setSelStart(null);
      setPutting(false);
      loadDetails();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="mpl-home event-home player-round onboarding">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      {showEdit ? (
        <MatchPlayTournamentEditor
          goBack={() => setShowEdit(false)}
          edit={true}
          reload={loadDetails}
          tournID={tournID}
        />
      ) : show ? (
        toShow
      ) : (
        <>
          {showInvite && (
            <InviteModal
              tournamentID={tournID}
              defaultSelected={invited}
              defaultPlayers={inviteable}
              hide={() => setShowInvite(false)}
              reload={loadDetails}
            />
          )}
          {showAdd && (
            <ManualPlayerAdd
              tournamentID={tournID}
              hide={() => setShowAdd(false)}
              reload={loadDetails}
              players={inviteable}
            />
          )}

          <Dialog open={confirmStart} onClose={() => setConfirmStart(false)}>
            <div className="delete-popup">
              <PageHeader
                showClose
                close={() => setConfirmStart(false)}
                text="Start Tournament"
              />
              <p>
                Are you sure you want to start this tournament? Players cannot
                be added once the tournament has started
              </p>
              <div className="flex-center">
                <button onClick={startTournament} className="default-button">
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>

          <Dialog
            className="mp-ma-dia"
            open={knockoutManual}
            onClose={() => setKnockoutManual(false)}
          >
            <div className="mp-start-dia">
              <PageHeader
                showClose
                text="Input Result"
                close={() => {
                  setKnockoutManual(false);
                }}
              />
              <div className="ph-msg">
                <p>Click on a match to select or deselect</p>
              </div>

              {putting ? (
                <>
                  <BackRow
                    action={() => {
                      setManOne();
                      setManTwo();
                      setSelStart(null);
                      setPutting(false);
                    }}
                  />
                  <div className="flex-center matchbox mt-10">
                    <div className="mpb-match">
                      {doubles ? (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manOne &&
                            selWinner.teamID === manOne.teamID
                          }
                          showProfile={() => setSelWinner(manOne)}
                          hideRemove
                          hideImage
                          hideHCP
                          showAdd
                          hideMessage
                          hideProfile
                          noLink
                          hideIcons
                          name={manOne.name}
                        />
                      ) : (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manOne &&
                            selWinner.playerID === manOne.playerID
                          }
                          showProfile={() => setSelWinner(manOne)}
                          hideRemove
                          hcp={manOne.handicap}
                          hideImage
                          hideHCP
                          showAdd
                          hideMessage
                          hideProfile
                          noLink
                          hideIcons
                          name={manOne.name}
                        />
                      )}
                      <div className="mp-cir-box">
                        <div className="mpbm-circle">
                          <p>vs</p>
                        </div>
                      </div>

                      {doubles ? (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manTwo &&
                            selWinner.teamID === manTwo.teamID
                          }
                          showProfile={() => {
                            console.log(manOne, manTwo, selWinner);
                            setSelWinner(manTwo);
                          }}
                          hideRemove
                          hideImage
                          hideIcons
                          showAdd
                          hideHCP
                          hideMessage
                          hideProfile
                          noLink
                          name={manTwo.name}
                        />
                      ) : (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manTwo &&
                            selWinner.playerID === manTwo.playerID
                          }
                          showProfile={() => setSelWinner(manTwo)}
                          hideRemove
                          hideImage
                          hideIcons
                          showAdd
                          hideHCP
                          hideMessage
                          hideProfile
                          noLink
                          name={manTwo.name}
                        />
                      )}
                    </div>
                  </div>

                  <div className="mp-man-holes">
                    <div className="input-group">
                      <p>Holes Up</p>
                      <input
                        type="number"
                        disabled={selWinner && selWinner.name === "Tie"}
                        onChange={(e) => setManualBy(e.target.value)}
                        value={manualBy}
                        className="default-input"
                      />
                    </div>
                    <div className="input-group">
                      <p>Holes Remaining</p>
                      <input
                        type="number"
                        disabled={selWinner && selWinner.name === "Tie"}
                        onChange={(e) => setManualRem(e.target.value)}
                        value={manualRem}
                        className="default-input"
                      />
                    </div>
                  </div>
                  <div className="flex-center mb-20">
                    <button
                      disabled={
                        selWinner === undefined ||
                        manualRem > manualBy ||
                        (manualBy === 0 &&
                          manualRem === 0 &&
                          selWinner.name !== "Tie")
                      }
                      className="default-button"
                      onClick={() => {
                        manualKnockoutFinish();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="mp-start">
                    {!putting &&
                      !doubles &&
                      !loading &&
                      matches.map((round, index) => {
                        // console.log(index);
                        const roundMatches = round.matches;

                        let corr = false;

                        for (let i = 0; i < roundMatches.length; i++) {
                          const match = roundMatches[i];
                          const { players } = match;
                          if (
                            players[0].playerID !== undefined &&
                            players[1].playerID !== undefined
                          ) {
                            corr = true;
                          }
                        }

                        if (corr === false) {
                          return null;
                        }

                        return (
                          <div key={`${index}edit`} className="mpl-edit">
                            <div className="mpl-round-header">
                              {index === matches.length - 1 ? (
                                <h2>Final</h2>
                              ) : (
                                <h2>Round {index + 1}</h2>
                              )}
                            </div>
                            <div className="mpl-matches">
                              {roundMatches.map((match, index2) => {
                                const p1 = match.players[0];
                                const p2 = match.players[1];
                                if (match.started || match.completed) {
                                  return null;
                                }
                                if (p1.name === "") {
                                  p1.name = "BYE";
                                }

                                if (p2.name === "") {
                                  p2.name = "BYE";
                                }

                                if (match.players.length === 0) {
                                  return (
                                    <div className="mpl-mps">
                                      <p>Match {match.match}</p>{" "}
                                      <MatchplayScore hideThru preMatch />
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className="mpb-match mpb-match-small"
                                    onClick={() => {
                                      setManOne(p1);
                                      setManTwo(p2);
                                      setPutting(true);
                                      setSelStart([index, index2]);
                                    }}
                                  >
                                    <PlayerItem
                                      hideRemove
                                      hideEdit={true}
                                      hcp={p1.handicap}
                                      hideImage
                                      showAdd
                                      hideHCP={isNaN(p1.handicap)}
                                      hideMessage
                                      hideProfile
                                      noLink
                                      editAction={() => {}}
                                      name={p1.name}
                                    />
                                    <div className="mpbm-circle">
                                      {match.winnerID === "" ? (
                                        <p>vs</p>
                                      ) : (
                                        <>
                                          {match.winnerID === p1.playerID ? (
                                            <p>{match.by} UP</p>
                                          ) : (
                                            <p>{match.by} DN</p>
                                          )}
                                        </>
                                      )}
                                    </div>

                                    <PlayerItem
                                      hideRemove
                                      hcp={p2.handicap}
                                      hideImage
                                      hideEdit={true}
                                      hideHCP={isNaN(p2.handicap)}
                                      showAdd
                                      hideMessage
                                      hideProfile
                                      noLink
                                      name={p2.name}
                                      editAction={() => {}}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    {!putting &&
                      doubles &&
                      !loading &&
                      matches.map((round, index) => {
                        // console.log(round);
                        // return null;
                        const roundMatches = round.matches;

                        let corr = false;

                        for (let i = 0; i < roundMatches.length; i++) {
                          const match = roundMatches[i];
                          const { players } = match;
                          if (!players) {
                            continue;
                          }
                          if (
                            players[0].teamID !== undefined &&
                            players[1].teamID !== undefined &&
                            !match.completed
                          ) {
                            corr = true;
                          }
                        }

                        if (corr === false) {
                          return null;
                        }

                        return (
                          <div key={`${index}edit`} className="mpl-edit">
                            <div className="mpl-round-header">
                              {index === matches.length - 1 ? (
                                <h2>Final</h2>
                              ) : (
                                <h2>Round {index + 1}</h2>
                              )}
                            </div>
                            <div className="mpl-matches">
                              {roundMatches.map((match, index2) => {
                                const p1 = match.players[0];
                                const p2 = match.players[1];
                                if (match.started || match.completed) {
                                  return null;
                                }
                                if (p1.name === "") {
                                  p1.name = "BYE";
                                }

                                if (p2.name === "") {
                                  p2.name = "BYE";
                                }

                                if (match.players.length === 0) {
                                  return (
                                    <div className="mpl-mps">
                                      <p>Match {match.match}</p>{" "}
                                      <MatchplayScore hideThru preMatch />
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className="mpb-match mpb-match-small"
                                    onClick={() => {
                                      setManOne(p1);
                                      setManTwo(p2);
                                      setPutting(true);
                                      setSelStart([index, index2]);
                                    }}
                                  >
                                    <PlayerItem
                                      hideRemove
                                      hideEdit={true}
                                      hcp={p1.handicap}
                                      hideImage
                                      showAdd
                                      hideHCP={isNaN(p1.handicap)}
                                      hideMessage
                                      hideProfile
                                      noLink
                                      editAction={() => {}}
                                      name={p1.name}
                                    />
                                    <div className="mpbm-circle">
                                      {match.winnerID === "" ? (
                                        <p>vs</p>
                                      ) : (
                                        <>
                                          {match.winnerID === p1.playerID ? (
                                            <p>{match.by} UP</p>
                                          ) : (
                                            <p>{match.by} DN</p>
                                          )}
                                        </>
                                      )}
                                    </div>

                                    <PlayerItem
                                      hideRemove
                                      hcp={p2.handicap}
                                      hideImage
                                      hideEdit={true}
                                      hideHCP={isNaN(p2.handicap)}
                                      showAdd
                                      hideMessage
                                      hideProfile
                                      noLink
                                      name={p2.name}
                                      editAction={() => {}}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </Dialog>

          <Dialog
            className="mp-ma-dia"
            open={showStart}
            onClose={() => {
              setStarter();
              setOpper();
              setChoosing(false);
              setShowStart(false);
            }}
          >
            <div className="mp-start-dia">
              <PageHeader
                showClose
                close={() => {
                  setStarter();
                  setOpper();
                  setChoosing(false);
                  setShowStart(false);
                }}
                text="Start Match"
              />
              <div className="ph-msg">
                <p>Click on a match/player to select or deselect</p>
              </div>
              <div className="mp-start">
                {(details.tournamentType === "knockout" ||
                  (details.tournamentType === "tournament" &&
                    details.stage === "knockouts")) && (
                  <>
                    {selStart === null &&
                      !loading &&
                      !doubles &&
                      matches.map((round, index) => {
                        const roundMatches = round.matches;

                        let corr = false;

                        for (let i = 0; i < roundMatches.length; i++) {
                          const match = roundMatches[i];
                          const { players } = match;
                          if (
                            players[0].playerID !== undefined &&
                            players[1].playerID !== undefined
                          ) {
                            corr = true;
                          }
                        }

                        if (corr === false) {
                          return null;
                        }

                        return (
                          <div className="mpl-edit">
                            <div className="mpl-round-header">
                              {index === matches.length - 1 ? (
                                <h2>Final</h2>
                              ) : (
                                <h2>Round {index + 1}</h2>
                              )}
                            </div>
                            <div className="mpl-matches">
                              {roundMatches.map((match, index2) => {
                                const p1 = match.players[0];
                                const p2 = match.players[1];
                                if (match.started || match.completed) {
                                  return null;
                                }
                                if (p1.name === "") {
                                  p1.name = "BYE";
                                }

                                if (p2.name === "") {
                                  p2.name = "BYE";
                                }

                                if (match.players.length === 0) {
                                  return (
                                    <div className="mpl-mps">
                                      <p>Match {match.match}</p>{" "}
                                      <MatchplayScore hideThru preMatch />
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className="mpb-match mpb-match-small"
                                    onClick={() => setSelStart([index, index2])}
                                  >
                                    <PlayerItem
                                      hideRemove
                                      hideEdit={true}
                                      hcp={p1.handicap}
                                      hideImage
                                      showAdd
                                      hideHCP={isNaN(p1.handicap)}
                                      hideMessage
                                      hideProfile
                                      noLink
                                      editAction={() => {}}
                                      name={p1.name}
                                    />
                                    <div className="mpbm-circle">
                                      {match.winnerID === "" ? (
                                        <p>vs</p>
                                      ) : (
                                        <>
                                          {match.winnerID === p1.playerID ? (
                                            <p>{match.by} UP</p>
                                          ) : (
                                            <p>{match.by} DN</p>
                                          )}
                                        </>
                                      )}
                                    </div>

                                    <PlayerItem
                                      hideRemove
                                      hcp={p2.handicap}
                                      hideImage
                                      hideEdit={true}
                                      hideHCP={isNaN(p2.handicap)}
                                      showAdd
                                      hideMessage
                                      hideProfile
                                      noLink
                                      name={p2.name}
                                      editAction={() => {}}
                                    />
                                  </div>
                                );
                                return (
                                  <div className="mpl-mps">
                                    <p>Match {match.match}</p>
                                    <div className="mpl-mpc">
                                      <GradientEditIcon
                                        onClick={() => {}}
                                        className={"mpl-mpc-icon mr-10"}
                                      />
                                      <MatchplayScore
                                        hcpOne={p1.handicap}
                                        hideThru
                                        preMatch
                                        hcpTwo={p2.handicap}
                                        nameOne={p1.name}
                                        nameTwo={p2.name}
                                      />
                                      <GradientEditIcon
                                        onClick={() => {}}
                                        className={"mpl-mpc-icon ml-10"}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    {selStart === null &&
                      !loading &&
                      doubles &&
                      matches.map((round, index) => {
                        const roundMatches = round.matches;
                        let corr = false;
                        let roundComp = true;

                        for (let i = 0; i < roundMatches.length; i++) {
                          const match = roundMatches[i];
                          const { teams } = match;
                          if (match.completed !== true) {
                            roundComp = false;
                          }
                          if (details.tournamentType === "robin") {
                            if (teams[0].players[0].name.includes("inner")) {
                              corr = true;
                            }
                          }
                          if (details.tournamentType === "knockout") {
                            if (teams[0].players[0].name) {
                              if (teams[0].players[0].name.includes("inner")) {
                                corr = true;
                              }
                            }
                            if (teams[1].players[0].name) {
                              if (teams[1].players[0].name.includes("inner")) {
                                corr = true;
                              }
                            }
                          }
                          if (details.tournamentType === "tournament") {
                            if (
                              match.players[0].name.includes("inner") ||
                              match.players[1].name.includes("inner")
                            ) {
                              corr = true;
                            }
                          }
                        }

                        if (corr === true || roundComp === true) {
                          return null;
                        }

                        return (
                          <div className="mpl-edit">
                            <div className="mpl-round-header">
                              {index === matches.length - 1 ? (
                                <h2>Final</h2>
                              ) : (
                                <h2>Round {index + 1}</h2>
                              )}
                            </div>
                            <div className="mpl-matches">
                              {details.tournamentType === "tournament" &&
                                roundMatches.map((match, index2) => {
                                  console.log(match);
                                  const { players } = match;

                                  const t1p1 = players[0].players[0];
                                  const t1p2 = players[0].players[1];
                                  const t2p1 = players[1].players[0];
                                  const t2p2 = players[1].players[1];

                                  const t1 = `${t1p1.name} / ${t1p2.name}`;
                                  const t2 = `${t2p1.name} / ${t2p2.name}`;

                                  if (match.completed || match.roundID) {
                                    return null;
                                  }

                                  // if (match.players.length === 0) {
                                  //   return (
                                  //     <div className="mpl-mps">
                                  //       <p>Match {match.match}</p>{" "}
                                  //       <MatchplayScore hideThru preMatch />
                                  //     </div>
                                  //   );
                                  // }
                                  return (
                                    <div
                                      className="mpb-match mpb-match-small"
                                      onClick={() =>
                                        setSelStart([index, index2])
                                      }
                                    >
                                      <PlayerItem
                                        hideRemove
                                        hideEdit={true}
                                        hideImage
                                        showAdd
                                        hideHCP
                                        hideMessage
                                        hideProfile
                                        noLink
                                        editAction={() => {}}
                                        name={t1}
                                      />
                                      <div className="mpbm-circle">
                                        {match.winnerID === "" ? (
                                          <p>vs</p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>

                                      <PlayerItem
                                        hideRemove
                                        hideImage
                                        hideEdit={true}
                                        hideHCP
                                        showAdd
                                        hideMessage
                                        hideProfile
                                        noLink
                                        name={t2}
                                        editAction={() => {}}
                                      />
                                    </div>
                                  );
                                })}
                              {details.tournamentType === "knockout" &&
                                roundMatches.map((match, index2) => {
                                  const { teams } = match;

                                  const t1p1 = teams[0].players[0];
                                  const t1p2 = teams[0].players[1];
                                  const t2p1 = teams[1].players[0];
                                  const t2p2 = teams[1].players[1];

                                  const t1 = `${t1p1.name} / ${t1p2.name}`;
                                  const t2 = `${t2p1.name} / ${t2p2.name}`;

                                  if (match.completed || match.roundID) {
                                    return null;
                                  }

                                  // if (match.players.length === 0) {
                                  //   return (
                                  //     <div className="mpl-mps">
                                  //       <p>Match {match.match}</p>{" "}
                                  //       <MatchplayScore hideThru preMatch />
                                  //     </div>
                                  //   );
                                  // }
                                  return (
                                    <div
                                      className="mpb-match mpb-match-small"
                                      onClick={() =>
                                        setSelStart([index, index2])
                                      }
                                    >
                                      <PlayerItem
                                        hideRemove
                                        hideEdit={true}
                                        hideImage
                                        showAdd
                                        hideHCP
                                        hideMessage
                                        hideProfile
                                        noLink
                                        editAction={() => {}}
                                        name={t1}
                                      />
                                      <div className="mpbm-circle">
                                        {match.winnerID === "" ? (
                                          <p>vs</p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>

                                      <PlayerItem
                                        hideRemove
                                        hideImage
                                        hideEdit={true}
                                        hideHCP
                                        showAdd
                                        hideMessage
                                        hideProfile
                                        noLink
                                        name={t2}
                                        editAction={() => {}}
                                      />
                                    </div>
                                  );
                                })}
                              {details.tournamentType === "robin" &&
                                roundMatches.map((match, index2) => {
                                  // console.log(match);
                                  const { teams } = match;
                                  const t1p1 = teams[0].players[0];
                                  const t1p2 = teams[0].players[1];
                                  const t2p1 = teams[1].players[0];
                                  const t2p2 = teams[1].players[1];

                                  const t1 = `${t1p1.name} / ${t1p2.name}`;
                                  const t2 = `${t2p1.name} / ${t2p2.name}`;

                                  if (match.completed || match.roundID) {
                                    return null;
                                  }

                                  // if (match.players.length === 0) {
                                  //   return (
                                  //     <div className="mpl-mps">
                                  //       <p>Match {match.match}</p>{" "}
                                  //       <MatchplayScore hideThru preMatch />
                                  //     </div>
                                  //   );
                                  // }
                                  return (
                                    <div
                                      className="mpb-match mpb-match-small"
                                      onClick={() =>
                                        setSelStart([index, index2])
                                      }
                                    >
                                      <PlayerItem
                                        hideRemove
                                        hideEdit={true}
                                        hideImage
                                        showAdd
                                        hideHCP
                                        hideMessage
                                        hideProfile
                                        noLink
                                        editAction={() => {}}
                                        name={t1}
                                      />
                                      <div className="mpbm-circle">
                                        {match.winnerID === "" ? (
                                          <p>vs</p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>

                                      <PlayerItem
                                        hideRemove
                                        hideImage
                                        hideEdit={true}
                                        hideHCP
                                        showAdd
                                        hideMessage
                                        hideProfile
                                        noLink
                                        name={t2}
                                        editAction={() => {}}
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}

                    {selStart !== null && !doubles && (
                      <>
                        {returnMatch()}

                        <div className="flex-center">
                          <button
                            onClick={startMatch}
                            className="default-button"
                          >
                            Confirm{" "}
                          </button>
                        </div>
                      </>
                    )}
                    {selStart !== null && doubles && (
                      <>
                        {returnTeamMatch()}

                        <div className="flex-center">
                          <button
                            onClick={startMatch}
                            className="default-button"
                          >
                            Confirm{" "}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
                {details.tournamentType === "robin" && !doubles && (
                  <>
                    {choosing && (
                      <BackRow
                        action={() => {
                          setOpper();
                          setChoosing(false);
                        }}
                      />
                    )}
                    <div className="mps-players">
                      {players.map((player, index) => {
                        if (choosing) {
                          console.log(index);

                          if (starter && player.playerID === starter.playerID) {
                            return null;
                          }
                          const arr = playedMap[player.playerID] ?? [];
                          console.log(arr);
                          if (starter && arr.includes(starter.playerID)) {
                            return null;
                          }
                        }
                        return (
                          <PlayerItem
                            hcp={player.handicap}
                            name={`${player.name}`}
                            playerID={player.playerID}
                            showAdd
                            hideIcons
                            hideEdit
                            hideProfile
                            noLink
                            showBg={
                              (starter &&
                                starter.playerID === player.playerID) ||
                              (opper && opper.playerID === player.playerID)
                            }
                            showProfile={() => {
                              if (choosing) {
                                setOpper(player);
                              } else {
                                setStarter(player);
                              }
                            }}
                            img={player.profileImage}
                          />
                        );
                      })}
                    </div>
                    {starter !== undefined &&
                      opper === undefined &&
                      !choosing && (
                        <div className="flex-center">
                          <button
                            onClick={() => {
                              setChoosing(true);
                            }}
                            className="default-button"
                          >
                            Choose Opponent
                          </button>
                        </div>
                      )}

                    {starter !== undefined && opper !== undefined && (
                      <div className="flex-center mb-20">
                        <button onClick={startMatch} className="default-button">
                          Setup Match
                        </button>
                      </div>
                    )}
                  </>
                )}
                {details.tournamentType === "robin" && doubles && (
                  <>
                    {choosing && (
                      <BackRow
                        action={() => {
                          setOpper();
                          setChoosing(false);
                        }}
                      />
                    )}
                    <div className="mps-players">
                      {teams.map((team, index) => {
                        if (choosing) {
                          if (starter && team.teamID === starter.teamID) {
                            return null;
                          }
                          const arr = playedMap[team.teamID] ?? [];
                          if (starter && arr.includes(starter.teamID)) {
                            return null;
                          }
                        }
                        if (opper) {
                          console.log(team.teamID, opper.teamID);
                        }
                        const name = `${team.players[0].name} / ${team.players[1].name}`;
                        return (
                          <PlayerItem
                            name={name}
                            hideHCP
                            showAdd
                            hideIcons
                            hideEdit
                            hideProfile
                            noLink
                            showBg={
                              (starter && starter.teamID === team.teamID) ||
                              (opper && opper.teamID === team.teamID)
                            }
                            showProfile={() => {
                              team.name = name;
                              if (choosing) {
                                setOpper(team);
                              } else {
                                setStarter(team);
                              }
                            }}
                            // img={player.profileImage}
                          />
                        );
                      })}
                    </div>
                    {/* {starter !== undefined &&
                      opper === undefined &&
                      !choosing && (
                        <div className="flex-center">
                          <button
                            onClick={() => setChoosing(true)}
                            className="default-button"
                          >
                            Choose Opponent
                          </button>
                        </div>
                      )} */}

                    {/* {starter !== undefined && opper !== undefined && (
                      <div className="flex-center mb-20">
                        <button onClick={startMatch} className="default-button">
                          Setup Match
                        </button>
                      </div>
                    )} */}
                  </>
                )}
                {details.tournamentType === "tournament" &&
                  details.stage === "groups" && (
                    <>
                      {choosing && (
                        <BackRow
                          action={() => {
                            setOpper();
                            setChoosing(false);
                          }}
                        />
                      )}
                    </>
                  )}
                {details.tournamentType === "tournament" &&
                  details.stage === "groups" &&
                  !doubles && (
                    <div className="mps-players">
                      {players.map((player, index) => {
                        if (choosing) {
                          if (starter && player.playerID === starter.playerID) {
                            return null;
                          }
                          const arr = playedMap[player.playerID] ?? [];
                          if (starter && arr.includes(starter.playerID)) {
                            return null;
                          }
                          if (starter) {
                            const arr2 = groupMap[starter.playerID] ?? [];
                            if (!arr2.includes(player.playerID)) {
                              return null;
                            }
                          }
                        }
                        return (
                          <PlayerItem
                            hcp={player.handicap}
                            name={player.name}
                            playerID={player.playerID}
                            showAdd
                            hideIcons
                            hideEdit
                            hideProfile
                            noLink
                            showBg={
                              (starter &&
                                starter.playerID === player.playerID) ||
                              (opper && opper.playerID === player.playerID)
                            }
                            showProfile={() => {
                              if (choosing) {
                                setOpper(player);
                              } else {
                                setStarter(player);
                              }
                            }}
                            img={player.profileImage}
                          />
                        );
                      })}
                    </div>
                  )}
                {details.tournamentType === "tournament" &&
                  details.stage === "groups" &&
                  doubles && (
                    <div className="mps-players">
                      {teams.map((team, index) => {
                        if (choosing) {
                          if (starter && team.teamID === starter.teamID) {
                            return null;
                          }
                          const arr = playedMap[team.teamID] ?? [];
                          if (starter && arr.includes(starter.teamID)) {
                            return null;
                          }
                          if (starter) {
                            const arr2 = groupMap[starter.teamID] ?? [];
                            if (!arr2.includes(team.teamID)) {
                              return null;
                            }
                          }
                        }
                        return (
                          <PlayerItem
                            hcp={team.handicap}
                            name={team.name}
                            playerID={team.playerID}
                            showAdd
                            hideIcons
                            hideEdit
                            hideProfile
                            hideHCP
                            noLink
                            showBg={
                              (starter && starter.teamID === team.teamID) ||
                              (opper && opper.teamID === team.teamID)
                            }
                            showProfile={() => {
                              if (choosing) {
                                setOpper(team);
                              } else {
                                setStarter(team);
                              }
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                {details.tournamentType === "tournament" &&
                  details.stage === "groups" &&
                  starter !== undefined &&
                  opper === undefined &&
                  !choosing && (
                    <div className="flex-center">
                      <button
                        onClick={() => setChoosing(true)}
                        className="default-button"
                      >
                        Choose Opponent
                      </button>
                    </div>
                  )}

                {details.tournamentType === "tournament" &&
                  details.stage === "groups" &&
                  starter !== undefined &&
                  opper !== undefined && (
                    <div className="flex-center mb-20">
                      <button onClick={startMatch} className="default-button">
                        Setup Match
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </Dialog>

          <Dialog
            className="mp-ma-dia"
            open={showManual}
            onClose={() => {
              // setManChoosing(false);
              // setManOne();
              // setManTwo();
              setShowManual(false);
            }}
          >
            <div className="mp-start-dia">
              <PageHeader
                showClose
                close={() => setShowManual(false)}
                text="Input Match"
              />
              {putting ? (
                <>
                  <BackRow action={() => setPutting(false)} />
                  <div className="flex-center matchbox mt-10">
                    <div className="mpb-match">
                      {doubles ? (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manOne &&
                            selWinner.teamID === manOne.teamID
                          }
                          showProfile={() => setSelWinner(manOne)}
                          hideRemove
                          hcp={manOne.handicap}
                          hideImage
                          hideHCP
                          showAdd
                          hideMessage
                          hideProfile
                          noLink
                          hideIcons
                          name={manOne.name}
                        />
                      ) : (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manOne &&
                            selWinner.playerID === manOne.playerID
                          }
                          showProfile={() => setSelWinner(manOne)}
                          hideRemove
                          hcp={manOne.handicap}
                          hideImage
                          hideHCP
                          showAdd
                          hideMessage
                          hideProfile
                          noLink
                          hideIcons
                          name={manOne.name}
                        />
                      )}
                      <div className="mp-cir-box">
                        <div className="mpbm-circle">
                          <p>vs</p>
                        </div>
                      </div>

                      {doubles ? (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manTwo &&
                            selWinner.teamID === manTwo.teamID
                          }
                          showProfile={() => setSelWinner(manTwo)}
                          hideRemove
                          hcp={manTwo.handicap}
                          hideImage
                          hideIcons
                          showAdd
                          hideHCP
                          hideMessage
                          hideProfile
                          noLink
                          name={manTwo.name}
                        />
                      ) : (
                        <PlayerItem
                          showBg={
                            selWinner &&
                            manTwo &&
                            selWinner.playerID === manTwo.playerID
                          }
                          showProfile={() => setSelWinner(manTwo)}
                          hideRemove
                          hcp={manTwo.handicap}
                          hideImage
                          hideIcons
                          showAdd
                          hideHCP
                          hideMessage
                          hideProfile
                          noLink
                          name={manTwo.name}
                        />
                      )}
                    </div>
                  </div>

                  <div className="mini-pi">
                    <PlayerItem
                      name={"Tie"}
                      showBg={selWinner && selWinner.name === "Tie"}
                      hideIcons
                      noLink
                      hideHCP
                      hideImage
                      showProfile={() => {
                        setSelWinner({
                          name: "Tie",
                          playerID: "tie",
                        });
                        setManualBy(0);
                        setManualRem(0);
                      }}
                    />
                  </div>

                  <div className="mp-man-holes">
                    <div className="input-group">
                      <p>Holes Up</p>
                      <input
                        type="number"
                        disabled={selWinner && selWinner.name === "Tie"}
                        onChange={(e) => setManualBy(e.target.value)}
                        value={manualBy}
                        className="default-input"
                      />
                    </div>
                    <div className="input-group">
                      <p>Holes Remaining</p>
                      <input
                        type="number"
                        disabled={selWinner && selWinner.name === "Tie"}
                        onChange={(e) => setManualRem(e.target.value)}
                        value={manualRem}
                        className="default-input"
                      />
                    </div>
                  </div>
                  <div className="flex-center mb-20">
                    <button
                      disabled={
                        selWinner === undefined ||
                        manualRem > manualBy ||
                        (manualBy === 0 &&
                          manualRem === 0 &&
                          selWinner.name !== "Tie")
                      }
                      className="default-button"
                      onClick={() => {
                        if (details.tournamentType === "robin") {
                          manualFinish({
                            players: [manOne, manTwo],
                            // roundID: manMatch.roundID,
                          });
                        }
                        if (
                          details.tournamentType === "tournament" &&
                          details.stage === "groups"
                        ) {
                          if (doubles) {
                            manualTeamGroupFinish({
                              players: [manOne, manTwo],
                            });
                          } else {
                            manualGroupFinish({
                              players: [manOne, manTwo],
                            });
                          }
                        }
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {manChoosing && (
                    <BackRow
                      action={() => {
                        setManChoosing(false);
                        setManTwo();
                      }}
                    />
                  )}
                  <div className="mps-players">
                    {!doubles &&
                      players.map((player, index) => {
                        if (manChoosing) {
                          if (manOne && player.playerID === manOne.playerID) {
                            console.log(1);
                            return null;
                          }
                          const arr = playedMap[player.playerID] ?? [];
                          if (manOne && arr.includes(manOne.playerID)) {
                            return null;
                          }
                          if (
                            manOne &&
                            details.tournamentType === "tournament"
                          ) {
                            const arr2 = groupMap[manOne.playerID] ?? [];
                            if (!arr2.includes(player.playerID)) {
                              console.log(3);

                              return null;
                            }
                          }
                        }
                        return (
                          <PlayerItem
                            key={player.playerID}
                            hcp={player.handicap}
                            name={player.name}
                            playerID={player.playerID}
                            showAdd
                            hideIcons
                            hideEdit
                            hideProfile
                            noLink
                            showBg={
                              (manOne && manOne.playerID === player.playerID) ||
                              (manTwo && manTwo.playerID === player.playerID)
                            }
                            showProfile={() => {
                              if (manChoosing) {
                                setManTwo(player);
                              } else {
                                setManOne(player);
                              }
                            }}
                            img={player.profileImage}
                          />
                        );
                      })}
                    {doubles &&
                      teams.map((team, index) => {
                        if (manChoosing) {
                          if (manOne && team.teamID === manOne.teamID) {
                            return null;
                          }
                          const arr = playedMap[team.teamID] ?? [];
                          // console.log(arr, manOne);
                          if (manOne && arr.includes(manOne.teamID)) {
                            return null;
                          }
                          if (manOne && details.tournamentType !== "robin") {
                            const arr2 = groupMap[manOne.teamID] ?? [];
                            if (!arr2.includes(team.teamID)) {
                              console.log("ret this");

                              return null;
                            }
                          }
                        }
                        const name = `${team.players[0].name} / ${team.players[1].name}`;
                        return (
                          <PlayerItem
                            hideHCP
                            name={name}
                            showAdd
                            hideIcons
                            hideEdit
                            hideProfile
                            noLink
                            showBg={
                              (manOne && manOne.teamID === team.teamID) ||
                              (manTwo && manTwo.teamID === team.teamID)
                            }
                            showProfile={() => {
                              team.name = name;
                              if (manChoosing) {
                                setManTwo(team);
                              } else {
                                setManOne(team);
                              }
                            }}
                            // img={player.profileImage}
                          />
                        );
                      })}
                  </div>
                  {manOne !== undefined &&
                    manTwo === undefined &&
                    !manChoosing && (
                      <div className="flex-center mb-20">
                        <button
                          onClick={() => {
                            setManChoosing(true);
                          }}
                          className="default-button"
                        >
                          Choose Opponent
                        </button>
                      </div>
                    )}

                  {manOne !== undefined && manTwo !== undefined && (
                    <div className="flex-center mb-20">
                      <button
                        onClick={() => setPutting(true)}
                        className="default-button"
                      >
                        Input Result
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Dialog>

          <Dialog open={showRemove} onClose={() => setShowRemove(false)}>
            <div className="delete-popup">
              <GradientErrorIcon
                className="err-icon"
                style={{ color: "#E24955" }}
              />
              <p>Are you sure you want to remove this player?</p>
              <div
                onClick={() => removePlayer(playerRemove)}
                className="delete-button"
              >
                Confirm
              </div>
            </div>
          </Dialog>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <div className="delete-popup">
              <GradientErrorIcon
                className="err-icon"
                style={{ color: "#E24955" }}
              />
              <p>
                Are you sure you want to start this tournament? Any incomplete
                groups/fixtures will be auto generated.
              </p>
              <div onClick={() => startTournament()} className="confirm-button">
                Confirm
              </div>
            </div>
          </Dialog>
          <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
            <div className="delete-popup">
              <GradientErrorIcon
                className="err-icon"
                style={{ color: "#E24955" }}
              />
              <p>
                Are you sure you want to cancel this tournament? This cannot be
                undone.
              </p>
              <div onClick={() => cancelTournament()} className="delete-button">
                Confirm
              </div>
            </div>
          </Dialog>
          <div className="back-row" onClick={() => goBack()}>
            <ArrowBack />
            <p>Back</p>
          </div>

          <PageHeader
            text={`Matchplay Dashboard - ${details.tournamentName}`}
          />
          {details.tournamentName === "" ? (
            <>
              <PlayerLoader />
            </>
          ) : (
            <></>
          )}

          <div className="ch-links">
            <div className="ch-link">
              <div className="sub-header mb-10">
                <h5>General</h5>
              </div>
              <div className="quick-links">
                <Pill
                  text={"Edit Details"}
                  onClick={() => {
                    setShowEdit(true);
                  }}
                />
                <Pill
                  text={"Public Page "}
                  onClick={() => {
                    window.open(
                      `https://www.theugatour.com/tournaments/${tournID}`,
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
            {!started && (
              <div className="ch-link">
                <div className="sub-header mb-10">
                  <h5>Players</h5>
                </div>
                <div className="quick-links">
                  {!started && (
                    <Pill
                      text={"Invite Players"}
                      onClick={() => {
                        setShowInvite(true);
                      }}
                    />
                  )}
                  {!started && (
                    <Pill
                      text={"Add Players"}
                      onClick={() => {
                        setShowAdd(true);
                      }}
                    />
                  )}
                  {!started && (
                    <Pill
                      text={"Upload Players"}
                      onClick={() => {
                        setToShow(
                          <MatchplayUpload
                            clubID={details.clubID}
                            tournamentType={details.tournamentType}
                            tournamentID={tournID}
                            reload={loadDetails}
                            goBack={() => setShow(false)}
                          />
                        );
                        setShow(true);
                      }}
                    />
                  )}
                  {!started && doubles && (
                    <Pill
                      text={"Manage Teams"}
                      onClick={() => {
                        setToShow(
                          <MatchplayTeamManager
                            goBack={() => setShow(false)}
                            reload={loadDetails}
                            tournID={tournID}
                          />
                        );
                        setShow(true);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="ch-link">
              <div className="sub-header mb-10">
                <h5>Fixtures</h5>
              </div>
              <div className="quick-links">
                {started && (
                  <Pill
                    text={"Start Match"}
                    onClick={() => {
                      setShowStart(true);
                    }}
                  />
                )}
                {details.tournamentType === "robin" && started && (
                  <Pill
                    text={"Input Result"}
                    onClick={() => {
                      setShowManual(true);
                    }}
                  />
                )}
                {details.tournamentType === "tournament" &&
                  details.stage === "groups" &&
                  started && (
                    <Pill
                      text={"Input Result"}
                      onClick={() => {
                        setShowManual(true);
                      }}
                    />
                  )}
                {details.tournamentType === "tournament" &&
                  details.stage === "knockouts" &&
                  started && (
                    <Pill
                      text={"Input Result"}
                      onClick={() => {
                        setKnockoutManual(true);
                      }}
                    />
                  )}
                {details.tournamentType === "robin" && (
                  <Pill
                    text={"View Fixtures"}
                    onClick={() => {
                      setToShow(
                        <MatchplayRobins
                          goBack={() => setShow(false)}
                          tournamentID={tournID}
                        />
                      );
                      setShow(true);
                    }}
                  />
                )}
                {details.tournamentType === "tournament" && (
                  <Pill
                    onClick={() => {
                      setToShow(
                        <MatchplayTournamentFixtures
                          goBack={() => setShow(false)}
                          reload={loadDetails}
                          tournID={tournID}
                        />
                      );
                      setShow(true);
                    }}
                    text={"View Fixtures"}
                  />
                )}
                {details.tournamentType === "robin" && started && (
                  <Pill
                    text={"View Table"}
                    onClick={() => {
                      setToShow(
                        <MatchplayLog
                          playerResults={resMap}
                          tournID={tournID}
                          goBack={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                  />
                )}
                {details.tournamentType === "knockout" &&
                  details.fixtures === "manual" && (
                    <Pill
                      className="mpl-link mr-20"
                      text={"Manage Bracket"}
                      onClick={() => {
                        if (doubles) {
                          setToShow(
                            <MatchplayTeamBracket
                              edit={true}
                              started={started}
                              teams={teams}
                              goBack={() => setShow(false)}
                              tournID={tournID}
                              bracket={details.bracket}
                              players={players}
                              reload={loadDetails}
                            />
                          );
                        } else {
                          setToShow(
                            <MatchplayBracket
                              edit={true}
                              started={started}
                              goBack={() => setShow(false)}
                              tournID={tournID}
                              bracket={details.bracket}
                              players={players}
                              reload={loadDetails}
                            />
                          );
                        }
                        setShow(true);
                      }}
                    />
                  )}
                {details.tournamentType === "knockout" && (
                  <Pill
                    text={"View Bracket"}
                    onClick={() => {
                      if (doubles) {
                        setToShow(
                          <TeamBracketDisplay
                            goBack={() => setShow(false)}
                            tournamentID={tournID}
                          />
                        );
                      } else {
                        setToShow(
                          <BracketDisplay
                            tournamentID={tournID}
                            goBack={() => setShow(false)}
                          />
                        );
                      }
                      setShow(true);
                    }}
                  />
                )}
                {details.tournamentType === "knockout" &&
                  details.fixtures === "generated" && (
                    <Pill
                      text={"View Fixtures"}
                      onClick={() => {
                        setToShow(
                          <MatchplayBracket
                            edit={false}
                            goBack={() => setShow(false)}
                            tournID={tournID}
                            bracket={details.bracket}
                            players={players}
                          />
                        );
                        setShow(true);
                      }}
                      className="mpl-link mr-20"
                    />
                  )}
                {started && details.tournamentType === "knockout" && (
                  <Pill
                    text={"View Rounds"}
                    onClick={() => {
                      setToShow(
                        <MatchplayRounds
                          tournID={tournID}
                          goBack={() => setShow(false)}
                        />
                      );
                      setShow(true);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="ch-link">
              <div className="sub-header mb-10">
                <h5>Admin</h5>
              </div>
              <div className="quick-links">
                {details.tournamentType === "tournament" &&
                  details.fixtures === "manual" && (
                    <Pill
                      onClick={() => {
                        setToShow(
                          <MatchplayGroups
                            goBack={() => setShow(false)}
                            defaultGroups={details.groups ?? []}
                            tournID={tournID}
                            defaultKnockout={details.knockout}
                            defaultGroupSize={details.groupSize}
                            defaultProgression={details.progression}
                            players={players}
                            playerResults={resMap}
                            reload={loadDetails}
                            display={started}
                            doubles={doubles}
                            teams={teams}
                          />
                        );
                        setShow(true);
                      }}
                      text="View Groups"
                      className="mpl-link mr-20"
                    />
                  )}
                {details.tournamentType === "tournament" &&
                  details.fixtures === "generated" && (
                    <Pill
                      onClick={() => {
                        setToShow(
                          <MatchplayGroups
                            goBack={() => setShow(false)}
                            defaultGroups={details.groups ?? []}
                            tournID={tournID}
                            defaultKnockout={details.knockout}
                            players={players}
                            reload={loadDetails}
                            teams={teams}
                          />
                        );
                        setShow(true);
                      }}
                      text="View Groups"
                      className="mpl-link mr-20"
                    />
                  )}
                {!started &&
                  !(
                    details.tournamentType === "tournament" &&
                    details.groups.length === 0
                  ) &&
                  !(
                    details.tournamentType === "knockout" &&
                    details.bracket.length === 0
                  ) && (
                    <Pill
                      onClick={() => {
                        setConfirmStart(true);
                      }}
                      className="mpl-link"
                      text={"Start Tournament"}
                    />
                  )}
              </div>
            </div>
          </div>

          <div className="eh-box-box">
            <div className="eh-box">
              <div className="ehb-header">
                <h1 className="ne-header">Details</h1>
                <div className="svg-cont">
                  <GradientEditIcon
                    className="edit-hint icon-cursor mb-10"
                    onClick={() => setShowEdit(true)}
                    style={{ color: "#21c17c" }}
                  />
                </div>
              </div>
              <div className="ehbh-item">
                <div className="info-circle">
                  <LocationOnIcon className="ic-icon" />
                </div>
                <p>
                  {details.city}, {details.country}
                </p>
              </div>
              {details.courseName && (
                <div className="ehbh-item">
                  <div className="info-circle">
                    <GolfCourseIcon className="ic-icon" />
                  </div>
                  <p>{details.courseName}</p>
                </div>
              )}
              <div className="ehbh-item">
                <div className="info-circle">
                  <EmojiEvents className="ic-icon" />
                </div>
                <p>
                  {details.tournamentType === "robin"
                    ? "Round Robin"
                    : details.tournamentType === "tournament"
                    ? "World Cup Style"
                    : "Knockout"}
                </p>
              </div>
            </div>
            <div className="p-r-course">
              <div className="p-r-course-left">
                <Avatar alt={details.tournamentName} src={details.imageLink} />
              </div>
            </div>
          </div>

          {false && (
            <div className="ehb-header">
              <h1 className="ne-header">Players</h1>
            </div>
          )}
          <div className="ig-header pl-20">
            <h3>Players ({players.length})</h3>
            {!started && (
              <div className="svg-cont eh-invite-add">
                <GradientCircleIcon
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={() => setShowAdd(true)}
                  className="dg-icon-bg icon-cursor"
                />
              </div>
            )}
          </div>
          <div className="ec-box">
            <div className="part-players-box">
              {players.map((player) => (
                <div key={player.playerID} className="ec-pi">
                  <PlayerItem
                    hcp={player.handicap}
                    name={player.name}
                    playerID={player.playerID}
                    img={player.profileImage}
                    showProfile={() => {}}
                    // noLink
                    showAdd
                    removeAction={() => {
                      setPlayerRemove(player.playerID);
                      setShowRemove(true);
                    }}
                    hideEdit
                    hideProfile
                  />
                  {false && (
                    <GradientRemoveCircleIcon
                      onClick={() => {}}
                      className="rci"
                    />
                  )}
                  {false && (
                    <div className="svg-cont ml-10">
                      <GradientEditIcon
                      // onClick={() => {
                      //   setRound(
                      //     <PlayerSetup
                      //       hcpOnly
                      //       playerID={player.playerID}
                      //       handicap={player.handicap}
                      //       changeHandicap={(newHcp) => {
                      //         saveHandicap({
                      //           playerID: player.playerID,
                      //           handicap: newHcp,
                      //         });
                      //         setSelectedPlayers((current) => {
                      //           for (let i = 0; i < current.length; i++) {
                      //             if (
                      //               current[i].playerID === player.playerID
                      //             ) {
                      //               current[i].handicap = newHcp;
                      //             }
                      //           }
                      //           return current;
                      //         });
                      //       }}
                      //       name={player.name}
                      //       customHandicap
                      //       imageLink={player.profileImage}
                      //       goBack={() => setShowRound(false)}
                      //     />
                      //   );
                      //   setShowRound(true);
                      // }}
                      // style={{
                      //   color: "#21c17c",
                      //   height: "20px",
                      //   marginLeft: "10px",
                      // }}
                      // className="rci"
                      />
                    </div>
                  )}
                </div>
              ))}
              {players.length === 0 && (
                <div className="no-players">
                  <p>No players have joined this tournament yet</p>
                </div>
              )}
            </div>
          </div>
          {!started && (
            <div className="ig-header mt-20 pl-20">
              <h3>Invited ({invited.length})</h3>
              <div className="svg-cont eh-invite-add">
                <GradientCircleIcon
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={() => setShowInvite(true)}
                  className="dg-icon-bg icon-cursor"
                />
              </div>
            </div>
          )}
          {!started && (
            <div className="ec-box">
              {false && (
                <div className="input-group">
                  <Dropdown
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    options={inviteable}
                    onChange={(e) => {
                      const player = inviteable.find(
                        (obj) => obj.value === e.value
                      );
                      setInvited((current) => [...current, player]);
                      setInvitable((current) =>
                        current.filter((obj) => obj.value !== e.value)
                      );
                      setChanged(true);
                    }}
                  />
                </div>
              )}
              <div className="part-players-box">
                {invited.map((player) => {
                  return (
                    <div key={player.playerID} className="ec-pi">
                      <PlayerItem
                        hcp={player.handicap}
                        name={player.name}
                        playerID={player.playerID}
                        showAdd
                        hideEdit
                        hideProfile
                        img={player.profileImage}
                      />
                      {false && (
                        <GradientRemoveCircleIcon
                          onClick={() => {
                            // alert("boobs");
                            setInvited((current) => {
                              const newArr = current.filter(
                                (pla) => pla.playerID !== player.playerID
                              );
                              return newArr;
                            });
                            setInvitable((current) => {
                              const newArr = [...current, player];
                              return newArr;
                            });
                            setChanged(true);
                          }}
                          className="rci"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {invited.length === 0 && (
                <div className="no-players mt-10">
                  <p>No players have been invited yet</p>
                </div>
              )}
            </div>
          )}
          {changed && (
            <div style={{ marginBottom: "20px" }} className="ec-button-row">
              <button
                onClick={() => {
                  saveInvites();
                }}
              >
                Save Changes
              </button>
            </div>
          )}
          {!started && (
            <div className="flex-center mb-20">
              <button
                onClick={() => setShowDelete(true)}
                className=" delete-button"
              >
                Cancel Tournament
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MatchplayTournamentHome;
