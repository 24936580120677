import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons

// Components
import { Avatar } from "@mui/material";
import BackRow from "../../display/Rows/BackRow";
import PageHeader from "../../display/Rows/PageHeader";
import BackdropLoader from "../../Loaders/BackdropLoader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import PlayerItem from "../../display/PlayerItem/PlayerItem";

function MatchupResult({
  eventID = "",
  goBack = () => {},
  format = "",
  betterballStableford = false,
}) {
  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [matchups, setMatchups] = useState([]);
  const [selMatch, setSelMatch] = useState();
  const [showDia, setShowDia] = useState(false);
  const [selWinner, setSelWinner] = useState();
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }

  // UE
  useEffect(() => {
    console.log(format, betterballStableford);
    loadMatchups();
  }, []);

  // Fucs
  async function loadMatchups() {
    const eventDoc = await db.collection("teamEvents").doc(eventID).get();
    const event = eventDoc.data();

    const matchups = event.matchups ?? [];
    console.log(matchups);
    setMatchups(matchups);
    setLoading(false);
  }

  async function saveResult() {
    setLoading(true);
    setShowDia(false);
    try {
      const eventDoc = await db.collection("teamEvents").doc(eventID).get();
      const event = eventDoc.data();

      const matchups = event.matchups ?? [];

      for (let i = 0; i < matchups.length; i++) {
        // if (matchups[i].match)
        const mc = matchups[i];

        if (
          mc.teamOne.teamID === selMatch.teamOne.teamID &&
          mc.teamTwo.teamID === selMatch.teamTwo.teamID
        ) {
          matchups[i].conceded = true;
          matchups[i].winner = selWinner;

          const loserID =
            selWinner === mc.teamOne.teamID
              ? mc.teamTwo.teamID
              : mc.teamOne.teamID;

          if (selWinner === mc.teamOne.teamID) {
            if (betterballStableford) {
              matchups[i].teamOne.score = 2.5;
              matchups[i].teamTwo.score = 1.5;
            } else if (format === "Matchplay") {
              matchups[i].teamOne.score = 3.5;
              matchups[i].teamTwo.score = 2.5;
            } else {
              matchups[i].teamOne.score = 1.5;
              matchups[i].teamTwo.score = 0.5;
            }
          } else {
            if (betterballStableford) {
              matchups[i].teamOne.score = 1.5;
              matchups[i].teamTwo.score = 2.5;
            } else if (format === "Matchplay") {
              matchups[i].teamOne.score = 2.5;
              matchups[i].teamTwo.score = 3.5;
            } else {
              matchups[i].teamOne.score = 0.5;
              matchups[i].teamTwo.score = 1.5;
            }
          }
        }
      }

      console.log(matchups);

      await eventDoc.ref.update({ matchups });
      setSelMatch();
      setSelWinner();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="matchup-result">
      <Dialog open={showDia} onClose={() => setShowDia(false)}>
        <div className="mrd-ctt pd-20">
          <PageHeader
            text="Result Input"
            showClose
            close={() => setShowDia(false)}
          />

          <div className="neh-box">
            <h2 className="ne-header">Select Winner</h2>
          </div>

          {selMatch && (
            <>
              <div className="mrd-mcs pd-20">
                <PlayerItem
                  noLink
                  hideHCP
                  hideImage
                  hideIcons
                  name={selMatch.teamOne.name}
                  showBg={selMatch.teamOne.teamID === selWinner}
                  showProfile={() => {
                    setSelWinner(selMatch.teamOne.teamID);
                    update();
                  }}
                />
                <PlayerItem
                  noLink
                  hideHCP
                  hideImage
                  hideIcons
                  name={selMatch.teamTwo.name}
                  showBg={selMatch.teamTwo.teamID === selWinner}
                  showProfile={() => {
                    setSelWinner(selMatch.teamTwo.teamID);
                    update();
                  }}
                />
              </div>
            </>
          )}

          <div className="flex-center">
            <button onClick={saveResult} className="default-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal text={error} hide={() => setError("")} />}
      <BackRow action={goBack} />
      <PageHeader text="Matchup Result" />
      <div className="mr-ms">
        {matchups.map((mtch, index) => {
          const { teamOne, teamTwo } = mtch;

          return (
            <div
              key={`${index}match`}
              className="tem-team"
              onClick={() => {
                setSelWinner(mtch.winner ?? "");
                setSelMatch(mtch);
                setShowDia(true);
              }}
            >
              <div className="tt-left">
                <div className="ttl-left">
                  <Avatar src={teamOne.imageLink} />
                  <h2>
                    {teamOne.name}
                    {/* <span style={{ marginRight: "10px" }} /> */}
                  </h2>
                </div>
                <div className="ttl-right"></div>
              </div>
              <div className="tt-mid">
                <h2>-</h2>
              </div>
              <div className="tt-right">
                <div className="ttr-left"></div>
                <div className="ttr-right">
                  <h2>
                    {/* <span style={{ marginRight: "10px" }} /> */}
                    {teamTwo.name}
                  </h2>
                  <Avatar src={teamTwo.imageLink} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MatchupResult;
