import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../../firebase";
import { useDropzone } from "react-dropzone";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// Components
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import BackRow from "../../components/display/Rows/BackRow";
import ClubSponsor from "./Sponsors/ClubSponsor";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { toast } from "react-toastify";
import { successIco } from "../../components/ClientStats/HelperFunctions";
function BoardSettings({ goBack, clubID = "" }) {
  const moptions = ["1", "2", "3", "4", "5", "6"];

  const [update, setUpdate] = useState(0);
  function upd() {
    setUpdate((current) => current + 1);
  }
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [imgageURL, setImageURL] = useState("");

  const [loading, setLoading] = useState(true);

  const [availableSponsors, setAvailableSponsors] = useState([]);
  const [selSponsors, setSelSponsors] = useState([]);
  const [tvCode, setTVCode] = useState("");

  const [settings, setSettings] = useState({
    eventMonths: "2",
  });

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles[0]);
    setImage(acceptedFiles[0]);
    const url = URL.createObjectURL(acceptedFiles[0]);
    setImageURL(url);
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  useEffect(() => {
    loadAll();
  }, []);

  async function loadAll() {
    const clubDoc = await db.collection("clubs").doc(clubID).get();
    const club = clubDoc.data();
    const sponsors = club.sponsors ?? [];
    const boardSponsors = club.boardSponsors ?? [];
    const boardSettings = club.boardSettings ?? {
      eventMonths: "2",
    };
    const boardTVCode = club.tvCode ?? "";
    setImageURL(club.boardImage ?? "");
    setTVCode(boardTVCode);
    setAvailableSponsors(sponsors);
    setSelSponsors(boardSponsors);
    setSettings(boardSettings);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      await db.collection("clubs").doc(clubID).update({
        boardSponsors: selSponsors,
        boardSettings: settings,
      });

      if (image !== "") {
        const task = storage.ref().child(`${clubID}/${image.name}`).put(image);
        await task.then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then((url) => {
            db.collection("clubs").doc(clubID).update({
              boardImage: url,
            });
          });
        });
      }
      toast.success("Changes successfully saved", {
        icon: successIco,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again"
      );
    }
    setLoading(false);
  }

  return (
    <div className="board-settings">
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <BackRow action={goBack} />
      <PageHeader text="Board Settings" />
      <div className="ph-msg">
        <p>Adjust your settings for your club's TV leaderboards</p>
      </div>

      <div className="ec-box">
        {false && (
          <div className="input-group">
            <p>Events (select months to show)</p>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={settings.eventMonths}
              options={moptions}
              onChange={(e) => {
                setSettings((current) => {
                  current.eventMonths = e.value;
                  return current;
                });
                upd();
              }}
            />
          </div>
        )}
        <div className="input-group ">
          <p>Background Image</p>
          <div className="image-buttons">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dz-sel">
                {imgageURL !== "" && (
                  <div className="event-img mt-0">
                    <img className="" alt="profile" src={imgageURL} />
                  </div>
                )}
                {imgageURL === "" && (
                  <h5>
                    Drag 'n' drop an image here, or click to open up the file
                    explorer
                  </h5>
                )}
              </div>
            </div>
            {/* <div className="ec-is" style={{ marginLeft: "0px" }}>
                    <button className="" onClick={() => setShowImages(true)}>
                      Choose Preset Image
                    </button>
                  </div> */}
          </div>
        </div>
      </div>

      {tvCode !== "" && (
        <div className="tv-code-display">
          <p>
            TV Code: <span>{tvCode}</span>
          </p>
        </div>
      )}

      {false && (
        <>
          <div className="ph-msg mt-40">
            <p>Select up to 8 sponsors</p>
          </div>

          {availableSponsors.length > 0 && (
            <div className="cs-box flex-wrapper">
              {availableSponsors.map((sponsor, index) => (
                <ClubSponsor
                  sponsor={sponsor}
                  key={sponsor.sponsorID}
                  hideIcons
                  active={selSponsors.includes(sponsor.sponsorID)}
                  select
                  selectAction={() => {
                    setSelSponsors((current) => {
                      if (current.includes(sponsor.sponsorID)) {
                        const filt = current.filter(
                          (it) => it !== sponsor.sponsorID
                        );
                        return filt;
                      } else {
                        if (current.length === 8) {
                          return current;
                        } else {
                          current.push(sponsor.sponsorID);
                          return current;
                        }
                      }
                    });
                    upd();
                  }}
                />
              ))}
            </div>
          )}

          {availableSponsors.length === 0 && (
            <div className="no-msg">
              <p>No sponsors to display</p>
            </div>
          )}
        </>
      )}

      <div className="flex-center mb-40 mt-20">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default BoardSettings;
