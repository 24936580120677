import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Edit from "@mui/icons-material/Edit";

// Components
import PageHeader from "../../display/Rows/PageHeader";
import BackdropLoader from "../../Loaders/BackdropLoader";
import BackRow from "../../display/Rows/BackRow";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Dialog } from "@mui/material";
import { DeleteForever, Info, Save, Undo } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import Switch from "react-ios-switch/lib/Switch";
import InfoModal from "../../ErrorModal/InfoModal";
import { toast } from "react-toastify";
import { successIco } from "../../ClientStats/HelperFunctions";

function EventPlayerList({
  eventID = "",
  goBack = () => {},
  teamEvent = false,
  reload = () => {},
  disableChanges = false,
}) {
  // State
  const [loading, setLoading] = useState(true);
  const [selRound, setSelRound] = useState(0);
  const [upd, setUpd] = useState(0);
  function update() {
    setUpd((c) => c + 1);
  }
  const [rdOpts, setRDOpts] = useState([{ label: "Roud 1", value: 0 }]);
  const [rounds, setRounds] = useState([
    {
      playerHandicaps: {},
      courseSelections: {},
      teeMap: {},
      playerIndexes: {},
    },
  ]);
  const [cfa, setCFA] = useState(false);

  const [courseID, setCourseID] = useState("");
  const [courseMap, setCourseMap] = useState({});
  const [courseOpts, setCourseOpts] = useState([]);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [adjusted, setAdjusted] = useState(false);
  const [players, setPlayers] = useState([]);
  const [useLowHI, setUseLowHI] = useState(false);
  const [playerEdit, setPlayerEdit] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [editInput, setEditInput] = useState("");
  const [sr, setSR] = useState(true);
  const [sga, setSGA] = useState(100);

  const [indexPlayerEdit, setIndexPlayerEdit] = useState("");
  const [showIndexEdit, setShowIndexEdit] = useState(false);
  const [indexEditInput, setIndexEditInput] = useState("");

  const [handicapLimit, setHandicapLimit] = useState(54);
  const [handicapIndexLimit, setHandicapIndexLimit] = useState(54);

  const [eventSR, setEventSR] = useState(false);

  const [infoText, setInfoText] = useState("");

  // UE
  useEffect(() => {
    loadDetails();
  }, []);

  // Funcs
  async function loadDetails() {
    setLoading(true);
    const eventDoc = await db
      .collection(teamEvent ? "teamEvents" : "events")
      .doc(eventID)
      .get();
    const event = eventDoc.data();
    const { courseID, rounds, playerIDs, courseName } = event;
    setSR(event.sr);
    setUseLowHI(event.useLowHI ?? false);
    setAdjusted(event.adjusted ?? false);
    setEventSR(event.sr ?? false);
    setCourseID(courseID);
    const courses = event.courses ?? [{ courseID, courseName }];
    const courseOpts = courses.map((crs) => {
      const toRet = {
        value: crs.courseID,
        label: crs.name ?? crs.label,
      };
      return toRet;
    });
    setCourseOpts(courseOpts);
    setSGA(event.singlesAllowance ?? 100);
    setHandicapLimit(event.handicapLimit ?? 54);
    setHandicapIndexLimit(event.handicapIndexLimit ?? 54);

    for (let i = 0; i < rounds.length; i++) {
      if (rounds[i].courseSelections === undefined) {
        rounds[i].courseSelections = {};
        for (let j = 0; j < playerIDs.length; j++) {
          const id = playerIDs[j];
          rounds[i].courseSelections[id] = courseID;
        }
      }
      if (rounds[i].playerHandicaps === undefined) {
        rounds[i].playerHandicaps = {};
      }
      if (rounds[i].playerIndexes === undefined) {
        rounds[i].playerIndexes = {};
      }
      if (rounds[i].teeMap === undefined) {
        rounds[i].teeMap = {};
      }
    }

    const rdOpts = rounds.map((rd, i) => {
      const toRet = {
        label: `Round ${i + 1}`,
        value: `${i}`,
      };
      return toRet;
    });
    console.log(rdOpts);
    setRDOpts(rdOpts);
    setSelRound(rdOpts[0].value);
    // console.log(rounds);
    setRounds(rounds);

    const carr = {};

    for (let i = 0; i < courses.length; i++) {
      const course = courses[i];
      const courseID = course.courseID ?? course.value;
      const courseDoc = await db.collection("courses").doc(courseID).get();
      const courseData = courseDoc.data();

      const { teeArray, par, slope, femSlope, ratings, femRatings } =
        courseData;
      carr[courseID] = {
        teeArray,
        par,
        slope,
        femSlope,
        ratings,
        femRatings,
        sr: slope !== undefined,
      };
    }

    setCourseMap(carr);

    const refs = playerIDs.map((uid) => {
      return db.collection("users").doc(uid).get();
    });
    const arr = [];
    const docs = await Promise.all(refs);
    for (let i = 0; i < docs.length; i++) {
      const userDoc = docs[i];
      const playerID = userDoc.id;
      const player = userDoc.data();
      if (!player) {
        continue;
      }
      const { firstName, lastName, handicap, profileImage } = player;
      const lowHI = player.lowHI ?? handicap;
      arr.push({
        name: `${firstName} ${lastName}`,
        handicap,
        profileImage,
        playerID,
        gender: player.gender ?? "male",
        lowHI,
      });
    }

    const guests = event.guests ?? [];

    for (let i = 0; i < guests.length; i++) {
      const guest = guests[i];
      arr.push({
        name: `${guest.firstName} ${guest.lastName}`,
        playerID: guest.guestID,
        gender: guest.gender ?? "male",
        handicap: guest.handicap,
      });
    }

    console.log(arr);

    setPlayers(arr);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    try {
      const eventDoc = await db
        .collection(teamEvent ? "teamEvents" : "events")
        .doc(eventID)
        .get();
      const event = eventDoc.data();
      const evtRounds = event.rounds;

      evtRounds[selRound].courseSelections = rounds[selRound].courseSelections;
      evtRounds[selRound].teeMap = rounds[selRound].teeMap;
      evtRounds[selRound].playerHandicaps = rounds[selRound].playerHandicaps;
      evtRounds[selRound].playerIndexes = rounds[selRound].playerIndexes;

      if (cfa) {
        for (let i = 0; i < evtRounds.length; i++) {
          evtRounds[i].courseSelections = rounds[selRound].courseSelections;
          evtRounds[i].teeMap = rounds[selRound].teeMap;
          evtRounds[i].playerHandicaps = rounds[selRound].playerHandicaps;
          evtRounds[i].playerIndexes = rounds[selRound].playerIndexes;
        }
      }

      //   return setLoading(false);

      await eventDoc.ref.update({
        rounds: evtRounds,
        sr,
      });
      reload();
      toast.success("Changes were successfully saved", {
        icon: successIco,
      });
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there - please try again"
      );
    }
    setLoading(false);
  }

  return (
    <div className="evt-player-list">
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      {infoText !== "" && (
        <InfoModal text={infoText} hide={() => setInfoText("")} />
      )}

      <PageHeader text="Player Handicaps" />
      <div className="ph-msg">
        <p>An all-in-one overview of your players for this event</p>
      </div>

      <div className="input-group nassau-group pd-20">
        <div className="ig-header">
          <p className="mb-0">Enable Course Rating & Slope</p>
          <Info
            className="dg-icon-bg icon-cursor ml-20 mr-20"
            onClick={() =>
              setInfoText(
                "Enabling this will take into account the selected course's slope and rating values (if available) when calculating playing handicap."
              )
            }
          />
          {/* <span>*Will be applied to final handicaps</span> */}
          <Switch
            onColor="#1e7a69"
            checked={sr}
            disabled={disableChanges}
            className={sr ? "scoring-switch switch-on" : "scoring-switch"}
            onChange={() => {
              setSR((current) => {
                // handleSR(!current);
                return !current;
              });
            }}
          />
        </div>
      </div>

      <div className="ec-box">
        {rounds.length > 1 && (
          <div className="input-group">
            <p className="">Select Round</p>
            <Dropdown
              arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
              arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={selRound}
              options={rdOpts}
              onChange={(e) => {
                console.log(e);
                setSelRound(e.value);
                update();
              }}
            />
          </div>
        )}
        {!disableChanges && rdOpts.length > 1 && (
          <div className="input-group groups-hint ml-20">
            <p className="">Import from Round</p>
            <Dropdown
              // arrowClosed={
              //   <KeyboardArrowDownIcon className="drop-icon" />
              // }
              // arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
              value={"Select"}
              onChange={(e) => {
                console.log(e);
                const rd = parseInt(e.value);
                setRounds((c) => {
                  c[selRound] = c[rd];
                  return c;
                });
                setUpd((c) => c + 1);
              }}
              options={rdOpts}
            />
          </div>
        )}

        <div className="input-group">
          <p>Search for player</p>
          <input
            className="default-input"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Start typing..."
          />
        </div>
        {!disableChanges && rounds.length > 1 && (
          <div className="input-group pl-20">
            <p>Change for all Rounds</p>
            <Switch
              onColor="#1e7a69"
              checked={cfa}
              className={cfa ? "scoring-switch switch-on" : "scoring-switch"}
              onChange={() => {
                setCFA((current) => {
                  // handleSR(!current);
                  return !current;
                });
              }}
            />
          </div>
        )}
      </div>

      {!disableChanges && (
        <button onClick={saveChanges} className="default-button ml-20">
          Save Changes
        </button>
      )}

      <div className="epl-pl">
        <div className="epl-player epl-header">
          <div className="epl-name">
            <p>Name</p>
          </div>
          {courseOpts.length > 1 && (
            <div className="epl-crs">
              <p>Course</p>
            </div>
          )}
          <div className="epl-tee">
            <p>Tee</p>
          </div>
          <div className="epl-hi">
            <p>{isMobile || true ? "HI" : "Handicap Index"}</p>
          </div>
          <div className="epl-edit"></div>

          <div className="epl-hi">
            <p>{isMobile || true ? "PH" : "Playing Handicap"}</p>
          </div>
          <div className="epl-edit"></div>
        </div>

        {players.map((player, i) => {
          if (search !== "") {
            if (!player.name.toLowerCase().includes(search.toLowerCase())) {
              return null;
            }
          }
          //   console.log(player.gender);
          const round = rounds[selRound];

          const playerID = player.playerID;

          let custom = false;
          let customIndex = false;

          if (round.playerIndexes[playerID]) {
            customIndex = true;
          }

          const playerCourseID =
            rounds[selRound].courseSelections[playerID] ?? courseID;
          const course = courseMap[playerCourseID];
          //   if (i === 0) {
          //     console.log(playerCourseID, courseMap);
          //   }
          const playerTee =
            round.teeMap[playerID] ?? courseMap[playerCourseID].teeArray[0];
          let hcpIndex = round.playerIndexes[playerID] ?? player.handicap;
          let playingHcp;

          if (useLowHI && player.lowHI) {
            console.log("LOWHI");
            hcpIndex = player.lowHI;
          }

          if (typeof hcpIndex === "string") {
            hcpIndex = parseFloat(hcpIndex);
          }

          if (round.playerHandicaps[playerID] !== undefined) {
            playingHcp = round.playerHandicaps[playerID];
            custom = true;
          } else {
            if (course.sr && sr) {
              if (player.gender === "male") {
                const { slope, ratings, par } = course;
                // console.log(
                //   slope[playerTee],
                //   ratings[playerTee],
                //   par[playerTee]
                // );

                let ps = slope[playerTee];
                if (typeof ps === "string") {
                  ps = parseFloat(ps);
                }
                let pr = ratings[playerTee];
                if (typeof pr === "string") {
                  pr = parseFloat(pr);
                }

                playingHcp = Math.round(
                  (hcpIndex * ps) / 113 + pr - par[playerTee]
                );
              } else {
                const { femSlope, femRatings, par } = course;
                //   console.log(slope, ratings);
                let ps = femSlope[playerTee];
                if (typeof ps === "string") {
                  ps = parseFloat(ps);
                }
                let pr = femRatings[playerTee];
                if (typeof pr === "string") {
                  pr = parseFloat(pr);
                }
                playingHcp = Math.round(
                  (hcpIndex * ps) / 113 + pr - par[playerTee]
                );
              }

              if (adjusted) {
                playingHcp = Math.round((playingHcp + 4) * 0.8);
              }

              playingHcp = Math.round((playingHcp * sga) / 100);
            } else {
              playingHcp = Math.round(hcpIndex);
              if (adjusted) {
                playingHcp = Math.round((playingHcp + 4) * 0.8);
              }
              playingHcp = Math.round((playingHcp * sga) / 100);
            }
          }

          if (playingHcp > handicapLimit) {
            playingHcp = handicapLimit;
          }

          return (
            <div key={player.playerID} className="epl-player">
              <div className="epl-name">
                <p>{player.name}</p>
              </div>
              {courseOpts.length > 1 && (
                <div className="epl-crs ">
                  {!loading && (
                    <Dropdown
                      disabled={disableChanges}
                      arrowClosed={
                        <KeyboardArrowDownIcon className="drop-icon" />
                      }
                      arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                      value={
                        rounds[selRound].courseSelections[playerID] ?? courseID
                      }
                      options={courseOpts}
                      onChange={(e) => {
                        setRounds((c) => {
                          c[selRound].courseSelections[playerID] = e.value;
                          return c;
                        });
                        update();
                      }}
                    />
                  )}
                </div>
              )}
              <div className="epl-tee">
                {!loading && (
                  <Dropdown
                    disabled={disableChanges}
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                    value={
                      rounds[selRound].teeMap[playerID] ??
                      courseMap[playerCourseID].teeArray[0]
                    }
                    options={courseMap[playerCourseID].teeArray}
                    onChange={(e) => {
                      setRounds((c) => {
                        c[selRound].teeMap[playerID] = e.value;
                        return c;
                      });
                      update();
                    }}
                  />
                )}
              </div>
              <div className="epl-hi epl-ph">
                {showIndexEdit && indexPlayerEdit === player.playerID ? (
                  <input
                    className="default-input"
                    type="number"
                    value={indexEditInput}
                    onChange={(e) => setIndexEditInput(e.target.value)}
                  />
                ) : (
                  <p>{hcpIndex < 0 ? `+${-hcpIndex}` : hcpIndex}</p>
                )}
              </div>
              <div className="epl-edit">
                {showIndexEdit &&
                !disableChanges &&
                indexPlayerEdit === player.playerID ? (
                  <div className="flex flex-align-center">
                    <Undo
                      onClick={() => {
                        setShowIndexEdit(false);
                        setIndexPlayerEdit("");
                        setIndexEditInput(0);
                        update();
                      }}
                      className="icon-cursor red-icon-bg mr-20"
                    />
                    <Save
                      onClick={() => {
                        if (indexPlayerEdit < -10 || indexPlayerEdit > 54) {
                          setError(
                            "Please enter a playing handicap between -10 and 54"
                          );
                          return;
                        }

                        setRounds((c) => {
                          c[selRound].playerIndexes[playerID] =
                            parseInt(indexEditInput);

                          delete c[selRound].playerHandicaps[playerID];

                          return c;
                        });
                        setShowIndexEdit(false);
                        setIndexPlayerEdit("");
                        setIndexEditInput(0);
                        update();
                      }}
                      className="icon-cursor dg-icon-bg mr-20"
                    />
                  </div>
                ) : (
                  <div className="flex flex-align-center">
                    {!disableChanges && (
                      <Edit
                        onClick={() => {
                          setIndexPlayerEdit(player.playerID);
                          setIndexEditInput(hcpIndex);
                          update();
                          setShowIndexEdit(true);
                        }}
                        className="icon-cursor dg-icon-bg mr-20"
                      />
                    )}
                    {customIndex && !disableChanges && (
                      <DeleteForever
                        onClick={() => {
                          setRounds((c) => {
                            delete c[selRound].playerIndexes[playerID];
                            return c;
                          });
                          update();
                        }}
                        className="icon-cursor red-icon-bg mr-20"
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="epl-hi epl-ph">
                {showEdit && playerEdit === player.playerID ? (
                  <input
                    className="default-input"
                    type="number"
                    value={editInput}
                    onChange={(e) => setEditInput(e.target.value)}
                  />
                ) : (
                  <p>{playingHcp < 0 ? `+${-playingHcp}` : playingHcp}</p>
                )}
              </div>
              <div className="epl-edit">
                {showEdit && playerEdit === player.playerID ? (
                  <div className="flex flex-align-center">
                    <Undo
                      onClick={() => {
                        setShowEdit(false);
                        setPlayerEdit("");
                        setEditInput(0);
                        update();
                      }}
                      className="icon-cursor red-icon-bg mr-20"
                    />
                    <Save
                      onClick={() => {
                        if (playerEdit < -10 || playerEdit > 54) {
                          setError(
                            "Please enter a playing handicap between -10 and 54"
                          );
                          return;
                        }

                        setRounds((c) => {
                          c[selRound].playerHandicaps[playerID] =
                            parseInt(editInput);
                          delete c[selRound].playerIndexes[playerID];
                          return c;
                        });
                        setShowEdit(false);
                        setPlayerEdit("");
                        setEditInput(0);
                        update();
                      }}
                      className="icon-cursor dg-icon-bg mr-20"
                    />
                  </div>
                ) : (
                  <div className="flex flex-align-center">
                    {!disableChanges && (
                      <Edit
                        onClick={() => {
                          setPlayerEdit(player.playerID);
                          setEditInput(playingHcp);
                          update();
                          setShowEdit(true);
                        }}
                        className="icon-cursor dg-icon-bg mr-20"
                      />
                    )}
                    {custom && !disableChanges && (
                      <DeleteForever
                        onClick={() => {
                          setRounds((c) => {
                            delete c[selRound].playerHandicaps[playerID];
                            return c;
                          });
                          update();
                        }}
                        className="icon-cursor red-icon-bg mr-20"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EventPlayerList;
