import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

// Icons

// Components
import { Oval } from "react-loader-spinner";
import TimeItem from "../../display/TeeTimes/TimeItem";
function EventDashTeeTimes({ eventID = "", roundID = "" }) {
  // State
  const [loading, setLoading] = useState(true);
  const [times, setTimes] = useState([]);

  // UE
  useEffect(() => {
    loadTeeTimes();
  }, []);

  async function loadTeeTimes() {
    const roundDoc = await db.collection("rounds").doc(roundID).get();
    const round = roundDoc.data();
    const { leaderboard, format, playerIDs } = round;
    let teeTimes = round.teeTimes ?? round.matches;
    if (teeTimes.length === 0) {
      return setLoading(false);
    }

    if (round.cup) {
      if (format === "Matchplay") {
        for (let i = 0; i < teeTimes.length; i++) {
          const mtc = teeTimes[i];
          const { playerOne, playerTwo } = mtc;
          teeTimes[i].players = [playerOne, playerTwo];
        }
      } else {
        for (let i = 0; i < teeTimes.length; i++) {
          const mtc = teeTimes[i];
          const { teamOne, teamTwo } = mtc;
          const players = [];
          for (let j = 0; j < teamOne.length; j++) {
            players.push(teamOne[j]);
          }
          for (let j = 0; j < teamTwo.length; j++) {
            players.push(teamTwo[j]);
          }
          teeTimes[i].players = players;
        }
      }
      setTimes(teeTimes);
      setLoading(false);
    } else {
      // Check types
      const firstGroup = teeTimes[0] ?? {};
      const players = firstGroup.players ?? firstGroup.playerIDs ?? [];
      const firstPlayer = players[0];
      let convertTime = false;
      const firstTime = firstGroup.time;
      if (typeof firstTime === "string") {
      } else {
        convertTime = true;
      }

      if (convertTime) {
        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];
          console.log(group.time);
          if (group.time !== "") {
            const timeObj = new Date(group.time.toDate());
            const timeString = timeObj.toLocaleTimeString("en-us", {
              timeStyle: "short",
            });
            teeTimes[i].time = timeString;
          }
        }
      }
      // return console.log(teeTimes);
      // Load details from leaderboard
      if (typeof firstPlayer === "string") {
        const playerMap = {};

        if (format.includes("team")) {
          const refs = playerIDs.map((uid) =>
            db.collection("users").doc(uid).get()
          );
          const docs = await Promise.all(refs);

          for (let i = 0; i < docs.length; i++) {
            const playerDoc = docs[i];
            const player = playerDoc.data();
            const playerID = playerDoc.id;
            const { firstName, lastName } = player;
            playerMap[playerID] = { name: `${firstName} ${lastName}` };
          }
        } else {
          leaderboard.forEach(
            (player) => (playerMap[player.playerID] = player)
          );
        }
        for (let i = 0; i < teeTimes.length; i++) {
          const group = teeTimes[i];
          const players = group.players ?? group.playerIDs;
          if (group.players === undefined) {
            teeTimes[i].players = [];
          }
          for (let j = 0; j < players.length; j++) {
            const uid = players[j];
            teeTimes[i].players[j] = playerMap[uid].name;
          }
        }
        setTimes(teeTimes);
        setLoading(false);
      } else {
        for (let i = 0; i < teeTimes.length; i++) {
          teeTimes[i].players = teeTimes[i].players.filter(
            (pl) => pl.name !== "Empty"
          );
        }

        setTimes(teeTimes);
        setLoading(false);
      }
    }
  }

  return (
    <div className="ed-tee-times">
      {loading && (
        <div className="edt-loading">
          <Oval
            color="#1e7a69"
            secondaryColor="#ffffff"
            height={40}
            width={40}
          />
        </div>
      )}

      {!loading && (
        <div className="scale-in-ps edt-box">
          {!loading &&
            times.map((time, index) => {
              return (
                <TimeItem
                  tee={time.startingTee}
                  time={time.time}
                  players={time.players}
                  key={`group${index}`}
                  name={time.name ?? ""}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}

export default EventDashTeeTimes;
