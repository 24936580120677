import React, { useState, useEffect } from "react";
import "./Settings.css";
import { admin, db, auth } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

// Icons
import PaidIcon from "@mui/icons-material/Paid";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// Components
import Switch from "react-ios-switch/lib/Switch";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import { Checkbox, Tab, Tabs } from "@mui/material";
import { Dialog } from "@mui/material";
import PaymentHistory from "./PaymentHistory";
import Pill from "../../components/Buttons/Pill";
import Onboarding from "../../components/SignUp/Onboarding";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import SuccessModal from "../../components/ErrorModal/SuccessModal";
import PageHeader from "../../components/display/Rows/PageHeader";
import { featuresProp } from "../../components/HelperFunctions";
import { toast } from "react-toastify";
import { successIco } from "../../components/ClientStats/HelperFunctions";

function Settings() {
  const [settings, setSettings] = useState({
    joinedLeague: true,
    leftLeague: true,
    pastEvents: true,
    joinedEvent: true,
    leftEvent: true,
    homeEvents: true,
    homeNotifications: true,
    leagueTables: true,
    showQuickMenu: true,
    showHelper: false,
  });

  const sdOpts = [
    "Add Event",
    "Add Team Event",
    "Add League",
    "Add Team League",
    "Add User",
    "View Calendar",
  ];

  const { currentUser, portalSettings, userDetails, clubFeatures } = useAuth();

  const [accountType, setAccountType] = useState("free");
  const [features, setFeatures] = useState(featuresProp);

  const [email, setEmail] = useState("");
  const [selSDOpts, setSelSDOpts] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selTab, setSelTab] = useState("notifications");
  const [success, setSuccess] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [subType, setSubType] = useState("monthly");
  const [subscriptionID, setSubscriptionID] = useState("");
  const [nextRun, setNextRun] = useState("");

  const [showCancel, setShowCancel] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const [defaultClub, setDefaultClub] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [clubOptions, setClubOptions] = useState("");

  const [expiry, setExpiry] = useState("");

  const [cancelInput, setCancelInput] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    if (currentUser) {
      loadSettings();
    }
  }, [currentUser]);

  useEffect(() => {
    if (userDetails) {
      setAccountType(userDetails.accountType);
    }
  }, [userDetails]);

  useEffect(() => {
    if (clubFeatures) {
      // console.log(clubFeatures);
      setFeatures(clubFeatures);
    }
  }, [clubFeatures]);

  async function loadSettings() {
    const userDoc = await db.collection("users").doc(currentUser.uid).get();
    const user = userDoc.data();
    const portalSettings = user.portalSettings ?? {
      joinedLeague: true,
      leftLeague: true,
      joinedEvent: true,
      leftEvent: true,
      homeEvents: true,
      homeNotifications: true,
      pastEvents: true,
      leagueTables: true,
      showQuickMenu: true,
    };
    if (portalSettings.showQuickMenu === undefined) {
      portalSettings.showQuickMenu = true;
    }
    const userSDOpts = user.sdOpts ?? sdOpts;
    setSelSDOpts(userSDOpts);
    setSubType(user.portalType);
    setSubscriptionID(user.subscriptionID);
    if (user.nextRun) {
      if (typeof user.nextRun !== "string") {
        const nextRun = new Date(user.nextRun.toDate());
        const nrString = nextRun.toLocaleDateString("en-us", {
          dateStyle: "medium",
        });
        setNextRun(nrString);
      }
    }
    if (user.nextRun === "") {
      if (user.portalExpiry) {
        const expiryDate = new Date(user.portalExpiry.toDate());
        const edString = expiryDate.toLocaleDateString("en-us", {
          dateStyle: "medium",
        });
        setExpiry(edString);
      }
    }

    const clubArr = [{ value: "", label: "None" }];
    const clubDocs = await db
      .collection("clubs")
      .where("adminIDs", "array-contains", currentUser.uid)
      .get();

    for (let i = 0; i < clubDocs.docs.length; i++) {
      const clubdoc = clubDocs.docs[i];
      const clubID = clubdoc.id;
      const club = clubdoc.data();
      clubArr.push({
        label: club.clubName,
        value: clubID,
      });
    }

    setClubOptions(clubArr);

    setDefaultClub(portalSettings.clubID ?? "");

    setSettings(portalSettings);
    setLoading(false);
  }

  async function saveChanges() {
    setLoading(true);
    portalSettings.clubID = defaultClub;
    await db.collection("users").doc(currentUser.uid).update({
      portalSettings: settings,
      sdOpts: selSDOpts,
    });
    setLoading(false);
    toast.success("Your changes were successfully saved", {
      icon: successIco,
    });
    // setSuccess("Your changes were successfully saved");
  }

  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  }

  async function changeMail() {
    if (validateEmail(email) === false) {
      return setError("Sorry - seems like that email address is invalid");
    }

    setLoading(true);
    try {
      await auth.currentUser.updateEmail(email);
      await db.collection("users").doc(currentUser.uid).update({
        emailAddress: email,
      });
      setEmail("");
      toast.success("Your email address was successfully changed", {
        icon: successIco,
      });
      // setSuccess("Your email address was successfully changed");
    } catch (err) {
      console.log(err);
      setError("Could not change your email address, please try again");
    }
    setLoading(false);
  }

  async function savePassword() {
    setLoading(true);

    try {
      await admin.auth().currentUser.updatePassword(newPassword);
      setNewPassword("");
      setNewPasswordConfirm("");
      setCurrentPassword("");
      toast.success("Your password was successfully changed", {
        icon: successIco,
      });
      // setSuccess("Your password was successfully changed");
      // setSuccess(true);
    } catch (err) {
      console.log(err);
      return setError(
        "Sorry! We encountered some technical difficulties there, please try again"
      );
    }
    setLoading(false);
  }

  async function cancelSubscription() {
    db.collection("triggers").add({
      userID: currentUser.uid,
      subscriptionID,
      type: "cancel-sub",
    });
  }

  return (
    <div className="settings-page">
      <Dialog open={showCancel} onClose={() => setShowCancel(false)}>
        <div className="cancel-sub">
          <div className="page-header">
            <h2>Cancel Subscription</h2>
          </div>
          <div className="ph-msg">
            <p>Sorry to see you go</p>
          </div>
          <div className="input-group">
            <p>Type "CONFIRM" to confirm</p>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}

      {show ? (
        toShow
      ) : (
        <>
          <PageHeader text="Settings" />

          <div className="sub-header">
            <h5>Quick Links</h5>
          </div>

          <div className="quick-links">
            <Pill
              onClick={() => {
                setToShow(<Onboarding edit goBack={() => setShow(false)} />);
                setShow(true);
              }}
              text={"Edit Profile"}
            />
          </div>

          <div className="ec-tabs">
            <Tabs value={selTab} onChange={(e, value) => setSelTab(value)}>
              {userDetails && (userDetails.test || userDetails.admin) && (
                <Tab label="General" value="general" />
              )}
              <Tab label="Notifications" value="notifications" />
              <Tab label="Quick Menu" value="qm" />
              <Tab label="Account" value="account" />
            </Tabs>
          </div>

          {accountType === "admin" && (
            <>
              <div className="sub-header">
                <h5>Account</h5>
              </div>

              <div className="sub-details mb-40">
                <div className="input-group">
                  <p>Default Club</p>
                  <Dropdown
                    value={defaultClub}
                    onChange={(e) => {
                      setDefaultClub(e.value);
                    }}
                    options={clubOptions}
                    arrowClosed={
                      <KeyboardArrowDownIcon className="drop-icon" />
                    }
                    arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  />
                </div>
              </div>
            </>
          )}

          {false && (
            <>
              <div className="sub-header">
                <h5>Subscription</h5>
              </div>

              <div className="ph-msg mt-10">
                <p>Edit or cancel your subscription here</p>
              </div>

              <div className="sub-details">
                <div className="sd-item">
                  <p>Subscription Type:</p>
                  <h5>{subType}</h5>
                </div>
                {subType !== "once" && subType !== "free" && nextRun !== "" && (
                  <div className="sd-item">
                    <p>Next Payment Date:</p>
                    <h5>{nextRun}</h5>
                  </div>
                )}
                {expiry !== "" && (
                  <div className="sd-item">
                    <p>Expiry Date:</p>
                    <h5>{expiry}</h5>
                  </div>
                )}
              </div>

              <div className="sp-group">
                <div
                  onClick={() => {
                    setToShow(<PaymentHistory goBack={() => setShow(false)} />);
                    setShow(true);
                  }}
                  className="sp-item spi-but"
                >
                  <p>View Payment History</p>
                  <PaidIcon className="dg-icon" />
                </div>
                <div
                  onClick={() => {
                    window.open(
                      `https://www.payfast.co.za/eng/recurring/update/${subscriptionID}?return=https://www.theugatour.com/`
                    );
                  }}
                  className="sp-item spi-but"
                >
                  <p>Change Card Details</p>
                  <CreditCardIcon className="dg-icon" />
                </div>
                <div
                  onClick={() => setShowCancel(true)}
                  className="sp-item spi-but"
                >
                  <p>Cancel Subscription</p>
                  <CancelIcon className="sp-icon-red" />
                </div>
              </div>
            </>
          )}

          {accountType === "club" && selTab === "home" && (
            <>
              <div className="sub-header">
                <h5>Home Page</h5>
              </div>
              <div className="ph-msg mt-10">
                <p>Select which items appear on the home screen</p>
              </div>
              <div className="sp-group">
                <div className="sp-item">
                  <p>Upcoming Events</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.homeEvents}
                    className={
                      settings.homeEvents
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        console.log(current);
                        current.homeEvents = !current.homeEvents;
                        console.log(current);

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
                <div className="sp-item">
                  <p>Past Events</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.pastEvents}
                    className={
                      settings.pastEvents
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        current.pastEvents = !current.pastEvents;

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
                <div className="sp-item">
                  <p>Latest Notifications</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.homeNotifications}
                    className={
                      settings.homeNotifications
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        console.log(current);
                        current.homeNotifications = !current.homeNotifications;
                        console.log(current);

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
                <div className="sp-item">
                  <p>League Tables</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.leagueTables}
                    className={
                      settings.leagueTables
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        current.leagueTables = !current.leagueTables;

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {selTab === "general" && (
            <>
              <div className="sp-group">
                <div className="sp-item">
                  <p>Show Helper</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.showHelper}
                    className={
                      settings.showHelper
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        console.log(current);
                        current.showHelper = !current.showHelper;
                        console.log(current);

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {selTab === "notifications" && (
            <>
              <div className="sub-header">
                <h5>Notifications</h5>
              </div>
              <div className="ph-msg mt-10">
                <p>Select which occurrences you wish to be notified of</p>
              </div>
              <div className="sp-group">
                <div className="sp-item">
                  <p>Player joins one of your leagues</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.joinedLeague}
                    className={
                      settings.joinedLeague
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        console.log(current);
                        current.joinedLeague = !current.joinedLeague;
                        console.log(current);

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
                <div className="sp-item">
                  <p>Player leaves one of your leagues</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.leftLeague}
                    className={
                      settings.leftLeague
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        console.log(current);
                        current.leftLeague = !current.leftLeague;
                        console.log(current);

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
                <div className="sp-item">
                  <p>Player joins one of your events</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.joinedEvent}
                    className={
                      settings.joinedEvent
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        console.log(current);
                        current.joinedEvent = !current.joinedEvent;
                        console.log(current);

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
                <div className="sp-item">
                  <p>Player leaves one of your events</p>
                  <Switch
                    onColor="#1e7a69"
                    checked={settings.leftEvent}
                    className={
                      settings.leftEvent
                        ? "scoring-switch switch-on"
                        : "scoring-switch"
                    }
                    onChange={() => {
                      setSettings((current) => {
                        console.log(current);
                        current.leftEvent = !current.leftEvent;
                        console.log(current);

                        return current;
                      });
                      setUpdate((current) => current + 1);
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {(accountType === "club" || accountType === "admin") &&
            selTab === "qm" && (
              <>
                <div className="sub-header">
                  <h5>Quick Menu</h5>
                </div>

                <div className="ph-msg mt-10">
                  <p>
                    Select whether to show the Quick Menu or not, and select
                    which items are displayed.
                  </p>
                </div>
                <div className="set-sds">
                  <div className="sp-group">
                    <div className="sp-item" style={{ marginBottom: "0px" }}>
                      <p>Show Quick Menu</p>
                      <Switch
                        onColor="#1e7a69"
                        checked={settings.showQuickMenu}
                        className={
                          settings.showQuickMenu
                            ? "scoring-switch switch-on"
                            : "scoring-switch"
                        }
                        onChange={() => {
                          setSettings((current) => {
                            console.log(current);
                            current.showQuickMenu = !current.showQuickMenu;
                            // console.log(current);

                            return current;
                          });
                          setUpdate((current) => current + 1);
                        }}
                      />
                    </div>
                  </div>

                  {settings.showQuickMenu && (
                    <>
                      <div className="eve-rds">
                        {sdOpts.map((opt, i) => {
                          if (
                            (opt === "Add Event" || opt === "View Calendar") &&
                            features.events !== true
                          ) {
                            return null;
                          }
                          if (
                            (opt === "Add Team Event" ||
                              opt === "Add Team League") &&
                            features.teamLeagues !== true
                          ) {
                            return null;
                          }
                          if (
                            opt === "Add League" &&
                            features.leagues !== true
                          ) {
                            return null;
                          }

                          return (
                            <div className="eve-rd-item" key={opt}>
                              <Checkbox
                                checked={selSDOpts.includes(opt)}
                                onChange={(e) => {
                                  setSelSDOpts((c) => {
                                    if (c.includes(opt)) {
                                      const filt = c.filter(
                                        (val) => val !== opt
                                      );
                                      return filt;
                                    } else {
                                      c.push(opt);
                                      return c;
                                    }
                                  });
                                  setUpdate((c) => c + 1);
                                }}
                              />
                              <p>{opt}</p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          {selTab === "account" && (
            <>
              <div className="sub-header">
                <h5>Change Password</h5>
              </div>

              {/* <div className="ec-box"></div> */}

              <div className="ec-box">
                <div className="input-group">
                  <p>Current Password</p>
                  <input
                    placeholder="Start typing..."
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    type="password"
                    className="default-input"
                    value={currentPassword}
                  />
                </div>
                <div className="input-group">
                  <p>New Password (min 6 characters)</p>
                  <input
                    placeholder="Start typing..."
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                    className="default-input"
                    value={newPassword}
                  />
                </div>
                <div className="input-group">
                  <p>Confirm New Password</p>
                  <input
                    placeholder="Start typing..."
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    type="password"
                    className="default-input"
                    value={newPasswordConfirm}
                  />
                </div>
                <button
                  disabled={
                    currentPassword === "" ||
                    newPassword === "" ||
                    newPassword.length < 6 ||
                    newPassword !== newPasswordConfirm
                  }
                  onClick={savePassword}
                  className="green-link"
                >
                  Save
                </button>
              </div>
              <div className="sub-header">
                <h5>Change Email</h5>
              </div>
              {currentUser && (
                <div className="ph-msg mt-20">
                  <p>Current: {currentUser.email}</p>
                </div>
              )}
              <div className="ec-box">
                <div className="input-group">
                  <p>New Email Address</p>
                  <input
                    className="default-input"
                    placeholder="Start typing"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                  />
                </div>
                <button
                  className="green-link"
                  onClick={changeMail}
                  disabled={email === ""}
                >
                  Save
                </button>
              </div>
            </>
          )}
          {selTab !== "account" && (
            <div className="default-button-row">
              <button onClick={() => saveChanges()} className="default-button">
                Save Changes
              </button>
            </div>
          )}
        </>
      )}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {success !== "" && (
        <SuccessModal hide={() => setSuccess("")} text={success} />
      )}
    </div>
  );
}

export default Settings;
