import React, { useState, useEffect } from "react";
import "./UserImport.css";
import "animate.css";

import BackdropLoader from "../Loaders/BackdropLoader";
import { useAuth } from "../../contexts/AuthContext";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { Autocomplete, TextField } from "@mui/material";
import { Country, State, City } from "country-state-city";
import { db } from "../../firebase";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import PageHeader from "../display/Rows/PageHeader";

function UserImport({
  goBack,
  userID = "",
  edit = false,
  clubID = "",
  added = false,
}) {
  const platformOptions = [
    "Clubmaster",
    "Chronogolf",
    "Golf RSA",
    "Membership ID",
    "Custom",
  ];

  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [failed, setFailed] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [handicap, setHandicap] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [lowHI, setLowHI] = useState("");
  const [gender, setGender] = useState("male");
  const [playerClubName, setPlayerClubName] = useState("");
  const [clubHNAID, setClubHNAID] = useState("");

  const [platformID, setPlatformID] = useState("");
  const [platform, setPlatform] = useState("");

  const [error, setError] = useState("");

  const [hnaID, setHNAID] = useState("");

  const [update, setUpdate] = useState(0);

  const [customPlatform, setCustomPlatform] = useState("");

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [leagues, setLeagues] = useState([]);
  const [leagueID, setLeagueID] = useState("");

  const [rounds, setRounds] = useState(0);

  const [trigUnsub, setTrigUnsub] = useState(false);

  useEffect(() => {
    console.log(userID);
    console.log(added);
    if (userID) {
      loadUser();
    }
    if (currentUser) {
      loadCountries();
      loadLeagues();
    }
  }, [currentUser]);

  async function loadUser() {
    const playerDoc = await db.collection("users").doc(userID).get();
    const player = playerDoc.data();
    const {
      firstName,
      lastName,
      city,
      country,
      state,
      handicap,
      emailAddress,

      dob,
    } = player;
    setLowHI(player.lowHI ?? "");
    const hcpArray = player.hcpArray ?? [];
    const dateObj = new Date(dob.toDate());
    // console.log(dateObj);
    const dateString = dateObj.toISOString().split("T")[0];
    setClubHNAID(player.clubHNAID ?? "");
    setPlayerClubName(player.playerClubName ?? "");
    setHNAID(player.hnaID ?? "");
    setGender(player.gender ?? "male");
    setRounds(hcpArray.length);
    setDob(dateString);
    setEmailAddress(emailAddress);
    setFirstName(firstName);
    setLastName(lastName);
    setCity(city);
    setCountry(country);
    setHandicap(handicap);
    setState(state);
  }

  async function loadLeagues() {
    const leagues = await db
      .collection("leagues")
      .where("admins", "array-contains", currentUser.uid)
      .get();
    const arr = [];

    for (let i = 0; i < leagues.docs.length; i++) {
      const league = leagues.docs[i].data();
      const leagueID = leagues.docs[i].id;

      arr.push({
        label: league.leagueName,
        value: leagueID,
      });
    }
    // console.log(arr);
    setLeagues(arr);
    setLoading(false);
  }

  async function loadCountries() {
    const cunts = Country.getAllCountries();
    const newArr = cunts.map((country) => {
      // console.log(country)
      const item = {
        name: country.name,
        label: country.name,
        value: country.name,
        code: country.isoCode,
      };
      return item;
    });
    // console.log(newArr)
    setCountries(newArr);
  }

  async function loadStates(country) {
    // return console.log(country.code);
    const states = State.getStatesOfCountry(country.code);

    for (let i = 0; i < states.length; i++) {
      states[i].label = states[i].name;
    }
    // return console.log(states);
    const countryDeets = Country.getCountryByCode(country.code);
    setCountry(countryDeets.name);
    setStates(states);
  }

  async function loadCities(state) {
    const test = State.getStatesOfCountry(country);
    console.log(test.length, state);
    let sc = "";
    let cc = "";
    for (let i = 0; i < test.length; i++) {
      if (test[i].name === state.name) {
        sc = test[i].isoCode;
        cc = test[i].countryCode;
      }
    }
    console.log(sc, cc);
    const cities = City.getCitiesOfState(cc, sc);

    const newArr = cities.map((city) => {
      const item = {
        name: city.name,
        value: city.name,
        label: city.name,
      };
      return item;
    });
    // console.log(newArr);
    setUpdate((current) => current + 1);
    setCities(newArr);
  }

  function validate() {
    setError("");

    // Names
    if (firstName === "") {
      setError("Please enter a first name");
      return false;
    }
    if (lastName === "") {
      setError("Please enter a last name");
      return false;
    }

    // Password
    if (password === "" && !edit) {
      setError("Please enter a password");
      return false;
    }

    // Dob
    if (dob === "") {
      setError("Please enter a date of birth");
      return false;
    }

    // Handicap
    if (handicap === "") {
      setError("Please enter a handicap");
      return false;
    }

    // Country
    if (country === "" && !edit) {
      setError("Please enter a country");
      return false;
    }

    // State
    if (state === "" && !edit) {
      setError("Please enter a state");
      return false;
    }

    // City
    if (city === "" && !edit) {
      setError("Please enter a city");
      return false;
    }
  }

  async function saveChanges() {
    // return console.log(hnaID);
    setFailed(false);
    if (validate() === false) {
      return;
    }

    setLoading(true);

    await db
      .collection("users")
      .doc(userID)
      .update({
        firstName,
        lastName,
        // dob: new Date(dob),
        handicap,
        gender,
        lowHI: lowHI !== "" ? parseFloat(lowHI) : "",
        playerClubName,
        hnaID,
        clubHNAID,
      });

    setLoading(false);
    goBack();
  }

  async function saveUser() {
    setFailed(false);
    if (validate() === false) {
      return;
    }

    setLoading(true);

    const trigger = await db.collection("triggers").add({
      firstName,
      lastName,
      emailAddress,
      password,
      handicap: parseFloat(handicap),
      city,
      country,
      state,
      dob,
      leagueID,
      status: "pending",
      type: "import-user",
      dateAdded: new Date(),
      addedBy: currentUser.uid,
      platform: platform === "Custom" ? customPlatform : platform,
      platformID,
      clubID,
      clubHNAID,
      hnaID,
      lowHI: lowHI !== "" ? parseFloat(lowHI) : "",
      playerClubName,
      gender,
    });

    setLoading(false);

    setWaiting(true);

    trigger.onSnapshot((doc) => {
      const trig = doc.data();

      if (trig.status === "completed") {
        setLoading(true);
        setTimeout(() => {
          setWaiting(false);
          setCompleted(true);
          setLoading(false);
        }, 500);
      }

      if (trig.status === "failed") {
        setLoading(true);
        setTimeout(() => {
          setWaiting(false);
          setFailed(true);
          setLoading(false);
        }, 500);
      }
    });
  }

  function resetValues() {
    setLoading(true);

    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setDob("");
    setPassword("");
    setHandicap("");
    setCountry("");
    setState("");
    setCity("");
    setCompleted(false);
    setLoading(false);
  }

  return (
    <div className="user-import">
      {loading && <BackdropLoader />}
      <div onClick={goBack} className="back-row">
        <ArrowBackIcon />
        <p>Back</p>
      </div>
      <PageHeader text={edit ? "Edit User" : "Add New User"} />

      {failed ? (
        <>
          <div className="ui-waiting">
            <p>Sorry - we encountered a technical difficulty there</p>
            <div className="default-button-row">
              <button onClick={saveUser} className="default-button">
                Try Again
              </button>
            </div>
          </div>
        </>
      ) : waiting ? (
        <>
          <div className="ui-waiting">
            <div className="uiw-spinner">
              <Oval
                color="#1e7a69"
                secondaryColor="#ffffff"
                height={40}
                width={40}
              />
            </div>
            <p>Creating {firstName}'s profile...</p>
          </div>
        </>
      ) : completed ? (
        <>
          <div className="ui-waiting">
            <p>Successfully created</p>
            <div className="default-button-row">
              <button onClick={resetValues} className="default-button">
                Add Another User
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="ui-inputs">
            {!(edit && !added) && (
              <div className="input-group">
                <p>First Name</p>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Start typing..."
                  className={
                    error !== "" && firstName === ""
                      ? "default-input di-error"
                      : "default-input"
                  }
                />
              </div>
            )}
            {!(edit && !added) && (
              <div className="input-group">
                <p>Last Name</p>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Start typing..."
                  className={
                    error !== "" && lastName === ""
                      ? "default-input di-error"
                      : "default-input"
                  }
                />
              </div>
            )}

            <div className="input-group">
              <p>Club Name</p>
              <input
                type="text"
                value={playerClubName}
                onChange={(e) => setPlayerClubName(e.target.value)}
                placeholder="Start typing..."
                className={
                  error !== "" && playerClubName === ""
                    ? "default-input di-error"
                    : "default-input"
                }
              />
            </div>

            {!edit && (
              <div className="input-group">
                <p>Email Address</p>
                <input
                  type="text"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  placeholder="Start typing..."
                  className={
                    error !== "" && emailAddress === ""
                      ? "default-input di-error"
                      : "default-input"
                  }
                />
              </div>
            )}

            {/* {rounds === 0 && ( */}
            <div className="input-group">
              <p>Handicap</p>
              <input
                type="number"
                value={handicap}
                onChange={(e) => {
                  if (e.target.value > 54 || e.target.value < -10) {
                    return;
                  }
                  setHandicap(e.target.value);
                }}
                placeholder="Start typing..."
                className={
                  error !== "" && handicap === ""
                    ? "default-input di-error"
                    : "default-input"
                }
              />
            </div>
            <div className="input-group">
              <p>Low HI</p>
              <input
                type="number"
                value={lowHI}
                onChange={(e) => {
                  if (e.target.value > 54 || e.target.value < -10) {
                    return;
                  }
                  setLowHI(e.target.value);
                }}
                placeholder="Start typing..."
                className={"default-input"}
              />
            </div>
            {/* )} */}
            {!edit && (
              <div className="input-group">
                <p>Password</p>
                <input
                  type="text"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Start typing..."
                  className={
                    error !== "" && password === ""
                      ? "default-input di-error"
                      : "default-input"
                  }
                />
              </div>
            )}
            <div className="input-group">
              <p>Date of Birth</p>
              <input
                type="date"
                value={dob}
                onChange={(e) => {
                  setDob(e.target.value);
                }}
                placeholder="Start typing..."
                className={
                  error !== "" && dob === ""
                    ? "default-input di-error"
                    : "default-input"
                }
              />
            </div>
            {!edit && (
              <>
                <div className="input-group select-group">
                  <p>Country</p>
                  <Autocomplete
                    // open
                    className={
                      error !== "" && country === "" ? "di-auto-error" : ""
                    }
                    value={country}
                    // open={true}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      loadStates(newValue);
                      setCountry(newValue.code);
                    }}
                    autoComplete={false}
                    popupIcon={null}
                    disablePortal
                    sx={{ width: 300 }}
                    options={countries}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select Country..."
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <div className="input-group select-group">
                  <p>State / Province</p>
                  <Autocomplete
                    className={
                      error !== "" && country === "" ? "di-auto-error" : ""
                    }
                    defaultValue={state}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      loadCities(newValue);
                      setState(newValue.name);
                    }}
                    // autoComplete={false}
                    disabled={country === ""}
                    popupIcon={null}
                    disablePortal
                    sx={{ width: 300 }}
                    options={states}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select State..."
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <div className="input-group select-group">
                  <p>City</p>
                  <Autocomplete
                    className={
                      error !== "" && country === "" ? "di-auto-error" : ""
                    }
                    defaultValue={city}
                    disabled={state === ""}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setCity(newValue.name);
                    }}
                    popupIcon={null}
                    disablePortal
                    sx={{ width: 300 }}
                    options={cities}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select City..."
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
              </>
            )}
            {!edit && (
              <div className="input-group select-group">
                <p>Add to League</p>
                <Autocomplete
                  defaultValue={leagueID}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setLeagueID(newValue.value);
                  }}
                  popupIcon={null}
                  disablePortal
                  sx={{ width: 300 }}
                  options={leagues}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select League..."
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </div>
            )}
            <div className="input-group">
              <p>Handicap ID</p>
              <input
                type="text"
                value={hnaID}
                onChange={(e) => {
                  setHNAID(e.target.value);
                }}
                placeholder="Start typing..."
                className={"default-input"}
              />
            </div>

            <div className="input-group">
              <p>Club Handicap ID</p>
              <input
                type="text"
                value={clubHNAID}
                onChange={(e) => {
                  setClubHNAID(e.target.value);
                }}
                placeholder="Start typing..."
                className={"default-input"}
              />
            </div>

            {!edit && (
              <div className="input-group select-group">
                <p>Select Platform</p>

                <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  options={platformOptions}
                  value={platform}
                  onChange={(e) => {
                    console.log(e.value);
                    setPlatform(e.value);
                  }}
                />
              </div>
            )}
            {platform === "Custom" && (
              <div className="input-group">
                <p>Custom Platform</p>
                <input
                  type="text"
                  value={customPlatform}
                  onChange={(e) => {
                    setCustomPlatform(e.target.value);
                  }}
                  placeholder="Start typing..."
                  className={"default-input"}
                />
              </div>
            )}
            {!edit && (
              <div className="input-group">
                <p>Platform ID</p>
                <input
                  type="text"
                  value={platformID}
                  onChange={(e) => {
                    setPlatformID(e.target.value);
                  }}
                  placeholder="Start typing..."
                  className={"default-input"}
                />
              </div>
            )}
            <div className="input-group">
              <p>Gender</p>
              <div className="selector-group pd-20">
                <button
                  onClick={() => setGender("male")}
                  className={
                    gender === "male" ? "sg-left sg-active" : "sg-left"
                  }
                >
                  Male
                </button>
                <button
                  onClick={() => setGender("female")}
                  className={
                    gender === "female" ? "sg-left sg-active" : "sg-left"
                  }
                >
                  Female
                </button>
              </div>
            </div>
          </div>
          {error !== "" && (
            <div className="error-message animate__animated animate__fadeInUp">
              <p>{error}</p>
            </div>
          )}
          <div className="default-button-row">
            {edit ? (
              <button onClick={saveChanges} className="default-button">
                Save Changes
              </button>
            ) : (
              <button onClick={saveUser} className="default-button">
                Save User
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default UserImport;
