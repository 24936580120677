import React, { useState } from "react";
import "./RadioGroup.css";

function RadioGroup({ buttons = [], active = "", setActive = () => {} }) {
  return (
    <div className="radio-group">
      <div className="RadioButton btn-group">
        {buttons.map((button, index) => (
          <div
            key={`${index}radio`}
            data-val={`${index}`}
            onClick={() => setActive(button.value)}
            className={
              active === button.value
                ? "btn btn-active btn-default"
                : "btn btn-default"
            }
          >
            {button.text}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RadioGroup;
