import React, { useRef, useState, useCallback } from "react";
import { db } from "../../firebase";
import Papa from "papaparse";

// Components
import BackRow from "../../components/display/Rows/BackRow";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PageHeader from "../../components/display/Rows/PageHeader";
import csvexample from "../../assets/PlayerExample.csv";
import { useDropzone } from "react-dropzone";

// Icons

function MatchplayUpload({
  tournamentID = "",
  tournamentType = "",
  goBack = () => {},
  clubID = "",
  reload = () => {},
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState([]);
  const [fileName, setFileName] = useState("");
  const [upload, setUpload] = useState([]);

  const [uploadJSON, setUploadJSON] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    Papa.parse(acceptedFiles[0], {
      complete: (res) => {
        const arr = [];
        const headerRow = res.data[0];
        let plCol = "";
        let idCol = "";
        let hcpCol = "";
        let timeCol = "";
        let roundCol = "";
        let teeColl = "";
        // console.log(headerRow);
        for (let i = 0; i < headerRow.length; i++) {
          if (headerRow[i] === "platform") {
            plCol = i;
          } else if (headerRow[i] === "platformID") {
            idCol = i;
          } else if (headerRow[i] === "handicap") {
            hcpCol = i;
          } else if (headerRow[i] === "teeTime") {
            timeCol = i;
          } else if (headerRow[i] === "round") {
            roundCol = i;
          } else if (headerRow[i] === "startingTee") {
            teeColl = i;
          }
        }

        if (plCol === "" || idCol === "") {
          return setError(
            "Sorry, we couldn't quite sort that out - please check your column headers!"
          );
        }

        for (let i = 1; i < res.data.length; i++) {
          const row = res.data[i];
          let hcp = row[hcpCol] ?? "";
          if (!isNaN(hcp)) {
            hcp = "";
          }
          if (hcp !== "") {
            hcp = parseInt(hcp);
          }
          let rnd = row[roundCol];
          if (rnd === "") {
            rnd = 1;
          }

          arr.push({
            platform: row[plCol],
            platformID: row[idCol],
            handicap: row[hcpCol] ?? "",
            teeTime: row[timeCol] ?? "",
            round: rnd,
            startingTee: row[teeColl] ?? 1,
          });
        }
        loadPlayers(arr);
        setUpload(res);
        setUploadJSON(arr);
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  async function loadPlayers(arr = []) {
    const plarr = [];

    setLoading(true);
    try {
      const clubDoc = await db.collection("clubs").doc(clubID).get();
      const clubData = clubDoc.data();
      const { memberIDs } = clubData;
      const refs = memberIDs.map((id) => db.collection("users").doc(id).get());

      const docs = await Promise.all(refs);

      console.log(docs.length);

      for (let i = 0; i < docs.length; i++) {
        const playerDoc = docs[i];
        const playerID = playerDoc.id;
        const player = playerDoc.data();
        if (!playerDoc.exists) {
          continue;
        }
        const { firstName, lastName, profileImage } = player;
        let found = false;
        if (i === 0) {
          //   console.log(platformIDs);
        }

        const platformIDs = player.platformIDs ?? [];

        for (let n = 0; n < arr.length; n++) {
          if (found) {
            continue;
          }
          const row = arr[n];
          console.log(row);
          const { platform, platformID, handicap } = row;

          if (i === 0) {
            // console.log(row);
          }
          for (let x = 0; x < platformIDs.length; x++) {
            const plRow = platformIDs[x];
            const rowPlatform = plRow.platform;
            const rowPlatformID = plRow.platformID;
            if (rowPlatform === platform && rowPlatformID === platformID) {
              if (row.teeTime) {
                const teeTime = row.teeTime;
                const startingTee = row.startingTee ?? 1;
                const round = row.round ?? 1;
                plarr.push({
                  playerID,
                  name: `${firstName} ${lastName}`,
                  platform,
                  platformID,
                  handicap,
                  teeTime,
                  startingTee,
                  round,
                  profileImage,
                });
              } else {
                plarr.push({
                  playerID,
                  name: `${firstName} ${lastName}`,
                  platform,
                  platformID,
                  handicap,
                  profileImage,
                });
              }

              found = true;
            }
          }
        }
      }
      console.log(plarr);
      setPlayers(plarr);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }
    setLoading(false);
  }

  async function savePlayers() {
    setLoading(true);
    const tournDoc = await db.collection("tournaments").doc(tournamentID).get();
    const tourn = tournDoc.data();
    const { playerIDs } = tourn;

    // Players

    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      console.log(player);
      const { playerID } = player;
      if (!playerIDs.includes(playerID)) {
        playerIDs.push(playerID);
      }
    }

    // console.log(playerIDs, rounds);
    // console.log(playerHandicaps, playerIDs, event);
    // return setLoading(false);

    try {
      await tournDoc.ref.update({
        playerIDs,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there - please try again."
      );
    }

    setLoading(false);
  }

  return (
    <div>
      <BackRow action={goBack} />
      <PageHeader text="Matchplay Player Upload" />
      <div className="ph-msg">
        <p>
          Upload players from a different platform for this event. Please note
          only members from your club can be added.
        </p>
      </div>
      {loading && <BackdropLoader />}
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <div className="bi-box">
        <div className="pd-20 bi-drop">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-sel">
              {fileName ? (
                <h5 className="filename">{fileName}</h5>
              ) : (
                <h5>
                  Drag 'n' drop a .csv file here, or click to open up the file
                  explorer
                </h5>
              )}
            </div>
          </div>
        </div>
        <div className="bi-info">
          <p>
            In order for the players to be added correctly, formatting is
            important but simple! Only a few fields are needed, the platform
            name and the relevant platform ID. <br />
            <br />
            <strong>The first row (header row) will be included.</strong> Name
            your colums as follows (letter case is important):
          </p>
          <ul>
            <li>platform</li>
            <li>platformID</li>
            {/* {tournamentType === "tournament" && <li>group (optional)</li>} */}
          </ul>
          <a href={csvexample} className="green-link mt-20">
            Download Sample
          </a>
          <p className="mt-20"></p>
        </div>
      </div>
      {players.length > 0 && (
        <div className="epu-players">
          <div className="sub-header">
            <h5>Selected Players</h5>
          </div>
          <div className="epup-box">
            {players.map((player, index) => (
              <div key={player.playerID} className={"flex-align-center"}>
                <p style={{ width: "30vw" }}>
                  {player.name}{" "}
                  {player.handicap !== "" && `(hcp ${player.handicap})`}{" "}
                </p>
                <p> {player.teeTime}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {uploadJSON.length > 0 && (
        <div className="pd-20">
          <button onClick={savePlayers} className="default-button">
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default MatchplayUpload;
