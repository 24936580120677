import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as Slider from "@radix-ui/react-slider";

// Components
import { ClipLoader } from "react-spinners";
import CustomDropdown from "./CustomDropdown";
import { DateRangePicker } from "react-date-range";
import { Autocomplete, TextField } from "@mui/material";
import RoundCard from "./RoundCard/RoundCard";
import { db } from "../../firebase";
const meters = true;
const propSeries = [
  {
    name: "Net Average",
    data: [72, 80, 70, 69, 75, 71, 78],
  },
  //   {
  //     name: "Gross Average",
  //     data: [81, 82, 79, 77, 78, 77, 82],
  //   },
];

const yLabels = {
  "Stroke Average": "Strokes",
  "Average Putts": "Putts",
  "Average Putt Length": "Distance",
  "Driving Distance": "Distance",
  Scrambling: "Percentage",
  "Birdie Conversion": "Percentage",
  "Average GIR": "Percentage",
  "Average FIR": "Percentage",
  "Par 3 Averages": "Strokes",
  "Par 4 Averages": "Strokes",
  "Par 5 Averages": "Strokes",
  "Last 12 Months": "Last 12 Mths",
  "Last 20 Rounds": "Scores",
  columns: "To Par",
  putting: "Putts",
  puttHoles: "Putts by hole",
  parAverages: "Average",
  firAverages: "Average",
  girAverages: "Average",
  puttLengths: "Putt Lengths",
  misses: "Missed",
};

function ClientStatsGraphs({
  stats = [],
  rounds = [],
  overallStats = {},
  lengthStats = [],
  userID = "",
}) {
  // Admin
  const graphOpts = [
    {
      label: "Line",
      value: "line",
    },
    {
      label: "Column",
      value: "column",
    },
    {
      label: "Pie",
      value: "pie",
    },
  ];

  const statOpts = {
    line: [
      {
        type: "group",
        name: "Averages",
        items: [
          {
            label: "Gross Strokes",
            value: "averageGross",
          },
          {
            label: "Putts / Round",
            value: "averagePutts",
          },
        ],
      },
      {
        type: "group",
        name: "Rounds",
        items: [
          {
            label: "Gross Strokes",
            value: "totalGrossStrokes",
          },
          {
            label: "Net Strokes",
            value: "totalNetStrokes",
          },
          {
            label: "Stableford Points",
            value: "totalPoints",
          },
          {
            label: "Total Putts",
            value: "totalPutts",
          },
          {
            label: "Fairways Hit",
            value: "totalFIR",
          },
          {
            label: "Greens Hit",
            value: "totalGIR",
          },
        ],
      },
    ],
    column: [],
    pie: [],
  };

  const statOpts2 = {
    line: [
      {
        label: "Gross Average",
        value: "averageGross",
        type: "Averages",
      },
      {
        label: "Putts / Round",
        value: "averagePutts",
        type: "Averages",
      },
      {
        label: "1 Putts / Round",
        value: "averageOnePutts",
        type: "Averages",
      },
      {
        label: "3 Putts / Round",
        value: "averageThreePutts",
        type: "Averages",
      },
      {
        label: "Fairways",
        value: "averageFIR",
        type: "Averages",
      },
      {
        label: "Greens in Regulation",
        value: "averageGIR",
        type: "Averages",
      },
      {
        label: "Average to Par",
        value: "averageToPar",
        type: "Averages",
      },
      {
        label: "Average to Par (Par 3s)",
        value: "parThreesToPar",
        type: "Averages",
      },
      {
        label: "Average to Par (Par 4s)",
        value: "parFoursToPar",
        type: "Averages",
      },
      {
        label: "Average to Par (Par 5s)",
        value: "parFivesToPar",
        type: "Averages",
      },
      {
        label: "Par 3 Averages",
        value: "averageParThrees",
        type: "Averages",
        gross: true,
      },
      {
        label: "Par 4 Averages",
        value: "averageParFours",
        type: "Averages",
        gross: true,
      },
      {
        label: "Par 5 Averages",
        value: "averageParFives",
        type: "Averages",
        gross: true,
      },
      {
        label: "Par 3 Averages (GIR)",
        value: "parThreeGrossGIRScoringAverage",
        type: "Averages",
      },
      {
        label: "Par 4 Averages (GIR)",
        value: "parFourGrossGIRScoringAverage",
        type: "Averages",
      },
      {
        label: "Par 5 Averages (GIR)",
        value: "parFiveGrossGIRScoringAverage",
        type: "Averages",
      },
      {
        label: "Par 4 Averages (FIR)",
        value: "parFourGrossGIRScoringAverage",
        type: "Averages",
      },
      {
        label: "Par 5 Averages (FIR)",
        value: "parFiveGrossGIRScoringAverage",
        type: "Averages",
      },
      {
        label: "Scrambling (Ave)",
        value: "scramblePerc",
        type: "Averages",
      },
      {
        label: "Birdie Conversion (Ave)",
        value: "birdieConversion",
        type: "Averages",
      },
      {
        label: "Gross Strokes",
        value: "totalGrossStrokes",
        type: "Rounds",
      },
      {
        label: "Net Strokes",
        value: "totalNetStrokes",
        type: "Rounds",
      },
      {
        label: "Stableford Points",
        value: "totalPoints",
        type: "Rounds",
      },
      {
        label: "Total Putts",
        value: "totalPutts",
        type: "Rounds",
      },
      {
        label: "1 Putts",
        value: "onePutts",
        type: "Rounds",
      },
      {
        label: "Three Putts",
        value: "threePutts",
        type: "Rounds",
      },
      {
        label: "Fairways Hit",
        value: "totalFIR",
        type: "Rounds",
      },
      {
        label: "Greens Hit",
        value: "totalGIR",
        type: "Rounds",
      },
      {
        label: "Par 3s To Par",
        value: "parThreesToPar",
        type: "Rounds",
      },
      {
        label: "Par 4s To Par",
        value: "parFoursToPar",
        type: "Rounds",
      },
      {
        label: "Par 5s To Par",
        value: "parFivesToPar",
        type: "Rounds",
      },
      {
        label: "Par 3s",
        value: "averageParThrees",
        type: "Rounds",
      },
      {
        label: "Par 4s",
        value: "averageParFours",
        type: "Rounds",
      },
      {
        label: "Par 5s",
        value: "averageParFives",
        type: "Rounds",
      },
      {
        label: "Par 3s (GIR)",
        value: "parThreeGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 4s (GIR)",
        value: "parFourGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 5s (GIR)",
        value: "parFiveGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 4s (FIR)",
        value: "parFourGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Par 5s (FIR)",
        value: "parFiveGrossGIRScoringAverage",
        type: "Rounds",
      },
      {
        label: "Birdie Conversion",
        value: "birdieConversion",
        type: "Rounds",
      },
      {
        label: "Scrambling",
        value: "scramblePerc",
        type: "Rounds",
      },
    ],
    column: [
      {
        label: "Pars to Par",
        value: "parsToPar",
      },
      {
        label: "Pars to Par (GIR)",
        value: "parsToParGIR",
      },
      {
        label: "Par 3s by Distance",
        value: "par3sByDistance",
      },
      {
        label: "Par 4s by Distance",
        value: "par4sByDistance",
      },
      {
        label: "Par 5s by Distance",
        value: "par5sByDistance",
      },
      {
        label: "Last 10 Rounds",
        value: "last10",
      },
      {
        label: "Last 12 Months",
        value: "last12",
      },
    ],
    pie: [
      {
        label: "Putting",
        value: "putting",
      },
    ],
  };

  // State
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();
  const [update, setUpdate] = useState(0);
  const [barLabels, setBarLabels] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ]);
  const [search, setSearch] = useState("");

  const [type, setType] = useState("line");
  const [selStat, setSelStat] = useState("");
  const [selStats, setSelStats] = useState([]);

  const [dateArr, setDateArr] = useState([]);
  const [selDate, setSelDate] = useState();

  const [period, setPeriod] = useState("rds");

  const [showDates, setShowDates] = useState(false);
  const [dateRge, setDateRge] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [dates, setDates] = useState([]);

  const [rdOpts, setRDOpts] = useState([]);

  const [rdAmt, setRdAmt] = useState("");
  const [series, setSeries] = useState([]);
  const [barSeries, setBarSeries] = useState([]);
  const [puttDist, setPuttDist] = useState([]);
  const [pieSeries, setPieSeries] = useState([]);

  const [selRds, setSelRds] = useState([1, rounds.length]);

  const proptions = {
    chart: {
      height: 350,
      type,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          // console.log(config);
          const idx = config.dataPointIndex + selRds[0] - 1;
          // console.log(idx);
          const roundID = rounds[idx].roundID;
          loadRd(roundID);
        },
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.3,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    colors: ["#6ba390", "#232c2e", "#A9363F"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      type: "date",
      categories: dates,
    },
    yaxis: {
      title: {
        text: selStat.label,
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      intersect: true,
      shared: false,
    },
  };

  const pieProp = {
    options: {
      colors:
        selStat.value === "putting"
          ? ["#6ba390", "#232c2e", "#A9363F"]
          : ["#FFD700", "#1e7a69", "#A9363F", "#232c2e"],
      dataLabels: {
        enabled: true,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      chart: {
        width: 400,
        type: "pie",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      legend: {
        position: "bottom",
      },
      labels:
        selStat.value === "Finishes"
          ? ["Wins", "Top 3", "Top 10", "Top 20"]
          : ["1-putts", "2-putts", "3-putts"],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };

  const barProp = {
    series: [
      {
        name: "PRODUCT A",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "PRODUCT B",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "PRODUCT C",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "PRODUCT D",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      colors: ["#6ba390", "#db5461", "#232c2e"],
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        stackType: "100",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: false,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.3,
        },
      },
      //   plotOptions: {
      //     bar: {
      //       colors: {
      //         ranges: [
      //           {
      //             from: -100,
      //             to: -46,
      //             color: "#F15B46",
      //           },
      //           {
      //             from: -45,
      //             to: 0,
      //             color: "#FEB019",
      //           },
      //         ],
      //       },
      //       columnWidth: "80%",
      //     },
      //   },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          distributed:
            (selStat.value && selStat.value.includes("Averages")) ||
            (selStat.value && selStat.value === "misses") ||
            (selStat.value && selStat.value.includes("parsToPar")),
          colors: {
            ranges:
              selStat.value &&
              (selStat.value.includes("Dista") ||
                selStat.value.includes("parsToPar"))
                ? []
                : selStat.value === "puttHoles"
                ? [
                    {
                      from: 1,
                      to: 2,
                      color: "#6ba390",
                    },
                    {
                      from: 2,
                      to: 3,
                      color: "#000000",
                    },
                    {
                      from: 3,
                      to: 5,
                      color: "#db5461",
                    },
                  ]
                : [
                    {
                      from: -2,
                      to: -1,
                      color: "#209E73",
                    },
                    {
                      from: -1,
                      to: 0,
                      color: "#6ba390",
                    },
                    {
                      from: 0,
                      to: 1,
                      color: "#db5461",
                    },
                    {
                      from: 2,
                      to: 5,
                      color: "#A9363F",
                    },
                  ],
          },
        },
      },
      xaxis: {
        categories:
          selStat.value && selStat.value.includes("Averages")
            ? ["Par 3s", "Par 4s", "Par 5s"]
            : selStat.value === "misses"
            ? ["Left", "FIR", "Right"]
            : selStat.value === "puttLengths"
            ? ["< 5", "5-10", "11-20", "21-40", "> 40"]
            : barLabels,
        labels: {
          rotate: -90,
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            // console.log(value);
            // return value;
            return value.toFixed(2);
          },
        },
        // min: -2,
        ...(type === "columns" && { max: 2 }),
        title: {
          text: yLabels[selStat.value] ?? selStat.label,
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  // UE
  useEffect(() => {
    // console.log(rounds);
    sorttDates();
  }, []);

  // Funcs
  function sortGraphs() {}

  function sorttDates() {
    if (stats.length === 0) {
      return;
    }

    const first = stats[0];
    const dt = new Date(first.date.toDate());
    setMinDate(dt);

    const optArr = [];
    const dtArr = [
      {
        label: "Today",
        value: "today",
      },
    ];

    for (let i = 0; i < stats.length; i++) {
      optArr.push(`${i + 1}`);
      const dt = new Date(stats[i].date.toDate());
      const ds = dt.toLocaleDateString("en-us", { dateStyle: "medium" });
      dtArr.push({
        label: ds,
        value: `${i}`,
      });
    }

    setDateArr(dtArr);
    setRDOpts(optArr);

    const puttArr = [
      overallStats.onePutts,
      overallStats.totalPutts - overallStats.onePutts - overallStats.threePutts,
      overallStats.threePutts,
    ];
    setPuttDist(puttArr);
  }

  function sortRLGraph() {
    const srs = [
      {
        name: selStat.label,
        data: [],
      },
    ];
    const dateArr = [];

    if (period === "rds") {
      const arr = [...rounds];
      const ct = parseInt(rdAmt);
      arr.reverse();
      for (let i = 0; i < ct; i++) {
        const shot = arr[i];
        const { date } = shot;
        const ds = date.toLocaleDateString("en-us", {
          dateStyle: "medium",
        });
        dateArr.push(ds);
        srs[0].data.push(shot[selStat.value]);
      }

      dateArr.reverse();
      srs[0].data.reverse();
    }

    if (period === "mths") {
      for (let i = 0; i < stats.length; i++) {
        const shot = stats[i];
        const { date } = shot;
        const dateObj = new Date(date.toDate());

        if (dateObj > dateRge.startDate && dateObj < dateRge.endDate) {
          const ds = date.toLocaleDateString("en-us", {
            dateStyle: "medium",
          });
          dateArr.push(ds);
          srs[0].data.push(shot[selStat.value]);
        }
      }
    }
    setDates(dateArr);
    setSeries(srs);
  }

  function sortLineGraph() {
    const srs = [];
    const dateArr = [];
    const sr = selRds[0] - 1;
    const er = selRds[1];
    for (let i = 0; i < selStats.length; i++) {
      srs.push({
        name: selStats[i].label,
        data: [],
      });
    }

    if (period === "rds") {
      const statArr = [...stats];
      const rdArr = [...rounds];
      const ct = parseInt(rdAmt);
      console.log(rdArr);

      // statArr.reverse();
      // rdArr.reverse();
      for (let i = sr; i < er; i++) {
        // console.log(i);
        for (let j = 0; j < selStats.length; j++) {
          const stat = selStats[j];
          if (stat.type === "Averages") {
            const shot = statArr[i];
            const { stats, date } = shot;
            if (stat.gross) {
              srs[j].data.push(shot.grossStats[stat.value]);
            } else {
              srs[j].data.push(stats[stat.value]);
            }

            if (j === 0) {
              const ds = new Date(date.toDate()).toLocaleDateString("en-us", {
                dateStyle: "medium",
              });
              dateArr.push(ds);
              // console.log(ds);
            }
          } else {
            const shot = rdArr[i];
            // console.log(shot);
            if (j === 0) {
              const { date } = shot;
              const ds = date.toLocaleDateString("en-us", {
                dateStyle: "medium",
              });
              // console.log(ds);
              dateArr.push(ds);
            }
            srs[j].data.push(isNaN(shot[stat.value]) ? 0 : shot[stat.value]);
          }
        }
      }

      // dateArr.reverse();
      for (let i = 0; i < selStats.length; i++) {
        // srs[i].data.reverse();
      }
    }

    if (period === "mths") {
      for (let i = 0; i < stats.length; i++) {
        for (let j = 0; j < selStats.length; j++) {
          const stat = selStats[j];
          if (stat.type === "Averages") {
            const shot = stats[i];
            const { date } = shot;
            const dateObj = new Date(date.toDate());
            if (dateObj > dateRge.startDate && dateObj < dateRge.endDate) {
              const ds = new Date(date.toDate()).toLocaleDateString("en-us", {
                dateStyle: "medium",
              });
              if (j === 0) {
                dateArr.push(ds);
              }
              if (stat.gross) {
                srs[j].data.push(shot.grossStats[stat.value]);
              } else {
                srs[j].data.push(shot.stats[stat.value]);
              }
              //   srs[j].data.push(shot.stats[stat.value]);
            }
          } else {
            const shot = rounds[i];
            if (shot === undefined) {
              continue;
            }
            const { date } = shot;
            const dateObj = new Date(date);

            if (dateObj > dateRge.startDate && dateObj < dateRge.endDate) {
              console.log(shot);
              const ds = date.toLocaleDateString("en-us", {
                dateStyle: "medium",
              });
              if (j === 0) {
                dateArr.push(ds);
              }
              srs[j].data.push(shot[stat.value]);
            }
          }
        }
      }
    }
    // console.log(srs);
    setDates(dateArr);
    setSeries(srs);
  }

  function sortPieGraph() {
    if (selStat.value === "putting") {
      if (selDate) {
        if (selDate.value === "today") {
          setPieSeries(puttDist);
          return;
        } else {
          const pl = parseInt(selDate.value);
          const shot = stats[pl].stats;
          const puttArr = [
            shot.onePutts,
            shot.totalPutts - shot.onePutts - shot.threePutts,
            shot.threePutts,
          ];
          setPieSeries(puttArr);
        }
      } else {
        setPieSeries(puttDist);
      }
    }
  }

  function sortBarGraph() {
    if (selStat.value === "parsToPar") {
      const arr = [
        overallStats.parThreesToPar,
        overallStats.parFoursToPar,
        overallStats.parFivesToPar,
      ];
      console.log(arr);
      setBarSeries([
        {
          name: "Gross",
          data: arr,
        },
      ]);
      setBarLabels(["Par 3s", "Par 4s", "Par 5s"]);
    }
    if (selStat.value === "parsToParGIR") {
      const arr = [
        Math.round(overallStats.parThreesToParGIR * 100) / 100,
        Math.round(overallStats.parFoursToParGIR * 100) / 100,
        Math.round(overallStats.parFivesToParGIR * 100) / 100,
      ];
      console.log(arr);
      setBarSeries([
        {
          name: "Gross",
          data: arr,
        },
      ]);
      setBarLabels(["Par 3s", "Par 4s", "Par 5s"]);
    }

    if (selStat.label === "Par 3s by Distance") {
      const barData = lengthStats.parThrees.map(
        (length) => length.averageGross
      );
      const netBarData = lengthStats.parThrees.map(
        (length) => length.averageNet
      );
      setBarLabels(
        meters
          ? [
              "< 100m",
              "100 - 120m",
              "120 - 140m",
              "140 - 160m",
              "160 - 180m",
              "180m+",
            ]
          : [
              "< 110yds",
              "110 - 130yds",
              "130 - 155yds",
              "155 - 175yds",
              "175 - 200yds",
              "200yds+",
            ]
      );
      console.log(barData, netBarData);
      setBarSeries([
        { name: "Gross", data: barData },
        { name: "Net", data: netBarData },
      ]);
    }
    if (selStat.label === "Par 4s by Distance") {
      const barData = lengthStats.parFours.map((length) => length.averageGross);
      const netBarData = lengthStats.parFours.map(
        (length) => length.averageNet
      );
      setBarLabels(
        meters
          ? [
              "< 200m",
              "200 - 250m",
              "250 - 300m",
              "300 - 350m",
              "350 - 400m",
              "400m+",
            ]
          : [
              "< 220yds",
              "220 - 275yds",
              "275 - 330yds",
              "330 - 380yds",
              "380 - 440yds",
              "440yds+",
            ]
      );
      setBarSeries([
        { name: "Gross", data: barData },
        { name: "Net", data: netBarData },
      ]);
    }
    if (selStat.label === "Par 5s by Distance") {
      const barData = lengthStats.parFives.map((length) => length.averageGross);
      const netBarData = lengthStats.parFives.map(
        (length) => length.averageNet
      );
      setBarLabels(
        meters
          ? [
              "< 300m",
              "300 - 400m",
              "400 - 450m",
              "450 - 500m",
              "500 - 550m",
              "550m+",
            ]
          : [
              "< 330yds",
              "330 - 440yds",
              "440 - 490yds",
              "490 - 550yds",
              "550 - 600yds",
              "600yds+",
            ]
      );
      setBarSeries([
        { name: "Gross", data: barData },
        { name: "Net", data: netBarData },
      ]);
    }

    if (selStat.label === "Last 10 Rounds") {
      // console.log(hcpArray);
      const arr = [
        {
          name: "Net",
          data: [],
        },
        {
          name: "Gross",
          data: [],
        },
      ];
      const length = rounds.length > 10 ? 10 : rounds.length;
      for (let i = 0; i < length; i++) {
        const round = rounds[i];
        const { totalGrossStrokes, totalNetStrokes } = round;
        arr[1].data.push(totalGrossStrokes);
        arr[0].data.push(totalNetStrokes);
      }
      setBarLabels(dates);
      setBarSeries(arr);
    }

    if (selStat.label === "Last 12 Months") {
      const monthArr = [];
      for (let i = 0; i < 12; i++) {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        const month = date.toLocaleDateString("en-us", { month: "narrow" });
        monthArr.push(month);
      }
      monthArr.reverse();
      const monthRounds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < rounds.length; i++) {
        const round = rounds[i];
        const roundDate = new Date(round.date);
        const roundMoment = moment(roundDate);
        const now = new Date();
        now.setDate(30);
        const nowMoment = moment(now);
        const diff = nowMoment.diff(roundMoment, "months");

        if (diff < 12) {
          monthRounds[diff] += 1;
        }
      }
      monthRounds.reverse();
      console.log(monthArr, monthRounds);
      setBarLabels(monthArr);
      setBarSeries([{ name: "Rounds Played", data: monthRounds }]);
      return setUpdate((current) => current + 1);
    }
  }

  async function loadRd(roundID) {
    console.log(roundID);
    setLoading(true);
    const rdDoc = await db.collection("rounds").doc(roundID).get();
    const scoreDoc = await rdDoc.ref.collection("scores").doc(userID).get();
    const scoreData = scoreDoc.data();
    const rdData = rdDoc.data();

    const { date, courseName, courseID } = rdData;
    const dateString = new Date(date.toDate()).toLocaleDateString("en-us", {
      dateStyle: "full",
    });
    scoreData.courseName = courseName;
    scoreData.courseID = courseID;
    // scoreData.notes = rdData.notes ?? "";

    scoreData.dateString = dateString;
    scoreData.roundID = roundID;
    scoreData.clientID = userID;

    console.log(scoreData);

    setToShow(
      <RoundCard
        round={scoreData}
        goBack={() => setShow(false)}
        showBack
        userID={userID}
      />
    );
    setShow(true);

    setLoading(false);
  }

  return (
    <div className="cs-graphs">
      {loading ? (
        <>
          <div className="cp-loader">
            <ClipLoader color="whitesmoke" size={20} className="btn-ico" />
          </div>
        </>
      ) : show ? (
        toShow
      ) : (
        <>
          <div className="neh-box">
            <h2 className="ne-header">Graph Builder</h2>
          </div>
          <div className="bs-subtle bss-white">
            <div className="flex flex-wrap flex-align-center">
              <div className="input-group">
                <p>Graph Type</p>
                <CustomDropdown
                  value={type}
                  opts={graphOpts}
                  handleChange={(e) => setType(e.value)}
                />
              </div>
              {type === "line" && (
                <div className="input-group">
                  <p>Statistic (max 3)</p>
                  {/* <CustomDropdown
                value={selStat}
                opts={statOpts[type] ?? []}
                handleChange={(e) => {
                  console.log(e);
                  setSelStat(e);
                }}
              /> */}
                  <Autocomplete
                    groupBy={(op) => op.type}
                    value={selStats}
                    options={statOpts2[type]}
                    multiple
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select..."
                        className="ac-tf"
                        {...params}
                      />
                    )}
                    onChange={(e, v) => {
                      console.log(v);
                      if (v.length === 4) {
                        return;
                      }
                      setSelStats(v);
                    }}
                    popupIcon={<KeyboardArrowDownIcon className="drop-ico" />}
                    sx={{ width: "20vw" }}
                  />
                </div>
              )}
              {type === "pie" && (
                <div className="input-group">
                  <p>Statistic</p>
                  <CustomDropdown
                    value={selStat}
                    opts={statOpts2[type] ?? []}
                    handleChange={(e) => {
                      console.log(e);
                      setSelStat(e);
                    }}
                  />
                </div>
              )}
              {type === "column" && (
                <div className="input-group">
                  <p>Statistic</p>
                  <CustomDropdown
                    value={selStat}
                    opts={statOpts2[type] ?? []}
                    handleChange={(e) => {
                      console.log(e);
                      setSelStat(e);
                    }}
                  />
                </div>
              )}
              {type === "line" && (
                <div className="csg-per">
                  <div className="input-group">
                    <p>Period</p>
                    <div className="flex">
                      <div className=" selector-group sg-small mr-20">
                        <button
                          onClick={() => {
                            setPeriod("rds");
                          }}
                          className={
                            period === "rds" ? "sg-left sg-active" : "sg-left"
                          }
                        >
                          Rounds
                        </button>
                        <button
                          onClick={() => {
                            setPeriod("mths");
                          }}
                          className={
                            period === "mths"
                              ? "sg-right sg-active"
                              : "sg-right"
                          }
                        >
                          Dates
                        </button>
                      </div>
                    </div>
                  </div>
                  {period === "mths" && (
                    <>
                      <div className="drp-container">
                        {false && (
                          <DateRangePicker
                            className="aca-drp"
                            color="#6ba390"
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(e) => {
                              console.log(e);
                              setDateRge(e.selection);
                            }}
                            ranges={[dateRge]}
                          />
                        )}
                      </div>
                      {true && (
                        <div className="csg-dates flex">
                          <div className="input-group">
                            <p>Start Date</p>
                            <input
                              type="date"
                              className="default-input"
                              value={dateRge[0]}
                              onChange={(e) =>
                                setDateRge((c) => {
                                  c.startDate = e.target.valueAsDate;
                                  return c;
                                })
                              }
                            />
                          </div>
                          <div className="input-group">
                            <p>End Date</p>
                            <input
                              type="date"
                              className="default-input"
                              value={dateRge[1]}
                              onChange={(e) =>
                                setDateRge((c) => {
                                  c.endDate = e.target.valueAsDate;
                                  return c;
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {period === "rds" && (
                    <>
                      <div className="input-group">
                        <p>Rounds</p>
                        <div className="">
                          <Slider.Root
                            minStepsBetweenThumbs={1}
                            className="SliderRoot"
                            // defaultValue={[50]}
                            max={rounds.length}
                            onValueChange={(e) => {
                              setSelRds(e);
                            }}
                            step={1}
                            min={1}
                            value={selRds}
                          >
                            <Slider.Track className="SliderTrack sl-tr-rg">
                              <Slider.Range className="SliderRange sl-rg" />
                            </Slider.Track>
                            <Slider.Thumb
                              className="SliderThumb"
                              aria-label="Volume"
                            />
                            <Slider.Thumb
                              className="SliderThumb"
                              aria-label="Volume"
                            />
                          </Slider.Root>

                          <div className="slider-vals flex flex-align-center flex-betw mt-10">
                            <div className="sv-left">
                              <p>{selRds[0]}</p>
                            </div>
                            <div className="sv-left">
                              <p>{selRds[1]}</p>
                            </div>
                          </div>

                          {/* <h3 className="ml-20">{discountValue}%</h3> */}
                        </div>
                      </div>
                      {false && (
                        <div>
                          <CustomDropdown
                            opts={rdOpts}
                            value={rdAmt}
                            handleChange={(e) => setRdAmt(e.value)}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {type === "pie" && (
                <div className="input-group">
                  <p>Date</p>
                  <CustomDropdown
                    opts={dateArr}
                    value={selDate}
                    handleChange={setSelDate}
                  />
                </div>
              )}
              <button
                disabled={
                  (type === "line" && selStats.length === 0) ||
                  (type === "pie" && selStat === "")
                }
                onClick={() => {
                  if (type === "line") {
                    sortLineGraph();
                  }
                  if (type === "pie") {
                    sortPieGraph();
                  }
                  if (type === "column") {
                    sortBarGraph();
                  }
                }}
                className="default-button small-but"
              >
                Generate
              </button>
            </div>{" "}
          </div>

          <div className="bs-subtle mt-20 bss-white">
            {type === "line" && (
              <Chart
                options={proptions}
                series={series}
                type={type}
                height={320}
              />
            )}
            {type === "pie" && (
              <Chart
                options={pieProp.options}
                series={pieSeries}
                type={type}
                height={400}
              />
            )}
            {type === "column" && (
              <Chart
                options={barProp.options}
                series={barSeries}
                type={"bar"}
                height={320}
              />
            )}{" "}
          </div>
        </>
      )}
    </div>
  );
}

export default ClientStatsGraphs;
