import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Components
import BackRow from "../../display/Rows/BackRow";
import BackdropLoader from "../../Loaders/BackdropLoader";
import PageHeader from "../../display/Rows/PageHeader";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { Dialog } from "@mui/material";
import ScoreInput from "../ScoreInput/ScoreInput";
import LiveTeeChange from "./LiveTeeChange";
import RoundPlayer from "../../RoundPlayer/RoundPlayer";
import EventHandicapEdit from "./EventHandicapEdit";
import TEDResultInput from "./TEDResultInput";
import MatchplayHoleResults from "./MatchplayHoleResults";
import { GolfCourse } from "@mui/icons-material";

function LiveCupGroups({
  roundID = "",
  goBack = () => {},
  cupID = "",
  cupName = "",
}) {
  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState(false);

  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");

  const [groups, setGroups] = useState([]);

  const [error, setError] = useState("");

  const [scrollPos, setScrollPos] = useState(0);

  const [format, setFormat] = useState("");
  const [playerHandicaps, setPlayerHCPs] = useState({});
  const [teeMap, setTeeMap] = useState({});

  useEffect(() => {
    loadGroups();
  }, []);

  async function loadGroups() {
    db.collection("rounds")
      .doc(roundID)
      .onSnapshot(async (snapshot) => {
        const round = snapshot.data();
        const { format, teeMap, teams } = round;
        const playerHandicaps = round.playerHandicaps ?? {};
        setTeeMap(round.teeMap ?? {});
        setPlayerHCPs(playerHandicaps);
        setFormat(format);
        const { matches } = round;

        setTeam1(teams[0].name);
        setTeam2(teams[1].name);
        if (format === "Matchplay") {
          // console.log(matches);
          setGroups(matches);
        } else {
          // console.log(matches);
          for (let i = 0; i < matches.length; i++) {
            const mtc = matches[i];
            const { teamOne, teamTwo } = mtc;
            const players = [];
            matches[i].teamOne.name = teams[0].name;
            matches[i].teamTwo.name = teams[1].name;
            for (let j = 0; j < teamOne.length; j++) {
              matches[i].teamOne[j].tee =
                teeMap[matches[i].teamOne[j].playerID];
              const playerID = matches[i].teamOne[j].playerID;
              if (playerHandicaps[playerID]) {
                matches[i].teamOne[j].handicap = playerHandicaps[playerID];
              }
              players.push(teamOne[j]);
            }
            for (let j = 0; j < teamTwo.length; j++) {
              matches[i].teamTwo[j].tee =
                teeMap[matches[i].teamTwo[j].playerID];
              const playerID = matches[i].teamTwo[j].playerID;
              if (playerHandicaps[playerID]) {
                matches[i].teamTwo[j].handicap = playerHandicaps[playerID];
              }
              players.push(teamTwo[j]);
            }
            matches[i].players = players;
          }
          // console.log(matches);
          setGroups(matches);
        }
        setLoading(false);
      });
  }

  return (
    <div className="">
      {show ? (
        toShow
      ) : (
        <>
          <BackRow action={goBack} />
          {loading && <BackdropLoader />}
          {error !== "" && (
            <ErrorModal hide={() => setError("")} text={error} />
          )}
          <PageHeader text={`Live Group View - ${cupName}`} />
          <div className="drop-groups">
            {format === "Fourball" &&
              groups.map((group, i) => {
                return (
                  <div
                    key={i}
                    className={
                      group.completed ? "drop-group dg-comp" : "drop-group"
                    }
                  >
                    <div className="group-header">
                      <div className="gh-flex">
                        <div className="flex-align-center">
                          <h2 className="ne-header">
                            Group {i + 1} - {group.time}
                            {group.startingTee}
                          </h2>
                          <div className="flex-align-center mr-10 ml-10 rpt-box">
                            <GolfCourse className="dg-icon rp-thru-ico" />
                            <div className="rp-thru">
                              <p>{group.thru}</p>
                            </div>
                          </div>
                        </div>
                        {group.completed && (
                          <>
                            <CheckCircleIcon className="dg-icon mr-20 " />
                          </>
                        )}
                        <div className="flex-align-center">
                          <div
                            onClick={() => {
                              setToShow(
                                <MatchplayHoleResults
                                  cupID={cupID}
                                  defaultMatch={group}
                                  format={format}
                                  goBack={() => setShow(false)}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="black-link mr-10 "
                          >
                            Holes
                          </div>

                          {/* <div
                            onClick={() => {
                              setToShow(
                                <TeamResultUpdate
                                  defaultMatch={group}
                                  goBack={() => setShow(false)}
                                  format={format}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="black-link mr-10 "
                          >
                            Score
                          </div> */}
                          <div
                            onClick={() => {
                              setToShow(
                                <TEDResultInput
                                  cupID={cupID}
                                  defaultMatch={group}
                                  goBack={() => setShow(false)}
                                  format={format}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="green-link mr-10 "
                          >
                            Result
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dg-players">
                      <div className="dgp-team">
                        <p>{group.teamOne.name}</p>
                      </div>
                      {group.teamOne.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        const { thru } = group;
                        return (
                          <RoundPlayer
                            finished={player.thru === 18}
                            guest={player.playerID.includes("guest")}
                            scoreAction={() => {
                              setScrollPos(window.scrollY);
                              setToShow(
                                <ScoreInput
                                  roundID={roundID}
                                  matchplay
                                  // eventID={}
                                  cupID={cupID}
                                  cup
                                  eventName={cupName}
                                  defaultPlayer={player}
                                  goBack={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            thru={thru === 0 || thru === 18 ? "" : thru}
                            hcpAction={() => {
                              setScrollPos(window.scrollY);
                              console.log(cupID);
                              setToShow(
                                <EventHandicapEdit
                                  live
                                  cupID={cupID}
                                  defaultPlayer={player}
                                  roundID={roundID}
                                  hide={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            teeAction={() => {
                              setScrollPos(window.scrollY);

                              setToShow(
                                <LiveTeeChange
                                  defaultPlayer={player}
                                  cupID={cupID}
                                  cup
                                  hide={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            liveGroup
                            name={player.name}
                            tee={teeMap[player.playerID] ?? player.tee}
                            handicap={
                              playerHandicaps[player.playerID] ??
                              player.handicap
                            }
                            key={player.playerID}
                          />
                        );
                      })}
                      {group.thru !== 0 &&
                        !group.completed &&
                        group.leader !== "" && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p
                              className={
                                group.leader === "teamOne"
                                  ? "tlg-gr"
                                  : "tlg-red"
                              }
                            >
                              {group.score}{" "}
                              {group.leader === "teamOne" ? "Up" : "Down"}
                            </p>
                          </div>
                        )}
                      {group.completed && (
                        <div className="tlg-ms flex-center vs-pee-pee">
                          <p
                            className={
                              group.leader === "teamOne"
                                ? "tlg-gr"
                                : group.leader === "tied" || group.leader === ""
                                ? "tlg-black"
                                : "tlg-red"
                            }
                          >
                            {group.score === 0 && "Tied"}
                            {group.score === 1 && "1 Up"}
                            {group.score === 2 && group.thru === 18 && "2 Up"}
                            {group.score === 2 && group.thru === 17 && "2 & 1"}
                            {group.score > 2 &&
                              `${group.score} & ${18 - group.thru}`}
                          </p>
                        </div>
                      )}
                      {group.thru !== 0 &&
                        !group.completed &&
                        group.leader === "" && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p className={"tlg-black"}>Tied</p>
                          </div>
                        )}
                      {group.thru === 0 && (
                        <div className="vs-pee-pee flex-center">
                          <p>vs</p>
                        </div>
                      )}
                      <div className="dgp-team">
                        <p>{group.teamTwo.name}</p>
                      </div>
                      {group.teamTwo.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        const { thru } = group;
                        return (
                          <RoundPlayer
                            finished={player.thru === 18}
                            guest={player.playerID.includes("guest")}
                            scoreAction={() => {
                              setScrollPos(window.scrollY);
                              setToShow(
                                <ScoreInput
                                  roundID={roundID}
                                  matchplay
                                  // eventID={}
                                  cupID={cupID}
                                  cup
                                  eventName={cupName}
                                  defaultPlayer={player}
                                  goBack={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            thru={thru === 0 || thru === 18 ? "" : thru}
                            hcpAction={() => {
                              setScrollPos(window.scrollY);

                              setToShow(
                                <EventHandicapEdit
                                  live
                                  cupID={cupID}
                                  defaultPlayer={player}
                                  roundID={roundID}
                                  hide={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                />
                              );
                              setShow(true);
                            }}
                            teeAction={() => {
                              setScrollPos(window.scrollY);

                              setToShow(
                                <LiveTeeChange
                                  defaultPlayer={player}
                                  cupID={cupID}
                                  cup
                                  hide={() => {
                                    setShow(false);
                                    window.scrollTo({
                                      top: scrollPos,
                                      behavior: "smooth",
                                    });
                                  }}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            liveGroup
                            name={player.name}
                            tee={teeMap[player.playerID] ?? player.tee}
                            handicap={
                              playerHandicaps[player.playerID] ??
                              player.handicap
                            }
                            key={player.playerID}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            {format === "Matchplay" &&
              groups.map((group, i) => {
                const { thru } = group;
                // console.log(group);

                return (
                  <div
                    key={i}
                    className={
                      group.completed ? "drop-group dg-comp" : "drop-group"
                    }
                  >
                    <div className="group-header">
                      <div className="gh-flex">
                        <div className="flex-align-center">
                          <h2 className="ne-header">
                            Group {i + 1} - {group.time}
                            {group.startingTee}
                          </h2>
                          <div className="flex-align-center mr-10 ml-10 rpt-box">
                            <GolfCourse className="dg-icon rp-thru-ico" />
                            <div className="rp-thru">
                              <p>{group.thru}</p>
                            </div>
                          </div>
                        </div>
                        {group.completed && (
                          <>
                            <CheckCircleIcon className="dg-icon mr-20 " />
                          </>
                        )}
                        <div className="flex-align-center">
                          <div
                            onClick={() => {
                              setToShow(
                                <MatchplayHoleResults
                                  cupID={cupID}
                                  defaultMatch={group}
                                  format={format}
                                  goBack={() => setShow(false)}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="black-link mr-10 "
                          >
                            Holes
                          </div>

                          {/* <div
                            onClick={() => {
                              setToShow(
                                <TeamResultUpdate
                                  defaultMatch={group}
                                  goBack={() => setShow(false)}
                                  format={format}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="black-link mr-10 "
                          >
                            Score
                          </div> */}
                          <div
                            onClick={() => {
                              setToShow(
                                <TEDResultInput
                                  cupID={cupID}
                                  defaultMatch={group}
                                  goBack={() => setShow(false)}
                                  format={format}
                                  roundID={roundID}
                                />
                              );
                              setShow(true);
                            }}
                            className="green-link mr-10 "
                          >
                            Result
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dg-players">
                      <div className="dgp-team">
                        <p>{team1}</p>
                      </div>
                      {/* {group.teamOne.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        const { thru } = group; */}
                      {/* return ( */}
                      <RoundPlayer
                        showBg={group.leader === group.playerOne.playerID}
                        finished={group.complete || group.completed}
                        guest={group.playerOne.playerID.includes("guest")}
                        scoreAction={() => {
                          setScrollPos(window.scrollY);
                          setToShow(
                            <ScoreInput
                              roundID={roundID}
                              matchplay
                              // eventID={}
                              cupID={cupID}
                              cup
                              eventName={cupName}
                              defaultPlayer={group.playerOne}
                              goBack={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                            />
                          );
                          setShow(true);
                        }}
                        // thru={thru === 0 || thru === 18 ? "" : thru}
                        hcpAction={() => {
                          setScrollPos(window.scrollY);

                          setToShow(
                            <EventHandicapEdit
                              live
                              cupID={cupID}
                              defaultPlayer={group.playerOne}
                              roundID={roundID}
                              hide={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                            />
                          );
                          setShow(true);
                        }}
                        teeAction={() => {
                          setScrollPos(window.scrollY);

                          setToShow(
                            <LiveTeeChange
                              defaultPlayer={group.playerOne}
                              cupID={cupID}
                              cup
                              hide={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                              roundID={roundID}
                            />
                          );
                          setShow(true);
                        }}
                        liveGroup
                        name={group.playerOne.name}
                        tee={
                          teeMap[group.playerOne.playerID] ??
                          group.playerOne.tee
                        }
                        handicap={
                          playerHandicaps[group.playerOne.playerID] ??
                          group.playerOne.handicap
                        }
                        key={group.playerOne.playerID}
                      />

                      {/* })} */}
                      {group.thru !== 0 &&
                        !group.completed &&
                        group.leader !== "" && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p
                              className={
                                group.leader === group.playerOne.playerID
                                  ? "tlg-gr"
                                  : "tlg-red"
                              }
                            >
                              {group.score}{" "}
                              {group.leader === group.playerOne.playerID
                                ? "Up"
                                : "Down"}
                            </p>
                          </div>
                        )}
                      {group.completed && (
                        <div className="tlg-ms flex-center vs-pee-pee">
                          <p
                            className={
                              group.leader === group.playerOne.playerID
                                ? "tlg-gr"
                                : group.leader === "tied" || group.leader === ""
                                ? "tlg-black"
                                : "tlg-red"
                            }
                          >
                            {group.score === 0 && "Tied"}
                            {group.score === 1 && "1 Up"}
                            {group.score === 2 && group.thru === 18 && "2 Up"}
                            {group.score === 2 && group.thru === 17 && "2 & 1"}
                            {group.score > 2 &&
                              `${group.score} & ${18 - group.thru}`}
                          </p>
                        </div>
                      )}
                      {group.thru !== 0 &&
                        !group.completed &&
                        group.leader === "" && (
                          <div className="tlg-ms flex-center vs-pee-pee">
                            <p className={"tlg-black"}>Tied</p>
                          </div>
                        )}
                      {group.thru === 0 && (
                        <div className="vs-pee-pee flex-center">
                          <p>vs</p>
                        </div>
                      )}
                      <div className="dgp-team">
                        <p>{team2}</p>
                      </div>
                      {/* {group.teamTwo.map((player, i2) => {
                        if (player.name === "Empty") {
                          return null;
                        }
                        const { thru } = group;
                        return ( */}
                      <RoundPlayer
                        showBg={group.leader === group.playerTwo.playerID}
                        finished={group.completed}
                        guest={group.playerTwo.playerID.includes("guest")}
                        scoreAction={() => {
                          setScrollPos(window.scrollY);
                          setToShow(
                            <ScoreInput
                              roundID={roundID}
                              matchplay
                              // eventID={}
                              cupID={cupID}
                              cup
                              eventName={cupName}
                              defaultPlayer={group.playerTwo}
                              goBack={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                            />
                          );
                          setShow(true);
                        }}
                        // thru={thru === 0 || thru === 18 ? "" : thru}
                        hcpAction={() => {
                          setScrollPos(window.scrollY);

                          setToShow(
                            <EventHandicapEdit
                              live
                              cupID={cupID}
                              defaultPlayer={group.playerTwo}
                              roundID={roundID}
                              hide={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                            />
                          );
                          setShow(true);
                        }}
                        teeAction={() => {
                          setScrollPos(window.scrollY);

                          setToShow(
                            <LiveTeeChange
                              defaultPlayer={group.playerTwo}
                              cupID={cupID}
                              cup
                              hide={() => {
                                setShow(false);
                                window.scrollTo({
                                  top: scrollPos,
                                  behavior: "smooth",
                                });
                              }}
                              roundID={roundID}
                            />
                          );
                          setShow(true);
                        }}
                        liveGroup
                        name={group.playerTwo.name}
                        tee={
                          teeMap[group.playerTwo.playerID] ??
                          group.playerTwo.tee
                        }
                        handicap={
                          playerHandicaps[group.playerTwo.playerID] ??
                          group.playerTwo.handicap
                        }
                        key={group.playerTwo.playerID}
                      />
                      {/* );
                      })} */}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default LiveCupGroups;
