import React, { useEffect, useState } from "react";
import { db, admin } from "../../firebase";
// Icons
import EditIcon from "@mui/icons-material/Edit";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
// Components
import BackRow from "../../components/display/Rows/BackRow";
import PageHeader from "../../components/display/Rows/PageHeader";
import RadioGroupTwo from "../../components/Buttons/RadioGroup/RadioGroupTwo";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";
import { Dialog } from "@mui/material";
import NewsPill from "./NewsPill";
function getRound(currentRound, totalRounds) {
  const roundNames = [
    "First Round",
    "Round of 32",
    "Round of 16",
    "Quarter-finals",
    "Semi-Finals",
    "Final",
  ];

  // Check if the current round is within the range of the provided rounds
  if (currentRound > totalRounds) {
    return "Invalid round";
  }

  // Calculate the maximum number of rounds that can be represented with the available round names
  const maxRounds = roundNames.length;

  // Calculate the number of remaining rounds after the current round
  const remainingRounds = totalRounds - currentRound;

  // Calculate the index of the round name based on the remaining rounds
  const roundIndex = Math.min(maxRounds - remainingRounds, maxRounds - 1);

  // Return the corresponding round name
  return roundNames[roundIndex];
}

function MatchplayTournamentFixtures({
  tournID = "",
  goBack = () => {},
  reload = () => {},
}) {
  useEffect(() => {
    loadFixtures();
  }, []);

  const [active, setActive] = useState("res");
  const [stage, setStage] = useState("groups");
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [rounds, setRounds] = useState([]);
  const [error, setError] = useState("");
  const [res, setRes] = useState([]);
  const [manMatch, setManMatch] = useState();
  const [selWinner, setSelWinner] = useState();
  const [manualBy, setManualBy] = useState(0);
  const [manualRem, setManualRem] = useState(0);
  const [showManual, setShowManual] = useState(false);
  const [matchEdit, setMatchEdit] = useState();
  const [knockouts, setKnockouts] = useState([]);

  const [selGroup, setSelGroup] = useState("all");

  const [groups, setGroups] = useState([]);

  const [doubles, setDoubles] = useState(false);

  async function loadFixtures() {
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tourn = tournDoc.data();

    const { stage } = tourn;
    const dbls = tourn.playerType === "doubles";
    const knockouts = tourn.knockout ?? [];

    for (let i = 0; i < knockouts.length; i++) {
      knockouts[i].round = getRound(i, knockouts.length);
    }

    knockouts.reverse();
    // console.log(knockouts);
    setKnockouts(knockouts);
    const results = tourn.results ?? [];
    const groups = tourn.groups ?? [];

    console.log(groups);

    setGroups(groups);

    const groupMap = {};

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      const { players } = group;
      for (let j = 0; j < players.length; j++) {
        const player = players[j];
        if (dbls) {
          groupMap[player.teamID] = i;
        } else {
          groupMap[player.playerID] = i;
        }
      }
    }

    console.log(groupMap);

    for (let i = 0; i < results.length; i++) {
      const res = results[i];
      if (dbls) {
        const { teamOne } = res;
        const group = groupMap[teamOne.teamID];
        results[i].group = group;
      } else {
        const { playerOne } = res;
        const { playerID } = playerOne;
        const group = groupMap[playerID];
        results[i].group = group;
      }
    }

    setDoubles(dbls);
    setStage(stage);
    setRes(results ?? []);
    const roundDocs = await db
      .collection("rounds")
      .where("tournamentID", "==", tournID)
      .orderBy("date", "desc")
      .get();

    const arr = [];

    for (let i = 0; i < roundDocs.docs.length; i++) {
      const roundDoc = roundDocs.docs[i];
      const roundID = roundDoc.id;
      const round = roundDoc.data();
      console.log(round);
      const dateObject = new Date(round.date.toDate());
      const dateString = dateObject.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      round.roundID = roundID;
      round.dateObject = dateObject;
      round.dateString = dateString;

      const { playerIDs } = round;
      // console.log(playerIDs);
      if (playerIDs[0]) {
        const group = groupMap[playerIDs[0]];
        round.group = group;
      }

      arr.push(round);
    }

    setRounds(arr);
    setLoading(false);
  }

  async function manualFinish({ players = [], roundID = "" }) {
    // return console.log(players);
    setLoading(true);
    setShowManual(false);
    try {
      const tournDoc = await db.collection("tournaments").doc(tournID).get();
      const tourn = tournDoc.data();

      const { pointsType, groups } = tourn;

      const playedMap = tourn.playedMap ?? {};

      const playerOne = players[0];
      const playerTwo = players[1];

      const p1ID = players[0].playerID;
      const p2ID = players[1].playerID;

      if (playedMap[p1ID]) {
        playedMap[p1ID].push(p2ID);
      } else {
        playedMap[p1ID] = [p2ID];
      }

      if (playedMap[p2ID]) {
        playedMap[p2ID].push(p1ID);
      } else {
        playedMap[p2ID] = [p1ID];
      }

      let res = {};

      if (doubles) {
        res = {
          date: new Date(),
          teamOne: players[0],
          teamTwo: players[1],
          by: manualBy,
          winner: selWinner,
          rem: manualRem,
          roundID,
        };
      } else {
        res = {
          date: new Date(),
          playerOne: players[0],
          playerTwo: players[1],
          by: manualBy,
          winner: selWinner,
          rem: manualRem,
          roundID,
        };
      }
      console.log(res);
      // return setLoading(false);

      let tie = false;
      let loserID = "";
      if (!doubles) {
        if (playerOne.playerID === selWinner.playerID) {
          loserID = playerTwo.playerID;
        } else if (playerTwo.playerID === selWinner.playerID) {
          loserID = playerOne.playerID;
        } else {
          tie = true;
        }
      } else {
        if (playerOne.teamID === selWinner.teamID) {
          loserID = playerTwo.teamID;
        } else if (playerTwo.teamID === selWinner.teamID) {
          loserID = playerOne.teamID;
        } else {
          tie = true;
        }
      }

      if (doubles) {
        const teamOne = players[0];
        const teamTwo = players[1];
        const t1ID = players[0].teamID;
        const t2ID = players[1].teamID;
        for (let i = 0; i < groups.length; i++) {
          const group = groups[i];
          const { players } = group;
          const teamIDs = players.map((player) => player.teamID);

          if (!teamIDs.includes(t1ID) && !teamIDs.includes(t2ID)) {
            continue;
          }
          if (tie) {
            for (let x = 0; x < players.length; x++) {
              if (
                players[x].teamID === teamOne.teamID ||
                players[x].teamID === teamTwo.teamID
              ) {
                if (pointsType === "normal") {
                  groups[i].players[x].points += 5;
                }
                groups[i].players[x].played += 1;
                groups[i].players[x].ties += 1;
              }
            }
          } else {
            for (let x = 0; x < players.length; x++) {
              if (players[x].teamID === selWinner.teamID) {
                groups[i].players[x].played += 1;
                groups[i].players[x].wins += 1;
                if (pointsType === "normal") {
                  groups[i].players[x].points += 10;
                } else {
                  groups[i].players[x].points += parseInt(manualBy);
                }
              }
              if (players[x].teamID === loserID) {
                groups[i].players[x].played += 1;
              }
            }
          }
        }
      } else {
        for (let i = 0; i < groups.length; i++) {
          const group = groups[i];
          const { players } = group;
          const playerIDs = players.map((player) => player.playerID);
          if (!playerIDs.includes(selWinner.playerID)) {
            continue;
          }
          if (tie) {
            for (let x = 0; x < players.length; x++) {
              if (
                players[x].playerID === playerOne.playerID ||
                players[x].playerID === playerTwo.playerID
              ) {
                groups[i].players[x].points += 10;
                groups[i].players[x].played += 1;
                groups[i].players[x].ties += 1;
              }
            }
          } else {
            for (let x = 0; x < players.length; x++) {
              if (players[x].playerID === selWinner.playerID) {
                groups[i].players[x].played += 1;
                groups[i].players[x].wins += 1;
                if (pointsType === "normal") {
                  groups[i].players[x].points += 20;
                } else {
                  groups[i].players[x].points += parseInt(manualBy);
                }
              }
              if (players[x].playerID === loserID) {
                groups[i].players[x].played += 1;
              }
            }
          }
        }
      }

      await tournDoc.ref.update({
        results: admin.firestore.FieldValue.arrayUnion(res),
        groups,
        playedMap,
      });

      if (roundID !== "") {
        if (doubles) {
          await db
            .collection("users")
            .doc(players[0].players[0].playerID)
            .update({
              activeRoundID: "",
            });
          await db
            .collection("users")
            .doc(players[0].players[1].playerID)
            .update({
              activeRoundID: "",
            });
          await db
            .collection("users")
            .doc(players[1].players[0].playerID)
            .update({
              activeRoundID: "",
            });
          await db
            .collection("users")
            .doc(players[1].players[1].playerID)
            .update({
              activeRoundID: "",
            });
        } else {
          await db.collection("users").doc(players[0].playerID).update({
            activeRoundID: "",
          });
          await db.collection("users").doc(players[1].playerID).update({
            activeRoundID: "",
          });
        }
        const rd = await db.collection("rounds").doc(roundID).get();
        const rdd = rd.data();
        const { leaderboard } = rdd;
        leaderboard.completed = true;
        leaderboard.manual = true;
        leaderboard.by = manualBy;
        leaderboard.score = manualBy;
        leaderboard.thru = 18 - manualRem;
        if (doubles) {
          leaderboard.leader = selWinner.teamID;

          if (selWinner.teamID === players[0].teamID) {
            leaderboard.leader = "teamOne";
          }
          if (selWinner.teamID === players[1].teamID) {
            leaderboard.leader = "teamTwo";
          }
        } else {
          leaderboard.leader = selWinner.playerID;
        }
        await rd.ref.update({
          leaderboard,
        });
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }

    setLoading(false);
    loadFixtures();
  }

  // async function manualKnockoutFinish({ matchIndex = [], roundID = "" }) {
  //   setLoading(true);
  //   setShowManual(false);
  //   const i1 = matchIndex[0];
  //   const i2 = matchIndex[1];

  //   const tournamentDoc = await db.collection("tournaments").doc(tournID).get();
  //   const tournDoc = tournamentDoc.data();

  //   const { knockout } = tournDoc;
  //   try {
  //     const match = knockout[i1].matches[i2];
  //     match.winner = selWinner;
  //     match.completed = true;
  //     match.by = manualBy;
  //     match.rem = manualRem;
  //     match.winnerID = selWinner.playerID;
  //     match.winnerName = selWinner.name;
  //     const { matchIndex } = match;
  //     if (i1 !== knockout.length) {
  //       let found = false;
  //       const nextRound = knockout[i1 + 1];
  //       for (let i = 0; i < nextRound.matches.length; i++) {
  //         if (found) {
  //           continue;
  //         }
  //         const match = nextRound.matches[i];
  //         console.log(match);
  //         if (match.qualis.includes(matchIndex)) {
  //           if (matchIndex === match.qualis[0]) {
  //             match.players[0] = selWinner;
  //           } else {
  //             match.players[1] = selWinner;
  //           }
  //           found = true;
  //           knockout[i1 + 1].matches[i] = match;
  //         }
  //       }
  //     } else {
  //       await tournamentDoc.ref.update({
  //         winnerID: selWinner.playerID,
  //         winnerName: selWinner.name,
  //         winner: selWinner,
  //         completed: true,
  //       });
  //     }
  //     knockout[i1].matches[i2] = match;
  //     //   return setLoading(false);
  //     await tournamentDoc.ref.update({
  //       knockout,
  //     });
  //   } catch (err) {
  //     console.log(err);
  //     setError(
  //       "Sorry - we encountered a technical difficulty there, please try again."
  //     );
  //   }
  //   setLoading(false);
  // }

  async function manualKnockoutFinish({ matchIndex = [] }) {
    setLoading(true);
    setShowManual(false);
    const i1 = matchIndex[0];
    const i2 = matchIndex[1];
    // console.log(matchIndex);
    // return setLoading(false);

    const tournamentDoc = await db.collection("tournaments").doc(tournID).get();
    const tournDoc = tournamentDoc.data();

    const { knockout, bracket } = tournDoc;
    try {
      if (tournDoc.tournamentType === "tournament") {
        if (doubles) {
          const match = knockout[i1].matches[i2];
          match.winner = selWinner;
          match.completed = true;
          match.by = manualBy;
          match.rem = manualRem;
          match.winnerID = selWinner.teamID;
          match.winnerName = selWinner.name;
          const { matchIndex } = match;
          if (match.roundID) {
            const roundDoc = await db
              .collection("rounds")
              .doc(match.roundID)
              .get();
            const rd = roundDoc.data();
            const { leaderboard } = rd;
            leaderboard.completed = true;
            leaderboard.manual = true;
            leaderboard.by = manualBy;
            leaderboard.thru = 18 - manualRem;
            leaderboard.leader =
              selWinner.teamID === match.players[0].teamID
                ? "teamOne"
                : selWinner.teamID === match.players[1].teamID
                ? "teamTwo"
                : "";
            await roundDoc.ref.update({
              leaderboard,
            });
          }
          if (i1 !== knockout.length - 1) {
            let found = false;
            const nextRound = knockout[i1 + 1];
            for (let i = 0; i < nextRound.matches.length; i++) {
              if (found) {
                continue;
              }
              const match = nextRound.matches[i];
              if (match.qualis.includes(matchIndex)) {
                if (matchIndex === match.qualis[0]) {
                  match.players[0] = selWinner;
                } else {
                  match.players[1] = selWinner;
                }
                found = true;
                knockout[i1 + 1].matches[i] = match;
              }
            }
          } else {
            // alert("last");
            await tournamentDoc.ref.update({
              winnerID: selWinner.playerID,
              winnerName: selWinner.name,
              winner: selWinner,
              completed: true,
            });
          }
          knockout[i1].matches[i2] = match;
        } else {
          const match = knockout[i1].matches[i2];
          match.winner = selWinner;
          match.completed = true;
          match.by = manualBy;
          match.rem = manualRem;
          match.winnerID = selWinner.playerID;
          match.winnerName = selWinner.name;
          const { matchIndex } = match;
          if (match.roundID) {
            const roundDoc = await db
              .collection("rounds")
              .doc(match.roundID)
              .get();
            const rd = roundDoc.data();
            const { leaderboard } = rd;
            leaderboard.completed = true;
            leaderboard.completed = true;
            leaderboard.manual = true;
            leaderboard.by = manualBy;
            leaderboard.thru = 18 - manualRem;
            leaderboard.leader = selWinner.playerID;
            await roundDoc.ref.update({
              leaderboard,
            });
          }
          if (i1 !== knockout.length) {
            let found = false;
            const nextRound = knockout[i1 + 1];
            for (let i = 0; i < nextRound.matches.length; i++) {
              if (found) {
                continue;
              }
              const match = nextRound.matches[i];
              if (match.qualis.includes(matchIndex)) {
                if (matchIndex === match.qualis[0]) {
                  match.players[0] = selWinner;
                } else {
                  match.players[1] = selWinner;
                }
                found = true;
                knockout[i1 + 1].matches[i] = match;
              }
            }
          } else {
            await tournamentDoc.ref.update({
              winnerID: selWinner.playerID,
              winnerName: selWinner.name,
              winner: selWinner,
              completed: true,
            });
          }
          knockout[i1].matches[i2] = match;
        }
        // console.log(knockout);
        // return setLoading(false);
        await tournamentDoc.ref.update({
          knockout,
        });
      } else {
        const match = bracket[i1].matches[i2];
        match.winner = selWinner;
        match.completed = true;
        match.by = manualBy;
        match.rem = manualRem;
        match.winnerID = selWinner.playerID;
        match.winnerName = selWinner.name;
        bracket[i1].matches[i2] = match;
        await tournamentDoc.ref.update({
          bracket,
        });
      }
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  return (
    <div className="mpt-fixtures">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      <Dialog open={showManual} onClose={() => setShowManual(false)}>
        {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

        <div className="mpb-manual pd-40">
          <PageHeader
            close={() => setShowManual(false)}
            text="Manual Input"
            showClose
          />
          <div className="ph-msg mb-20">
            <p>Select the player who won this game </p>
          </div>
          {manMatch && (
            <div className="mpb-match">
              <PlayerItem
                showBg={
                  selWinner &&
                  ((!doubles &&
                    selWinner.playerID === manMatch.players[0].playerID) ||
                    (doubles &&
                      selWinner.teamID === manMatch.players[0].teamID))
                }
                showProfile={() => setSelWinner(manMatch.players[0])}
                hideRemove
                hcp={manMatch.players[0].handicap}
                hideImage
                hideHCP
                showAdd
                hideMessage
                hideProfile
                noLink
                hideIcons
                name={manMatch.players[0].name}
              />
              <div className="mp-cir-box">
                <div className="mpbm-circle">
                  <p>vs</p>
                </div>
              </div>

              <PlayerItem
                showBg={
                  selWinner &&
                  ((!doubles &&
                    selWinner.playerID === manMatch.players[1].playerID) ||
                    (doubles &&
                      selWinner.teamID === manMatch.players[1].teamID))
                }
                showProfile={() => setSelWinner(manMatch.players[1])}
                hideRemove
                hcp={manMatch.players[1].handicap}
                hideImage
                hideIcons
                showAdd
                hideHCP
                hideMessage
                hideProfile
                noLink
                name={manMatch.players[1].name}
              />
            </div>
          )}
          {stage === "groups" && (
            <div className="mini-pi">
              <PlayerItem
                name={"Tie"}
                showBg={selWinner && selWinner.name === "Tie"}
                hideIcons
                noLink
                hideHCP
                hideImage
                showProfile={() => {
                  setSelWinner({
                    name: "Tie",
                    playerID: "tie",
                  });
                  setManualBy(0);
                  setManualRem(0);
                }}
              />
            </div>
          )}
          <div className="mp-man-holes">
            <div className="input-group">
              <p>Holes Up</p>
              <input
                type="number"
                disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualBy(e.target.value)}
                value={manualBy}
                className="default-input"
              />
            </div>
            <div className="input-group">
              <p>Holes Remaining</p>
              <input
                type="number"
                disabled={selWinner && selWinner.name === "Tie"}
                onChange={(e) => setManualRem(e.target.value)}
                value={manualRem}
                className="default-input"
              />
            </div>
          </div>
          <div className="flex-center">
            <button
              disabled={
                selWinner === undefined ||
                manualRem > manualBy ||
                (stage === "knockouts" && manualRem === 0 && manualBy === 0)
              }
              className="default-button"
              onClick={() => {
                // console.log(manMatch);
                if (stage === "groups") {
                  manualFinish({
                    players: manMatch.players,
                    roundID: manMatch.roundID,
                  });
                } else {
                  manualKnockoutFinish({
                    matchIndex: manMatch.matchIndex,
                    roundID: manMatch.roundID,
                  });
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      {loading && <BackdropLoader />}
      <BackRow action={goBack} />
      <PageHeader text="Matchplay Fixtures" />
      <RadioGroupTwo
        buttons={[
          { text: "Results", value: "res" },
          { text: "Rounds", value: "rounds" },
        ]}
        active={active}
        setActive={setActive}
      />
      <div className="input-group mb-20 pl-20">
        <p>Search</p>
        <input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          type="text"
          placeholder="Search for player..."
          className="default-input"
        />
      </div>

      <div className="mpr-rounds pd-20">
        {active === "res" &&
          !doubles &&
          knockouts.map((round, i) => {
            const { matches } = round;

            return (
              <>
                <div className="neh-box pl-0" key={`ko${i}`}>
                  <h3 className="ne-header">{round.round}</h3>
                </div>
                {matches.map((match, i2) => {
                  let dateString = "";
                  const players = match.players;
                  const p1 = players[0];
                  const p2 = players[1];
                  if (match.date) {
                    dateString = new Date(
                      match.date.toDate()
                    ).toLocaleDateString("en-us", { dateStyle: "medium" });
                  }
                  return (
                    <div className="mpr-match">
                      {dateString && (
                        <div className="mprm-details mb-10">
                          <h3>{dateString}</h3>
                        </div>
                      )}
                      <div className="mpb-match">
                        <PlayerItem
                          showBg={match.winner.playerID === p1.playerID}
                          hideRemove
                          hideEdit={true}
                          hcp={p1.handicap}
                          hideImage
                          showAdd
                          hideHCP={isNaN(p1.handicap)}
                          hideMessage
                          hideProfile
                          noLink
                          editAction={() => {}}
                          name={p1.name}
                        />
                        <div className="mp-cir-box">
                          {/* {round.roundID && !match.completed && (
                      <RssFeedIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )} */}
                          {round.roundID && (
                            <GolfCourseIcon
                              onClick={() =>
                                window.open(
                                  `https://www.theugatour.com/rounds/${round.roundID}`,
                                  "_blank"
                                )
                              }
                              className="dg-icon icon-cursor mb-10"
                            />
                          )}
                          <div className="mpbm-circle">
                            {match.leader === "" ? (
                              <p>vs</p>
                            ) : match.by === "BYE" ? (
                              <p>BYE</p>
                            ) : (
                              <>
                                {match.leader === p1.playerID ? (
                                  <p>
                                    {match.rem === 0
                                      ? `${match.by} UP`
                                      : `${match.by} & ${match.rem}`}
                                  </p>
                                ) : match.leader === "" ? (
                                  <p>AS</p>
                                ) : (
                                  <p>
                                    {match.rem === 0
                                      ? `${match.by} DN`
                                      : `${match.by} & ${match.rem}`}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <PlayerItem
                          showBg={match.winner.playerID === p2.playerID}
                          hideRemove
                          hcp={p2.handicap}
                          hideImage
                          hideEdit={true}
                          hideHCP={isNaN(p2.handicap)}
                          showAdd
                          hideMessage
                          hideProfile
                          noLink
                          name={p2.name}
                          editAction={() => {}}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}

        {active === "res" && (
          <div className="neh-box pl-0 pb-0">
            <h3 className="ne-header">Group Stages</h3>
          </div>
        )}
        {active === "res" && (
          <div className="gr-filts">
            <NewsPill
              text={"All"}
              active={selGroup === "all"}
              handleClick={() => setSelGroup("all")}
            />
            {groups.map((group, i) => (
              <NewsPill
                key={`${i}grppill`}
                text={`Group ${i + 1}`}
                active={selGroup === i}
                handleClick={() => setSelGroup(i)}
              />
            ))}
          </div>
        )}

        {active === "res" &&
          !doubles &&
          res.map((round, index) => {
            // console.log(round);
            const p1 = round.playerOne;
            const p2 = round.playerTwo;
            const match = round;
            let showEdit = false;

            if (selGroup !== "all") {
              console.log(round.group, selGroup);
              if (round.group !== selGroup) {
                return null;
              }
            }

            if (searchInput !== "") {
              const sl = searchInput.toLowerCase();
              const n1 = p1.name.toLowerCase();
              const n2 = p2.name.toLowerCase();

              if (!n1.includes(sl) && !n2.includes(sl)) {
                return null;
              }
            }

            let dateString = "";

            if (match.date) {
              dateString = new Date(match.date.toDate()).toLocaleDateString(
                "en-us",
                { dateStyle: "medium" }
              );
            }

            return (
              <div key={index} className="mpr-match mb-20">
                {dateString && (
                  <div className="mprm-details mb-10">
                    <h3>{dateString}</h3>
                  </div>
                )}
                {match.group !== undefined && (
                  <div className="mprm-group">
                    <p>Group {match.group + 1}</p>
                  </div>
                )}
                <div className="mpb-match">
                  <PlayerItem
                    showBg={match.winner.playerID === p1.playerID}
                    hideRemove
                    hideEdit={true}
                    hcp={p1.handicap}
                    hideImage
                    showAdd
                    hideHCP={isNaN(p1.handicap)}
                    hideMessage
                    hideProfile
                    noLink
                    editAction={() => {}}
                    name={p1.name}
                  />
                  <div className="mp-cir-box">
                    {/* {round.roundID && !match.completed && (
                      <RssFeedIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )} */}
                    {round.roundID && (
                      <GolfCourseIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    <div className="mpbm-circle">
                      {match.leader === "" ? (
                        <p>vs</p>
                      ) : match.by === "BYE" ? (
                        <p>BYE</p>
                      ) : (
                        <>
                          {match.leader === p1.playerID ? (
                            <p>
                              {match.rem === 0
                                ? `${match.by} UP`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          ) : match.leader === "" ? (
                            <p>AS</p>
                          ) : (
                            <p>
                              {match.rem === 0
                                ? `${match.by} DN`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <PlayerItem
                    showBg={match.winner.playerID === p2.playerID}
                    hideRemove
                    hcp={p2.handicap}
                    hideImage
                    hideEdit={true}
                    hideHCP={isNaN(p2.handicap)}
                    showAdd
                    hideMessage
                    hideProfile
                    noLink
                    name={p2.name}
                    editAction={() => {}}
                  />
                </div>
              </div>
            );
          })}
        {active === "res" &&
          doubles &&
          res.map((round, index) => {
            const p1 = round.teamOne;
            const p2 = round.teamTwo;
            const match = round;
            // console.log(round);
            let showEdit = false;
            if (selGroup !== "all") {
              console.log(round.group, selGroup);
              if (round.group !== selGroup) {
                return null;
              }
            }
            if (searchInput !== "") {
              const sl = searchInput.toLowerCase();
              const n1 = p1.name.toLowerCase();
              const n2 = p2.name.toLowerCase();

              if (!n1.includes(sl) && !n2.includes(sl)) {
                return null;
              }
            }

            let dateString = "";

            if (match.date) {
              dateString = new Date(match.date.toDate()).toLocaleDateString(
                "en-us",
                { dateStyle: "medium" }
              );
            }
            return (
              <div key={index} className="mpr-match mb-20">
                {dateString !== "" && (
                  <div className="mprm-details mb-10">
                    <h3>{dateString}</h3>
                  </div>
                )}
                {match.group !== undefined && (
                  <div className="mprm-group">
                    <p>Group {match.group + 1}</p>
                  </div>
                )}

                <div className="mpb-match">
                  <PlayerItem
                    showBg={match.winner && match.winner.teamID === p1.teamID}
                    hideRemove
                    hideEdit={true}
                    // hcp={p1.handicap}
                    hideImage
                    showAdd
                    hideHCP
                    hideMessage
                    hideProfile
                    noLink
                    editAction={() => {}}
                    name={p1.name}
                  />
                  <div className="mp-cir-box">
                    {/* {round.roundID && !match.completed && (
                      <RssFeedIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )} */}
                    {round.roundID && (
                      <GolfCourseIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    <div className="mpbm-circle">
                      {match.leader === "" ? (
                        <p>vs</p>
                      ) : match.by === "BYE" ? (
                        <p>BYE</p>
                      ) : (
                        <>
                          {match.winner.teamID === p1.teamID ? (
                            <p>
                              {match.rem === 0
                                ? `${match.by} UP`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          ) : match.winner.name === "Tie" ? (
                            <p>AS</p>
                          ) : (
                            <p>
                              {match.rem === 0
                                ? `${match.by} DN`
                                : `${match.by} & ${match.rem}`}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <PlayerItem
                    showBg={match.winner && match.winner.teamID === p2.teamID}
                    hideRemove
                    hideImage
                    hideEdit={true}
                    hideHCP
                    showAdd
                    hideMessage
                    hideProfile
                    noLink
                    name={p2.name}
                    editAction={() => {}}
                  />
                </div>
              </div>
            );
          })}
        {active === "rounds" &&
          rounds.map((round, index) => {
            const { groups } = round;
            const p1 = groups[0];
            const p2 = groups[1];
            const match = round.leaderboard;
            match.score = match.by;
            let showEdit = !match.completed;
            // console.log(round);

            let rem = 18 - match.thru;
            if (match.completed) {
              // rem = 0;
            }
            if (searchInput !== "") {
              const sl = searchInput.toLowerCase();
              const n1 = p1.name.toLowerCase();
              const n2 = p2.name.toLowerCase();

              if (!n1.includes(sl) && !n2.includes(sl)) {
                return null;
              }
            }

            let leader = "";
            if (match.leader === "teamOne") {
              leader = p1.teamID;
            }
            if (match.leader === "teamTwo") {
              leader = p2.teamID;
            }

            console.log(leader);

            // console.log(match);

            return (
              <div key={round.roundID} className="mpr-match mb-20">
                <div className="mprm-details mb-10">
                  <h3>{round.dateString}</h3>
                </div>
                {round.group !== undefined && (
                  <div className="mprm-group">
                    <p>Group {round.group + 1}</p>
                  </div>
                )}

                <div className="mpb-match">
                  <PlayerItem
                    showBg={leader === p1.teamID}
                    hideRemove
                    hideEdit={
                      index > 0 || match.winnerID !== "" || match.started
                    }
                    hcp={p1.handicap}
                    hideImage
                    showAdd
                    hideHCP={isNaN(p1.handicap)}
                    hideMessage
                    hideProfile
                    noLink
                    editAction={() => {
                      setMatchEdit(match.match);
                    }}
                    name={p1.name}
                  />
                  <div className="mp-cir-box">
                    {round.roundID && !match.completed && (
                      <RssFeedIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    {round.roundID && match.completed && (
                      <GolfCourseIcon
                        onClick={() =>
                          window.open(
                            `https://www.theugatour.com/rounds/${round.roundID}`,
                            "_blank"
                          )
                        }
                        className="dg-icon icon-cursor mb-10"
                      />
                    )}
                    <div className="mpbm-circle">
                      {match.leader === "" ? (
                        <p>vs</p>
                      ) : match.by === "BYE" ? (
                        <p>BYE</p>
                      ) : (
                        <>
                          {leader === p1.teamID ? (
                            <p>
                              {rem === 0
                                ? `${match.score} UP`
                                : `${match.score} & ${18 - match.thru}`}
                            </p>
                          ) : (
                            <p>
                              {rem === 0
                                ? `${match.score} UP`
                                : `${match.score} & ${18 - match.thru}`}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    {showEdit && (
                      <EditIcon
                        onClick={() => {
                          // console.log(round);
                          setManMatch({
                            players: groups,
                            roundID: round.roundID,
                            matchIndex: round.matchIndex,
                          });
                          setShowManual(true);
                        }}
                        className="icon-cursor dg-icon-bg mt-10"
                      />
                    )}
                  </div>

                  <PlayerItem
                    showBg={leader === p2.teamID}
                    hideRemove
                    hcp={p2.handicap}
                    hideImage
                    hideEdit={
                      index > 0 || match.winnerID !== "" || match.started
                    }
                    hideHCP={isNaN(p2.handicap)}
                    showAdd
                    hideMessage
                    hideProfile
                    noLink
                    name={p2.name}
                    editAction={() => {}}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MatchplayTournamentFixtures;
