import React, { useState, useEffect } from "react";
import { db } from "../../firebase";

// Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";

// Components
import PlayerScore from "../../components/display/PlayerScore/PlayerScore";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import ContestInput from "./ContestInput";
import ContestPlayer from "./ContestPlayer";

function AdminContestInput({ contestID = "", players = {} }) {
  const [loading, setLoading] = useState(true);
  const [board, setBoard] = useState([]);

  const [units, setUnits] = useState("");
  const [scoreInput, setScoreInput] = useState("");
  const [showInput, setShowInput] = useState(false);

  const [input, setInput] = useState();

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const contestDoc = await db.collection("contests").doc(contestID).get();
    const contest = contestDoc.data();

    const { leaderboard, prizeType, percs, prizes, entrance, playerIDs } =
      contest;

    const arr = [];

    const purse = entrance * playerIDs.length;
    console.log(purse);

    for (const [key, value] of Object.entries(leaderboard)) {
      const player = players[key];
      const comb = { ...value, ...player };
      arr.push(comb);
    }
    const sorted = arr.sort((a, b) => {
      if (a.best === "") {
        return 1;
      }
      if (b.best === "") {
        return -1;
      }
      if (a.best < b.best) {
        return -1;
      } else {
        return 1;
      }
    });

    for (let i = 0; i < 3; i++) {
      if (sorted[i] === undefined) {
        continue;
      }

      if (prizeType === "accum") {
        const perc = parseInt(percs[i]) / 100;
        console.log(perc);
        const prize = purse * perc;
        if (prize !== 0) {
          sorted[i].prize = prize;
        }
      } else {
        const prize = prizes[i];
        if (prize !== 0) {
          sorted[i].rounds = prize;
        }
      }
    }

    setBoard(sorted);
    setLoading(false);
  }

  return (
    <div className="contest-input admin-contest-input">
      {loading && <BackdropLoader />}

      {showInput ? (
        <>{input}</>
      ) : (
        <>
          <div className="neh-box mt-20">
            <h2 className="ne-header">Score Input</h2>
          </div>

          <div
            className="leaderboard-players aci-players"
            style={{ width: "95%" }}
          >
            {/* <PlayerScore
              hideImage
              name="Name"
              rank={"Pos"}
              header
              thru={""}
              hcp={"Attempts"}
              score={"Score"}
            /> */}
            <div className="contest-player contest-player-header ">
              <p className="cp-pos">POS</p>
              <p className="cp-name">NAME</p>
              <p className="cp-pos">ATTEMPTS</p>
              <p className="cp-pos">BEST</p>
              <p className="cp-pos">INPUT</p>
            </div>
            {board.map((player, index) => (
              <ContestPlayer
                name={player.name}
                key={player.playerID}
                pos={index + 1}
                attempts={player.attempts.length}
                score={player.best}
                showEdit={true}
                handleEdit={() => {
                  setInput(
                    <ContestInput
                      contestID={contestID}
                      userID={player.playerID}
                      userName={player.name}
                      showBack
                      goBack={() => {
                        setShowInput(false);
                        load();
                      }}
                    />
                  );
                  setShowInput(true);
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AdminContestInput;
