import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import "./Matchdays.css";

// Icons

// Components
import CourseItem from "../display/CourseItem/CourseItem";
import BackRow from "../display/Rows/BackRow";
import BackdropLoader from "../Loaders/BackdropLoader";
import PageHeader from "../display/Rows/PageHeader";
import Pill from "../Buttons/Pill";
import MatchdayCreator from "./MatchdayCreator";
import RadioGroupTwo from "../Buttons/RadioGroup/RadioGroupTwo";
import MatchdayHome from "./MatchdayHome";

function Matchdays({ clubID = "", goBack = () => {} }) {
  // State
  const [loading, setLoading] = useState(true);

  const [matchdays, setMatchdays] = useState([]);
  const [past, setPast] = useState([]);
  const [future, setFuture] = useState([]);

  const [show, setShow] = useState(false);
  const [toShow, setToShow] = useState();

  const [sel, setSel] = useState("upcoming");

  const radioOptions = [
    {
      text: "Past",
      value: "past",
    },
    {
      text: "Upcoming",
      value: "upcoming",
    },
  ];

  useEffect(() => {
    loadMatchdays();
  }, []);

  async function loadMatchdays() {
    const matchdayDocs = await db
      .collection("matchdays")
      .where("clubID", "==", clubID)
      .orderBy("matchDate", "desc")
      .get();

    const arr = [];
    const parr = [];
    const farr = [];
    const now = new Date();

    for (let i = 0; i < matchdayDocs.docs.length; i++) {
      const mdDoc = matchdayDocs.docs[i];
      const matchdayID = mdDoc.id;
      const matchday = mdDoc.data();
      const dateObj = new Date(matchday.matchDate.toDate());
      const dateString = dateObj.toLocaleDateString("en-us", {
        dateStyle: "full",
      });
      matchday.matchdayID = matchdayID;
      matchday.dateObj = dateObj;
      matchday.dateString = dateString;
      if (dateObj > now) {
        farr.push(matchday);
      } else {
        parr.push(matchday);
      }
      arr.push(matchday);
    }
    console.log(arr.length);
    setMatchdays(arr);
    setPast(parr);
    setFuture(farr);
    setLoading(false);
  }

  return (
    <div className="matchdays-home">
      {show ? (
        toShow
      ) : (
        <>
          {loading && <BackdropLoader />}
          <BackRow action={goBack} />
          <PageHeader text="Matchdays" />
          <div className="quick-links">
            <Pill
              text={"New Matchday"}
              onClick={() => {
                setToShow(
                  <MatchdayCreator
                    clubID={clubID}
                    goBack={() => setShow(false)}
                    reload={loadMatchdays}
                  />
                );
                setShow(true);
              }}
            />
          </div>
          <RadioGroupTwo
            buttons={radioOptions}
            active={sel}
            setActive={setSel}
          />
          <div className="md-box">
            {sel === "past" &&
              past.map((match, index) => {
                return (
                  <CourseItem
                    courseName={match.name}
                    key={match.matchdayID}
                    hideStar
                    hideComma
                    city={`${match.eventIDs.length} Events`}
                    showCourse={() => {
                      setToShow(
                        <MatchdayHome
                          clubID={clubID}
                          goBack={() => setShow(false)}
                          matchdayID={match.matchdayID}
                          reload={loadMatchdays}
                        />
                      );
                      setShow(true);
                    }}
                  />
                );
              })}
            {sel === "upcoming" &&
              future.map((match, index) => {
                return (
                  <CourseItem
                    courseName={match.name}
                    key={match.matchdayID}
                    hideStar
                    hideComma
                    city={`${match.eventIDs.length} Events`}
                    showCourse={() => {
                      setToShow(
                        <MatchdayHome
                          clubID={clubID}
                          goBack={() => setShow(false)}
                          matchdayID={match.matchdayID}
                          reload={loadMatchdays}
                        />
                      );
                      setShow(true);
                    }}
                  />
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}

export default Matchdays;
