import React, { useState, useEffect } from "react";
import { admin, db } from "../../firebase";

// Icons
import { AddCircle } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// Components
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import { Dialog, Avatar } from "@mui/material";
import PageHeader from "../../components/display/Rows/PageHeader";
import BackRow from "../../components/display/Rows/BackRow";
import BackdropLoader from "../../components/Loaders/BackdropLoader";
import PlayerItem from "../../components/display/PlayerItem/PlayerItem";

function makeID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function MatchplayTeamManager({
  goBack = () => {},
  tournID = "",
  reload = () => {},
}) {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState("");

  const [showSelector, setShowSelector] = useState(false);

  const [players, setPlayers] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  const [teamEdit, setTeamEdit] = useState();
  const [playerEdit, setPlayerEdit] = useState();
  const [indexEdit, setIndexEdit] = useState([]);

  const [selPlayer, setSelPlayer] = useState();

  const [up, setUp] = useState(0);
  function update() {
    setUp((current) => current + 1);
  }

  useEffect(() => {
    loadTeams();
  }, []);

  async function saveChanges() {
    console.log(teams);
    setLoading(true);
    try {
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        console.log(team);
        const { players } = team;
        const name = `${players[0].name} / ${players[1].name}`;
        teams[i].name = name;
      }

      await db.collection("tournaments").doc(tournID).update({
        teams,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry, we encountered a technical difficulty there, please try again."
      );
    }
    setLoading(false);
  }

  async function loadTeams() {
    const tournDoc = await db.collection("tournaments").doc(tournID).get();
    const tourn = tournDoc.data();
    const { playerIDs } = tourn;
    const teams = tourn.teams ?? [];

    let takenIDs = [];

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      const teamIDs = team.playerIDs ?? [];
      takenIDs = [...takenIDs, ...teamIDs];
    }
    const filt = playerIDs.filter((id) => !takenIDs.includes(id));
    const refs = filt.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);
    const arr = [];
    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName, profileImage, handicap } = player;
      const name = `${firstName} ${lastName}`;
      arr.push({
        playerID,
        name,
        handicap,
        profileImage,
      });
    }

    setPlayers(arr);

    setTeams(teams);

    setLoading(false);
  }

  function addTeam() {
    setTeams((current) => {
      const toAdd = {
        teamID: makeID(10),
        players: [{ name: "Empty" }, { name: "Empty" }],
        playerIDs: [],
      };
      current.push(toAdd);
      return current;
    });
    update();
  }

  function teamChange() {
    setShowSelector(false);
    setTeams((current) => {
      const i1 = indexEdit[0];
      const i2 = indexEdit[1];
      const toChange = current[i1].players[i2];
      if (toChange.name !== "Empty") {
        setPlayers((current) => {
          current.push(toChange);
          const filt = current.filter(
            (pl) => pl.playerID !== selPlayer.playerID
          );
          return filt;
        });
      } else {
        setPlayers((current) => {
          const filt = current.filter(
            (pl) => pl.playerID !== selPlayer.playerID
          );
          return filt;
        });
        current[i1].playerIDs = current[i1].playerIDs.filter(
          (id) => id !== toChange.playerID
        );
      }
      current[i1].players[i2] = selPlayer;
      current[i1].players.push(selPlayer.playerID);
      return current;
    });
    setSelPlayer();
    setIndexEdit([]);
    update();
  }

  function shuffle(array) {
    // alert('everyday')
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  function shuffleTeams() {
    const avail = [...players];

    const temp = [...teams];

    for (let i = 0; i < temp.length; i++) {
      for (let x = 0; x < temp[i].players.length; x++) {
        const playr = temp[i].players[x];
        if (playr.name !== "Empty") {
          avail.push(playr);
        }
      }
    }

    const shuffled = shuffle(avail);

    const arr = [];

    for (let i = 0; i < shuffled.length; i++) {
      const player = shuffled[i];
      if (i % 2 === 0) {
        arr.push({
          teamID: makeID(10),
          playerIDs: [player.playerID],
          players: [player, { name: "Empty" }],
        });
      } else {
        arr[arr.length - 1].playerIDs.push(player.playerID);
        arr[arr.length - 1].players[1] = player;
      }
    }

    console.log(arr);
    setTeams(arr);
    setPlayers([]);
    update();
  }

  return (
    <div className="mpl-team-manager">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}

      <Dialog open={showSelector} onClose={() => setShowSelector(false)}>
        <div className="mpl-selector">
          <PageHeader
            text="Select a player for this slot"
            showClose
            close={() => setShowSelector(false)}
          />
          <div className="mpls-players">
            {players.map((player) => {
              if (!searchInput === "") {
                const sl = searchInput.toLowerCase();
                const nl = player.name.toLowerCase();
                if (!nl.includes(sl)) {
                  return null;
                }
              }
              const showBG =
                selPlayer && selPlayer.playerID === player.playerID;

              return (
                <PlayerItem
                  noLink
                  key={`${player.playerID}sel`}
                  hideImage
                  showBg={showBG}
                  hideIcons
                  hideRemove
                  hcp={player.handicap}
                  name={player.name}
                  img={player.profileImage}
                  showProfile={() => {
                    if (showBG) {
                      setSelPlayer();
                    } else {
                      setSelPlayer(player);
                    }
                  }}
                />
              );
            })}
          </div>

          <div className="flex-center">
            <button
              onClick={() => {
                teamChange();
              }}
              className="default-button mt-20 mb-20"
              disabled={!selPlayer}
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
      <BackRow action={goBack} />
      <PageHeader text="Matchplay Team Manager" />
      {loading && <BackdropLoader />}
      <div className="ph-msg">
        <p>Select your teams for this tournament</p>
      </div>

      <div className="flex-align-center">
        <p onClick={addTeam} className="green-link mt-20 ml-20 mb-20">
          Add Team
        </p>
        <p onClick={shuffleTeams} className="green-link ml-20">
          Shuffle
        </p>
      </div>

      {players.length > 0 && (
        <>
          <div className="sub-header">
            <h5>Pending</h5>
          </div>

          <div className="gs-removed">
            {players.map((player) => (
              <div key={`${player.playerID}removed`} className="gsr-item">
                <Avatar alt={player.name} src={player.profileImage} />
                <p>{player.name}</p>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="mtm-teams">
        {teams.map((team, index) => {
          const playerOne = team.players[0];
          const playerTwo = team.players[1];
          return (
            <div className="mtm-team" key={team.teamID}>
              <div className="mtmt-header pd-20">
                <h2 className="ne-header">Team {index + 1}</h2>
                <DeleteForeverIcon
                  className="icon-cursor red-icon"
                  onClick={() => {
                    setTeams((current) => {
                      current.splice(index, 1);
                      console.log(current);
                      return current;
                    });
                    setPlayers((current) => {
                      current.push(playerOne);
                      current.push(playerTwo);
                      return current;
                    });
                    update();
                  }}
                />
              </div>
              <div className="mtmt-players">
                {playerOne.name === "Empty" ? (
                  <div className="mtmt-empty gs-empty">
                    <AddCircle
                      onClick={() => {
                        setIndexEdit([index, 0]);
                        setShowSelector(true);
                      }}
                      className="big-icon icon-cursor dg-icon"
                      sx={{ fill: "url(#linearColors)" }}
                    />
                  </div>
                ) : (
                  <PlayerItem
                    name={playerOne.name}
                    img={playerOne.profileImage}
                    hcp={playerOne.handicap}
                    hideMessage
                    hideProfile
                    showAdd
                    hideEdit
                    hideImage
                    removeAction={() => {
                      setPlayers((current) => {
                        current.push(playerOne);
                        return current;
                      });
                      setTeams((current) => {
                        current[index].players[0] = {
                          name: "Empty",
                        };
                        return current;
                      });
                      update();
                    }}
                  />
                )}
                {playerTwo.name === "Empty" ? (
                  <div className="mtmt-empty gs-empty">
                    <AddCircle
                      onClick={() => {
                        setIndexEdit([index, 1]);
                        setShowSelector(true);
                      }}
                      className="big-icon icon-cursor dg-icon"
                      sx={{ fill: "url(#linearColors)" }}
                    />
                  </div>
                ) : (
                  <PlayerItem
                    name={playerTwo.name}
                    img={playerTwo.profileImage}
                    hcp={playerTwo.handicap}
                    hideProfile
                    showAdd
                    hideEdit
                    hideMessage
                    hideImage
                    removeAction={() => {
                      setPlayers((current) => {
                        current.push(playerTwo);
                        return current;
                      });
                      setTeams((current) => {
                        current[index].players[1] = {
                          name: "Empty",
                        };
                        return current;
                      });
                      update();
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex-center mb-40">
        <button onClick={saveChanges} className="default-button">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default MatchplayTeamManager;
