import { Skeleton } from "@mui/material";
import React from "react";
import "./Loaders.css";
function CoursePickerLoader() {
  return (
    <div className="copi-loader">
      <div className="cpl-top">
        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      </div>

      <Skeleton className="mt-20" variant="text" sx={{ fontSize: "1rem" }} />

      <div className="cpl-courses mt-10">
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={200}
          height={120}
        />
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={200}
          height={120}
        />
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={200}
          height={120}
        />
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={200}
          height={120}
        />
      </div>
      <Skeleton className="mt-20" variant="text" sx={{ fontSize: "1rem" }} />

      <div className="cpl-courses mt-10">
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={100}
          height={120}
        />
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={100}
          height={120}
        />
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={100}
          height={120}
        />
        <Skeleton
          sx={{ marginRight: "20px" }}
          variant="rectangular"
          width={100}
          height={120}
        />
      </div>
    </div>
  );
}

export default CoursePickerLoader;
