/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import "./CupHome.css";
import { admin, db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { Steps } from "intro.js-react";
import { Avatar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TodayIcon from "@mui/icons-material/Today";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Dialog from "@mui/material/Dialog";
import PlayerTeeSelector from "../Events/EventCreator/PlayerTeeSelector";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ErrorIcon from "@mui/icons-material/Error";
import Info from "@mui/icons-material/Info";
import { BallTriangle, TailSpin } from "react-loader-spinner";
import BackdropLoader from "../Loaders/BackdropLoader";
import EventHandicapEdit from "../Events/EventDashboard/EventHandicapEdit";
import { useParams, useNavigate, Link } from "react-router-dom";

// Components
import EventRound from "../Events/EventCreator/EventRound";
import CupDashboard from "../Events/EventDashboard/CupDashboard";
import CupCreator from "./CupCreator";
import PlayerItem from "../display/PlayerItem/PlayerItem";
import CupRound from "./CupRound";
import Pill from "../Buttons/Pill";
import TeamSelector from "./TeamSelector";
import EventPlayerSelector from "../Events/EventCreator/EventPlayerSelector";
import ManualPlayerAdd from "../Events/EventCreator/ManualPlayerAdd";
import CupMatchups from "./CupMatchups";
import PageHeader from "../display/Rows/PageHeader";

function CupHome({ reload = () => {} }) {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const cupID = useParams().id;
  const [loaded, setLoaded] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading event...");
  const [error, setError] = useState("");
  const [modal, setModal] = useState();
  const [showRemove, setShowRemove] = useState(false);
  const [playerRemove, setPlayerRemove] = useState("");
  const [showPop, setShowPop] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showRound, setShowRound] = useState(false);
  const [round, setRound] = useState();
  const [showCancel, setShowCancel] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [showReset, setShowReset] = useState(false);

  const [rounds, setRounds] = useState([]);

  const [roundToChange, setRoundToChange] = useState("");

  const [roundIDs, setRoundIDs] = useState([]);

  const [players, setPlayers] = useState([]);

  const [showInvite, setShowInvite] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const { currentUser, onboarding, setOnboard, userDetails } = useAuth();

  const props = {
    cupName: "",
    eventDate: "",
    imageLink: "",
    rounds: [],
    roundIDs: [],
    rules: [],
    taskID: "",
    courseID: "",
    courseName: "",
    dateString: "",
    description: "",
    playerIDs: [],
    matchups: "Admin",
    teeMap: {},
    teams: [],
    nassau: false,
    enabledStats: [],
  };

  const [cup, setCup] = useState(props);

  useEffect(() => {
    loadCup();
  }, []);

  const [course, setCourse] = useState();
  const [disableChanges, setDisableChanges] = useState(false);

  const [toInvite, setToInvite] = useState([]);

  async function loadFriends(uids = []) {
    const refs = uids.map((uid) => db.collection("users").doc(uid).get());
    const docs = await Promise.all(refs);

    const arr = [];

    for (let i = 0; i < docs.length; i++) {
      const playerDoc = docs[i];
      if (!playerDoc.exists) {
        continue;
      }
      const playerID = playerDoc.id;
      const player = playerDoc.data();
      const { firstName, lastName, handicap, profileImage } = player;
      const name = `${firstName} ${lastName}`;
      arr.push({
        name,
        playerID,
        handicap,
        profileImage,
        firstName,
        lastName,
      });
    }

    setToInvite(arr);
  }

  async function loadCup() {
    setLoaded(false);
    const cupDoc = await db.collection("cups").doc(cupID).get();
    const cup = cupDoc.data();

    const {
      tasKID,
      courseID,
      courseName,
      cupDate,
      cupName,
      description,
      playerIDs,
      matchups,
      rules,
      teeMap,
      teams,
      imageLink,
      rounds,
      roundIDs,
      nassau,
      seeding,
      scratchMode,
    } = cup;
    if (roundIDs.length > 0) {
      setDisableChanges(true);
    }
    const playerHandicaps = cup.playerHandicaps ?? {};
    const { friends } = userDetails;
    const filterde = friends.filter((uid) => !playerIDs.includes(uid));
    loadFriends(filterde);

    await loadRounds(roundIDs);

    const refs = playerIDs.map((uid) => db.collection("users").doc(uid).get());
    const playerDocs = await Promise.all(refs);
    setRoundIDs(roundIDs);
    const arr = [];
    for (let i = 0; i < playerDocs.length; i++) {
      const playerDoc = playerDocs[i];
      const player = playerDoc.data();
      const playerID = playerDoc.id;
      const { firstName, lastName, profileImage } = player;
      const handicap = playerHandicaps[playerID] ?? player.handicap;
      const name = `${firstName} ${lastName}`;
      arr.push({
        playerID,
        name,
        handicap,
        profileImage,
        originalHandicap: player.handicap,
      });
    }

    setPlayers(arr);

    const enabledStats = cup.enabledStats ?? [];
    const teamOneColor = cup.teamOneColor ?? "#21c17c";
    const teamTwoColor = cup.teamTwoColor ?? "#e24955";
    const courseDoc = await db.collection("courses").doc(courseID).get();
    const course = courseDoc.data();
    const { teeArray, tees, length } = course;
    setCourse({
      teeArray,
      tees,
      length,
      imageLink: course.imageLink,
    });
    const dateObj = new Date(cupDate.toDate());
    const dateString = new Date(cupDate.toDate()).toLocaleDateString("en-us", {
      dateStyle: "full",
    });

    setCup({
      completion: cup.completion ?? "auto",
      bbLeaderboard: cup.bbLeaderboard ?? false,
      allowance: cup.allowance ?? 100,
      dateObj,
      taskID: tasKID,
      courseID,
      courseName,
      cupName,
      seeding,
      length,
      description,
      playerIDs,
      matchups,
      rules,
      teeMap,
      teams,
      date: cupDate,
      cupDate,
      dateString,
      imageLink,
      rounds,
      roundIDs,
      scratchMode,
      nassau,
      enabledStats,
      teeArray,
      teamOneColor,
      teamTwoColor,
    });
    setLoaded(true);
  }

  async function loadRounds(roundIDs = []) {
    const refs = roundIDs.map((roundID) =>
      db.collection("rounds").doc(roundID).get()
    );
    const docs = await Promise.all(refs);

    const arr = [];

    for (let i = 0; i < docs.length; i++) {
      const roundDoc = docs[i];
      if (!roundDoc.exists) {
        continue;
      }
      const roundID = roundDoc.id;
      const round = roundDoc.data();
      const { matches } = round;
      let started = false;
      for (let n = 0; n < matches.length; n++) {
        if (matches[n].thru !== 0) {
          started = true;
        }
      }
      console.log(started);
      round.started = started;
      round.roundID = roundID;
      arr.push(round);
    }

    setRounds(arr);
  }

  async function removeRound(index) {
    const rounds = cup.rounds;
    rounds.splice(index, 1);
    // return console.log(rounds)

    try {
      await db.collection("cups").doc(cupID).update({
        rounds,
      });
      setShowPop(false);
      loadCup();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (onboarding) {
      if (onboarding.cupHome === false) {
        setHintsEnabled(true);
      }
    }
  }, [onboarding]);

  async function cancelEvent() {
    setShowCancel(false);
    setError("");
    setLoadingText("Cancelling event...");
    setLoaded(false);

    const tasks = await db
      .collection("tasks")
      .where("eventID", "==", cupID)
      .where("type", "==", "cupRundCreation")
      .get();

    const task = tasks.docs[0];

    task.ref.delete();

    try {
      await db.collection("cups").doc(cupID).update({
        cancelled: true,
      });
      reload();
      goBack();
    } catch (err) {
      setError("Could not cancel your event, please try again");
    }
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);
  const steps = [
    {
      element: ".edit-hint",
      intro: "Tap here to edit the event's details as well as invite players",
    },
    {
      element: ".round-hint",
      intro: "Add a round for this cup",
    },
  ];

  async function saveTeams(teams) {
    setLoaded(false);
    await db.collection("cups").doc(cupID).update({
      teams,
    });
    loadCup();
    setLoaded(true);
  }

  async function removePlayer() {
    setShowRemove(false);
    setLoaded(false);

    const cupDoc = await db.collection("cups").doc(cupID).get();
    const cup = cupDoc.data();
    const { teams, playerIDs } = cup;
    for (let i = 0; i < teams.length; i++) {
      const filterede = teams[i].players.filter((player) => {
        // console.log(playerIDs, player.playerID);
        // console.log(playerIDs.includes(player.playerID));
        return playerIDs.includes(player.playerID);
      });
      teams[i].players = filterede;
    }
    try {
      await db
        .collection("cups")
        .doc(cupID)
        .update({
          playerIDs: admin.firestore.FieldValue.arrayRemove(playerRemove),
          teams,
        });
      setPlayers((current) =>
        current.filter((item) => item.playerID !== playerRemove)
      );
      setLoaded(true);
    } catch (err) {
      setError("Could not remove player, please try again");
      setLoaded(true);
    }
  }

  async function saveLiveMatches(matches = [], roundIndex = 0) {
    setLoaded(false);
    const round = rounds[roundIndex];
    const currentMatches = round.matches;
    const format = round.format;
    for (let i = 0; i < matches.length; i++) {
      if (format === "Fourball") {
        currentMatches[i].teamOne = matches[i].teamOne;
        currentMatches[i].teamTwo = matches[i].teamTwo;
      } else {
        currentMatches[i].playerOne = matches[i].playerOne;
        currentMatches[i].playerTwo = matches[i].playerTwo;
      }
    }

    await db.collection("rounds").doc(round.roundID).update({
      matches: currentMatches,
    });
  }

  async function createRounds() {
    setShowCreate(false);
    setLoaded(false);
    const taskDocs = await db
      .collection("tasks")
      .where("cupID", "==", cupID)
      .where("type", "==", "cupRoundCreation")
      .get();
    if (taskDocs.docs.length === 0) {
      setLoaded(true);
      return setError(
        "Sorry, we encountered a technical difficulty there. Please try again."
      );
    }

    const taskDoc = taskDocs.docs[0];
    await taskDoc.ref.update({
      status: "triggered",
    });
    setDisableChanges(true);
    loadCup();
  }

  async function resetEvt() {
    setLoaded(false);
    setShowReset(false);
    const rdDocs = await db
      .collection("rounds")
      .where("cup", "==", cupID)
      .get();

    rdDocs.forEach((rdDoc) => rdDoc.ref.delete());

    await db.collection("cups").doc(cupID).update({
      roundIDs: [],
    });

    const triggerDocs = await db
      .collection("tasks")
      .where("cupID", "==", cupID)
      .where("type", "==", "cupRoundCreation")
      .get();

    await triggerDocs.docs[0].ref.update({
      status: "pending",
    });

    const refs = players.map((pl, i) =>
      db.collection("users").doc(pl.playerID).update({
        activeRoundID: "",
        activeTeamEventID: "",
        activeCupID: "",
      })
    );

    await Promise.all(refs);

    await loadCup();
  }

  return (
    <div className="cup-home event-home onboarding">
      <Dialog open={showReset} onClose={() => setShowReset(false)}>
        <div className="delete-popup">
          <Info className="big-icon dg-icon" />
          <p>Are you sure?</p>
          <div className="flex-center">
            <button onClick={resetEvt} className="default-button">
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      {!loaded && <BackdropLoader />}
      {modal}
      {showInvite && (
        <ManualPlayerAdd
          players={toInvite}
          reload={loadCup}
          cupID={cupID}
          hide={() => setShowInvite(false)}
        />
      )}
      {!loaded ? (
        <div className="loading-container elc">
          {/* <TailSpin color="#21c17c" height={80} width={80} /> */}
          {/* <p>{loadingText}</p> */}
        </div>
      ) : showRound ? (
        round
      ) : showEdit ? (
        <CupCreator
          disableChanges={cup.roundIDs.length > 0}
          reload={() => {
            loadCup();
            reload();
          }}
          defaultAllowance={cup.allowance}
          defaultBB={cup.bbLeaderboard}
          edit={true}
          defaultImageLink={cup.imageLink}
          defaultSeeding={cup.seeding}
          defaultTees={cup.teeArray}
          defaultStats={cup.enabledStats}
          goBack={() => setShowEdit(false)}
          cupID={cupID}
          taskID={cup.taskID}
          defaultNassau={cup.nassau}
          defaultCourseID={cup.courseID}
          defaultCourseName={cup.courseName}
          defaultDate={cup.dateObj}
          defaultEventName={cup.cupName}
          defaultDescription={cup.description}
          defaultPlayers={cup.playerIDs}
          defaultMatchups={cup.matchups}
          defaultRules={cup.rules}
          teeMap={cup.teeMap}
          defaultTeams={cup.teams}
          rounds={cup.rounds}
          defaultLength={cup.length}
          defaultScratchMode={cup.scratchMode}
          defaultTeamOneColor={cup.teamOneColor}
          defaultTeamTwoColor={cup.teamTwoColor}
        />
      ) : (
        <div className=" scale-in-ps">
          <Dialog open={showCreate} onClose={() => setShowCreate(false)}>
            <div className="delete-popup">
              <Info className="big-icon dg-icon" />
              <p>
                Are you sure you want to create the rounds for this event? Some
                details cannot be changed once this action has been performed.
              </p>
              <div className="flex-center">
                <button onClick={createRounds} className="default-button">
                  Confirm
                </button>
              </div>
            </div>
          </Dialog>
          <Dialog open={showPop} onClose={() => setShowPop(false)}>
            <div className="delete-popup">
              <ErrorIcon className="err-icon" style={{ color: "#a9363f" }} />
              <p>Are you sure you want to delete this round?</p>
              <div
                onClick={() => removeRound(deleteIndex)}
                className="delete-button"
              >
                Delete
              </div>
            </div>
          </Dialog>
          <Dialog open={showCancel} onClose={() => setShowCancel(false)}>
            <div className="delete-popup">
              <ErrorIcon className="err-icon" style={{ color: "#a9363f" }} />
              <p>Are you sure you want to cancel this event?</p>
              <div onClick={() => cancelEvent()} className="delete-button">
                Confirm
              </div>
            </div>
          </Dialog>

          <Dialog open={showRemove} onClose={() => setShowRemove(false)}>
            <div className="delete-popup">
              <ErrorIcon className="err-icon" style={{ color: "#a9363f" }} />
              <p>Are you sure you want to remove this player?</p>
              <div onClick={() => removePlayer()} className="delete-button">
                Confirm
              </div>
            </div>
          </Dialog>

          <div onClick={() => goBack()} className="back-row">
            <ArrowBack className="ts-arrow" />
            <p>Back</p>
          </div>

          <PageHeader text={cup.cupName} />
          <div className="sub-header mb-10">
            <h5>Quick Links</h5>
          </div>

          <div className="quick-links">
            {cup.roundIDs && cup.roundIDs.length > 0 && (
              <Pill onClick={() => setShowReset(true)} text={"reset"} />
            )}
            <Pill onClick={() => setShowEdit(true)} text={"Edit Details"} />
            {!disableChanges && (
              <Pill
                text={"Add Players"}
                onClick={() => {
                  setShowInvite(true);
                }}
              />
            )}
            {!disableChanges && (
              <Pill
                text={"Manage Teams"}
                onClick={() => {
                  setRound(
                    <TeamSelector
                      players={players}
                      teams={cup.teams ?? []}
                      setTeams={(e) => {
                        console.log(e);
                        saveTeams(e);
                      }}
                      goBack={() => setShowRound(false)}
                    />
                  );
                  setShowRound(true);
                }}
              />
            )}
            {!disableChanges && (
              <Pill
                text={"Manage Handicaps"}
                onClick={() => {
                  setRound(
                    <EventHandicapEdit
                      courseID={cup.courseID}
                      defaultPlayers={players}
                      hide={() => setShowRound(false)}
                      cupID={cupID}
                      live={false}
                    />
                  );
                  setShowRound(true);
                }}
              />
            )}
            {!disableChanges && (
              <Pill
                text={"Add Round"}
                onClick={() => {
                  setRound(
                    <CupRound
                      seeding={cup.seeding}
                      course={course}
                      defaultImageLink={course.imageLink}
                      defaultLength={course.length}
                      defaultTeeArray={course.teeArray}
                      defaultTees={course.tees}
                      courseID={cup.courseID}
                      courseName={cup.courseName}
                      cupDate={cup.dateObj}
                      playerIDs={cup.playerIDs}
                      cupID={cupID}
                      goBack={() => setShowRound(false)}
                      roundIndex={cup.rounds.length}
                      teams={cup.teams}
                      reload={loadCup}
                      rounds={cup.rounds}
                      defaultDate={
                        cup.rounds.length === 0 &&
                        cup.dateObj.toISOString().split("T")[0]
                      }
                    />
                  );
                  setShowRound(true);
                }}
              />
            )}

            {cup.roundIDs.length === 0 &&
              cup.rounds.length > 0 &&
              !disableChanges && (
                <Pill
                  onClick={() => setShowCreate(true)}
                  text={"Create Rounds"}
                />
              )}
            {disableChanges && (
              <Link to={`/cup-dash/${cupID}`}>
                <Pill
                  text={"Live Dashboard"}
                  // onClick={() => {
                  //   setRound(
                  //     <CupDashboard
                  //       cupID={cupID}
                  //       goBack={() => setShowRound(false)}
                  //     />
                  //   );
                  //   setShowRound(true);
                  // }}
                />
              </Link>
            )}
          </div>

          <div className="ehb-header">
            <h1 className="ne-header mr-20">Details</h1>
            <EditIcon
              className="icon-cursor dg-icon-bg mb-10"
              onClick={() => setShowEdit(true)}
            />
          </div>
          <div className="eh-box">
            <div className="ehbh-item">
              <div className="info-circle">
                <TodayIcon className="ic-icon" />
              </div>
              <p>{cup.dateString}</p>
            </div>
            <div className="ehbh-item">
              <div className="info-circle">
                <GolfCourseIcon className="ic-icon" />
              </div>
              <p>{cup.courseName}</p>
            </div>
            {cup.description !== "" && (
              <div className="ehbh-item">
                <div className="info-circle">
                  <TextSnippetIcon className="ic-icon" />
                </div>
                <p style={{ width: "70vw" }}>{cup.description}</p>
              </div>
            )}
          </div>

          <div className="ehb-header">
            <h1 className="ne-header mr-20">Rounds</h1>
            {!disableChanges && (
              <AddCircleIcon
                className="icon-cursor dg-icon-bg mb-10"
                onClick={() => {
                  setRound(
                    <CupRound
                      seeding={cup.seeding}
                      course={course}
                      defaultImageLink={course.imageLink}
                      defaultLength={course.length}
                      defaultTeeArray={course.teeArray}
                      defaultTees={course.tees}
                      courseID={cup.courseID}
                      courseName={cup.courseName}
                      cupDate={cup.dateObj}
                      playerIDs={cup.playerIDs}
                      cupID={cupID}
                      goBack={() => setShowRound(false)}
                      roundIndex={cup.rounds.length}
                      teams={cup.teams}
                      rounds={cup.rounds}
                      defaultDate={
                        cup.rounds.length === 0 &&
                        cup.dateObj.toISOString().split("T")[0]
                      }
                    />
                  );
                  setShowRound(true);
                }}
              />
            )}
          </div>

          <div className="ehb-rounds">
            {cup.rounds.map((round, index) => {
              const roundd = rounds[index] ?? {};
              const started = roundd.started || false;
              console.log(started, disableChanges);
              return (
                <EventRound
                  liveCup={disableChanges && started}
                  hideIcons={disableChanges}
                  custom={round.groupTypes === "custom"}
                  key={index}
                  editGroups={() => {
                    setRound(
                      <CupMatchups
                        showBack
                        saveMatches={saveLiveMatches}
                        teams={cup.teams}
                        teamOneColor={roundd.teamOneColor}
                        teamTwoColor={roundd.teamTwoColor}
                        goBack={() => setShowRound(false)}
                        defaultMatches={roundd.matches}
                        format={roundd.format}
                      />
                    );
                    setShowRound(true);
                  }}
                  editTees={() => {
                    setRound(
                      <PlayerTeeSelector
                        goBack={() => setShowRound(false)}
                        eventID={cupID}
                        eventName={cup.cupName}
                        players={players}
                        round={round}
                        roundIndex={index}
                        rounds={cup.rounds}
                        cup
                      />
                    );
                    setShowRound(true);
                  }}
                  editTimes={() => {}}
                  edit={() => {
                    if (disableChanges) {
                      setRoundToChange(roundIDs[index]);
                      setRound(
                        <EventHandicapEdit
                          courseID={cup.courseID}
                          defaultPlayers={players}
                          hide={() => setShowRound(false)}
                          cupID={cupID}
                          live
                          roundID={roundIDs[index]}
                        />
                      );
                      setShowRound(true);
                    } else {
                      setRound(
                        <CupRound
                          defaultDate={
                            new Date(round.date.toDate())
                              .toISOString()
                              .split("T")[0]
                          }
                          defaultTimesArray={round.timesArray ?? []}
                          defaultCTPEnabled={round.ctpEnabled}
                          defaultLDEnabled={round.ldEnabled}
                          defaultLDHole={round.ldHole}
                          defaultCTPHole={round.ctpHole}
                          seeding={cup.seeding}
                          defaultTeeArray={round.teeArray}
                          defaultLength={round.length}
                          course={course}
                          defaultTees={round.tees}
                          courseID={round.courseID}
                          courseName={round.courseName}
                          cupDate={cup.cupDate}
                          cupID={cupID}
                          goBack={() => setShowRound(false)}
                          roundIndex={index}
                          teams={cup.teams}
                          defaultMatches={round.matches}
                          playerIDs={cup.playerIDs}
                          rounds={cup.rounds}
                          reload={loadCup}
                          defaultFormat={round.format}
                          edit={true}
                        />
                      );
                      setShowRound(true);
                    }
                  }}
                  remove={() => {
                    setDeleteIndex(index);
                    setShowPop(true);
                  }}
                  img={round.imageLink}
                  date={round.date}
                  num={index + 1}
                />
              );
            })}

            {cup.rounds.length === 0 && (
              <div className="no-rounds">
                <p>No rounds added yet</p>
              </div>
            )}
          </div>

          <div className="ehb-header">
            <h1 className="ne-header">Players</h1>
            <AddCircleIcon
              className="icon-cursor dg-icon-bg mb-10"
              onClick={() => {
                setShowInvite(true);
              }}
            />
          </div>

          <div className="part-players-box pl-20">
            {players.map((player) => (
              <div key={player.playerID} className="ec-pi">
                <PlayerItem
                  event
                  playerID={player.playerID}
                  hideRemove={disableChanges}
                  // hideEdit={disableChanges}
                  hideEdit
                  showAdd
                  hcp={player.handicap}
                  name={player.name}
                  img={player.profileImage}
                  removeAction={() => {
                    setPlayerRemove(player.value);
                    setShowRemove(true);
                  }}
                  editAction={() => {}}
                />
              </div>
            ))}
            {players.length === 0 && (
              <div className="no-players">
                <p>No players have confirmed their attendance yet</p>
              </div>
            )}
          </div>

          <div className="flex-center mt-20 mb-20">
            <button
              onClick={() => setShowCancel(true)}
              className="cancel-button delete-button"
            >
              Cancel Event
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CupHome;
