/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "./CupRound.css";
import { db } from "../../firebase";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Steps } from "intro.js-react";
import CupFormatPicker from "./CupFormatPicker";
import CupMatchups from "./CupMatchups";
import Switch from "react-ios-switch";
import Edit from "@mui/icons-material/Edit";
import BackdropLoader from "../Loaders/BackdropLoader";
import { Dialog } from "@mui/material";
import { BallTriangle } from "react-loader-spinner";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAuth } from "../../contexts/AuthContext";
import CoursePicker from "../Events/CoursePicker/CoursePicker";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ErrorModal from "../ErrorModal/ErrorModal";
import BackRow from "../display/Rows/BackRow";
import PageHeader from "../display/Rows/PageHeader";
function CupRound({
  cupID = "",
  goBack = () => {},
  reload = () => {},
  roundIndex = 0,
  teams = [],
  defaultDate = "",
  edit = false,
  playerIDs = [],
  courseName = "",
  courseID = "",
  cupDate = new Date(),
  rounds = [],
  defaultMatches = [],
  defaultFormat = "Fourball",
  defaultCTPEnabled = false,
  defaultLDEnabled = false,
  defaultCTPHole = "",
  defaultLDHole = "",
  // course = [],
  defaultTimesArray = [],
  seeding = false,
  defaultTees = [],
  // defaultCourseID = '',
  // defaultCourseName = '',
  defaultImageLink = "",
  defaultTeeArray = [],
  defaultLength,
}) {
  const formats = [
    {
      value: "Matchplay",
      label: "Match Play",
    },
    {
      value: "Fourball",
      name: "Fourball",
    },
    // {
    //     value: 'Foursomes',
    //     name: 'Foursomes',
    //     description: 'Two teams of two golfers go up against each other in a game of match play. Teammates alternate playing the same ball on a given hole, as well as alternating tee shots.',
    // }
  ];

  const [loading, setLoading] = useState(false);
  const [format, setFormat] = useState(defaultFormat);
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState("");
  const { currentUser } = useAuth();
  const [chosen, setChosen] = useState(true);
  const [course, setCourse] = useState({
    label: courseName,
    value: courseID,
    courseID: courseID,
    name: courseName,
    imageLink: defaultImageLink,
    tees: defaultTees,
    teeArray: defaultTeeArray,
    length: defaultLength,
  });
  const [error, setError] = useState("");

  const [cupTeams, seCupTeams] = useState(teams);

  const [showMatch, setShowMatch] = useState(false);
  const [showFormats, setShowFormats] = useState(false);
  const [userDates, setUserDates] = useState([]);
  const [enableClosest, setEnableClosest] = useState(defaultCTPEnabled);
  const [ctpHole, setCTPHole] = useState(defaultCTPHole);
  const [ctpOptions, setCTPOptions] = useState([]);

  const [enableLD, setEnableLD] = useState(defaultLDEnabled);
  const [ldHole, setLDHole] = useState(defaultLDHole);
  const [ldOptions, setLDOptions] = useState([]);

  const [timesArray, setTimesArray] = useState(defaultTimesArray);

  const [matches, setMatches] = useState(defaultMatches);

  const dateRef = useRef();

  const [dateState, setDateState] = useState(defaultDate);

  useEffect(() => {
    // console.log(course)
    const arr = [];
    const ldArr = [];
    if (course.tees) {
      const tee = course.teeArray[0];

      for (let i = 0; i < course.tees[tee].length; i++) {
        const hole = course.tees[tee][i];
        // console.log(hole);
        if (hole.par === 3) {
          arr.push({
            value: i,
            label: `Hole ${i + 1}`,
          });
        } else {
          ldArr.push({
            value: i,
            label: `Hole ${i + 1}`,
          });
        }
      }
      setCTPOptions(arr);
      setLDOptions(ldArr);
    }
  }, [course]);

  useEffect(() => {
    // console.log("TRIGGEED");
    if (seeding) {
      sortTimes();
    }
  }, [format]);

  useEffect(() => {
    // console.log(defaultFormat);
  }, []);

  function sortTimes() {
    // if (timesArray.length === 0) {
    const timesArr = [];
    if (format === "Fourball") {
      const amount = Math.floor(playerIDs.length / 4);
      console.log(amount);
      for (let i = 0; i < amount; i++) {
        timesArr.push("");
      }
    } else {
      const amount = Math.floor(playerIDs.length / 2);
      console.log(amount);

      for (let i = 0; i < amount; i++) {
        timesArr.push("");
      }
    }
    setTimesArray(timesArr);
    // }
  }

  useEffect(() => {
    if (currentUser) {
      loadUserDates();
    }
  }, [currentUser]);

  async function loadUserDates() {
    const now = new Date();
    const cups = await db
      .collection("cups")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("cupDate", ">", now)
      .get();
    const events = await db
      .collection("events")
      .where("playerIDs", "array-contains", currentUser.uid)
      .where("eventDate", ">", now)
      .get();

    const dateArr = [];
    for (let i = 0; i < cups.docs.length; i++) {
      const cupDoc = cups.docs[i];
      const cup = cupDoc.data();
      const { rounds } = cup;
      for (let j = 0; j < rounds.length; j++) {
        if (j === roundIndex && cupDoc.id === cupID) {
          continue;
        }
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    for (let i = 0; i < events.docs.length; i++) {
      const eventDoc = events.docs[i];
      const event = eventDoc.data();
      const { rounds } = event;
      for (let j = 0; j < rounds.length; j++) {
        const roundDate = new Date(rounds[j].date.toDate());
        dateArr.push(roundDate);
      }
    }
    setUserDates(dateArr);
  }

  function validate() {
    setError("");
    const date = dateRef.current.value;
    const dateVal = dateRef.current.valueAsDate;
    const now = moment();
    const dvMom = moment(dateVal);
    const cupMom = moment(cupDate);

    if (date === "") {
      setError("Please select a date for this round");
      dateRef.current.focus();
      return false;
    }
    // if (now.isAfter(dvMom)) {
    //   // setError("Please select a date in the future for this round");
    //   dateRef.current.focus();
    //   return false;
    // }
    // if (cupMom.isAfter(dvMom)) {
    //   // setError("Please ensure that this round starts after the cup start date");
    //   dateRef.current.focus();
    //   return false;
    // }

    // for (let i = 0; i < userDates.length; i++) {
    //   const userDateMoment = moment(userDates[i]);
    //   if (userDateMoment.isSame(dvMom, "date")) {
    //     setError("Sorry! This date conflicts with one of your future rounds");
    //     dateRef.current.focus();
    //     return false;
    //   }
    // }

    for (let i = 0; i < rounds.length; i++) {
      const round = rounds[i];
      if (i > roundIndex) {
        continue;
      }
      console.log(round);
      // const date = new Date(round.date.toDate());
      const roundMom = moment(round.date);
      if (roundMom.isAfter(dvMom)) {
        setError(
          "Please ensure that this round starts after the previous rounds"
        );
        dateRef.current.focus();
        return false;
      }
    }

    // Matchplay
    if (format === "Matchplay") {
      // let missing = false;
      for (let i = 0; i < matches.length; i++) {
        if (matches[i].playerOne === "" || matches[i].playerTwo === "") {
          setError("Please ensure all matches are set up");
          return false;
        }
      }
    } else {
      for (let i = 0; i < matches.length; i++) {
        const teamOne = matches[i].teamOne;
        const teamTwo = matches[i].teamTwo;
        if (teamOne[0] === "") {
          setError("Please ensure all matches are set up");
          return false;
        }
        if (teamOne[1] === "") {
          setError("Please ensure all matches are set up");
          return false;
        }
        if (teamTwo[0] === "") {
          setError("Please ensure all matches are set up");
          return false;
        }
        if (teamTwo[1] === "") {
          setError("Please ensure all matches are set up");
          return false;
        }
      }
    }
    if (enableClosest && ctpHole === "") {
      setError("Please select a hole for closest to the pin");
      return false;
    }

    if (enableLD && ldHole === "") {
      setError("Please select a hole for longest drive");
      return false;
    }
  }

  async function storeEvent() {
    // return console.log(matches)
    // return console.log(cupID)
    setLoading(true);

    if (validate() === false) {
      return setLoading(false);
    }
    const date = new Date(dateRef.current.value);

    // const courseDoc = await db.collection("courses").doc(courseID).get();
    // const courseData = courseDoc.data();
    // const { imageLink, teeArray, length } = courseData;
    const { imageLink, teeArray, length, courseID, name, tees } = course;

    const cupDoc = await db.collection("cups").doc(cupID).get();
    const cup = cupDoc.data();
    const { seeding, teams } = cup;
    const teamOne = teams[0].name;
    const teamTwo = teams[1].name;
    const seedProp = {};
    seedProp[teamOne] = { rounds: [] };
    seedProp[teamTwo] = { rounds: [] };

    for (let i = 0; i < matches.length; i++) {
      const match = matches[i];
      if (format === "Matchplay") {
        if (
          match.playerOne.tee === undefined ||
          !teeArray.includes(match.playerOne.tee)
        ) {
          console.log("changing 1");
          matches[i].playerOne.tee = teeArray[0];
        }
        if (
          match.playerTwo.tee === undefined ||
          !teeArray.includes(match.playerTwo.tee)
        ) {
          console.log("changing 2");
          matches[i].playerTwo.tee = teeArray[0];
        }
      } else {
      }
    }

    // console.log(matches)
    // return setLoading(false)

    const seeds = cup.seeds ?? seedProp;
    console.log(seeds, teamOne);
    console.log(seeds[teamOne]);

    if (seeding) {
      if (seeds[teamOne].rounds === undefined) {
        seeds[teamOne].rounds = [];
      }
      if (seeds[teamTwo].rounds === undefined) {
        seeds[teamTwo].rounds = [];
      }

      seeds[teamOne].rounds.push({ players: [] });
      seeds[teamTwo].rounds.push({ players: [] });
    }
    console.log(seeds);
    // return console.log(date, courseID, name, imageLink, matches, teeArray, length, format, enableClosest, ctpHole, enableLD, timesArray)

    rounds.push({
      date,
      tees,
      courseID,
      courseName: name,
      imageLink,
      matches,
      teeArray,
      length,
      format,
      ctpEnabled: enableClosest,
      ctpHole,
      ldEnabled: enableLD,
      ldHole,
      timesArray,
    });

    try {
      await db.collection("cups").doc(cupID).update({
        rounds,
        seeds,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered some difficulties there, please try again."
      );
      setLoading(false);
    }
  }

  async function saveChanges() {
    if (validate() === false) {
      return;
    }
    // setLoading(true);
    const date = new Date(dateRef.current.value);

    // const courseDoc = await db.collection("courses").doc(courseID).get();
    // const courseData = courseDoc.data();
    // const { imageLink, teeArray, length } = courseData;
    // console.log(course)

    const { imageLink, teeArray, length, courseID, name, tees } = course;

    rounds[roundIndex] = {
      date,
      courseID,
      tees,
      courseName: name,
      imageLink,
      matches,
      teeArray,
      length,
      format,
      ctpEnabled: enableClosest,
      ctpHole,
      ldEnabled: enableLD,
      ldHole,
      timesArray,
    };

    // return console.log(rounds)

    try {
      await db.collection("cups").doc(cupID).update({
        rounds,
      });
      reload();
      goBack();
    } catch (err) {
      console.log(err);
      setError(
        "Sorry - we encountered some difficulties there, please try again."
      );
      setLoading(false);
    }
  }

  const [hintsEnabled, setHintsEnabled] = useState(false);
  const steps = [
    {
      element: ".shuffle-hint",
      intro: "Tap shuffle to let the app generate matches",
    },
    {
      element: ".times-hint",
      intro: "Tap on 'Select player' to chose a player for the relevant match",
    },
    {
      element: ".format-hint",
      intro: "Select a format for this round - singles or group matchplay",
    },
  ];

  return (
    <div className="cup-round scale-in-ps event-creator">
      {error !== "" && <ErrorModal hide={() => setError("")} text={error} />}
      {loading && <BackdropLoader />}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="info-message">
          <InfoIcon className="big-info-icon dg-icon" />
          <p>{dialog}</p>
        </div>
      </Dialog>
      {false ? (
        <div className="loading-container elc">
          <BallTriangle color="#1e7a69" height={80} width={80} />
          <p>Saving round...</p>
        </div>
      ) : !chosen ? (
        <CoursePicker
          round
          goBack={() => setChosen(true)}
          setChosen={setChosen}
          setCourseDetails={(e) => {
            setCourse(e);
            // setCourseChanged(true);
          }}
        />
      ) : showMatch ? (
        <CupMatchups
          goBack={() => setShowMatch(false)}
          format={format}
          teams={teams}
          saveMatches={setMatches}
          defaultMatches={matches}
        />
      ) : showFormats ? (
        <CupFormatPicker
          goBack={() => setShowFormats(false)}
          setFormat={setFormat}
          chosenFormat={format}
        />
      ) : (
        <>
          {false && (
            <div onClick={goBack} className="back-row">
              <ArrowBack className="" />
              <p>Round Setup</p>
            </div>
          )}

          <BackRow action={goBack} />
          <PageHeader text="Round Setup" />

          <div className="round-input">
            <div className="ec-box" style={{ alignItems: "flex-end" }}>
              <div className="input-group">
                <div className="ig-header">
                  <p className="mb-0">Course</p>
                  <Edit
                    className="dg-icon-bg icon-cursor ml-20"
                    onClick={() => {
                      setChosen(false);
                    }}
                  />
                </div>
                <div>
                  <input disabled value={course.label ?? course.name} />
                </div>
              </div>
              <div className="input-group">
                <p>Date</p>
                <input
                  type="date"
                  ref={dateRef}
                  defaultValue={dateState}
                  onChange={(e) => setDateState(e.target.value)}
                />
              </div>

              <div className="input-group fp-drop format-hint">
                <div className="ig-header">
                  <p>Format</p>

                  <Edit
                    className="dg-icon-bg icon-cursor ml-20"
                    onClick={() => {
                      setShowFormats(true);
                    }}
                  />
                </div>
                {/* <Dropdown
                  arrowClosed={<KeyboardArrowDownIcon className="drop-icon" />}
                  arrowOpen={<KeyboardArrowUpIcon className="drop-icon" />}
                  value={format}
                  options={formats}
                  onChange={(e) => {
                    console.log(e.value);
                    setFormat(e.value);
                  }}
                /> */}

                <div className="">
                  <input disabled value={format} />
                </div>
              </div>
            </div>

            <div className="ec-box">
              <div className="input-group ">
                <div className="ig-header">
                  <p>Closest to the pin</p>
                  <InfoIcon
                    onClick={() => {
                      setDialog(
                        "Enable a closest to the pin competition on a par 3 of your choice - the winner earns their team an additional point."
                      );
                      setShowDialog(true);
                    }}
                    className="info-icon dg-icon-bg mb-10"
                  />
                  <Switch
                    className={
                      enableClosest
                        ? "scoring-switch switch-on mb-10"
                        : "scoring-switch mb-10"
                    }
                    onColor="#1e7a69"
                    checked={enableClosest}
                    onChange={() => setEnableClosest((current) => !current)}
                  />
                </div>
                {enableClosest && (
                  <Dropdown
                    placeholder={"Select hole..."}
                    value={ctpHole}
                    onChange={(e) => setCTPHole(e)}
                    options={ctpOptions}
                  />
                )}
              </div>
              <div className="input-group">
                <div className="ig-header">
                  <p>Longest Drive</p>
                  <InfoIcon
                    onClick={() => {
                      setDialog(
                        "Enable a longest drive competition on a par 4 or 5 of your choice - the winner earns their team an additional point.."
                      );
                      setShowDialog(true);
                    }}
                    className="info-icon dg-icon-bg mb-10"
                  />
                  <Switch
                    className={
                      enableLD
                        ? "scoring-switch switch-on mb-10"
                        : "scoring-switch mb-10"
                    }
                    onColor="#1e7a69"
                    checked={enableLD}
                    onChange={() => setEnableLD((current) => !current)}
                  />
                </div>
                {enableLD && (
                  <Dropdown
                    placeholder={"Select hole..."}
                    value={ldHole}
                    onChange={(e) => setLDHole(e)}
                    options={ldOptions}
                  />
                )}
              </div>
            </div>

            {seeding && (
              <>
                <div className="neh-box" style={{ padding: "0px" }}>
                  <h2 className="ne-header">Times</h2>
                </div>
                {/* <p>Times</p> */}
                {timesArray.map((time, index) => (
                  <div className="input-group">
                    <p>Match {index + 1}</p>
                    <input
                      // value={time}
                      defaultValue={time}
                      type={"time"}
                      onChange={(e) =>
                        setTimesArray((current) => {
                          current[index] = e.target.value;
                          return current;
                        })
                      }
                    />
                  </div>
                ))}
              </>
            )}
          </div>

          {!seeding && (
            <CupMatchups
              goBack={() => setShowMatch(false)}
              format={format}
              teams={teams}
              saveMatches={setMatches}
              defaultMatches={matches}
            />
          )}

          {/* {error !== "" && (
            <div className="error-message-row">
              <p>{error}</p>
            </div>
          )} */}
          {/* 
          <div className="ec-button-row">
                <button className='invite-button' onClick={() => setShowMatch(true)}>Set Matches</button>
            </div> */}

          <div className="flex-center mb-20 mt-40">
            {!edit ? (
              <button className="default-button" onClick={storeEvent}>
                Add Round
              </button>
            ) : (
              <button className="default-button" onClick={saveChanges}>
                Save Changes
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default CupRound;
